export const reworkItemStatus = {
    rpc: {
        getOne: {
            funcId: 12001,
            funcName: "reworkItemStatusOne",
        },
        getAll: {
            funcId: 12002,
            funcName: "reworkItemStatusAll",
        },
        insert: {
            funcId: 12003,
            funcName: "reworkItemStatusInsert",
        },
        update: {
            funcId: 12004,
            funcName: "reworkItemStatusUpdate",
        },
        inserted: {
            funcId: 12005,
            funcName: "reworkItemStatusInserted",
        },
        updated: {
            funcId: 12006,
            funcName: "reworkItemStatusUpdated",
        },
        delete: {
            funcId: 12007,
            funcName: "reworkItemStatusDelete",
        },
        deleted: {
            funcId: 12008,
            funcName: "reworkItemStatusDeleted",
        },
    },
    serviceId: 120,
    serviceVersion: 1,
};
