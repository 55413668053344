import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    ElectronicSignatureAllReq,
    ElectronicSignatureAllRes,
    ElectronicSignatureInsertedNotif,
    ElectronicSignatureInsertReq,
    ElectronicSignatureInsertRes,
    ElectronicSignatureOneReq,
    ElectronicSignatureOneRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { electronicSignatureAll } from "./all";
import { electronicSignatureInsert } from "./insert";
import { electronicSignatureInserted } from "./inserted";
import { electronicSignatureOne } from "./one";

interface IAOSClientServiceWrappedElectronicSignature {
    getAll: AOSClientServiceRequestWrapped<ElectronicSignatureAllReq, ElectronicSignatureAllRes>;
    getOne: AOSClientServiceRequestWrapped<ElectronicSignatureOneReq, ElectronicSignatureOneRes>;
    insert: AOSClientServiceRequestWrapped<ElectronicSignatureInsertReq, ElectronicSignatureInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<ElectronicSignatureInsertedNotif>;
}

type AOSClientServiceWrappedElectronicSignature = IAOSClientServiceWrappedElectronicSignature;

// ADD_API_CALL Electronic Signature
export function generateElectronicSignatureServiceClient(
    router: IRouterClient,
    notifier: EventEmitter
): AOSClientServiceWrappedElectronicSignature {
    return {
        getAll: AOSClientServiceWrapperUI.request(electronicSignatureAll(router)),
        getOne: AOSClientServiceWrapperUI.request(electronicSignatureOne(router)),
        insert: AOSClientServiceWrapperUI.request(electronicSignatureInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(electronicSignatureInserted(router, notifier)),
    };
}

generateElectronicSignatureServiceClient.serviceId = 110;
