import {
    ResultSettingActionType,
    ResultSettingItemUpsertedAction,
    ResultSettingMetadataUpsertedAction,
    SetResultSettingItemListAction,
    SetResultSettingMetadataListAction,
} from "./result-setting-types";

/**
 * Returns an action
 *
 * @param {SetResultSettingItemListAction} resultSettingItems - value of the action
 */
export function setResultSettingItemListAction(
    resultSettingItems: SetResultSettingItemListAction["value"]
): SetResultSettingItemListAction {
    return { type: ResultSettingActionType.SET_RESULT_SETTING_ITEM_LIST, value: resultSettingItems };
}

/**
 * Returns an action
 *
 * @param {ResultSettingItemUpsertedAction} resultSettingItems - value of the action
 */
export function resultSettingItemUpsertedAction(
    resultSettingItems: ResultSettingItemUpsertedAction["value"]
): ResultSettingItemUpsertedAction {
    return { type: ResultSettingActionType.UPSERT_RESULT_SETTING_ITEM, value: resultSettingItems };
}

/**
 * Returns an action
 *
 * @param {SetResultSettingMetadataListAction} resultSettingMetadata - value of the action
 */
export function setResultSettingMetadataListAction(
    resultSettingMetadata: SetResultSettingMetadataListAction["value"]
): SetResultSettingMetadataListAction {
    return { type: ResultSettingActionType.SET_RESULT_SETTING_METADATA_LIST, value: resultSettingMetadata };
}

/**
 * Returns an action
 *
 * @param {ResultSettingMetadataUpsertedAction} resultSettingMetadata - value of the action
 */
export function resultSettingMetadataUpsertedAction(
    resultSettingMetadata: ResultSettingMetadataUpsertedAction["value"]
): ResultSettingMetadataUpsertedAction {
    return { type: ResultSettingActionType.UPSERT_RESULT_SETTING_METADATA, value: resultSettingMetadata };
}
