import { ILoopState, ProcessActionStepLoop } from "@kortex/aos-common";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    outputContainer: {
        height: "-webkit-fill-available",
        padding: "32px",
        width: "-webkit-fill-available",
    },
    outputText: {
        marginBottom: "32px",
        textAlign: "center",
    },
    outputTitle: {
        marginBottom: "32px",
    },
});

export interface IOwnProps {
    loopProps: ProcessActionStepLoop;
    loopState: ILoopState;
}

type IAllProps = IOwnProps;

function LoopPlayer(props: IAllProps): JSX.Element {
    const { loopProps, loopState } = props;

    const classes = useStyles();
    const translate = useTranslate();

    if (loopProps.config.alwaysDoOnce) {
        return (
            <div className={classes.outputContainer}>
                <Typography className={classes.outputTitle} variant="h1">
                    {translate("player.loop.count")}
                </Typography>
                <Typography className={classes.outputText} variant="h3">
                    {loopState.loopCount}
                </Typography>
                <Typography className={classes.outputTitle} variant="h1">
                    {translate("player.loop.maxCount")}
                </Typography>
                <Typography className={classes.outputText} variant="h3">
                    {loopProps.config.maxLoopCount}
                </Typography>
                <Typography className={classes.outputTitle} variant="h1">
                    {translate("player.loop.doOnce")}
                </Typography>
            </div>
        );
    }

    return (
        // @JAY TODO: Since the Runner is now using Kortex-Sequencer's loop,
        //            Some of the loopState properties are no longer updated during the execution.
        //            This page should be reworked with SequencerActionStateLoop.
        <div className={classes.outputContainer}>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.loop.count")}
            </Typography>
            <Typography className={classes.outputText} variant="h3">
                {loopState.loopCount}
            </Typography>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.loop.maxCount")}
            </Typography>
            <Typography className={classes.outputText} variant="h3">
                {loopProps.config.maxLoopCount}
            </Typography>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.conditionPlayer.condition")}
            </Typography>
            <Typography className={classes.outputText} variant="h3">
                {loopState.condition}
            </Typography>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.conditionPlayer.substitution")}
            </Typography>
            <Typography className={classes.outputText} variant="h3">
                {loopState.interpolatedCondition}
            </Typography>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.conditionPlayer.result")}
            </Typography>
            <Typography className={classes.outputText} variant="h3">
                {/*loopState.result.toString()*/}
            </Typography>
        </div>
    );
}

export default LoopPlayer;
