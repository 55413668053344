import { KortexTextField, greyPalette, theme } from "@aos/react-components";
import MomentUtils from "@date-io/moment";
import {
    IJobProcessOfTheReworkModel,
    ITokenDecoded,
    JOB_QTY_MAX,
    JobProcessStatusEnum,
    ProcessApprovalStatusFilter,
    getDateFormat,
    getDateFromTimestamp,
    getElapsedTimeStr,
    getTimestampFromDateStr,
} from "@kortex/aos-common";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ReworkIcon from "@material-ui/icons/BuildOutlined";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useSelectorLanguage } from "../../../../../redux/selectors";
import { userCanInsert } from "../../../../../utilitites/IUserRights";
import {
    getJobProcessInProgress,
    getJobProcessStatusColor,
    getJobProcessStatusLabelKey,
} from "../../../../../utilitites/jobAndJobProcessData";
import KortexPanelCard from "../../../../core/KortexPanelCard";
import ProcessVersionPicker from "../../../../core/ProcessVersionPicker/ProcessVersionPicker";

const useStyles = makeStyles({
    textSmall: {
        margin: "5px",
        width: "80px",
    },
    textLarge: {
        margin: "5px",
        width: "170px",
    },
    processPanelCardAllContainer: {
        display: "flex",
        flexDirection: "row",
    },
    processPanelCardContainer: {
        margin: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 2,
    },
    processPanelCardProcessNameContainer: {
        display: "inline-flex",
        flex: 1,
    },
    processPanelCardTitle: {
        display: "flex",
        flexDirection: "row",
        color: theme.palette.primary[500],
        justifyContent: "space-between",
    },
    processPanelCardTypo: {
        padding: "5px 10px",
    },
    processPanelCardText: {
        alignItems: "flex-end",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexFlow: "wrap",
    },
    iconContainer: {
        color: greyPalette[500],
    },
    datePickerLabel: {
        display: "inline-block",
    },
    datePickerInput: {
        padding: "5px",
        fontSize: "1rem",
    },
});

interface IOwnProps {
    jobProcess: IJobProcessOfTheReworkModel;
    isSelected: boolean;
    isDragDisabled: boolean;
    userInfo?: ITokenDecoded;
    key?: number;
    onUpdateProcess?: (jobProcess: IJobProcessOfTheReworkModel) => void;
    onSelection?: (jobProcess: IJobProcessOfTheReworkModel) => void;
}

export default function FailureTicketJobProcessCard(props: IOwnProps): JSX.Element {
    const { jobProcess, isDragDisabled, userInfo, isSelected } = props;

    const classes = useStyles();
    const language = useSelectorLanguage();
    const [textFieldReadOnly, setTextFieldReadOnly] = useState<boolean>(false);
    const translate = useTranslate();

    /**
     * Update state of text field based on jobProcess status and user access
     */
    useEffect((): void => {
        if (jobProcess.status === JobProcessStatusEnum.ARCHIVED || !userCanInsert(userInfo?.roleRights.rework)) {
            setTextFieldReadOnly(true);
        }
    }, [isSelected]);

    /**
     * Handle called when the "Planned On" field is updated
     *
     * @param {IJobProcessOfTheReworkModel} changeProcess - jobProcess to update
     */
    const handleJobPlannedDateChange =
        (changeProcess: IJobProcessOfTheReworkModel): ((date: MaterialUiPickersDate) => void) =>
        (date: MaterialUiPickersDate): void => {
            if (isSelected) {
                changeProcess.scheduledOn = date ? getTimestampFromDateStr(String(date)) : 0;

                if (props.onUpdateProcess) {
                    props.onUpdateProcess(changeProcess);
                }
            }
        };

    /**
     * Handle selection of the job process
     *
     * @param {IJobProcessOfTheReworkModel} jobProcess - process selected
     */
    const handleSelectJobProcess =
        (jobProcess: IJobProcessOfTheReworkModel): (() => void) =>
        (): void => {
            if (props.onSelection) {
                props.onSelection(jobProcess);
            }
        };

    /**
     * handle the process version change
     *
     * @param {number} processId - process id
     */
    const handleProcessVersionChange = (processId: number): void => {
        if (props.onUpdateProcess && process && jobProcess.processId !== processId) {
            props.onUpdateProcess({ ...jobProcess, processId: processId, purgeVersion: false });
        }
    };

    const jobProcessInProgress = getJobProcessInProgress(jobProcess.qtyStarted, jobProcess.qty, jobProcess.qtyPassed, jobProcess.qtyFailed);
    return (
        <React.Fragment>
            {process && (
                <div style={{ paddingTop: jobProcess.routingId > 0 ? "0px" : "6px" }}>
                    <KortexPanelCard
                        isSelected={isSelected}
                        spaceBetweenCard={jobProcess.routingId > 0 ? 0 : 1}
                        padding={10}
                        variant={jobProcess.routingId > 0 ? "flat" : "none"}
                        onSelect={handleSelectJobProcess(jobProcess)}
                        isDraggable={!isDragDisabled}
                        statusColor={getJobProcessStatusColor(jobProcess.status)}
                    >
                        <div className={classes.processPanelCardAllContainer} id="jobProcessCardId">
                            <div className={classes.processPanelCardContainer}>
                                <div className={classes.processPanelCardTitle}>
                                    <ReworkIcon className={classes.iconContainer} />
                                    <div className={classes.processPanelCardProcessNameContainer}>
                                        {/* PROCESS NAME */}
                                        <Typography
                                            id="jobProcessCardProcessNameId"
                                            className={classes.processPanelCardTypo}
                                            color="primary"
                                            variant="h6"
                                        >
                                            {jobProcess.process.treeNodeProcess.label}
                                        </Typography>
                                    </div>

                                    {jobProcess && jobProcess.processId && (
                                        <>
                                            <ProcessVersionPicker
                                                latest={false}
                                                onVersionChange={handleProcessVersionChange}
                                                processId={jobProcess.processId}
                                                readOnly={
                                                    jobProcess.routingId !== 0 ||
                                                    jobProcess.status !== JobProcessStatusEnum.DRAFT ||
                                                    textFieldReadOnly
                                                }
                                                treeNodeId={jobProcess.process.treeNodeProcess.treeNodeId}
                                                showPurgeIcon={jobProcess.purgeVersion}
                                                variant={ProcessApprovalStatusFilter.APPROVED}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className={classes.processPanelCardText} id={"jobProcessStatusSelectId"}>
                                    {/* STATUS */}
                                    <KortexTextField
                                        className={classes.textLarge}
                                        InputProps={{
                                            id: "jobProcessStatusSelectId",
                                            readOnly: true,
                                        }}
                                        label={translate("scheduler.status")}
                                        value={translate(getJobProcessStatusLabelKey(jobProcess.status))}
                                        variant="standard"
                                    />
                                    {/* QUANTITY */}
                                    <KortexTextField
                                        label={translate("scheduler.quantity")}
                                        value={jobProcess.qty}
                                        InputProps={{
                                            id: "jobProcessQuantityId",
                                            readOnly: textFieldReadOnly,
                                        }}
                                        max={JOB_QTY_MAX}
                                        className={classes.textLarge}
                                        variant="standard"
                                        type="number"
                                    />
                                    {jobProcess.status !== JobProcessStatusEnum.DRAFT && (
                                        <div>
                                            {/* QUANTITY IN PROGRESS*/}
                                            <KortexTextField
                                                label={translate("scheduler.quantityInProgress")}
                                                value={jobProcessInProgress.inProgress}
                                                className={classes.textSmall}
                                                variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                    id: "jobProcessQuantityStartedId",
                                                }}
                                            />
                                            {/* QUANTITY REMAINING TO START*/}
                                            <KortexTextField
                                                label={translate("scheduler.quantityRemainingToStart")}
                                                value={jobProcessInProgress.remainingToStart}
                                                className={classes.textSmall}
                                                variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                    id: "jobProcessQuantityRemainingId",
                                                }}
                                            />
                                            {/* QUANTITY PASS */}
                                            <KortexTextField
                                                label={translate("scheduler.quantityPass")}
                                                value={jobProcess.qtyPassed ?? 0}
                                                className={classes.textSmall}
                                                variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                    id: "jobProcessQuantityPassId",
                                                }}
                                            />
                                            {/* QUANTITY FAIL */}
                                            <KortexTextField
                                                label={translate("scheduler.quantityFail")}
                                                value={jobProcess.qtyFailed ?? 0}
                                                className={classes.textSmall}
                                                variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                    id: "jobProcessQuantityFailId",
                                                }}
                                            />
                                        </div>
                                    )}
                                    {/* TIME RUN */}
                                    <KortexTextField
                                        label={translate("scheduler.elapsedTime")}
                                        value={`${getElapsedTimeStr(jobProcess.timeRun)}`}
                                        className={classes.textLarge}
                                        variant="standard"
                                        InputProps={{
                                            readOnly: true,
                                            id: "jobProcessElapsedTimeId",
                                        }}
                                    />
                                    {/* DATE SCHEDULED_ON */}
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                                        <DateTimePicker
                                            allowKeyboardControl={true}
                                            ampm={false}
                                            className={classes.textLarge}
                                            clearable={true}
                                            emptyLabel=" "
                                            format={getDateFormat(true)}
                                            InputProps={{
                                                inputProps: {
                                                    className: classes.datePickerInput,
                                                },
                                            }}
                                            label={<span className={classes.datePickerLabel}>{translate("scheduler.scheduledOn")}</span>}
                                            onChange={handleJobPlannedDateChange(jobProcess)}
                                            readOnly={textFieldReadOnly}
                                            value={Boolean(jobProcess.scheduledOn) ? getDateFromTimestamp(jobProcess.scheduledOn) : null}
                                            variant="dialog"
                                            id="scheduledOnId"
                                            clearLabel={translate("general.clear")}
                                            cancelLabel={translate("general.cancel")}
                                            okLabel={translate("general.select")}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {/* CHECK PREVIOUS BUTTON */}
                                    <FormControlLabel
                                        className={classes.textLarge}
                                        control={
                                            <Checkbox
                                                disabled={jobProcess.routingId > 0 || textFieldReadOnly}
                                                checked={jobProcess.checkPrevious}
                                            />
                                        }
                                        label={translate("scheduler.checkPrevious")}
                                    />
                                </div>
                            </div>
                        </div>
                    </KortexPanelCard>
                </div>
            )}
        </React.Fragment>
    );
}
