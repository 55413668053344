import { ISpeedDialAction, KortexDialogConfirmation, KortexSpeedDial, theme } from "@aos/react-components";
import {
    IJobUiModel,
    ITokenDecoded,
    ITreeNodeDbModel,
    JobStatusEnum,
    ProcessApprovalStatusFilter,
    TreeNodeNodeTypeEnum,
    createJobUiModel,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { makeStyles } from "@material-ui/core";
import ProcessIcon from "@material-ui/icons/FolderOutlined";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import * as React from "react";
import { useContext, useState } from "react";

import { useTranslate } from "../../../../hooks/useTranslate";
import { processGet } from "../../../../redux/process-manager/process-thunks-process";
import { jobInsert, jobJobProcessInsert, jobJobProcessRoutingInsert } from "../../../../redux/scheduler-manager/scheduler-thunks-job";
import { userCanInsert } from "../../../../utilitites/IUserRights";
import RoutingIcon from "../../../core/Icons/Routing/Routing";
import ProcessRepository from "../../ProcessEditor/RepositoryManager/ProcessRepository";
import { SchedulerContext } from "../utilities";

const useStyles = makeStyles({
    speedDial: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    speedDialFab: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    speedDialButton: {
        width: "35px",
        height: "35px",
        boxShadow: "0px 4px 5px 0px",
        backgroundColor: theme.palette.grey[200],
    },
    speedDialActionFab: {
        width: "35px",
        height: "35px",
        boxShadow: "0px 4px 5px 0px",
        backgroundColor: theme.palette.grey[300],
    },
    speedDialTooltipLabel: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[50],
    },
    dialogContent: {
        height: "60vh",
        width: "80vw",
        maxWidth: "1102px",
    },
});

interface IOwnProps {
    userInfo?: ITokenDecoded;
    onNewJobAdded: () => void;
}

export default function AddJobProcessRouting(props: IOwnProps): JSX.Element {
    const { userInfo, onNewJobAdded } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const [selectedProcessRouting, setSelectedProcessRouting] = useState<ITreeNodeDbModel | undefined>(undefined);
    const [selectedProcessRoutingVersions, setSelectedProcessRoutingVersions] = useState<number | undefined>(undefined);
    const [isProcessRepoDialogOpen, setIsProcessRepoDialogOpen] = useState(false);
    const [filterType, setFilterType] = useState<TreeNodeNodeTypeEnum | undefined>(undefined);

    const [selectedJob] = useContext(SchedulerContext);

    /**
     * Handle called when the user add a job manually
     */
    const handleAddJob = (): void => {
        const newJob: IJobUiModel = createJobUiModel();
        dispatch(jobInsert([newJob]));
        onNewJobAdded();
    };

    /**
     * Handle called when the user request to add a process.  This simply open the process repos dialog
     */
    const handleAddProcess = (): void => {
        setFilterType(TreeNodeNodeTypeEnum.PROCESS);
        if (selectedJob) {
            setIsProcessRepoDialogOpen(true);
        }
    };

    /**
     * Handle called when the user request to add a routing.  This simply open the process repos dialog
     */
    const handleAddRouting = (): void => {
        setFilterType(TreeNodeNodeTypeEnum.ROUTING);
        if (selectedJob) {
            setIsProcessRepoDialogOpen(true);
        }
    };

    /**
     * Handle that process the repository/routing selections of the user.  For now, only the process are accepted...
     * Needs more definition
     */
    const handleProcessRepoSelection = async (): Promise<void> => {
        setIsProcessRepoDialogOpen(false);
        if (selectedProcessRouting && selectedJob) {
            if (selectedProcessRouting.treeNodeId && selectedProcessRoutingVersions) {
                const process = await dispatch(processGet(selectedProcessRoutingVersions));

                if (process) {
                    if (selectedProcessRouting.nodeType === TreeNodeNodeTypeEnum.PROCESS) {
                        dispatch(
                            jobJobProcessInsert({
                                job: selectedJob,
                                processId: process.processId || -1,
                                reworkId: selectedJob.reworkId,
                                latestVersion: false,
                            })
                        );
                    } else if (selectedProcessRouting.nodeType === TreeNodeNodeTypeEnum.ROUTING) {
                        dispatch(jobJobProcessRoutingInsert({ job: selectedJob, routingProcessId: process.processId || -1 }));
                    }
                }
            } else {
                // Create a jira issue for that. The user has no idea why it did not work since there is no snackbar message...
                console.error("No version of the process selected, cannot use draft in scheduler");
            }
        }
    };

    /**
     * Handle that is called when an option in the dialog process repos is changed... Keep value in a state until apply is called
     *
     * @param {ITreeNodeDbModel[]} processesOrRoutings - processes  and/or routings selected
     */
    const handleProcessRepoSelectionChanged = async (
        processesOrRoutings: ITreeNodeDbModel[],
        versions?: (number | undefined)[]
    ): Promise<void> => {
        // const treeNode = processesOrRoutings.length > 0 ? processesOrRoutings[0] : undefined;
        // const processId = versions && versions.length > 0 ? versions[0] : undefined;

        setSelectedProcessRouting(processesOrRoutings[0]);
        setSelectedProcessRoutingVersions(versions?.[0]);
    };

    /**
     * Handle that is called to open or close the process/routing repos
     *
     * @param {boolean} isOpen - open or close the dialog
     */
    const handleProcessRepoOpen =
        (isOpen: boolean): (() => void) =>
        (): void => {
            setIsProcessRepoDialogOpen(isOpen);
        };

    /**
     * Gets the actions given to SpeedDial
     */
    const getSpeedDialActions = (): ISpeedDialAction[] => {
        if (selectedJob && selectedJob.status === JobStatusEnum.DRAFT) {
            return [
                {
                    callback: handleAddJob,
                    icon: <SpeedDialIcon id="schedulerInsertJobButtonId" />,
                    label: translate("scheduler.insertJob"),
                    classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
                },
                {
                    callback: handleAddProcess,
                    icon: <ProcessIcon id="schedulerInsertProcessButtonId" />,
                    label: translate("scheduler.insertProcess"),
                    classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
                },
                {
                    callback: handleAddRouting,
                    icon: <RoutingIcon id="schedulerInsertRoutingButtonId" />,
                    label: translate("scheduler.insertRouting"),
                    classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
                },
            ];
        } else {
            return [
                {
                    callback: handleAddJob,
                    icon: <SpeedDialIcon id="schedulerInsertJobButtonId" />,
                    label: translate("scheduler.insertJob"),
                    classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
                },
            ];
        }
    };

    return (
        <>
            {/* Speed Dial */}
            {userCanInsert(userInfo?.roleRights.scheduler) && (
                <KortexSpeedDial
                    ariaLabel="SchedulerSpeedDial"
                    SpeedDialProps={{
                        className: classes.speedDial,
                        id: "SchedulerSpeedDialId",
                    }}
                    actions={getSpeedDialActions()}
                />
            )}
            {/* PrecessRepository Selector */}
            <KortexDialogConfirmation
                open={isProcessRepoDialogOpen}
                dialogProps={{
                    fullWidth: false,
                    maxWidth: false,
                    onBackdropClick: handleProcessRepoOpen(true),
                }}
                textLabels={
                    filterType === TreeNodeNodeTypeEnum.PROCESS
                        ? {
                              titleLabel: translate("scheduler.selectProcess"),
                              cancelButtonLabel: translate("scheduler.cancel"),
                              proceedButtonLabel: translate("scheduler.select"),
                          }
                        : {
                              titleLabel: translate("scheduler.selectRouting"),
                              cancelButtonLabel: translate("scheduler.cancel"),
                              proceedButtonLabel: translate("scheduler.select"),
                          }
                }
                onCancel={handleProcessRepoOpen(false)}
                onConfirm={handleProcessRepoSelection}
                closeOnEscape={true}
                confirmDisabled={!Boolean(selectedProcessRouting)}
            >
                <div className={classes.dialogContent}>
                    {isProcessRepoDialogOpen && (
                        <ProcessRepository
                            filterType={filterType}
                            onSelectionChanged={handleProcessRepoSelectionChanged}
                            multiItemSelection={false}
                            preselectedTreeNodeId={selectedProcessRouting?.treeNodeId}
                            selectOnly={true}
                            showVersionVariant={ProcessApprovalStatusFilter.RELEASED}
                            showLatestVersion={false}
                            handleSelectProcessConfirmation={handleProcessRepoSelection}
                        />
                    )}
                </div>
            </KortexDialogConfirmation>
        </>
    );
}
