import { IResultSettingItemDbModel, UncategorizedGetResultSettingItemRes, UnwrapAOSPayload } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { resultSettingItemUpsertedAction, setResultSettingItemListAction } from "./result-setting-actions";

/**
 * Get all ResultSettingItem
 */
export function resultSettingItemsGetAll(
    options?: IStandardThunkOptions
): StandardThunk<UnwrapAOSPayload<UncategorizedGetResultSettingItemRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<UncategorizedGetResultSettingItemRes>> {
        try {
            if (!skipLookup) {
                if (fetchedOnce.was(resultSettingItemsGetAll)) {
                    return getState().resultSetting.resultSettingItems;
                }
            }

            return api.services.uncategorized
                .getResultSettingItem(emptyObject)()
                .then((items) => {
                    if (!skipDispatch) {
                        fetchedOnce.seal(resultSettingItemsGetAll);

                        dispatch(setResultSettingItemListAction([...items]));
                    }

                    return items;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            return [];
        }
    };
}

/**
 * Upsert ResultSettingItem
 */
export function resultSettingItemUpsert(
    resultSettingItem: APIPayload<"uncategorized", "upsertResultSettingItem">
): StandardThunk<IResultSettingItemDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IResultSettingItemDbModel | undefined> => {
        try {
            const resp = await api.services.uncategorized
                .upsertResultSettingItem(resultSettingItem)()
                .then((item) => {
                    dispatch(resultSettingItemUpsertedAction([item]));
                    return item;
                });
            return resp;
        } catch (error) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            handleAPIError(error, dispatch);

            return undefined;
        }
    };
}
