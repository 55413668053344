import { IWorkInstructionsElementConfig, IWorkInstructionsVideoProps } from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core";
import * as React from "react";
import ReactPlayer from "react-player";

import * as CloudStorage from "../../../../../../../../utilitites/storage";

const useStyles = makeStyles({
    player: {
        pointerEvents: "all",
        position: "absolute",
    },
});

export interface IOwnProps {
    videoProps: IWorkInstructionsElementConfig<IWorkInstructionsVideoProps>;
}

export default function WorkInstructionsVideo(props: IOwnProps): JSX.Element {
    const { extendedProps, height, scale, rotation, width, x, y } = props.videoProps;

    const classes = useStyles();

    const finalHeight = height * scale;
    const finalWidth = width * scale;

    return (
        <g transform={`translate(${x},${y}) rotate(${rotation} ${finalWidth / 2} ${finalHeight / 2})`}>
            <foreignObject style={{ height: finalHeight, width: finalWidth }}>
                <ReactPlayer
                    className={classes.player}
                    height={finalHeight}
                    url={`${CloudStorage.get().host}/${extendedProps.url}`}
                    width={finalWidth}
                />
            </foreignObject>
            {/* This rect is used to patch the foreignObject hover setting the offsetX and Y to 0. See AOS-1087 */}
            <rect fill="transparent" height={finalHeight} width={finalWidth} />
        </g>
    );
}
