import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../hooks/useTranslate";

const useStyles = makeStyles({
    watermark: {
        color: "rgba(0,0,0,0.1)",
        fontSize: "12.5rem",
        position: "fixed",
        textAlign: "center",
        top: "240px",
        width: "100%",
        transform: "rotate(33deg)",
    },
});

interface IOwnProps {
    isDraft: boolean;
}

export default function PrintDraftWatermark(props: IOwnProps): JSX.Element {
    const { isDraft } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const watermarkText = translate("process.printing.draft");

    return isDraft ? <span className={classes.watermark}>{watermarkText}</span> : <div />;
}
