import {
    IProcessDbModel,
    ITaskInfoProcessApproval,
    IUserDbModel,
    IUserGroupDbModel,
    TTask,
    TaskTypeEnum,
    UserGroupDbModel,
    getFormattedDate,
} from "@kortex/aos-common";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../hooks/useTranslate";

import { IPage, PrintBasePage } from "./PrintBasePage";
import PrintDraftWatermark from "./PrintDraftWaterMark";

const useStyles = makeStyles({
    tableCell: {
        whiteSpace: "nowrap",
    },
    tableCellChangelog: {
        whiteSpace: "pre-wrap",
    },
});

interface IOwnProps {
    version?: IProcessDbModel;
    userList: IUserDbModel[];
    userGroups: IUserGroupDbModel[];
    tasks: TTask<TaskTypeEnum.PROCESS_APPROVAL, ITaskInfoProcessApproval>[];
}

type IAllProps = IOwnProps & IPage;

export default function PrintApproval(props: IAllProps): JSX.Element {
    const { version, userList, userGroups, tasks } = props;
    const classes = useStyles();

    const translate = useTranslate();

    /**
     * Generate Approval Table React Element
     */
    const generateApprovalTable = (): JSX.Element[] | undefined => {
        if (!version) {
            return;
        }

        return tasks.map((approvalTask, index): JSX.Element => {
            // User who did the approval
            const approvalUserInfo = userList.find((user) => user.userId === approvalTask.info.approverInfo[0].userId);
            const approverFullName = `${approvalUserInfo?.firstName} ${approvalUserInfo?.lastName}`;
            // ApprovalGroup
            const userGroup = userGroups.find((userGroup: UserGroupDbModel): boolean => userGroup.userGroupId === approvalTask.groupId);
            return (
                <TableRow key={index}>
                    <TableCell className={classes.tableCell}>{userGroup?.name}</TableCell>
                    <TableCell className={classes.tableCell}>{`${approverFullName} (${approvalUserInfo?.userName})`}</TableCell>
                    <TableCell className={classes.tableCellChangelog}>
                        {approvalTask.info.approverInfo[0].completedDate
                            ? getFormattedDate(approvalTask.info.approverInfo[0].completedDate, true)
                            : ""}
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <PrintBasePage {...props}>
            <g>
                <foreignObject x="0" y="0" width="100%" height="100%">
                    <div xmlns="http://www.w3.org/1999/xhtml">
                        <Box marginTop={2}>
                            <Typography variant="h1" color="primary" align="center" display="block">
                                {translate("process.printing.approvals")}
                            </Typography>
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography>{translate("process.printing.approvalGroup")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{translate("process.printing.approverName")}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{translate("process.printing.approvalDate")}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{generateApprovalTable()}</TableBody>
                        </Table>
                        <PrintDraftWatermark isDraft={Boolean(version && version.isDraft)} />
                    </div>
                </foreignObject>
            </g>
        </PrintBasePage>
    );
}
