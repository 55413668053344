// common
import { ProcessActionType } from "@kortex/aos-common";

import { ILocale } from "../locales/ILocale";

/**
 * Gets action description based on action type
 *
 * @param {ProcessActionType} actionType - type of the action
 * @returns {keyof ILocale} action description
 */
export function getActionDescription(actionType: ProcessActionType): keyof ILocale {
    switch (actionType) {
        case "core-condition":
            return "action.condition.description";
        case "core-connector":
            return "action.connector.description";
        case "core-data-store":
            return "action.dataStore.description";
        case "core-time":
            return "action.time.description";
        case "core-email":
            return "action.email.description";
        case "core-input":
            return "action.input.description";
        case "core-loop":
            return "action.loop.description";
        case "core-math":
            return "action.math.description";
        case "core-message":
            return "action.message.description";
        case "core-failure-ticket-create":
            return "action.failureTicketCreate.description";
        case "core-output":
            return "action.output.description";
        case "core-parser":
            return "action.parser.description";
        case "core-robot":
            return "action.robotControl.description";
        case "core-routing-process":
            return "action.routingProcess.description";
        case "core-routing-fail":
            return "action.routingFail.description";
        case "core-set":
            return "action.set.description";
        case "core-stop-process":
            return "action.stop.description";
        case "core-fail":
            return "action.fail.description";
        case "core-work-instructions":
            return "action.workInstructions.description";
        default:
            return "action.undefinedAction.description";
    }
}
