import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

function Line(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <polygon id="icons-line" points="2 13.2222222 22 13.2222222 22 11 2 11" />
        </SvgIcon>
    );
}

export default Line;
