import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    FileOneReq,
    FileOneRes,
    FileOneByTreeNodeReq,
    FileOneByTreeNodeRes,
    FileInsertReq,
    FileInsertRes,
    FileInsertedNotif,
    FileUpdateReq,
    FileUpdateRes,
    FileUpdatedNotif,
    FileOneByFileIdReq,
    FileOneByFileIdRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { fileInsert } from "./insert";
import { fileInserted } from "./inserted";
import { fileOne } from "./one";
import { fileOneByFileId } from "./oneByFileId";
import { fileOneByTreeNode } from "./oneByTreeNode";
import { fileUpdate } from "./update";
import { fileUpdated } from "./updated";

interface IAOSClientServiceWrappedFile {
    getOne: AOSClientServiceRequestWrapped<FileOneReq, FileOneRes>;
    getOneByFileId: AOSClientServiceRequestWrapped<FileOneByFileIdReq, FileOneByFileIdRes>;
    getOneByTreeNode: AOSClientServiceRequestWrapped<FileOneByTreeNodeReq, FileOneByTreeNodeRes>;
    insert: AOSClientServiceRequestWrapped<FileInsertReq, FileInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<FileInsertedNotif>;
    update: AOSClientServiceRequestWrapped<FileUpdateReq, FileUpdateRes>;
    onUpdated: AOSClientServiceNotificationWrapped<FileUpdatedNotif>;
}

type AOSClientServiceWrappedFile = IAOSClientServiceWrappedFile;

// ADD_API_CALL File
export function generateFileServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedFile {
    return {
        getOne: AOSClientServiceWrapperUI.request(fileOne(router)),
        getOneByFileId: AOSClientServiceWrapperUI.request(fileOneByFileId(router)),
        getOneByTreeNode: AOSClientServiceWrapperUI.request(fileOneByTreeNode(router)),
        insert: AOSClientServiceWrapperUI.request(fileInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(fileInserted(router, notifier)),
        update: AOSClientServiceWrapperUI.request(fileUpdate(router)),
        onUpdated: AOSClientServiceWrapperUI.notification(fileUpdated(router, notifier)),
    };
}

generateFileServiceClient.serviceId = 80;
