import { ProcessVariableStorer } from "../../../../../Process";
export var EnumConnectorType;
(function (EnumConnectorType) {
    EnumConnectorType["FILE"] = "file";
    EnumConnectorType["HTTP"] = "http";
    EnumConnectorType["MQTT"] = "mqtt";
    EnumConnectorType["ODBC"] = "pdcb";
    EnumConnectorType["REST"] = "rest";
    EnumConnectorType["SHELL"] = "shell";
    EnumConnectorType["TCP"] = "tcp";
    EnumConnectorType["UDP"] = "udp";
    EnumConnectorType["UNDEF"] = "N/A";
})(EnumConnectorType || (EnumConnectorType = {}));
export function createStepConfigConnector() {
    return {
        extendedProps: undefined,
        storeTo: new ProcessVariableStorer(),
        type: EnumConnectorType.UNDEF,
    };
}
