import { theme } from "@aos/react-components";
import { IBomItem } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles({
    tableCell: {
        border: `1px solid ${theme.palette.common.black}`,
        padding: "12px",
    },
    tableRowEven: {
        backgroundColor: theme.palette.grey[100],
    },
    tableRowHeader: {
        backgroundColor: theme.palette.grey[300],
    },
    tableRowOdd: {
        backgroundColor: theme.palette.common.white,
    },
});

export interface IOwnProps {
    item: IBomItem;
}

const BomItemInfoTable: FC<IOwnProps> = (props) => {
    const { item } = props;
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Table>
            <TableHead>
                <TableRow className={classes.tableRowHeader}>
                    <TableCell className={classes.tableCell} width="30%">
                        <Typography variant="h6">{translate("action.workInstructions.bom.information")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell} width="70%">
                        <Typography variant="h6">{translate("action.workInstructions.bom.value")}</Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow className={classes.tableRowOdd}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("bomPage.bomTable.partNumber")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2" id="partNumberId">
                            {item.partNumber}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.tableRowEven}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("bomPage.bomTable.description")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2" id="descriptionId">
                            {item.description}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.tableRowOdd}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("bomPage.bomTable.quantity")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2" id="quantityId">
                            {item.quantity}
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow className={classes.tableRowEven}>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2">{translate("bomPage.bomTable.unit")}</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        <Typography variant="body2" id="unitId">
                            {item.unit}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
};

export default BomItemInfoTable;
