import {
    ElectronicSignatureAllRes,
    ElectronicSignatureDbModelKey,
    IElectronicSignatureDbModel,
    UnwrapAOSPayload,
} from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import {
    electronicSignatureInsertedAction,
    electronicSignatureUpdatedAction,
    setElectronicSignatureListAction,
} from "./electronic-signature-actions";

/**
 * Get all electronicSignatures
 */
export function electronicSignatureGetAll(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<ElectronicSignatureAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ElectronicSignatureAllRes>> {
        if (!skipLookup) {
            if (fetchedOnce.was(electronicSignatureGetAll)) {
                return getState().electronicSignature.electronicSignatures;
            }
        }

        return api.services.electronicSignature
            .getAll(emptyObject)()
            .then((electronicSignatures) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(electronicSignatureGetAll);

                    dispatch(setElectronicSignatureListAction([...electronicSignatures]));
                }

                return electronicSignatures;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

/**
 * Get one electronicSignature
 */
export function electronicSignatureGetOne(
    electronicSignature: ElectronicSignatureDbModelKey
): StandardThunk<IElectronicSignatureDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IElectronicSignatureDbModel | undefined> => {
        try {
            const resp = await api.services.electronicSignature
                .getOne(electronicSignature)()
                .then((electronicSignature) => {
                    dispatch(electronicSignatureUpdatedAction([electronicSignature]));
                    return electronicSignature;
                });
            return resp;
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return undefined;
        }
    };
}

/**
 * Insert one electronicSignature
 */
export function electronicSignatureInsert(
    electronicSignature: APIPayload<"electronicSignature", "insert">
): StandardThunk<IElectronicSignatureDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IElectronicSignatureDbModel | undefined> => {
        try {
            const resp = await api.services.electronicSignature
                .insert(electronicSignature)()
                .then((electronicSignature) => {
                    dispatch(electronicSignatureInsertedAction(electronicSignature));
                    return electronicSignature;
                });
            return resp;
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return undefined;
        }
    };
}
