import { IRestKeyValue, ProcessEditorRightsEnum } from "@kortex/aos-common";
import { theme } from "@aos/react-components";
import { IconButton, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import * as React from "react";

import VariablePicker from "../../../../../../../core/VariablePicker/VariablePicker";
import { IUserRightsProps, userCanWrite } from "../../../../../../../../utilitites/IUserRights";
import { useTranslate } from "../../../../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    iconButton: {
        marginRight: "16px",
        marginTop: "16px",
    },
    keyTextField: {
        marginTop: "16px",
        marginRight: "16px",
        flex: 1,
    },
    root: {
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid rgba(0,0,0,0.12)",
        display: "grid",
        gridTemplateColumns: "1fr 1fr auto",
        marginBottom: "5px",
        paddingBottom: "16px",
        paddingLeft: "16px",
    },
    valueTextField: {
        marginTop: "16px",
        marginRight: "16px",
        flex: 1,
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    onBlur: (param: IRestKeyValue) => void;
    onDelete: () => void;
    param: IRestKeyValue;
}

export default function ConnectorRestParam(props: IOwnProps): JSX.Element {
    const { onBlur, onDelete, param, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Called when a param input is blurred
     *
     * @param {string} key - name of the edited field
     */
    const handleBlur =
        (key: keyof IRestKeyValue): ((value: string) => void) =>
        (value: string): void => {
            if (param[key] !== value) {
                onBlur({ ...param, [key]: value });
            }
        };

    return (
        <div className={classes.root}>
            <VariablePicker
                onBlur={handleBlur("key")}
                KortexTextFieldProps={{
                    className: classes.keyTextField,
                    label: translate("action.connector.rest.key"),
                }}
                userAccessLevel={userAccessLevel}
                value={param.key}
            />
            <VariablePicker
                onBlur={handleBlur("value")}
                KortexTextFieldProps={{
                    className: classes.valueTextField,
                    label: translate("action.connector.rest.value"),
                }}
                userAccessLevel={userAccessLevel}
                value={param.value}
            />
            <IconButton className={classes.iconButton} disabled={readOnly} id="connector-rest-param-delete-icon" onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
        </div>
    );
}
