import { greyPalette, KortexTextField, KortexTimeField } from "@aos/react-components";
import { ProcessEditorRightsEnum } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Cancel from "@material-ui/icons/Cancel";
import * as React from "react";

import { useForeground } from "../../../../../hooks/useForeground";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesTreeProcess } from "../../../../../redux/effects";
import { processUpdate, processUpdateStandardTime } from "../../../../../redux/process-manager/process-thunks-process";
import { useSelectorEditedProcessId, useSelectorProcesses } from "../../../../../redux/selectors";
import { treeProcessUpdate } from "../../../../../redux/tree-manager/tree-thunks-process";
import { IUserRightsProps, userCanWrite } from "../../../../../utilitites/IUserRights";

const useStyles = makeStyles({
    buttonIcons: {
        marginRight: "10px",
    },
    dialogActions: {
        backgroundColor: greyPalette[200],
        margin: "0px",
        padding: "8px 4px",
    },
    dialogButtons: {
        margin: "5px",
    },
    dialogHeader: {
        alignItems: "center",
        display: "inline-flex",
        width: "100%",
    },
    dialogContent: {
        display: "flex",
        justifyContent: "space-between",
    },
    field: {
        flex: 1,
    },
    timeField: {
        flex: 1,
        margin: "5px",
    },
    spacer: {
        flex: 1,
        margin: "5px",
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    onClose: () => void;
    open: boolean;
    userAccessLevel?: ProcessEditorRightsEnum;
    readOnly: boolean;
}

export default function ProcessInfo(props: IOwnProps): JSX.Element {
    const { open } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const dispatch = useThunkDispatch();
    const editedProcessId = useSelectorEditedProcessId();
    const processes = useSelectorProcesses();
    const treeProcess = useEntitiesTreeProcess();

    const editedProcess = processes.find((process) => process.processId === editedProcessId);
    const editedTreeNode = treeProcess.find((treeNode) => treeNode.treeNodeId === editedProcess?.treeNodeId);

    const readOnly = !props.readOnly ? !userCanWrite(props.userAccessLevel) : props.readOnly;

    useForeground(open);

    /**
     * Calls onClose when the Cancel button is clicked
     */
    const handleDialogClose = (): void => {
        props.onClose();
    };

    /**
     * Update tree node
     */
    const handleProcessNameChange = (value: string): void => {
        if (editedTreeNode) {
            editedTreeNode.label = value;
            dispatch(treeProcessUpdate([editedTreeNode]));
        }
    };

    /**
     * Update proces
     */
    const handleUpdateProcess =
        (key: "productReference" | "bomId" | "bomRev"): ((value: string) => void) =>
        (value: string): void => {
            if (editedProcess) {
                editedProcess[key] = value.trim();
                dispatch(processUpdate(editedProcess));
            }
        };

    /**
     * Calls when Standard time is changed
     */
    const handleStandardTimeChange = (seconds: number): void => {
        if (editedProcess) {
            editedProcess.standardTime = seconds;
            dispatch(processUpdateStandardTime(editedProcess));
        }
    };

    return (
        <Dialog fullWidth={true} id="processInfoId" maxWidth="md" open={open}>
            <DialogTitle>{translate("processInfo.processInfo")}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <KortexTextField
                    className={classes.field}
                    InputProps={{
                        id: "processInfoProcessId",
                    }}
                    TextFieldProps={{
                        disabled: true,
                    }}
                    label={translate("processInfo.processId")}
                    value={editedProcess?.processId ?? ""}
                    variant="standard"
                />
                <KortexTextField
                    className={classes.field}
                    InputProps={{
                        id: "processInfoProcessNameId",
                    }}
                    TextFieldProps={{
                        disabled: readOnly,
                    }}
                    label={translate("processInfo.processName")}
                    onChanged={handleProcessNameChange}
                    value={editedTreeNode?.label ?? ""}
                    variant="standard"
                />
                <KortexTextField
                    className={classes.field}
                    InputProps={{
                        id: "processInfoProductReferenceId",
                    }}
                    TextFieldProps={{
                        disabled: readOnly,
                        placeholder: translate("processInfo.example"),
                    }}
                    label={translate("processInfo.productReference")}
                    onChanged={handleUpdateProcess("productReference")}
                    value={editedProcess?.productReference ?? ""}
                    variant="standard"
                />
                <KortexTimeField
                    classes={{ root: classes.timeField }}
                    format="hms"
                    id="processInfoStandardTimeId"
                    onChange={handleStandardTimeChange}
                    seconds={editedProcess?.standardTime ?? 0}
                    textLabels={{
                        inputLabel: translate("processInfo.standardTime"),
                    }}
                    variant="standard"
                />
            </DialogContent>
            <DialogTitle>{translate("processInfo.bom")}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <KortexTextField
                    className={classes.field}
                    InputProps={{
                        id: "processInfoBomId",
                    }}
                    TextFieldProps={{
                        disabled: true,
                    }}
                    label={translate("processInfo.bomId")}
                    // onChanged={handleUpdateProcess("bomId")} TODO: on réactive ?????
                    value={editedProcess?.bomId ?? ""}
                    variant="standard"
                />
                <KortexTextField
                    className={classes.field}
                    InputProps={{
                        id: "processInfoBomRev",
                    }}
                    TextFieldProps={{
                        disabled: true,
                    }}
                    label={translate("processInfo.bomRevision")}
                    // onChanged={handleUpdateProcess("bomRev")} TODO: on réactive ?????
                    value={editedProcess?.bomRev ?? ""}
                    variant="standard"
                />
                <div className={classes.spacer} />
                <div className={classes.spacer} />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    className={classes.dialogButtons}
                    color="default"
                    id="processInfoCancelButtonId"
                    onClick={handleDialogClose}
                    variant="contained"
                >
                    <Cancel className={classes.buttonIcons} />
                    {translate("processInfo.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
