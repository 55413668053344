import { Kinova, ServiceManagerClient, WSClient } from "@kortex/aos-api-client";
import { BomFollowUpEditSerializedItemReq, BomFollowUpEditSerializedItemRes, getRPCHubInfo } from "@kortex/aos-common";

import { objectFromUTF8Array, utf8ArrayFromObject, utf8ArrayToKortexApiErrorObject } from "../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const bomFollowUpEditSerializedItem = (router: WSClient["router"]) =>
    ServiceManagerClient.getRR<BomFollowUpEditSerializedItemReq, BomFollowUpEditSerializedItemRes, Kinova.Api.IError>(
        getRPCHubInfo("bomFollowUp")("editSerializedItem"),
        {
            router,
            serializer: {
                decode: objectFromUTF8Array,
                encode: utf8ArrayFromObject,
                err: {
                    decode: utf8ArrayToKortexApiErrorObject,
                },
            },
        }
    );
