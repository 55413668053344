export const job = {
    rpc: {
        getOne: {
            funcId: 3000,
            funcName: "jobOneWithJobProcess",
        },
        getAll: {
            funcId: 3001,
            funcName: "jobAllWithJobProcess",
        },
        getJobProcess: {
            funcId: 3002,
            funcName: "jobProcess",
        },
        insert: {
            funcId: 3003,
            funcName: "jobInsert",
        },
        insertJobProcess: {
            funcId: 3004,
            funcName: "jobInsertJobProcess",
        },
        insertJobRouting: {
            funcId: 3005,
            funcName: "jobInsertJobProcess",
        },
        update: {
            funcId: 3006,
            funcName: "jobUpdate",
        },
        updateJobProcess: {
            funcId: 3007,
            funcName: "jobUpdateJobProcess",
        },
        deleteJobProcess: {
            funcId: 3008,
            funcName: "jobDeleteJobProcess",
        },
        deleteJobRouting: {
            funcId: 3009,
            funcName: "jobDeleteJobProcess",
        },
        progress: {
            funcId: 3010,
            funcName: "jobProgress",
        },
        inserted: {
            funcId: 3012,
            funcName: "jobInserted",
        },
        updated: {
            funcId: 3013,
            funcName: "jobUpdated",
        },
        updateStatus: {
            funcId: 3014,
            funcName: "jobUpdateStatus",
        },
        updateJobProcessStatus: {
            funcId: 3015,
            funcName: "jobUpdateJobProcessStatus",
        },
        getAllProcess: {
            funcId: 3018,
            funcName: "jobAllProcess",
        },
        archiveAllCompletedJobs: {
            funcId: 3019,
            funcName: "archiveAllCompletedJobs",
        },
        archivedAllCompletedJobs: {
            funcId: 3020,
            funcName: "archivedAllCompletedJobs",
        },
        getFromErpAndCreateJobs: {
            funcId: 3021,
            funcName: "jobGetFromErpAndCreateJobs",
        },
    },
    serviceId: 30,
    serviceVersion: 1,
};
