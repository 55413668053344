// **WARNING: DO NOT ALTER EXISTING ENUM VALUES***
export var EnumDBResultType;
(function (EnumDBResultType) {
    EnumDBResultType[EnumDBResultType["RESULT_MASTER"] = 0] = "RESULT_MASTER";
    EnumDBResultType[EnumDBResultType["RESULT_PROCESS"] = 1] = "RESULT_PROCESS";
    EnumDBResultType[EnumDBResultType["RESULT_PROCESS_ACTION"] = 2] = "RESULT_PROCESS_ACTION";
    EnumDBResultType[EnumDBResultType["RESULT_PROCESS_ISSUE"] = 3] = "RESULT_PROCESS_ISSUE";
    EnumDBResultType[EnumDBResultType["RESULT_KEY_VALUE"] = 4] = "RESULT_KEY_VALUE";
    EnumDBResultType[EnumDBResultType["RESULT_TABLE_VALUE"] = 5] = "RESULT_TABLE_VALUE";
    EnumDBResultType[EnumDBResultType["RESULT_FAIL"] = 6] = "RESULT_FAIL";
    EnumDBResultType[EnumDBResultType["RESULT_PROCESS_HISTORY"] = 7] = "RESULT_PROCESS_HISTORY";
    EnumDBResultType[EnumDBResultType["RESULT_TRAINING"] = 8] = "RESULT_TRAINING";
})(EnumDBResultType || (EnumDBResultType = {}));
