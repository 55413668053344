import { getTimeStr, SEC_TO_MS, TimeState, TimeType, TimeConversion } from "@kortex/aos-common";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    outputContainer: {
        height: "-webkit-fill-available",
        padding: "32px",
        width: "-webkit-fill-available",
    },
    outputText: {
        fontSize: "10rem",
        marginBottom: "32px",
        textAlign: "center",
    },
    outputTitle: {
        fontSize: "5rem",
        marginBottom: "32px",
        textAlign: "center",
    },
});

export interface IOwnProps {
    actionState: TimeState;
}

export default function TimePlayer(props: IOwnProps): JSX.Element {
    const { actionState } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const outputFontSize = { fontSize: actionState.type === TimeType.WAIT ? "10rem" : "3rem" };
    const titleFontSize = { fontSize: actionState.type === TimeType.WAIT ? "5rem" : "2rem" };

    /**
     * Gets the proper title based on selected time type
     */
    const getTitle = (): string => {
        switch (actionState.type) {
            case TimeType.CONVERSION:
                return translate("action.time.conversion");
            case TimeType.COUNTDOWN:
                return translate("action.time.countdownCreated");
            case TimeType.TIMER:
                return translate("action.time.timerCreated");
            case TimeType.WAIT:
                return translate("action.time.wait");
            default:
                return "";
        }
    };

    /**
     * Gets the proper value based on selected time type and time function
     */
    const getValue = (): string | number => {
        if (actionState.type === TimeType.CONVERSION) {
            if (actionState.function === TimeConversion.CONVERT_TIMESTAMP_TO_DATE) {
                return actionState.date;
            } else {
                return actionState.time;
            }
        } else {
            return getTimeStr(actionState.time * SEC_TO_MS);
        }
    };

    return (
        <div className={classes.outputContainer}>
            <Typography className={classes.outputTitle} style={titleFontSize} variant="h2">
                {getTitle()}
            </Typography>
            {(actionState.type === TimeType.CONVERSION || actionState.type === TimeType.WAIT) && (
                <Typography className={classes.outputText} style={outputFontSize} variant="h3">
                    {getValue()}
                </Typography>
            )}
        </div>
    );
}
