import { IWoBomItem } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { useWoBomTableContext } from "../../context";
import { isBomItemTraceable } from "../../utils";

import { WoBomTableItemRow } from "./row";
import { sortWoBomTableItemFollowUpRows } from "./utils";

const useStyles = makeStyles({
    root: {}, // To overwrite with props
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    index: number;
    item: IWoBomItem;
}

const WoBomTableItem: FC<IOwnProps> = (props) => {
    const { classes, index, item } = props;

    const { workInstructionsItems = {} } = useWoBomTableContext();
    const translate = useTranslate();

    const isItemTraceable = isBomItemTraceable(item);

    const getWarningMessage = (...indexes: number[]): string => {
        const wiItems = workInstructionsItems[item.partNumber] ?? [];
        const itemQuantityAddedInSteps = wiItems.map((item) => item.quantity).reduce((acc, value) => acc + value, 0);

        if (
            Math.max(...indexes) === 0 && // Warning will not be displayed for non-traceable items
            itemQuantityAddedInSteps > item.quantity
        ) {
            return `${translate("bomPage.bomTable.warningItemQuantityExceeded")}\n${translate(
                "bomPage.bomTable.warningItemQuantityExpected"
            )} ${item.quantity}\n${translate("bomPage.bomTable.warningItemQuantityReceived")} ${itemQuantityAddedInSteps}`;
        }

        return "";
    };

    /**
     * Render a row for each follow-up
     */
    const renderRows = (): JSX.Element[] => {
        const wiItems = workInstructionsItems[item.partNumber] ?? [];
        const elements: JSX.Element[] = [];
        let quantityRemaining = item.quantity;

        //-------------------------------------------------------------------------------
        // Items assigned to a work instructions step
        //-------------------------------------------------------------------------------

        // Add rows for each step in which the item was included
        for (const [workInstructionsItemIndex, workInstructionsItem] of wiItems.entries()) {
            let workInstructionsItemQuantityRemaining = workInstructionsItem.quantity;

            // If item is not traceable, add base row (no follow-up) before follow-up rows
            if (!isItemTraceable) {
                elements.push(
                    <WoBomTableItemRow
                        classes={classes}
                        id={workInstructionsItemIndex}
                        index={elements.length}
                        item={item}
                        key={`woBomTableItemRowWorkInstructionsItemWithoutFollowUp${index}-${workInstructionsItemIndex}`}
                        warning={getWarningMessage(workInstructionsItemIndex)}
                        workInstructionsItem={workInstructionsItem}
                    />
                );

                quantityRemaining -= workInstructionsItemQuantityRemaining;
            }

            // Follow-up rows
            for (const [followUpIndex, followUp] of sortWoBomTableItemFollowUpRows(
                item.followUp[workInstructionsItem.processActionStep.processActionStepId] ?? []
            ).entries()) {
                elements.push(
                    <WoBomTableItemRow
                        classes={classes}
                        followUp={followUp}
                        id={followUpIndex}
                        index={elements.length}
                        item={item}
                        key={`woBomTableItemRowWorkInstructionsItemWithFollowUp${index}-${workInstructionsItemIndex}-${followUpIndex}`}
                        warning={getWarningMessage(workInstructionsItemIndex, followUpIndex)}
                        workInstructionsItem={workInstructionsItem}
                    />
                );

                if (!followUp.isOverconsumption) {
                    const diff =
                        followUp.quantity === 0
                            ? 1 // To take into account items with a quantity of 0
                            : followUp.quantity;

                    workInstructionsItemQuantityRemaining -= diff;
                    quantityRemaining -= diff;
                }
            }

            // Add rows with empty traceability if the item is traceable
            if (isItemTraceable && workInstructionsItemQuantityRemaining > 0) {
                elements.push(
                    <WoBomTableItemRow
                        classes={classes}
                        id={workInstructionsItemIndex}
                        index={elements.length}
                        item={item}
                        key={`woBomTableItemRowWorkInstructionsItemWithoutFollowUp${index}-${workInstructionsItemIndex}`}
                        quantity={workInstructionsItemQuantityRemaining}
                        workInstructionsItem={workInstructionsItem}
                    />
                );

                quantityRemaining -= workInstructionsItemQuantityRemaining;
            }
        }

        //-------------------------------------------------------------------------------
        // Items not assigned to a work instructions step
        //-------------------------------------------------------------------------------

        // Add non-traceable row as the first row
        if (!isItemTraceable && (quantityRemaining > 0 || item.quantity === 0)) {
            elements.push(
                <WoBomTableItemRow
                    classes={classes}
                    id={index}
                    index={elements.length}
                    item={item}
                    key={`woBomTableItemRowWorkInstructionsItemEmpty${index}`}
                    quantity={quantityRemaining}
                />
            );
        }

        // Add rows for each follow-up manually added in the BOM page (index 0)
        for (const [followUpIndex, followUp] of sortWoBomTableItemFollowUpRows(item.followUp[0] ?? []).entries()) {
            elements.push(
                <WoBomTableItemRow
                    classes={classes}
                    followUp={followUp}
                    id={followUpIndex}
                    index={elements.length}
                    item={item}
                    key={`woBomTableItemRowWorkInstructionsItemWithFollowUp${index}-${elements.length}-${followUpIndex}`}
                    warning={getWarningMessage(elements.length, followUpIndex)}
                />
            );

            if (!followUp.isOverconsumption) {
                quantityRemaining -=
                    followUp.quantity === 0
                        ? 1 // To take into account items with a quantity of 0
                        : followUp.quantity;
            }
        }

        // Add remaining rows with empty traceability
        if (isItemTraceable) {
            if (quantityRemaining > 0 || (item.quantity === 0 && quantityRemaining === 0)) {
                elements.push(
                    <WoBomTableItemRow
                        classes={classes}
                        id={index}
                        index={elements.length}
                        item={item}
                        key={`woBomTableItemRowWorkInstructionsItemEmpty${index}`}
                        quantity={quantityRemaining}
                    />
                );
            }
        }

        return elements;
    };

    return <>{renderRows()}</>;
};

export default WoBomTableItem;
