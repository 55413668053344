import { IBomItem } from "@kortex/aos-common";
import React, { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useState } from "react";

interface IWorkInstructionsBomContext {
    searchValue: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
    itemFilterCb: (item: IBomItem) => boolean;
}

const WorkInstructionsBomContext = createContext<IWorkInstructionsBomContext>({
    searchValue: "",
    setSearchValue: () => void 0,
    itemFilterCb: (): boolean => true,
});

type WorkInstructionsBomProviderProps = PropsWithChildren<{}>;

export const WorkInstructionsBomProvider: FC<WorkInstructionsBomProviderProps> = (props) => {
    const { children } = props;
    const [searchValue, setSearchValue] = useState("");

    const itemFilterCb = (item: IBomItem): boolean => {
        return (
            !searchValue ||
            item.partNumber.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            item.description.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        );
    };

    return (
        <WorkInstructionsBomContext.Provider
            value={{
                searchValue,
                setSearchValue,
                itemFilterCb,
            }}
        >
            {children}
        </WorkInstructionsBomContext.Provider>
    );
};

export const useWorkInstructionBomContext = (): IWorkInstructionsBomContext =>
    useContext<IWorkInstructionsBomContext>(WorkInstructionsBomContext);
