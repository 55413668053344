import { ProcessEditorRightsEnum } from "@kortex/aos-common";
import { theme } from "@aos/react-components";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import * as React from "react";

import VariablePicker from "../../../../../../../core/VariablePicker/VariablePicker";
import { IUserRightsProps, userCanWrite } from "../../../../../../../../utilitites/IUserRights";
import { useTranslate } from "../../../../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    iconButton: {
        marginRight: "16px",
        marginTop: "16px",
    },
    root: {
        alignItems: "center",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid rgba(0,0,0,0.12)",
        display: "grid",
        gridTemplateColumns: "1fr auto",
        marginBottom: "5px",
        paddingBottom: "16px",
        paddingLeft: "16px",
    },
    shellCommandTextField: {
        marginTop: "16px",
        marginRight: "16px",
        flex: 1,
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    command: string;
    index: number;
    isLast: boolean;
    onBlur: (value: string) => void;
    onDelete: () => void;
}

export default function ConnectorShellCommand(props: IOwnProps): JSX.Element {
    const { command, index, isLast, onBlur, onDelete, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const disabled = !userCanWrite(userAccessLevel) || isLast;

    /**
     * Called when a param input is blurred
     *
     * @param {string} value - updated value
     */
    const handleBlur = (value: string): void => {
        if (command !== value) {
            onBlur(value);
        }
    };

    return (
        <div className={classes.root}>
            <VariablePicker
                onBlur={handleBlur}
                KortexTextFieldProps={{
                    className: classes.shellCommandTextField,
                    label: translate("action.connector.shell.command") + " #" + (index + 1),
                }}
                userAccessLevel={userAccessLevel}
                value={command}
            />
            <IconButton className={classes.iconButton} disabled={disabled} id="connector-shell-command-delete-icon" onClick={onDelete}>
                <DeleteIcon />
            </IconButton>
        </div>
    );
}
