import { IProcessDbModel, ApprovalStatusEnum } from "@kortex/aos-common";

export enum ProcessStatus {
    UNKNOWN = "UNKN",
    DRAFT = "DRAF",
    WAITING_APPROVAL = "WAPR",
    REJECTED = "REJT",
    APPROVED = "APRV",
    RELEASED = "RLSD",
    RETIRED = "RETD",
    ARCHIVED = "ACHV",
    HOLD = "HOLD",
    CANCELLED = "CNCD",
}

/**
 * Return Process Status
 *
 * @param {IProcessDbModel} process - the process object
 */
export const getProcessStatus = (process: IProcessDbModel): ProcessStatus => {
    const now = new Date().getTime();
    if (!process) {
        return ProcessStatus.UNKNOWN;
    }

    if (process.isDraft) {
        return ProcessStatus.DRAFT;
    }

    switch (process.versionStatus) {
        case ApprovalStatusEnum.APPROVED:
            if ((!process.releasedOn && !process.retiredOn) || (process.releasedOn && process.releasedOn > now && !process.retiredOn)) {
                return ProcessStatus.APPROVED;
            } else if (process.releasedOn && process.releasedOn <= now && !(process.retiredOn && process.retiredOn < now)) {
                return ProcessStatus.RELEASED;
            } else {
                return ProcessStatus.RETIRED;
            }
        case ApprovalStatusEnum.REJECTED:
            return ProcessStatus.REJECTED;
        case ApprovalStatusEnum.AWAITING:
            return ProcessStatus.WAITING_APPROVAL;
        case ApprovalStatusEnum.HOLD:
            return ProcessStatus.HOLD;
        case ApprovalStatusEnum.CANCELLED:
            return ProcessStatus.CANCELLED;
        default:
            return ProcessStatus.WAITING_APPROVAL;
    }
};
