import { ProcessActionStepMath, ProcessVariableStorer, ProcessVariableStoringMethod } from "@kortex/aos-common";
import { Paper, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import VariablePicker from "../../../../../core/VariablePicker/VariablePicker";
import { IActionStepProps } from "../IActionStepProps";

const useStyles = makeStyles({
    root: {
        height: "100%",
        padding: "16px",
        boxSizing: "border-box",
    },
    content: {
        display: "flex",
        gap: "10px",
    },
    mathEquationField: {
        flex: 0.8,
        margin: "5px",
    },
    mathStoreToField: {
        flex: 0.2,
        margin: "5px",
    },
});

export default function ActMathStepEditor(props: IActionStepProps<ProcessActionStepMath>): JSX.Element {
    const { actionStepProps, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [storeTo, setStoreTo] = useState<ProcessVariableStorer>(actionStepProps.config.storeTo);
    const [equation, setEquation] = useState<string>(actionStepProps.config.equation);

    /**
     * Updates "Equation" inner state on props change
     */
    useEffect((): void => {
        setEquation(actionStepProps.config.equation);
    }, [actionStepProps.config.equation]);

    /**
     * Updates "StoreTo" inner state on props change
     */
    useEffect((): void => {
        setStoreTo(actionStepProps.config.storeTo);
    }, [actionStepProps.config.storeTo]);

    /**
     * Called when equation textfield is blurred
     *
     * @param {string} updatedEquation - textfield value
     */
    const handleEquationBlur = (updatedEquation: string): void => {
        if (updatedEquation !== actionStepProps.config.equation) {
            props.onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    equation: updatedEquation,
                },
            });
        }
    };

    /**
     * Called when storeTo textfield is changed
     *
     * @param {string} identifier - textfield value
     * @param {string} storingMethod - overwrite or append
     */
    const handleStoreToChange = (identifier: string, storingMethod: ProcessVariableStoringMethod): void => {
        if (storingMethod !== storeTo.storingMethod || identifier !== storeTo.identifier) {
            props.onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    storeTo: new ProcessVariableStorer(identifier, storingMethod),
                },
            });
        }
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.content}>
                <VariablePicker
                    onBlur={handleEquationBlur}
                    KortexTextFieldProps={{
                        label: translate("action.math.equation"),
                        TextFieldProps: { id: "mathEquationId", multiline: true, className: classes.mathEquationField },
                    }}
                    userAccessLevel={userAccessLevel}
                    value={equation}
                />
                <VariablePicker
                    onChange={handleStoreToChange}
                    storingMethod={storeTo.storingMethod}
                    KortexTextFieldProps={{
                        label: translate("action.math.storeTo"),
                        TextFieldProps: { id: "mathStoreToId", className: classes.mathStoreToField },
                    }}
                    userAccessLevel={userAccessLevel}
                    value={storeTo.identifier}
                />
            </div>
        </Paper>
    );
}
