import { upsertObjectFromArray } from "@kortex/aos-common";

import { SchedulerState, SchedulerActions, SchedulerActionType } from "./scheduler-types";

const initialState: SchedulerState = {
    jobs: [],
};

export function schedulerReducer(state: SchedulerState = initialState, action: SchedulerActions): SchedulerState {
    switch (action.type) {
        case SchedulerActionType.CLEAR_SCHEDULER:
            return {
                ...initialState,
            };
        case SchedulerActionType.UPDATE_JOBS:
            return {
                ...state,
                jobs: [...action.value],
            };
        case SchedulerActionType.INSERT_JOB:
            return {
                ...state,
                jobs: upsertObjectFromArray(state.jobs, action.value, function (job) {
                    return job.jobId === this.jobId;
                }),
            };
        case SchedulerActionType.UPDATE_JOB:
            if (!action.value.length) {
                return state;
            }
            return {
                ...state,
                jobs: upsertObjectFromArray(state.jobs, action.value, function (job) {
                    return job.jobId === this.jobId;
                }),
            };
        case SchedulerActionType.ARCHIVE_COMPLETED_JOBS:
            return {
                ...state,
                // Find and replace jobs that match those already present in state
                // Do not add the rest
                jobs: state.jobs.map((job) => {
                    for (const archivedJob of action.value) {
                        if (archivedJob.jobId === job.jobId) {
                            return archivedJob;
                        }
                    }

                    return job;
                }),
            };

        default:
            return state;
    }
}
