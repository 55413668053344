import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import { AOSClientServiceRequestWrapped, ErpSettingsGetReq, ErpSettingsGetRes } from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { erpSettingsGet } from "./getErpSettings";

interface IAOSClientServiceWrappedErp {
    get: AOSClientServiceRequestWrapped<ErpSettingsGetReq, ErpSettingsGetRes>;
}

type AOSClientServiceWrappedErp = IAOSClientServiceWrappedErp;

// ADD_API_CALL Electronic Signature
export function generateErpSettingsServiceClient(router: IRouterClient, _: EventEmitter): AOSClientServiceWrappedErp {
    return {
        get: AOSClientServiceWrapperUI.request(erpSettingsGet(router)),
    };
}

generateErpSettingsServiceClient.serviceId = 160;
