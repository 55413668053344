import { KortexTextField, UnknownObject } from "@aos/react-components";
import {
    IParserConfig,
    PARSER_TYPE,
    ParserJson,
    ParserRegexExtract,
    ProcessActionStepParser,
    ProcessVariableStorer,
    ProcessVariableStoringMethod,
    TParserExtendedConfig,
    TParserJson,
    TParserRegex,
    TParserType,
} from "@kortex/aos-common";
import { ListItem, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import VariablePicker from "../../../../../core/VariablePicker/VariablePicker";
import { IActionStepProps } from "../IActionStepProps";

import ParserJsonEditor from "./Json/ParserJsonEditor";
import ParserRegexEditor from "./Regex/ParserRegexEditor";

const useStyles = makeStyles({
    line: {
        display: "flex",
        marginBottom: "16px",
    },
    lineTextField: {
        flex: 1,
    },
    regexTypeSelect: {
        width: "140px",
    },
    root: {
        display: "flex",
        flexDirection: "column",
        padding: "16px",

        height: "calc(100vh - 139px)", // Header(75px), parser editor margins(32px), action editor margins (32px)
        minWidth: "160px",
        overflowX: "hidden",
        overflowY: "auto",
    },
    textField: {
        marginBottom: "16px",
    },
});

export default function ParserEditor(props: IActionStepProps<ProcessActionStepParser>): JSX.Element {
    const { actionStepProps, onChanged, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Get parser editor based on type
     */
    const getParser = (): JSX.Element => {
        switch (actionStepProps.config.type) {
            case PARSER_TYPE.JSON:
                return (
                    <ParserJsonEditor
                        onChanged={handleParserValueChange}
                        jsonProps={actionStepProps.config.parser as TParserJson}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case PARSER_TYPE.REGEX:
                return (
                    <ParserRegexEditor
                        onChanged={handleParserValueChange}
                        regexProps={actionStepProps.config.parser as TParserRegex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            default:
                return <div />;
        }
    };

    /**
     * Handles parser type change
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - event data
     */
    const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                parser: event.target.value === PARSER_TYPE.JSON ? new ParserJson() : new ParserRegexExtract(),
                type: event.target.value as TParserType,
            },
        });
    };

    /**
     * Handles storeTo textfield value change
     *
     * @param {string} identifier - textfield value
     * @param {ProcessVariableStoringMethod} storingMethod - overwrite or append
     */
    const handleStoreToChange = (identifier: string, storingMethod: ProcessVariableStoringMethod): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                storeTo: new ProcessVariableStorer(identifier, storingMethod),
            },
        });
    };

    /**
     * Handles parser changes
     *
     * @param {TParserExtendedConfig} parser - parser type
     */
    // eslint-disable-next-line
    const handleParserValueChange = (parser: TParserExtendedConfig): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                parser,
            },
        });
    };

    /**
     * Handles textfield value changes
     *
     * @param {keyof IParserConfig<TParserType, TParserExtendedConfig, string | UnknownObject>} key - modified property
     */
    const handleValueChange =
        (key: keyof IParserConfig<TParserType, TParserExtendedConfig, string | UnknownObject>): ((value: string) => void) =>
        (value: string): void => {
            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    [key]: value,
                },
            });
        };

    return (
        <Paper className={classes.root}>
            <div className={classes.line}>
                {/* PARSER TYPE */}
                <KortexTextField
                    className={classes.regexTypeSelect}
                    label={translate("action.parser.type")}
                    onChange={handleTypeChange}
                    TextFieldProps={{
                        id: "parserEditorTypeId",
                        select: true,
                        disabled: readOnly,
                    }}
                    value={actionStepProps.config.type}
                >
                    <ListItem value={PARSER_TYPE.JSON}>{translate("action.parser.json")}</ListItem>
                    <ListItem value={PARSER_TYPE.REGEX}>{translate("action.parser.regex")}</ListItem>
                </KortexTextField>

                {/* STORE TO */}
                <VariablePicker
                    KortexTextFieldProps={{
                        className: classes.lineTextField,
                        label: translate("action.parser.storeTo"),
                        TextFieldProps: {
                            id: "parserEditorStoreToId",
                        },
                    }}
                    onChange={handleStoreToChange}
                    storingMethod={actionStepProps.config.storeTo.storingMethod}
                    userAccessLevel={userAccessLevel}
                    value={actionStepProps.config.storeTo.identifier}
                />
            </div>

            {/* SOURCE STRING */}
            <VariablePicker
                KortexTextFieldProps={{
                    className: classes.textField,
                    label: translate("action.parser.source"),
                    TextFieldProps: {
                        id: "parserEditorSourceId",
                        multiline: true,
                    },
                }}
                onChange={handleValueChange("source")}
                userAccessLevel={userAccessLevel}
                value={actionStepProps.config.source as string} // Should always be a string when editing
            />

            {/* PARSER */}
            {getParser()}
        </Paper>
    );
}
