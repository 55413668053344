export class WorkInstructionsState {
    constructor() {
        this.trainingCommuniqueRequired = false;
        this.trainingCommuniqueViewed = null;
        this.workInstructionElementsState = [];
    }
    toJSON() {
        return {
            trainingCommuniqueRequired: this.trainingCommuniqueRequired,
            trainingCommuniqueViewed: this.trainingCommuniqueViewed,
            workInstructionElementsState: this.workInstructionElementsState,
        };
    }
}
