import { ActionTypeEnum, ClientUpdateTypeEnum, FailureCodeEnum, FailureSourceEnum, KhronoEventEnum, KhronoHookSourceEnum, KhronoSnapshotCallbackSourceEnum, RemoteEventEnum, RemoteEventSource, SequencerActionStatusEnum, SequencerStatusEnum, SequencerActionGroupPlayCompletedPayloadTypeEnum, SequencerActionGroupStatusEnum, SequencerActionPlayCompletedPayloadTypeEnum, } from "../enums";
import { ActionGroupHookOnFailCircularError, ActionHookOnFailCircularError, ACTION_GROUP_CONTEXT_SEPARATOR, CoreActionConfigError, CoreActionFlowError, HookOnFailCircularError, InterpolationError, InvalidCoreActionTypeError, RemoteActionExternalError, RemoteActionMethodTimeoutError, SequencerConfigError, SequencerError, SequencerHookOnFailCircularError, UndefinedRemoteMethodError, VariableManagerError, } from "../interfaces";
import { Khrono } from "../khrono";
import { localeKeys } from "../locales";
import { SequencerActionGroup } from "../sequencer-action-group";
import { SubProgramManager } from "../sub-program-manager";
import { assignNewActionGroupIds, deepClone, initBranchesLastActionGroup, isActionGroupPaused, isNumber, isObject, isSequencerActionGroupConfigFail, isSequencerActionGroupConfigInput, isSequencerCompleted, isSequencerFailed, isSequencerInactive, isSequencerPlaying, isSequencerStopped, isSequencerStopping, KortexSequencerDebugger, timestamp, waitAfter, wrapVariableManager, } from "../utilities";
const debug = KortexSequencerDebugger.sequencer;
function create(params) {
    debug("new sequencer created:%o", params);
    if (!params.hooks.onFail) {
        debug("error:hook onFail not defined");
        throw new SequencerConfigError(localeKeys.sequencer.hookErrorOnFailUndefined, "Sequencer's 'onFail' hook is not assigned.");
    }
    const { delayAfterHookMs, hooks, khronoEnabled = false, pausable = true, variableManager, variableNamespace } = params;
    const _actionGroups = [], _delayAfterHookMs = delayAfterHookMs ?? 0, _subProgramManager = SubProgramManager.create(), _subPrograms = [], _wrappedVariableManager = wrapVariableManager(variableManager);
    let _playCountActionGroupFail = 0, _nextActionGroup, _state = {
        currentActionGroup: null,
        currentActionIndex: 0,
        failPathAvailable: false,
        waitingForCmdHookCompletion: false,
    }, _status = SequencerStatusEnum.IDLE;
    Khrono.init(khronoEnabled);
    Khrono.onSnapshot(KhronoSnapshotCallbackSourceEnum.VARIABLE_MANAGER, () => _wrappedVariableManager.getAllVars(variableNamespace));
    async function _complete() {
        debug("completed");
        if (_status !== SequencerStatusEnum.FAILED) {
            return _updateStatus({ status: SequencerStatusEnum.COMPLETED, payload: null });
        }
    }
    async function _fail(failure, sequencerFailed = true) {
        debug("fail:%o", failure);
        Khrono.createEvent({
            payload: failure,
            type: KhronoEventEnum.SEQUENCER_FAILURE,
        });
        return sequencerFailed
            ? _updateStatus({ status: SequencerStatusEnum.FAILED, payload: { failure, sequencerFailed } })
            : _runSequencerHook("onFail", { failure, sequencerFailed });
    }
    function _getFailAction() {
        debug("get Fail action");
        return _actionGroups.find((actionGroup) => isSequencerActionGroupConfigFail(actionGroup));
    }
    function _getInputAction() {
        debug("get Input action");
        return _actionGroups.find((actionGroup) => isSequencerActionGroupConfigInput(actionGroup));
    }
    async function _handleActionGroupCompleted(payload) {
        debug("action group completed:%o", payload);
        if (isSequencerStopping(_status)) {
            debug("%s:action group completed:sequencer is stopped");
            return _updateStatus({
                payload: null,
                status: SequencerStatusEnum.STOPPED,
            });
        }
        const { currentActionGroup } = _state;
        if (!currentActionGroup) {
            debug("action group completed:fail:current action group is not defined");
            return _fail({
                code: FailureCodeEnum.CRITICAL,
                key: localeKeys.sequencer.currentActionGroupNotDefined,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            });
        }
        {
            if (_isCompletedActionGroupBranching(payload)) {
                const actionGroupIdFromBranch = currentActionGroup.config.branches[payload.result];
                if (actionGroupIdFromBranch) {
                    const _nextActionGroup = _actionGroups.find((actionGroup) => actionGroup.config.actionGroupId === actionGroupIdFromBranch);
                    if (!isSequencerPlaying(getStatus())) {
                        debug("%s:action completed:not playing next action group because the sequencer is not playing:%s", getStatus());
                        return void 0;
                    }
                    if (_nextActionGroup) {
                        return _playActionGroup(_nextActionGroup);
                    }
                    else {
                        return _fail({
                            code: FailureCodeEnum.CONFIGURATION_ERROR,
                            key: localeKeys.sequencer.branchActionGroupNotFound,
                            sequencer: {
                                config: params,
                                state: _state,
                            },
                            source: FailureSourceEnum.SEQUENCER,
                        });
                    }
                }
            }
        }
        {
            if (!currentActionGroup.config.next) {
                if (_subPrograms.length) {
                    const lastSubProgramActionGroupId = _subPrograms.pop();
                    const lastSubProgramActionGroup = _actionGroups.find((actionGroup) => actionGroup.config.actionGroupId === lastSubProgramActionGroupId);
                    if (lastSubProgramActionGroup) {
                        debug("%s:action completed:last action of sub program:%d", lastSubProgramActionGroup.config.actionGroupId);
                        return _playActionGroup(lastSubProgramActionGroup);
                    }
                }
                return _complete();
            }
            _nextActionGroup = _actionGroups.find((actionGroup) => actionGroup.config.actionGroupId === currentActionGroup.config.next);
            if (!isSequencerPlaying(getStatus())) {
                debug("%s:action completed:not playing next action group because the sequencer is not playing:%s", getStatus());
                return void 0;
            }
            if (_nextActionGroup) {
                return _playActionGroup(_nextActionGroup);
            }
            return _fail({
                code: FailureCodeEnum.CONFIGURATION_ERROR,
                key: localeKeys.sequencer.nextActionGroupNotFound,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            });
        }
    }
    async function _handleActionGroupStateChange(state) {
        return _updateState({ currentActionIndex: state.actionIndex });
    }
    async function _handleActionGroupStatusChange(payload) {
        debug("action group status changed:%o", payload);
        switch (payload.status) {
            case SequencerActionGroupStatusEnum.COMPLETED:
                return _handleActionGroupCompleted(payload.payload);
            case SequencerActionGroupStatusEnum.FAILED:
                const actionFail = _getFailAction();
                if (actionFail &&
                    _playCountActionGroupFail === 0 &&
                    (_status === SequencerStatusEnum.PLAYING || _status === SequencerStatusEnum.STOPPING)) {
                    _status = SequencerStatusEnum.PLAYING;
                    _playCountActionGroupFail += 1;
                    return _runSequencerHook("onFailPath", {
                        failure: {
                            ...payload.payload,
                            sequencer: {
                                config: params,
                                state: _state,
                            },
                        },
                        sequencerFailed: false,
                    }).then(() => _playActionGroup(actionFail));
                }
                return _fail({
                    ...payload.payload,
                    sequencer: {
                        config: params,
                        state: _state,
                    },
                });
            case SequencerActionGroupStatusEnum.STOPPED:
                return _updateStatus({ status: SequencerStatusEnum.STOPPED, payload: null });
        }
    }
    function _initSystemVariables() {
        debug("Initiating system variables");
    }
    function _isCompletedActionGroupBranching(payload) {
        return payload.type === SequencerActionGroupPlayCompletedPayloadTypeEnum.BRANCH_INDEX;
    }
    async function _playActionGroup(actionGroup, resetState = true) {
        debug("play action group:setup:subscribe to action group state changed callback");
        actionGroup.onStateChange(_handleActionGroupStateChange);
        debug("play action group:setup:subscribe to action group status changed callback");
        actionGroup.onStatusChange(_handleActionGroupStatusChange);
        actionGroup.onPlaySubProgram((branches) => {
            debug("play action group:sub program:init branches:%O", branches);
            if (_state.currentActionGroup) {
                _state.currentActionGroup.config.branches = branches;
                _subPrograms.push(_state.currentActionGroup.config.actionGroupId);
            }
            else {
                _fail({
                    code: FailureCodeEnum.CRITICAL,
                    key: localeKeys.sequencer.currentActionGroupNotDefined,
                    sequencer: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.SEQUENCER,
                });
            }
        });
        if (isSequencerActionGroupConfigFail(actionGroup)) {
            await _updateState({ failPathAvailable: false });
        }
        await _updateState({
            currentActionGroup: actionGroup,
            currentActionIndex: resetState ? 0 : _state.currentActionIndex,
        });
        if (_status === SequencerStatusEnum.PLAYING) {
            debug("play next action group:play");
            return actionGroup.play(resetState);
        }
    }
    function _runSequencerHook(key, payload) {
        debug("run hook:%o", { key, payload });
        let initPromise = Promise.resolve();
        if (hooks[key]) {
            debug("run hook:%s is defined:executing hook...", key);
            initPromise = initPromise
                .then(() => waitAfter(Khrono.createHookEvent({
                hook: key,
                source: KhronoHookSourceEnum.SEQUENCER,
            }, hooks[key], undefined, payload), _delayAfterHookMs))
                .catch(async (error) => {
                if (key === "onFail") {
                    debug("run hook:error:%s not implemented, so throwing an error", key);
                    Khrono.setMainSequenceStat({ end: timestamp() });
                    throw new SequencerHookOnFailCircularError({
                        code: FailureCodeEnum.CRITICAL,
                        error,
                        key: localeKeys.sequencer.hookErrorOnFail,
                        sequencer: {
                            config: params,
                            state: _state,
                        },
                        source: FailureSourceEnum.SEQUENCER,
                    });
                }
                debug("run hook:fail:%s failed (error thrown or promised rejected)", key);
                return _fail({
                    code: FailureCodeEnum.IMPLEMENTER_HOOK_ERROR,
                    error,
                    key: localeKeys.sequencer.hookError,
                    sequencer: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.SEQUENCER,
                });
            });
        }
        return initPromise;
    }
    async function _updateHistory(history) {
        Khrono.setHistory(history);
        const orderedKhronoActions = history.actions.sort((a, b) => a.snapshotStart.timestamp.now - b.snapshotStart.timestamp.now);
        for (const khronoAction of orderedKhronoActions) {
            const actionGroup = _actionGroups.find((group) => group.config.actionGroupId === khronoAction.actionGroupId);
            if (!actionGroup || !actionGroup.actions[khronoAction.index]) {
                return _fail({
                    code: FailureCodeEnum.CONFIGURATION_ERROR,
                    key: localeKeys.sequencer.historyActionGroupNotFound,
                    sequencer: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.SEQUENCER,
                });
            }
            actionGroup.state.actionIndex = khronoAction.index;
            if (khronoAction.snapshotEnd) {
                actionGroup.actions[khronoAction.index].core.setState?.(khronoAction.snapshotEnd.state, undefined, false);
                actionGroup.actions[khronoAction.index].status = khronoAction.snapshotEnd.status;
                actionGroup.state.actionIndex = khronoAction.index + 1;
                actionGroup.status =
                    khronoAction.index === actionGroup.actions.length - 1
                        ? SequencerActionGroupStatusEnum.COMPLETED
                        : SequencerActionGroupStatusEnum.IDLE;
                await Promise.all(khronoAction.snapshotEnd.variables.map((variable) => _wrappedVariableManager.setVar(variableNamespace, variable)));
            }
        }
    }
    async function _updateState(payload) {
        debug("update state:%o", payload);
        let notifyStateChange = false;
        for (const key in payload) {
            if (_state[key] !== payload[key]) {
                notifyStateChange = true;
                break;
            }
        }
        _state = {
            ..._state,
            ...payload,
        };
        if (notifyStateChange) {
            debug("update state:state is different, updating...");
            return _runSequencerHook("onStateChange", _state);
        }
    }
    async function _updateStatus(payload, hook) {
        debug("status update:%o", payload);
        if (_status === payload.status) {
            return void 0;
        }
        _status = payload.status;
        debug("status update:status is different, updating...");
        let defaultHook = hook;
        if (!defaultHook) {
            switch (payload.status) {
                case SequencerStatusEnum.COMPLETED:
                    defaultHook = "onComplete";
                    break;
                case SequencerStatusEnum.FAILED:
                    defaultHook = "onFail";
                    break;
                case SequencerStatusEnum.PAUSED:
                    defaultHook = "onPause";
                    break;
                case SequencerStatusEnum.PLAYING:
                    defaultHook = "onPlay";
                    break;
                case SequencerStatusEnum.STOPPING:
                    defaultHook = "onStop";
                    break;
                case SequencerStatusEnum.STOPPED:
                    defaultHook = "onStopped";
                    break;
            }
        }
        return (defaultHook ? _runSequencerHook(defaultHook, payload?.payload) : Promise.resolve()).then(() => _runSequencerHook("onStatusChange", payload).then(async () => {
            if (isSequencerCompleted(payload.status) || isSequencerFailed(payload.status) || isSequencerStopped(payload.status)) {
                await _wrappedVariableManager.init();
                return Khrono.setMainSequenceStat({ end: timestamp() });
            }
        }));
    }
    function addActionGroup(actionGroup) {
        const copiedActionGroup = deepClone(actionGroup);
        if (!getActionGroup(copiedActionGroup.actionGroupConfig.actionGroupId)) {
            try {
                _actionGroups.push(SequencerActionGroup.create({
                    ...copiedActionGroup,
                    context: copiedActionGroup.actionGroupConfig.actionGroupId.toString(),
                    delayAfterHookMs: _delayAfterHookMs,
                    subProgramManager: _subProgramManager,
                    variableManager: _wrappedVariableManager,
                    variableNamespace: copiedActionGroup.variableNamespace ?? variableNamespace,
                }));
            }
            catch (error) {
                debug("add action group:fail:an error occurred while creating the action group:%o", error);
                _fail({
                    code: FailureCodeEnum.CONFIGURATION_ERROR,
                    error,
                    key: error.key,
                    sequencer: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.SEQUENCER,
                });
            }
        }
        else {
            debug("add action group:fail:action group is already added");
            _fail({
                code: FailureCodeEnum.CONFIGURATION_ERROR,
                key: localeKeys.sequencer.actionGroupAlreadyAdded,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            });
        }
    }
    async function addSubPrograms(subPrograms) {
        debug("add sub programs:%o", subPrograms);
        try {
            for (const subProgram of subPrograms) {
                const updatedActionGroups = assignNewActionGroupIds(await Promise.all(subProgram.actionGroups.map(async (actionGroup) => {
                    const copiedActionGroup = deepClone(actionGroup);
                    const newContext = subProgram.context + ACTION_GROUP_CONTEXT_SEPARATOR + actionGroup.actionGroupConfig.actionGroupId;
                    const newVariableNamespace = (copiedActionGroup.variableNamespace ?? variableNamespace) +
                        ACTION_GROUP_CONTEXT_SEPARATOR +
                        subProgram.context;
                    await Promise.all(subProgram.variables.map((variable) => _wrappedVariableManager.setVar(newVariableNamespace, variable)));
                    return SequencerActionGroup.create({
                        ...copiedActionGroup,
                        context: newContext,
                        delayAfterHookMs: _delayAfterHookMs,
                        subProgramManager: _subProgramManager,
                        variableManager: _wrappedVariableManager,
                        variableNamespace: newVariableNamespace,
                    });
                })));
                for (const actionGroup of updatedActionGroups) {
                    if (isSequencerActionGroupConfigInput(actionGroup)) {
                        _subProgramManager.setInput(subProgram.context, actionGroup.config.actionGroupId);
                        initBranchesLastActionGroup(actionGroup, null, updatedActionGroups);
                    }
                    _actionGroups.push(actionGroup);
                }
            }
        }
        catch (error) {
            debug("add sub programs:fail:o%", error);
            _fail({
                code: FailureCodeEnum.CONFIGURATION_ERROR,
                error,
                key: error.key,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            });
        }
    }
    async function clientUpdate(payload) {
        debug("client update");
        if (payload.type === ClientUpdateTypeEnum.RETRY_ACTION) {
            debug("client update:retry");
            if (isSequencerFailed(_status)) {
                await _updateStatus({ status: SequencerStatusEnum.PLAYING, payload: null }).then(async () => {
                    _state.waitingForCmdHookCompletion = false;
                    return _runSequencerHook("onCmdHookComplete", "play");
                });
            }
            if (_state.currentActionGroup) {
                return _state.currentActionGroup.clientUpdate(payload);
            }
            debug("client update:retry:fail:no current action group");
            return _fail({
                code: FailureCodeEnum.FLOW_ERROR,
                key: localeKeys.sequencer.flowError.clientUpdate.noCurrentActionGroup,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            }, false);
        }
        else if (!isSequencerInactive(_status)) {
            if (_state.currentActionGroup) {
                return _state.currentActionGroup.clientUpdate(payload);
            }
            else {
                debug("client update:fail:current action group is not defined");
                return _fail({
                    code: FailureCodeEnum.CRITICAL,
                    key: localeKeys.sequencer.currentActionGroupNotDefined,
                    sequencer: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.SEQUENCER,
                });
            }
        }
        else {
            debug("client update:fail:sequencer is idle");
            return _fail({
                code: FailureCodeEnum.FLOW_ERROR,
                key: localeKeys.sequencer.flowError.clientUpdate.notPlaying,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            }, false);
        }
    }
    function getActionGroup(actionId) {
        return _actionGroups.find((actionGroup) => actionGroup.config.actionGroupId === actionId);
    }
    function getCurrentActionGroup() {
        return _state.currentActionGroup
            ? {
                actionGroup: _state.currentActionGroup,
                actionIndex: _state.currentActionIndex,
            }
            : undefined;
    }
    function getStatus() {
        return _status;
    }
    async function getVariable(identifier) {
        debug("get variable:%s", identifier);
        try {
            return _wrappedVariableManager.getVar(variableNamespace, identifier)?.value;
        }
        catch (error) {
            debug("get variable:fail:could not get the variable:%o", error);
            return _fail({
                code: FailureCodeEnum.VARIABLE_MANAGER_ERROR,
                error,
                key: localeKeys.sequencer.getVariableError,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            }).then(() => undefined);
        }
    }
    async function setVariables(variables) {
        debug("set variable:%o", variables);
        try {
            for (const variable of variables) {
                await _wrappedVariableManager.setVar(variableNamespace, variable);
            }
        }
        catch (error) {
            debug("set variable:fail:could not set the variable:%o", error);
            return _fail({
                code: FailureCodeEnum.VARIABLE_MANAGER_ERROR,
                error,
                key: localeKeys.sequencer.setVariableError,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            });
        }
    }
    async function pause() {
        debug("pause");
        if (pausable) {
            if (isSequencerPlaying(_status)) {
                if (!_state.waitingForCmdHookCompletion) {
                    if (_state.currentActionGroup) {
                        if (_state.currentActionGroup.isPausable()) {
                            _state.waitingForCmdHookCompletion = true;
                        }
                        return _updateStatus({ status: SequencerStatusEnum.PAUSED, payload: null }).then(async () => {
                            _state.waitingForCmdHookCompletion = false;
                            return _runSequencerHook("onCmdHookComplete", "pause").then(() => _state.currentActionGroup
                                ? _state.currentActionGroup.pause()
                                : _fail({
                                    code: FailureCodeEnum.CRITICAL,
                                    key: localeKeys.sequencer.currentActionGroupNotDefined,
                                    sequencer: {
                                        config: params,
                                        state: _state,
                                    },
                                    source: FailureSourceEnum.SEQUENCER,
                                }, false));
                        });
                    }
                    else {
                        debug("pause:fail:current action group is undefined");
                        return _fail({
                            code: FailureCodeEnum.CONFIGURATION_ERROR,
                            key: localeKeys.sequencer.currentActionGroupNotDefined,
                            sequencer: {
                                config: params,
                                state: _state,
                            },
                            source: FailureSourceEnum.SEQUENCER,
                        }, false);
                    }
                }
                else {
                    debug("pause:fail:request is already in progress");
                    return _fail({
                        code: FailureCodeEnum.FLOW_ERROR,
                        key: localeKeys.sequencer.flowError.hook.alreadyInProgress,
                        sequencer: {
                            config: params,
                            state: _state,
                        },
                        source: FailureSourceEnum.SEQUENCER,
                    }, false);
                }
            }
            else {
                debug("pause:fail:sequencer is not playing");
                return _fail({
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.sequencer.flowError.pause.notPlaying,
                    sequencer: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.SEQUENCER,
                }, false);
            }
        }
        else {
            debug("pause:fail:sequencer is not pausable");
            return _fail({
                code: FailureCodeEnum.FLOW_ERROR,
                key: localeKeys.sequencer.flowError.pause.notPausable,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            }, false);
        }
    }
    async function play(param) {
        debug("play");
        const clonedParam = deepClone(param);
        if (_status === SequencerStatusEnum.IDLE) {
            if (!_state.waitingForCmdHookCompletion) {
                Khrono.setMainSequenceStat({ start: timestamp() });
                _initSystemVariables();
                Khrono.snapshotVariables();
                let firstActionToPlay = isNumber(clonedParam) ? getActionGroup(clonedParam) : _getInputAction();
                const inputAction = _getInputAction();
                if (isObject(clonedParam)) {
                    debug("Parameter is a history");
                    await _updateHistory(clonedParam);
                    const lastKhronoAction = clonedParam.actions.sort((a, b) => a.snapshotStart.timestamp.now - b.snapshotStart.timestamp.now)[clonedParam.actions.length - 1];
                    if (lastKhronoAction?.snapshotEnd?.next?.actionGroupId) {
                        firstActionToPlay = getActionGroup(lastKhronoAction.snapshotEnd.next.actionGroupId);
                        if (firstActionToPlay) {
                            firstActionToPlay.state.actionIndex = lastKhronoAction.snapshotEnd.next.actionIndex;
                        }
                    }
                    else {
                        firstActionToPlay = getActionGroup(lastKhronoAction.actionGroupId);
                    }
                    await _updateState({
                        currentActionIndex: lastKhronoAction?.snapshotEnd?.next?.actionIndex ?? 0,
                    });
                }
                debug("Register Khrono to variable change event");
                _wrappedVariableManager.on("change", async (namespace, identifier, newValue, oldValue) => {
                    if (namespace === variableNamespace) {
                        return Khrono.createEvent({
                            payload: {
                                identifier,
                                previousValue: oldValue,
                                value: newValue,
                            },
                            type: KhronoEventEnum.VARIABLE_CHANGE,
                        });
                    }
                });
                if (firstActionToPlay) {
                    debug("First action");
                    _state.waitingForCmdHookCompletion = true;
                    try {
                        debug("Setting Group Branch Last Actions");
                        initBranchesLastActionGroup(inputAction ? inputAction : firstActionToPlay, null, _actionGroups);
                        debug("%O", _actionGroups);
                    }
                    catch (error) {
                        return _fail({
                            code: FailureCodeEnum.CONFIGURATION_ERROR,
                            key: error.key,
                            sequencer: {
                                config: params,
                                state: _state,
                            },
                            source: FailureSourceEnum.SEQUENCER,
                        });
                    }
                    await _updateState({
                        failPathAvailable: Boolean(_actionGroups.find((actionGroup) => actionGroup.config.type === ActionTypeEnum.FAIL)),
                    });
                    return _updateStatus({ status: SequencerStatusEnum.PLAYING, payload: null }).then(async () => {
                        _state.waitingForCmdHookCompletion = false;
                        return _runSequencerHook("onCmdHookComplete", "play").then(() => _playActionGroup(firstActionToPlay, !isObject(clonedParam)));
                    });
                }
                else {
                    return _fail({
                        code: FailureCodeEnum.CONFIGURATION_ERROR,
                        key: localeKeys.sequencer.inputActionGroupNotFound,
                        sequencer: {
                            config: params,
                            state: _state,
                        },
                        source: FailureSourceEnum.SEQUENCER,
                    });
                }
            }
            else {
                debug("play:fail:request is already in progress");
                return _fail({
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.sequencer.flowError.hook.alreadyInProgress,
                    sequencer: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.SEQUENCER,
                }, false);
            }
        }
        else {
            debug("play:fail:sequencer is not idle");
            return _fail({
                code: FailureCodeEnum.FLOW_ERROR,
                key: localeKeys.sequencer.flowError.play.notIdle,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            }, false);
        }
    }
    async function reset() {
        debug("reset");
        if (isSequencerInactive(_status)) {
            _actionGroups.splice(0, _actionGroups.length);
            Khrono.reset();
            await _wrappedVariableManager.init();
            return _updateState({ currentActionGroup: null }).then(() => _updateStatus({ status: SequencerStatusEnum.IDLE, payload: null }));
        }
        else {
            debug("reset:fail:sequencer is not idle");
            return _fail({
                code: FailureCodeEnum.FLOW_ERROR,
                key: localeKeys.sequencer.flowError.reset.sequencerStillActive,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            }, false);
        }
    }
    async function resume() {
        debug("resume");
        if (_status === SequencerStatusEnum.PAUSED) {
            if (!_state.waitingForCmdHookCompletion) {
                _state.waitingForCmdHookCompletion = true;
                return _updateStatus({ status: SequencerStatusEnum.PLAYING, payload: null }, "onResume").then(async () => {
                    _state.waitingForCmdHookCompletion = false;
                    return _runSequencerHook("onCmdHookComplete", "resume").then(() => {
                        if (_state.currentActionGroup) {
                            if (isActionGroupPaused(_state.currentActionGroup.status)) {
                                return _state.currentActionGroup.resume();
                            }
                            if (_nextActionGroup) {
                                return _playActionGroup(_nextActionGroup);
                            }
                            return _fail({
                                code: FailureCodeEnum.CRITICAL,
                                key: localeKeys.sequencer.nextActionGroupNotFound,
                                sequencer: {
                                    config: params,
                                    state: _state,
                                },
                                source: FailureSourceEnum.SEQUENCER,
                            });
                        }
                        return _fail({
                            code: FailureCodeEnum.CRITICAL,
                            key: localeKeys.sequencer.currentActionGroupNotDefined,
                            sequencer: {
                                config: params,
                                state: _state,
                            },
                            source: FailureSourceEnum.SEQUENCER,
                        });
                    });
                });
            }
            else {
                debug("resume:fail:request is already in progress");
                return _fail({
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.sequencer.flowError.hook.alreadyInProgress,
                    sequencer: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.SEQUENCER,
                }, false);
            }
        }
        else {
            debug("resume:fail:sequencer is not paused");
            return _fail({
                code: FailureCodeEnum.FLOW_ERROR,
                key: localeKeys.sequencer.flowError.resume.notPaused,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            }, false);
        }
    }
    async function stop(causeFailure = false) {
        debug("stop");
        if (!_state.waitingForCmdHookCompletion) {
            if ([SequencerStatusEnum.PLAYING, SequencerStatusEnum.PAUSED].includes(_status)) {
                _state.waitingForCmdHookCompletion = true;
                return _updateStatus({ status: SequencerStatusEnum.STOPPING, payload: null }).then(async () => {
                    _state.waitingForCmdHookCompletion = false;
                    return _runSequencerHook("onCmdHookComplete", "stop").then(() => _state.currentActionGroup
                        ? _state.currentActionGroup.stop(causeFailure)
                        : causeFailure
                            ? _fail({
                                code: FailureCodeEnum.CRITICAL,
                                key: localeKeys.sequencer.currentActionGroupNotDefined,
                                sequencer: {
                                    config: params,
                                    state: _state,
                                },
                                source: FailureSourceEnum.SEQUENCER,
                            })
                            : _updateStatus({ status: SequencerStatusEnum.STOPPED, payload: null }));
                });
            }
            else {
                debug("stop:fail:sequencer is idle");
                return _fail({
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.sequencer.flowError.stop.notPlayingOrPaused,
                    sequencer: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.SEQUENCER,
                }, false);
            }
        }
        else {
            debug("stop:fail:request is already in progress");
            return _fail({
                code: FailureCodeEnum.FLOW_ERROR,
                key: localeKeys.sequencer.flowError.hook.alreadyInProgress,
                sequencer: {
                    config: params,
                    state: _state,
                },
                source: FailureSourceEnum.SEQUENCER,
            }, false);
        }
    }
    return {
        get state() {
            return _state;
        },
        addActionGroup,
        addSubPrograms,
        clientUpdate,
        getActionGroup,
        getCurrentActionGroup,
        getStatus,
        getVariable,
        play,
        stop,
        pause,
        resume,
        reset,
        setVariables,
    };
}
export const Sequencer = {
    get Action() {
        return {
            ActionTypeEnum,
            SequencerActionPlayCompletedPayloadTypeEnum,
            SequencerActionStatusEnum,
        };
    },
    get ClientUpdate() {
        return {
            RemoteEventEnum,
            RemoteEventSource,
            ClientUpdateTypeEnum,
        };
    },
    get Error() {
        return {
            ActionGroupHookOnFailCircularError,
            ActionHookOnFailCircularError,
            CoreActionConfigError,
            CoreActionFlowError,
            HookOnFailCircularError,
            InterpolationError,
            InvalidCoreActionTypeError,
            RemoteActionExternalError,
            RemoteActionMethodTimeoutError,
            SequencerConfigError,
            SequencerError,
            SequencerHookOnFailCircularError,
            UndefinedRemoteMethodError,
            VariableManagerError,
        };
    },
    get Failure() {
        return {
            FailureCodeEnum,
            FailureSourceEnum,
        };
    },
    khrono: Khrono.services,
    get SequencerStatusEnum() {
        return SequencerStatusEnum;
    },
    create,
};
