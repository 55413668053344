import { ProcessVariableType } from "../../../process/variable";
export const ComparisonOperator = {
    EQUALS: "===",
    NOT_EQUAL: "!==",
    GREATER_THAN: ">",
    LESS_THAN: "<",
    GREATER_THAN_OR_EQUAL_TO: ">=",
    LESS_THAN_OR_EQUAL_TO: "<=",
};
export const LogicalOperator = {
    NA: " ",
    AND: "&&",
    OR: "||",
};
export const ConditionMode = {
    SIMPLIFIED: "s",
    EXPERT: "e",
};
export const ConditionType = {
    BOOLEAN: ProcessVariableType.BOOLEAN,
    DATE: "date",
    JSON: ProcessVariableType.JSON,
    NUMBER: ProcessVariableType.NUMBER,
    STRING: ProcessVariableType.STRING,
};
export class ConditionBaseConfig {
    constructor() {
        this.mode = ConditionMode.SIMPLIFIED;
        this.expert = "";
        this.simplified = {
            type: ConditionType.STRING,
            leftOperand: "",
            comparisonOperator: ComparisonOperator.EQUALS,
            rightOperand: "",
        };
        this.logicalOperator = LogicalOperator.NA;
    }
}
export function createStepConfigCondition() {
    return {
        conditions: [new ConditionBaseConfig()],
        failOnFalse: false,
    };
}
