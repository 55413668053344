import { ITrainingPending, TrainingPendingTrainingCertificateSignRequest } from "@kortex/aos-common";

import { BareAction, ComplexAction } from "../app.types";

export interface TrainingPendingState {
    trainingsPendingOfUser: ITrainingPending[];
    trainingPendingList: ITrainingPending[];
}

export enum TrainingPendingActionType {
    // TRAINING PENDING OF USER
    CLEAR_TRAINING_PENDING_OF_USER = "@@TRAINING_PENDING/CLEAR_TRAINING_PENDING_OF_USER",
    SET_TRAINING_PENDING_OF_USER = "@@TRAINING_PENDING/SET_TRAINING_PENDING_OF_USER",
    INSERT_TRAINING_PENDING_OF_USER = "@@TRAINING_PENDING/INSERT_TRAININGS_PENDING_OF_USER",
    DELETE_TRAINING_PENDING_OF_USER = "@@TRAINING_PENDING/DELETE_TRAINING_PENDING_OF_USER",
    // TRAINING PENDING OF LIST
    CLEAR_TRAINING_PENDING_LIST = "@@TRAINING_PENDING/CLEAR_TRAINING_PENDING_LIST",
    SET_TRAINING_PENDING_LIST = "@@TRAINING_PENDING/SET_TRAINING_PENDING_LIST",
    INSERT_TRAINING_PENDING_LIST = "@@TRAINING_PENDING/INSERT_TRAININGS_PENDING_LIST",
    DELETE_TRAINING_PENDING_LIST = "@@TRAINING_PENDING/DELETE_TRAINING_PENDING_LIST",
}

// TRAINING PENDING OF USER
export type TrainingPendingOfUserClear = BareAction<TrainingPendingActionType.CLEAR_TRAINING_PENDING_OF_USER>;
export type TrainingPendingOfUserInsertedAction = ComplexAction<
    TrainingPendingActionType.INSERT_TRAINING_PENDING_OF_USER,
    ITrainingPending[]
>;
export type TrainingPendingOfUserSetAction = ComplexAction<TrainingPendingActionType.SET_TRAINING_PENDING_OF_USER, ITrainingPending[]>;
export type TrainingPendingOfUserDeletedAction = ComplexAction<
    TrainingPendingActionType.DELETE_TRAINING_PENDING_OF_USER,
    ITrainingPending[]
>;

// TRAINING PENDING OF LIST
export type TrainingPendingListClear = BareAction<TrainingPendingActionType.CLEAR_TRAINING_PENDING_LIST>;
export type TrainingPendingListSetAction = ComplexAction<TrainingPendingActionType.SET_TRAINING_PENDING_LIST, ITrainingPending[]>;
export type TrainingPendingListInsertedAction = ComplexAction<TrainingPendingActionType.INSERT_TRAINING_PENDING_LIST, ITrainingPending[]>;
export type TrainingPendingListDeletedAction = ComplexAction<TrainingPendingActionType.DELETE_TRAINING_PENDING_LIST, ITrainingPending[]>;

export type TrainingPendingActions =
    | TrainingPendingOfUserClear
    | TrainingPendingOfUserSetAction
    | TrainingPendingOfUserInsertedAction
    | TrainingPendingOfUserDeletedAction
    | TrainingPendingListClear
    | TrainingPendingListSetAction
    | TrainingPendingListInsertedAction
    | TrainingPendingListDeletedAction;

export type TrainingCertificateSign = {
    trainingsCertificateSelected: TrainingPendingTrainingCertificateSignRequest;
    unselectTrainingsCertificate: ITrainingPending[];
};
