import { ACTION_GROUP_CONTEXT_SEPARATOR } from "../interfaces";
import { deepClone } from "./deep-clone";
import { generateActionGroupId } from "./generate-action-group-id";
import { getParentContext } from "./get-parent-context";
export function assignNewActionGroupIds(actionGroups) {
    const clonedActionGroups = deepClone(actionGroups);
    const actionGroupIdOldToNew = {};
    for (let actionIndex = 0; actionIndex < clonedActionGroups.length; actionIndex++) {
        const newActionGroupId = generateActionGroupId();
        const newContext = getParentContext(actionGroups[actionIndex]) + ACTION_GROUP_CONTEXT_SEPARATOR + newActionGroupId;
        actionGroupIdOldToNew[clonedActionGroups[actionIndex].context] = newActionGroupId;
        clonedActionGroups[actionIndex].config.actionGroupId = newActionGroupId;
        clonedActionGroups[actionIndex] = {
            ...clonedActionGroups[actionIndex],
            context: newContext,
        };
    }
    for (let actionIndex = 0; actionIndex < clonedActionGroups.length; actionIndex++) {
        const parentContext = getParentContext(clonedActionGroups[actionIndex]);
        if (clonedActionGroups[actionIndex].config.next !== null) {
            clonedActionGroups[actionIndex].config.next =
                actionGroupIdOldToNew[parentContext + ACTION_GROUP_CONTEXT_SEPARATOR + clonedActionGroups[actionIndex].config.next] ?? null;
        }
        for (let branchIndex = 0; branchIndex < clonedActionGroups[actionIndex].config.branches.length; branchIndex++) {
            if (clonedActionGroups[actionIndex].config.branches[branchIndex] !== null) {
                clonedActionGroups[actionIndex].config.branches[branchIndex] =
                    actionGroupIdOldToNew[parentContext + ACTION_GROUP_CONTEXT_SEPARATOR + clonedActionGroups[actionIndex].config.branches[branchIndex]] ?? null;
            }
        }
    }
    return clonedActionGroups;
}
