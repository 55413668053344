import { greyPalette } from "@aos/react-components";
import {
    IJobProcessUiModel,
    IJobUiModel,
    IProcessUiModel,
    ITokenDecoded,
    IUserGroupDbModel,
    ProcessApprovalStatusFilter,
    TreeNodeDbModel,
} from "@kortex/aos-common";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useState } from "react";

import RoutingIcon from "../../../core/Icons/Routing/Routing";
import KortexPanelCard from "../../../core/KortexPanelCard";
import ProcessVersionPicker from "../../../core/ProcessVersionPicker/ProcessVersionPicker";

import SchedulerJobProcessCard from "./SchedulerJobProcessCard";

const useStyles = makeStyles({
    root: {},
    container: {
        marginTop: "6px",
    },
    processContainer: {
        marginLeft: "5px",
    },
    progressContainer: {
        paddingLeft: "5px",
    },
    cardContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    spacer: {
        flexGrow: 1,
    },
    iconNameContainer: {
        color: greyPalette[500],
    },
    processPanelCardTypo: {
        marginLeft: "5px",
    },
});

interface IOwnProps {
    job?: IJobUiModel;
    routingId: number;
    routingName: string;
    routingVersion: string;
    orderId: number;
    isSelected: boolean;
    jobProcessList: IJobProcessUiModel[];
    groupsList: IUserGroupDbModel[];
    isDragDisabled: boolean;
    userInfo?: ITokenDecoded;
    processes: IProcessUiModel[];
    uniqueKey: number;

    onSelection?: (routingId: number, orderId: number, uniqueKey?: number) => void;
    onVersionChange?: (processId: number) => void;
    onUpdateProcess?: (job: IJobUiModel, jobProcess: IJobProcessUiModel) => void;
}

export default function SchedulerJobRoutingCard(props: IOwnProps): JSX.Element {
    const { onSelection, onVersionChange, uniqueKey, orderId, routingId, routingName, isDragDisabled, userInfo, processes = [] } = props;
    const classes = useStyles();

    const [job, setJob] = useState<IJobUiModel | undefined>(undefined);
    const [treeNodeId, setTreeNodeId] = useState<TreeNodeDbModel["treeNodeId"]>(0);

    useEffect((): void => {
        if (props.job) {
            setJob(props.job);
        }
    }, [props.job]);

    /**
     * Get the tree node ID of the job process
     */
    useEffect((): void => {
        const routingOfProcesses = processes.find((routing) => routing.processId === routingId);
        if (routingOfProcesses) {
            setTreeNodeId(routingOfProcesses.treeNodeId);
        }
    }, [processes]);

    /**
     * Called when the card is selected
     */
    const handleSelect = (): void => {
        if (onSelection) {
            onSelection(routingId, orderId, uniqueKey);
        }
    };

    /**
     * Called when the version changes
     *
     * @param {number} processId - processId of the selected version
     */
    const handleVersionChange = (processId: number): void => {
        if (onVersionChange) {
            onVersionChange(processId);
        }
    };

    /**
     * Handle change on a process
     *
     * @param {IJobUiModel} job - job containing the process
     * @param {IJobProcessUiModel} process - the process to be changed
     */
    const handleJobProcessChange = (job: IJobUiModel, process: IJobProcessUiModel): void => {
        if (props.onUpdateProcess) {
            props.onUpdateProcess(job, process);
        }
    };

    return (
        <React.Fragment>
            {job && (
                <Paper elevation={2} className={classes.container}>
                    <KortexPanelCard
                        isSelected={props.isSelected}
                        spaceBetweenCard={1}
                        onSelect={handleSelect}
                        padding={10}
                        isDraggable={!isDragDisabled}
                    >
                        <div className={classes.cardContainer}>
                            <RoutingIcon className={classes.iconNameContainer} />
                            <Typography className={classes.processPanelCardTypo} color="primary" variant="h6">
                                {routingName}
                            </Typography>
                            <div className={classes.spacer} />
                            <ProcessVersionPicker
                                onVersionChange={handleVersionChange}
                                latest={false}
                                processId={routingId}
                                readOnly={true}
                                treeNodeId={treeNodeId}
                                variant={ProcessApprovalStatusFilter.APPROVED}
                            />
                        </div>
                    </KortexPanelCard>
                    <div className={classes.processContainer} id="jobRoutingCardId">
                        {props.jobProcessList
                            .filter((jobProcess) => jobProcess.routingId === routingId)
                            .map((jobProcess, index) => {
                                return (
                                    <SchedulerJobProcessCard
                                        key={index}
                                        isSelected={props.isSelected}
                                        job={job}
                                        jobProcess={jobProcess}
                                        groupsList={props.groupsList}
                                        onUpdateProcess={handleJobProcessChange}
                                        onSelection={handleSelect}
                                        isDragDisabled={true}
                                        userInfo={userInfo}
                                        process={processes.find((process) => process.processId === jobProcess.processId)}
                                    />
                                );
                            })}
                    </div>
                </Paper>
            )}
        </React.Fragment>
    );
}
