import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    ProcessTrainingGetLatestApprovedProcessReq,
    ProcessTrainingGetLatestApprovedProcessRes,
    ProcessTrainingGetTrainingsByProcessIdReq,
    ProcessTrainingGetTrainingsByProcessIdRes,
    ProcessTrainingInsertReq,
    ProcessTrainingInsertRes,
    ProcessTrainingInsertedNotif,
    ProcessTrainingLatestApprovedProcessUpdatedNotif,
    ProcessTrainingUpdateStatusReq,
    ProcessTrainingUpdateStatusRes,
    ProcessTrainingUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";
import { processTrainingGetLatestApprovedProcess } from "../processTraining/getLatestApprovedProcess";
import { processTrainingInsert } from "../processTraining/insert";
import { processTrainingInserted } from "../processTraining/inserted";
import { processTrainingUpdated } from "../processTraining/updated";

import { processTrainingGetTrainingsByProcessId } from "./getTrainingsByProcessId";
import { processTrainingLatestApprovedProcessUpdated } from "./latestApprovedProcessUpdated";
import { processTrainingUpdateStatus } from "./updateStatus";

interface IAOSClientServiceWrappedProcessTraining {
    getLatestApprovedProcess: AOSClientServiceRequestWrapped<
        ProcessTrainingGetLatestApprovedProcessReq,
        ProcessTrainingGetLatestApprovedProcessRes
    >;
    getTrainingsByProcessId: AOSClientServiceRequestWrapped<
        ProcessTrainingGetTrainingsByProcessIdReq,
        ProcessTrainingGetTrainingsByProcessIdRes
    >;
    insert: AOSClientServiceRequestWrapped<ProcessTrainingInsertReq, ProcessTrainingInsertRes>;
    onLatestApprovedProcessUpdated: AOSClientServiceNotificationWrapped<ProcessTrainingLatestApprovedProcessUpdatedNotif>;
    onInserted: AOSClientServiceNotificationWrapped<ProcessTrainingInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<ProcessTrainingUpdatedNotif>;
    updateStatus: AOSClientServiceRequestWrapped<ProcessTrainingUpdateStatusReq, ProcessTrainingUpdateStatusRes>;
}

type AOSClientServiceWrappedProcessTraining = IAOSClientServiceWrappedProcessTraining;

// Process training
export function generateProcessTrainingServiceClient(
    router: IRouterClient,
    notifier: EventEmitter
): AOSClientServiceWrappedProcessTraining {
    return {
        getLatestApprovedProcess: AOSClientServiceWrapperUI.request(processTrainingGetLatestApprovedProcess(router)),
        getTrainingsByProcessId: AOSClientServiceWrapperUI.request(processTrainingGetTrainingsByProcessId(router)),
        insert: AOSClientServiceWrapperUI.request(processTrainingInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(processTrainingInserted(router, notifier)),
        onLatestApprovedProcessUpdated: AOSClientServiceWrapperUI.notification(
            processTrainingLatestApprovedProcessUpdated(router, notifier)
        ),
        onUpdated: AOSClientServiceWrapperUI.notification(processTrainingUpdated(router, notifier)),
        updateStatus: AOSClientServiceWrapperUI.request(processTrainingUpdateStatus(router)),
    };
}

generateProcessTrainingServiceClient.serviceId = 130;
