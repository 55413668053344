// common
import { ProcessActionType } from "@kortex/aos-common";
// theme
import { rainbowPalette, theme } from "@aos/react-components";

/**
 * Gets action color based on action type
 *
 * 1500 is for now for unused action
 *
 * @param {ProcessActionType} actionType - type of the action
 * @returns {string} action color
 */
export function getActionColor(actionType: ProcessActionType): string {
    switch (actionType) {
        case "core-work-instructions":
            return rainbowPalette[200]; // Sky Blue #1E88E5
        case "core-condition":
            return rainbowPalette[700]; // Yellow/Green #C0CA33
        case "core-loop":
            return rainbowPalette[100]; // Purple #515EBB
        case "core-math":
            return rainbowPalette[800]; // Orange/Brown #D8AB23
        case "core-message":
            return rainbowPalette[500]; // Orange/Brown #D8AB23
        case "core-failure-ticket-create":
            return rainbowPalette[500]; // Orange/Brown #D8AB23
        case "core-time":
            return rainbowPalette[900]; // Orange #FF7043
        case "core-parser":
            return rainbowPalette[500];
        case "core-set":
            return rainbowPalette[400];
        case "core-stop-process":
            return rainbowPalette[1200];
        case "core-connector":
            return rainbowPalette[600];
        case "core-routing-process":
            return rainbowPalette[100];
        case "core-input":
            return rainbowPalette[1400];
        case "core-output":
            return rainbowPalette[1400];
        case "core-fail":
        case "core-routing-fail":
            return rainbowPalette[1000];

        /* NOT REALLY USED FOR NOW */
        case "core-robot":
            return rainbowPalette[300]; // Blue #00A9FF
        case "core-email":
            return rainbowPalette[1500];

        default:
            return theme.palette.primary[500]; // primary
    }
}
