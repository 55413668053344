import { bomFollowUp } from "./rpcBomFollowUp";
import { bomFollowUpSymptom } from "./rpcBomFollowUpSymptom";
import { electron } from "./rpcElectron";
import { electronicSignature } from "./rpcElectronicSignature";
import { erp } from "./rpcERP";
import { erpSettings } from "./rpcErpSettings";
import { failureSymptoms } from "./rpcFailureSymtoms";
import { failureTypes } from "./rpcFailureTypes";
import { file } from "./rpcFile";
import { general } from "./rpcGeneral";
import { job } from "./rpcJob";
import { kanban } from "./rpcKanban";
import { process } from "./rpcProcess";
import { processActionSettings } from "./rpcProcessActionSettings";
import { processApprovalGroup } from "./rpcProcessApprovalGroup";
import { processPlayer } from "./rpcProcessPlayer";
import { processTraining } from "./rpcProcessTraining";
import { report } from "./rpcReport";
import { reportTag } from "./rpcReportTag";
import { rework } from "./rpcRework";
import { reworkItemStatus } from "./rpcReworkItemStatus";
import { reworkJobProcess } from "./rpcReworkJobProcess";
import { reworkLog } from "./rpcReworkLog";
import { rootCause } from "./rpcRootCause";
import { runner } from "./rpcRunner";
import { runnerPlayer } from "./rpcRunnerPlayer";
import { runnerUi } from "./rpcRunnerUi";
import { settingOrganization } from "./rpcSettingOrganization";
import { symptomLog } from "./rpcSymptomLog";
import { task } from "./rpcTask";
import { training } from "./rpcTraining";
import { trainingCertificate } from "./rpcTrainingCertificate";
import { trainingCertification } from "./rpcTrainingCertification";
import { trainingCertificationPending } from "./rpcTrainingCertificationPending";
import { trainingPending } from "./rpcTrainingPending";
import { trainingReadingCertificate } from "./rpcTrainingReadingCertificate";
import { treeFile } from "./rpcTreeFile";
import { treeProcess } from "./rpcTreeProcess";
import { treeWorkZone } from "./rpcTreeWorkZone";
import { uncategorized } from "./rpcUncategorised";
import { user } from "./rpcUser";
import { userGroup } from "./rpcUserGroup";
import { userRole } from "./rpcUserRole";
import { userTraining } from "./rpcUserTraining";
import { workSchedule } from "./rpcWorkSchedule";
// ADD_API_CALL - Add RPC name
const rpc = {
    // REQ - UI TO HUB
    // general
    general,
    // Settings
    settingOrganization,
    // Users
    user,
    userRole,
    userGroup,
    processApprovalGroup,
    // Scheduler, Jobs, Rework
    job,
    rework,
    reworkLog,
    reworkJobProcess,
    symptomLog,
    workSchedule,
    rootCause,
    bomFollowUp,
    bomFollowUpSymptom,
    // reports
    report,
    reportTag,
    // Tree
    treeFile,
    treeProcess,
    treeWorkZone,
    // Process
    process,
    processActionSettings,
    processPlayer,
    // Failure
    failureTypes,
    failureSymptoms,
    // File
    file,
    // Unknown
    uncategorized,
    // Task
    task,
    // File
    electronicSignature,
    // Rework Item Status
    reworkItemStatus,
    // Training
    processTraining,
    userTraining,
    trainingCertificate,
    trainingPending,
    training,
    trainingCertification,
    trainingCertificationPending,
    trainingReadingCertificate,
    // Kanban
    kanban,
    // ERP
    erp,
    erpSettings,
    // Runner to Hub (req)
    runner,
    // Hub to Runner (cmd)
    runnerPlayer,
    // Runner to Hub to UI (req)
    runnerUi,
    // REQ - UI TO ELECTRON
    electron, // 600
};
const rpcRunner = {
    runnerPlayer: rpc.runnerPlayer,
};
const rpcHub = {
    electron: rpc.electron,
    erp: rpc.erp,
    erpSettings: rpc.erpSettings,
    general: rpc.general,
    settingOrganization: rpc.settingOrganization,
    user: rpc.user,
    userRole: rpc.userRole,
    userGroup: rpc.userGroup,
    processApprovalGroup: rpc.processApprovalGroup,
    job: rpc.job,
    rework: rpc.rework,
    reworkItemStatus: rpc.reworkItemStatus,
    reworkJobProcess: rpc.reworkJobProcess,
    reworkLog: rpc.reworkLog,
    symptomLog: rpc.symptomLog,
    workSchedule: rpc.workSchedule,
    rootCause: rpc.rootCause,
    bomFollowUp: rpc.bomFollowUp,
    bomFollowUpSymptom: rpc.bomFollowUpSymptom,
    report: rpc.report,
    reportTag: rpc.reportTag,
    treeFile: rpc.treeFile,
    treeProcess: rpc.treeProcess,
    treeWorkZone: rpc.treeWorkZone,
    process: rpc.process,
    processActionSettings: rpc.processActionSettings,
    processPlayer: rpc.processPlayer,
    electronicSignature: rpc.electronicSignature,
    failureTypes: rpc.failureTypes,
    failureSymptoms: rpc.failureSymptoms,
    file: rpc.file,
    kanban: rpc.kanban,
    training: rpc.training,
    processTraining: rpc.processTraining,
    userTraining: rpc.userTraining,
    trainingCertificate: rpc.trainingCertificate,
    trainingPending: rpc.trainingPending,
    trainingCertification: rpc.trainingCertification,
    trainingCertificationPending: rpc.trainingCertificationPending,
    trainingReadingCertificate: rpc.trainingReadingCertificate,
    uncategorized: rpc.uncategorized,
    task: rpc.task,
    runner: rpc.runner,
    runnerUi: rpc.runnerUi, // [Browser: Runner-to-Hub-to-UI requests] or [Desktop: Runner-to-UI requests]
};
export function getRPCHubInfo(service) {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return function (func) {
        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error -
            ...rpcHub[service]["rpc"][func],
            serviceId: rpcHub[service].serviceId,
            serviceVersion: rpcHub[service].serviceVersion,
        };
    };
}
export function getRPCRunnerInfo(service) {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return function (func) {
        return {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...rpcRunner[service]["rpc"][func],
            serviceId: rpcRunner[service].serviceId,
            serviceVersion: rpcRunner[service].serviceVersion,
        };
    };
}
/**
 * shield against high blood alcohol concentration
 */
(function () {
    const rpcIds = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function traverse(o, cb) {
        for (const i in o) {
            if (i === "funcId") {
                cb.apply(this, [i, o[i]]);
            }
            if (o[i] !== null && typeof o[i] === "object") {
                //going one step down in the object tree!!
                traverse(o[i], cb);
            }
        }
    }
    function process(_, value) {
        const rpcId = Number(value);
        if (Number.isFinite(rpcId)) {
            rpcIds.push(rpcId);
        }
    }
    traverse(rpc, process);
    if (rpcIds.length !== new Set(rpcIds).size) {
        console.error("rpcIds", rpcIds);
        throw new Error("high blood alcohol concentration");
    }
})();
