import { useEntitiesUsers } from "@kortex/aos-ui/redux/effects";
import React from "react";

import { useProcessTrainingContext } from "../context";

import { TrainingItem, UserTraining } from "./userTraining";

function ProcessTrainingTrainings(): JSX.Element {
    const { processTrainings } = useProcessTrainingContext();
    const users = useEntitiesUsers();

    const getTrainingItems = (): TrainingItem[] => {
        // Add trainings to the left panel
        const items: TrainingItem[] = processTrainings.left.trainings.map((training): TrainingItem => {
            const user = users.find((u) => u.userId === training.userId);

            return {
                left: training,
                right: null,
                user: {
                    firstName: user?.firstName || "",
                    lastName: user?.lastName || "",
                    userId: training.userId,
                },
            };
        });

        // Add trainings to the right panel
        for (const training of processTrainings.right.trainings) {
            const index = items.findIndex((item) => item.user.userId === training.userId);

            if (index === -1) {
                const user = users.find((u) => u.userId === training.userId);

                items.push({
                    left: null,
                    right: training,
                    user: {
                        firstName: user?.firstName || "",
                        lastName: user?.lastName || "",
                        userId: training.userId,
                    },
                });
            } else {
                items[index].right = training;
            }
        }

        return items.sort((item1, item2) =>
            `${item1.user.firstName} ${item1.user.lastName}`.localeCompare(`${item2.user.firstName} ${item2.user.lastName}`)
        );
    };

    return (
        <>
            {getTrainingItems().map((training, index) => (
                <div key={`trainingUserTraining${index}`}>
                    <UserTraining index={index} training={training} />
                </div>
            ))}
        </>
    );
}

export default ProcessTrainingTrainings;
