import { EventEmitter } from "events";

import { createContext, useEffect, useState } from "react";

import { Callback, COPY_PASTE_KEY, EventType, Id, Key, LayerId, LayerStack, StackMap } from "./event";

export interface IKeybindManagerContext {
    /**
     * Trigger an event listener
     */
    emit: <T extends Event = KeyboardEvent>(id: Id, type: EventType, ...cbParams: Parameters<Callback<T>>) => void;

    /**
     * Get the last ID of a stack from a key in the map
     */
    getLastKeyId: (key: Key, indexModifier?: number) => Id;

    /**
     * Chronologically ordered stack of ALL key binding events that were added by the useKeybind hook.
     */
    layers: LayerStack;

    /**
     * Map of all keys an event is bound with.
     * The value of each key is a chronologically ordered stack of all events bound to that key that were appended by the useKeybind hook.
     */
    map: StackMap;

    /**
     * Remove keybind event
     */
    off: <T extends Event = KeyboardEvent>(id: Id, type: EventType, cb: Callback<T>) => void;

    /**
     * Creates a keybind event
     */
    on: <T extends Event = KeyboardEvent>(id: Id, type: EventType, cb: Callback<T>) => void;

    /**
     * Add an ID to the map for specified key
     */
    pushKey: (key: Key, id: Id) => void;

    /**
     * Add a layer to the stack
     */
    pushLayer: (id: LayerId, stopPropagation?: boolean) => void;

    /**
     * Remove an ID from the map for specified key
     */
    removeKey: (key: Key, id: Id) => void;

    /**
     * Remove a layer from the stack
     */
    removeLayer: (id: LayerId) => void;

    /**
     * Set the `stopPropagation` value of a layer
     */
    setLayerPropagation: (id: LayerId, stopPropagation: boolean) => void;
}

const _eventEmitter = new EventEmitter();

/**
 * Create the default context for the keybind manager
 */
export const KeybindManagerContext = createContext<IKeybindManagerContext>({
    emit: () => void 0,
    getLastKeyId: () => "",
    layers: [],
    map: {},
    off: () => void 0,
    on: () => void 0,
    pushKey: () => void 0,
    pushLayer: () => void 0,
    removeKey: () => void 0,
    removeLayer: () => void 0,
    setLayerPropagation: () => void 0,
});

/**
 * Create keybind manager
 */
export const useKeybindManager = (): IKeybindManagerContext => {
    const [layers, setLayers] = useState<LayerStack>([]);
    const [map, setMap] = useState<StackMap>({});

    useEffect((): (() => void) => {
        const keyDownListener: Callback = (event) => emit(getLastKeyId(event.key), "keydown", event);
        const keyUpListener: Callback = (event) => emit(getLastKeyId(event.key), "keyup", event);
        const copyListener: Callback<ClipboardEvent> = (event) => emit(getLastKeyId(COPY_PASTE_KEY), "copy", event);
        const pasterListener: Callback<ClipboardEvent> = (event) => emit(getLastKeyId(COPY_PASTE_KEY), "paste", event);

        document.addEventListener("keydown", keyDownListener);
        document.addEventListener("keyup", keyUpListener);
        document.addEventListener("copy", copyListener);
        document.addEventListener("paste", pasterListener);

        return () => {
            document.removeEventListener("keydown", keyDownListener);
            document.removeEventListener("keyup", keyUpListener);
            document.removeEventListener("copy", copyListener);
            document.removeEventListener("paste", pasterListener);
        };
    }, [layers, map]);

    const getLastKeyId = (key: Key, indexModifier = 0): Id => {
        const ids = map[key];

        if (!ids) return "";

        return ids[ids.length - 1 - indexModifier] ?? "";
    };

    const emit: IKeybindManagerContext["emit"] = (id, type, event) => {
        if (id) _eventEmitter.emit(id + type, event);
    };

    const off: IKeybindManagerContext["off"] = (id, type, cb) => {
        _eventEmitter.off(id + type, cb);
    };

    const on: IKeybindManagerContext["on"] = (id, type, cb) => {
        _eventEmitter.on(id + type, cb);
    };

    const pushKey: IKeybindManagerContext["pushKey"] = (key, id) => {
        setMap((prevState) => {
            const updatedState = { ...prevState };

            if (!updatedState[key]) updatedState[key] = [];
            updatedState[key].push(id);

            return updatedState;
        });
    };

    const pushLayer: IKeybindManagerContext["pushLayer"] = (id, stopPropagation = false) => {
        setLayers((prevState) => {
            const updatedState = [...prevState];
            updatedState.push([id, stopPropagation]);

            return updatedState;
        });
    };

    const removeKey: IKeybindManagerContext["removeKey"] = (key, id) => {
        setMap((prevState) => {
            const updatedState = { ...prevState };
            const index = updatedState[key].findIndex((currentId) => currentId === id);

            if (index !== -1) updatedState[key].splice(index, 1);

            return updatedState;
        });
    };

    const removeLayer: IKeybindManagerContext["removeLayer"] = (id) => {
        setLayers((prevState) => {
            const updatedState = [...prevState];
            const index = updatedState.findIndex(([currentId]) => currentId === id);

            if (index !== -1) updatedState.splice(index, 1);

            return updatedState;
        });
    };

    const setLayerPropagation: IKeybindManagerContext["setLayerPropagation"] = (id, stopPropagation) => {
        setLayers((prevState) => {
            const updatedState = [...prevState];
            const index = updatedState.findIndex(([currentId]) => currentId === id);

            if (index !== -1) updatedState[index][1] = stopPropagation;

            return updatedState;
        });
    };

    return {
        emit,
        getLastKeyId,
        layers,
        map,
        off,
        on,
        pushKey,
        pushLayer,
        removeKey,
        removeLayer,
        setLayerPropagation,
    };
};
