import { EventEmitter } from "events";

import { ServiceManagerClient, WSClient, Kinova } from "@kortex/aos-api-client";
import { getRPCHubInfo, ProcessPlayerUiActionPropsUpdatedNotif } from "@kortex/aos-common";

import {
    objectFromUTF8Array,
    utf8ArrayFromObject,
    utf8ArrayToKortexApiErrorObject,
    utf8ArrayToKortexApiNotificationHandleObject,
} from "../helpers";
import { emptyObject } from "../constants";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const processPlayerUiActionPropsUpdated = (router: WSClient["router"], notifier: EventEmitter) =>
    ServiceManagerClient.getNR<
        emptyObject,
        Kinova.Api.Common.INotificationHandle,
        ProcessPlayerUiActionPropsUpdatedNotif,
        Kinova.Api.IError
    >(getRPCHubInfo("processPlayer")("uiActionPropsUpdated"), {
        notifications: notifier,
        router,
        serializer: {
            decode: utf8ArrayToKortexApiNotificationHandleObject,
            encode: utf8ArrayFromObject,
            err: {
                decode: utf8ArrayToKortexApiErrorObject,
            },
            notifier: objectFromUTF8Array,
        },
    });
