/**
 * Assign follow-ups to their respective item in a WO BOM object.
 * The original WO BOM object will be modified.
 */
export function assignFollowUpToWoBom(woBom, followUps) {
    for (const partNumber of Object.keys(woBom.items)) {
        const followUpMap = {};
        for (const followUp of followUps) {
            if (followUp.partNumber !== partNumber)
                continue;
            if (followUpMap[followUp.processActionStepId]) {
                followUpMap[followUp.processActionStepId].push(followUp);
            }
            else {
                followUpMap[followUp.processActionStepId] = [followUp];
            }
        }
        woBom.items[partNumber].followUp = followUpMap;
    }
    return woBom;
}
