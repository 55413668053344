export class TimeState {
    constructor() {
        this.date = ""; // Used for dates
        this.function = undefined;
        this.time = 0; // Used for timers, countdowns and timestamps
        this.type = undefined;
    }
    toJSON() {
        return {
            date: this.date,
            time: this.time,
            function: this.function,
            type: this.type,
        };
    }
}
