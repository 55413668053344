import { useState } from "react";
export function useFormInput(initialValue, label, trim, validator, onChanged) {
    if (trim === void 0) { trim = false; }
    var _a = useState(initialValue), value = _a[0], setValue = _a[1];
    var _b = useState(validator ? !validator(initialValue) : false), error = _b[0], setError = _b[1];
    function handleChange(e) {
        var result = e.target.value;
        if (trim && (typeof result === "string" || result instanceof String)) {
            result = result.trim();
        }
        setValue(result);
        setError(validator ? !validator(result) : false);
        if (onChanged && value !== result) {
            onChanged(result);
        }
    }
    return {
        value: value,
        onChange: handleChange,
        label: label,
        error: error,
    };
}
export function useFormInput2(initialValue, label, trim, validator, onChanged) {
    if (trim === void 0) { trim = false; }
    var _a = useState(initialValue), value = _a[0], setValue = _a[1];
    var _b = useState(validator ? !validator(initialValue) : undefined), error = _b[0], setError = _b[1];
    function handleChange(e) {
        var result = e.target.value;
        if (trim && typeof result === "string") {
            result = result.trim();
        }
        setValue(result);
        setError(validator ? !validator(result) : undefined);
        if (onChanged && value !== result) {
            onChanged(result);
        }
    }
    return {
        inputProps: {
            value: value,
            onChange: handleChange,
            label: label,
            error: error,
        },
        setValue: setValue,
    };
}
