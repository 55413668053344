import { ConditionMode, ConditionType } from "./ConditionConfig";
/**
 * Returns the merge condition string based on received condition list
 *
 * @param { ConditionBaseConfig[] } conditions - List of conditions
 *
 * @returns {string} - merged condition strings
 */
export const GetMergedConditionFromConditions = (conditions) => {
    let returnCondition = "";
    conditions.forEach((condition) => {
        if (condition.mode === ConditionMode.SIMPLIFIED) {
            let leftOperand = "";
            let rightOperand = "";
            switch (condition.simplified.type) {
                case ConditionType.STRING:
                    leftOperand = `"${condition.simplified.leftOperand}"`;
                    rightOperand = `"${condition.simplified.rightOperand}"`;
                    break;
                case ConditionType.NUMBER:
                    leftOperand = `${condition.simplified.leftOperand}`;
                    rightOperand = `${condition.simplified.rightOperand}`;
                    break;
                case ConditionType.DATE:
                    leftOperand = `new Date('${condition.simplified.leftOperand}').getTime()`;
                    rightOperand = `new Date('${condition.simplified.rightOperand}').getTime()`;
                    break;
                default:
                    break;
            }
            returnCondition += leftOperand + condition.simplified.comparisonOperator + rightOperand + " ";
        }
        else {
            returnCondition += "(" + condition.expert + ") ";
        }
        returnCondition += condition.logicalOperator;
    });
    return returnCondition;
};
