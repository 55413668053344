import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { IBomFollowUp, IBomFollowUpDbModel } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { Button, CircularProgress, IconButton, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { FC, useEffect, useState } from "react";

import { serviceMap, ServiceVariant } from "../../utils";

const useStyles = makeStyles({
    buttonIcon: {
        paddingRight: "10px",
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 20px",
    },
    row: {
        marginBottom: "10px",
    },
    quantityTextField: {
        width: "150px",
    },
    typographyMaximumQuantity: {
        margin: "0 0 10px 5px",
    },
});

type FollowUpRow = Pick<IBomFollowUpDbModel, "traceability" | "quantity" | "partNumber">;

interface IOwnProps {
    followUp: IBomFollowUp;
    onClose: () => void;
    open: boolean;
    variant: ServiceVariant;
}

const ORIGINAL_FOLLOW_UP_QUANTITY_INDEX = -1;

const MultipleTraceabilitiesDialog: FC<IOwnProps> = (props) => {
    const { followUp, onClose, open, variant } = props;

    const isFollowUpQuantityZero = followUp.quantity === 0;
    const initialOriginalFollowQuantity = isFollowUpQuantityZero ? 0 : followUp.quantity <= 1 ? 1 : followUp.quantity - 1;
    const minimumQuantity = isFollowUpQuantityZero ? 0 : 1;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);
    const [originalFollowUpQuantity, setOriginalFollowUpQuantity] = useState<number>(initialOriginalFollowQuantity);
    const [rows, setRows] = useState<FollowUpRow[]>([
        {
            partNumber: followUp.partNumber,
            quantity: minimumQuantity,
            traceability: "",
        },
    ]);

    const totalQuantity =
        originalFollowUpQuantity + // Quantity of the item we want to 'Multiple Traceability'
        rows.map((row) => row.quantity).reduce((accumulator, currentValue) => accumulator + currentValue, 0); // Sum of quantity for all traceability we want to add
    const confirmButtonDisabled =
        loading ||
        !rows.length || // There must be at least 1 new traceability
        // !isFollowUpQuantityZero || // Quantity is 0
        followUp.quantity !== totalQuantity || // Total quantity must be equal to the maximum quantity
        rows.some((row) => !row.traceability?.trim() || (!isFollowUpQuantityZero && row.quantity <= 0)); // New traceabiliies must be valid

    /**
     * Resets the fields when the dialog is opened
     */
    useEffect(() => {
        if (open) {
            setOriginalFollowUpQuantity(initialOriginalFollowQuantity);
            setRows([
                {
                    partNumber: followUp.partNumber,
                    quantity: minimumQuantity,
                    traceability: "",
                },
            ]);
        }
    }, [open]);

    /**
     * Creates a new row in the dialog to add a traceability
     */
    const handleAddTraceability = (): void => {
        const updatedRows = deepClone(rows);

        updatedRows.push({
            partNumber: followUp.partNumber,
            quantity: minimumQuantity,
            traceability: "",
        });

        setRows(updatedRows);
    };

    /**
     * Updates the traceability of a row
     */
    const handleTraceabilityChange =
        (index: number): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const updatedRows = deepClone(rows);

            updatedRows[index].traceability = event.target.value;

            setRows(updatedRows);
        };

    /**
     * Increases the quantity of specified traceability
     */
    const handleQuantityChange =
        (index: number) =>
        (value: number): void => {
            if (index === ORIGINAL_FOLLOW_UP_QUANTITY_INDEX) {
                setOriginalFollowUpQuantity(value);
            } else {
                const updatedRows = deepClone(rows);

                updatedRows[index].quantity = value;

                setRows(updatedRows);
            }
        };

    /**
     * Closes the dialog
     */
    const handleClose = (): void => {
        onClose();
    };

    /**
     * Deletes a row
     */
    const handleDeleteRow =
        (index: number): (() => void) =>
        (): void => {
            const updatedRows = deepClone(rows);

            updatedRows.splice(index, 1);

            setRows(updatedRows);
        };

    /**
     * Proceeds to add a traceability to an item
     */
    const handleConfirm = (): void => {
        setLoading(true);

        dispatch(
            serviceMap[variant].multipleTraceabilities({
                newItems: rows.map((row) => ({
                    quantity: row.quantity,
                    traceability: row.traceability,
                })),
                originalItem: {
                    bomFollowUpId: followUp.bomFollowUpId,
                    quantity: originalFollowUpQuantity,
                },
            })
        )
            .then(handleClose)
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={confirmButtonDisabled}
            onCancel={handleClose}
            onConfirm={handleConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("bomPage.bomTable.multipleTraceabilities"),
            }}
            textLabelsIcons={{
                proceedButtonIcon: loading ? <CircularProgress /> : undefined,
            }}
        >
            <>
                {/* ORIGINAL TRACEABILITY */}
                <div key="multipleTraceabilitiesDialogOriginalTraceability">
                    <Typography
                        className={classes.typographyMaximumQuantity}
                        color={followUp.quantity === totalQuantity ? "textPrimary" : "error"}
                        variant="body2"
                    >{`${translate("bomPage.bomTable.quantitySum")} ${followUp.quantity}`}</Typography>
                    <div className={classes.row} id="rowOriginalValueId">
                        <KortexTextField
                            label={translate("bomPage.bomTable.originalTraceability")}
                            TextFieldProps={{
                                autoComplete: "off",
                                disabled: true,
                                id: "multipleTraceabilitiesDialogOriginalTraceabilityId",
                                required: true,
                            }}
                            value={followUp.traceability}
                            variant="outlined"
                        />
                        {/* QUANTITY */}
                        <KortexTextField
                            changedDelayMS={0}
                            className={classes.quantityTextField}
                            label={translate("bomPage.bomTable.quantity")}
                            min={minimumQuantity}
                            onChanged={handleQuantityChange(ORIGINAL_FOLLOW_UP_QUANTITY_INDEX)}
                            TextFieldProps={{
                                autoComplete: "off",
                                disabled: isFollowUpQuantityZero,
                                id: "multipleTraceabilitiesDialogOriginalTraceabilityQuantityId",
                                required: true,
                            }}
                            type="number"
                            value={originalFollowUpQuantity}
                            withButtons={!isFollowUpQuantityZero}
                        />
                    </div>
                </div>
                {/* NEW TRACEABILITIES */}
                {rows.map(
                    (row, index): JSX.Element => (
                        <div className={classes.row} key={`multipleTraceabilitiesDialogRow${index}`} id={`rowValue${index}Id`}>
                            <KortexTextField
                                label={translate("bomPage.bomTable.traceability")}
                                onChange={handleTraceabilityChange(index)}
                                TextFieldProps={{
                                    autoComplete: "off",
                                    id: `multipleTraceabilitiesDialogTraceability${index}Id`,
                                    required: true,
                                }}
                                value={row.traceability}
                                variant="outlined"
                            />
                            {/* QUANTITY */}
                            <KortexTextField
                                changedDelayMS={0}
                                className={classes.quantityTextField}
                                label={translate("bomPage.bomTable.quantity")}
                                min={minimumQuantity}
                                onChanged={handleQuantityChange(index)}
                                TextFieldProps={{
                                    autoComplete: "off",
                                    disabled: isFollowUpQuantityZero,
                                    id: `multipleTraceabilitiesDialogQuantity${index}Id`,
                                    required: true,
                                }}
                                type="number"
                                value={row.quantity}
                                withButtons={!isFollowUpQuantityZero}
                            />
                            {index > 0 ? (
                                <IconButton id={`multipleTraceabilitiesDialogDeleteRow${index}Id`} onClick={handleDeleteRow(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            ) : null}
                        </div>
                    )
                )}
                <Button
                    className={classes.dialogButtons}
                    color="secondary"
                    disabled={false} // faire en fonction de la quantity par step
                    id="multipleTraceabilitiesDialogAddTraceabilityButtonId"
                    onClick={handleAddTraceability}
                    variant="contained"
                >
                    <AddIcon className={classes.buttonIcon} />
                    {translate("bomPage.bomTable.addTraceability")}
                </Button>
            </>
        </KortexDialogConfirmation>
    );
};

export default MultipleTraceabilitiesDialog;
