var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function Stop(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M17.4358974,4.56410256 L19.4358974,4.56410256 L19.4358974,19.9487179 L17.4358974,19.9487179 L17.4358974,4.56410256 Z M16.4358974,4.56410256 L17.4358974,4.56410256 L17.4358974,19.9487179 L16.4358974,19.9487179 L16.4358974,4.56410256 Z M13.4358974,4.56410256 L14.4358974,4.56410256 L14.4358974,19.9487179 L13.4358974,19.9487179 L13.4358974,4.56410256 Z M11.4358974,4.56410256 L12.4358974,4.56410256 L12.4358974,19.9487179 L11.4358974,19.9487179 L11.4358974,4.56410256 Z M6.43589744,4.56410256 L7.43589744,4.56410256 L7.43589744,19.9487179 L6.43589744,19.9487179 L6.43589744,4.56410256 Z M15.4358974,4.56410256 L15.9358974,4.56410256 L15.9358974,19.9487179 L15.4358974,19.9487179 L15.4358974,4.56410256 Z M4.43589744,4.56410256 L5.43589744,4.56410256 L5.43589744,19.9487179 L4.43589744,19.9487179 L4.43589744,4.56410256 Z M9.43589744,4.56410256 L10.4358974,4.56410256 L10.4358974,19.9487179 L9.43589744,19.9487179 L9.43589744,4.56410256 Z M8.43589744,4.56410256 L8.93589744,4.56410256 L8.93589744,19.9487179 L8.43589744,19.9487179 L8.43589744,4.56410256 Z M2,2 L6.61538462,2 L6.61538462,3.02564103 L3.02564103,3.02564103 L3.02564103,6.61538462 L2,6.61538462 L2,2 Z M22,2 L22,6.61538462 L20.974359,6.61538462 L20.974359,3.02564103 L17.3846154,3.02564103 L17.3846154,2 L22,2 Z M2,22.5128205 L2,17.8974359 L3.02564103,17.8974359 L3.02564103,21.4871795 L6.61538462,21.4871795 L6.61538462,22.5128205 L2,22.5128205 Z M22,22.5128205 L17.3846154,22.5128205 L17.3846154,21.4871795 L20.974359,21.4871795 L20.974359,17.8974359 L22,17.8974359 L22,22.5128205 Z", id: "icons-tracking" })));
}
