var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function Textfield(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z M4,4 L4,20 L20,20 L20,4 L4,4 Z M9.5,14.8 L14.5,14.8 L15.4,17 L17.5,17 L12.75,6 L11.25,6 L6.5,17 L8.6,17 L9.5,14.8 Z M12,7.98 L13.87,13 L10.13,13 L12,7.98 Z", id: "icons-text-field" })));
}
