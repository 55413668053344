import shortid from "shortid";
export const TEMPLATE_BLANK_ID = "blank";
export var EnumTemplateIcon;
(function (EnumTemplateIcon) {
    EnumTemplateIcon["BLANK"] = "B";
    EnumTemplateIcon["FULL"] = "F";
    EnumTemplateIcon["SIDE_BAR_AND_TITLE"] = "S";
})(EnumTemplateIcon || (EnumTemplateIcon = {}));
export class WorkInstructionsTemplateConfig {
    constructor() {
        this._id = shortid.generate();
        this.color = "#000000";
        this.description = "";
        this.workInstructionElements = [];
        this.icon = EnumTemplateIcon.FULL;
        this.label = "";
    }
}
