import { KortexDialogConfirmation, KortexTextField, theme } from "@aos/react-components";
import { IFailureSubTypeDbModel, IFailureTypeDbModel, TreeNodeDbModel } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";
import { deepClone } from "@kortex/utilities";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    Typography,
    makeStyles,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ArchiveIcon from "@material-ui/icons/Archive";
import EditIcon from "@material-ui/icons/Edit";
import AddPlusIcon from "@material-ui/icons/PlaylistAdd";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesFailureSymptoms, useEntitiesFailureTypes, useEntitiesTreeProcess } from "../../../../../redux/effects";
import { failureSymptomsInsert, failureSymptomsUpdate } from "../../../../../redux/failures-manager/failures-thunks-symptom";
import { failureTypesInsert, failureTypesUpdate } from "../../../../../redux/failures-manager/failures-thunks-type";
import { getParentNodesPath, getParentPathString } from "../../../../../utilitites/getParentNodes";

const useStyles = makeStyles({
    list: {
        width: "80vw",
        display: "flex",
        flexDirection: "column",
        height: "calc(60vh)",
    },
    listGroup: {
        height: "calc(60vh - 40px)",
        maxWidth: "1102px",
        display: "flex",
        flexDirection: "row",
    },
    groupType: {
        display: "flex",
        flexDirection: "column",
        width: "300px",
        padding: "0 20px",
    },
    groupSymptom: {
        display: "flex",
        flexDirection: "column",
        width: "600px",
        padding: "0 20px",
    },
    listOfItem: {
        width: "100%",
        overflowY: "auto",
    },

    itemGroup: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "calc(60vh - 120px)",
        border: `1px solid ${theme.palette.grey[200]}`,
    },
    itemAction: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
    },
    item: {
        width: "100%",
    },
    itemParent: {
        width: "100%",
        color: theme.palette.grey[400],
    },
    smallText: {
        paddingLeft: "10px",
        fontSize: "0.7rem",
    },
    title: {
        paddingLeft: "10px",
    },
    dialogContent: {
        width: "500px",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    textArchived: {
        color: theme.palette.grey[400],
    },
});

interface IOwnProps {
    onClose: () => void;
    opened: boolean;
    treeNode: TreeNodeDbModel;
}

enum FailureSupportedEnum {
    FAILURE_TYPE = "failureType",
    FAILURE_SYMPTOM = "failureSymptom",
}

export default function FailureTypeDialog(props: IOwnProps): JSX.Element {
    const { onClose, opened, treeNode } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();

    const [failureTypes, setFailureTypes] = useState<IFailureTypeDbModel[]>([]);
    const [selectedFailureType, setSelectedFailureType] = useState<IFailureTypeDbModel | undefined>(undefined);
    const [selectedEditFailureType, setSelectedEditFailureType] = useState<IFailureTypeDbModel | undefined>(undefined);

    const [selectedFailureSymptom, setSelectedFailureSymptom] = useState<IFailureSubTypeDbModel | undefined>(undefined);
    const [selectedEditFailureSymptom, setSelectedEditFailureSymptom] = useState<IFailureSubTypeDbModel | undefined>(undefined);
    const [failureSymptoms, setFailureSymptoms] = useState<IFailureSubTypeDbModel[]>([]);

    const [valueToInsert, setValueToInsert] = useState("");
    const [typeToInsert, setTypeToInsert] = useState<FailureSupportedEnum>(FailureSupportedEnum.FAILURE_TYPE);
    const [insertDialogOpen, setInsertDialogOpen] = useState(false);

    const [showArchived, setShowArchived] = useState<boolean>(false);

    const allFailureTypes = useEntitiesFailureTypes();
    const allFailureSymptoms = useEntitiesFailureSymptoms();
    const allNodes = useEntitiesTreeProcess();

    const translate = useTranslate();
    const language = useSelectorLanguage();
    /**
     * Effect triggered when failure types changed in store
     */
    useEffect((): void => {
        const parentNodes = getParentNodesPath(treeNode.treeNodeId, allNodes);
        let nodeFailureTypes: IFailureTypeDbModel[] = [];

        for (const node of parentNodes) {
            const failureTypes = allFailureTypes.filter((failureType) => {
                return failureType.treeNodeId === node.treeNodeId;
            });
            nodeFailureTypes = nodeFailureTypes.concat(failureTypes);
        }
        setFailureTypes(nodeFailureTypes);
    }, [allFailureTypes, showArchived]);

    /**
     * Effect triggered when failure symptoms changed in store or when a failure type is selected
     */
    useEffect((): void => {
        const nodeFailureSymptoms = allFailureSymptoms.filter((symptom) => {
            return symptom.failureTypeId === selectedFailureType?.failureTypeId;
        });

        setFailureSymptoms(nodeFailureSymptoms);
    }, [allFailureSymptoms, selectedFailureType]);

    /**
     * Handle change of failure type
     *
     * @param {IFailureTypeDbModel} failureType - selected failure type
     */
    const handleFailureTypeChange =
        (failureType: IFailureTypeDbModel): (() => void) =>
        (): void => {
            setSelectedFailureSymptom(undefined);
            setSelectedFailureType(failureType);
        };

    /**
     * Handle change of failure symptom
     *
     * @param {IFailureTypeDbModel} failureSymptom - selected failure symptom
     */
    const handleFailureSymptomChange =
        (failureSymptom: IFailureSubTypeDbModel): (() => void) =>
        (): void => {
            setSelectedFailureSymptom(failureSymptom);
        };

    /*** RENAME */

    /**
     * Handle rename of failure type
     *
     * @param {IFailureTypeDbModel} failureType - failure type to rename
     */
    const handleFailureTypeRename =
        (failureType: IFailureTypeDbModel): ((value: string) => void) =>
        (value: string): void => {
            failureType.title = value;
            dispatch(failureTypesUpdate([failureType]));
        };

    /**
     * Handle rename of failure symptom
     *
     * @param {IFailureSubTypeDbModel} failureSymptom - failure symptom to rename
     */
    const handleFailureSymptomRename =
        (failureSymptom: IFailureSubTypeDbModel): ((value: string) => void) =>
        (value: string): void => {
            failureSymptom.title = value;
            dispatch(failureSymptomsUpdate([failureSymptom]));
        };

    /*** EDIT */

    /**
     * Handle edition of failure type.  Flip from Text to Input
     *
     * @param {IFailureTypeDbModel | undefined } failureType - failure type to edit
     */
    const handleEditFailureType =
        (failureType?: IFailureTypeDbModel): (() => void) =>
        (): void => {
            if (failureType) {
                if (!selectedEditFailureType) {
                    setSelectedEditFailureType(failureType);
                } else {
                    setSelectedEditFailureType(undefined);
                }
            } else {
                setSelectedEditFailureType(undefined);
            }
        };

    /**
     * Handle edition of failure symptom.  Flip from Text to Input
     *
     * @param {IFailureTypeDbModel | undefined } failureSymptom - failure symptom to edit
     */
    const handleEditFailureSymptom =
        (failureSymptom?: IFailureSubTypeDbModel): (() => void) =>
        (): void => {
            if (failureSymptom) {
                if (!selectedEditFailureSymptom) {
                    setSelectedEditFailureSymptom(failureSymptom);
                } else {
                    setSelectedEditFailureSymptom(undefined);
                }
            } else {
                setSelectedEditFailureSymptom(undefined);
            }
        };

    /*** INSERTS */

    /**
     * Handle insertion of failure types (one or many).  Many are in CSV format.  This handle the dialog open for types
     */
    const handleInsertFailureType = (): void => {
        /* Setup and open dialog */
        setTypeToInsert(FailureSupportedEnum.FAILURE_TYPE);
        setValueToInsert("");
        setInsertDialogOpen(true);
    };

    /**
     * Handle insertion of failure symptoms (one or many).  Many are in CSV format.  This handle the dialog open for symptoms
     */
    const handleInsertFailureSymptom = (): void => {
        /* Setup and open dialog */
        setTypeToInsert(FailureSupportedEnum.FAILURE_SYMPTOM);
        setValueToInsert("");
        setInsertDialogOpen(true);
    };

    /**
     * Handle insertion of failure types or symptoms (one or many).  Many are in CSV format.
     * Types or symptoms is in typeToInsert, value is in valueToInsert
     */
    const handleInsertDialogAdd = (): void => {
        /* Request to add one element or CVS elements */
        const valuesArrayToInsert: string[] = valueToInsert.split(",");
        switch (typeToInsert) {
            case FailureSupportedEnum.FAILURE_TYPE: {
                /* A reduce is used here to clean up invalid entry from the user */
                const arrayFailureTypesToInsert: IFailureTypeDbModel[] = valuesArrayToInsert.reduce(
                    (accumulator: IFailureTypeDbModel[], value: string) => {
                        const trimValue = value.trim();
                        if (trimValue !== "") {
                            accumulator.push({
                                failureTypeId: 0,
                                archived: false,
                                treeNodeId: treeNode.treeNodeId,
                                title: trimValue,
                            });
                        } // else... empty string, simply drop
                        return accumulator;
                    },
                    []
                );

                dispatch(failureTypesInsert(arrayFailureTypesToInsert));
                break;
            }
            case FailureSupportedEnum.FAILURE_SYMPTOM: {
                if (selectedFailureType) {
                    /* A reduce is used here to clean up invalid entry from the user */
                    const arrayFailureSymptomsToInsert: IFailureSubTypeDbModel[] = valuesArrayToInsert.reduce(
                        (accumulator: IFailureSubTypeDbModel[], value: string) => {
                            const trimValue = value.trim();
                            if (trimValue !== "") {
                                accumulator.push({
                                    failureSubTypeId: 0,
                                    failureTypeId: selectedFailureType?.failureTypeId,
                                    archived: false,
                                    title: trimValue,
                                });
                            } // else... empty string, simply drop
                            return accumulator;
                        },
                        []
                    );
                    dispatch(failureSymptomsInsert(arrayFailureSymptomsToInsert));
                } else {
                    console.error("When inserting a symptom, a failure type shall be selected");
                }
                break;
            }
            default:
                console.error("Unsupported failure Type");
                break;
        }
    };

    /**
     * Handle insertion of failure types or symptoms (one or many).  Many are in CSV format.
     * Types or symptoms is in typeToInsert, value is in valueToInsert
     */
    /* As requested, disable for now
    const handleInsertDialogAddSpecial = async (): Promise<void> => {
        // Request to add one element or CVS elements
        const valuesArrayToInsert: string[] = valueToInsert.split(",");
        let newFailureTypes: IFailureTypeDbModel[] = [];

        // Retreive list of types to add (separated by -)
        const arrayFailureTypesToInsert: IFailureTypeDbModel[] = valuesArrayToInsert.reduce(
            (accumulator: IFailureTypeDbModel[], value: string) => {
                const sectionsOfItem: string[] = value.split("-");
                if (sectionsOfItem.length === 2) {
                    // Shall have 2 value separated by -... otherwise, reject
                    const newType = sectionsOfItem[0].trim();
                    if (accumulator.find((type) => type.title === newType) === undefined) {
                        accumulator.push({
                            failureTypeId: 0,
                            archived: false,
                            treeNodeId: treeNode.treeNodeId,
                            title: newType,
                        });
                    }
                }
                return accumulator;
            },
            []
        );

        // Dispatch creation of all new types
        if (arrayFailureTypesToInsert.length > 0) {
            newFailureTypes = await dispatch(failureTypesInsert(arrayFailureTypesToInsert));
        }

        // Add symptoms for each types
        if (newFailureTypes.length > 0) {
            // A reduce is used here to clean up invalid entry from the user
            const arrayFailureSymptomsToInsert: IFailureSubTypeDbModel[] = valuesArrayToInsert.reduce(
                (accumulator: IFailureSubTypeDbModel[], value: string) => {
                    const sectionsOfItem: string[] = value.split("-");
                    if (sectionsOfItem.length === 2) {
                        // Shall have 2 value separated by -... otherwise, reject
                        const itemType = sectionsOfItem[0].trim();
                        const itemSymptom = sectionsOfItem[1].trim();
                        // Find de failureType
                        const failureType = newFailureTypes.find((type) => type.title === itemType);
                        if (failureType) {
                            accumulator.push({
                                failureSubTypeId: 0,
                                failureTypeId: failureType.failureTypeId,
                                archived: false,
                                title: itemSymptom,
                            });
                        }
                    }
                    return accumulator;
                },
                []
            );
            dispatch(failureSymptomsInsert(arrayFailureSymptomsToInsert));
        }
    };
    */

    /**
     * Handle close of insertion dialog.
     */
    const handleInsertDialogClose = (): void => {
        setInsertDialogOpen(false);
    };

    /**
     * Handle change of possible value for types or symptoms
     *
     * @param {string} value - new value to store
     *
     */
    const onInsertItemValueChanged = (value: string): void => {
        setValueToInsert(value);
    };

    /**
     * Handle Archive/UnArchive of failure type
     */
    const handleArchiveFailureType = (): void => {
        if (selectedFailureType) {
            const failureTypeToUpdate = deepClone(selectedFailureType);
            failureTypeToUpdate.archived = !selectedFailureType.archived;
            dispatch(failureTypesUpdate([failureTypeToUpdate]));
            if (failureTypeToUpdate.archived && !showArchived) {
                setSelectedFailureType(undefined);
            }
        }
    };

    /**
     * Handle Archive/UnArchive of failure symptom
     */
    const handleArchiveFailureSymptom = (): void => {
        if (selectedFailureSymptom) {
            const symptomToUpdate = deepClone(selectedFailureSymptom);
            symptomToUpdate.archived = !selectedFailureSymptom.archived;
            dispatch(failureSymptomsUpdate([symptomToUpdate]));
            if (symptomToUpdate.archived && !showArchived) {
                setSelectedFailureSymptom(undefined);
            }
        }
    };

    /**
     * Handle select/unselect of the option show archive
     */
    const handleOptionShowArchived = (): void => {
        setShowArchived(!showArchived);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={true}
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: onClose,
            }}
            onCancel={onClose}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("failureTypeDialog.close"),
                titleLabel: `${translate("failureTypeDialog.failureTypesFor")} ${treeNode.label}`,
            }}
        >
            <div className={classes.list}>
                <div className={classes.listGroup}>
                    <div className={classes.groupType}>
                        <Typography variant={"h6"}>{translate("failureTypeDialog.failureTypes")}</Typography>
                        <div className={classes.itemGroup}>
                            <List className={classes.listOfItem}>
                                {failureTypes
                                    .filter((failureType) => failureType.treeNodeId === treeNode.treeNodeId)
                                    .filter((failureType) => showArchived || !failureType.archived)
                                    .sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: "base" }))
                                    .map((failureType): JSX.Element => {
                                        return selectedEditFailureType === failureType ? (
                                            <KortexTextField
                                                InputProps={{
                                                    autoFocus: true,
                                                }}
                                                value={failureType.title}
                                                key={failureType.failureTypeId}
                                                onChanged={handleFailureTypeRename(failureType)}
                                                variant={"standard"}
                                                onBlur={handleEditFailureType(undefined)}
                                            />
                                        ) : (
                                            <ListItem
                                                button
                                                className={classes.item}
                                                selected={failureType.failureTypeId === selectedFailureType?.failureTypeId}
                                                key={failureType.failureTypeId}
                                                onClick={handleFailureTypeChange(failureType)}
                                                onDoubleClick={handleEditFailureType(failureType)}
                                            >
                                                <ListItemText
                                                    primary={failureType.title}
                                                    className={failureType.archived ? classes.textArchived : ""}
                                                />
                                            </ListItem>
                                        );
                                    })}
                                <Divider />
                                {/* PARENT NODE FAILURE TYPES */}
                                {failureTypes
                                    .filter((failureType) => failureType.treeNodeId !== treeNode.treeNodeId)
                                    .filter((failureType) => showArchived || !failureType.archived)
                                    .sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: "base" }))
                                    .map((failureType): JSX.Element => {
                                        return (
                                            <ListItem
                                                button
                                                className={classes.itemParent}
                                                selected={failureType.failureTypeId === selectedFailureType?.failureTypeId}
                                                key={failureType.failureTypeId}
                                                onClick={handleFailureTypeChange(failureType)}
                                            >
                                                <ListItemText
                                                    primary={failureType.title}
                                                    secondary={getParentPathString(failureType.treeNodeId, allNodes)}
                                                />
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        </div>
                        <div className={classes.itemAction}>
                            <IconButton id="insertManyFailureTypeId" color="primary" onClick={handleInsertFailureType}>
                                <AddPlusIcon />
                            </IconButton>
                            <IconButton
                                id="editFailureTypeId"
                                color="primary"
                                onClick={handleEditFailureType(selectedFailureType)}
                                disabled={!selectedFailureType || selectedFailureType.treeNodeId !== treeNode.treeNodeId}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                id="archiveFailureTypeId"
                                color="primary"
                                onClick={handleArchiveFailureType}
                                disabled={!selectedFailureType || selectedFailureType.treeNodeId !== treeNode.treeNodeId}
                            >
                                {selectedFailureType && !selectedFailureType.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.groupSymptom}>
                        <Typography variant={"h6"}>{translate("failureTypeDialog.failureSymptoms")}</Typography>
                        <div className={classes.itemGroup}>
                            <List className={classes.listOfItem}>
                                {failureSymptoms
                                    .filter((symptom) => showArchived || !symptom.archived)
                                    .sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: "base" }))
                                    .map((symptom): JSX.Element => {
                                        return selectedEditFailureSymptom === symptom ? (
                                            <KortexTextField
                                                value={symptom.title}
                                                key={symptom.failureSubTypeId}
                                                onChanged={handleFailureSymptomRename(symptom)}
                                                variant={"standard"}
                                            />
                                        ) : (
                                            <ListItem
                                                button
                                                className={classes.item}
                                                selected={symptom.failureSubTypeId === selectedFailureSymptom?.failureSubTypeId}
                                                key={symptom.failureSubTypeId}
                                                onClick={handleFailureSymptomChange(symptom)}
                                                onDoubleClick={handleEditFailureSymptom(symptom)}
                                            >
                                                <ListItemText
                                                    primary={symptom.title}
                                                    className={symptom.archived ? classes.textArchived : ""}
                                                />
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        </div>
                        <div className={classes.itemAction}>
                            <IconButton
                                id="insertManyFailureSymptomId"
                                color="primary"
                                onClick={handleInsertFailureSymptom}
                                disabled={!selectedFailureType || selectedFailureType.treeNodeId !== treeNode.treeNodeId}
                            >
                                <AddPlusIcon />
                            </IconButton>
                            <IconButton
                                id="editFailureSymptomId"
                                color="primary"
                                onClick={handleEditFailureSymptom(selectedFailureSymptom)}
                                disabled={
                                    !selectedFailureSymptom ||
                                    !selectedFailureType ||
                                    selectedFailureType.treeNodeId !== treeNode.treeNodeId
                                }
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton
                                id="archiveFailureSymptomId"
                                color="primary"
                                onClick={handleArchiveFailureSymptom}
                                disabled={
                                    !selectedFailureSymptom ||
                                    !selectedFailureType ||
                                    selectedFailureType.treeNodeId !== treeNode.treeNodeId
                                }
                            >
                                {selectedFailureSymptom && !selectedFailureSymptom.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className={classes.itemAction}>
                    <div className={classes.optionsItem}>
                        <Typography>{`${translate("failureTypeDialog.optionShowArchived")} `}</Typography>
                        <Checkbox checked={showArchived} onChange={handleOptionShowArchived} />
                    </div>
                </div>
            </div>
            <Dialog open={insertDialogOpen} disableAutoFocus={true} fullWidth={false}>
                <DialogContent>
                    <div>
                        <Typography className={classes.title} variant="h5">
                            {translate(
                                typeToInsert === FailureSupportedEnum.FAILURE_TYPE
                                    ? "failureTypeDialog.failureTypes"
                                    : "failureTypeDialog.failureSymptoms"
                            ) + translate("failureTypeDialog.toInsert")}
                        </Typography>
                        <KortexTextField
                            className={classes.dialogContent}
                            TextFieldProps={{
                                id: "insertItemId",
                            }}
                            onChanged={onInsertItemValueChanged}
                            value={valueToInsert}
                        />
                    </div>
                    <div className={classes.smallText}>{translate("failureTypeDialog.addInstructions")}</div>
                </DialogContent>
                <DialogActions>
                    {/* As requested, disable for now}
                    <Button
                        id="addSpecialButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleInsertDialogAddSpecial}
                        className={classes.dialogButtons}
                    >
                        <Typography>{translate("failureTypeDialog.addSpecial")}</Typography>
                    </Button>
                        */}
                    <Button id="addButtonId" variant="contained" color="secondary" onClick={handleInsertDialogAdd}>
                        <Typography>{translate("failureTypeDialog.add")}</Typography>
                    </Button>
                    <Button id="closeButtonId" variant="contained" color="secondary" onClick={handleInsertDialogClose}>
                        <Typography>{translate("failureTypeDialog.close")}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </KortexDialogConfirmation>
    );
}
