import { KortexTextField } from "@aos/react-components";
import { ProcessActionStepStopProcess } from "@kortex/aos-common";
import { ListItem, Paper, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import { IActionStepProps } from "../IActionStepProps";

const useStyles = makeStyles({
    root: {
        height: "100%",
        padding: "16px",
    },
    textField: {
        width: "240px",
    },
});

export default function StopProcessEditor(props: IActionStepProps<ProcessActionStepStopProcess>): JSX.Element {
    const { actionStepProps, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [triggerRework, setTriggerRework] = useState(actionStepProps.config.triggerRework);

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Updates "Trigger Rework" internal state if component props are updated
     */
    useEffect((): void => {
        setTriggerRework(actionStepProps.config.triggerRework);
    }, [actionStepProps.config.triggerRework]);

    /**
     * Handle update of the trigger rework option
     *
     */
    const handleTriggerReworkChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const triggerRework = event.target.value === "true";
        props.onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                triggerRework,
            },
        });
    };

    return (
        <Paper className={classes.root}>
            <KortexTextField
                className={classes.textField}
                onChange={handleTriggerReworkChange}
                TextFieldProps={{
                    id: "triggerReworkId",
                    select: true,
                    disabled: readOnly,
                }}
                value={String(triggerRework)}
                variant="outlined"
                label={translate("action.stop.process.trigger.rework")}
            >
                <ListItem value={"true"}>{translate("action.stop.process.yes")}</ListItem>
                <ListItem value={"false"}>{translate("action.stop.process.no")}</ListItem>
            </KortexTextField>
        </Paper>
    );
}
