import { ActionTypeEnum, FailureCodeEnum, FailureSourceEnum, SequencerActionPlayCompletedPayloadTypeEnum, SequencerActionStatusEnum, } from "../../../enums";
import { CoreActionConfigError, } from "../../../interfaces";
import { localeKeys } from "../../../locales";
import { KortexSequencerDebugger, deepClone, defer, isNullOrUndefined, isPromiseStarted } from "../../../utilities";
import { interpolateActionConfig } from "../../../utilities/interpolate-action-config";
const debug = KortexSequencerDebugger.core.wait.time;
export const SEQUENCER_ACTION_WAIT_TIME_DEFAULT_STATE = {
    remainingMilliseconds: 0,
};
function create({ variableManager, variableNamespace }) {
    let _paused = false, _play, _config, _start, _state = { ...SEQUENCER_ACTION_WAIT_TIME_DEFAULT_STATE }, _stateChanged = async () => void 0, _timeout;
    function _fail(failure) {
        debug("fail:%o", failure);
        return _play?.resolve({
            payload: failure,
            status: SequencerActionStatusEnum.FAILED,
        });
    }
    function _waitTime() {
        debug("play:starting timer...");
        _start = new Date().getTime();
        _timeout = setTimeout(() => {
            debug("play:completed:time over");
            _play.resolve({
                payload: {
                    output: null,
                    type: SequencerActionPlayCompletedPayloadTypeEnum.NEXT,
                },
                status: SequencerActionStatusEnum.COMPLETED,
            });
        }, _state.remainingMilliseconds);
    }
    function assertValidConfig(config) {
        debug("config validation:%o", config);
        if (isNullOrUndefined(config.milliseconds) || config.milliseconds < 0) {
            debug("config validation:error:time to wait is below 0");
            throw new CoreActionConfigError(localeKeys.action.core.waitTime.validateInvalidMilliseconds, "Config validation error: time to wait is below 0.");
        }
        debug("config validation:successful");
    }
    async function onStateChange(cb) {
        debug("on state change:callback saved");
        _stateChanged = cb;
    }
    async function pause() {
        debug("pause");
        if (!isPromiseStarted(_play)) {
            debug("pause:error:action is not playing");
            return void 0;
        }
        clearTimeout(_timeout);
        const done = new Date().getTime();
        setState({ remainingMilliseconds: _state.remainingMilliseconds - (done - _start) });
        _paused = true;
        debug("pause:action is paused");
    }
    async function play(config) {
        debug("play:%o", config);
        if (isPromiseStarted(_play)) {
            debug("play:fail:action is already in progress");
            return {
                payload: {
                    action: {
                        state: {},
                    },
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.action.core.waitTime.playAlreadyInProgress,
                    source: FailureSourceEnum.ACTION,
                },
                status: SequencerActionStatusEnum.FAILED,
            };
        }
        _config = interpolateActionConfig(config, variableManager.getAllVars(variableNamespace));
        _state = { remainingMilliseconds: _config.milliseconds };
        clearTimeout(_timeout);
        _play = defer(_waitTime);
        return _play.promise;
    }
    async function resume() {
        debug("resume");
        if (!(_paused && isPromiseStarted(_play))) {
            debug("resume:error:action is not paused");
            return void 0;
        }
        _paused = false;
        debug("resume:action resumed");
        _waitTime();
    }
    async function setState(state, feedback, triggerOnStateChange = true) {
        debug("set state:%o", state);
        const previousState = deepClone(_state);
        const notifyChange = triggerOnStateChange &&
            state.remainingMilliseconds !== undefined &&
            state.remainingMilliseconds !== _state.remainingMilliseconds;
        _state = {
            ..._state,
            ...state,
        };
        if (notifyChange) {
            debug("set state:state is changed, trigger state changed callback");
            _stateChanged({
                feedback,
                previousState,
                state: _state,
            });
        }
        return {
            status: SequencerActionStatusEnum.CONTINUE,
            payload: _state,
        };
    }
    async function stop(causeFailure) {
        debug("stop");
        clearTimeout(_timeout);
        const done = new Date().getTime();
        if (causeFailure) {
            return _fail({
                code: FailureCodeEnum.STOP,
                action: {
                    state: {
                        remainingMilliseconds: _state.remainingMilliseconds - (done - _start),
                    },
                },
                key: localeKeys.action.core.waitTime.stop,
                source: FailureSourceEnum.ACTION,
            });
        }
        return _play?.resolve({
            payload: null,
            status: SequencerActionStatusEnum.STOPPED,
        });
    }
    return {
        get state() {
            return deepClone(_state);
        },
        get type() {
            return ActionTypeEnum.WAIT_TIME;
        },
        assertValidConfig,
        onStateChange,
        pause,
        play,
        resume,
        stop,
        setState,
    };
}
export const SequencerActionWaitTimeFactory = {
    create,
};
