import { KortexTextField } from "@aos/react-components";
import { ConnectorFileConfig, EnumFileAction, EnumFileType, ProcessEditorRightsEnum } from "@kortex/aos-common";
import { MenuItem, makeStyles } from "@material-ui/core";
import * as React from "react";

import { useTranslate } from "../../../../../../../../hooks/useTranslate";
import { IUserRightsProps, userCanWrite } from "../../../../../../../../utilitites/IUserRights";
import VariablePicker from "../../../../../../../core/VariablePicker/VariablePicker";

const WRITE_DATA_ROW_COUNT = 25;

const useStyles = makeStyles({
    fileAction: {
        marginRight: "32px",
        marginTop: "32px",
        width: "120px",
    },
    filePathTextField: {
        flex: 1,
        marginTop: "32px",
    },
    fileType: {
        marginTop: "32px",
        width: "120px",
    },
    root: {
        display: "grid",
    },
    row: {
        alignItems: "flex-end",
        display: "inline-flex",
    },
    writeDataTextField: {
        marginTop: "32px",
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    onChange: (actionStepProps: ConnectorFileConfig) => void;
    fileProps: ConnectorFileConfig;
}

export default function ConnectorFileEditor(props: IOwnProps): JSX.Element {
    const { fileProps, onChange, userAccessLevel } = props;
    const { action, filePath, fileType, writeData } = fileProps;

    const classes = useStyles();
    const translate = useTranslate();

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Called when action type is changed
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - change event data
     */
    const handleActionChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChange({
            ...fileProps,
            action: event.target.value as EnumFileAction,
        });
    };

    /**
     * Called when file path textfield is blurred
     */
    const handleFilePathBlur = (value: string): void => {
        if (filePath !== value) {
            onChange({ ...fileProps, filePath: value });
        }
    };

    /**
     * Called when write value textfield is blurred
     */
    const handleWriteValueBlur = (value: string): void => {
        if (writeData !== value) {
            onChange({ ...fileProps, writeData: value });
        }
    };

    /**
     * Called when file type is changed
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - change event data
     */
    const handleFileTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChange({ ...fileProps, fileType: event.target.value as EnumFileType });
    };

    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <KortexTextField
                    className={classes.fileAction}
                    label={translate("action.connector.file.action")}
                    onChange={handleActionChange}
                    TextFieldProps={{
                        disabled: readOnly,
                        id: "connector-file-editor-file-action-select",
                        select: true,
                    }}
                    value={action}
                >
                    <MenuItem value={EnumFileAction.READ}>{translate("action.connector.file.action.read")}</MenuItem>
                    <MenuItem value={EnumFileAction.WRITE}>{translate("action.connector.file.action.write")}</MenuItem>
                </KortexTextField>
                <VariablePicker
                    onBlur={handleFilePathBlur}
                    KortexTextFieldProps={{
                        className: classes.filePathTextField,
                        label: translate("action.connector.file.filePath"),
                    }}
                    userAccessLevel={userAccessLevel}
                    value={filePath}
                />
            </div>
            {action === EnumFileAction.READ && (
                <div className={classes.row}>
                    <KortexTextField
                        className={classes.fileType}
                        label={translate("action.connector.file.action.read.fileType")}
                        onChange={handleFileTypeChange}
                        TextFieldProps={{
                            disabled: readOnly,
                            id: "connector-file-editor-file-type-select",
                            select: true,
                        }}
                        value={fileType}
                    >
                        <MenuItem value={EnumFileType.BINARY}>{translate("action.connector.file.action.read.fileType.binary")}</MenuItem>
                        <MenuItem value={EnumFileType.TEXT}>{translate("action.connector.file.action.read.fileType.text")}</MenuItem>
                    </KortexTextField>
                </div>
            )}
            {action === EnumFileAction.WRITE && (
                <VariablePicker
                    onBlur={handleWriteValueBlur}
                    KortexTextFieldProps={{
                        className: classes.writeDataTextField,
                        label: translate("action.connector.file.action.write.data"),
                        TextFieldProps: {
                            multiline: true,
                            rows: WRITE_DATA_ROW_COUNT,
                        },
                    }}
                    userAccessLevel={userAccessLevel}
                    value={writeData}
                />
            )}
        </div>
    );
}
