import {
    PlayerActionType,
    PlayerEnablePlayNextAction,
    PlayerInitStateAction,
    PlayerUpdateActionStateAction,
    PlayerUpdateHistoryAction,
    PlayerUpdateHubConnectionAction,
    PlayerUpdateProcessStateAction,
    PlayerUpdateUiActionPropsAction,
} from "./player-types";

export function enablePlayNextAction(enabled: PlayerEnablePlayNextAction["value"]): PlayerEnablePlayNextAction {
    return { type: PlayerActionType.ENABLE_PLAY_NEXT, value: enabled };
}

export function initStateAction(state: PlayerInitStateAction["value"] = {}): PlayerInitStateAction {
    return { type: PlayerActionType.INIT_STATE, value: state };
}

export function updateActionStateAction(processState: PlayerUpdateActionStateAction["value"]): PlayerUpdateActionStateAction {
    return { type: PlayerActionType.UPDATE_ACTION_STATE, value: processState };
}

export function updateHistoryAction(history: PlayerUpdateHistoryAction["value"]): PlayerUpdateHistoryAction {
    return { type: PlayerActionType.SET_HISTORY, value: history };
}

export function updateHubConnectionStatusAction(hubConnection: PlayerUpdateHubConnectionAction["value"]): PlayerUpdateHubConnectionAction {
    return { type: PlayerActionType.SET_HUB_CONNECTION, value: hubConnection };
}

export function updateProcessStateAction(processState: PlayerUpdateProcessStateAction["value"]): PlayerUpdateProcessStateAction {
    return { type: PlayerActionType.UPDATE_PROCESS_STATE, value: processState };
}

export function updateUiActionPropsAction(uiActionProps: PlayerUpdateUiActionPropsAction["value"]): PlayerUpdateUiActionPropsAction {
    return { type: PlayerActionType.SET_UI_ACTION_PROPS, value: uiActionProps };
}
