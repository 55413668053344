// krc
import { theme } from "@aos/react-components";
import { IReworkUiModel, ITokenDecoded, ReworkStatusEnum } from "@kortex/aos-common";
// mui
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ProcessSchedulerIcon from "@material-ui/icons/TodayOutlined";
// react
import React, { useEffect, useState } from "react";

import { userCanInsert } from "../../../../utilitites/IUserRights";

import CreateReworkJobDialog from "./DialogJob/CreateReworkJobDialog";

const useStyles = makeStyles({
    buttons: {
        display: "flex",
        flexDirection: "column",
    },
    button: {
        marginBottom: "5px",
        display: "flex",
        justifyContent: "flex-start",
    },
    scheduleIcon: {
        color: theme.palette.primary[500],
        width: "30px",
        height: "30px",
    },
});

interface IOwnProps {
    rework: IReworkUiModel;
    userInfo?: ITokenDecoded;

    onDelete: (rework: IReworkUiModel) => void;
    onClose: () => void;
}

export default function FailureTicketDetailsButtons(props: IOwnProps): JSX.Element {
    const { rework, userInfo } = props;
    const classes = useStyles();

    const [manualFailureTicket, setManualFailureTicket] = useState<boolean>(false);
    const [createJobDialogOpen, setCreateJobDialogOpen] = useState<boolean>(false);

    /**
     * failure ticket is manual failure ticket
     */
    useEffect((): void => {
        setManualFailureTicket(!rework.originalResultMasterId);
    }, [rework]);

    /**
     * toggle the dialog
     */
    const toggleDialog = (): void => {
        props.onClose();
    };

    /**
     * Create a job for the selected rework
     */
    const handleScheduleRework = (): void => {
        setCreateJobDialogOpen(true);
    };

    /**
     * handles the delete rework action
     */
    const handleDeleteRework = (): void => {
        props.onDelete(rework);
    };

    /**
     * handle request to close the CreateReworkJobDialog
     */
    const handleCreateJobDialogClose = (): void => {
        setCreateJobDialogOpen(false);
    };

    return (
        <div>
            {/* Failure Ticket Buttons */}
            <div className={classes.buttons}>
                <IconButton onClick={toggleDialog} id={"buttonCloseFailureDetailId"}>
                    <CloseIcon />
                </IconButton>
                {userCanInsert(userInfo?.roleRights.rework) && (
                    <div>
                        {manualFailureTicket &&
                            !rework.jobRework &&
                            (rework.reworkStatus === ReworkStatusEnum.NEW || rework.reworkStatus === ReworkStatusEnum.INPRG) && (
                                <IconButton onClick={handleScheduleRework} id={"buttonScheduleReworkId"}>
                                    <ProcessSchedulerIcon className={classes.scheduleIcon} />
                                </IconButton>
                            )}

                        {manualFailureTicket && !rework.jobRework && (
                            <IconButton onClick={handleDeleteRework} id={"buttonDeleteFailureTicketId"}>
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </div>
                )}
            </div>

            {/* Dialog to create rework job */}
            <CreateReworkJobDialog open={createJobDialogOpen} rework={rework} onClose={handleCreateJobDialogClose} />
        </div>
    );
}
