import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    outputContainer: {
        height: "-webkit-fill-available",
        padding: "32px",
        width: "-webkit-fill-available",
    },
    message: {
        marginBottom: "32px",
        textAlign: "center",
    },
    title: {
        marginBottom: "32px",
    },
});

export default function FailPathPlayer(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.outputContainer}>
            <Typography className={classes.title} variant="h1">
                {translate("player.failPathTitle")}
            </Typography>
            <Typography className={classes.message} variant="h3">
                {translate("player.failPathMessage")}
            </Typography>
        </div>
    );
}
