import shortid from "shortid";
export var EnumFontStyles;
(function (EnumFontStyles) {
    EnumFontStyles[EnumFontStyles["NORMAL"] = 0] = "NORMAL";
    EnumFontStyles[EnumFontStyles["HEADER1"] = 1] = "HEADER1";
    EnumFontStyles[EnumFontStyles["HEADER2"] = 2] = "HEADER2";
    EnumFontStyles[EnumFontStyles["HEADER3"] = 3] = "HEADER3";
    EnumFontStyles[EnumFontStyles["SMALL"] = 4] = "SMALL";
    EnumFontStyles[EnumFontStyles["TITLE"] = 5] = "TITLE";
})(EnumFontStyles || (EnumFontStyles = {}));
export var EnumHorizontalAlignment;
(function (EnumHorizontalAlignment) {
    EnumHorizontalAlignment["LEFT"] = "left";
    EnumHorizontalAlignment["CENTER"] = "center";
    EnumHorizontalAlignment["RIGHT"] = "right";
})(EnumHorizontalAlignment || (EnumHorizontalAlignment = {}));
export var EnumVerticalAlignment;
(function (EnumVerticalAlignment) {
    EnumVerticalAlignment["TOP"] = "top";
    EnumVerticalAlignment["MIDDLE"] = "middle";
    EnumVerticalAlignment["BOTTOM"] = "bottom";
})(EnumVerticalAlignment || (EnumVerticalAlignment = {}));
export class WorkInstructionsTextConfig {
    constructor() {
        this.backgroundColor = "#FFFFFFFF";
        this.border = "0px";
        this.borderColor = "#000000";
        this.fontStyle = EnumFontStyles.NORMAL;
        this.horizontalAlignment = EnumHorizontalAlignment.LEFT;
        this.padding = "0px";
        this.textColor = "#000000";
        this.textLoc = { id: shortid.generate(), defaultMessage: "" };
        this.verticalAlignment = EnumVerticalAlignment.TOP;
    }
}
