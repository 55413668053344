import React, { useEffect, useState } from "react";

interface IPreviewPaneImageProps<Container = HTMLDivElement> {
    containerRef: React.MutableRefObject<Container | null>;
    src: string;
}

export default function PreviewPaneImage(props: IPreviewPaneImageProps): JSX.Element {
    const { containerRef, src } = props;

    const [height, setHeight] = useState<number>(0);
    const [width, setWidth] = useState<number>(0);

    // Effect that preloads the image and resize it if it is larger than the maximum height and width
    useEffect((): void => {
        setHeight(0);
        setWidth(0);

        if (!containerRef.current) return void 0; // Do nothing if parent container does not exist
        if (!src) return void 0; // Reset image if no src is provided

        // Create image and trigger event when loaded
        const image = new Image();

        image.onload = (): void => {
            // Adjust image size
            const imageRatio = image.naturalWidth / image.naturalHeight;
            const { height: maxHeight = 0, width: maxWidth = 0 } = containerRef.current ? containerRef.current.getBoundingClientRect() : {};

            let finalWidth = image.naturalWidth;
            let finalHeight = image.naturalHeight;

            if (maxWidth && finalWidth > maxWidth) {
                finalWidth = maxWidth;
                finalHeight = finalWidth / imageRatio;
            }
            if (maxHeight && finalHeight > maxHeight) {
                finalHeight = maxHeight;
                finalWidth = finalHeight * imageRatio;
            }

            setHeight(finalHeight);
            setWidth(finalWidth);
        };

        // Load image
        image.src = src;
    }, [containerRef, src]);

    return <img src={src} height={height} width={width} />;
}
