import { IOperatorJobProcessInfo } from "@kortex/aos-common";

/**
 * Return true if process can be played
 *
 * @param {IOperatorJobProcessInfo} jobProcess - jobProcess info
 *
 * @returns {boolean} - true if can be played
 */
export function canPlayProcessFromJobProcess(
    jobProcess: IOperatorJobProcessInfo | undefined,
    dryRunMode: boolean,
    testRunMode: boolean
): boolean {
    if (dryRunMode || testRunMode) {
        return true;
    }

    if (!jobProcess) {
        return false;
    }

    if (jobProcess.qtyPassed === 0 && jobProcess.qty === 0) {
        return false;
    }

    if (jobProcess.qtyPassed >= jobProcess.qty) {
        return false;
    }
    return true;
}
