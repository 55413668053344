import shortid from "shortid";
//--------------------------------------------------------------
// Action link
//--------------------------------------------------------------
export class ActionOutputLink {
    constructor(label) {
        this.id = shortid.generate();
        this.label = "";
        this.remoteIds = [];
        this.label = label;
    }
}
//--------------------------------------------------------------
// Utilities
//--------------------------------------------------------------
/**
 * Creates a new process action
 */
export function newProcessActionDbModel(processId, type) {
    let outputs;
    let inputs;
    // Setup Inputs
    switch (type) {
        case "core-fail":
        case "core-routing-fail":
        case "core-input":
            inputs = [];
            break;
        default:
            inputs = [new ActionOutputLink("A")];
            break;
    }
    // Setup Outputs
    switch (type) {
        case "core-output":
        case "core-stop-process":
            outputs = [];
            break;
        case "core-condition":
        case "core-loop":
            outputs = [new ActionOutputLink("A"), new ActionOutputLink("B")];
            break;
        default:
            outputs = [new ActionOutputLink("A")];
            break;
    }
    return {
        processActionId: 0,
        label: "",
        inputs,
        outputs,
        posX: type === "core-input" ? -150 : 150,
        posY: 18,
        processId,
        type,
    };
}
