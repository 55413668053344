import { KortexOutlinedTextField, KortexTextField, greyPalette, theme } from "@aos/react-components";
import {
    EnumActionStatus,
    IActionBaseState,
    IFailureTypeDbModel,
    IOperatorJobProcessInfo,
    IProcessUiModel,
    IResultFailProblemModel,
    IReworkInfo,
    IReworkItemStatusDbModel,
    IStepFailure,
    ITreeNodeDbModel,
    ReworkYieldType,
} from "@kortex/aos-common";
import NotificationsCenterDialog from "@kortex/aos-ui/components/pages/NotificationsCenter/NotificationsCenterDialog";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, Fab, MenuItem, Typography, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EndIcon from "@material-ui/icons/NotInterested";
import RemoveIcon from "@material-ui/icons/Remove";
import SearchIcon from "@material-ui/icons/Search";
import WarningIcon from "@material-ui/icons/Warning";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import {
    useEntitiesFailureSymptoms,
    useEntitiesFailureTypes,
    useEntitiesReworkItemStatus,
    useEntitiesTreeProcess,
} from "../../../../../redux/effects";
import { IPlayerState } from "../../../../../redux/player-manager/player-types";
import { reworkGetInfoBeforeCreate } from "../../../../../redux/rework-manager/rework-thunks";
import { getParentNodesPath } from "../../../../../utilitites/getParentNodes";
import YieldTypeSelector from "../../../../core/YieldTypeSelector/YieldTypeSelector";
import ProcessIcon from "../../../Icons/Process/Process";
import KortexLabelText from "../../../KortexLabelText";
import ProcessPicker from "../../../ProcessPicker/ProcessPicker";
import RunDetailsDialogContent from "../RunDetailsDialog/RunDetailsDialogContent";

const useStyles = makeStyles({
    root: {},
    dialogTitle: {
        display: "grid",
        gridAutoColumns: "10% 85% 5%",
        gridAutoFlow: "column",
    },
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    mainIcon: {
        fontSize: "40px",
        marginRight: "20px",
        color: theme.palette.error[500],
    },
    buttonIcon: {
        paddingRight: "10px",
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 20px",
    },
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
    },
    buttonIcons: {
        marginRight: "10px",
    },
    mainForm: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
    },
    failure: {
        marginTop: "15px",
        backgroundColor: theme.palette.grey.A100,
        padding: "10px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
    },
    failureRow: {
        display: "flex",
        flexDirection: "row",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    failureBatch: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10px",
        paddingRight: "10px",
        alignItems: "center",
    },
    deleteIcon: {
        position: "absolute",
        top: "-5px",
        right: "-10px",
    },
    problemContainer: {
        display: "flex",
        marginTop: "20px",
        width: "100%",
    },
    problems: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    problem: {
        flexGrow: 1,
        display: "flex",
        marginTop: "15px",
        width: "100%",
    },
    addIcon: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.grey[500],
        width: "60px",
        cursor: "pointer",
    },
    removeIcon: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.grey[500],
        width: "60px",
        cursor: "pointer",
    },
    text: {
        margin: "5px 0px",
        color: theme.palette.primary[500],
    },
    description: {
        margin: "15px 0px 5px 0px",
        color: theme.palette.primary[500],
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: "15px",
        maxHeight: "200px",
        overflowY: "auto",
        marginBottom: "20px",
    },
    box: {
        margin: "15px 0px 5px 0px",
        color: theme.palette.primary[500],
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: "15px",
        marginBottom: "20px",
    },
    boxRow: {
        display: "flex",
        alignItems: "center",
    },
    boxRowTitle: {
        display: "flex",
        margin: "5px 0px 20px 10px",
    },
    select: {
        width: "200px",
        margin: "5px 15px 0px 0px",
    },
    comments: {
        flexGrow: 1,
        margin: "5px 5px 0px 0px",
    },
    processName: {
        margin: "0px 10px",
    },
    error: {
        marginTop: "20px",
        color: theme.palette.error[500],
    },
    endIcon: {
        color: theme.palette.grey[400],
        paddingLeft: "10px",
    },
    fieldContainer: {
        padding: "10px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    yieldSelectorContainer: {
        display: "flex",
        flexDirection: "row",
        padding: "5px 0",
    },
});

interface IDefaultProblem extends IResultFailProblemModel {
    valid: boolean;
}

const defaultProblem: IDefaultProblem = {
    resultMasterId: 0,
    failureTypeId: 0,
    failureSubTypeId: 0,
    comments: "",
    valid: false,
};

enum UIReportDisplayEnum {
    DISPLAY = "D",
    FORM = "F",
}

interface IOwnProps {
    // Own Props
    canRetry: boolean;
    dryRunMode: boolean;
    open: boolean;
    actionBaseState?: IActionBaseState;
    processTreeNodeId?: number;
    maxFailQty: number;
    playerState?: IPlayerState;
    process?: IProcessUiModel;
    jobProcessInfo?: IOperatorJobProcessInfo;
    reworkId?: number;
    runWithoutJobProcess?: boolean;
    manualFailure?: boolean;

    onRetry?: () => void;
    onSendReport?: (failures: IStepFailure[], reworkInfo?: IReworkInfo) => void;
    onClose?: () => void;
    onCancel?: () => void;
}

export default function FailedDialog(props: IOwnProps): JSX.Element {
    const { actionBaseState, dryRunMode, open, maxFailQty, onCancel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [uiReportDisplay, setUIReportDisplay] = useState<UIReportDisplayEnum>(UIReportDisplayEnum.DISPLAY);
    const [validate, setValidate] = useState<boolean>(false);
    const [failureTypes, setFailureTypes] = useState<IFailureTypeDbModel[]>([]);
    const [reworkItemStatuses, setReworkItemStatuses] = useState<IReworkItemStatusDbModel[]>([]);

    const [failures, setFailures] = useState<IStepFailure[]>([]);
    const [failureQty, setFailureQty] = useState<number>(1);

    const [ftTracking, setFtTracking] = useState<string>("");
    const [ftBatch, setFtBatch] = useState<boolean>(false);
    const [ftPartNumber, setFtPartNumber] = useState<string>("");
    const [ftOriginalWO, setFtOriginalWO] = useState<string>("");
    const [ftOriginalWOId, setFtOriginalWOId] = useState<number | undefined>(undefined);

    const [errorQty, setErrorQty] = useState<boolean>(false);
    const [isProcessRepoDialogOpen, setIsProcessRepoDialogOpen] = useState<boolean>(false);
    const [processTreeNodeId, setProcessTreeNodeId] = useState<number | undefined>(props.processTreeNodeId);
    const [processIdSelected, setProcessIdSelected] = useState<number | undefined>(undefined);
    const [processNameSelected, setProcessNameSelected] = useState<string | undefined>(undefined);

    const [yieldType, setYieldType] = useState<ReworkYieldType>("TPY");

    const allReworkItemStatus = useEntitiesReworkItemStatus();

    const allFailureTypes = useEntitiesFailureTypes();
    const allFailureSymptoms = useEntitiesFailureSymptoms();
    const allNodes = useEntitiesTreeProcess();

    const dispatch = useThunkDispatch();
    const language = useSelectorLanguage();
    // Reset data when opening dialog
    useEffect(() => {
        setFailureQty(1);
        setFtPartNumber("");
        setFtTracking("");
        setFtBatch(false);
        setFtOriginalWO("");
        setFtOriginalWOId(undefined);
        setYieldType("TPY");
        if (open && actionBaseState === undefined && !dryRunMode) {
            setUIReportDisplay(UIReportDisplayEnum.FORM);
        }

        if (open && actionBaseState !== undefined) {
            setFailureQty(maxFailQty);
        }
    }, [open]);

    /**
     * Effect triggered on failure qty changes, sets the array to represent said failures
     */
    useEffect((): void => {
        if (failures.length < failureQty) {
            // add new failures
            const newFailures = [...failures];
            for (let i = 0; i < failureQty - failures.length; i++) {
                newFailures.push({
                    reworkItemStatusId: 0,
                    problems: [{ ...defaultProblem }],
                    qty: 1,
                    manualFailure: false,
                    batchEnable: false,
                    trackingId: "",
                    reworkId: props.reworkId ?? 0,
                });
            }
            setFailures(newFailures);
        }
    }, [failureQty]);

    /**
     * Effect triggered when failure types changed in store
     */
    useEffect((): void => {
        if (processTreeNodeId && allFailureTypes.length > 0) {
            const parentNodes = getParentNodesPath(processTreeNodeId, allNodes);
            let nodeFailureTypes: IFailureTypeDbModel[] = [];

            for (const node of parentNodes) {
                const failureTypes = allFailureTypes.filter((failureType) => {
                    return failureType.treeNodeId === node.treeNodeId && !failureType.archived;
                });
                nodeFailureTypes = nodeFailureTypes.concat(failureTypes);
            }

            setFailureTypes(nodeFailureTypes);
        }
    }, [allFailureTypes, processTreeNodeId]);

    /**
     * Effect triggered when rework item status changed in store
     */
    useEffect((): void => {
        if (processTreeNodeId && allReworkItemStatus.length > 0) {
            const parentNodes = getParentNodesPath(processTreeNodeId, allNodes);
            let nodeReworkItemStatus: IReworkItemStatusDbModel[] = [];

            for (const node of parentNodes) {
                const reworkItemStatus = allReworkItemStatus.filter((status) => {
                    return status.treeNodeId === node.treeNodeId && !status.archived;
                });
                nodeReworkItemStatus = nodeReworkItemStatus.concat(reworkItemStatus);
            }

            setReworkItemStatuses(nodeReworkItemStatus);
        }
    }, [allReworkItemStatus, processTreeNodeId]);

    /**
     * handles the retry
     */
    const handleRetry = (): void => {
        if (props.onRetry) {
            props.onRetry();
        }
    };

    /**
     * handles the show form
     */
    const handleShowForm = (): void => {
        setUIReportDisplay(UIReportDisplayEnum.FORM);
    };

    /**
     * handles send an empty report to make it fail in dry run
     */
    const handleSendReportEmpty = (): void => {
        if (props.onSendReport) {
            props.onSendReport([]);
        }
        handleClose();
    };

    /**
     * handles the send report
     */
    const handleSendReport = (): void => {
        setValidate(true);
        if (props.onSendReport) {
            let formValid = true;
            let totalQty = 0;
            // check that all types and symptoms are filled and quantities are greater than 0
            for (const failure of failures) {
                if (ftTracking === "") {
                    formValid = false;
                }

                if (ftPartNumber === "") {
                    formValid = false;
                }

                if (failure.qty < 1) {
                    formValid = false;
                }
                totalQty += failure.qty;
                for (const problem of failure.problems) {
                    if (problem.failureTypeId === 0 || problem.failureSubTypeId === 0) {
                        formValid = false;
                    }
                }

                // Check if reworkItemStatus has been filled
                if (failure.reworkItemStatusId === 0) {
                    formValid = false;
                }
            }

            // check quantities
            if (totalQty > maxFailQty) {
                formValid = false;
                setErrorQty(true);
            } else {
                setErrorQty(false);
            }

            if (formValid) {
                const reworkInfo: IReworkInfo = {
                    trackingId: ftTracking,
                    batchEnable: ftBatch,
                    partNumber: ftPartNumber,
                    originalJobId: ftOriginalWOId,
                    originalJobRefId: ftOriginalWO,
                    processId: processIdSelected ?? 0,
                    yieldType,
                };

                // we are valid to send!!!
                props.onSendReport(failures, reworkInfo);
                handleClose();
                setFailures([
                    {
                        reworkItemStatusId: 0,
                        problems: [{ ...defaultProblem }],
                        qty: 1,
                        batchEnable: ftBatch,
                        manualFailure: props.manualFailure ?? false,
                        trackingId: ftTracking,
                    },
                ]);
                setFailureQty(1);
                setValidate(false);
            }
        }
    };

    /**
     * handles the send report
     */
    const handleClose = (): void => {
        setUIReportDisplay(UIReportDisplayEnum.DISPLAY);
        if (props.onClose) {
            props.onClose();
        }
    };

    /**
     * Sets the problem Failure Type
     *
     * @param {number} failureIndex - failure index
     * @param {number} index - problem index
     */
    const setProblemType =
        (failureIndex: number, index: number): ((e: React.ChangeEvent) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newFailures = [...failures];
            newFailures[failureIndex].problems[index].failureTypeId = parseInt(e.target.value, 10);
            setFailures(newFailures);
        };

    /**
     * Sets the problem Failure Sub Type
     *
     * @param {number} failureIndex - failure index
     * @param {number} index - problem index
     */
    const setProblemSubType =
        (failureIndex: number, index: number): ((e: React.ChangeEvent) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newFailures = [...failures];
            newFailures[failureIndex].problems[index].failureSubTypeId = parseInt(e.target.value, 10);
            setFailures(newFailures);
        };

    /**
     * Sets the problem Failure Comments
     *
     * @param {number} failureIndex - failure index
     * @param {number} index - problem index
     */
    const setProblemComments =
        (failureIndex: number, index: number): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newFailures = [...failures];
            newFailures[failureIndex].problems[index].comments = e.target.value;
            setFailures(newFailures);
        };

    /**
     * Sets the problem status
     *
     */
    const setStatus =
        (failureIndex: number): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newFailures = [...failures];
            newFailures[failureIndex].reworkItemStatusId = parseInt(e.target.value, 10);
            setFailures(newFailures);
        };

    /**
     * Sets the problem quantity
     */
    const setQuantity =
        (failureIndex: number): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newFailures = [...failures];
            const val = parseInt(e.target.value, 10);
            if (val > 0) {
                newFailures[failureIndex].qty = val;
            } else {
                newFailures[failureIndex].qty = 0;
            }
            setFailures(newFailures);
        };

    /**
     * Sets failures batch enable property
     */
    const setBatchEnable = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        setFtBatch(checked);
    };

    /**
     * Sets failure tracking Id property
     */
    const setTrackingId = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFtTracking(e.target.value);
    };

    /**
     * Sets failure part number property
     */
    const setPartNumber = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFtPartNumber(e.target.value);
    };

    /**
     * Sets the problem Failure Comments
     */
    const addProblem =
        (failureIndex: number): (() => void) =>
        (): void => {
            const newFailures = [...failures];
            newFailures[failureIndex].problems.push({ ...defaultProblem });
            setFailures(newFailures);
        };

    /**
     * Sets the problem Failure Comments
     *
     * @param {number} failureIndex - the failure index
     * @param {number} index - the problem index
     */
    const removeProblem =
        (failureIndex: number, index: number): (() => void) =>
        (): void => {
            // don't delete the add button
            if (index < failures[failureIndex].problems.length - 1) {
                const newFailures = [...failures];
                newFailures[failureIndex].problems.splice(index, 1);
                setFailures(newFailures);
            }
        };

    /**
     * indicates if we should show the fail form
     */
    const showFailForm = (): boolean => {
        if (dryRunMode) {
            return false;
        }

        return uiReportDisplay === UIReportDisplayEnum.FORM;
    };

    /**
     * handles add failure qty
     */
    const handleAddFailure = (): void => {
        setFailureQty(failureQty + 1);
    };

    /**
     * handles delete failure
     */
    const handleDeleteFailure = (index: number) => (): void => {
        const newFailures = [...failures];
        newFailures.splice(index, 1);
        setFailures(newFailures);
        setFailureQty(failureQty - 1);
    };

    /**
     * Handle that is called to open the process/routing repos
     */
    const handleProcessRepoOpen = (): void => {
        setIsProcessRepoDialogOpen(true);
    };

    /**
     * Handle that is called to close the process/routing repos
     */
    const handleProcessRepoCancel = (): void => {
        setIsProcessRepoDialogOpen(false);
    };

    /**
     * Handle the selection of process in manual mode
     */
    const handleProcessRepoSelection = async (processId: number, node: ITreeNodeDbModel): Promise<void> => {
        setIsProcessRepoDialogOpen(false);

        if (node && processId) {
            setProcessIdSelected(processId);
            setProcessTreeNodeId(node.treeNodeId);
            setProcessNameSelected(node.label);
        } else {
            console.error("No version of the process selected");
        }
    };

    /**
     * Handle search tracking id to pre-fill values
     */
    const handleSearchTrackingId = (): void => {
        dispatch(reworkGetInfoBeforeCreate({ trackingId: ftTracking })).then((info) => {
            if (info) {
                if (info.found === true && info.entities && info.entities.length > 0) {
                    setFtPartNumber(info.entities[0].partNumber);
                    setFtOriginalWO(info.originalJobRefId);
                    setFtOriginalWOId(info.originalJobId);
                    setFtBatch(info.entities[0].batchEnable);
                } else {
                    setFtPartNumber("");
                    setFtOriginalWO("");
                    setFtOriginalWOId(undefined);
                    setFtBatch(false);
                }
            } else {
                setFtPartNumber("");
                setFtOriginalWO("");
                setFtOriginalWOId(undefined);
                setFtBatch(false);
            }
        });
    };

    const handleYieldTypeChange = (value: ReworkYieldType): void => {
        setYieldType(value);
    };

    return (
        <div id="failedDialogId" className={classes.root}>
            <Dialog open={open} disableAutoFocus={true} fullWidth={true} maxWidth="lg">
                <DialogContent className={classes.dialogContent}>
                    {/* HEADER */}
                    <div className={classes.dialogTitle}>
                        <WarningIcon className={classes.mainIcon} />
                        <div className={classes.dialogDescription}>
                            {props.manualFailure ? (
                                <Typography id="processFailedReportMessageId" variant="h4">
                                    {translate("player.manualFailure")}
                                </Typography>
                            ) : uiReportDisplay === UIReportDisplayEnum.FORM ? (
                                <Typography id="processFailedReportMessageId" variant="h4">
                                    {translate("player.processFailureReport")}
                                </Typography>
                            ) : (
                                <Typography id="processFailedMessageId" variant="h4">
                                    {translate("player.processFailed")}
                                </Typography>
                            )}
                        </div>
                        {<NotificationsCenterDialog backgroundWhite={true} />}
                    </div>

                    {/* FAILED DIALOG FORM */}
                    <div id="failedDialogFormId" className={classes.mainForm}>
                        <React.Fragment>
                            {!props.manualFailure && (
                                <Typography variant="h6" className={classes.text}>
                                    <div>
                                        {actionBaseState?.failure?.actionName
                                            ? actionBaseState?.failure?.actionName
                                            : actionBaseState?.description}{" "}
                                        -{" "}
                                        {actionBaseState?.failure?.actionStepName ??
                                            actionBaseState?.stepsState.find(
                                                (step) =>
                                                    step.status === EnumActionStatus.PLAYING ||
                                                    step.status === EnumActionStatus.WAITING_USER_NEXT
                                            )?.description}
                                    </div>
                                </Typography>
                            )}
                            {/* RUN DETAILS DIALOG */}
                            {props.playerState && (
                                <RunDetailsDialogContent
                                    playerState={props.playerState}
                                    jobProcessInfo={props.jobProcessInfo}
                                    process={props?.process ?? null}
                                    reworkId={props.reworkId}
                                    runWithoutJobProcess={props.runWithoutJobProcess}
                                />
                            )}
                            {/* DETAIL OF THE ORIGIN OF THE TYPE OF FAILURE */}
                            <div className={classes.description}>
                                {props.manualFailure ? (
                                    <Typography variant="body2">{translate("player.failed.MANUAL_FAILURE_TICKET")}</Typography>
                                ) : actionBaseState?.failure?.description &&
                                  new RegExp(/^[A-Z]+(?:_[A-Z]+)*$/).test(
                                      actionBaseState?.failure?.description ? actionBaseState?.failure?.description : ""
                                  ) ? (
                                    <Typography variant="body2">{translate(actionBaseState?.failure?.description ?? "")}</Typography>
                                ) : (
                                    <pre>
                                        {actionBaseState?.failure?.description ??
                                            translate("player.failed.PROCESS_MANUALLY_STOPPED_BY_USER")}
                                    </pre>
                                )}
                            </div>
                        </React.Fragment>

                        {/* MANUAL FAILURE TICKET ITEM INFO */}
                        {props.manualFailure && (
                            <div className={classes.box}>
                                <div className={classes.boxRowTitle}>
                                    <Typography variant="body2">{translate("player.failed.enterTrackingTitle")}</Typography>
                                </div>
                                <div className={classes.boxRow}>
                                    <KortexTextField
                                        label={translate("player.failed.trackingId")}
                                        InputProps={{
                                            id: "trackingIdInputId",
                                        }}
                                        error={validate && ftTracking === "" ? translate("rework.trackinIdRequired") : undefined}
                                        TextFieldProps={{
                                            id: "trackingId",
                                            required: true,
                                        }}
                                        onChange={setTrackingId}
                                        type={"text"}
                                        min={1}
                                        value={ftTracking}
                                    />
                                    <Button
                                        id="searchTrackingIdButtonId"
                                        variant="contained"
                                        color="secondary"
                                        disabled={failureQty >= maxFailQty}
                                        onClick={handleSearchTrackingId}
                                        className={classes.dialogButtons}
                                    >
                                        <SearchIcon className={classes.buttonIcon} />
                                    </Button>
                                    <KortexTextField
                                        label={translate("player.failed.partNumber")}
                                        InputProps={{
                                            id: "partNumberInputId",
                                        }}
                                        error={validate && ftPartNumber === "" ? translate("rework.partNumberRequired") : undefined}
                                        TextFieldProps={{
                                            id: "partNumberId",
                                            required: true,
                                        }}
                                        onChange={setPartNumber}
                                        type={"text"}
                                        min={1}
                                        value={ftPartNumber}
                                    />
                                    {ftOriginalWO && (
                                        <KortexTextField
                                            label={translate("player.failed.originalJobRefId")}
                                            InputProps={{
                                                id: "originalWOInputId",
                                                readOnly: true,
                                                disabled: true,
                                            }}
                                            TextFieldProps={{
                                                id: "originalWOId",
                                                disabled: true,
                                            }}
                                            type={"text"}
                                            value={ftOriginalWO}
                                        />
                                    )}

                                    <div className={classes.failureBatch}>
                                        <Typography variant="caption">{translate("player.runDetailsDialog.batch")}</Typography>
                                        <Checkbox checked={ftBatch} onChange={setBatchEnable} />
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* MANUAL FAILURE TICKET SELECT PROCESS */}
                        {props.manualFailure && (
                            <div className={classes.box}>
                                <div className={classes.boxRowTitle}>
                                    <Typography variant="body2">{translate("player.failed.selectProcessTitle")}</Typography>
                                </div>
                                <div className={classes.boxRow}>
                                    <Button
                                        id="addReworkButtonId"
                                        variant="contained"
                                        color="secondary"
                                        disabled={failureQty >= maxFailQty}
                                        onClick={handleProcessRepoOpen}
                                        className={classes.dialogButtons}
                                    >
                                        <ProcessIcon className={classes.buttonIcon} /> {translate("player.selectRelatedProcess")}
                                    </Button>
                                    {processNameSelected && (
                                        <KortexLabelText
                                            label={translate("player.failed.processName")}
                                            text={processNameSelected}
                                            className={classes.processName}
                                            id={"processNameSelectedId"}
                                            styleLabelText={{
                                                styleLabel: { color: greyPalette[700] },
                                                styleText: { color: theme.palette.common.black },
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        <ProcessPicker
                            open={isProcessRepoDialogOpen}
                            onSelectedProcess={handleProcessRepoSelection}
                            onCancel={handleProcessRepoCancel}
                        />

                        {/* FAILURE INFO */}
                        {showFailForm() && (
                            <div className={classes.box}>
                                <div className={classes.boxRowTitle}>
                                    <Typography variant="body2">{translate("player.failed.addFailuresTitle")}</Typography>
                                </div>
                                {maxFailQty > 1 && (
                                    <Button
                                        id="addReworkButtonId"
                                        variant="contained"
                                        color="secondary"
                                        disabled={failureQty >= maxFailQty}
                                        onClick={handleAddFailure}
                                        className={classes.dialogButtons}
                                    >
                                        <AddIcon className={classes.buttonIcon} /> {translate("player.addFailures")}
                                    </Button>
                                )}
                                {errorQty && (
                                    <div className={classes.error}>
                                        {translate("player.failed.qtyError")} {maxFailQty}
                                    </div>
                                )}
                                {failures.map((failure, failureIndex) => (
                                    <div className={classes.failure} key={failureIndex}>
                                        {maxFailQty > 1 && (
                                            <Fab
                                                className={classes.deleteIcon}
                                                onClick={handleDeleteFailure(failureIndex)}
                                                size="small"
                                                color="secondary"
                                            >
                                                <DeleteIcon />
                                            </Fab>
                                        )}
                                        <div className={classes.failureRow}>
                                            <KortexOutlinedTextField
                                                id="reworkStatusId"
                                                value={failures[failureIndex].reworkItemStatusId}
                                                label={translate("player.status")}
                                                select={true}
                                                className={classes.select}
                                                required={true}
                                                onChange={setStatus(failureIndex)}
                                                error={validate && failures[failureIndex].reworkItemStatusId === 0}
                                            >
                                                <MenuItem value={0} disabled>
                                                    <Typography>{`${translate("player.select")} ...`}</Typography>
                                                </MenuItem>

                                                {reworkItemStatuses
                                                    ?.sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: "base" }))
                                                    .map((status, index): JSX.Element => {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                value={status.reworkItemStatusId}
                                                                id={`${status.title}Id`}
                                                            >
                                                                {status.title}
                                                                {status.automaticClose && <EndIcon className={classes.endIcon} />}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </KortexOutlinedTextField>
                                            <KortexTextField
                                                label={translate("player.failed.failedQty")}
                                                InputProps={{
                                                    id: "failedQuantityInputId",
                                                    error: errorQty,
                                                }}
                                                TextFieldProps={{
                                                    id: "reworkFailedQtyId",
                                                }}
                                                onChange={setQuantity(failureIndex)}
                                                type={"number"}
                                                min={1}
                                                value={failure.qty}
                                            />
                                        </div>
                                        <div className={classes.failureRow}>
                                            <div className={classes.problemContainer}>
                                                <div className={classes.problems}>
                                                    {failures[failureIndex].problems.map((problem, problemIndex): JSX.Element => {
                                                        return (
                                                            <div key={problemIndex} className={classes.problem}>
                                                                <KortexOutlinedTextField
                                                                    id="reworkFailureTypeId"
                                                                    value={problem.failureTypeId}
                                                                    label={translate("player.type")}
                                                                    select={true}
                                                                    className={classes.select}
                                                                    required={true}
                                                                    error={validate && problem.failureTypeId === 0}
                                                                    onChange={setProblemType(failureIndex, problemIndex)}
                                                                >
                                                                    <MenuItem value={0} disabled>
                                                                        <Typography>{`${translate("player.select")} ...`}</Typography>
                                                                    </MenuItem>
                                                                    {failureTypes
                                                                        ?.sort((a, b) =>
                                                                            a.title.localeCompare(b.title, language, {
                                                                                sensitivity: "base",
                                                                            })
                                                                        )
                                                                        .map((type, TIndex): JSX.Element => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={TIndex}
                                                                                    value={type.failureTypeId}
                                                                                    id={`failureType${TIndex}Id`}
                                                                                >
                                                                                    {type.title}
                                                                                </MenuItem>
                                                                            );
                                                                        })}
                                                                </KortexOutlinedTextField>
                                                                <KortexOutlinedTextField
                                                                    id="reworkFailureSubTypeId"
                                                                    value={problem.failureSubTypeId}
                                                                    label={translate("player.symptom")}
                                                                    select={true}
                                                                    className={classes.select}
                                                                    required={true}
                                                                    error={validate && problem.failureSubTypeId === 0}
                                                                    onChange={setProblemSubType(failureIndex, problemIndex)}
                                                                >
                                                                    <MenuItem value={0} disabled>
                                                                        <Typography>{`${translate("player.select")} ...`}</Typography>
                                                                    </MenuItem>
                                                                    {allFailureSymptoms
                                                                        .filter(
                                                                            (symptoms): boolean =>
                                                                                symptoms.failureTypeId === problem.failureTypeId &&
                                                                                !symptoms.archived
                                                                        )
                                                                        .sort((a, b) =>
                                                                            a.title.localeCompare(b.title, language, {
                                                                                sensitivity: "base",
                                                                            })
                                                                        )
                                                                        .map((symptoms, STIndex): JSX.Element => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={STIndex}
                                                                                    value={symptoms.failureSubTypeId}
                                                                                    id={`failureSymptom${STIndex}Id`}
                                                                                >
                                                                                    {symptoms.title}
                                                                                </MenuItem>
                                                                            );
                                                                        })}
                                                                </KortexOutlinedTextField>
                                                                <KortexTextField
                                                                    label={translate("player.comments")}
                                                                    className={classes.comments}
                                                                    InputProps={{
                                                                        id: "failedCommentsInputId",
                                                                        multiline: true,
                                                                    }}
                                                                    onChange={setProblemComments(failureIndex, problemIndex)}
                                                                    value={problem.comments}
                                                                />
                                                                {problemIndex === failures[failureIndex].problems.length - 1 ? (
                                                                    <div className={classes.addIcon} onClick={addProblem(failureIndex)}>
                                                                        <AddIcon />
                                                                        <Typography>{translate("player.add")}</Typography>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={classes.removeIcon}
                                                                        onClick={removeProblem(failureIndex, problemIndex)}
                                                                    >
                                                                        <RemoveIcon />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* YIELD */}
                        <div className={classes.box}>
                            <div className={classes.boxRowTitle}>
                                <Typography variant="body2">{translate("player.failed.selectYieldType")}</Typography>
                            </div>
                            <YieldTypeSelector
                                classes={{ container: classes.yieldSelectorContainer }}
                                onChange={handleYieldTypeChange}
                                value={yieldType}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    {/* BUTTON DIALOG*/}
                    {uiReportDisplay === UIReportDisplayEnum.FORM ? (
                        <div id="failedDialogActionId">
                            {(props.manualFailure || !Boolean(actionBaseState?.failure?.description)) && (
                                <Button
                                    id="cancelButtonId"
                                    variant="outlined"
                                    color="secondary"
                                    onClick={onCancel}
                                    className={classes.dialogButtons}
                                >
                                    {translate("player.cancel")}
                                </Button>
                            )}

                            <Button
                                id="proceedButtonId"
                                variant="contained"
                                color="secondary"
                                onClick={dryRunMode ? handleSendReportEmpty : handleSendReport}
                                className={classes.dialogButtons}
                            >
                                {props.manualFailure ? <>{translate("general.create")}</> : <>{translate("player.fail")}</>}
                            </Button>
                        </div>
                    ) : (
                        <div id="failedDialogActionId">
                            {props.canRetry && (
                                <Button
                                    variant="contained"
                                    color="inherit"
                                    id="cancelButtonId"
                                    onClick={handleRetry}
                                    className={classes.dialogButtons}
                                >
                                    {translate("player.retry")}
                                </Button>
                            )}
                            {dryRunMode || (actionBaseState && !actionBaseState.showFailForm) ? (
                                <Button
                                    id="closeButtonId"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClose}
                                    className={classes.dialogButtons}
                                >
                                    {translate("player.close")}
                                </Button>
                            ) : (
                                <Button
                                    id="proceedButtonId"
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleShowForm}
                                    className={classes.dialogButtons}
                                >
                                    {translate("player.report")}
                                </Button>
                            )}
                        </div>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}
