export function createBomFollowUpSerializedItemHistory(bomFollowUpHistory) {
    const { bomFollowUpSerializedItemId, bomFollowUpSymptomId = null, justification = "", previousValues = {}, serialNumber, type, userId, } = bomFollowUpHistory;
    return {
        bomFollowUpSerializedItemId,
        bomFollowUpSymptomId,
        justification,
        previousValues,
        serialNumber,
        type,
        userId,
    };
}
