import { IJobUiModel } from "@kortex/aos-common";

import { BareAction, ComplexAction } from "../app.types";

export interface ISchedulerState {
    jobs: IJobUiModel[];
}

export enum SchedulerActionType {
    CLEAR_SCHEDULER = "@@SCHEDULER/CLEAR_SCHEDULER",
    UPDATE_JOBS = "@@SCHEDULER/UPDATE_JOBS",
    UPDATE_JOB = "@@SCHEDULER/UPDATE_JOB",
    INSERT_JOB = "@@SCHEDULER/INSERT_JOB",
    ARCHIVE_COMPLETED_JOBS = "@@SCHEDULER/ARCHIVE_COMPLETED_JOBS",
}

export type SchedulerState = ISchedulerState;

export type ClearSchedulerAction = BareAction<SchedulerActionType.CLEAR_SCHEDULER>;
export type JobInsertedAction = ComplexAction<SchedulerActionType.INSERT_JOB, ISchedulerState["jobs"]>;
export type JobUpdatedAction = ComplexAction<SchedulerActionType.UPDATE_JOB, ISchedulerState["jobs"]>;
export type SetJobListAction = ComplexAction<SchedulerActionType.UPDATE_JOBS, ISchedulerState["jobs"]>;
export type ArchiveCompletedJobsAction = ComplexAction<SchedulerActionType.ARCHIVE_COMPLETED_JOBS, IJobUiModel[]>;

export type SchedulerActions = ClearSchedulerAction | JobInsertedAction | JobUpdatedAction | SetJobListAction | ArchiveCompletedJobsAction;
