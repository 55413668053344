import { KortexDialogConfirmation, KortexTextField, theme } from "@aos/react-components";
import { IReworkItemStatusDbModel, TreeNodeDbModel } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";
import { deepClone } from "@kortex/utilities";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    IconButton,
    ListItem,
    ListItemText,
    Tooltip,
    Typography,
    makeStyles,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ArchiveIcon from "@material-ui/icons/Archive";
import EditIcon from "@material-ui/icons/Edit";
import AddPlusIcon from "@material-ui/icons/PlaylistAdd";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesReworkItemStatus, useEntitiesTreeProcess } from "../../../../../redux/effects";
import { reworkItemStatusInsert, reworkItemStatusUpdate } from "../../../../../redux/rework-manager/rework-item-status-thunks";
import { getParentNodesPath, getParentPathString } from "../../../../../utilitites/getParentNodes";

const useStyles = makeStyles({
    list: {
        display: "flex",
        flexDirection: "column",
    },
    groupType: {
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
    },
    listOfItem: {
        width: "100%",
        overflowY: "auto",
    },
    itemGroup: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${theme.palette.grey[200]}`,
    },
    itemAction: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
    },
    item: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0 16px",
    },
    itemParent: {
        width: "100%",
        color: theme.palette.grey[400],
    },
    itemState: {
        width: "300px",
    },
    smallText: {
        paddingLeft: "10px",
        fontSize: "0.7rem",
    },
    title: {
        paddingLeft: "10px",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    textArchived: {
        color: theme.palette.grey[400],
    },
    checkboxDoubleTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "50px",
    },
    checkboxDouble: {
        alignItems: "center",
        display: "flex",
        justifyContent: "space-around",
        width: "100px",
    },
    checkbox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100px",
    },
    checkboxes: {
        display: "flex",
        flexDirection: "row",
        width: "300px",
    },
    listHeader: {
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "bold",
        color: theme.palette.primary[500],
    },
    textBox: {
        margin: 0,
        width: "200px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IOwnProps {
    onClose: () => void;
    opened: boolean;
    treeNode: TreeNodeDbModel;
}

export default function ReworkItemStatusDialog(props: IOwnProps): JSX.Element {
    const { onClose, opened, treeNode } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();

    const [ReworkItemStatus, setReworkItemStatus] = useState<IReworkItemStatusDbModel[]>([]);
    const [selectedReworkItemStatus, setSelectedReworkItemStatus] = useState<IReworkItemStatusDbModel | undefined>(undefined);
    const [selectedEditReworkItemStatus, setSelectedEditReworkItemStatus] = useState<IReworkItemStatusDbModel | undefined>(undefined);

    const [valueToInsert, setValueToInsert] = useState("");
    const [insertDialogOpen, setInsertDialogOpen] = useState(false);

    const [showArchived, setShowArchived] = useState<boolean>(false);

    const allReworkItemStatus = useEntitiesReworkItemStatus();
    const allNodes = useEntitiesTreeProcess();

    const translate = useTranslate();
    const language = useSelectorLanguage();
    /**
     * Effect triggered when failure types changed in store
     */
    useEffect((): void => {
        const parentNodes = getParentNodesPath(treeNode.treeNodeId, allNodes);
        let nodeReworkItemStatus: IReworkItemStatusDbModel[] = [];

        for (const node of parentNodes) {
            const ReworkItemStatus = allReworkItemStatus.filter((reworkItemStatus) => {
                return reworkItemStatus.treeNodeId === node.treeNodeId;
            });
            nodeReworkItemStatus = nodeReworkItemStatus.concat(ReworkItemStatus);
        }
        setReworkItemStatus(nodeReworkItemStatus);
    }, [allReworkItemStatus, showArchived]);

    /**
     * Handle change of failure type
     *
     * @param {IReworkItemStatusDbModel} reworkItemStatus - selected failure type
     */
    const handleReworkItemStatusChange =
        (reworkItemStatus: IReworkItemStatusDbModel): (() => void) =>
        (): void => {
            setSelectedReworkItemStatus(reworkItemStatus);
        };

    /*** RENAME */

    /**
     * Handle rename of failure type
     *
     * @param {IReworkItemStatusDbModel} reworkItemStatus - failure type to rename
     */
    const handleReworkItemStatusRename =
        (reworkItemStatus: IReworkItemStatusDbModel): ((value: string) => void) =>
        (value: string): void => {
            const newItem = { ...reworkItemStatus };
            newItem.title = value;
            dispatch(reworkItemStatusUpdate(newItem));
        };

    /*** EDIT */

    /**
     * Handle edition of failure type.  Flip from Text to Input
     *
     * @param {IReworkItemStatusDbModel | undefined } reworkItemStatus - failure type to edit
     */
    const handleEditReworkItemStatus =
        (reworkItemStatus?: IReworkItemStatusDbModel): (() => void) =>
        (): void => {
            if (reworkItemStatus) {
                if (!selectedEditReworkItemStatus) {
                    setSelectedEditReworkItemStatus(reworkItemStatus);
                } else {
                    setSelectedEditReworkItemStatus(undefined);
                }
            } else {
                setSelectedEditReworkItemStatus(undefined);
            }
        };

    /*** INSERTS */

    /**
     * Handle insertion of failure types (one or many).  Many are in CSV format.  This handle the dialog open for types
     */
    const handleInsertReworkItemStatus = (): void => {
        setValueToInsert("");
        setInsertDialogOpen(true);
    };

    /**
     * Handle insertion of failure types or symptoms (one or many).  Many are in CSV format.
     * Types or symptoms is in typeToInsert, value is in valueToInsert
     */
    const handleInsertDialogAdd = (): void => {
        /* Request to add one element or CVS elements */
        const valuesArrayToInsert: string[] = valueToInsert.split(",");
        /* A reduce is used here to clean up invalid entry from the user */
        const arrayReworkItemStatusToInsert: IReworkItemStatusDbModel[] = valuesArrayToInsert.reduce(
            (accumulator: IReworkItemStatusDbModel[], value: string) => {
                const trimValue = value.trim();
                if (trimValue !== "") {
                    accumulator.push({
                        reworkItemStatusId: 0,
                        archived: false,
                        treeNodeId: treeNode.treeNodeId,
                        title: trimValue,
                        automaticClose: false,
                        automaticClosePass: false,
                        automaticArchive: false,
                        continue: false,
                    });
                } // else... empty string, simply drop
                return accumulator;
            },
            []
        );
        arrayReworkItemStatusToInsert.forEach((status) => {
            dispatch(reworkItemStatusInsert(status));
        });
    };

    /**
     * Handle close of insertion dialog.
     */
    const handleInsertDialogClose = (): void => {
        setInsertDialogOpen(false);
    };

    /**
     * Handle change of possible value for types or symptoms
     *
     * @param {string} value - new value to store
     *
     */
    const onInsertItemValueChanged = (value: string): void => {
        setValueToInsert(value);
    };

    /**
     * Handle Archive/UnArchive of failure type
     */
    const handleArchiveReworkItemStatus = (): void => {
        if (selectedReworkItemStatus) {
            const reworkItemStatusToUpdate = deepClone(selectedReworkItemStatus);
            reworkItemStatusToUpdate.archived = !selectedReworkItemStatus.archived;
            dispatch(reworkItemStatusUpdate(reworkItemStatusToUpdate));
            if (reworkItemStatusToUpdate.archived && !showArchived) {
                setSelectedReworkItemStatus(undefined);
            }
        }
    };

    /**
     * Handle select/unselect of the option show archive
     */
    const handleOptionShowArchived = (): void => {
        setShowArchived(!showArchived);
    };

    /**
     * Handle Rework Creation Click
     */
    const automaticCloseArchive =
        (
            reworkItemStatus: IReworkItemStatusDbModel,
            archive: boolean,
            closePass: boolean,
            continueRun: boolean
        ): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const checked = event.target.checked;
            const newItem = { ...reworkItemStatus };

            if (archive) {
                newItem.automaticArchive = checked;
                if (checked && !newItem.automaticClose) {
                    newItem.automaticClose = true;
                    newItem.automaticClosePass = false;
                }
            } else if (continueRun) {
                newItem.continue = checked;

                if (checked) {
                    newItem.automaticClose = true;
                    newItem.automaticArchive = true;
                    newItem.automaticClosePass = true;
                }
            } else {
                newItem.automaticClose = checked;

                if (checked) {
                    newItem.automaticClosePass = closePass;
                }
            }

            dispatch(reworkItemStatusUpdate(newItem));
        };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={true}
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: onClose,
            }}
            onCancel={onClose}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("general.close"),
                titleLabel: `${translate("reworkItemStatusDialog.reworkItemStatusFor")} ${treeNode.label}`,
            }}
        >
            <div className={classes.list}>
                <div className={classes.groupType}>
                    <Typography variant={"h6"}>{translate("reworkItemStatusDialog.reworkItemStatus")}</Typography>
                    <Typography variant={"caption"}>{translate("reworkItemStatusDialog.failureTicketsExplanation")}</Typography>
                    <div className={classes.itemGroup}>
                        <List className={classes.listOfItem}>
                            <ListItem className={classes.listHeader}>
                                <div>{translate("reworkItemStatusDialog.itemState")}</div>
                                <div className={classes.checkboxes}>
                                    <div className={classes.checkbox}>{translate("reworkItemStatusDialog.automaticClose")}</div>
                                    <div className={classes.checkbox}>{translate("reworkItemStatusDialog.automaticArchive")}</div>
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltip }}
                                        title={translate("reworkItemStatusDialog.continueTooltip")}
                                    >
                                        <div className={classes.checkbox}>{translate("reworkItemStatusDialog.continue")}</div>
                                    </Tooltip>
                                </div>
                            </ListItem>
                            <ListItem className={classes.listHeader}>
                                <div></div>
                                <div className={classes.checkboxes}>
                                    <div className={classes.checkboxDoubleTitle}>{translate("general.pass")}</div>
                                    <div className={classes.checkboxDoubleTitle}>{translate("general.fail")}</div>
                                </div>
                            </ListItem>
                            {ReworkItemStatus.filter((reworkItemStatus) => reworkItemStatus.treeNodeId === treeNode.treeNodeId)
                                .filter((reworkItemStatus) => showArchived || !reworkItemStatus.archived)
                                .sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: "base" }))
                                .map((reworkItemStatus): JSX.Element => {
                                    return selectedEditReworkItemStatus === reworkItemStatus ? (
                                        <div key={reworkItemStatus.reworkItemStatusId} className={classes.item}>
                                            <KortexTextField
                                                InputProps={{
                                                    autoFocus: true,
                                                }}
                                                className={classes.textBox}
                                                value={reworkItemStatus.title}
                                                key={reworkItemStatus.reworkItemStatusId}
                                                onChanged={handleReworkItemStatusRename(reworkItemStatus)}
                                                variant={"standard"}
                                                onBlur={handleEditReworkItemStatus(undefined)}
                                            />
                                            <div className={classes.checkboxDouble}>
                                                <Checkbox
                                                    color="secondary"
                                                    checked={reworkItemStatus.automaticClose && reworkItemStatus.automaticClosePass}
                                                    onChange={automaticCloseArchive(reworkItemStatus, false, true, false)}
                                                />
                                                <Checkbox
                                                    color="secondary"
                                                    checked={reworkItemStatus.automaticClose && !reworkItemStatus.automaticClosePass}
                                                    onChange={automaticCloseArchive(reworkItemStatus, false, false, false)}
                                                />
                                            </div>
                                            <div className={classes.checkbox}>
                                                <Checkbox
                                                    color="secondary"
                                                    checked={reworkItemStatus.automaticArchive}
                                                    onChange={automaticCloseArchive(reworkItemStatus, true, false, false)}
                                                />
                                            </div>
                                            <div className={classes.checkbox}>
                                                <Checkbox
                                                    color="secondary"
                                                    checked={reworkItemStatus.continue}
                                                    onChange={automaticCloseArchive(reworkItemStatus, false, false, true)}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <ListItem
                                            button
                                            className={classes.item}
                                            selected={reworkItemStatus.reworkItemStatusId === selectedReworkItemStatus?.reworkItemStatusId}
                                            key={reworkItemStatus.reworkItemStatusId}
                                            onClick={handleReworkItemStatusChange(reworkItemStatus)}
                                            onDoubleClick={handleEditReworkItemStatus(reworkItemStatus)}
                                        >
                                            <ListItemText
                                                primary={reworkItemStatus.title}
                                                className={`${classes.itemState} ${reworkItemStatus.archived ? classes.textArchived : ""}`}
                                            />
                                            <div className={classes.checkboxDouble}>
                                                <Checkbox
                                                    color="secondary"
                                                    checked={reworkItemStatus.automaticClose && reworkItemStatus.automaticClosePass}
                                                    onChange={automaticCloseArchive(reworkItemStatus, false, true, false)}
                                                />
                                                <Checkbox
                                                    color="secondary"
                                                    checked={reworkItemStatus.automaticClose && !reworkItemStatus.automaticClosePass}
                                                    onChange={automaticCloseArchive(reworkItemStatus, false, false, false)}
                                                />
                                            </div>
                                            <div className={classes.checkbox}>
                                                <Checkbox
                                                    color="secondary"
                                                    checked={reworkItemStatus.automaticArchive}
                                                    onChange={automaticCloseArchive(reworkItemStatus, true, false, false)}
                                                />
                                            </div>
                                            <div className={classes.checkbox}>
                                                <Checkbox
                                                    color="secondary"
                                                    checked={reworkItemStatus.continue}
                                                    onChange={automaticCloseArchive(reworkItemStatus, false, false, true)}
                                                />
                                            </div>
                                        </ListItem>
                                    );
                                })}
                            <Divider />
                            {/* PARENT NODE FAILURE TYPES */}
                            {ReworkItemStatus.filter((reworkItemStatus) => reworkItemStatus.treeNodeId !== treeNode.treeNodeId)
                                .filter((reworkItemStatus) => showArchived || !reworkItemStatus.archived)
                                .sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: "base" }))
                                .map((reworkItemStatus): JSX.Element => {
                                    return (
                                        <ListItem
                                            button
                                            className={classes.itemParent}
                                            selected={reworkItemStatus.reworkItemStatusId === selectedReworkItemStatus?.reworkItemStatusId}
                                            key={reworkItemStatus.reworkItemStatusId}
                                            onClick={handleReworkItemStatusChange(reworkItemStatus)}
                                        >
                                            <>
                                                <ListItemText
                                                    primary={reworkItemStatus.title}
                                                    secondary={getParentPathString(reworkItemStatus.treeNodeId, allNodes)}
                                                />
                                                <div className={classes.checkbox}>
                                                    <Checkbox
                                                        disabled={true}
                                                        color="secondary"
                                                        checked={reworkItemStatus.automaticClose && reworkItemStatus.automaticClosePass}
                                                    />
                                                    <Checkbox
                                                        disabled={true}
                                                        color="secondary"
                                                        checked={reworkItemStatus.automaticClose && !reworkItemStatus.automaticClosePass}
                                                    />
                                                </div>
                                                <div className={classes.checkbox}>
                                                    <Checkbox
                                                        disabled={true}
                                                        color="secondary"
                                                        checked={reworkItemStatus.automaticArchive}
                                                    />
                                                </div>
                                                <div className={classes.checkbox}>
                                                    <Checkbox disabled={true} color="secondary" checked={reworkItemStatus.continue} />
                                                </div>
                                            </>
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </div>
                    <div className={classes.itemAction}>
                        <IconButton id="insertManyReworkItemStatusId" color="primary" onClick={handleInsertReworkItemStatus}>
                            <AddPlusIcon />
                        </IconButton>
                        <IconButton
                            id="editReworkItemStatusId"
                            color="primary"
                            onClick={handleEditReworkItemStatus(selectedReworkItemStatus)}
                            disabled={!selectedReworkItemStatus || selectedReworkItemStatus.treeNodeId !== treeNode.treeNodeId}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            id="archiveReworkItemStatusId"
                            color="primary"
                            onClick={handleArchiveReworkItemStatus}
                            disabled={!selectedReworkItemStatus || selectedReworkItemStatus.treeNodeId !== treeNode.treeNodeId}
                        >
                            {selectedReworkItemStatus && !selectedReworkItemStatus.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                        </IconButton>
                    </div>
                </div>
                <div className={classes.itemAction}>
                    <div className={classes.optionsItem}>
                        <Typography>{`${translate("reworkItemStatusDialog.optionShowArchived")} `}</Typography>
                        <Checkbox checked={showArchived} onChange={handleOptionShowArchived} />
                    </div>
                </div>
            </div>
            <Dialog open={insertDialogOpen} disableAutoFocus={true} fullWidth={false}>
                <DialogContent>
                    <div>
                        <Typography className={classes.title} variant="h5">
                            {translate("reworkItemStatusDialog.itemsToInsert")}
                        </Typography>
                        <KortexTextField
                            TextFieldProps={{
                                id: "insertItemId",
                            }}
                            onChanged={onInsertItemValueChanged}
                            value={valueToInsert}
                        />
                    </div>
                    <div className={classes.smallText}>{translate("reworkItemStatusDialog.addInstructions")}</div>
                </DialogContent>
                <DialogActions>
                    <Button id="addButtonId" variant="contained" color="secondary" onClick={handleInsertDialogAdd}>
                        <Typography>{translate("reworkItemStatusDialog.add")}</Typography>
                    </Button>
                    <Button id="closeButtonId" variant="contained" color="secondary" onClick={handleInsertDialogClose}>
                        <Typography>{translate("general.close")}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </KortexDialogConfirmation>
    );
}
