var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function If(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M2,6.48051882 L5.42012937,6.48051882 L5.42012937,10.7818169 L7.54090827,10.7818169 L7.54090827,6.48051882 L10.9610376,6.48051882 L6.48051882,2 L2,6.48051882 Z M16.5331167,2 L21.0136355,6.48051882 L17.5935062,6.48051882 L17.6892642,8.93 L17.6930318,8.93258934 L17.6902642,8.934 L17.6921003,8.96862694 L16.8952642,9.666 L12.265113,13.9298904 L12.265113,22 L9.96026424,22 L9.96026424,13.0580209 L15.4722642,7.962 L15.4727273,6.48051882 L12.0525979,6.48051882 L16.5331167,2 Z", id: "icons-if", transform: "translate(11.506818, 12.000000) rotate(-270.000000) translate(-11.506818, -12.000000) " })));
}
export default If;
