// ---------------------------------------------------------------------------------------------------- //
// CODE
// ---------------------------------------------------------------------------------------------------- //
export var ScannerCodeTypeEnum;
(function (ScannerCodeTypeEnum) {
    ScannerCodeTypeEnum["ITEM"] = "I";
    ScannerCodeTypeEnum["KANBAN"] = "K";
    ScannerCodeTypeEnum["TABLE"] = "T";
    ScannerCodeTypeEnum["UNKNOWN"] = "U";
})(ScannerCodeTypeEnum || (ScannerCodeTypeEnum = {}));
// ---------------------------------------------------------------------------------------------------- //
// ITEM
// ---------------------------------------------------------------------------------------------------- //
export const SCANNER_ITEM_SEPARATOR = ",";
// ---------------------------------------------------------------------------------------------------- //
// Utilities
// ---------------------------------------------------------------------------------------------------- //
/**
 * Return the prefix for a code depending on its type
 */
function getScannerCodePrefix(type) {
    return `[${type}]`;
}
/**
 * Get the type of a code
 */
export function getScannerCodeType(code) {
    if (code.startsWith(getScannerCodePrefix(ScannerCodeTypeEnum.ITEM))) {
        return ScannerCodeTypeEnum.ITEM;
    }
    else if (code.startsWith(getScannerCodePrefix(ScannerCodeTypeEnum.KANBAN))) {
        return ScannerCodeTypeEnum.KANBAN;
    }
    else if (code.startsWith(getScannerCodePrefix(ScannerCodeTypeEnum.TABLE))) {
        return ScannerCodeTypeEnum.TABLE;
    }
    else {
        return ScannerCodeTypeEnum.UNKNOWN;
    }
}
/**
 * Remove the prefix from the code and return the code.
 */
export function getCleanScannerCode(code) {
    const type = getScannerCodeType(code);
    if (type === ScannerCodeTypeEnum.UNKNOWN) {
        // Code is invalid
        return code;
    }
    return code.slice(getScannerCodePrefix(type).length, code.length).trim();
}
// Regex which groups all the characters allowed for a code
// a - z
// A - Z
// 0 - 9
// ,
// -
// [ ]
const SCANNER_CODE_ALLOWED_KEYS = new RegExp("^[a-zA-Z0-9,\\-\\[\\]]$");
/**
 * Validates that a character is allowed to be used in a scanner code
 */
export function isCharAllowedForScannerCode(char) {
    return SCANNER_CODE_ALLOWED_KEYS.test(char);
}
// Regex to validate codes
const SCANNER_CODE_VALIDATOR_ITEM = new RegExp("^\\[I\\][a-zA-Z0-9]+(,|,[a-zA-Z0-9]+)?(,|,[0-9]+)?$"), 
/*
    Item nomenclature: [I]item,partnumber,quantity
        - Prefix [I] and item are mandatory
        - Part number and quantity are optional
            - If left blank, partnumber will be set to "N/A" and quantity will be set to 1

    Possible values:
        [I]KR8600
        [I]KR8600,
        [I]KR8600,,
        [I]KR8600,RC123456
        [I]KR8600,RC123456,50
*/
SCANNER_CODE_VALIDATOR_KANBAN = new RegExp("^\\[K\\][a-zA-Z0-9\\-]+$"), // ex: [K]Kanban1
SCANNER_CODE_VALIDATOR_TABLE = new RegExp("^\\[T\\][a-zA-Z0-9\\-]+$"); // ex: [T]Table-01
// Map that get corresponding validation function depending of the code type
const validateScannerCodeMap = {
    [ScannerCodeTypeEnum.ITEM]: (code) => isScannerCodeItemValid(code),
    [ScannerCodeTypeEnum.KANBAN]: (code) => SCANNER_CODE_VALIDATOR_KANBAN.test(code),
    [ScannerCodeTypeEnum.TABLE]: (code) => SCANNER_CODE_VALIDATOR_TABLE.test(code),
    [ScannerCodeTypeEnum.UNKNOWN]: () => false,
};
/**
 * Validate whether or not a code is valid
 */
export function isScannerCodeValid(code) {
    return validateScannerCodeMap[getScannerCodeType(code)](code);
}
/**
 * Validate whether or not a code is valid
 */
function isScannerCodeItemValid(code) {
    // Validate the characters
    if (!SCANNER_CODE_VALIDATOR_ITEM.test(code)) {
        return false;
    }
    const [_, __, quantity] = getCleanScannerCode(code).split(SCANNER_ITEM_SEPARATOR);
    // Validate the quantity
    // If a quantity is defined, it must be greater than 0
    if (quantity && parseInt(quantity, 10) <= 0) {
        return false;
    }
    return true;
}
/**
 * Extract item, lot number and quantity from an item code
 */
export function fromItemCode(code) {
    const [item, lotNumber, quantity] = getCleanScannerCode(code).split(SCANNER_ITEM_SEPARATOR);
    return [
        item,
        lotNumber || "N/A",
        parseInt(quantity || "1", 10), // Quantity - 1 if undefined
    ];
}
