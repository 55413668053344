import { theme } from "@aos/react-components";
import { IUserTrainingFilters } from "@kortex/aos-common";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import KortexSearchField from "@kortex/aos-ui/components/core/KortexSearchField";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useSelectorUsersRolesRights } from "@kortex/aos-ui/redux/selectors";
import { userCanWrite } from "@kortex/aos-ui/utilitites/IUserRights";
import { Checkbox, List, ListItem, Tooltip, Typography, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { FC } from "react";

import { useUserTrainingContext } from "./context";

const useStyles = makeStyles({
    cardContent: {
        display: "grid",
        gridTemplateColumns: "32% 10% 10% 10% 13% 13% 12%;",
        alignItems: "center",
    },
    text: {
        color: theme.palette.grey[700],
    },
    tooltips: {
        fontSize: "1.1rem",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
    },
    column: {
        display: "flex",
        alignItems: "center",
        height: "58px",
        justifyContent: "center",
    },
    columnBorderRight: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
    },
    columnProcessLabel: {
        justifyContent: "left",
        paddingRight: "5px",
    },
    columnReadingCertificate: {
        justifyContent: "flex-end",
        paddingRight: "10px",
    },
    searchbar: {
        width: "100%",
    },
    buttonContainer: {
        alignItems: "center",
        display: "inline-flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    filterLabel: {
        color: theme.palette.secondary[500],
        flex: 1,
    },
    filterLabelClear: {
        color: theme.palette.secondary[500],
        "&:hover": {
            color: theme.palette.primary[500],
            cursor: "pointer",
        },
    },
    filterOption: {
        alignItems: "center",
        display: "flex",
        marginTop: "15px",
    },
});

const UserTrainingsCardHeader: FC = () => {
    const classes = useStyles();
    const userTrainingContext = useUserTrainingContext();
    const translate = useTranslate();

    const accessTrainingUserCanWrite = userCanWrite(useSelectorUsersRolesRights("training"));

    /**
     *
     * Called when the filters change. Sets filters
     *
     * @param {string} processName - new value
     */
    const handleProcessNameFilterChange = (processName: string): void => {
        userTrainingContext.setTrainingFilters({ processName });
    };

    /**
     * Called when the add process formation button has been clicked to open the repository and select the process(es).
     */
    const handleOpenRepository = (): void => {
        userTrainingContext.setOpenRepositoryDialog(true);
    };

    /**
     * Clears filters
     */
    const handleClearFilters = (): void => {
        userTrainingContext.setTrainingFilters({
            processName: undefined,
            showArchived: false,
        });
    };

    /**
     * Update trainings filters.
     * This handle is used for filters that are controlled by a checkbox.
     */
    const handleFilterCheckboxChange =
        (key: keyof Pick<IUserTrainingFilters, "showArchived">): ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) =>
        (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            userTrainingContext.setTrainingFilters({ [key]: checked });
        };

    return (
        <KortexPanelCard
            statusColor={theme.palette.common.white} // white
            isSelected={false}
            padding={0}
            spaceBetweenCard={1}
            hover={false}
        >
            <div className={classes.cardContent} id="userTrainingCardHeaderId">
                {/* PROCESS TRAINING NAME */}
                <div className={`${classes.column} ${classes.columnBorderRight} ${classes.columnProcessLabel}`}>
                    <div className={classes.searchbar}>
                        <KortexSearchField
                            InputProps={{ id: "userTrainingProcessSearchBarId" }}
                            onChange={handleProcessNameFilterChange}
                            placeholder={translate("training.searchProcessTraining")}
                        >
                            <>
                                <div className={classes.filterOption}>
                                    <Typography className={classes.filterLabel}>{translate("training.showArchivedTrainings")}</Typography>
                                    <Checkbox
                                        checked={userTrainingContext.trainingFilters.showArchived}
                                        onChange={handleFilterCheckboxChange("showArchived")}
                                    />
                                </div>
                                <div className={classes.filterOption}>
                                    <div className={classes.filterLabel} />
                                    <Typography onClick={handleClearFilters} className={classes.filterLabelClear}>
                                        {translate("general.clearFilters")}
                                    </Typography>
                                </div>
                            </>
                        </KortexSearchField>
                    </div>
                    <Tooltip
                        classes={{ tooltip: classes.tooltips }}
                        placement="top"
                        title={translate("training.tooltipAddProcessTraining")}
                    >
                        <List>
                            <ListItem
                                button={true}
                                disabled={!accessTrainingUserCanWrite}
                                id="userTrainingAddProcessTrainingButtonId"
                                onClick={handleOpenRepository}
                            >
                                <div className={classes.buttonContainer}>
                                    <AddIcon />
                                </div>
                            </ListItem>
                        </List>
                    </Tooltip>
                </div>
                {/* TRAINED */}
                <div className={classes.column}>
                    <Typography className={classes.text}>{translate("training.trained").toUpperCase()}</Typography>
                </div>
                {/* CERTIFIED */}
                <div className={classes.column}>
                    <Typography className={classes.text}>{translate("training.certified").toUpperCase()}</Typography>
                </div>
                {/* VERSION */}
                <div className={classes.column}>
                    <Typography className={classes.text}>{translate("training.currentlyReleasedProcessVersion").toUpperCase()}</Typography>
                </div>
                {/* ORIGINAL VERSION */}
                <Tooltip classes={{ tooltip: classes.tooltips }} title={translate("training.tooltipOriginalVersion")} placement="top">
                    <div className={classes.column}>
                        <Typography className={classes.text}>{translate("training.originalVersion").toUpperCase()}</Typography>
                    </div>
                </Tooltip>
                {/* LEVEL OF CHANGE */}
                <div className={classes.column}>
                    <Typography className={classes.text}>{translate("training.levelOfChange").toUpperCase()}</Typography>
                </div>
            </div>
        </KortexPanelCard>
    );
};

export default UserTrainingsCardHeader;
