import { KortexDialogConfirmation, KortexTextField, theme } from "@aos/react-components";
import { EnumUserStatus, IUserDbModel, UserId } from "@kortex/aos-common";
import { useKeybind, useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Checkbox, CircularProgress, Divider, InputAdornment, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import { useEntitiesUsers, useEntitiesUserSession, useEntitiesUsersGroups } from "../../../redux/effects";
import { userValidate } from "../../../redux/user-manager/users-thunks-user";
import { TextFieldSelect } from "../TextFieldSelect";
import { Item } from "../TextFieldSelect/TextFieldSelectMenu";

const useStyles = makeStyles({
    circularProgress: {
        height: "24px !important", // Overwrite the style (set by material-ui) that is applied on top of this class
        marginRight: "10px",
        width: "24px !important", // Overwrite the style (set by material-ui) that is applied on top of this class
    },
    consentSection: {
        alignItems: "center",
        display: "flex",
        marginBottom: "20px",
    },
    divider: {
        marginBottom: "20px",
    },
    electronicSignature: {
        marginBottom: "20px",
    },
    electronicSignatureDateUTC: {
        color: theme.palette.primary[500],
        fontSize: "1.2rem",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    electronicSignatureText: {
        whiteSpace: "pre-line",
    },
    formContainer: {
        alignItems: "stretch",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        marginBottom: "15px",
        marginTop: "15px",
        padding: "0 30px",
    },
    textfield: {
        margin: 0,
        marginBottom: "20px",
    },
    typographyContext: {
        marginBottom: "20px",
    },
});

const NO_USER_SELECTED_ID = -1;

export interface IOwnProps {
    electronicSignatureContext?: React.ReactNode;
    excludeSelf?: boolean;
    isElectronicSignature?: boolean;
    labelContext?: string;
    loadingUsers?: boolean;
    onClose: () => void;
    onValidate: (validatedByUserId: number, username: string, password: string, consented: boolean) => Promise<void>;
    open: boolean;
    preSelectedUserId?: number;
    userFilterCb?: (user: IUserDbModel, index: number, array: IUserDbModel[]) => boolean;
    userIds?: UserId[];
    validationGroupsId?: number[];
}

export default function UserValidationDialog(props: IOwnProps): JSX.Element {
    const {
        electronicSignatureContext,
        excludeSelf = false,
        isElectronicSignature,
        labelContext,
        loadingUsers,
        onClose,
        onValidate,
        open,
        preSelectedUserId,
        userFilterCb = (): boolean => true,
        userIds,
        validationGroupsId,
    } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const dispatch = useThunkDispatch();
    const groups = useEntitiesUsersGroups();
    const session = useEntitiesUserSession();
    const users = useEntitiesUsers();

    const [consentChecked, setConsentChecked] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [selectedUserId, setSelectedUserId] = useState<number>(NO_USER_SELECTED_ID);
    const [loginError, setLoginError] = useState<string>("");
    const [validationSent, setValidationSent] = useState(false);

    const validateButtonDisabled =
        selectedUserId === NO_USER_SELECTED_ID || password === "" || (isElectronicSignature && !consentChecked) || validationSent;

    useStopKeybindPropagation(open);

    /**
     * Key down event - Enter
     * Validate the user and password
     */
    useKeybind("Enter", () => handleUserValidation(), {
        disabled: !open && validateButtonDisabled,
        preventDefault: true,
        stopPropagation: true,
    });

    /**
     * Update inner states when dialog opens or closes
     */
    useEffect((): void => {
        if (open) {
            setSelectedUserId(preSelectedUserId ?? NO_USER_SELECTED_ID);
            setValidationSent(false);
        } else {
            setConsentChecked(false);
            setPassword("");
            setLoginError("");
        }
    }, [open]);

    /**
     * Whenever groups and users change, make sure selected group and users are still valid
     */
    useEffect((): void => {
        if (preSelectedUserId) {
            setSelectedUserId(preSelectedUserId);
        }

        // Validate only when the dialog is open
        if (!open) {
            return;
        }

        if (!getValidUsers().length && !preSelectedUserId) {
            onValidate(0, "", "", false);
        }
    }, [groups, preSelectedUserId, users]);

    /**
     * Handles user validation
     */
    const handleUserValidation = async (): Promise<void> => {
        setValidationSent(true);

        const user = users.find((user: IUserDbModel): boolean => user.userId === selectedUserId);

        if (user && user.userId !== undefined && (await dispatch(userValidate(user.userName, password)))) {
            onValidate(user.userId, user.userName, password, consentChecked).finally(() => setValidationSent(false));
        } else {
            setLoginError(translate("player.wrongCredentials"));
            setValidationSent(false);
        }
    };

    /**
     * Handles password change
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - event info
     */
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
    };

    /**
     * Handles user change
     *
     * @param {number} value - value
     */
    const handleUserChange = (value: number): void => {
        setSelectedUserId(value);
    };

    /**
     * Returns a list of items for the the user selector
     */
    const getUserItems = (): Item[] => {
        if (!open) return [];

        return getValidUsers()
            .sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)) // Sort alphabetically
            .map((user) => {
                const label = user.firstName + " " + user.lastName;

                return {
                    component: <Typography>{label}</Typography>,
                    filterCallback: (value: string): boolean =>
                        value ? label.toLocaleLowerCase().includes(value.toLocaleLowerCase()) : true,
                    textFieldValue: label,
                    value: user.userId,
                };
            });
    };

    /**
     * Create a user list that will be selectable in the dropdown
     */
    const getValidUsers = (): IUserDbModel[] => {
        // If a userId is passed as prop, the dropdown should be disabled and automatically select the correct user
        if (preSelectedUserId) {
            const user = users.find((user) => user.userId === preSelectedUserId);

            if (user) {
                return [user];
            }

            return [];
        }

        // Return a list of all valid users
        return users.filter(
            (user, index, array) =>
                (!userIds || userIds?.includes(user.userId)) &&
                // Remove users that are not part of selected group (if provided)
                (!validationGroupsId?.length || user.groups.some((group) => validationGroupsId.includes(group))) &&
                // Apply filter given as prop
                userFilterCb(user, index, array) &&
                // Keep only active users
                user.status === EnumUserStatus.ACTIVE &&
                // Remove logged in user
                !(Boolean(session?.userId) && excludeSelf && session?.userId === user.userId)
        );
    };

    /**
     * handle close event
     */
    const handleCloseDialog = (): void => {
        onClose();
        setLoginError("");
    };

    /**
     * Called when the consent checkbox is checked/unchecked
     */
    const handleConsent = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        setConsentChecked(checked);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={validateButtonDisabled}
            onCancel={handleCloseDialog}
            onConfirm={handleUserValidation}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("player.close"),
                proceedButtonLabel: translate(isElectronicSignature ? "player.sign" : "player.validate"),
                titleLabel: translate(isElectronicSignature ? "player.electronicSignatureRequired" : "player.validationRequired"),
            }}
            textLabelsIcons={{
                proceedButtonIcon: validationSent ? <CircularProgress className={classes.circularProgress} /> : undefined,
            }}
            dialogProps={{
                id: "userValidationDialogId",
            }}
        >
            <div className={classes.formContainer} id={"userValidationDialogFormId"}>
                {labelContext && <Typography className={classes.typographyContext}>{labelContext}</Typography>}
                {labelContext && isElectronicSignature && electronicSignatureContext && <Divider className={classes.divider} />}
                {isElectronicSignature && electronicSignatureContext && (
                    <div className={classes.electronicSignature}>
                        <Typography className={classes.electronicSignatureText}>{electronicSignatureContext}</Typography>
                    </div>
                )}
                <TextFieldSelect
                    disabled={Boolean(preSelectedUserId) || loadingUsers}
                    KortexTextFieldProps={{
                        className: classes.textfield,
                        InputProps: {
                            endAdornment: loadingUsers ? (
                                <InputAdornment position="end">
                                    <CircularProgress className={classes.circularProgress} />
                                </InputAdornment>
                            ) : null,
                        },
                        label: translate("player.user"),
                        TextFieldProps: {
                            id: "usernameInputId",
                            placeholder: translate("player.selectTrainedOperator"),
                        },
                    }}
                    items={getUserItems()}
                    onChange={handleUserChange}
                    value={selectedUserId}
                />
                <KortexTextField
                    className={classes.textfield}
                    error={loginError}
                    label={translate("player.password")}
                    onChange={handlePasswordChange}
                    TextFieldProps={{
                        autoComplete: "current-password",
                        id: "passwordInputId",
                    }}
                    type="password"
                    value=""
                    variant="outlined"
                />
                {isElectronicSignature && (
                    <div className={classes.consentSection}>
                        <Checkbox checked={consentChecked} onChange={handleConsent} id="checkboxConsentId" />
                        <Typography>{translate("general.electronicSignatureConsent")}</Typography>
                    </div>
                )}
            </div>
        </KortexDialogConfirmation>
    );
}
