export const reworkJobProcess = {
    rpc: {
        getAll: {
            funcId: 3301,
            funcName: "reworkJobProcessAll",
        },
        insert: {
            funcId: 3302,
            funcName: "reworkJobProcessInsert",
        },
        inserted: {
            funcId: 3303,
            funcName: "reworkJobProcessInserted",
        },
        delete: {
            funcId: 3304,
            funcName: "reworkJobProcessDelete",
        },
        deleted: {
            funcId: 3305,
            funcName: "reworkJobProcessDeleted",
        },
    },
    serviceId: 33,
    serviceVersion: 1,
};
