import { KortexTextField, theme } from "@aos/react-components";
import { ApprovalStatusEnum, ProcessUiModel, getFormattedDate } from "@kortex/aos-common";
import KortexLabelText from "@kortex/aos-ui/components/core/KortexLabelText";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { useEffect, useState } from "react";

import { useForeground } from "../../../../hooks/useForeground";
import { useTranslate } from "../../../../hooks/useTranslate";
import { processUpdate } from "../../../../redux/process-manager/process-thunks-process";
import { useSelectorProcesses } from "../../../../redux/selectors";

const useStyles = makeStyles({
    main: {
        minWidth: "500px",
    },
    versions: {
        padding: "0 10px",
        marginTop: "-40px",
    },
    header: {
        display: "flex",
    },
    version: {
        padding: "0 5px",
        color: theme.palette.primary[500],
        marginTop: "15px",
        textTransform: "capitalize",
    },
    previousVersion: {
        backgroundColor: "#ecf4ff",
        padding: "10px",
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
    changeLog: {
        width: "100%",
    },
    buttonsBar: {
        display: "flex",
        justifyContent: "flex-end",
    },
});

interface IOwnProps {
    onClose: () => void;
    process: ProcessUiModel;
    readOnly?: boolean;
}

export default function ChangeLogs(props: IOwnProps): JSX.Element {
    const { onClose, process, readOnly = false } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();

    const processes = useSelectorProcesses();

    const [versions, setVersions] = useState<ProcessUiModel[]>([]);
    const [changeLog, setChangeLog] = useState<string>(process.versionChangeLog || "");

    useForeground();

    // effect to reset versions on processes change
    useEffect((): void => {
        setVersions(getVersions());
        setChangeLog(process.versionChangeLog ?? "");
    }, [processes, process]);

    /**
     * Filter and order process versions
     */
    const getVersions = (): ProcessUiModel[] => {
        if (process) {
            let versions: ProcessUiModel[] = [];
            versions = processes.filter(
                (proc) =>
                    proc.treeNodeId === process.treeNodeId &&
                    proc.version &&
                    process.version &&
                    // only show prior versions changes (no need to check temporality if current process is draft)
                    (proc.processId <= process.processId || process.isDraft) &&
                    // only show approved versions AND the current version's changelog
                    (proc.versionStatus === ApprovalStatusEnum.APPROVED || process.version === proc.version)
            );

            // sort
            const sortedVersions = versions.sort((version1, version2) => version1.processId - version2.processId);

            // Reverse the order of the versions, so that the more recent versions are displayed first
            sortedVersions.reverse();

            if (process.isDraft) {
                // move draft (last element at first)
                const draft = sortedVersions.pop();
                if (draft) {
                    sortedVersions.unshift(draft);
                }
            }

            return sortedVersions;
        } else {
            return [];
        }
    };

    /**
     * Handles the changelog changes
     *
     * @param {object} value - change event value
     */
    const handleChange = (value: React.ReactText): void => {
        const newValue = value.toString();
        if (newValue !== changeLog) {
            dispatch(processUpdate({ ...process, versionChangeLog: newValue }));
        }
    };

    /**
     * Calls onBlur when value text field is blurred
     */
    const handleValueBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        const newValue = event.target.value;
        if (newValue !== changeLog) {
            dispatch(processUpdate({ ...process, versionChangeLog: newValue }));
        }
    };

    return (
        <div className={classes.main} id="changeLogDialogDrawerId">
            <div className={classes.buttonsBar}>
                <IconButton id="changeLogDialogDrawerCloseId" color="primary" component="span" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </div>
            <div className={classes.versions}>
                <Typography variant="h4">{translate("processEditorSideMenu.changeLogs")}</Typography>
                {versions.map((version, index) => (
                    <div id={"changeLogEntryId"} key={index}>
                        <div id={`changeLogHeaderVersion${index}Id`} className={classes.header}>
                            <KortexLabelText
                                label={translate("process.versioning.version")}
                                text={version.version ?? ""}
                                className={classes.version}
                                id={`changeLogEntryVersion${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("process.versioning.releasedDate")}
                                text={getFormattedDate(version.releasedOn ?? 0, true)}
                                className={classes.version}
                                id={`releaseDateVersion${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("process.versioning.retiredDate")}
                                text={getFormattedDate(version.retiredOn ?? 0, true)}
                                className={classes.version}
                                id={`retiredDateVersion${index}Id`}
                            />
                        </div>
                        {version.isDraft && !readOnly ? (
                            <>
                                <KortexTextField
                                    TextFieldProps={{ id: `changeLogEntryDetails${index}Id`, multiline: true, rows: 10, required: true }}
                                    className={classes.changeLog}
                                    value={changeLog}
                                    onChanged={handleChange}
                                    onBlur={handleValueBlur}
                                />
                            </>
                        ) : (
                            <div id={`changeLogEntryDetails${index}Id`} className={classes.previousVersion}>
                                {version.versionChangeLog
                                    ? version.versionChangeLog
                                    : version.isDraft
                                    ? translate("processChangelog.emptyMessage")
                                    : ""}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
