var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton/IconButton";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import * as React from "react";
import { useState, useEffect, useLayoutEffect } from "react";
import { primaryPalette } from "../mui/palettes";
var DEFAULT_CHANGED_DELAY = 2000;
var useStyles = makeStyles({
    root: {
        margin: "5px",
    },
    standardInputProps: {
        fontSize: "1rem",
        lineHeight: "1rem",
        padding: "5px",
    },
    standardSelectProps: {
        fontSize: "1rem",
        lineHeight: "1rem",
        padding: "5px",
    },
    helperTextProps: {
        margin: "4px 6px 0",
        fontSize: "0.7em",
    },
    plusMinusIcon: {
        "&:hover": {
            color: primaryPalette[500],
        },
    },
});
var KORTEX_TEXT_FIELD_TYPE = {
    DATE: "date",
    NUMBER: "number",
    TEXT: "text",
    PASSWORD: "password",
};
var KORTEX_TEXT_FIELD_VARIANT = {
    OUTLINED: "outlined",
    STANDARD: "standard",
};
var defaultStandardErrorMsgs = {
    aboveMax: "Must be lower than %d",
    belowMin: "Must be higher than %d",
    invalidLength: "Must have between %d and %d characters",
    invalidRange: "Must be between %d and %d",
    regexNoMatch: "Must match the format",
    tooLong: "Must have at most %d characters",
    notANumber: "Must be a valid number",
    tooShort: "Must have at least %d characters",
};
function KortexTextField(props) {
    var _a;
    var classes = useStyles(props);
    var isMultiline = Boolean((_a = props.TextFieldProps) === null || _a === void 0 ? void 0 : _a.multiline);
    var _b = props.variant, variant = _b === void 0 ? KORTEX_TEXT_FIELD_VARIANT.OUTLINED : _b, label = props.label, onChange = props.onChange, onChanged = props.onChanged, onBlur = props.onBlur, onKeyDown = props.onKeyDown, value = props.value, _c = props.blurOnEnter, blurOnEnter = _c === void 0 ? !isMultiline : _c, _d = props.submitOnEnter, submitOnEnter = _d === void 0 ? true : _d, _e = props.type, type = _e === void 0 ? KORTEX_TEXT_FIELD_TYPE.TEXT : _e, _f = props.standardErrorMsgs, standardErrorMsgs = _f === void 0 ? defaultStandardErrorMsgs : _f, _g = props.min, min = _g === void 0 ? Number.NEGATIVE_INFINITY : _g, _h = props.max, max = _h === void 0 ? Number.POSITIVE_INFINITY : _h, _j = props.withButtons, withButtons = _j === void 0 ? false : _j, _k = props.step, step = _k === void 0 ? 1 : _k, _l = props.maxLength, maxLength = _l === void 0 ? Number.POSITIVE_INFINITY : _l, _m = props.minLength, minLength = _m === void 0 ? Number.NEGATIVE_INFINITY : _m, _o = props.regex, regex = _o === void 0 ? new RegExp(".*") : _o, _p = props.changedDelayMS, changedDelayMS = _p === void 0 ? DEFAULT_CHANGED_DELAY : _p, _q = props.error, error = _q === void 0 ? "" : _q, TextFieldProps = props.TextFieldProps, InputProps = props.InputProps, children = props.children, onError = props.onError, _r = props.disableValidation, disableValidation = _r === void 0 ? false : _r, _s = props.skipStopPropagationOnHandledKey, skipStopPropagationOnHandledKey = _s === void 0 ? false : _s;
    var _t = useState(value), stateValue = _t[0], setStateValue = _t[1];
    var _u = useState(value), initialValue = _u[0], setInitialValue = _u[1];
    var _v = useState(""), errorState = _v[0], setErrorState = _v[1];
    var _w = useState(setTimeout(function () { return null; }, 0)), debounce = _w[0], setDebounce = _w[1];
    // sets local value state when the value prop changes
    useLayoutEffect(function () {
        setStateValue(value);
        validate(value);
    }, [value]);
    useEffect(function () {
        if (error || disableValidation) {
            setErrorState(error);
        }
        else {
            validate(stateValue);
        }
    }, [error]);
    useEffect(function () {
        if (onError) {
            onError(errorState);
        }
    }, [errorState]);
    useEffect(function () {
        if (!disableValidation) {
            validate(stateValue);
        }
        else {
            setErrorState(""); // removes error
        }
    }, [disableValidation]);
    /**
     * Styles the TextField based on the variant
     *
     * @returns {StandardTextFieldProps|OutlinedTextFieldProps} - Styling props
     */
    var getVariantProps = function () {
        switch (variant) {
            case KORTEX_TEXT_FIELD_VARIANT.STANDARD:
                return {
                    InputProps: withButtons
                        ? getRangeInputProps()
                        : __assign(__assign({}, InputProps), { disableUnderline: InputProps && InputProps.disableUnderline
                                ? InputProps.disableUnderline
                                : false, classes: {
                                input: classes.standardInputProps,
                            } }),
                    SelectProps: {
                        classes: {
                            select: classes.standardSelectProps,
                        },
                    },
                    variant: KORTEX_TEXT_FIELD_VARIANT.STANDARD,
                    className: "".concat(props.className, " ").concat(classes.root),
                };
            default:
                return {
                    className: "".concat(props.className, " ").concat(classes.root),
                    variant: KORTEX_TEXT_FIELD_VARIANT.OUTLINED,
                    InputProps: withButtons ? getRangeInputProps() : InputProps,
                };
        }
    };
    /**
     * Returns the required InputProps in order to add the plus/minus buttons
     *
     * @returns {InputBaseProps} Props injected in the underlying Input
     */
    var getRangeInputProps = function () {
        return {
            endAdornment: (React.createElement(IconButton, { onClick: incrementOrDecrement(true), classes: { root: classes.plusMinusIcon } },
                React.createElement(PlusIcon, null))),
            startAdornment: (React.createElement(IconButton, { onClick: incrementOrDecrement(false), classes: { root: classes.plusMinusIcon } },
                React.createElement(MinusIcon, null))),
            style: {
                padding: 0,
            },
            inputProps: {
                style: {
                    textAlign: "center",
                },
            },
        };
    };
    /**
     * Called when a user presses the +/- buttons on number inputs
     *
     * @param {boolean} increment - boolean to distinguish increment from decrement
     */
    var incrementOrDecrement = function (increment) {
        return function () {
            var parsedValue;
            if (typeof stateValue === "string") {
                parsedValue = parseFloat(stateValue);
            }
            else {
                parsedValue = stateValue;
            }
            parsedValue += increment ? step : -step;
            if (parsedValue >= max) {
                parsedValue = max;
            }
            if (parsedValue <= min) {
                parsedValue = min;
            }
            if (errorState) {
                handleChange(increment ? min.toString() : max.toString(10));
            }
            else {
                handleChange(parsedValue.toString(10));
            }
        };
    };
    /**
     * Called when the input value changes.
     * Sets local value state and starts the debounce timeout.
     *
     * @param {React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | string} event - Change event
     */
    var handleChange = function (event) {
        clearTimeout(debounce);
        var val;
        if (typeof event === "string" || typeof event === "number") {
            val = event;
        }
        else {
            val = event.target.value;
        }
        setStateValue(val);
        if (validate(val)) {
            setDebounce(setTimeout(function () {
                if (onChanged && value !== val) {
                    submit(val);
                }
            }, changedDelayMS));
        }
        // injected behavior
        if (onChange) {
            if (typeof event !== "string" && typeof event !== "number") {
                onChange(event);
            }
        }
    };
    /**
     * Sets the error state based on the input's type (number or text)
     * Numbers: checks min, max and format
     * Texts: checks minLength, maxLength and regex
     *
     * @param {?} value - value to validate
     * @returns {boolean} True if the value is valid, false otherwise
     */
    var validate = function (value) {
        // error from prop has priority over stateError
        if (disableValidation) {
            setErrorState(""); // removes error
            return true;
        }
        if (error) {
            return false;
        }
        if (type === KORTEX_TEXT_FIELD_TYPE.NUMBER) {
            // @ts-ignore; should be a parsable string
            var parsedValue = parseFloat(value);
            var minIsSet = min !== Number.NEGATIVE_INFINITY;
            var maxIsSet = max !== Number.POSITIVE_INFINITY;
            // check if it's a valid number
            if (isNaN(parsedValue)) {
                setErrorState(standardErrorMsgs.notANumber ? standardErrorMsgs.notANumber : defaultStandardErrorMsgs.notANumber);
                return false;
                // check if both min and max are respected
            }
            else if (minIsSet && maxIsSet && (parsedValue < min || parsedValue > max)) {
                setErrorState(injectVariables(standardErrorMsgs.invalidRange ? standardErrorMsgs.invalidRange : defaultStandardErrorMsgs.invalidRange, [min, max]));
                return false;
                // check if only min is respected
            }
            else if (minIsSet && !maxIsSet && parsedValue < min) {
                setErrorState(injectVariables(standardErrorMsgs.belowMin ? standardErrorMsgs.belowMin : defaultStandardErrorMsgs.belowMin, [min]));
                return false;
                // check if only max is respected
            }
            else if (maxIsSet && !minIsSet && parsedValue > max) {
                setErrorState(injectVariables(standardErrorMsgs.aboveMax ? standardErrorMsgs.aboveMax : defaultStandardErrorMsgs.aboveMax, [max]));
                return false;
            }
        }
        else if (type === KORTEX_TEXT_FIELD_TYPE.TEXT || type === KORTEX_TEXT_FIELD_TYPE.PASSWORD) {
            var minIsSet = minLength !== Number.NEGATIVE_INFINITY;
            var maxIsSet = maxLength !== Number.POSITIVE_INFINITY;
            // check if both minLength and maxLength are respected
            // @ts-ignore; should be a parsable string
            if (minIsSet && maxIsSet && (value.length < minLength || value.length > maxLength)) {
                setErrorState(injectVariables(standardErrorMsgs.invalidLength ? standardErrorMsgs.invalidLength : defaultStandardErrorMsgs.invalidLength, [minLength, maxLength]));
                return false;
                // check if minLength is respected
                // @ts-ignore; should be a parsable string
            }
            else if (minIsSet && !maxIsSet && value.length < minLength) {
                setErrorState(injectVariables(standardErrorMsgs.tooShort ? standardErrorMsgs.tooShort : defaultStandardErrorMsgs.tooShort, [
                    minLength,
                ]));
                return false;
                // check if maxLength is respected
                // @ts-ignore; should be a parsable string
            }
            else if (maxIsSet && !minIsSet && value.length > maxLength) {
                setErrorState(injectVariables(standardErrorMsgs.tooLong ? standardErrorMsgs.tooLong : defaultStandardErrorMsgs.tooLong, [maxLength]));
                return false;
                // check if validation regex is respected
                // @ts-ignore; should be a parsable string
            }
            else if (!regex.test(value)) {
                setErrorState(standardErrorMsgs.regexNoMatch ? standardErrorMsgs.regexNoMatch : defaultStandardErrorMsgs.regexNoMatch);
                return false;
            }
        }
        setErrorState(""); // removes error
        return true;
    };
    /**
     * Takes a string and replaces the %d with variables
     *
     * @param {string} template - string containing %d to be replaced
     * @param {number[]} variables - variables with which we replace the %d
     * @returns {string} Modified string
     */
    var injectVariables = function (template, variables) {
        var newValue = template;
        variables.forEach(function (variable) {
            newValue = newValue.replace("%d", variable.toString(10));
        });
        return newValue;
    };
    /**
     * Called when the input blurs.
     * Triggers the onChanged if the value is different.
     */
    var handleBlur = function (e) {
        clearTimeout(debounce);
        if (onChanged && value !== stateValue && !errorState) {
            submit(stateValue);
        }
        // injected behavior
        if (onBlur) {
            onBlur(e);
        }
    };
    /**
     * Called when the input is selected
     * Keep the original value in state for later use if the user presses escape to revert the changes.
     */
    var handleFocus = function () {
        setInitialValue(stateValue);
    };
    /**
     * Called on a keyDown event from the input.
     * Sends onChanged when Escape or Enter is pressed
     */
    var handleKeyDown = function (e) {
        var handled = false;
        if (e.key === "Escape") {
            handled = true;
            clearTimeout(debounce);
            // Verify the the current state value is the same as the orignal value. If not, update the state only and wait for another escape key.
            if (initialValue != stateValue) {
                setStateValue(initialValue);
            }
            else {
                e.target.blur();
            }
        }
        else if (e.key === "Enter") {
            handled = true;
            if ((value !== stateValue && onChanged && !errorState && submitOnEnter && type === KORTEX_TEXT_FIELD_TYPE.NUMBER) ||
                !isMultiline) {
                clearTimeout(debounce);
                submit(stateValue);
            }
            blurOnEnter && e.target.blur();
        }
        if (handled) {
            if (!isMultiline)
                e.preventDefault();
            if (!skipStopPropagationOnHandledKey)
                e.stopPropagation();
        }
        // injected behavior
        onKeyDown && onKeyDown(e);
    };
    /**
     * Dispatches the new value in the onChanged callback
     *
     * @param {string | number} val - Value to dispatch
     */
    var submit = function (val) {
        if (onChanged) {
            onChanged(type === KORTEX_TEXT_FIELD_TYPE.NUMBER && typeof val === "string" ? parseFloat(val) : val);
        }
    };
    return (React.createElement(TextField, __assign({}, getVariantProps(), TextFieldProps, { InputLabelProps: TextFieldProps ? __assign({ shrink: true }, TextFieldProps.InputLabelProps) : { shrink: true }, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, value: stateValue, onKeyDown: handleKeyDown, error: errorState.length > 0, helperText: errorState, FormHelperTextProps: {
            className: classes.helperTextProps,
        }, label: label, type: type }), children));
}
export default KortexTextField;
