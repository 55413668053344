import { greyPalette } from "@aos/react-components";
import { makeStyles } from "@material-ui/core";
import React from "react";

import KortexLabel from "./KortexLabel";

const useStyles = makeStyles({
    text: {
        color: greyPalette[700],
    },
});
interface IStyleLabelText {
    styleLabel?: React.CSSProperties;
    styleText?: React.CSSProperties;
}

interface IOwnProps {
    label: string;
    text: string;
    id?: string;

    className?: string;

    style?: React.CSSProperties;
    styleLabelText?: IStyleLabelText;
}

export default function KortexLabelText(props: IOwnProps): JSX.Element {
    const { className, label, style, text, id, styleLabelText } = props;

    const classes = useStyles();

    return (
        <div className={className} style={{ display: "flex", flexDirection: "column", ...style }}>
            <KortexLabel label={label} variant="compact" styleLabel={styleLabelText?.styleLabel} />
            <span className={classes.text} id={id} style={{ ...styleLabelText?.styleText }}>
                {text}
            </span>
        </div>
    );
}
