export const PARSER_REGEX_ACTION = {
    EXTRACT: "extract",
    SUBSTITUTION: "substitution",
    VALIDATION: "validation",
};
export class ParserRegexExtract {
    constructor() {
        this.action = PARSER_REGEX_ACTION.EXTRACT;
        this.endFlags = "";
        this.endRegex = "";
        this.startFlags = "";
        this.startRegex = "";
    }
}
export class ParserRegexSubstitution {
    constructor() {
        this.action = PARSER_REGEX_ACTION.SUBSTITUTION;
        this.flags = "";
        this.regex = "";
        this.substitution = "";
    }
}
export class ParserRegexValidation {
    constructor() {
        this.action = PARSER_REGEX_ACTION.VALIDATION;
        this.flags = "";
        this.regex = "";
    }
}
