import { upsertObjectFromArray } from "@kortex/aos-common";
import { deepClone } from "@kortex/utilities";

import {
    TrainingReadingCertificatePendingAction,
    TrainingReadingCertificatePendingActionType,
    TrainingReadingCertificatePendingState,
} from "./training-reading-certificate-pending-types";

const initialState: TrainingReadingCertificatePendingState = {
    readingCertificatePendings: [],
};

export function trainingReadingCertificatePendingReducer(
    state: TrainingReadingCertificatePendingState = initialState,
    action: TrainingReadingCertificatePendingAction
): TrainingReadingCertificatePendingState {
    switch (action.type) {
        case TrainingReadingCertificatePendingActionType.CLEAR_READING_CERTIFICATE_PENDING:
            return {
                ...state,
                readingCertificatePendings: [],
            };
        case TrainingReadingCertificatePendingActionType.SET_READING_CERTIFICATE_PENDING:
            return {
                ...state,
                readingCertificatePendings: [...action.value],
            };
        case TrainingReadingCertificatePendingActionType.UPSERT_READING_CERTIFICATE_PENDING: {
            const stateClone = deepClone(state);

            for (const pending of action.value) {
                const index = stateClone.readingCertificatePendings.findIndex((p) => p.user.userId === pending.user.userId);

                if (index === -1) {
                    stateClone.readingCertificatePendings.push(pending);
                } else {
                    stateClone.readingCertificatePendings[index] = pending;
                }
            }

            return stateClone;
        }
        case TrainingReadingCertificatePendingActionType.DELETE_READING_CERTIFICATE_PENDING: {
            const stateClone = deepClone(state);

            return {
                ...stateClone,
                readingCertificatePendings: stateClone.readingCertificatePendings.filter((p) => !action.value.includes(p.user.userId)),
            };
        }
        case TrainingReadingCertificatePendingActionType.UPSERT_TRAINING_INTO_READING_CERTIFICATE_PENDING: {
            const stateClone = deepClone(state);

            for (const pending of action.value) {
                const indexUser = stateClone.readingCertificatePendings.findIndex((p) => p.user.userId === pending.user.userId);

                if (indexUser === -1) {
                    stateClone.readingCertificatePendings.push(pending);
                } else {
                    stateClone.readingCertificatePendings[indexUser].trainings = upsertObjectFromArray(
                        stateClone.readingCertificatePendings[indexUser].trainings,
                        pending.trainings,
                        function (training) {
                            return training.training.trainingId === this.training.trainingId;
                        }
                    );
                }
            }

            return stateClone;
        }
        case TrainingReadingCertificatePendingActionType.DELETE_TRAINING_FROM_READING_CERTIFICATE_PENDING: {
            const stateClone = deepClone(state);

            for (let i = 0; i < stateClone.readingCertificatePendings.length; i++) {
                // Remove trainings
                stateClone.readingCertificatePendings[i].trainings = stateClone.readingCertificatePendings[i].trainings.filter(
                    ({ training }) => !action.value.includes(training.trainingId)
                );
            }

            // Remove user if there are no training remaining
            stateClone.readingCertificatePendings = stateClone.readingCertificatePendings.filter((pending) => pending.trainings.length);

            return stateClone;
        }

        default:
            return state;
    }
}
