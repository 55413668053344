import * as React from "react";

import { useTranslate } from "../../../hooks/useTranslate";

import { pageHeight, pageMargin, pageWidth, pageY } from "./printUtils";

const PAGING_X_CENTER_OFFSET = 100;

const PAGING_X_OFFSET = 80;
const PAGING_Y_OFFSET = 30;

export interface IPage {
    children?: JSX.Element;
    pageIndex: number;
    totalPage: number;
}

export function PrintBasePage(props: IPage): JSX.Element {
    const { children, pageIndex, totalPage } = props;

    const translate = useTranslate();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            x={pageMargin}
            y={pageY(pageIndex)}
            width={pageWidth}
            height={pageHeight}
            style={{ backgroundColor: "white", fontFamily: "Helvetica" }}
        >
            {/* PLACE HOLDER FOR INJETED STYLE */}
            <style />
            <rect x="0" y="0" width="100%" height="100%" fill="white" />
            {children}

            <text x={pageWidth / 2 - PAGING_X_CENTER_OFFSET} y={pageHeight - PAGING_Y_OFFSET} width="100%" height="100%" stroke="black">
                {translate("process.printing.uncontrolledPrintedCopy")}
            </text>

            <text x={pageWidth - PAGING_X_OFFSET} y={pageHeight - PAGING_Y_OFFSET} width="100%" height="100%" stroke="black">
                {pageIndex + 1 + " / " + totalPage}
            </text>
        </svg>
    );
}
