import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { IWorkInstructionsElementConfig, IWorkInstructionsImageConfig, ProcessEditorRightsEnum } from "@kortex/aos-common";
import { useForeground } from "@kortex/aos-ui/hooks/useForeground";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntityFileByFileId } from "@kortex/aos-ui/redux/effects";
import { IUserRightsProps } from "@kortex/aos-ui/utilitites/IUserRights";
import { deepClone } from "@kortex/utilities";
import { makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";

import { WorkInstructionsEditorContext } from "../../../utilities";

const useStyles = makeStyles({
    dialogContentContainer: {
        display: "grid",
        gridRowGap: "10px",
    },
});

interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    disabled?: boolean;
    element: IWorkInstructionsElementConfig<IWorkInstructionsImageConfig>;
    fileId: string;
    onClose: () => void;
    open: boolean;
}

function WorkInstructionsImageTooltipEditor(props: IOwnProps): JSX.Element {
    const { disabled, element, fileId, onClose, open } = props;

    const classes = useStyles();
    const context = useContext(WorkInstructionsEditorContext);
    const file = useEntityFileByFileId(fileId);
    const translate = useTranslate();

    const [customTooltip, setCustomTooltip] = useState<string>(element.extendedProps.customTooltip);

    if (!file) {
        return <div />;
    }

    useForeground(open);
    useStopKeybindPropagation(open);

    const handleSaveTooltip = (): void => {
        const elementCopy = deepClone(element);
        elementCopy.extendedProps.customTooltip = customTooltip;

        context.updateElement(elementCopy);
        onClose();
    };

    const handleTooltipChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setCustomTooltip(event.target.value);
    };

    return (
        <KortexDialogConfirmation
            open={open}
            textLabels={{
                titleLabel: translate("action.workInstructions.image.tooltipEditor.title"),
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.save"),
            }}
            onConfirm={handleSaveTooltip}
            onCancel={onClose}
            closeOnEscape={true}
            dialogProps={{
                disableBackdropClick: true,
            }}
        >
            <div className={classes.dialogContentContainer}>
                <KortexTextField
                    label={translate("action.workInstructions.image.tooltipEditor.originalTooltip")}
                    TextFieldProps={{
                        disabled: true,
                        multiline: true,
                        rows: 5,
                    }}
                    value={file?.tooltip ?? ""}
                />
                <KortexTextField
                    label={translate("action.workInstructions.image.tooltipEditor.customTooltip")}
                    onChange={handleTooltipChange}
                    TextFieldProps={{
                        disabled,
                        multiline: true,
                        rows: 5,
                    }}
                    value={customTooltip}
                />
            </div>
        </KortexDialogConfirmation>
    );
}

export default WorkInstructionsImageTooltipEditor;
