import { DEFAULT_VERSION } from "../../../shared/constants";
import { ApprovalStatusEnum, ProcessTrainingRequirementEnum } from "./IProcessDbModel";
/**
 * Creates a new process ui model
 */
export function newProcessUiModel(treeNodeId, type, bomId = "", bomRev = "") {
    return {
        actions: [],
        releaseLogs: [],
        approvalGroups: [],
        isDraft: true,
        locMessages: {},
        processId: 0,
        treeNodeId,
        type,
        variables: [],
        version: DEFAULT_VERSION,
        trainingRequirement: ProcessTrainingRequirementEnum.NONE,
        standardTime: 0,
        bomId,
        bomRev,
    };
}
/**
 * Converts a process database model to a ui model
 *
 * @param {ProcessDbModel} process - process database model
 * @param {ProcessAction[]} [actions] - process actions
 */
export function toUiModel(process, actions = [], releaseLogs = [], approvalGroups = []) {
    return {
        ...process,
        actions,
        approvalGroups,
        releaseLogs,
        variables: process.variables.map((variable) => ({
            ...variable,
            defaultValue: Array.isArray(variable.defaultValue)
                ? variable.defaultValue.length === 1
                    ? variable.defaultValue[0]
                    : variable.defaultValue
                : variable.defaultValue,
        })),
    };
}
/**
 * Converts a process ui model to a database model
 *
 * @param {ProcessUiModel} process - process ui model
 */
export function toDbModel(process) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { actions, ...processDbModel } = process;
    return processDbModel;
}
/**
 * Filter all versions that are approved
 *
 * @param {ProcessUiModel[]} versionList - list of versions
 * @returns {ProcessUiModel[]} - list of approved process versions
 */
export function getApprovedProcessVersions(versionList) {
    return versionList.filter((version) => version.versionStatus === ApprovalStatusEnum.APPROVED);
}
/**
 * Filter all versions that are approved and not retired
 *
 * @param {ProcessUiModel[]} versionList - list of versions
 * @returns {ProcessUiModel[]} - list of approved and not retired process versions
 */
export function getApprovedAndNotRetiredProcessVersions(versionList) {
    const now = Date.now();
    return versionList.filter((version) => Boolean(version.versionStatus === ApprovalStatusEnum.APPROVED && (!version.retiredOn || version.retiredOn >= now)));
}
/**
 * Filter all versions that are training requirement
 *
 * @param {ProcessUiModel[]} versionList - list of versions
 * @returns {ProcessUiModel[]} - list of approved and not retired process versions
 */
export function getTrainingRequirementProcessVersions(versionList) {
    return versionList.filter((version) => version.versionStatus === ApprovalStatusEnum.APPROVED && version.trainingRequirement > 0);
}
/**
 * Filter all versions that are released
 *
 * @param {ProcessUiModel[]} versionList - list of versions
 * @returns {ProcessUiModel[]} - list of released process versions
 */
export function getReleasedProcessVersions(versionList) {
    const now = Date.now();
    return versionList.filter((version) => 
    // Show released version
    // Release date < Now < Retire date
    Boolean(version.releasedOn && version.releasedOn <= now && (!version.retiredOn || version.retiredOn >= now)));
}
/**
 * Filter all versions that are released (may be retired)
 *
 * @param {ProcessUiModel[]} versionList - list of versions
 * @returns {ProcessUiModel[]} - list of released process versions
 */
export function getReleasedOrRetiredProcessVersions(versionList) {
    return versionList.filter((version) => 
    // Show released version
    // Release date sometimes
    Boolean(version.releasedOn && version.releasedOn > 0));
}
/**
 * Filter all versions that are not draft
 *
 * @param {ProcessUiModel[]} versionList - list of versions
 * @returns {ProcessUiModel[]} - list of process versions without draft
 */
export function getProcessVersionsWithoutDraft(versionList) {
    return versionList.filter((version) => version.isDraft !== true);
}
