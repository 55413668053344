/**
 * Creates a new training certification pending model
 *
 */
export function newTrainingCertificationPendingModel(options) {
    const { trainingCertificationId, userId } = options;
    return {
        trainingCertificationPendingId: 0,
        trainingCertificationId,
        userId,
    };
}
