import { theme } from "@aos/react-components";
import {
    IWorkInstructionsElementConfig,
    IWorkInstructionsFormConfig,
    IWorkInstructionsFormItemState,
    WorkInstructionsFormItemState,
} from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useRef } from "react";

import WorkInstructionsFormInput from "./FormInputs/WorkInstructionsFormInput";

const useStyles = makeStyles({
    container: {
        height: "100%",
        display: "block",
        position: "relative",
        backgroundColor: theme.palette.common.white,
        padding: "0px 8px 0px 8px",
        borderRadius: "5px",
    },
    formListItem: {
        padding: "8px 0px 0px 0px",
        display: "block",
    },
    disabled: {
        pointerEvents: "none",
    },
    scrollable: {
        overflowY: "auto",
    },
});

export interface IOwnProps {
    disabled?: boolean;
    elementProps: IWorkInstructionsElementConfig<IWorkInstructionsFormConfig>;
    formItemsStates: IWorkInstructionsFormItemState[];
    onEnterPressed?: (id: string) => void;
    onStateChanged: (formItemState: IWorkInstructionsFormItemState) => void;
}

export default function WorkInstructionsForm(props: IOwnProps): JSX.Element {
    const classes = useStyles();
    const { disabled, elementProps, formItemsStates, onEnterPressed, onStateChanged } = props;
    const { extendedProps, height, rotation, scale, width, x, y } = elementProps;
    const inputsContainerRef = useRef<HTMLDivElement | null>(null);

    /**
     * Called when a user edits an input in a WorkInstructions Form
     *
     * @param {IWorkInstructionsFormItemState} inputState - New state of the form item that changed
     */
    const onStateChange = (inputState: IWorkInstructionsFormItemState): void => {
        onStateChanged(inputState);
    };

    return (
        <g transform={`translate(${x},${y}) rotate(${rotation} ${width / 2} ${height / 2}) scale(${scale})`}>
            <foreignObject width={width} height={height} className={classes.scrollable}>
                <div
                    xmlns="http://www.w3.org/1999/xhtml"
                    className={`${classes.container} ${disabled === true ? classes.disabled : ""}`}
                    ref={inputsContainerRef}
                >
                    {extendedProps.formItems.map((itemProps, index) => {
                        const formState =
                            formItemsStates.find((itemState) => itemState._id === itemProps._id) ||
                            new WorkInstructionsFormItemState("", "", "", itemProps.subValues);
                        return (
                            <div className={classes.formListItem} key={index}>
                                <WorkInstructionsFormInput
                                    autoFocus={!disabled && index === 0}
                                    itemProps={itemProps}
                                    itemState={formState}
                                    onStateChanged={onStateChange}
                                    onEnterPressed={onEnterPressed}
                                />
                            </div>
                        );
                    })}
                </div>
            </foreignObject>
        </g>
    );
}
