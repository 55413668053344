import { ITrainingReadingCertificatePending } from "@kortex/aos-common";
import React, { FC } from "react";

import { TrainingCard } from "./trainingCard";
import { TrainingHeaderCard } from "./trainingHeaderCard";

interface IOwnProps {
    readingCertificatePending: ITrainingReadingCertificatePending;
}

const Trainings: FC<IOwnProps> = (props) => {
    const { readingCertificatePending } = props;

    return (
        <div>
            <TrainingHeaderCard userId={readingCertificatePending.user.userId} />
            {readingCertificatePending.trainings
                .sort((a, b) => {
                    const nameA = a.treeNode.label.toLocaleLowerCase();
                    const nameB = b.treeNode.label.toLocaleLowerCase();

                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;

                    return 0;
                })
                .map((training, index) => (
                    <TrainingCard index={index} key={index} training={training} userId={readingCertificatePending.user.userId} />
                ))}
        </div>
    );
};

export default Trainings;
