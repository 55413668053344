import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
var useStyles = makeStyles({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
    },
    label: {
        fontSize: "0.7rem",
        textAlign: "center",
    },
});
function KortexLabelIcon(props) {
    var classes = useStyles(props);
    var children = props.children, label = props.label;
    return (React.createElement("div", { className: classes.wrapper },
        children,
        React.createElement("span", { className: classes.label }, label)));
}
export default KortexLabelIcon;
