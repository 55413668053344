import { JobProcessStatusEnum, JobStatusEnum } from "../enum/JobStatusEnum";
export const JOB_QTY_MAX = 1000;
/**
 * Assign a status to a job process
 * Validates that the job is released or not
 * Check if job is done is set to ready of in progress
 */
export function assignStatusToJobProcess(jobStatus, jobProcess) {
    switch (jobStatus) {
        case JobProcessStatusEnum.READY:
        case JobProcessStatusEnum.IN_PROGRESS:
            if (jobProcess.qtyPassed === jobProcess.qty) {
                jobProcess.status = JobProcessStatusEnum.DONE;
            }
            else if (jobProcess.qtyStarted !== 0) {
                jobProcess.status = JobProcessStatusEnum.IN_PROGRESS;
            }
            else {
                jobProcess.status = JobProcessStatusEnum.READY;
            }
        default:
            jobProcess.status = jobStatus;
    }
}
/**
 * Return the appropriate the job process status based on the job status
 */
export function getJobProcessStatusFromJobStatus(jobStatus, jobProcess) {
    switch (jobStatus) {
        case JobStatusEnum.READY:
        case JobStatusEnum.IN_PROGRESS:
            if (jobProcess.qtyPassed >= jobProcess.qty) {
                return JobProcessStatusEnum.DONE;
            }
            else if (jobProcess.qtyStarted !== 0) {
                return JobProcessStatusEnum.IN_PROGRESS;
            }
            else {
                return JobProcessStatusEnum.READY;
            }
        case JobStatusEnum.ERROR: // TODO: Do we need to manage this status individually?
        case JobStatusEnum.NONE: // TODO: Do we need to manage this status individually?
        case JobStatusEnum.DRAFT:
            return JobProcessStatusEnum.DRAFT;
        case JobStatusEnum.DONE:
            return JobProcessStatusEnum.DONE;
        case JobStatusEnum.ARCHIVED:
            return JobProcessStatusEnum.ARCHIVED;
        case JobStatusEnum.ON_HOLD:
            return JobProcessStatusEnum.ON_HOLD;
        case JobStatusEnum.CANCELLED:
            return JobProcessStatusEnum.CANCELLED;
    }
}
