var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function ThreeD(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { id: "icons-three-d", fill: "currentColor", d: "M11.6868134,2 L19.675827,5.91209413 L11.6868134,9.82418823 L3.69779968,5.91209413 L11.6868134,2 Z M3,18.0110169 L3,6.79119873 L11.1373596,10.7802277 L11.1373596,22 L11.1318665,22 L3,18.0110169 Z M12.2362671,10.7802277 L20.3736267,6.79119873 L20.3681335,18.0110169 L12.2362671,22 L12.2307587,22 L12.2362671,10.7802277 Z" })));
}
