import { SequencerConfigError } from "../interfaces";
import { localeKeys } from "../locales";
import { isActionGroupConfigLoop } from "./action-group-config-type-predicates";
import { isSequencerActionGroupConfigSubProgram } from "./sequencer-action-group-type-predicates";
export function initBranchesLastActionGroup(currentActionGroup, branchNext, allActionGroups, visitedGroups = []) {
    const nextActionGroup = allActionGroups.find((group) => group.config.actionGroupId === currentActionGroup.config.next);
    if (nextActionGroup) {
        if (visitedGroups.findIndex((group) => group.config.actionGroupId === currentActionGroup.config.actionGroupId) === -1) {
            visitedGroups.push(currentActionGroup);
            initBranchesLastActionGroup(nextActionGroup, branchNext, allActionGroups, visitedGroups);
        }
    }
    else if (branchNext) {
        currentActionGroup.config.next = branchNext;
    }
    if (isSequencerActionGroupConfigSubProgram(currentActionGroup)) {
        return void 0;
    }
    const newBranchNext = isActionGroupConfigLoop({ ...currentActionGroup.config, actions: [] })
        ? currentActionGroup.config.actionGroupId
        : currentActionGroup.config.next;
    for (const [index, branch] of currentActionGroup.config.branches.entries()) {
        if (branch) {
            const actionGroupFromBranch = allActionGroups.find((group) => group.config.actionGroupId === branch);
            if (!actionGroupFromBranch) {
                throw new SequencerConfigError(localeKeys.sequencer.branchActionGroupNotFound, "Action group (from branch) not found.");
            }
            initBranchesLastActionGroup(actionGroupFromBranch, newBranchNext, allActionGroups, visitedGroups);
        }
        else {
            currentActionGroup.config.branches[index] = newBranchNext;
        }
    }
}
