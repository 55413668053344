import { greyPalette, KortexDialogConfirmation } from "@aos/react-components";
import { IUserGroupDbModel } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesUsersGroups } from "@kortex/aos-ui/redux/effects";
import { Checkbox, Divider, makeStyles, Typography } from "@material-ui/core";
import IconChecked from "@material-ui/icons/Check";
import React, { useState } from "react";

const useStyles = makeStyles({
    checkContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: "42px",
    },
    divider: {
        margin: "12px",
    },
    greyed: {
        color: greyPalette[400],
    },
    groupContainer: {
        display: "flex",
        flexDirection: "row",
    },
    groupsContainer: {
        display: "flex",
        flexDirection: "column",
    },
    question: {
        paddingLeft: "12px",
    },
    row: {
        alignItems: "center",
        display: "flex",
    },
});

interface IOwnProps {
    missingTrainingCommunique: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    opened: boolean;
    selectedApproverGroups: number[];
}

function ProcessApprovalGroupAreYouSureDialog(props: IOwnProps): JSX.Element {
    const { missingTrainingCommunique, onCancel, onConfirm, opened, selectedApproverGroups } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const userGroups = useEntitiesUsersGroups({ isActive: true });

    const [approvalGroupsChecked, setApprovalGroupsChecked] = useState(false);
    const [trainingCommuniqueChecked, setTrainingCommuniqueChecked] = useState(false);

    if (!opened) return <></>;

    const approverGroups = userGroups.filter((group) => group.isApprover);
    const missingApprovalGroups = selectedApproverGroups.length !== approverGroups.length;
    const confirmDisabled = (missingTrainingCommunique && !trainingCommuniqueChecked) || (missingApprovalGroups && !approvalGroupsChecked);

    const handleApprovalGroupsChecked = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        setApprovalGroupsChecked(checked);
    };

    const handleTrainingCommuniqueChecked = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        setTrainingCommuniqueChecked(checked);
    };

    /**
     *
     */
    /*useEffect(() => {
        if (opened) {
            setApprovalGroupsChecked(false);
            setTrainingCommuniqueChecked(false);
        }
    }, [opened]);*/

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={confirmDisabled}
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: onCancel,
            }}
            onCancel={onCancel}
            onConfirm={onConfirm}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.proceed"),
                titleLabel: translate("process.versioning.areYouSureDialog.title"),
            }}
        >
            <>
                <Typography className={classes.question}>{translate("process.versioning.areYouSure.question")}</Typography>
                {missingApprovalGroups && (
                    <>
                        <Divider className={classes.divider} />
                        <div className={classes.row}>
                            <Checkbox
                                defaultChecked={approvalGroupsChecked}
                                onChange={handleApprovalGroupsChecked}
                                id={"missingApprouvalGroupId"}
                            />
                            <Typography>{translate("process.versioning.areYouSureDialog.approvalGroupsNotAllSelected")}</Typography>
                        </div>
                        <div className={classes.groupsContainer}>
                            {approverGroups
                                .sort((a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" }))
                                .map((group: IUserGroupDbModel, index: number): JSX.Element => {
                                    if (selectedApproverGroups.includes(group.userGroupId)) {
                                        return (
                                            <div
                                                className={classes.groupContainer}
                                                key={`processApprovalGroupAreYouSureDialogGroup${index}`}
                                            >
                                                <div className={classes.checkContainer}>
                                                    <IconChecked />
                                                </div>
                                                <Typography variant="h6">{group.name}</Typography>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div
                                                className={classes.groupContainer}
                                                key={`processApprovalGroupAreYouSureDialogGroup${index}`}
                                            >
                                                <div className={classes.checkContainer} />
                                                <Typography className={classes.greyed} variant="h6">
                                                    {group.name}
                                                </Typography>
                                            </div>
                                        );
                                    }
                                })}
                        </div>
                    </>
                )}
                {missingTrainingCommunique && (
                    <>
                        <Divider className={classes.divider} />
                        <div className={classes.row}>
                            <Checkbox
                                defaultChecked={trainingCommuniqueChecked}
                                onChange={handleTrainingCommuniqueChecked}
                                id={"missingTrainingCommuniqueId"}
                            />

                            <Typography>{translate("process.versioning.areYouSureDialog.missingTrainingCommunique")}</Typography>
                        </div>
                    </>
                )}
            </>
        </KortexDialogConfirmation>
    );
}

export default ProcessApprovalGroupAreYouSureDialog;
