import { KortexTextField } from "@aos/react-components";
import { IWorkInstructionsFormInputConfig } from "@kortex/aos-common";
import { MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../../../../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    fullWidth: {
        width: "100%",
    },
});

export interface IOwnProps extends IWorkInstructionsFormInputConfig {
    autoFocus?: boolean;
}

export default function WorkInstructionsFormInputDropdown(props: IOwnProps): JSX.Element {
    const { itemState, itemProps, autoFocus } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const displayedLabel = itemState.label ? itemState.label : itemProps.label;

    /**
     * Called when a user interacts with the input
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - change event
     */
    const onStateChanged = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        props.onStateChanged({ ...props.itemState, value: event.target.value });
    };

    /**
     * Render menu item of the dropdown
     *
     * @param {string} value - string
     * @param {number} index - number
     */
    const renderMenuItemDropdown = (value: string, index: number): JSX.Element => {
        return (
            <MenuItem key={index} value={value}>
                {value}
            </MenuItem>
        );
    };

    /**
     * Render dropdown items
     */
    const renderDropdownItems = (): JSX.Element[] => {
        const arrayItems: JSX.Element[] = [];

        if (!itemState.items) {
            return [];
        }

        let indexDropdown = 0;

        for (const item of itemState.items) {
            if (Array.isArray(item)) {
                for (const arrayItem of item) {
                    arrayItems.push(renderMenuItemDropdown(arrayItem, indexDropdown));
                    indexDropdown++;
                }
            } else {
                for (const arrayItem of item.split(",")) {
                    arrayItems.push(renderMenuItemDropdown(arrayItem, indexDropdown));
                    indexDropdown++;
                }
            }
        }
        return arrayItems;
    };

    return (
        <KortexTextField
            label={displayedLabel}
            className={classes.fullWidth}
            onChange={onStateChanged}
            value={itemState.value}
            TextFieldProps={{
                required: itemProps.required,
                select: true,
                autoFocus,
            }}
            error={itemState.errorKey ? translate("action.workInstructions.form.error." + itemState.errorKey) : ""}
        >
            {renderDropdownItems()}
        </KortexTextField>
    );
}
