var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useRef, useState } from "react";
import { SwatchesPicker } from "react-color";
import { theme } from "../mui/theme";
import { greyPalette, secondaryPalette, kinova_Royal_Ligth_Dark_Palettes as palettes } from "../mui/palettes";
import { useKeyPressed } from "../hooks";
var rgbStringLength = 7;
var containerPaddingRight = 10;
var containerPaddingBottom = 20;
var swatchWidth = 50; // 40 (width) + 10 (margin right)
var swatchHeight = 25; // 24 (width) + 1 (margin bottom)
var useStyles = makeStyles({
    buttonContainer: {
        background: "rgb(255, 255, 255)",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "2px",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 12px",
        padding: "4px",
    },
    button: {
        borderRadius: "2px",
        height: "28px",
        minHeight: "auto",
        minWidth: "auto",
        transition: "none",
        width: "90px",
    },
    pickerContainer: {
        position: "fixed",
        zIndex: theme.zIndex.tooltip,
    },
    opacityContainer: {
        minWidth: "107px",
    },
    opacityTypography: {
        cursor: "pointer",
        height: "24px",
        textAlign: "center",
    },
    sliderContainer: {
        paddingLeft: "20px",
        paddingRight: "20px",
    },
});
/**
 * Convert a variable of type Color or Object to string[][]
 *
 * @param {Palette} palette - a palette object to convert
 * @param {number} [maxColorCountPerColumn=6] - number of colors per column
 */
export function colorToArray(palette, maxColorCountPerColumn) {
    if (maxColorCountPerColumn === void 0) { maxColorCountPerColumn = 6; }
    var colorsKeys = Object.keys(palette);
    var colorArray = [];
    for (var row = 0; row < colorsKeys.length / maxColorCountPerColumn; row++) {
        colorArray.push([]);
        for (var column = 0; column < maxColorCountPerColumn; column++) {
            var color = palette[colorsKeys[column + row * maxColorCountPerColumn]];
            if (color) {
                colorArray[row].push(color);
            }
        }
    }
    return colorArray;
}
/**
 * Convert a variable of type Color Array or Object Array to string[][]
 *
 * @param {Palette[]} palettes - a palette object to convert
 * @param {number} [maxColorCountPerColumn=6] - number of colors per column
 */
export function colorPaletteListToArray(palettes, maxColorCountPerColumn) {
    if (maxColorCountPerColumn === void 0) { maxColorCountPerColumn = 6; }
    if (palettes.length === 0)
        return [[]];
    var returnValue = palettes.map(function (palette) { return colorToArray(palette, maxColorCountPerColumn)[0]; });
    return returnValue;
}
/**
 * Extract opacity hex value from rbga and convert it in percentage
 *
 * @param {string} rgbaColor - rgba color
 * @param {boolean} [transparency=false] - enable transparency
 */
function getOpacityPercentageFromRGBA(rgbaColor, transparency) {
    if (transparency === void 0) { transparency = false; }
    if (!transparency) {
        return 100;
    }
    else {
        return rgbaColor.length > rgbStringLength
            ? Math.ceil((parseInt(rgbaColor.substr(rgbStringLength, rgbaColor.length), 16) * 100) / 255)
            : 100;
    }
}
/**
 * Extract rgb value (first 7 characters) from rgba color string
 * Example:
 * RBGA: #RRGGBBAA (9 characters)
 * RBG: #RRGGBB (7 characters)
 *
 * @param {string} rgbaColor - rgba color
 */
function extractRGBFromRGBA(rgbaColor) {
    return rgbaColor.substr(0, rgbStringLength);
}
function KortexColorPicker(props) {
    var _a;
    var classes = useStyles(props);
    var inputStyle = props.inputStyle, textLabels = props.textLabels, onChange = props.onChange, style = props.style, swatchContainerStyle = props.swatchContainerStyle, transparency = props.transparency;
    var colors = (_a = props.colors) !== null && _a !== void 0 ? _a : colorPaletteListToArray(palettes, 7); // Default to standard Kinova theme + Standard Colors from commcercial Software.
    var _b = useState(false), opened = _b[0], setOpened = _b[1];
    var pickerContainer = useRef(null);
    var _c = useState(extractRGBFromRGBA(props.color)), swatchColor = _c[0], setSwatchColor = _c[1];
    var _d = useState(getOpacityPercentageFromRGBA(props.color, transparency)), opacity = _d[0], setOpacity = _d[1];
    var escapePressed = useKeyPressed("Escape", !opened);
    /**
     * Called when user click escape key when the menu is open... close the menu.
     */
    useEffect(function () {
        if (escapePressed) {
            setOpened(false);
        }
    }, [escapePressed]);
    /**
     * Effect that applies color and opacity when color prop changes
     */
    useEffect(function () {
        var newColor = extractRGBFromRGBA(props.color);
        var newOpacity = getOpacityPercentageFromRGBA(props.color, props.transparency);
        if (newColor !== swatchColor) {
            setSwatchColor(newColor);
        }
        if (props.transparency && newOpacity !== opacity) {
            setOpacity(newOpacity);
        }
    }, [props.color, props.transparency]);
    /**
     * Effect that changes the style of the swatch container
     */
    useEffect(function () {
        if (pickerContainer && pickerContainer.current) {
            pickerContainer.current.style.display = "flex";
            pickerContainer.current.style.justifyContent = "center";
            pickerContainer.current.children[0].children[0].children[1].children[0].style.overflowY = "";
            pickerContainer.current.children[0].children[0].children[1].children[0].children[0].style.padding =
                "10px 0px 0px 10px";
        }
    });
    /**
     * Convert opacity percentage to hex
     *
     * @param {number} [value] - opacity value
     */
    var opacityToHex = function (value) {
        var opacityHex = Math.floor(((value !== undefined ? value : opacity) * 255) / 100).toString(16);
        return parseInt(opacityHex, 16) > 16 ? opacityHex : "0" + opacityHex;
    };
    /**
     * Closes the popper on click away
     */
    var handleClickAway = function () {
        setOpened(false);
    };
    /**
     * Changes the color when a swatch is clicked
     *
     * @param {ColorResult} updatedColor - updated color
     */
    var handleColorChange = function (updatedColor) {
        setSwatchColor(updatedColor.hex);
        onChange(updatedColor.hex + opacityToHex());
        setOpened(false);
    };
    /**
     * Open/close the popper when the main button is clicked
     */
    var handleButtonClick = function () {
        if (!opened && colors.length > 0) {
            setOpened(true);
        }
        else {
            setOpened(false);
        }
    };
    /**
     * Handles opacity change
     *
     * @param {React.ChangeEvent<object>} _event - Change event data
     * @param {number} value - Updated opacity value from slider
     */
    var handleOpacityChange = function (_event, value) {
        setOpacity(value);
        onChange(swatchColor + opacityToHex(value));
    };
    return (React.createElement("div", { style: style },
        textLabels && textLabels.inputLabel && (React.createElement(Typography, { style: { color: opened ? secondaryPalette[500] : greyPalette[700] }, variant: "body2" }, textLabels.inputLabel)),
        React.createElement(ClickAwayListener, { onClickAway: handleClickAway, mouseEvent: "onMouseDown" },
            React.createElement("div", null,
                React.createElement("div", { className: classes.buttonContainer },
                    React.createElement(Button, { className: classes.button, style: __assign(__assign({}, inputStyle), { backgroundColor: swatchColor + opacityToHex() }), onClick: handleButtonClick }, "\u00A0")),
                opened && (React.createElement(Paper, { className: classes.pickerContainer, style: swatchContainerStyle },
                    transparency && (React.createElement("div", { className: classes.opacityContainer, style: { width: containerPaddingRight + swatchWidth * colors.length } },
                        React.createElement(Typography, { className: classes.opacityTypography, id: "sliderLabel", variant: "body2" }, textLabels && textLabels.opacityLabel ? textLabels.opacityLabel + ": " + opacity + "%" : ""),
                        React.createElement("div", { className: classes.sliderContainer },
                            React.createElement(Slider, { "aria-labelledby": "sliderLabel", max: 100, min: 0, onChange: handleOpacityChange, step: 5, style: { paddingTop: "4px", paddingBottom: "8px" }, value: opacity })))),
                    React.createElement("div", { ref: pickerContainer },
                        React.createElement(SwatchesPicker, { color: swatchColor, colors: colors, height: containerPaddingBottom + swatchHeight * colors[0].length, onChange: handleColorChange, width: containerPaddingRight + swatchWidth * colors.length }))))))));
}
export default KortexColorPicker;
