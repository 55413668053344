import { ApprovalStatusEnum } from "../../interfaces/models";
import { isTaskAlreadyApprovedByUser } from "../../interfaces/models/task/utils";
import { Task } from "./Task";
export class TaskProcessApproval extends Task {
    constructor(task) {
        super(task);
    }
    /**
     * Process ID for which the task has been assigned.
     * For a process approval task, the link ID is a process ID (model.linkId = processId).
     */
    get processId() {
        return this._model.linkId;
    }
    set processId(processId) {
        this._model.linkId = processId;
    }
    /**
     * Checks if a user has already approved the task
     */
    isAlreadyApprovedByUser(userId) {
        return isTaskAlreadyApprovedByUser(this._model, userId);
    }
}
TaskProcessApproval.APPROVAL_STATUS = ApprovalStatusEnum;
