import { SequencerError } from "./sequencer-error";
export class SequencerConfigError extends SequencerError {
    constructor(key, message = "Sequencer configuration error") {
        super(key, message);
    }
    get name() {
        return "SequencerConfigError";
    }
    get originalError() {
        return super.originalError;
    }
}
