import { upsertObjectFromArray } from "@kortex/aos-common";

import { NotificationActions, NotificationActionType, NotificationState } from "./notification-types";

const initialState: NotificationState = {
    notifications: [],
};

/*
 *
 * @param {UnknownObject} state - current state containing notifications.
 * @param {NotificationUnknown[]} notifications - notifications to be upserted.
 *
 * @return {NotificationState} - new state with the addition of update
 */ export function notificationReducer(state: NotificationState = initialState, action: NotificationActions): NotificationState {
    switch (action.type) {
        case NotificationActionType.SET_NOTIFICATION_LIST:
            return {
                ...state,
                notifications: action.value,
            };
        case NotificationActionType.INSERT_NOTIFICATION: {
            return {
                ...state,
                notifications: upsertObjectFromArray(state.notifications, action.value, function (variable) {
                    return variable.notificationId === this.notificationId;
                }),
            };
        }
        case NotificationActionType.DELETE_NOTIFICATION: {
            return {
                ...state,
                notifications: state.notifications.filter((notification) => notification.notificationId !== action.value.notificationId),
            };
        }
        case NotificationActionType.DELETE_ALL_NOTIFICATIONS: {
            return {
                ...state,
                notifications: action.value,
            };
        }
        default:
            return state;
    }
}
