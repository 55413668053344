import { theme } from "@aos/react-components";
import { TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import ReactPlayer from "react-player";

import { useTranslate } from "../../../hooks/useTranslate";
import { useEntityFileByFileId } from "../../../redux/effects";
import * as CloudStorage from "../../../utilitites/storage";
import PdfPlayer from "../PdfPlayer/PdfPlayer";

import PreviewPaneImage from "./PreviewPaneImage/PreviewPaneImage";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const useStyles = makeStyles({
    noPreview: {
        height: "50px",
        overflow: "hidden",
        paddingTop: "50px",
        textAlign: "center",
        width: "100%",
    },
    previewPane: {
        alignItems: "center",
        bottom: "0px",
        display: "flex",
        justifyContent: "center",
        left: "0px",
        overflow: "auto",
        position: "absolute",
        right: "0px",
        top: "0px",
    },
    previewPaneContainer: {
        width: "100%",
        height: "100%",
        textAlign: "center",
    },
    previewPlayerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    previewPlayerButton: {
        margin: "5px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IOwnProps {
    fileId: string;
    fileType: TreeNodeNodeTypeEnum;
    text: string;
}

type IAllProps = IOwnProps;

export default function PreviewPane(props: IAllProps): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    const { fileId, fileType, text } = props;

    const [pageNumberPDF, setPageNumberPDF] = useState<number>(1);
    const [numPagesPDF, setNumPagesPDF] = useState<number>(1);
    const [pdfFile, setPdfFile] = useState("");

    const previewPaneRef = useRef<HTMLDivElement | null>(null);
    const tooltip = useEntityFileByFileId(fileId)?.tooltip ?? "";

    const url = `${CloudStorage.get().host}/${fileId}`;

    /**
     * When new file is loaded, reset all pages states
     */
    useEffect((): void => {
        setPageNumberPDF(1);
        setNumPagesPDF(1);

        if (fileType === TreeNodeNodeTypeEnum.PDF) {
            fetch(`${url}`).then((response): void => {
                if (response.ok) {
                    response.blob().then((blob): void => {
                        const reader = new FileReader();

                        reader.onload = (): void => {
                            setPdfFile(reader.result as string);
                        };

                        reader.readAsDataURL(blob);
                    });
                }
            });
        }
    }, [url]);

    /**
     * Handle successfull loading of the PDF
     *
     * @param { any } document - the number of pages on the PDF
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlePDFLoadSuccess = (document: any): void => {
        const { numPages } = document;
        setPageNumberPDF(1); // Set it to first page
        setNumPagesPDF(numPages);
    };

    /**
     * Handle pdf page change request
     */
    const handlePdfPageChange = (pageNumber: number): void => {
        if (pageNumberPDF < numPagesPDF) {
            setPageNumberPDF(pageNumber);
        }
    };

    return (
        <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltip}>
            <div className={classes.previewPane} ref={previewPaneRef}>
                {url && (
                    <>
                        {fileType === TreeNodeNodeTypeEnum.IMAGE && <PreviewPaneImage containerRef={previewPaneRef} src={url} />}
                        {fileType === TreeNodeNodeTypeEnum.VIDEO && (
                            <ReactPlayer url={url} width={300} height={200} controls={true} loop={true} playing={true} />
                        )}
                        {fileType === TreeNodeNodeTypeEnum.PDF && (
                            <div className={classes.previewPaneContainer}>
                                <PdfPlayer allPages={true} totalPages={numPagesPDF} onPageChange={handlePdfPageChange} />
                                <Document file={pdfFile} onLoadSuccess={handlePDFLoadSuccess}>
                                    <Page pageNumber={pageNumberPDF} />
                                </Document>
                            </div>
                        )}
                        {fileType === TreeNodeNodeTypeEnum.TEXT && <span>{text}</span>}
                        {fileType === TreeNodeNodeTypeEnum.UNKNOWN && (
                            <div className={classes.noPreview}>
                                <Typography>{translate("previewPane.noPreviewAvailable")}</Typography>
                            </div>
                        )}
                    </>
                )}
            </div>
        </Tooltip>
    );
}
