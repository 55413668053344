export {};
/*
    FIXME: Review how the IUserTrainingTrainingsHistory interface is built (AOS-2270)
        1) The "history" should contain all records
        2) The tree node is the same for all trainings
        3) Do we include the user info? (probably not...)

    So the interface should look like this:
*/
/*
export interface IUserTrainingTrainingsHistory {
    records: {
        electronicSignatures: {
            certificate: IElectronicSignatureDbModel | null;
            results: IElectronicSignatureDbModel[];
        };
        originalProcess: Pick<IProcessDbModel, "processId" | "version">;
        process: IProcessDbModel;
        training: TrainingDbModel;
    }[];
    treeNode: Pick<ITreeNodeDbModel, "label">;
}
*/
