import { secondaryPalette } from "@aos/react-components";
import { EnumUserStatus, IUserDbModel, IUserGroupDbModel, IUserRoleDbModel, newUser, UserEditorRightsEnum } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Fab, IconButton, makeStyles, Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import * as React from "react";
import { useState } from "react";

import KortexLabelIcon from "../../../../components/core/KortexLabelIcon";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useEntitiesUsers, useEntitiesUsersGroups, useEntitiesUsersRoles } from "../../../../redux/effects";
import { userInsert, userUpdate } from "../../../../redux/user-manager/users-thunks-user";
import { formatUserStatus } from "../../../../utilitites/formatUserStatus";
import { IUserRightsProps, userCanInsert } from "../../../../utilitites/IUserRights";

import UserEditor, { IUserSaveOptions } from "./UserEditor";
import UserList from "./UserList";
import UserSearchBar, { defaultUserFilters, IUserFilters as IUserFilter } from "./UserSearchBar";

const useStyles = makeStyles({
    root: {
        padding: "16px",
        display: "grid",
        gridTemplateColumns: "33% 1fr",
        columnGap: "12px",
    },
    leftPanel: {
        height: "calc(100vh - 162px)", // Header (64px) + Top & Bottom margins (32px) + bottom menu
        position: "relative",
    },
    listContainer: {
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        height: "calc(100vh - 230px)", // Header (64px) + Top & Bottom margins (32px) + SearchBar (64px) + bottom menu
    },
    actionContainer: {
        paddingTop: "10px",
    },
    rightPanel: {
        display: "flex",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    fab: {
        position: "absolute",
        bottom: "20px",
        right: "20px",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
});

export default function UserEditorPage(props: IUserRightsProps<UserEditorRightsEnum>): JSX.Element {
    const { userAccessLevel } = props;

    const classes = useStyles();
    const users = useEntitiesUsers();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();
    const allUserRoles: IUserRoleDbModel[] = useEntitiesUsersRoles();
    const allUserGroups: IUserGroupDbModel[] = useEntitiesUsersGroups();
    const [selectedUser, setSelectedUser] = useState<IUserDbModel | undefined>();
    const [userFilters, setUserFilters] = useState<IUserFilter>(defaultUserFilters);

    /**
     * Called when the filters change. Sets local filters state
     *
     * @param {IUserFilter} newFilters - newly set filters
     */
    const handleFiltersChange = (newFilters: IUserFilter): void => {
        setUserFilters(newFilters);
    };

    /**
     * Tests a single user against the filters
     *
     * @param {IUserDbModel} user - job to test
     * @returns {boolean} - true if job passed
     */
    const applyFilters = (user: IUserDbModel): boolean => {
        if (userFilters.plainText !== "") {
            // create string that contains all searchable params (firstName, lastName, userName, status, role names, gorup names)
            let searchString = `${user.firstName}${user.lastName}${user.userName}${formatUserStatus(user.status, translate)}`;
            for (const userRole of user.roles) {
                // Add role name to search string
                const roleListItem = allUserRoles.find((role): boolean => role.userRoleId === userRole);
                if (roleListItem) {
                    searchString += roleListItem.name;
                }
            }
            for (const userGroup of user.groups) {
                // Add group name to search string
                const groupListItem = allUserGroups.find((group): boolean => group.userGroupId === userGroup);
                if (groupListItem) {
                    searchString += groupListItem.name;
                }
            }

            // check if string contains the searched plainText
            if (!searchString.toLowerCase().includes(userFilters.plainText.toLowerCase())) {
                return false;
            }
        }

        // Check roles
        if (userFilters.roles.length) {
            for (const role of userFilters.roles) {
                if (user.roles.includes(role)) {
                    return true;
                }
            }
            return false;
        }
        // Check groups
        if (userFilters.groups.length) {
            for (const group of userFilters.groups) {
                if (user.groups.includes(group)) {
                    return true;
                }
            }
            return false;
        }
        // check Status
        if (userFilters.status.length && !userFilters.status.includes(user.status)) {
            return false;
        }

        // check Archived
        if (!userFilters.showArchived && user.status === EnumUserStatus.ARCHIVED) {
            return false;
        }
        return true;
    };

    /**
     * Handle called when the user is selected
     *
     * @param {IUserDbModel} user - Selected role
     */
    const handleSelectUser = (user: IUserDbModel): void => {
        setSelectedUser(user);
    };

    /**
     * Set a new default user to initiate user creation
     */
    const handleInsertUser = (): void => {
        setSelectedUser(newUser());
    };

    /**
     * Remove selected user
     */
    const handleCancel = (): void => {
        setSelectedUser(undefined);
    };

    /**
     * Handles saving of user
     *
     * @param {IUserDbModel} user - User to create
     * @param {IUserSaveOptions} options - User creation options
     */
    const handleSaveUser = (user: IUserDbModel, options: IUserSaveOptions): Promise<void> => {
        if (!user) {
            return Promise.resolve();
        }
        if (!user.userId && options.temporaryPassword) {
            return dispatch(userInsert({ user, temporaryPassword: options.temporaryPassword }));
        } else {
            return dispatch(userUpdate(user, options.forcePasswordUpdateFlag));
        }
    };

    /**
     * Handles updating user when modified
     *
     * @param {IUserDbModel} modedUser - User
     */
    const handleUserChanged = (modedUser: IUserDbModel, options: IUserSaveOptions): void => {
        setSelectedUser(modedUser);
        handleSaveUser(modedUser, options);
    };

    /**
     * Handles export of the user email to clipboard
     */
    const handleExportEmail = (): void => {
        const usersEmail = users.reduce((accumulator: string[], user): string[] => {
            if (applyFilters(user)) {
                if (user.email && user.email !== "") {
                    accumulator.push(user.email);
                }
            }
            return accumulator;
        }, []);

        // Copy emails to clipboard
        navigator.clipboard.writeText(usersEmail.join(","));
    };

    return (
        <div id="settingsUsersPageId" className={classes.root}>
            <div>
                <Paper className={classes.leftPanel} id="userEditorPanelId">
                    {/* USER SEARCH */}
                    <div>
                        <UserSearchBar onFiltersChange={handleFiltersChange} />
                    </div>
                    <div className={classes.listContainer}>
                        <UserList users={users} onUserSelection={handleSelectUser} applyFilters={applyFilters} />
                        {/* INSERT BUTTON */}
                        <Fab
                            id="insertUserButtonId"
                            color="secondary"
                            className={classes.fab}
                            onClick={handleInsertUser}
                            disabled={!userCanInsert(userAccessLevel)}
                        >
                            <AddIcon />
                        </Fab>
                    </div>
                </Paper>
                <div className={classes.actionContainer}>
                    <IconButton id="exportEmailButtonId" onClick={handleExportEmail}>
                        <KortexLabelIcon label={translate("settings.userSettings.exportEmail")}>
                            <EmailIcon />
                        </KortexLabelIcon>
                    </IconButton>
                </div>
            </div>
            {selectedUser && (
                <div className={classes.rightPanel}>
                    <UserEditor
                        userToEdit={selectedUser}
                        userAccessLevel={userAccessLevel}
                        onCancel={handleCancel}
                        onSaveUser={handleSaveUser}
                        onEditedUserChanged={handleUserChanged}
                    />
                </div>
            )}
        </div>
    );
}
