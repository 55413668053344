import * as React from "react";

function p(x: number, y: number): string {
    return x + " " + y + " ";
}

interface IOwnProps {
    x?: number;
    y?: number;
    width: number;
    height: number;
    r1?: number;
    r2?: number;
    r3?: number;
    r4?: number;
    style?: React.CSSProperties;
    onClick?: (e: React.MouseEvent<SVGPathElement>) => void;
    onDoubleClick?: (e: React.MouseEvent<SVGPathElement>) => void;
    onMouseDown?: (e: React.MouseEvent<SVGPathElement>) => void;
    onMouseUp?: (e: React.MouseEvent<SVGPathElement>) => void;
    filter?: string;
    id?: string;
}

function RectRounded({
    x = 0,
    y = 0,
    width,
    height,
    r1 = 0,
    r2 = 0,
    r3 = 0,
    r4 = 0,
    style,
    onClick,
    onDoubleClick,
    onMouseDown,
    onMouseUp,
    filter,
    id,
}: IOwnProps): JSX.Element {
    // const { x, y, width, height, r1, r2, r3, r4 } = props;
    let strPath = "M" + p(x + r1, y); // A
    strPath += "L" + p(x + width - r2, y) + "Q" + p(x + width, y) + p(x + width, y + r2); // B
    strPath += "L" + p(x + width, y + height - r3) + "Q" + p(x + width, y + height) + p(x + width - r3, y + height); // C
    strPath += "L" + p(x + r4, y + height) + "Q" + p(x, y + height) + p(x, y + height - r4); // D
    strPath += "L" + p(x, y + r1) + "Q" + p(x, y) + p(x + r1, y); // A
    strPath += "Z";

    return (
        <svg xmlns="http://www.w3.org/2000/svg">
            <path
                d={strPath}
                style={style}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                filter={filter}
                id={id}
            />
        </svg>
    );
}

export default RectRounded;
