import { EventEmitter } from "events";

import { mangleURL, Token } from "@kortex/aos-common";
import Debug from "debug";

// types

type CHANGE_EVENT = typeof CHANGE_EVENT;

type State = {
    host: string;
    token: Token;
};

/**
 * @interface EditableState
 */
type EditableState = Partial<State>;

// constants

const CHANGE_EVENT = "change";

// helpers

/**
 * Checks deep equality between two (2) objects
 *
 * @param {object} x - object to compare to
 * @param {object} y - object to compare
 */
function deepEqual(x: object, y: object): boolean {
    const ok = Object.keys,
        tx = typeof x,
        ty = typeof y;
    return x && y && tx === "object" && tx === ty
        ? ok(x).length === ok(y).length && ok(x).every((key) => deepEqual(x[key], y[key]))
        : String(x) === String(y);
}

// privates

const _debug = Debug("kortex:aos:storage");
const _ee = new EventEmitter();

let _state: State;

function _emit(event: CHANGE_EVENT, state: State, newValue: EditableState, oldValue: EditableState): boolean;
function _emit(event: Parameters<EventEmitter["emit"]>[0], ...params: Parameters<EventEmitter["emit"]>[1]): boolean {
    return _ee.emit(event, ...params);
}

function _set(editableState: Partial<State>): State {
    const currentState = get();

    for (const prop of Object.keys(editableState) as Array<keyof EditableState>) {
        switch (prop) {
            case "host": {
                editableState[prop] = (editableState[prop] ?? "").replace(/\/$/, "");

                break;
            }
        }
    }

    _state = {
        ...currentState,
        ...editableState,
    };

    // emit only if there is a listener
    if (_ee.listenerCount(CHANGE_EVENT)) {
        const oldState = ((): EditableState => {
            const oldValue: EditableState = {};

            for (const prop of Object.keys(editableState) as Array<keyof EditableState>) {
                oldValue[prop] = currentState[prop];
            }

            return oldValue;
        })();
        const newState = { ...editableState };

        if (!deepEqual(newState, oldState)) {
            _emit(CHANGE_EVENT, { ..._state }, newState, oldState);
        }
    }

    return get();
}

// definition

/**
 * Invokes initializer
 */
export async function bootstrap(): Promise<void> {
    if (Boolean(_state)) {
        // throw new Error("already bootstrapped"); // FIXME: No one catches this error
        console.warn("Storage is already bootstrapped.");
        return void 0;
    }

    _state = {
        host: "",
        token: "",
    };

    _debug("bootstrapped state: %o", _state);
}

export const helpers = {
    /**
     * Returns storage host from a hub host
     *
     * @param {string} hubHost - hub host to get storage host from
     */
    toHostFromHubHost: function hostFromHubHost(hubHost: string): string {
        return process.env.NODE_ENV !== "production" && process.env.AOS_UI_STORAGE_HOST
            ? process.env.AOS_UI_STORAGE_HOST
            : mangleURL(hubHost).toHTTP().replace("hub.", "storage.").replace(/\/$/, "");
    },
};

/**
 * Checks if a top level property of the state is set
 */
export function isSet(key: keyof EditableState): boolean {
    return typeof _state[key] === "undefined" ? false : true;
}

/**
 * Returns a copy of the current state
 */
export function get(): State {
    return {
        ..._state,
    };
}

/**
 * Registers to an event
 *
 * @param {string} event - event to listen to
 * @param {(state: State, newValue: EditableState, oldValue: EditableState) => void} cb - callback to invoke on target event
 */
export function on(event: CHANGE_EVENT, cb: (state: State, newValue: EditableState, oldValue: EditableState) => void): void;
export function on(event: Parameters<EventEmitter["on"]>[0], cb: Parameters<EventEmitter["on"]>[1]): void {
    _ee.on(event, cb);
}

/**
 * Sets editable state
 *
 * @param {EditableState} editableState - cscsc
 */
export function set(editableState: EditableState): State {
    return _set(editableState);
}

/**
 * Throws a TypeError if the top level property is not set
 */
export function throwIfNotSet(key: keyof EditableState): void {
    if (!isSet(key)) {
        throw new TypeError(`"${key}" is not set`);
    }
}

on("change", (_state, newValue, oldValue) => _debug("state changed from %o to %o", oldValue, newValue));
