let cbCheckPreviousProcess;
let cbGetProcess;
let cbGetJobProcess;
let cbGetRunQuantityProcessFromTrackingNumber;
let cbGetJobProcessAlreadyExecuted;
let cbGetInProgressRun;
let cbGetDataStoreReadItem;
let cbUserValidate;
let cbInsertElectronicSignature;
let cbForkFailureTicket;
// Create Failure Ticket Callback
export function setCbForkFailureTicket(getForkFailureTicketFct) {
    cbForkFailureTicket = getForkFailureTicketFct;
}
export function forkFailureTicket(reworkId, workOrder) {
    if (cbForkFailureTicket) {
        return cbForkFailureTicket(reworkId, workOrder);
    }
    else {
        throw new Error("Callback cbForkFailureTicket not registered");
    }
}
export function setCbInsertElectronicSignature(getInsertElectronicSignatureFct) {
    // Get User Validate Callback
    cbInsertElectronicSignature = getInsertElectronicSignatureFct;
}
export function insertElectronicSignature(electronicSignature) {
    if (cbInsertElectronicSignature) {
        return cbInsertElectronicSignature(electronicSignature);
    }
    else {
        throw new Error("Callback cbInsertElectronicSignature not registered");
    }
}
// Get User Validate Callback
export function setCbUserValidate(getUserValidateFct) {
    cbUserValidate = getUserValidateFct;
}
export function userValidate(userName, password) {
    if (cbUserValidate) {
        return cbUserValidate(userName, password);
    }
    else {
        throw new Error("Callback cbUserValidate not registered");
    }
}
// Check Previous Callback
export function setCbCheckPreviousProcess(checkPreviousProcessFct) {
    cbCheckPreviousProcess = checkPreviousProcessFct;
}
export function checkPreviousProcess(jobProcessId, trackingNumber, qtyStarted, qtyStoppedAndFail) {
    if (cbCheckPreviousProcess) {
        return cbCheckPreviousProcess(jobProcessId, trackingNumber, qtyStarted, qtyStoppedAndFail);
    }
    else {
        throw new Error("Callback cbCheckPreviousProcess not registered");
    }
}
// Get Job Process Callback
export function setCbGetJobProcess(getJobProcessFct) {
    cbGetJobProcess = getJobProcessFct;
}
export function getJobProcess(jobProcessId) {
    if (cbGetJobProcess) {
        return cbGetJobProcess(jobProcessId);
    }
    else {
        throw new Error("Callback cbGetJobProcess not registered");
    }
}
// Get Process Callback
export function setCbGetProcess(getProcessFct) {
    cbGetProcess = getProcessFct;
}
export function getProcess(processId) {
    if (cbGetProcess) {
        return cbGetProcess(processId);
    }
    else {
        throw new Error("Callback cbGetProcess not registered");
    }
}
// Get Run Quantity Process From Tracking Number
export function setCbGetRunQuantityProcessFromTrackingNumber(getRunQuantityProcessFromTrackingNumberFct) {
    cbGetRunQuantityProcessFromTrackingNumber = getRunQuantityProcessFromTrackingNumberFct;
}
export function getRunQuantityProcessFromTrackingNumber(processId, trackingNumber, jobProcessId) {
    if (cbGetRunQuantityProcessFromTrackingNumber) {
        return cbGetRunQuantityProcessFromTrackingNumber(processId, trackingNumber, jobProcessId);
    }
    else {
        throw new Error("Callback cbGetRunQuantityProcessFromTrackingNumber not registered");
    }
}
export function setCbGetJobProcessAlreadyExecuted(getJobProcessAlreadyExecutedFct) {
    cbGetJobProcessAlreadyExecuted = getJobProcessAlreadyExecutedFct;
}
export function getJobProcessAlreadyExecuted(jobProcessId, trackingNumber) {
    if (cbGetJobProcessAlreadyExecuted) {
        return cbGetJobProcessAlreadyExecuted(jobProcessId, trackingNumber);
    }
    else {
        throw new Error("Callback cbGetJobProcessAlreadyExecuted not registered");
    }
}
// Get In Progress Run
export function setCbGetInProgressRun(getInProgressRunFct) {
    cbGetInProgressRun = getInProgressRunFct;
}
export function getInProgressRun(resultProcessHistoryId) {
    if (cbGetInProgressRun) {
        return cbGetInProgressRun(resultProcessHistoryId);
    }
    else {
        throw new Error("Callback setCbGetInProgressRun not registered");
    }
}
// Get Data Store Read Item
export function setCbGetDataStoreReadItem(getDataStoreReadItemFct) {
    cbGetDataStoreReadItem = getDataStoreReadItemFct;
}
export function getDataStoreReadItem(storageType, filters, reqColumn, sortDescending) {
    if (cbGetDataStoreReadItem) {
        return cbGetDataStoreReadItem(storageType, filters, reqColumn, sortDescending);
    }
    else {
        throw new Error("Callback cbGetDataStoreReadItem not registered");
    }
}
