import { KortexTextField, theme } from "@aos/react-components";
import {
    ProcessAction,
    ProcessActionStep,
    ProcessActionType,
    ProcessEditorRightsEnum,
    isInput,
    isOutput,
    isSequencerCoreAction,
    isWorkInstructions,
} from "@kortex/aos-common";
import { useKeybind } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { deepClone } from "@kortex/utilities";
import { AppBar, ClickAwayListener, Divider, IconButton, List, ListItem, Menu, MenuItem, Paper, Toolbar, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Add from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Redo from "@material-ui/icons/Redo";
import Undo from "@material-ui/icons/Undo";
import Debug from "debug";
import * as jsonPatch from "fast-json-patch";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { SortEnd, SortableContainer, SortableElement } from "react-sortable-hoc";

import { useHistoryStack } from "../../../../../hooks/useHistoryStack";
import { useStoreState } from "../../../../../hooks/useStoreState";
import { useTranslate } from "../../../../../hooks/useTranslate";
import {
    processActionStepCopy,
    processActionStepDelete,
    processActionStepInsert,
    processActionStepInsertCopy,
    processActionStepReorder,
    processActionStepUpdate,
    processActionUpdate,
} from "../../../../../redux/process-manager/process-thunks-process";
import { useSelectorProcessActionStepCopy } from "../../../../../redux/selectors";
import { IUserRightsProps, userCanWrite } from "../../../../../utilitites/IUserRights";
import KortexLabelIcon from "../../../../core/KortexLabelIcon";
import NotificationsCenterDialog from "../../../NotificationsCenter/NotificationsCenterDialog";

import ActFailureTicketForkStepEditor from "./ActFailureTicketForkStepEditor/ActFailureTicketForkStepEditor";
import InputStepEditor from "./ActInputEditor/ActInputStepEditor";
import ActMathStepEditor from "./ActMathEditor/ActMathStepEditor";
import ActMessageStepEditor from "./ActMessageStepEditor/ActMessageStepEditor";
import ActionStepEditor from "./ActionStepEditor";
import ConditionEditor from "./ConditionAndLoop/ConditionEditor";
import LoopEditor from "./ConditionAndLoop/LoopEditor";
import ConnectorEditor from "./Connector/ConnectorEditor";
import DataStoreEditor from "./DataStore/DataStoreEditor";
import ParserEditor from "./Parser/ParserEditor";
import RoutingProcessEditor from "./RoutingProcessEditor/RoutingProcessEditor";
import ActSetStepEditor from "./Set/ActSetStepEditor";
import StopProcessEditor from "./StopProcessEditor/StopProcessEditor";
import TimeEditor from "./Time/TimeEditor";
import WorkInstructionsEditor from "./WorkInstructions/WorkInstructionsEditor";
import { ActionEditorContext, ActionEditorContextProps } from "./context";

const debug = Debug("kortex:ui:action-editor");

const useStyles = makeStyles({
    root: {
        backgroundColor: theme.palette.grey[200],
        display: "grid",
        gridTemplateRows: "auto 1fr",
        height: "100%",
    },
    content: {
        display: "grid",
        gridColumnGap: "16px",
        gridTemplateColumns: "188px 1fr",
        margin: "16px",
    },
    horizontalList: {
        display: "flex",
        flexDirection: "row",
        padding: "0px",
    },
    listItem: {
        color: theme.palette.grey[600],
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        justifyContent: "center",
        width: "61px",
    },
    verticalDivider: {
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        margin: "10px 2px",
        width: "0px",
    },
    leftColumn: {
        display: "grid",
        gridTemplateRows: "auto 1fr",
        rowGap: "16px",
    },
    noWrapLabel: {
        whiteSpace: "nowrap",
    },
    toolbar: {
        display: "flex",
        height: "75px",
    },
    toolbarContent: {
        alignItems: "center",
        display: "grid",
        gridAutoColumns: "94% 2% 4%",
        gridAutoFlow: "column",
        width: "100%",
    },
    stepTitle: {
        minWidth: "300px",
        width: "100%",
    },
    stepTitleInput: {
        borderBottom: `2px solid ${theme.palette.grey[500]}`,
        color: theme.palette.common.white,
    },
    textFieldInput: {
        "& .Mui-disabled": {
            color: theme.palette.grey[500],
        },
        width: "100%",
    },
    stepsContainer: {
        backgroundColor: theme.palette.grey[100],
        height: "calc(100vh - 176px)", // Header(75px), margins(32px), toolbar icons(53px), header/toolbar grid gap(16px)
        minWidth: "160px",
        overflowX: "hidden",
        overflowY: "auto",
    },
    stepItem: {
        alignItems: "center",
        border: "1px solid rgba(0,0,0,0.12)",
        borderRadius: "2px",
        display: "grid",
        fontSize: "1.1rem",
        gridTemplateColumns: "1fr auto auto auto",
        lineHeight: "18px",
        marginBottom: "5px",
        minHeight: "50px",
        paddingLeft: "8px",
    },
    stepLabel: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        cursor: "pointer",
    },
    stepLabelTime: {
        paddingRight: "8px",
        whiteSpace: "nowrap",
    },
    stepLabelTimeSelected: {
        whiteSpace: "nowrap",
    },
    stepLabelTooltip: {
        borderRadius: "5px",
        fontSize: "1.1rem",
        fontWeight: 400,
        whiteSpace: "pre-line",
    },
    stepListRoot: {
        padding: "5px",
    },
});

interface ISortableItemProps {
    step: ProcessActionStep;
    itemIndex: number;
}

interface ISortableListProps {
    stepsList: ProcessActionStep[];
}

let stepModifiersCb: Parameters<ActionEditorContextProps["setStepModifiersCb"]>[0];

interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    editedAction: ProcessAction;
    onCloseDialog?: () => void;
    onSelectedStepChanged?: (selectedIndex: number) => void;
    preselectedStep?: number;
}

export default function ActionEditor(props: IOwnProps): JSX.Element {
    const { userAccessLevel, editedAction } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const dispatch = useThunkDispatch();

    const copiedProcessActionStep = useSelectorProcessActionStepCopy();

    const rootRef = useRef(null);
    const [selectedStepIndex, setSelectedStepIndex] = useState<number>(props.preselectedStep ?? editedAction.steps.length > 0 ? 0 : -1);
    const [stepEditorOpen, setStepEditorOpen] = useState<boolean>(false);
    const [editedActionStep, setEditedActionStep] = useState<ProcessActionStep | undefined>(undefined);
    // the reason we use coords instead of a ref, is because material ui throws an error because the refed element is within a sortableList
    const [menuAnchorCoords, setMenuAnchorCoords] = useState<{ top: number; left: number } | null>(null);
    const [previousStepIndex, setPreviousStepIndex] = useState<number>(-1);
    const [isCreateNextChecked, setIsCreateNextChecked] = useState<boolean>(false);
    const [stepContainerFocused, setStepContainerFocused] = useState(false);
    const [insertNewStep, setInsertNewStep] = useState<boolean>(false);
    const [steps, setSteps] = useState<ProcessActionStep[]>(editedAction.steps);

    /**
     * Callback used when debouncing complete to crete callback history
     */
    const createAndPushHistory = (updatedActionProps: ProcessAction): void => {
        const previousPatch = jsonPatch.compare(updatedActionProps, editedAction);
        const nextPatch = jsonPatch.compare(editedAction, updatedActionProps);

        if (previousPatch && previousPatch.length > 0) {
            pushHist(previousPatch, nextPatch);
        }
    };

    const [editedActionProps, setEditedActionProps] = useStoreState<ProcessAction>(editedAction, processActionUpdate, {
        debouncingTimeInMs: 1000,
        dispatchArray: true,
        endOfDebouncingCb: createAndPushHistory,
    });
    const [pushHist, undoHist, redoHist, canUndoHist, canRedoHist] = useHistoryStack();

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Verify how many steps there are in the current action
     * If there is only one step remaining, it cannot be delete
     */
    const isStepDeletable = editedAction.steps.length > 1;

    // Key down event - Arrow up - Select the previous step in the list
    useKeybind("ArrowUp", () => {
        {
            const index = selectedStepIndex - 1;

            if (index >= 0) {
                handleSelectStep(index)();
            }
        }
    });

    /**
     * Key down event - Arrow down
     * Select the next step in the list
     */
    useKeybind("ArrowDown", (): void => {
        const index = selectedStepIndex + 1;

        if (index <= editedActionProps.steps.length - 1) {
            handleSelectStep(index)();
        }
    });

    /**
     * Key down event - Delete
     * Delete the current step
     */
    useKeybind(
        "Delete",
        () => {
            handleDeleteStep();
        },
        { disabled: !isStepDeletable || !stepContainerFocused || readOnly }
    );

    /**
     * Key down event - Ctrl+y
     * Redo changes
     */
    useKeybind(
        "y",
        () => {
            if (canRedoHist) {
                handleUndoRedoHist(true)();
            }
        },
        { ctrl: true, disabled: readOnly }
    );

    /**
     * Key down event - Ctrl+z
     * Undo changes
     */
    useKeybind(
        "z",
        () => {
            if (canUndoHist) {
                handleUndoRedoHist(false)();
            }
        },
        { ctrl: true, disabled: readOnly }
    );

    /**
     * Effect triggered when parent update the edited action props
     * We update local state based on new injected props
     * If "Create another" was checked during step creation, open another step editor
     */
    useEffect((): void => {
        if (!stepEditorOpen) {
            setEditedActionStep(editedAction.steps[selectedStepIndex]);
            setSteps(editedAction.steps);
        }

        if (isCreateNextChecked) {
            handleInsertStep();
            setIsCreateNextChecked(false);
        }
    }, [editedAction]);

    /**
     * Updates selected step index on prop change
     */
    useEffect((): void => {
        if (props.preselectedStep !== undefined) {
            setSelectedStepIndex(props.preselectedStep);
            setEditedActionStep(editedAction.steps[props.preselectedStep]);
        }
    }, [props.preselectedStep]);

    /**
     * Called when the process label changes
     *
     * @param {string} value - New label to save
     */
    const handleActionLabelChanged = (value: string): void => {
        const clonedActionProps = deepClone(editedActionProps);
        clonedActionProps.label = value;

        saveCurrentAction(clonedActionProps, true);
    };

    /**
     * Saves current action
     *
     * @param {ProcessAction} updatedActionProps - action props
     * @param {boolean} pushHistory - indicates if we should push the history
     */
    const saveCurrentAction = (updatedActionProps: ProcessAction, pushHistory: boolean): void => {
        setEditedActionProps(updatedActionProps, undefined, { noDeboucing: pushHistory === false });
    };

    /**
     * Called when user presses Undo/Redo buttons.
     * Applies change based on stack and ensures the right step is selected.
     *
     * @param {boolean} redo - true if its a redo, false if its an undo
     */
    const handleUndoRedoHist =
        (redo: boolean): (() => void) =>
        (): void => {
            const jsonUndoPatch = redo ? redoHist() : undoHist();

            if (jsonUndoPatch.length > 0) {
                const patchedResult: ProcessAction = jsonPatch.deepClone(editedActionProps);
                jsonPatch.applyPatch(patchedResult, jsonUndoPatch);

                // get modified step from path (ex. "/extendedProps/<stepIndex>/elements/0")
                const stepIndex = parseInt(jsonUndoPatch[0].path.split("/")[2], 10);
                if (editedActionProps.label !== patchedResult.label) {
                    // action name updated
                    dispatch(processActionUpdate([patchedResult]));
                } else {
                    // steps list edited
                    if (stepIndex <= patchedResult.steps.length) {
                        if (editedActionProps.steps.length > patchedResult.steps.length) {
                            // undo step added or redo step inserted by undo step deleted
                            // delete step in database
                            dispatch(
                                processActionStepDelete({
                                    processActionStepId: editedActionProps.steps[stepIndex].processActionStepId,
                                    previousStepId: editedActionProps.steps[stepIndex].previousStepId,
                                    processAction: editedActionProps,
                                    selectedStepIndex: stepIndex,
                                })
                            );
                            setSelectedStepIndexWrapper(stepIndex - 1);
                        } else if (editedActionProps.steps.length < patchedResult.steps.length) {
                            // undo step deleted or redo step deleted by undo step added
                            // insert step in database
                            dispatch(
                                processActionStepInsert({
                                    previousStepId: editedActionProps.steps[stepIndex - 1].processActionStepId,
                                    processAction: patchedResult,
                                    stepProps: patchedResult.steps[stepIndex],
                                    undoStepDeleted: true,
                                })
                            );

                            setSelectedStepIndexWrapper(stepIndex);
                        } else if (editedActionProps.steps[stepIndex].previousStepId !== patchedResult.steps[stepIndex].previousStepId) {
                            // reorder step

                            // get all index in the path
                            const allIndexPath = jsonUndoPatch.map((j) => parseInt(j.path.split("/")[2], 10));
                            // remove duplicates
                            const indexListStepsUpdated = [...new Set(allIndexPath)];

                            // build steps list to be updated.
                            const stepsToBeUpdated: ProcessActionStep[] = [];
                            indexListStepsUpdated.map((index) => stepsToBeUpdated.push(patchedResult.steps[index]));

                            // update steps list
                            dispatch(
                                processActionStepUpdate({
                                    processActionSteps: stepsToBeUpdated,
                                    processAction: editedActionProps,
                                    selectedStepIndex,
                                })
                            );
                            setSelectedStepIndexWrapper(stepIndex);
                        } else if (editedActionProps.steps.length === patchedResult.steps.length) {
                            // step updated
                            dispatch(
                                processActionStepUpdate({
                                    processActionSteps: [patchedResult.steps[stepIndex]],
                                    processAction: editedActionProps,
                                    selectedStepIndex,
                                })
                            );

                            setSelectedStepIndexWrapper(stepIndex);
                        }
                    } else {
                        setSelectedStepIndexWrapper(patchedResult.steps.length - 1 || 0);
                    }
                }
            }
        };

    /**
     * handles the close dialog
     */
    const handleCloseDialog = (): void => {
        if (props.onCloseDialog) {
            props.onCloseDialog();
        }
    };

    /**
     * handle insert step
     */
    const handleInsertStep = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        if (event) {
            event.stopPropagation();
        }

        setInsertNewStep(true);
        setPreviousStepIndex(selectedStepIndex);
        setStepEditorOpen(true);
    };

    /**
     * handles the edit step
     *
     * @param {number} index - edit index
     */
    const handleEditStep =
        (index: number): (() => void) =>
        (): void => {
            setSelectedStepIndexWrapper(index);
            setEditedActionStep(steps[index]);
        };

    /**
     * handles process action and steps changed
     *
     * @param {ProcessAction} action - process action and steps changed
     * @param {number} index - index new selected step
     */
    const handleProcessActionAndStepsChanged =
        (action: ProcessAction, index: number): (() => void) =>
        (): void => {
            createAndPushHistory(action);
            setSteps(action.steps);
            if (index !== selectedStepIndex) {
                setSelectedStepIndex(index);
            }
        };

    /**
     * handle the copy step
     */
    const handleCopyStep = (): void => {
        setMenuAnchorCoords(null);

        if (!editedActionStep) {
            return;
        }

        dispatch(
            processActionStepCopy({
                actionType: editedActionProps.type,
                actionStep: { ...editedActionStep },
            })
        );
    };

    /**
     * handles the paste step
     */
    const handlePasteStep = (): void => {
        setMenuAnchorCoords(null);

        if (copiedProcessActionStep?.actionType === editedActionProps.type) {
            dispatch(
                processActionStepInsertCopy(
                    {
                        processActionStepIdSelected: editedActionProps.steps[selectedStepIndex].processActionStepId,
                        processActionStepToCopy: {
                            ...copiedProcessActionStep?.actionStep,
                            processActionId: editedActionProps.processActionId,
                        },
                        processActionTye: copiedProcessActionStep?.actionType,
                    },
                    editedActionProps.processId
                )
            ).then((res) => {
                if (res) {
                    handleProcessActionAndStepsChanged(res, selectedStepIndex + 1)();
                }
            });
        }
    };

    /**
     * Selects a step. Sets the previousStepIndex. Should always be used instead of setSelectedStepIndex
     *
     * @param {number} stepIndex - Step index to select
     */
    const setSelectedStepIndexWrapper = (stepIndex: number): void => {
        setSelectedStepIndex(stepIndex);
        setPreviousStepIndex(selectedStepIndex);
    };

    /*
     * Handles the deletion of a step
     */
    const handleDeleteStep = (): void => {
        setMenuAnchorCoords(null);
        deleteStep();
    };

    /**
     * handles the select step
     *
     * @param {number} newStepIndex - new step index
     */
    const handleSelectStep =
        (newStepIndex: number): (() => void) =>
        (): void => {
            handleEditStep(newStepIndex)();
            if (props.onSelectedStepChanged) {
                props.onSelectedStepChanged(newStepIndex);
            }
        };

    /**
     * deletes a step
     */
    const deleteStep = (): void => {
        if (!editedActionProps) {
            return;
        }

        dispatch(
            processActionStepDelete({
                processActionStepId: editedActionProps.steps[selectedStepIndex].processActionStepId,
                previousStepId: editedActionProps.steps[selectedStepIndex].previousStepId,
                processAction: editedActionProps,
                selectedStepIndex: selectedStepIndex,
            })
        ).then((res) => {
            if (res) {
                handleProcessActionAndStepsChanged(res, selectedStepIndex > 0 ? selectedStepIndex - 1 : 0)();
            }
        });

        setStepEditorOpen(false);
    };

    /**
     * handles the cancel step
     */
    const handleCancelStep = (): void => {
        setInsertNewStep(false);
        setStepEditorOpen(false);
        setSelectedStepIndexWrapper(previousStepIndex);
    };

    /**
     * Called when user create a new step
     *
     * @param {ProcessActionStep} step - info of the newly created step
     * @param {boolean} createNext - if "Create another" checkbox was checked, keep the editor opened and create another step
     */
    const handleSaveNewStep = (step: ProcessActionStep, createNext: boolean): void => {
        debug("handleSaveNewStep");
        if (!validateStepInfo(step)) {
            return;
        }

        insertNewStep
            ? dispatch(
                  processActionStepInsert({
                      previousStepId: editedActionProps.steps[selectedStepIndex].processActionStepId,
                      processAction: editedActionProps,
                      stepProps: step,
                  })
              ).then((res) => {
                  if (res) {
                      handleProcessActionAndStepsChanged(res, selectedStepIndex + 1)();
                  }
              })
            : dispatch(processActionStepUpdate({ processActionSteps: [step], processAction: editedActionProps, selectedStepIndex })).then(
                  (res) => {
                      if (res) {
                          handleProcessActionAndStepsChanged(res, selectedStepIndex)();
                      }
                  }
              );

        if (!createNext) {
            setStepEditorOpen(false);
            setInsertNewStep(false);
        }
    };

    /**
     * handles save action step props
     *
     * @param {ProcessActionStep} modifiedActionStep - modified action step
     */
    const handleSaveActionStep = (modifiedActionStep: ProcessActionStep): void => {
        dispatch(
            processActionStepUpdate({ processActionSteps: [modifiedActionStep], processAction: editedActionProps, selectedStepIndex })
        ).then((res) => {
            if (res) {
                handleProcessActionAndStepsChanged(res, selectedStepIndex)();
            }
        });
    };

    /**
     * handles the reorder step
     *
     * @param {SortEnd} sort - sort order
     */
    const handleReorderStep = (sort: SortEnd): void => {
        if (sort.oldIndex === sort.newIndex) {
            return;
        }

        dispatch(
            processActionStepReorder(
                { processActionSteps: editedActionProps.steps, newIndex: sort.newIndex, oldIndex: sort.oldIndex },
                editedActionProps.processId
            )
        ).then((res) => {
            if (res) {
                handleProcessActionAndStepsChanged(res, sort.newIndex)();
            }
        });
    };

    /**
     * validates the step info
     *
     * @param {ProcessActionStep} step - step to be validated
     */
    const validateStepInfo = (step: ProcessActionStep): boolean => {
        return Boolean(step.label) && step.label.length >= 3;
    };

    /**
     *
     * @param {number} index - handles the step menu click
     */
    const handleStepMenuClick =
        (index: number): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            setMenuAnchorCoords({ left: event.clientX, top: event.clientY });
            handleEditStep(index)();
        };

    /**
     * handle the step menu close
     */
    const handleStepMenuClose = (): void => {
        setMenuAnchorCoords(null);
    };

    /**
     * handles the stpe menu edit
     */
    const handleStepMenuEdit = (event: React.MouseEvent | React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
        event.stopPropagation();
        setMenuAnchorCoords(null);
        setStepEditorOpen(true);
    };

    /**
     * are steps disabled
     *
     * @param {ProcessActionType} type - action type
     */
    const stepsAreDisabled = (type: ProcessActionType): boolean => {
        return (
            type === "core-input" ||
            type === "core-output" ||
            type === "core-routing-process" ||
            type === "core-routing-fail" ||
            type === "core-stop-process" ||
            type === "core-fail" ||
            type === "core-loop"
        );
    };

    /**
     * displays the standard time
     *
     * @param {number} standardTimeSec - time in seconds
     */
    const displayStandardTime = (standardTimeSec: number): string => {
        const hours = Math.floor(standardTimeSec / 3600);
        const minutes = Math.floor((standardTimeSec % 3600) / 60);
        const seconds = Math.floor((standardTimeSec % 3600) % 60);

        return standardTimeSec > 0
            ? " (" +
                  (hours !== 0 ? hours + "h" : "") + // Hours
                  (hours !== 0 && minutes !== 0 ? " " : "") + // Space between hours and minutes
                  (minutes !== 0 ? minutes + "m" : "") + // Minutes
                  ((hours !== 0 || minutes !== 0) && seconds !== 0 ? " " : "") + // Space between hours/minutes and seconds
                  (seconds !== 0 ? seconds + "s)" : ")") // Seconds
            : "";
    };

    const SortableItem = SortableElement<ISortableItemProps>(({ step, itemIndex }: ISortableItemProps) => {
        const selected = itemIndex === selectedStepIndex;
        const modifiers = stepModifiersCb?.(step);

        return (
            <div
                className={classes.stepItem}
                id="actionStepId"
                onDoubleClick={handleStepMenuEdit}
                onClick={handleSelectStep(itemIndex)}
                style={{ backgroundColor: selected ? theme.palette.grey[200] : theme.palette.common.white }}
            >
                {/* Label */}
                <Tooltip classes={{ tooltip: classes.stepLabelTooltip }} key={`step${itemIndex}LabelTooltip`} title={step.label}>
                    <span className={classes.stepLabel}>{step.label}</span>
                </Tooltip>

                {/* Training communique */}
                {modifiers?.icons?.map(({ element, tooltip = "" }, index) => (
                    <Tooltip classes={{ tooltip: classes.stepLabelTooltip }} key={`step${itemIndex}ModifierIcon${index}`} title={tooltip}>
                        <div>{element}</div>
                    </Tooltip>
                ))}

                {/* Standard Time */}
                <span className={selected ? classes.stepLabelTimeSelected : classes.stepLabelTime}>
                    {displayStandardTime(step.standardTimeSec)}
                </span>

                {/* Menu */}
                {itemIndex === selectedStepIndex && (
                    <IconButton onClick={handleStepMenuClick(itemIndex)} id="actionStepMenuButtonId">
                        <MoreVertIcon />
                    </IconButton>
                )}
            </div>
        );
    });

    const SortableList = SortableContainer<ISortableListProps>(({ stepsList }: ISortableListProps): JSX.Element => {
        return (
            <List classes={{ root: classes.stepListRoot }}>
                {stepsList.map(
                    (step: ProcessActionStep, index: number): JSX.Element => (
                        <SortableItem key={index} index={index} step={step} itemIndex={index} />
                    )
                )}
            </List>
        );
    });

    /**
     * Action which execute a callback for each step in the current action
     *
     * @param {(step: ProcessActionStep, index: number) => ProcessActionStep} callback - Action which will be called for each step
     */
    const forEachStep = (callback: (step: ProcessActionStep, index: number) => ProcessActionStep): void => {
        const updatedAction = deepClone(editedActionProps);
        editedActionProps.steps.forEach(
            (step, extendedPropsIndex): ProcessActionStep => (updatedAction.steps[extendedPropsIndex] = callback(step, extendedPropsIndex))
        );
        saveCurrentAction(updatedAction, true);
    };

    /**
     * Display selected action's editor
     */
    const displayEditor = (): JSX.Element => {
        if (selectedStepIndex < 0 || !editedActionProps.steps[selectedStepIndex]) {
            return <div />;
        }

        switch (editedActionProps.type) {
            case "core-condition":
                return (
                    <ConditionEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        lastStep={editedActionProps.steps.length === selectedStepIndex + 1}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-connector":
                return (
                    <ConnectorEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-data-store":
                return (
                    <DataStoreEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-input":
                return (
                    <InputStepEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-failure-ticket-create":
                return (
                    <ActFailureTicketForkStepEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-loop":
                return (
                    <LoopEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );

            case "core-math":
                return (
                    <ActMathStepEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-message":
                return (
                    <ActMessageStepEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-parser":
                return (
                    <ParserEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-routing-process":
                return (
                    <RoutingProcessEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-time":
                return (
                    <TimeEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-work-instructions":
                return (
                    <WorkInstructionsEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        forEachStep={forEachStep}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case "core-stop-process":
                return (
                    <StopProcessEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={props.userAccessLevel}
                    />
                );
            case "core-set":
                return (
                    <ActSetStepEditor
                        actionStepProps={editedActionProps.steps[selectedStepIndex]}
                        onChanged={handleSaveActionStep}
                        selectedStepIndex={selectedStepIndex}
                        userAccessLevel={props.userAccessLevel}
                    />
                );
            default:
                return <div />;
        }
    };

    /**
     * Check if step sorting is disabled
     */
    const isStepSortingDisabled = (): boolean => {
        return readOnly;
    };

    /**
     * Handle step list focus toggling to ensable/disable delete keypress
     *
     * @param {boolean} focused - If the step list should be focused or not
     */
    const handleSetStepListFocus = (focused: boolean) => (): void => {
        setStepContainerFocused(focused);
    };

    const setStepModifiersCb: ActionEditorContextProps["setStepModifiersCb"] = (cb) => {
        if (cb) stepModifiersCb = cb;
        else stepModifiersCb = undefined;
    };

    return (
        <ActionEditorContext.Provider value={{ setStepModifiersCb, editedActionStep, onChangedStep: handleSaveActionStep }}>
            <div id="actionEditorId" ref={rootRef} className={classes.root}>
                {/* ACTION EDITOR TOOLBAR */}
                <AppBar position="static">
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.toolbarContent}>
                            <KortexTextField
                                TextFieldProps={{
                                    autoComplete: "off",
                                    disabled: readOnly,
                                    id: "actionLabelTextfieldId",
                                    placeholder: translate("action.editor.untitled"),
                                    className: classes.textFieldInput,
                                }}
                                onChanged={handleActionLabelChanged}
                                className={classes.stepTitle}
                                InputProps={{
                                    className: classes.stepTitleInput,
                                }}
                                value={editedActionProps.label}
                            />
                            {<NotificationsCenterDialog />}
                            <IconButton color="inherit" onClick={handleCloseDialog} aria-label="Close" id="actionCloseButtonId">
                                <CloseIcon fontSize="large" />
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>

                <div className={classes.content}>
                    {/* GENERAL STEP EDITOR */}
                    <div className={classes.leftColumn}>
                        <Paper>
                            <List className={classes.horizontalList}>
                                <ListItem
                                    className={classes.listItem}
                                    button={true}
                                    disabled={readOnly || stepsAreDisabled(editedActionProps.type)}
                                    onClick={handleInsertStep}
                                    id="actionAddStepButtonId"
                                >
                                    <KortexLabelIcon label={translate("action.editor.addStep")} classes={{ label: classes.noWrapLabel }}>
                                        <Add />
                                    </KortexLabelIcon>
                                </ListItem>
                                <div className={classes.verticalDivider} />
                                <ListItem
                                    className={classes.listItem}
                                    button={true}
                                    onClick={handleUndoRedoHist(false)}
                                    disabled={readOnly || !canUndoHist}
                                    id="actionUndoButtonId"
                                >
                                    <KortexLabelIcon label={translate("action.editor.undo")}>
                                        <Undo />
                                    </KortexLabelIcon>
                                </ListItem>
                                <ListItem
                                    className={classes.listItem}
                                    button={true}
                                    onClick={handleUndoRedoHist(true)}
                                    disabled={readOnly || !canRedoHist}
                                    id="actionRedoButtonId"
                                >
                                    <KortexLabelIcon label={translate("action.editor.redo")}>
                                        <Redo />
                                    </KortexLabelIcon>
                                </ListItem>
                            </List>
                        </Paper>
                        <ClickAwayListener onClickAway={handleSetStepListFocus(false)}>
                            <Paper className={classes.stepsContainer} onClick={handleSetStepListFocus(true)}>
                                <SortableList
                                    stepsList={steps}
                                    helperContainer={rootRef.current as unknown as HTMLElement | undefined}
                                    onSortEnd={handleReorderStep}
                                    distance={20}
                                    shouldCancelStart={isStepSortingDisabled}
                                />
                            </Paper>
                        </ClickAwayListener>
                    </div>

                    {/* PAGE EDITOR */}
                    {displayEditor()}
                </div>

                <ActionStepEditor
                    autoNextDisabled={
                        isInput(editedActionProps) ||
                        isOutput(editedActionProps) ||
                        isWorkInstructions(editedActionProps) ||
                        isSequencerCoreAction(editedActionProps)
                    }
                    edit={selectedStepIndex !== -1}
                    editedStep={editedActionStep}
                    open={stepEditorOpen}
                    onSave={handleSaveNewStep}
                    onCancel={handleCancelStep}
                    userAccessLevel={userAccessLevel}
                />

                <Menu
                    anchorPosition={{
                        left: menuAnchorCoords ? menuAnchorCoords.left : 0,
                        top: menuAnchorCoords ? menuAnchorCoords.top : 0,
                    }}
                    anchorReference={"anchorPosition"}
                    open={menuAnchorCoords !== null}
                    onClose={handleStepMenuClose}
                    id="actionStepMenuId"
                >
                    <MenuItem onClick={handleStepMenuEdit} id="actionStepMenuEditId">
                        {readOnly ? translate("action.editor.read") : translate("action.editor.edit")}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        onClick={handleCopyStep}
                        id="actionStepMenuCopyId"
                        disabled={readOnly || stepsAreDisabled(editedActionProps.type)}
                    >
                        {translate("action.editor.copy")}
                    </MenuItem>
                    <MenuItem
                        onClick={handlePasteStep}
                        id="actionStepMenuPasteId"
                        disabled={readOnly || stepsAreDisabled(editedActionProps.type)}
                    >
                        {translate("action.editor.paste")}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        disabled={readOnly || !isStepDeletable || stepsAreDisabled(editedActionProps.type)}
                        onClick={handleDeleteStep}
                        id="actionStepMenuDeleteId"
                    >
                        {translate("action.editor.delete")}
                    </MenuItem>
                </Menu>
            </div>
        </ActionEditorContext.Provider>
    );
}
