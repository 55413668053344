import { forEachLinkedProcessAction } from "./forEachLinkedProcessAction";
/**
 * Return process with only the actions that are linked, from the Input to the Output (also includes Fail path).
 */
export function getProcessWithLinkedActions(process) {
    const linkedProcessActions = [];
    /**
     * Go through all the linked actions (from the Input to the Output, then the Fail path).
     * Add them to the array.
     */
    forEachLinkedProcessAction(process, (action) => {
        linkedProcessActions.push({ ...action });
    });
    return { ...process, actions: linkedProcessActions };
}
