import { KortexDialogLogin, KortexOutlinedTextField } from "@aos/react-components";
import { LocationKeys, mangleURL } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { default as React, useEffect } from "react";

import appConfigs from "../../../configs/app";
import { useLocalStorage } from "../../../hooks/useStorage";
import { useTranslate } from "../../../hooks/useTranslate";
import { EnumLocalStorageItem } from "../../../redux/EnumLocalStorageItem";
import { setLanguage } from "../../../redux/general-manager/general-thunks-general";
import { useSelectorLanguage } from "../../../redux/selectors";
import { login } from "../../../redux/user-manager/UserActions";
import { isDesktopMode } from "../../../utilitites/desktopUtils";
import getUrlParameter from "../../../utilitites/getUrlParameter";

interface IOwnProps {
    open: boolean;
}

const HUB_HOST_INSTANCE_PROD = "https://hub.aos.kinovaapps.com";
const HUB_HOST_INSTANCE_DEV = "https://hub.aos-dev.kinovaapps.com";
const HUB_HOST_INSTANCE_STAGING = "https://hub.aos-staging.kinovaapps.com";
const HUB_HOST_INSTANCE_TEST = "http://hub.aos-test.kinovaapps.com";
const HUB_HOST_PREFIX_SECURED = "https://hub.";
const HUB_HOST_PREFIX_UNSECURED = "http://hub.";

export default function LoginPage(props: IOwnProps): JSX.Element {
    const { open } = props;

    const dispatch = useThunkDispatch();
    const language = useSelectorLanguage();
    const translate = useTranslate();

    const [defaultUserName, setDefaultUserName] = useLocalStorage(EnumLocalStorageItem.USER_NAME, "");
    const [defaultInstance, setDefaultInstance] = useLocalStorage(EnumLocalStorageItem.INSTANCE, "");

    /** Called on Mount */
    useEffect((): void => {
        if (open) {
            let instance = getUrlParameter("instance");
            let username = getUrlParameter("username");
            const password = getUrlParameter("password");
            const currentUrl = mangleURL(window.location.href);
            const isLocalhost = currentUrl.host === "localhost";

            if (instance) {
                setDefaultInstance(instance);
            } else if (!isLocalhost) {
                if (isDesktopMode) {
                    if (process.env.NODE_ENV === "production") {
                        instance = HUB_HOST_INSTANCE_PROD;
                    } else if (process.env.NODE_ENV === "staging") {
                        instance = HUB_HOST_INSTANCE_STAGING;
                    } else {
                        instance = HUB_HOST_INSTANCE_DEV;
                    }
                    setDefaultInstance(instance);
                } else {
                    // FIXME: PATCH FOR AUTOMATED TEST
                    if (currentUrl.host === "aos-test.kinovaapps.com") {
                        instance = HUB_HOST_INSTANCE_TEST;
                    } else {
                        instance = currentUrl.secured
                            ? HUB_HOST_PREFIX_SECURED + currentUrl.host
                            : HUB_HOST_PREFIX_UNSECURED + currentUrl.host;
                    }
                    instance = currentUrl.secured ? HUB_HOST_PREFIX_SECURED + currentUrl.host : HUB_HOST_PREFIX_UNSECURED + currentUrl.host;
                    setDefaultInstance(instance);
                }
            } else {
                instance = defaultInstance;
            }

            if (username) {
                setDefaultUserName(username as "");
            } else {
                username = defaultUserName;
            }

            if (username && password && instance) {
                // FIXME: add api ready var in redux store
                setTimeout((): void => {
                    dispatch(login(username, password, instance));
                }, 500);
            }
        }
    }, []);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        const userName = event.currentTarget.elements.namedItem("usernameInputId") as HTMLInputElement;
        const password = event.currentTarget.elements.namedItem("passwordInputId") as HTMLInputElement;
        const instance = event.currentTarget.elements.namedItem("instanceInputId") as HTMLInputElement;

        setDefaultUserName(userName.value);
        setDefaultInstance(instance.value);

        return dispatch(login(userName.value, password.value, instance.value));
    };

    const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        dispatch(setLanguage(event.target.value as LocationKeys));
    };

    return (
        <KortexDialogLogin
            textLabels={{
                connectButtonLabel: translate("login.connect"),
                passwordLabel: translate("login.password"),
                usernameLabel: translate("login.username"),
                titleLabel: "Kortex™ AOS",
                languageLabel: translate("login.language"),
                accordionLabel: translate("login.advsettings"),
            }}
            onConnect={handleLogin}
            open={open}
            username={defaultUserName}
            acceptedLanguages={appConfigs.acceptedLanguages.map((lang) => {
                return {
                    key: lang,
                    label: translate(lang),
                };
            })}
            language={language}
            onLanguageChange={handleLanguageChange}
            showForgotPassword={true}
            expandedByDefault={true}
        >
            <KortexOutlinedTextField
                id="instanceInputId"
                fullWidth={true}
                defaultValue={defaultInstance}
                label={translate("login.instance")}
            />
        </KortexDialogLogin>
    );
}
