import { theme } from "@aos/react-components";
import { UserId } from "@kortex/aos-common";
import UserSelectorDialog from "@kortex/aos-ui/components/core/UserSelectorDialog/UserSelectorDialog";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesUsers } from "@kortex/aos-ui/redux/effects";
import {
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
    makeStyles,
} from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import GetUsersIcon from "@material-ui/icons/GroupAdd";
import WarningIcon from "@material-ui/icons/Warning";
import React from "react";

const useStyles = makeStyles({
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
    traineeButton: {
        margin: "5px",
        padding: "0px 30px",
    },
    userIconsContainer: {
        display: "flex",
    },
    // DIALOG
    userList: {
        display: "flex",
        flexDirection: "column",
    },
    listTitler: {
        padding: "5px",
    },
    headerContent: {
        display: "flex",
        backgroundColor: theme.palette.grey[200],
        padding: "8px 0px",
    },
    containerFab: {
        height: "60px",
        display: "flex",
        justifyContent: "flex-end",
    },
    warningIcon: {
        color: theme.palette.warning.main,
        fontSize: "40px",
        marginRight: "20px",
    },
});

export interface IUserListWarninings {
    [userId: UserId]: string;
}

interface IOwnProps {
    disabled?: boolean;
    title: string;
    userIdsSelected: UserId[];
    userIdToExclude?: UserId[];
    userWarnings?: IUserListWarninings;
    trainer?: boolean;
    handleClearUsers?: () => void;
    handleDeleteUserId: (userIdToBeDeleted: UserId) => void;
    handleGetUsers?: () => void;
    handleUserIdSelectorDialogConfirm: (selectedUserIdsDialog: UserId[]) => void;
}

function TrainingCertificateFormUserList(props: IOwnProps): JSX.Element {
    const {
        disabled = false,
        title,
        trainer = false,
        userIdsSelected,
        userIdToExclude = [],
        handleClearUsers,
        handleDeleteUserId,
        handleGetUsers,
        handleUserIdSelectorDialogConfirm,
        userWarnings = {},
    } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const users = useEntitiesUsers();

    /**
     * Deletes user
     */
    const handleDeleteUser =
        (userIdToBeDeleted: UserId): (() => void) =>
        (): void => {
            handleDeleteUserId(userIdToBeDeleted);
        };

    /**
     * Confirm selection
     */
    const handleConfirmSelection = (selectedUserIdsDialog: UserId[]): void => {
        handleUserIdSelectorDialogConfirm(selectedUserIdsDialog);
    };

    return (
        <div id={`${title}ContainerId`} className={classes.userList}>
            <Typography variant="h4" className={classes.listTitler}>
                {title}
            </Typography>

            <List
                id={`${title}ContentId`}
                disablePadding={true}
                /* ----------------------------------------------------------------------------------------------- */
                /* HEADER -------------------------------------------------------------------------------- */
                /* ----------------------------------------------------------------------------------------------- */
                subheader={
                    <div>
                        <div className={classes.headerContent}>
                            {/* USER NAME */}
                            <ListItem>
                                <Typography variant="h6">{translate("training.userName")}</Typography>
                            </ListItem>
                            <div className={classes.userIconsContainer}>
                                {handleClearUsers ? (
                                    <Tooltip classes={{ tooltip: classes.tooltip }} title={translate("general.clearAll")}>
                                        <IconButton id="userListGetUsersButtonId" onClick={handleClearUsers}>
                                            <ClearAllIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                                {handleGetUsers ? (
                                    <Tooltip classes={{ tooltip: classes.tooltip }} title={translate("training.getUsersWithTraining")}>
                                        <IconButton id="userListGetUsersButtonId" onClick={handleGetUsers}>
                                            <GetUsersIcon />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </div>
                        </div>
                    </div>
                }
            >
                {/* ----------------------------------------------------------------------------------------------- */}
                {/* USER LIST ---------------------------------------------------------------------------------- */}
                {/* ----------------------------------------------------------------------------------------------- */}
                {users
                    .filter((userToBeFiltered) => userIdsSelected.includes(userToBeFiltered.userId))
                    .sort((a, b) => {
                        const indexA = userIdsSelected.indexOf(a.userId);
                        const indexB = userIdsSelected.indexOf(b.userId);
                        return indexA - indexB;
                    })
                    .map((user, index) => (
                        <ListItem
                            key={index}
                            style={{
                                backgroundColor: index % 2 === 0 ? theme.palette.common.white : theme.palette.grey[100],
                            }}
                        >
                            {userWarnings[user.userId] ? (
                                <Tooltip classes={{ tooltip: classes.tooltip }} title={userWarnings[user.userId]}>
                                    <ListItemIcon className={classes.warningIcon}>
                                        <WarningIcon />
                                    </ListItemIcon>
                                </Tooltip>
                            ) : null}
                            <ListItemText id={`${title}UserNameIdId`} primary={`${user?.firstName} ${user?.lastName}`} />
                            <ListItemSecondaryAction>
                                {(!trainer || index > 0) && (
                                    <Tooltip classes={{ tooltip: classes.tooltip }} title={translate("general.delete")} placement="left">
                                        <IconButton onClick={handleDeleteUser(user.userId)} size="small">
                                            <DeleteIcon id={`${title}DeleteIconId`} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
            </List>
            <div className={classes.containerFab}>
                <UserSelectorDialog
                    disabled={disabled}
                    excludedUserIdsFromTheList={userIdToExclude}
                    onConfirmDialog={handleConfirmSelection}
                />
            </div>
        </div>
    );
}

export default TrainingCertificateFormUserList;
