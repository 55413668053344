export class MathState {
    constructor() {
        this.equation = "";
        this.interpolatedEquation = "";
    }
    toJSON() {
        return {
            equation: this.equation,
            interpolatedEquation: this.interpolatedEquation,
            result: this.result,
        };
    }
}
