import {
    TreeNodeNodeTypeEnum,
    ITreeNodeDbModel,
    ITreeViewNodeView,
    ProcessApprovalStatusFilter,
    RepositoryEditorRightsEnum,
    StorageEditorsRightsEnum,
} from "@kortex/aos-common";
import { greyPalette, KortexTextField, theme } from "@aos/react-components";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FolderIcon from "@material-ui/icons/Folder";
import ImageIcon from "@material-ui/icons/Image";
import OtherIcon from "@material-ui/icons/InsertDriveFileOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MovieIcon from "@material-ui/icons/Movie";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import IconArchive from "@material-ui/icons/ArchiveOutlined";
import LabelIcon from "@material-ui/icons/LabelOutlined";
import * as React from "react";
import { useRef, useEffect, useState } from "react";

import { IUserRightsProps, userCanWrite } from "../../../utilitites/IUserRights";
import ProcessIcon from "../Icons/Process/Process";
import ProcessVersionPicker from "../ProcessVersionPicker/ProcessVersionPicker";

const LEFT_MARGIN = 12;
const CHEVRON_MARGIN = 24;
const CHEVRON_DEF_MARGING = 5;
const TREENODE_LABEL_MAX_LENGTH = 255;

const useStyles = makeStyles({
    label: {
        margin: "0px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    labelArchived: {
        color: theme.palette.grey[300],
    },
    input: {
        padding: "10px",
        backgroundColor: theme.palette.common.white,
    },
    chevron: {
        width: "24px",
        height: "36px",
    },
    icon: {},
    iconArchived: {
        color: theme.palette.grey[300],
    },
    versionSelect: {
        color: greyPalette[800],
        cursor: "pointer",
        display: "flex",
        fontSize: "1rem",
        lineHeight: "24px",
        padding: "0 24px 0 0",
    },
    labelIcon: {
        color: "#888888",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface ITreeNodeViewProps extends IUserRightsProps<RepositoryEditorRightsEnum | StorageEditorsRightsEnum> {
    style?: React.CSSProperties;
    isSelected: boolean;
    enableRename: boolean;
    node: ITreeViewNodeView<ITreeNodeDbModel>;
    onToggleExpand?: (nodeId?: number) => void;
    onSelected: (nodeId?: number, versionId?: number) => void;
    onVersionChange: (id: number, latest: boolean) => void;
    onDragSelected?: (nodeId?: number) => void;
    onDragSourceStart?: (nodeId?: number) => ITreeNodeDbModel[];
    onShowMenu: (node: ITreeNodeDbModel, event: React.MouseEvent<HTMLElement>) => void;
    onNameChange?: (newName: string, nodeId?: number) => void;
    showLatestVersion?: boolean;
    showProcessVersion?: ProcessApprovalStatusFilter;
    onTagClick?: () => void;
    handleSelectProcessConfirmation?: () => void;
}

export default function TreeViewNode(props: ITreeNodeViewProps): JSX.Element {
    const classes = useStyles();
    const {
        enableRename,
        isSelected,
        node,
        onDragSelected,
        onDragSourceStart,
        onNameChange,
        onSelected,
        onVersionChange,
        onShowMenu,
        onToggleExpand,
        showLatestVersion,
        showProcessVersion,
        style,
        userAccessLevel,
        handleSelectProcessConfirmation,
    } = props;

    const [isRenameOn, setIsRenameOn] = useState<boolean>(false);
    const [newName, setNewName] = useState<string>("");
    const [versionId, setVersionId] = useState<number | undefined>(undefined);
    const [latest, setLatest] = useState<boolean | undefined>(showLatestVersion);
    const [isLabelEllipsisActive, setIsLabelEllipsisActive] = useState<boolean>(false);
    const canWrite = userCanWrite(userAccessLevel);

    const labelSpanRef = useRef<HTMLSpanElement | null>(null);

    const leftMargin = node.baseNode.nodeType === TreeNodeNodeTypeEnum.FOLDER ? node.level * LEFT_MARGIN : 0;
    const chevronMargin = node.baseNode.nodeType === TreeNodeNodeTypeEnum.FOLDER ? CHEVRON_MARGIN : CHEVRON_DEF_MARGING;

    const nodeStyle: React.CSSProperties = {
        ...style,
        color: theme.palette.grey[800],
        lineHeight: "48px",
        display: "grid",
        gridTemplateColumns: `${leftMargin}px ${chevronMargin}px auto ${isRenameOn ? "70%" : "auto"} 1fr auto ${
            node.baseNode.archived ? "24px" : ""
        } 48px`,
        alignItems: "center",
        backgroundColor: isSelected ? theme.palette.grey[300] : undefined,
        gridColumnGap: 4,
        width: "100%",
    };

    useEffect((): void => {
        const element = labelSpanRef.current;

        if (!element) {
            setIsLabelEllipsisActive(false);

            return void 0;
        }

        setIsLabelEllipsisActive(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);
    });

    /**
     * Effect used to detect when the item is requested to be renamed (via props)
     */
    useEffect((): void => {
        setIsRenameOn(canWrite && enableRename);
    }, [enableRename]);

    /**
     * Effect used to set the name of the state on initial init
     */
    useEffect((): void => {
        setNewName(node.baseNode.label);
    }, [node]);

    /**
     * Called to request to open the 3dot menu
     *
     * @param event - Contains the event info
     */

    const handleOnShowMenu = (event: React.MouseEvent<HTMLElement>): void => {
        onShowMenu(node.baseNode, event);
    };

    /**
     * Called when the element is a folder, to call the toggleExpand callback
     *
     * @param event - Contains the event info
     */

    const handleToggleExpand = (event: React.MouseEvent<SVGSVGElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        if (onToggleExpand) {
            onToggleExpand(node.baseNode.treeNodeId);
        }
    };

    /**
     * Called when the user is over this node and ready to drop
     *
     * @param event - Contains the event info
     */

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();

        if (onDragSelected) {
            onDragSelected(node.baseNode.treeNodeId);
        }
    };

    /**
     * Called when the user is over this node and ready to drop
     *
     * @param event - Contains the event info
     */

    const handleDragEnterExpand = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();

        if (onToggleExpand) {
            onToggleExpand(node.baseNode.treeNodeId);
        }
    };

    /**
     * Called when the user is starting a drag and drop operation from this node
     *
     * @param event - Contains the event info
     */

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>): void => {
        // Root element cannot be drag & drop
        if (node.baseNode.treeNodeId !== undefined && onDragSourceStart) {
            const elem = onDragSourceStart(node.baseNode.treeNodeId);
            event.dataTransfer.setData("text/plain", JSON.stringify(elem));
        }
    };

    /**
     * Remove the textbox used to rename the node when leaving the input field
     */
    const handleNameChangeBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        const newValue = event.target.value;
        setNewName(newValue);

        if (isRenameOn) {
            if (onNameChange) {
                onNameChange(newValue, node.baseNode.treeNodeId);
            }
        }
        setIsRenameOn(false);
    };

    /**
     * Called to avoid event propagation to the parent target
     *
     * @param event - Contains the event info
     */

    const handleOnClick = (event: React.MouseEvent): void => {
        event.stopPropagation();
    };

    /**
     * Used to allow drag selection of text within TextField
     */
    const handleTextFieldDragStart = (event: React.DragEvent): void => {
        event.nativeEvent.stopImmediatePropagation();
        event.preventDefault();
    };

    /**
     * Called when the element is clicked, either calls selected callback or enables the renaming
     */
    const handleNodeClick = (): void => {
        onSelected(node.baseNode.treeNodeId);
    };

    /**
     * Handles the changes of version
     *
     * @param {number} id - id of the version
     */
    const handleVersionChange = (id: number, latest?: boolean): void => {
        setLatest(latest);
        if (latest) {
            setVersionId(undefined);
            onVersionChange(0, true);
        } else {
            setVersionId(id);
            onVersionChange(id, false);
        }
    };

    const showIcon = node.baseNode.archived && !isSelected ? classes.iconArchived : classes.icon;

    return (
        <div
            id="treeViewNodeId"
            style={nodeStyle}
            draggable={canWrite}
            onDragStart={canWrite ? handleDragStart : undefined}
            onDragEnter={canWrite ? handleDragEnter : undefined}
            onDoubleClick={!isRenameOn ? handleSelectProcessConfirmation : undefined}
            onClick={handleNodeClick}
        >
            {/* GAP */}
            <div />
            {/* EXPAND COLLAPSE */}
            {(node.hasSubFolder && (
                <div id="chevronExpandContainerId" className={classes.chevron} onDragEnter={handleDragEnterExpand}>
                    {!node.isExpanded ? (
                        <ChevronRightIcon id="chevronExpandId" onClick={handleToggleExpand} />
                    ) : (
                        <ExpandMoreIcon id="chevronExpandId" onClick={handleToggleExpand} />
                    )}
                </div>
            )) || <div />}
            {/* ICON */}
            {(node.baseNode.nodeType === TreeNodeNodeTypeEnum.FOLDER && <FolderIcon className={showIcon} />) ||
                (node.baseNode.nodeType === TreeNodeNodeTypeEnum.PROCESS && <ProcessIcon className={showIcon} />) ||
                (node.baseNode.nodeType === TreeNodeNodeTypeEnum.ROUTING && <CallSplitIcon className={showIcon} />) ||
                (node.baseNode.nodeType === TreeNodeNodeTypeEnum.IMAGE && <ImageIcon className={showIcon} />) ||
                (node.baseNode.nodeType === TreeNodeNodeTypeEnum.SVG && <ImageIcon className={showIcon} />) ||
                (node.baseNode.nodeType === TreeNodeNodeTypeEnum.VIDEO && <MovieIcon className={showIcon} />) ||
                (node.baseNode.nodeType === TreeNodeNodeTypeEnum.PDF && <PictureAsPdfIcon className={showIcon} />) ||
                (node.baseNode.nodeType === TreeNodeNodeTypeEnum.TEXT && <TextFormatIcon className={showIcon} />) ||
                (node.baseNode.nodeType === TreeNodeNodeTypeEnum.UNKNOWN && <OtherIcon className={showIcon} />) || <div />}
            {/* LABEL */}
            {isRenameOn ? (
                <KortexTextField
                    className={classes.label}
                    InputProps={{ inputProps: { maxLength: TREENODE_LABEL_MAX_LENGTH } }}
                    onBlur={handleNameChangeBlur}
                    TextFieldProps={{
                        id: "labelTextFieldId",
                        onClick: handleOnClick,
                        draggable: true,
                        onDragStart: handleTextFieldDragStart,
                        autoFocus: true,
                    }}
                    value={newName}
                    variant="standard"
                />
            ) : (
                <Tooltip classes={{ tooltip: classes.tooltip }} title={isLabelEllipsisActive ? node.baseNode.label : ""}>
                    <span
                        id="labelFixedFieldId"
                        className={`${classes.label} ${node.baseNode.archived && !isSelected ? classes.labelArchived : ""}`}
                        ref={labelSpanRef}
                        style={{ cursor: "pointer", userSelect: "none" }}
                    >
                        {" "}
                        {node.baseNode.label}{" "}
                    </span>
                </Tooltip>
            )}
            {/* TAG ICON */}
            {node.baseNode.nodeType === TreeNodeNodeTypeEnum.PROCESS && node.baseNode.reportTagEntryId ? (
                <LabelIcon className={classes.labelIcon} onClick={props.onTagClick} />
            ) : (
                <div />
            )}
            {/* PROCESS VERSION */}
            {isSelected && showProcessVersion !== ProcessApprovalStatusFilter.NONE ? (
                <ProcessVersionPicker
                    classes={{ textFieldInput: classes.versionSelect }}
                    latest={latest}
                    supportLatest={showLatestVersion}
                    onVersionChange={handleVersionChange}
                    processId={versionId}
                    treeNodeId={node.baseNode.treeNodeId}
                    variant={showProcessVersion}
                    userAccessLevel={userAccessLevel}
                />
            ) : (
                <div />
            )}
            {/* ARCHIVE ICON */}
            {node.baseNode.archived ? <IconArchive className={showIcon} /> : undefined}

            {/* OPTION */}
            {isSelected && canWrite ? (
                <IconButton id="nodeMenuOptionId" aria-label="Options" onClick={handleOnShowMenu}>
                    <MoreVertIcon />
                </IconButton>
            ) : (
                <div />
            )}
        </div>
    );
}
