import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default function SortAlphaUp(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M2.47 5.048l1.061 1.061 2.432-2.432v19.573h1.5v-19.573l2.432 2.432 1.061-1.061-4.243-4.243-4.243 4.243z M14.25 14.625h4.736l-5.111 4.722v2.028h6.75v-1.5h-5.111l5.111-4.722v-2.028h-6.375v1.5z M16.041 7.875h2.794l0.875 2.625h1.581l-2.75-8.25h-2.206l-2.75 8.25h1.581zM17.416 3.75h0.044l0.875 2.625h-1.794z"
                id="icons-shapes"
            />
        </SvgIcon>
    );
}
