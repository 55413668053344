export var EnumElementType;
(function (EnumElementType) {
    EnumElementType["FORM"] = "F";
    EnumElementType["IMAGE"] = "I";
    EnumElementType["LINE"] = "N";
    EnumElementType["MARKER"] = "M";
    EnumElementType["PDF"] = "P";
    EnumElementType["SHAPE"] = "S";
    EnumElementType["TEXT"] = "L";
    EnumElementType["THREE_D"] = "T";
    EnumElementType["UNKWNOWN"] = "U";
    EnumElementType["VIDEO"] = "V";
})(EnumElementType || (EnumElementType = {}));
