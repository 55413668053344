import { greyPalette, theme } from "@aos/react-components";
import { Paper, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ProcessDragHandle from "@material-ui/icons/DragHandle";
import React from "react";

const useStyles = makeStyles({
    root: {
        marginBottom: "8px",
        position: "relative",
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${greyPalette[300]}`,
    },
    rootSelected: {
        backgroundColor: greyPalette[200],
    },
    rootHover: {
        "&:hover": {
            background: greyPalette[200],
        },
    },
    cardContainer: {
        display: "flex",
        width: "100%",
    },
    cardStatus: {
        width: "10px",
    },
    dragHandleContainer: {
        paddingLeft: "5px",
        display: "flex",
        alignItems: "center",
        width: "24px",
    },
    iconContainer: {
        color: greyPalette[500],
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IKortexPanelCardProps {
    className?: string;
    statusColor?: string;
    isSelected: boolean;
    spaceBetweenCard?: number;
    children?: JSX.Element;
    padding?: number;
    onSelect?: () => void;
    variant?: string;
    isDraggable?: boolean;
    hover?: boolean;
    tooltipValue?: string;
}

const CARD_FLAT = 0;
const CARD_ELEVATED = 2;
const SPACE_BETWEEN_CARD = 6;

export default React.forwardRef(function KortexPanelCard(props: IKortexPanelCardProps, ref): JSX.Element {
    const classes = useStyles();
    const { isSelected, statusColor, padding, hover = true, tooltipValue = "" } = props;

    const selectedClass = isSelected ? classes.rootSelected : "";
    const hoverClass = hover ? classes.rootHover : "";
    const extraStylePaper = {
        marginBottom: props.spaceBetweenCard !== undefined ? props.spaceBetweenCard : SPACE_BETWEEN_CARD,
    };
    const extraStylePaperFlat = {
        marginBottom: props.spaceBetweenCard !== undefined ? props.spaceBetweenCard : SPACE_BETWEEN_CARD,
        border: 0,
        borderTop: "1px",
        borderStyle: "solid",
        borderColor: greyPalette[200],
    };

    const extraStyleCardContent = { padding: `${typeof padding !== "undefined" ? padding : 20}px`, width: "100%" };

    /**
     * Calls onSelect when card is clicked
     */
    const handleSelect = (): void => {
        if (props.onSelect) {
            props.onSelect();
        }
    };

    return (
        <Paper
            ref={ref}
            className={`${classes.root} ${selectedClass} ${hoverClass} ${props.className}`}
            style={props.variant === "flat" ? extraStylePaperFlat : extraStylePaper}
            onClick={handleSelect}
            elevation={props.variant === "flat" ? CARD_FLAT : CARD_ELEVATED}
        >
            <div className={classes.cardContainer} id="cardId">
                {statusColor && (
                    <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltipValue} placement="right">
                        <div className={classes.cardStatus} style={{ backgroundColor: statusColor }} id={"cardStatusId"} />
                    </Tooltip>
                )}
                {props.isDraggable === true && (
                    <div className={classes.dragHandleContainer}>
                        <ProcessDragHandle className={classes.iconContainer} />
                    </div>
                )}
                {props.isDraggable === false && <div className={classes.dragHandleContainer} />}
                <div style={extraStyleCardContent}>{props.children}</div>
            </div>
        </Paper>
    );
});
