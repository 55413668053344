import { theme } from "@aos/react-components";
import { getActiveProcessWhereUsed, isProcessUsed, ProcessId, ProcessWhereUsed } from "@kortex/aos-common";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    CircularProgress,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import { client } from "../../../utilitites/kortex-client/client";
import {
    getProcessReleaseStatusLabelKey,
    getProcessVersionStatusLabelKey,
} from "../ProcessEditor/ProcessEditor/ProcessApproval/ProcessApprovalCard";
import { getJobProcessStatusLabelKey, getJobStatusLabelKey } from "../Scheduler/SchedulerData";

const LATEST_INDEX = -1;

const useStyles = makeStyles({
    content: {
        height: "60vh",
        maxWidth: "1102px",
        width: "80vw",
    },
    contentLoading: {
        alignItems: "center",
        display: "flex",
        height: "60vh",
        justifyContent: "center",
        maxWidth: "1102px",
        width: "80vw",
    },
    emptyWhereUsedMessage: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%",
    },
    pannelDetailsContent: {
        width: "100%",
    },
    pannelSummary: {
        backgroundColor: theme.palette.grey[300],
        minHeight: "32px",
    },
    pannelSummaryContent: {
        alignItems: "center",
        display: "inline-flex",
        width: "100%",
    },
    showInactiveCheckbox: {
        display: "flex",
        alignItems: "center",
    },
    spacer: {
        paddingBottom: "12px",
    },
    tableCell: {
        padding: "12px",
    },
    tableHeader: {
        backgroundColor: theme.palette.grey[200],
    },
    typographyProcessVersion: {
        flex: 1,
    },
});

interface IOwnProps {
    onLoad?: (whereUsedFound: ProcessWhereUsed[]) => void;
    processId?: ProcessId; // If a process ID is specified, only get "where used" of specified process. Else get "where used" of all versions of the process.
    showEmptyMessage?: boolean; // If no "Where used" found, display a message
    treeNodeId: number;
}

export default function ProcessWhereUsedPage(props: IOwnProps): JSX.Element {
    const { onLoad, processId, showEmptyMessage, treeNodeId } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [openedPanels, setOpenedPanels] = useState<number[]>([]);
    const [showInactive, setShowInactive] = useState<boolean>(false);
    const [whereUsed, setWhereUsed] = useState<ProcessWhereUsed[]>([]);
    const [whereUsedLoaded, setWhereUsedLoaded] = useState<boolean>(false);

    /**
     * Set inner state when "Show inactive" checkbox is checked or unchecked
     */
    const handleCheckShowInactive = (): void => {
        setShowInactive(!showInactive);
    };

    /**
     * Open/close the clicked expansion pannel
     */
    const handleSummaryClick =
        (processId?: ProcessId): (() => void) =>
        (): void => {
            if (!processId) {
                return;
            }

            const index = openedPanels.findIndex((id) => id === processId);

            if (index === -1) {
                // Panel was closed
                setOpenedPanels([...openedPanels, processId]);
            } else {
                // Panel was opened
                const closedPanelIds = [...openedPanels];
                closedPanelIds.splice(index, 1);
                setOpenedPanels(closedPanelIds);
            }
        };

    /**
     * Get filtered where used
     * If showInactive is true, show all where used
     * Else only show active ones
     */
    const getFilteredWhereUsed = (): ProcessWhereUsed[] => {
        if (showInactive) {
            return whereUsed;
        }

        return getActiveProcessWhereUsed(whereUsed);
    };

    /**
     * Load all where used
     */
    const loadWhereUsed = async (): Promise<void> => {
        let loadedWhereUsed: ProcessWhereUsed[] = [];

        if (processId) {
            // If a process ID is specified, get the "Where used" of that process
            const result = await client.services.process.whereUsed({ processId })({ timeout: 10_000 });
            loadedWhereUsed = isProcessUsed(result) ? [result] : [];
        } else {
            // Else get the "Where used" of all versions of the process
            // timeout increased for extreme cases
            loadedWhereUsed = [...(await client.services.process.whereUsedAllVersions({ nodeId: treeNodeId })({ timeout: 60_000 }))];
        }

        // Set inner state
        setWhereUsed(loadedWhereUsed);

        // If there is only one "Where used" found, expand its panel
        if (loadedWhereUsed.length === 1) {
            setOpenedPanels([loadedWhereUsed[0].process?.processId ?? LATEST_INDEX]);
        }

        // Remove the loading icon
        setWhereUsedLoaded(true);

        if (onLoad) {
            onLoad(loadedWhereUsed);
        }
    };

    /**
     * Find where tree node is used when page is opened
     */
    useEffect((): void => {
        loadWhereUsed();
    }, [processId, treeNodeId]);

    // Get filtered list of where used
    const filteredWhereUsed = getFilteredWhereUsed();

    return (
        <React.Fragment>
            {
                /* LOADING ICON */
                !Boolean(whereUsedLoaded) ? (
                    <div className={classes.contentLoading} id="processWhereUsedPageLoadingId">
                        <CircularProgress />
                    </div>
                ) : (
                    <div />
                )
            }
            {
                /* WHERE USED LOADED */
                whereUsedLoaded && (
                    <div className={classes.content} id="processWhereUsedPageLoadedId">
                        {
                            /* SHOW INACTIVE CHECKBOX */
                            Boolean(whereUsed.length) && (
                                <div className={classes.showInactiveCheckbox}>
                                    <Typography>{translate("processWhereUsed.showInactive")}</Typography>
                                    <Checkbox checked={showInactive} onChange={handleCheckShowInactive} />
                                </div>
                            )
                        }
                        {Boolean(filteredWhereUsed.length) ? (
                            filteredWhereUsed
                                .sort((a, b) => parseFloat(b.process?.version ?? "0") - parseFloat(a.process?.version ?? "0"))
                                .map((item, index) => {
                                    const expanded = Boolean(openedPanels.includes(item.process?.processId ?? LATEST_INDEX));

                                    return (
                                        <Accordion key={index} expanded={expanded}>
                                            <AccordionSummary
                                                className={classes.pannelSummary}
                                                onClick={handleSummaryClick(item.process?.processId ?? LATEST_INDEX)}
                                            >
                                                <div className={classes.pannelSummaryContent}>
                                                    <Typography className={classes.typographyProcessVersion} variant="h4">
                                                        {item.process ? item.process.version : "Latest"}
                                                    </Typography>
                                                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className={classes.pannelDetailsContent}>
                                                    {
                                                        /* JOB LIST */
                                                        item.jobs.length ? (
                                                            <React.Fragment>
                                                                <Typography variant="h6">{translate("processWhereUsed.jobs")}</Typography>
                                                                <Table>
                                                                    <TableHead className={classes.tableHeader}>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography>
                                                                                    {translate("processWhereUsed.refId")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography>
                                                                                    {translate("processWhereUsed.reference")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography>
                                                                                    {translate("processWhereUsed.partNumber")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography>
                                                                                    {translate("processWhereUsed.jobStatus")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography>
                                                                                    {translate("processWhereUsed.jobProcessStatus")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {item.jobs.map((whereUsedJob) =>
                                                                            whereUsedJob.jobProcesses.map((jobProcess, jobProcessIndex) => (
                                                                                <TableRow key={jobProcessIndex}>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography>{whereUsedJob.job.jobRefId}</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography>
                                                                                            {whereUsedJob.job.referenceId}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography>
                                                                                            {whereUsedJob.job.partNumber}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography>
                                                                                            {translate(
                                                                                                getJobStatusLabelKey(
                                                                                                    whereUsedJob.job.status
                                                                                                )
                                                                                            )}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography>
                                                                                            {translate(
                                                                                                getJobProcessStatusLabelKey(
                                                                                                    jobProcess.status
                                                                                                )
                                                                                            )}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </React.Fragment>
                                                        ) : (
                                                            <div />
                                                        )
                                                    }
                                                    <div className={classes.spacer} />
                                                    {
                                                        /* ROUTING LIST */
                                                        item.routings.length ? (
                                                            <React.Fragment>
                                                                <Typography variant="h6">
                                                                    {translate("processWhereUsed.routings")}
                                                                </Typography>
                                                                <Table>
                                                                    <TableHead className={classes.tableHeader}>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography>
                                                                                    {translate("processWhereUsed.processLabel")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography>
                                                                                    {translate("processWhereUsed.processVersion")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography>
                                                                                    {translate("processWhereUsed.processAction")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography>
                                                                                    {translate("processWhereUsed.approvalStatus")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography>
                                                                                    {translate("processWhereUsed.releaseStatus")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {item.routings
                                                                            .sort(
                                                                                (a, b) =>
                                                                                    parseFloat(b.routing.version ?? "0") -
                                                                                    parseFloat(a.routing.version ?? "0")
                                                                            )
                                                                            .map((whereUsedRouting) =>
                                                                                whereUsedRouting.actions.map((action, actionIndex) => (
                                                                                    <TableRow key={actionIndex}>
                                                                                        <TableCell className={classes.tableCell}>
                                                                                            <Typography>
                                                                                                {whereUsedRouting.treeNode.label}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.tableCell}>
                                                                                            <Typography>
                                                                                                {whereUsedRouting.routing.version}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.tableCell}>
                                                                                            <Typography>{action.label}</Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.tableCell}>
                                                                                            <Typography>
                                                                                                {translate(
                                                                                                    getProcessVersionStatusLabelKey(
                                                                                                        whereUsedRouting.routing
                                                                                                            .versionStatus
                                                                                                    )
                                                                                                )}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.tableCell}>
                                                                                            <Typography>
                                                                                                {translate(
                                                                                                    getProcessReleaseStatusLabelKey(
                                                                                                        whereUsedRouting.routing
                                                                                                    )
                                                                                                )}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                ))
                                                                            )}
                                                                    </TableBody>
                                                                </Table>
                                                            </React.Fragment>
                                                        ) : (
                                                            <div />
                                                        )
                                                    }
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })
                        ) : Boolean(showEmptyMessage) ? (
                            /* WHERE USED LOADED BUT NO MATCH FOUND (PROCESS IS NOT USED BY ANY ROUTING OR JOB) */
                            <div className={classes.emptyWhereUsedMessage} id="processWhereUsedPageLoadedEmptyId">
                                {!Boolean(whereUsed.length) ? (
                                    <Typography>{translate("processWhereUsed.empty")}</Typography>
                                ) : (
                                    <Typography>{translate("processWhereUsed.emptyActive")}</Typography>
                                )}
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                )
            }
        </React.Fragment>
    );
}
