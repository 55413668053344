import { encode } from "@msgpack/msgpack";
/**
 * Converts a given object to an Uint8Array
 *
 * @param {object} data - object to serialize
 * @returns {Uint8Array}
 */
export function utf8ArrayFromObject(data) {
    return encode(data);
}
