import { KortexDialogConfirmation, theme } from "@aos/react-components";
import { IJobProcessOfTheReworkModel, IReworkJobProcessInsertReq, IReworkUiModel, JobProcessStatusEnum } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { insertReworkJobProcess, reworkJobProcessGetAll } from "@kortex/aos-ui/redux/rework-manager/rework-job-process-thunks";
import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";

import ProcessSelectorOriginalJobList from "./ProcessSelectorOriginalJobList";

const useStyles = makeStyles({
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
});

export interface IAddReworkJobProcessFromOriginalJobDialogProps {
    rework: IReworkUiModel;
    processSelectorOriginalJobListOpen: boolean;
    activeJobIdOfFailureTicket: number;

    onHandleDialogClose: () => void;
}

export default function AddReworkJobProcessFromOriginalJobDialog(props: IAddReworkJobProcessFromOriginalJobDialogProps): JSX.Element {
    const { rework, processSelectorOriginalJobListOpen, activeJobIdOfFailureTicket, onHandleDialogClose } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();

    const [selectedProcessVersionId, setSelectedProcessVersionId] = useState<number[]>([]);
    const [jobPocessesOriginalJob, setJobPocessesOriginalJob] = useState<IJobProcessOfTheReworkModel[]>([]);

    useEffect(() => {
        dispatch(
            reworkJobProcessGetAll(rework.reworkId, false, rework.originalJobId, { redux: { skipLookup: true, skipDispatch: true } })
        ).then((jobProcesses) => {
            setJobPocessesOriginalJob([...jobProcesses]);
        });
    }, []);

    /**
     * handle request to close the dialog
     */
    const handleClose = (): void => {
        onHandleDialogClose();
        setJobPocessesOriginalJob([]);
    };

    /**
     * handle request to close the dialog
     */
    const HandelProcessOriginalJobListSelected = (processIdList: number[]): void => {
        setSelectedProcessVersionId(processIdList);
    };

    /**
     * handle process selection, to complete the insertion of the selected process in the original job
     */
    const handleProcessOriginalJobList = async (): Promise<void> => {
        // Insert the rework job process in the job
        const jobProcessesToBeAdded: IReworkJobProcessInsertReq[] = [];
        for (const [count, currentProcessVersionId] of selectedProcessVersionId.entries()) {
            jobProcessesToBeAdded.push({
                jobId: activeJobIdOfFailureTicket,
                processId: currentProcessVersionId,
                status: JobProcessStatusEnum.READY,
                reworkId: rework.reworkId,
                qty: rework.quantity,
                checkPrevious: count !== 0,
            });
        }
        await dispatch(insertReworkJobProcess(jobProcessesToBeAdded));

        handleClose();
    };

    return (
        <KortexDialogConfirmation
            open={processSelectorOriginalJobListOpen}
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: handleClose,
            }}
            textLabels={{
                titleLabel: translate("scheduler.selectProcess"),
                cancelButtonLabel: translate("scheduler.cancel"),
                proceedButtonLabel: translate("scheduler.select"),
            }}
            onCancel={handleClose}
            onConfirm={handleProcessOriginalJobList}
            closeOnEscape={true}
        >
            <div className={classes.dialogContent}>
                <ProcessSelectorOriginalJobList
                    jobPocessesOriginalJob={jobPocessesOriginalJob}
                    processOriginalJobListSelected={HandelProcessOriginalJobListSelected}
                />
            </div>
        </KortexDialogConfirmation>
    );
}
