import { SequencerActionGroupStatusEnum } from "../enums";
export function isActionGroupCompleted(status) {
    return status === SequencerActionGroupStatusEnum.COMPLETED;
}
export function isActionGroupFailed(status) {
    return status === SequencerActionGroupStatusEnum.FAILED;
}
export function isActionGroupIdle(status) {
    return status === SequencerActionGroupStatusEnum.IDLE;
}
export function isActionGroupPaused(status) {
    return status === SequencerActionGroupStatusEnum.PAUSED;
}
export function isActionGroupPlaying(status) {
    return status === SequencerActionGroupStatusEnum.PLAYING;
}
export function isActionGroupStopped(status) {
    return status === SequencerActionGroupStatusEnum.STOPPED;
}
export function isActionGroupStopping(status) {
    return status === SequencerActionGroupStatusEnum.STOPPING;
}
export function isActionGroupInactive(status) {
    return [SequencerActionGroupStatusEnum.COMPLETED, SequencerActionGroupStatusEnum.FAILED, SequencerActionGroupStatusEnum.IDLE].includes(status);
}
export function isActionGroupInProgress(status) {
    return [SequencerActionGroupStatusEnum.PAUSED, SequencerActionGroupStatusEnum.PLAYING].includes(status);
}
