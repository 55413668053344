import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default function Group(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M12,1 C5.928,1 1,5.928 1,12 C1,18.072 5.928,23 12,23 C18.072,23 23,18.072 23,12 C23,5.928 18.072,1 12,1 Z M12,20.8 C7.149,20.8 3.2,16.851 3.2,12 C3.2,7.149 7.149,3.2 12,3.2 C16.851,3.2 20.8,7.149 20.8,12 C20.8,16.851 16.851,20.8 12,20.8 Z M6.5,12 L10.9,12 L10.9,16.4 L6.5,16.4 L6.5,12 Z M12,5.4 L14.2,9.8 L9.8,9.8 L12,5.4 Z M15.3,16.4 C16.5150264,16.4 17.5,15.4150264 17.5,14.2 C17.5,12.9849736 16.5150264,12 15.3,12 C14.0849736,12 13.1,12.9849736 13.1,14.2 C13.1,15.4150264 14.0849736,16.4 15.3,16.4 Z"
                id="group-icon"
            />
        </SvgIcon>
    );
}
