import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider/Divider";
import Typography from "@material-ui/core/Typography/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as React from "react";
import { ChangeEvent } from "react";

import { useTranslate } from "../../../../hooks/useTranslate";
import { ILocale } from "../../../../locales/ILocale";

import { globalSettingsStyles } from "./GlobalSettingsCommon";

export const useStyles = makeStyles({
    panel: {},
    panelSummary: {
        padding: "0px 12px 0px 12px",
    },
    expanded: {
        "&$expanded": {
            margin: "8px 0 ",
        },
    },
    header: {
        margin: "0px 0px 10px 0px",
    },
});

interface IOwnProps {
    titleKey: keyof ILocale;
    expanded: boolean;
    children: JSX.Element;
    onCollapseChange: (expanded: boolean) => void;
    id: string;
}

function GlobalSettingElement(props: IOwnProps): JSX.Element {
    const classesSettings = globalSettingsStyles();
    const classes = useStyles();
    const translate = useTranslate();

    /**
     * Handle collapse changes
     *
     * @param {ChangeEvent} event - unused
     * @param {boolean} expanded - true if expanded
     */
    const handleCollapseChange = (event: ChangeEvent, expanded: boolean): void => {
        props.onCollapseChange(expanded);
    };

    return (
        <Accordion square classes={{ expanded: classes.expanded }} expanded={props.expanded} onChange={handleCollapseChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon id={props.id} />} className={classes.panelSummary}>
                <Typography variant="h6">{translate(props.titleKey)}</Typography>
            </AccordionSummary>

            <AccordionDetails id={"accordionDetailsId"}>
                {props.children}

                <Divider className={classesSettings.divider} />
            </AccordionDetails>
        </Accordion>
    );
}

export default GlobalSettingElement;
