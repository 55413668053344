import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    BomFollowUpSymptomAllReq,
    BomFollowUpSymptomAllRes,
    BomFollowUpSymptomInsertReq,
    BomFollowUpSymptomInsertRes,
    BomFollowUpSymptomInsertedNotif,
    BomFollowUpSymptomUpdateReq,
    BomFollowUpSymptomUpdateRes,
    BomFollowUpSymptomUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { bomFollowUpSymptomAll } from "./all";
import { bomFollowUpSymptomInsert } from "./insert";
import { bomFollowUpSymptomInserted } from "./inserted";
import { bomFollowUpSymptomUpdate } from "./update";
import { bomFollowUpSymptomUpdated } from "./updated";

interface IAOSClientServiceWrappedBomFollowUpSymptom {
    getAll: AOSClientServiceRequestWrapped<BomFollowUpSymptomAllReq, BomFollowUpSymptomAllRes>;
    insert: AOSClientServiceRequestWrapped<BomFollowUpSymptomInsertReq, BomFollowUpSymptomInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<BomFollowUpSymptomInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<BomFollowUpSymptomUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<BomFollowUpSymptomUpdateReq, BomFollowUpSymptomUpdateRes>;
}

type AOSClientServiceWrappedBomFollowUpSymptom = IAOSClientServiceWrappedBomFollowUpSymptom;

// ADD_API_CALL Bom follow-up symptom
export function generateBomFollowUpSymptomServiceClient(
    router: IRouterClient,
    notifier: EventEmitter
): AOSClientServiceWrappedBomFollowUpSymptom {
    return {
        getAll: AOSClientServiceWrapperUI.request(bomFollowUpSymptomAll(router)),
        insert: AOSClientServiceWrapperUI.request(bomFollowUpSymptomInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(bomFollowUpSymptomInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(bomFollowUpSymptomUpdated(router, notifier)),
        update: AOSClientServiceWrapperUI.request(bomFollowUpSymptomUpdate(router)),
    };
}

generateBomFollowUpSymptomServiceClient.serviceId = 38;
