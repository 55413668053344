import { greyPalette, theme } from "@aos/react-components";
import {
    ITrainingCertificationPending,
    ITrainingPending,
    TrainingCertificationPendingGetAllFilters,
    TrainingPendingGetAllFilters,
} from "@kortex/aos-common";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { UseListObserver } from "@kortex/aos-ui/hooks/useListObserver";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Typography, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import TrainingCertificateSearchBar from "./TrainingCertificateSearchBar";

//import { UserTrainingsContext } from "./context";

const useStyles = makeStyles({
    cardContent: {
        display: "grid",
        gridTemplateColumns: "31% 38% 9% 20% 2%",
        alignItems: "center",
    },
    text: {
        color: greyPalette[700],
    },
    column: {
        display: "flex",
        alignItems: "center",
        height: "58px",
        justifyContent: "center",
    },
    searchbar: {
        width: "100%",
    },
});

interface IOwnProps {
    columnTitle: string;
    useContext: () =>
        | UseListObserver<ITrainingPending, TrainingPendingGetAllFilters>
        | UseListObserver<ITrainingCertificationPending, TrainingCertificationPendingGetAllFilters>;
}

const TrainingsPendingCardHeader: FC<IOwnProps> = (props) => {
    const { columnTitle, useContext } = props;
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <KortexPanelCard
            statusColor={theme.palette.common.white} // white
            isSelected={false}
            padding={0}
            spaceBetweenCard={1}
            hover={false}
        >
            <div className={classes.cardContent} id="userTrainingCardHeaderId">
                {/* SEARCH USER */}
                <div className={`${classes.column}`}>
                    <div className={classes.searchbar}>
                        <TrainingCertificateSearchBar titleTrainerFilter={columnTitle} useContext={useContext} />
                    </div>
                </div>
                {/* REFERENCE */}
                <div className={classes.column}>
                    <Typography className={classes.text}>{translate("training.trainingCertificateReference").toUpperCase()}</Typography>
                </div>
                {/* DATE */}
                <div className={classes.column}>
                    <Typography className={classes.text}>{translate("general.date").toUpperCase()}</Typography>
                </div>
                {/* TRAINER / AUDITOR */}
                <div className={classes.column}>
                    <Typography className={classes.text}>{columnTitle.toUpperCase()}</Typography>
                </div>
            </div>
        </KortexPanelCard>
    );
};

export default TrainingsPendingCardHeader;
