import Debug from "debug";

const debug = Debug("kortex:aos:svgtoCanvas");

/**
 * Convertion of a stringnified SVG element to an HTML canvas element
 *
 * @param {string} svgStringSource - SVG element in string format
 * @param {number} canvasWidth - target canvas width
 * @param {number} canvasHeight - target canvas height
 * @returns {Promise<HTMLCanvasElement>} HTMLCanvasElement
 */
export function svgToCanvas(svgStringSource: string, canvasWidth: number, canvasHeight: number): Promise<HTMLCanvasElement> {
    return new Promise<HTMLCanvasElement>(async (resolve, reject): Promise<void> => {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        const img = new Image();
        img.crossOrigin = "anonymous";

        img.onload = (): void => {
            ctx.drawImage(img, 0, 0, canvasWidth, canvasHeight);
            resolve(canvas);
        };

        img.onerror = (err): void => {
            reject(err);
        };

        // Trigger image rendering
        img.src = buildSvgImageUrl(await convertHrefToDataUri(svgStringSource));
    });
}

/**
 * Builds svg image url
 *
 * @param {string} svg - SVG Element in string format
 * @returns {string} SVG String URL
 */
function buildSvgImageUrl(svg: string): string {
    const b64: string = b64EncodeUnicode(svg);
    return "data:image/svg+xml;base64," + b64;
}

/**
 * Encode base64 string in unicode
 * Must be used to support unicode char (é,è etc)
 *
 * @param {string} str - input string
 * @returns {string} encoded string
 */
function b64EncodeUnicode(str: string): string {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1): string => {
            return String.fromCharCode(parseInt("0x" + p1, 16));
        })
    );
}

/**
 * Converts href string to data uri string
 *
 * @param {string} hrefString - href string
 * @returns {Promise<string>} data uri string
 */
async function convertHrefToDataUri(hrefString: string): Promise<string> {
    const regex = /(href=")(http.*?)"/gm;
    let result = hrefString;
    let m = regex.exec(hrefString);

    while (m !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
            regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        try {
            result = result.replace(m[2], await getDataUri(m[2]));
        } catch (error) {
            debug(error);
        }

        m = regex.exec(hrefString);
    }

    return result;
}

/**
 * Gets data uri from URL
 * Source https://davidwalsh.name/convert-image-data-uri-javascript
 *
 * @param {string} url - url to get the data uri from
 * @returns {Promise<string>} data uri
 */
function getDataUri(url: string): Promise<string> {
    return new Promise<string>((resolve, reject): void => {
        const image = new Image();
        image.crossOrigin = "anonymous";

        image.onload = (): void => {
            const canvas: HTMLCanvasElement = document.createElement("canvas");
            canvas.width = image.naturalWidth; // or 'width' if you want a special/scaled size
            canvas.height = image.naturalHeight; // or 'height' if you want a special/scaled size

            if (canvas === null) {
                reject(null);
            } else {
                (canvas.getContext("2d") as CanvasRenderingContext2D).drawImage(image, 0, 0);

                try {
                    resolve(canvas.toDataURL("image/png"));
                } catch (error) {
                    reject(error);
                }
            }
        };

        image.onerror = (): void => {
            reject();
        };

        image.src = url;
    });
}
