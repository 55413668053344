import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    TaskAllReq,
    TaskAllRes,
    TaskInsertReq,
    TaskInsertRes,
    TaskInsertedNotif,
    TaskUpdateReq,
    TaskUpdateRes,
    TaskUpdatedNotif,
    TaskDeletedNotif,
    TaskDeleteRes,
    TaskDeleteReq,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { taskAll } from "./all";
import { taskInsert } from "./insert";
import { taskInserted } from "./inserted";
import { taskUpdate } from "./update";
import { taskUpdated } from "./updated";
import { taskDelete } from "./delete";
import { taskDeleted } from "./deleted";

interface IAOSClientServiceWrappedTask {
    getAll: AOSClientServiceRequestWrapped<TaskAllReq, TaskAllRes>;
    insert: AOSClientServiceRequestWrapped<TaskInsertReq, TaskInsertRes>;
    update: AOSClientServiceRequestWrapped<TaskUpdateReq, TaskUpdateRes>;
    delete: AOSClientServiceRequestWrapped<TaskDeleteReq, TaskDeleteRes>;
    onInserted: AOSClientServiceNotificationWrapped<TaskInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<TaskUpdatedNotif>;
    onDeleted: AOSClientServiceNotificationWrapped<TaskDeletedNotif>;
}

type AOSClientServiceWrappedTask = IAOSClientServiceWrappedTask;

// ADD_API_CALL Task
export function generateTaskServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedTask {
    return {
        getAll: AOSClientServiceWrapperUI.request(taskAll(router)),
        insert: AOSClientServiceWrapperUI.request(taskInsert(router)),
        update: AOSClientServiceWrapperUI.request(taskUpdate(router)),
        delete: AOSClientServiceWrapperUI.request(taskDelete(router)),
        onInserted: AOSClientServiceWrapperUI.notification(taskInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(taskUpdated(router, notifier)),
        onDeleted: AOSClientServiceWrapperUI.notification(taskDeleted(router, notifier)),
    };
}

generateTaskServiceClient.serviceId = 100;
