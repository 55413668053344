export class TokenDecoded {
    constructor(userId, userName, fullName, userCode, clientId, userGroups, instanceId, passwordExpired, roleRights) {
        this.userId = userId;
        this.userName = userName;
        this.fullName = fullName;
        this.userCode = userCode;
        this.clientId = clientId;
        this.userGroups = userGroups;
        this.instanceId = instanceId;
        this.passwordExpired = passwordExpired;
        if (roleRights) {
            this.roleRights = roleRights;
        }
    }
    toJSON() {
        return { ...this };
    }
}
