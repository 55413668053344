import { deepClone } from "@kortex/utilities";
import { JobProcessStatusEnum, JobStatusEnum } from "../enum/JobStatusEnum";
/**
 * Filter an array of "Where used" and returns those that are used by active job processes or routings
 *
 * @param {ProcessWhereUsed[]} whereUsed - where used
 */
export function getActiveProcessWhereUsed(whereUsed) {
    return (deepClone(whereUsed) // Copy where used (parameter)
        .map((whereUsedItem) => {
        return {
            jobs: whereUsedItem.jobs
                .map((whereUsedJob) => {
                return {
                    job: whereUsedJob.job,
                    // Remove inactive job processes
                    // A job process is inactive when it is not READY or IN PROGRESS
                    jobProcesses: whereUsedJob.jobProcesses.filter((jobProcess) => jobProcess.status === JobProcessStatusEnum.IN_PROGRESS ||
                        jobProcess.status === JobProcessStatusEnum.READY),
                };
            })
                // Remove inactive job
                // A job is inactive when it is not READY or IN PROGRESS
                // Also removes the job if all the job process array is empty (due to the previous filter)
                .filter((whereUsedJob) => whereUsedJob.jobProcesses.length &&
                (whereUsedJob.job.status === JobStatusEnum.IN_PROGRESS || whereUsedJob.job.status === JobStatusEnum.READY)),
            process: whereUsedItem.process,
            routings: whereUsedItem.routings.filter((whereUsedRouting) => !(whereUsedRouting.routing.archived ||
                (whereUsedRouting.routing.retiredOn && whereUsedRouting.routing.retiredOn <= new Date().getTime()))), // Remove all archived and retired routings
        };
    })
        // Remove empty Where Used items
        .filter((whereUsedItem) => whereUsedItem.jobs.length || whereUsedItem.routings.length));
}
export function getNonArchiveProcessWhereUsed(whereUsed) {
    return (deepClone(whereUsed) // Copy where used (parameter)
        .map((whereUsedItem) => {
        return {
            jobs: whereUsedItem.jobs
                .map((whereUsedJob) => {
                return {
                    job: whereUsedJob.job,
                    // Remove inactive job processes
                    // A job process is inactive when it is not READY or IN PROGRESS
                    jobProcesses: whereUsedJob.jobProcesses.filter((jobProcess) => jobProcess.status !== JobProcessStatusEnum.ARCHIVED),
                };
            })
                // Remove inactive job
                // A job is inactive when it is not READY or IN PROGRESS
                // Also removes the job if all the job process array is empty (due to the previous filter)
                .filter((whereUsedJob) => whereUsedJob.jobProcesses.length && whereUsedJob.job.status !== JobStatusEnum.ARCHIVED),
            process: whereUsedItem.process,
            routings: whereUsedItem.routings.filter((whereUsedRouting) => !(whereUsedRouting.routing.archived ||
                (whereUsedRouting.routing.retiredOn && whereUsedRouting.routing.retiredOn <= new Date().getTime()))), // Remove all archived and retired routings
        };
    })
        // Remove empty Where Used items
        .filter((whereUsedItem) => whereUsedItem.jobs.length || whereUsedItem.routings.length));
}
/**
 * Determine whether or not a process is currently used by at least one job or routing.
 */
export function isProcessUsed(whereUsed) {
    return Boolean(whereUsed.jobs.length) || Boolean(whereUsed.routings.length);
}
