import { theme } from "@aos/react-components";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Box, Tab, Tabs, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

import { ProcessTrainingTab } from "./processTrainingTab";
import { ProcessTrainingProvider } from "./processTrainingTab/context";
import { ReadingCertificateProvider, ReadingCertificateTab } from "./readingCertificateTab";
import { TrainingCertificateProvider } from "./trainingCertificate/TrainingCertificateContext";
import TrainingCertificateTab from "./trainingCertificate/TrainingCertificateTab";
import { TrainingCetrificationProvider } from "./trainingCertification/TrainingCertificationContext";
import TrainingCertificationTab from "./trainingCertification/TrainingCertificationTab";
import UserTrainingTab from "./userTrainingTab/UserTrainingTab";
import { UserTrainingProvider } from "./userTrainingTab/context";
import { useTrainingPageContext } from "./utilities/trainingPageContext";

const useStyles = makeStyles({
    contentTab: {
        backgroundColor: theme.palette.common.white,
    },
    contentTabHeader: {
        flex: 4,
    },
    headerTab: {
        backgroundColor: theme.palette.common.white,
        display: "flex",
        alignItems: "center",
    },
    root: {
        height: "calc(100vh - 84px)", // Header (64px) + margins (20px)
        margin: "10px",
    },
});

const TAB_USER_TRAINING = 0;
const TAB_PROCESS_TRAINING = 1;
const TAB_TRAINING_CERTIFICATE = 2;
const TAB_TRAINING_CERTIFICATION = 3;
const TAB_READING_CERTIFICATE = 4;

function TrainingPage(): JSX.Element {
    const classes = useStyles();
    const context = useTrainingPageContext();
    const translate = useTranslate();

    const [tabSelected, setTabSelected] = useState<number>(0);

    const handleTabChange = (_: React.SyntheticEvent, newValue: number): void => {
        setTabSelected(newValue);
    };

    return (
        <div className={classes.root} id={"trainingPageId"}>
            <div className={classes.headerTab}>
                <Tabs
                    className={classes.contentTabHeader}
                    value={tabSelected}
                    onChange={handleTabChange}
                    variant="standard"
                    textColor="primary"
                >
                    <Tab label={translate("training.userTraining")} id={"userTrainingTabId"} />
                    <Tab label={translate("training.processTraining")} id={"processTrainingTabId"} />
                    <Tab label={translate("training.trainingCertificate")} id={"trainingCertificateTabId"} />
                    <Tab label={translate("training.trainingCertification")} id={"trainingCertificationTabId"} />
                    <Tab label={translate("training.readingCertificate")} id={"readingCertificateTabId"} />
                </Tabs>
                {context.customHeaderComponent}
            </div>
            <Box className={classes.contentTab}>
                {tabSelected === TAB_USER_TRAINING && (
                    <UserTrainingProvider>
                        <UserTrainingTab />
                    </UserTrainingProvider>
                )}
                {tabSelected === TAB_PROCESS_TRAINING && (
                    <ProcessTrainingProvider>
                        <ProcessTrainingTab />
                    </ProcessTrainingProvider>
                )}
                {tabSelected === TAB_TRAINING_CERTIFICATE && (
                    <TrainingCertificateProvider>
                        <TrainingCertificateTab />
                    </TrainingCertificateProvider>
                )}
                {tabSelected === TAB_TRAINING_CERTIFICATION && (
                    <TrainingCetrificationProvider>
                        <TrainingCertificationTab />
                    </TrainingCetrificationProvider>
                )}
                {tabSelected === TAB_READING_CERTIFICATE && (
                    <ReadingCertificateProvider>
                        <ReadingCertificateTab />
                    </ReadingCertificateProvider>
                )}
            </Box>
        </div>
    );
}

export default TrainingPage;
