import { ParserState } from "@kortex/aos-common";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    result: {
        marginBottom: "32px",
        textAlign: "center",
    },
    resultTitle: {
        marginBottom: "32px",
    },
    root: {
        height: "-webkit-fill-available",
        padding: "32px",
        width: "-webkit-fill-available",
    },
});

export interface IOwnProps {
    actionState: ParserState;
}

export default function ParserPlayer(props: IOwnProps): JSX.Element {
    const { actionState } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.root}>
            <Typography className={classes.resultTitle} variant="h1">
                {translate("player.parserPlayer.result")}
            </Typography>
            <Typography className={classes.result} variant="h3">
                {actionState.result}
            </Typography>
        </div>
    );
}
