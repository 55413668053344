import { secondaryPalette } from "@aos/react-components";
import { IReworkUiModel, ITokenDecoded, ReworkYieldType } from "@kortex/aos-common";
import { YieldTypeSelector } from "@kortex/aos-ui/components/core/YieldTypeSelector";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { reworkUpdate } from "@kortex/aos-ui/redux/rework-manager/rework-thunks";
import { userCanWrite } from "@kortex/aos-ui/utilitites/IUserRights";
import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles({
    item: {
        fontSize: "1rem",
    },
});

interface IYieldSelectorProps {
    rework: IReworkUiModel;
    userInfo?: ITokenDecoded;
}

const FailureTicketDetailsYieldSelector: FC<IYieldSelectorProps> = (props) => {
    const { rework, userInfo } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();

    const handleYieldTypeChange = (yieldType: ReworkYieldType): void => {
        dispatch(
            reworkUpdate({
                ...rework,
                yieldType,
            })
        );
    };

    return (
        <YieldTypeSelector
            classes={{ item: classes.item }}
            KortexTextFieldProps={{
                InputLabelProps: {
                    style: { color: secondaryPalette[500] },
                },
                size: "small",
                disabled: !userCanWrite(userInfo?.roleRights.rework),
            }}
            onChange={handleYieldTypeChange}
            value={rework.yieldType}
        />
    );
};

export default FailureTicketDetailsYieldSelector;
