import { greyPalette, theme } from "@aos/react-components";
import { ITrainingReadingCertificatePending, getDateStr } from "@kortex/aos-common";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import { Accordion, AccordionDetails, AccordionSummary, Typography, makeStyles } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { FC, createRef, useEffect, useState } from "react";

import { Trainings } from "./trainings";

const useStyles = makeStyles({
    accordionDetailsRoot: {
        backgroundColor: greyPalette[200],
        flexDirection: "column",
        padding: "0 0 10px 0",
    },
    accordionSummary: {
        margin: "0px",
        padding: "0px",
        "&.Mui-expanded": {
            margin: "0px",
            padding: "0px",
        },
    },
    card: {
        width: "100%",
    },
    cardContent: {
        display: "grid",
        gridTemplateColumns: "38% 33% 28%",
        alignItems: "center",
    },
    column: {
        alignItems: "center",
        display: "flex",
        height: "58px",
        justifyContent: "center",
    },
    columnUserName: {
        justifyContent: "left",
        paddingLeft: "10px",
    },
    lineCard: {
        border: `1px solid ${greyPalette[300]}`,
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    text: {
        color: greyPalette[700],
    },
});

interface IOwnProps {
    index: number;
    readingCertificatePending: ITrainingReadingCertificatePending;
}

const UserCard: FC<IOwnProps> = (props) => {
    const { index, readingCertificatePending } = props;

    const ref = createRef<HTMLDivElement>();
    const classes = useStyles();
    const session = useSelectorUserSession();

    const [expand, setExpand] = useState<boolean>(session?.userId === readingCertificatePending.user.userId);

    useEffect(() => {
        setExpand(session?.userId === readingCertificatePending.user.userId);
    }, [readingCertificatePending, session]);

    /**
     * Handel expand
     */
    const handleExpand = (): void => {
        setExpand(!expand);
    };

    return (
        <Accordion expanded={expand}>
            <AccordionSummary
                classes={{ content: classes.accordionSummary, root: classes.accordionSummary }}
                onClick={handleExpand}
                id={`readingCertificateUserCardExpand${index}Id`}
            >
                <div className={classes.lineCard}>
                    {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    <KortexPanelCard
                        className={classes.card}
                        hover={false}
                        isSelected={false}
                        padding={0}
                        spaceBetweenCard={1}
                        statusColor={theme.palette.error[500]}
                        variant="flat"
                    >
                        <div className={classes.cardContent} id={`readingCertificateUserCard${index}Id`} ref={ref}>
                            {/* USER NAME */}
                            <div className={`${classes.column} ${classes.columnUserName}`}>
                                <Typography className={classes.text} id={`readingCertificateUserCardUserName${index}Id`}>
                                    {`${readingCertificatePending.user.firstName} ${readingCertificatePending.user.lastName}`}
                                </Typography>
                            </div>
                            {/* NUMBER OF READINGS TO DO */}
                            <div className={classes.column}>
                                <Typography className={classes.text} id={`readingCertificateUserCardReadingCount${index}Id`}>
                                    {readingCertificatePending.trainings.length}
                                </Typography>
                            </div>
                            {/* LATE SINCE */}
                            <div className={classes.column}>
                                <Typography className={classes.text} id={`readingCertificateUserCardLateSince${index}Id`}>
                                    {readingCertificatePending.trainings.length
                                        ? getDateStr(Math.min(...readingCertificatePending.trainings.map((t) => t.training.createdOn!)))
                                        : ""}
                                </Typography>
                            </div>
                        </div>
                    </KortexPanelCard>
                </div>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                <Trainings readingCertificatePending={readingCertificatePending} />
            </AccordionDetails>
        </Accordion>
    );
};

export default UserCard;
