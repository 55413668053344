export class LoopState {
    constructor() {
        this.condition = "";
        this.interpolatedCondition = "";
        this.loopCount = 0;
    }
    toJSON() {
        return {
            condition: this.condition,
            interpolatedCondition: this.interpolatedCondition,
            loopCount: this.loopCount,
            result: this.result,
        };
    }
}
