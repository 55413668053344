var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Variable(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M6.06228374,21 C5.09342076,19.7214889 4.34083313,18.3729043 3.80449827,16.9542056 C3.26816341,15.5355069 3,13.8785141 3,11.9831776 C3,10.1775611 3.27681384,8.54860539 3.83044983,7.09626168 C4.38408581,5.64391797 5.12802301,4.27851107 6.06228374,3 L8,3 C7.11187561,4.44673621 6.41407405,5.890647 5.90657439,7.3317757 C5.39907474,8.7729044 5.14532872,10.3233562 5.14532872,11.9831776 C5.14532872,13.665429 5.38177388,15.1962548 5.85467128,16.5757009 C6.32756869,17.9551471 7.04267111,19.4298987 8,21 L6.06228374,21 Z M13.0735586,12.1244513 L16,16.7647059 L13.9960239,16.7647059 L11.9284294,13.4934153 L10.0039761,16.7647059 L8,16.7647059 L10.9264414,11.8933275 L8,7.23529412 L10.0039761,7.23529412 L12.0874751,10.5421422 L13.9960239,7.25307287 L16,7.23529412 L13.0735586,12.1244513 Z M16,21 C16.9027823,19.5420488 17.6030068,18.098138 18.1006944,16.6682243 C18.5983821,15.2383106 18.8472222,13.6822514 18.8472222,12 C18.8472222,10.3065336 18.6157431,8.77570778 18.1527778,7.40747664 C17.6898125,6.0392455 16.9722271,4.57010131 16,3 L17.9270833,3 C18.9108845,4.27851107 19.6689788,5.624292 20.2013889,7.03738318 C20.733799,8.45047436 21,10.1046634 21,12 C21,13.8280465 20.728012,15.4626096 20.1840278,16.9037383 C19.6400436,18.344867 18.8877363,19.7102739 17.9270833,21 L16,21 Z", id: "icons-variable" })));
}
export default Variable;
