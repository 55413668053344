import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default function SortAlphaDown(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M7.243 20.379v-19.574h-1.5v19.574l-2.432-2.432-1.061 1.061 4.243 4.243 4.243-4.243-1.061-1.061-2.432 2.432z M14.030 14.68h4.736l-5.111 4.722v2.028h6.75v-1.5h-5.111l5.111-4.722v-2.028h-6.375v1.5z M15.821 7.93h2.794l0.875 2.625h1.581l-2.75-8.25h-2.206l-2.75 8.25h1.581zM17.196 3.805h0.044l0.875 2.625h-1.794z"
                id="icons-shapes"
            />
        </SvgIcon>
    );
}
