import { ResultSettingMetadataUiModel, UncategorizedGetResultSettingMetadataRes, UnwrapAOSPayload } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { resultSettingMetadataUpsertedAction, setResultSettingMetadataListAction } from "./result-setting-actions";

/**
 * Get all ResultSettingMetadata
 */
export function resultSettingMetadataGetAll(
    options?: IStandardThunkOptions
): StandardThunk<UnwrapAOSPayload<UncategorizedGetResultSettingMetadataRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<UncategorizedGetResultSettingMetadataRes>> {
        try {
            if (!skipLookup) {
                if (fetchedOnce.was(resultSettingMetadataGetAll)) {
                    return getState().resultSetting.resultSettingMetadata;
                }
            }

            return api.services.uncategorized
                .getResultSettingMetadata(emptyObject)()
                .then((metadata) => {
                    if (!skipDispatch) {
                        fetchedOnce.seal(resultSettingMetadataGetAll);

                        dispatch(setResultSettingMetadataListAction([...metadata]));
                    }

                    return metadata;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            return [];
        }
    };
}

/**
 * Upsert ResultSettingMetadata
 */
export function resultSettingMetadataUpsert(
    resultSettingMetadata: APIPayload<"uncategorized", "upsertResultSettingMetadata">
): StandardThunk<ResultSettingMetadataUiModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<ResultSettingMetadataUiModel | undefined> => {
        try {
            return api.services.uncategorized
                .upsertResultSettingMetadata(resultSettingMetadata)()
                .then(
                    (metadata) => {
                        dispatch(resultSettingMetadataUpsertedAction([metadata]));

                        return metadata;
                    },
                    () => undefined
                );
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }

            return undefined;
        }
    };
}
