import { makeStyles } from "@material-ui/core/styles";
import WebAppIcon from "@material-ui/icons/TabletAndroidTwoTone";
import HubIcon from "@material-ui/icons/DeviceHubTwoTone";
import StorageIcon from "@material-ui/icons/InsertDriveFileTwoTone";
import CheckIcon from "@material-ui/icons/BeenhereOutlined";
import "moment/locale/fr";
import * as React from "react";
import { Typography, Card } from "@material-ui/core";
import { theme } from "@aos/react-components";
import { useState, useEffect } from "react";
import { IVersion } from "@kortex/aos-common";

import { version } from "../../../version";
import { useSelectorConnectionList } from "../../../redux/selectors";
import { useTranslate } from "../../../hooks/useTranslate";
import RunnerIcon from "../../core/Icons/Runner/Runner";
import * as CloudStorage from "../../../utilitites/storage";

const useStyles = makeStyles({
    root: {
        padding: "16px",
    },
    card: {
        marginTop: "16px",
        marginRight: "16px",
        padding: "16px",
        width: "500px",
    },
    cardList: {
        display: "flex",
        flexDirection: "row",
        flexFlow: "wrap",
    },
    mainTitle: {
        display: "flex",
        padding: "30px 20px 0px 20px",
        height: "48px",
        alignItems: "center",
        color: theme.palette.grey[500],
    },
    title: {
        display: "flex",
        paddingLeft: "20px",
        height: "42px",
        alignItems: "center",
        color: theme.palette.grey[500],
    },
    titleIcon: {
        width: "42px",
        height: "42px",
        color: theme.palette.grey[700],
    },
    titleRunnerIcon: {
        width: "56px",
        height: "56px",
        color: theme.palette.grey[700],
        marginLeft: "-6px",
    },
    checkIcon: {
        width: "20px",
        height: "20px",
        paddingLeft: "10px",
        color: theme.palette.grey[500],
    },
    header: {
        display: "flex",
        flexDirection: "row",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        borderTop: "1px solid",
        borderColor: theme.palette.grey[200],
        marginLeft: "80px",
        height: "30px",
        alignItems: "center",
    },
    tableLeft: {
        width: "150px",
        color: theme.palette.grey[700],
    },
    tableRight: {
        color: theme.palette.grey[700],
        width: "275px",
        lineHeight: "1",
    },
});

function InfoPage(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    const [storageVersion, setStorageVersion] = useState("");

    const connectionList = useSelectorConnectionList();

    /**
     * Retreive Storage Version
     */
    useEffect((): void => {
        getStorageVersion();
    }, []);

    // console.log for troubleshoot in prod
    useEffect((): void => {
        console.log("connectionList UI : ", connectionList.ui);
    }, [connectionList]);

    /**
     * Retrieve storage version via REST Get message
     */
    async function getStorageVersion(): Promise<void> {
        fetch(`${CloudStorage.get().host}/version`, {
            method: "GET",
        })
            .then((res): void => {
                if (res.ok) {
                    res.json().then(({ version }) => setStorageVersion(version));
                } else {
                    console.error("Unable to get version from storage server");
                    setStorageVersion("Cannot connect");
                }
            })
            .catch((err): string => {
                console.error("Error", err);
                return translate("info.unknown");
            });
    }

    /**
     * Return one row of property:value type
     *
     * @param {string} propertyName - property name
     * @param {string} value - value
     *
     */
    const getRow = (propertyName: string, value: string): JSX.Element => {
        return (
            <div className={classes.row}>
                <Typography className={classes.tableLeft} variant="h6">
                    {propertyName}
                </Typography>
                <Typography className={classes.tableRight} variant="h6">
                    {value}
                </Typography>
            </div>
        );
    };

    /**
     * Return one row of property:value type
     *
     * @param {IVersion} version - version to be shown
     */
    const getRowVersion = (version: IVersion): JSX.Element => {
        const versionString = `${version.major}.${version.minor}.${version.patch}-r.${version.build}`;

        return (
            <div className={classes.row}>
                <Typography className={classes.tableLeft} variant="h6">
                    {translate("info.version")}
                </Typography>
                <Typography className={classes.tableRight} variant="h6">
                    {versionString}
                </Typography>
            </div>
        );
    };

    return (
        <div className={classes.root} id="InfoPageId">
            {/* SUMMARY RESULTS */}
            <Card className={classes.card}>
                <div className={classes.header}>
                    <WebAppIcon className={classes.titleIcon} />
                    <Typography className={classes.title} variant="h2">
                        {translate("info.ui")}
                    </Typography>
                </div>
                {getRowVersion(version)}
            </Card>

            <Typography className={classes.mainTitle} variant="h2">
                {translate("info.connection")}
            </Typography>

            <div className={classes.cardList}>
                <Card className={classes.card}>
                    <div className={classes.header}>
                        <HubIcon className={classes.titleIcon} />
                        <Typography className={classes.title} variant="h2">
                            {translate("info.hub")}
                        </Typography>
                    </div>
                    {getRowVersion(connectionList.hub.version)}
                </Card>
            </div>

            <div className={classes.cardList}>
                {connectionList.ui.map((ui, index: number): JSX.Element => {
                    return (
                        <Card key={index} className={classes.card}>
                            <div className={classes.header}>
                                <WebAppIcon className={classes.titleIcon} />
                                <Typography className={classes.title} variant="h2">
                                    {translate("info.ui")}
                                </Typography>
                                {ui.current && <CheckIcon className={classes.checkIcon} />}
                            </div>
                            {getRow(translate("info.fullName"), `${ui.fullName}`)}
                            {getRow(translate("info.userName"), `${ui.userName}`)}
                        </Card>
                    );
                })}
            </div>

            <div className={classes.cardList}>
                {connectionList.runner.length > 0 ? (
                    connectionList.runner.map((runner, index: number): JSX.Element => {
                        return (
                            <Card key={index} className={classes.card}>
                                <div className={classes.header}>
                                    <RunnerIcon className={classes.titleRunnerIcon} />
                                    <Typography className={classes.title} variant="h1">
                                        {translate("info.runner")}
                                    </Typography>
                                </div>
                                {getRowVersion(runner.version)}
                            </Card>
                        );
                    })
                ) : (
                    <Card className={classes.card}>
                        <div className={classes.header}>
                            <RunnerIcon className={classes.titleRunnerIcon} />
                            <Typography className={classes.title} variant="h1">
                                {translate("info.runner")}
                            </Typography>
                        </div>
                        {getRow(translate("info.notConnected"), "")}
                    </Card>
                )}
            </div>
            <div className={classes.cardList}>
                <Card className={classes.card}>
                    <div className={classes.header}>
                        <StorageIcon className={classes.titleIcon} />
                        <Typography className={classes.title} variant="h1">
                            {translate("info.storage")}
                        </Typography>
                    </div>
                    {getRow(translate("info.host"), CloudStorage.get().host)}
                    {getRow(translate("info.version"), storageVersion)}
                </Card>
            </div>
        </div>
    );
}

export default InfoPage;
