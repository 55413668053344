import { EventEmitter } from "events";

import { ServiceManagerClient, WSClient, Kinova } from "@kortex/aos-api-client";
import { FailureSymptomsUpdatedNotif, getRPCHubInfo } from "@kortex/aos-common";

import {
    objectFromUTF8Array,
    utf8ArrayFromObject,
    utf8ArrayToKortexApiErrorObject,
    utf8ArrayToKortexApiNotificationHandleObject,
} from "../helpers";
import { emptyObject } from "../constants";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const failureSymptomsUpdated = (router: WSClient["router"], notifier: EventEmitter) =>
    ServiceManagerClient.getNR<emptyObject, Kinova.Api.Common.INotificationHandle, FailureSymptomsUpdatedNotif, Kinova.Api.IError>(
        getRPCHubInfo("failureSymptoms")("updated"),
        {
            notifications: notifier,
            router,
            serializer: {
                decode: utf8ArrayToKortexApiNotificationHandleObject,
                encode: utf8ArrayFromObject,
                err: {
                    decode: utf8ArrayToKortexApiErrorObject,
                },
                notifier: objectFromUTF8Array,
            },
        }
    );
