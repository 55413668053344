export class ConnectorState {
    constructor() {
        this.output = [];
    }
    toJSON() {
        return {
            output: this.output,
        };
    }
}
