import { theme } from "@aos/react-components";
import { UserValidationDialog } from "@kortex/aos-ui/components/core/UserValidationDialog";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import { trainingReadingCertificateSign } from "@kortex/aos-ui/redux/training-reading-certificate-pending-manager/training-reading-certificate-pending-thunks";
import { IconButton, List, ListItem, ListItemText, Tooltip, Typography, makeStyles } from "@material-ui/core";
import ReadingCertificateIcon from "@material-ui/icons/AssignmentTurnedIn";
import React, { FC, useState } from "react";

import { useReadingCertificateContext } from "../../../../context";

const useStyles = makeStyles({
    certificationCriteriaContent: {
        marginLeft: "50px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

const ReadingCertificateFormDialog: FC = () => {
    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const session = useSelectorUserSession();
    const { selectedTrainings, setSelectedTraining } = useReadingCertificateContext();

    const [open, setOpen] = useState<boolean>(false);

    const openButtonDisabled = !selectedTrainings.length;

    const handleOpenDialog = (): void => {
        setOpen(true);
    };

    const handleCancel = (): void => {
        setOpen(false);
    };

    const handleSign = async (): Promise<void> => {
        if (!session?.userId) return void 0;

        return dispatch(
            trainingReadingCertificateSign({
                context: translate("training.readingCertificateSignatureContext"),
                trainingIds: selectedTrainings.map((training) => training.trainingId),
                userId: session.userId,
            })
        ).then(() => {
            setOpen(false);
            setSelectedTraining([]);
        });
    };

    return (
        <>
            {/* ICON TO OPEN DIALOG */}
            <Tooltip classes={{ tooltip: classes.tooltip }} placement="top" title={translate("training.readingCertificate")}>
                <IconButton disabled={openButtonDisabled} onClick={handleOpenDialog}>
                    <ReadingCertificateIcon color={openButtonDisabled ? "disabled" : "secondary"} />
                </IconButton>
            </Tooltip>

            {/* DIALOG */}
            <UserValidationDialog
                electronicSignatureContext={
                    <>
                        <Typography>{translate("training.readingCertificateSignatureContext")}</Typography>
                        <List>
                            {selectedTrainings.map((training, index) => (
                                <ListItem
                                    key={index}
                                    style={{
                                        backgroundColor: index % 2 === 0 ? theme.palette.grey[200] : theme.palette.common.white,
                                    }}
                                >
                                    <ListItemText
                                        id={`readingCertificateDialogProcess${index}`}
                                        primary={`${training.processName} (${training.processVersion})`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </>
                }
                isElectronicSignature={true}
                onClose={handleCancel}
                onValidate={handleSign}
                open={open}
                preSelectedUserId={session?.userId}
            />
        </>
    );
};

export default ReadingCertificateFormDialog;
