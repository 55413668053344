import { theme } from "@aos/react-components";
import { EnumProcessStatus, IOperatorJobProcessInfo } from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useSelectorPlayer } from "@kortex/aos-ui/redux/selectors";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

import { initStateAction } from "../../../redux/player-manager/player-actions";
import NotificationsCenterDialog from "../../pages/NotificationsCenter/NotificationsCenterDialog";

import Player from "./Player";
import { PlayerProvider } from "./context/playerContext";

const useStyles = makeStyles({
    root: {
        position: "relative",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        height: "100%",
    },
    dialogTitle: {
        ...theme.typography.h5,
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.primary[500],
        color: theme.palette.common.white,
        padding: "5px 10px",
        height: "60px",
        display: "grid",
        gridAutoColumns: "94% 2% 4%",
        gridAutoFlow: "column",
    },
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
    },
});

const Transition = React.forwardRef((props: SlideProps, ref): JSX.Element => <Slide {...props} direction="up" ref={ref} />);
Transition.displayName = "SlideUp";

interface IOwnProps {
    open: boolean;
    dryRunMode: boolean;
    testRunMode: boolean;
    runWithoutJobProcess?: boolean;
    title: string;
    processId: number;
    reworkId: number;
    jobProcessInfo?: IOperatorJobProcessInfo;
    onClose: () => void;
}

function PlayerDialog(props: IOwnProps): JSX.Element {
    const { title, processId, dryRunMode, testRunMode, open, jobProcessInfo, reworkId } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();

    const playerState = useSelectorPlayer();

    useStopKeybindPropagation(open);

    /**
     * handle the close event
     */
    const handleClose = (): void => {
        if (props.onClose) {
            props.onClose();
            dispatch(initStateAction());
        }
    };

    return (
        <Dialog fullScreen={true} open={open} TransitionComponent={Transition}>
            <DialogTitle className={classes.dialogTitle} disableTypography={true}>
                {title}
                {<NotificationsCenterDialog />}
                <IconButton
                    color="inherit"
                    id="playerCloseButtonId"
                    onClick={handleClose}
                    aria-label="Close"
                    disabled={
                        playerState.processState.status === EnumProcessStatus.PLAYING ||
                        playerState.processState.status === EnumProcessStatus.PAUSED
                    }
                >
                    <CloseIcon fontSize="large" />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <PlayerProvider processId={processId} jobProcessInfo={jobProcessInfo}>
                    <Player
                        dryRunMode={dryRunMode}
                        isTestRun={testRunMode}
                        runWithoutJobProcess={props.runWithoutJobProcess}
                        processId={processId}
                        onClose={handleClose}
                        reworkId={reworkId}
                    />
                </PlayerProvider>
            </DialogContent>
        </Dialog>
    );
}

export default PlayerDialog;
