import {
    ActionBlockSizeEnum,
    ActionOutputLink,
    ElementConnectorSourceEnum,
    ICoords,
    ICoordsPair,
    IProcessActionDbModel,
    connectorPath,
    getHeightIncrementToAlignGrid,
    getPositionAlignedGrid,
} from "@kortex/aos-common";
import * as React from "react";

/**
 * Get the input/output coordinates of an action
 *
 * @param {ElementConnectorSourceEnum} source - Target type
 * @param {IProcessActionDbModel} action - Action from which the input/output comes from
 * @param {number} index - Index of the input/output
 */
export const getInputOutputCoordinates = (source: ElementConnectorSourceEnum, action: IProcessActionDbModel, index = 0): ICoords => {
    const maxIOCount = Math.max(action.inputs.length, action.outputs.length);
    const destIOCOunt = source === ElementConnectorSourceEnum.INPUT ? action.inputs.length : action.outputs.length;

    const x =
        action.posX +
        (source === ElementConnectorSourceEnum.INPUT || source === ElementConnectorSourceEnum.RETURN
            ? ActionBlockSizeEnum.INPUT_OFFSET
            : ActionBlockSizeEnum.OUTPUT_OFFSET);
    const y_notAlignedOnGrid =
        source === ElementConnectorSourceEnum.RETURN
            ? action.posY +
              ActionBlockSizeEnum.DEFAULT_HEIGHT +
              ActionBlockSizeEnum.SHADOW_OFFSET +
              2 * getHeightIncrementToAlignGrid(2) +
              ActionBlockSizeEnum.RETURN_OFFSET
            : action.posY +
              ActionBlockSizeEnum.HEADER_HEIGHT +
              ActionBlockSizeEnum.SHADOW_OFFSET +
              ((ActionBlockSizeEnum.DEFAULT_HEIGHT -
                  ActionBlockSizeEnum.HEADER_HEIGHT +
                  maxIOCount * getHeightIncrementToAlignGrid(maxIOCount)) /
                  (destIOCOunt > 1 ? 1 + maxIOCount : 2)) *
                  (index + 1);

    const y = getPositionAlignedGrid(y_notAlignedOnGrid, action.posY);

    return { x, y };
};

/**
 * Get the coordinates of the link
 *
 * @param {IProcessActionDbModel[]} actionList - list of action
 * @param {IProcessActionDbModel} currentAction - action from which the link will be drawn
 * @param {ActionOutputLink} output - output from which the link will be drawn (currentAction's output)
 * @param {number} index - index of the output
 *
 * @returns {ICoordsPair} the coordinates of current action's output (x1,y1) and the coordinates of target action's input (x2,y2)
 */
export const getActionLinkCoordinates = (
    actionList: IProcessActionDbModel[],
    currentAction: IProcessActionDbModel,
    output: ActionOutputLink,
    index: number
): ICoordsPair | undefined => {
    const actionLinkCoords: ICoordsPair = {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
    };

    const targetAction = actionList.find((destAction: IProcessActionDbModel): boolean =>
        Boolean(output.remoteIds.length > 0 && destAction.processActionId === output.remoteIds[0].actionId)
    );

    if (!targetAction) {
        return void 0;
    }
    const isReturnForInput = output.remoteIds[index]?.isReturn;

    const currentActionOutputCoords = getInputOutputCoordinates(ElementConnectorSourceEnum.OUTPUT, currentAction, index);
    const targetActionInputCoords = getInputOutputCoordinates(
        isReturnForInput ? ElementConnectorSourceEnum.RETURN : ElementConnectorSourceEnum.INPUT,
        targetAction
    );

    actionLinkCoords.x1 = currentActionOutputCoords.x;
    actionLinkCoords.y1 = currentActionOutputCoords.y;
    actionLinkCoords.x2 = targetActionInputCoords.x;
    actionLinkCoords.y2 = targetActionInputCoords.y;

    return actionLinkCoords;
};

export interface IActionLinkProps {
    actionsProps: IProcessActionDbModel[];
}

export default function ActionLink(props: IActionLinkProps): JSX.Element {
    const { actionsProps } = props;

    /**
     * Draw the action link
     *
     * @param {object[]} actionList - list of action
     * @param {object} currentAction - action from which the link will be drawn
     * @param {object} output - output from which the link will be drawn (currentAction's output)
     * @param {number} index - index of the output
     */
    const drawActionLinks = (
        actionList: IProcessActionDbModel[],
        currentAction: IProcessActionDbModel,
        output: ActionOutputLink,
        index: number
    ): JSX.Element => {
        if (output.remoteIds.length === 0) {
            return <div key={index} />;
        }

        const coords = getActionLinkCoordinates(actionList, currentAction, output, index);

        if (!coords) {
            return <div key={index} />;
        }

        return (
            <g key={output.id} style={{ pointerEvents: "none" }}>
                <path
                    id="actionLinkId"
                    d={connectorPath(coords.x1, coords.y1, coords.x2, coords.y2)}
                    stroke="black"
                    strokeWidth="1"
                    fill="none"
                />
                <circle r={ActionBlockSizeEnum.INPUT_OUTPUT_RADIUS} fill="black" cx={coords.x1} cy={coords.y1} />
                <circle r={ActionBlockSizeEnum.INPUT_OUTPUT_RADIUS} fill="black" cx={coords.x2} cy={coords.y2} />
            </g>
        );
    };

    return (
        <g>
            {actionsProps.map((actionProps: IProcessActionDbModel): JSX.Element[] => {
                return actionProps.outputs.map((output: ActionOutputLink, index: number): JSX.Element => {
                    return drawActionLinks(actionsProps, actionProps, output, index);
                });
            })}
        </g>
    );
}
