/**
 * remove needles from a haystack.
 *
 * If the predicate returns "true" it will remove the occurence in the haystack
 *
 * @typedef T
 * @param {T[]} haystack - The array of T to search in
 * @param {T[]|T} needles - What to search for
 * @param {(this: T, value: T, index: number, obj: T[]) => boolean} predicate - Predicate for matching needles against the haystack
 *
 * @returns {T[]}
 */
export function remove(haystack, needles, predicate) {
    const shallowHaystack = [...haystack];
    for (const needle of Array.isArray(needles) ? needles : [needles]) {
        const index = shallowHaystack.findIndex(predicate, needle);
        if (index > -1) {
            shallowHaystack.splice(index, 1);
        }
    }
    return shallowHaystack;
}
