import { TNotification } from "@kortex/aos-common";

import {
    SetNotificationListAction,
    NotificationActionType,
    NotificationInsertedAction,
    NotificationDeletedAction,
    AllNotificationsDeletedAction,
} from "./notification-types";

/**
 * Returns an action
 *
 * @param {NotificationInsertedAction} notification - value of the action
 */
export function notificationInsertedAction(notification: NotificationInsertedAction["value"]): NotificationInsertedAction {
    return { type: NotificationActionType.INSERT_NOTIFICATION, value: notification };
}

/**
 * Returns an action
 *
 * @param {NotificationDeletedAction} notification - value of the action
 */
export function notificationDeletedAction(notification: TNotification): NotificationDeletedAction {
    return { type: NotificationActionType.DELETE_NOTIFICATION, value: notification };
}

/**
 * Returns an action
 */
export function allNotificationsDeletedAction(): AllNotificationsDeletedAction {
    return { type: NotificationActionType.DELETE_ALL_NOTIFICATIONS, value: [] };
}

/**
 * Returns an action
 *
 * @param {SetNotificationListAction} notifications - value of the action
 */
export function setNotificationListAction(notifications: SetNotificationListAction["value"]): SetNotificationListAction {
    return { type: NotificationActionType.SET_NOTIFICATION_LIST, value: notifications };
}
