import { IOperatorJobProcessesFiltersOptions, IOperatorJobProcessInfo } from "@kortex/aos-common";

import { IFilters } from "../SchedulerOperatorSearchBar";
import {
    removeFalsyPropsExceptBoolean,
    formatStringFilterForFilterOptions,
    formatStringArrayFilterForFilterOptions,
    formatDateFilterForFilterOptions,
} from "../../../../utilitites/filterSearchBar";

export const verifyIfFiltersAreEmpty = (filters: IFilters): boolean => {
    return !Object.keys(filters).some(
        (key) => !(!filters[key] || (typeof filters[key] === "object" && Object.keys(filters[key]).length === 0))
    );
};

/**
 * Get job list sorted by order ID
 */
export const getOperatorJobProcessesSortedById = (operatorList: IOperatorJobProcessInfo[]): IOperatorJobProcessInfo[] => {
    return operatorList.sort((first, second): number => {
        return first && second ? first.jobProcessId - second.jobProcessId : 0;
    });
};

export const convertUiFiltersToBackendFilters = (uiFilters: IFilters): IOperatorJobProcessesFiltersOptions => {
    // Proceed with the call to the backend
    return removeFalsyPropsExceptBoolean({
        masterSearch: formatStringFilterForFilterOptions(uiFilters.plainText),
        statuses: formatStringArrayFilterForFilterOptions(uiFilters.status) as Array<string> | undefined,
        jobRefId: formatStringFilterForFilterOptions(uiFilters.jobRefId),
        partNumber: formatStringFilterForFilterOptions(uiFilters.partNumber),
        referenceId: formatStringFilterForFilterOptions(uiFilters.reference),
        trackingId: formatStringFilterForFilterOptions(uiFilters.trackingId),
        scheduledDateFrom: formatDateFilterForFilterOptions(uiFilters.scheduledDateFrom),
        scheduledDateTo: formatDateFilterForFilterOptions(uiFilters.scheduledDateTo),
        requestedDateFrom: formatDateFilterForFilterOptions(uiFilters.requestedDateFrom),
        requestedDateTo: formatDateFilterForFilterOptions(uiFilters.requestedDateTo),
        plannedDateFrom: formatDateFilterForFilterOptions(uiFilters.plannedDateFrom),
        plannedDateTo: formatDateFilterForFilterOptions(uiFilters.plannedDateTo),
    });
};
