import { greyPalette, theme } from "@aos/react-components";
import { ICoords } from "@kortex/aos-common";
import { Divider, IconButton, Menu, MenuItem, makeStyles } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useContext, useState } from "react";

import { useTranslate } from "../../../../../../../hooks/useTranslate";
import { IWorkInstructionsElementMenuItem, WorkInstructionsEditorContext } from "../utilities";

const useStyles = makeStyles({
    icon: {
        backgroundColor: theme.palette.common.white,
        borderRadius: "50%",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 12px",
        height: "48px",
        position: "fixed",
        width: "48px",
        zIndex: theme.zIndex.modal,
        "&:hover": {
            backgroundColor: greyPalette[300],
        },
    },
});

export enum EnumSortDirection {
    FRONT = 0,
    FORWARD = 1,
    BACKWARD = 2,
    BACK = 3,
}

export interface IOwnProps {
    onDelete: () => void;
    onEdit: () => void;
    onSort: (sort: EnumSortDirection) => void;
    position?: ICoords;
}

export default function WorkInstructionsElementMenu(props: IOwnProps): JSX.Element {
    const { onDelete, onEdit, onSort, position } = props;

    const classes = useStyles();
    const { elementMenuItems } = useContext(WorkInstructionsEditorContext);
    const translate = useTranslate();

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | undefined>(undefined);

    const extraMenuItems = elementMenuItems.filter((item) => !item.hidden);

    /**
     * Called when clicking away from the menu
     * Closes the menu
     */
    const handleClose = (): void => {
        setMenuAnchor(undefined);
    };

    /**
     * Called when Delete is clicked
     */
    const handleDelete = (): void => {
        onDelete();
    };

    /**
     * Called when Edit is clicked
     */
    const handleEdit = (): void => {
        onEdit();
    };

    /**
     * Called when 3-dots icon is clicked
     * Opens the menu
     *
     * @param {React.MouseEvent<HTMLElement>} event - mouse event data
     */
    const handleIconClick = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setMenuAnchor(event.currentTarget);
    };

    /**
     * Called when a sort option is clicked
     */
    const handleSort =
        (sort: EnumSortDirection): (() => void) =>
        (): void => {
            onSort(sort);
        };

    const menuItemCbWrapper =
        (cb: IWorkInstructionsElementMenuItem["onClick"]): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            event.stopPropagation();
            handleClose();
            cb();
        };

    return (
        <React.Fragment>
            {/* 3-DOTS ICON */}
            <IconButton
                className={classes.icon}
                id="workInstructionsElementMenuButtonId"
                onClick={handleIconClick}
                style={{
                    left: position ? position.x.toString() + "px" : undefined,
                    display: position === undefined ? "none" : undefined,
                    top: position ? position.y.toString() + "px" : undefined,
                }}
            >
                <MoreVertIcon />
            </IconButton>

            {/* MENU */}
            <Menu anchorEl={menuAnchor} onClose={handleClose} open={Boolean(menuAnchor)}>
                <MenuItem id="menuEdit" onClick={menuItemCbWrapper(handleEdit)}>
                    {translate("action.workInstructions.elementMenu.edit")}
                </MenuItem>
                <Divider />
                <MenuItem id="menuSortFront" onClick={menuItemCbWrapper(handleSort(EnumSortDirection.FRONT))}>
                    {translate("action.workInstructions.elementMenu.front")}
                </MenuItem>
                <MenuItem id="menuSortForward" onClick={menuItemCbWrapper(handleSort(EnumSortDirection.FORWARD))}>
                    {translate("action.workInstructions.elementMenu.forward")}
                </MenuItem>
                <MenuItem id="menuSortBackward" onClick={menuItemCbWrapper(handleSort(EnumSortDirection.BACKWARD))}>
                    {translate("action.workInstructions.elementMenu.backward")}
                </MenuItem>
                <MenuItem id="menuSortBack" onClick={menuItemCbWrapper(handleSort(EnumSortDirection.BACK))}>
                    {translate("action.workInstructions.elementMenu.back")}
                </MenuItem>
                <Divider />
                <MenuItem id="menuDelete" onClick={menuItemCbWrapper(handleDelete)}>
                    {translate("action.workInstructions.elementMenu.delete")}
                </MenuItem>
                {extraMenuItems.length > 0 && <Divider />}
                {extraMenuItems.map((item, index) => (
                    <MenuItem id={item.id} key={`WorkInstructionsElementMenuItem${index}`} onClick={menuItemCbWrapper(item.onClick)}>
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}
