import { useEffect, useState, useContext, createContext } from "react";
import shortid from "shortid";

interface IStackContext {
    stack: string[];
    push: (id: string) => void;
    remove: (id: string) => void;
}

/**
 * Based on ...
 * https://medium.com/@cosmvs/react-usecontext-how-to-update-context-from-child-component-8fa2894eee3d
 *
 * Creates the context that contains the stack's actual value
 */
export const ForegroundStackContext = createContext<IStackContext>({
    stack: [],
    push: () => undefined,
    remove: () => undefined,
});

/**
 * Hook that's used to initialize the context's initial value and setters
 */
export const useForegroundStack = (): IStackContext => {
    const [stack, setStack] = useState<string[]>([]);

    const remove = (id: string): void => {
        setStack((prevStack) => prevStack.filter((elem) => elem !== id));
    };

    const push = (id: string): void => {
        setStack((prevStack) => [...prevStack, id]);
    };

    return {
        stack,
        push,
        remove,
    };
};

/**
 * This hook is used to handle multiple modals getting stacked within the app. It allows the programmer to
 * keep track of which modal is on the foreground, and therefore disable the ones that are not.
 * This hook should only be used in components of which a single instance is rendered on screen at once.
 *
 * It works by keeping a stack as a context variable. Only the last element that was added to the stack
 * returns true to its state variable isForeground. By default, the elements are added to the stack when
 * they are rendered for the first time, and removed when they are dismounted.
 *
 * @param {boolean} condition - Allows to use a boolean as a condition to add the component to the stack, instead of the default behavior of mount/dismount.
 */
export function useForeground(condition?: boolean): boolean {
    // generate a persisting id for each component that registers
    const [id] = useState(shortid.generate());
    const { stack, push, remove } = useContext(ForegroundStackContext);

    useEffect(() => {
        if (condition === undefined) {
            // add and activate new element
            push(id);
        }

        return (): void => {
            // remove element from stack
            remove(id);
        };
    }, []);

    if (condition !== undefined) {
        useEffect(() => {
            // add and activate new element
            if (condition) {
                push(id);
            } else {
                remove(id);
            }
        }, [condition]);
    }

    return stack.length > 0 && stack[stack.length - 1] === id;
}
