export type EventTypeKey = "keydown" | "keyup";
export type EventTypeCopy = "copy" | "paste";
export type EventType = EventTypeKey | EventTypeCopy;

export type Key = KeyboardEvent["key"];
export type Id = string;

export type Callback<T extends Event = KeyboardEvent> = (event: T) => void;

export type StackMap = Record<Key, Id[] /* FILO stack */>;

export type LayerId = string;
export type Layer = [
    LayerId, // ID
    boolean, // Stop propagation
];
export type LayerStack = Layer[]; // FILO stack

export const COPY_PASTE_KEY = "CopyPaste";
