import * as React from "react";
import { ClickAwayListener } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import TasksIcon from "@material-ui/icons/AssignmentInd";
import { useEffect, useState } from "react";
import { theme } from "@aos/react-components";
import { TTaskUnknown, TaskStatusEnum, TaskTypeEnum, ITaskInfoProcessReview } from "@kortex/aos-common";

import { useEntitiesTasks, useEntitiesUserSession } from "../../../redux/effects";

const useStyles = makeStyles({
    mainContainer: {
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
    },
    mainButton: {
        padding: "0",
    },
    mainIcon: {
        height: "32px",
        width: "32px",
        color: theme.palette.common.white,
    },
    taskBadge: {
        position: "absolute",
        left: "15px",
        top: "0px",
        borderRadius: "50%",
        minWidth: "20px",
        width: "20px",
        height: "20px",
        color: theme.palette.common.white, // Text color
        fontSize: "0.85rem",
        paddingTop: "1px",
        display: "flex",
        justifyContent: "center",
    },
});

interface IOwnProps {
    onGoToTasksPage?: () => void;
}

export default function TasksStatus(props: IOwnProps): JSX.Element {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [badgeColor, setBadgeColor] = useState<string>("");
    const [badgeCount, setBadgeCount] = useState<number>(0);
    const [badgeInvisible, setBadgeInvisible] = useState<boolean>(true);

    const allTasks: TTaskUnknown[] = useEntitiesTasks();
    const userInfo = useEntitiesUserSession();

    const divBadgeStyle = {
        backgroundColor: badgeColor,
    };

    // Effect that updates state when the tasks (for everyone) from store change
    useEffect((): void => {
        let myTasksCounter = 0;

        if (!userInfo) {
            return;
        }

        for (const task of allTasks) {
            // Only open task
            if (task.status === TaskStatusEnum.OPEN) {
                // And for me... TBD - Check if I am the userId, in the GroupId or the reviewer id
                if (
                    task.userId === userInfo?.userId || // creator of the task is me
                    userInfo.userGroups.find((group) => group === task.groupId) || // user is within the group list
                    (task.type === TaskTypeEnum.PROCESS_REVIEW && (task.info as ITaskInfoProcessReview).reviewerId === userInfo?.userId)
                ) {
                    myTasksCounter++;
                }
            }
        }

        if (myTasksCounter > 0) {
            setBadgeColor(theme.palette.secondary.main);
            setBadgeCount(myTasksCounter);
            setBadgeInvisible(false);
            return;
        } else {
            setBadgeCount(0);
            setBadgeInvisible(true);
        }
    }, [allTasks, userInfo]);

    /**
     * handle the poper click over
     */
    const handleClickButton = (): void => {
        if (props.onGoToTasksPage) {
            props.onGoToTasksPage();
        }
    };

    /*
     * Click away listener
     */
    const handleClickAway = (): void => {
        setOpen(false);
    };

    return (
        <div>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className={classes.mainContainer}>
                    <Button
                        disableRipple={false}
                        aria-owns={open ? "simple-popper" : undefined}
                        aria-haspopup={true}
                        className={classes.mainButton}
                        onClick={handleClickButton}
                    >
                        <TasksIcon className={classes.mainIcon} />
                        {!badgeInvisible ? (
                            <div className={classes.taskBadge} style={divBadgeStyle}>
                                {badgeCount}
                            </div>
                        ) : null}
                    </Button>
                </div>
            </ClickAwayListener>
        </div>
    );
}
