import { MathState } from "@kortex/aos-common";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    outputContainer: {
        height: "-webkit-fill-available",
        padding: "32px",
        width: "-webkit-fill-available",
    },
    outputText: {
        marginBottom: "32px",
        textAlign: "center",
    },
    outputTitle: {
        marginBottom: "32px",
    },
});

export interface IOwnProps {
    actionState: MathState;
}

export default function MathPlayer(props: IOwnProps): JSX.Element {
    const { actionState } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.outputContainer}>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.mathPlayer.equation")}
            </Typography>
            <Typography className={classes.outputText} variant="h3">
                {actionState.equation}
            </Typography>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.mathPlayer.substitution")}
            </Typography>
            <Typography className={classes.outputText} variant="h3">
                {actionState.interpolatedEquation}
            </Typography>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.mathPlayer.result")}
            </Typography>
            <Typography className={classes.outputText} variant="h3">
                {actionState.result ? actionState.result.toString() : ""}
            </Typography>
        </div>
    );
}
