import { ActionTypeEnum, ConditionOperandTypeEnum, ConditionOperatorComparisonEnum, ConditionOperatorLogicalEnum, ConditionStatementEnum, FailureCodeEnum, FailureSourceEnum, SequencerActionPlayCompletedPayloadTypeEnum, SequencerActionStatusEnum, } from "../../enums";
import { CoreActionConfigError, InterpolationError, } from "../../interfaces";
import { localeKeys } from "../../locales";
import { KortexSequencerDebugger, deepClone, defer, interpolateVariable, isPromiseStarted, timestamp, } from "../../utilities";
import { isConditionAdvanced, isConditionBasic } from "../../utilities/condition-type-predicates";
import { interpolateActionConfig } from "../../utilities/interpolate-action-config";
const debug = KortexSequencerDebugger.core.condition;
export const SEQUENCER_ACTION_CONDITION_DEFAULT_STATE = {};
const CONDITION_COMPARISON_OPERATOR_MAP = {
    [ConditionOperatorComparisonEnum.EQUAL]: "===",
    [ConditionOperatorComparisonEnum.GREATER_THAN]: ">",
    [ConditionOperatorComparisonEnum.GREATER_THAN_OR_EQUAL_TO]: ">=",
    [ConditionOperatorComparisonEnum.LESS_THAN]: "<",
    [ConditionOperatorComparisonEnum.LESS_THAN_OR_EQUAL_TO]: "<=",
    [ConditionOperatorComparisonEnum.NOT_EQUAL]: "!==",
};
const CONDITION_LOGICAL_OPERATOR_MAP = {
    [ConditionOperatorLogicalEnum.AND]: "&&",
    [ConditionOperatorLogicalEnum.NA]: "",
    [ConditionOperatorLogicalEnum.OR]: "||",
};
const CONDITION_OPERAND_MAP = {
    [ConditionOperandTypeEnum.BOOLEAN]: (operand) => operand,
    [ConditionOperandTypeEnum.DATE]: (operand) => timestamp(operand).now,
    [ConditionOperandTypeEnum.NUMBER]: (operand) => operand,
    [ConditionOperandTypeEnum.TEXT]: (operand) => "'" + operand + "'",
    [ConditionOperandTypeEnum.VARIABLE]: (operand, variables) => {
        try {
            const result = interpolateVariable(operand, variables);
            return typeof result === "string" ? CONDITION_OPERAND_MAP[ConditionOperandTypeEnum.TEXT](result, variables) : result;
        }
        catch (error) {
            throw error;
        }
    },
};
export function createConditionString(condition, variables) {
    try {
        return (CONDITION_LOGICAL_OPERATOR_MAP[condition.logical] +
            CONDITION_OPERAND_MAP[condition.left.type](condition.left.value, variables) +
            CONDITION_COMPARISON_OPERATOR_MAP[condition.comparison] +
            CONDITION_OPERAND_MAP[condition.right.type](condition.right.value, variables));
    }
    catch (error) {
        throw error;
    }
}
export async function resolveCondition(conditionString) {
    debug("play:resolve condition:executing condition...");
    try {
        const result = new Function(`return ${conditionString};`)();
        debug("play:condition result:%s", result);
        return result;
    }
    catch (error) {
        throw new InterpolationError(localeKeys.utils.interpolationError, "Interpolation error while trying to solve condition.", error);
    }
}
function create({ variableManager, variableNamespace }) {
    let _fullConditions = [], _play;
    const _state = SEQUENCER_ACTION_CONDITION_DEFAULT_STATE;
    function _fail(failure, resolve) {
        debug("fail:%o", failure);
        return resolve?.({
            payload: failure,
            status: SequencerActionStatusEnum.FAILED,
        });
    }
    function assertValidConfig(config) {
        debug("config validation:%o", config);
        if (!config.statements || config.statements.length < 1) {
            debug("config validation:error:less than 1 statements");
            throw new CoreActionConfigError(localeKeys.action.core.condition.validateNoStatement, "Config validation error: less than 1 statements.");
        }
        if (config.statements[0]?.statement !== ConditionStatementEnum.IF) {
            debug("config validation:error:1st statement is not an IF");
            throw new CoreActionConfigError(localeKeys.action.core.condition.validateInvalidFirstStatement, "Config validation error: 1st statement is not an IF.");
        }
        let statementIfCounter = 0;
        for (const [statementIndex, statement] of config.statements.entries()) {
            if (statement.statement === ConditionStatementEnum.IF) {
                statementIfCounter++;
                if (statementIfCounter > 1) {
                    debug("config validation:error:statement #%d:condition:more than 1 IF statement", statementIndex);
                    throw new CoreActionConfigError(localeKeys.action.core.condition.validateTooManyIfStatements, `Config validation error: statement #${statementIndex} has more than 1 IF statement.`);
                }
            }
            for (const [index, condition] of statement.conditions.entries()) {
                if (isConditionBasic(condition)) {
                    if (condition.left?.type === ConditionOperandTypeEnum.VARIABLE && !condition.left?.value?.trim()) {
                        debug("config validation:error:statement #%d:condition #%d:left operand:variable identifier is undefined", statementIndex, index);
                        throw new CoreActionConfigError(localeKeys.action.core.condition.validateUndefinedLeftOperandVariableIdentifier, `Config validation error: statement #${statementIndex}: condition #${index}: left operand variable identifier is undefined.`);
                    }
                    if (condition.right?.type === ConditionOperandTypeEnum.VARIABLE && !condition.right?.value?.trim()) {
                        debug("config validation:error:statement #%d:condition #%d:right operand:variable identifier is undefined", statementIndex, index);
                        throw new CoreActionConfigError(localeKeys.action.core.condition.validateUndefinedRightOperandVariableIdentifier, `Config validation error: statement #${statementIndex}: condition #${index}: right operand variable identifier is undefined.`);
                    }
                    if (index === 0 && condition.logical !== ConditionOperatorLogicalEnum.NA) {
                        debug("config validation:error:statement #%d:condition #%d:logical operator should be NA", statementIndex, index);
                        throw new CoreActionConfigError(localeKeys.action.core.condition.validateInvalidLogicalOperator, `Config validation error: statement #${statementIndex}: condition #${index}: logical operator should be NA.`);
                    }
                    if (index !== 0 &&
                        condition.logical !== ConditionOperatorLogicalEnum.AND &&
                        condition.logical !== ConditionOperatorLogicalEnum.OR) {
                        debug("Config validation:error:statement #%d:condition #%d:logical operator should be AND or OR", statementIndex, index);
                        throw new CoreActionConfigError(localeKeys.action.core.condition.validateInvalidLogicalOperator, `Config validation error: statement #${statementIndex}: condition #${index}: logical operator should be AND or OR.`);
                    }
                }
            }
        }
        debug("config validation:successful");
    }
    async function play(config) {
        debug("play:%o", config);
        if (isPromiseStarted(_play)) {
            debug("play:fail:action is already in progress");
            return {
                payload: {
                    action: {
                        state: {},
                    },
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.action.core.condition.playAlreadyInProgress,
                    source: FailureSourceEnum.ACTION,
                },
                status: SequencerActionStatusEnum.FAILED,
            };
        }
        _play = defer(async (resolve) => {
            let invalidCondition = false;
            _fullConditions = [];
            const interpolatedConfig = interpolateActionConfig(config, variableManager.getAllVars(variableNamespace));
            for (const [statementIndex, statement] of interpolatedConfig.statements.entries()) {
                _fullConditions.push("");
                for (const condition of statement.conditions) {
                    if (isConditionAdvanced(condition)) {
                        _fullConditions[statementIndex] = condition;
                    }
                    else {
                        try {
                            _fullConditions[statementIndex] += createConditionString(condition, variableManager.getAllVars(variableNamespace));
                        }
                        catch (error) {
                            return _fail({
                                action: {
                                    state: {},
                                },
                                code: FailureCodeEnum.CONFIGURATION_ERROR,
                                error,
                                key: error.key,
                                source: FailureSourceEnum.ACTION,
                            }, resolve);
                        }
                    }
                }
            }
            debug("play:full condition:", _fullConditions);
            for (const [index, statement] of _fullConditions.entries()) {
                debug("play:condition #%d:resolving condition...", index);
                const result = await resolveCondition(statement).catch((error) => {
                    invalidCondition = true;
                    _fail({
                        code: FailureCodeEnum.CONFIGURATION_ERROR,
                        action: {
                            state: SEQUENCER_ACTION_CONDITION_DEFAULT_STATE,
                        },
                        error,
                        key: error.key,
                        source: FailureSourceEnum.ACTION,
                    }, resolve);
                    return false;
                });
                if (invalidCondition) {
                    return void 0;
                }
                debug("play:condition #%d:result:%s", result);
                if (result) {
                    debug("play:completed:condition will play branch #%d", index);
                    return resolve({
                        payload: {
                            output: index,
                            type: SequencerActionPlayCompletedPayloadTypeEnum.BRANCH_INDEX,
                        },
                        status: SequencerActionStatusEnum.COMPLETED,
                    });
                }
            }
            debug("play:completed:no IF nor ELSE-IF were true, so branch to ELSE");
            if (config.failsOnElse) {
                debug("play:completed:fails on else");
                return _fail({
                    code: FailureCodeEnum.ACTION_COMPLETION_FAIL,
                    action: {
                        state: SEQUENCER_ACTION_CONDITION_DEFAULT_STATE,
                    },
                    key: localeKeys.action.core.condition.playFailsOnElse,
                    source: FailureSourceEnum.ACTION,
                }, resolve);
            }
            return resolve({
                payload: {
                    output: interpolatedConfig.statements.length,
                    type: SequencerActionPlayCompletedPayloadTypeEnum.BRANCH_INDEX,
                },
                status: SequencerActionStatusEnum.COMPLETED,
            });
        });
        return _play.promise;
    }
    async function stop(causeFailure) {
        debug("stop");
        if (causeFailure) {
            return _fail({
                code: FailureCodeEnum.STOP,
                action: {
                    state: SEQUENCER_ACTION_CONDITION_DEFAULT_STATE,
                },
                key: localeKeys.action.core.condition.stop,
                source: FailureSourceEnum.ACTION,
            });
        }
        return _play?.resolve({
            payload: null,
            status: SequencerActionStatusEnum.STOPPED,
        });
    }
    return {
        get state() {
            return deepClone(_state);
        },
        get type() {
            return ActionTypeEnum.CONDITION;
        },
        assertValidConfig,
        play,
        stop,
    };
}
export const SequencerActionConditionFactory = {
    create,
};
