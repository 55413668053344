import { theme } from "@aos/react-components";
import { IBomItem } from "@kortex/aos-common";
import { Tooltip, makeStyles } from "@material-ui/core";
import React, { FC, ReactElement } from "react";

import { BomItemInfoTable } from "../bomItemInfoTable";

const useStyles = makeStyles({
    tableContainer: {
        padding: "6px",
    },
    tooltip: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: "5px",
        minWidth: "500px",
    },
});

export interface IOwnProps {
    children: ReactElement;
    item: IBomItem;
}

const BomItemInfoTooltip: FC<IOwnProps> = (props) => {
    const { children, item } = props;
    const classes = useStyles();

    return (
        <Tooltip
            id="tooltipInfoItemId"
            classes={{ tooltip: classes.tooltip }}
            placement="left-end"
            title={
                <div className={classes.tableContainer}>
                    <BomItemInfoTable item={item} />
                </div>
            }
        >
            {children}
        </Tooltip>
    );
};

export default BomItemInfoTooltip;
