export function createBomFollowUpHistory(bomFollowUpHistory) {
    const { bomFollowUpHistoryId = 0, bomFollowUpId, bomFollowUpSymptomId = null, justification = "", previousValues = {}, quantity, traceability, type, userId, } = bomFollowUpHistory;
    return {
        bomFollowUpHistoryId,
        bomFollowUpId,
        bomFollowUpSymptomId,
        justification,
        previousValues,
        quantity,
        traceability,
        type,
        userId,
    };
}
