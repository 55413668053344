import { EventEmitter } from "events";

import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    UserGetTrainedUsersReq,
    UserGetTrainedUsersRes,
    UserAllReq,
    UserAllRes,
    UserInsertedNotif,
    UserInsertReq,
    UserInsertRes,
    UserLoginReq,
    UserLoginRes,
    UserOneReq,
    UserOneRes,
    UserPasswordExpiryStateReq,
    UserPasswordExpiryStateRes,
    UserPasswordUpdateReq,
    UserPasswordUpdateRes,
    UserUpdatedNotif,
    UserUpdateReq,
    UserUpdateRes,
    UserValidateReq,
    UserValidateRes,
} from "@kortex/aos-common";
import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";

import { AOSClientServiceWrapperUI } from "../helpers";

import { userAll } from "./all";
import { userInsert } from "./insert";
import { userInserted } from "./inserted";
import { userLogin } from "./login";
import { userOne } from "./one";
import { userPasswordExpiryState } from "./passwordExpiryState";
import { userPasswordUpdateByAdmin } from "./passwordUpdateByAdmin";
import { userPasswordUpdateByUser } from "./passwordUpdateByUser";
import { userUpdate } from "./update";
import { userUpdated } from "./updated";
import { userValidate } from "./validate";
import { trainingGetTrainedUsers } from "./getTrainedUsers";

interface IAOSClientServiceWrappedUser {
    getAll: AOSClientServiceRequestWrapped<UserAllReq, UserAllRes>;
    getOne: AOSClientServiceRequestWrapped<UserOneReq, UserOneRes>;
    getPasswordExpiryState: AOSClientServiceRequestWrapped<UserPasswordExpiryStateReq, UserPasswordExpiryStateRes>;
    insert: AOSClientServiceRequestWrapped<UserInsertReq, UserInsertRes>;
    login: AOSClientServiceRequestWrapped<UserLoginReq, UserLoginRes>;
    onInserted: AOSClientServiceNotificationWrapped<UserInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<UserUpdatedNotif>;
    passwordUpdateByAdmin: AOSClientServiceRequestWrapped<UserPasswordUpdateReq, UserPasswordUpdateRes>;
    passwordUpdateByUser: AOSClientServiceRequestWrapped<UserPasswordUpdateReq, UserPasswordUpdateRes>;
    update: AOSClientServiceRequestWrapped<UserUpdateReq, UserUpdateRes>;
    validate: AOSClientServiceRequestWrapped<UserValidateReq, UserValidateRes>;
    getTrainedUsers: AOSClientServiceRequestWrapped<UserGetTrainedUsersReq, UserGetTrainedUsersRes>;
}

type AOSClientServiceWrappedUser = IAOSClientServiceWrappedUser;

// ADD_API_CALL User
export function generateUserServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedUser {
    return {
        getAll: AOSClientServiceWrapperUI.request(userAll(router)),
        getOne: AOSClientServiceWrapperUI.request(userOne(router)),
        getPasswordExpiryState: AOSClientServiceWrapperUI.request(userPasswordExpiryState(router)),
        insert: AOSClientServiceWrapperUI.request(userInsert(router)),
        login: AOSClientServiceWrapperUI.request(userLogin(router)), // save token to client
        onInserted: AOSClientServiceWrapperUI.notification(userInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(userUpdated(router, notifier)),
        passwordUpdateByAdmin: AOSClientServiceWrapperUI.request(userPasswordUpdateByAdmin(router)),
        passwordUpdateByUser: AOSClientServiceWrapperUI.request(userPasswordUpdateByUser(router)),
        update: AOSClientServiceWrapperUI.request(userUpdate(router)),
        validate: AOSClientServiceWrapperUI.request(userValidate(router)),
        getTrainedUsers: AOSClientServiceWrapperUI.request(trainingGetTrainedUsers(router)),
    };
}

generateUserServiceClient.serviceId = 20;
