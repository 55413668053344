export {};
// ---------------------------------------------------------------------------------------------------- //
// Uncomment those types when we want to send notifications to a single player at a time
// Find and replace all the notif types from PROCESS PLAYER in AOSPayload (aos-common/src/types/AOSPayload.ts)
// ---> ex: export type ProcessPlayerRunnerStateUpdatedNotif = AOSPayload<RunnerPlayerNotificationStateUpdated>;
/*
export type RunnerPlayerNotification = IRunnerPlayerNotification<Kinova.Api.IError>; // At the moment, we only send errors, but it could be anything
export type RunnerPlayerNotificationStateUpdated = IRunnerPlayerNotification<IPlayerProcessAction>;
export type RunnerPlayerNotificationDashboardInfoUpdated = IRunnerPlayerNotification<IDashboardInfo>;
export type RunnerPlayerNotificationCanRunAgainUpdated = IRunnerPlayerNotification<ISyncerState>;
*/
