import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    UserGroupAllReq,
    UserGroupAllRes,
    UserGroupInsertReq,
    UserGroupInsertRes,
    UserGroupInsertedNotif,
    UserGroupUpdateReq,
    UserGroupUpdateRes,
    UserGroupUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { userGroupAll } from "./all";
import { userGroupInsert } from "./insert";
import { userGroupInserted } from "./inserted";
import { userGroupUpdate } from "./update";
import { userGroupUpdated } from "./updated";

interface IAOSClientServiceWrappedUserGroups {
    getAll: AOSClientServiceRequestWrapped<UserGroupAllReq, UserGroupAllRes>;
    insert: AOSClientServiceRequestWrapped<UserGroupInsertReq, UserGroupInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<UserGroupInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<UserGroupUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<UserGroupUpdateReq, UserGroupUpdateRes>;
}

type AOSClientServiceWrappedUserGroups = IAOSClientServiceWrappedUserGroups;

// ADD_API_CALL UserGroup
export function generateUserGroupServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedUserGroups {
    return {
        getAll: AOSClientServiceWrapperUI.request(userGroupAll(router)),
        insert: AOSClientServiceWrapperUI.request(userGroupInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(userGroupInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(userGroupUpdated(router, notifier)),
        update: AOSClientServiceWrapperUI.request(userGroupUpdate(router)),
    };
}

generateUserGroupServiceClient.serviceId = 22;
