import { KortexTextField } from "@aos/react-components";
import { ComparisonOperator, ConditionBaseConfig, ConditionType, ProcessEditorRightsEnum, ProcessVariableType } from "@kortex/aos-common";
import { MenuItem, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import VariablePicker from "../../../../../core/VariablePicker/VariablePicker";

const useStyles = makeStyles({
    datePicker: {
        padding: "5px",
    },
    tableCellComparison: {
        width: "150px",
    },
    tableCellOperand: {
        width: "350px",
    },
    tableCellRightOperandDate: {
        paddingBottom: "18px",
        width: "350px",
    },
    tableCellType: {
        width: "100px",
    },
    textField: {
        width: "100%",
    },
});

export interface IOwnProps {
    condition: ConditionBaseConfig;
    onChange: (condition: ConditionBaseConfig) => void;
    userAccessLevel?: ProcessEditorRightsEnum;
}

export default function ConditionRowSimplified(props: IOwnProps): JSX.Element {
    const { condition, onChange, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const numberComparisonStyle =
        condition.simplified.type === ConditionType.NUMBER || condition.simplified.type === ConditionType.DATE ? {} : { display: "none" };
    const rightOperandTableCellStyle =
        condition.simplified.type === ConditionType.DATE ? classes.tableCellRightOperandDate : classes.tableCellOperand;
    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Get corresponding process variable type based on condition type
     */
    const getVariableTypeFromConditionType = (): ProcessVariableType => {
        switch (condition.simplified.type) {
            case ConditionType.BOOLEAN:
                return ProcessVariableType.BOOLEAN;
            case ConditionType.JSON:
                return ProcessVariableType.JSON;
            case ConditionType.NUMBER:
                return ProcessVariableType.NUMBER;
            case ConditionType.DATE:
            case ConditionType.STRING:
                return ProcessVariableType.STRING;
        }
    };

    /**
     * Handles right operand value change
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - updated right operand value
     */
    const handleComparisonOperatorChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChange({
            ...condition,
            simplified: {
                ...condition.simplified,
                comparisonOperator: event.target.value as ComparisonOperator,
            },
        });
    };

    /**
     * Handles left operand value change
     *
     * @param {string} value - updated left operand value
     */
    const handleLeftOperandChange = (value: string): void => {
        onChange({
            ...condition,
            simplified: {
                ...condition.simplified,
                leftOperand: value,
            },
        });
    };

    /**
     * Handles right operand value change
     *
     * @param {string} value - updated right operand value
     */
    const handleRightOperandChange = (value: string): void => {
        onChange({
            ...condition,
            simplified: {
                ...condition.simplified,
                rightOperand: value,
            },
        });
    };

    /**
     * Handles type change
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - updated right operand value
     */
    const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        let comparisonOperator = condition.simplified.comparisonOperator;

        if (
            condition.simplified.type === ProcessVariableType.NUMBER &&
            event.target.value !== ProcessVariableType.NUMBER &&
            condition.simplified.comparisonOperator !== ComparisonOperator.EQUALS &&
            condition.simplified.comparisonOperator !== ComparisonOperator.NOT_EQUAL
        ) {
            comparisonOperator = ComparisonOperator.EQUALS;
        }

        onChange({
            ...condition,
            simplified: {
                ...condition.simplified,
                comparisonOperator,
                type: event.target.value as ConditionType,
            },
        });
    };

    const variableTypeFilter = getVariableTypeFromConditionType();

    return (
        <React.Fragment>
            {/* TYPE */}
            <TableCell className={classes.tableCellType}>
                <KortexTextField
                    className={classes.textField}
                    label={translate("action.condition.simplified.type")}
                    onChange={handleTypeChange}
                    TextFieldProps={{
                        disabled: readOnly,
                        select: true,
                    }}
                    value={condition.simplified.type}
                    variant="standard"
                >
                    <MenuItem value={ConditionType.STRING}>{translate("action.condition.simplified.type.text")}</MenuItem>
                    <MenuItem value={ConditionType.NUMBER}>{translate("action.condition.simplified.type.numeric")}</MenuItem>
                    <MenuItem value={ConditionType.DATE}>{translate("action.condition.simplified.type.date")}</MenuItem>
                </KortexTextField>
            </TableCell>
            {/* LEFT OPERAND */}
            <TableCell className={classes.tableCellOperand}>
                <VariablePicker
                    KortexTextFieldProps={{
                        className: classes.textField,
                        label: translate("action.condition.simplified.leftOperand"),
                        variant: "standard",
                    }}
                    onChange={handleLeftOperandChange}
                    userAccessLevel={userAccessLevel}
                    value={condition.simplified.leftOperand}
                    variableTypeFilter={variableTypeFilter}
                />
            </TableCell>
            {/* COMPARISON OPERATOR */}
            <TableCell className={classes.tableCellComparison}>
                <KortexTextField
                    className={classes.textField}
                    label={translate("action.condition.simplified.comparison")}
                    onChange={handleComparisonOperatorChange}
                    TextFieldProps={{
                        disabled: readOnly,
                        select: true,
                    }}
                    value={condition.simplified.comparisonOperator}
                    variant="standard"
                >
                    <MenuItem value={ComparisonOperator.EQUALS}>{translate("action.condition.simplified.comparison.equals")}</MenuItem>
                    <MenuItem value={ComparisonOperator.NOT_EQUAL}>{translate("action.condition.simplified.comparison.notEqual")}</MenuItem>
                    <MenuItem style={numberComparisonStyle} value={ComparisonOperator.GREATER_THAN}>
                        {translate("action.condition.simplified.comparison.greaterThan")}
                    </MenuItem>
                    <MenuItem style={numberComparisonStyle} value={ComparisonOperator.LESS_THAN}>
                        {translate("action.condition.simplified.comparison.lessThan")}
                    </MenuItem>
                    <MenuItem style={numberComparisonStyle} value={ComparisonOperator.GREATER_THAN_OR_EQUAL_TO}>
                        {translate("action.condition.simplified.comparison.greaterThanOrEqualTo")}
                    </MenuItem>
                    <MenuItem style={numberComparisonStyle} value={ComparisonOperator.LESS_THAN_OR_EQUAL_TO}>
                        {translate("action.condition.simplified.comparison.lessThanOrEqualTo")}
                    </MenuItem>
                </KortexTextField>
            </TableCell>
            {/* RIGHT OPERAND */}
            <TableCell className={rightOperandTableCellStyle}>
                <VariablePicker
                    KortexTextFieldProps={{
                        className: classes.textField,
                        label: translate("action.condition.simplified.rightOperand"),
                        variant: "standard",
                    }}
                    onChange={handleRightOperandChange}
                    userAccessLevel={userAccessLevel}
                    value={condition.simplified.rightOperand}
                    variableTypeFilter={variableTypeFilter}
                />
            </TableCell>
        </React.Fragment>
    );
}
