import { ErpSettingsActionType, SetErpSettingsListAction } from "./erp-settings-types";

/**
 * Returns an action
 *
 * @param {SetErpSettingsListAction} erpSettings - value of the action
 */
export function setErpSettingsListAction(erpSettings: SetErpSettingsListAction["value"]): SetErpSettingsListAction {
    return { type: ErpSettingsActionType.ERP_SETTINGS_FETCH, value: erpSettings };
}
