import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceRequestWrapped,
    ReportTagGetColumnListReq,
    ReportTagGetColumnListRes,
    ReportTagGetEntryListReq,
    ReportTagGetEntryListRes,
    ReportTagGetEntryViewListReq,
    ReportTagGetEntryViewListRes,
    ReportTagGetValueListReq,
    ReportTagGetValueListRes,
    ReportTagUpsertColumnReq,
    ReportTagUpsertColumnRes,
    ReportTagUpsertEntryReq,
    ReportTagUpsertEntryRes,
    ReportTagUpsertValueReq,
    ReportTagUpsertValueRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { reportTagGetEntryList } from "./getEntryList";
import { reportTagUpsertEntry } from "./upsertEntry";
import { reportTagGetEntryViewList } from "./getEntryViewList";
import { reportTagGetColumnList } from "./getColumnList";
import { reportTagUpsertColumn } from "./upsertColumn";
import { reportTagGetValueList } from "./getValueList";
import { reportTagUpsertValue } from "./upsertValue";

interface IAOSClientServiceWrappedReportTag {
    getColumnList: AOSClientServiceRequestWrapped<ReportTagGetColumnListReq, ReportTagGetColumnListRes>;
    getEntryList: AOSClientServiceRequestWrapped<ReportTagGetEntryListReq, ReportTagGetEntryListRes>;
    getEntryViewList: AOSClientServiceRequestWrapped<ReportTagGetEntryViewListReq, ReportTagGetEntryViewListRes>;
    getValueList: AOSClientServiceRequestWrapped<ReportTagGetValueListReq, ReportTagGetValueListRes>;
    upsertColumn: AOSClientServiceRequestWrapped<ReportTagUpsertColumnReq, ReportTagUpsertColumnRes>;
    upsertEntry: AOSClientServiceRequestWrapped<ReportTagUpsertEntryReq, ReportTagUpsertEntryRes>;
    upsertValue: AOSClientServiceRequestWrapped<ReportTagUpsertValueReq, ReportTagUpsertValueRes>;
}

type AOSClientServiceWrappedReportTag = IAOSClientServiceWrappedReportTag;

// ADD_API_CALL Report
export function generateReportTagServiceClient(router: IRouterClient): AOSClientServiceWrappedReportTag {
    return {
        getColumnList: AOSClientServiceWrapperUI.request(reportTagGetColumnList(router)),
        getEntryList: AOSClientServiceWrapperUI.request(reportTagGetEntryList(router)),
        getEntryViewList: AOSClientServiceWrapperUI.request(reportTagGetEntryViewList(router)),
        getValueList: AOSClientServiceWrapperUI.request(reportTagGetValueList(router)),
        upsertColumn: AOSClientServiceWrapperUI.request(reportTagUpsertColumn(router)),
        upsertEntry: AOSClientServiceWrapperUI.request(reportTagUpsertEntry(router)),
        upsertValue: AOSClientServiceWrapperUI.request(reportTagUpsertValue(router)),
    };
}

generateReportTagServiceClient.serviceId = 41;
