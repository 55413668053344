import { ClientUpdateTypeEnum, FailureCodeEnum, FailureSourceEnum, KhronoEventEnum, KhronoHookSourceEnum, KhronoSnapshotCallbackSourceEnum, SequencerActionGroupPlayCompletedPayloadTypeEnum, SequencerActionGroupStatusEnum, SequencerActionPlayCompletedPayloadTypeEnum, SequencerActionStatusEnum, } from "../enums";
import { ActionGroupHookOnFailCircularError, } from "../interfaces";
import { Khrono } from "../khrono";
import { localeKeys } from "../locales";
import { SequencerAction } from "../sequencer-action";
import { KortexSequencerDebugger, defer, isActionGroupCompleted, isActionGroupIdle, isActionGroupPlaying, isActionGroupStopping, isActionIdle, isActionTypeSubProgram, waitAfter, } from "../utilities";
const debug = KortexSequencerDebugger.actionGroup;
export const SequencerActionGroup = {
    create: (params) => {
        debug("new action group created:%o", params);
        const { actions, actionGroupConfig, context, delayAfterHookMs, hooks = {}, subProgramManager, variableNamespace, variableManager, } = params;
        let _state = {
            actionIndex: 0,
        }, _status = SequencerActionGroupStatusEnum.IDLE;
        let _initSubProgramActions = () => void 0;
        let _stateChanged = async (data) => {
            debug("%s:state changed:%o", actionGroupConfig.type, data);
            return _runSequencerActionGroupHook("onStateChange", data);
        };
        let _statusChanged = async (payload) => {
            debug("%s:status changed:%o", actionGroupConfig.type, payload);
        };
        let _deferNextAction = null;
        let variablesSnapshot = [];
        const _actions = actions.map((action) => SequencerAction.create({
            ...action,
            context,
            delayAfterHookMs,
            type: actionGroupConfig.type,
            variableManager,
            variableNamespace: action.variableNamespace ?? variableNamespace,
        }));
        async function _complete(data) {
            debug("%s:completed:%o", actionGroupConfig.type, data);
            const actionGroupCompletedPayload = _createActionGroupCompletedPayload(data);
            return _updateStatus({ status: SequencerActionGroupStatusEnum.COMPLETED, payload: actionGroupCompletedPayload });
        }
        function _createActionGroupCompletedPayload(payload) {
            return _isCompletedActionBranching(payload)
                ? {
                    result: payload.output,
                    type: SequencerActionGroupPlayCompletedPayloadTypeEnum.BRANCH_INDEX,
                }
                : {
                    result: null,
                    type: SequencerActionGroupPlayCompletedPayloadTypeEnum.NEXT,
                };
        }
        async function _fail(failure) {
            debug("%s:fail:%o", actionGroupConfig.type, failure);
            _deferNextAction?.reject?.();
            Khrono.createEvent({
                payload: failure,
                type: KhronoEventEnum.ACTION_GROUP_FAILURE,
            });
            return _updateStatus({ status: SequencerActionGroupStatusEnum.FAILED, payload: failure });
        }
        function _getCurrentAction() {
            return _actions[_state.actionIndex];
        }
        async function _handleActionStatusChange(payload) {
            debug("%s:action status changed:%o", actionGroupConfig.type, payload);
            switch (payload.status) {
                case SequencerActionStatusEnum.COMPLETED:
                    return _handleActionCompleted(payload.payload);
                case SequencerActionStatusEnum.FAILED:
                    if (isActionGroupPlaying(_status) && payload.payload.action.config.actionConfig.continueIfFail) {
                        debug("%s:action status changed:fail:continue if fail:%o", payload.payload.action.config.actionConfig.continueIfFail);
                        return _handleActionCompleted(payload.payload.action.config.actionConfig.continueIfFail);
                    }
                    if (_getCurrentAction()?.config.waitAfterFailed && payload.payload.code !== FailureCodeEnum.STOP) {
                        debug("%s:action status changed:fail:wait after failed");
                        let stopAfterWait = false;
                        _deferNextAction = defer(() => _runSequencerActionGroupHook("onActionWaiting", {
                            status: SequencerActionStatusEnum.FAILED,
                        }));
                        await _deferNextAction.promise
                            .catch(() => {
                            stopAfterWait = true;
                        })
                            .finally(() => {
                            _deferNextAction = null;
                        });
                        if (stopAfterWait) {
                            debug("%s:action status changed:fail:wait after failed:reject");
                            return void 0;
                        }
                        debug("%s:action status changed:fail:wait after failed:resolved");
                    }
                    return _fail({
                        ...payload.payload,
                        actionGroup: {
                            config: params,
                            state: _state,
                        },
                    });
                case SequencerActionStatusEnum.STOPPED:
                    return _updateStatus({ status: SequencerActionGroupStatusEnum.STOPPED, payload: null });
            }
        }
        async function _handleActionCompletedAfterWait(payload) {
            const next = {
                actionGroupId: -1,
                actionIndex: 0,
            };
            if (_isCompletedActionBranching(payload)) {
                next.actionGroupId = actionGroupConfig.branches[payload.output];
                next.actionIndex = 0;
            }
            else if (_actions[_state.actionIndex + 1]) {
                next.actionGroupId = actionGroupConfig.actionGroupId;
                next.actionIndex = _state.actionIndex + 1;
            }
            else {
                next.actionGroupId = actionGroupConfig.next;
                next.actionIndex = 0;
            }
            Khrono.snapshotAction(_state.actionIndex, _getCurrentAction().core.state, _getCurrentAction().status, variableNamespace, variableManager.getAllVars, next);
            return _runSequencerActionGroupHook("onActionCompleted", payload);
        }
        async function _handleActionCompleted(payload) {
            debug("%s:action completed:%o", actionGroupConfig.type, payload);
            if (isActionGroupStopping(_status)) {
                debug("%s:action completed:action group is stopped");
                return _updateStatus({
                    payload: null,
                    status: SequencerActionGroupStatusEnum.STOPPED,
                });
            }
            if (_getCurrentAction()?.config.waitAfterCompleted) {
                debug("%s:action completed:wait after completed");
                let stopAfterWait = false;
                _deferNextAction = defer(() => _runSequencerActionGroupHook("onActionWaiting", {
                    status: SequencerActionStatusEnum.COMPLETED,
                }));
                await _deferNextAction.promise
                    .catch(() => {
                    stopAfterWait = true;
                })
                    .finally(() => {
                    _deferNextAction = null;
                    return _handleActionCompletedAfterWait(payload);
                });
                if (stopAfterWait) {
                    debug("%s:action completed:wait after completed:rejected");
                    return void 0;
                }
                debug("%s:action completed:wait after completed:resolved");
            }
            else {
                await _handleActionCompletedAfterWait(payload);
            }
            if (_isCompletedActionBranching(payload)) {
                debug("%s:action completed:action is branching", actionGroupConfig.type);
                return _complete(payload);
            }
            return _incrementActionIndex().then(() => {
                if (_getCurrentAction()) {
                    if (isActionGroupPlaying(_status)) {
                        debug("%s:action completed:play next action", actionGroupConfig.type);
                        return _playAction();
                    }
                    debug("%s:action completed:not playing next action because action group is not playing:%s", _status);
                    return void 0;
                }
                else {
                    debug("%s:action completed:action group completed", actionGroupConfig.type);
                    return _complete(payload);
                }
            });
        }
        function _incrementActionIndex() {
            _state.actionIndex++;
            return _stateChanged(_state);
        }
        function _isCompletedActionBranching(payload) {
            return payload.type === SequencerActionPlayCompletedPayloadTypeEnum.BRANCH_INDEX;
        }
        async function _playAction() {
            debug("%s:play action:group action id %d:action index %d", actionGroupConfig.type, actionGroupConfig.actionGroupId, _state.actionIndex);
            _getCurrentAction().onStatusChange(_handleActionStatusChange);
            if (_getCurrentAction().config.retryable) {
                variablesSnapshot = variableManager.getAllVars(variableNamespace);
            }
            return _getCurrentAction().play();
        }
        function _resetState() {
            _state.actionIndex = 0;
        }
        function _runSequencerActionGroupHook(key, payload) {
            debug("%s:run hook:%o", actionGroupConfig.type, { key, payload });
            let initPromise = Promise.resolve();
            if (hooks[key]) {
                debug("%s:run hook:%s is defined:executing hook...", actionGroupConfig.type, key);
                initPromise = initPromise.then(() => waitAfter(Khrono.createHookEvent({
                    hook: key,
                    source: KhronoHookSourceEnum.ACTION_GROUP,
                }, hooks[key], undefined, payload), delayAfterHookMs));
                initPromise.catch((error) => {
                    if (key === "onFail") {
                        debug("%s:run hook:error:%s not implemented, so throwing an error", actionGroupConfig.type, key);
                        throw new ActionGroupHookOnFailCircularError({
                            actionGroup: {
                                config: params,
                                state: _state,
                            },
                            code: FailureCodeEnum.CRITICAL,
                            error,
                            key: localeKeys.actionGroup.hookErrorOnFail,
                            source: FailureSourceEnum.ACTION_GROUP,
                        });
                    }
                    debug("%s:run hook:fail:%s failed (error thrown or promised rejected)", actionGroupConfig.type, key);
                    return _fail({
                        actionGroup: {
                            config: params,
                            state: _state,
                        },
                        code: FailureCodeEnum.IMPLEMENTER_HOOK_ERROR,
                        error,
                        key: localeKeys.actionGroup.hookError,
                        source: FailureSourceEnum.ACTION_GROUP,
                    });
                });
            }
            return initPromise;
        }
        async function _updateStatus(payload, hook) {
            debug("%s:status update:%o", actionGroupConfig.type, payload);
            if (payload.status === _status) {
                return void 0;
            }
            _status = payload.status;
            debug("%s:status update:status is different, updating...", actionGroupConfig.type);
            let defaultHook = hook;
            if (!defaultHook) {
                switch (payload.status) {
                    case SequencerActionGroupStatusEnum.COMPLETED:
                        defaultHook = "onComplete";
                        break;
                    case SequencerActionGroupStatusEnum.FAILED:
                        defaultHook = "onFail";
                        break;
                    case SequencerActionGroupStatusEnum.PAUSED:
                        defaultHook = "onPause";
                        break;
                    case SequencerActionGroupStatusEnum.PLAYING:
                        defaultHook = "onPlay";
                        break;
                    case SequencerActionGroupStatusEnum.STOPPING:
                        defaultHook = "onStop";
                        break;
                    case SequencerActionGroupStatusEnum.STOPPED:
                        defaultHook = "onStopped";
                        break;
                }
            }
            return (defaultHook ? _runSequencerActionGroupHook(defaultHook, payload?.payload) : Promise.resolve()).then(() => _runSequencerActionGroupHook("onStatusChange", payload).then(() => _statusChanged(payload)));
        }
        async function clientUpdate(payload) {
            debug("%s:client update", actionGroupConfig.type);
            if (payload.type === ClientUpdateTypeEnum.RETRY_ACTION) {
                debug("client update:retry");
                if (_status === SequencerActionGroupStatusEnum.FAILED) {
                    await _updateStatus({
                        status: SequencerActionGroupStatusEnum.PLAYING,
                        payload: {
                            resetState: false,
                        },
                    });
                }
                if (_getCurrentAction()?.status === SequencerActionStatusEnum.FAILED) {
                    debug("client update:retry:revert all variable values");
                    await Promise.all(variablesSnapshot.map((variable) => variableManager.setVar(variableNamespace, variable)));
                    debug("client update:retry:reset the failed action");
                    const clone = SequencerAction.create({
                        ...actions[_state.actionIndex],
                        context,
                        delayAfterHookMs,
                        type: actionGroupConfig.type,
                        variableManager,
                        variableNamespace: actions[_state.actionIndex].variableNamespace ?? variableNamespace,
                    });
                    _actions.splice(_state.actionIndex, 1, clone);
                    return _updateStatus({
                        status: SequencerActionGroupStatusEnum.PLAYING,
                        payload: {
                            resetState: false,
                        },
                    }).then(() => {
                        Khrono.onSnapshot(KhronoSnapshotCallbackSourceEnum.ACTION_GROUP, () => actionGroupConfig.actionGroupId);
                        return _playAction();
                    });
                }
                debug("client update:retry:fail:current action status is not FAILED");
                return _fail({
                    code: FailureCodeEnum.CONFIGURATION_ERROR,
                    key: localeKeys.actionGroup.flowError.clientUpdate.retryCurrentActionNotFailed,
                    actionGroup: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.ACTION_GROUP,
                });
            }
            else if (payload.type === ClientUpdateTypeEnum.PLAY_NEXT) {
                debug("client update:play next");
                if (_deferNextAction?.resolve) {
                    return _deferNextAction.resolve();
                }
                debug("client update:play next:fail:play next received while no action is waiting");
                return _fail({
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.actionGroup.flowError.clientUpdate.noWaitingAction,
                    actionGroup: {
                        config: params,
                        state: _state,
                    },
                    source: FailureSourceEnum.ACTION_GROUP,
                });
            }
            return _getCurrentAction().clientUpdate(payload);
        }
        function isPausable() {
            return _actions.length ? _actions[0].isPausable() : false;
        }
        function onPlaySubProgram(cb) {
            _initSubProgramActions = cb;
        }
        function onStateChange(cb) {
            _stateChanged = async (data) => {
                debug("%s:state changed:%o", actionGroupConfig.type, _state);
                return _runSequencerActionGroupHook("onStateChange", data).then(() => cb(data));
            };
        }
        function onStatusChange(cb) {
            _statusChanged = async (payload) => {
                debug("%s:status changed:%s:%o", actionGroupConfig.type, SequencerActionGroupStatusEnum[payload.status], payload);
                return cb(payload);
            };
        }
        async function pause() {
            debug("%s:pause", actionGroupConfig.type);
            if (isActionGroupPlaying(_status)) {
                return _updateStatus({ status: SequencerActionGroupStatusEnum.PAUSED, payload: null }).then(() => {
                    if (_deferNextAction) {
                        return _deferNextAction.reject();
                    }
                    return _getCurrentAction().pause();
                });
            }
            else {
                debug("%s:pause:fail:action group is not playing", actionGroupConfig.type);
            }
        }
        async function play(resetState = true) {
            debug("%s:play", actionGroupConfig.type);
            if (isActionGroupIdle(_status) || isActionGroupCompleted(_status)) {
                if (resetState) {
                    _resetState();
                }
                return _updateStatus({
                    status: SequencerActionGroupStatusEnum.PLAYING,
                    payload: { resetState },
                }).then(() => {
                    if (_actions.length) {
                        Khrono.onSnapshot(KhronoSnapshotCallbackSourceEnum.ACTION_GROUP, () => actionGroupConfig.actionGroupId);
                        if (isActionTypeSubProgram(actionGroupConfig.type) && !_actions[_state.actionIndex].core.state.playing) {
                            for (let i = 0; i < _actions.length; i++) {
                                actionGroupConfig.branches = new Array(_actions.length);
                                const inputActionGroupId = subProgramManager.getInput(context);
                                if (inputActionGroupId !== undefined) {
                                    actionGroupConfig.branches[i] = inputActionGroupId;
                                }
                                else {
                                    return _fail({
                                        actionGroup: {
                                            config: params,
                                            state: _state,
                                        },
                                        code: FailureCodeEnum.CONFIGURATION_ERROR,
                                        key: localeKeys.actionGroup.undefinedSubProgram,
                                        source: FailureSourceEnum.ACTION_GROUP,
                                    });
                                }
                            }
                            _initSubProgramActions(actionGroupConfig.branches);
                        }
                        return _playAction();
                    }
                    else {
                        debug("%s:play:fail:there is no action to play", actionGroupConfig.type);
                        return _fail({
                            actionGroup: {
                                config: params,
                                state: _state,
                            },
                            code: FailureCodeEnum.CONFIGURATION_ERROR,
                            key: localeKeys.actionGroup.noAction,
                            source: FailureSourceEnum.ACTION_GROUP,
                        });
                    }
                });
            }
            else {
                debug("%s:play:fail:action group is not idle", actionGroupConfig.type);
            }
        }
        async function resume() {
            debug("%s:resume", actionGroupConfig.type);
            if (_status === SequencerActionGroupStatusEnum.PAUSED) {
                return _updateStatus({
                    status: SequencerActionGroupStatusEnum.PLAYING,
                    payload: {
                        resetState: false,
                    },
                }, "onResume").then(() => {
                    if (isActionIdle(_getCurrentAction().status)) {
                        return _playAction();
                    }
                    return _getCurrentAction().resume();
                });
            }
            else {
                debug("%s:resume:fail:action group is not paused", actionGroupConfig.type);
            }
        }
        async function stop(causeFailure = false) {
            debug("%s:stop", actionGroupConfig.type);
            if ([SequencerActionGroupStatusEnum.PLAYING, SequencerActionGroupStatusEnum.PAUSED].includes(_status)) {
                _deferNextAction?.reject();
                return _runSequencerActionGroupHook("onStop", null).then(() => {
                    const currentAction = _getCurrentAction();
                    if ([SequencerActionStatusEnum.PAUSED, SequencerActionStatusEnum.PLAYING].includes(currentAction.status)) {
                        return currentAction.stop(causeFailure);
                    }
                    debug("%s:stop:fail:current action is not active");
                    return causeFailure
                        ? _fail({
                            actionGroup: {
                                config: params,
                                state: _state,
                            },
                            code: FailureCodeEnum.STOP,
                            key: localeKeys.actionGroup.stop,
                            source: FailureSourceEnum.ACTION_GROUP,
                        })
                        : _updateStatus({ status: SequencerActionGroupStatusEnum.STOPPED, payload: null });
                });
            }
            else {
                debug("%s:stop:fail:action group is not in progress", actionGroupConfig.type);
                return _fail({
                    actionGroup: {
                        config: params,
                        state: _state,
                    },
                    code: FailureCodeEnum.STOP,
                    key: localeKeys.actionGroup.stop,
                    source: FailureSourceEnum.ACTION_GROUP,
                });
            }
        }
        return {
            get actions() {
                return _actions;
            },
            get config() {
                return actionGroupConfig;
            },
            get context() {
                return context;
            },
            get state() {
                return _state;
            },
            set state(state) {
                _state = state;
            },
            get status() {
                return _status;
            },
            set status(status) {
                _status = status;
            },
            clientUpdate,
            isPausable,
            onPlaySubProgram,
            onStateChange,
            onStatusChange,
            pause,
            play,
            resume,
            stop,
        };
    },
};
