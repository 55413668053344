import Debug from "debug";
import { config } from "dotenv";
import React from "react";
import { createRoot } from "react-dom/client";
import { pdfjs } from "react-pdf";
import { Provider } from "react-redux";

import App from "./components/App";
import { store } from "./redux/store";

// Font CSS
import "@fontsource/exo-2/300.css"; // Exo 2 - Light
import "@fontsource/exo-2/400.css"; // Exo 2 - Normal
import "@fontsource/exo-2/500.css"; // Exo 2 - Medium
import "@fontsource/exo-2/600.css"; // Exo 2 - Semi-bold
import "@fontsource/exo-2/700.css"; // Exo 2 - Bold
import "@fontsource/roboto/300.css"; // Roboto - Light
import "@fontsource/roboto/400.css"; // Roboto - Normal
import "@fontsource/roboto/500.css"; // Roboto - Medium
import "@fontsource/roboto/700.css"; // Roboto - Bold

config();

Debug.enable("kortex:*");

pdfjs.GlobalWorkerOptions.workerSrc = new URL("npm:pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

createRoot(document.getElementById("root")!).render(
    <Provider store={store}>
        <App />
    </Provider>
);
