import { AOSError } from "../aosError";
export class ErpError extends AOSError {
    //---------------------------------------------------------
    // Private
    //---------------------------------------------------------
    //---------------------------------------------------------
    // Public
    //---------------------------------------------------------
    constructor(description, originalError, argument) {
        super(description, originalError, argument);
    }
    get name() {
        return "ErpError";
    }
    static throw(error, message, functionName, argument) {
        throw new ErpError({
            explanation: ErpError.ExplanationKeyEnum.ERP_QUERY_FAILED,
            level: ErpError.MessageLevelEnum.ERROR,
            message: message,
            path: functionName,
            suggestion: ErpError.SuggestionKeyEnum.CONTACT_AOS_DEV_TEAM,
            title: ErpError.TitleKeyEnum.ERP_ERROR,
        }, error, argument);
    }
}
