import { greyPalette, theme } from "@aos/react-components";
import { EnumElementType, ProcessEditorRightsEnum } from "@kortex/aos-common";
import { List, ListItem, Paper, makeStyles } from "@material-ui/core";
import LabelIcon from "@material-ui/icons/Label";
import MovieIcon from "@material-ui/icons/Movie";
import PhotoIcon from "@material-ui/icons/Photo";
import PictureAsPdfRoundedIcon from "@material-ui/icons/PictureAsPdfRounded";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import LineIcon from "@material-ui/icons/TrendingFlat";
import * as React from "react";

import { useTranslate } from "../../../../../../../../hooks/useTranslate";
import { IUserRightsProps, userCanWrite } from "../../../../../../../../utilitites/IUserRights";
import FormIcon from "../../../../../../../core/Icons/Form/Form";
import ShapesIcon from "../../../../../../../core/Icons/Shapes/Shapes";
import KortexLabelIcon from "../../../../../../../core/KortexLabelIcon";

const useStyles = makeStyles({
    horizontalList: {
        display: "inline-flex",
        flexDirection: "row",
        padding: "0px",
    },
    listItem: {
        width: "61px",
        color: greyPalette[600],
        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    className?: string;
    hidden?: boolean;
    onClick: (type: EnumElementType) => void;
    style?: React.CSSProperties;
}

export default function WorkInstructionsElementsToolbar(props: IOwnProps): JSX.Element {
    const { className, hidden, onClick } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const readOnly = !userCanWrite(props.userAccessLevel);

    /**
     * Called when an option from the toolbar is clicked
     *
     * @param {EnumElementType} type - clicked element type
     */
    const handleClick =
        (type: EnumElementType): ((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) =>
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
            onClick(type);

            // blur to make sure button can't be pressed again using Enter key
            e.currentTarget.blur();
        };

    return (
        <Paper className={className} hidden={hidden}>
            <List className={classes.horizontalList}>
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarImage"
                    onClick={handleClick(EnumElementType.IMAGE)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.image")}>
                        <PhotoIcon />
                    </KortexLabelIcon>
                </ListItem>
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarVideo"
                    onClick={handleClick(EnumElementType.VIDEO)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.video")}>
                        <MovieIcon />
                    </KortexLabelIcon>
                </ListItem>
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarPDF"
                    onClick={handleClick(EnumElementType.PDF)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.pdf")}>
                        <PictureAsPdfRoundedIcon />
                    </KortexLabelIcon>
                </ListItem>
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarText"
                    onClick={handleClick(EnumElementType.TEXT)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.text")}>
                        <TextFormatIcon />
                    </KortexLabelIcon>
                </ListItem>
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarLine"
                    onClick={handleClick(EnumElementType.LINE)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.line")}>
                        <LineIcon />
                    </KortexLabelIcon>
                </ListItem>
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarShape"
                    onClick={handleClick(EnumElementType.SHAPE)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.shape")}>
                        <ShapesIcon />
                    </KortexLabelIcon>
                </ListItem>
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarMarker"
                    onClick={handleClick(EnumElementType.MARKER)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.marker")}>
                        <LabelIcon />
                    </KortexLabelIcon>
                </ListItem>
                <ListItem
                    button={true}
                    className={classes.listItem}
                    disabled={readOnly}
                    id="toolbarForm"
                    onClick={handleClick(EnumElementType.FORM)}
                >
                    <KortexLabelIcon label={translate("action.workInstructions.toolbar.form")}>
                        <FormIcon />
                    </KortexLabelIcon>
                </ListItem>
            </List>
        </Paper>
    );
}
