export const RAD_TO_DEG = 180 / Math.PI;
export const DEG_TO_RAD = Math.PI / 180;

export interface IPoint {
    x: number;
    y: number;
}

/**
 * Return new point coordinate based on point and angle
 *
 * @param {{ x: number, y: number }} point - coordinate
 * @param {number} angleDeg - angle in degree
 */
export function pointFromAngle(point: { x: number; y: number }, angleDeg: number): { x: number; y: number } {
    const angleRad = angleDeg * DEG_TO_RAD;
    return {
        x: point.y * Math.sin(angleRad) + point.x * Math.cos(angleRad),
        y: point.y * Math.cos(angleRad) - point.x * Math.sin(angleRad),
    };
}

/**
 * Calculate bounding box of a rectangle shape based on its angle
 *
 * @param {number} x - Upper left x coordinate
 * @param {number} y - Upper left y coordinate
 * @param {number} width - Rectangle width
 * @param {number} height - Rectangle height
 * @param {number} angleDeg - Rectangle angle in degree
 */
export function calcBoundingBox(
    x: number,
    y: number,
    width: number,
    height: number,
    angleDeg: number
): { x: number; y: number; width: number; height: number } {
    const p1 = pointFromAngle({ x: -width / 2, y: -height / 2 }, angleDeg);
    const p2 = pointFromAngle({ x: width / 2, y: -height / 2 }, angleDeg);
    const p3 = pointFromAngle({ x: width / 2, y: height / 2 }, angleDeg);
    const p4 = pointFromAngle({ x: -width / 2, y: height / 2 }, angleDeg);

    const minX = x + Math.min(p1.x, p2.x, p3.x, p4.x) + width / 2;
    const minY = y + Math.min(p1.y, p2.y, p3.y, p4.y) + height / 2;
    const maxX = x + Math.max(p1.x, p2.x, p3.x, p4.x) + width / 2;
    const maxY = y + Math.max(p1.y, p2.y, p3.y, p4.y) + height / 2;
    const maxWidth = maxX - minX;
    const maxHeight = maxY - minY;

    return {
        x: minX,
        y: minY,
        width: maxWidth,
        height: maxHeight,
    };
}

/**
 * Return a rounded value based on given resolution
 *
 * @param {number} value - value to be rounded
 * @param {number} resolution - targeted resolution
 * @param {boolean} upward - specify if value must be rounded up or down
 */
export function snapToValue(value: number, resolution: number, upward = false): number {
    return upward ? Math.ceil(value / resolution) * resolution : Math.floor(value / resolution) * resolution;
}

/**
 * Convert degree to radian
 *
 * @param {number} degree - degree
 */
export function degreeToRadian(degree: number): number {
    return (degree / 180) * Math.PI;
}

/**
 * Convert radian to degree
 *
 * @param {number} radian - radian
 */
export function radianToDegree(radian: number): number {
    return (radian / Math.PI) * 180;
}
