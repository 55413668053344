export function createResultFailModel() {
    return {
        resultProcessFailId: 0,
        resultMasterId: 0,
        reworkItemStatusId: 0,
        processActionId: 0,
        processActionStepIndex: -1,
        quantity: 1,
        description: "",
        failedOn: Date.now(),
    };
}
