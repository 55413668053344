export const userTraining = {
    rpc: {
        getAllTrainingsByUserId: {
            funcId: 13101,
            funcName: "userTrainingGetAllTrainingsByUserId",
        },
        insert: {
            funcId: 13102,
            funcName: "userTrainingInsert",
        },
        inserted: {
            funcId: 13103,
            funcName: "userTrainingInserted",
        },
        updated: {
            funcId: 13105,
            funcName: "userTrainingUpdated",
        },
        getTrainingHistoryByUserIdAndTreeNodeId: {
            funcId: 13106,
            funcName: "userTrainingGetTrainingHistoryByUserIdAndTreeNodeId",
        },
        signReadingCertificate: {
            funcId: 13107,
            funcName: "userTrainingSignReadingCertificate",
        },
        updateStatus: {
            funcId: 13108,
            funcName: "userTrainingUpdateStatus",
        },
    },
    serviceId: 131,
    serviceVersion: 1,
};
