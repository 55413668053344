export var EnumShapeTypes;
(function (EnumShapeTypes) {
    EnumShapeTypes["ARROW"] = "arrow";
    EnumShapeTypes["CIRCLE"] = "circle";
    EnumShapeTypes["OCTAGON"] = "octagon";
    EnumShapeTypes["RECTANGLE"] = "rectangle";
    EnumShapeTypes["TRIANGLE"] = "triangle";
})(EnumShapeTypes || (EnumShapeTypes = {}));
export class WorkInstructionsShapeConfig {
    constructor() {
        this.border = "0px";
        this.borderColor = "#000000";
        this.color = "#000000";
        this.type = EnumShapeTypes.RECTANGLE;
    }
}
