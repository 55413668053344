import { KortexTextField } from "@aos/react-components";
import { IOrgSettingDbModel } from "@kortex/aos-common";
import * as React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

import GlobalSettingElement from "./GlobalSettingElement";
import { globalSettingsStyles } from "./GlobalSettingsCommon";

interface IOwnProps {
    expanded: boolean;
    organizationInfo: IOrgSettingDbModel;
    userCanEdit: boolean;

    onUpdate?: <Key extends keyof IOrgSettingDbModel>(setting: Key, value: IOrgSettingDbModel[Key]) => void;
    onCollapseChange?: (settingName: string, expanded: boolean) => void;
}

function GlobalSettingGeneralSettings(props: IOwnProps): JSX.Element {
    const classes = globalSettingsStyles();
    const translate = useTranslate();

    const { organizationInfo, userCanEdit } = props;

    /**
     * Handle setting change
     *
     * @param {keyof IOrgSettingDbModel} setting - which setting to update
     */
    const handleSettingChange =
        (setting: keyof IOrgSettingDbModel): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (props.onUpdate) {
                props.onUpdate(setting, event.target.value);
            }
        };

    /**
     * Handle panel collapse change
     *
     * @param {boolean} expanded - true or false, if expanded or not
     */
    const handleCollapseChange = (expanded: boolean): void => {
        if (props.onCollapseChange) {
            props.onCollapseChange("GlobalSettingGeneralSettings", expanded);
        }
    };

    return (
        <GlobalSettingElement
            titleKey="settings.globalSettings.generalSettings"
            expanded={props.expanded}
            onCollapseChange={handleCollapseChange}
            id={"generalSettingsId"}
        >
            <div>
                <KortexTextField
                    className={classes.textField}
                    label={translate("settings.globalSettings.generalSettings.organizationName")}
                    variant="standard"
                    value={organizationInfo.orgName}
                    onChange={handleSettingChange("orgName")}
                    TextFieldProps={{
                        disabled: !userCanEdit,
                    }}
                />
                <KortexTextField
                    className={classes.textField}
                    label={translate("settings.globalSettings.generalSettings.itEmail")}
                    variant="standard"
                    value={organizationInfo.itEmail}
                    onChange={handleSettingChange("itEmail")}
                    TextFieldProps={{
                        disabled: !userCanEdit,
                    }}
                />
            </div>
        </GlobalSettingElement>
    );
}

export default GlobalSettingGeneralSettings;
