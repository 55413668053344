import { errorPalette } from "@aos/react-components";
import { IWorkInstructionsFormInputConfig } from "@kortex/aos-common";
import { Checkbox, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../../../../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    errorLabel: {}, // injected
    asterisk: {
        color: errorPalette[500],
        paddingLeft: "5px",
    },
    flex: {
        display: "flex",
        alignItems: "center",
    },
});

export interface IOwnProps extends IWorkInstructionsFormInputConfig {
    classes?: Pick<ReturnType<typeof useStyles>, "errorLabel">;
}

export default function WorkInstructionsFormInputCheckbox(props: IOwnProps): JSX.Element {
    const { itemState, itemProps } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    const displayedLabel = itemState.label ? itemState.label : itemProps.label;

    /**
     * Called when a user interacts with the input
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - unused
     * @param {boolean} checked - new state of the checkbox
     */
    const onStateChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        props.onStateChanged({ ...props.itemState, value: String(checked) });
    };

    return (
        <React.Fragment>
            <div className={classes.flex}>
                <Checkbox onChange={onStateChanged} checked={itemState.value === "true"} disableRipple={true} />
                <Typography>{displayedLabel}</Typography>
                {itemProps.required && (
                    <Typography className={classes.asterisk} variant="subtitle1">
                        {"*"}
                    </Typography>
                )}
            </div>
            {itemState.errorKey && (
                <div id="error-key-checkbox" className={classes.errorLabel}>
                    {translate("action.workInstructions.form.error." + itemState.errorKey)}
                </div>
            )}
        </React.Fragment>
    );
}
