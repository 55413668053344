import { isProcessReleased, isProcessRetired } from "@kortex/aos-common";

import { useTranslate } from "../../../../hooks/useTranslate";
import { processGet } from "../../../../redux/process-manager/process-thunks-process";
import { StandardDispatch } from "../../../../redux/store";
import { isProcessValid } from "../../../../utilitites/process";

export interface IProcessIsReadyToBePlayed {
    readyToBePlayed: boolean;
    messageError: string;
}

export async function processIsReadyToBePlayed(
    processId: number,
    purgeVersion: boolean,
    dispatch: StandardDispatch,
    translate: ReturnType<typeof useTranslate>
): Promise<IProcessIsReadyToBePlayed> {
    return dispatch(processGet(processId)).then((process) => {
        if (process) {
            // Check the integrity of the process is compliant by validating each action and all the output links.
            if (isProcessValid(process)) {
                // Check version of the process
                const released = isProcessReleased(process);
                const retired = isProcessRetired(process);
                // Check if the process is released
                if (released) {
                    // Check if the process is not retired
                    // Check if the process is in purge
                    if (!retired || purgeVersion === true) {
                        return {
                            readyToBePlayed: true,
                            messageError: "",
                        };
                    }

                    return {
                        readyToBePlayed: false,
                        messageError: translate("scheduler.processRetired"),
                    };
                }

                return {
                    readyToBePlayed: false,
                    messageError: translate("scheduler.processNotReleased"),
                };
            }

            return {
                readyToBePlayed: false,
                messageError: translate("scheduler.processNotValid"),
            };
        }

        return {
            readyToBePlayed: false,
            messageError: translate("scheduler.processNotFound"),
        };
    });
}
