import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default function Blank(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <rect id="icons-templates-blank" x="2" y="4" width="20" height="14" />
        </SvgIcon>
    );
}
