var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _VariableManagerError_key, _VariableManagerError_originalError;
export class VariableManagerError extends Error {
    constructor(key, message = "Variable manager error.", originalError) {
        super(message);
        _VariableManagerError_key.set(this, void 0);
        _VariableManagerError_originalError.set(this, void 0);
        __classPrivateFieldSet(this, _VariableManagerError_key, key, "f");
        __classPrivateFieldSet(this, _VariableManagerError_originalError, Object.freeze(originalError), "f");
    }
    get key() {
        return __classPrivateFieldGet(this, _VariableManagerError_key, "f");
    }
    get originalError() {
        return __classPrivateFieldGet(this, _VariableManagerError_originalError, "f");
    }
    get name() {
        return "VariableManagerError";
    }
}
_VariableManagerError_key = new WeakMap(), _VariableManagerError_originalError = new WeakMap();
