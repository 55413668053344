import { SetTaskListAction, TaskActionType, TaskInsertedAction, TaskUpdatedAction, TaskDeletedAction } from "./task-types";

/**
 * Returns an action
 *
 * @param {TaskInsertedAction} task - value of the action
 */
export function taskInsertedAction(task: TaskInsertedAction["value"]): TaskInsertedAction {
    return { type: TaskActionType.INSERT_TASK, value: task };
}

/**
 * Returns an action
 *
 * @param {TaskUpdatedAction} task - value of the action
 */
export function taskUpdatedAction(task: TaskUpdatedAction["value"]): TaskUpdatedAction {
    return { type: TaskActionType.UPDATE_TASK, value: task };
}

/**
 * Returns an action
 *
 * @param {TaskUpdatedAction} task - value of the action
 */
export function taskDeletedAction(task: TaskUpdatedAction["value"]): TaskDeletedAction {
    return { type: TaskActionType.DELETE_TASK, value: task };
}

/**
 * Returns an action
 *
 * @param {SetTaskListAction} tasks - value of the action
 */
export function setTaskListAction(tasks: SetTaskListAction["value"]): SetTaskListAction {
    return { type: TaskActionType.SET_TASK_LIST, value: tasks };
}
