import { KortexTextField, primaryPalette, secondaryPalette } from "@aos/react-components";
import { JobProcessStatusEnum } from "@kortex/aos-common";
import { FormControl, Input, MenuItem, Select, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import KortexSearchField from "../../core/KortexSearchField";
import { getJobProcessStatusLabelKey } from "../Scheduler/SchedulerData";
import DateTimeFromToFilters from "../Scheduler/SchedulerJobListAndSearch/DateTimeFromToFilters";

export interface IFilters {
    plainText: string;
    status: string[];
    jobRefId: string;
    partNumber: string;
    reference: string;
    trackingId: string;
    requestedDateFrom: string;
    requestedDateTo: string;
    plannedDateFrom: string;
    plannedDateTo: string;
    scheduledDateFrom: string;
    scheduledDateTo: string;
}

export const defaultFilters: IFilters = {
    plainText: "",
    status: [JobProcessStatusEnum.READY, JobProcessStatusEnum.IN_PROGRESS],
    jobRefId: "",
    partNumber: "",
    reference: "",
    trackingId: "",
    requestedDateFrom: "",
    requestedDateTo: "",
    plannedDateFrom: "",
    plannedDateTo: "",
    scheduledDateFrom: "",
    scheduledDateTo: "",
};

const useStyles = makeStyles({
    searchBox: {
        width: "100%",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    filterInput: {
        flex: 2,
    },
    filterInputSelect: {
        padding: "5px",
    },
    clearFilterLabel: {
        color: secondaryPalette[500],
        "&:hover": {
            color: primaryPalette[500],
            cursor: "pointer",
        },
    },
});

interface IOwnProps {
    disabled?: boolean;
    onFiltersChange: (filters: IFilters) => void;
}

export default function SchedulerOperatorSearchBar(props: IOwnProps): JSX.Element {
    const { disabled = false, onFiltersChange } = props;
    const initialFilters = Object.assign({}, defaultFilters);

    const classes = useStyles();
    const translate = useTranslate();

    const onFiltersChangeDelayMS = 750;

    const [filters, setFilters] = useState<IFilters>(initialFilters);
    const [onFiltersChangeCallbackEnabled, setOnFiltersChangeCallbackEnabled] = useState<boolean>(false);

    /**
     * When filters change, execute onChange cb
     */
    useEffect((): void => {
        if (onFiltersChangeCallbackEnabled) {
            onFiltersChange(filters);
            setOnFiltersChangeCallbackEnabled(false);
        }
    }, [filters]);

    /**
     * Called when the inner filters change
     *
     * @param {string} key - indicates which filter changed
     */
    const handleFilterChange =
        (key: keyof IFilters): ((e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
            setFilters({ ...filters, [key]: e.target.value });
        };

    /**
     * Handle called when the card header item is updated
     *
     * @param {keyof IFilters} key - key of that job that changed
     */
    const handleDateFilterChange =
        (key: keyof IFilters): ((date: MaterialUiPickersDate) => void) =>
        (date: MaterialUiPickersDate): void => {
            setFilters({ ...filters, [key]: date ? String(date) : "" });
        };

    /**
     * Called when filters change. Checks if filters equal their initial value (plainText is excluded from check)
     */
    const isFilterActive = (): boolean => {
        let active = false;
        Object.keys(filters).forEach((key): void => {
            if (key !== "plainText" && filters[key] !== initialFilters[key]) {
                active = true;
            }
        });
        return active;
    };

    /**
     * Called when clicking the Clear Filter label
     */
    const handleClearFilters = (): void => {
        setFilters(initialFilters);
    };

    /**
     * Called when the text in the searchbar changes
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        setOnFiltersChangeCallbackEnabled(true);
        setFilters({ ...filters, plainText: val });
    };

    /**
     * Called when the text in the searchbar closes
     */
    const handleSearchTextClose = (): void => {
        onFiltersChange(filters);
    };

    return (
        <KortexSearchField
            className={classes.searchBox}
            onChange={handleSearchTextChange}
            onClose={handleSearchTextClose}
            isFilterActive={isFilterActive()}
            buttonText={translate("player.apply")}
            changedDelayMS={onFiltersChangeDelayMS}
            disabled={disabled}
        >
            <React.Fragment>
                {/* STATUS */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("scheduler.status")}</Typography>
                    <FormControl className={classes.filterInput}>
                        <Select
                            inputProps={{
                                className: classes.filterInputSelect,
                            }}
                            multiple={true}
                            input={<Input id="select-multiple-chip" />}
                            onChange={handleFilterChange("status")}
                            value={filters.status}
                        >
                            {defaultFilters.status.map((key: JobProcessStatusEnum): JSX.Element => {
                                return (
                                    <MenuItem key={key} value={key}>
                                        {translate(getJobProcessStatusLabelKey(key))}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
                {/* JobRefId */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("scheduler.jobRefId")}</Typography>
                    <KortexTextField
                        className={classes.filterInput}
                        onChange={handleFilterChange("jobRefId")}
                        value={filters.jobRefId}
                        variant={"standard"}
                    />
                </div>
                {/* PartNumber */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("scheduler.partNumber")}</Typography>
                    <KortexTextField
                        className={classes.filterInput}
                        onChange={handleFilterChange("partNumber")}
                        value={filters.partNumber}
                        variant={"standard"}
                    />
                </div>
                {/* ReferenceId */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("scheduler.referenceId")}</Typography>
                    <KortexTextField
                        className={classes.filterInput}
                        onChange={handleFilterChange("reference")}
                        value={filters.reference}
                        variant={"standard"}
                    />
                </div>

                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("scheduler.tracking")}</Typography>
                    <KortexTextField
                        className={classes.filterInput}
                        onChange={handleFilterChange("trackingId")}
                        value={filters.trackingId}
                        variant={"standard"}
                    />
                </div>

                <DateTimeFromToFilters
                    filterLabel={"scheduler.requestedOn"}
                    ValueFrom={filters.requestedDateFrom}
                    ValueTo={filters.requestedDateTo}
                    onChangeFrom={handleDateFilterChange("requestedDateFrom")}
                    onChangeTo={handleDateFilterChange("requestedDateTo")}
                />

                <DateTimeFromToFilters
                    filterLabel={"scheduler.plannedOn"}
                    ValueFrom={filters.plannedDateFrom}
                    ValueTo={filters.plannedDateTo}
                    onChangeFrom={handleDateFilterChange("plannedDateFrom")}
                    onChangeTo={handleDateFilterChange("plannedDateTo")}
                />

                <DateTimeFromToFilters
                    filterLabel={"scheduler.scheduledOn"}
                    ValueFrom={filters.scheduledDateFrom}
                    ValueTo={filters.scheduledDateTo}
                    onChangeFrom={handleDateFilterChange("scheduledDateFrom")}
                    onChangeTo={handleDateFilterChange("scheduledDateTo")}
                />

                <div className={classes.optionsItem}>
                    <div className={classes.filterLabel} />
                    <Typography onClick={handleClearFilters} className={classes.clearFilterLabel}>
                        {translate("scheduler.clearFilters")}
                    </Typography>
                </div>
            </React.Fragment>
        </KortexSearchField>
    );
}
