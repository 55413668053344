var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function Columns(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M6,2 L18,2 L18,22 L6,22 L6,2 Z M7,3 L7,9 L17,9 L17,3 L7,3 Z M7,10 L7,12 L17,12 L17,10 L7,10 Z M7,13 L7,15 L17,15 L17,13 L7,13 Z M7,16 L7,18 L17,18 L17,16 L7,16 Z M7,19 L7,21 L17,21 L17,19 L7,19 Z", id: "icons-column" })));
}
