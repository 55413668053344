import {
    AccessLevelEnum,
    BomRightsEnum,
    DashboardRightsEnum,
    GroupEditorRightsEnum,
    ProcessApprovalRightsEnum,
    ProcessEditorRightsEnum,
    ProcessPlayerRightsEnum,
    ProcessReleaseRightsEnum,
    ReportsRightsEnum,
    RepositoryEditorRightsEnum,
    ReworkRightsEnum,
    RoleEditorRightsEnum,
    SchedulerOperatorRightsEnum,
    SchedulerRightsEnum,
    StorageEditorsRightsEnum,
    SystemRightsEnum,
    TrainingRightsEnum,
    UserEditorRightsEnum,
} from "@kortex/aos-common";

type TUserRights =
    | DashboardRightsEnum
    | GroupEditorRightsEnum
    | ProcessApprovalRightsEnum
    | ProcessEditorRightsEnum
    | ProcessReleaseRightsEnum
    | ProcessPlayerRightsEnum
    | RepositoryEditorRightsEnum
    | RoleEditorRightsEnum
    | ReportsRightsEnum
    | SchedulerRightsEnum
    | SchedulerOperatorRightsEnum
    | StorageEditorsRightsEnum
    | SystemRightsEnum
    | UserEditorRightsEnum
    | ReworkRightsEnum
    | TrainingRightsEnum
    | BomRightsEnum;

export interface IUserRightsProps<TUserAccessLevel = TUserRights> {
    userAccessLevel?: TUserAccessLevel;
}

export function userCanRead(accessLevel?: TUserRights): boolean {
    return Boolean(accessLevel && accessLevel >= AccessLevelEnum.READ);
}

export function userCanWrite(accessLevel?: TUserRights): boolean {
    return Boolean(accessLevel && accessLevel >= AccessLevelEnum.WRITE);
}

export function userCanInsert(accessLevel?: TUserRights): boolean {
    return Boolean(accessLevel && accessLevel >= AccessLevelEnum.INSERT);
}

export function userCanArchive(accessLevel?: TUserRights): boolean {
    return Boolean(accessLevel && accessLevel >= AccessLevelEnum.ARCHIVE);
}
