import { ITreeNodeDbModel, TreeNodeDbModel } from "@kortex/aos-common";

import { BareAction, ComplexAction, OrUndefined, Unpack } from "../app.types";

export enum TreeActionType {
    CLEAR_TREES = "@@TREE/CLEAR_TREES",
    INSERT_FILE = "@@TREE/INSERT_FILE",
    INSERT_PROCESS = "@@TREE/INSERT_PROCESS",
    INSERT_WORK_ZONE = "@@TREE/INSERT_WORK_ZONE",
    SET_EDITED_TREE_PROCESS_NODE_ID = "@@TREE/SET_EDITED_TREE_PROCESS_NODE_ID",
    SET_FILES = "@@TREE/SET_FILES",
    SET_PROCESSES = "@@TREE/SET_PROCESSES",
    SET_WORK_ZONES = "@@TREE/SET_WORK_ZONES",
    UPDATE_FILE = "@@TREE/UPDATE_FILE",
    UPDATE_PROCESS = "@@TREE/UPDATE_PROCESS",
    UPDATE_WORK_ZONE = "@@TREE/UPDATE_WORK_ZONE",
}

interface ITreeState {
    editedTreeProcessNodeId: OrUndefined<TreeNodeDbModel["treeNodeId"]>;
    files: ITreeNodeDbModel[];
    processes: ITreeNodeDbModel[];
    workZones: ITreeNodeDbModel[];
}

export type TreeState = ITreeState;

export type ClearTreesAction = BareAction<TreeActionType.CLEAR_TREES>;
export type SetEditedTreeProcessNodeIdAction = ComplexAction<
    TreeActionType.SET_EDITED_TREE_PROCESS_NODE_ID,
    ITreeState["editedTreeProcessNodeId"]
>;
export type SetTreeFileAction = ComplexAction<TreeActionType.SET_FILES, ITreeState["files"]>;
export type SetTreeProcessAction = ComplexAction<TreeActionType.SET_PROCESSES, ITreeState["processes"]>;
export type SetTreeWorkZoneAction = ComplexAction<TreeActionType.SET_WORK_ZONES, ITreeState["workZones"]>;
export type TreeFileInsertedAction = ComplexAction<TreeActionType.INSERT_FILE, ITreeState["files"]>;
export type TreeFileUpdatedAction = ComplexAction<TreeActionType.UPDATE_FILE, ITreeState["files"]>;
export type TreeProcessInsertedAction = ComplexAction<TreeActionType.INSERT_PROCESS, Unpack<ITreeState["processes"]>>;
export type TreeProcessUpdatedAction = ComplexAction<TreeActionType.UPDATE_PROCESS, ITreeState["processes"]>;
export type TreeWorkZoneInsertedAction = ComplexAction<TreeActionType.INSERT_WORK_ZONE, Unpack<ITreeState["workZones"]>>;
export type TreeWorkZoneUpdatedAction = ComplexAction<TreeActionType.UPDATE_WORK_ZONE, ITreeState["workZones"]>;

export type TreeActions =
    | ClearTreesAction
    | SetEditedTreeProcessNodeIdAction
    | SetTreeFileAction
    | SetTreeProcessAction
    | SetTreeWorkZoneAction
    | TreeFileInsertedAction
    | TreeFileUpdatedAction
    | TreeProcessInsertedAction
    | TreeProcessUpdatedAction
    | TreeWorkZoneInsertedAction
    | TreeWorkZoneUpdatedAction;
