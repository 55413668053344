import { ProcessEditorRightsEnum } from "@kortex/aos-common";
import { List } from "@material-ui/core";
import * as React from "react";

import ShellCommand from "../ShellCommand/ConnectorShellCommand";
import { IUserRightsProps } from "../../../../../../../../utilitites/IUserRights";

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    commands: string[];
    onChange: (commands: string[]) => void;
}

export default function ConnectorShellCommandList(props: IOwnProps): JSX.Element {
    const { commands, onChange, userAccessLevel } = props;

    /**
     * Called when a command textfield is blurred
     *
     * @param {number} index - command index
     */
    const handleBlur =
        (index: number): ((value: string) => void) =>
        (value: string): void => {
            const clone = [...commands];
            clone[index] = value;

            onChange(clone);
        };

    /**
     * Called when deleting a command from the list
     *
     * @param {number} index - command index
     */
    const handleDelete =
        (index: number): (() => void) =>
        (): void => {
            const clone = [...commands];
            clone.splice(index, 1);

            onChange(clone);
        };

    return (
        <React.Fragment>
            <List>
                {commands.map((command, index) => (
                    <ShellCommand
                        command={command}
                        index={index}
                        isLast={commands.length === 1}
                        key={index.toString()}
                        onBlur={handleBlur(index)}
                        onDelete={handleDelete(index)}
                        userAccessLevel={userAccessLevel}
                    />
                ))}
            </List>
        </React.Fragment>
    );
}
