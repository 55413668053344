export const treeFile = {
    rpc: {
        all: {
            funcId: 5001,
            funcName: "treeFileAll",
        },
        insert: {
            funcId: 5002,
            funcName: "treeFileInsert",
        },
        inserted: {
            funcId: 5003,
            funcName: "treeFileInserted",
        },
        update: {
            funcId: 5004,
            funcName: "treeFileUpdate",
        },
        updated: {
            funcId: 5005,
            funcName: "treeFileUpdated",
        },
    },
    serviceId: 50,
    serviceVersion: 1,
};
