import { ProcessVariableStorer } from "../../../../../Process/ProcessVariable";
export const TimeType = {
    CONVERSION: "conversion",
    COUNTDOWN: "countdown",
    TIMER: "timer",
    WAIT: "wait",
};
export const TimeConversion = {
    CONVERT_TIMESTAMP_TO_DATE: "convertTimestampToDate",
    CONVERT_DATE_TO_TIMESTAMP: "convertDateToTimestamp",
    GET_YEAR_FROM_TIMESTAMP: "getYearFromTimestamp",
    GET_MONTH_FROM_TIMESTAMP: "getMonthFromTimestamp",
    GET_DAY_FROM_TIMESTAMP: "getDayFromTimestamp",
    GET_HOURS_FROM_TIMESTAMP: "getHoursFromTimestamp",
    GET_MINUTES_FROM_TIMESTAMP: "getMinutesFromTimestamp",
    GET_SECONDS_FROM_TIMESTAMP: "getSecondsFromTimestamp",
};
export const TimeTimer = {
    START_TIMER: "startTime",
    STOP_TIMER: "stopTimer",
};
export function createStepConfigTime() {
    return {
        function: TimeConversion.CONVERT_DATE_TO_TIMESTAMP,
        storeTo: new ProcessVariableStorer(),
        type: TimeType.TIMER,
        value: "",
    };
}
