import { KortexTextField } from "@aos/react-components";
import { IOrgSettingDbModel, LocationKeys } from "@kortex/aos-common";
import { Button, List, ListItem, ListItemText, MenuItem, Typography } from "@material-ui/core";
import * as React from "react";
import { useCallback, useState } from "react";

import appConfigs from "../../../../configs/app";
import { useTranslate } from "../../../../hooks/useTranslate";
import { LanguageSelectorDialog } from "../../../core/LanguageSelectorDialog";

import GlobalSettingElement from "./GlobalSettingElement";
import { globalSettingsStyles } from "./GlobalSettingsCommon";

interface IOwnProps {
    expanded: boolean;
    organizationInfo: IOrgSettingDbModel;
    userCanEdit: boolean;

    onUpdate?: <Key extends keyof IOrgSettingDbModel>(setting: Key, value: IOrgSettingDbModel[Key]) => void;
    onCollapseChange?: (settingName: string, expanded: boolean) => void;
}

function GlobalSettingLocalization(props: IOwnProps): JSX.Element {
    const { onUpdate, organizationInfo, userCanEdit } = props;

    const classes = globalSettingsStyles();

    const translate = useTranslate();
    const [langDialogOpen, setLangDialogOpen] = useState(false);

    const languageSelectorFilterFunction = useCallback(
        // prevent user from adding main language and already added secondary languages
        (lang: LocationKeys): boolean => lang !== organizationInfo.defaultLoc && !organizationInfo.customLocations.includes(lang),
        [organizationInfo.defaultLoc, organizationInfo.customLocations]
    );

    /**
     * Handles a secondary language add request from the language selector dialog
     */
    const handleAddLang = (): void => {
        setLangDialogOpen(true);
    };

    /**
     * Handles a selection of a secondary language
     */
    const handleLangSelected = (lang: LocationKeys): void => {
        const exist = organizationInfo.customLocations.indexOf(lang);
        if (exist >= 0) {
            return;
        }

        if (onUpdate) {
            onUpdate("customLocations", [...organizationInfo.customLocations, lang]);
        }
        setLangDialogOpen(false);
    };

    /**
     * Handles a close request from the language selector dialog
     */
    const handleCloseDialog = (): void => {
        setLangDialogOpen(false);
    };

    /**
     * Handles a change in the collapsed state of a menu
     *
     * @param {boolean} expanded - new state
     */
    const handleCollapseChange = (expanded: boolean): void => {
        if (props.onCollapseChange) {
            props.onCollapseChange("GlobalSettingLocalization", expanded);
        }
    };

    /**
     * Handles a change to the org's main language
     *
     * @param {React.ChangeEvent} e - event from KortexTextField onChange
     */
    const handleOrgMainLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        if (onUpdate) {
            onUpdate("defaultLoc", e.target.value);
        }
    };

    return (
        <GlobalSettingElement
            titleKey="settings.globalSettings.localization"
            expanded={props.expanded}
            onCollapseChange={handleCollapseChange}
            id={"localizationSettingsId"}
        >
            <div>
                <Typography variant="subtitle2">{translate("settings.globalSettings.localization.mainLanguage")}</Typography>
                <KortexTextField
                    value={organizationInfo.defaultLoc}
                    variant={"standard"}
                    className={classes.textField}
                    TextFieldProps={{
                        select: true,
                        disabled: !userCanEdit,
                    }}
                    onChange={handleOrgMainLanguageChange}
                >
                    {appConfigs.acceptedLanguages.map((lang) => {
                        return (
                            <MenuItem value={lang} key={lang}>
                                {translate(lang)}
                            </MenuItem>
                        );
                    })}
                </KortexTextField>
                <Typography variant="subtitle2">{translate("settings.globalSettings.localization.secondaryLanguages")}</Typography>
                <List className={classes.langList}>
                    {organizationInfo.customLocations.map((locationItem): JSX.Element => {
                        return (
                            <ListItem key={locationItem}>
                                <ListItemText primary={locationItem} />
                            </ListItem>
                        );
                    })}
                </List>
                <Button disabled={!userCanEdit} variant="contained" color="secondary" onClick={handleAddLang}>
                    {translate("settings.globalSettings.localization.addLanguage")}
                </Button>
                <LanguageSelectorDialog
                    open={langDialogOpen}
                    onLangSelected={handleLangSelected}
                    onClose={handleCloseDialog}
                    filterFunction={languageSelectorFilterFunction}
                />
            </div>
        </GlobalSettingElement>
    );
}

export default GlobalSettingLocalization;
