export const processTraining = {
    rpc: {
        insert: {
            funcId: 13004,
            funcName: "processTrainingInsert",
        },
        inserted: {
            funcId: 13005,
            funcName: "processTrainingInserted",
        },
        updated: {
            funcId: 13007,
            funcName: "processTrainingUpdated",
        },
        updateStatus: {
            funcId: 13009,
            funcName: "processTrainingUpdateStatus",
        },
        getLatestApprovedProcess: {
            funcId: 13010,
            funcName: "processTrainingGetLatestApprovedProcess",
        },
        getTrainingsByProcessId: {
            funcId: 13011,
            funcName: "processTrainingGetTrainingsByProcessId",
        },
        latestApprovedProcessUpdated: {
            funcId: 13012,
            funcName: "processTrainingLatestApprovedProcessUpdated",
        },
    },
    serviceId: 130,
    serviceVersion: 1,
};
