import { theme } from "@aos/react-components";
import { IBomFollowUp, IWoBomWorkInstructionsItem } from "@kortex/aos-common";
import ProcessIcon from "@kortex/aos-ui/components/core/Icons/Process/Process";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles({
    root: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
    tableCell: {
        border: `1px solid ${theme.palette.common.black}`,
        padding: "12px",
    },
    tableContainer: {
        padding: "6px",
    },
    tableRowEven: {
        backgroundColor: theme.palette.grey[100],
    },
    tableRowHeader: {
        backgroundColor: theme.palette.grey[300],
    },
    tableRowOdd: {
        backgroundColor: theme.palette.common.white,
    },
    tooltip: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: "5px",
        minWidth: "500px",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    followUp?: IBomFollowUp;
    workInstructionsItem: IWoBomWorkInstructionsItem;
}

const ProcessInfoIcon: FC<IOwnProps> = (props) => {
    const { workInstructionsItem } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    return (
        <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement="left"
            title={
                <div className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableRowHeader}>
                                <TableCell className={classes.tableCell} width="25%">
                                    <Typography variant="h6">{translate("action.workInstructions.bom.information")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell} width="75%">
                                    <Typography variant="h6">{translate("action.workInstructions.bom.value")}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className={classes.tableRowOdd}>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body2">{translate("bomPage.bomTable.process")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body2">{workInstructionsItem.treeNode.label}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.tableRowEven}>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body2">{translate("bomPage.bomTable.processAction")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body2">{workInstructionsItem.processAction.label}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.tableRowOdd}>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body2">{translate("bomPage.bomTable.processActionStep")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant="body2">{workInstructionsItem.processActionStep.label}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            }
        >
            <div className={classes.root}>
                {/* div is needed for the tooltip */}
                <ProcessIcon />
            </div>
        </Tooltip>
    );
};

export default ProcessInfoIcon;
