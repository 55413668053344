import { ServiceManagerClient, WSClient, Kinova } from "@kortex/aos-api-client";
import { getRPCHubInfo, OrganizationSettingsUpdateReq, OrganizationSettingsUpdateRes } from "@kortex/aos-common";

import { objectFromUTF8Array, utf8ArrayFromObject, utf8ArrayToKortexApiErrorObject } from "../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const settingOrganizationUpdate = (router: WSClient["router"]) =>
    ServiceManagerClient.getRR<OrganizationSettingsUpdateReq, OrganizationSettingsUpdateRes, Kinova.Api.IError>(
        getRPCHubInfo("settingOrganization")("update"),
        {
            router,
            serializer: {
                decode: objectFromUTF8Array,
                encode: utf8ArrayFromObject,
                err: {
                    decode: utf8ArrayToKortexApiErrorObject,
                },
            },
        }
    );
