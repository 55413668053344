import { SequencerError } from "./sequencer-error";
export class RemoteActionMethodTimeoutError extends SequencerError {
    constructor(key, message = "Remote action method timeout has expired.") {
        super(key, message);
    }
    get name() {
        return "RemoteActionMethodTimeoutError";
    }
    get originalError() {
        return super.originalError;
    }
}
