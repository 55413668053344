/**
 * Repeat a function until a condition is satisfied.
 *
 * @param {Function} cb - Function that will be called (repeated) each iteration
 * @param {Function} until - Repeat "cb" until "until" returns true
 * @param {IOptions} options - options
 */
export function repeat(cb, until, options = {}) {
    const copiedOptions = { ...options };
    let counter = 0, result;
    while (!until((result = cb()))) {
        options.onFail?.(result, counter++);
        if (copiedOptions?.maxTries && counter >= copiedOptions.maxTries)
            return copiedOptions.fallback ?? result;
    }
    return result;
}
/**
 * Repeat a function until a condition is satisfied.
 *
 * @param {Function} cb - Function that will be called (repeated) each iteration
 * @param {Function} until - Repeat "cb" until "until" returns true
 * @param {IOptionsAsync} options - options
 */
export async function repeatAsync(cb, until, options = {}) {
    const copiedOptions = { ...options };
    let counter = 0, result;
    while (!until((result = await cb()))) {
        await options.onFail?.(result, counter++);
        if (copiedOptions?.maxTries && counter >= copiedOptions.maxTries)
            return copiedOptions.fallback ?? result;
    }
    return result;
}
