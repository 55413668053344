export const settingOrganization = {
    rpc: {
        getAll: {
            funcId: 1000,
            funcName: "settingOrganizationAll",
        },
        insert: {
            funcId: 1001,
            funcName: "settingOrganizationInsert",
        },
        update: {
            funcId: 1002,
            funcName: "settingOrganizationUpdate",
        },
        inserted: {
            funcId: 1003,
            funcName: "settingOrganizationInserted",
        },
        updated: {
            funcId: 1004,
            funcName: "settingOrganizationUpdated",
        },
    },
    serviceId: 10,
    serviceVersion: 1,
};
