import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default function Action(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M6.40823,12.71429 C6.09603,13.90283 5.02338,14.77842 3.74863,14.77778 C2.23011,14.77701 0.999621217,13.53316 1.00000009,11.99931 C1.00037896,10.46545 2.23148,9.22222 3.75,9.22222 C4.4797,9.22222 5.17949,9.51517 5.69527,10.03655 C6.04034,10.38537 6.28412,10.81729 6.40753,11.28571 L8.65162,11.28571 L8.65162,8 L15.9526,8 L15.9526,11.28571 L18.5441,11.28571 L18.5441,8 L23,12.00014 L18.5441,16.0351176 L18.5441,12.71429 L15.9526,12.71429 L15.9526,16 L8.65162,16 L8.65162,12.71429 L6.40823,12.71429 Z"
                id="icons-process"
            />
        </SvgIcon>
    );
}
