import { AOSError } from "../aosError";
export class DataBaseError extends AOSError {
    //---------------------------------------------------------
    // Private
    //---------------------------------------------------------
    //---------------------------------------------------------
    // Public
    //---------------------------------------------------------
    constructor(description, originalError, argument) {
        super(description, originalError, argument);
    }
    get name() {
        return "DataBaseError";
    }
}
