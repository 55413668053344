import { KortexDialogConfirmation } from "@aos/react-components";
import {
    EnumProcessStatus,
    PlayerProcessHubConnectionStatusEnum,
    STORE_RESULT_RETRIES,
    STORE_RESULT_RETRY_DELAY,
    STORE_RESULT_TIMEOUT,
} from "@kortex/aos-common";
import { useSelectorPlayer } from "@kortex/aos-ui/redux/selectors";
import { makeStyles, CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    circularProgress: {
        marginRight: "10px",
    },
    message: {
        marginTop: "8px",
        width: "100%",
        margin: "0px",
    },
});

interface IOwnProps {
    onRetry: () => void;
    onStop: () => void;
}

const automaticEnableStopTimeoutMs = (STORE_RESULT_RETRIES + 1) * (STORE_RESULT_TIMEOUT + STORE_RESULT_RETRY_DELAY);

let enableStopTimeout: ReturnType<typeof setTimeout>;
let automaticRetryTimeout: ReturnType<typeof setTimeout>;

function clearAllTimeouts(): void {
    clearTimeout(enableStopTimeout);
    clearTimeout(automaticRetryTimeout);
}

export function RunnerHubFailureDialog(props: IOwnProps): JSX.Element {
    const { onRetry, onStop } = props;

    const classes = useStyles();

    const playerState = useSelectorPlayer();
    const translate = useTranslate();

    const [timeoutExpired, setTimeoutExpired] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [waitingForResponse, setWaitingForResponse] = useState<boolean>(false);

    const manualRetryDisabled = !playerState.hubConnection.canRetry || waitingForResponse;
    const manualStopDisabled = !timeoutExpired && manualRetryDisabled;

    const ProgressCircle = useMemo(() => <CircularProgress classes={{ root: classes.circularProgress }} size="24px" />, []);

    /**
     * Update inner states on redux state change
     */
    useEffect((): void => {
        // Prevent the dialog from opening when the player is inactive
        if (playerState.processState.status === EnumProcessStatus.IDLE) {
            setOpen(false);

            return void 0;
        }

        const willOpen = playerState.hubConnection.status === PlayerProcessHubConnectionStatusEnum.FAIL;

        // Open/close the dialog depending on the status
        setOpen(willOpen);

        if (willOpen) {
            startTimeout();
        }

        // Check if we reset waitingForResponse and timeouts
        if (playerState.hubConnection.status === PlayerProcessHubConnectionStatusEnum.OK) {
            clearAllTimeouts();
            setWaitingForResponse(false);
        } else if (playerState.hubConnection.canRetry) {
            clearTimeout(enableStopTimeout);
            setWaitingForResponse(false);
        }
    }, [playerState.hubConnection]);

    /**
     * Retry the failed operation
     */
    function handleRetry(): void {
        setWaitingForResponse(true);
        onRetry();
        startTimeout();
    }

    /**
     * Stop the process
     */
    function handleStop(): void {
        clearAllTimeouts();
        setOpen(false);
        onStop();
    }

    /**
     * Start a timeout, waiting for the response from the Runner. If a response is never received, it will unlock the Stop button.
     * Also start a second timeout which automatically retry if no buttons are clicked after a several minutes.
     */
    function startTimeout(): void {
        // Clear previous timeouts
        clearAllTimeouts();

        // Start the timeout to enable the stop button
        enableStopTimeout = setTimeout(() => {
            setTimeoutExpired(true);
        }, automaticEnableStopTimeoutMs);

        // Start the timeout to automatically retry
        automaticRetryTimeout = setTimeout(() => {
            handleRetry();
        }, 300_000); // 5 minutes
    }

    return (
        <KortexDialogConfirmation
            cancelDisabled={manualStopDisabled}
            closeOnEscape={true}
            confirmDisabled={manualRetryDisabled}
            onCancel={handleStop}
            onConfirm={handleRetry}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("player.runnerHubFailureDialog.stop"),
                proceedButtonLabel: translate("player.runnerHubFailureDialog.retry"),
                titleLabel: translate("player.runnerHubFailureDialog.title"),
            }}
            textLabelsIcons={{
                proceedButtonIcon: manualRetryDisabled ? ProgressCircle : undefined,
            }}
        >
            <Typography>
                {playerState.hubConnection.canRetry ? (
                    <>
                        {translate("player.runnerHubFailureDialog.autoRetryUnsuccessful") /* Retry or stop */}
                        <br />
                        {translate("player.runnerHubFailureDialog.manualRetry") /* Manual retry */}
                    </>
                ) : (
                    <>
                        {translate("player.runnerHubFailureDialog.autoRetry") /* Server's automatic retry */}
                        <br />
                        {
                            `\n\n${translate("player.runnerHubFailureDialog.attempt")}${
                                playerState.hubConnection.reconnectionAttemptCount + 1
                            } ` /* Attempt counter */
                        }
                    </>
                )}
            </Typography>
        </KortexDialogConfirmation>
    );
}
