import { ITrainingPending, TrainingPendingGetAllFilters } from "@kortex/aos-common";
import { UseListObserver, useListObserver } from "@kortex/aos-ui/hooks/useListObserver";
import { useEntitiesTrainingPendingList } from "@kortex/aos-ui/redux/effects";
import { trainingPendingGetAll } from "@kortex/aos-ui/redux/training-pending-manager/training-pending-thunks";
import React, { FC, PropsWithChildren, createContext, useContext } from "react";

const TrainingCertificateContext = createContext<UseListObserver<ITrainingPending, TrainingPendingGetAllFilters>>({
    filters: {},
    entities: [],
    hasNext: false,
    isDataLoading: false,
    Observer: () => null,
    observerId: undefined,
    setFilters: () => void 0,
});

type TrainingCertificateProviderProps = PropsWithChildren<{}>;

export const TrainingCertificateProvider: FC<TrainingCertificateProviderProps> = (props) => {
    const { children } = props;

    const { entities, filters, hasNext, isDataLoading, Observer, observerId, setFilters } = useListObserver(
        useEntitiesTrainingPendingList,
        (trainingPending) => trainingPending?.trainingPendingId ?? 0,
        trainingPendingGetAll,
        {}
    );

    return (
        <TrainingCertificateContext.Provider
            value={{
                entities,
                filters,
                hasNext,
                isDataLoading,
                Observer,
                observerId,
                setFilters,
            }}
        >
            {children}
        </TrainingCertificateContext.Provider>
    );
};

export const useTrainingCertificateContext = (): UseListObserver<ITrainingPending, TrainingPendingGetAllFilters> =>
    useContext<UseListObserver<ITrainingPending, TrainingPendingGetAllFilters>>(TrainingCertificateContext);
