import { theme } from "@aos/react-components";
import { Tooltip, makeStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles({
    root: {
        display: "contents",
    },
    text: {
        color: theme.palette.primary[500],
        fontSize: "1.2rem",
        fontWeight: 400,
        lineHeight: "24px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "&:hover": {
            backgroundColor: theme.palette.grey[300],
            borderRadius: "5px",
            cursor: "pointer",
        },
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IOwnProps {
    index: number;
    label: string;
    onClick: () => void;
    parentPathLength: number;
}

function BreadCrumb(props: IOwnProps): JSX.Element {
    const { index, label, onClick, parentPathLength } = props;

    const classes = useStyles();

    const [isLabelEllipsisActive, setIsLabelEllipsisActive] = useState<boolean>(false);

    const labelSpanRef = useRef<HTMLSpanElement | null>(null);

    // Check if the ellipsis is active on the label
    useEffect((): void => {
        const element = labelSpanRef.current;

        if (!element) {
            setIsLabelEllipsisActive(false);

            return void 0;
        }

        setIsLabelEllipsisActive(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);
    });

    return (
        <div className={classes.root} key={index}>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={isLabelEllipsisActive ? label : ""}>
                <span className={classes.text} id={`processRoutingParentPathId${index}`} onClick={onClick} ref={labelSpanRef}>
                    {label}
                </span>
            </Tooltip>
            {index < parentPathLength - 1 && <ChevronRightIcon />}
        </div>
    );
}

export default BreadCrumb;
