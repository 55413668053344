import { theme } from "@aos/react-components";
import { UserId } from "@kortex/aos-common";
import UserSelectorDialog from "@kortex/aos-ui/components/core/UserSelectorDialog/UserSelectorDialog";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesUsers } from "@kortex/aos-ui/redux/effects";
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
    makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import React from "react";

const useStyles = makeStyles({
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },

    // DIALOG
    userList: {
        display: "flex",
        flexDirection: "column",
    },
    listTitler: {
        padding: "5px",
    },
    headerContent: {
        display: "flex",
        backgroundColor: theme.palette.grey[200],
    },
    containerFab: {
        height: "60px",
        display: "flex",
        justifyContent: "flex-end",
    },
});

interface IOwnProps {
    disabled?: boolean;
    title: string;
    userIdSelected: UserId | undefined;
    userIdToExclude?: UserId[];
    handleDeleteUserId: (userIdToBeDeleted: UserId) => void;
    handleUserIdSelectorDialogConfirm: (selectedUserIdDialog: UserId) => void;
}

function TrainingCertificationFormUserSelector(props: IOwnProps): JSX.Element {
    const { disabled = false, title, userIdSelected, userIdToExclude = [], handleDeleteUserId, handleUserIdSelectorDialogConfirm } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const users = useEntitiesUsers();

    /**
     * Deletes user
     */
    const handleDeleteUser =
        (userIdToBeDeleted: UserId): (() => void) =>
        (): void => {
            handleDeleteUserId(userIdToBeDeleted);
        };

    /**
     * Confirm selection
     */
    const handleConfirmSeclection = (selectedUserIdsDialog: UserId[]): void => {
        handleUserIdSelectorDialogConfirm(selectedUserIdsDialog[0]);
    };

    return (
        <div id={"trainingCertificationFormUserSelectorContainerId"} className={classes.userList}>
            <Typography variant="h4" className={classes.listTitler}>
                {title}
            </Typography>

            <List
                id={"trainingCertificationFormUserSelectorContentId"}
                disablePadding={true}
                /* ----------------------------------------------------------------------------------------------- */
                /* HEADER -------------------------------------------------------------------------------- */
                /* ----------------------------------------------------------------------------------------------- */
                subheader={
                    <div>
                        <div className={classes.headerContent}>
                            {/* USER NAME */}
                            <ListItem>
                                <Typography variant="h6">{translate("training.userName")}</Typography>
                            </ListItem>
                        </div>
                        <Divider />
                    </div>
                }
            >
                {/* ----------------------------------------------------------------------------------------------- */}
                {/* USER LIST ---------------------------------------------------------------------------------- */}
                {/* ----------------------------------------------------------------------------------------------- */}
                {userIdSelected &&
                    users
                        .filter((userToBeFiltered) => userIdSelected === userToBeFiltered.userId)
                        .sort(function (a, b) {
                            const nameA = a.userName.toUpperCase(); // ignore upper and lowercase
                            const nameB = b.userName.toUpperCase(); // ignore upper and lowercase
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        })
                        .map((user, index) => (
                            <ListItem
                                key={index}
                                style={{
                                    backgroundColor: index % 2 === 0 ? theme.palette.common.white : theme.palette.grey[100],
                                }}
                                id={"trainingCertificationFormUserSelectorUserId"}
                            >
                                <ListItemText
                                    id={"trainingCertificationFormUserSelectorUserNameId"}
                                    primary={`${user.firstName} ${user.lastName}`}
                                />
                                <ListItemSecondaryAction>
                                    <Tooltip classes={{ tooltip: classes.tooltip }} title={translate("general.delete")} placement="left">
                                        <IconButton onClick={handleDeleteUser(user.userId)} size="small">
                                            <DeleteIcon id={"trainingCertificationFormUserSelectorDeleteIconId"} />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
            </List>
            {!userIdSelected && (
                <div className={classes.containerFab}>
                    <UserSelectorDialog
                        disabled={disabled}
                        excludedUserIdsFromTheList={userIdToExclude}
                        onConfirmDialog={handleConfirmSeclection}
                        multiSelect={false}
                    />
                </div>
            )}
        </div>
    );
}

export default TrainingCertificationFormUserSelector;
