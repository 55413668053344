import { theme } from "@aos/react-components";
import ProcessVersionPicker from "@kortex/aos-ui/components/core/ProcessVersionPicker/ProcessVersionPicker";
import UserSearchBar from "@kortex/aos-ui/components/core/UserSelectorDialog/UserSearchBar";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Divider, ListItem, ListItemText, ListSubheader, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { useProcessTrainingContext } from "../context";

import { ProcessTrainingVersionInfoIcon } from "./processTrainingVersionInfoIcon";

const useStyles = makeStyles({
    listItem: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: "15px",
    },
    listItemText: {
        display: "flex",
        justifyContent: "center",
        width: "11%",
    },
    emptyCell: {
        width: "33%",
    },
    iconInfoLeft: {
        marginLeft: "10px",
    },
    listItemTextVersionTitle: {
        display: "flex",
        justifyContent: "center",
        width: "33%",
    },
    userSearch: {
        width: "33%",
    },
    userName: {
        width: "33%",
    },
    listItemTextVersion: {
        display: "flex",
        justifyContent: "center",
        width: "33%",
    },
    root: {
        backgroundColor: theme.palette.common.white,
    },
    processVersionPickerContainer: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    },
});

function ProcessTrainingHeader(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();
    const { filters, processTrainings, setFilters, selectProcess } = useProcessTrainingContext();

    const handleProcessVersionChange = (processId: number): void => {
        if (
            processId && // Since we exclude the "left" version, processId can be NaN
            processId !== processTrainings.right.originalProcessId
        ) {
            selectProcess(processId);
        }
    };

    return (
        <div className={classes.root} id={"processTrainingHeaderId"}>
            <ListSubheader component="div" disableGutters={true}>
                {/* --------------------------------------------------------------------------------------- */}
                {/* 1nd HEADER ROW ------------------------------------------------------------------------ */}
                {/* --------------------------------------------------------------------------------------- */}
                <ListItem className={classes.listItem} disableGutters={true}>
                    {/* EMPTY CELL */}
                    <ListItemText className={classes.emptyCell} />
                    <Divider flexItem={true} orientation="vertical" variant="middle" />
                    {/* LATEST APPROVED VERSION (LABEL) */}
                    <ListItemText className={classes.listItemTextVersionTitle}>
                        <Typography>{translate("training.latestApprovedVersion")}</Typography>
                    </ListItemText>
                    <Divider flexItem={true} orientation="vertical" variant="middle" />
                    {/* HISTORY (LABEL) */}
                    <ListItemText className={classes.listItemTextVersionTitle}>
                        <Typography>{translate("training.history")}</Typography>
                    </ListItemText>
                </ListItem>
                {/* --------------------------------------------------------------------------------------- */}
                {/* 2rd HEADER ROW ------------------------------------------------------------------------ */}
                {/* --------------------------------------------------------------------------------------- */}
                <ListItem className={classes.listItem} disableGutters={true}>
                    {/* search user */}
                    <ListItemText className={classes.userSearch}>
                        <UserSearchBar filters={filters} onFilterChange={setFilters} />
                    </ListItemText>
                    <Divider flexItem={true} orientation="vertical" variant="middle" />
                    {/* LATEST APPROVED VERSION */}
                    <ListItemText className={classes.listItemTextVersion}>
                        {processTrainings.treeNodeId ? (
                            <div className={classes.processVersionPickerContainer}>
                                <ProcessVersionPicker
                                    processId={processTrainings.left.originalProcessId}
                                    readOnly={true}
                                    treeNodeId={processTrainings.treeNodeId}
                                />
                                <ProcessTrainingVersionInfoIcon
                                    classes={{ iconInfo: classes.iconInfoLeft }}
                                    processTrainings={processTrainings.left}
                                />
                            </div>
                        ) : (
                            <Typography id={"typographyLatestApprovedVersionNotSelectedId"}>{translate("general.na")}</Typography>
                        )}
                    </ListItemText>
                    <Divider flexItem={true} orientation="vertical" variant="middle" />
                    {/* COMPARISON VERSION */}
                    <ListItemText className={classes.listItemTextVersion}>
                        {processTrainings.treeNodeId ? (
                            <div className={classes.processVersionPickerContainer}>
                                <ProcessVersionPicker
                                    excludedProcessIds={[processTrainings.left.originalProcessId]}
                                    onVersionChange={handleProcessVersionChange}
                                    processId={processTrainings.right.originalProcessId}
                                    treeNodeId={processTrainings.treeNodeId}
                                    variant="approved"
                                />
                                <ProcessTrainingVersionInfoIcon processTrainings={processTrainings.right} />
                            </div>
                        ) : (
                            <Typography id={"typographyComparisonVersionSelectedId"}>{translate("general.na")}</Typography>
                        )}
                    </ListItemText>
                </ListItem>
                {/* --------------------------------------------------------------------------------------- */}
                {/* 3th HEADER ROW ------------------------------------------------------------------------ */}
                {/* --------------------------------------------------------------------------------------- */}
                <ListItem className={classes.listItem} disableGutters={true}>
                    {/* USER FULL NAME */}
                    <ListItemText className={classes.userName}>
                        <Typography>{translate("training.userName")}</Typography>
                    </ListItemText>
                    <Divider flexItem={true} orientation="vertical" variant="middle" />
                    {/* TRAINED - LAST VERSION WITH TRAINING REQUIREMENT */}
                    <ListItemText className={classes.listItemText}>
                        <Typography>{translate("training.trained")}</Typography>
                    </ListItemText>
                    {/* CERTIFIED - LAST VERSION WITH TRAINING REQUIREMENT */}
                    <ListItemText className={classes.listItemText}>
                        <Typography>{translate("training.certified")}</Typography>
                    </ListItemText>
                    {/* SPACER FOR MORE-OPTIONS-BUTTON */}
                    <div className={classes.listItemText} />
                    <Divider flexItem={true} orientation="vertical" variant="middle" />
                    {/* TRAINED - CURRENT VERSION */}
                    <ListItemText className={classes.listItemText}>
                        <Typography>{translate("training.trained")}</Typography>
                    </ListItemText>
                    {/* CERTIFIED - CURRENT VERSION */}
                    <ListItemText className={classes.listItemText}>
                        <Typography>{translate("training.certified")}</Typography>
                    </ListItemText>
                    {/* SPACER FOR MORE-OPTIONS-BUTTON */}
                    <div className={classes.listItemText} />
                </ListItem>
            </ListSubheader>
        </div>
    );
}

export default ProcessTrainingHeader;
