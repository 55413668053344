import { theme } from "@aos/react-components";
import { IProcessTrainingCertificationForm, ProcessId } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Fab, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip, Typography, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import React, { useState } from "react";

import ProcessTrainingSelectorDialog from "./ProcessTrainingSelector";

const useStyles = makeStyles({
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
    listTitler: {
        padding: "5px",
    },
    processList: {
        display: "flex",
        flexDirection: "column",
    },
    headerContent: {
        width: "100%",
        backgroundColor: theme.palette.grey[200],
    },
    processName: {
        width: "80%",
    },
    version: {
        display: "flex",
        justifyContent: "center",
    },
    processListContent: {
        display: "flex",
    },
    containerFab: {
        height: "60px",
        display: "flex",
        justifyContent: "flex-end",
    },
    fab: {
        margin: "5px",
    },
});

interface IOwnProps {
    title: string;
    selectedProcesses: IProcessTrainingCertificationForm[];
    handleDeleteProcess: (processIdToBeDeleted: ProcessId) => void;
    handleProcessIdsSelected: (selectedProcessesVersionId: ProcessId[]) => void;
}

function TrainingFormProcessList(props: IOwnProps): JSX.Element {
    const { title, handleDeleteProcess, handleProcessIdsSelected, selectedProcesses } = props;
    const classes = useStyles();
    const translate = useTranslate();

    const [repoDialogOpen, setRepoDialogOpen] = useState<boolean>(false);

    /**
     * handle close repo dialog,
     */
    const handleCloseRepoDialog = (): void => {
        setRepoDialogOpen(false);
    };

    /**
     * handle open repo dialog,
     */
    const handleOpenRepoDialog = (): void => {
        setRepoDialogOpen(true);
    };

    /**
     * Deletes process
     */
    const handleDelete =
        (processIdToBeDeleted: ProcessId): (() => void) =>
        (): void => {
            handleDeleteProcess(processIdToBeDeleted);
        };

    return (
        <div id="processContainerId" className={classes.processList}>
            <Typography variant="h4" className={classes.listTitler}>
                {title}
            </Typography>
            <div id="processListContentId">
                <List
                    disablePadding={true}
                    /* ----------------------------------------------------------------------------------------------- */
                    /* HEADER ---------------------------------------------------------------------------------------- */
                    /* ----------------------------------------------------------------------------------------------- */
                    subheader={
                        <div className={classes.headerContent}>
                            <ListItem>
                                {/* PROCESS NAME */}
                                <ListItemText
                                    primary={<Typography variant="h6">{translate("training.processName")}</Typography>}
                                    className={classes.processName}
                                />
                                {/* VERSION OF THE PROCESS */}
                                <ListItemText
                                    primary={<Typography variant="h6">{translate("training.trainingVersion")}</Typography>}
                                    className={classes.version}
                                />
                                <ListItemSecondaryAction></ListItemSecondaryAction>
                            </ListItem>
                        </div>
                    }
                >
                    {/* ----------------------------------------------------------------------------------------------- */}
                    {/* PROCESS LIST --------------------------------------------------------------------------------- */}
                    {/* ----------------------------------------------------------------------------------------------- */}
                    {selectedProcesses.map((process, index) => (
                        <ListItem
                            key={index}
                            className={classes.processListContent}
                            style={{
                                backgroundColor: index % 2 === 0 ? theme.palette.common.white : theme.palette.grey[100],
                            }}
                        >
                            <ListItemText primary={process.treeNode.label} className={classes.processName} />
                            <ListItemText primary={process.process.version} className={classes.version} />
                            <ListItemSecondaryAction>
                                <Tooltip classes={{ tooltip: classes.tooltip }} title={translate("general.delete")} placement="left">
                                    <IconButton onClick={handleDelete(process.process.processId)} size="small">
                                        <DeleteIcon id={"processDeleteIconId"} />
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
                <div className={classes.containerFab}>
                    <Fab
                        className={classes.fab}
                        size="medium"
                        id="processSelectorDialogButtonId"
                        color="secondary"
                        onClick={handleOpenRepoDialog}
                    >
                        <AddIcon />
                    </Fab>
                    <ProcessTrainingSelectorDialog
                        onProcessesSelected={handleProcessIdsSelected}
                        onRepoHandleCloseDialog={handleCloseRepoDialog}
                        repoDialogOpen={repoDialogOpen}
                    />
                </div>
            </div>
        </div>
    );
}

export default TrainingFormProcessList;
