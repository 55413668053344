import { IOrgSettingDbModel, newOrgSetting, SystemRightsEnum } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import Paper from "@material-ui/core/Paper";
import * as React from "react";
import { useEffect, useState } from "react";

import { useDebounce } from "../../../../hooks/useDebounce";
import { useEntitiesSettingOrganizations } from "../../../../redux/effects";
import { settingOrganizationUpdate } from "../../../../redux/setting-organization-manager/setting-organization-thunks-settings";
import { IUserRightsProps, userCanWrite } from "../../../../utilitites/IUserRights";

import GlobalSettingGeneralSettings from "./GlobalSettingGeneralSettings";
import GlobalSettingLocalization from "./GlobalSettingLocalization";
import GlobalSettingProcessApprovals from "./GlobalSettingProcessApprovals";
import GlobalSettingProcessReleases from "./GlobalSettingProcessReleases";
import { globalSettingsStyles } from "./GlobalSettingsCommon";
import { GlobalSettingsTraining } from "./GlobalSettingsTraining";
import GlobalSettingUserAndPassword from "./GlobalSettingUserAndPassword";
//import GlobalSettingPatch from "./GlobalSettingPatch";

type IAllProps = IUserRightsProps<SystemRightsEnum>;

function GlobalSettings(props: IAllProps): JSX.Element {
    const classes = globalSettingsStyles();

    const { userAccessLevel } = props;

    // FIXME: this is temporary; as we will have more than a setting
    //        and it will not be hardcoded
    const orgInfo = useEntitiesSettingOrganizations()[0];
    const dispatch = useThunkDispatch();

    const [updatedFlag, setUpdatedFlag] = useState(false);
    const [editedOrgInfo, setEditedOrgInfo] = useState<IOrgSettingDbModel>(orgInfo || newOrgSetting());
    const debouncedOrgInfo = useDebounce(editedOrgInfo, 500);
    const [expandedSetting, setExpandedSetting] = useState("GlobalSettingGeneralSettings");

    const userCanEdit = userCanWrite(userAccessLevel);

    /*
     * Effect trigged when organization info changed
     */
    useEffect((): void => {
        setEditedOrgInfo(orgInfo || newOrgSetting());
    }, [orgInfo]);

    /*
     * Effect trigged when debouncing event conclude
     */
    useEffect((): void => {
        if (updatedFlag) {
            setUpdatedFlag(false);
            dispatch(settingOrganizationUpdate(debouncedOrgInfo));
        }
    }, [debouncedOrgInfo]);

    /**
     * Handle change on settings
     *
     * @param {keyof IOrgSettingDbModel} setting - setting that changes
     * @param {string | string[]} value - change on settings
     */
    const handleChange = <Key extends keyof IOrgSettingDbModel>(setting: Key, value: IOrgSettingDbModel[Key]): void => {
        const updatedOrg = { ...editedOrgInfo, [setting]: value };

        // remove new defaultLoc from customLocations
        if (setting === "defaultLoc") {
            updatedOrg.customLocations = updatedOrg.customLocations.filter((loc) => loc !== value);
        }

        setEditedOrgInfo(updatedOrg);
        setUpdatedFlag(true);
    };

    /**
     * Handle collapse change
     *
     * @param {keyof IOrgSettingDbModel} settingName - setting group that change
     * @param {boolean} expanded - true if expanded
     */
    const handleCollapseChange = (settingName: string, expanded: boolean): void => {
        if (expanded) {
            setExpandedSetting(settingName);
        } else {
            setExpandedSetting("");
        }
    };

    // Temp handler use when a patch needs to be added... Re-enable when needed
    /*const handleApplyPatch = (): void => {
        client.services.uncategorized.patch(emptyObject)();
    };*/

    return (
        <div className={classes.root}>
            <Paper className={classes.tab} id="generalSettingsPageId">
                <GlobalSettingGeneralSettings
                    organizationInfo={editedOrgInfo}
                    onUpdate={handleChange}
                    userCanEdit={userCanEdit}
                    expanded={expandedSetting === "GlobalSettingGeneralSettings"}
                    onCollapseChange={handleCollapseChange}
                />
                <GlobalSettingUserAndPassword
                    organizationInfo={editedOrgInfo}
                    onUpdate={handleChange}
                    userCanEdit={userCanEdit}
                    expanded={expandedSetting === "GlobalSettingUserAndPassword"}
                    onCollapseChange={handleCollapseChange}
                />
                <GlobalSettingLocalization
                    organizationInfo={editedOrgInfo}
                    onUpdate={handleChange}
                    userCanEdit={userCanEdit}
                    expanded={expandedSetting === "GlobalSettingLocalization"}
                    onCollapseChange={handleCollapseChange}
                />
                <GlobalSettingProcessApprovals
                    organizationInfo={editedOrgInfo}
                    onUpdate={handleChange}
                    expanded={expandedSetting === "GlobalSettingProcessApprovals"}
                    onCollapseChange={handleCollapseChange}
                    userCanEdit={userCanEdit}
                />
                <GlobalSettingProcessReleases
                    organizationInfo={editedOrgInfo}
                    onUpdate={handleChange}
                    expanded={expandedSetting === "GlobalSettingProcessReleases"}
                    onCollapseChange={handleCollapseChange}
                    userCanEdit={userCanEdit}
                />
                <GlobalSettingsTraining
                    expanded={expandedSetting === "GlobalSettingTraining"}
                    onUpdate={handleChange}
                    onCollapseChange={handleCollapseChange}
                    trainingSettings={{
                        trainingEnabled: editedOrgInfo.trainingEnabled,
                        trainingInactivityExcludedUserGroupIds: editedOrgInfo.trainingInactivityExcludedUserGroupIds,
                        trainingInactivityScheduledEvery: editedOrgInfo.trainingInactivityScheduledEvery,
                        trainingInactivityScheduledEveryUnit: editedOrgInfo.trainingInactivityScheduledEveryUnit,
                        trainingInactivityStartDate: editedOrgInfo.trainingInactivityStartDate,
                        trainingInactivityTimeout: editedOrgInfo.trainingInactivityTimeout,
                        trainingInactivityTimeoutUnit: editedOrgInfo.trainingInactivityTimeoutUnit,
                    }}
                    userCanEdit={userCanEdit}
                />

                {/* PATH ON PREVIOUS VERSION, could be used for new patch in the future */}
                {/* <GlobalSettingPatch
                    expanded={expandedSetting === "GlobalSettingPatch"}
                    onCollapseChange={handleCollapseChange}
                /> */}
            </Paper>
        </div>
    );
}

export default GlobalSettings;
