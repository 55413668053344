import { theme } from "@aos/react-components";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Typography, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { useBomContext } from "../context/bomContext";

import { BomAdjustmentType } from "./adjustmentType";
import { BomJob } from "./job";
import { BomPartNumber } from "./partNumber";
import { BomTraceability } from "./traceability";
import { BomTrackingId } from "./trackingId";

const useStyles = makeStyles({
    root: {
        height: "calc(100vh - 179px)", // 64px (app header) + 83px (page header) + 32px (padding)
        overflowY: "auto",
    },
    rootEmpty: {
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.grey["400"]}`,
        display: "flex",
        justifyContent: "center",
        padding: "16px",
    },
});

const BomBody: FC = () => {
    const classes = useStyles();
    const { search } = useBomContext();

    const translate = useTranslate();

    const renderBomData = (): JSX.Element => {
        switch (search.type) {
            case "adjustmentType":
                return <BomAdjustmentType />;
            case "job":
                return <BomJob />;
            case "partNumber":
                return <BomPartNumber />;
            case "traceability":
                return <BomTraceability />;
            case "trackingId":
                return <BomTrackingId />;
            case "none":
            default:
                return (
                    <div className={classes.rootEmpty}>
                        <Typography variant="body1">{translate("bomPage.noData")}</Typography>
                    </div>
                );
        }
    };

    return <div className={classes.root}>{renderBomData()}</div>;
};

export default BomBody;
