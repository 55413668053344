import { greyPalette, theme } from "@aos/react-components";
import { TrainingDbModel } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { IProcessTrainingPanelSide } from "@kortex/aos-ui/redux/process-training-manager/process-training-types";
import { useSelectorUsersRolesRights } from "@kortex/aos-ui/redux/selectors";
import { userCanArchive, userCanWrite } from "@kortex/aos-ui/utilitites/IUserRights";
import { Checkbox, IconButton, Menu, MenuItem, PopoverPosition, Tooltip, makeStyles } from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { FC, useState } from "react";

import { TrainingArchiveConfirmationDialog } from "../../../../trainingArchiveConfirmationDialog";
import { useProcessTrainingContext } from "../../../context";

const useStyles = makeStyles({
    checkboxTrainingRoot: {
        pointerEvents: "none",
    },
    iconDeprecated: {
        height: "24px",
        width: "24px",
    },
    iconDeprecatedContainer: {
        alignItems: "center",
        display: "flex",
        height: "48px",
        justifyContent: "center",
        width: "48px",
    },
    listItemSection: {
        display: "flex",
        width: "33%",
    },
    listItemSubSection: {
        display: "flex",
        justifyContent: "center",
        width: "33%",
    },
    moreIconSection: {
        justifyContent: "flex-end",
    },
    tooltip: {
        fontSize: "1.1rem",
        backgroundColor: greyPalette[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
    },
});

interface IOwnProps {
    index: number;
    panel: IProcessTrainingPanelSide;
    training: TrainingDbModel | null;
    userFullName: string;
}

const ProcessTraining: FC<IOwnProps> = (props) => {
    const { index, panel, training, userFullName } = props;

    const { processTrainings, updateTrainingStatus } = useProcessTrainingContext();
    const classes = useStyles();
    const translate = useTranslate();
    const userRoleRightsTraining = useSelectorUsersRolesRights("training");

    const [archiveDialogOpened, setArchiveDialogOpened] = useState<boolean>(false);
    const [menuPosition, setMenuPosition] = useState<PopoverPosition | null>(null);

    const displayCheckBoxes = training && training.status !== "archived";
    const readOnly = !userCanWrite(userRoleRightsTraining);
    const deprecateDisabled = !userCanArchive(userRoleRightsTraining);
    // || (panel === "right" && (!Boolean(latest) || latest?.status === "archived"));

    const handleArchiveTrainingClose = (): void => {
        setArchiveDialogOpened(false);
    };

    const handleArchiveTrainingConfirm = (): void => {
        handleArchiveTrainingClose();

        if (!props.training) return void 0;

        updateTrainingStatus(props.training.trainingId, "archived");
    };

    const handleMenuClose = (): void => {
        setMenuPosition(null);
    };

    const handleMoreClick = (event: React.MouseEvent): void => {
        setMenuPosition({
            left: event.clientX,
            top: event.clientY,
        });
    };

    const handleDeprecateTraining = (): void => {
        handleMenuClose();

        if (deprecateDisabled || !props.training) return void 0;

        updateTrainingStatus(props.training.trainingId, props.training.status === "deprecated" ? "active" : "deprecated");
    };

    const handleOpenArchiveDialog = (): void => {
        handleMenuClose();

        setArchiveDialogOpened(true);
    };

    return (
        <div className={classes.listItemSection}>
            {/* CHECKBOX - TRAINED */}
            <div className={classes.listItemSubSection}>
                {displayCheckBoxes ? (
                    <Checkbox
                        checked={training.trained}
                        classes={{ root: classes.checkboxTrainingRoot }}
                        id={`${panel}ProcessTrainingTrainedCheckbox${index}Id`}
                        inputProps={{ readOnly: true }}
                    />
                ) : null}
            </div>
            {/* CHECKBOX - CERTIFIED */}
            <div className={classes.listItemSubSection}>
                {displayCheckBoxes ? (
                    <Checkbox
                        checked={training.certified}
                        classes={{ root: classes.checkboxTrainingRoot }}
                        id={`${panel}ProcessTrainingCertifiedCheckbox${index}Id`}
                        inputProps={{ readOnly: true }}
                    />
                ) : null}
            </div>
            <div className={`${classes.listItemSubSection} ${classes.moreIconSection}`}>
                {displayCheckBoxes ? (
                    <>
                        {/* STATUS ICON - DEPRECATED */}
                        {training.status === "deprecated" ? (
                            <Tooltip
                                classes={{ tooltip: classes.tooltip }}
                                title={translate("training.tooltipTrainingStatusDeprecated")}
                                placement="top"
                            >
                                <div className={classes.iconDeprecatedContainer}>
                                    <ArchiveIcon
                                        className={classes.iconDeprecated}
                                        color="error"
                                        id={`${panel}ProcessTrainingDeprecatedIcon${index}Id`}
                                    />
                                </div>
                            </Tooltip>
                        ) : null}
                        {/* MORE OPTIONS ICON */}
                        <IconButton disabled={readOnly} id={`${panel}ProcessTrainingMoreButton${index}Id`} onClick={handleMoreClick}>
                            <MoreVertIcon />
                        </IconButton>
                    </>
                ) : null}
            </div>
            {/* MORE OPTIONS MENU */}
            {displayCheckBoxes ? (
                <>
                    <Menu
                        anchorPosition={menuPosition ?? undefined}
                        anchorReference="anchorPosition"
                        id={`${panel}ProcessTrainingMoreMenu${index}Id`}
                        onClose={handleMenuClose}
                        open={menuPosition !== null}
                    >
                        {/* DEPRECATE TRAINING */}
                        <MenuItem
                            disabled={deprecateDisabled}
                            id={`${panel}ProcessTrainingMoreMenuDeprecated${index}Id`}
                            onClick={handleDeprecateTraining}
                        >
                            {training.status === "deprecated"
                                ? translate("training.moreOptions.maintain")
                                : translate("training.moreOptions.noLongerMaintain")}
                        </MenuItem>
                        {/* ARCHIVE TRAINING */}
                        <MenuItem
                            disabled={!userCanArchive(userRoleRightsTraining)}
                            id={`${panel}ProcessTrainingMoreMenuArchive${index}Id`}
                            onClick={handleOpenArchiveDialog}
                        >
                            {translate("training.moreOptions.archive")}
                        </MenuItem>
                    </Menu>
                    {/* ARCHIVE TRAINING DIALOG */}
                    <TrainingArchiveConfirmationDialog
                        certified={training.certified}
                        onCancel={handleArchiveTrainingClose}
                        onConfirm={handleArchiveTrainingConfirm}
                        open={archiveDialogOpened}
                        processLabel={processTrainings[panel]?.label}
                        processVersion={processTrainings[panel].version || translate("general.na")}
                        trained={training.trained}
                        userName={userFullName}
                    />
                </>
            ) : null}
        </div>
    );
};

export default ProcessTraining;
