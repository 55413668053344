import { upsertObjectFromArray } from "@kortex/aos-common";
import { deepClone } from "@kortex/utilities";
// import { deepClone } from "@kortex/utilities";

import { TaskState, TaskActions, TaskActionType } from "./task-types";

const initialState: TaskState = {
    tasks: [],
};

/* upsertTasksOnTasks: Special upsert for task.
 *   For unknown reason for now, upsertObjectFromArray cannot be used, so manual upsert has been done for this state
 *
 * @param {TaskState} state - current state containing tasks.
 * @param {TaskUnknown[]} tasks - tasks to be upserted.
 *
 * @return {TaskState} - new state with the addition of update
 */ export function taskReducer(state: TaskState = initialState, action: TaskActions): TaskState {
    switch (action.type) {
        case TaskActionType.SET_TASK_LIST:
            return {
                ...state,
                tasks: action.value,
            };
        case TaskActionType.INSERT_TASK: {
            return {
                ...state,
                tasks: upsertObjectFromArray(state.tasks, action.value, function (variable) {
                    return variable.taskId === this.taskId;
                }),
            };
        }
        case TaskActionType.UPDATE_TASK: {
            return {
                ...state,
                tasks: upsertObjectFromArray(state.tasks, action.value, function (variable) {
                    return variable.taskId === this.taskId;
                }),
            };
        }
        case TaskActionType.DELETE_TASK: {
            let tasksCleaned = deepClone(state.tasks);
            for (const taskToDelete of action.value) {
                tasksCleaned = tasksCleaned.filter((task) => task.taskId !== taskToDelete.taskId);
            }
            return {
                ...state,
                tasks: tasksCleaned,
            };
        }
        default:
            return state;
    }
}
