import { IUserRoleDbModel, UnwrapAOSPayload, UserRoleAllRes } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { StandardThunk, AppState, StandardDispatch } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";

import { setRoleListAction, userRoleInsertedAction, userRoleUpdatedAction, userRoleDeletedAction } from "./users-actions";

// thunks
/**
 * Retrieves roles
 *
 * @param {IStandardThunkOptions} [options] - options
 */
export function userRoleGetAll(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<UserRoleAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<UserRoleAllRes>> {
        try {
            if (!skipLookup) {
                if (fetchedOnce.was(userRoleGetAll)) {
                    return getState().user.roles;
                }
            }

            return api.services.userRoles
                .getAll(emptyObject)()
                .then((userRoles) => {
                    if (!skipDispatch) {
                        fetchedOnce.seal(userRoleGetAll);

                        dispatch(setRoleListAction([...userRoles]));
                    }

                    return userRoles;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            return [];
        }
    };
}

export function userRoleInsert(insertedUserRole: IUserRoleDbModel): StandardThunk<IUserRoleDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IUserRoleDbModel | undefined> => {
        try {
            return api.services.userRoles
                .insert(insertedUserRole)()
                .then((insertedUserRole) => {
                    dispatch(userRoleInsertedAction(insertedUserRole));
                    return insertedUserRole;
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
        return undefined;
    };
}

export function userRoleUpdate(updatedUserRole: APIPayload<"userRoles", "update">): StandardThunk<IUserRoleDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IUserRoleDbModel | undefined> => {
        try {
            return api.services.userRoles
                .update(updatedUserRole)()
                .then((updatedUserRole) => {
                    dispatch(userRoleUpdatedAction([updatedUserRole]));
                    return updatedUserRole;
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
        return undefined;
    };
}

export function userRoleDelete(toDeleteUserRole: APIPayload<"userRoles", "delete">): StandardThunk<IUserRoleDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IUserRoleDbModel | undefined> => {
        try {
            return api.services.userRoles
                .delete(toDeleteUserRole)()
                .then((deletedUserRole) => {
                    dispatch(userRoleDeletedAction([deletedUserRole]));
                    return deletedUserRole;
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
        return undefined;
    };
}
