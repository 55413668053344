var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function Shapes(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M9.5,2 C5.36594318,2 2,5.36593144 2,9.49999915 C2,13.4043873 5.00194091,16.6259779 8.81818182,16.9715915 L8.81818182,21.3181819 C8.81821947,21.694724 9.12345781,21.9999624 9.5,22 L21.3181818,22 C21.694724,21.9999624 21.9999624,21.694724 22,21.3181819 L22,9.50000142 C21.9999624,9.12345927 21.694724,8.81822096 21.3181818,8.81818332 L16.9715909,8.81818332 C16.6233705,5.00457011 13.402525,2.00000227 9.5,2.00000227 L9.5,2 Z M9.5,3.36363621 C12.8971,3.36363621 15.6363636,6.1028859 15.6363636,9.49999915 C15.6363636,12.8970919 12.8970977,15.6363644 9.5,15.6363644 C6.10290682,15.6363644 3.36363636,12.8970919 3.36363636,9.49999915 C3.36363636,6.1028859 6.10290227,3.36363621 9.5,3.36363621 Z M16.9715909,10.1818173 L20.6363636,10.1818173 L20.6363636,20.6363638 L10.1818182,20.6363638 L10.1818182,16.9715915 C13.7741205,16.6462506 16.6470023,13.7743191 16.9715909,10.1818195 L16.9715909,10.1818173 Z", id: "icons-shapes" })));
}
