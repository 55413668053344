import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import { AOSClientServiceRequestWrapped, SymptomLogInsertReq, SymptomLogInsertRes } from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { symptomLogInsert } from "./insert";

interface IAOSClientServiceWrappedSymptomLog {
    insert: AOSClientServiceRequestWrapped<SymptomLogInsertReq, SymptomLogInsertRes>;
}

type AOSClientServiceWrappedSymptomLog = IAOSClientServiceWrappedSymptomLog;

export function generateSymptomLogServiceClient(router: IRouterClient): AOSClientServiceWrappedSymptomLog {
    return {
        insert: AOSClientServiceWrapperUI.request(symptomLogInsert(router)),
    };
}

generateSymptomLogServiceClient.serviceId = 34;
