import {
    ProcessEditorRightsEnum,
    PARSER_REGEX_ACTION,
    ParserRegexExtract,
    ParserRegexSubstitution,
    ParserRegexValidation,
    TParserRegex,
    TParserRegexExtract,
    TParserRegexSubstitution,
} from "@kortex/aos-common";
import { theme } from "@aos/react-components";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Fragment } from "react";
import { KortexTextField } from "@aos/react-components";
import { ListItem, Typography } from "@material-ui/core";

import VariablePicker from "../../../../../../../components/core/VariablePicker/VariablePicker";
import { IUserRightsProps, userCanWrite } from "../../../../../../../utilitites/IUserRights";
import { useTranslate } from "../../../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    labelRegexFirstMatch: {
        color: theme.palette.error[500],
        marginLeft: "8px",
    },
    line: {
        display: "flex",
        marginBottom: "16px",
    },
    lineTextField: {
        flex: 1,
    },
    regexActionSelect: {
        marginBottom: "16px",
        width: "140px",
    },
    textField: {
        marginBottom: "16px",
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    onChanged: (regexProps: TParserRegex) => void;
    regexProps: TParserRegex;
}

export default function ParserRegexEditor(props: IOwnProps): JSX.Element {
    const { regexProps, onChanged, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Handles action value changes
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - select input change event
     */
    const handleActionValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        switch (event.target.value) {
            case PARSER_REGEX_ACTION.EXTRACT:
                onChanged(new ParserRegexExtract());
                break;
            case PARSER_REGEX_ACTION.SUBSTITUTION:
                onChanged(new ParserRegexSubstitution());
                break;
            case PARSER_REGEX_ACTION.VALIDATION:
                onChanged(new ParserRegexValidation());
                break;
        }
    };

    /**
     * Handles textfield value changes
     *
     * @param {keyof TParserRegexExtract} key - IParserRegexProps properties
     */
    const handleRegexExtractChange =
        (key: keyof Omit<TParserRegexExtract, "action">): ((value: string) => void) =>
        (value: string): void => {
            onChanged({
                ...regexProps,
                [key]: value,
            });
        };

    /**
     * Handles textfield value changes
     *
     * @param {keyof TParserRegexSubstitution} key - IParserRegexProps properties
     */
    const handleSubstitutionRegexChange =
        (key: keyof Pick<TParserRegexSubstitution, "flags" | "regex">): ((value: string) => void) =>
        (value: string): void => {
            const updatedRegexProps = regexProps as TParserRegexSubstitution;
            onChanged({
                ...updatedRegexProps,
                [key]: value,
            });
        };

    /**
     * Handles textfield value changes
     *
     * @param {string} value - value
     */
    const handleSubstitutionChange = (value: string): void => {
        const updatedRegexProps = regexProps as TParserRegexSubstitution;
        onChanged({
            ...updatedRegexProps,
            substitution: value,
        });
    };

    return (
        <Fragment>
            {/* REGEX ACTION */}
            <KortexTextField
                className={classes.regexActionSelect}
                label={translate("action.parser.regexAction")}
                onChange={handleActionValueChange}
                TextFieldProps={{
                    id: "parserEditorRegexActionId",
                    select: true,
                    disabled: readOnly,
                }}
                value={regexProps.action}
            >
                <ListItem value={PARSER_REGEX_ACTION.EXTRACT}>{translate("action.parser.regexExtract")}</ListItem>
                <ListItem value={PARSER_REGEX_ACTION.SUBSTITUTION}>{translate("action.parser.regexSubstitution")}</ListItem>
                <ListItem value={PARSER_REGEX_ACTION.VALIDATION}>{translate("action.parser.regexValidation")}</ListItem>
            </KortexTextField>
            {regexProps.action === PARSER_REGEX_ACTION.EXTRACT && (
                <Typography className={classes.labelRegexFirstMatch} variant="caption">
                    {translate("action.parser.regexExtractFirstMatch")}
                </Typography>
            )}
            <div className={classes.line}>
                {(regexProps.action === PARSER_REGEX_ACTION.SUBSTITUTION || regexProps.action === PARSER_REGEX_ACTION.VALIDATION) && (
                    <React.Fragment>
                        {/* REGEX */}
                        <VariablePicker
                            KortexTextFieldProps={{
                                className: classes.lineTextField,
                                label: translate("action.parser.regex"),
                                TextFieldProps: {
                                    id: "parserRegexEditorRegexId",
                                },
                            }}
                            onChange={handleSubstitutionRegexChange("regex")}
                            userAccessLevel={userAccessLevel}
                            value={regexProps.regex}
                        />

                        {/* FLAGS */}
                        <VariablePicker
                            KortexTextFieldProps={{
                                label: translate("action.parser.regexFlags"),
                                TextFieldProps: {
                                    id: "parserRegexEditorRegexFlagsId",
                                },
                            }}
                            onChange={handleSubstitutionRegexChange("flags")}
                            userAccessLevel={userAccessLevel}
                            value={regexProps.flags}
                        />
                    </React.Fragment>
                )}
            </div>

            {/* SUBSTITUTION */}
            {regexProps.action === PARSER_REGEX_ACTION.SUBSTITUTION && (
                <VariablePicker
                    KortexTextFieldProps={{
                        className: classes.textField,
                        label: translate("action.parser.regexSubstitution"),
                        TextFieldProps: {
                            id: "parserRegexEditorRegexSubstitutionId",
                            multiline: true,
                        },
                    }}
                    onChange={handleSubstitutionChange}
                    userAccessLevel={userAccessLevel}
                    value={regexProps.substitution}
                />
            )}

            {/* EXTRACT */}
            {regexProps.action === PARSER_REGEX_ACTION.EXTRACT && (
                <React.Fragment>
                    <div className={classes.line}>
                        {/* REGEX START */}
                        <VariablePicker
                            KortexTextFieldProps={{
                                className: classes.lineTextField,
                                label: translate("action.parser.regexStart"),
                                TextFieldProps: {
                                    id: "parserRegexEditorRegexStartId",
                                },
                            }}
                            onChange={handleRegexExtractChange("startRegex")}
                            userAccessLevel={userAccessLevel}
                            value={regexProps.startRegex}
                        />

                        {/* FLAGS START */}
                        <VariablePicker
                            KortexTextFieldProps={{
                                label: translate("action.parser.regexFlagsStart"),
                                TextFieldProps: {
                                    id: "parserRegexEditorRegexFlagsStartId",
                                },
                            }}
                            onChange={handleRegexExtractChange("startFlags")}
                            userAccessLevel={userAccessLevel}
                            value={regexProps.startFlags}
                        />
                    </div>
                    <div className={classes.line}>
                        {/* REGEX END */}
                        <VariablePicker
                            KortexTextFieldProps={{
                                className: classes.lineTextField,
                                label: translate("action.parser.regexEnd"),
                                TextFieldProps: {
                                    id: "parserRegexEditorRegexEndId",
                                },
                            }}
                            onChange={handleRegexExtractChange("endRegex")}
                            userAccessLevel={userAccessLevel}
                            value={regexProps.endRegex}
                        />

                        {/* FLAGS END */}
                        <VariablePicker
                            KortexTextFieldProps={{
                                label: translate("action.parser.regexFlagsEnd"),
                                TextFieldProps: {
                                    id: "parserRegexEditorRegexFlagsEndId",
                                },
                            }}
                            onChange={handleRegexExtractChange("endFlags")}
                            userAccessLevel={userAccessLevel}
                            value={regexProps.endFlags}
                        />
                    </div>
                </React.Fragment>
            )}
        </Fragment>
    );
}
