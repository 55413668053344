import { useEffect, useState } from "react";
/**
 * keybaordEventCode - See the [DOM Level 3 Events spec](https://www.w3.org/TR/uievents-key/#named-key-attribute-values). for possible values
 */
export function useKeyPressed(keybaordEventCode, disabled, options) {
    var _a = useState(false), keyPressed = _a[0], setKeyPressed = _a[1];
    // Effect that handles the eventListener based on the disabled prop
    useEffect(function () {
        var preventDefault = !Boolean(options === null || options === void 0 ? void 0 : options.skipPreventDefaultCall);
        var stopPropagation = !Boolean(options === null || options === void 0 ? void 0 : options.skipStopPropagationCall);
        var handleKeydown = function (e) {
            if (e.key === keybaordEventCode) {
                // Apply options
                if (preventDefault)
                    e.preventDefault();
                if (stopPropagation)
                    e.stopPropagation();
                // Update state
                if (!e.repeat)
                    setKeyPressed(true);
            }
        };
        var handleKeyup = function (e) {
            if (e.key === keybaordEventCode) {
                // Apply options
                if (preventDefault)
                    e.preventDefault();
                if (stopPropagation)
                    e.stopPropagation();
                // Update state
                setKeyPressed(false);
            }
        };
        if (disabled) {
            window.removeEventListener("keyup", handleKeyup);
            window.removeEventListener("keydown", handleKeydown);
            setKeyPressed(false);
            return;
        }
        else {
            window.addEventListener("keydown", handleKeydown);
            window.addEventListener("keyup", handleKeyup);
        }
        // destructor removes event listeners
        return function () {
            window.removeEventListener("keyup", handleKeyup);
            window.removeEventListener("keydown", handleKeydown);
        };
    }, [disabled]);
    if (disabled) {
        return false;
    }
    return keyPressed;
}
