import { deepClone } from "@kortex/utilities";
import { TaskStatusEnum, TaskTypeEnum } from "../../interfaces/models";
export class Task {
    /**
     * Construct the class based on a task database model
     */
    constructor(task) {
        this._model = deepClone(task);
    }
    /**
     * Task model
     */
    get model() {
        return this._model;
    }
    set model(props) {
        this._model = {
            ...this._model,
            ...props,
        };
    }
    /**
     * Checks if the task is a process approval task class
     *
     * @param {TTask<TaskTypeEnum, TaskInfo>} task - task
     */
    static isProcessApproval(task) {
        return task.type === Task.TYPE.PROCESS_APPROVAL;
    }
    /**
     * Checks if the task is a process approval task class
     *
     * @param {TTask<TaskTypeEnum, TaskInfo>} task - task
     */
    static isProcessReview(task) {
        return task.type === Task.TYPE.PROCESS_REVIEW;
    }
}
Task.STATUS = TaskStatusEnum;
Task.TYPE = TaskTypeEnum;
