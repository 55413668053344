import { KortexCard, KortexColorPicker, KortexDialogConfirmation, KortexTextField, greyPalette } from "@aos/react-components";
import { EnumTemplateIcon, IWorkInstructionsTemplateConfig, WorkInstructionsTemplateConfig } from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../../../hooks/useTranslate";
import { useEntitiesProcessActionSettings } from "../../../../../../../../redux/effects";
import { processActionSettingsGetAll } from "../../../../../../../../redux/process-manager/process-thunks-process-action-settings";
import WorkInstructionsTemplateIcon from "../Icon/WorkInstructionsTemplateIcon";

export const newTemplateTemporaryId = ".";

const useStyles = makeStyles({
    label: {
        marginRight: "16px",
    },
    dialogRow: {
        display: "flex",
    },
    cardPreview: {
        borderRight: "1px solid " + greyPalette[300],
        marginRight: "16px",
        maxWidth: "372px",
        display: "grid",
    },
    cardContainer: {
        padding: "0px 16px 0px 6px",
        height: "0px",
    },
    cardTitleRow: {
        alignItems: "flex-start",
        display: "flex",
        marginBottom: "16px",
    },
    overwriteAll: {
        alignItems: "center",
        display: "inline-flex",
    },
    settingsContainer: {
        width: "100%",
    },
    templateSelector: {
        marginRight: "16px",
    },
});

export interface IWorkInstructionsTemplateEditorProps {
    onCancel: () => void;
    onConfirm: (template: IWorkInstructionsTemplateConfig, overwriteAll: boolean) => void;
    open: boolean;
}

export default function WorkInstructionsTemplateEditor(props: IWorkInstructionsTemplateEditorProps): JSX.Element {
    const { onCancel, onConfirm, open } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const dispatch = useThunkDispatch();
    const processActionSettings = useEntitiesProcessActionSettings();
    const workInstructionsSettings = processActionSettings.find((settings) => settings.type === "core-work-instructions");

    const [color, setColor] = useState<string>("#000000");
    const [description, setDescription] = useState<string>("");
    const [label, setLabel] = useState<string>("");
    const [overwriteChecked, setOverwriteChecked] = useState<boolean>(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState<string>(newTemplateTemporaryId);

    useStopKeybindPropagation(open);

    /**
     * Effect which fetch action settings (which contain template list)
     */
    useEffect((): void => {
        if (open) {
            dispatch(processActionSettingsGetAll());
            resetStates();
        }
    }, [open]);

    /**
     * Function which handles the template editing cancelation
     */
    const handleCancel = (): void => {
        onCancel();
    };

    /**
     * Function which handles card color change
     *
     * @param {string} updatedColor - New color value
     */
    const handleColorChange = (updatedColor: string): void => {
        setColor(updatedColor);
    };

    /**
     * Function which handles the confirmation of creating/overwriting a template
     */
    const handleConfirm = (): void => {
        if (validateTemplateInfo()) {
            const template = new WorkInstructionsTemplateConfig();
            template.color = color;
            template.description = description;
            template.label = label;
            template.icon = EnumTemplateIcon.FULL;

            if (selectedTemplateId !== newTemplateTemporaryId) {
                template._id = selectedTemplateId;
            }

            onConfirm(template, overwriteChecked);
        }
    };

    /**
     * Function which handles card description change
     */
    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDescription(event.target.value);
    };

    /**
     * Function which handles card label change
     */
    const handleLabelChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLabel(event.target.value);
    };

    /**
     * Function which handles the overwrite checkbox's state change
     */
    const handleOverwriteChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setOverwriteChecked(event.target.checked);
    };

    /**
     * Function which handles the selection of a template from the fetched template list
     */
    const handleSelectedTemplateIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const id = event.target.value;
        setSelectedTemplateId(id);
        if (workInstructionsSettings) {
            const selectedTemplate = workInstructionsSettings.settings.templates.find(
                (template: IWorkInstructionsTemplateConfig): boolean => template._id === id
            );
            if (selectedTemplate) {
                setColor(selectedTemplate.color);
                setDescription(selectedTemplate.description);
                setLabel(selectedTemplate.label);
            } else {
                resetStates();
            }
        }
    };

    /**
     * Function which resets all card's state to initial values
     */
    const resetStates = (): void => {
        setSelectedTemplateId(newTemplateTemporaryId);
        setColor("#000000");
        setDescription("");
        setLabel("");
        setOverwriteChecked(false);
    };

    /**
     * Function which validates the card's infos
     */
    const validateTemplateInfo = (): boolean => {
        if (label.length < 3 || description.length < 3) {
            return false;
        }

        return true;
    };

    return (
        <KortexDialogConfirmation
            open={open}
            dialogProps={{
                maxWidth: "md",
                fullWidth: true,
            }}
            textLabels={{
                titleLabel: translate("action.workInstructions.template.editor.title"),
                cancelButtonLabel: translate("action.workInstructions.editDialog.cancel"),
                proceedButtonLabel:
                    selectedTemplateId === newTemplateTemporaryId
                        ? translate("action.workInstructions.template.editor.save")
                        : translate("action.workInstructions.template.editor.overwrite"),
            }}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            closeOnEscape={true}
            confirmDisabled={!validateTemplateInfo()}
        >
            <div className={classes.dialogRow}>
                <div className={classes.cardPreview}>
                    <KortexTextField
                        className={classes.templateSelector}
                        label={translate("action.workInstructions.template.editor.saveAs")}
                        onChange={handleSelectedTemplateIdChange}
                        TextFieldProps={{
                            select: true,
                        }}
                        value={selectedTemplateId}
                    >
                        {[
                            <MenuItem key={0} value={newTemplateTemporaryId}>
                                {translate("action.workInstructions.template.editor.newTemplate")}
                            </MenuItem>,
                        ].concat(
                            workInstructionsSettings?.settings.templates.map(
                                (template: IWorkInstructionsTemplateConfig, index: number): JSX.Element => (
                                    <MenuItem key={index + 1} value={template._id}>
                                        {template.label}
                                    </MenuItem>
                                )
                            ) ?? []
                        )}
                    </KortexTextField>
                    <div className={classes.cardContainer}>
                        <KortexCard
                            color={color}
                            icon={
                                <WorkInstructionsTemplateIcon
                                    icon={EnumTemplateIcon.FULL}
                                    style={{
                                        color: "white",
                                        fontSize: 40,
                                    }}
                                />
                            }
                            key={"templateCardPreview"}
                            textLabels={{
                                label,
                                description,
                            }}
                        />
                    </div>
                    <div />
                </div>
                <div className={classes.settingsContainer}>
                    <div className={classes.cardTitleRow}>
                        <KortexTextField
                            className={classes.label}
                            error={label.length < 3 ? translate("action.workInstructions.template.editor.labelRequired") : undefined}
                            label={translate("action.workInstructions.template.editor.label")}
                            value={label}
                            onChange={handleLabelChange}
                            TextFieldProps={{
                                id: "workInstructionsTemplateLabelId",
                            }}
                        />
                        <KortexColorPicker
                            color={color}
                            onChange={handleColorChange}
                            textLabels={{ inputLabel: translate("action.workInstructions.template.editor.color") }}
                        />
                    </div>
                    <KortexTextField
                        className={classes.dialogRow}
                        error={
                            description.length < 3 ? translate("action.workInstructions.template.editor.descriptionRequired") : undefined
                        }
                        label={translate("action.workInstructions.template.editor.description")}
                        value={description}
                        onChange={handleDescriptionChange}
                        TextFieldProps={{
                            id: "workInstructionsTemplateDescriptionId",
                            multiline: true,
                            rows: 10,
                        }}
                    />
                    {selectedTemplateId !== newTemplateTemporaryId && (
                        <div className={classes.overwriteAll}>
                            <Checkbox checked={overwriteChecked} onChange={handleOverwriteChange} />
                            <Typography>{translate("action.workInstructions.template.editor.applyToAll")}</Typography>
                        </div>
                    )}
                </div>
            </div>
        </KortexDialogConfirmation>
    );
}
