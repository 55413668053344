import { theme } from "@aos/react-components";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import { clearTrainingReadingCertificatePending } from "@kortex/aos-ui/redux/training-reading-certificate-pending-manager/training-reading-certificate-pending-thunks";
import { Backdrop, CircularProgress, List, ListSubheader, makeStyles } from "@material-ui/core";
import React, { FC, useEffect } from "react";

import { useReadingCertificateContext } from "./context";
import { UserCard } from "./userCard";
import { UserHeaderCard } from "./userHeaderCard";

const useStyles = makeStyles({
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 4,
    },
    root: {
        marginTop: "3px",
        padding: "0px 10px",
        height: "calc(100vh - 132px)", // Header (64px) + Tabs (48px) + margins (20px)
        overflow: "auto",
    },
});

const ReadingCertificateTab: FC = () => {
    const classes = useStyles();
    const context = useReadingCertificateContext();
    const dispatch = useThunkDispatch();
    const session = useSelectorUserSession();

    useEffect(() => {
        return () => {
            dispatch(clearTrainingReadingCertificatePending());
        };
    }, []);

    return (
        <div id={"trainingReadingCertificateTabId"}>
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={context.isDataLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <List
                    disablePadding={true}
                    /* ----------------------------------------------------------------------------------------------- */
                    /* HEADER ---------------------------------------------------------------------------------------- */
                    /* ----------------------------------------------------------------------------------------------- */
                    subheader={
                        <ListSubheader disableGutters={true}>
                            <UserHeaderCard />
                        </ListSubheader>
                    }
                >
                    {/* ----------------------------------------------------------------------------------------------- */}
                    {/* USER ROWS ------------------------------------------------------------------------------------- */}
                    {/* ----------------------------------------------------------------------------------------------- */}
                    {context.readingCertificatePendings
                        .sort((a, b) => {
                            if (a.user.userId === session?.userId) return -1;
                            if (b.user.userId === session?.userId) return 1;

                            const nameA = `${a.user.firstName} ${a.user.lastName}`.toLocaleLowerCase();
                            const nameB = `${b.user.firstName} ${b.user.lastName}`.toLocaleLowerCase();

                            if (nameA < nameB) return -1;
                            if (nameA > nameB) return 1;

                            return 0;
                        })
                        .map((readingCertificatePending, index) => (
                            <div key={`trainingReadingCertificate${index}Id`}>
                                <UserCard index={index} readingCertificatePending={readingCertificatePending} />
                                {readingCertificatePending.user.userId === context.observerId &&
                                    !context.isDataLoading &&
                                    context.hasNext && <context.Observer />}
                            </div>
                        ))}
                </List>
            </div>
        </div>
    );
};

export default ReadingCertificateTab;
