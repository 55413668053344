import { KortexTextField } from "@aos/react-components";
import { IWorkInstructionsFormInputConfig } from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../../../../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    fullWidth: {
        width: "100%",
    },
});

export interface IOwnProps extends IWorkInstructionsFormInputConfig {
    autoFocus?: boolean;
}

export default function WorkInstructionsFormInputApproval(props: IOwnProps): JSX.Element {
    const { itemState, itemProps, autoFocus } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const displayedLabel = itemState.label ? itemState.label : itemProps.label;

    /**
     * Called when a user interacts with the input
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - change event
     */
    const onStateChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        props.onStateChanged({ ...props.itemState, value: event.target.value });
    };

    return (
        <KortexTextField
            label={displayedLabel}
            className={classes.fullWidth}
            onChange={onStateChanged}
            value={itemState.value}
            TextFieldProps={{
                required: itemProps.required,
                autoFocus,
            }}
            type="password"
            error={itemState.errorKey ? translate("action.workInstructions.form.error." + itemState.errorKey) : ""}
        />
    );
}
