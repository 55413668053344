import { createIntl, createIntlCache } from "react-intl";

import { en } from "./en";
import { fr } from "./fr";

const intlCache = createIntlCache();

export const intlFr = createIntl({ locale: "fr", messages: fr }, intlCache);
export const intlEn = createIntl({ locale: "en", messages: en }, intlCache);

type Intl = ReturnType<typeof createIntl>;

interface IIntlMiddleware {
    intl: Intl;
    set: (intl: Intl) => void;
}

export const IntlMiddleware: IIntlMiddleware = {
    intl: intlEn,
    // setter to update the intl instance
    set: (intl) => {
        IntlMiddleware.intl = intl;
    },
};

export const formatMessage = function (id: string): string {
    return IntlMiddleware.intl.formatMessage({ id });
};
