import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    FailureSymptomsAllReq,
    FailureSymptomsAllRes,
    FailureSymptomsInsertReq,
    FailureSymptomsInsertRes,
    FailureSymptomsInsertedNotif,
    FailureSymptomsUpdatedNotif,
    FailureSymptomsUpdateReq,
    FailureSymptomsUpdateRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { failureSymptomsAll } from "./all";
import { failureSymptomsInsert } from "./insert";
import { failureSymptomsUpdate } from "./update";
import { failureSymptomsInserted } from "./inserted";
import { failureSymptomsUpdated } from "./updated";

interface IAOSClientServiceWrappedFailureSymptoms {
    getAll: AOSClientServiceRequestWrapped<FailureSymptomsAllReq, FailureSymptomsAllRes>;
    insert: AOSClientServiceRequestWrapped<FailureSymptomsInsertReq, FailureSymptomsInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<FailureSymptomsInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<FailureSymptomsUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<FailureSymptomsUpdateReq, FailureSymptomsUpdateRes>;
}

type AOSClientServiceWrappedFailureSymptoms = IAOSClientServiceWrappedFailureSymptoms;

// ADD_API_CALL Failure Symptoms
export function generateFailureSymptomsServiceClient(
    router: IRouterClient,
    notifier: EventEmitter
): AOSClientServiceWrappedFailureSymptoms {
    return {
        getAll: AOSClientServiceWrapperUI.request(failureSymptomsAll(router)),
        insert: AOSClientServiceWrapperUI.request(failureSymptomsInsert(router)),
        update: AOSClientServiceWrapperUI.request(failureSymptomsUpdate(router)),
        onInserted: AOSClientServiceWrapperUI.notification(failureSymptomsInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(failureSymptomsUpdated(router, notifier)),
    };
}

generateFailureSymptomsServiceClient.serviceId = 71;
