import { EventEmitter } from "events";
import Debug from "debug";
import { VariableTypeEnum } from "./variable-type-enum";
import { VariableManagerError } from "./variable-manager-error";
import { deepClone, isBoolean, isNumber, isObject, isString, roundTo } from "./utils";
const debug = Debug("aos:variable-manager");
export const NAMESPACE_SEPARATOR = "/";
const locales = {
    variableManager: {
        getAllError: "VARIABLE_MANAGER_GET_ALL_ERROR",
        setInvalidValueType: "VARIABLE_MANAGER_SET_INVALID_VALUE_TYPE",
    },
};
function create(configs) {
    let _disableTypeValidationOnAssignment = false;
    const _ee = new EventEmitter();
    const event = {
        change: "change",
    };
    const variableValidateMap = {
        [VariableTypeEnum.BOOLEAN]: (value) => {
            if (!isBoolean(value)) {
                throw new VariableManagerError(locales.variableManager.setInvalidValueType, `Tried to set a non-boolean value (${value}) to a boolean variable.`);
            }
        },
        [VariableTypeEnum.NUMBER]: (value) => {
            if (!isNumber(value)) {
                throw new VariableManagerError(locales.variableManager.setInvalidValueType, `Tried to set a non-number value (${value}) to a number variable.`);
            }
        },
        [VariableTypeEnum.OBJECT]: (value) => {
            if (isString(value)) {
                try {
                    JSON.parse(value);
                }
                catch (error) {
                    throw new VariableManagerError(locales.variableManager.setInvalidValueType, `Tried to set a non-parseable string value (${value}) to a object variable.`, error);
                }
            }
            else if (!isObject(value)) {
                throw new VariableManagerError(locales.variableManager.setInvalidValueType, `Tried to set a non-object value (${value}) to a object variable.`);
            }
        },
        [VariableTypeEnum.TEXT]: (value) => {
            if (!isString(value)) {
                throw new VariableManagerError(locales.variableManager.setInvalidValueType, `Tried to set a non-string value (${value}) to a string variable.`);
            }
        },
    };
    let _state;
    async function init(options) {
        if (configs.init) {
            _state = deepClone(await configs.init());
        }
        else {
            _state = {};
        }
        _disableTypeValidationOnAssignment = Boolean(options?.disableTypeValidationOnAssignment);
        return Promise.resolve();
    }
    function _assertValidVariableType(variable) {
        if (_disableTypeValidationOnAssignment) {
            return void 0;
        }
        if (Array.isArray(variable.value)) {
            for (const value of variable.value) {
                try {
                    const target = variableValidateMap[variable.type](value);
                }
                catch (error) {
                    throw new VariableManagerError(locales.variableManager.setInvalidValueType, `${error.message} Index: ${value}.`, error);
                }
            }
            return void 0;
        }
        return variableValidateMap[variable.type](variable.value);
    }
    function _emit(event, ...params) {
        return _ee.emit(event, ...params);
    }
    function _get() {
        return deepClone(_state);
    }
    function _set(namespace, variable, persistent) {
        _assertValidVariableType(variable);
        const variableClone = deepClone(variable);
        if (variableClone.type === VariableTypeEnum.NUMBER && variableClone.decimals !== undefined) {
            variableClone.value = Array.isArray(variableClone.value)
                ? variableClone.value.map((value) => roundTo(value, variableClone.decimals))
                : roundTo(variableClone.value, variableClone.decimals);
        }
        let currentState = _get();
        debug(`set:namespace=${namespace}:variable=${variableClone}`);
        if (!currentState[namespace]) {
            debug("set:namespace does not exist:adding it to the manager");
            currentState = Object.assign(currentState, { [namespace]: [] });
        }
        const variableToSet = currentState[namespace]?.find((existingVariable) => existingVariable.identifier === variableClone.identifier);
        const oldVariable = deepClone(variableToSet);
        if (!variableToSet) {
            debug("set:variable does not exist:adding it to the manager");
            currentState[namespace].push(variableClone);
            _state = currentState;
            _emit(event.change, namespace, variableClone.identifier, variableClone.value, undefined);
            if (persistent && configs.onSetVariablePersistent) {
                return configs.onSetVariablePersistent(namespace, variableClone);
            }
        }
        else {
            debug("set:updating variable manager");
            _state = currentState;
            if (variableToSet) {
                variableToSet.value = variableClone.value;
                if (oldVariable?.value !== variableToSet.value) {
                    debug("set:variable manager changed:emit onChange event");
                    _emit(event.change, namespace, variableClone.identifier, variableToSet.value, oldVariable?.value);
                    if (persistent && configs.onSetVariablePersistent) {
                        return configs.onSetVariablePersistent(namespace, variableToSet);
                    }
                }
            }
        }
        return Promise.resolve(void 0);
    }
    function getNamespaces() {
        return Object.keys(_state);
    }
    function getVariable(namespace, identifier) {
        return deepClone(_state[namespace]?.find((variable) => variable.identifier === identifier));
    }
    function deleteVariable(namespace, identifier, persistent) {
        if (_state[namespace]) {
            _state[namespace] = _state[namespace].filter((variable) => variable.identifier !== identifier);
            if (configs.onDeleteVariablePersistent && persistent) {
                return configs.onDeleteVariablePersistent(namespace, identifier);
            }
        }
        return Promise.resolve();
    }
    function deleteNamespaces(namespace, includeSubNamespaces, persistent) {
        if (_state[namespace]) {
            delete _state[namespace];
            if (includeSubNamespaces) {
                for (const stateNamespace in _state) {
                    if (stateNamespace.startsWith(namespace)) {
                        delete _state[stateNamespace];
                    }
                }
            }
            if (configs.onDeleteNamespacePersistent && persistent) {
                return configs.onDeleteNamespacePersistent(namespace, includeSubNamespaces);
            }
        }
        return Promise.resolve();
    }
    function getAllVariablesFromNamespace(namespace) {
        return _state[namespace] ? deepClone(_state[namespace]) : [];
    }
    function off(event, cb) {
        _ee.removeListener(event, cb);
    }
    function on(event, cb) {
        _ee.on(event, cb);
    }
    function reset() {
        _state = {};
        _ee.removeAllListeners();
    }
    async function setVariable(...params) {
        return _set(...params);
    }
    on("change", (_state, newValue, oldValue) => {
        debug(`/state changed from ${oldValue} to ${newValue}`);
    });
    return {
        getNamespaces,
        getVar: getVariable,
        getAllVars: getAllVariablesFromNamespace,
        off,
        on,
        reset,
        setVar: setVariable,
        deleteVar: deleteVariable,
        deleteNamespace: deleteNamespaces,
        init,
    };
}
export const VariableManagerFactory = {
    create,
};
