import { upsertObjectFromArray } from "@kortex/aos-common";

import { BomFollowUpActions, BomFollowUpState, BomFollowUpSymptomActionType } from "./bom-follow-up-types";

const initialState: BomFollowUpState = {
    bomFollowUpSymptom: [],
};

export function bomFollowUpReducer(state: BomFollowUpState = initialState, action: BomFollowUpActions): BomFollowUpState {
    switch (action.type) {
        // ------------------ BOM FOLLOW-UP SYMPTOM ----------------------------
        case BomFollowUpSymptomActionType.SET_BOM_FOLLOW_UP_SYMPTOM:
            return {
                ...state,
                bomFollowUpSymptom: [...action.value],
            };
        case BomFollowUpSymptomActionType.INSERT_BOM_FOLLOW_UP_SYMPTOM:
            return {
                ...state,
                bomFollowUpSymptom: upsertObjectFromArray(state.bomFollowUpSymptom, action.value, function (bomFollowUpSymptom) {
                    return bomFollowUpSymptom.bomFollowUpSymptomId === this.bomFollowUpSymptomId;
                }),
            };
        case BomFollowUpSymptomActionType.UPDATE_BOM_FOLLOW_UP_SYMPTOM:
            return {
                ...state,
                bomFollowUpSymptom: upsertObjectFromArray(state.bomFollowUpSymptom, action.value, function (bomFollowUpSymptom) {
                    return bomFollowUpSymptom.bomFollowUpSymptomId === this.bomFollowUpSymptomId;
                }),
            };
        default:
            return state;
    }
}
