const COLUMN_SEPARATOR = ",";
const ROW_SEPARATOR = "\r\n";
const UNICODE_BOM = "\uFEFF";
const wrapValue = (value) => `"${value}"`;
// eslint-disable-next-line quotes
const escapeValue = (value) => (value || "").replace(/"/, '""');
const toHeaderRow = (columns) => Object.keys(columns).map(escapeValue).map(wrapValue).join(COLUMN_SEPARATOR);
const toRow = (columns, item) => Object.values(columns)
    .map((field) => (typeof field === "function" ? field(item) : item[field]))
    .map(String)
    .map(escapeValue)
    .map(wrapValue)
    .join(COLUMN_SEPARATOR);
/**
 * Takes an array of objects and converts it to a CSV (https://tools.ietf.org/html/rfc4180)
 */
export const toCSV = function (data, columns) {
    const rows = [];
    rows.push(toHeaderRow(columns));
    for (const item of data) {
        rows.push(toRow(columns, item));
    }
    return UNICODE_BOM + rows.join(ROW_SEPARATOR);
};
export const toHrefCSV = function (data, columns) {
    return `data:text/csv;charset=utf-8;header=present,${encodeURIComponent(toCSV(data, columns))}`;
};
