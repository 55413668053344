import { JobStatusEnum } from "../../../enum/JobStatusEnum";
export function createJobDbModel() {
    return {
        jobId: 0,
        orderId: 0,
        status: JobStatusEnum.DRAFT,
        referenceId: "",
        customer: "",
        plannerId: 0,
        partNumber: "",
        jobRefId: "",
        qty: 1,
        qtyPassed: 0,
        infoJSON: { trackingNumbersList: [] },
        reworkId: 0,
        comments: "",
        requestedOn: 0,
        plannedOn: 0,
        scheduledOn: 0,
        startedOn: 0,
        endedOn: 0,
    };
}
export function createJobUiModel() {
    return {
        jobId: 0,
        orderId: 0,
        status: JobStatusEnum.DRAFT,
        referenceId: "",
        customer: "",
        plannerId: 0,
        partNumber: "",
        jobRefId: "",
        qty: 1,
        qtyPassed: 0,
        infoJSON: { trackingNumbersList: [] },
        reworkId: 0,
        comments: "",
        requestedOn: 0,
        plannedOn: 0,
        scheduledOn: 0,
        startedOn: 0,
        endedOn: 0,
        processList: [],
    };
}
