import { theme } from "@aos/react-components";
import { Tooltip, TooltipProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";

const useStyles = makeStyles({
    tooltip: {
        backgroundColor: theme.palette.grey[200],
        borderRadius: "5px",
        color: theme.palette.primary[500],
        fontSize: "1.1rem",
        fontWeight: 400,
        textAlign: "center",
        maxWidth: "99%",
        whiteSpace: "pre-line",
    },
});

interface IEllipsisTooltipProps extends Omit<TooltipProps, "classes" | "title"> {
    label: string;
}

type UseEllipsisTooltip = [(tooltipProps: IEllipsisTooltipProps) => JSX.Element, React.MutableRefObject<HTMLSpanElement | null>];

/**
 *
 */
export function useEllipsisTooltip(): UseEllipsisTooltip {
    const classes = useStyles();

    const [isLabelEllipsisActive, setIsLabelEllipsisActive] = useState<boolean>(false);

    const labelSpanRef = useRef<HTMLSpanElement | null>(null);

    /**
     * Check if the ellipsis is active on the label
     */
    useEffect((): void => {
        const element = labelSpanRef.current;

        if (!element) {
            setIsLabelEllipsisActive(false);

            return void 0;
        }

        setIsLabelEllipsisActive(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);
    });

    /**
     *
     */
    function tooltip(props: IEllipsisTooltipProps): JSX.Element {
        return (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={isLabelEllipsisActive ? props.label : ""} {...props}>
                {props.children}
            </Tooltip>
        );
    }

    return [tooltip, labelSpanRef];
}
