import { MessageLevelEnum } from "@kortex/aos-common";
import { Typography } from "@material-ui/core";
import { OptionsObject, ProviderContext, VariantType, useSnackbar as useNotistack } from "notistack";
import React from "react";

//----------------------------------------------------------
// Snackbar configuration
//----------------------------------------------------------
// These variables will be initialized if <SnackbarConfigurator> is a child of <SnackbarProvider>
let useSnackbarRef: ProviderContext;

export const SnackbarConfigurator: React.FC = () => {
    useSnackbarRef = useNotistack();

    return null;
};

//------------------------------------------------------------------------------------
// Context
//------------------------------------------------------------------------------------
interface ISnackbarContext {
    error: (message: string, options?: OptionsObject) => void;
    info: (message: string, options?: OptionsObject) => void;
    success: (message: string, options?: OptionsObject) => void;
    toast: (message: string, level?: MessageLevelEnum, options?: OptionsObject) => void;
    warning: (message: string, options?: OptionsObject) => void;
}

// Usable if <SnackbarConfigurator> is initialized
export function useSnackbar(): ISnackbarContext {
    return {
        error(message: string, options?: OptionsObject): void {
            return this.toast(message, MessageLevelEnum.ERROR, options);
        },
        info(message: string, options?: OptionsObject): void {
            return this.toast(message, MessageLevelEnum.INFO, options);
        },
        success(message: string, options?: OptionsObject): void {
            return this.toast(message, MessageLevelEnum.SUCCESS, options);
        },
        toast(message: string, level: MessageLevelEnum = MessageLevelEnum.OTHER, options?: OptionsObject): void {
            useSnackbarRef.enqueueSnackbar(<Typography variant="body2">{message}</Typography>, {
                ...DEFAULT_SNACKBAR_OPTIONS,
                ...options,
                variant: SNACKBAR_MESSAGE_LEVEL_TO_VARIANT_MAP[level],
            });
        },
        warning(message: string, options?: OptionsObject): void {
            return this.toast(message, MessageLevelEnum.WARNING, options);
        },
    };
}

//------------------------------------------------------------------------------------
// Usage outside a React component
//------------------------------------------------------------------------------------
// The following allows any functions (ex: API) outside a React component to use the snackbars
export default {
    error(message: string, options?: OptionsObject): void {
        return this.toast(message, MessageLevelEnum.ERROR, options);
    },
    info(message: string, options?: OptionsObject): void {
        return this.toast(message, MessageLevelEnum.INFO, options);
    },
    success(message: string, options?: OptionsObject): void {
        return this.toast(message, MessageLevelEnum.SUCCESS, options);
    },
    toast(message: string, level: MessageLevelEnum = MessageLevelEnum.OTHER, options?: OptionsObject): void {
        useSnackbarRef.enqueueSnackbar(<Typography variant="body2">{message}</Typography>, {
            ...DEFAULT_SNACKBAR_OPTIONS,
            ...options,
            variant: SNACKBAR_MESSAGE_LEVEL_TO_VARIANT_MAP[level],
        });
    },
    warning(message: string, options?: OptionsObject): void {
        return this.toast(message, MessageLevelEnum.WARNING, options);
    },
};

//------------------------------------------------------------------------------------
// Default values
//------------------------------------------------------------------------------------
// Convertion map: notistack variant to MessageLevelEnum
const SNACKBAR_MESSAGE_LEVEL_TO_VARIANT_MAP: Record<MessageLevelEnum, VariantType> = {
    [MessageLevelEnum.OTHER]: "default",
    [MessageLevelEnum.ERROR]: "error",
    [MessageLevelEnum.INFO]: "info",
    [MessageLevelEnum.SUCCESS]: "success",
    [MessageLevelEnum.WARNING]: "warning",
};

// Default snackbar options
const DEFAULT_SNACKBAR_OPTIONS: OptionsObject = {
    autoHideDuration: 20_000,
};
