import { KortexDialogConfirmation, theme } from "@aos/react-components";
import { ITreeNodeDbModel, ProcessApprovalStatusFilter, TreeNodeNodeTypeEnum, UserId } from "@kortex/aos-common";
import { TextFieldSelect } from "@kortex/aos-ui/components/core/TextFieldSelect";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesUsers } from "@kortex/aos-ui/redux/effects";
import { userTrainingInsert } from "@kortex/aos-ui/redux/user-training-manager/user-training-thunks";
import { Backdrop, CircularProgress, InputAdornment, List, ListSubheader, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { SearchUserIcon } from "../../../core/Icons/searchUser";
import ProcessRepository from "../../ProcessEditor/RepositoryManager/ProcessRepository";
import { useTrainingPageContext } from "../utilities/trainingPageContext";

import UserTrainingCard from "./UserTrainingCard";
import UserTrainingsCardHeader from "./UserTrainingsCardHeader";
import { useUserTrainingContext } from "./context";
import UserTrainingHistoryDialog from "./trainingHistoryDialog/UserTrainingHistoryDialog";
import { getUserTrainingStatusColor } from "./utilities/getUserTrainingStatusColor";

const useStyles = makeStyles({
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 4,
    },
    dialogContent: {
        height: "60vh",
        width: "100%",
    },
    input: {
        padding: "8px 8px",
    },
    root: {
        height: "calc(100vh - 155px)", // Header (64px) + Tabs (68px) + margins (23px)
        marginTop: "3px",
        overflow: "auto",
        padding: "0px 10px 10px 10px",
    },
    textField: {
        padding: "5px 5px",
    },
    user: {
        alignItems: "center",
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        paddingRight: "15px",
        whiteSpace: "normal",
        width: "100%",
    },
});

function UserTrainingTab(): JSX.Element {
    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const trainingPageContext = useTrainingPageContext();
    const userTrainingContext = useUserTrainingContext();
    const users = useEntitiesUsers();

    const [selectedTreeNodeIds, setSelectedTreeNodeIds] = useState<number[] | undefined>(undefined);
    const [selectedVersionIds, setSelectedVersionIds] = useState<number[] | undefined>(undefined);

    useEffect(() => {
        const handleChangeSelectedUser = (userId: UserId): void => {
            userTrainingContext.setTrainingFilters({ userId });
        };

        // Add user selector to training page header
        trainingPageContext.setCustomHeaderComponent(
            <div className={classes.user}>
                <TextFieldSelect
                    items={users.map((user) => {
                        const label = user.firstName + " " + user.lastName;

                        return {
                            component: <Typography>{label}</Typography>,
                            filterCallback: (value: string): boolean =>
                                value ? label.toLocaleLowerCase().includes(value.toLocaleLowerCase()) : true,
                            textFieldValue: label,
                            value: user.userId,
                        };
                    })}
                    KortexTextFieldProps={{
                        InputProps: {
                            classes: {
                                input: classes.input,
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchUserIcon />
                                </InputAdornment>
                            ),
                        },
                        label: "",
                        TextFieldProps: {
                            className: classes.textField,
                            id: "usernameInputId",
                        },
                    }}
                    onChange={handleChangeSelectedUser}
                    value={userTrainingContext.trainingFilters.userId}
                />
            </div>
        );

        return () => {
            trainingPageContext.setCustomHeaderComponent(null);
        };
    }, [users, userTrainingContext.trainingFilters.userId]);

    // ----------------------------------------------------------------------------------
    // ------------------- REPOSITORY ---------------------------------------------------
    // ----------------------------------------------------------------------------------

    /**
     * handles the cancel of the dialog
     */
    const handleCloseRepositoryDialog = (): void => {
        userTrainingContext.setOpenRepositoryDialog(false);
    };

    /**
     * Handles the selection change
     *
     * @param {ITreeNodeDbModel[]} treeNodes - selected treeNode
     */
    const handleSelectionChanged = (treeNodes: ITreeNodeDbModel[], processes?: (number | undefined)[]): void => {
        if (!treeNodes.length || !processes) return void 0;

        const treeNodeIds: number[] = [];
        const processIds: number[] = [];

        processes.forEach((process, index) => {
            if (process) processIds.push(process);
            treeNodeIds.push(treeNodes[index].treeNodeId);
        });

        setSelectedTreeNodeIds(treeNodeIds);
        setSelectedVersionIds(processIds);
    };

    /**
     * Called when the user accepts the confirmation dialog (after he has selected a process).
     */
    const handleSelectProcessConfirmation = (): void => {
        if (selectedTreeNodeIds && selectedVersionIds) {
            dispatch(
                userTrainingInsert({
                    processIds: selectedVersionIds,
                    userId: userTrainingContext.trainingFilters.userId,
                    treeNodeIds: selectedTreeNodeIds,
                })
            );
            handleCloseRepositoryDialog();
            setSelectedVersionIds(undefined);
        }
    };

    return (
        <>
            <div className={classes.root} id="userTrainingTabId">
                <Backdrop className={classes.backdrop} open={userTrainingContext.isDataLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <List
                    disablePadding={true}
                    /* ----------------------------------------------------------------------------------------------- */
                    /* HEADER ---------------------------------------------------------------------------------------- */
                    /* ----------------------------------------------------------------------------------------------- */
                    subheader={
                        <ListSubheader disableGutters={true}>
                            <UserTrainingsCardHeader />
                        </ListSubheader>
                    }
                >
                    {/* ----------------------------------------------------------------------------------------------- */}
                    {/* TRAINING ROWS --------------------------------------------------------------------------------- */}
                    {/* ----------------------------------------------------------------------------------------------- */}
                    {userTrainingContext.userTrainings
                        .sort((a, b) => {
                            // Compare by priority
                            const priorityComparison =
                                getUserTrainingStatusColor(a.current, a.latest).priority -
                                getUserTrainingStatusColor(b.current, b.latest).priority;

                            if (priorityComparison !== 0) {
                                return priorityComparison;
                            } else {
                                // Compare by process name
                                return a.treeNode.label.localeCompare(b.treeNode.label);
                            }
                        })
                        .map((userTraining, index) => (
                            <div key={`userTraining${index}`}>
                                <UserTrainingCard index={index} userTraining={userTraining} />
                            </div>
                        ))}
                </List>
            </div>
            <div>
                {/* ----------------------------------------------------------------------------------------------- */}
                {/* DIALOG ---------------------------------------------------------------------------------------- */}
                {/* ----------------------------------------------------------------------------------------------- */}
                <KortexDialogConfirmation
                    dialogProps={{
                        fullWidth: true,
                        maxWidth: "xl",
                        onBackdropClick: handleCloseRepositoryDialog,
                        keepMounted: false, // ensures the children are mounted on open and dismounted on close... useful for useForeground handling
                    }}
                    onCancel={handleCloseRepositoryDialog}
                    onConfirm={handleSelectProcessConfirmation}
                    closeOnEscape={true}
                    open={userTrainingContext.openRepositoryDialog}
                    textLabels={{
                        cancelButtonLabel: translate("general.cancel"),
                        proceedButtonLabel: translate("general.select"),
                        titleLabel: translate("training.processTraining"),
                    }}
                    confirmDisabled={!Boolean(selectedVersionIds && selectedVersionIds[0])}
                >
                    <div className={classes.dialogContent} id="processReposDialogId">
                        <ProcessRepository
                            onSelectionChanged={handleSelectionChanged}
                            preselectedTreeNodeId={selectedTreeNodeIds ? selectedTreeNodeIds[0] : undefined}
                            handleSelectProcessConfirmation={handleSelectProcessConfirmation}
                            filterType={TreeNodeNodeTypeEnum.PROCESS}
                            selectOnly={true}
                            showVersionVariant={ProcessApprovalStatusFilter.APPROVED_AND_NOT_RETIRED_RELEASED_SELECTED_BY_DEFAULT}
                            multiItemSelection={true}
                        />
                    </div>
                </KortexDialogConfirmation>
                <UserTrainingHistoryDialog
                    open={userTrainingContext.openHistoryDialog}
                    treeNodeId={userTrainingContext.historyTreeNodeIdSelected}
                />
            </div>
        </>
    );
}

export default UserTrainingTab;
