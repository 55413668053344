import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    TreeWorkZoneAllReq,
    TreeWorkZoneAllRes,
    TreeWorkZoneInsertReq,
    TreeWorkZoneInsertRes,
    TreeWorkZoneInsertedNotif,
    TreeWorkZoneUpdateReq,
    TreeWorkZoneUpdateRes,
    TreeWorkZoneUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { treeWorkZoneAll } from "./all";
import { treeWorkZoneInsert } from "./insert";
import { treeWorkZoneInserted } from "./inserted";
import { treeWorkZoneUpdate } from "./update";
import { treeWorkZoneUpdated } from "./updated";

interface IAOSClientServiceWrappedTreeWorkZone {
    getAll: AOSClientServiceRequestWrapped<TreeWorkZoneAllReq, TreeWorkZoneAllRes>;
    insert: AOSClientServiceRequestWrapped<TreeWorkZoneInsertReq, TreeWorkZoneInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<TreeWorkZoneInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<TreeWorkZoneUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<TreeWorkZoneUpdateReq, TreeWorkZoneUpdateRes>;
}

type AOSClientServiceWrappedTreeWorkZone = IAOSClientServiceWrappedTreeWorkZone;

// ADD_API_CALL Tree work zones
export function generateTreeWorkZoneServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedTreeWorkZone {
    return {
        getAll: AOSClientServiceWrapperUI.request(treeWorkZoneAll(router)),
        insert: AOSClientServiceWrapperUI.request(treeWorkZoneInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(treeWorkZoneInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(treeWorkZoneUpdated(router, notifier)),
        update: AOSClientServiceWrapperUI.request(treeWorkZoneUpdate(router)),
    };
}

generateTreeWorkZoneServiceClient.serviceId = 52;
