import { IReworkUiModel, LogInfo, ReworkLogAllRes, ReworkLogDbModelKey, UnwrapAOSPayload } from "@kortex/aos-common";

import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { reworkLogInsertedAction, reworkLogUpdatedAction, setReworkLogListAction } from "./rework-actions";

/**
 * Get all reworkLog
 */
export function reworkLogGetAll(rework: IReworkUiModel, options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<ReworkLogAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ReworkLogAllRes>> {
        if (!skipLookup) {
            if (fetchedOnce.was(reworkLogGetAll)) {
                return getState().rework.reworkLogs;
            }
        }

        return api.services.reworkLog
            .getAll(rework)()
            .then((reworkLog) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(reworkLogGetAll);

                    dispatch(setReworkLogListAction([...reworkLog]));
                }

                return reworkLog;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

/**
 * Get one reworkLog
 */
export function reworkLogGetOne(reworkLogId: ReworkLogDbModelKey): StandardThunk<LogInfo | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<LogInfo | undefined> => {
        try {
            const resp = await api.services.reworkLog
                .getOne(reworkLogId)()
                .then((reworkLog) => {
                    dispatch(reworkLogUpdatedAction([reworkLog]));
                    return reworkLog;
                });
            return resp;
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return undefined;
        }
    };
}

/**
 * Insert one reworkLog
 */
export function reworkLogInsert(reworkLog: APIPayload<"reworkLog", "insert">): StandardThunk<LogInfo | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<LogInfo | undefined> => {
        try {
            const resp = await api.services.reworkLog
                .insert(reworkLog)()
                .then((reworkLog) => {
                    dispatch(reworkLogInsertedAction([reworkLog]));
                    return reworkLog;
                });
            return resp;
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return undefined;
        }
    };
}

/**
 * Insert one symptom log
 */
export function symptomLogInsert(symptomLog: APIPayload<"symptomLog", "insert">): StandardThunk<LogInfo[] | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<LogInfo[] | undefined> => {
        try {
            const resp = await api.services.symptomLog
                .insert(symptomLog)()
                .then((reworkLogs) => {
                    const reworkLogsCopy = [...reworkLogs];
                    dispatch(reworkLogInsertedAction(reworkLogsCopy));
                    return reworkLogsCopy;
                });
            return resp;
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return undefined;
        }
    };
}

/**
 * Update reworkLog
 */
export function reworkLogUpdate(reworkLog: APIPayload<"reworkLog", "update">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.reworkLog
                .update(reworkLog)()
                .then((reworkLog) => {
                    dispatch(reworkLogUpdatedAction([reworkLog]));
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
    };
}
