import { ITreeNodeDbModel, ProcessApprovalStatusFilter, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { KortexDialogConfirmation } from "@aos/react-components";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import ProcessRepository from "../../pages/ProcessEditor/RepositoryManager/ProcessRepository";

const useStyles = makeStyles({
    root: {
        paddingTop: "5px",
    },
    dialogContent: {
        height: "60vh",
        width: "80vw",
        maxWidth: "1102px",
        overflowY: "auto",
    },
});

interface IOwnProps {
    open: boolean;

    onSelectedProcess: (processId?: number, node?: ITreeNodeDbModel) => void;
    onCancel: () => void;
}

export default function ProcessPicker(props: IOwnProps): JSX.Element {
    const { open } = props;
    const classes = useStyles();

    const translate = useTranslate();

    const [selectedProcessNode, setSelectedProcessNode] = useState<ITreeNodeDbModel | undefined>(undefined);
    const [selectedProcessId, setSelectedProcessId] = useState<number | undefined>(undefined);

    /**
     * Called when the user cancel the dialog
     */
    const handleCancel = (): void => {
        props.onCancel();
    };

    /**
     * Called when the user confirm selection of users
     */
    const handleConfirmSelection = (): void => {
        if (selectedProcessId) {
            props.onSelectedProcess(selectedProcessId, selectedProcessNode);
        }
    };

    /**
     * Handle that is called when an option in the dialog process repos is changed... Keep value in a state until apply is called
     *
     * @param {ITreeNodeDbModel[]} process - processes  and/or routings selected
     * @param {ITreeNodeDbModel[]} versions - version of the process
     */
    const handleSelectionChanged = async (process: ITreeNodeDbModel[], versions?: (number | undefined)[]): Promise<void> => {
        const treeNode = process.length > 0 ? process[0] : undefined;
        const processId = versions && versions.length > 0 ? versions[0] : undefined;

        setSelectedProcessNode(treeNode);
        setSelectedProcessId(processId);
    };

    return (
        <div className={classes.root}>
            <KortexDialogConfirmation
                open={open}
                dialogProps={{
                    fullWidth: false,
                    maxWidth: false,
                    id: "dialogConfirmationId",
                    onBackdropClick: handleCancel,
                }}
                textLabels={{
                    titleLabel: translate("scheduler.selectProcess"),
                    cancelButtonLabel: translate("scheduler.cancel"),
                    proceedButtonLabel: translate("scheduler.select"),
                }}
                onCancel={handleCancel}
                onConfirm={handleConfirmSelection}
                confirmDisabled={!Boolean(selectedProcessId)}
                closeOnEscape={true}
            >
                <div className={classes.dialogContent}>
                    {open && (
                        <ProcessRepository
                            filterType={TreeNodeNodeTypeEnum.PROCESS}
                            onSelectionChanged={handleSelectionChanged}
                            multiItemSelection={false}
                            selectOnly={true}
                            showVersionVariant={ProcessApprovalStatusFilter.APPROVED_AND_NOT_RETIRED}
                            showLatestVersion={false}
                            preselectedTreeNodeId={selectedProcessNode?.treeNodeId}
                            handleSelectProcessConfirmation={handleConfirmSelection}
                        />
                    )}
                </div>
            </KortexDialogConfirmation>
        </div>
    );
}
