import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import { AOSClientServiceRequestWrapped, KanbanInsertReq, KanbanInsertRes } from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { kanbanInsert } from "./insert";

interface IAOSClientServiceWrappedKanban {
    insert: AOSClientServiceRequestWrapped<KanbanInsertReq, KanbanInsertRes>;
}

type AOSClientServiceWrappedKanban = IAOSClientServiceWrappedKanban;

// ADD_API_CALL Kanban
export function generateKanbanServiceClient(router: IRouterClient): AOSClientServiceWrappedKanban {
    return {
        insert: AOSClientServiceWrapperUI.request(kanbanInsert(router)),
    };
}

generateKanbanServiceClient.serviceId = 140;
