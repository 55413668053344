import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

function Math(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M9.54761905,15.8095238 L9.54761905,16.7619048 L7.64285714,16.7619048 L7.64285714,18.6666667 L6.69047619,18.6666667 L6.69047619,16.7619048 L4.78571429,16.7619048 L4.78571429,15.8095238 L6.69047619,15.8095238 L6.69047619,13.9047619 L7.64285714,13.9047619 L7.64285714,15.8095238 L9.54761905,15.8095238 Z M4.78571429,6.92857143 L9.54761905,6.92857143 L9.54761905,7.88095238 L4.78571429,7.88095238 L4.78571429,6.92857143 Z M14.2380952,17.4761905 L19,17.4761905 L19,18.4285714 L14.2380952,18.4285714 L14.2380952,17.4761905 Z M14.2380952,14.8571429 L19,14.8571429 L19,15.8095238 L14.2380952,15.8095238 L14.2380952,14.8571429 Z M14.5952381,8.73809524 L15.9285714,7.4047619 L14.5952381,6.04761905 L15.2619048,5.38095238 L16.6190476,6.71428571 L17.952381,5.38095238 L18.6190476,6.04761905 L17.2857143,7.4047619 L18.6190476,8.73809524 L17.952381,9.42857143 L16.6190476,8.07142857 L15.2619048,9.42857143 L14.5952381,8.73809524 Z M19.7830178,2 C21.0076676,2 22.0039261,2.99606299 21.9999884,4.22047244 L21.9999884,19.7795276 C21.9999884,21.003937 21.0037298,22 19.77908,22 L4.22090834,22 C2.99625853,22 2,21.003937 2,19.7755906 L2,4.22047244 C2,2.99606299 2.99625853,2 4.22090834,2 L19.7830178,2 Z M11.446736,20.8897638 L11.446736,12.5551181 L3.11045417,12.5551181 L3.11045417,19.7795276 C3.11045417,20.3897638 3.60661454,20.8897638 4.22090834,20.8897638 L11.446736,20.8897638 Z M11.446736,11.4448819 L11.4427982,11.4448819 L11.4427982,3.11023622 L4.22090834,3.11023622 C3.61055232,3.11023622 3.11045417,3.60629921 3.11045417,4.22047244 L3.11045417,11.4448819 L11.446736,11.4448819 Z M20.893472,19.7795276 L20.893472,12.5551181 L12.5571902,12.5551181 L12.5571902,20.8897638 L19.7830178,20.8897638 C20.3933738,20.8897638 20.893472,20.3937008 20.893472,19.7795276 Z M20.893472,11.4448819 L20.8895342,11.4448819 L20.8895342,4.22047244 C20.8895342,3.61023622 20.3933738,3.11023622 19.77908,3.11023622 L12.5571902,3.11023622 L12.5571902,11.4448819 L20.893472,11.4448819 Z"
                id="icons-math"
            />
        </SvgIcon>
    );
}

export default Math;
