import { theme } from "@aos/react-components";
import { IWorkInstructionsElementConfig, IWorkInstructionsImageConfig, upsert } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Tooltip, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";

import { useEntityFileByFileId } from "../../../../../../../../redux/effects";
import { normalizeSVGDimension } from "../../../../../../../../utilitites/normalizeSVGDimension";
import * as CloudStorage from "../../../../../../../../utilitites/storage";
import { WorkInstructionsEditorContext } from "../../utilities";

import { WorkInstructionsImageMagnifier } from "./ImageMagnifier";
import { WorkInstructionsImageTooltipEditor } from "./TooltipEditor";

const useStyles = makeStyles({
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
    zoomIn: {
        cursor: "zoom-in",
    },
});

export interface IOwnProps {
    disabled?: boolean;
    id: string;
    imageProps: IWorkInstructionsElementConfig<IWorkInstructionsImageConfig>;
    playMode?: boolean;
}

export default function WorkInstructionsImage(props: IOwnProps): JSX.Element {
    const { disabled, id, imageProps, playMode = false } = props;
    const { cropBottom, cropLeft, cropRight, cropTop, extendedProps, height, rotation, width, x, y } = imageProps;
    const finalHeight = height - cropBottom - cropTop;
    const finalWidth = width - cropRight - cropLeft;
    const imageUrl = `${CloudStorage.get().host}/${extendedProps.url}`;

    const classes = useStyles();
    const { elementMenuItems, setElementMenuItems } = useContext(WorkInstructionsEditorContext);
    const translate = useTranslate();
    const file = useEntityFileByFileId(extendedProps.url);

    const [tooltipEditorOpen, setTooltipEditorOpen] = useState(false);
    const [zoomIn, setZoomIn] = useState(false);

    const handleCloseImageMagnifier = (): void => {
        setZoomIn(false);
    };

    const handleCloseTooltipEditor = (): void => {
        setTooltipEditorOpen(false);
    };

    const handleImageMouseDown = (event: React.MouseEvent<SVGImageElement, MouseEvent>): void => {
        if ((event.button === 0 && playMode) || event.button === 1) {
            // Left click while in the player OR middle click
            event.stopPropagation();
            setZoomIn(true);
        }

        // Add menu items
        if (!playMode) {
            setElementMenuItems(
                upsert(
                    elementMenuItems,
                    [
                        {
                            id: "workInstructionsElementMenuItemImageZoom",
                            elementId: id,
                            label: translate("action.workInstructions.image.zoom"),
                            onClick: (): void => {
                                setZoomIn(true);
                            },
                        },
                        {
                            id: "workInstructionElementMenuItemImageEditTooltip",
                            elementId: id,
                            label: translate("action.workInstructions.image.editTooltip"),
                            onClick: (): void => {
                                setTooltipEditorOpen(true);
                            },
                        },
                    ],
                    function (value) {
                        return this.id === value.id;
                    }
                )
            );
        }
    };

    return (
        <>
            <g transform={`translate(${x},${y}) rotate(${rotation} ${finalWidth / 2} ${finalHeight / 2})`}>
                <svg
                    height={normalizeSVGDimension(finalHeight)}
                    preserveAspectRatio="xMidYMid slice"
                    width={normalizeSVGDimension(finalWidth)}
                    x="0"
                    xmlns="http://www.w3.org/2000/svg"
                    y="0"
                >
                    <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={extendedProps.customTooltip || file?.tooltip || ""}
                        style={{ pointerEvents: "fill" }}
                    >
                        <image
                            className={playMode && !zoomIn ? classes.zoomIn : undefined}
                            onMouseDown={handleImageMouseDown}
                            height={height}
                            href={imageUrl}
                            width={width}
                            x={-cropLeft}
                            y={-cropTop}
                        />
                    </Tooltip>
                </svg>
            </g>
            {zoomIn ? <WorkInstructionsImageMagnifier imageUrl={imageUrl} onClose={handleCloseImageMagnifier} open={zoomIn} /> : null}
            {file && tooltipEditorOpen && (
                <WorkInstructionsImageTooltipEditor
                    disabled={disabled}
                    element={imageProps}
                    fileId={file.fileId}
                    onClose={handleCloseTooltipEditor}
                    open={tooltipEditorOpen}
                />
            )}
        </>
    );
}
