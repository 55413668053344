import { KortexTextField, theme } from "@aos/react-components";
import {
    EnumResultSettingType,
    getAllMetadataProperties,
    IDataStoreKeyValueConfig,
    isNullOrUndefined,
    ProcessEditorRightsEnum,
    ResultKeyValueMetadataProperty,
    ResultSettingMetadataId,
    ResultSettingMetadataMetadata,
} from "@kortex/aos-common";
import { IconButton, makeStyles, Menu, MenuItem, Paper, Tooltip } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import VariablePicker from "../../../../../../../../components/core/VariablePicker/VariablePicker";
import { useTranslate } from "../../../../../../../../hooks/useTranslate";
import { useSelectorResultSettingItems, useSelectorResultSettingMetadata } from "../../../../../../../../redux/selectors";
import { IUserRightsProps, userCanWrite } from "../../../../../../../../utilitites/IUserRights";

const INPUT_DELAY_MS = 500;
const METADATA_NODE_ID = "0";

const useStyles = makeStyles({
    root: {
        marginBottom: "16px",
    },
    main: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr auto",
        gridColumnGap: "16px",
        padding: "16px",
    },
    formControl: {
        width: "100%",
    },
    inputHeight: {
        height: "32px",
    },
    input: {
        paddingBottom: "10px",
    },
    metadataInput: {
        flex: 1,
    },
    treeItemLabelContainer: {
        alignItems: "center",
        display: "flex",
        padding: theme.spacing(0.5, 0),
        paddingRight: "16px",
    },
    treeItemLabelKey: {
        flex: 1,
        padding: "10px",
        paddingRight: "16px",
    },
    treeView: {
        paddingBottom: "16px",
        paddingLeft: "16px",
    },
});

export interface IDataStoreKeyValueItemEditorProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    index: number;
    keyValueProps: IDataStoreKeyValueConfig;
    keyValueIndex: number;
    metadataExpanded: boolean;
    onKeyValuePropsChanged: (keyValueProps: IDataStoreKeyValueConfig, index: number) => void;
    onKeyValuePropsDeleted: (keyValuePropsIndex: number) => void;
    onMetadataExpand: (expanded: boolean, index: number) => void;
}

export default function DataStoreKeyValueItemEditorWrite(props: IDataStoreKeyValueItemEditorProps): JSX.Element {
    const { index, keyValueProps, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const metadataTemplates = useSelectorResultSettingMetadata();
    const resultSettingItems = useSelectorResultSettingItems();

    const hasMetadata = Boolean(
        resultSettingItems.find((item) => item.resultSettingItemId === keyValueProps.subGroupId)?.resultSettingMetadataId
    );
    const id = index.toString();
    const metadataExpanded = props.metadataExpanded ? [METADATA_NODE_ID] : [];
    const readOnly = !userCanWrite(userAccessLevel);

    const [menuOpen, setMenuOpen] = useState(false);
    const [readonlyMetadata, setReadonlyMetadata] = useState(keyValueProps.metadata); // Only used when the component is in readonly mode, so we can expand the metadata without updating the action
    const menuRef = useRef(null);

    /**
     * Collapse metadata when any result setting item/metadata is changed
     */
    useEffect(() => {
        props.onMetadataExpand(false, index);
    }, [resultSettingItems, metadataTemplates]);

    /**
     * Reconstruct the metadata of the key value when expanded
     */
    useEffect(() => {
        if (props.metadataExpanded) {
            const subGroup = resultSettingItems.find((item) => item.resultSettingItemId === keyValueProps.subGroupId);
            const updatedMetadata = subGroup?.resultSettingMetadataId
                ? getAllKeyValueMetadataProperties(subGroup.resultSettingMetadataId)
                : undefined;

            if (readOnly) {
                setReadonlyMetadata(updatedMetadata);
            }
            props.onKeyValuePropsChanged(
                {
                    ...keyValueProps,
                    metadata: updatedMetadata,
                },

                props.keyValueIndex
            );
        }
    }, [props.metadataExpanded]);

    /**
     * Get all metadata properties
     */
    const getAllKeyValueMetadataProperties = (resultSettingMetadataId: ResultSettingMetadataId): ResultKeyValueMetadataProperty[] => {
        const metadataTemplate = metadataTemplates.find(
            (item): item is ResultSettingMetadataMetadata => item.resultSettingMetadataId === resultSettingMetadataId
        );

        if (!metadataTemplate) {
            return [];
        }

        return getAllMetadataProperties(metadataTemplate, metadataTemplates).map((item): ResultKeyValueMetadataProperty => {
            return {
                parentId: item.property.parentId,
                path: item.path,
                resultSettingMetadataId: item.property.resultSettingMetadataId,
                value:
                    keyValueProps.metadata?.find((property) => property.resultSettingMetadataId === item.property.resultSettingMetadataId)
                        ?.value ?? "",
            };
        });
    };

    /**
     * Handle user change of group id
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e - Input event
     */
    const handleGroupIdChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        props.onKeyValuePropsChanged(
            {
                ...keyValueProps,
                groupId: parseInt(e.target.value),
                subGroupId: 0,
                metadata: null,
            },
            props.keyValueIndex
        );
    };

    const handleSubGroupIdChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const subGroupId = parseInt(e.target.value);
        const subGroup = resultSettingItems.find((item) => item.resultSettingItemId === subGroupId);

        props.onKeyValuePropsChanged(
            {
                ...keyValueProps,
                subGroupId,
                metadata: subGroup?.resultSettingMetadataId ? getAllKeyValueMetadataProperties(subGroup.resultSettingMetadataId) : null,
            },
            props.keyValueIndex
        );
    };

    /**
     * Handle user change of value tag
     *
     * @param {string} newValue - new tag value
     */
    const handleTagChanged = (newValue: string): void => {
        props.onKeyValuePropsChanged({ ...keyValueProps, tag: newValue }, props.keyValueIndex);
    };

    /**
     * Handle value change
     */
    const handleStoreToChange = (value: string): void => {
        props.onKeyValuePropsChanged({ ...keyValueProps, value }, props.keyValueIndex);
    };

    /**
     * Handle open menu click
     */
    const handleMenuClick = (): void => {
        setMenuOpen(true);
    };

    /**
     * Handle menu close
     */
    const handleMenuClose = (): void => {
        setMenuOpen(false);
    };

    /**
     * Handle delete key value
     */
    const handleDeleteKeyValue = (): void => {
        props.onKeyValuePropsDeleted(props.keyValueIndex);
    };

    /**
     * Calls function from props on metadata expand
     */
    const handleMetadataExpand = (): void => {
        props.onMetadataExpand(!props.metadataExpanded, index);
    };

    /**
     * Handles the changes applied to a property of the metadata
     */
    const handleMetadataPropertyValueChange =
        (index: number): ((value: string) => void) =>
        (value: string): void => {
            if (!keyValueProps.metadata) {
                return void 0;
            }

            keyValueProps.metadata[index].value = value;

            props.onKeyValuePropsChanged(keyValueProps, props.keyValueIndex);
        };

    /**
     * Recursively renders the metadata form
     */
    const renderMetadata = (metadataProperties: IDataStoreKeyValueConfig["metadata"]): JSX.Element[] => {
        const metadataToRender = readOnly ? readonlyMetadata : metadataProperties;

        return !isNullOrUndefined(metadataToRender)
            ? metadataToRender.map((property, index) => (
                  <Tooltip
                      key={index}
                      title={property.path
                          .map((id) => metadataTemplates.find((metadata) => id === metadata.resultSettingMetadataId)?.label ?? "")
                          .concat(
                              metadataTemplates.find((metadata) => metadata.resultSettingMetadataId === property.resultSettingMetadataId)
                                  ?.label ?? ""
                          )
                          .join(".")}
                  >
                      <div className={classes.treeItemLabelContainer}>
                          <VariablePicker
                              KortexTextFieldProps={{
                                  changedDelayMS: INPUT_DELAY_MS,
                                  className: clsx(classes.formControl, classes.metadataInput),
                                  label:
                                      metadataTemplates.find((item) => item.resultSettingMetadataId === property.resultSettingMetadataId)
                                          ?.label ?? "",
                                  TextFieldProps: {
                                      id: `input-metadata-${index}-id`,
                                      inputProps: {
                                          className: classes.input,
                                      },
                                  },
                                  variant: "standard",
                              }}
                              onChange={handleMetadataPropertyValueChange(index)}
                              userAccessLevel={userAccessLevel}
                              value={property.value}
                          />
                      </div>
                  </Tooltip>
              ))
            : [<div key="0" />];
    };

    return (
        <Paper className={classes.root} key={index}>
            <div className={classes.main}>
                {/* Group */}
                <KortexTextField
                    variant="standard"
                    className={classes.formControl}
                    InputProps={{
                        className: classes.inputHeight,
                    }}
                    label={translate("action.datastore.groupId")}
                    onChange={handleGroupIdChanged}
                    TextFieldProps={{
                        disabled: readOnly,
                        select: true,
                        id: `input-group${index}Id`,
                    }}
                    value={keyValueProps.groupId}
                >
                    {resultSettingItems
                        .filter((settingItem): boolean => settingItem.type === EnumResultSettingType.GROUP)
                        .map((groupItem, index): JSX.Element => {
                            return (
                                <MenuItem key={index} value={groupItem.resultSettingItemId}>
                                    {groupItem.label}
                                </MenuItem>
                            );
                        })}
                </KortexTextField>

                {/* Sub Group */}
                <KortexTextField
                    variant="standard"
                    className={classes.formControl}
                    InputProps={{
                        className: classes.inputHeight,
                    }}
                    label={translate("action.datastore.subGroupId")}
                    onChange={handleSubGroupIdChanged}
                    TextFieldProps={{
                        disabled: readOnly,
                        select: true,
                        id: `input-sub-group${index}Id`,
                    }}
                    value={keyValueProps.subGroupId}
                >
                    {resultSettingItems
                        .filter(
                            (settingItem): boolean =>
                                settingItem.type === EnumResultSettingType.SUB_GROUP && settingItem.parentId === keyValueProps.groupId
                        )
                        .map((valueItem, index): JSX.Element => {
                            return (
                                <MenuItem key={index} value={valueItem.resultSettingItemId}>
                                    {valueItem.label}
                                </MenuItem>
                            );
                        })}
                </KortexTextField>

                {/* Tag */}
                <VariablePicker
                    KortexTextFieldProps={{
                        TextFieldProps: {
                            id: "input-tag" + id,
                            inputProps: {
                                className: classes.input,
                            },
                        },
                        variant: "standard",
                        className: classes.formControl,
                        label: translate("action.datastore.key"),
                        changedDelayMS: INPUT_DELAY_MS,
                    }}
                    userAccessLevel={userAccessLevel}
                    value={keyValueProps.tag}
                    onChange={handleTagChanged}
                />

                {/* Value */}
                <VariablePicker
                    KortexTextFieldProps={{
                        variant: "standard",
                        className: classes.formControl,
                        label: translate("action.datastore.value"),
                        changedDelayMS: INPUT_DELAY_MS,
                        TextFieldProps: {
                            id: "input-value" + id,
                            inputProps: {
                                className: classes.input,
                            },
                        },
                    }}
                    userAccessLevel={userAccessLevel}
                    value={keyValueProps.value}
                    onChange={handleStoreToChange}
                />

                <div ref={menuRef}>
                    <IconButton disabled={readOnly} id="show-menu-button" onClick={handleMenuClick}>
                        <MoreVertIcon />
                    </IconButton>
                </div>
            </div>

            {/* Metadata */}
            {hasMetadata && (
                <TreeView
                    className={classes.treeView}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={metadataExpanded}
                >
                    <TreeItem
                        label={`${translate("action.datastore.metadata")}`}
                        nodeId={METADATA_NODE_ID}
                        onLabelClick={handleMetadataExpand}
                        onIconClick={handleMetadataExpand}
                    >
                        {renderMetadata(keyValueProps.metadata)}
                    </TreeItem>
                </TreeView>
            )}

            <Menu anchorEl={menuRef.current} open={menuOpen} onClose={handleMenuClose}>
                <MenuItem id="delete-button" onClick={handleDeleteKeyValue} onMouseDown={handleMenuClose}>
                    {translate("action.datastore.delete")}
                </MenuItem>
            </Menu>
        </Paper>
    );
}
