import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import { AOSClientServiceRequestWrapped, TrainingCertificationInsertReq, TrainingCertificationInsertRes } from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { trainingCertificationInsert } from "./insert";

interface IAOSClientServiceWrappedTrainingCertification {
    insert: AOSClientServiceRequestWrapped<TrainingCertificationInsertReq, TrainingCertificationInsertRes>;
}

type AOSClientServiceWrappedTrainingCertification = IAOSClientServiceWrappedTrainingCertification;

// ADD_API_CALL Root cause
export function generateTrainingCertificationServiceClient(router: IRouterClient): AOSClientServiceWrappedTrainingCertification {
    return {
        insert: AOSClientServiceWrapperUI.request(trainingCertificationInsert(router)),
    };
}

generateTrainingCertificationServiceClient.serviceId = 135;
