import { ConditionBaseConfig, ProcessEditorRightsEnum } from "@kortex/aos-common";
import { TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import VariablePicker from "../../../../../core/VariablePicker/VariablePicker";

const useStyles = makeStyles({
    textField: {
        width: "100%",
    },
});

export interface IOwnProps {
    condition: ConditionBaseConfig;
    onChange: (condition: ConditionBaseConfig) => void;
    userAccessLevel?: ProcessEditorRightsEnum;
}

export default function ConditionRowExpert(props: IOwnProps): JSX.Element {
    const { condition, onChange, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    /**
     * Called when an expert condition is changed
     *
     * @param {string} value - value
     */
    const handleChange = (value: string): void => {
        onChange({
            ...condition,
            expert: value,
        });
    };

    return (
        <TableCell colSpan={4}>
            <VariablePicker
                KortexTextFieldProps={{
                    className: classes.textField,
                    label: translate("action.condition.expert.condition"),
                    variant: "standard",
                }}
                onChange={handleChange}
                userAccessLevel={userAccessLevel}
                value={condition.expert}
            />
        </TableCell>
    );
}
