import { ProcessUiModel } from "@kortex/aos-common";
import { KortexDialogConfirmation } from "@aos/react-components";
import * as React from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import ProcessWhereUsedPage from "../../../ProcessWhereUsed/ProcessWhereUsedPage";
import { useSelectorTreeProcess } from "../../../../../redux/selectors";

interface IOwnProps {
    onClose: () => void;
    opened: boolean;
    process: ProcessUiModel;
}

export default function ProcessApprovalWhereUsedDialog(props: IOwnProps): JSX.Element {
    const { onClose, opened, process } = props;

    const treeNode = useSelectorTreeProcess().find((node) => node.treeNodeId === process.treeNodeId);
    const translate = useTranslate();

    // If tree node is undefined, do no render the dialog
    if (!treeNode) {
        return <div />;
    }

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={true}
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: onClose,
            }}
            onCancel={onClose}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("process.versioning.whereUsedClose"),
                titleLabel: translate("process.versioning.whereUsedTitle") + treeNode.label,
            }}
        >
            <ProcessWhereUsedPage processId={process.processId} showEmptyMessage={true} treeNodeId={treeNode.treeNodeId} />
        </KortexDialogConfirmation>
    );
}
