export var SequencerActionGroupStatusEnum;
(function (SequencerActionGroupStatusEnum) {
    SequencerActionGroupStatusEnum[SequencerActionGroupStatusEnum["COMPLETED"] = 1] = "COMPLETED";
    SequencerActionGroupStatusEnum[SequencerActionGroupStatusEnum["FAILED"] = 2] = "FAILED";
    SequencerActionGroupStatusEnum[SequencerActionGroupStatusEnum["IDLE"] = 3] = "IDLE";
    SequencerActionGroupStatusEnum[SequencerActionGroupStatusEnum["PAUSED"] = 4] = "PAUSED";
    SequencerActionGroupStatusEnum[SequencerActionGroupStatusEnum["PLAYING"] = 5] = "PLAYING";
    SequencerActionGroupStatusEnum[SequencerActionGroupStatusEnum["STOPPING"] = 6] = "STOPPING";
    SequencerActionGroupStatusEnum[SequencerActionGroupStatusEnum["STOPPED"] = 7] = "STOPPED";
})(SequencerActionGroupStatusEnum || (SequencerActionGroupStatusEnum = {}));
