import { SequencerError } from "./sequencer-error";
export class InterpolationError extends SequencerError {
    constructor(key, message = "Interpolation error", originalError) {
        super(key, message, originalError);
    }
    get name() {
        return "InterpolationError";
    }
    get originalError() {
        return super.originalError;
    }
}
