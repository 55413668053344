var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function SubProgram(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M19.4319066,3 C20.8478381,3 22.0002568,4.09309472 22,5.43668016 L22,5.43668016 L22,18.5633198 C22,19.9069053 20.8478381,21 19.4316497,21 L19.4316497,21 L4.56835021,21 C3.15216191,21 2,19.9069053 2,18.5633198 L2,18.5633198 L2,5.43668016 C2,4.09309472 3.15216191,3 4.56835021,3 L4.56835021,3 Z M20.4589899,10.951835 L3.54101013,10.951835 L3.54101013,18.7477001 C3.54101013,19.1836804 4.00177216,19.5382356 4.56835021,19.5382356 L4.56835021,19.5382356 L19.4319066,19.5382356 C19.9984846,19.5382356 20.4592467,19.1836804 20.4589899,18.7475025 L20.4589899,18.7475025 L20.4589899,10.951835 Z M19.4316497,4.4620081 L4.56835021,4.4620081 C4.00202899,4.4620081 3.54101013,5.18830253 3.54101013,6.08049152 L3.54101013,6.08049152 L3.54101013,9.48982686 L20.4589898,9.48982686 L20.4589898,6.08049152 C20.4589898,5.18789791 19.9982278,4.4620081 19.4316497,4.4620081 L19.4316497,4.4620081 Z M7,6 L7,8 L5,8 L5,6 L7,6 Z M10,6 L10,8 L8,8 L8,6 L10,6 Z M13,6 L13,8 L11,8 L11,6 L13,6 Z", id: "icons-sub-program" })));
}
export default SubProgram;
