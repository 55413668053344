export function createResultFailProblemModel() {
    return {
        resultProcessFailProblemId: 0,
        resultProcessFailId: 0,
        resultMasterId: 0,
        failureTypeId: 0,
        failureSubTypeId: 0,
        comments: "",
    };
}
