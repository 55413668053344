import { IReportTagColumnDbModel, SystemRightsEnum } from "@kortex/aos-common";
import PlayListAddIcon from "@material-ui/icons/PlaylistAdd";
import { KortexTextField, theme } from "@aos/react-components";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon/SpeedDialIcon";
import * as React from "react";
import { useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";

import ColumnsIcon from "../../../../components/core/Icons/Columns/Columns";
import { IUserRightsProps, userCanWrite } from "../../../../utilitites/IUserRights";
import { useTranslate } from "../../../../hooks/useTranslate";

const INPUT_DELAY_MS = 750;

const useStyles = makeStyles({
    root: {
        padding: "16px",
    },
    tableList: {
        position: "relative",
    },
    list: {
        height: "400px",
        overflowY: "scroll",
    },
    speedDial: {
        position: "absolute",
        right: "16px",
        bottom: "16px",
    },
    speedDialFab: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    speedDialButton: {
        boxShadow: "0px 4px 5px 0px",
    },
});

export interface ITagEditorProps extends IUserRightsProps<SystemRightsEnum> {
    tagColumns: IReportTagColumnDbModel[];

    onSelectedColumnIdChanged?: (tagColumndId: number) => void;
    onInsertTagColumns?: () => void;
    onInsertTagColumnValue?: () => void;
    onUpdateTagColumn?: (updatedTagColumn: IReportTagColumnDbModel) => void;
}

export default function TagColumnEditor(props: ITagEditorProps): JSX.Element {
    /**
     * COMPONENT PROPS
     */
    const { tagColumns, userAccessLevel } = props;
    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * COMPONENT STATES
     */
    const classes = useStyles();
    const translate = useTranslate();
    const [selectedTagColumndId, setSelectedTagColumndId] = useState(0);
    const [speedDialOpen, setSpeedDialOpen] = useState(false);

    /**
     * Handle speed dial menu toggle event
     */
    const handleToggleDialMenu = (): void => {
        setSpeedDialOpen(!speedDialOpen);
    };

    /**
     * Handle speed dial menu close event
     */
    const handleCloseDialMenu = (): void => {
        setSpeedDialOpen(false);
    };

    /**
     * Handle selection of a tag column row
     *
     * @param {number} tagColumnId - Selected tag column id
     */
    const handleSelectColumnClick =
        (tagColumnId: number): (() => void) =>
        (): void => {
            setSelectedTagColumndId(tagColumnId);

            if (props.onSelectedColumnIdChanged) {
                props.onSelectedColumnIdChanged(tagColumnId);
            }
        };

    /**
     * Trigger tag column update when name is changed by user
     *
     * @param {number} tagColumnId - Changed tag column id
     */
    const handleUpdateTagColumn =
        (tagColumnId: number): ((tagColumnName: string) => void) =>
        (tagColumnName: string): void => {
            if (!tagColumnId) {
                return;
            }

            const tagColumn = tagColumns.find((tagColumn): boolean => tagColumn.reportTagColumnId === tagColumnId);
            if (props.onUpdateTagColumn) {
                props.onUpdateTagColumn({ ...tagColumn, label: tagColumnName });
            }
        };

    return (
        <Paper className={classes.tableList}>
            <List className={classes.list}>
                {tagColumns.map((tagItem, index): JSX.Element => {
                    return (
                        <ListItem key={index} button={true} onClick={handleSelectColumnClick(tagItem.reportTagColumnId || -1)}>
                            <ColumnsIcon />
                            {tagItem.reportTagColumnId === selectedTagColumndId && (
                                <KortexTextField
                                    variant="standard"
                                    value={tagItem.label || ""}
                                    onChanged={handleUpdateTagColumn(tagItem.reportTagColumnId || -1)}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    TextFieldProps={{
                                        disabled: readOnly,
                                    }}
                                />
                            )}
                            {tagItem.reportTagColumnId !== selectedTagColumndId && <div>{tagItem.label}</div>}
                        </ListItem>
                    );
                })}
            </List>

            <ClickAwayListener onClickAway={handleCloseDialMenu}>
                <SpeedDial
                    ariaLabel=""
                    open={speedDialOpen}
                    icon={<SpeedDialIcon />}
                    classes={{ fab: classes.speedDialFab }}
                    className={classes.speedDial}
                    hidden={readOnly}
                    onClick={handleToggleDialMenu}
                >
                    <SpeedDialAction
                        classes={{ fab: classes.speedDialButton }}
                        icon={<PlayListAddIcon />}
                        tooltipTitle={translate("settings.tag.value")}
                        tooltipOpen={true}
                        onClick={props.onInsertTagColumnValue}
                        onMouseUp={handleCloseDialMenu}
                    />
                    <SpeedDialAction
                        classes={{ fab: classes.speedDialButton }}
                        icon={<ColumnsIcon />}
                        tooltipTitle={translate("settings.tag.column")}
                        tooltipOpen={true}
                        onClick={props.onInsertTagColumns}
                        onMouseUp={handleCloseDialMenu}
                    />
                </SpeedDial>
            </ClickAwayListener>
        </Paper>
    );
}
