export var EnumFileAction;
(function (EnumFileAction) {
    EnumFileAction["READ"] = "R";
    EnumFileAction["WRITE"] = "W";
})(EnumFileAction || (EnumFileAction = {}));
export var EnumFileType;
(function (EnumFileType) {
    EnumFileType["BINARY"] = "B";
    EnumFileType["TEXT"] = "T";
})(EnumFileType || (EnumFileType = {}));
export class ConnectorFileConfig {
    constructor() {
        this.action = EnumFileAction.READ;
        this.filePath = "";
        this.fileType = EnumFileType.TEXT;
        this.writeData = "";
    }
}
