import { getDateStr, getElapsedTimeStr, IOperatorJobProcessInfo, JobProcessStatusEnum } from "@kortex/aos-common";
import { greyPalette, theme } from "@aos/react-components";
import { makeStyles, Typography } from "@material-ui/core";
import ReworkIcon from "@material-ui/icons/BuildOutlined";
import PlayIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import ProcessIcon from "../../core/Icons/Process/Process";
import KortexLabelText from "../../core/KortexLabelText";
import KortexPanelCard from "../../core/KortexPanelCard";
import { GetJobInProgress, getJobProcessStatusColor, getJobProcessStatusLabelKey } from "../Scheduler/SchedulerData";

const useStyles = makeStyles({
    textLarge: {
        margin: "5px",
        width: "170px",
    },
    processPanelCardAllContainer: {
        display: "flex",
        flexDirection: "row",
    },
    processPanelCardContainer: {
        display: "flex",
        flexDirection: "column",
    },
    processPanelCardTitle: {
        display: "flex",
        flexDirection: "row",
        color: theme.palette.primary[500],
    },
    processPanelCardTypo: {
        padding: "5px 10px",
    },
    processPanelCardStates: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexDirection: "row",
    },
    processPanelCardState: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    processPanelCardStateQuantities: {
        borderLeft: "1px solid",
        borderColor: greyPalette[300],
        paddingLeft: "20px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    text: {
        margin: "5px",
        width: "130px",
    },
    textShort: {
        margin: "5px",
        width: "100px",
    },
    iconContainer: {
        color: greyPalette[500],
    },
    listButtons: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        color: theme.palette.primary[500],
        height: "100px",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        fontSize: "0.8rem",
    },
    listButton: {
        display: "flex",
        flexDirection: "column",
        color: theme.palette.primary[500],
        height: "100px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.8rem",
        margin: "10px",
    },
    playIcon: {
        color: theme.palette.primary[500],
        width: "30px",
        height: "30px",
        cursor: "pointer",
    },
});

interface IOwnProps {
    index: number;
    jobProcessInfo: IOperatorJobProcessInfo;
    isSelected: boolean;
    onSelection?: (process: IOperatorJobProcessInfo) => void;
    onPlay?: () => void;
}

export default function SchedulerOperatorJobProcessCard(props: IOwnProps): JSX.Element {
    const { index } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [jobProcessInfo, setJobProcessInfo] = useState<IOperatorJobProcessInfo | undefined>(undefined);

    /**
     * Effect that update job process info when updated via props
     */
    useEffect((): void => {
        if (props.jobProcessInfo) {
            setJobProcessInfo(props.jobProcessInfo);
        }
    }, [props.jobProcessInfo]);

    /**
     * Handle selection of the job process including jobProcess and related job
     *
     * @param {IOperatorJobProcessInfo} jobProcessInfo - job process info selected
     */
    const handleSelectJobProcessInfo =
        (jobProcessInfo: IOperatorJobProcessInfo): (() => void) =>
        (): void => {
            if (props.onSelection) {
                props.onSelection(jobProcessInfo);
            }
        };

    /**
     * Handle request to play a process, simply call the callback
     */
    const handlePlayButton = (): void => {
        if (props.onPlay) {
            props.onPlay();
        }
    };

    const canRun = jobProcessInfo?.status === JobProcessStatusEnum.READY || jobProcessInfo?.status === JobProcessStatusEnum.IN_PROGRESS;

    return (
        <React.Fragment>
            {jobProcessInfo && (
                <div>
                    <KortexPanelCard
                        key={index}
                        isSelected={props.isSelected}
                        padding={10}
                        onSelect={handleSelectJobProcessInfo(jobProcessInfo)}
                        statusColor={props.onPlay ? getJobProcessStatusColor(jobProcessInfo.status) : undefined}
                    >
                        <div className={classes.processPanelCardAllContainer}>
                            <div>
                                <div className={classes.processPanelCardTitle}>
                                    {jobProcessInfo.reworkId ? (
                                        <ReworkIcon className={classes.iconContainer} />
                                    ) : (
                                        <ProcessIcon className={classes.iconContainer} />
                                    )}
                                    <Typography className={classes.processPanelCardTypo} color="primary" variant="h6">
                                        {jobProcessInfo.process.treeNodeProcess.label}
                                    </Typography>
                                    {jobProcessInfo.reworkId > 0 &&
                                        jobProcessInfo.rework.trackingId &&
                                        jobProcessInfo.rework.trackingInstances && (
                                            <div className={classes.processPanelCardTitle}>
                                                <KortexLabelText
                                                    label={translate("scheduler.tracking")}
                                                    text={jobProcessInfo.rework.trackingId}
                                                    className={classes.textShort}
                                                />
                                                <KortexLabelText
                                                    label={translate("scheduler.trackingInstances")}
                                                    text={jobProcessInfo.rework.trackingInstances}
                                                    className={classes.textShort}
                                                />
                                            </div>
                                        )}
                                </div>
                                <div className={classes.processPanelCardStates}>
                                    <div className={classes.processPanelCardState}>
                                        <KortexLabelText
                                            label={translate("scheduler.status")}
                                            text={translate(getJobProcessStatusLabelKey(jobProcessInfo.status))}
                                            className={classes.textShort}
                                            id={`jobProcessStatus${index}Id`}
                                        />
                                        <KortexLabelText
                                            label={translate("scheduler.jobRefId")}
                                            text={jobProcessInfo.job ? jobProcessInfo.job.jobRefId : "0"}
                                            className={classes.text}
                                        />
                                        <KortexLabelText
                                            label={translate("scheduler.referenceId")}
                                            text={jobProcessInfo.job ? jobProcessInfo.job.referenceId : "0"}
                                            className={classes.text}
                                        />
                                        <KortexLabelText
                                            label={translate("scheduler.partNumber")}
                                            text={jobProcessInfo.job ? jobProcessInfo.job.partNumber : ""}
                                            className={classes.text}
                                        />
                                        <KortexLabelText
                                            className={classes.textLarge}
                                            label={translate("scheduler.scheduledOn")}
                                            text={getDateStr(jobProcessInfo.scheduledOn)}
                                        />
                                    </div>
                                    <div className={classes.processPanelCardStateQuantities}>
                                        <KortexLabelText
                                            label={translate("scheduler.quantityInProgress")}
                                            text={GetJobInProgress(
                                                jobProcessInfo.qtyStarted,
                                                jobProcessInfo.qty,
                                                jobProcessInfo.qtyPassed,
                                                jobProcessInfo.qtyFailed
                                            ).inProgress.toString()}
                                            className={classes.textShort}
                                        />
                                        <KortexLabelText
                                            label={translate("scheduler.quantityRemainingToStart")}
                                            text={GetJobInProgress(
                                                jobProcessInfo.qtyStarted,
                                                jobProcessInfo.qty,
                                                jobProcessInfo.qtyPassed,
                                                jobProcessInfo.qtyFailed
                                            ).remainingToStart.toString()}
                                            className={classes.textShort}
                                        />
                                        <KortexLabelText
                                            label={translate("scheduler.quantityPass")}
                                            text={`${jobProcessInfo.qtyPassed ?? 0}`}
                                            className={classes.textShort}
                                        />
                                        <KortexLabelText
                                            label={translate("scheduler.quantityFail")}
                                            text={`${jobProcessInfo.qtyFailed ?? 0}`}
                                            className={classes.textShort}
                                        />
                                        <KortexLabelText
                                            label={translate("scheduler.quantity")}
                                            text={`${jobProcessInfo.qty ?? 0}`}
                                            className={classes.textShort}
                                        />
                                        <KortexLabelText
                                            label={translate("scheduler.elapsedTime")}
                                            text={`${getElapsedTimeStr(jobProcessInfo.timeRun)}`}
                                            className={classes.text}
                                        />
                                    </div>
                                </div>
                            </div>
                            {props.onPlay && canRun && (
                                <div className={classes.listButtons}>
                                    <div className={classes.listButton} onClick={handlePlayButton}>
                                        <PlayIcon id="playProcessButtonId" className={classes.playIcon} />
                                        <Typography>{translate("scheduler.run")}</Typography>
                                    </div>
                                </div>
                            )}
                        </div>
                    </KortexPanelCard>
                </div>
            )}
        </React.Fragment>
    );
}
