import { convertToString } from "../../../../../utilities";
import { FormItemTypeEnum } from "./Form/Item/WorkInstructionsFormItemConfig";
/**
 * Verifies that a required form item has a valid value based on its type
 *
 * @param {string} value - value of a required form item
 * @param {FormItemTypeEnum} type - type of the form item
 * @param {string[]} subValues - sub-values of the form item
 */
export const isRequiredValueValidFromType = (value, type, subValues, items = []) => {
    switch (type) {
        case FormItemTypeEnum.TEXTBOXLIST:
            return items.length > 0;
        case FormItemTypeEnum.CHECKBOX:
            return value === "true";
        case FormItemTypeEnum.LABEL:
            // Always valid
            return true;
        case FormItemTypeEnum.DROPDOWN:
        case FormItemTypeEnum.RADIO:
            // Validates that the value is one of the possible subvalues
            if (!subValues) {
                return false;
            }
            return Boolean(subValues.find((subValue) => subValue === value));
        case FormItemTypeEnum.CHECKLIST:
            // Validates that all the checkboxes are checked
            return value === "true";
        default:
            // Validates that the value is not empty
            return convertToString(value) !== "";
    }
};
/**
 * Based on the type of form item, returns the default value (will be overwritten by configurable defaultValue)
 *
 * @param {FormItemTypeEnum} type - formItem type
 */
export const getDefaultValueFromType = (type) => {
    switch (type) {
        case FormItemTypeEnum.CHECKBOX:
        case FormItemTypeEnum.CHECKLIST:
            return "false";
        default:
            return "";
    }
};
