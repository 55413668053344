export var TaskStatusEnum;
(function (TaskStatusEnum) {
    TaskStatusEnum["OPEN"] = "O";
    TaskStatusEnum["CLOSE"] = "C";
    TaskStatusEnum["ARCHIVE"] = "A";
})(TaskStatusEnum || (TaskStatusEnum = {}));
export var TaskTypeEnum;
(function (TaskTypeEnum) {
    TaskTypeEnum["PROCESS_APPROVAL"] = "PA";
    TaskTypeEnum["PROCESS_REVIEW"] = "PR";
})(TaskTypeEnum || (TaskTypeEnum = {}));
