import { KortexDialogConfirmation, KortexOutlinedTextField, KortexTextField, theme } from "@aos/react-components";
import MomentUtils from "@date-io/moment";
import {
    ElectronicSignatureTypeEnum,
    EnumResultSettingType,
    IProcessUiModel,
    IReportTagEntryDbView,
    IResultSettingItemDbModel,
    ITreeNodeDbModel,
    ProcessApprovalStatusFilter,
    ProcessId,
    ReportTypeEnum,
    RepositoryEditorRightsEnum,
    TreeNodeId,
    TreeNodeNodeTypeEnum,
    getDateFormat,
} from "@kortex/aos-common";
import { useEntitiesTreeProcess, useEntitiesUsers } from "@kortex/aos-ui/redux/effects";
import { Checkbox, IconButton, InputAdornment, Popover, Typography } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import "moment/locale/fr";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import FiltersIcon from "../../../components/core/Icons/Filters/Filters";
import { useTranslate } from "../../../hooks/useTranslate";
import { useSelectorProcesses, useSelectorResultSettingItems } from "../../../redux/selectors";
import { SearchUserIcon } from "../../core/Icons/searchUser";
import { TextFieldSelect } from "../../core/TextFieldSelect";
import ProcessRepository from "../ProcessEditor/RepositoryManager/ProcessRepository";

const CURRENT_DATE = new Date();
const DEFAULT_FROM_DATE = new Date(CURRENT_DATE.getFullYear(), CURRENT_DATE.getMonth(), CURRENT_DATE.getDate() - 7, 0, 0, 0);
const DEFAULT_TO_DATE = new Date(CURRENT_DATE.getFullYear(), CURRENT_DATE.getMonth(), CURRENT_DATE.getDate(), 23, 59, 59);

const useStyles = makeStyles({
    root: {
        padding: "16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    textField: {
        marginRight: "16px",
        width: "200px",
    },
    dateField: {
        marginRight: "16px",
        width: "150px",
    },
    testRunContainer: {
        display: "flex",
        flexDirection: "column",
        color: theme.palette.grey[700],
    },
    testRunCbHeader: {
        paddingLeft: "10px",
        fontSize: "0.85rem",
    },
    testRunCbContainer: {
        display: "flex",
        flexDirection: "row",
    },
    flexSpacer: {
        flexGrow: 1,
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    filterLabel: {
        color: theme.palette.secondary[500],
        flex: 1,
    },
    filterInput: {
        flex: 2,
    },
    filterInputDate: {
        flex: 1,
        margin: "5px",
    },
    filterInputDateInput: {
        fontSize: "1rem",
        padding: "5px",
    },
    filterInputUserSelector: {
        flex: 2,
        margin: "5px",
    },
    filterInputUserSelectorTextFieldRoot: {
        margin: 0,
    },
    // Button user selector
    textFieldUserSelector: {
        height: "100%",
        padding: "10px 5px",
    },
    input: {
        padding: "18.5px 14px",
    },
    inputLabel: {
        top: "initial",
        left: "initial",
    },
    // Button process
    textFieldProcess: {
        margin: "5px 20px",
        width: "45%",
    },
    // Dialog repo
    dialogContent: {
        height: "60vh",
        maxWidth: "1102px",
        width: "80vw",
    },
    typoFilters: {
        marginTop: "15px",
    },
    // Filter icon
    popOver: {
        borderRadius: "2px",
        width: "30%",
    },
});

export interface IReportingFilters {
    reportType: ReportTypeEnum | number;
    fromDate?: Date;
    toDate?: Date;
    trackingId?: string;
    refJobId?: string;
    refId?: string;
    selectedDataStoreTableId?: number;
    showTestRun?: boolean;
    showOnlyTestRun?: boolean;
    electronicSignatureType: ElectronicSignatureTypeEnum;

    userId?: number;
    processId?: number;
    groupData?: boolean;
    showArchived?: boolean;
    tag?: IReportTagEntryDbView["reportTagEntry"];

    readingCertificateFromDate?: Date;
    readingCertificateToDate?: Date;

    referenceCertificate?: string;
    trainerId?: number;
    trainingCertificateFromDate?: Date;
    trainingCertificateToDate?: Date;

    referenceCertification?: string;
    auditorId?: number;
    certificationFromDate?: Date;
    certificationToDate?: Date;
}

export interface IReportingFiltersProps {
    onFiltersChanged: (filters: IReportingFilters) => void;
}

export const DEFAULT_FILTERS: IReportingFilters = {
    reportType: 0,
    fromDate: DEFAULT_FROM_DATE,
    toDate: DEFAULT_TO_DATE,
    trackingId: "",
    selectedDataStoreTableId: 0,
    showTestRun: false,
    showOnlyTestRun: false,
    electronicSignatureType: ElectronicSignatureTypeEnum.PROCESS_APPROVAL,

    userId: undefined,
    processId: undefined,
    groupData: undefined,
    showArchived: true,
    tag: undefined,

    readingCertificateFromDate: undefined,
    readingCertificateToDate: undefined,

    referenceCertificate: undefined,
    trainerId: undefined,
    trainingCertificateFromDate: undefined,
    trainingCertificateToDate: undefined,

    referenceCertification: undefined,
    auditorId: undefined,
    certificationFromDate: undefined,
    certificationToDate: undefined,
};

export default function ReportingFilters(props: IReportingFiltersProps): JSX.Element {
    /**
     * Component states definitions
     */
    const classes = useStyles();
    const resultSettingItems = useSelectorResultSettingItems();
    const translate = useTranslate();
    const intl = useIntl();
    const users = useEntitiesUsers();
    const treeNodes = useEntitiesTreeProcess();
    const refElement = useRef(null);

    const [reportingFilters, setReportingFilters] = useState(DEFAULT_FILTERS);
    const [dataStoreTableList, setDataStoreTableList] = useState<IResultSettingItemDbModel[]>(
        resultSettingItems.filter((item) => item.type === EnumResultSettingType.TABLE)
    );
    const [processRepositoryDialogOpen, setProcessRepositoryDialogOpen] = useState<boolean>(false);
    const [selectedProcessId, setSelectedProcessId] = useState<ProcessId | null>(null);
    const [selectedTreeNodeId, setSelectedTreeNodeId] = useState<TreeNodeId | null>(null);
    const [groupData, setGroupData] = useState<boolean>(false);
    const [showArchived, setShowArchived] = useState<boolean>(true);
    const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
    const [selectedProcessLabel, setSelectedProcessLabel] = useState<string | undefined>(undefined);

    const selectedProcess: IProcessUiModel | undefined = useSelectorProcesses((process) => process.processId === selectedProcessId)?.[0];

    /**
     * Load table list on mount
     */
    useEffect((): void => {
        setDataStoreTableList(resultSettingItems.filter((item) => item.type === EnumResultSettingType.TABLE));
    }, [resultSettingItems]);

    /**
     * Notify parent when filters are changed
     */
    useEffect((): void => {
        if (props.onFiltersChanged) {
            props.onFiltersChanged(reportingFilters);
        }
    }, [reportingFilters]);

    useEffect((): void => {
        if (reportingFilters.reportType === ReportTypeEnum.TRAINING) {
            setReportingFilters({ ...reportingFilters, fromDate: undefined, toDate: undefined });
        } else {
            setReportingFilters({ ...DEFAULT_FILTERS, reportType: reportingFilters.reportType });
        }
    }, [reportingFilters.reportType]);

    /**
     * Handle from date value change event
     */
    const handleFromDateOnChange =
        (
            propsKey: keyof Pick<
                IReportingFilters,
                "fromDate" | "readingCertificateFromDate" | "trainingCertificateFromDate" | "certificationFromDate"
            >
        ): ((date: MaterialUiPickersDate) => void) =>
        (date: MaterialUiPickersDate): void => {
            setReportingFilters({ ...reportingFilters, [propsKey]: date ? new Date(String(date)) : undefined });
        };

    /**
     * Handle to date value change event
     */
    const handleToDateOnChange =
        (
            propsKey: keyof Pick<
                IReportingFilters,
                "toDate" | "readingCertificateToDate" | "trainingCertificateToDate" | "certificationToDate"
            >
        ): ((date: MaterialUiPickersDate) => void) =>
        (date: MaterialUiPickersDate): void => {
            setReportingFilters({ ...reportingFilters, [propsKey]: date ? new Date(String(date)) : undefined });
        };

    /**
     * Handle tracking id value change event
     */
    const handleTrackingIdOnChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setReportingFilters({ ...reportingFilters, trackingId: event.target.value });
    };

    /**
     * Handle job ref id value change event
     */
    const handleRefJobIdIdOnChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setReportingFilters({ ...reportingFilters, refJobId: event.target.value });
    };

    /**
     * Handle checkbox show with test run changes
     */
    const handleCheckboxShowWithTestRun = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setReportingFilters({ ...reportingFilters, showTestRun: event.target.checked, showOnlyTestRun: false });
    };

    /**
     * Handle checkbox show only with test run changes
     */
    const handleCheckboxShowOnlyTestRun = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setReportingFilters({ ...reportingFilters, showTestRun: false, showOnlyTestRun: event.target.checked });
    };

    /**
     * Handle report type value change event
     */
    const handleReportTypeChanged = (value: number): void => {
        if (value === ReportTypeEnum.ELECTRONIC_SIGNATURE) {
            setReportingFilters({
                ...reportingFilters,
                reportType: value,
                electronicSignatureType: ElectronicSignatureTypeEnum.PROCESS_APPROVAL,
            });
        } else {
            setReportingFilters({ ...reportingFilters, reportType: value });
        }
    };

    /**
     * Handle data store table id value change event
     */
    const handleDataStoreTableIdChanged = (value: number): void => {
        setReportingFilters({ ...reportingFilters, selectedDataStoreTableId: value });
    };

    /**
     * Handle electronic signature value change event
     */
    const handleElectronicSignatureTypeChanged = (value: ElectronicSignatureTypeEnum): void => {
        setReportingFilters({ ...reportingFilters, electronicSignatureType: value });
    };

    /**
     * show the process related filters
     */
    const showProcessRelatedFilters = (): boolean => {
        if (reportingFilters.reportType !== ReportTypeEnum.AUDIT_TRAIL) {
            return !(
                (reportingFilters.reportType === ReportTypeEnum.ELECTRONIC_SIGNATURE &&
                    reportingFilters.electronicSignatureType !== ElectronicSignatureTypeEnum.PROCESS_RUN_STEP_APPROVAL &&
                    reportingFilters.electronicSignatureType !== ElectronicSignatureTypeEnum.PROCESS_TRAINING_COACH_REQUIRED &&
                    reportingFilters.electronicSignatureType !== ElectronicSignatureTypeEnum.PROCESS_TRAINING_COMPLETED) ||
                reportingFilters.reportType === ReportTypeEnum.TRAINING
            );
        }

        return false;
    };

    /**
     * Handles user change
     *
     * @param {string} value - value
     */
    const handleUserChange = (value: number | string): void => {
        setReportingFilters({ ...reportingFilters, userId: typeof value === "string" ? undefined : value });
    };

    /**
     * Handles trainer change
     *
     * @param {number} value - value
     */
    const handleTrainerChange = (value: number): void => {
        setReportingFilters({ ...reportingFilters, trainerId: typeof value === "string" ? undefined : value });
    };

    /**
     * Handles auditor change
     *
     * @param {number} value - value
     */
    const handleAuditorChange = (value: number): void => {
        setReportingFilters({ ...reportingFilters, auditorId: typeof value === "string" ? undefined : value });
    };

    /**
     * Handles tag change
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - event
     */
    const handleTagChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setReportingFilters({ ...reportingFilters, tag: event.target.value.length > 0 ? event.target.value : undefined });
    };

    /**
     * Handles reference certificate change
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - event
     */
    const handleReferenceCertificateChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setReportingFilters({ ...reportingFilters, referenceCertificate: event.target.value.length > 0 ? event.target.value : undefined });
    };

    /**
     * Handles reference certificationchange
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - event
     */
    const handleReferenceCertificationChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setReportingFilters({
            ...reportingFilters,
            referenceCertification: event.target.value.length > 0 ? event.target.value : undefined,
        });
    };

    /**
     * Called when the ClearInput button is clicked
     */
    const handleClearInput = (): void => {
        setSelectedProcessLabel(undefined);
        setSelectedProcessId(null);
        setReportingFilters({ ...reportingFilters, processId: undefined });
    };

    /**
     * Opens or closes the process respository dialog
     *
     * @param {boolean} open - opened or closed
     */
    const handleProcessRepositoryDialogOpen =
        (open: boolean): (() => void) =>
        (): void => {
            setProcessRepositoryDialogOpen(open);
        };

    /**
     * Closes the process repository dialog when the Cancel button is clicked
     */
    const handleProcessRepositoryDialogCancel = (): void => {
        setProcessRepositoryDialogOpen(false);
    };

    /**
     * Selects the process when the Confirm button is clicked and closes the process repository dialog
     */
    const handleProcessRepositoryDialogConfirm = async (): Promise<void> => {
        if (selectedProcessId) setReportingFilters({ ...reportingFilters, processId: selectedProcessId });
        setProcessRepositoryDialogOpen(false);

        const treeNode = treeNodes.find((node) => node.treeNodeId === selectedTreeNodeId);
        setSelectedProcessLabel(treeNode ? `${treeNode.label} ${selectedProcess ? `(${selectedProcess.version})` : ""}` : undefined);
    };

    /**
     * Selects the process
     *
     * @param {ITreeNodeDbModel[]} treeNodes - the process list
     */
    const handleSelectProcess = (treeNodes: ITreeNodeDbModel[], processIds: (number | undefined)[]): void => {
        if (treeNodes.length && processIds.length) {
            setSelectedProcessId(processIds[0] ?? null);
            setSelectedTreeNodeId(treeNodes[0].treeNodeId);
        } else {
            setSelectedProcessId(null);
            setSelectedTreeNodeId(null);
        }
    };

    /**
     * Handle called when the check group data is changed
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - not used
     */
    const handleGroupeData = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.stopPropagation();
        setGroupData(!groupData);
        setReportingFilters({ ...reportingFilters, groupData: !groupData });
    };

    /**
     * Handle called when the check show archive is changed
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - not used
     */
    const handleShowArchived = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.stopPropagation();
        setShowArchived(!showArchived);
        setReportingFilters({ ...reportingFilters, showArchived: !showArchived });
    };

    /**
     * Opens the filters popper
     */
    const handleOpenOptions = (): void => {
        setOptionsOpen(true);
    };

    /**
     * Closes the filters popper
     */
    const handleCloseOptions = (): void => {
        setOptionsOpen(false);
    };

    return (
        <>
            <Paper id="reportingFiltersId" className={classes.root}>
                <KortexTextField
                    className={classes.textField}
                    label={translate("reporting.report.type")}
                    value={reportingFilters.reportType}
                    TextFieldProps={{
                        select: true,
                        id: "reportTypeId",
                    }}
                    changedDelayMS={0}
                    onChanged={handleReportTypeChanged}
                >
                    <MenuItem value={ReportTypeEnum.PROCESS_SUMMARY}>{translate("reporting.type.summary")}</MenuItem>
                    <MenuItem value={ReportTypeEnum.PROCESS_DETAILS}>{translate("reporting.type.process")}</MenuItem>
                    <MenuItem value={ReportTypeEnum.PROCESS_STEPS}>{translate("reporting.type.process.step")}</MenuItem>
                    <MenuItem value={ReportTypeEnum.FAILURE_REPORT}>{translate("reporting.type.rework")}</MenuItem>
                    <MenuItem value={ReportTypeEnum.DATA_STORE_VALUE}>{translate("reporting.type.data.store.value")}</MenuItem>
                    <MenuItem value={ReportTypeEnum.DATA_STORE_TABLE}>{translate("reporting.type.data.store.table")}</MenuItem>
                    <MenuItem value={ReportTypeEnum.AUDIT_TRAIL}>{translate("reporting.type.audit.trail")}</MenuItem>
                    <MenuItem value={ReportTypeEnum.ELECTRONIC_SIGNATURE}>{translate("reporting.type.electronic.signature")}</MenuItem>
                    <MenuItem value={ReportTypeEnum.ENTITY}>{translate("reporting.type.entity")}</MenuItem>
                    <MenuItem value={ReportTypeEnum.TRAINING}>{translate("reporting.type.training")}</MenuItem>
                </KortexTextField>

                {/* DATA_STORE_TABLE FILTERS */}
                {reportingFilters.reportType === ReportTypeEnum.DATA_STORE_TABLE && (
                    <KortexTextField
                        className={classes.textField}
                        label={translate("reporting.table")}
                        value={reportingFilters.selectedDataStoreTableId || 0}
                        TextFieldProps={{
                            select: true,
                            id: "reportTableId",
                        }}
                        changedDelayMS={0}
                        onChanged={handleDataStoreTableIdChanged}
                    >
                        {dataStoreTableList.map((table, index): JSX.Element => {
                            return (
                                <MenuItem key={index} value={table.resultSettingItemId}>
                                    {table.label}
                                </MenuItem>
                            );
                        })}
                    </KortexTextField>
                )}

                {/* ELECTRONIC_SIGNATURE FILTERS */}
                {reportingFilters.reportType === ReportTypeEnum.ELECTRONIC_SIGNATURE && (
                    <KortexTextField
                        className={classes.textField}
                        label={translate("reporting.electronicSignature.type")}
                        value={reportingFilters.electronicSignatureType}
                        TextFieldProps={{
                            select: true,
                            id: "reportTableId",
                        }}
                        changedDelayMS={0}
                        onChanged={handleElectronicSignatureTypeChanged}
                    >
                        <MenuItem value={ElectronicSignatureTypeEnum.PROCESS_APPROVAL}>
                            {translate("reporting.electronicSignature.type.processApproval")}
                        </MenuItem>
                        <MenuItem value={ElectronicSignatureTypeEnum.PROCESS_RELEASE}>
                            {translate("reporting.electronicSignature.type.processRelease")}
                        </MenuItem>
                        <MenuItem value={ElectronicSignatureTypeEnum.PROCESS_RUN_STEP_APPROVAL}>
                            {translate("reporting.electronicSignature.type.processStepApproval")}
                        </MenuItem>
                        <MenuItem value={ElectronicSignatureTypeEnum.PROCESS_TRAINING_COACH_REQUIRED}>
                            {translate("reporting.electronicSignature.type.processTrainingCoachRequired")}
                        </MenuItem>
                        <MenuItem value={ElectronicSignatureTypeEnum.PROCESS_TRAINING_COMPLETED}>
                            {translate("reporting.electronicSignature.type.processTrainingCompleted")}
                        </MenuItem>
                        <MenuItem value={ElectronicSignatureTypeEnum.READING_CERTIFICATE}>
                            {translate("reporting.electronicSignature.type.readingCertificate")}
                        </MenuItem>
                        <MenuItem value={ElectronicSignatureTypeEnum.TRAINING_CERTIFICATE}>
                            {translate("reporting.electronicSignature.type.trainingCertificate")}
                        </MenuItem>
                        <MenuItem value={ElectronicSignatureTypeEnum.TRAINING_CERTIFICATION}>
                            {translate("reporting.electronicSignature.type.trainingCertification")}
                        </MenuItem>
                    </KortexTextField>
                )}

                {/* DATE FILTERS */}
                {reportingFilters.reportType !== ReportTypeEnum.TRAINING && (
                    <>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale}>
                            <DatePicker
                                allowKeyboardControl={true}
                                className={classes.dateField}
                                format={reportingFilters.fromDate ? getDateFormat(false) : ""}
                                id="fromDateId"
                                autoOk={true}
                                label={translate("reporting.from")}
                                onChange={handleFromDateOnChange("fromDate")}
                                value={reportingFilters.fromDate ? new Date(reportingFilters.fromDate) : null}
                                clearable={true}
                                variant="dialog"
                                inputVariant="outlined"
                                clearLabel={translate("general.clear")}
                                cancelLabel={translate("general.cancel")}
                                okLabel={translate("general.select")}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale}>
                            <DatePicker
                                allowKeyboardControl={true}
                                className={classes.dateField}
                                format={reportingFilters.toDate ? getDateFormat(false) : ""}
                                id="toDateId"
                                autoOk={true}
                                label={translate("reporting.to")}
                                onChange={handleToDateOnChange("toDate")}
                                value={reportingFilters.toDate ? new Date(reportingFilters.toDate) : null}
                                clearable={true}
                                variant="dialog"
                                inputVariant="outlined"
                                clearLabel={translate("general.clear")}
                                cancelLabel={translate("general.cancel")}
                                okLabel={translate("general.select")}
                            />
                        </MuiPickersUtilsProvider>
                    </>
                )}

                {/* TRAINING FILTERS */}
                {reportingFilters.reportType === ReportTypeEnum.TRAINING && (
                    <>
                        {/* USERS */}
                        <TextFieldSelect
                            KortexTextFieldProps={{
                                label: "User",
                                TextFieldProps: {
                                    className: classes.textFieldUserSelector,
                                    id: "usernameInputId",
                                    InputLabelProps: {
                                        className: classes.inputLabel,
                                    },
                                },
                                InputProps: {
                                    classes: {
                                        input: classes.input,
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchUserIcon />
                                        </InputAdornment>
                                    ),
                                },
                                variant: "outlined",
                            }}
                            value={reportingFilters.userId ?? ""}
                            items={users.map((user) => {
                                const label = user.firstName + " " + user.lastName;

                                return {
                                    component: <Typography>{label}</Typography>,
                                    filterCallback: (value: string): boolean =>
                                        value ? label.toLocaleLowerCase().includes(value.toLocaleLowerCase()) : true,
                                    textFieldValue: label,
                                    value: user.userId,
                                };
                            })}
                            onChange={handleUserChange}
                            showClearButton={Boolean(reportingFilters.userId)}
                        />
                        {/* PROCESS */}
                        <KortexOutlinedTextField
                            className={classes.textFieldProcess}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            disableRipple={true}
                                            onClick={selectedProcessLabel ? handleClearInput : handleProcessRepositoryDialogOpen(true)}
                                        >
                                            {selectedProcessLabel ? <CloseIcon /> : <SearchIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                readOnly: true,
                            }}
                            label={translate("action.routingProcess.process")}
                            onClick={!selectedProcessLabel ? handleProcessRepositoryDialogOpen(true) : (): void => void 0}
                            placeholder={translate("action.routingProcess.selectProcess")}
                            value={selectedProcessLabel ?? ""}
                        />
                        {/* ICON FILTER */}
                        <div ref={refElement}>
                            <IconButton onClick={handleOpenOptions} id={"filterId"}>
                                <FiltersIcon />
                            </IconButton>
                            <Popover
                                id={"popoverId"}
                                open={optionsOpen}
                                anchorEl={refElement.current}
                                onClose={handleCloseOptions}
                                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                classes={{
                                    paper: classes.popOver,
                                }}
                            >
                                <div style={{ padding: "16px" }}>
                                    <div id={"filterViewId"}>
                                        {/* 
                                            GENERAL FILTERS
                                        */}
                                        <Typography variant={"h6"}>{translate("general.general")}</Typography>
                                        {/* TAG */}
                                        <div className={classes.optionsItem}>
                                            <Typography className={classes.filterLabel}>{translate("reporting.tag")}</Typography>
                                            <KortexTextField
                                                className={classes.filterInput}
                                                onChange={handleTagChange}
                                                value={reportingFilters.tag ?? ""}
                                                variant="standard"
                                                TextFieldProps={{
                                                    id: "inputTagId",
                                                }}
                                            />
                                        </div>
                                        {/* SHOW ARCHIVE */}
                                        <div className={classes.optionsItem}>
                                            <Typography className={classes.filterLabel}>{translate("reporting.showArchived")}</Typography>
                                            <Checkbox
                                                checked={showArchived}
                                                onClick={handleShowArchived}
                                                id="showArchivedCheckBoxId"
                                                color={"default"}
                                            />
                                        </div>
                                        {/* GROUP DATA */}
                                        <div className={classes.optionsItem}>
                                            <Typography className={classes.filterLabel}>{translate("reporting.groupData")}</Typography>
                                            <Checkbox
                                                checked={groupData}
                                                onClick={handleGroupeData}
                                                id="groupDataCheckBoxId"
                                                color={"default"}
                                            />
                                        </div>
                                        {/* 
                                            READING CERTIFICATE FILTERS
                                        */}
                                        <Typography variant={"h6"} className={classes.typoFilters}>
                                            {translate("reporting.readingCertificateFilters")}
                                        </Typography>
                                        <div className={classes.optionsItem}>
                                            <Typography className={classes.filterLabel}>{translate("general.date")}</Typography>
                                            <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale}>
                                                <DatePicker
                                                    allowKeyboardControl={true}
                                                    className={classes.filterInputDate}
                                                    format={reportingFilters.readingCertificateFromDate ? getDateFormat(false) : ""}
                                                    id="readingCertificateFromDateId"
                                                    inputProps={{ className: classes.filterInputDateInput }}
                                                    autoOk={true}
                                                    label={translate("reporting.from")}
                                                    onChange={handleFromDateOnChange("readingCertificateFromDate")}
                                                    value={
                                                        reportingFilters.readingCertificateFromDate
                                                            ? new Date(reportingFilters.readingCertificateFromDate)
                                                            : null
                                                    }
                                                    clearable={true}
                                                    variant="dialog"
                                                    clearLabel={translate("general.clear")}
                                                    cancelLabel={translate("general.cancel")}
                                                    okLabel={translate("general.select")}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale}>
                                                <DatePicker
                                                    allowKeyboardControl={true}
                                                    className={classes.filterInputDate}
                                                    format={reportingFilters.readingCertificateToDate ? getDateFormat(false) : ""}
                                                    id="readingCertificateToDateId"
                                                    inputProps={{ className: classes.filterInputDateInput }}
                                                    autoOk={true}
                                                    label={translate("reporting.to")}
                                                    onChange={handleToDateOnChange("readingCertificateToDate")}
                                                    value={
                                                        reportingFilters.readingCertificateToDate
                                                            ? new Date(reportingFilters.readingCertificateToDate)
                                                            : null
                                                    }
                                                    clearable={true}
                                                    variant="dialog"
                                                    clearLabel={translate("general.clear")}
                                                    cancelLabel={translate("general.cancel")}
                                                    okLabel={translate("general.select")}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        {/* 
                                            CERTIFICATE FILTERS
                                        */}
                                        <Typography variant={"h6"} className={classes.typoFilters}>
                                            {translate("reporting.certificateFilters")}
                                        </Typography>
                                        {/* TRAINER */}
                                        <div className={classes.optionsItem}>
                                            <Typography className={classes.filterLabel}>{translate("reporting.trainer")}</Typography>
                                            <TextFieldSelect
                                                classes={{ root: classes.filterInputUserSelector }}
                                                KortexTextFieldProps={{
                                                    TextFieldProps: {
                                                        autoFocus: false,
                                                        classes: { root: classes.filterInputUserSelectorTextFieldRoot },
                                                        id: "trainingCertificateTrainerNameInputId",
                                                    },
                                                    variant: "standard",
                                                    InputProps: {
                                                        classes: {
                                                            input: classes.input,
                                                        },
                                                    },
                                                }}
                                                value={reportingFilters.trainerId}
                                                items={users.map((user) => {
                                                    const label = user.firstName + " " + user.lastName;

                                                    return {
                                                        component: <Typography>{label}</Typography>,
                                                        filterCallback: (value: string): boolean =>
                                                            value ? label.toLocaleLowerCase().includes(value.toLocaleLowerCase()) : true,
                                                        textFieldValue: label,
                                                        value: user.userId,
                                                    };
                                                })}
                                                onChange={handleTrainerChange}
                                                showClearButton={true}
                                            />
                                        </div>
                                        {/* TRAINING CERTIFICATE REFERENCE */}
                                        <div className={classes.optionsItem}>
                                            <Typography className={classes.filterLabel}>{translate("reporting.reference")}</Typography>
                                            <KortexTextField
                                                className={classes.filterInput}
                                                onChange={handleReferenceCertificateChange}
                                                value={reportingFilters.referenceCertificate ?? ""}
                                                variant="standard"
                                                TextFieldProps={{
                                                    id: "inputTrainingCertificateReferenceId",
                                                }}
                                            />
                                        </div>
                                        <div className={classes.optionsItem}>
                                            <Typography className={classes.filterLabel}>{translate("general.date")}</Typography>
                                            <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale}>
                                                <DatePicker
                                                    allowKeyboardControl={true}
                                                    className={classes.filterInputDate}
                                                    format={reportingFilters.trainingCertificateFromDate ? getDateFormat(false) : ""}
                                                    id="trainingCertificateFromDateId"
                                                    inputProps={{ className: classes.filterInputDateInput }}
                                                    autoOk={true}
                                                    label={translate("reporting.from")}
                                                    onChange={handleFromDateOnChange("trainingCertificateFromDate")}
                                                    value={
                                                        reportingFilters.trainingCertificateFromDate
                                                            ? new Date(reportingFilters.trainingCertificateFromDate)
                                                            : null
                                                    }
                                                    clearable={true}
                                                    variant="dialog"
                                                    clearLabel={translate("general.clear")}
                                                    cancelLabel={translate("general.cancel")}
                                                    okLabel={translate("general.select")}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale}>
                                                <DatePicker
                                                    allowKeyboardControl={true}
                                                    className={classes.filterInputDate}
                                                    format={reportingFilters.trainingCertificateToDate ? getDateFormat(false) : ""}
                                                    id="trainingCertificateToDateId"
                                                    inputProps={{ className: classes.filterInputDateInput }}
                                                    autoOk={true}
                                                    label={translate("reporting.to")}
                                                    onChange={handleToDateOnChange("trainingCertificateToDate")}
                                                    value={
                                                        reportingFilters.trainingCertificateToDate
                                                            ? new Date(reportingFilters.trainingCertificateToDate)
                                                            : null
                                                    }
                                                    clearable={true}
                                                    variant="dialog"
                                                    clearLabel={translate("general.clear")}
                                                    cancelLabel={translate("general.cancel")}
                                                    okLabel={translate("general.select")}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        {/* 
                                            CERTIFICATION FILTERS
                                        */}
                                        <Typography variant={"h6"} className={classes.typoFilters}>
                                            {translate("reporting.certificationFilters")}
                                        </Typography>
                                        {/* AUDITOR */}
                                        <div className={classes.optionsItem}>
                                            <Typography className={classes.filterLabel}>{translate("reporting.auditor")}</Typography>
                                            <TextFieldSelect
                                                classes={{ root: classes.filterInputUserSelector }}
                                                KortexTextFieldProps={{
                                                    TextFieldProps: {
                                                        autoFocus: false,
                                                        classes: { root: classes.filterInputUserSelectorTextFieldRoot },
                                                        id: "certificationAuditorNameInputId",
                                                    },
                                                    variant: "standard",
                                                    InputProps: {
                                                        classes: {
                                                            input: classes.input,
                                                        },
                                                    },
                                                }}
                                                initialValue={reportingFilters.auditorId}
                                                items={users.map((user) => {
                                                    const label = user.firstName + " " + user.lastName;

                                                    return {
                                                        component: <Typography>{label}</Typography>,
                                                        filterCallback: (value: string): boolean =>
                                                            value ? label.toLocaleLowerCase().includes(value.toLocaleLowerCase()) : true,
                                                        textFieldValue: label,
                                                        value: user.userId,
                                                    };
                                                })}
                                                onChange={handleAuditorChange}
                                                showClearButton={true}
                                            />
                                        </div>
                                        {/* CERTIFICATION REFERENCE */}
                                        <div className={classes.optionsItem}>
                                            <Typography className={classes.filterLabel}>{translate("reporting.reference")}</Typography>
                                            <KortexTextField
                                                className={classes.filterInput}
                                                onChange={handleReferenceCertificationChange}
                                                value={reportingFilters.referenceCertification ?? ""}
                                                variant="standard"
                                                TextFieldProps={{
                                                    id: "certificationReferenceInputId",
                                                }}
                                            />
                                        </div>
                                        <div className={classes.optionsItem}>
                                            <Typography className={classes.filterLabel}>{translate("general.date")}</Typography>
                                            <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale}>
                                                <DatePicker
                                                    allowKeyboardControl={true}
                                                    className={classes.filterInputDate}
                                                    format={reportingFilters.certificationFromDate ? getDateFormat(false) : ""}
                                                    id="certificationFromDateId"
                                                    inputProps={{ className: classes.filterInputDateInput }}
                                                    autoOk={true}
                                                    label={translate("reporting.from")}
                                                    onChange={handleFromDateOnChange("certificationFromDate")}
                                                    value={
                                                        reportingFilters.certificationFromDate
                                                            ? new Date(reportingFilters.certificationFromDate)
                                                            : null
                                                    }
                                                    clearable={true}
                                                    variant="dialog"
                                                    clearLabel={translate("general.clear")}
                                                    cancelLabel={translate("general.cancel")}
                                                    okLabel={translate("general.select")}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <MuiPickersUtilsProvider utils={MomentUtils} locale={intl.locale}>
                                                <DatePicker
                                                    allowKeyboardControl={true}
                                                    className={classes.filterInputDate}
                                                    format={reportingFilters.certificationToDate ? getDateFormat(false) : ""}
                                                    id="certificationToDateId"
                                                    inputProps={{ className: classes.filterInputDateInput }}
                                                    autoOk={true}
                                                    label={translate("reporting.to")}
                                                    onChange={handleToDateOnChange("certificationToDate")}
                                                    value={
                                                        reportingFilters.certificationToDate
                                                            ? new Date(reportingFilters.certificationToDate)
                                                            : null
                                                    }
                                                    clearable={true}
                                                    variant="dialog"
                                                    clearLabel={translate("general.clear")}
                                                    cancelLabel={translate("general.cancel")}
                                                    okLabel={translate("general.select")}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </>
                )}

                {showProcessRelatedFilters() && (
                    <>
                        <KortexTextField
                            label={translate("reporting.tracking.id")}
                            className={classes.textField}
                            onChange={handleTrackingIdOnChanged}
                            TextFieldProps={{
                                id: "trackingId",
                            }}
                            value={reportingFilters.trackingId || ""}
                        />
                        <KortexTextField
                            label={translate("reporting.job.ref.id")}
                            className={classes.textField}
                            onChange={handleRefJobIdIdOnChanged}
                            TextFieldProps={{
                                id: "jobRefId",
                            }}
                            value={reportingFilters.refJobId || ""}
                        />
                        <div className={classes.flexSpacer} />
                        <div className={classes.testRunContainer}>
                            <div className={classes.testRunCbHeader}>{translate("reporting.testRun")}</div>
                            <div className={classes.testRunCbContainer}>
                                <div>
                                    <Checkbox
                                        checked={reportingFilters.showTestRun}
                                        color="default"
                                        id="showWithTestRun"
                                        onChange={handleCheckboxShowWithTestRun}
                                    ></Checkbox>
                                    {translate("reporting.testRunIncludes")}
                                </div>
                                <div>
                                    <Checkbox
                                        checked={reportingFilters.showOnlyTestRun}
                                        color="default"
                                        id="showOnlyTestRun"
                                        onChange={handleCheckboxShowOnlyTestRun}
                                    ></Checkbox>
                                    {translate("reporting.testRunOnly")}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {/* PROCESS REPOSITORY */}
                <KortexDialogConfirmation
                    closeOnEscape={true}
                    dialogProps={{
                        fullWidth: false,
                        maxWidth: false,
                        onBackdropClick: handleProcessRepositoryDialogOpen(false),
                    }}
                    onCancel={handleProcessRepositoryDialogCancel}
                    onConfirm={handleProcessRepositoryDialogConfirm}
                    open={processRepositoryDialogOpen}
                    textLabels={{
                        cancelButtonLabel: translate("repoBreadCrumbs.cancel"),
                        proceedButtonLabel: translate("repoBreadCrumbs.select"),
                        titleLabel: translate("repoBreadCrumbs.titleProcess"),
                    }}
                    confirmDisabled={!Boolean(selectedTreeNodeId)}
                >
                    <div className={classes.dialogContent}>
                        <ProcessRepository
                            filterType={TreeNodeNodeTypeEnum.PROCESS}
                            onSelectionChanged={handleSelectProcess}
                            showLatestVersion={false}
                            showVersionVariant={ProcessApprovalStatusFilter.ALL}
                            userAccessLevel={RepositoryEditorRightsEnum.READ}
                            preselectedTreeNodeId={selectedTreeNodeId ?? undefined}
                            handleSelectProcessConfirmation={handleProcessRepositoryDialogConfirm}
                        />
                    </div>
                </KortexDialogConfirmation>
            </Paper>
        </>
    );
}
