import { TimeBaseSettingEnum } from "../interfaces/models/orgSettings/IOrgSettingDbModel";
export function newOrgSetting() {
    return {
        allowSelfProcessApproval: false,
        allowSelfRoutingApproval: false,
        allowUserCodeLogin: false,
        approvalContextText: "",
        customLocations: [],
        defaultLoc: "fr",
        itEmail: "",
        lockPendingProcess: false,
        lockPendingRouting: false,
        orgName: "",
        orgSettingId: 0,
        processApproval: "A",
        processRelease: "A",
        processVersionWarningDays: 0,
        releaseContextText: "",
        routingApproval: "A",
        routingRelease: "A",
        passwordAccountLockTime: 15,
        passwordAccountLockTimeUnit: TimeBaseSettingEnum.MINUTE,
        passwordExpiresTime: 90,
        passwordExpiresTimeUnit: TimeBaseSettingEnum.DAY,
        passwordRetriesLimit: 5,
        passwordChangeOnFirstLogin: true,
        trainingEnabled: false,
        trainingInactivityTimeout: 0,
        trainingInactivityTimeoutUnit: TimeBaseSettingEnum.DAY,
        trainingInactivityScheduledEvery: 0,
        trainingInactivityScheduledEveryUnit: TimeBaseSettingEnum.DAY,
        trainingInactivityStartDate: 0,
        trainingInactivityExcludedUserGroupIds: [],
    };
}
export function isOrgSettingValid(globalSettings) {
    return globalSettings.orgName.length > 3 && globalSettings.defaultLoc.length > 0;
}
