import { ITrainingCertificationPending, TrainingCertificationId, TrainingCertificationSignRequest, UserId } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesTrainingsCertificationPendingOfUser } from "@kortex/aos-ui/redux/effects";
import {
    clearTrainingCertificationPendingOfUser,
    trainingCertificationSign,
} from "@kortex/aos-ui/redux/training-certification-pending-manager/training-certification-pending-thunks";
import React, { FC, useEffect, useState } from "react";

import { UserValidationDialog } from "../UserValidationDialog";

import TrainingCertificationContextSignature from "./TrainingCertificationContextSignature";

interface IOwnProps {
    userId: UserId;
}

export const TrainingCertificationSignatureDialog: FC<IOwnProps> = (props) => {
    const { userId } = props;

    const translate = useTranslate();

    const dispatch = useThunkDispatch();

    const trainingsCertificationPending = useEntitiesTrainingsCertificationPendingOfUser(userId);

    const [TrainingCertificationPendingCount, setTrainingCertificationPendingCount] = useState<number>(0);
    const [selectedTrainingCertificationIds, setSelectedTrainingCertificationIds] = useState<TrainingCertificationId[]>([]);

    useEffect(() => {
        setTrainingCertificationPendingCount(trainingsCertificationPending.length - 1);
        if (trainingsCertificationPending.length > 0) {
            setSelectedTrainingCertificationIds(
                trainingsCertificationPending.map((TrainingCertificationPending) => TrainingCertificationPending.trainingCertificationId)
            );
        }
    }, [trainingsCertificationPending]);

    /**
     * Handle called when the user cancel the dialog
     */
    const handleTrainerSignatureCancel = (): void => {
        dispatch(clearTrainingCertificationPendingOfUser());
    };

    /**
     * Handle called when the user select training certification
     */
    const handelSelectedTrainingCertificationIds = (trainingCertificationIds: TrainingCertificationId[]): void => {
        setSelectedTrainingCertificationIds(trainingCertificationIds);
    };

    /**
     * Handle called when the trainee or trainer has signed the certification
     */
    const handleTrainerHasSigned = async (): Promise<void> => {
        const trainingsCertificationSelected: TrainingCertificationSignRequest = [];
        const unselectTrainingsCertification: ITrainingCertificationPending[] = [];

        for (const TrainingCertificationPending of trainingsCertificationPending) {
            if (selectedTrainingCertificationIds.includes(TrainingCertificationPending.trainingCertificationId)) {
                trainingsCertificationSelected.push({
                    ...TrainingCertificationPending,
                    context: translate("training.trainingCertificationCertifiedUserSignatureContext"),
                });
            } else {
                unselectTrainingsCertification.push(TrainingCertificationPending);
            }
        }

        await dispatch(trainingCertificationSign({ trainingsCertificationSelected, unselectTrainingsCertification }));
    };

    return (
        <UserValidationDialog
            open={trainingsCertificationPending.length > 0}
            isElectronicSignature={true}
            electronicSignatureContext={trainingsCertificationPending.map((TrainingCertificationPending, index) => (
                <TrainingCertificationContextSignature
                    key={`${index}TrainingCertificationContextSignature`}
                    index={index}
                    onSelectedTrainingCertificationIds={handelSelectedTrainingCertificationIds}
                    selectedTrainingCertificationIds={selectedTrainingCertificationIds}
                    trainingCertificationPending={TrainingCertificationPending}
                    trainingCertificationPendingCount={TrainingCertificationPendingCount}
                />
            ))}
            onClose={handleTrainerSignatureCancel}
            preSelectedUserId={userId}
            onValidate={handleTrainerHasSigned}
        />
    );
};

export default TrainingCertificationSignatureDialog;
