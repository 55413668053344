import {
    TrainingPendingGetAllRequest,
    TrainingReadingCertificateGetPendingsRes,
    TrainingReadingCertificateSignRes,
    UnwrapAOSPayload,
} from "@kortex/aos-common";

import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import {
    trainingReadingCertificatePendingClearAction,
    trainingReadingCertificatePendingDeletedAction,
    trainingReadingCertificatePendingSetAction,
    trainingReadingCertificatePendingTrainingDeletedAction,
    trainingReadingCertificatePendingUpsertedAction,
} from "./training-reading-certificate-pending-actions";
import {
    TrainingReadingCertificatePendingDeletedAction,
    TrainingReadingCertificatePendingUpsertedAction,
} from "./training-reading-certificate-pending-types";
/**
 * Clear training reading certificate pending state
 *
 */
export function clearTrainingReadingCertificatePending(): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(trainingReadingCertificatePendingClearAction());
    };
}

/**
 * Clear training reading certificate pending state
 *
 */
export function insertTrainingReadingCertificatePending(
    trainingReadingCertificatePendings: TrainingReadingCertificatePendingUpsertedAction["value"]
): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(trainingReadingCertificatePendingUpsertedAction(trainingReadingCertificatePendings));
    };
}

/**
 * Clear training reading certificate pending state
 *
 */
export function deleteTrainingReadingCertificatePending(
    trainingReadingCertificatePendings: TrainingReadingCertificatePendingDeletedAction["value"]
): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(trainingReadingCertificatePendingDeletedAction(trainingReadingCertificatePendings));
    };
}

/**
 * Fetch all pending reading certificates
 */
export function trainingReadingCertificatePendingGetAll(
    limit: number,
    offset: number,
    filterOptions: TrainingPendingGetAllRequest["filterOptions"],
    options: IStandardThunkOptions
): StandardThunk<UnwrapAOSPayload<TrainingReadingCertificateGetPendingsRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TrainingReadingCertificateGetPendingsRes>> => {
        if (!skipLookup) {
            return { data: getState().trainingReadingCertificatePending.readingCertificatePendings };
        }

        return api.services.trainingReadingCertificate
            .getPendings({ filterOptions, limit, offset })()
            .then((res) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(trainingReadingCertificatePendingGetAll);

                    if (offset === 0) {
                        dispatch(trainingReadingCertificatePendingSetAction(res.data));
                    } else {
                        dispatch(trainingReadingCertificatePendingUpsertedAction(res.data));
                    }
                }

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return { data: [] };
            });
    };
}

/**
 * Sign a reading certificate
 */
export function trainingReadingCertificateSign(
    signature: APIPayload<"trainingReadingCertificate", "sign">
): StandardThunk<UnwrapAOSPayload<TrainingReadingCertificateSignRes>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TrainingReadingCertificateSignRes>> => {
        return api.services.trainingReadingCertificate
            .sign(signature)()
            .then(([...res]) => {
                if (res) {
                    dispatch(trainingReadingCertificatePendingTrainingDeletedAction(res));
                }

                return res;
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }

                return [];
            });
    };
}
