import { MS_TO_SEC_RATIO, MS_TO_USEC_RATIO } from "../interfaces";
export function timestamp(...date) {
    const [newDate = new Date()] = date;
    const now = new Date(newDate).getTime();
    return {
        now,
        sec: now * MS_TO_SEC_RATIO,
        usec: now * MS_TO_USEC_RATIO,
    };
}
