import { theme } from "@aos/react-components";
import { ITrainingPending, TrainingCertificateId, getFormattedDate } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Checkbox, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";

const useStyles = makeStyles({
    certificate: {
        display: "flex",
    },
    processListContent: {
        display: "flex",
    },
    processName: {
        width: "80%",
    },
    date: {
        fontSize: "12px",
    },
    divider: {
        marginTop: "10px",
    },
});

interface IOwnProps {
    index: number;
    onSelectedTrainingCertificateIds: (trainingCertificateIds: TrainingCertificateId[]) => void;
    selectedTrainingCertificateIds: TrainingCertificateId[];
    trainingPending: ITrainingPending;
    trainingPendingCount: number;
}

export const TrainingCertificateContextSignature: FC<IOwnProps> = (props) => {
    const { index, onSelectedTrainingCertificateIds, selectedTrainingCertificateIds, trainingPending, trainingPendingCount } = props;
    const classes = useStyles();

    const translate = useTranslate();

    /**
     * Handle called when the user is selected
     */
    const handleSelectTrainingCertificate =
        (trainingCertificateId: TrainingCertificateId): ((_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) =>
        (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            const trainingCertificateIds = [...selectedTrainingCertificateIds];

            if (checked) {
                trainingCertificateIds.push(trainingCertificateId);
            } else {
                trainingCertificateIds.splice(trainingCertificateIds.indexOf(trainingCertificateId), 1);
            }

            onSelectedTrainingCertificateIds(trainingCertificateIds);
        };

    return (
        <List>
            <Typography>
                {trainingPending.type === "trainee"
                    ? translate("training.trainingCertificateTraineeSignatureContext")
                    : translate("training.trainingCertificateTrainerSignatureContext")}
            </Typography>
            <ListItem key={index} className={classes.certificate}>
                <ListItemText
                    primary={<Typography variant="h6">{trainingPending.trainingCertificateReference}</Typography>}
                    className={classes.processName}
                />
                <ListItemText primary={getFormattedDate(trainingPending.date ?? 0)} classes={{ primary: classes.date }} />
                <ListItemSecondaryAction>
                    <Checkbox
                        edge="end"
                        checked={selectedTrainingCertificateIds.includes(trainingPending.trainingCertificateId)}
                        id={`trainingCertificateSignatureCheckBoxSelectCertificateId${index}`}
                        onChange={handleSelectTrainingCertificate(trainingPending.trainingCertificateId)}
                        value={trainingPending.trainingCertificateId}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            {trainingPending.processes.map((process, index): JSX.Element => {
                return (
                    <ListItem
                        key={index}
                        className={classes.processListContent}
                        style={{
                            backgroundColor: index % 2 === 0 ? theme.palette.grey[200] : theme.palette.common.white,
                        }}
                    >
                        <ListItemText primary={`${process.label} (${process.version})`} className={classes.processName} />
                    </ListItem>
                );
            })}

            {index < trainingPendingCount && <Divider variant="fullWidth" className={classes.divider} />}
        </List>
    );
};

export default TrainingCertificateContextSignature;
