import { SequencerActionStatusEnum } from "../enums";
export function isActionCompleted(status) {
    return status === SequencerActionStatusEnum.COMPLETED;
}
export function isActionFailed(status) {
    return status === SequencerActionStatusEnum.FAILED;
}
export function isActionIdle(status) {
    return status === SequencerActionStatusEnum.IDLE;
}
export function isActionPaused(status) {
    return status === SequencerActionStatusEnum.PAUSED;
}
export function isActionPlaying(status) {
    return status === SequencerActionStatusEnum.PLAYING;
}
export function isActionStopped(status) {
    return status === SequencerActionStatusEnum.STOPPED;
}
export function isActionStopping(status) {
    return status === SequencerActionStatusEnum.STOPPING;
}
export function isActionInactive(status) {
    return [SequencerActionStatusEnum.COMPLETED, SequencerActionStatusEnum.FAILED, SequencerActionStatusEnum.IDLE].includes(status);
}
export function isActionInProgress(status) {
    return [SequencerActionStatusEnum.PAUSED, SequencerActionStatusEnum.PLAYING].includes(status);
}
