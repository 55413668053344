import { IKortexTextFieldProps, KortexTextField } from "@aos/react-components";
import {
    ILocMessage,
    ILocMessages,
    IOrgSettingDbModel,
    IProcessVariable,
    LocationKeys,
    ProcessEditorRightsEnum,
    ProcessVariableStorer,
} from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Divider, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import IconButton from "@material-ui/core/IconButton/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import TranslateIcon from "@material-ui/icons/Translate";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import shortid from "shortid";

import { useTranslate } from "../../hooks/useTranslate";
import { useEntitiesSettingOrganizations } from "../../redux/effects";
import { translateServer } from "../../redux/general-manager/general-thunks-general";
import { processUpdate } from "../../redux/process-manager/process-thunks-process";
import { useSelectorEditedProcessId, useSelectorProcesses } from "../../redux/selectors";
import { IUserRightsProps, userCanWrite } from "../../utilitites/IUserRights";
import VariableIcon from "../core/Icons/Variables/Variables";
import ProcessVariableManager from "../pages/ProcessEditor/ProcessEditor/ProcessVariables/ProcessVariableManager";

const useStyles = makeStyles({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        alignItems: "center",
    },
    textField: {
        width: "100%",
    },
    locTextField: {
        display: "block",
    },
    dividerHorizontal: {
        height: "1px",
    },
    dividerVertical: {
        width: "1px",
        height: "38px",
        marginTop: "10px",
        marginBottom: "10px",
    },
    label: {
        margin: "10px 0px",
    },
});

interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    // Ownprops
    classes?: Partial<ReturnType<typeof useStyles>>;
    disabled?: boolean;
    disableTranslation?: boolean;
    KortexTextFieldProps?: Omit<IKortexTextFieldProps, "className" | "value" | "onChange" | "label">;
    label?: string;
    locMessage: ILocMessage;
    multiline?: boolean;
    onChange: (values: ILocMessage) => void;
    rows?: number;
    showVariablePicker?: boolean;
}

export default function KortexTextFieldLocation(props: IOwnProps): JSX.Element {
    const classes = useStyles(props);
    const translate = useTranslate();
    const {
        disabled,
        disableTranslation,
        KortexTextFieldProps,
        label,
        multiline = false,
        onChange,
        rows = 1,
        showVariablePicker = false,
        userAccessLevel,
    } = props;
    let { locMessage } = props;

    const dispatch = useThunkDispatch();
    const orgInfo: IOrgSettingDbModel | undefined = useEntitiesSettingOrganizations()[0];

    const [editorOpen, setEditorOpen] = useState(false);
    const [variableManagerOpened, setVariableManagerOpened] = useState<boolean>(false);

    useStopKeybindPropagation(editorOpen);

    // process related state values
    const processes = useSelectorProcesses();
    const editedProcessId = useSelectorEditedProcessId();
    const editedProcess = React.useMemo(() => {
        return processes.find((process) => process.processId === editedProcessId);
    }, [editedProcessId, processes]);
    const [locMessagesLocalState, setLocMessagesLocalState] = useState<ILocMessages>(editedProcess?.locMessages || {});

    const [textFieldFocused, setTextFieldFocused] = useState<boolean>(false);

    /**
     * Effect that copies the props into a state variable
     */
    useEffect((): void => {
        setLocMessagesLocalState({ ...editedProcess?.locMessages } || {});
    }, [editedProcess, editorOpen]);

    const readOnly =
        !userCanWrite(userAccessLevel) ||
        Boolean(KortexTextFieldProps && KortexTextFieldProps.InputProps && KortexTextFieldProps.InputProps.readOnly) ||
        disabled;

    if (!locMessage) {
        locMessage = { id: shortid.generate(), defaultMessage: "" };
    }

    /**
     * Handles a textField focus event
     */
    const handleTextFieldFocus = useCallback(() => {
        setTextFieldFocused(true);
    }, [setTextFieldFocused]);

    /**
     * Handles a textField blur event
     */
    const handleTextFieldBlur = useCallback(() => {
        setTextFieldFocused(false);
    }, [setTextFieldFocused]);

    /**
     * Handles a change in the base TextField
     */
    const handleDefaultMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        if (onChange) {
            onChange({ ...locMessage, defaultMessage: e.target.value });
        }
    };

    /**
     * Handles a change in a translation TextField
     */
    const handleLocMessageChanged =
        (loc: LocationKeys): ((value: string) => void) =>
        (value: string): void => {
            updateLocMessage(loc, value);
        };

    /**
     * Updates a single field in the locMessages state variable
     */
    const updateLocMessage = (loc: LocationKeys, text: string): void => {
        if (locMessagesLocalState[loc]) {
            setLocMessagesLocalState({ ...locMessagesLocalState, [loc]: { ...locMessagesLocalState[loc], [locMessage.id]: text } });
        } else {
            setLocMessagesLocalState({ ...locMessagesLocalState, [loc]: { [locMessage.id]: text } });
        }
    };

    /**
     * Updates multiple fields in the locMessages state variable
     */
    const updateLocMessages = (messages: { loc: LocationKeys; text: string }[]): void => {
        const locMessagesCopy = { ...locMessagesLocalState };
        messages.forEach((message) => {
            if (!locMessagesCopy[message.loc]) {
                locMessagesCopy[message.loc] = {};
            }
            locMessagesCopy[message.loc] = { ...locMessagesCopy[message.loc], [locMessage.id]: message.text };
        });

        setLocMessagesLocalState(locMessagesCopy);
    };

    /**
     * Translates all fields and updates the locMessages local state
     */
    const translateAll = (): void => {
        if (!orgInfo) {
            return;
        }
        const results: { loc: LocationKeys; text: string }[] = [];
        const promises = [];

        // translate each field and push results in an array
        for (const lang of orgInfo.customLocations) {
            promises.push(
                dispatch(translateServer({ text: locMessage.defaultMessage, target: lang, src: orgInfo.defaultLoc })).then((res) => {
                    results.push({ loc: res.target, text: res.result });
                })
            );
        }

        // the value to translate should be written in the same language as the org's main language
        results.push({ loc: orgInfo.defaultLoc, text: locMessage.defaultMessage });

        Promise.all(promises).then(() => {
            updateLocMessages(results);
        });
    };

    /**
     * Closes dialog
     *
     * @param {boolean} save - if save == true, saves the field in the hub
     */
    const handleCloseDialog =
        (save: boolean): (() => void) =>
        (): void => {
            if (save && editedProcess) {
                dispatch(processUpdate({ ...editedProcess, locMessages: locMessagesLocalState }));
            }
            setEditorOpen(false);
        };

    /**
     * Opens dialog
     */
    const handleOpenDialog = (): void => {
        setEditorOpen(true);
    };

    /**
     * Called when user presses a key and Dialog is focused
     */
    const handleKeydown = (e: React.KeyboardEvent): void => {
        e.nativeEvent.stopImmediatePropagation();

        if (e.key === "Enter" && !textFieldFocused) {
            handleCloseDialog(true)();
        } else if (e.key === "Escape") {
            handleCloseDialog(false)();
        }
    };

    /**
     * If this component is used as a variable storer, replace current textfield value by the new one
     * If not, append the variable id to text field value
     *
     * @param {IProcessVariable} variable - selected variable
     */
    const handleSelectVariable = (variable: IProcessVariable): void => {
        if (editedProcess) {
            setVariableManagerOpened(false);

            if (onChange) {
                onChange({ ...locMessage, defaultMessage: locMessage.defaultMessage + "${" + variable.identifier + "}" });
            }
        }
    };

    /**
     * Called to open/close the variable manager
     */
    const toggleVariableManager =
        (open: boolean): (() => void) =>
        (): void => {
            setVariableManagerOpened(open);
        };

    /**
     * Updates the value of the textfield when a variable identifier from the variable manager is changed
     *
     * @param {string} oldIdentifier - old identifier
     * @param {string} updatedIdentifier - updated identifier
     */
    const handleVariableIdentifierChange = (oldIdentifier: string, updatedIdentifier: string): void => {
        locMessage.defaultMessage = locMessage.defaultMessage.replace(
            new RegExp("\\" + ProcessVariableStorer.addTemplateLitteral(oldIdentifier), "g"),
            ProcessVariableStorer.addTemplateLitteral(updatedIdentifier)
        );
    };

    return (
        <div className={`${classes.root}`}>
            <KortexTextField
                {...KortexTextFieldProps}
                label={label}
                TextFieldProps={{
                    ...(KortexTextFieldProps && KortexTextFieldProps.TextFieldProps),
                    multiline,
                    rows,
                }}
                className={classes.textField}
                value={locMessage.defaultMessage}
                onChange={handleDefaultMessageChange}
                InputProps={{
                    ...(KortexTextFieldProps && KortexTextFieldProps.InputProps),
                    endAdornment: !disableTranslation && (
                        <div style={{ display: multiline ? "block" : "flex" }}>
                            {showVariablePicker && (
                                <React.Fragment>
                                    <IconButton
                                        id="kortexTextFieldLocationAdornmentVariableId"
                                        onClick={toggleVariableManager(true)}
                                        disabled={readOnly}
                                    >
                                        <VariableIcon />
                                    </IconButton>
                                    <Divider className={multiline ? classes.dividerHorizontal : classes.dividerVertical} />
                                </React.Fragment>
                            )}
                            <IconButton onClick={handleOpenDialog} disabled={editorOpen || readOnly}>
                                <TranslateIcon />
                            </IconButton>
                        </div>
                    ),
                    disabled: readOnly,
                }}
            />

            <Dialog open={editorOpen} onKeyDown={handleKeydown}>
                <DialogTitle>{translate("coreComponents.KortexTextFieldLocation.title")}</DialogTitle>
                <DialogContent>
                    <Typography className={classes.label} variant={"subtitle1"}>
                        {translate("coreComponents.KortexTextFieldLocation.mainLanguage")}
                    </Typography>
                    {orgInfo && (
                        <KortexTextField
                            label={orgInfo.defaultLoc}
                            className={classes.locTextField}
                            value={
                                locMessagesLocalState[orgInfo.defaultLoc] && locMessagesLocalState[orgInfo.defaultLoc][locMessage.id]
                                    ? locMessagesLocalState[orgInfo.defaultLoc][locMessage.id]
                                    : ""
                            }
                            onChanged={handleLocMessageChanged(orgInfo.defaultLoc)}
                            TextFieldProps={{
                                multiline: multiline,
                                onFocus: handleTextFieldFocus,
                            }}
                            onBlur={handleTextFieldBlur}
                        />
                    )}

                    {orgInfo && orgInfo.customLocations.length > 0 ? (
                        <>
                            <Typography className={classes.label} variant={"subtitle1"}>
                                {translate("coreComponents.KortexTextFieldLocation.secondaryLanguages")}
                            </Typography>
                            {orgInfo.customLocations.map((lang: LocationKeys, index: number): JSX.Element => {
                                const value =
                                    locMessagesLocalState[lang] && locMessagesLocalState[lang][locMessage.id]
                                        ? locMessagesLocalState[lang][locMessage.id]
                                        : "";
                                return (
                                    <KortexTextField
                                        key={index}
                                        label={lang}
                                        className={classes.locTextField}
                                        value={value}
                                        onChanged={handleLocMessageChanged(lang)}
                                        TextFieldProps={{
                                            multiline: multiline,
                                            onFocus: handleTextFieldFocus,
                                        }}
                                        onBlur={handleTextFieldBlur}
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <></>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={translateAll}>
                        {translate("coreComponents.KortexTextFieldLocation.translateAll")}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleCloseDialog(false)}>
                        {translate("coreComponents.KortexTextFieldLocation.close")}
                    </Button>
                    <Button variant="contained" color="secondary" onClick={handleCloseDialog(true)}>
                        {translate("coreComponents.KortexTextFieldLocation.save")}
                    </Button>
                </DialogActions>
            </Dialog>
            <ProcessVariableManager
                onClose={toggleVariableManager(false)}
                onSelect={handleSelectVariable}
                onVariableIdentifierChange={handleVariableIdentifierChange}
                open={variableManagerOpened}
                userAccessLevel={userAccessLevel}
            />
        </div>
    );
}
