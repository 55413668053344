var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// react external
import * as React from "react";
import { useEffect, useState } from "react";
// mui
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import UserIcon from "@material-ui/icons/AccountCircle";
import { Card, CardActions, CardContent, CircularProgress, ClickAwayListener, Divider, TextField, Typography } from "@material-ui/core";
import { rootDefaults } from "../mui/rootDefaults";
import { arrow, popper } from "../mui/classes";
import { theme } from "../mui/theme";
import { useKeyPressed } from "../hooks";
var useStyles = makeStyles({
    root: __assign({}, rootDefaults),
    mainContainer: {
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
    },
    mainButton: {
        position: "relative",
        width: "32px",
        height: "32px",
        padding: 0,
    },
    avatar: {
        width: "32px",
        height: "32px",
        color: "white",
    },
    loading: {
        position: "absolute",
    },
    avatarLoading: {
        width: "32px",
        height: "32px",
        filter: "grayscale(100%)",
        webkitFilter: "grayscale(100%)",
    },
    userInfo: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        paddingLeft: "10px",
    },
    userName: {
        margin: 0,
    },
    Popper: __assign({}, popper(theme)),
    arrow: __assign({}, arrow),
    cardContent: {
        flexDirection: "row",
        display: "flex",
        minWidth: "280px",
        color: theme.palette.primary.main,
    },
    mainAvatar: {
        height: "80px",
        width: "80px",
        fill: "currentColor",
    },
});
function KortexConnectionStatus(props) {
    var classes = useStyles(props);
    var acceptedLanguages = props.acceptedLanguages, isLoading = props.isLoading, language = props.language, _a = props.offsetX, offsetX = _a === void 0 ? 0 : _a, _b = props.offsetY, offsetY = _b === void 0 ? 0 : _b, onGoToUsersPage = props.onGoToUsersPage, onLanguageChange = props.onLanguageChange, onLogout = props.onLogout, textLabels = props.textLabels, user = props.user;
    var _c = useState(null), anchorEl = _c[0], setAnchorEl = _c[1];
    var _d = useState(null), arrowRef = _d[0], setArrowRef = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var _f = useState(false), open = _f[0], setOpen = _f[1];
    var escapePressed = useKeyPressed("Escape", !open);
    /**
     * Called when user click escape key when the menu is open... close the menu.
     */
    useEffect(function () {
        if (escapePressed) {
            setOpen(false);
        }
    }, [escapePressed]);
    /**
     * Set "loading" inner state if prop changes
     */
    useEffect(function () {
        if (isLoading !== loading) {
            setLoading(isLoading);
        }
    }, [isLoading]);
    /**
     * get the user's images
     */
    /*
    private getUserImage = (type = "") => {
        let image = props.user ? props.user.avatar : "";

        // FIXME: when avatar is modifiable
        // if (!image || image === "") {
        if (type === "main") {
            image = "/images/user_blue.svg";
        } else {
            image = "/images/user_white.svg";
        }
        // }
        return image;
    }*/
    /**
     * Closes the popper
     */
    var handleClosePopper = function () {
        setOpen(false);
        setAnchorEl(null);
    };
    /**
     * handle the poper click over
     */
    var handleClickButton = function (event) {
        if (!open) {
            setOpen(true);
            setAnchorEl(event.currentTarget);
        }
        else {
            handleClosePopper();
        }
    };
    /**
     * handles the logout click
     */
    var handleLogout = function () {
        handleClosePopper();
        onLogout();
    };
    /**
     * handle the language change
     */
    var handleLanguageChange = function (event) {
        onLanguageChange(event.target.value);
    };
    /**
     * handle the click to my account
     */
    var handleGoToUsersPage = function () {
        handleClosePopper();
        onGoToUsersPage();
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement(ClickAwayListener, { onClickAway: handleClosePopper },
            React.createElement("div", { className: classes.mainContainer },
                React.createElement(Button, { disableRipple: true, "aria-owns": open ? "simple-popper" : undefined, "aria-haspopup": true, className: classes.mainButton, onClick: handleClickButton, id: "connectionStatusButtonId" },
                    React.createElement(UserIcon, { className: loading ? classes.avatarLoading : classes.avatar }),
                    loading && React.createElement(CircularProgress, { className: classes.loading, color: "secondary", size: 30 })),
                React.createElement(Popper, { anchorEl: anchorEl, className: classes.Popper, disablePortal: true, modifiers: {
                        arrow: {
                            enabled: true,
                            element: arrowRef,
                        },
                        flip: {
                            enabled: false,
                        },
                        offset: {
                            offset: "".concat(offsetX, ", ").concat(offsetY),
                        },
                        computeStyle: {
                            gpuAcceleration: false,
                        },
                    }, open: open, placement: "bottom-end" },
                    React.createElement("div", { className: classes.arrow, ref: setArrowRef }),
                    React.createElement(Card, null,
                        React.createElement(CardContent, { className: classes.cardContent },
                            React.createElement(UserIcon, { className: classes.mainAvatar }),
                            React.createElement("div", { className: classes.userInfo },
                                React.createElement(Typography, { variant: "h4", id: "username", className: classes.userName }, user ? user.username : ""),
                                React.createElement(TextField, { select: true, label: textLabels.language, value: language, onChange: handleLanguageChange, margin: "normal", SelectProps: {
                                        native: true,
                                    } }, acceptedLanguages.map(function (lang, index) { return (React.createElement("option", { value: lang.key, key: index }, lang.label)); })))),
                        React.createElement(Divider, null),
                        React.createElement(CardActions, null,
                            React.createElement(Button, { variant: "outlined", onClick: handleGoToUsersPage }, textLabels.leftButton),
                            React.createElement(Button, { variant: "contained", color: "secondary", onClick: handleLogout, id: "logoutButtonId" }, textLabels.rightButton))))))));
}
export default KortexConnectionStatus;
