import { greyPalette, theme } from "@aos/react-components";
import { List, ListItem, Paper, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import RemoveIcon from "@material-ui/icons/Remove";
import * as React from "react";

const useStyles = makeStyles({
    root: {
        position: "absolute",
        right: "16px",
        bottom: "16px",
        "@media (max-height: 720px)": {
            right: "80px",
        },
    },
    horizontalList: {
        display: "flex",
        flexDirection: "row",
        padding: 0,
    },
    listItem: {
        paddingLeft: "10px",
        paddingRight: "10px",
        color: greyPalette[600],
        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },
});

interface IOwnProps {
    onZoomIn: () => void;
    onZoomOut: () => void;
    onCenter: () => void;
}

export default function ProcessEditorNavMenu(props: IOwnProps): JSX.Element {
    const classes = useStyles();

    const handleZoomIn = (): void => {
        if (props.onZoomIn) {
            props.onZoomIn();
        }
    };

    const handleZoomOut = (): void => {
        if (props.onZoomOut) {
            props.onZoomOut();
        }
    };

    const handleCenter = (): void => {
        if (props.onCenter) {
            props.onCenter();
        }
    };

    return (
        <Paper className={classes.root}>
            <List className={classes.horizontalList}>
                <ListItem id="centerButtonId" button={true} className={classes.listItem} onClick={handleCenter}>
                    <AspectRatioIcon />
                </ListItem>
                <ListItem id="zoomInButtonId" button={true} className={classes.listItem} onClick={handleZoomIn}>
                    <AddIcon />
                </ListItem>
                <ListItem id="zoomOutButtonId" button={true} className={classes.listItem} onClick={handleZoomOut}>
                    <RemoveIcon />
                </ListItem>
            </List>
        </Paper>
    );
}
