import { makeStyles } from "@material-ui/core";
import * as React from "react";

import ProcessEditor from "../ProcessEditor/ProcessEditor/ProcessEditor";

import { ProcessEditorProvider } from "./ProcessEditor/context";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: "100%",
        zIndex: 1,
        position: "relative",
        boxSizing: "border-box",
        padding: "16px",
    },
});

export default function ProcessEditorPage(): JSX.Element {
    const classes = useStyles();

    return (
        <div className={classes.root} id="ProcessEditorPageId">
            <ProcessEditorProvider>
                <ProcessEditor />
            </ProcessEditorProvider>
        </div>
    );
}
