import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { Typography, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
        marginRight: "40px",
        width: "100%",
    },
    comments: {
        margin: "20px 0px",
    },
});

interface IOwnProps {
    onCancel: () => void;
    onConfirm: (comments: string) => void;
    opened: boolean;
}

export default function ProcessRejectVersionDialog(props: IOwnProps): JSX.Element {
    const { onCancel, onConfirm, opened } = props;

    const classes = useStyles();

    const [comments, setComments] = useState<string>("");
    const [validate, setValidate] = useState<boolean>(false);

    const translate = useTranslate();

    /**
     * handle the save
     */
    const handleConfirm = (): void => {
        setValidate(true);
        if (comments !== "") {
            setValidate(false);
            onConfirm(comments);
            setComments("");
        }
    };

    /**
     * handles the comments change event
     *
     */
    const handleCommentsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setComments(e.target.value);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: onCancel,
            }}
            onCancel={onCancel}
            onConfirm={handleConfirm}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("process.versioning.recoverVersionDialogCancel"),
                proceedButtonLabel: translate("process.versioning.recoverVersionDialogConfirm"),
                titleLabel: translate("process.versioning.reject"),
            }}
        >
            <div className={classes.dialogDescription}>
                <Typography>{translate("process.versioning.rejectVersionDialogMessage")}</Typography>
                <KortexTextField
                    value={comments}
                    label={translate("process.versioning.rejectVersionDialogReason")}
                    className={classes.comments}
                    onChange={handleCommentsChange}
                    error={validate && comments === "" ? translate("process.versioning.rejectVersionDialogReasonRequired") : undefined}
                    TextFieldProps={{
                        id: "processRejectCommentsId",
                        multiline: true,
                        required: true,
                        rows: 10,
                    }}
                />
            </div>
        </KortexDialogConfirmation>
    );
}
