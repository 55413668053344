import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    ReworkItemStatusAllReq,
    ReworkItemStatusAllRes,
    ReworkItemStatusDeleteReq,
    ReworkItemStatusDeleteRes,
    ReworkItemStatusDeletedNotif,
    ReworkItemStatusInsertReq,
    ReworkItemStatusInsertRes,
    ReworkItemStatusInsertedNotif,
    ReworkItemStatusOneReq,
    ReworkItemStatusOneRes,
    ReworkItemStatusUpdateReq,
    ReworkItemStatusUpdateRes,
    ReworkItemStatusUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { reworkItemStatusAll } from "./all";
import { reworkItemStatusOne } from "./one";
import { reworkItemStatusInsert } from "./insert";
import { reworkItemStatusUpdate } from "./update";
import { reworkItemStatusInserted } from "./inserted";
import { reworkItemStatusUpdated } from "./updated";
import { reworkItemStatusDelete } from "./delete";
import { reworkItemStatusDeleted } from "./deleted";

interface IAOSClientServiceWrappedReworkItemStatus {
    delete: AOSClientServiceRequestWrapped<ReworkItemStatusDeleteReq, ReworkItemStatusDeleteRes>;
    getAll: AOSClientServiceRequestWrapped<ReworkItemStatusAllReq, ReworkItemStatusAllRes>;
    getOne: AOSClientServiceRequestWrapped<ReworkItemStatusOneReq, ReworkItemStatusOneRes>;
    insert: AOSClientServiceRequestWrapped<ReworkItemStatusInsertReq, ReworkItemStatusInsertRes>;
    onDeleted: AOSClientServiceNotificationWrapped<ReworkItemStatusDeletedNotif>;
    onInserted: AOSClientServiceNotificationWrapped<ReworkItemStatusInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<ReworkItemStatusUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<ReworkItemStatusUpdateReq, ReworkItemStatusUpdateRes>;
}

type AOSClientServiceWrappedReworkItemStatus = IAOSClientServiceWrappedReworkItemStatus;

// ADD_API_CALL ReworkItemStatus
export function generateReworkItemStatusServiceClient(
    router: IRouterClient,
    notifier: EventEmitter
): AOSClientServiceWrappedReworkItemStatus {
    return {
        delete: AOSClientServiceWrapperUI.request(reworkItemStatusDelete(router)),
        getAll: AOSClientServiceWrapperUI.request(reworkItemStatusAll(router)),
        getOne: AOSClientServiceWrapperUI.request(reworkItemStatusOne(router)),
        insert: AOSClientServiceWrapperUI.request(reworkItemStatusInsert(router)),
        onDeleted: AOSClientServiceWrapperUI.notification(reworkItemStatusDeleted(router, notifier)),
        onInserted: AOSClientServiceWrapperUI.notification(reworkItemStatusInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(reworkItemStatusUpdated(router, notifier)),
        update: AOSClientServiceWrapperUI.request(reworkItemStatusUpdate(router)),
    };
}

generateReworkItemStatusServiceClient.serviceId = 120;
