import {
    TrainingCertificationPendingDbModelKey,
    TrainingCertificationPendingDeleteRes,
    TrainingCertificationPendingGetAllByUserIdRes,
    TrainingCertificationPendingGetAllRequest,
    TrainingCertificationPendingGetAllRes,
    TrainingCertificationSignRes,
    UnwrapAOSPayload,
    UserId,
} from "@kortex/aos-common";

import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import {
    trainingCertificationPendingListDeletedAction,
    trainingCertificationPendingListInsertedAction,
    trainingCertificationPendingListSetAction,
    trainingCertificationPendingOfUserClear,
    trainingCertificationPendingOfUserDeletedAction,
    trainingCertificationPendingOfUserSetAction,
} from "./training-certification-pending-actions";
import { TrainingCertificationSign } from "./training-certification-pending-types";

/**
 * clear training pending state
 *
 */
export function clearTrainingCertificationPendingOfUser(): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(trainingCertificationPendingOfUserClear());
    };
}

/**
 * Fetch all trainings
 */
export function trainingCertificationPendingGetAll(
    limit: number,
    offset: number,
    filterOptions: TrainingCertificationPendingGetAllRequest["filterOptions"],
    options: IStandardThunkOptions
): StandardThunk<UnwrapAOSPayload<TrainingCertificationPendingGetAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TrainingCertificationPendingGetAllRes>> => {
        if (!skipLookup) {
            return { data: getState().trainingCertificationPending.trainingCertificationPendingList };
        }

        return api.services.trainingCertificationPending
            .getAll({ filterOptions, limit, offset })()
            .then((res) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(trainingCertificationPendingGetAll);
                    if (offset === 0) {
                        dispatch(trainingCertificationPendingListSetAction(res.data));
                    } else {
                        dispatch(trainingCertificationPendingListInsertedAction(res.data));
                    }
                }

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return { data: [] };
            });
    };
}

/**
 * Fetch all trainings pending of userId
 */
export function trainingCertificationPendingGetAllByUserId(
    userId: UserId,
    options: IStandardThunkOptions
): StandardThunk<UnwrapAOSPayload<TrainingCertificationPendingGetAllByUserIdRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TrainingCertificationPendingGetAllByUserIdRes>> => {
        if (!skipLookup) {
            return getState().trainingCertificationPending.trainingCertificationsPendingOfUser;
        }

        return api.services.trainingCertificationPending
            .getAllByUserId({ userId: userId })()
            .then((res) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(trainingCertificationPendingGetAllByUserId);
                    dispatch(trainingCertificationPendingOfUserSetAction([...res]));
                }

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return [];
            });
    };
}

/**
 * Training Certification Sign
 */
export function trainingCertificationSign(
    trainingCertification: TrainingCertificationSign
): StandardThunk<UnwrapAOSPayload<TrainingCertificationSignRes>["trainingCertificationsPendingDeleted"]> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TrainingCertificationSignRes>["trainingCertificationsPendingDeleted"]> {
        try {
            return api.services.trainingCertificationPending
                .trainingCertificationSign(trainingCertification.trainingsCertificationSelected)()
                .then((res) => {
                    const cleanState = [...res.trainingCertificationsPendingDeleted];
                    trainingCertification.unselectTrainingsCertification.map((unseleteTraining) => cleanState.push(unseleteTraining));

                    // Deleting deleted and unsigned pending trainings from redux. Unsigned pending trainings will be displayed again the next time you open AOS.
                    dispatch(trainingCertificationPendingOfUserDeletedAction(cleanState));
                    dispatch(trainingCertificationPendingListDeletedAction([...res.trainingCertificationsPendingDeleted]));

                    return res.trainingCertificationsPendingDeleted;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            return [];
        }
    };
}

/**
 * Delete Training Certification
 */
export function trainingCertificationPendingDelete(
    trainingCertificationPendingId: TrainingCertificationPendingDbModelKey
): StandardThunk<UnwrapAOSPayload<TrainingCertificationPendingDeleteRes>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TrainingCertificationPendingDeleteRes>> => {
        try {
            return api.services.trainingCertificationPending
                .delete(trainingCertificationPendingId)()
                .then((deletedTrainingCertificationPending) => {
                    dispatch(trainingCertificationPendingListDeletedAction([...deletedTrainingCertificationPending]));
                    return deletedTrainingCertificationPending;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            return [];
        }
    };
}
