import { upsertObjectFromArray } from "@kortex/aos-common";

import { ResultSettingActions, ResultSettingActionType, ResultSettingState } from "./result-setting-types";

const initialState: ResultSettingState = {
    resultSettingItems: [],
    resultSettingMetadata: [],
};

export function resultSettingReducer(state: ResultSettingState = initialState, action: ResultSettingActions): ResultSettingState {
    switch (action.type) {
        case ResultSettingActionType.SET_RESULT_SETTING_ITEM_LIST:
            return {
                ...state,
                resultSettingItems: [...action.value],
            };
        case ResultSettingActionType.UPSERT_RESULT_SETTING_ITEM:
            return {
                ...state,
                resultSettingItems: upsertObjectFromArray(state.resultSettingItems, action.value, function (item) {
                    return item.resultSettingItemId === this.resultSettingItemId;
                }),
            };
        case ResultSettingActionType.SET_RESULT_SETTING_METADATA_LIST:
            return {
                ...state,
                resultSettingMetadata: [...action.value],
            };
        case ResultSettingActionType.UPSERT_RESULT_SETTING_METADATA:
            return {
                ...state,
                resultSettingMetadata: upsertObjectFromArray(state.resultSettingMetadata, action.value, function (metadata) {
                    return metadata.resultSettingMetadataId === this.resultSettingMetadataId;
                }),
            };
        default:
            return state;
    }
}
