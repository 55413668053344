export class OutputState {
    constructor() {
        this.reworkItemStatusId = 0;
        this.problems = [];
        this.showFailForm = false;
        this.failureInfo = undefined;
        this.reworkNewItemStatusId = 0;
        this.reworkComment = "";
    }
    toJSON() {
        return {
            problems: this.problems,
            reworkItemStatusId: this.reworkItemStatusId,
            failureInfo: this.failureInfo,
            reworkComment: this.reworkComment,
            reworkNewItemStatusId: this.reworkNewItemStatusId,
            showFailForm: this.showFailForm,
        };
    }
}
