import { IBomFollowUp, IWoBomComplete } from "@kortex/aos-common";
import { deepClone } from "@kortex/utilities";

import { BomActionType, BomActions, BomState } from "./bom-types";

const initialState: BomState = {
    boms: [],
};

export function bomReducer(state: BomState = initialState, action: BomActions): BomState {
    let bom: IWoBomComplete | undefined;
    let followUp: IBomFollowUp;
    let followUpIndex: number;
    let updatedState: BomState;
    let updatedCount = 0;

    switch (action.type) {
        case BomActionType.CLEAR:
            return { boms: [] };
        case BomActionType.EDIT_ITEM:
            updatedState = deepClone(state);
            followUp = action.value;
            bom = updatedState.boms.find(
                (b) => b.woBom.jobRefId === action.value.jobRefId && b.woBom.trackingId === action.value.trackingId
            );

            if (!bom) return state; // Return original state

            // Find which follow-up to update
            followUpIndex =
                bom.woBom.items[followUp.partNumber]?.followUp[followUp.processActionStepId]?.findIndex(
                    (followUpToUpdate) => followUpToUpdate.bomFollowUpId === followUp.bomFollowUpId
                ) ?? -1;

            if (followUpIndex === -1) return state; // Return original state

            bom.woBom.items[followUp.partNumber].followUp[followUp.processActionStepId][followUpIndex] = followUp;

            return updatedState;
        case BomActionType.INSERT_ITEM:
            updatedState = deepClone(state);
            followUp = action.value;
            bom = updatedState.boms.find(
                (b) => b.woBom.jobRefId === action.value.jobRefId && b.woBom.trackingId === action.value.trackingId
            );

            if (!bom || !bom.woBom.items[followUp.partNumber]) return state; // Return original state

            // Insert follow-up
            if (bom.woBom.items[followUp.partNumber].followUp[followUp.processActionStepId]) {
                bom.woBom.items[followUp.partNumber].followUp[followUp.processActionStepId].push(followUp);
            } else {
                bom.woBom.items[followUp.partNumber].followUp[followUp.processActionStepId] = [followUp];
            }

            return updatedState;
        case BomActionType.MULTIPLE_TRACEABILITIES_ITEM:
            updatedState = deepClone(state);

            for (const followUp of action.value) {
                bom = updatedState.boms.find((b) => b.woBom.jobRefId === followUp.jobRefId && b.woBom.trackingId === followUp.trackingId);

                if (!bom) continue;

                // Find which follow-up to update
                followUpIndex =
                    bom.woBom.items[followUp.partNumber]?.followUp[followUp.processActionStepId]?.findIndex(
                        (followUpToUpdate) => followUpToUpdate.bomFollowUpId === followUp.bomFollowUpId
                    ) ?? -1;

                if (followUpIndex === -1) {
                    // New follow-up
                    bom.woBom.items[followUp.partNumber].followUp[followUp.processActionStepId].push(followUp);
                } else {
                    // Original follow-up
                    bom.woBom.items[followUp.partNumber].followUp[followUp.processActionStepId][followUpIndex] = followUp;
                }

                updatedCount++;
            }

            return updatedCount > 0 ? updatedState : state; // If updatedCount is 0, do not trigger redux update by returning original state
        case BomActionType.OVERCONSUME_ITEM:
            updatedState = deepClone(state);
            followUp = action.value;
            bom = updatedState.boms.find(
                (b) => b.woBom.jobRefId === action.value.jobRefId && b.woBom.trackingId === action.value.trackingId
            );

            if (!bom || !bom.woBom.items[followUp.partNumber]?.followUp[followUp.processActionStepId]) return state; // Return original state

            bom.woBom.items[followUp.partNumber].followUp[followUp.processActionStepId].push(followUp);

            return updatedState;
        case BomActionType.REPLACE_ITEM:
            updatedState = deepClone(state);

            for (const followUp of action.value) {
                bom = updatedState.boms.find((b) => b.woBom.jobRefId === followUp.jobRefId && b.woBom.trackingId === followUp.trackingId);

                if (!bom) continue;

                // Find which follow-up to update
                followUpIndex =
                    bom.woBom.items[followUp.partNumber]?.followUp[followUp.processActionStepId]?.findIndex(
                        (followUpToUpdate) => followUpToUpdate.bomFollowUpId === followUp.bomFollowUpId
                    ) ?? -1;

                if (followUpIndex === -1) {
                    // New follow-up
                    bom.woBom.items[followUp.partNumber].followUp[followUp.processActionStepId].push(followUp);
                } else {
                    // Original follow-up
                    bom.woBom.items[followUp.partNumber].followUp[followUp.processActionStepId][followUpIndex] = followUp;
                }

                updatedCount++;
            }

            return updatedCount > 0 ? updatedState : state; // If updatedCount is 0, do not trigger redux update by returning original state
        case BomActionType.SET_BOMS:
            return {
                ...state,
                boms: action.value,
            };
        case BomActionType.INSERT_SERIALIZED_ITEM:
            updatedState = deepClone(state);
            followUp = action.value;
            bom = updatedState.boms.find(
                (b) => b.woBom.jobRefId === action.value.jobRefId && b.woBom.trackingId === action.value.trackingId
            );

            if (!bom) return state; // Return original state

            // Find which follow-up to update
            followUpIndex =
                bom.woBom.items[followUp.partNumber]?.followUp[followUp.processActionStepId]?.findIndex(
                    (followUpToUpdate) => followUpToUpdate.bomFollowUpId === followUp.bomFollowUpId
                ) ?? -1;

            if (followUpIndex === -1) return state; // Return original state

            bom.woBom.items[followUp.partNumber].followUp[followUp.processActionStepId][followUpIndex] = followUp;

            return updatedState;
        default:
            return state;
    }
}
