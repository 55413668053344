import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import { AOSClientServiceRequestWrapped, ElectronUpdateHostReq, ElectronUpdateHostRes } from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { electronHostUpdate } from "./updateHost";

interface IAOSClientServiceWrappedElectron {
    updateHost: AOSClientServiceRequestWrapped<ElectronUpdateHostReq, ElectronUpdateHostRes>;
}

type AOSClientServiceWrappedElectron = IAOSClientServiceWrappedElectron;

// ADD_API_CALL

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function generateElectronServiceClient(router: IRouterClient): AOSClientServiceWrappedElectron {
    return {
        updateHost: AOSClientServiceWrapperUI.request(electronHostUpdate(router)),
    };
}

generateElectronServiceClient.serviceId = 71;
