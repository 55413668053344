import { IReworkItemStatusDbModel, ReworkItemStatusAllRes, ReworkItemStatusDbModelKey, UnwrapAOSPayload } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import {
    reworkItemStatusDeletedAction,
    reworkItemStatusInsertedAction,
    reworkItemStatusUpdatedAction,
    setReworkItemStatusListAction,
} from "../rework-manager/rework-actions";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

/**
 * Get all reworkItemStatus
 */
export function reworkItemStatusGetAll(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<ReworkItemStatusAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ReworkItemStatusAllRes>> {
        if (!skipLookup) {
            if (fetchedOnce.was(reworkItemStatusGetAll)) {
                return getState().rework.reworkItemStatus;
            }
        }

        return api.services.reworkItemStatus
            .getAll(emptyObject)()
            .then((reworkItemStatus) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(reworkItemStatusGetAll);

                    dispatch(setReworkItemStatusListAction([...reworkItemStatus]));
                }

                return reworkItemStatus;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

/**
 * Get one reworkItemStatus
 */
export function reworkItemStatusGetOne(
    reworkItemStatusId: ReworkItemStatusDbModelKey
): StandardThunk<IReworkItemStatusDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IReworkItemStatusDbModel | undefined> => {
        try {
            const resp = await api.services.reworkItemStatus
                .getOne(reworkItemStatusId)()
                .then((reworkItemStatus) => {
                    dispatch(reworkItemStatusUpdatedAction([reworkItemStatus]));
                    return reworkItemStatus;
                });
            return resp;
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return undefined;
        }
    };
}

/**
 * Insert one reworkItemStatus
 */
export function reworkItemStatusInsert(
    reworkItemStatus: APIPayload<"reworkItemStatus", "insert">
): StandardThunk<IReworkItemStatusDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IReworkItemStatusDbModel | undefined> => {
        try {
            const resp = await api.services.reworkItemStatus
                .insert(reworkItemStatus)()
                .then((reworkItemStatus) => {
                    dispatch(reworkItemStatusInsertedAction([reworkItemStatus]));
                    return reworkItemStatus;
                });
            return resp;
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return undefined;
        }
    };
}

/**
 * Update reworkItemStatus
 */
export function reworkItemStatusUpdate(reworkItemStatus: APIPayload<"reworkItemStatus", "update">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.reworkItemStatus
                .update(reworkItemStatus)()
                .then((reworkItemStatus) => {
                    dispatch(reworkItemStatusUpdatedAction([reworkItemStatus]));
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
    };
}

/**
 * Delete reworkItemStatus
 */
export function reworkItemStatusDelete(reworkItemStatus: APIPayload<"reworkItemStatus", "delete">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.reworkItemStatus
                .delete(reworkItemStatus)()
                .then((reworkItemStatus) => {
                    dispatch(reworkItemStatusDeletedAction(reworkItemStatus));
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
    };
}
