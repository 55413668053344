import { decode } from "jsonwebtoken";
/**
 * Decodes and types an AOS JWT token the unsecure way :'(
 *
 * @param {string} token - token to decode
 * @returns {ITokenDecoded}
 */
export function jwtDecodeUnsecured(token) {
    return decode(token);
}
