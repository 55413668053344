import { SequencerError } from "./sequencer-error";
export class CoreActionFlowError extends SequencerError {
    constructor(key, message = "Core action flow error") {
        super(key, message);
    }
    get name() {
        return "CoreActionFlowError";
    }
    get originalError() {
        return super.originalError;
    }
}
