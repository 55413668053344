import { TreeNodeNodeTypeEnum } from "./ITreeNodeDbModel";
export const TreeFileInsertType = {
    CAD: TreeNodeNodeTypeEnum.CAD,
    FOLDER: TreeNodeNodeTypeEnum.FOLDER,
    IMAGE: TreeNodeNodeTypeEnum.IMAGE,
    PDF: TreeNodeNodeTypeEnum.PDF,
    SOURCE_CODE: TreeNodeNodeTypeEnum.SOURCE_CODE,
    SVG: TreeNodeNodeTypeEnum.SVG,
    TEXT: TreeNodeNodeTypeEnum.TEXT,
    VIDEO: TreeNodeNodeTypeEnum.VIDEO,
};
