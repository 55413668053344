import { DataStoreState } from "@kortex/aos-common";
import { theme } from "@aos/react-components";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useEffect, useRef } from "react";
import { AutoSizer } from "react-virtualized";

const useStyles = makeStyles({
    dataStoreConsole: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        height: "90%",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "16px",
        width: "90%",
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
});

export interface IOwnProps {
    actionState: DataStoreState;
}

export default function DataStorePlayer(props: IOwnProps): JSX.Element {
    const { actionState } = props;

    const classes = useStyles();
    const dataStoreConsole = useRef<HTMLDivElement | null>(null);

    /**
     * Auto scroll dataStoreConsole
     */
    useEffect((): void => {
        if (dataStoreConsole && dataStoreConsole.current) {
            dataStoreConsole.current.scrollTop = dataStoreConsole.current.scrollHeight;
        }
    }, [actionState]);

    return (
        <div className={classes.dataStoreConsole} ref={dataStoreConsole}>
            <AutoSizer>
                {({ height, width }): JSX.Element => (
                    <div style={{ height, width }}>
                        {actionState.result.map((line, index): JSX.Element => {
                            return (
                                <div key={index}>
                                    <span>&#8226;</span> {/* 8226 = Bullets */}
                                    {line}
                                </div>
                            );
                        })}
                    </div>
                )}
            </AutoSizer>
        </div>
    );
}
