import { IRouterClientSendOptions } from "@kortex/aos-api-client/definitions/client/IRouterClient";

import { handleAPIError } from "../redux/handleAPIError";
import { AOSKortexClient, APIPayload, APIResponse, client } from "../utilitites/kortex-client/client";

import { useThunkDispatch } from "./useThunkDispatch";

export function useClientService<Module extends keyof AOSKortexClient["services"], Fn extends keyof AOSKortexClient["services"][Module]>(
    moduleService: Module,
    functionModule: Fn
): (params: APIPayload<Module, Fn>, options?: IRouterClientSendOptions) => Promise<APIResponse<Module, Fn> | undefined> {
    const dispatch = useThunkDispatch();

    return (params: APIPayload<Module, Fn>, options?: IRouterClientSendOptions): Promise<APIResponse<Module, Fn> | undefined> =>
        // @ts-expect-error - type should be fine
        client.services[moduleService][functionModule](params)(options).catch((reason: unknown) => {
            // @ts-expect-error - we don't need to know the typing of 'reason'
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return;
        });
}
