import { ServiceManagerClient, WSClient, Kinova } from "@kortex/aos-api-client";
import { getRPCHubInfo, TreeProcessAllReq, TreeProcessAllRes } from "@kortex/aos-common";

import { objectFromUTF8Array, utf8ArrayFromObject, utf8ArrayToKortexApiErrorObject } from "../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const treeProcessAll = (router: WSClient["router"]) =>
    ServiceManagerClient.getRR<TreeProcessAllReq, TreeProcessAllRes, Kinova.Api.IError>(getRPCHubInfo("treeProcess")("all"), {
        router,
        serializer: {
            decode: objectFromUTF8Array,
            encode: utf8ArrayFromObject,
            err: {
                decode: utf8ArrayToKortexApiErrorObject,
            },
        },
    });
