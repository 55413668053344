import { theme } from "@aos/react-components";
import { ITrainingCertificationPending, TrainingCertificationId, getFormattedDate } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Checkbox, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";

const useStyles = makeStyles({
    certification: {
        display: "flex",
    },
    processListContent: {
        display: "flex",
    },
    processName: {
        width: "80%",
    },
    date: {
        fontSize: "12px",
    },
    divider: {
        marginTop: "10px",
    },
});

interface IOwnProps {
    index: number;
    onSelectedTrainingCertificationIds: (trainingCertificationIds: TrainingCertificationId[]) => void;
    selectedTrainingCertificationIds: TrainingCertificationId[];
    trainingCertificationPending: ITrainingCertificationPending;
    trainingCertificationPendingCount: number;
}

export const TrainingCertificationContextSignature: FC<IOwnProps> = (props) => {
    const {
        index,
        onSelectedTrainingCertificationIds,
        selectedTrainingCertificationIds,
        trainingCertificationPending,
        trainingCertificationPendingCount,
    } = props;
    const classes = useStyles();

    const translate = useTranslate();

    /**
     * Handle called when the user is selected
     */
    const handleSelectTrainingCertification =
        (trainingCertificationId: TrainingCertificationId): ((_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) =>
        (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            const trainingCertificationIds = [...selectedTrainingCertificationIds];

            if (checked) {
                trainingCertificationIds.push(trainingCertificationId);
            } else {
                trainingCertificationIds.splice(trainingCertificationIds.indexOf(trainingCertificationId), 1);
            }

            onSelectedTrainingCertificationIds(trainingCertificationIds);
        };

    return (
        <List>
            <Typography>{translate("training.trainingCertificationCertifiedUserSignatureContext")}</Typography>
            <ListItem key={index} className={classes.certification}>
                <ListItemText
                    primary={<Typography variant="h6">{trainingCertificationPending.reference}</Typography>}
                    className={classes.processName}
                />
                <ListItemText primary={getFormattedDate(trainingCertificationPending.date ?? 0)} classes={{ primary: classes.date }} />
                <ListItemSecondaryAction>
                    <Checkbox
                        edge="end"
                        checked={selectedTrainingCertificationIds.includes(trainingCertificationPending.trainingCertificationId)}
                        id={`trainingCertificationSignatureCheckBoxSelectCertificationId${index}`}
                        onChange={handleSelectTrainingCertification(trainingCertificationPending.trainingCertificationId)}
                        value={trainingCertificationPending.trainingCertificationId}
                    />
                </ListItemSecondaryAction>
            </ListItem>
            {trainingCertificationPending.processes.map((process, index): JSX.Element => {
                return (
                    <ListItem
                        key={index}
                        className={classes.processListContent}
                        style={{
                            backgroundColor: index % 2 === 0 ? theme.palette.grey[200] : theme.palette.common.white,
                        }}
                    >
                        <ListItemText primary={process.label} className={classes.processName} />
                    </ListItem>
                );
            })}

            {index < trainingCertificationPendingCount && <Divider variant="fullWidth" className={classes.divider} />}
        </List>
    );
};

export default TrainingCertificationContextSignature;
