import { IKortexTextFieldProps, theme } from "@aos/react-components";
import { useKeybind } from "@kortex/aos-ui/hooks/useKeybind";
import {
    Grow,
    MenuItem,
    MenuItemProps,
    MenuList,
    MenuListProps,
    Paper,
    PaperProps,
    Popper,
    PopperProps,
    makeStyles,
} from "@material-ui/core";
import React, { MutableRefObject, useEffect, useState } from "react";

const ITEM_HEIGHT = 56;

const useStyles = makeStyles({
    menuItem: {
        padding: "16px 14px",
    },
    papper: {
        backgroundColor: theme.palette.common.white,
        maxHeight: `${ITEM_HEIGHT * 4.66}px`,
        overflow: "auto",
    },
    popper: {
        zIndex: theme.zIndex.modal,
    },
});

type TextFieldValue = IKortexTextFieldProps["value"];

export interface Item {
    component: JSX.Element;
    disabled?: boolean;
    filterCallback?: (value: TextFieldValue) => boolean;
    textFieldValue: TextFieldValue;
    value: TextFieldValue;
}

export interface OwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    disabled?: boolean;
    items: Item[];
    MenuItemProps?: Partial<MenuItemProps<"li", { button: true }>>;
    MenuListProps?: Partial<MenuListProps>;
    onItemClick: (value: Item["value"], textFieldValue: Item["textFieldValue"]) => void;
    open: boolean;
    PaperProps?: Partial<PaperProps>;
    PopperProps?: Partial<PopperProps>;
    textFieldRef: MutableRefObject<HTMLInputElement | null>;
    textFieldValue: TextFieldValue;
}

function TextFieldSelectMenu(props: OwnProps): JSX.Element | null {
    /**
     * Props
     */
    const {
        disabled = false,
        items,
        MenuItemProps = {},
        MenuListProps = {},
        onItemClick,
        PaperProps = {},
        open,
        PopperProps = {},
        textFieldRef,
        textFieldValue,
    } = props;

    // Do nothing if disabled
    if (disabled) return <></>;

    /**
     * State
     */
    const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);

    /**
     * Other hooks
     */
    const classes = useStyles(props);

    useEffect(() => setSelectedItemIndex(menuItems.findIndex((item) => item !== null)), [textFieldValue]);

    useEffect(
        () =>
            document.getElementById(`textFieldSelectMenuId${selectedItemIndex}`)?.scrollIntoView({
                block: "nearest",
                behavior: "smooth",
            }),
        [selectedItemIndex]
    );

    useKeybind(
        "ArrowDown",
        (): void => {
            let updatedValue = selectedItemIndex;

            do {
                updatedValue++;
                if (updatedValue >= menuItems.length) return void 0;
            } while (menuItems[updatedValue] === null);

            setSelectedItemIndex(updatedValue);
        },
        {
            disabled: !open,
            stopPropagation: true,
            next: !open,
        }
    );

    useKeybind(
        "ArrowUp",
        (): void => {
            let updatedValue = selectedItemIndex;

            do {
                updatedValue--;
                if (updatedValue < 0) return void 0;
            } while (menuItems[updatedValue] === null);

            setSelectedItemIndex(updatedValue);
        },
        {
            disabled: !open,
            stopPropagation: true,
            next: !open,
        }
    );

    useKeybind("Enter", () => handleItemClick(selectedItemIndex)(), {
        disabled: !open,
        stopPropagation: true,
        preventDefault: true,
    });

    /**
     * Functions
     */
    const handleItemClick =
        (index: number): (() => void) =>
        (): void => {
            const { textFieldValue, value } = items[index];

            onItemClick(value, textFieldValue);
        };

    /**
     * FunMenu items to renderctions
     */
    const menuItems = items.map(({ component, disabled = false, filterCallback = (): boolean => true, value }, index) =>
        filterCallback(textFieldValue) ? (
            <MenuItem
                button={true}
                className={classes.menuItem}
                disabled={disabled}
                id={`textFieldSelectMenuId${index}`}
                key={`textFieldSelectMenu${index}`}
                onClick={handleItemClick(index)}
                selected={selectedItemIndex === index}
                value={value}
                {...MenuItemProps}
            >
                {component}
            </MenuItem>
        ) : null
    );

    /**
     * Component
     */
    return (
        <Popper
            anchorEl={textFieldRef.current}
            className={classes.popper}
            open={open}
            placement="bottom-start"
            transition={true}
            {...PopperProps}
        >
            {({ TransitionProps }): JSX.Element => (
                <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
                    <Paper className={classes.papper} {...PaperProps}>
                        <MenuList {...MenuListProps}>{menuItems}</MenuList>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}

export default TextFieldSelectMenu;
