import { KortexTextField, theme } from "@aos/react-components";
import {
    EnumProgressRunSelection,
    EnumTrackingType,
    IInProgressRun,
    IInputState,
    JobRefId,
    ProcessActionStepInput,
    getDateTimeStr,
} from "@kortex/aos-common";
import { Button, IconButton, MenuItem, Radio, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconUnlock from "@material-ui/icons/LockOpenOutlined";
import IconLock from "@material-ui/icons/LockOutlined";
import React, { useEffect, useState } from "react";

import { useTranslate } from "../../../../hooks/useTranslate";
import { useEntitiesUsers } from "../../../../redux/effects";
import ProcessIcon from "../../Icons/Process/Process";
import KortexLabelText from "../../KortexLabelText";
import { usePlayerContext } from "../context";

const INPUT_DELAY_MS = 500;

const useStyles = makeStyles({
    tracking: {
        width: "400px",
        marginBottom: "20px",
    },
    trackingSN: {
        display: "flex",
        flexDirection: "row",
        width: "400px",
        marginBottom: "20px",
    },
    trackingField: {
        width: "400px",
    },
    reference: {
        width: "400px",
        marginTop: "30px",
        marginBottom: "20px",
    },
    select: {
        width: "100%",
        marginBottom: "20px",
    },
    selectField: {
        width: "400px",
    },
    formCb: {
        paddingLeft: "10px",
    },
    content: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    title: {
        paddingBottom: "20px",
    },
    actions: {
        display: "flex",
        justifyContent: "flex-end",
    },
    buttons: {
        margin: "5px",
        padding: "0px 30px",
    },
    itemList: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    itemText: {
        padding: "20px",
    },
    itemIcon: {
        padding: "20px",
    },
    selectQuantity: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
    },
});

export interface IOwnProps {
    actionProps: ProcessActionStepInput;
    actionState: IInputState;
    disabled?: boolean;
    processName?: string;

    onAddComment: () => void;
    onNew: () => void;
    onResume: (resultProcessHistoryIdSelected?: number, instancesSelected?: string, isResumeOfAnFailure?: boolean) => void;
    onStateChange?: (actionState: IInputState) => void;
}

export default function InputPlayer(props: IOwnProps): JSX.Element {
    const { actionProps, actionState, disabled = false, processName } = props;
    const classes = useStyles();
    const { mock, playerState } = usePlayerContext();
    const translate = useTranslate();
    const userList = useEntitiesUsers();

    const [serialNumberError, setSerialNumberError] = useState<string>("");
    const [trackingNumberError, setTrackingNumberError] = useState<string>("");
    const [batchQtyError, setBatchQtyError] = useState<string>("");
    const [qtyMenuItems, setQtyMenuItems] = useState<number[]>([1]);
    const [qty, setQty] = useState<number>(1);

    // ProgressRunDialog
    const [allInProgressRunStopped, setAllInProgressRunStopped] = useState<IInProgressRun[]>([]);
    const [allInProgressRunFailed, setAllInProgressRunFailed] = useState<IInProgressRun[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>("");
    const [selectProgressRunDialog, setSelectProgressRunDialog] = useState<boolean>(false);
    //const [inProgressRunSelected, setInProgressRunSelected] = useState<IInProgressRun | undefined>(undefined);
    const [resultProcessHistoryIdSelected, setResultProcessHistoryIdSelected] = useState<number | undefined>(undefined);
    const [instancesSelected, setInstancesSelected] = useState<string | undefined>(undefined);
    const [isResumeOfAnFailure, setIsResumeOfAnFailure] = useState<boolean>(false);

    // We only use this to mock a BOM while in dry/test run
    const mockJobEnabled =
        playerState.processState.bom.enabled && (playerState.processState.dryRunMode || playerState.processState.isTestRun);

    // Update local state when parent props are injected
    useEffect((): void => {
        if (
            actionState &&
            actionState.selectedProgressRun === EnumProgressRunSelection.UNSELECT &&
            actionState.inProgressRun &&
            actionState.inProgressRun
        ) {
            setAllInProgressRunStopped(actionState.inProgressRun.allInProgressRunStopped);
            setAllInProgressRunFailed(actionState.inProgressRun.allInProgressRunFailed);
            setSelectProgressRunDialog(true);
        }
        if (actionState && actionState.retryComment && props.onAddComment) {
            props.onAddComment();
        }

        if (actionState && actionState.error.serialNumberMissing) {
            setSerialNumberError(translate("player.inputPlayer.invalidEmpty"));
        } else if (actionState && actionState.error.serialNumberRegexError) {
            setSerialNumberError(translate("player.inputPlayer.invalidRegex"));
        } else if (actionState && actionState.error.previousProcessNotRun) {
            setSerialNumberError(translate("player.inputPlayer.previousProcessNotRun"));
        } else {
            setSerialNumberError("");
        }

        if (actionState && actionState.error.invalidBatchQty) {
            setBatchQtyError(translate("player.inputPlayer.invalidBatchQty"));
        } else {
            setBatchQtyError("");
        }

        if (actionState && actionState.error.trackingNumberMissing) {
            setTrackingNumberError(translate("player.inputPlayer.invalidEmpty"));
        } else if (actionState && actionState.error.trackingNumberRegexError) {
            setTrackingNumberError(translate("player.inputPlayer.invalidRegex"));
        } else {
            setTrackingNumberError("");
        }
    }, [actionProps, actionState]);

    // Set Quantity to play based on batch, fail and remaining
    useEffect((): void => {
        // Check if we handle quantities (Re. we are in a jobProcess)
        if (actionState.quantities.total > 0) {
            /* Check if new item is produced */
            if (actionState.batchNew) {
                let quantity = actionState.quantities.total - actionState.quantities.passed;
                // Never to more than the max set in the process
                if (quantity > actionProps.config.maxBatchQty) {
                    quantity = actionProps.config.maxBatchQty;
                }
                setQtyMenuItems([...Array(quantity).keys()].map((i) => i + 1));
            } else {
                // Only failed item can be "repaired" when not producing new stuff
                let quantity = actionState.quantities.failed;

                // Never to more than the max set in the process
                if (quantity > actionProps.config.maxBatchQty) {
                    quantity = actionProps.config.maxBatchQty;
                }
                setQtyMenuItems([...Array(quantity).keys()].map((i) => i + 1));
                if (actionState.quantities.failed === 0 && actionState.batchQty !== 0) {
                    const newState = { ...actionState };
                    newState.batchQty = 0;

                    if (props.onStateChange) {
                        props.onStateChange(newState);
                    }
                }
            }
        } else {
            setQtyMenuItems([...Array(actionProps.config.maxBatchQty).keys()].map((i) => i + 1));
        }
        setQty(actionState.batchQty);
    }, [actionState]);

    /**
     * handles the value changed of textfields
     *
     * @param {string} propsKey - property key of IInputState
     * @returns {(value: string) => undefined}
     */
    const handleValueChanged =
        (propsKey: keyof IInputState): ((value: string) => void) =>
        (value: string): void => {
            const newState = { ...actionState, [propsKey]: value };

            if (props.onStateChange) {
                props.onStateChange(newState);
            }
        };

    const handleMockJobChanged = (jobRefId: JobRefId): void => {
        mock.set("jobRefId", jobRefId);
    };

    /**
     * Called in batch to unlock or lock the serial number field to avoir errors
     */
    const handleSerialNumberUnLock = (): void => {
        const newState = { ...actionState, ["serialNumberLock"]: !actionState.serialNumberLock };

        if (props.onStateChange) {
            props.onStateChange(newState);
        }
    };

    // ProgressRunDialog
    /**
     * handle the continue
     */
    const handleResume = (): void => {
        if (resultProcessHistoryIdSelected) {
            setSelectedValue("");
            props.onResume(resultProcessHistoryIdSelected, instancesSelected, isResumeOfAnFailure);
        }
    };

    /**
     * handle the continue
     */
    const handleNew = (): void => {
        if (props.onNew) {
            setResultProcessHistoryIdSelected(undefined);
            setInstancesSelected(undefined);
            setSelectedValue("");
            props.onNew();
        }
    };

    /**
     * Handle select/unselect of the result Process History
     */
    const handleSelectedResultProcessHistory =
        (inProgressRunSelected: IInProgressRun, isFail: boolean): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            setSelectedValue(event.target.value);
            setIsResumeOfAnFailure(isFail);
            setResultProcessHistoryIdSelected(inProgressRunSelected.resultProcessHistory.resultProcessHistoryId);
            setInstancesSelected(inProgressRunSelected.trackingInstances);
        };

    /**
     * info in progress run
     *
     * @param {IInProgressRun} inProgressRunInfo - log of the rework
     */
    const infoInprogressRun = (inProgressRunInfo: IInProgressRun, index: number): JSX.Element => {
        const user = userList.find((user) => user.userId === inProgressRunInfo.resultProcessHistory.userId);
        const value = `${inProgressRunInfo.resultProcessHistory.stopTime}-${inProgressRunInfo.resultProcessHistory.resultMasterId}`;
        const isFail = inProgressRunInfo.resultProcessHistory.failed > 0;

        return (
            <div className={classes.itemList} key={index}>
                <ProcessIcon className={classes.itemIcon} />
                <KortexLabelText
                    className={classes.itemText}
                    label={translate("selectProgressRunInfo.startTime")}
                    text={getDateTimeStr(inProgressRunInfo.resultProcessHistory.startTime)}
                />
                {inProgressRunInfo.resultProcessHistory?.stopTime !== 0 && (
                    <KortexLabelText
                        className={classes.itemText}
                        label={translate("selectProgressRunInfo.stopTime")}
                        text={getDateTimeStr(inProgressRunInfo.resultProcessHistory.stopTime)}
                    />
                )}
                <KortexLabelText
                    className={classes.itemText}
                    label={"Run by"}
                    text={`${user?.firstName} ${user?.lastName} (${user?.userName})`}
                />
                <KortexLabelText
                    className={classes.itemText}
                    label={translate("action.input.quantity")}
                    text={`${inProgressRunInfo.trackingInstances.split(",").length}`}
                />
                {inProgressRunInfo.resultProcessHistory.failed > 0 && (
                    <KortexLabelText
                        className={classes.itemText}
                        label={translate("selectProgressRunInfo.trackingInstanceList")}
                        text={inProgressRunInfo.trackingInstances}
                    />
                )}
                <Radio
                    id={`selecteResultProcessHistory${index}`}
                    checked={selectedValue === value}
                    onChange={handleSelectedResultProcessHistory(inProgressRunInfo, isFail)}
                    value={value}
                    name="radio-button"
                    color="secondary"
                />
            </div>
        );
    };

    return (
        <div style={{ position: "relative" }} id="inputProcessActionInputId">
            {/* SERIAL NUMBER */}
            {actionProps.config.serialType !== EnumTrackingType.NONE && (
                <div className={classes.tracking}>
                    <div className={classes.trackingSN}>
                        <KortexTextField
                            className={classes.tracking}
                            label={actionProps.config.serialNumberLabelLoc.defaultMessage}
                            value={actionState.serialNumber}
                            onChanged={handleValueChanged("serialNumber")}
                            TextFieldProps={{
                                id: "serialNumberInputId",
                                autoFocus: true,
                                required: true,
                                disabled:
                                    disabled ||
                                    (!actionState.batchNew && actionState.quantities.failed === 0) ||
                                    actionState.serialNumberLock,
                            }}
                            changedDelayMS={INPUT_DELAY_MS}
                        />
                        {Boolean(actionProps.config.enableBatchProcessing) && (
                            <div>
                                <IconButton onClick={handleSerialNumberUnLock}>
                                    {actionState.serialNumberLock ? <IconLock /> : <IconUnlock />}
                                </IconButton>
                            </div>
                        )}
                    </div>

                    {serialNumberError ? (
                        <Typography variant={"body2"} color={"error"}>
                            {serialNumberError}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </div>
            )}
            {/* BATCH */}
            {Boolean(actionProps.config.enableBatchProcessing) && (
                <div className={classes.selectQuantity}>
                    <KortexTextField
                        className={classes.selectField}
                        label={translate("action.input.quantity")}
                        onChanged={handleValueChanged("batchQty")}
                        TextFieldProps={{
                            disabled: disabled || (actionState.quantities.failed === 0 && !actionState.batchNew),
                            select: true,
                            id: "quantitySelectId",
                        }}
                        value={qty}
                    >
                        {qtyMenuItems.map((val, index) => (
                            <MenuItem id={`quantitySelect_${index + 1}_Id`} value={val} key={index}>
                                {val}
                            </MenuItem>
                        ))}
                    </KortexTextField>
                    {batchQtyError ? (
                        <Typography variant={"body2"} color={"error"}>
                            {batchQtyError}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </div>
            )}
            {/* MOCK JOB */}
            {mockJobEnabled ? (
                <div className={classes.trackingSN}>
                    <KortexTextField
                        changedDelayMS={INPUT_DELAY_MS}
                        className={classes.tracking}
                        label={translate("action.input.job")}
                        onChanged={handleMockJobChanged}
                        TextFieldProps={{
                            disabled,
                            id: "mockJobId",
                        }}
                        value={mock.jobRefId ?? ""}
                    />
                </div>
            ) : null}
            {/* PROGRESS RUN DIALOG */}
            {selectProgressRunDialog && (
                <div className={classes.select} id={"resumeProcessInfoId"}>
                    <div className={classes.content}>
                        <Typography className={classes.title} variant="h6">
                            {processName}
                        </Typography>
                        {allInProgressRunStopped.length > 0 && (
                            <div>
                                <Typography className={classes.title} variant="h4" id="titleRunStoppedId">
                                    {translate("selectProgressRunInfo.runInProgress")}
                                </Typography>
                                {allInProgressRunStopped.map(
                                    (inProgressRunInfo, index): JSX.Element => infoInprogressRun(inProgressRunInfo, index)
                                )}
                            </div>
                        )}
                        {allInProgressRunFailed.length > 0 && (
                            <div>
                                <Typography className={classes.title} variant="h4" id="titleRunFailedId">
                                    {translate("selectProgressRunInfo.lastRunFailed")}
                                </Typography>
                                {allInProgressRunFailed.map(
                                    (inProgressRunInfo, index): JSX.Element => infoInprogressRun(inProgressRunInfo, index)
                                )}
                            </div>
                        )}
                    </div>
                    <div className={classes.actions}>
                        <Button
                            id="resumeButtonId"
                            variant="contained"
                            color="secondary"
                            disabled={!resultProcessHistoryIdSelected}
                            onClick={handleResume}
                            className={classes.buttons}
                        >
                            <Typography>{translate("general.resume")}</Typography>
                        </Button>
                        <Button id="newButtonId" variant="contained" color="secondary" onClick={handleNew} className={classes.buttons}>
                            <Typography>{translate("general.new")}</Typography>
                        </Button>
                    </div>
                </div>
            )}

            {trackingNumberError ? (
                <Typography variant={"body2"} color={"error"}>
                    {trackingNumberError}
                </Typography>
            ) : (
                <></>
            )}

            <div />

            {/* TRACKING NUMBER (JOB) */}
            {actionProps.config.trackingType !== EnumTrackingType.NONE && (
                <KortexTextField
                    className={classes.reference}
                    label={actionProps.config.trackingNumberLabelLoc.defaultMessage}
                    value={actionState.trackingNumber}
                    onChanged={handleValueChanged("trackingNumber")}
                    TextFieldProps={{
                        autoFocus: actionProps.config.serialType === EnumTrackingType.NONE,
                        required: true,
                        disabled: disabled,
                    }}
                    changedDelayMS={INPUT_DELAY_MS}
                />
            )}

            {trackingNumberError ? (
                <Typography variant={"body2"} color={"error"}>
                    {trackingNumberError}
                </Typography>
            ) : (
                <></>
            )}
        </div>
    );
}
