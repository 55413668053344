import { ICoords, ICoordsPair } from "@kortex/aos-common";
import { secondaryPalette } from "@aos/react-components";
import * as React from "react";
import { useEffect, useState } from "react";

export interface ISvgMouseDrawProps {
    canvasRef: React.MutableRefObject<SVGSVGElement | null>;
    canvasScale?: number;
    disabled?: boolean;
    initialX: number;
    initialY: number;
    onDrawCompleted?: (endCoords: ICoordsPair) => void;
}

export default function SvgMouseDraw(props: ISvgMouseDrawProps): JSX.Element {
    const { canvasRef, canvasScale = 1, disabled, onDrawCompleted, initialX, initialY } = props;

    const [coords, setCoords] = useState<ICoords>({
        x: initialX,
        y: initialY,
    });

    const [dispatch, setDispatch] = useState<boolean>(false);

    // Effect that captures the mouseup event
    useEffect(() => {
        const handleMouseUp = (): void => {
            setDispatch(true);
        };

        document.addEventListener("mouseup", handleMouseUp);

        return (): void => {
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    // Effect that triggers the onDrawCompleted callback
    useEffect(() => {
        // Send final coordinates to parent
        if (dispatch && initialX !== coords.x && initialY !== coords.y && onDrawCompleted) {
            onDrawCompleted({
                x1: initialX * canvasScale,
                x2: coords.x * canvasScale,
                y1: initialY * canvasScale,
                y2: coords.y * canvasScale,
            });
        }
    }, [dispatch, coords]);

    // Effect that registers mousemove event listeners
    useEffect(() => {
        const handleMouseMove = (event: MouseEvent): void => {
            setCoords({
                x: event.offsetX,
                y: event.offsetY,
            });
        };

        canvasRef.current?.addEventListener("mousemove", handleMouseMove);

        return (): void => {
            canvasRef.current?.removeEventListener("mousemove", handleMouseMove);
        };
    }, [canvasRef.current]);

    // Do no display the blue rectangle if disabled
    if (disabled) {
        return <></>;
    }

    return (
        <path
            d={`
                M${initialX * canvasScale} ${initialY * canvasScale} 
                L${initialX * canvasScale} ${coords.y * canvasScale} 
                L${coords.x * canvasScale} ${coords.y * canvasScale} 
                L${coords.x * canvasScale} ${initialY * canvasScale} 
                Z
            `}
            fill={secondaryPalette[500] + "20"} // Add transparency to fill color
            id="svgMouseDrawId"
            stroke={secondaryPalette[500]}
            strokeWidth="1px"
        />
    );
}
