export const reworkLog = {
    rpc: {
        getOne: {
            funcId: 3200,
            funcName: "reworkLogOne",
        },
        getAll: {
            funcId: 3201,
            funcName: "reworkLogAll",
        },
        insert: {
            funcId: 3202,
            funcName: "reworkLogInsert",
        },
        update: {
            funcId: 3203,
            funcName: "reworkLogUpdate",
        },
        inserted: {
            funcId: 3204,
            funcName: "reworkLogInserted",
        },
        updated: {
            funcId: 3205,
            funcName: "reworkLogUpdated",
        },
    },
    serviceId: 32,
    serviceVersion: 1,
};
