import {
    EnumVerticalAlignment,
    IWorkInstructionsElementConfig,
    IWorkInstructionsTextConfig,
    IWorkInstructionsTextState,
} from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core";
import * as React from "react";

import { getTextStyle } from "../../../../../../../theme";

// This constant is only used for older WorkInstructionsText element that did not have the padding property.
// The real default value is 0px (set in the WorkInstructionsText constructor).
export const DEFAULT_PADDING = "16px";

const useStyles = makeStyles({
    textContainer: {
        display: "table",
        height: "100%",
        width: "100%",
    },
    text: {
        display: "table-cell",
        position: "relative",
        whiteSpace: "pre-wrap",
    },
});

export interface IOwnProps {
    textProps: IWorkInstructionsElementConfig<IWorkInstructionsTextConfig>;
    textState: IWorkInstructionsTextState;
}

export default function WorkInstructionsText(props: IOwnProps): JSX.Element {
    const { extendedProps, height, rotation, width, x, y } = props.textProps;

    const classes = useStyles();

    const { backgroundColor, border, borderColor, fontStyle, horizontalAlignment, padding, textColor, textLoc, verticalAlignment } =
        extendedProps as IWorkInstructionsTextConfig;
    const offset = parseInt(border, 10) / 2 + "px";

    return (
        <g id="workInstructionsTextId" transform={`translate(${x},${y}) rotate(${rotation} ${width / 2} ${height / 2})`}>
            <foreignObject height={height} width={width} x="0" y="0">
                <div className={classes.textContainer} style={{ backgroundColor }} xmlns="http://www.w3.org/1999/xhtml">
                    <span
                        className={classes.text}
                        id="workInstructionsTextSpanId"
                        style={{
                            ...getTextStyle(fontStyle),
                            bottom: verticalAlignment === EnumVerticalAlignment.BOTTOM ? offset : undefined,
                            color: textColor,
                            left: horizontalAlignment === "left" ? offset : undefined,
                            padding: padding ? padding : DEFAULT_PADDING,
                            right: horizontalAlignment === "right" ? offset : undefined,
                            textAlign: horizontalAlignment,
                            top: verticalAlignment === EnumVerticalAlignment.TOP ? offset : undefined,
                            verticalAlign: verticalAlignment,
                        }}
                    >
                        {props.textState.text ? props.textState.text : textLoc.defaultMessage}
                    </span>
                </div>
            </foreignObject>
            <rect fill="transparent" height={height} stroke={borderColor} strokeWidth={border} width={width} />
        </g>
    );
}
