export const reportTag = {
    rpc: {
        getEntryList: {
            funcId: 4100,
            funcName: "getEntryList",
        },
        upsertEntry: {
            funcId: 4101,
            funcName: "upsertEntry",
        },
        getEntryViewList: {
            funcId: 4102,
            funcName: "getEntryViewList",
        },
        getColumnList: {
            funcId: 4103,
            funcName: "getColumnList",
        },
        upsertColumn: {
            funcId: 4104,
            funcName: "upsertColumn",
        },
        getValueList: {
            funcId: 4105,
            funcName: "getValueList",
        },
        upsertValue: {
            funcId: 4106,
            funcName: "upsertValue",
        },
    },
    serviceId: 41,
    serviceVersion: 1,
};
