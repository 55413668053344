/**
 * Upserts needles into a haystack.
 *
 * If the predicate returns "true" it will replace the occurence in the haystack,
 * if it returns "false" it will add it to the haystack
 *
 * @typedef T
 * @param {T[]} haystack - The array of T to search in
 * @param {T[]|T} needles - What to search for
 * @param {(this: T, value: T, index: number, obj: T[]) => boolean} predicate - Predicate for matching needles against the haystack
 *
 * @returns {T[]}
 */
export function upsert(haystack, needles, predicate) {
    const shallowHaystack = [...haystack];
    for (const needle of Array.isArray(needles) ? needles : [needles]) {
        const index = shallowHaystack.findIndex(predicate, needle);
        index > -1 ? (shallowHaystack[index] = { ...needle }) : shallowHaystack.push({ ...needle });
    }
    return shallowHaystack;
}
