/**
 * Parses an URL into parts
 *
 * @param {string} url - full url to mangle
 */
export function mangleURL(url) {
    // TODO: move that elsewhere ? make it agnostic
    const [, , scheme, host, , , , port, pathname = ""] = url.split(/^((.*):\/\/)?([a-z0-9]+([\-.][a-z0-9]+)*(\.[a-z]{2,})?)(:(\d*))?(.*)/gi);
    let [path, queryString] = pathname.split(/\?(.+)/);
    const secured = mangleURL.securedScheme.indexOf(scheme) > -1;
    path = path ? path : "/";
    queryString = queryString ? queryString : "";
    return {
        scheme,
        host,
        port: port ? Number.parseInt(port, 10) : undefined,
        path,
        queryString,
        secured,
        toHTTP: (secure = secured) => `http${secure ? "s" : ""}://${host}${port ? `:${port}` : ""}${path}${queryString ? `?${queryString}` : ""}`,
        toWS: (secure = secured) => `ws${secure ? "s" : ""}://${host}${port ? `:${port}` : ""}${path}${queryString ? `?${queryString}` : ""}`,
    };
}
mangleURL.securedScheme = ["https", "wss"];
