// React
import * as React from "react";
// Mui
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import classNames from "classnames";
import { primaryPalette } from "../mui/palettes";
import { theme } from "../mui/theme";
var useStyles = makeStyles({
    description: {
        fontSize: "14px",
        lineHeight: "18px",
        maxHeight: "54px",
        overflow: "hidden",
    },
    iconContainer: {
        display: "grid",
        alignItems: "center",
        justifyItems: "center",
        borderRadius: "2px 0px 0px 2px",
    },
    label: {
        color: primaryPalette[500],
        fontSize: "18px",
        fontWeight: 600,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
    labelContainer: {
        display: "grid",
        gridTemplateRows: "30px 1fr",
        padding: "16px",
    },
    root: {
        width: "340px",
        height: "120px",
        display: "grid",
        gridTemplateColumns: "120px 1fr",
    },
    selected: {
        backgroundColor: theme.palette.secondary[50],
    },
    body2: {
        lineHeight: "18px",
    },
});
function KortexCard(props) {
    var _a;
    var classes = useStyles(props);
    var color = props.color, icon = props.icon, id = props.id, onClick = props.onClick, onDoubleClick = props.onDoubleClick, textLabels = props.textLabels, _b = props.selected, selected = _b === void 0 ? false : _b;
    /**
     * Calls props's onClick function when card is clicked
     */
    var handleClick = function () {
        if (onClick) {
            onClick();
        }
    };
    /**
     * Calls props's onDoubleClick function when card is double clicked
     */
    var handleDoubleClick = function () {
        if (onDoubleClick) {
            onDoubleClick();
        }
    };
    return (React.createElement(Paper, { className: classNames(classes.root, (_a = {},
            _a[classes.selected] = selected,
            _a)), id: id, onClick: handleClick, onDoubleClick: handleDoubleClick },
        React.createElement("div", { className: classes.iconContainer, style: { backgroundColor: color } }, icon),
        React.createElement("div", { className: classes.labelContainer },
            textLabels && textLabels.label && (React.createElement("span", { className: classes.label },
                React.createElement(Typography, { variant: "h5" }, textLabels.label))),
            textLabels && textLabels.description && (React.createElement("span", { className: classes.description },
                React.createElement(Typography, { variant: "body2" }, textLabels.description))))));
}
export default KortexCard;
