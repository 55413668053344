import { greyPalette, theme } from "@aos/react-components";
import {
    getTimestampFromDateStr,
    IProcessUiModel,
    ITaskInfoProcessReview,
    Task,
    TaskStatusEnum,
    TaskTypeEnum,
    TimeUnitsEnum,
    TTaskUnknown,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Backdrop, CircularProgress, makeStyles, Paper } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import {
    useEntitiesTasks,
    useEntitiesTreeProcess,
    useEntitiesUsers,
    useEntitiesUserSession,
    useEntitiesUsersGroups,
} from "../../../redux/effects";
import { processGet } from "../../../redux/process-manager/process-thunks-process";
import { useSelectorProcesses } from "../../../redux/selectors";
import { taskUpdate } from "../../../redux/task-manager/task-thunks-task";
import ProcessApprovalCardEditor from "../ProcessEditor/ProcessEditor/ProcessApproval/ProcessApprovalCardEditor";

import TaskCardProcess from "./TaskCardProcess";
import { getTaskStatusKey } from "./TasksCommon";
import TasksSearchBar, { defaultFilters, IFilters } from "./TasksSearchBar";

const useStyles = makeStyles({
    mainContainer: {
        height: "calc(100% - 16px)",
        padding: "16px",
        columnGap: "12px",
        display: "flex",
    },
    pageContainer: {
        height: "calc(100vh - 64px)",
        margin: "10px",
        flexGrow: 2,
    },
    cardSpacer: {
        flexGrow: 2,
    },
    cardFooter: {
        backgroundColor: greyPalette[200],
        color: greyPalette[600],
        borderTop: "solid 1px",
        borderTopColor: greyPalette[400],
        flexFlow: "row",
        flexDirection: "row",
        display: "flex",
        padding: "0px",
    },
    tasksPage: {
        margin: "0px",
        borderRadius: "0px",
        overflow: "auto",
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 180px)",
    },
    taskSelectedPage: {
        margin: "0px",
        borderRadius: "0px",
        overflow: "auto",
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 90px)",
    },
    searchContainer: {
        justifyItems: "center",
        marginBottom: "3px",
        padding: "10px",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 100,
    },
});

export default function TasksPage(): JSX.Element {
    const [referedProcess, setReferedProcess] = useState<IProcessUiModel | undefined>(undefined);
    const [selectedTask, setSelectedTask] = useState<TTaskUnknown | undefined>(undefined);
    const [tasks, setTasks] = useState<TTaskUnknown[]>([]);
    const [filters, setFilters] = useState<IFilters>(defaultFilters);
    const [isLoading, setIsLoading] = useState(true);

    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();
    const userInfo = useEntitiesUserSession();
    const users = useEntitiesUsers();
    const groups = useEntitiesUsersGroups();
    const processes = useSelectorProcesses();
    const treeNodes = useEntitiesTreeProcess();

    const allTasks = useEntitiesTasks();

    // Effect that updates state when the tasks (for everyone) from store change...
    useEffect((): void => {
        if (!userInfo) {
            return;
        }
        const myTasks = allTasks
            .filter((task) => {
                // Only open task
                if (task.status !== TaskStatusEnum.ARCHIVE || filters.showArchived) {
                    // And for me... TBD - Check if I am the userId, in the GroupId or the reviewer id
                    if (
                        (userInfo.userGroups && userInfo.userGroups.find((group) => group === task.groupId)) || // user is within the group list
                        (task.type === TaskTypeEnum.PROCESS_REVIEW &&
                            (task.info as ITaskInfoProcessReview).reviewerId === userInfo?.userId) ||
                        task.userId === userInfo?.userId
                    ) {
                        return true; // applyFilters(task);
                    }
                }
                return false;
            })
            .sort((a, b) => (a.status > b.status ? -1 : 1));
        setIsLoading(false);
        setTasks(myTasks);

        if (selectedTask) {
            setSelectedTask(allTasks.find((task) => task.taskId === selectedTask.taskId));
        }
    }, [allTasks, filters]);

    /**
     * Apply filters on the selected tasks
     */
    const applyFilters = (task: TTaskUnknown): boolean => {
        if (task) {
            if (filters.plainText !== "") {
                const user = users.find((user) => user.userId === task.userId);
                const group = groups.find((group) => group.userGroupId === task.groupId);
                let processName = "";
                const process = processes.find((process) => process.processId === task.linkId);
                if (process) {
                    const treeNode = treeNodes.find((treeNode) => treeNode.treeNodeId === process.treeNodeId);
                    if (treeNode) {
                        processName = treeNode.label;
                    }
                }

                // create string that contains all searchable params (Work Order, Reference ID, Part Number and Processes' name)
                const searchString = `${processName} ${
                    task.referenceId
                } ${user?.firstName} ${user?.lastName} ${user?.userName} ${user?.userId.toString()} ${group?.name}  ${translate(
                    getTaskStatusKey(task.status)
                )}`;

                // check if string contains the searched plainText
                if (!searchString.toLowerCase().includes(filters.plainText.toLowerCase())) {
                    return false;
                }
            }

            // Check group
            let foundProcess = false;
            if (filters.group.length > 0) {
                // For each process, check if any of the element in the filter group is entered in any of the group list
                if (task.groupId) {
                    for (const filterGroup of filters.group) {
                        const groupname = groups.find((groupInList): boolean => groupInList.userGroupId === task.groupId);
                        if (groupname && groupname.name === filterGroup) {
                            foundProcess = true;
                        }
                    }
                }
                if (foundProcess === false) {
                    return false;
                }
            }

            // check Status
            if (filters.status.length > 0 && !filters.status.includes(task.status)) {
                return false;
            }
            // check PlannedDateFrom
            if (
                filters.requestedDateFrom !== "" &&
                task.createdOn &&
                (task.createdOn === 0 || task.createdOn < getTimestampFromDateStr(filters.requestedDateFrom, TimeUnitsEnum.MILLISECOND))
            ) {
                return false;
            }
            // check PlannedDateTo
            if (
                filters.requestedDateTo !== "" &&
                task.createdOn &&
                (task.createdOn === 0 || task.createdOn > getTimestampFromDateStr(filters.requestedDateTo, TimeUnitsEnum.MILLISECOND))
            ) {
                return false;
            }
        }
        return true;
    };

    /**
     * Handle selection of the task on the variant change of the card
     *
     * @param {TTaskUnknown} task - task selected
     */
    const handleSelectedTask = async (task: TTaskUnknown): Promise<void> => {
        if (selectedTask === task) {
            setSelectedTask(undefined);
        } else {
            setSelectedTask(task);
            if (Task.isProcessApproval(task) || Task.isProcessApproval(task)) {
                if (task.linkId) {
                    const process = await dispatch(processGet(task.linkId));
                    setReferedProcess(process);
                }
            }
        }
    };

    /**
     * Handle request to complete a review task
     */
    const handleReviewDone = (): void => {
        if (selectedTask) {
            selectedTask.status = TaskStatusEnum.CLOSE;
            selectedTask.completedOn = Date.now();
            dispatch(taskUpdate([selectedTask]));
        }
    };

    /**
     * Called when the filters change. Sets local filters state
     *
     * @param {IFilters} newFilters - newly set filters
     */
    const handleFiltersChange = (newFilters: IFilters): void => {
        setFilters(newFilters);
    };

    return (
        <div className={classes.mainContainer} id="TasksPageId">
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.pageContainer}>
                <Paper className={classes.searchContainer}>
                    <TasksSearchBar
                        enabledFilters={{
                            plainText: true,
                            status: true,
                            requester: true,
                            requestedOn: true,
                            showArchived: true,
                            group: true,
                        }}
                        onFiltersChange={handleFiltersChange}
                        usersList={users}
                    />
                </Paper>

                <div className={classes.tasksPage}>
                    {tasks &&
                        tasks
                            .filter((task): boolean => applyFilters(task))
                            .map((task, index): JSX.Element => {
                                return (
                                    <div key={index}>
                                        {(Task.isProcessApproval(task) || Task.isProcessReview(task)) && (
                                            <TaskCardProcess
                                                index={index}
                                                task={task}
                                                isSelected={task.taskId === selectedTask?.taskId}
                                                onSelection={handleSelectedTask}
                                                variant={"mini"}
                                                process={referedProcess}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                </div>
                {/* END SPACER*/}
                <div className={classes.cardSpacer} />
                {/* BOTTOM MENU */}
            </div>
            {selectedTask && (
                <div className={classes.taskSelectedPage}>
                    {(Task.isProcessApproval(selectedTask) || Task.isProcessReview(selectedTask)) && (
                        <TaskCardProcess
                            index={selectedTask.taskId}
                            task={selectedTask}
                            isSelected={false}
                            onSelection={handleSelectedTask}
                            process={referedProcess}
                        ></TaskCardProcess>
                    )}

                    {(Task.isProcessApproval(selectedTask) || Task.isProcessReview(selectedTask)) && referedProcess && (
                        <ProcessApprovalCardEditor
                            versionInfo={referedProcess}
                            key={referedProcess.processId}
                            approvedInfoOnly={true}
                            reviewOnly={true}
                            onReviewDone={handleReviewDone}
                            isDraftLocked={true}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
