import { Kinova, ServiceManagerClient, WSClient } from "@kortex/aos-api-client";
import { TrainingCertificationInsertReq, TrainingCertificationInsertRes, getRPCHubInfo } from "@kortex/aos-common";

import { objectFromUTF8Array, utf8ArrayFromObject, utf8ArrayToKortexApiErrorObject } from "../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const trainingCertificationInsert = (router: WSClient["router"]) =>
    ServiceManagerClient.getRR<TrainingCertificationInsertReq, TrainingCertificationInsertRes, Kinova.Api.IError>(
        getRPCHubInfo("trainingCertification")("insert"),
        {
            router,
            serializer: {
                decode: objectFromUTF8Array,
                encode: utf8ArrayFromObject,
                err: {
                    decode: utf8ArrayToKortexApiErrorObject,
                },
            },
        }
    );
