import { theme } from "@aos/react-components";
import { TrainingDbModel, UserDbModel } from "@kortex/aos-common";
import { useEllipsisTooltip } from "@kortex/aos-ui/hooks/useEllipsisTooltip";
import { Divider, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";

import { ProcessTraining } from "./processTraining";

const useStyles = makeStyles({
    listItem: {
        paddingBottom: 0,
        paddingTop: 0,
        paddingLeft: "15px",
    },
    listItemSection: {
        display: "flex",
        width: "33%",
    },
});

export interface TrainingItem {
    left: TrainingDbModel | null;
    right: TrainingDbModel | null;
    user: Pick<UserDbModel, "firstName" | "lastName" | "userId">;
}

interface IOwnProps {
    index: number;
    training: TrainingItem;
}

function UserTraining(props: IOwnProps): JSX.Element {
    const { index, training } = props;

    const classes = useStyles();
    const [EllipsisTooltip, labelSpanRef] = useEllipsisTooltip();

    const userFullName = `${training.user.firstName} ${training.user.lastName}`;

    return (
        <Fragment key={`trainedUserStatusUserListItem${index}`}>
            <Divider />
            <ListItem
                className={classes.listItem}
                style={{
                    backgroundColor: index % 2 === 0 ? theme.palette.grey[200] : theme.palette.common.white,
                }}
                disableGutters={true}
            >
                {/* USER FULL NAME */}
                <div className={classes.listItemSection}>
                    <EllipsisTooltip label={userFullName}>
                        <Typography ref={labelSpanRef} id={`userFullName${index}Id`}>
                            {userFullName}
                        </Typography>
                    </EllipsisTooltip>
                </div>
                <Divider flexItem={true} orientation="vertical" variant="middle" />
                {/* TRAINING - LEFT SIDE */}
                <ProcessTraining index={index} panel="left" training={training.left} userFullName={userFullName} />
                <Divider flexItem={true} orientation="vertical" variant="middle" />
                {/* TRAINING - RIGHT SIDE */}
                <ProcessTraining index={index} panel="right" training={training.right} userFullName={userFullName} />
            </ListItem>
        </Fragment>
    );
}

export default UserTraining;
