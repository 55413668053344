import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    ProcessActionCopyReq,
    ProcessActionCopyRes,
    ProcessActionDeleteReq,
    ProcessActionDeleteRes,
    ProcessActionDeletedNotif,
    ProcessActionInsertReq,
    ProcessActionInsertRes,
    ProcessActionInsertedNotif,
    ProcessActionStepCopyReq,
    ProcessActionStepCopyRes,
    ProcessActionStepDeleteReq,
    ProcessActionStepDeleteRes,
    ProcessActionStepInsertReq,
    ProcessActionStepInsertRes,
    ProcessActionStepReorderReq,
    ProcessActionStepReorderRes,
    ProcessActionStepUpdateReq,
    ProcessActionStepUpdateRes,
    ProcessActionUpdateReq,
    ProcessActionUpdateRes,
    ProcessActionUpdatedNotif,
    ProcessGetAllDraftsFromParentTreeNodeReq,
    ProcessGetAllDraftsFromParentTreeNodeRes,
    ProcessGetLatestVersionReq,
    ProcessGetLatestVersionRes,
    ProcessInsertReq,
    ProcessInsertRes,
    ProcessInsertedNotif,
    ProcessOneReq,
    ProcessOneRes,
    ProcessStandardTimeUpdateReq,
    ProcessStandardTimeUpdateRes,
    ProcessTrainingRequirementUpdateReq,
    ProcessTrainingRequirementUpdateRes,
    ProcessUpdateJobProcessNewProcessVersionReq,
    ProcessUpdateJobProcessNewProcessVersionRes,
    ProcessUpdateReq,
    ProcessUpdateRes,
    ProcessUpdatedNotif,
    ProcessVariableDeleteReq,
    ProcessVariableDeleteRes,
    ProcessVariableInsertReq,
    ProcessVariableInsertRes,
    ProcessVariableUpdateReq,
    ProcessVariableUpdateRes,
    ProcessVariableWhereUsedReq,
    ProcessVariableWhereUsedRes,
    ProcessVersionAllParentReq,
    ProcessVersionAllParentRes,
    ProcessVersionAllReq,
    ProcessVersionAllRes,
    ProcessVersionApproveReq,
    ProcessVersionApproveRes,
    ProcessVersionArchiveReq,
    ProcessVersionArchiveRes,
    ProcessVersionCancelReq,
    ProcessVersionCancelRes,
    ProcessVersionDraftReq,
    ProcessVersionDraftRes,
    ProcessVersionHoldReq,
    ProcessVersionHoldRes,
    ProcessVersionInsertReq,
    ProcessVersionInsertRes,
    ProcessVersionRecoverReq,
    ProcessVersionRecoverRes,
    ProcessVersionUpdateReleaseDatesReq,
    ProcessVersionUpdateReleaseDatesRes,
    ProcessWhereUsedAllVersionsReq,
    ProcessWhereUsedAllVersionsRes,
    ProcessWhereUsedReq,
    ProcessWhereUsedRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { processActionInsertCopy } from "./actionCopy";
import { processActionDelete } from "./actionDelete";
import { processActionDeleted } from "./actionDeleted";
import { processActionInsert } from "./actionInsert";
import { processActionInserted } from "./actionInserted";
import { processActionUpdate } from "./actionUpdate";
import { processActionUpdated } from "./actionUpdated";
import { processGetAllDraftsFromParentTreeNode } from "./getAllDraftsFromParentTreeNode";
import { processGetLatestVersion } from "./getLatestVersion";
import { processInsert } from "./insert";
import { processInserted } from "./inserted";
import { processOne } from "./one";
import { processStandardTimeUpdate } from "./standardTimeUpdate";
import { processActionStepInsertCopy } from "./stepCopy";
import { processActionStepDelete } from "./stepDelete";
import { processActionStepInsert } from "./stepInsert";
import { processActionStepReorder } from "./stepReorder";
import { processActionStepUpdate } from "./stepUpdate";
import { processUpdate } from "./update";
import { processUpdateJobProcessNewProcessVersion } from "./updateJobProcessNewProcessVersion";
import { processUpdated } from "./updated";
import { processVariableDelete } from "./variableDelete";
import { processVariableInsert } from "./variableInsert";
import { processVariableUpdate } from "./variableUpdate";
import { processVariableWhereUsed } from "./variableWhereUsed";
import { processVersionAll } from "./versionAll";
import { processVersionAllParent } from "./versionAllParent";
import { processVersionApprove } from "./versionApprove";
import { processVersionArchive } from "./versionArchive";
import { processVersionCancel } from "./versionCancel";
import { processVersionDraft } from "./versionDraft";
import { processVersionHold } from "./versionHold";
import { processVersionInsert } from "./versionInsert";
import { processVersionRecover } from "./versionRecover";
import { processVersionTrainingUpdate } from "./versionTrainingUpdate";
import { processVersionUpdateReleaseDates } from "./versionUpdateReleaseDates";
import { processWhereUsed } from "./whereUsed";
import { processWhereUsedAllVersions } from "./whereUsedAllVersions";

interface IAOSClientServiceWrappedProcess {
    approveVersion: AOSClientServiceRequestWrapped<ProcessVersionApproveReq, ProcessVersionApproveRes>;
    archiveVersion: AOSClientServiceRequestWrapped<ProcessVersionArchiveReq, ProcessVersionArchiveRes>;
    cancelVersion: AOSClientServiceRequestWrapped<ProcessVersionCancelReq, ProcessVersionCancelRes>;
    getAllDraftsFromParentTreeNode: AOSClientServiceRequestWrapped<
        ProcessGetAllDraftsFromParentTreeNodeReq,
        ProcessGetAllDraftsFromParentTreeNodeRes
    >;
    getAllVersions: AOSClientServiceRequestWrapped<ProcessVersionAllReq, ProcessVersionAllRes>;
    getAllVersionsParent: AOSClientServiceRequestWrapped<ProcessVersionAllParentReq, ProcessVersionAllParentRes>;
    getLatestVersion: AOSClientServiceRequestWrapped<ProcessGetLatestVersionReq, ProcessGetLatestVersionRes>;
    getDraftVersion: AOSClientServiceRequestWrapped<ProcessVersionDraftReq, ProcessVersionDraftRes>;
    deleteAction: AOSClientServiceRequestWrapped<ProcessActionDeleteReq, ProcessActionDeleteRes>;
    deleteStep: AOSClientServiceRequestWrapped<ProcessActionStepDeleteReq, ProcessActionStepDeleteRes>;
    deleteVariable: AOSClientServiceRequestWrapped<ProcessVariableDeleteReq, ProcessVariableDeleteRes>;
    getOne: AOSClientServiceRequestWrapped<ProcessOneReq, ProcessOneRes>;
    insert: AOSClientServiceRequestWrapped<ProcessInsertReq, ProcessInsertRes>;
    insertActionCopy: AOSClientServiceRequestWrapped<ProcessActionCopyReq, ProcessActionCopyRes>;
    insertStepCopy: AOSClientServiceRequestWrapped<ProcessActionStepCopyReq, ProcessActionStepCopyRes>;
    insertAction: AOSClientServiceRequestWrapped<ProcessActionInsertReq, ProcessActionInsertRes>;
    insertStep: AOSClientServiceRequestWrapped<ProcessActionStepInsertReq, ProcessActionStepInsertRes>;
    insertVariable: AOSClientServiceRequestWrapped<ProcessVariableInsertReq, ProcessVariableInsertRes>;
    insertVersion: AOSClientServiceRequestWrapped<ProcessVersionInsertReq, ProcessVersionInsertRes>;
    onActionDeleted: AOSClientServiceNotificationWrapped<ProcessActionDeletedNotif>;
    onActionInserted: AOSClientServiceNotificationWrapped<ProcessActionInsertedNotif>;
    onActionUpdated: AOSClientServiceNotificationWrapped<ProcessActionUpdatedNotif>;
    onInserted: AOSClientServiceNotificationWrapped<ProcessInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<ProcessUpdatedNotif>;
    recoverVersion: AOSClientServiceRequestWrapped<ProcessVersionRecoverReq, ProcessVersionRecoverRes>;
    reorderStep: AOSClientServiceRequestWrapped<ProcessActionStepReorderReq, ProcessActionStepReorderRes>;
    holdVersion: AOSClientServiceRequestWrapped<ProcessVersionHoldReq, ProcessVersionHoldRes>;
    update: AOSClientServiceRequestWrapped<ProcessUpdateReq, ProcessUpdateRes>;
    updateAction: AOSClientServiceRequestWrapped<ProcessActionUpdateReq, ProcessActionUpdateRes>;
    updateJobProcessNewProcessVersion: AOSClientServiceRequestWrapped<
        ProcessUpdateJobProcessNewProcessVersionReq,
        ProcessUpdateJobProcessNewProcessVersionRes
    >;
    updateStep: AOSClientServiceRequestWrapped<ProcessActionStepUpdateReq, ProcessActionStepUpdateRes>;
    updateStandardTime: AOSClientServiceRequestWrapped<ProcessStandardTimeUpdateReq, ProcessStandardTimeUpdateRes>;
    updateVariable: AOSClientServiceRequestWrapped<ProcessVariableUpdateReq, ProcessVariableUpdateRes>;
    updateVersionReleaseDates: AOSClientServiceRequestWrapped<ProcessVersionUpdateReleaseDatesReq, ProcessVersionUpdateReleaseDatesRes>;
    updateVersionTraining: AOSClientServiceRequestWrapped<ProcessTrainingRequirementUpdateReq, ProcessTrainingRequirementUpdateRes>;
    whereUsed: AOSClientServiceRequestWrapped<ProcessWhereUsedReq, ProcessWhereUsedRes>;
    whereUsedVariable: AOSClientServiceRequestWrapped<ProcessVariableWhereUsedReq, ProcessVariableWhereUsedRes>;
    whereUsedAllVersions: AOSClientServiceRequestWrapped<ProcessWhereUsedAllVersionsReq, ProcessWhereUsedAllVersionsRes>;
}

type AOSClientServiceWrappedProcess = IAOSClientServiceWrappedProcess;

// ADD_API_CALL Process
export function generateProcessServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedProcess {
    return {
        approveVersion: AOSClientServiceWrapperUI.request(processVersionApprove(router)),
        archiveVersion: AOSClientServiceWrapperUI.request(processVersionArchive(router)),
        cancelVersion: AOSClientServiceWrapperUI.request(processVersionCancel(router)),
        getAllDraftsFromParentTreeNode: AOSClientServiceWrapperUI.request(processGetAllDraftsFromParentTreeNode(router)),
        getAllVersions: AOSClientServiceWrapperUI.request(processVersionAll(router)),
        getAllVersionsParent: AOSClientServiceWrapperUI.request(processVersionAllParent(router)),
        getLatestVersion: AOSClientServiceWrapperUI.request(processGetLatestVersion(router)),
        getDraftVersion: AOSClientServiceWrapperUI.request(processVersionDraft(router)),
        deleteAction: AOSClientServiceWrapperUI.request(processActionDelete(router)),
        deleteStep: AOSClientServiceWrapperUI.request(processActionStepDelete(router)),
        deleteVariable: AOSClientServiceWrapperUI.request(processVariableDelete(router)),
        getOne: AOSClientServiceWrapperUI.request(processOne(router)),
        insert: AOSClientServiceWrapperUI.request(processInsert(router)),
        insertActionCopy: AOSClientServiceWrapperUI.request(processActionInsertCopy(router)),
        insertStepCopy: AOSClientServiceWrapperUI.request(processActionStepInsertCopy(router)),
        insertAction: AOSClientServiceWrapperUI.request(processActionInsert(router)),
        insertStep: AOSClientServiceWrapperUI.request(processActionStepInsert(router)),
        insertVariable: AOSClientServiceWrapperUI.request(processVariableInsert(router)),
        insertVersion: AOSClientServiceWrapperUI.request(processVersionInsert(router)),
        onActionDeleted: AOSClientServiceWrapperUI.notification(processActionDeleted(router, notifier)),
        onActionInserted: AOSClientServiceWrapperUI.notification(processActionInserted(router, notifier)),
        onActionUpdated: AOSClientServiceWrapperUI.notification(processActionUpdated(router, notifier)),
        onInserted: AOSClientServiceWrapperUI.notification(processInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(processUpdated(router, notifier)),
        recoverVersion: AOSClientServiceWrapperUI.request(processVersionRecover(router)),
        reorderStep: AOSClientServiceWrapperUI.request(processActionStepReorder(router)),
        holdVersion: AOSClientServiceWrapperUI.request(processVersionHold(router)),
        update: AOSClientServiceWrapperUI.request(processUpdate(router)),
        updateAction: AOSClientServiceWrapperUI.request(processActionUpdate(router)),
        updateJobProcessNewProcessVersion: AOSClientServiceWrapperUI.request(processUpdateJobProcessNewProcessVersion(router)),
        updateStep: AOSClientServiceWrapperUI.request(processActionStepUpdate(router)),
        updateStandardTime: AOSClientServiceWrapperUI.request(processStandardTimeUpdate(router)),
        updateVariable: AOSClientServiceWrapperUI.request(processVariableUpdate(router)),
        updateVersionReleaseDates: AOSClientServiceWrapperUI.request(processVersionUpdateReleaseDates(router)),
        updateVersionTraining: AOSClientServiceWrapperUI.request(processVersionTrainingUpdate(router)),
        whereUsed: AOSClientServiceWrapperUI.request(processWhereUsed(router)),
        whereUsedVariable: AOSClientServiceWrapperUI.request(processVariableWhereUsed(router)),
        whereUsedAllVersions: AOSClientServiceWrapperUI.request(processWhereUsedAllVersions(router)),
    };
}

generateProcessServiceClient.serviceId = 60;
