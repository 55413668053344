import { upsertObjectFromArray } from "@kortex/aos-common";

import { UserTrainingActionType, UserTrainingActions, UserTrainingState } from "./user-training-types";

const initialState: UserTrainingState = {
    trainings: [],
    trainingFilters: {
        processName: "",
        showArchived: false,
        userId: 0,
    },
};

export function userTrainingReducer(state: UserTrainingState = initialState, action: UserTrainingActions): UserTrainingState {
    switch (action.type) {
        case UserTrainingActionType.CLEAR_USER_TRAININGS:
            return {
                ...initialState,
            };
        case UserTrainingActionType.SET_USER_TRAININGS:
            return {
                ...state,
                trainings: [...action.value],
            };
        case UserTrainingActionType.SET_TRAINING_FILTERS:
            return {
                ...state,
                trainingFilters: {
                    ...state.trainingFilters,
                    ...action.value,
                },
            };
        case UserTrainingActionType.INSERT_USER_TRAININGS:
            return {
                ...state,
                trainings: updateTrainingsOfSelectedUser(state, action.value),
            };
        case UserTrainingActionType.UPDATE_USER_TRAININGS:
            let updatedTrainings = updateTrainingsOfSelectedUser(state, action.value);

            if (!state.trainingFilters.showArchived) {
                updatedTrainings = updatedTrainings.filter(
                    (training) =>
                        // Remove items which both trainings (last and current process) are archived
                        !(
                            (!training.current || training.current.training?.status === "archived") &&
                            (!training.latest || training.latest.training?.status === "archived")
                        )
                );
            }

            return {
                ...state,
                trainings: updatedTrainings,
            };

        default:
            return state;
    }
}

function updateTrainingsOfSelectedUser(
    state: UserTrainingState,
    updatedTrainings: UserTrainingState["trainings"]
): UserTrainingState["trainings"] {
    return upsertObjectFromArray(
        state.trainings,
        updatedTrainings.filter(
            (training) =>
                training.current?.training?.userId === state.trainingFilters.userId ||
                training.latest?.training?.userId === state.trainingFilters.userId
        ),
        function (training) {
            return training.treeNode.treeNodeId === this.treeNode.treeNodeId;
        }
    )
        .filter((training): boolean => {
            // Filter if training does not have at least 1 training
            if (!training.current && !training.latest) return false;

            // Filter by selected user ID
            if (
                !(
                    training.current?.training?.userId === state.trainingFilters.userId ||
                    training.latest?.training?.userId === state.trainingFilters.userId
                )
            )
                return false;

            // Filter by process name
            if (state.trainingFilters.processName && !training.treeNode.label.includes(state.trainingFilters.processName)) return false;

            // Filter if archived are displayed
            if (
                !state.trainingFilters.showArchived &&
                (!training.current || training.current.training?.status === "archived") &&
                (!training.latest || training.latest.training?.status === "archived")
            )
                return false;

            return true;
        })
        .sort();
}
