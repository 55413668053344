import { IReportTagValueDbModel, SystemRightsEnum } from "@kortex/aos-common";
import { KortexTextField } from "@aos/react-components";
import { List, ListItem, makeStyles, Paper } from "@material-ui/core";
import PlayListAddIcon from "@material-ui/icons/PlaylistAdd";
import * as React from "react";
import { useState } from "react";

import { IUserRightsProps, userCanWrite } from "../../../../utilitites/IUserRights";

const INPUT_DELAY_MS = 750;

const useStyles = makeStyles({
    root: {
        padding: "16px",
    },
    tableList: {
        position: "relative",
    },
    list: {
        height: "400px",
        overflowY: "scroll",
    },
});

export interface ITagEditorProps extends IUserRightsProps<SystemRightsEnum> {
    tagColumnValues: IReportTagValueDbModel[];

    onTagColumnValueChanged: (tagColumnValue: IReportTagValueDbModel) => void;
}

export default function TagValueEditor(props: ITagEditorProps): JSX.Element {
    /**
     * COMPONENT PROPS
     */
    const { tagColumnValues, userAccessLevel } = props;

    /**
     * COMPONENT STATES
     */
    const classes = useStyles();
    const [selectedTagColumndValueId, setSelectedTagColumndValueId] = useState(0);
    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Handle selection of tag column value row in list
     *
     * @param {number} tagColumnValueId - Id of the selected tag column value
     */
    const handleSelectColumnClick =
        (tagColumnValueId: number): (() => void) =>
        (): void => {
            setSelectedTagColumndValueId(tagColumnValueId);
        };

    /**
     * Handle update event for tag column value update
     *
     * @param {number} tagColumnValueId - Tag column value id
     */
    const handleUpdateTagColumnValue =
        (tagColumnValueId: number): ((value: string) => void) =>
        (value: string): void => {
            const tagColumnValue = tagColumnValues.find((tagColumnValue): boolean => tagColumnValue.reportTagValueId === tagColumnValueId);

            if (tagColumnValue && props.onTagColumnValueChanged) {
                props.onTagColumnValueChanged({
                    ...tagColumnValue,
                    value: value,
                });
            }
        };

    return (
        <Paper className={classes.tableList}>
            <List className={classes.list}>
                {tagColumnValues.map((tagColumnValue, index): JSX.Element => {
                    return (
                        <ListItem key={index} button={true} onClick={handleSelectColumnClick(tagColumnValue.reportTagValueId || -1)}>
                            <PlayListAddIcon />
                            {tagColumnValue.reportTagValueId === selectedTagColumndValueId && (
                                <KortexTextField
                                    variant="standard"
                                    value={tagColumnValue.value || ""}
                                    onChanged={handleUpdateTagColumnValue(tagColumnValue.reportTagValueId || -1)}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    TextFieldProps={{
                                        disabled: readOnly,
                                    }}
                                />
                            )}
                            {tagColumnValue.reportTagValueId !== selectedTagColumndValueId && <div>{tagColumnValue.value}</div>}
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
}
