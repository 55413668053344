import { IElectronicSignatureDbModel } from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export interface IElectronicSignatureState {
    electronicSignatures: IElectronicSignatureDbModel[];
}

export enum ElectronicSignatureActionType {
    INSERT_ELECTRONIC_SIGNATURE = "@@ELECTRONIC_SIGNATURE/INSERT_ELECTRONIC_SIGNATURE",
    UPDATE_ELECTRONIC_SIGNATURES = "@@ELECTRONIC_SIGNATURE/UPDATE_ELECTRONIC_SIGNATURES",
    UPDATE_ELECTRONIC_SIGNATURE = "@@ELECTRONIC_SIGNATURE/UPDATE_ELECTRONIC_SIGNATURE",
    UPDATE_ELECTRONIC_SIGNATURE_FAILURE_PROBLEMS = "@@ELECTRONIC_SIGNATURE/UPDATE_ELECTRONIC_SIGNATURES_FAILURE_PROBLEMS",
}

export type ElectronicSignatureState = IElectronicSignatureState;

export type ElectronicSignatureInsertedAction = ComplexAction<
    ElectronicSignatureActionType.INSERT_ELECTRONIC_SIGNATURE,
    IElectronicSignatureDbModel
>;
export type ElectronicSignatureUpdatedAction = ComplexAction<
    ElectronicSignatureActionType.UPDATE_ELECTRONIC_SIGNATURE,
    IElectronicSignatureDbModel[]
>;

export type SetElectronicSignatureListAction = ComplexAction<
    ElectronicSignatureActionType.UPDATE_ELECTRONIC_SIGNATURES,
    IElectronicSignatureDbModel[]
>;

export type ElectronicSignatureActions =
    | ElectronicSignatureInsertedAction
    | ElectronicSignatureUpdatedAction
    | SetElectronicSignatureListAction;
