import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { IconButton, TableCell, TableRow, Typography, makeStyles } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { FC } from "react";

import { usePlayerContext } from "../../../../context";

const useStyles = makeStyles({
    circularProgress: {
        height: "24px !important",
        margin: "12px",
        width: "24px !important",
    },
    tableCellContent: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
});

interface IOwnProps {
    colSpan?: number;
}

const PlayerControlsBomReload: FC<IOwnProps> = (props) => {
    const { colSpan } = props;

    const classes = useStyles();
    const { loadWoBom } = usePlayerContext();
    const translate = useTranslate();

    return (
        <TableRow>
            <TableCell colSpan={colSpan}>
                <div className={classes.tableCellContent}>
                    <Typography color="error" variant="body1">
                        {translate("action.workInstructions.bom.bomLoadError")}
                    </Typography>
                    <IconButton onClick={loadWoBom}>
                        <RefreshIcon />
                    </IconButton>
                </div>
            </TableCell>
        </TableRow>
    );
};

export default PlayerControlsBomReload;
