export const runnerUi = {
    rpc: {
        enablePlayNext: {
            funcId: 40001,
            funcName: "runnerUiEnablePlayNext",
        },
        initState: {
            funcId: 40002,
            funcName: "runnerUiInitState",
        },
        sendNotification: {
            funcId: 40003,
            funcName: "runnerUiSendNotification",
        },
        updateActionState: {
            funcId: 40004,
            funcName: "runnerUiUpdateActionState",
        },
        updateHistory: {
            funcId: 40005,
            funcName: "runnerUiSetHistory",
        },
        updateHubConnection: {
            funcId: 40006,
            funcName: "runnerUiUpdateHubConnection",
        },
        updateProcessState: {
            funcId: 40007,
            funcName: "runnerUiUpdateProcessState",
        },
        updateRunnerDashboardInfo: {
            funcId: 40008,
            funcName: "runnerUiDashboardInfoUdpate",
        },
        updateUiActionProps: {
            funcId: 40009,
            funcName: "runnerUiUpdateUiActionProps",
        },
    },
    serviceId: 400,
    serviceVersion: 1,
};
