import { AOSError } from "../aosError";
export class AccessUnauthorizedError extends AOSError {
    //---------------------------------------------------------
    // Public
    //---------------------------------------------------------
    constructor(...params) {
        super(...params);
    }
    get name() {
        return "AccessUnauthorizedError";
    }
}
