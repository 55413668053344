import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default function TemplateSidebarAndTitle(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M2,4 L22,4 L22,18 L2,18 L2,4 Z M3,5 L3,8 L7,8 L7,5 L3,5 Z M3,9 L3,10 L7,10 L7,9 L3,9 Z M9,5 L9,6 L21,6 L21,5 L9,5 Z M3,11 L3,17 L7,17 L7,11 L3,11 Z"
                id="icons-templates-sidebar"
            />
        </SvgIcon>
    );
}
