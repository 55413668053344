import { IUserGroupDbModel, UnwrapAOSPayload, UserGroupAllRes } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { setGroupListAction, userGroupInsertedAction, userGroupUpdatedAction } from "./users-actions";

// thunks

/**
 * Retrieves groups
 *
 * @param {IStandardThunkOptions} [options] - options
 */
export function userGroupGetAll(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<UserGroupAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<UserGroupAllRes>> {
        try {
            if (!skipLookup) {
                if (fetchedOnce.was(userGroupGetAll)) {
                    return getState().user.groups;
                }
            }

            return api.services.userGroups
                .getAll(emptyObject)()
                .then((groups) => {
                    if (!skipDispatch) {
                        fetchedOnce.seal(userGroupGetAll);

                        dispatch(setGroupListAction([...groups]));
                    }

                    return groups;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            return [];
        }
    };
}

export function userGroupInsert(insertedUserGroup: APIPayload<"userGroups", "insert">): StandardThunk<IUserGroupDbModel> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IUserGroupDbModel> => {
        try {
            return api.services.userGroups
                .insert(insertedUserGroup)()
                .then((insertedUserGroup) => {
                    dispatch(userGroupInsertedAction(insertedUserGroup));
                    return insertedUserGroup;
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return reason;
        }
    };
}

export function userGroupUpdate(updatedUserGroup: APIPayload<"userGroups", "update">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.userGroups
                .update(updatedUserGroup)()
                .then((updatedUserGroup) => {
                    dispatch(userGroupUpdatedAction([updatedUserGroup]));
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
    };
}
