import { TreeNodeDbModel } from "@kortex/aos-common";
import { KortexDialogConfirmation } from "@aos/react-components";
import * as React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";
import ProcessWhereUsedPage from "../../ProcessWhereUsed/ProcessWhereUsedPage";

interface IOwnProps {
    onClose: () => void;
    opened: boolean;
    treeNode: TreeNodeDbModel;
}

export default function ProcessRepositoryWhereUsedDialog(props: IOwnProps): JSX.Element {
    const { onClose, opened, treeNode } = props;

    const translate = useTranslate();

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={true}
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: onClose,
            }}
            onCancel={onClose}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("processRepository.whereUsedClose"),
                titleLabel: translate("processRepository.whereUsed") + treeNode.label,
            }}
        >
            <ProcessWhereUsedPage showEmptyMessage={true} treeNodeId={treeNode.treeNodeId} />
        </KortexDialogConfirmation>
    );
}
