import { ITokenDecoded, IUserDbModel, IUserRoleDbModel, IUserGroupDbModel } from "@kortex/aos-common";

import { ComplexAction, BareAction, Unpack } from "../app.types";

export interface ILoginCredentials {
    username: string;
    password: string;
}

export enum UserActionType {
    CLEAR_USER_SESSION = "@@USER/CLEAR_USER_SESSION",
    FETCH_USER_LIST = "@@USER/FETCH_USER_LIST",
    INSERT_USER_GROUP = "@@USER/INSERT_USER_GROUP",
    INSERT_USER_ROLE = "@@USER/INSERT_USER_ROLE",
    INSERT_USER = "@@USER/INSERT_USER",
    RESET_PASSWORD = "@@USER/RESET_PASSWORD",
    SET_USER_GROUP_LIST = "@@USER/SET_USER_GROUP_LIST",
    SET_USER_ROLE_LIST = "@@USER/SET_USER_ROLE_LIST",
    SET_USER_LIST = "@@USER/SET_USER_LIST",
    SET_USER_SESSION = "@@USER/SET_USER_INFO",
    UPDATE_USER_GROUP = "@@USER/UPDATE_USER_GROUP",
    UPDATE_USER_ROLE = "@@USER/UPDATE_USER_ROLE",
    UPDATE_USER = "@@USER/UPDATE_USER",
    DELETE_USER_ROLE = "@@USER/DELETE_USER_ROLE",
}

interface IUserState {
    groups: IUserGroupDbModel[];
    roles: IUserRoleDbModel[];
    session?: ITokenDecoded;
    users: IUserDbModel[];
}

export type UserState = IUserState;

export type ClearUserSessionAction = BareAction<UserActionType.CLEAR_USER_SESSION>;
export type UserGroupInsertedAction = ComplexAction<UserActionType.INSERT_USER_GROUP, Unpack<IUserState["groups"]>>;
export type UserGroupUpdatedAction = ComplexAction<UserActionType.UPDATE_USER_GROUP, IUserState["groups"]>;
export type UserRoleInsertedAction = ComplexAction<UserActionType.INSERT_USER_ROLE, Unpack<IUserState["roles"]>>;
export type UserRoleUpdatedAction = ComplexAction<UserActionType.UPDATE_USER_ROLE, IUserState["roles"]>;
export type UserRoleDeletedAction = ComplexAction<UserActionType.DELETE_USER_ROLE, IUserState["roles"]>;
export type SetUserGroupListAction = ComplexAction<UserActionType.SET_USER_GROUP_LIST, IUserState["groups"]>;
export type SetUserRoleListAction = ComplexAction<UserActionType.SET_USER_ROLE_LIST, IUserState["roles"]>;
export type SetUserListAction = ComplexAction<UserActionType.SET_USER_LIST, IUserState["users"]>;
export type SetUserSessionAction = ComplexAction<UserActionType.SET_USER_SESSION, IUserState["session"]>;
export type UserInsertedAction = ComplexAction<UserActionType.INSERT_USER, Unpack<IUserState["users"]>>;
export type UserUpdatedAction = ComplexAction<UserActionType.UPDATE_USER, IUserState["users"]>;

export type UserActions =
    | ClearUserSessionAction
    | UserGroupInsertedAction
    | UserGroupUpdatedAction
    | UserRoleInsertedAction
    | UserRoleUpdatedAction
    | UserRoleDeletedAction
    | SetUserGroupListAction
    | SetUserRoleListAction
    | SetUserListAction
    | SetUserSessionAction
    | UserInsertedAction
    | UserUpdatedAction;
