import { isResultSettingMetadataTypeMetadata } from "./isResultSettingMetadataTypeMetadata";
import { isResultSettingMetadataTypePropertyObject } from "./isResultSettingMetadataTypePropertyObject";
/**
 * Returns true if current resultSettingMetadataId would cause an infinite loop
 * if it was added as an object to source metadata
 */
export function isRecursiveMetadata(current, source, allMetadata) {
    // Is recursive if ID is the same as the selected one
    if (current === source) {
        return true;
    }
    // Check all properties of current metadata
    for (const property of allMetadata) {
        if (isResultSettingMetadataTypeMetadata(property) || property.parentId !== current) {
            continue;
        }
        // Check only object properties
        if (isResultSettingMetadataTypePropertyObject(property) && property.objectId) {
            // If the metadata ID of the other properties are not the same as the original, call this function recursively
            if (isRecursiveMetadata(property.objectId, source, allMetadata)) {
                return true;
            }
        }
    }
    // No recursivity found
    return false;
}
