export var EnumDashType;
(function (EnumDashType) {
    EnumDashType["DOT"] = "dot";
    EnumDashType["DASH"] = "dash";
    EnumDashType["NONE"] = "none";
})(EnumDashType || (EnumDashType = {}));
export var EnumArrowheadIndex;
(function (EnumArrowheadIndex) {
    EnumArrowheadIndex[EnumArrowheadIndex["START"] = 0] = "START";
    EnumArrowheadIndex[EnumArrowheadIndex["END"] = 1] = "END";
})(EnumArrowheadIndex || (EnumArrowheadIndex = {}));
export var EnumArrowheadShape;
(function (EnumArrowheadShape) {
    EnumArrowheadShape["ARROW"] = "arrow";
    EnumArrowheadShape["ARROW_NOTCHED"] = "arrowNotched";
    EnumArrowheadShape["CIRCLE"] = "circle";
    EnumArrowheadShape["DIAMOND"] = "diamond";
    EnumArrowheadShape["NONE"] = "none";
    EnumArrowheadShape["SQUARE"] = "square";
})(EnumArrowheadShape || (EnumArrowheadShape = {}));
export class WorkInstructionsLineConfig {
    constructor() {
        this.arrowheads = [
            {
                shape: EnumArrowheadShape.NONE,
                x: 50,
                y: 100,
            },
            {
                shape: EnumArrowheadShape.NONE,
                x: 100,
                y: 100,
            },
        ];
        this.color = "#000000";
        this.dash = EnumDashType.NONE;
        this.thickness = 3;
    }
}
