import { LocationKeys } from "@kortex/aos-common";
import { KortexDialogConfirmation, KortexOutlinedTextField } from "@aos/react-components";
import { makeStyles, MenuItem, Typography } from "@material-ui/core";
import * as React from "react";
import { useMemo, useState } from "react";

import appConfigs from "../../configs/app";
import { useTranslate } from "../../hooks/useTranslate";

const useStyles = makeStyles({
    textField: {
        minWidth: "180px",
    },
});

interface IOwnProps {
    open: boolean;
    filterFunction: (lang: LocationKeys) => boolean;
    onLangSelected: (lang: string) => void;
    onClose: () => void;
}

export function LanguageSelectorDialog(props: IOwnProps): JSX.Element {
    const { open, filterFunction } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [selectedLanguage, setSelectedLanguage] = useState("");

    const availableLanguages = useMemo(() => {
        return appConfigs.acceptedLanguages.filter(filterFunction);
    }, [filterFunction]);

    /**
     * Handles a change in the select input
     */
    const handleLangChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSelectedLanguage(event.target.value as LocationKeys);
    };

    /**
     * Handles a click on the dialog's confirm button
     */
    const handleSelect = (): void => {
        props.onLangSelected(selectedLanguage);
        setSelectedLanguage("");
    };

    /**
     * Handles a click on the dialog's cancel button
     */
    const handleClose = (): void => {
        props.onClose();
        setSelectedLanguage("");
    };

    return (
        <KortexDialogConfirmation
            open={open}
            textLabels={{
                titleLabel: translate("coreComponents.languageSelector.selectLanguage"),
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.select"),
            }}
            onCancel={handleClose}
            onConfirm={handleSelect}
            closeOnEscape={true}
            confirmDisabled={selectedLanguage === ""}
        >
            {availableLanguages.length > 0 ? (
                <KortexOutlinedTextField
                    select={true}
                    label={translate("general.language")}
                    value={selectedLanguage}
                    onChange={handleLangChanged}
                    className={classes.textField}
                >
                    {availableLanguages.map((lang) => {
                        return (
                            <MenuItem key={lang} value={lang}>
                                {lang}
                            </MenuItem>
                        );
                    })}
                </KortexOutlinedTextField>
            ) : (
                <Typography variant={"body2"}>{translate("coreComponents.languageSelector.noLanguagesAvailable")}</Typography>
            )}
        </KortexDialogConfirmation>
    );
}
