/**
 * Creates a new training certificate trainer model
 *
 */
export function newTrainingCertificateTrainerModel(options) {
    const { electronicSignatureId, trainingCertificateId } = options;
    return {
        trainingCertificateTrainerId: 0,
        electronicSignatureId,
        trainingCertificateId,
    };
}
