import {
    IProcessVariable,
    ProcessEditorRightsEnum,
    ProcessVariableStoringMethod,
    ProcessVariableStorer,
    ProcessVariableType,
    getVariableDefaultRegex,
} from "@kortex/aos-common";
import { KortexTextField, IKortexTextFieldProps } from "@aos/react-components";
import { IconButton, InputAdornment } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";

import { IUserRightsProps, userCanWrite } from "../../../utilitites/IUserRights";
import ProcessVariableManager from "../../pages/ProcessEditor/ProcessEditor/ProcessVariables/ProcessVariableManager";
import VariableIcon from "../Icons/Variables/Variables";
import { useTranslate } from "../../../hooks/useTranslate";

const ON_CHANGED_DELAY = 500;

interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    disabled?: boolean;
    onBlur?: (value: string, store?: ProcessVariableStoringMethod, defaultRegex?: string) => void;
    onChange?: (value: string, store?: ProcessVariableStoringMethod, defaultRegex?: string) => void;
    storingMethod?: ProcessVariableStoringMethod;
    KortexTextFieldProps?: Omit<IKortexTextFieldProps, "value" | "onBlur" | "onChange">;
    value: string;
    variableTypeFilter?: ProcessVariableType;
}

export default function VariablePicker(props: IOwnProps): JSX.Element {
    const { onBlur, onChange, storingMethod, KortexTextFieldProps, userAccessLevel, value, variableTypeFilter } = props;

    const translate = useTranslate();

    const [textFieldValue, setTextFieldValue] = useState<string>("");
    const [variableManagerOpened, setVariableManagerOpened] = useState<boolean>(false);

    const disabled = props.disabled || !userCanWrite(userAccessLevel);
    const onChangedDelay =
        KortexTextFieldProps && KortexTextFieldProps.changedDelayMS !== undefined ? KortexTextFieldProps.changedDelayMS : ON_CHANGED_DELAY;

    /**
     * Effect that updates text field value when props change
     */
    useEffect((): void => {
        if (value !== textFieldValue) {
            setTextFieldValue(value);
        }
    }, [value]);

    /**
     * Clear the text field value
     */
    const handleClear = (): void => {
        if (!storingMethod) {
            return;
        }

        if (onChange) {
            onChange("", storingMethod);
        }

        setVariableManagerOpened(false);
    };

    /**
     * Called to close the variable manager
     */
    const handleCloseVariableManager = (): void => {
        setVariableManagerOpened(false);
    };

    /**
     * Called to open the variable manager
     */
    const handleOpenVariableManager = (): void => {
        setVariableManagerOpened(true);
    };

    /**
     * Calls onBlur when value text field is blurred
     */
    const handleValueBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        if (onBlur) {
            onBlur(event.target.value);
        }
    };

    /**
     * Calls onChange
     */
    const handleValueChange = (value: string): void => {
        setTextFieldValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    /**
     * If this component is used as a variable storer, replace current textfield value by the new one
     * If not, append the variable id to text field value
     *
     * @param {IProcessVariable} variable - selected variable
     * @param {ProcessVariableStoringMethod} [storingMethod] - append or overwrite
     */
    const handleSelectVariable = (variable: IProcessVariable, storingMethod?: ProcessVariableStoringMethod): void => {
        let newValue: string;

        if (storingMethod) {
            newValue = ProcessVariableStorer.addTemplateLitteral(variable.identifier);
        } else {
            newValue = (value ? value : "") + ProcessVariableStorer.addTemplateLitteral(variable.identifier);
        }

        setTextFieldValue(newValue);
        setVariableManagerOpened(false);

        if (onChange) {
            onChange(newValue, storingMethod, getVariableDefaultRegex(variable));
        } else if (onBlur) {
            onBlur(newValue, storingMethod, getVariableDefaultRegex(variable));
        }
    };

    /**
     * Updates the value of the textfield when a variable identifier from the variable manager is changed.
     * If the field is used as a variable storer and the selected variable had its identifier updated, replace it by the updated identifer.
     * Else, find any instance of the old identifier in the field and replace it by the updated one.
     *
     * @param {string} oldIdentifier - old identifier
     * @param {string} updatedIdentifier - updated identifier
     */
    const handleVariableIdentifierChange = (oldIdentifier: string, updatedIdentifier: string): void => {
        if (storingMethod) {
            setTextFieldValue(ProcessVariableStorer.addTemplateLitteral(updatedIdentifier));
        } else {
            setTextFieldValue(
                textFieldValue.replace(
                    new RegExp("\\" + ProcessVariableStorer.addTemplateLitteral(oldIdentifier), "g"),
                    ProcessVariableStorer.addTemplateLitteral(updatedIdentifier)
                )
            );
        }
    };

    return (
        <React.Fragment>
            <KortexTextField
                {...KortexTextFieldProps}
                TextFieldProps={{
                    ...(KortexTextFieldProps && KortexTextFieldProps.TextFieldProps),
                }}
                label={
                    KortexTextFieldProps &&
                    KortexTextFieldProps.label &&
                    KortexTextFieldProps.label + (storingMethod === ProcessVariableStorer.APPEND ? translate("variablePicker.append") : "")
                }
                onBlur={handleValueBlur}
                onChanged={handleValueChange}
                InputProps={{
                    disabled: disabled || storingMethod !== undefined,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                disabled={disabled}
                                disableRipple={true}
                                id="variablePickerAdornmentVariableId"
                                onClick={handleOpenVariableManager}
                            >
                                <VariableIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                changedDelayMS={onChangedDelay}
                value={textFieldValue}
            />
            <ProcessVariableManager
                onClear={handleClear}
                onClose={handleCloseVariableManager}
                onSelect={handleSelectVariable}
                onVariableIdentifierChange={handleVariableIdentifierChange}
                open={variableManagerOpened}
                storingMethod={storingMethod}
                typeFilter={variableTypeFilter}
                userAccessLevel={userAccessLevel}
            />
        </React.Fragment>
    );
}
