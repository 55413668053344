import { NAMESPACE_SEPARATOR } from "@aos/variable-manager";
export function wrapVariableManager(manager) {
    function _extractIdentifier(namespace, namespacedIdentifier) {
        const namespacesFromIdentifer = namespacedIdentifier.split(NAMESPACE_SEPARATOR);
        const identifier = namespacesFromIdentifer.pop();
        return [(namespace.trim() === "" ? [] : [namespace]).concat(namespacesFromIdentifer).join(NAMESPACE_SEPARATOR), identifier];
    }
    function getAllVars(...params) {
        const [defaultNamespace] = params;
        let allVariables = [];
        for (const namespace of manager.getNamespaces()) {
            if (defaultNamespace === namespace) {
                allVariables = allVariables.concat(manager.getAllVars(namespace));
            }
            else {
                allVariables = allVariables.concat(manager.getAllVars(namespace).map((variable) => ({
                    ...variable,
                    identifier: namespace + NAMESPACE_SEPARATOR + variable.identifier,
                })));
            }
        }
        return allVariables;
    }
    function getVar(...params) {
        const [namespace, identifier] = params;
        const [fullNamespace, extractedIdenfier] = _extractIdentifier(namespace, identifier);
        if (extractedIdenfier === "") {
            return undefined;
        }
        return manager.getVar(fullNamespace, extractedIdenfier);
    }
    function setVar(...params) {
        const [namespace, variable, persist] = params;
        const [fullNamespace, extractedIdenfier] = _extractIdentifier(namespace, variable.identifier);
        if (extractedIdenfier === "") {
            return Promise.resolve();
        }
        return manager.setVar(fullNamespace, {
            ...variable,
            identifier: extractedIdenfier,
        }, persist);
    }
    return {
        ...manager,
        getAllVars,
        getVar,
        setVar,
    };
}
