import { KortexTextField } from "@aos/react-components";
import { IOrgSettingDbModel, ProcessApprovalType } from "@kortex/aos-common";
import { MenuItem } from "@material-ui/core";
import * as React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

import GlobalSettingElement from "./GlobalSettingElement";
import { globalSettingsStyles } from "./GlobalSettingsCommon";

interface IOwnProps {
    expanded: boolean;
    organizationInfo: IOrgSettingDbModel;
    userCanEdit: boolean;

    onUpdate?: <Key extends keyof IOrgSettingDbModel>(setting: Key, value: IOrgSettingDbModel[Key]) => void;
    onCollapseChange?: (settingName: string, expanded: boolean) => void;
}

function GlobalSettingProcessApprovals(props: IOwnProps): JSX.Element {
    const classes = globalSettingsStyles();
    const translate = useTranslate();

    const { organizationInfo, userCanEdit } = props;

    /**
     * Handle setting change
     *
     * @param {keyof IOrgSettingDbModel} setting - setting to be changed
     */
    const handleSettingChange =
        (setting: keyof IOrgSettingDbModel): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (props.onUpdate) {
                let value: Parameters<typeof props.onUpdate>[1] = event.target.value;

                // Convert stringified boolean to boolean
                if (value === "true") {
                    value = true;
                } else if (value === "false") {
                    value = false;
                }

                props.onUpdate(setting, value);
            }
        };

    /**
     * Handle panel collapse change
     *
     * @param {boolean} expanded - true or false, if expanded or not
     */
    const handleCollapseChange = (expanded: boolean): void => {
        if (props.onCollapseChange) {
            props.onCollapseChange("GlobalSettingProcessApprovals", expanded);
        }
    };

    return (
        <GlobalSettingElement
            titleKey="settings.globalSettings.approval.title"
            expanded={props.expanded}
            onCollapseChange={handleCollapseChange}
            id={"approvalSettingsId"}
        >
            <div>
                <div>
                    <KortexTextField
                        className={classes.textField}
                        label={translate("settings.globalSettings.approval.process")}
                        onChange={handleSettingChange("processApproval")}
                        TextFieldProps={{ select: true, disabled: !userCanEdit }}
                        value={organizationInfo.processApproval}
                        variant={"standard"}
                    >
                        <MenuItem value={ProcessApprovalType.AUTO}>{translate("settings.globalSettings.approval.automatic")}</MenuItem>
                        <MenuItem value={ProcessApprovalType.MANUAL}>{translate("settings.globalSettings.approval.manual")}</MenuItem>
                        <MenuItem value={ProcessApprovalType.ELECTRONIC_SIGNATURE}>
                            {translate("settings.globalSettings.approval.electronicSignature")}
                        </MenuItem>
                    </KortexTextField>
                    <KortexTextField
                        className={classes.textField}
                        label={translate("settings.globalSettings.approval.lockPendingProcess")}
                        onChange={handleSettingChange("lockPendingProcess")}
                        TextFieldProps={{ select: true, disabled: !userCanEdit }}
                        value={organizationInfo.lockPendingProcess.toString()}
                        variant={"standard"}
                    >
                        <MenuItem value={"true"}>{translate("settings.globalSettings.approval.lockPendingProcessYes")}</MenuItem>
                        <MenuItem value={"false"}>{translate("settings.globalSettings.approval.lockPendingProcessNo")}</MenuItem>
                    </KortexTextField>
                    <KortexTextField
                        className={classes.textField}
                        label={translate("settings.globalSettings.approval.allowSelfProcessApproval")}
                        onChange={handleSettingChange("allowSelfProcessApproval")}
                        TextFieldProps={{ select: true, disabled: !userCanEdit }}
                        value={organizationInfo.allowSelfProcessApproval.toString()}
                        variant="standard"
                    >
                        <MenuItem value="true">{translate("general.yes")}</MenuItem>
                        <MenuItem value="false">{translate("general.no")}</MenuItem>
                    </KortexTextField>
                </div>
                <div>
                    <KortexTextField
                        className={classes.textField}
                        label={translate("settings.globalSettings.release.routing")}
                        onChange={handleSettingChange("routingApproval")}
                        TextFieldProps={{ select: true, disabled: !userCanEdit }}
                        value={organizationInfo.routingApproval}
                        variant={"standard"}
                    >
                        <MenuItem value={ProcessApprovalType.AUTO}>{translate("settings.globalSettings.approval.automatic")}</MenuItem>
                        <MenuItem value={ProcessApprovalType.MANUAL}>{translate("settings.globalSettings.approval.manual")}</MenuItem>
                        <MenuItem value={ProcessApprovalType.ELECTRONIC_SIGNATURE}>
                            {translate("settings.globalSettings.approval.electronicSignature")}
                        </MenuItem>
                    </KortexTextField>
                    <KortexTextField
                        className={classes.textField}
                        label={translate("settings.globalSettings.approval.lockPendingRouting")}
                        onChange={handleSettingChange("lockPendingRouting")}
                        TextFieldProps={{ select: true, disabled: !userCanEdit }}
                        value={organizationInfo.lockPendingRouting.toString()}
                        variant={"standard"}
                    >
                        <MenuItem value={"true"}>{translate("settings.globalSettings.approval.lockPendingProcessYes")}</MenuItem>
                        <MenuItem value={"false"}>{translate("settings.globalSettings.approval.lockPendingProcessNo")}</MenuItem>
                    </KortexTextField>
                    <KortexTextField
                        className={classes.textField}
                        label={translate("settings.globalSettings.approval.allowSelfRoutingApproval")}
                        onChange={handleSettingChange("allowSelfRoutingApproval")}
                        TextFieldProps={{ select: true, disabled: !userCanEdit }}
                        value={organizationInfo.allowSelfRoutingApproval.toString()}
                        variant="standard"
                    >
                        <MenuItem value="true">{translate("general.yes")}</MenuItem>
                        <MenuItem value="false">{translate("general.no")}</MenuItem>
                    </KortexTextField>
                </div>
                <KortexTextField
                    TextFieldProps={{ multiline: true, rows: 8, className: classes.textAreaInner, disabled: !userCanEdit }}
                    label={translate("settings.globalSettings.approval.contextText")}
                    className={classes.textArea}
                    value={organizationInfo.approvalContextText}
                    onChange={handleSettingChange("approvalContextText")}
                />
            </div>
        </GlobalSettingElement>
    );
}

export default GlobalSettingProcessApprovals;
