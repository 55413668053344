import { ProcessId, TreeNodeId, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useSelectorProcesses } from "@kortex/aos-ui/redux/selectors";
import { makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

import { processGet } from "../../../../../../redux/process-manager/process-thunks-process";
import { RepoBreadCrumbs } from "../../../RepositoryManager/RepoBreadCrumbs";

const useStyles = makeStyles({
    root: {
        // To override...
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    disabled?: boolean;
    processId?: ProcessId;
    onSelectProcess: (treeNodeId: TreeNodeId, processId?: ProcessId) => void;
    onVersionChange: (processId: ProcessId, latest?: boolean, changedOnLoad?: boolean) => void;
}

const ProcessTrainingCopyPicker: FC<IOwnProps> = (props) => {
    const { disabled, processId, onSelectProcess, onVersionChange } = props;

    const classes = useStyles(props);
    const dispatch = useThunkDispatch();
    const [process] = useSelectorProcesses((process) => process.processId === processId);

    const [loading, setLoading] = useState<boolean>(false); // To force reload

    useEffect((): void => {
        // Do nothing if :
        // - there's no process ID
        // - process has already been fetched
        if (process || !processId) return void 0;

        setLoading(true);

        // Fetch process
        dispatch(processGet(processId)).finally(() => {
            setLoading(false);
        });
    }, [process, processId]);

    return (
        <div className={classes.root}>
            <RepoBreadCrumbs
                disabled={disabled || loading}
                onSelectProcess={onSelectProcess}
                onVersionChange={onVersionChange}
                processRepositoryProps={{
                    filterType: TreeNodeNodeTypeEnum.PROCESS,
                    showVersionVariant: "approved",
                }}
                processId={processId}
                treeNodeId={process?.treeNodeId}
                // userAccessLevel={userInfo && userInfo.roleRights.}
                wrapBreadCrumbs={true}
            />
        </div>
    );
};

export default ProcessTrainingCopyPicker;
