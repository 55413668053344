import {
    IUserTrainingFilters,
    UnwrapAOSPayload,
    UserTrainingGetAllTrainingsByUserIdReq,
    UserTrainingGetAllTrainingsByUserIdRes,
    UserTrainingInsertRes,
    UserTrainingUpdateStatusRes,
} from "@kortex/aos-common";

import { APIPayload } from "../../utilitites/kortex-client/client";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";

import {
    userTrainingClear,
    userTrainingSetAction,
    userTrainingSetTrainingFiltersAction,
    userTrainingUpdatedAction,
} from "./user-training-actions";

/**
 * clear training page
 *
 */
export function clearUserTraining(): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(userTrainingClear());
    };
}

/**
 * Set training filters from state
 */
export function userTrainingSetTrainingFilters(filters: Partial<IUserTrainingFilters>): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(userTrainingSetTrainingFiltersAction(filters));
    };
}

/**
 * Fetch all trainings
 */
export function userTrainingGetAllByUserId(
    filterOptions: UnwrapAOSPayload<UserTrainingGetAllTrainingsByUserIdReq>
): StandardThunk<UnwrapAOSPayload<UserTrainingGetAllTrainingsByUserIdRes>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<UserTrainingGetAllTrainingsByUserIdRes>> => {
        // Update state's filters
        dispatch(userTrainingSetTrainingFiltersAction(filterOptions));

        return api.services.userTraining
            .getAllTrainingsByUserId(filterOptions)()
            .then((res) => {
                dispatch(userTrainingSetAction([...res]));

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return [];
            });
    };
}

/**
 * User training insert a training
 */
export function userTrainingInsert(
    trainings: APIPayload<"userTraining", "insert">
): StandardThunk<UnwrapAOSPayload<UserTrainingInsertRes> | never[]> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<UserTrainingInsertRes> | never[]> => {
        return api.services.userTraining
            .insert(trainings)()
            .then((res) => {
                dispatch(userTrainingUpdatedAction([...res]));
                return res;
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }

                return [];
            });
    };
}

/**
 * User training update status a training
 */
export function userTrainingUpdateStatus(
    training: APIPayload<"userTraining", "updateStatus">
): StandardThunk<UnwrapAOSPayload<UserTrainingUpdateStatusRes> | never[]> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<UserTrainingUpdateStatusRes> | never[]> => {
        return api.services.userTraining
            .updateStatus(training)()
            .then((res) => {
                if (res) {
                    dispatch(userTrainingUpdatedAction([res]));
                }

                return res;
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }

                return [];
            });
    };
}
