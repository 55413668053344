var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function TemplateFull(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M2,4 L22,4 L22,18 L2,18 L2,4 Z M3,5 L3,8 L7,8 L7,5 L3,5 Z M3,9 L3,10 L7,10 L7,9 L3,9 Z M9,5 L9,6 L21,6 L21,5 L9,5 Z M9,8 L9,8.5 L20,8.5 L20,8 L9,8 Z M9,13 L9,13.5 L20,13.5 L20,13 L9,13 Z M9,9 L9,12 L12,12 L12,9 L9,9 Z M9,14 L9,17 L12,17 L12,14 L9,14 Z M13,9 L13,12 L16,12 L16,9 L13,9 Z M13,14 L13,17 L16,17 L16,14 L13,14 Z M17,9 L17,12 L20,12 L20,9 L17,9 Z M17,14 L17,17 L20,17 L20,14 L17,14 Z M3,11 L3,17 L7,17 L7,11 L3,11 Z", id: "icons-template-full" })));
}
