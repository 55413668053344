import { greyPalette, theme, warningPalette } from "@aos/react-components";
import { ITrainingReadingCertificatePending, ProcessEditorRightsEnum, Unpack, UserId, getDateStr } from "@kortex/aos-common";
import KortexLabel from "@kortex/aos-ui/components/core/KortexLabel";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { history } from "@kortex/aos-ui/redux/browserHistory";
import { setRoute } from "@kortex/aos-ui/redux/general-manager/general-thunks-general";
import { EnumPageTypes } from "@kortex/aos-ui/redux/general-manager/general-types";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import { Checkbox, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import WarningIcon from "@material-ui/icons/Warning";
import React, { FC } from "react";

import { useReadingCertificateContext } from "../../../context";

const useStyles = makeStyles({
    card: {
        width: "100%",
    },
    cardContent: {
        alignItems: "center",
        display: "grid",
        gridTemplateColumns: "5% 33% 33% 28%",
    },
    column: {
        alignItems: "center",
        display: "flex",
        height: "58px",
        justifyContent: "center",
    },
    columnUserName: {
        justifyContent: "left",
        paddingLeft: "10px",
    },
    goToStepIcon: {
        marginRight: "10px",
    },
    text: {
        color: greyPalette[700],
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
    warningIcon: {
        color: warningPalette[500],
    },
});

interface IOwnProps {
    index: number;
    training: Unpack<ITrainingReadingCertificatePending["trainings"]>;
    userId: UserId;
}

const TrainingCard: FC<IOwnProps> = (props) => {
    const { index, training, userId } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const session = useSelectorUserSession();
    const translate = useTranslate();
    const { isReadingCertificateEnabled, selectedTrainings, setSelectedTraining } = useReadingCertificateContext();

    const canAccessProcessEditor = Boolean(session!.roleRights.processEditor >= ProcessEditorRightsEnum.READ);
    const selected = selectedTrainings.some((selectedTraining) => selectedTraining.trainingId === training.training.trainingId);

    const handleGoToStep = (): void => {
        history.push(location.search + "#" + training.treeNode.treeNodeId);
        dispatch(setRoute(EnumPageTypes.PROCESS));
    };

    const handleCheck = (): void => {
        const index = selectedTrainings.findIndex((selectedTraining) => selectedTraining.trainingId === training.training.trainingId);

        if (index === -1) {
            setSelectedTraining((prevState) => [
                ...prevState,
                {
                    processName: training.treeNode.label,
                    processVersion: training.process.version,
                    trainingId: training.training.trainingId,
                },
            ]);
        } else {
            setSelectedTraining(([...prevState]) => {
                prevState.splice(index, 1);
                return prevState;
            });
        }
    };

    return (
        <KortexPanelCard
            className={classes.card}
            hover={true}
            isSelected={false}
            key={index}
            padding={0}
            spaceBetweenCard={1}
            variant="flat"
        >
            <div className={classes.cardContent} id="readingCertificateCardHeaderId">
                {/* PROCESS SELECTION FOR READING CERTIFICATE */}
                <div className={classes.column}>
                    {!training.training.trainingCertificateTraineeId ? (
                        // WARNING - TRAINING CERTIFICATE REQUIRED
                        <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title={translate("training.tooltipReadingCertificate")}
                            placement="top"
                        >
                            <WarningIcon className={classes.warningIcon} />
                        </Tooltip>
                    ) : (
                        // CHECKBOX
                        isReadingCertificateEnabled(userId) && <Checkbox checked={selected} onChange={handleCheck} />
                    )}
                </div>
                {/* PROCESS NAME*/}
                <div className={`${classes.column} ${classes.columnUserName}`}>
                    {canAccessProcessEditor && (
                        <IconButton className={classes.goToStepIcon} onClick={handleGoToStep} size="small">
                            <LaunchIcon />
                        </IconButton>
                    )}
                    <KortexLabel
                        id={`readingCertificateCardExpandedProcessName${index}Id`}
                        label={training.treeNode.label}
                        variant="compact"
                        styleLabel={{ fontSize: "1rm" }}
                    />
                </div>
                {/* PROCESS VERSION */}
                <div className={classes.column}>
                    <Typography className={classes.text} id={`readingCertificateCardExpandedProcessVersion${index}Id`} variant={"body1"}>
                        {training.process.version}
                    </Typography>
                </div>
                {/* CREATED ON */}
                <div className={classes.column}>
                    <Typography className={classes.text} id={`readingCertificateCardExpandedCreatedOn${index}Id`} variant={"body1"}>
                        {getDateStr(training.training.createdOn!)}
                    </Typography>
                </div>
            </div>
        </KortexPanelCard>
    );
};

export default TrainingCard;
