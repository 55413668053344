import { KortexColorPicker, KortexTextField } from "@aos/react-components";
import { EnumFontStyles, EnumHorizontalAlignment, IWorkInstructionsTextConfig, ProcessEditorRightsEnum } from "@kortex/aos-common";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../../../hooks/useTranslate";
import { IUserRightsProps } from "../../../../../../../../utilitites/IUserRights";

const useStyles = makeStyles({
    topRow: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "16px",
    },
    bottomRow: {
        display: "flex",
        width: "50%",
        justifyContent: "space-between",
    },
    selectContainer: {
        margin: "5px",
    },
    select: {
        width: "150px",
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    labelProps?: IWorkInstructionsTextConfig;
    onCancel: () => void;
    onSave: (labelProps: IWorkInstructionsTextConfig) => void;
    open: boolean;
}

export default function WorkInstructionsLabelToolbar(props: IOwnProps): JSX.Element {
    const { onSave } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [labelProps, setLabelProps] = useState<IWorkInstructionsTextConfig | undefined>(props.labelProps ? props.labelProps : undefined);

    /**
     * Reassign shape props to local state whenever shape props are changed
     */
    useEffect((): void => {
        setLabelProps(props.labelProps);
    }, [props.labelProps]);

    /**
     * Called when a marker property is changed
     */
    const handleChange =
        (key: keyof IWorkInstructionsTextConfig): ((event: React.ChangeEvent<HTMLSelectElement>) => void) =>
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            if (!labelProps) {
                return;
            }

            const newLabelProps = {
                ...labelProps,
                [key]: event.target.value,
            };

            setLabelProps(newLabelProps);

            onSave(newLabelProps);
        };

    /**
     * Called when marker's color is changed
     *
     * @param {string} key - updated color attribute name
     */
    const handleColorChange =
        (key: keyof IWorkInstructionsTextConfig): ((color: string) => void) =>
        (color: string): void => {
            // utility functions
            if (!labelProps) {
                return;
            }

            const isWhite = (color: string): boolean => color.slice(0, 7).toLowerCase() === "#ffffff";
            const isTransparent = (color: string): boolean => color.slice(7, 9) === "00";

            const previousColor = labelProps.backgroundColor;
            let newColor = color;

            if (key === "backgroundColor" && isWhite(previousColor) && isTransparent(previousColor) && !isWhite(newColor)) {
                // force opacity to 100%
                newColor = newColor.slice(0, 7).toLowerCase() + "ff";
            }

            const newLabelProps = {
                ...labelProps,
                [key]: newColor,
            };

            setLabelProps(newLabelProps);

            onSave(newLabelProps);
        };

    return (
        <>
            <div className={classes.topRow}>
                <KortexTextField
                    className={classes.selectContainer}
                    InputProps={{ className: classes.select }}
                    label={translate("action.workInstructions.text.style")}
                    onChange={handleChange("fontStyle")}
                    TextFieldProps={{
                        select: true,
                    }}
                    value={labelProps ? labelProps.fontStyle : EnumFontStyles.NORMAL}
                >
                    <MenuItem value={EnumFontStyles.TITLE}>{translate("action.workInstructions.text.style.title")}</MenuItem>
                    <MenuItem value={EnumFontStyles.HEADER1}>{translate("action.workInstructions.text.style.header1")}</MenuItem>
                    <MenuItem value={EnumFontStyles.HEADER2}>{translate("action.workInstructions.text.style.header2")}</MenuItem>
                    <MenuItem value={EnumFontStyles.HEADER3}>{translate("action.workInstructions.text.style.header3")}</MenuItem>
                    <MenuItem value={EnumFontStyles.NORMAL}>{translate("action.workInstructions.text.style.normal")}</MenuItem>
                    <MenuItem value={EnumFontStyles.SMALL}>{translate("action.workInstructions.text.style.small")}</MenuItem>
                </KortexTextField>
                <KortexTextField
                    className={classes.selectContainer}
                    InputProps={{ className: classes.select }}
                    label={translate("action.workInstructions.text.horizontalAlignment")}
                    onChange={handleChange("horizontalAlignment")}
                    TextFieldProps={{
                        select: true,
                    }}
                    value={labelProps ? labelProps.horizontalAlignment : EnumHorizontalAlignment.LEFT}
                >
                    <MenuItem value={"left"}>{translate("action.workInstructions.text.horizontalAlignment.left")}</MenuItem>
                    <MenuItem value={"center"}>{translate("action.workInstructions.text.horizontalAlignment.center")}</MenuItem>
                    <MenuItem value={"right"}>{translate("action.workInstructions.text.horizontalAlignment.right")}</MenuItem>
                </KortexTextField>
                <KortexTextField
                    className={classes.selectContainer}
                    InputProps={{ className: classes.select }}
                    label={translate("action.workInstructions.text.padding")}
                    onChange={handleChange("padding")}
                    TextFieldProps={{
                        select: true,
                    }}
                    value={labelProps ? labelProps.padding : "0px"}
                >
                    <MenuItem value={"0px"}>{translate("action.workInstructions.text.padding.none")}</MenuItem>
                    <MenuItem value={"1px"}>1px</MenuItem>
                    <MenuItem value={"2px"}>2px</MenuItem>
                    <MenuItem value={"3px"}>3px</MenuItem>
                    <MenuItem value={"4px"}>4px</MenuItem>
                    <MenuItem value={"8px"}>8px</MenuItem>
                    <MenuItem value={"12px"}>12px</MenuItem>
                    <MenuItem value={"18px"}>18px</MenuItem>
                    <MenuItem value={"24px"}>24px</MenuItem>
                </KortexTextField>
                <KortexTextField
                    className={classes.selectContainer}
                    InputProps={{ className: classes.select }}
                    label={translate("action.workInstructions.text.border")}
                    onChange={handleChange("border")}
                    TextFieldProps={{
                        select: true,
                    }}
                    value={labelProps ? labelProps.border : "0px"}
                >
                    <MenuItem value={"0px"}>{translate("action.workInstructions.text.border.none")}</MenuItem>
                    <MenuItem value={"1px"}>1px</MenuItem>
                    <MenuItem value={"2px"}>2px</MenuItem>
                    <MenuItem value={"3px"}>3px</MenuItem>
                    <MenuItem value={"4px"}>4px</MenuItem>
                    <MenuItem value={"8px"}>8px</MenuItem>
                    <MenuItem value={"12px"}>12px</MenuItem>
                    <MenuItem value={"18px"}>18px</MenuItem>
                    <MenuItem value={"24px"}>24px</MenuItem>
                </KortexTextField>
            </div>
            <div className={classes.bottomRow}>
                <div className={classes.selectContainer}>
                    <div id="textColorContainerId">
                        <KortexColorPicker
                            color={labelProps ? labelProps.textColor : "#000000"}
                            inputStyle={{ width: "110px" }}
                            onChange={handleColorChange("textColor")}
                            textLabels={{
                                inputLabel: translate("action.workInstructions.text.textColor"),
                                opacityLabel: translate("kortexReactComponent.kortexColorPicker.opacity"),
                            }}
                            transparency={true}
                        />
                    </div>
                </div>
                <div className={classes.selectContainer}>
                    <div id="backgroundColorContainerId">
                        <KortexColorPicker
                            color={labelProps ? labelProps.backgroundColor : "#FFFFFFFF"}
                            inputStyle={{ width: "110px" }}
                            onChange={handleColorChange("backgroundColor")}
                            textLabels={{
                                inputLabel: translate("action.workInstructions.text.backgroundColor"),
                                opacityLabel: translate("kortexReactComponent.kortexColorPicker.opacity"),
                            }}
                            transparency={true}
                        />
                    </div>
                </div>
                <div className={classes.selectContainer}>
                    <KortexColorPicker
                        color={labelProps ? labelProps.borderColor : "#000000"}
                        inputStyle={{ width: "110px" }}
                        onChange={handleColorChange("borderColor")}
                        textLabels={{
                            inputLabel: translate("action.workInstructions.text.borderColor"),
                        }}
                    />
                </div>
            </div>
        </>
    );
}
