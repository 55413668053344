import { IBomFollowUpSymptomDbModel } from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export interface IBomFollowUpState {
    bomFollowUpSymptom: IBomFollowUpSymptomDbModel[];
}
export type BomFollowUpState = IBomFollowUpState;

/* ------------------------------------------------ 
 ------------- BOM FOLLOW-UP SYMPTOM---------------
 --------------------------------------------------*/

export enum BomFollowUpSymptomActionType {
    INSERT_BOM_FOLLOW_UP_SYMPTOM = "@@BOM_FOLLOW_UP_SYMPTOM/INSERT_BOM_FOLLOW_UP_SYMPTOM",
    SET_BOM_FOLLOW_UP_SYMPTOM = "@@BOM_FOLLOW_UP_SYMPTOM/SET_BOM_FOLLOW_UP_SYMPTOM",
    UPDATE_BOM_FOLLOW_UP_SYMPTOM = "@@BOM_FOLLOW_UP_SYMPTOM/UPDATE_BOM_FOLLOW_UP_SYMPTOM",
}

export type BomFollowUpSymptomInsertedAction = ComplexAction<
    BomFollowUpSymptomActionType.INSERT_BOM_FOLLOW_UP_SYMPTOM,
    IBomFollowUpSymptomDbModel
>;
export type BomFollowUpSymptomUpdatedAction = ComplexAction<
    BomFollowUpSymptomActionType.UPDATE_BOM_FOLLOW_UP_SYMPTOM,
    IBomFollowUpSymptomDbModel
>;

export type SetBomFollowUpSymptomListAction = ComplexAction<
    BomFollowUpSymptomActionType.SET_BOM_FOLLOW_UP_SYMPTOM,
    IBomFollowUpState["bomFollowUpSymptom"]
>;

/* ------------------------------------------------ 
 ---------------------- ALL -----------------------
 --------------------------------------------------*/
export type BomFollowUpActions = BomFollowUpSymptomInsertedAction | BomFollowUpSymptomUpdatedAction | SetBomFollowUpSymptomListAction;
