import {
    TreeNodeDbModel,
    TreeProcessAllRes,
    TreeProcessArchiveReq,
    TreeProcessArchiveRes,
    TreeProcessInsertReq,
    TreeProcessInsertRes,
    TreeProcessUpdateBomReq,
    TreeProcessUpdateReq,
    UnwrapAOSPayload,
} from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { IStandardThunkOptions, OrUndefined } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import {
    setEditedTreeProcessNodeIdAction,
    setTreeProcessAction,
    treeProcessInsertedAction,
    treeProcessUpdatedAction,
} from "./tree-actions";

// thunks

/**
 * Retrieves tree processes
 *
 * @param {TreeProcessArchiveReq} [options] - options
 */
export function treeProcessArchive(
    options: UnwrapAOSPayload<TreeProcessArchiveReq>
): StandardThunk<UnwrapAOSPayload<TreeProcessArchiveRes>> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TreeProcessArchiveRes>> {
        try {
            return api.services.treeProcess
                .archive(options)()
                .then((res) => {
                    if (res) {
                        dispatch(treeProcessUpdatedAction(res.treeNode));
                    }

                    return res;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            throw error;
        }
    };
}

/**
 * Retrieves tree processes
 *
 * @param {IStandardThunkOptions} [options] - options
 */
export function treeProcessGet(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<TreeProcessAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TreeProcessAllRes>> {
        try {
            if (!skipLookup) {
                if (fetchedOnce.was(treeProcessGet)) {
                    return getState().tree.processes;
                }
            }

            return api.services.treeProcess
                .getAll(emptyObject)()
                .then((processes) => {
                    if (!skipDispatch) {
                        fetchedOnce.seal(treeProcessGet);

                        dispatch(setTreeProcessAction([...processes]));
                    }

                    return processes;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            throw error;
        }
    };
}

/**
 * Insert tree process in store
 *
 * @param {object} insertedTreeProcess - inserted tree process
 */
export function treeProcessInsert(
    insertedTreeProcess: UnwrapAOSPayload<TreeProcessInsertReq>
): StandardThunk<OrUndefined<UnwrapAOSPayload<TreeProcessInsertRes>>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<UnwrapAOSPayload<TreeProcessInsertRes>>> => {
        try {
            return api.services.treeProcess
                .insert(insertedTreeProcess)()
                .then((insertedTreeProcess) => {
                    if (insertedTreeProcess) {
                        dispatch(treeProcessInsertedAction(insertedTreeProcess));
                        return insertedTreeProcess;
                    }

                    return void 0;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return void 0;
    };
}

/**
 * Update tree process in store
 *
 * @param {number} treeNodeId - edited tree node ID
 */
export function treeSetEditedTreeProcessNodeId(treeNodeId: TreeNodeDbModel["treeNodeId"]): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(setEditedTreeProcessNodeIdAction(treeNodeId));
    };
}

/**
 * Update tree process in store
 *
 * @param {object} treeNodes - updated tree process
 */
export function treeProcessUpdate(treeNodes: UnwrapAOSPayload<TreeProcessUpdateReq>): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.treeProcess
                .update(treeNodes)()
                .then((updatedTreeProcess) => {
                    dispatch(treeProcessUpdatedAction([...updatedTreeProcess]));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}

/**
 * Update BOM of tree process in store
 *
 * @param {object} treeNodes - updated tree process
 */
export function treeProcessUpdateBom(treeNodes: UnwrapAOSPayload<TreeProcessUpdateBomReq>): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.treeProcess
                .updateBom(treeNodes)()
                .then((updatedTreeProcess) => {
                    dispatch(treeProcessUpdatedAction([...updatedTreeProcess]));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}
