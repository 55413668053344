import { isNullOrUndefined } from "../isNullOrUndefined";
const EPSILON = 0.00001;
/**
 * Compares 2 values and returns true if they are similar, otherwise false.
 */
export function areSimilar(a, b, options) {
    if (isNullOrUndefined(a) || isNullOrUndefined(b)) {
        return a === b;
    }
    switch (typeof a) {
        case "object":
            // Checks:
            //  - Both objects have the same number of properties
            //  - Recursively check that every property is similar
            return Object.keys(a).length === Object.keys(b).length && Object.keys(a).every((key) => areSimilar(a[key], b[key], options));
        case "number":
            // Checks:
            //  - If float, both values must be within the EPSILON range
            //  - Else check equality
            return options?.floating && typeof b == "number" ? Math.abs(a - b) < EPSILON : a === b;
        default:
            return a === b;
    }
}
