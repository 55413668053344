import {
    FailureActionType,
    setFailureSymptomsListAction,
    setFailureTypesListAction,
    failureSymptomsInsertedAction,
    failureSymptomsUpdatedAction,
    failureTypesInsertedAction,
    failureTypesUpdatedAction,
} from "./failures-types";

/**
 * Returns an action
 *
 * @param {setFailureSymptomsListAction} symptoms - value of the action
 */
export function setFailureSymptomsListAction(symptoms: setFailureSymptomsListAction["value"]): setFailureSymptomsListAction {
    return { type: FailureActionType.FAILURE_SYMPTOM_FETCH_ALL, value: symptoms };
}

/**
 * Returns an action
 *
 * @param {failureSymptomsInsertedAction} symptom - value of the action
 */
export function failureSymptomsInsertedAction(symptom: failureSymptomsInsertedAction["value"]): failureSymptomsInsertedAction {
    return { type: FailureActionType.FAILURE_SYMPTOM_NOTIFICATION_INSERTED, value: symptom };
}

/**
 * Returns an action
 *
 * @param {failureSymptomsUpdatedAction} symptom - value of the action
 */
export function failureSymptomsUpdatedAction(symptom: failureSymptomsUpdatedAction["value"]): failureSymptomsUpdatedAction {
    return { type: FailureActionType.FAILURE_SYMPTOM_NOTIFICATION_UPDATED, value: symptom };
}

/**
 * Returns an action
 *
 * @param {setFailureTypesListAction} types - value of the action
 */
export function setFailureTypesListAction(types: setFailureTypesListAction["value"]): setFailureTypesListAction {
    return { type: FailureActionType.FAILURE_TYPE_FETCH_ALL, value: types };
}

/**
 * Returns an action
 *
 * @param {failureTypesInsertedAction} type - value of the action
 */
export function failureTypesInsertedAction(type: failureTypesInsertedAction["value"]): failureTypesInsertedAction {
    return { type: FailureActionType.FAILURE_TYPE_NOTIFICATION_INSERTED, value: type };
}

/**
 * Returns an action
 *
 * @param {failureTypesUpdatedAction} type - value of the action
 */
export function failureTypesUpdatedAction(type: failureTypesUpdatedAction["value"]): failureTypesUpdatedAction {
    return { type: FailureActionType.FAILURE_TYPE_NOTIFICATION_UPDATED, value: type };
}
