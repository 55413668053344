var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function Checkbox(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M16.0238095,7.75 L17.452381,9.25 L10.7952381,16.25 L6.5,11.75 L7.92857143,10.25 L10.7952381,13.25 L16.0238095,7.75 Z", id: "icons-approve" })));
}
