import { theme } from "@aos/react-components";
import { ScannerCodeTypeEnum } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { CodeTable } from "./CodeTable";
import { ItemCodeTable } from "./ItemCodeTable";

const useStyles = makeStyles({
    firstCodeTable: {
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        height: "505px",
        marginBottom: "5px",
    },
});

interface ICodesProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    items: string[];
    kanban: string | null;
    onDelete: (type: ScannerCodeTypeEnum, index?: number) => void;
    table: string | null;
}

function Codes(props: ICodesProps): JSX.Element {
    const { items, kanban, onDelete, table } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    const handleDelete =
        (type: ScannerCodeTypeEnum): ((index: number) => void) =>
        (index: number) => {
            onDelete(type, index);
        };

    return (
        <Paper className={classes.root}>
            <CodeTable
                classes={{
                    tableContainer: classes.firstCodeTable,
                }}
                codes={table ? [table] : []}
                id="codeTableTableCodesId"
                labels={{
                    empty: translate("scanner.scanTable"),
                    header: translate("scanner.table"),
                }}
                onDelete={handleDelete(ScannerCodeTypeEnum.TABLE)}
            />
            <CodeTable
                codes={kanban ? [kanban] : []}
                id="codeTableKanbanCodesId"
                labels={{
                    empty: translate("scanner.scanKanban"),
                    header: translate("scanner.kanban"),
                }}
                onDelete={handleDelete(ScannerCodeTypeEnum.KANBAN)}
            />
            <ItemCodeTable id="codeTableItemCodesId" items={items} onDelete={handleDelete(ScannerCodeTypeEnum.ITEM)} />
        </Paper>
    );
}

export default Codes;
