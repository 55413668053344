import {
    EnumArrowheadShape,
    EnumDashType,
    ILineArrowhead,
    IWorkInstructionsElementConfig,
    IWorkInstructionsLineConfig,
} from "@kortex/aos-common";
import * as React from "react";

import { radianToDegree } from "../../../../../../../../utilitites/math/math";

export const ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER = 3;
export const ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER_HALF = ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER / 2;
export const DASH_THICKNESS_MULTIPLIER = 3;
export const DIAMOND_DEFAULT_ROTATION_DEGREE = 45;
export const SHADOW_THICKNESS = 16;

export interface IOwnProps {
    disabled?: boolean;
    lineProps: IWorkInstructionsElementConfig<IWorkInstructionsLineConfig>;
}

export default function WorkInstructionsLine(props: IOwnProps): JSX.Element {
    const { disabled, lineProps } = props;
    const { extendedProps, _id } = lineProps;
    const { arrowheads, color, dash, thickness } = extendedProps;

    /**
     * Get selected dash type
     */
    const getStrokeDashArray = (): number | undefined => {
        switch (dash) {
            case EnumDashType.DASH:
                return thickness * DASH_THICKNESS_MULTIPLIER;
            case EnumDashType.DOT:
                return thickness;
            default:
                return;
        }
    };

    /**
     * Get the angle of the line
     *
     * @param {boolean} isStart - True if it is the first arrowhead (start of the line), false if it is the second (end of the line)
     */
    const getArrowheadAngle = (isStart: boolean): number => {
        const angle = Math.atan2(arrowheads[1].y - arrowheads[0].y, arrowheads[1].x - arrowheads[0].x) + Math.PI / 2;
        return isStart ? angle : angle + Math.PI;
    };

    /**
     * Renders the arrowheads of the line
     *
     * @param {ILineArrowhead} arrowhead - coordinates and shape of the arrowhead
     * @param {boolean} isStart - true if start of line, false if end of the line
     */
    const renderArrowhead = (arrowhead: ILineArrowhead, isStart: boolean): JSX.Element => {
        const { x, y, shape } = arrowhead;
        const angle = getArrowheadAngle(isStart);
        const id = (isStart ? "workInstructionsLineStartId" : "workInstructionsLineEndId") + _id;
        const cursor = disabled ? "default" : "crosshair";

        switch (shape) {
            case EnumArrowheadShape.ARROW:
                const arrowSize = thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER;
                return (
                    <path
                        d={`M${x} ${y} L${x - arrowSize * Math.sin(-angle - Math.PI / 5)} ${
                            y - arrowSize * Math.cos(-angle - Math.PI / 5)
                        } L${x - arrowSize * Math.sin(-angle + Math.PI / 5)} ${y - arrowSize * Math.cos(-angle + Math.PI / 5)} Z`}
                        fill={color}
                        id={id}
                    />
                );

            case EnumArrowheadShape.ARROW_NOTCHED:
                const notchedArrowSize = thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER;
                return (
                    <g cursor={disabled ? "default" : "pointer"}>
                        <path
                            d={`M${x} ${y} 
                            L${x - notchedArrowSize * Math.sin(-angle - Math.PI / 5)} ${
                                y - notchedArrowSize * Math.cos(-angle - Math.PI / 5)
                            } 
                            L${x - (notchedArrowSize * Math.sin(-angle)) / 2} ${y - (notchedArrowSize * Math.cos(-angle)) / 2} 
                            L${x - notchedArrowSize * Math.sin(-angle + Math.PI / 5)} ${
                                y - notchedArrowSize * Math.cos(-angle + Math.PI / 5)
                            } 
                            Z`}
                            fill={color}
                        />
                    </g>
                );

            case EnumArrowheadShape.CIRCLE:
                return (
                    <circle
                        cursor={cursor}
                        cx={x}
                        cy={y}
                        fill={color}
                        id={id}
                        r={thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER_HALF}
                    />
                );

            case EnumArrowheadShape.DIAMOND:
                return (
                    <rect
                        cursor={cursor}
                        fill={color}
                        height={thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER}
                        id={id}
                        transform={`rotate(${radianToDegree(angle) + DIAMOND_DEFAULT_ROTATION_DEGREE} ${x} ${y})`}
                        width={thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER}
                        x={x - thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER_HALF}
                        y={y - thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER_HALF}
                    />
                );

            case EnumArrowheadShape.SQUARE:
                return (
                    <rect
                        cursor={cursor}
                        fill={color}
                        height={thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER}
                        id={id}
                        transform={`rotate(${radianToDegree(angle)} ${x} ${y})`}
                        width={thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER}
                        x={x - thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER_HALF}
                        y={y - thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER_HALF}
                    />
                );

            default:
                return <g id={id} />;
        }
    };

    /**
     * Get the coordinates of the start and the end of the line
     *
     * @returns {string} svg coordinates "x1,y1 x2,y2"
     */
    const getLinePoints = (): string => {
        let x1 = arrowheads[0].x;
        let y1 = arrowheads[0].y;
        let x2 = arrowheads[1].x;
        let y2 = arrowheads[1].y;

        if (arrowheads[0].shape !== EnumArrowheadShape.NONE) {
            x1 += Math.sin(getArrowheadAngle(true)) * (thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER_HALF - 1);
            y1 -= Math.cos(getArrowheadAngle(true)) * (thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER_HALF - 1);
        }

        if (arrowheads[1].shape !== EnumArrowheadShape.NONE) {
            x2 += Math.sin(getArrowheadAngle(false)) * (thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER_HALF - 1);
            y2 -= Math.cos(getArrowheadAngle(false)) * (thickness * ARROWHEAD_DEFAULT_THICKNESS_MULTIPLIER_HALF - 1);
        }

        return `${x1},${y1} ${x2},${y2}`;
    };

    return (
        <g id="workInstructionsLineId">
            {/*Line */}
            <polyline
                id="workInstructionsLineLineId"
                stroke={color}
                strokeWidth={thickness}
                strokeDasharray={getStrokeDashArray()}
                points={getLinePoints()}
            />

            {/* Shadow */}
            <polyline
                cursor={props.disabled ? "default" : "pointer"}
                id="workInstructionsLineShadowId"
                stroke="transparent"
                strokeWidth={thickness + SHADOW_THICKNESS}
                points={getLinePoints()}
            />

            {/*Line */}
            {renderArrowhead(arrowheads[0], true)}
            {renderArrowhead(arrowheads[1], false)}
        </g>
    );
}
