import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import { AOSClientServiceRequestWrapped, TrainingCertificateInsertReq, TrainingCertificateInsertRes } from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { trainingCertificateInsert } from "./insert";

interface IAOSClientServiceWrappedTrainingCertificate {
    insert: AOSClientServiceRequestWrapped<TrainingCertificateInsertReq, TrainingCertificateInsertRes>;
}

type AOSClientServiceWrappedTrainingCertificate = IAOSClientServiceWrappedTrainingCertificate;

// ADD_API_CALL Root cause
export function generateTrainingCertificateServiceClient(router: IRouterClient): AOSClientServiceWrappedTrainingCertificate {
    return {
        insert: AOSClientServiceWrapperUI.request(trainingCertificateInsert(router)),
    };
}

generateTrainingCertificateServiceClient.serviceId = 132;
