import { KortexDialogConfirmation } from "@aos/react-components";
import { ITreeNodeDbModel, ProcessApprovalStatusFilter, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";

import ProcessRepository from "../../ProcessEditor/RepositoryManager/ProcessRepository";

const useStyles = makeStyles({
    dialogRepoContent: {
        height: "60vh",
        width: "80vw",
        maxWidth: "1102px",
    },
});

export interface IProcessTrainingSelectorDialog {
    repoDialogOpen: boolean;

    onProcessesSelected: (selectedProcessVersionId: number[]) => void;
    onRepoHandleCloseDialog: () => void;
}

export default function ProcessTrainingSelectorDialog(props: IProcessTrainingSelectorDialog): JSX.Element {
    const { repoDialogOpen, onProcessesSelected, onRepoHandleCloseDialog } = props;
    const classes = useStyles();
    const translate = useTranslate();

    const [selectedProcessVersionId, setSelectedProcessVersionId] = useState<number[]>([]);

    /**
     * handle request to close the dialog
     */
    const handleClose = (): void => {
        onRepoHandleCloseDialog();
    };

    /**
     * Handle that is called when an option in the dialog process repos is changed... Keep value in a state until apply is called
     */
    const handleRepoSelectionChanged = (processesOrRoutings: ITreeNodeDbModel[], versions?: number[]): void => {
        // param processesOrRoutings should be delete when ProcessRepositorySeletor will be create
        if (versions && versions.length > 0) {
            setSelectedProcessVersionId(versions);
        }
    };

    /**
     * handles the processes selected
     */
    const handleRepoSelection = (): void => {
        onProcessesSelected(selectedProcessVersionId);
        handleClose();
    };

    return (
        <KortexDialogConfirmation
            open={repoDialogOpen}
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: handleClose,
                id: "processTrainingSelectorDialogId",
            }}
            textLabels={{
                titleLabel: translate("training.selectProcess"),
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.select"),
            }}
            onCancel={handleClose}
            onConfirm={handleRepoSelection}
            confirmDisabled={!Boolean(selectedProcessVersionId)}
            closeOnEscape={true}
        >
            <div className={classes.dialogRepoContent}>
                {repoDialogOpen && (
                    <ProcessRepository
                        multiItemSelection={true}
                        filterType={TreeNodeNodeTypeEnum.PROCESS}
                        onSelectionChanged={handleRepoSelectionChanged}
                        selectOnly={true}
                        showVersionVariant={ProcessApprovalStatusFilter.APPROVED_AND_DEFAULT_RELEASED}
                        handleSelectProcessConfirmation={handleRepoSelection}
                    />
                )}
            </div>
        </KortexDialogConfirmation>
    );
}
