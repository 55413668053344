import { ITreeNodeDbModel, RepositoryEditorRightsEnum, StorageEditorsRightsEnum } from "@kortex/aos-common";
import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useSelectorFiles } from "@kortex/aos-ui/redux/selectors";

import { filesUpdate } from "../../../../redux/file-manager/file-thunks-file";
import { IUserRightsProps, userCanWrite } from "../../../../utilitites/IUserRights";
import { useThunkDispatch } from "../../../../hooks/useThunkDispatch";
import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    dialogContentContainer: {
        display: "grid",
    },
});

interface IOwnProps extends IUserRightsProps<RepositoryEditorRightsEnum | StorageEditorsRightsEnum> {
    onClose: () => void;
    open: boolean;
    treeNode: ITreeNodeDbModel;
}

function TooltipEditor(props: IOwnProps): JSX.Element {
    const { onClose, open, treeNode, userAccessLevel } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const file = useSelectorFiles().find((file) => file.treeNodeId === treeNode.treeNodeId);
    const translate = useTranslate();

    const [tooltip, setTooltip] = useState<string>(file?.tooltip ?? "");

    const handleSaveTooltip = (): void => {
        if (file) {
            dispatch(
                filesUpdate([
                    {
                        ...file,
                        tooltip,
                    },
                ])
            );
        }

        onClose();
    };

    const handleTooltipChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setTooltip(event.target.value);
    };

    return (
        <KortexDialogConfirmation
            open={open}
            textLabels={{
                titleLabel: `${translate("treeview.menu.editTooltip.title")} - ${treeNode.label}`,
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.save"),
            }}
            onConfirm={handleSaveTooltip}
            onCancel={onClose}
            closeOnEscape={true}
        >
            <div className={classes.dialogContentContainer}>
                <KortexTextField
                    label={translate("treeview.menu.editTooltip.tooltip")}
                    onChange={handleTooltipChange}
                    TextFieldProps={{
                        disabled: !userCanWrite(userAccessLevel),
                        multiline: true,
                        rows: 5,
                    }}
                    value={tooltip}
                />
            </div>
        </KortexDialogConfirmation>
    );
}

export default TooltipEditor;
