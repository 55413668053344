import { KortexTextField, theme } from "@aos/react-components";
import {
    EnumProcessStatus,
    IOperatorJobProcessInfo,
    IProcessUiModel,
    ProcessActionInput,
    ProcessActionStepInput,
    getDateTimeStr,
    getElapsedTimeStr,
    isInput,
} from "@kortex/aos-common";
import { Accordion, AccordionDetails, AccordionSummary, Typography, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesTreeProcess } from "../../../../../redux/effects";
import { IPlayerState } from "../../../../../redux/player-manager/player-types";
import {
    getProcessReleaseStatusLabelKey,
    getProcessVersionStatusLabelKey,
} from "../../../../pages/ProcessEditor/ProcessEditor/ProcessApproval/ProcessApprovalCard";
import { GetJobInProgress } from "../../../../pages/Scheduler/SchedulerData";

const useStyles = makeStyles({
    root: {},
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
        width: "100%",
    },
    textSmall: {
        margin: "5px",
        width: "50px",
    },
    textMedium: {
        margin: "5px",
        width: "150px",
    },
    textLarge: {
        margin: "5px",
        width: "250px",
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: "bold",
    },
});

interface IOwnProps {
    // Own Props
    playerState: IPlayerState;
    process: IProcessUiModel | null;
    jobProcessInfo?: IOperatorJobProcessInfo;
    reworkId?: number;
    runWithoutJobProcess?: boolean;
}

export default function RunDetailsDialogContent(props: IOwnProps): JSX.Element {
    const { jobProcessInfo, process, playerState } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const treeNodes = useEntitiesTreeProcess();

    const [processName, setProcessName] = useState<string>("");
    const [processInputProps, setProcessInputProps] = useState<ProcessActionStepInput | undefined>(undefined);

    /**
     * update process name
     */
    useEffect((): void => {
        const treeNode = treeNodes.find((treeNode) => treeNode.treeNodeId === process?.treeNodeId);
        if (treeNode) {
            setProcessName(treeNode.label);
        } else {
            setProcessName("");
        }
        const input = process?.actions.find((action): action is ProcessActionInput => isInput(action));
        if (input && input.steps.length > 0) {
            setProcessInputProps(input.steps[0]);
        }
    }, [process]);

    return (
        <div className={classes.dialogDescription}>
            <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panelInformationsId">
                    <Typography className={classes.heading}>{translate("player.runDetailsDialog.currentDetails")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <KortexTextField
                        className={classes.textMedium}
                        label={translate("player.runDetailsDialog.singleOrBatch")}
                        value={
                            processInputProps?.config.enableBatchProcessing
                                ? translate("player.runDetailsDialog.batch")
                                : translate("player.runDetailsDialog.single")
                        }
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                        }}
                        variant={"standard"}
                    />
                    <KortexTextField
                        className={classes.textMedium}
                        label={translate("player.runDetailsDialog.trackingNumber")}
                        value={playerState.processState.serialNumber ? playerState.processState.serialNumber : translate("general.none")}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                        }}
                        variant={"standard"}
                    />
                    {Boolean(
                        processInputProps?.config.enableBatchProcessing && playerState.processState.status === EnumProcessStatus.IDLE
                    ) && (
                        <KortexTextField
                            className={classes.textMedium}
                            label={translate("player.runDetailsDialog.maxQty")}
                            value={processInputProps?.config.maxBatchQty ? processInputProps?.config.maxBatchQty : ""}
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                            }}
                            variant={"standard"}
                        />
                    )}
                    {Boolean(playerState.processState.batchEnable && playerState.processState.status !== EnumProcessStatus.IDLE) && (
                        <>
                            <KortexTextField
                                className={classes.textMedium}
                                label={translate("player.runDetailsDialog.newProducts")}
                                value={translate(playerState.processState.batchNew ? "general.yes" : "general.no")}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                variant={"standard"}
                            />
                            <KortexTextField
                                className={classes.textMedium}
                                label={translate("player.runDetailsDialog.totalQty")}
                                value={playerState.processState.batchQty}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                variant={"standard"}
                            />
                            <KortexTextField
                                className={classes.textMedium}
                                label={translate("player.runDetailsDialog.inProgressQty")}
                                value={playerState.processState.batchQty - playerState.processState.failQty}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                variant={"standard"}
                            />
                            <KortexTextField
                                className={classes.textMedium}
                                label={translate("player.runDetailsDialog.failedQty")}
                                value={playerState.processState.failQty}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                variant={"standard"}
                            />
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panelMoreInformationsId">
                    <Typography className={classes.heading}>{translate("player.runDetailsDialog.moreInfo")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {(playerState.processState.isTestRun || playerState.processState.dryRunMode) && (
                        <KortexTextField
                            className={classes.textMedium}
                            label={translate("player.runDetailsDialog.runType")}
                            value={
                                playerState.processState.isTestRun
                                    ? translate("player.runDetailsDialog.testRun")
                                    : playerState.processState.dryRunMode
                                    ? translate("player.runDetailsDialog.dryRun")
                                    : translate("player.runDetailsDialog.normalRun")
                            }
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                            }}
                            variant={"standard"}
                        />
                    )}
                    <KortexTextField
                        className={classes.textLarge}
                        label={translate("player.runDetailsDialog.runFromJobProcess")}
                        value={`${translate(!props.runWithoutJobProcess ? "general.yes" : "general.no")} ${
                            !props.runWithoutJobProcess ? "(" + jobProcessInfo?.jobProcessId + ")" : ""
                        }`}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                        }}
                        variant={"standard"}
                    />
                    <KortexTextField
                        className={classes.textLarge}
                        label={translate("player.runDetailsDialog.isRework")}
                        value={`${translate(props.reworkId ? "general.yes" : "general.no")} ${
                            props.reworkId ? "(" + props.reworkId + ")" : ""
                        }`}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                        }}
                        variant={"standard"}
                    />
                </AccordionDetails>
            </Accordion>

            <Accordion disabled={!process}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panelProcessId">
                    <Typography className={classes.heading}>{translate("player.runDetailsDialog.process")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {process && (
                        <>
                            <KortexTextField
                                className={classes.textSmall}
                                label={translate("general.id")}
                                value={process.processId}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                variant={"standard"}
                            />
                            <KortexTextField
                                className={classes.textLarge}
                                label={translate("player.runDetailsDialog.processName")}
                                value={processName}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                variant={"standard"}
                            />
                            <KortexTextField
                                className={classes.textMedium}
                                label={translate("player.runDetailsDialog.releaseStatus")}
                                value={translate(getProcessReleaseStatusLabelKey(process))}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                variant={"standard"}
                            />
                            <KortexTextField
                                className={classes.textMedium}
                                label={translate("player.runDetailsDialog.versionStatus")}
                                value={translate(getProcessVersionStatusLabelKey(process.versionStatus))}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                variant={"standard"}
                            />
                            <KortexTextField
                                className={classes.textMedium}
                                label={translate("player.runDetailsDialog.releasedOn")}
                                value={process.releasedOn ? getDateTimeStr(process.releasedOn) : ""}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true,
                                }}
                                variant={"standard"}
                            />
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
            <Accordion disabled={!jobProcessInfo}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panelJobProcessId">
                    <Typography className={classes.heading}>{translate("player.runDetailsDialog.jobProcess")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {jobProcessInfo && (
                        <>
                            <KortexTextField
                                label={translate("scheduler.jobRefId")}
                                value={`${jobProcessInfo.job?.jobRefId}`}
                                className={classes.textMedium}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <KortexTextField
                                label={translate("scheduler.referenceId")}
                                value={`${jobProcessInfo.job?.referenceId}`}
                                className={classes.textMedium}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <KortexTextField
                                label={translate("scheduler.partNumber")}
                                value={`${jobProcessInfo.job?.partNumber}`}
                                className={classes.textMedium}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <KortexTextField
                                label={translate("scheduler.quantityInProgress")}
                                value={
                                    GetJobInProgress(
                                        jobProcessInfo.qtyStarted,
                                        jobProcessInfo.qty,
                                        jobProcessInfo.qtyPassed,
                                        jobProcessInfo.qtyFailed
                                    ).inProgress
                                }
                                className={classes.textMedium}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <KortexTextField
                                label={translate("scheduler.quantityRemainingToStart")}
                                value={
                                    GetJobInProgress(
                                        jobProcessInfo.qtyStarted,
                                        jobProcessInfo.qty,
                                        jobProcessInfo.qtyPassed,
                                        jobProcessInfo.qtyFailed
                                    ).remainingToStart
                                }
                                className={classes.textMedium}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <KortexTextField
                                label={translate("scheduler.quantityPass")}
                                value={`${jobProcessInfo.qtyPassed ?? 0}`}
                                className={classes.textMedium}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <KortexTextField
                                label={translate("scheduler.quantityFail")}
                                value={`${jobProcessInfo.qtyFailed ?? 0}`}
                                className={classes.textMedium}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <KortexTextField
                                label={translate("scheduler.quantity")}
                                value={`${jobProcessInfo.qty ?? 0}`}
                                className={classes.textMedium}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <KortexTextField
                                label={translate("scheduler.elapsedTime")}
                                value={`${getElapsedTimeStr(jobProcessInfo.timeRun)}`}
                                className={classes.textMedium}
                                variant="standard"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
