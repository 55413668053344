import Debug from "debug";
import { getTime } from "../../../../utilities/utilities";
import { ActionBaseGenerator } from "./ActionBaseGenerator";
import { ActionBaseState } from "./ActionBaseState";
import { ActionBlockSizeEnum, EnumActionStatus } from "./ActionEnums";
const debug = Debug("kortex:common:action-helpers");
/**
 * Finds the next action in the process tree
 *
 * @param {IProcessActionDbModel} action - process action object
 * @param {number} outputIndex - number
 * @param {IProcessActionDbModel[]} actions - list of actions
 *
 */
function findNextAction(action, outputIndex, actions) {
    if (action &&
        action.outputs.length > outputIndex &&
        action.outputs[outputIndex].remoteIds &&
        action.outputs[outputIndex].remoteIds.length > 0) {
        return actions.find((actionItem) => actionItem.processActionId === action.outputs[outputIndex].remoteIds[0].actionId);
    }
    else {
        return undefined;
    }
}
/**
 * Orders action tree recursively
 *
 * @param {IProcessActionDbModel} parentAction - previous parent Action
 * @param {Array.<IProcessActionDbModel>} actions - list of actions
 * @param {number} forkedIndex - the current forked index
 * @param {IActionOrderingTable} orderedTable - the ordered actions
 * @param {number} playedActions - actions that have been played already
 */
function orderActionRecur(parentAction, actions, forkedIndex, orderedTable, playedActions) {
    let nextAction = undefined;
    if (parentAction.outputs.length === 1) {
        // only one output, let's go to the next one
        nextAction = findNextAction(parentAction, 0, actions);
    }
    else {
        // we have more than one output, it's either a branch or a loop, let's see if already played this action
        // if we did we cna follow that branch
        // TODO: special case for loops
        if (playedActions) {
            const nextOutputId = playedActions.find((playedAction) => parentAction.outputs.findIndex((output) => playedAction === output.remoteIds[0]?.actionId) > -1);
            if (nextOutputId) {
                nextAction = actions.find((action) => action.processActionId === nextOutputId);
            }
        }
    }
    if (nextAction) {
        let infiniteLoopDetected = false;
        // Find it in the ordered table
        const orderedParentEntryAction = orderedTable.find((action) => action.actionId === parentAction?.processActionId);
        const orderedNextEntryAction = orderedTable.find((action) => action.actionId === nextAction?.processActionId);
        if (orderedNextEntryAction && orderedParentEntryAction) {
            if (orderedNextEntryAction.playOrder !== 0) {
                // Infinite loop...  get out
                infiniteLoopDetected = true;
            }
            orderedNextEntryAction.playOrder = orderedParentEntryAction.playOrder + 1;
        }
        if (infiniteLoopDetected === false) {
            orderActionRecur(nextAction, actions, 0, orderedTable, playedActions);
        }
    }
}
/**
 * Orders the action list
 *
 * @param {Array.<IProcessActionDbModel>} actions - List of actions
 * @param {number} curActionId - Current action currently at the beginning of the path
 * @param {number[]} playedActions - actions that have been played already
 */
export function orderActionList(actions, curActionId, playedActions) {
    let curAction;
    if (curActionId === undefined) {
        curAction = actions.find((actionItem) => actionItem.type === "core-input");
    }
    else {
        curAction = actions.find((actionItem) => actionItem.processActionId === curActionId);
    }
    const orderedTable = [];
    if (!curAction) {
        debug("Can't find start action. Missing InputAction in process");
        return [];
    }
    for (let count = 0; count < actions.length; count++) {
        const actionEntry = {
            actionId: actions[count].processActionId,
            playOrder: 0,
        };
        orderedTable.push(actionEntry);
    }
    const curOrderedAction = orderedTable.find((action) => action.actionId === curAction?.processActionId);
    if (curOrderedAction) {
        curOrderedAction.playOrder = 1;
    }
    if (curAction?.outputs.length === 1) {
        orderActionRecur(curAction, actions, -1, orderedTable, playedActions);
    }
    return orderedTable.sort((a, b) => a.playOrder - b.playOrder);
}
/**
 * converts a process object to process state object
 *
 * @param {IProcessActionDbModel[]} actions - List of actions
 */
export function convertProcessToClientProcessState(actions) {
    const clientActionBases = [];
    for (const action of actions) {
        const baseState = new ActionBaseState(EnumActionStatus.IDLE, action.label, getTime());
        const newActionBaseState = Object.assign(baseState, action);
        const actionBase = new ActionBaseGenerator();
        const newAction = Object.assign(actionBase, action);
        newAction.initStepStates();
        newActionBaseState.stepsState = newAction.state.stepsState;
        clientActionBases.push({
            actionId: action.processActionId || -1,
            actionType: action.type,
            baseStates: newActionBaseState,
        });
    }
    return clientActionBases;
}
/**
 * Creates line connector between 2 points
 *
 * @param {number} x1 - x of the first coordinate
 * @param {number} y1 - y of the first coordinate
 * @param {number} x2 - x of the second coordinate
 * @param {number} y2 - y of the second coordinate
 */
export function connectorPath(x1, y1, x2, y2) {
    let bezierCurve = 15; // length of each segment of 90 degree angle
    let clearanceH = 40; // horizontal clearance
    const clearanceVB = 25; // vertical bottom clearance
    const clearanceVT = 100; // vertical top clearance
    if (Math.abs(y2 - y1) <= bezierCurve * 2 || Math.abs(x2 - x1) <= bezierCurve * 2) {
        if (Math.abs(y2 - y1) < Math.abs(x2 - x1)) {
            bezierCurve = Math.ceil(Math.abs(y2 - y1) / 4);
        }
        else {
            bezierCurve = Math.ceil(Math.abs(x2 - x1) / 4);
        }
    }
    if (x2 > x1 + clearanceH && y1 === y2) {
        // Straight line
        return `M ${x1} ${y1} L ${x2} ${y2}`;
    }
    if (x2 > x1 + clearanceH + bezierCurve && Math.abs(x2 - x1) < 2 * clearanceH) {
        clearanceH = Math.floor(clearanceH / 3);
    }
    if (x2 > x1 + clearanceH * 2) {
        // Target on the right
        /*
         This draws a line like this with curved corners
            |------
            |
         ---|
        */
        return `M ${x1} ${y1}
                H ${x1 + clearanceH}
                C ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1 - bezierCurve * (y2 > y1 ? -1 : 1)}
                V ${y2 + bezierCurve * (y2 > y1 ? -2 : 2)}
                C ${x1 + clearanceH + bezierCurve},${y2} ${x1 + clearanceH + bezierCurve},${y2} ${x1 + clearanceH + bezierCurve * 2},${y2}
                H ${x2}`;
    }
    else if (y2 <= y1 + clearanceVT) {
        // Target behind and above source
        if (y1 - (y2 + clearanceVB) < clearanceVB + 4 * bezierCurve) {
            bezierCurve = 10;
        }
        if (y1 - (y2 + clearanceVB) < clearanceVB + 2 * bezierCurve) {
            bezierCurve = 5;
        }
        /*
         This draws a line like this with curved corners
            |---
            |
            |-----------|
                        |
                      --|
        */
        return `M ${x1} ${y1}
                H ${x1 + clearanceH}
                C ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1 - bezierCurve * (y2 > y1 ? -1 : 1)}
                V ${y2 + clearanceVB + 3 * bezierCurve}
                C ${x1 + clearanceH + bezierCurve},${y2 + clearanceVB + 2 * bezierCurve} ${x1 + clearanceH + bezierCurve},${y2 + clearanceVB + 2 * bezierCurve} ${x1 + clearanceH},${y2 + 2 * bezierCurve + clearanceVB}
                H ${x2 - clearanceH + bezierCurve}
                C ${x2 - clearanceH},${y2 + clearanceVB + 2 * bezierCurve} ${x2 - clearanceH},${y2 + clearanceVB + 2 * bezierCurve} ${x2 - clearanceH},${y2 + clearanceVB + bezierCurve}
                V ${y2 + bezierCurve}
                C ${x2 - clearanceH},${y2} ${x2 - clearanceH},${y2} ${x2 - clearanceH + bezierCurve},${y2}
                H ${x2}`;
    }
    else {
        // target is behind and under
        if (y2 - (y1 + clearanceVT) < clearanceVT + 4 * bezierCurve) {
            bezierCurve = 10;
        }
        if (y2 - (y1 + clearanceVT) < clearanceVT + 2 * bezierCurve) {
            bezierCurve = 5;
        }
        /*
         This draws a line like this with curved corners
                    ----|
                        |
            |-----------|
            |
            |---
        */
        return `M ${x1} ${y1}
                H ${x1 + clearanceH}
                C ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1 + bezierCurve}
                V ${y2 - clearanceVT - 3 * bezierCurve}
                C ${x1 + clearanceH + bezierCurve},${y2 - clearanceVT} ${x1 + clearanceH + bezierCurve},${y2 - clearanceVT} ${x1 + clearanceH},${y2 - clearanceVT}
                H ${x2 - clearanceH + bezierCurve}
                C ${x2 - clearanceH},${y2 - clearanceVT} ${x2 - clearanceH},${y2 - clearanceVT} ${x2 - clearanceH},${y2 - clearanceVT + bezierCurve}
                V ${y2 - bezierCurve}
                C ${x2 - clearanceH},${y2} ${x2 - clearanceH},${y2} ${x2 - clearanceH + bezierCurve},${y2}
                H ${x2}`;
    }
}
/**
 * Creates line connector between 2 points
 *
 * @param {number} x1 - x of the first coordinate
 * @param {number} y1 - y of the first coordinate
 * @param {number} x2 - x of the second coordinate
 * @param {number} y2 - y of the second coordinate
 */
export function connectorPathReverse(x1, y1, x2, y2) {
    let bezierCurve = 15; // length of each segment of 90 degree angle
    let clearanceH = 40; // horizontal clearance
    const clearanceVB = 25; // vertical bottom clearance
    const clearanceVT = 100; // vertical top clearance
    if (Math.abs(y2 - y1) <= bezierCurve * 2 || Math.abs(x2 - x1) <= bezierCurve * 2) {
        if (Math.abs(y2 - y1) < Math.abs(x2 - x1)) {
            bezierCurve = Math.ceil(Math.abs(y2 - y1) / 4);
        }
        else {
            bezierCurve = Math.ceil(Math.abs(x2 - x1) / 4);
        }
    }
    if (x2 > x1 + clearanceH && y1 === y2) {
        // Straight line
        return `M ${x1} ${y1} L ${x2} ${y2}`;
    }
    if (x2 > x1 + clearanceH + bezierCurve && Math.abs(x2 - x1) < 2 * clearanceH) {
        clearanceH = Math.floor(clearanceH / 3);
    }
    if (x2 > x1 + clearanceH * 2) {
        // Target on the right
        /*
         This draws a line like this with curved corners
            |------
            |
         ---|
        */
        return `M ${x1} ${y1}
                H ${x1 + clearanceH}
                C ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1 - bezierCurve * (y2 > y1 ? -1 : 1)}
                V ${y2 + bezierCurve * (y2 > y1 ? -2 : 2)}
                C ${x1 + clearanceH + bezierCurve},${y2} ${x1 + clearanceH + bezierCurve},${y2} ${x1 + clearanceH + bezierCurve * 2},${y2}
                H ${x2}`;
    }
    else if (y2 <= y1 + clearanceVT) {
        // Target behind and above source
        if (y1 - (y2 + clearanceVB) < clearanceVB + 4 * bezierCurve) {
            bezierCurve = 10;
        }
        if (y1 - (y2 + clearanceVB) < clearanceVB + 2 * bezierCurve) {
            bezierCurve = 5;
        }
        /*
         This draws a line like this with curved corners
            |---
            |
            |-----------|
                        |
                      --|
        */
        return `M ${x1} ${y1}
                H ${x1 + clearanceH}
                C ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1 - bezierCurve * (y2 > y1 ? -1 : 1)}
                V ${y2 + clearanceVB + 3 * bezierCurve}
                C ${x1 + clearanceH + bezierCurve},${y2 + clearanceVB + 2 * bezierCurve} ${x1 + clearanceH + bezierCurve},${y2 + clearanceVB + 2 * bezierCurve} ${x1 + clearanceH},${y2 + 2 * bezierCurve + clearanceVB}
                H ${x2 - clearanceH + bezierCurve}
                C ${x2 - clearanceH},${y2 + clearanceVB + 2 * bezierCurve} ${x2 - clearanceH},${y2 + clearanceVB + 2 * bezierCurve} ${x2 - clearanceH},${y2 + clearanceVB + bezierCurve}
                V ${y2 + bezierCurve}
                C ${x2 - clearanceH},${y2} ${x2 - clearanceH},${y2} ${x2 - clearanceH + bezierCurve},${y2}
                H ${x2}`;
    }
    else {
        // target is behind and under
        if (y2 - (y1 + clearanceVT) < clearanceVT + 4 * bezierCurve) {
            bezierCurve = 10;
        }
        if (y2 - (y1 + clearanceVT) < clearanceVT + 2 * bezierCurve) {
            bezierCurve = 5;
        }
        /*
         This draws a line like this with curved corners
                    ----|
                        |
            |-----------|
            |
            |---
        */
        return `M ${x1} ${y1}
                H ${x1 + clearanceH}
                C ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1} ${x1 + clearanceH + bezierCurve},${y1 + bezierCurve}
                V ${y2 - clearanceVT - 3 * bezierCurve}
                C ${x1 + clearanceH + bezierCurve},${y2 - clearanceVT} ${x1 + clearanceH + bezierCurve},${y2 - clearanceVT} ${x1 + clearanceH},${y2 - clearanceVT}
                H ${x2 - clearanceH + bezierCurve}
                C ${x2 - clearanceH},${y2 - clearanceVT} ${x2 - clearanceH},${y2 - clearanceVT} ${x2 - clearanceH},${y2 - clearanceVT + bezierCurve}
                V ${y2 - bezierCurve}
                C ${x2 - clearanceH},${y2} ${x2 - clearanceH},${y2} ${x2 - clearanceH + bezierCurve},${y2}
                H ${x2}`;
    }
}
/**
 * Generate an increment close to ActionBlockSizeEnum.HEIGHT_INCREMENT that will make the outputs node aligned on the grid.
 *
 * @param {number} multiplier - count of connector(s)
 */
export function getHeightIncrementToAlignGrid(multiplier) {
    if (multiplier <= 1) {
        return 0;
    }
    // Compute the precise value --> not aligned to grid
    const totalHeightNotAligned = multiplier * ActionBlockSizeEnum.HEIGHT_INCREMENT + ActionBlockSizeEnum.DEFAULT_HEIGHT;
    const nodeHeighNotAligned = (totalHeightNotAligned - ActionBlockSizeEnum.HEADER_HEIGHT) / (1 + multiplier);
    //  Align to grid by rounding to the closest grid value
    const nodeHeightAligned = getPositionAlignedGrid(nodeHeighNotAligned);
    const totalHeightAligned = nodeHeightAligned * (1 + multiplier) + ActionBlockSizeEnum.HEADER_HEIGHT;
    // Compute the actual height increment
    const heightIncrementAligned = (totalHeightAligned - ActionBlockSizeEnum.DEFAULT_HEIGHT) / multiplier;
    return heightIncrementAligned;
}
/**
 * Compute the next best position to be aligned on the grid.
 *
 * @param {number} position_not_aligned - count of input connector(s)
 * @param {number} OffsetPosition - count of input connector(s)
 */
export function getPositionAlignedGrid(position_not_aligned, OffsetPosition = 0) {
    return (ActionBlockSizeEnum.GRID_RESOLUTION *
        Math.trunc((position_not_aligned - OffsetPosition) / ActionBlockSizeEnum.GRID_RESOLUTION + 0.5) +
        OffsetPosition);
}
