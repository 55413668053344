import { ServiceManagerClient, WSClient, Kinova } from "@kortex/aos-api-client";
import { getRPCHubInfo, ElectronUpdateHostReq, ElectronUpdateHostRes } from "@kortex/aos-common";

import { objectFromUTF8Array, utf8ArrayFromObject, utf8ArrayToKortexApiErrorObject } from "../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const electronHostUpdate = (router: WSClient["router"]) =>
    ServiceManagerClient.getRR<ElectronUpdateHostReq, ElectronUpdateHostRes, Kinova.Api.IError>(getRPCHubInfo("electron")("updateHost"), {
        router,
        serializer: {
            decode: objectFromUTF8Array,
            encode: utf8ArrayFromObject,
            err: {
                decode: utf8ArrayToKortexApiErrorObject,
            },
        },
    });
