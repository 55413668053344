import { upsertObjectFromArray } from "@kortex/aos-common";

import { FileActions, FileActionType, FileState } from "./file-types";

const initialState: FileState = {
    files: [],
};

// definition

export function fileReducer(state: FileState = initialState, action: FileActions): FileState {
    switch (action.type) {
        case FileActionType.INSERT_FILES:
            return {
                ...state,
                files: upsertObjectFromArray(state.files, action.value, function (file) {
                    return file.fileInfoId === this.fileInfoId;
                }),
            };
        case FileActionType.SET_FILES:
            return {
                ...state,
                files: [...action.value],
            };
        case FileActionType.UPDATE_FILES:
            return {
                ...state,
                files: upsertObjectFromArray(state.files, action.value, function (file) {
                    return file.fileInfoId === this.fileInfoId;
                }),
            };
        default:
            return state;
    }
}
