import { UndefinedRemoteMethodError } from "../interfaces";
import { localeKeys } from "../locales";
export function assertRequiredRemoteMethodsDefined(remote) {
    if (!remote) {
        throw new UndefinedRemoteMethodError(localeKeys.action.core.remote.remoteMethodsUndefined, "Remote methods are not defined.");
    }
    if (!remote.play) {
        throw new UndefinedRemoteMethodError(localeKeys.action.core.remote.remoteMethodPlayUndefined, "Remote 'play' method is not defined.");
    }
    if (!remote.stop) {
        throw new UndefinedRemoteMethodError(localeKeys.action.core.remote.remoteMethodStopUndefined, "Remote 'stop' method is not defined.");
    }
}
export function assertRemoteMethodDefined(method) {
    if (!method) {
        throw new UndefinedRemoteMethodError(localeKeys.action.core.remote.remoteMethodUndefined, "Remote method is not defined.");
    }
}
