import { greyPalette, theme } from "@aos/react-components";
import { ApprovalStatusEnum, IProcessUiModel, ProcessApprovalRightsEnum, ProcessEditorRightsEnum, ProcessType } from "@kortex/aos-common";
import { TrainingIcon } from "@kortex/aos-ui/components/core/Icons/training";
import { Divider, Drawer, List, ListItem, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TestRunIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import DebugIcon from "@material-ui/icons/BugReport";
import HistoryIcon from "@material-ui/icons/History";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PlayIcon from "@material-ui/icons/PlayCircleOutlineOutlined";
import PreviewIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../hooks/useTranslate";
import { useSelectorEditedProcessId, useSelectorProcesses } from "../../../../redux/selectors";
import { userCanWrite } from "../../../../utilitites/IUserRights";
import ActionIcon from "../../../core/Icons/Action/Action";
import VariablesIcon from "../../../core/Icons/Variables/Variables";
import KortexLabelIcon from "../../../core/KortexLabelIcon";

import ChangeLogs from "./ChangeLogs";
import ProcessApprovalEditor from "./ProcessApproval/ProcessApprovalEditor";
import ProcessInfo from "./ProcessInfo/ProcessInfo";
import ProcessVariableManager from "./ProcessVariables/ProcessVariableManager";

enum PANE_TYPES {
    INFO,
    TASK_SETTINGS,
    PROCESS_VARIABLES,
    ACTION_SELECTOR,
    MEDIA_MANAGER,
    CHAT,
    BUG_REPORT,
}

const useStyles = makeStyles({
    sideMenuContainer: {
        position: "absolute",
        right: "16px",
        top: "16px",
        whiteSpace: "nowrap",
        borderRadius: 0,
        zIndex: 1,
    },
    title: {
        marginLeft: "16px",
        marginRight: "16px",
    },
    sideMenu: {
        backgroundColor: theme.palette.common.white,
        color: greyPalette[600],
        width: "60px",
    },
    varRow: {
        marginTop: "16px",
        marginBottom: "16px",
    },
    formControl: {
        width: "100%",
        marginTop: "16px",
    },
    expandHeader: {
        backgroundColor: greyPalette[600],
        color: theme.palette.common.white,
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        alignItems: "center",
        marginTop: "16px",
        borderRadius: "2px",
    },
    listItem: {
        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },
});

interface IOwnProps {
    readOnly: boolean;
    processApprovalSideMenuUserAccessLevel: ProcessApprovalRightsEnum;
    processEditorSideMenuUserAccessLevel: ProcessEditorRightsEnum;
    onInsertAction: () => void;
    onDryRunClick?: () => void;
    onTestPlayClick?: () => void;
    onPlayClick?: () => void;
    onTrainingClick: () => void;
    onValidateProcess?: () => void;
}

export default function ProcessEditorSideMenu(props: IOwnProps): JSX.Element {
    const { readOnly, processApprovalSideMenuUserAccessLevel, processEditorSideMenuUserAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const editedProcessId = useSelectorEditedProcessId();
    const processes = useSelectorProcesses();

    const [currentProcess, setCurrentProcess] = useState<IProcessUiModel | undefined>(undefined);
    const [sidePanelOpen, setSidePanelOpen] = useState(false);
    const [selectedPane, setSelectedPane] = useState<PANE_TYPES | undefined>(undefined);

    const [changeLogsOpen, setChangeLogsOpen] = useState<boolean>(false);

    const globalReadOnly = !userCanWrite(processEditorSideMenuUserAccessLevel) || readOnly;

    const cannotAccessApprovalPage =
        currentProcess?.archived || processApprovalSideMenuUserAccessLevel <= ProcessApprovalRightsEnum.AUTHENTICATED;

    /**
     * Updates current process based on currently selected processed
     */
    useEffect((): void => {
        setCurrentProcess(processes.find((process) => process.processId === editedProcessId));
    }, [editedProcessId, processes]);

    /**
     * checks if current process is routing
     */
    const isRouting = currentProcess ? currentProcess.type === ProcessType.ROUTING : true;

    /**
     * handles the menu click
     *
     * @param {PANE_TYPES} newSelectedPane - selected panel types
     */
    const handleMenuClick =
        (newSelectedPane: PANE_TYPES): (() => void) =>
        (): void => {
            if (newSelectedPane === PANE_TYPES.INFO) {
                setSelectedPane(newSelectedPane);
            } else if (newSelectedPane !== PANE_TYPES.ACTION_SELECTOR) {
                setSelectedPane(newSelectedPane);
                setSidePanelOpen(true);
            } else {
                props.onInsertAction();
            }
        };

    /**
     * show changeLogs
     */
    const handleShowChangeLogs = (): void => {
        setChangeLogsOpen(true);
    };

    /**
     * handle change log drawer close
     */
    const handleCloseChangeLogs = (): void => {
        setChangeLogsOpen(false);
    };

    /**
     * show settings
     */
    const handleShowSettings = (): void => {
        setSelectedPane(PANE_TYPES.TASK_SETTINGS);
    };

    /**
     * close dialog
     */
    const handleCloseDialog = (): void => {
        setSelectedPane(undefined);
        setSidePanelOpen(false);
    };

    /**
     * Handles a click on the DryRun button
     */
    const handleDryRunProcess = (): void => {
        if (props.onDryRunClick) {
            props.onDryRunClick();
        }
    };

    /**
     * play process
     */
    const handlePlayProcess = (): void => {
        if (props.onPlayClick) {
            props.onPlayClick();
        }
    };

    /**
     * test run process
     */
    const handleTestProcess = (): void => {
        if (props.onTestPlayClick) {
            props.onTestPlayClick();
        }
    };

    /**
     * FIXME: Remove when "Run" button will be removed from side menu
     * Get all versions that are released and sort them
     */
    const isVersionReleased = (): boolean => {
        if (!currentProcess) {
            return false;
        }

        // Process shall not have the isDraft flag set
        if (currentProcess.isDraft) {
            return false;
        }

        // Process shall be approved
        if (currentProcess.versionStatus !== ApprovalStatusEnum.APPROVED) {
            return false;
        }

        const now = Date.now();

        // Show released version
        // Release date < Now < Retire date
        if (
            currentProcess.releasedOn &&
            currentProcess.releasedOn <= now &&
            (!currentProcess.retiredOn || currentProcess.retiredOn >= now)
        ) {
            return true;
        }

        return false;
    };

    return (
        <div>
            {/* SIDE MENU */}
            <Paper className={classes.sideMenuContainer}>
                <List className={classes.sideMenu}>
                    <ListItem
                        button={true}
                        id="processEditorSideMenuInfoId"
                        onClick={handleMenuClick(PANE_TYPES.INFO)}
                        className={classes.listItem}
                    >
                        <KortexLabelIcon label={translate("processEditorSideMenu.info")}>
                            <InfoIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    <Divider />
                    <ListItem
                        button={true}
                        className={classes.listItem}
                        disabled={isRouting}
                        id="processEditorSideMenuTrainingId"
                        onClick={props.onTrainingClick}
                    >
                        <KortexLabelIcon fontSize={9} label={translate("processEditorSideMenu.communique")}>
                            <TrainingIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    <ListItem
                        button={true}
                        id="processEditorSideMenuManageChangeLogs"
                        onClick={handleShowChangeLogs}
                        className={classes.listItem}
                    >
                        <KortexLabelIcon label={translate("processEditorSideMenu.changeLogs")}>
                            <ListAltIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    <ListItem
                        button={true}
                        id="processEditorSideMenuManageVersioningId"
                        onClick={handleShowSettings}
                        className={classes.listItem}
                        disabled={cannotAccessApprovalPage}
                    >
                        <KortexLabelIcon label={translate("processEditorSideMenu.versioning")}>
                            <HistoryIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    <Divider />
                    <ListItem
                        button={true}
                        id="processEditorSideMenuAddActionId"
                        onClick={handleMenuClick(PANE_TYPES.ACTION_SELECTOR)}
                        className={classes.listItem}
                        disabled={globalReadOnly}
                    >
                        <KortexLabelIcon label={translate("processEditorSideMenu.actions")}>
                            <ActionIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    <ListItem
                        button={true}
                        id="processEditorSideMenuManageVariablesId"
                        onClick={handleMenuClick(PANE_TYPES.PROCESS_VARIABLES)}
                        className={classes.listItem}
                        disabled={globalReadOnly}
                    >
                        <KortexLabelIcon label={translate("processEditorSideMenu.variables")}>
                            <VariablesIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    {/*<ListItem
                        button={true}
                        id="processEditorSideMenuManageTranslationsId"
                        onClick={handleOpenLocalization}
                        className={classes.listItem}
                    >
                        <KortexLabelIcon label={translate("processEditorSideMenu.translations")}>
                            <TranslateIcon />
                        </KortexLabelIcon>
                    </ListItem>*/}
                    <ListItem
                        button={true}
                        id="processEditorSideMenuValidateProcessId"
                        onClick={props.onValidateProcess}
                        className={classes.listItem}
                    >
                        <KortexLabelIcon label={translate("processEditorSideMenu.validate")}>
                            <DebugIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    <Divider />
                    <ListItem
                        button={true}
                        disabled={isRouting}
                        id="processEditorSideMenuLaunchDryRunProcessId"
                        onClick={handleDryRunProcess}
                        className={classes.listItem}
                    >
                        <KortexLabelIcon label={translate("processEditorSideMenu.dryRun")}>
                            <PreviewIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    <ListItem
                        button={true}
                        id="processEditorSideMenuLaunchTestRunProcessId"
                        onClick={handleTestProcess}
                        className={classes.listItem}
                        disabled={currentProcess?.archived || isRouting}
                    >
                        <KortexLabelIcon label={translate("processEditorSideMenu.testRun")}>
                            <TestRunIcon />
                        </KortexLabelIcon>
                    </ListItem>
                    <ListItem
                        button={true}
                        disabled={!isVersionReleased() || currentProcess?.archived}
                        id="processEditorSideMenuLaunchRunProcessId"
                        onClick={handlePlayProcess}
                        className={classes.listItem}
                    >
                        <KortexLabelIcon label={translate("processEditorSideMenu.run")}>
                            <PlayIcon />
                        </KortexLabelIcon>
                    </ListItem>
                </List>
            </Paper>

            {/* MENU CONTENT */}
            {currentProcess && (
                <>
                    <>
                        <Drawer anchor="right" open={changeLogsOpen} onClose={handleCloseChangeLogs}>
                            <ChangeLogs onClose={handleCloseChangeLogs} process={currentProcess} readOnly={globalReadOnly} />
                        </Drawer>
                        <ProcessInfo
                            open={selectedPane === PANE_TYPES.INFO}
                            onClose={handleCloseDialog}
                            userAccessLevel={processEditorSideMenuUserAccessLevel}
                            readOnly={readOnly}
                        />
                    </>
                    <ProcessApprovalEditor
                        readOnly={globalReadOnly}
                        open={selectedPane === PANE_TYPES.TASK_SETTINGS}
                        onClose={handleCloseDialog}
                        processId={currentProcess.processId}
                        treeNodeId={currentProcess.treeNodeId}
                    />
                    <ProcessVariableManager
                        fullscreen={true}
                        onClose={handleCloseDialog}
                        open={sidePanelOpen}
                        selectHidden={true}
                        userAccessLevel={processEditorSideMenuUserAccessLevel}
                    />
                </>
            )}
        </div>
    );
}
