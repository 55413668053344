import { KortexTextField } from "@aos/react-components";
import { IOrgSettingDbModel, ProcessApprovalType } from "@kortex/aos-common";
import { MenuItem } from "@material-ui/core";
import * as React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

import GlobalSettingElement from "./GlobalSettingElement";
import { globalSettingsStyles } from "./GlobalSettingsCommon";

interface IOwnProps {
    expanded: boolean;
    organizationInfo: IOrgSettingDbModel;
    userCanEdit: boolean;

    onUpdate?: <Key extends keyof IOrgSettingDbModel>(setting: Key, value: IOrgSettingDbModel[Key]) => void;
    onCollapseChange?: (settingName: string, expanded: boolean) => void;
}

function GlobalSettingProcessReleases(props: IOwnProps): JSX.Element {
    const classes = globalSettingsStyles();
    const translate = useTranslate();

    const { organizationInfo, userCanEdit } = props;

    /**
     * Handle setting change
     *
     * @param {keyof IOrgSettingDbModel} setting - setting to be changed
     */
    const handleSettingChange =
        (setting: keyof IOrgSettingDbModel): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (props.onUpdate) {
                props.onUpdate(setting, event.target.value as string);
            }
        };

    /**
     * Handle panel collapse change
     *
     * @param {boolean} expanded - true or false, if expanded or not
     */
    const handleCollapseChange = (expanded: boolean): void => {
        if (props.onCollapseChange) {
            props.onCollapseChange("GlobalSettingProcessReleases", expanded);
        }
    };

    return (
        <GlobalSettingElement
            titleKey="settings.globalSettings.release.title"
            expanded={props.expanded}
            onCollapseChange={handleCollapseChange}
            id={"releaseSettingsId"}
        >
            <div>
                <KortexTextField
                    className={classes.textField}
                    label={translate("settings.globalSettings.approval.process")}
                    onChange={handleSettingChange("processRelease")}
                    TextFieldProps={{ select: true, disabled: !userCanEdit }}
                    value={organizationInfo.processRelease}
                >
                    <MenuItem value={ProcessApprovalType.AUTO}>{translate("settings.globalSettings.approval.automatic")}</MenuItem>
                    <MenuItem value={ProcessApprovalType.MANUAL}>{translate("settings.globalSettings.approval.manual")}</MenuItem>
                    <MenuItem value={ProcessApprovalType.ELECTRONIC_SIGNATURE}>
                        {translate("settings.globalSettings.approval.electronicSignature")}
                    </MenuItem>
                </KortexTextField>
                <KortexTextField
                    className={classes.textField}
                    label={translate("settings.globalSettings.release.routing")}
                    onChange={handleSettingChange("routingRelease")}
                    TextFieldProps={{ select: true, disabled: !userCanEdit }}
                    value={organizationInfo.routingRelease}
                >
                    <MenuItem value={ProcessApprovalType.AUTO}>{translate("settings.globalSettings.approval.automatic")}</MenuItem>
                    <MenuItem value={ProcessApprovalType.MANUAL}>{translate("settings.globalSettings.approval.manual")}</MenuItem>
                    <MenuItem value={ProcessApprovalType.ELECTRONIC_SIGNATURE}>
                        {translate("settings.globalSettings.approval.electronicSignature")}
                    </MenuItem>
                </KortexTextField>
                <KortexTextField
                    TextFieldProps={{ multiline: true, rows: 8, className: classes.textAreaInner, disabled: !userCanEdit }}
                    label={translate("settings.globalSettings.release.contextText")}
                    className={classes.textArea}
                    value={organizationInfo.releaseContextText}
                    onChange={handleSettingChange("releaseContextText")}
                />
            </div>
        </GlobalSettingElement>
    );
}

export default GlobalSettingProcessReleases;
