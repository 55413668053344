export class ConditionState {
    constructor() {
        this.condition = "";
        this.interpolatedCondition = "";
    }
    toJSON() {
        return {
            condition: this.condition,
            interpolatedCondition: this.interpolatedCondition,
            result: this.result,
        };
    }
}
