import { SequencerStatusEnum } from "../enums";
export function isSequencerCompleted(status) {
    return status === SequencerStatusEnum.COMPLETED;
}
export function isSequencerFailed(status) {
    return status === SequencerStatusEnum.FAILED;
}
export function isSequencerIdle(status) {
    return status === SequencerStatusEnum.IDLE;
}
export function isSequencerPaused(status) {
    return status === SequencerStatusEnum.PAUSED;
}
export function isSequencerPlaying(status) {
    return status === SequencerStatusEnum.PLAYING;
}
export function isSequencerStopped(status) {
    return status === SequencerStatusEnum.STOPPED;
}
export function isSequencerStopping(status) {
    return status === SequencerStatusEnum.STOPPING;
}
export function isSequencerInactive(status) {
    return [SequencerStatusEnum.COMPLETED, SequencerStatusEnum.FAILED, SequencerStatusEnum.IDLE, SequencerStatusEnum.STOPPED].includes(status);
}
export function isSequencerInProgress(status) {
    return [SequencerStatusEnum.PAUSED, SequencerStatusEnum.PLAYING].includes(status);
}
