import { APIPayload } from "../../utilitites/kortex-client/client";
import { handleAPIError } from "../handleAPIError";
import { StandardThunk, AppState, StandardDispatch } from "../store";

import { userRoleUpdatedAction, userRoleInsertedAction } from "./users-actions";
import { UserRoleInsertedAction } from "./users-types";

export function userRoleInsert(insertedUserRole: UserRoleInsertedAction["value"]): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.userRoles
                .insert(insertedUserRole)()
                .then((insertedUserRole) => {
                    dispatch(userRoleInsertedAction(insertedUserRole));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}

export function userRoleUpdate(updatedUserRole: APIPayload<"userRoles", "update">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.userRoles
                .update(updatedUserRole)()
                .then((updatedUserRole) => {
                    dispatch(userRoleUpdatedAction([updatedUserRole]));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}
