export const DOUBLE_QUOTE = "\u0022";
// time conversions
export const MS_TO_SEC = 1 / 1000;
export const SEC_TO_MS = 1000;
export const MIN_TO_SEC = 60;
export const HOUR_TO_MIN = 60;
export const HOUR_TO_SEC = HOUR_TO_MIN * MIN_TO_SEC;
export const DAY_TO_HOUR = 24;
export const MIN_TO_MS = 60000;
export const HOUR_TO_MS = 3600000;
export const DAY_TO_MS = 86400000;
export const WEEK_TO_MS = 604800000;
export const MONTH_TO_MS = 2629746000;
export const YEAR_TO_MS = 31556952000;
// password expiration delay
export const PASSWORD_EXPIRATION_DELAY_IN_DAYS = 90;
export const DEFAULT_VERSION = "draft";
