import { KhronoEventEnum } from "../enums";
import { Khrono } from "../khrono";
import { deepInterpolatedClone } from "./deep-interpolated-clone";
export function interpolateActionConfig(config, variables) {
    const interpolatedConfig = deepInterpolatedClone(config, variables);
    Khrono.createEvent({
        payload: {
            config: interpolatedConfig,
            originalConfig: config,
        },
        type: KhronoEventEnum.ACTION_CONFIG_INTERPOLATION,
    });
    return interpolatedConfig;
}
