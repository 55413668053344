import { KortexDialogConfirmation, greyPalette, theme } from "@aos/react-components";
import {
    ITreeNodeDbModel,
    ITreeViewNodeView,
    ProcessApprovalStatusFilter,
    RepositoryEditorRightsEnum,
    StorageEditorsRightsEnum,
    TreeFileInsertType,
    TreeNodeModuleTypeEnum,
    TreeNodeNodeTypeEnum,
    TreeViewNodeView,
    listToTree,
    sortList,
    treeToList,
} from "@kortex/aos-common";
import { Button, Divider, IconButton, Menu, MenuItem, Typography, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { ListRowProps, List as VirtualList } from "react-virtualized";

import { useForeground } from "../../../hooks/useForeground";
import { useKeyPressed } from "../../../hooks/useKeyPressed";
import { useTranslate } from "../../../hooks/useTranslate";
import { IUserRightsProps, userCanArchive, userCanWrite } from "../../../utilitites/IUserRights";
import { uploadFile } from "../../../utilitites/uploadFile/uploadFile";
import { useSnackbar } from "../../layout/snackbarConfigurator";

import TagSelector from "./TagSelector";
import { TooltipEditor } from "./TooltipEditor";
import TreeViewNode from "./TreeViewNode";
import { BomAssignmentDialog } from "./bomAssignmentDialog";

export enum MultiSelectModeEnum {
    NONE = "N",
    INDIVIDUAL = "I",
    GROUP = "G",
}

const PANEL_DOTTED_PADDING = 25;
const TREEVIEW_ROW_HEIGHT = 48;
const DND_SCROLLZONE_HEIGHT = 100;
const DND_SCROLL_TICK = 2;

const useStyles = makeStyles({
    mainContainer: {
        height: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "16px",
    },
    hidden: {
        display: "none",
    },
    dragContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        margin: "10px",
        border: "3px dashed",
        borderColor: theme.palette.grey[500],
        borderRadius: "2px",
        backgroundColor: theme.palette.grey[100],
    },
    dragContainerClose: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
    },
    dragContainerInfo: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    dragInfo: {
        padding: "5px",
        color: theme.palette.primary.main,
        fontSize: "16px",
        fontWeight: "bolder",
    },
    dragInfoOr: {
        padding: "5px",
        color: theme.palette.grey[500],
        fontSize: "16px",
        fontWeight: "bolder",
    },
    emptyFolderLabel: {
        paddingTop: "50px",
        textAlign: "center",
        color: greyPalette[500],
    },
});

export const TreeViewVariantFolder = "folder";
export const TreeViewVariantItem = "item";
export const TreeViewVariantFile = "file";
export type TreeViewVariantFolder = typeof TreeViewVariantFolder;
export type TreeViewVariantItem = typeof TreeViewVariantItem;
export type TreeViewVariantFile = typeof TreeViewVariantFile;
export type TreeViewVariants = TreeViewVariantFolder | TreeViewVariantItem | TreeViewVariantFile;

export const AcceptAll = "all";
export const AcceptImage = "image";
export const AcceptPDF = "pdf";
export const AcceptVideo = "video";
export type AcceptAll = typeof AcceptAll;
export type AcceptImage = typeof AcceptImage;
export type AcceptPDF = typeof AcceptPDF;
export type AcceptVideo = typeof AcceptVideo;
export type AcceptedFileType = AcceptAll | AcceptImage | AcceptPDF | AcceptVideo;

interface INodeWithVersion {
    latest: boolean;
    nodeId: number;
    versionId: number;
}

export type NodeWithVersion = INodeWithVersion;

export interface IOwnProps<T extends ITreeNodeDbModel = ITreeNodeDbModel>
    extends IUserRightsProps<RepositoryEditorRightsEnum | StorageEditorsRightsEnum> {
    acceptedFileType?: AcceptedFileType;
    variant?: TreeViewVariants;
    nodeList: T[];
    preSelectedNode?: number;
    selectedNodes: ITreeNodeDbModel[];
    width: number;
    height: number;
    overscanRowCount: number;
    parentNode?: ITreeNodeDbModel;
    multiSelect?: boolean;
    // Not used, temporary disabled --  menuExtra?: { locale: string, callback: (nodeId?: string) => void }[];
    dragMode?: boolean;
    enableAttributeArchive?: boolean;
    showArchived?: boolean;
    showLatestVersion?: boolean;
    showProcessVersion?: ProcessApprovalStatusFilter;

    // Events
    onNodesSelected: (nodes: ITreeNodeDbModel[]) => void;
    onNodeVersionChange?: (nodeWithVersion: NodeWithVersion) => void;
    onInsertFile?: (treeNodeId: number, fileId: string, nodeType: TreeNodeNodeTypeEnum, fileName: string) => void;
    onUpdateNode?: (node: ITreeNodeDbModel) => void;
    onArchive?: (archived: boolean, nodeIds: number[]) => void;
    onCloseMenu?: () => void;
    onWhereUsed?: (treeNodeId: number) => void;
    onFailureType?: (treeNodeId: number) => void;
    onReworkItemStatus?: (treeNodeId: number) => void;
    onRootCause?: (treeNodeId: number) => void;
    onBomFollowUpSymptom?: (treeNodeId: number) => void;
    handleSelectProcessConfirmation?: () => void;
}

let scrollingInterval: NodeJS.Timer | undefined; // initialize timeout

enum ScrollingDirectionsEnum {
    NONE = 0,
    UP = 1,
    DOWN = 2,
}

export default function TreeView(props: IOwnProps): JSX.Element {
    const {
        acceptedFileType,
        dragMode,
        height,
        multiSelect,
        onCloseMenu,
        onInsertFile,
        onNodesSelected,
        onNodeVersionChange,
        onUpdateNode,
        onArchive,
        onWhereUsed,
        onFailureType,
        onReworkItemStatus,
        onRootCause,
        overscanRowCount,
        nodeList,
        parentNode,
        selectedNodes = [],
        showProcessVersion,
        showLatestVersion,
        userAccessLevel,
        variant,
        width,
        handleSelectProcessConfirmation,
    } = props;

    const classes = useStyles();
    const snackbar = useSnackbar();
    const translate = useTranslate();

    const [nodeListView, setNodeListView] = useState<ITreeViewNodeView[]>([]);
    const [nodeMenuAnchorEl, setNodeMenuAnchorEl] = useState<HTMLElement | undefined>(undefined);
    const [lastSelectedNode, setLastSelectedNode] = useState<ITreeNodeDbModel | undefined>(undefined);
    const [nodeBeingRenamed, setNodeBeingRenamed] = useState<ITreeNodeDbModel | undefined>(undefined);
    const [multiSelectMode, setMultiSelectMode] = useState<MultiSelectModeEnum>(MultiSelectModeEnum.NONE);
    const [editedNode, setEditedNode] = useState<ITreeNodeDbModel | undefined>(undefined);
    const [tooltipEditorOpen, setTooltipEditorOpen] = useState<boolean>(false);
    const [tagSelectorOpen, setTagSelectorOpen] = useState<boolean>(false);
    const [selectedTagEntryId, setSelectedTagEntryId] = useState(0);
    const [bomAssignmentDialogOpened, setBomAssignmentDialogOpened] = useState(false);

    const nodeListEl = useRef<VirtualList | null>(null);
    const inputNodeRef = useRef<HTMLInputElement | null>(null);

    const isForeground = useForeground();

    // Key binding
    const ctrlPressed = useKeyPressed(17, !isForeground);
    const shiftPressed = useKeyPressed(16, !isForeground);

    /**
     * Called when key binding changed for CTRL and SHIFT
     */
    useEffect((): void => {
        if (multiSelect) {
            setMultiSelectModeUsingKeys(ctrlPressed, shiftPressed);
        }
    }, [ctrlPressed, shiftPressed]);

    /**
     * Effect triggered when nodeList is modified
     */
    useEffect((): void => {
        setNodeBeingRenamed(undefined);
        refreshNodeListView();
    }, [nodeList]);

    /**
     * Effect triggered when selected folder is changed
     */
    useEffect((): void => {
        refreshNodeListView();
        setLastSelectedNode(selectedNodes[0] ? selectedNodes[0] : undefined);
    }, [props.selectedNodes]);

    /**
     * Effect triggered when pre selected folder is changed
     */
    useEffect((): void => {
        const folder = nodeList.find((node) => node.treeNodeId === props.preSelectedNode);
        if (folder) {
            onNodesSelected([folder]);
        }
    }, [props.preSelectedNode]);

    /**
     * Refresh Node List View when attribute changed
     */
    useEffect((): void => {
        refreshNodeListView();
    }, [props.showArchived]);

    /* @istanbul ignore else */
    const setMultiSelectModeUsingKeys = (ctrl: boolean, shift: boolean): void => {
        if (ctrl && !shift) {
            setMultiSelectMode(MultiSelectModeEnum.INDIVIDUAL);
        } else if (!ctrl && shift) {
            setMultiSelectMode(MultiSelectModeEnum.GROUP);
        } else if (!ctrl && !shift) {
            setMultiSelectMode(MultiSelectModeEnum.NONE);
        } else if (ctrl && shift) {
            setMultiSelectMode(MultiSelectModeEnum.INDIVIDUAL);
        }
    };

    /**
     * Get the corresponding string for the "accept" parameter of a file input
     */
    const getAcceptedFileType = (): string | undefined => {
        switch (acceptedFileType) {
            case "image":
                return "image/*";
            case "video":
                return "video/*";
            case "pdf":
                return ".pdf";
            case "all":
            default:
                return;
        }
    };

    /**
     * Handle drag over event for file drag and drop feature
     */
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
    };

    /**
     * Handle drop event for file drag and drop feature
     */
    const handleDrop = (event: React.DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();

        const dt: DataTransfer | null = event.dataTransfer;
        const files: FileList = dt.files;
        const targetNode = selectedNodes.length > 0 ? selectedNodes[0] : undefined;
        const dragContentString = event.dataTransfer.getData("text");
        let sourceNodes: ITreeNodeDbModel[] = [];
        if (dragContentString) {
            sourceNodes = JSON.parse(dragContentString);
        }
        if (variant === TreeViewVariantFile) {
            // If there is some files, it's because it's a drop of files
            if (files && files.length > 0) {
                if (onInsertFile && parentNode && parentNode.treeNodeId) {
                    uploadFilesFromList(parentNode.treeNodeId, files); // Dont know the parent, will be decided on the owner of the TreeView
                }
            }
        } else if (targetNode) {
            if (!files || targetNode.nodeType !== TreeNodeNodeTypeEnum.FOLDER) {
                if (variant === TreeViewVariantFolder) {
                    return;
                }
            }
            if (files && files.length) {
                if (variant === TreeViewVariantFolder) {
                    if (onInsertFile && targetNode.treeNodeId) {
                        uploadFilesFromList(targetNode.treeNodeId, files); // Dont know the parent, will be decided on the owner of the TreeView
                    }
                }
            } else if (sourceNodes.length > 0) {
                // It's probably a drop of folder or file
                let reject = false;

                // Reject if the same
                if (targetNode.treeNodeId === sourceNodes[0].treeNodeId) {
                    reject = true; // Target and source =
                }

                // Reject if the parent is already in the target hierarchy
                if (sourceNodes[0].nodeType === TreeNodeNodeTypeEnum.FOLDER) {
                    let verifiedTargetNode = targetNode;
                    while (reject === false && verifiedTargetNode.parentId && verifiedTargetNode.parentId !== undefined) {
                        if (verifiedTargetNode.parentId === sourceNodes[0].treeNodeId) {
                            reject = true;
                        } else {
                            const targetNodeView = nodeListView.filter(
                                (view): boolean => view.baseNode.treeNodeId === verifiedTargetNode.parentId
                            )[0];
                            verifiedTargetNode = targetNodeView.baseNode;
                        }
                    }
                }

                // Reject if dragged element and target already have the same parent
                if (sourceNodes[0].parentId === targetNode.parentId && targetNode.nodeType !== TreeNodeNodeTypeEnum.FOLDER) {
                    reject = true;
                }

                // If not rejected, request to change the parent
                if (reject === false) {
                    sourceNodes.forEach((elem): void => {
                        if (onUpdateNode && targetNode.treeNodeId) {
                            // if target node is a folder, it becomes the node's parent
                            if (targetNode.nodeType === TreeNodeNodeTypeEnum.FOLDER) {
                                elem.parentId = targetNode.treeNodeId;
                            } else {
                                // otherwise the targetNode's parent becomes the node's parent
                                elem.parentId = targetNode.parentId;
                            }
                            onUpdateNode(elem);
                        }
                    });
                }
            }
        }
    };

    /**
     * Upload a file to the storage server and store file information into database
     *
     * @param {number | undefined} parentId - ParentId of the file
     * @param {File} file - File blob (data)
     * @param {TreeFileInsertType} fileType - File type
     */
    const handleUploadFile = (parentId: number, file: File, fileType: TreeFileInsertType): void => {
        if (typeof fetch !== "undefined") {
            uploadFile(file, fileType)
                .then((fileId) => {
                    onInsertFile?.(parentId, fileId, fileType, file.name);
                    snackbar.info(translate("storage.success"));
                })
                .catch((err: Error) => {
                    snackbar.error(translate("storage.error.upload") + " " + translate(err.message));
                });
        } else {
            // **TEST MODE** - In test mode, in jest, fetch is not defined.. So simulate it
            onInsertFile?.(12, "testFileId", TreeNodeNodeTypeEnum.IMAGE, "testFileName");
        }
    };

    /**
     * Function that uploads the files on the server.
     *
     * @param {number | undefined} parentId - Parent Node Id
     * @param {FileList} files - Files list (object)
     */
    function uploadFilesFromList(parentId: number, files: FileList): void {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < files.length; i++) {
            const file: File = files[i];
            switch (file.type) {
                case "video/mp4":
                    handleUploadFile(parentId, file, TreeFileInsertType.VIDEO);
                    break;
                case "image/png":
                case "image/jpeg":
                case "image/gif":
                    handleUploadFile(parentId, file, TreeFileInsertType.IMAGE);
                    break;
                case "application/pdf":
                    handleUploadFile(parentId, file, TreeFileInsertType.PDF);
                    break;
                case "text/plain":
                    handleUploadFile(parentId, file, TreeFileInsertType.TEXT);
                    break;
                case "image/svg+xml":
                    handleUploadFile(parentId, file, TreeFileInsertType.SVG);
                    break;
                default:
                    snackbar.error("Unsupported file type"); // FIXME: Add locale
                    break;
            }
        }
    }

    /**
     * Refresh file list views based on selected folder
     */
    const refreshNodeListView = (ignoreSelectedNodes = false): void => {
        const newNodeList: ITreeViewNodeView[] = [];

        for (const node of nodeList) {
            const existingNodeView = nodeListView.filter((view): boolean => view.baseNode.treeNodeId === node.treeNodeId)[0];
            if (existingNodeView) {
                existingNodeView.baseNode = node;
                newNodeList.push(existingNodeView);
            } else {
                newNodeList.push(new TreeViewNodeView(node));
            }
        }

        // recursively expand all the parents of the selected node
        if (variant === TreeViewVariantFolder && selectedNodes[0] && !ignoreSelectedNodes) {
            let targetNodeToExpand = newNodeList.find((node): boolean => node.baseNode.treeNodeId === selectedNodes[0].treeNodeId);

            while (targetNodeToExpand && targetNodeToExpand.baseNode.parentId && targetNodeToExpand.baseNode.parentId !== undefined) {
                targetNodeToExpand.isExpanded = true;
                const targetNodeParentId = targetNodeToExpand.baseNode.parentId;
                targetNodeToExpand = newNodeList.find((view): boolean => {
                    return view.baseNode.treeNodeId === targetNodeParentId;
                });
            }
        }

        // Apply attributes filter
        let attributeFilteredList;

        if (props.enableAttributeArchive) {
            attributeFilteredList = newNodeList.filter(
                (view): boolean => view.baseNode.archived === false || view.baseNode.archived === props.showArchived
            );
        } else {
            attributeFilteredList = newNodeList;
        }

        if (variant === TreeViewVariantFolder) {
            const treeList = listToTree(attributeFilteredList);
            const treeFlatList = treeToList(treeList).filter((view): boolean => view.baseNode.nodeType === TreeNodeNodeTypeEnum.FOLDER);
            setNodeListView(treeFlatList);
        } else {
            setNodeListView(attributeFilteredList.sort(sortList));
        }
    };

    /**
     * Handles row menu click to make file/folder menu appear
     *
     * @param {ITreeNodeDbModel} node - Node selected
     */
    const handleShowMenu = (node: ITreeNodeDbModel, event: React.MouseEvent<HTMLElement>): void => {
        setEditedNode(node);
        setNodeMenuAnchorEl(event.currentTarget);
        event.stopPropagation(); // Prevent handleSelectFileRow to be trigged
    };

    /**
     * Close the three-dots menu
     */
    const handleMenuClose = (): void => {
        setNodeMenuAnchorEl(undefined);
    };

    /**
     * Handles a drag and drog start from a node item
     *
     * @param {number} nodeId - Folder or file id of the node that the drag start with
     */
    const handleDragSourceStart = (nodeId: number): ITreeNodeDbModel[] => {
        const foundNode = selectedNodes.find((node): boolean => node.treeNodeId === nodeId);

        if (!foundNode) {
            const nodeArray: ITreeNodeDbModel[] = [];
            const newSelNode = nodeList.find((node): boolean => node.treeNodeId === nodeId);
            if (newSelNode) {
                nodeArray.push(newSelNode);
                onNodesSelected(nodeArray);
            }
            return nodeArray;
        } else {
            return selectedNodes;
        }
    };

    /**
     * Helper that find if a node is selected by ID
     *
     * @param {number} nodeId - contains the node id of the file selected
     */
    const findSelectedNode = (nodeId?: number): ITreeNodeDbModel | undefined => {
        return selectedNodes.find((node): boolean => node.treeNodeId === nodeId);
    };

    /**
     * Drag event when the user starts a drag selection
     *
     * @param {number} nodeId - contains the node id of the file selected
     */
    const handleDragSelected = (nodeId: number): void => {
        const node = nodeList.find((node): boolean => node.treeNodeId === nodeId);
        if (node) {
            onNodesSelected([node]);
        }
    };

    /**
     * Handles row menu select of a folder
     *
     * @param {number} nodeId - contains the node id of the file selected
     */
    const handleSelected = (nodeId: number): void => {
        const node = nodeList.find((node): boolean => node.treeNodeId === nodeId);
        const selectedElem = findSelectedNode(nodeId);

        if (node && selectedNodes.length > 0 && multiSelectMode === MultiSelectModeEnum.NONE && selectedElem) {
            onNodesSelected([node]); // Replace/Add it
        } else if (node) {
            if (multiSelectMode === MultiSelectModeEnum.NONE) {
                // *** SINGLE SELECT
                onNodesSelected([node]); // Replace/Add it
            } else if (multiSelectMode === MultiSelectModeEnum.INDIVIDUAL) {
                // *** MULTI INDIVIDUAL SELECT

                // Check if in the list
                if (!selectedElem) {
                    onNodesSelected([...selectedNodes, node]); // Add it
                } else {
                    const index = selectedNodes.indexOf(selectedElem);
                    if (index !== -1) {
                        // Remove it
                        const res = [...selectedNodes];
                        res.splice(index, 1);
                        onNodesSelected(res);
                    }
                }
            } else if (multiSelectMode === MultiSelectModeEnum.GROUP) {
                // *** MULTI GROUP SELECT
                // Check if the click happen after having already a selection, to fill up the group
                if (lastSelectedNode) {
                    const nodeStart = nodeListView.find((view): boolean => view.baseNode.treeNodeId === lastSelectedNode.treeNodeId);
                    const nodeEnd = nodeListView.find((view): boolean => view.baseNode.treeNodeId === node.treeNodeId);
                    // Find start & end of the group

                    if (nodeStart && nodeEnd) {
                        const indexStart = nodeListView.indexOf(nodeStart);
                        const indexEnd = nodeListView.indexOf(nodeEnd);

                        if (indexStart >= 0 && nodeStart) {
                            const nodeArray: ITreeNodeDbModel[] = [];
                            // One way
                            if (indexStart <= indexEnd) {
                                for (let count = indexStart + 1; count <= indexEnd; count++) {
                                    const foundNode = nodeList.find(
                                        (node): boolean => node.treeNodeId === nodeListView[count].baseNode.treeNodeId
                                    );

                                    if (foundNode) {
                                        nodeArray.push(foundNode);
                                    }
                                }
                            } else {
                                // Or the other
                                for (let count = indexEnd; count < indexStart; count++) {
                                    const foundNode = nodeList.find(
                                        (node): boolean => node.treeNodeId === nodeListView[count].baseNode.treeNodeId
                                    );

                                    if (foundNode) {
                                        nodeArray.push(foundNode);
                                    }
                                }
                            }
                            onNodesSelected([...selectedNodes, ...nodeArray]); // Add the group to the list
                        }
                    }
                } else {
                    onNodesSelected([node]); // Add it from the last selected
                }
            }

            // Keep a copy of the last selected file
            setLastSelectedNode(node);
        }
    };

    /**
     * Handles the selection of a version
     */
    const handleVersionSelected =
        (nodeId?: number): ((versionId: number, latest: boolean) => void) =>
        (versionId: number, latest: boolean): void => {
            if (onNodeVersionChange && nodeId) {
                onNodeVersionChange({
                    latest,
                    nodeId,
                    versionId,
                });
            }
        };

    /**
     * Toggle Handler, to called to toggle expand or Collaps the current folder (from widget)
     *
     * @param {number} nodeId - string containing the node id of the folder
     */
    const handleToggleExpand = (nodeId: number): void => {
        // Expand or Collapse the current folder based on id
        const expandedNode = nodeListView.find((view): boolean => view.baseNode.treeNodeId === nodeId);

        if (expandedNode) {
            expandedNode.isExpanded = !expandedNode.isExpanded;
        }

        refreshNodeListView(true);
    };

    /**
     * Handles request to rename a file via the menu
     */
    const handleRenameFile = (): void => {
        handleMenuClose();
        setNodeBeingRenamed(editedNode);
    };

    /**
     * Handler called when the context menu file insert is clicked
     * Remaining of the process is done in handleInsertFileAfterInput after user select files
     *
     */
    const handleInsertFile = (): void => {
        if (inputNodeRef.current) {
            inputNodeRef.current.click(); // Open the file picker using the hidden input
        }
    };

    /**
     * Callback after a user selects his file in the input file browser
     */
    const handleInsertFileAfterInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            if (onInsertFile && parentNode) {
                uploadFilesFromList(parentNode.treeNodeId || 0, event.target.files); // Dont know the parent, will be decided on the owner of the TreeView
            }
        }
    };

    /**
     * Handles request to change the name of a folder or a name, from the item itself
     *
     * @param {number} newName - id of the node that that name must be changed
     * @param {string} elemId - new name to apply
     */
    const handleNameChange = (newName: string, elemId?: number): void => {
        const foundFolderFile = nodeListView.find((view): boolean => view.baseNode.treeNodeId === elemId);
        if (foundFolderFile) {
            foundFolderFile.baseNode.label = newName;
            if (onUpdateNode) {
                onUpdateNode(foundFolderFile.baseNode);
            }
            handleSelected(foundFolderFile.baseNode.treeNodeId);
        }
    };

    /**
     * Called by the JSX to render a new folder row
     *
     * @param {ListRowProps} rowProps - to be rendered
     * @returns {object} - TreeViewNode to render
     */
    const renderRow = (rowProps: ListRowProps): JSX.Element => {
        const { index, key, style } = rowProps;
        const nodeView = nodeListView[index];
        const isSelected = findSelectedNode(nodeView.baseNode.treeNodeId) !== undefined;
        const enableRename = nodeBeingRenamed ? nodeView.baseNode.treeNodeId === nodeBeingRenamed.treeNodeId : false;

        return (
            <TreeViewNode
                style={style}
                key={key}
                isSelected={isSelected}
                enableRename={enableRename}
                node={nodeView}
                onToggleExpand={handleToggleExpand}
                onSelected={handleSelected}
                onVersionChange={handleVersionSelected(nodeView.baseNode.treeNodeId)}
                onDragSourceStart={handleDragSourceStart}
                onDragSelected={handleDragSelected}
                onShowMenu={handleShowMenu}
                onNameChange={handleNameChange}
                userAccessLevel={userAccessLevel}
                showProcessVersion={showProcessVersion}
                showLatestVersion={showLatestVersion}
                handleSelectProcessConfirmation={handleSelectProcessConfirmation}
            />
        );
    };

    /**
     * Called when user close the panel using the X on the top right
     */
    const handleInsertFilePanelClose = (): void => {
        if (onCloseMenu) {
            onCloseMenu();
        }
    };

    /**
     * Called when dragging an element over the list.
     * Used to scroll when hovering in the top or bottom sections
     */
    const handleListDragOver = (e: React.MouseEvent): void => {
        if (e.currentTarget) {
            const containerProps = e.currentTarget.getBoundingClientRect();
            const listElem = e.currentTarget.querySelector("#listId");
            if (!listElem) {
                return;
            }

            const inTopZone = e.clientY - containerProps.top < DND_SCROLLZONE_HEIGHT;
            const inBottomZone = e.clientY - containerProps.top > containerProps.height - DND_SCROLLZONE_HEIGHT;

            // set scrollingInterval based on where the drag is
            if (inTopZone && !scrollingInterval) {
                scrollingInterval = setInterval((): void => scroll(ScrollingDirectionsEnum.UP, listElem));
            } else if (inBottomZone && !scrollingInterval) {
                scrollingInterval = setInterval((): void => scroll(ScrollingDirectionsEnum.DOWN, listElem));
            } else if (!inTopZone && !inBottomZone) {
                stopScrolling();
            }
        }
    };

    /**
     * Scrolls the list based on the direction
     *
     * @param {ScrollingDirectionsEnum} direction - direction in which to scroll (top or bottom)
     */
    const scroll = (direction: ScrollingDirectionsEnum, elem: Element): void => {
        const tick = direction === ScrollingDirectionsEnum.UP ? -DND_SCROLL_TICK : DND_SCROLL_TICK;
        if (nodeListEl.current) {
            elem.scrollBy(0, tick);
        }
    };

    /**
     * Stops scroll interval when cursor leaves list area
     */
    const stopScrolling = (): void => {
        clearInterval(scrollingInterval);
        scrollingInterval = undefined;
    };

    /**
     * Set archive node of the node and all the childs
     */
    const setArchiveNode = (node: ITreeNodeDbModel, isArchived: boolean): void => {
        if (onUpdateNode && onArchive) {
            const updatedNodesList = buildNodeTree(node);
            onArchive(isArchived, updatedNodesList);
        }
    };

    /**
     * Buid list of tree ids from the received node (maybe a item or a parent)
     *
     * @param {ITreeNodeDbModel} node - Node to get the tree of ids from parent to bottom
     */
    const buildNodeTree = (node: ITreeNodeDbModel): number[] => {
        const nodeIdsList: number[] = [];

        recursiveBuildNodeTree(nodeIdsList, node);

        return nodeIdsList;
    };

    /**
     * Buid list of tree ids from the received node (maybe a item or a parent)
     * This must be used with buildNodeTree and is the recursive portion of the call
     *
     * @param {number[]} nodeIdsList - List of current node of the tree.  New node will be pushed in that list
     * @param {ITreeNodeDbModel} node - Node to get the tree of ids from parent to bottom
     */
    const recursiveBuildNodeTree = (nodeIdsList: number[], node: ITreeNodeDbModel): void => {
        if (node.treeNodeId) {
            nodeIdsList.push(node.treeNodeId);
        }

        if (node.nodeType === TreeNodeNodeTypeEnum.FOLDER) {
            const childOfNodeList = nodeList.filter((nodeInList): boolean => nodeInList.parentId === node.treeNodeId);
            for (const childNode of childOfNodeList) {
                //  WARNING: RECURSIVE CALL
                recursiveBuildNodeTree(nodeIdsList, childNode);
            }
        }
    };

    /**
     * Handles request to archive (or not) a file or a folder via the menu
     */
    const handleArchiveFile = (): void => {
        handleMenuClose();

        if (editedNode) {
            if (editedNode.nodeType === TreeNodeNodeTypeEnum.FOLDER) {
                setArchiveNode(editedNode, !editedNode.archived);
            } else {
                setArchiveNode(editedNode, !editedNode.archived);
            }
        }
    };

    /**
     * Handle where used request, simply call the props callback (handle by the parent)
     */
    const handleWhereUsed = (): void => {
        if (editedNode && editedNode.treeNodeId && onWhereUsed) {
            onWhereUsed(editedNode.treeNodeId);
        }

        handleMenuClose();
    };

    /**
     * Handle open failure type dialog
     */
    const handleFailureType = (): void => {
        if (editedNode && editedNode.treeNodeId && onFailureType) {
            onFailureType(editedNode.treeNodeId);
        }

        handleMenuClose();
    };

    /**
     * Handle open rework item status dialog
     */
    const handleReworkItemStatus = (): void => {
        if (editedNode && editedNode.treeNodeId && onReworkItemStatus) {
            onReworkItemStatus(editedNode.treeNodeId);
        }

        handleMenuClose();
    };

    /**
     * Handle open root cause dialog
     */
    const handleRootCause = (): void => {
        if (editedNode && editedNode.treeNodeId && onRootCause) {
            onRootCause(editedNode.treeNodeId);
        }

        handleMenuClose();
    };

    /**
     * TODO: AOS-2252
     * Handle open bom follow-up symptom dialog
     */
    /*const handleBomFollowUpSymptom = (): void => {
        if (editedNode && editedNode.treeNodeId && onBomFollowUpSymptom) {
            onBomFollowUpSymptom(editedNode.treeNodeId);
        }

        handleMenuClose();
    };*/

    /**
     * Handle open tooltip dialog
     */
    const handleOpenTooltipEditor =
        (open: boolean): (() => void) =>
        (): void => {
            handleMenuClose();
            setTooltipEditorOpen(open);
        };

    /**
     * Handle opening of tag selector dialog
     */
    const handleAssignTag = (): void => {
        handleMenuClose();
        setTagSelectorOpen(true);
    };

    const handleOpenBomAssignmentDialog =
        (opened: boolean): (() => void) =>
        (): void => {
            setBomAssignmentDialogOpened((): boolean => {
                if (opened) handleMenuClose();

                return opened;
            });
        };

    /**
     * Handle tag selection dialog confirmation event
     * Save updated node to database
     */
    const handleConfirmTagSelection = (): void => {
        setTagSelectorOpen(false);
        if (editedNode && onUpdateNode) {
            onUpdateNode({ ...editedNode, reportTagEntryId: selectedTagEntryId });
        }
    };

    /**
     * Handle tag selection dialog cancel event
     */
    const handleCancelTagSelection = (): void => {
        setTagSelectorOpen(false);
    };

    /**
     * Handle tag entry id selection changed from tag selector
     *
     * @param {number} reportTagEntryId - Selected tag entry id
     */
    const handleSelectedTagEntryIdChanged = (reportTagEntryId: number): void => {
        setSelectedTagEntryId(reportTagEntryId);
    };

    return (
        <div
            id="treeViewId"
            className={classes.mainContainer}
            onDrop={userCanWrite(userAccessLevel) ? handleDrop : undefined}
            onDragOver={userCanWrite(userAccessLevel) ? handleDragOver : undefined}
        >
            {variant === TreeViewVariantFolder || variant === TreeViewVariantItem || dragMode === false ? (
                // Label displayed when the folder is empty
                nodeListView.length === 0 ? (
                    <div className={classes.emptyFolderLabel} style={{ width: width }}>
                        {translate("treeview.emptyFolder")}
                    </div>
                ) : (
                    // Node list
                    <div
                        style={{ position: "relative" }}
                        onDragOver={handleListDragOver}
                        onDragLeave={stopScrolling}
                        onDragEnd={stopScrolling}
                        onDrop={stopScrolling}
                    >
                        <VirtualList
                            id="listId"
                            ref={nodeListEl}
                            height={height}
                            width={width}
                            overscanRowCount={overscanRowCount}
                            rowCount={nodeListView.length}
                            rowHeight={TREEVIEW_ROW_HEIGHT}
                            rowRenderer={renderRow}
                        />
                        <Menu id="threeDotsMenuId" anchorEl={nodeMenuAnchorEl} open={Boolean(nodeMenuAnchorEl)} onClose={handleMenuClose}>
                            <MenuItem id="optionMenuRenameId" onClick={handleRenameFile}>
                                {translate("treeview.rename")}
                            </MenuItem>
                            {props.enableAttributeArchive &&
                                lastSelectedNode &&
                                lastSelectedNode.parentId != 0 &&
                                userCanArchive(userAccessLevel) && (
                                    <MenuItem id="optionMenuArchiveId" onClick={handleArchiveFile}>
                                        {editedNode && editedNode.archived ? translate("treeview.active") : translate("treeview.archive")}
                                    </MenuItem>
                                )}
                            {editedNode && onWhereUsed && (
                                <MenuItem id="optionMenuWhereUsed" onClick={handleWhereUsed}>
                                    {translate("treeview.menu.whereUsed")}
                                </MenuItem>
                            )}
                            {editedNode &&
                                editedNode.moduleType === TreeNodeModuleTypeEnum.PROCESS &&
                                (editedNode.nodeType === TreeNodeNodeTypeEnum.PROCESS ||
                                    editedNode.nodeType === TreeNodeNodeTypeEnum.FOLDER) && (
                                    <>
                                        <Divider />
                                        <MenuItem id="optionMenuAssignBom" onClick={handleOpenBomAssignmentDialog(true)}>
                                            {translate("treeview.menu.assignBom")}
                                        </MenuItem>
                                        {/*
                                            TODO: AOS-2252
                                            <MenuItem id="optionMenuBomFollowUpSymptom" onClick={handleBomFollowUpSymptom}>
                                                {translate("treeview.menu.bomFollowUpSymptom")}
                                            </MenuItem>
                                        */}
                                    </>
                                )}
                            {editedNode &&
                                (editedNode.nodeType === TreeNodeNodeTypeEnum.PROCESS ||
                                    editedNode.nodeType === TreeNodeNodeTypeEnum.ROUTING) && (
                                    <>
                                        <Divider />
                                        <MenuItem id="optionMenuAssignTag" onClick={handleAssignTag}>
                                            {translate("treeview.menu.assignTag")}
                                        </MenuItem>
                                    </>
                                )}
                            {editedNode && onFailureType && (
                                <>
                                    <Divider />
                                    <MenuItem id="optionMenuFailureType" onClick={handleFailureType}>
                                        {translate("treeview.menu.failureType")}
                                    </MenuItem>
                                </>
                            )}
                            {editedNode && onReworkItemStatus && (
                                <MenuItem id="optionMenuReworkItemStatus" onClick={handleReworkItemStatus}>
                                    {translate("treeview.menu.reworkItemStatus")}
                                </MenuItem>
                            )}
                            {editedNode && onRootCause && (
                                <MenuItem id="optionMenuRootCause" onClick={handleRootCause}>
                                    {translate("treeview.menu.rootCause")}
                                </MenuItem>
                            )}
                            {editedNode &&
                                editedNode.moduleType === TreeNodeModuleTypeEnum.FILE_STORAGE &&
                                editedNode.nodeType === TreeNodeNodeTypeEnum.IMAGE && (
                                    <>
                                        <Divider />
                                        <MenuItem id="optionMenuEditTooltip" onClick={handleOpenTooltipEditor(true)}>
                                            {translate("treeview.menu.editTooltip")}
                                        </MenuItem>
                                    </>
                                )}
                        </Menu>
                    </div>
                )
            ) : (
                <div
                    id="insertFilePanelId"
                    className={classes.dragContainer}
                    style={{ height: height - PANEL_DOTTED_PADDING, width: width - PANEL_DOTTED_PADDING }}
                >
                    {nodeListView.length > 0 && (
                        <div className={classes.dragContainerClose}>
                            <IconButton id="insertFilePanelCloseButtonId" onClick={handleInsertFilePanelClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    )}
                    <div className={classes.dragContainerInfo}>
                        <span className={classes.dragInfo}>
                            <Typography>{translate("treeview.dropFileToUpload")}</Typography>
                        </span>
                        <span className={classes.dragInfoOr}>
                            <Typography>{translate("treeview.or")}</Typography>
                        </span>
                        <Button
                            disabled={!userCanWrite(userAccessLevel)}
                            id="insertFileButtonId"
                            onClick={handleInsertFile}
                            variant="contained"
                            color="secondary"
                        >
                            <Typography>{translate("treeview.selectFile")}</Typography>
                        </Button>
                    </div>
                </div>
            )}
            <input
                ref={inputNodeRef}
                id="inputFileId"
                accept={getAcceptedFileType()}
                type="file"
                multiple={true}
                className={classes.hidden}
                onChange={handleInsertFileAfterInput}
            />

            <KortexDialogConfirmation
                open={tagSelectorOpen}
                dialogProps={{
                    id: "tagSelectorDialogId",
                }}
                textLabels={{
                    titleLabel: translate("treeview.tagSelectorHeader"),
                    cancelButtonLabel: translate("treeview.cascadeArchiveDialogCancel"),
                    proceedButtonLabel: translate("treeview.cascadeArchiveDialogProceed"),
                }}
                onCancel={handleCancelTagSelection}
                onConfirm={handleConfirmTagSelection}
                closeOnEscape={true}
            >
                <TagSelector
                    selectedTagEntryId={editedNode && editedNode.reportTagEntryId}
                    onSelectedTagEntryIdChanged={handleSelectedTagEntryIdChanged}
                />
            </KortexDialogConfirmation>

            {editedNode && tooltipEditorOpen && (
                <TooltipEditor
                    onClose={handleOpenTooltipEditor(false)}
                    open={tooltipEditorOpen}
                    treeNode={editedNode}
                    userAccessLevel={userAccessLevel}
                />
            )}

            {editedNode && (
                <BomAssignmentDialog
                    onClose={handleOpenBomAssignmentDialog(false)}
                    open={bomAssignmentDialogOpened}
                    treeNode={editedNode}
                />
            )}
        </div>
    );
}
