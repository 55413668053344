export var FailureCodeEnum;
(function (FailureCodeEnum) {
    FailureCodeEnum[FailureCodeEnum["ACTION_COMPLETION_FAIL"] = 1] = "ACTION_COMPLETION_FAIL";
    FailureCodeEnum[FailureCodeEnum["CONFIGURATION_ERROR"] = 2] = "CONFIGURATION_ERROR";
    FailureCodeEnum[FailureCodeEnum["CRITICAL"] = 3] = "CRITICAL";
    FailureCodeEnum[FailureCodeEnum["IMPLEMENTER_HOOK_ERROR"] = 4] = "IMPLEMENTER_HOOK_ERROR";
    FailureCodeEnum[FailureCodeEnum["FLOW_ERROR"] = 5] = "FLOW_ERROR";
    FailureCodeEnum[FailureCodeEnum["METHOD_NOT_IMPLEMENTED"] = 6] = "METHOD_NOT_IMPLEMENTED";
    FailureCodeEnum[FailureCodeEnum["REMOTE_ACTION_FAILURE"] = 7] = "REMOTE_ACTION_FAILURE";
    FailureCodeEnum[FailureCodeEnum["SET_STATE_ERROR"] = 8] = "SET_STATE_ERROR";
    FailureCodeEnum[FailureCodeEnum["VALIDATION_ERROR"] = 9] = "VALIDATION_ERROR";
    FailureCodeEnum[FailureCodeEnum["STOP"] = 10] = "STOP";
    FailureCodeEnum[FailureCodeEnum["VARIABLE_MANAGER_ERROR"] = 11] = "VARIABLE_MANAGER_ERROR";
})(FailureCodeEnum || (FailureCodeEnum = {}));
