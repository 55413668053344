import { ConnectorState } from "@kortex/aos-common";
import { theme } from "@aos/react-components";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useEffect, useRef } from "react";
import { AutoSizer } from "react-virtualized";

const useStyles = makeStyles({
    connectorConsole: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        height: "90%",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "16px",
        width: "90%",
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
});

export interface IOwnProps {
    actionState: ConnectorState;
}

export default function ConnectorPlayer(props: IOwnProps): JSX.Element {
    const { actionState } = props;

    const classes = useStyles();
    const connectorConsole = useRef<HTMLDivElement | null>(null);

    /**
     * Auto scroll console
     */
    useEffect((): void => {
        if (connectorConsole && connectorConsole.current) {
            connectorConsole.current.scrollTop = connectorConsole.current.scrollHeight;
        }
    }, [actionState]);

    return (
        <div className={classes.connectorConsole} ref={connectorConsole}>
            <AutoSizer>
                {({ height, width }): JSX.Element => (
                    <div style={{ height, width }}>
                        <span>{actionState.output}</span>
                    </div>
                )}
            </AutoSizer>
        </div>
    );
}
