import {
    ConditionState,
    ConnectorState,
    DataStoreState,
    EnumActionStatus,
    EnumProcessStatus,
    EnumProgressRunSelection,
    FailureTicketForkState,
    IInputState,
    ILoopState,
    IOutputState,
    IPlayerActionBaseState,
    IPlayerReworkInfo,
    IPlayerUIActionProps,
    IStepFailure,
    IWorkInstructionsState,
    MathState,
    MessageState,
    ParserState,
    ProcessActionId,
    ProcessActionStepInput,
    ProcessActionStepLoop,
    ProcessActionStepWorkInstructions,
    TimeState,
    UserId,
    convertProcessToClientProcessState,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { deepClone } from "@kortex/utilities";
import { Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Debug from "debug";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import { useEntitiesTreeProcess } from "../../../redux/effects";
import {
    playerEnablePlayNext,
    playerInitState,
    playerUpdateUiActionProps,
    processPlayerFailStep,
    processPlayerGetHistory,
    processPlayerInsertRework,
    processPlayerPause,
    processPlayerPlay,
    processPlayerResume,
    processPlayerRetry,
    processPlayerStop,
    processPlayerUpdateProcessState,
} from "../../../redux/player-manager/player-thunks-player";
import { IPlayerState } from "../../../redux/player-manager/player-types";
import { processGet } from "../../../redux/process-manager/process-thunks-process";
import { useSelectorProcesses } from "../../../redux/selectors";
import SchedulerOperatorJobProcessCard from "../../pages/SchedulerOperator/SchedulerOperatorJobProcessCard";
import { TrainerValidationDialog } from "../TrainerValidationDialog";
import { TrainingCompletionDialog } from "../TrainingCompletionDialog";

import ConditionPlayer from "./ActionPlayers/ConditionPlayer";
import ConnectorPlayer from "./ActionPlayers/ConnectorPlayer";
import DataStorePlayer from "./ActionPlayers/DataStorePlayer";
import FailPathPlayer from "./ActionPlayers/FailPathPlayer";
import FailureTicketForkPlayer from "./ActionPlayers/FailureTicketForkPlayer";
import InputPlayer from "./ActionPlayers/InputPlayer";
import LoopPlayer from "./ActionPlayers/LoopPlayer";
import MathPlayer from "./ActionPlayers/MathPlayer";
import MessagePlayer from "./ActionPlayers/MessagePlayer";
import ParserPlayer from "./ActionPlayers/ParserPlayer";
import SetPlayer from "./ActionPlayers/SetPlayer";
import TimePlayer from "./ActionPlayers/TimePlayer";
import { WorkInstructionsPlayer } from "./ActionPlayers/WorkInstructionsPlayer";
import PlayerStepFailedDialog from "./Dialogs/FailedDialog/PlayerStepFailedDialog";
import MessageCircularProgressDialog from "./Dialogs/MessageCircularProgressDialog/MessageCircularProgressDialog";
import MessageDialog from "./Dialogs/MessageDialog/MessageDialog";
import ProcessBomDialog from "./Dialogs/ProcessBomDialog/ProcessBomDialog";
import { ReworkLogDialog } from "./Dialogs/ReworkLogDialog/ReworkLogDialog";
import RunDetailsDialog from "./Dialogs/RunDetailsDialog/RunDetailsDialog";
import { RunnerHubFailureDialog } from "./Dialogs/RunnerHubFailureDialog";
import SuccessfulDialog from "./Dialogs/SuccessfulDialog/SuccessfulDialog";
import { canPlayProcessFromJobProcess } from "./PlayerCommon";
import { usePlayerContext } from "./context";
import { PlayerControls } from "./controls";
import { StepValidationDialog } from "./stepValidationDialog";

const debug = Debug("kortex:ui:player-page");
debug("Loaded");
Debug.enable("kortex:ui:player-page");

const useStyles = makeStyles({
    root: {
        height: "100%",
        display: "grid",
        gridTemplateRows: "auto 1fr",
    },
    mainContent: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        columnGap: "16px",
    },
    cardPreview: {
        height: "50px",
    },
    mediaContainer: {
        position: "relative",
    },
    actionUIContainer: {
        position: "relative",
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    processTitle: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "top",
        justifyContent: "center",
    },
    processInput: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "top",
        justifyContent: "center",
    },
});

const DEFAULT_HISTORY_INDEX = -1;
const DEFAULT_HISTORY_ACTION_BEFORE_LOADING: { actionId: ProcessActionId; stepIndex: number } = {
    actionId: -1,
    stepIndex: -1,
};

interface IOwnProps {
    // Own Props
    dryRunMode: boolean;
    isTestRun: boolean;
    processId: number;
    reworkId?: number;
    runWithoutJobProcess?: boolean;
    onClose: () => void;
}

function Player(props: IOwnProps): JSX.Element {
    const { dryRunMode, isTestRun, processId, reworkId } = props;

    const { jobProcessInfo } = usePlayerContext();

    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();
    const processes = useSelectorProcesses();
    const treeNodes = useEntitiesTreeProcess();

    const { playerState, process: processWithAction } = usePlayerContext();

    const nextButtonRef = useRef<HTMLButtonElement | null>(null);

    const [completedDialogOpened, setCompletedDialogOpened] = useState<boolean>(false);
    const [runAgain, setRunAgain] = useState<boolean>(false);
    const [checkValidationActionIsRequired, setCheckValidationActionIsRequired] = useState<boolean>(false);
    const [trainerValidationDialogOpen, setTrainerValidationDialogOpen] = useState<boolean>(false);
    const [trainingCompletionDialogOpen, setTrainingCompletionDialogOpen] = useState<boolean>(false);
    const [userValidationDialogOpen, setUserValidationDialogOpen] = useState<boolean>(false);

    const [failDialogOpened, setFailDialogOpened] = useState<boolean>(false);
    const [stepComment, setStepComment] = useState<string>("");
    const [messageDialogTitle, setMessageDialogTitle] = useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);
    const [processBomDialogOpen, setProcessBomDialogOpen] = useState<boolean>(false);
    const [validateBom, setValidateBom] = useState<boolean>(false);
    const [messageDialogAutoNext, setMessageDialogAutoNext] = useState<boolean>(false);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState<boolean>(false);
    const [submitPlayNext, setSubmitPlayNext] = useState<boolean>(false);

    const [currentPlayerState, setCurrentPlayerState] = useState<IPlayerState>(playerState); // the current player step (from history)
    const [playingHistory, setPlayingHistory] = useState<boolean>(false); // indicates if we are playing form the history
    const [playingHistoryIndex, setPlayingHistoryIndex] = useState<number>(DEFAULT_HISTORY_INDEX); // indicates which history index we are playing
    const [playingHistoryActionBeforeLoading, setPlayingHistoryActionBeforeLoading] = useState<{
        actionId: ProcessActionId;
        stepIndex: number;
    }>(DEFAULT_HISTORY_ACTION_BEFORE_LOADING); // indicates which history action was selected by user before the history is loaded
    const [reworkLogDialogOpen, setReworkLogDialogOpen] = useState<boolean>(false);
    const [reworkLogDone, setReworkLogDone] = useState<boolean>(false);
    const [openMessageCircularProgressDialog, setOpenMessageCircularProgressDialog] = useState<boolean>(false);

    const processName =
        (processWithAction && treeNodes && treeNodes.find((node) => node.treeNodeId === processWithAction.treeNodeId)?.label) || "";
    const trainingCommuniqueButtonEnabled =
        !playingHistory &&
        currentPlayerState.uiActionProps?.baseProps.type === "core-work-instructions" &&
        Boolean((currentPlayerState.uiActionProps.stepProps as ProcessActionStepWorkInstructions).config.trainingCommunique);

    /**
     * will be called on mount
     */
    useEffect((): void => {
        initTimelineState();
    }, [processWithAction]);

    /**
     * will be called when playerState Changed
     */
    useEffect((): void => {
        const curAction = getCurrentAction();

        if (checkValidationActionIsRequired) {
            // Check if a user is needed to validate the step
            const validateActionIndex = playerState.actionsState.findIndex(
                (actionState: IPlayerActionBaseState): boolean => actionState.baseStates.status === EnumActionStatus.VALIDATION_REQUIRED
            );

            setUserValidationDialogOpen(validateActionIndex > -1);

            // Check if we need a trainer validation
            setTrainerValidationDialogOpen(
                playerState.actionsState.findIndex(
                    (actionState) => actionState.baseStates.status === EnumActionStatus.TRAINER_SIGNATURE_REQUIRED
                ) > -1
            );

            // Check if we need a trainee validation
            setTrainingCompletionDialogOpen(
                playerState.actionsState.findIndex(
                    (actionState) => actionState.baseStates.status === EnumActionStatus.TRAINEE_SIGNATURE_REQUIRED
                ) > -1
            );
        }

        // set BOM dialog validation
        if (playerState.actionsState.findIndex((actionState) => actionState.baseStates.status === EnumActionStatus.VALIDATION_BOM) > -1) {
            setProcessBomDialogOpen(true);
            setValidateBom(true);
        } else {
            setProcessBomDialogOpen(false);
            setValidateBom(false);
        }

        // set rework log dialog
        if (
            playerState.processState.reworkId !== 0 &&
            curAction?.actionType === "core-output" &&
            (playerState?.uiActionProps?.stepState as IOutputState).reworkNewItemStatusId === undefined
        ) {
            setReworkLogDialogOpen(true);
        }

        // where not playing history! let's restore our current played state to the runner state
        if (!playingHistory) {
            setCurrentPlayerState(playerState);
        }
    }, [playerState]);

    /**
     * will be called when process is completed
     */
    useEffect((): void => {
        // set completed dialog
        if (currentPlayerState.processState.isCompleted && currentPlayerState.processState.status !== EnumProcessStatus.FAILED) {
            if (jobProcessInfo !== undefined) {
                const remainingProcess = jobProcessInfo.qty - jobProcessInfo.qtyPassed;
                if (runAgain && remainingProcess > 0) {
                    setOpenMessageCircularProgressDialog(false);
                    initTimelineState();
                    handleStartProcess();
                } else if (runAgain && remainingProcess > 0) {
                    // info loding
                    setOpenMessageCircularProgressDialog(true);
                } else {
                    if (!currentPlayerState.processState.inFailPath && !reworkLogDialogOpen) {
                        setCompletedDialogOpened(true);
                    }
                }
            }
        }
    }, [currentPlayerState.processState.isCompleted]);

    /**
     * will be called when jobProcessInfo changes
     */
    useEffect((): void => {
        if (jobProcessInfo === undefined) {
            setRunAgain(false);
        }
    }, [jobProcessInfo]);

    /**
     * Auto next rework log completed.
     */
    useEffect((): void => {
        if (reworkLogDone && !reworkLogDialogOpen) {
            handlePlayNext();
        }
    }, [reworkLogDialogOpen]);

    /**
     * Triggered when the player history is fetched.
     * If the player is in history mode, select the step from history that the user selected in the timeline.
     */
    useEffect(() => {
        if (playingHistory) {
            // Did the user clicked on the Rewind button?
            if (playingHistoryActionBeforeLoading.actionId === -1) {
                // Set index to the action that is before the one in progress
                setPlayingHistoryIndex(playerState.historyInfo.length - 1);
            } else {
                // User clicked on a step
                // Rewind to selected step
                setPlayingHistoryIndex(
                    playerState.historyInfo.findIndex(
                        (snapshot) =>
                            snapshot.processActionId === playingHistoryActionBeforeLoading.actionId &&
                            snapshot.stepIndex === playingHistoryActionBeforeLoading.stepIndex
                    ) ?? DEFAULT_HISTORY_INDEX
                );
            }
        }
    }, [playerState.historyInfo]);

    /**
     * gets the currently played action
     */
    const getCurrentAction = (): IPlayerActionBaseState | undefined => {
        return playerState.actionsState.find(
            (action: IPlayerActionBaseState): boolean =>
                action.baseStates.status === EnumActionStatus.PLAYING ||
                action.baseStates.status === EnumActionStatus.PAUSED ||
                action.baseStates.status === EnumActionStatus.TRAINER_SIGNATURE_REQUIRED ||
                action.baseStates.status === EnumActionStatus.WAITING_USER_NEXT
        );
    };

    /**
     * fetches the process
     */
    const initTimelineState = async (): Promise<void> => {
        setCompletedDialogOpened(false);
        if (processWithAction) {
            // Load Process Timeline State
            const clientProcessState = convertProcessToClientProcessState(processWithAction.actions);
            dispatch(playerInitState({ actionsState: clientProcessState }));
        }
    };

    /**
     * handles the start of a process
     */
    const handleStartProcess = (
        resultProcessHistoryIdSelected?: number,
        instancesSelected?: string,
        isResumeOfAnFailure?: boolean
    ): void => {
        debug("API Call: startRunnerProcess");
        debug(" Process Id    : ", processId);
        debug(" Job Process Id: ", jobProcessInfo ? jobProcessInfo.jobProcessId : 0);
        debug(" Dry Run Mode: ", dryRunMode);
        debug(" Test Run Mode: ", isTestRun);
        debug(" Rework Id: ", reworkId);
        debug(" Play from history: ", Boolean(resultProcessHistoryIdSelected));
        debug(" HistoryId selected: ", resultProcessHistoryIdSelected ? resultProcessHistoryIdSelected : undefined);
        debug(" Instances selected: ", instancesSelected ? instancesSelected : undefined);
        debug(" Resume history from failure : ", isResumeOfAnFailure ? isResumeOfAnFailure : false);

        setPlayingHistory(false);
        setPlayingHistoryIndex(DEFAULT_HISTORY_INDEX);

        // If process' latest version is required, get the tree node ID
        let treeNodeId;

        if (jobProcessInfo && jobProcessInfo.latestVersion) {
            treeNodeId = treeNodes.find(
                (node) => node.treeNodeId === processes.find((process) => process.processId === processId)?.treeNodeId
            )?.treeNodeId;
        }

        if (processWithAction && processWithAction.processId) {
            if (props.runWithoutJobProcess || canPlayProcessFromJobProcess(jobProcessInfo, dryRunMode, isTestRun)) {
                dispatch(
                    processPlayerPlay({
                        dryRunMode,
                        jobProcessId: jobProcessInfo ? jobProcessInfo.jobProcessId : 0,
                        processId,
                        reworkId: reworkId ? reworkId : 0,
                        treeNodeId,
                        isTestRun,
                        resultProcessHistoryIdSelected: resultProcessHistoryIdSelected ? resultProcessHistoryIdSelected : undefined,
                        instancesSelected: instancesSelected ? instancesSelected : undefined,
                        isResumeOfAnFailure: isResumeOfAnFailure ? isResumeOfAnFailure : false,
                    })
                );
            }
        }
    };

    /**
     * handles the play next event
     */
    const handlePlayNext = (
        validatedByUserId?: number,
        userName?: string,
        password?: string,
        consent?: boolean,
        context?: string,
        bomValidated?: boolean
    ): void => {
        setCheckValidationActionIsRequired(true);

        if (playingHistory && !reworkLogDone) {
            if (playingHistoryIndex + 1 === playerState.historyInfo.length) {
                setPlayingHistory(false);
                setPlayingHistoryIndex(DEFAULT_HISTORY_INDEX);
            } else if (playingHistoryIndex < playerState.historyInfo.length) {
                setPlayingHistoryIndex(playingHistoryIndex + 1);
            } else {
                setPlayingHistoryIndex(DEFAULT_HISTORY_INDEX);
                setPlayingHistory(false);
            }
        } else {
            if (!playerState.uiActionProps) {
                return void 0;
            }

            dispatch(
                processPlayerUpdateProcessState({
                    runnerId: "",
                    actionState: playerState.uiActionProps.stepState,
                    options: { validatedByUserId, userName, password, consent, stepComment, context, bomValidated },
                })
            );

            setSubmitPlayNext(true);
        }
    };

    /**
     * Rewinds history by 1 step
     */
    const handleRewind = (): void => {
        // Check if history mode is already active
        if (!playingHistory) {
            // Activate history mode
            setPlayingHistory(true);
            setPlayingHistoryIndex(DEFAULT_HISTORY_INDEX);

            // Set index to the action that is before the one in progress
            setPlayingHistoryActionBeforeLoading(DEFAULT_HISTORY_ACTION_BEFORE_LOADING);

            dispatch(processPlayerGetHistory());
        } else {
            // History mode active: rewind by 1 if not already equal to 0
            setPlayingHistoryIndex(playingHistoryIndex > 0 ? playingHistoryIndex - 1 : 0);
        }
    };

    /**
     * Rewinds history to specified step
     *
     * @param {ProcessActionId} actionId - ID of the action
     * @param {number} stepIndex - index of the step
     */
    const handleRewindTo = (actionId: ProcessActionId, stepIndex: number): void => {
        if (!playingHistory) {
            // Activate history mode
            setPlayingHistory(true);
            setPlayingHistoryIndex(DEFAULT_HISTORY_INDEX);

            setPlayingHistoryActionBeforeLoading({ actionId, stepIndex });

            dispatch(processPlayerGetHistory());
        } else {
            const rewindIndex = playerState.historyInfo.findIndex(
                (snapshot) => snapshot.processActionId === actionId && snapshot.stepIndex === stepIndex
            );

            // Check if we quit history mode
            if (rewindIndex !== -1) {
                // Rewind to selected step
                setPlayingHistoryIndex(rewindIndex);
            } else {
                setPlayingHistory(false);
            }
        }
    };

    /**
     * handles the stop process
     */
    const handleStopProcess = (): void => {
        const currentAction = getCurrentAction();
        if (currentAction && currentAction.actionType === "core-input") {
            initTimelineState();
            props.onClose();
        } else {
            dispatch(processPlayerStop());
        }

        setPlayingHistory(false);
        setPlayingHistoryIndex(DEFAULT_HISTORY_INDEX);
    };

    /**
     * handles the pause process
     */
    const handlePauseProcess = (): void => {
        debug("Pause");

        dispatch(processPlayerPause());
    };

    /**
     * handles the pause process
     */
    const handleResumeProcess = (): void => {
        debug(" Resume");
        setPlayingHistory(false);
        setPlayingHistoryIndex(DEFAULT_HISTORY_INDEX);

        dispatch(processPlayerResume());
    };

    /**
     * Handles form item change
     *
     * @param {IWorkInstructionsState} stepState - Updated state
     */
    const onFormItemStateChanged = (stepState: IWorkInstructionsState): void => {
        if (!playerState.uiActionProps) {
            return void 0;
        }

        dispatch(
            playerUpdateUiActionProps({
                ...playerState.uiActionProps,
                stepState,
            })
        );
    };

    /**
     * handles action change
     */
    const handleActionStateChange = (actionState: IInputState): void => {
        if (!playerState.uiActionProps) {
            return void 0;
        }

        dispatch(
            playerUpdateUiActionProps({
                ...playerState.uiActionProps,
                stepState: { ...actionState },
            })
        );
    };

    /**
     * handles action and/or step changed
     */
    const handleActionStepChanged = (): void => {
        setStepComment(""); // Clear step comment dialog
    };

    /**
     * handles message changed
     */
    const handleOnMessageChange = (message: string): void => {
        setStepComment(message);
    };

    /**
     * handle message dialog open
     */
    const handleMessageDialogOpen = (): void => {
        setMessageDialogTitle(translate("player.messageDialogTitleEnterMessage"));
        setMessageDialogAutoNext(false);
        setMessageDialogOpen(true);
    };

    /**
     * handle Bom followUp dialog open
     */
    const handleBomFollowUpDialogOpen = (): void => {
        setProcessBomDialogOpen(true);
    };

    /**
     * handle details dialog open
     */
    const handleDetailsDialogOpen = (): void => {
        setDetailsDialogOpen(true);
    };

    /**
     * handle dialog close and cancel
     */
    const handleDetailsDialogCancel = (): void => {
        setDetailsDialogOpen(false);
    };

    /**
     * handle dialog open for retry message
     */
    const handleAddProcessRetryComments = (): void => {
        if (submitPlayNext) {
            setMessageDialogTitle(translate("player.messageDialogTitleProcessRetryMessage"));
            setMessageDialogAutoNext(true);
            setMessageDialogOpen(true);
            setSubmitPlayNext(false);
        }
    };

    /**
     * handle dialog close for not selecting progress run
     */
    const handleSelectProgressRunNew = (): void => {
        if (playerState.uiActionProps) {
            const inputState = deepClone(playerState.uiActionProps.stepState as IInputState);
            inputState.selectedProgressRun = EnumProgressRunSelection.PLAY_NEW;

            dispatch(
                processPlayerUpdateProcessState({
                    runnerId: "",
                    actionState: inputState,
                })
            );
        }
    };

    /**
     * handle dialog close, continue and pdate message callback
     */
    const handleMessageDialogContinue = (): void => {
        setStepComment(stepComment);
        setMessageDialogOpen(false);
        if (messageDialogAutoNext) {
            handlePlayNext();
        }
    };

    /**
     * handle dialog close and cancel
     */
    const handleMessageDialogCancel = (): void => {
        setMessageDialogOpen(false);
        setSubmitPlayNext(false);
    };

    /**
     * handle dialog close
     */
    const handleProcessBomDialogClose = (): void => {
        setProcessBomDialogOpen(false);
    };

    /**
     * handle dialog close
     */
    const handleProcessBomDialogValidated = (): void => {
        setProcessBomDialogOpen(false);
        setValidateBom(false);
        handlePlayNext(undefined, undefined, undefined, undefined, undefined, true);
    };

    /**
     * handles the close completed dialog
     */
    const handleCloseCompletedDialog = (): void => {
        setCompletedDialogOpened(false);

        if (processWithAction && processWithAction.processId) {
            initTimelineState();
        }
        if (jobProcessInfo && jobProcessInfo.qtyPassed >= jobProcessInfo.qty) {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    /**
     * handles the close Message Circular Progress dialog
     */
    const handleCloseMessageCircularProgressDialog = (): void => {
        setOpenMessageCircularProgressDialog(false);
    };

    /**
     * handles the run again in completed dialog
     */
    const handleRunAgain = (runAgain: boolean): void => {
        setRunAgain(runAgain);

        initTimelineState();

        handleStartProcess();
    };

    /**
     * handles the retry in failed dialog
     */
    const handleRetryFailedDialog = (): void => {
        setFailDialogOpened(false);

        dispatch(processPlayerRetry());
    };

    /**
     * handles the send in failed dialog
     */
    const handleSendReportFailed = (failures: IStepFailure[], reworkInfo: IPlayerReworkInfo, continueRun: boolean): void => {
        setFailDialogOpened(false);

        if (!playerState.uiActionProps) {
            return void 0;
        }

        if (continueRun) {
            // Send failure to Hub
            dispatch(processPlayerInsertRework(failures, reworkInfo));
        } else {
            // Send failure to Runner
            dispatch(processPlayerFailStep({ runnerId: "", failures, yieldType: reworkInfo.yieldType }));
        }
    };

    /**
     * Cancel the failed dialog
     */
    const handleCancelFailedDialog = (): void => {
        setFailDialogOpened(false);
    };

    /**
     * close the failed dialog
     */
    const handleCloseFailedDialog = (): void => {
        setFailDialogOpened(false);

        if (dryRunMode) {
            dispatch(
                processPlayerFailStep({
                    failures: [],
                    runnerId: "",
                })
            );
        }

        if (playerState.processState.status === EnumProcessStatus.FAILED) {
            initTimelineState();
        }
    };

    /**
     * Fetch the newly selected process when changing version
     *
     * @param {number} processId - ID of the selected process version
     */
    // FIXME: AOS-2249 Is this function still necessary? Did we not disable changing version in the Player?
    const handleProcessVersionChange = (processId: number): void => {
        dispatch(processGet(processId)).then(() => {
            //setProcessWithAction(process);
            setSubmitPlayNext(false);
        });

        initTimelineState();
    };

    /**
     * Handles the successful validation of an action by a user
     *
     * @param {number} validatedByUserId - user ID for validator
     */
    const handleUserValidation = async (validatedByUserId: number, userName: string, password: string, consent: boolean): Promise<void> => {
        handlePlayNext(validatedByUserId, userName, password, consent);
        return void 0;
    };

    /**
     * Handles the successful validation of a trainer
     */
    const handleTrainerValidation = (trainerId: number, userName: string, password: string, consent: boolean, context: string): void => {
        handlePlayNext(trainerId, userName, password, consent, context);
    };

    /**
     * Handles the successful validation of a trainer
     */
    const handleTrainingCompletion = (userId: UserId, userName: string, password: string, consent: boolean, context: string): void => {
        handlePlayNext(userId, userName, password, consent, context);
    };

    /**
     * Focus player's "Next" button
     */
    const handleFocusNextButton = (): void => {
        if (nextButtonRef.current) {
            nextButtonRef.current.focus();
        }
    };

    /**
     * Get the action player that will be displayed
     */
    const getActionPlayer = (): JSX.Element | void => {
        const actionBaseProps = playingHistory
            ? processWithAction?.actions.find(
                  (action) => action.processActionId === playerState.historyInfo[playingHistoryIndex]?.processActionId
              )
            : currentPlayerState?.uiActionProps?.baseProps;

        const stepProps = playingHistory
            ? processWithAction?.actions.find(
                  (action) => action.processActionId === playerState.historyInfo[playingHistoryIndex]?.processActionId
              )?.steps[playerState.historyInfo[playingHistoryIndex]?.stepIndex]
            : currentPlayerState?.uiActionProps?.stepProps;

        const stepState = playingHistory
            ? playerState.historyInfo[playingHistoryIndex]?.stepState
            : currentPlayerState?.uiActionProps?.stepState;

        if (actionBaseProps && stepProps && stepState) {
            switch (actionBaseProps.type) {
                case "core-condition":
                    return <ConditionPlayer actionState={stepState as ConditionState} />;
                case "core-connector":
                    return <ConnectorPlayer actionState={stepState as ConnectorState} />;
                case "core-data-store":
                    return <DataStorePlayer actionState={stepState as DataStoreState} />;
                case "core-input":
                    return (
                        <InputPlayer
                            actionProps={stepProps as ProcessActionStepInput}
                            actionState={stepState as IInputState}
                            disabled={playingHistory}
                            processName={processName}
                            onAddComment={handleAddProcessRetryComments}
                            onNew={handleSelectProgressRunNew}
                            onResume={handleStartProcess}
                            onStateChange={handleActionStateChange}
                        />
                    );
                case "core-failure-ticket-create":
                    return <FailureTicketForkPlayer actionState={stepState as FailureTicketForkState} />;
                case "core-fail":
                    return <FailPathPlayer />;
                case "core-loop":
                    return <LoopPlayer loopProps={stepProps as ProcessActionStepLoop} loopState={stepState as ILoopState} />;
                case "core-math":
                    return <MathPlayer actionState={stepState as MathState} />;
                case "core-message":
                    return <MessagePlayer actionState={stepState as MessageState} />;
                case "core-parser":
                    return <ParserPlayer actionState={stepState as ParserState} />;
                case "core-set":
                    return <SetPlayer />;
                case "core-time":
                    return <TimePlayer actionState={stepState as TimeState} />;
                case "core-work-instructions":
                    return (
                        <WorkInstructionsPlayer
                            actionProps={stepProps as ProcessActionStepWorkInstructions}
                            actionState={stepState as IWorkInstructionsState}
                            onFocusNextButton={handleFocusNextButton}
                            onStateChange={onFormItemStateChanged}
                            disabled={playingHistory}
                        />
                    );
            }
        }
    };

    /**
     * handle user validation dialog close
     */
    const handleUserValidationDialogClose = (): void => {
        setUserValidationDialogOpen(false);
        setCheckValidationActionIsRequired(false);
        dispatch(playerEnablePlayNext(true));
    };

    /**
     * handle trainer validation dialog close
     */
    const handleTrainerValidationDialogClose = (): void => {
        setTrainerValidationDialogOpen(false);
        setCheckValidationActionIsRequired(false);
        dispatch(playerEnablePlayNext(true));
    };

    /**
     * handles step fail
     */
    const handleStepFail = (): void => {
        setFailDialogOpened(true);
    };

    /**
     * handle save rework log
     */
    const handleSaveReworkLog = (newReworkItemStatusId: number, comments: string): void => {
        if (playerState.uiActionProps) {
            const outputState = deepClone(playerState.uiActionProps.stepState as IOutputState);
            outputState.reworkNewItemStatusId = newReworkItemStatusId;
            outputState.reworkComment = comments;

            dispatch(
                playerUpdateUiActionProps({
                    ...playerState.uiActionProps,
                    stepState: outputState,
                })
            );

            setReworkLogDialogOpen(false);
            setReworkLogDone(true);
        }
    };

    /**
     * handle the logic to deactivate the arrow keys when applicable
     */
    const maskArrowKeys = (): boolean => {
        //  Deactivate the arrow keys when a dialod is opened.
        return (
            messageDialogOpen ||
            processBomDialogOpen ||
            failDialogOpened ||
            detailsDialogOpen ||
            userValidationDialogOpen ||
            completedDialogOpened ||
            reworkLogDialogOpen
        );
    };

    /**
     * Send payload to Runner to retry failed server operation
     */
    const handleRunnerHubFailureDialogRetry = (): void => {
        if (playerState.uiActionProps) {
            dispatch(
                processPlayerUpdateProcessState({
                    runnerId: "",
                    actionState: playerState.uiActionProps.stepState,
                    options: { retryHubConnection: true },
                })
            );
        }
    };

    /**
     * Stop the process
     */
    const handleRunnerHubFailureDialogStop = (): void => {
        const currentAction = getCurrentAction();

        if (currentAction?.actionType === "core-input") {
            initTimelineState();
            props.onClose();
        } else if (playerState.uiActionProps) {
            dispatch(
                processPlayerUpdateProcessState({
                    runnerId: "",
                    actionState: playerState.uiActionProps.stepState,
                    options: { retryHubConnection: false },
                })
            );
        }

        setPlayingHistory(false);
        setPlayingHistoryIndex(DEFAULT_HISTORY_INDEX);
    };

    const handleTrainingCommuniqueOpen = (): void => {
        if (!playerState.uiActionProps || !trainingCommuniqueButtonEnabled) return void 0;

        const uiActionProps = playerState.uiActionProps as IPlayerUIActionProps<ProcessActionStepWorkInstructions, IWorkInstructionsState>;

        dispatch(
            playerUpdateUiActionProps({
                ...uiActionProps,
                stepState: {
                    ...uiActionProps.stepState,
                    trainingCommuniqueViewed: false,
                },
            })
        );
    };

    return (
        <div className={classes.root} id="playerPageRootId">
            <PlayerStepFailedDialog
                dryRunMode={dryRunMode}
                jobProcessInfo={jobProcessInfo}
                onCancel={handleCancelFailedDialog}
                onClose={handleCloseFailedDialog}
                onRetry={handleRetryFailedDialog}
                onSendReport={handleSendReportFailed}
                open={failDialogOpened}
                process={processWithAction}
                processTreeNodeId={processWithAction?.treeNodeId}
                reworkId={props.reworkId}
                runWithoutJobProcess={props.runWithoutJobProcess}
            />
            {playerState.processState.reworkId !== 0 && (
                <ReworkLogDialog
                    open={reworkLogDialogOpen}
                    jobProcess={jobProcessInfo}
                    processTreeNodeId={processWithAction?.treeNodeId}
                    onSave={handleSaveReworkLog}
                />
            )}
            <div className={classes.mainContent}>
                {/* MEDIA */}
                <div className={classes.mediaContainer}>
                    <Paper className={classes.actionUIContainer}>
                        {/* PROCESS IDLE */}
                        {currentPlayerState && currentPlayerState.processState.status === EnumProcessStatus.IDLE && (
                            <>
                                {jobProcessInfo && (
                                    <SchedulerOperatorJobProcessCard
                                        index={jobProcessInfo.jobProcessId}
                                        isSelected={false}
                                        jobProcessInfo={jobProcessInfo}
                                    />
                                )}
                                <div className={classes.processTitle}>
                                    <Typography id="processTitleId" variant="h1">
                                        {processName}
                                    </Typography>
                                    {dryRunMode && <Typography variant="h2">{translate("scheduler.dryRun")}</Typography>}
                                </div>
                            </>
                        )}

                        {/* ACTION PLAYER */}
                        {currentPlayerState &&
                            currentPlayerState.processState.status !== EnumProcessStatus.FAILED &&
                            (currentPlayerState.uiActionProps && currentPlayerState.uiActionProps.baseProps.type === "core-input" ? (
                                <>
                                    {jobProcessInfo && (
                                        <SchedulerOperatorJobProcessCard
                                            index={jobProcessInfo.jobProcessId}
                                            isSelected={false}
                                            jobProcessInfo={jobProcessInfo}
                                        />
                                    )}
                                    <div className={classes.processInput}>
                                        <>{getActionPlayer()}</>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {(playerState.processState?.status !== EnumProcessStatus.COMPLETED || playingHistory) &&
                                        getActionPlayer()}
                                </>
                            ))}
                        {/* PROCESS RESULTS */}
                        {playerState.processState &&
                            (playerState.processState.isCompleted || playerState.processState.isStopped) &&
                            !playingHistory && (
                                <div style={{ textAlign: "center" }}>
                                    <Typography id="playerProcessCompletedH1Id" variant="h1">
                                        {translate(
                                            playerState.processState.isCompleted
                                                ? playerState.processState.status === EnumProcessStatus.FAILED ||
                                                  playerState.processState.inFailPath
                                                    ? "player.processFailed"
                                                    : "player.processCompleted"
                                                : "player.processStopped"
                                        )}
                                    </Typography>
                                </div>
                            )}
                        <StepValidationDialog
                            dryRun={playerState.processState.dryRunMode}
                            electronicSignatureContext={
                                playerState.uiActionProps && playerState.uiActionProps.stepProps.electronicSignatureContext
                            }
                            excludeSelf={!(playerState.processState.dryRunMode || playerState.processState.isTestRun)}
                            isElectronicSignature={
                                playerState.uiActionProps && playerState.uiActionProps.stepProps.electronicSignatureApproval
                            }
                            onClose={handleUserValidationDialogClose}
                            open={userValidationDialogOpen}
                            processId={processId}
                            onValidate={handleUserValidation}
                            validationGroupsId={
                                playerState.uiActionProps && playerState.uiActionProps.stepProps.validationGroupId
                                    ? [playerState.uiActionProps.stepProps.validationGroupId]
                                    : []
                            }
                        />
                        <TrainerValidationDialog
                            onClose={handleTrainerValidationDialogClose}
                            onValidate={handleTrainerValidation}
                            open={trainerValidationDialogOpen}
                            processId={processId}
                        />
                        <TrainingCompletionDialog onCompleteTraining={handleTrainingCompletion} open={trainingCompletionDialogOpen} />
                    </Paper>
                </div>

                <PlayerControls
                    dryRunMode={dryRunMode}
                    nextButtonRef={nextButtonRef}
                    playerState={playerState}
                    playingHistoryIndex={playingHistoryIndex}
                    playingHistory={playingHistory}
                    processWithActions={processWithAction}
                    onVersionChange={handleProcessVersionChange}
                    onStart={handleStartProcess}
                    onRewind={handleRewind}
                    onRewindTo={handleRewindTo}
                    onStop={handleStopProcess}
                    onPlayNext={handlePlayNext}
                    onPause={handlePauseProcess}
                    onResume={handleResumeProcess}
                    onActionStepChanged={handleActionStepChanged}
                    canPlay={props.runWithoutJobProcess || canPlayProcessFromJobProcess(jobProcessInfo, dryRunMode, isTestRun)}
                    maskArrowKeys={maskArrowKeys()}
                    onMessageDialog={handleMessageDialogOpen}
                    onBomFollowUpDialog={handleBomFollowUpDialogOpen}
                    onDetailsDialog={handleDetailsDialogOpen}
                    onFail={handleStepFail}
                    onTraningCommuniqueOpen={handleTrainingCommuniqueOpen}
                    showPurgeIcon={jobProcessInfo?.purgeVersion}
                    trainingCommuniqueEnabled={trainingCommuniqueButtonEnabled}
                />
                {completedDialogOpened && (
                    <SuccessfulDialog
                        open={completedDialogOpened}
                        onClose={handleCloseCompletedDialog}
                        reworkId={reworkId}
                        onRunAgain={handleRunAgain}
                        jobProcess={jobProcessInfo}
                        runAgain={runAgain}
                    />
                )}

                <MessageDialog
                    title={messageDialogTitle}
                    open={messageDialogOpen}
                    message={stepComment}
                    onMessageChange={handleOnMessageChange}
                    onCancel={handleMessageDialogCancel}
                    onContinue={handleMessageDialogContinue}
                />
                <ProcessBomDialog
                    open={processBomDialogOpen}
                    onClose={handleProcessBomDialogClose}
                    onValidated={handleProcessBomDialogValidated}
                    validateBom={validateBom}
                />
                <RunDetailsDialog
                    open={detailsDialogOpen}
                    playerState={playerState}
                    onCancel={handleDetailsDialogCancel}
                    process={processWithAction}
                    jobProcessInfo={jobProcessInfo}
                    reworkId={props.reworkId}
                    runWithoutJobProcess={props.runWithoutJobProcess}
                />
                <MessageCircularProgressDialog
                    open={openMessageCircularProgressDialog}
                    onClose={handleCloseMessageCircularProgressDialog}
                />

                <RunnerHubFailureDialog onRetry={handleRunnerHubFailureDialogRetry} onStop={handleRunnerHubFailureDialogStop} />
            </div>
        </div>
    );
}

export default Player;
