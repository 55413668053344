import { KortexDateTimePicker, KortexTextField, greyPalette, theme } from "@aos/react-components";
import {
    IJobProcessUiModel,
    IJobUiModel,
    IProcessUiModel,
    ITokenDecoded,
    IUserGroupDbModel,
    JOB_QTY_MAX,
    JobProcessStatusEnum,
    JobStatusEnum,
    ProcessApprovalStatusFilter,
    getElapsedTimeStr,
    getTimestampFromDateStr,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Checkbox, FormControl, FormControlLabel, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ReworkIcon from "@material-ui/icons/BuildOutlined";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import "moment/locale/fr";
import React, { useEffect, useState } from "react";

import { useTranslate } from "../../../../hooks/useTranslate";
import { jobJobProcessUpdateStatus } from "../../../../redux/scheduler-manager/scheduler-thunks-job";
import { userCanInsert } from "../../../../utilitites/IUserRights";
import ProcessIcon from "../../../core/Icons/Process/Process";
import KortexPanelCard from "../../../core/KortexPanelCard";
import ProcessVersionPicker from "../../../core/ProcessVersionPicker/ProcessVersionPicker";
import { GetJobInProgress, getJobProcessStatusColor, getJobProcessStatusLabelKey } from "../SchedulerData";

const useStyles = makeStyles({
    groupSelect: {
        padding: "5px",
    },
    optionsItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0px",
    },
    textSmall: {
        margin: "5px",
        width: "80px",
    },
    textLarge: {
        margin: "5px",
        width: "170px",
    },
    noMarginBottom: {
        marginBottom: "0px",
    },
    noMarginTop: {
        marginTop: "0px",
        paddingTop: "0px",
    },
    processPanelCardAllContainer: {
        display: "flex",
        flexDirection: "row",
    },
    processPanelCardContainer: {
        margin: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 2,
    },
    processPanelCardProcessNameContainer: {
        display: "inline-flex",
        flex: 1,
    },
    processPanelCardTitle: {
        display: "flex",
        flexDirection: "row",
        color: theme.palette.primary[500],
        justifyContent: "space-between",
    },
    processPanelCardTypo: {
        padding: "5px 10px",
    },
    processPanelCardText: {
        alignItems: "flex-end",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexFlow: "wrap",
    },
    iconContainer: {
        color: greyPalette[500],
        marginTop: "6px",
    },
    hiddenJobStatus: {
        display: "none",
    },
    datePickerLabel: {
        display: "inline-block",
    },
    datePickerInput: {
        padding: "5px",
        fontSize: "1rem",
    },
    multiInput: {
        fontSize: "0.85rem",
        padding: "0px",
        letterSpacing: "normal",
        display: "flex",
        width: "170px",
    },
    multiInputItem: {
        padding: "5px",
        fontSize: "14px",
    },
});

interface IOwnProps {
    job: IJobUiModel;
    jobProcess: IJobProcessUiModel;
    groupsList: IUserGroupDbModel[];
    isSelected: boolean;
    isDragDisabled: boolean;
    process?: IProcessUiModel;
    userInfo?: ITokenDecoded;
    failureTicket?: boolean;
    onUpdateProcess?: (job: IJobUiModel, jobProcess: IJobProcessUiModel) => void;
    onSelection?: (process: IJobProcessUiModel) => void;
}

export default function SchedulerJobProcessCard(props: IOwnProps): JSX.Element {
    const { groupsList, job, jobProcess, isDragDisabled, failureTicket = false, userInfo, process } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const [textFieldReadOnly, setTextFieldReadOnly] = useState<boolean>(false);
    const translate = useTranslate();

    /**
     * Update state of text field based on jobProcess status and user access
     */
    useEffect((): void => {
        if (
            (jobProcess && jobProcess.status === JobProcessStatusEnum.ARCHIVED) ||
            (!failureTicket && !userCanInsert(userInfo?.roleRights.scheduler)) ||
            (failureTicket && !userCanInsert(userInfo?.roleRights.rework))
        ) {
            setTextFieldReadOnly(true);
        }
    }, [job.status]);

    /**
     * Handle called when the card header item is updated
     *
     * @param {IJobProcessUiModel} changeProcess - jobProcess to update
     * @param {IJobProcessUiModel} key - Key in IJobUiModel changed
     */
    const handleJobProcessChange =
        (changeProcess: IJobProcessUiModel, key: keyof IJobProcessUiModel): ((value: string) => void) =>
        (value: string): void => {
            if (job && props.isSelected) {
                changeProcess[key as string] = value;

                if (props.onUpdateProcess) {
                    props.onUpdateProcess(job, changeProcess);
                }
            }
        };

    /**
     * Handle called when the card header item is updated
     *
     * @param {number} jobProcessId - ID of the job process to update
     */
    const handleJobProcessStatusChange =
        (jobProcessId: IJobProcessUiModel["jobProcessId"]): ((event: React.ChangeEvent<HTMLSelectElement>) => void) =>
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            if (job) {
                dispatch(
                    jobJobProcessUpdateStatus({
                        jobId: job.jobId,
                        jobProcessIds: [jobProcessId],
                        status: event.target.value as JobProcessStatusEnum,
                    })
                );
            }
        };

    /**
     * Handle called when the check previous of a process is changed
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - not used
     */
    const handleJobProcessChangeCheckPrevious = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.stopPropagation();

        if (job) {
            jobProcess.checkPrevious = !jobProcess.checkPrevious;

            if (props.onUpdateProcess) {
                props.onUpdateProcess(job, jobProcess);
            }
        }
    };

    /**
     * Handle called when the "Planned On" field is updated
     *
     * @param {IJobProcessUiModel} changeProcess - jobProcess to update
     */
    const handleJobPlannedDateChange =
        (changeProcess: IJobProcessUiModel): ((date: MaterialUiPickersDate) => void) =>
        (date: MaterialUiPickersDate): void => {
            if (job && props.isSelected) {
                changeProcess.scheduledOn = date ? getTimestampFromDateStr(String(date)) : 0;

                if (props.onUpdateProcess) {
                    props.onUpdateProcess(job, changeProcess);
                }
            }
        };

    /**
     * Called when the inner filters change
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - event containing the key and the value to change to update
     */
    const handleJobProcessChangeMulti = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (job && props.isSelected) {
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore Standard type for HTMLSelectElement is a string[].  Here we use number[] and it cannot be overcome.
            const groupIdsJSON_arrayNumber: number[] = event.target.value;
            /* eslint-enable @typescript-eslint/ban-ts-comment */
            const processIndex = job.processList.findIndex((process) => process.jobProcessId === jobProcess.jobProcessId);
            if (processIndex !== -1) {
                job.processList[processIndex].groupIdsJSON = groupIdsJSON_arrayNumber;
            }
            props.onUpdateProcess?.(job, jobProcess);
        }
    };

    /**
     * Handle selection of the job process
     *
     * @param {IJobProcessUiModel} jobProcess - process selected
     */
    const handleSelectJobProcess =
        (jobProcess: IJobProcessUiModel): (() => void) =>
        (): void => {
            if (props.onSelection) {
                props.onSelection(jobProcess);
            }
        };

    /**
     * handle the process version change
     *
     * @param {number} processId - process id
     */
    const handleProcessVersionChange = (processId: number): void => {
        if (props.onUpdateProcess && job && process && jobProcess.processId !== processId) {
            props.onUpdateProcess(job, { ...jobProcess, processId: processId, purgeVersion: false });
        }
    };

    return (
        <React.Fragment>
            {job && process && (
                <div style={{ paddingTop: jobProcess.routingId > 0 ? "0px" : "6px" }}>
                    <KortexPanelCard
                        isSelected={props.isSelected}
                        spaceBetweenCard={jobProcess.routingId > 0 ? 0 : 1}
                        padding={10}
                        variant={jobProcess.routingId > 0 ? "flat" : "none"}
                        onSelect={handleSelectJobProcess(jobProcess)}
                        isDraggable={!isDragDisabled}
                        statusColor={getJobProcessStatusColor(jobProcess.status)}
                    >
                        <div className={classes.processPanelCardAllContainer} id="jobProcessCardId">
                            <div className={classes.processPanelCardContainer}>
                                <div className={classes.processPanelCardTitle}>
                                    {jobProcess.reworkId ? (
                                        <ReworkIcon className={classes.iconContainer} />
                                    ) : (
                                        <ProcessIcon className={classes.iconContainer} />
                                    )}
                                    <div className={classes.processPanelCardProcessNameContainer}>
                                        <Typography
                                            id="jobProcessCardProcessNameId"
                                            className={classes.processPanelCardTypo}
                                            color="primary"
                                            variant="h6"
                                        >
                                            {jobProcess.processName}
                                        </Typography>
                                        {jobProcess.reworkId > 0 && jobProcess.rework.trackingId && (
                                            <KortexTextField
                                                label={translate("scheduler.tracking")}
                                                value={jobProcess.rework.trackingId}
                                                className={classes.textLarge}
                                                variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                    id: "trackingIdJobProcessId",
                                                }}
                                            />
                                        )}
                                    </div>

                                    {process && process.processId && (
                                        <>
                                            <ProcessVersionPicker
                                                latest={false}
                                                onVersionChange={handleProcessVersionChange}
                                                processId={process.processId}
                                                readOnly={
                                                    jobProcess.routingId !== 0 || job.status !== JobStatusEnum.DRAFT || textFieldReadOnly
                                                }
                                                treeNodeId={process.treeNodeId}
                                                showPurgeIcon={jobProcess.purgeVersion}
                                                variant={ProcessApprovalStatusFilter.APPROVED}
                                            />
                                        </>
                                    )}
                                </div>
                                <div className={classes.processPanelCardText}>
                                    {/*STATUS*/}
                                    <KortexTextField
                                        className={classes.textLarge}
                                        InputProps={{
                                            id: "jobProcessStatusSelectId",
                                            readOnly: true,
                                        }}
                                        label={translate("scheduler.status")}
                                        onChange={handleJobProcessStatusChange(jobProcess.jobProcessId)}
                                        value={translate(getJobProcessStatusLabelKey(jobProcess.status))}
                                        variant="standard"
                                    />
                                    {job.status === JobStatusEnum.DRAFT ? (
                                        <KortexTextField
                                            label={translate("scheduler.quantity")}
                                            value={jobProcess.qty}
                                            InputProps={{
                                                id: "jobProcessQuantityId",
                                                readOnly: textFieldReadOnly,
                                            }}
                                            max={JOB_QTY_MAX}
                                            className={classes.textLarge}
                                            variant="standard"
                                            type="number"
                                            onChanged={handleJobProcessChange(jobProcess, "qty")}
                                        />
                                    ) : (
                                        <React.Fragment>
                                            <KortexTextField
                                                label={translate("scheduler.quantity")}
                                                value={jobProcess.qty ?? 0}
                                                className={classes.textSmall}
                                                variant="standard"
                                                type="number"
                                                InputProps={{
                                                    readOnly: true,
                                                    id: "jobProcessQuantityId",
                                                }}
                                            />
                                            <KortexTextField
                                                label={translate("scheduler.quantityInProgress")}
                                                value={
                                                    GetJobInProgress(
                                                        jobProcess.qtyStarted,
                                                        jobProcess.qty,
                                                        jobProcess.qtyPassed,
                                                        jobProcess.qtyFailed
                                                    ).inProgress
                                                }
                                                className={classes.textSmall}
                                                variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                    id: "jobProcessQuantityStartedId",
                                                }}
                                            />
                                            <KortexTextField
                                                label={translate("scheduler.quantityRemainingToStart")}
                                                value={
                                                    GetJobInProgress(
                                                        jobProcess.qtyStarted,
                                                        jobProcess.qty,
                                                        jobProcess.qtyPassed,
                                                        jobProcess.qtyFailed
                                                    ).remainingToStart
                                                }
                                                className={classes.textSmall}
                                                variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                    id: "jobProcessQuantityRemainingId",
                                                }}
                                            />
                                            <KortexTextField
                                                label={translate("scheduler.quantityPass")}
                                                value={jobProcess.qtyPassed ?? 0}
                                                className={classes.textSmall}
                                                variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                    id: "jobProcessQuantityPassId",
                                                }}
                                            />
                                            <KortexTextField
                                                label={translate("scheduler.quantityFail")}
                                                value={jobProcess.qtyFailed ?? 0}
                                                className={classes.textSmall}
                                                variant="standard"
                                                InputProps={{
                                                    readOnly: true,
                                                    id: "jobProcessQuantityFailId",
                                                }}
                                            />
                                        </React.Fragment>
                                    )}
                                    <KortexTextField
                                        label={translate("scheduler.elapsedTime")}
                                        value={`${getElapsedTimeStr(jobProcess.timeRun)}`}
                                        className={classes.textLarge}
                                        variant="standard"
                                        InputProps={{
                                            readOnly: true,
                                            id: "jobProcessElapsedTimeId",
                                        }}
                                    />
                                    <KortexDateTimePicker
                                        value={jobProcess.scheduledOn}
                                        onChange={handleJobPlannedDateChange(jobProcess)}
                                        label={translate("scheduler.scheduledOn")}
                                        DateTimePickerProps={{
                                            className: classes.textLarge,
                                            readOnly: textFieldReadOnly,
                                            clearLabel: translate("general.clear"),
                                            cancelLabel: translate("general.cancel"),
                                            okLabel: translate("general.select"),
                                        }}
                                    />
                                    {groupsList && groupsList.length > 0 && (
                                        <FormControl className={classes.textLarge}>
                                            <Typography className={classes.multiInput}>{translate("scheduler.group")}</Typography>
                                            <Select
                                                multiple={true}
                                                className={classes.multiInput}
                                                value={jobProcess.groupIdsJSON}
                                                onChange={handleJobProcessChangeMulti}
                                                inputProps={{
                                                    className: classes.multiInputItem,
                                                    name: "groupIdsJSON",
                                                    id: "multiGroupSelectId",
                                                }}
                                            >
                                                {groupsList.map(
                                                    (group, groupIndex): JSX.Element => (
                                                        <MenuItem
                                                            key={groupIndex}
                                                            value={group.userGroupId}
                                                            className={classes.multiInputItem}
                                                        >
                                                            {group.name}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                    {/* Functionnality currently not available, removed until functionnality present
                                    <FormControl className={classes.textLarge}>
                                        <Typography className={classes.multiInput}>{translate("scheduler.workZone")}</Typography>
                                        <Select
                                            multiple={true}
                                            className={classes.multiInput}
                                            value={jobProcess.zoneIdsJSON}
                                            onChange={handleJobProcessChangeMulti}
                                            inputProps={{
                                                className: classes.multiInputItem,
                                                name: "workZoneIdsJSON",
                                                id: "workZoneSelectId",
                                                disabled: true,
                                            }}
                                        >
                                            <MenuItem key={"1"} value={1} />
                                            <MenuItem key={"2"} value={2} />
                                        </Select>
                                    </FormControl>
                                        */}
                                    <FormControlLabel
                                        className={classes.textLarge}
                                        control={
                                            <Checkbox
                                                disabled={jobProcess.routingId > 0 || textFieldReadOnly}
                                                checked={jobProcess.checkPrevious}
                                                onClick={handleJobProcessChangeCheckPrevious}
                                            />
                                        }
                                        label={translate("scheduler.checkPrevious")}
                                        style={{
                                            display: job.status === JobStatusEnum.DRAFT || failureTicket ? undefined : "none",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </KortexPanelCard>
                </div>
            )}
        </React.Fragment>
    );
}
