var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// react
import { useEffect, useState, useRef } from "react";
import * as React from "react";
// mui
import { Button, Dialog, DialogContent, Accordion, AccordionDetails, AccordionSummary, IconButton, InputAdornment, TextField, Typography, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Close from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useKeyPressed } from "../hooks/useKeyPressed";
import { theme } from "../mui/theme";
import { KortexLabel } from "../KortexLabel";
import { IconKinovaLogo } from "../icons";
// Style
var useStyles = makeStyles({
    dialogPaper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        borderRadius: "2px",
    },
    dialogContent: {
        padding: 0,
        "&:first-child": {
            padding: 0,
        },
    },
    dialogContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "stretch",
    },
    logoContainer: {
        position: "relative",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "75px",
    },
    closeIcon: {
        position: "absolute",
        top: "10px",
        right: "10px",
        color: theme.palette.common.white,
        cursor: "pointer",
    },
    logo: {
        width: "140px",
    },
    errorMessageContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
    },
    titleLabel: {
        display: "flex",
        justifyContent: "center",
        padding: "25px",
        fontWeight: 600,
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        flexGrow: 1,
        padding: "0 30px",
        marginBottom: "15px",
    },
    textField: {
        marginBottom: "20px",
    },
    collapse: {
        boxShadow: "none",
        "&:before": {
            content: "none",
        },
        "&$collapseExpanded": {
            margin: 0,
        },
    },
    collapseExpanded: {},
    advancedSettings: {
        display: "flex",
        flexDirection: "column",
        padding: 0,
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        margin: "5px 10px",
    },
    footerTextLeft: {
        color: theme.palette.grey[600],
        display: "flex",
        flexBasis: "50%",
    },
    footerTextRight: {
        color: theme.palette.grey[600],
        display: "flex",
        flexBasis: "50%",
        justifyContent: "flex-end",
    },
    iconButton: {
        padding: 0,
        "&:hover": {
            background: "none",
        },
    },
    button: {
        marginTop: "14px",
    },
    forgotPasswordLabel: {
        alignSelf: "center",
        marginTop: 16,
    },
    passwordInputProp: {
        width: "auto",
    },
});
function KortexDialogLogin(props) {
    var _this = this;
    var classes = useStyles(props);
    var children = props.children, textLabels = props.textLabels, username = props.username, language = props.language, open = props.open, expandedByDefault = props.expandedByDefault, acceptedLanguages = props.acceptedLanguages, showForgotPassword = props.showForgotPassword;
    var matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
    var _a = useState(expandedByDefault || false), expanded = _a[0], setExpanded = _a[1];
    var _b = useState(username), inputUsername = _b[0], setInputUsername = _b[1];
    var _c = useState(false), showPassword = _c[0], setShowPassword = _c[1];
    var _d = useState(false), working = _d[0], setWorking = _d[1];
    var enterPressed = useKeyPressed("Enter");
    var buttonRef = useRef(null);
    /**
     * Called when user click key... try to login
     */
    useEffect(function () {
        if (enterPressed) {
            if (buttonRef && buttonRef.current) {
                buttonRef.current.click();
            }
        }
    }, [enterPressed]);
    /**
     * Handle the language change and dispatch the new language
     */
    var handleLanguageChange = function (event) {
        if (props.onLanguageChange) {
            props.onLanguageChange(event);
        }
    };
    /**
     * Handle the toggling of the showPassword icon
     */
    var handleToggleShowPassword = function () {
        setShowPassword(!showPassword);
    };
    /**
     * Function called when the user completes the user
     * info form to connect to the robot.
     */
    var handleConnect = function (event) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    event.preventDefault();
                    if (working) {
                        return [2 /*return*/];
                    }
                    setWorking(true);
                    if (!props.onConnect) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, , 3, 4]);
                    return [4 /*yield*/, props.onConnect(event)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setWorking(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    /**
     * Function called when the user name input changes
     */
    var handleUserNameChanged = function (event) {
        setInputUsername(event.target.value);
    };
    /**
     * Function called when the user click forgot password button
     */
    var handleForgotPassword = function () {
        if (props.onForgotPassword) {
            props.onForgotPassword(inputUsername || "");
        }
    };
    /**
     * Function called when the user click forgot password button
     */
    var handleOnClose = function (event) {
        if (props.onClose) {
            props.onClose(event);
        }
    };
    /**
     * Function called when the user click forgot password button
     */
    var handleExpansionChange = function (_event, expanded) {
        setExpanded(expanded);
    };
    return (React.createElement(Dialog, { id: "loginDialogId", classes: { paper: classes.dialogPaper }, open: open, fullScreen: matchesXS, scroll: "paper", maxWidth: "sm" },
        React.createElement(DialogContent, { classes: { root: classes.dialogContent } },
            React.createElement("div", { className: classes.logoContainer },
                React.createElement(IconKinovaLogo, { fill: theme.palette.common.white, height: 25, width: 148 }),
                props.onClose && !matchesXS && (React.createElement("div", { className: classes.closeIcon, id: "closeIcon" },
                    React.createElement(Close, { onClick: handleOnClose })))),
            React.createElement("div", { className: classes.dialogContainer },
                React.createElement(Typography, { variant: "h4", classes: { root: classes.titleLabel } }, textLabels.titleLabel),
                textLabels.errorLabel && (React.createElement("div", { className: classes.errorMessageContainer },
                    React.createElement(Typography, { id: "errorMessageId", variant: "body2", color: "error" }, textLabels.errorLabel))),
                React.createElement("form", { className: classes.formContainer, onSubmit: handleConnect },
                    React.createElement(TextField, { disabled: working, fullWidth: true, defaultValue: inputUsername, id: "usernameInputId", label: textLabels.usernameLabel, variant: "outlined", className: classes.textField, onChange: handleUserNameChanged, InputLabelProps: {
                            shrink: true,
                        } }),
                    React.createElement(TextField, { disabled: working, fullWidth: true, defaultValue: "", id: "passwordInputId", label: textLabels.passwordLabel, variant: "outlined", autoComplete: "current-password", type: showPassword ? "text" : "password", className: classes.textField, InputLabelProps: {
                            shrink: true,
                        }, InputProps: {
                            className: classes.passwordInputProp,
                            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                React.createElement(IconButton, { disabled: working, onClick: handleToggleShowPassword, className: classes.iconButton }, showPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                        } }),
                    acceptedLanguages && acceptedLanguages.length > 0 && language && (React.createElement(TextField, { disabled: working, id: "languageInputId", select: true, label: textLabels.languageLabel, value: language, onChange: handleLanguageChange, margin: "none", variant: "outlined", SelectProps: {
                            native: true,
                        }, InputLabelProps: {
                            shrink: true,
                        } }, acceptedLanguages.map(function (lang, index) { return (React.createElement("option", { key: index, value: lang.key }, lang.label)); }))),
                    children && React.Children.count(children) > 0 && (React.createElement(Accordion, { classes: {
                            root: classes.collapse,
                            expanded: classes.collapseExpanded,
                        }, expanded: expanded, onChange: handleExpansionChange },
                        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMore, null), id: "panelExpandButtonId" },
                            React.createElement(Typography, { variant: "body2" }, textLabels.accordionLabel)),
                        React.createElement(AccordionDetails, { unselectable: "on", className: classes.advancedSettings }, React.Children.map(children, function (child) {
                            if (typeof child === "string") {
                                return child;
                            }
                            // if children are to be disabled while working,
                            // ensure that "disabled" props is implemented
                            return React.cloneElement(child, {
                                disabled: working,
                            });
                        })))),
                    React.createElement(Button, { disabled: working, type: "submit", variant: "contained", color: "secondary", className: classes.button, id: "connectButtonId" }, working ? React.createElement(CircularProgress, { size: "1em" }) : textLabels.connectButtonLabel),
                    React.createElement("input", { hidden: true, type: "submit", value: "SUBMIT", ref: buttonRef }),
                    showForgotPassword && (React.createElement("div", { className: classes.forgotPasswordLabel },
                        React.createElement(KortexLabel, { disabled: working, id: "forgotPasswordButton", variant: "standard", label: textLabels.forgotPasswordLabel, onClick: handleForgotPassword }))))),
            React.createElement("div", { className: classes.footer, id: "footerId" },
                textLabels.bottomLeftLabel && (React.createElement(Typography, { variant: "caption", className: classes.footerTextLeft }, textLabels.bottomLeftLabel)),
                textLabels.bottomRightLabel && (React.createElement(Typography, { variant: "caption", className: classes.footerTextRight }, textLabels.bottomRightLabel))))));
}
export default KortexDialogLogin;
