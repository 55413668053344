import { IOperatorJobProcessInfo, removeObjectFromArray, upsertObjectFromArray } from "@kortex/aos-common";
import { getOperatorJobProcessesSortedById } from "@kortex/aos-ui/components/pages/SchedulerOperator/utilites/utilities";

import { WorkScheduleState, WorkScheduleActions, WorkScheduleActionType, IWorkScheduleState } from "./work-schedule-types";

const initialState: WorkScheduleState = {
    jobProcesses: [],
};

export function workScheduleReducer(state: WorkScheduleState = initialState, action: WorkScheduleActions): WorkScheduleState {
    switch (action.type) {
        case WorkScheduleActionType.CLEAR_WORK_Schedule:
            return {
                ...initialState,
            };
        case WorkScheduleActionType.UPDATE_JOB_PROCESSES:
            return {
                ...state,
                jobProcesses: [...action.value],
            };
        case WorkScheduleActionType.INSERT_JOB_PROCESS:
            return {
                ...state,
                jobProcesses: getOperatorJobProcessesSortedById(updateJobProcessesList(state, action.value)),
            };
        case WorkScheduleActionType.UPDATE_JOB_PROCESS:
            if (!action.value.length) {
                return state;
            }
            return {
                ...state,
                jobProcesses: getOperatorJobProcessesSortedById(updateJobProcessesList(state, action.value)),
            };
        case WorkScheduleActionType.UPDATE_JOB_PROCESS_LIST:
            if (!action.value.length) {
                return state;
            }
            const operatorJobProcessesUpdated = {
                ...state,
                jobProcesses: upsertObjectFromArray(state.jobProcesses, action.value, function (jobProcess) {
                    return jobProcess.jobProcessId === this.jobProcessId;
                }),
            };
            return {
                jobProcesses: getOperatorJobProcessesSortedById(operatorJobProcessesUpdated.jobProcesses),
            };
        case WorkScheduleActionType.DELETE_JOB_PROCESS:
            return {
                ...state,
                jobProcesses: removeObjectFromArray(state.jobProcesses, action.value, function (jobProcess) {
                    return jobProcess.jobProcessId == this.jobProcessId;
                }),
            };
        default:
            return state;
    }
}

/* 
    Used when release date of the process is updated.
    Context: The list of job processes contained in the redux corresponds either to the filtered work processes,
            or to the job processes corresponding to the default filter as well as to the "offset" of the query(s).
    Purpose: A job process will only be inserted if it is linked to the worker process already contained in the redux. 
            Otherwise it is not relevant to add it in the redux.
*/
function updateJobProcessesList(state: IWorkScheduleState, value: IOperatorJobProcessInfo[]): IOperatorJobProcessInfo[] {
    const jobIdList = state.jobProcesses.map((jobProcess) => jobProcess.jobId);
    const jobProcessToBeInsert: IOperatorJobProcessInfo[] = [];
    for (const jobProcess of value) {
        if (jobIdList.includes(jobProcess.jobId)) {
            jobProcessToBeInsert.push(jobProcess);
        }
    }
    return upsertObjectFromArray(state.jobProcesses, jobProcessToBeInsert, function (jobProcess) {
        return jobProcess.jobProcessId === this.jobProcessId;
    });
}
