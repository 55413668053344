import { KortexDialogConfirmation, KortexTextField, theme } from "@aos/react-components";
import MomentUtils from "@date-io/moment";
import {
    IProcessTrainingCertificationForm,
    IUserDbModel,
    ProcessId,
    UserId,
    getDateFormat,
    getDateFromTimestamp,
    getTimestampFromDateStr,
} from "@kortex/aos-common";
import { UserValidationDialog } from "@kortex/aos-ui/components/core/UserValidationDialog";
import { useSnackbar } from "@kortex/aos-ui/components/layout/snackbarConfigurator";
import { useClientService } from "@kortex/aos-ui/hooks/useClientService";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesUserSession } from "@kortex/aos-ui/redux/effects";
import {
    useSelectorLanguage,
    useSelectorProcesses,
    useSelectorTrainingCertificationPendingList,
    useSelectorTreeProcess,
} from "@kortex/aos-ui/redux/selectors";
import { trainingCertificationPendingListInsertedAction } from "@kortex/aos-ui/redux/training-certification-pending-manager/training-certification-pending-actions";
import { client } from "@kortex/aos-ui/utilitites/kortex-client/client";
import { deepClone } from "@kortex/utilities";
import { Checkbox, Fab, Tooltip, Typography, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { FC, useEffect, useState } from "react";

import TrainingFormProcessList from "../../utilities/TrainingFormProcessList";

import TrainingCertificationFormUserSelector from "./trainingCertificationFormUserSelector/TrainingCertificationFormUserSelector";

const useStyles = makeStyles({
    fab: {
        margin: "5px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
    containerFab: {
        height: "60px",
        display: "flex",
        justifyContent: "flex-end",
    },

    // DIALOG
    certificationReferenceContainer: {
        display: "flex",
        marginTop: "10px",
    },
    date: {
        marginTop: "5px",
    },
    certificationCriteriaContainer: {
        margin: "5px 0px 15px 0px",
    },
    certificationCriteriaTilte: {
        display: "flex",
        alignItems: "center",
    },
    certificationCriteriaContent: {
        marginLeft: "50px",
    },
});

const TrainingCertificationFormDialog: FC = () => {
    const classes = useStyles();
    const translate = useTranslate();

    const dispatch = useThunkDispatch();
    const language = useSelectorLanguage();
    const processes = useSelectorProcesses();
    const snackbar = useSnackbar();
    const treeNodes = useSelectorTreeProcess();
    const userInfo = useEntitiesUserSession();

    const getTrainedUsers = useClientService("users", "getTrainedUsers");
    const trainingCertificationPendingList = useSelectorTrainingCertificationPendingList();
    const trainingCertificationInsert = useClientService("trainingCertification", "insert");

    const [open, setOpen] = useState<boolean>(false);
    const [selectedProcesses, setSelectedProcesses] = useState<IProcessTrainingCertificationForm[]>([]);
    const [certifiedUserIdSelected, setCertifiedUserIdSelected] = useState<UserId | undefined>(undefined);
    const [trainingCertificationReference, setTrainingCertificationReference] = useState<string>("");
    const [trainingCertificationComment, setTrainingCertificationComment] = useState<string>("");
    const [date, setDate] = useState<number>(Date.now());
    const [trainerSignatureDialogOpen, setTrainerSignatureDialogOpen] = useState<boolean>(false);
    const [attestsChecked, setAttestsChecked] = useState<boolean>(false);
    const [userIdToExcludeList, setUserIdToExcludeList] = useState<UserId[]>(userInfo ? [userInfo.userId] : []);

    const disabled = selectedProcesses.length === 0 || !attestsChecked || !Boolean(certifiedUserIdSelected);
    const criteria = translate("training.trainingCertificationCriteria").split("\n");
    const context = translate("training.trainingCertificationAuditorSignatureContext");

    useEffect(() => {
        if (open === false) {
            setCertifiedUserIdSelected(undefined);
            setSelectedProcesses([]);
            setTrainingCertificationReference("");
        }
    }, [open]);

    useEffect(() => {
        if (selectedProcesses.length > 0) {
            const processIds = selectedProcesses.map((process) => process.process.processId);
            const updateUserIdToExcludeList = deepClone(userIdToExcludeList);

            // Search for users in the training certification pending list for the selected processes.
            const trainingCertificationPendings = trainingCertificationPendingList.filter((trainingCertificationPending) => {
                let found = false;
                for (const process of trainingCertificationPending.processes) {
                    if (processIds.includes(process.processId)) {
                        found = true;
                        break;
                    }
                }
                return found;
            });
            const userIdsTrainingCertificationPending: UserId[] = [];
            for (const trainingCertificationPending of trainingCertificationPendings) {
                userIdsTrainingCertificationPending.push(trainingCertificationPending.user.userId);
                if (!updateUserIdToExcludeList.includes(trainingCertificationPending.user.userId)) {
                    updateUserIdToExcludeList.push(trainingCertificationPending.user.userId);
                }
            }

            // Search all users with an training for the selected processes
            client.services.training
                .getUserIdsOfTrainingsByOriginalProcessIds({
                    processIds: processIds,
                })()
                .then((trainingsOfTheProcess) => {
                    for (const training of trainingsOfTheProcess) {
                        // Add user id in th exclude list if is certified.
                        if (training.certified && !userIdsTrainingCertificationPending.includes(training.userId)) {
                            updateUserIdToExcludeList.push(training.userId);
                        }
                    }
                });

            setUserIdToExcludeList(updateUserIdToExcludeList);
        }
    }, [selectedProcesses]);

    /* ----------------------------------------------------------------------------------------------- */
    /* DIALOG ---------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------- */
    /**
     * Handle called when the user cancel the dialog
     */
    const handleCancel = (): void => {
        setOpen(false);
    };

    /**
     * Handle called when the user cancel the dialog
     */
    const handleTrainerSignatureCancel = (): void => {
        setTrainerSignatureDialogOpen(false);
    };

    /**
     * Handle called when the user confir the dialog
     */
    const handleConfirm = (): void => {
        setTrainerSignatureDialogOpen(true);
    };

    /**
     * Handle called when the trainer has signed the certificate
     */
    const handleTrainerHasSigned = async (): Promise<void> => {
        if (certifiedUserIdSelected && selectedProcesses.length > 0) {
            await trainingCertificationInsert({
                context: context,
                trainingCertification: {
                    comment: trainingCertificationComment,
                    reference: trainingCertificationReference,
                    date: date,
                },
                trainingCertificationProcessIds: selectedProcesses.map((process) => process.process.processId),
                certifiedUserId: certifiedUserIdSelected,
            }).then((res) => {
                setTrainerSignatureDialogOpen(false);

                if (res) {
                    setOpen(false);
                    dispatch(trainingCertificationPendingListInsertedAction([...res]));
                }
            });
        }

        return void 0;
    };

    /**
     * Opens dialog
     */
    const handleOpenDialog = (): void => {
        setOpen(true);
    };

    /**
     * handles the value changed of Training Certification Reference
     */
    const handleTrainingCertificationReference = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setTrainingCertificationReference(event.target.value);
    };

    /**
     * handles the value changed of Training Certification Comment
     */
    const handleTrainingCertificationComment = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setTrainingCertificationComment(event.target.value);
    };

    /* ----------------------------------------------------------------------------------------------- */
    /* CERTIFIED USER ------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------- */
    const handleUserIdSelectorDialogConfirm = (selectedUserIdDialog: UserId): void => {
        setCertifiedUserIdSelected(selectedUserIdDialog);
    };

    /**
     * Deletes user Id
     */
    const handleDeleteUserId = (): void => {
        setCertifiedUserIdSelected(undefined);
    };

    /* ----------------------------------------------------------------------------------------------- */
    /* PROCESS --------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------- */
    /**
     * Check if the auditor can add the selected process to the certification form
     */
    const validateAuditorQualification = async (processId: ProcessId): Promise<boolean> => {
        if (!userInfo?.userId) return false;

        // Get trained and certified users
        const trainedUsers = await getTrainedUsers({
            processId,
            certified: true,
            userIds: [userInfo.userId],
        }).catch(() => [] as IUserDbModel[]);

        if (!trainedUsers?.length) return false;

        // Validate
        return trainedUsers.some((user) => user.userId === userInfo.userId);
    };

    /**
     * handle process selector,
     */
    const handleProcessIdsSelected = async (selectedProcessesVersionId: ProcessId[]): Promise<void> => {
        let nameOfInvalidProcess: string | undefined;

        // Validate if the auditor is qualified for the selected processes
        for (const processId of selectedProcessesVersionId) {
            if (!(await validateAuditorQualification(processId))) {
                nameOfInvalidProcess = treeNodes.find(
                    (treeNode) => treeNode.treeNodeId === processes.find((process) => process.processId === processId)?.treeNodeId
                )?.label;

                break;
            }
        }

        if (nameOfInvalidProcess) {
            // Auditor is not qualified
            snackbar.warning(`${translate("training.trainingCertificationAuditorNotQualified")} ${nameOfInvalidProcess}`);

            // stop handle
            return void 0;
        }

        const processesSelected = deepClone(selectedProcesses);
        const processIdsSelected = selectedProcesses.map((process) => process.process.processId);
        for (const processId of selectedProcessesVersionId) {
            // validate that the process is not already selected
            if (!processIdsSelected.includes(processId)) {
                const process = processes.find((process) => process.processId === processId);
                if (process) {
                    const treeNode = treeNodes.find((treeNode) => treeNode.treeNodeId === process.treeNodeId);
                    if (treeNode) {
                        processesSelected.push({ process, treeNode });
                    }
                }
                processIdsSelected.push(processId);
            }
        }

        setSelectedProcesses(processesSelected);
    };

    /**
     * Deletes process Id
     */
    const handleDeleteProcess = (processIdToBeDeleted: ProcessId): void => {
        const updateProcessesSelected = deepClone(selectedProcesses);
        const index = updateProcessesSelected.findIndex((process) => process.process.processId === processIdToBeDeleted);
        updateProcessesSelected.splice(index, 1);
        setSelectedProcesses(updateProcessesSelected);
    };

    /**
     * Handle from date value change event
     */
    const handleFromDateOnChange = (date: MaterialUiPickersDate): void => {
        setDate(getTimestampFromDateStr(String(date)));
    };

    /**
     * Called when the attests checkbox is checked/unchecked
     */
    const handleAttests = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        setAttestsChecked(checked);
    };

    return (
        <>
            {/* ICON TO OPEN DIALOG */}
            <div className={classes.containerFab}>
                <Fab className={classes.fab} size="medium" id="addTrainingCertificationId" color="secondary" onClick={handleOpenDialog}>
                    <Tooltip classes={{ tooltip: classes.tooltip }} title={translate("training.addTrainingCertification")} placement="left">
                        <AddIcon />
                    </Tooltip>
                </Fab>
            </div>

            {/* DIALOG */}
            <KortexDialogConfirmation
                open={open}
                textLabels={{
                    titleLabel: translate("training.trainingCertificationFormDialogTitle"),
                    cancelButtonLabel: translate("general.cancel"),
                    proceedButtonLabel: translate("general.confirm"),
                }}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                closeOnEscape={true}
                confirmDisabled={disabled}
                dialogProps={{
                    maxWidth: "lg",
                }}
            >
                {/* REF AND DATE TRAINING CERTIFICATION */}
                <div className={classes.certificationReferenceContainer}>
                    <KortexTextField
                        TextFieldProps={{
                            id: "trainingCertificationReferenceInputId",
                            fullWidth: true,
                        }}
                        variant="outlined"
                        label={translate("training.trainingCertificationReference")}
                        value={trainingCertificationReference}
                        onChange={handleTrainingCertificationReference}
                    />
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                        <DatePicker
                            className={classes.date}
                            allowKeyboardControl={true}
                            format={getDateFormat(false)}
                            id="fromDateId"
                            label={translate("general.date")}
                            onChange={handleFromDateOnChange}
                            value={getDateFromTimestamp(date)}
                            variant="inline"
                            inputVariant="outlined"
                            disableFuture={true}
                            clearLabel={translate("general.clear")}
                            cancelLabel={translate("general.cancel")}
                            okLabel={translate("general.select")}
                        />
                    </MuiPickersUtilsProvider>
                </div>

                {/* COMMENT */}
                <KortexTextField
                    TextFieldProps={{
                        id: "trainingCertificationCommentInputId",
                        multiline: true,
                        rowsMax: 10,
                        fullWidth: true,
                    }}
                    label={translate("training.trainingCertificationComment")}
                    value={trainingCertificationComment}
                    onChange={handleTrainingCertificationComment}
                />

                {/* PROCESS LIST */}
                <TrainingFormProcessList
                    title={translate("training.processesTrainingCertification")}
                    handleDeleteProcess={handleDeleteProcess}
                    handleProcessIdsSelected={handleProcessIdsSelected}
                    selectedProcesses={selectedProcesses}
                />

                {/* CERTIFIED USER */}
                <TrainingCertificationFormUserSelector
                    // disabled={selectedProcesses.length === 0}
                    title={translate("training.trainingCertificationCertifiedUser")}
                    userIdSelected={certifiedUserIdSelected}
                    userIdToExclude={userIdToExcludeList}
                    handleDeleteUserId={handleDeleteUserId}
                    handleUserIdSelectorDialogConfirm={handleUserIdSelectorDialogConfirm}
                />

                {/* CERTIFICATION CRITERIA  */}
                <div className={classes.certificationCriteriaContainer}>
                    <div className={classes.certificationCriteriaTilte}>
                        <Checkbox checked={attestsChecked} onChange={handleAttests} required={true} id={"criteriaCheckBoxId"} />
                        <Typography>{translate("training.trainingCertificationCriteriaCheckbox")}</Typography>
                    </div>
                    <div className={classes.certificationCriteriaContent}>
                        <ul>
                            {criteria.map((line, index) => (
                                <React.Fragment key={index}>
                                    <li>
                                        <Typography>{line}</Typography>
                                    </li>
                                </React.Fragment>
                            ))}
                        </ul>
                    </div>
                </div>
            </KortexDialogConfirmation>
            <UserValidationDialog
                open={trainerSignatureDialogOpen}
                isElectronicSignature={true}
                electronicSignatureContext={context}
                onClose={handleTrainerSignatureCancel}
                preSelectedUserId={userInfo ? userInfo.userId : 0}
                onValidate={handleTrainerHasSigned}
            />
        </>
    );
};

export default TrainingCertificationFormDialog;
