export var ReportTypeEnum;
(function (ReportTypeEnum) {
    ReportTypeEnum[ReportTypeEnum["PROCESS_SUMMARY"] = 0] = "PROCESS_SUMMARY";
    ReportTypeEnum[ReportTypeEnum["PROCESS_DETAILS"] = 1] = "PROCESS_DETAILS";
    ReportTypeEnum[ReportTypeEnum["PROCESS_STEPS"] = 2] = "PROCESS_STEPS";
    ReportTypeEnum[ReportTypeEnum["AUDIT_TRAIL"] = 3] = "AUDIT_TRAIL";
    ReportTypeEnum[ReportTypeEnum["DATA_STORE_VALUE"] = 4] = "DATA_STORE_VALUE";
    ReportTypeEnum[ReportTypeEnum["DATA_STORE_TABLE"] = 5] = "DATA_STORE_TABLE";
    ReportTypeEnum[ReportTypeEnum["DATA_STORE_FILE"] = 6] = "DATA_STORE_FILE";
    ReportTypeEnum[ReportTypeEnum["FAILURE_REPORT"] = 7] = "FAILURE_REPORT";
    ReportTypeEnum[ReportTypeEnum["ELECTRONIC_SIGNATURE"] = 8] = "ELECTRONIC_SIGNATURE";
    ReportTypeEnum[ReportTypeEnum["ENTITY"] = 9] = "ENTITY";
    ReportTypeEnum[ReportTypeEnum["TRAINING"] = 10] = "TRAINING";
})(ReportTypeEnum || (ReportTypeEnum = {}));
export const reportTypeListInfo = [
    {
        reportType: ReportTypeEnum.PROCESS_SUMMARY,
        name: "Summary",
        translateKeys: ["reporting.type.summary"],
    },
    {
        reportType: ReportTypeEnum.PROCESS_DETAILS,
        name: "Process ",
        translateKeys: ["reporting.type.process"],
    },
    {
        reportType: ReportTypeEnum.PROCESS_STEPS,
        name: "Process Step",
        translateKeys: ["reporting.type.process.step"],
    },
    {
        reportType: ReportTypeEnum.FAILURE_REPORT,
        name: "Failure Report",
        translateKeys: ["reporting.type.rework"],
    },
    {
        reportType: ReportTypeEnum.DATA_STORE_VALUE,
        name: "DataStore Table",
        translateKeys: ["reporting.type.data.store.value"],
    },
    {
        reportType: ReportTypeEnum.DATA_STORE_TABLE,
        name: "DataStore Table",
        translateKeys: ["reporting.type.data.store.table"],
    },
    {
        reportType: ReportTypeEnum.AUDIT_TRAIL,
        name: "Process Audit Trail",
        translateKeys: ["reporting.type.audit.trail"],
    },
    {
        reportType: ReportTypeEnum.ELECTRONIC_SIGNATURE,
        name: "Electronic Signatures",
        translateKeys: ["reporting.type.electronic.signature"],
    },
    {
        reportType: ReportTypeEnum.ENTITY,
        name: "Entity",
        translateKeys: ["reporting.type.entity"],
    },
    {
        reportType: ReportTypeEnum.TRAINING,
        name: "Training",
        translateKeys: [
            "reporting.type.trainingReadingCertificate",
            "reporting.type.trainingTrainingCertificate",
            "reporting.type.trainingCertification",
        ],
    },
];
