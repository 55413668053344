import shortid from "shortid";
import { EnumElementType } from "../WorkInstructionsEnums";
export class WorkInstructionsElementConfig {
    constructor(type, config, zIndex) {
        this._id = shortid.generate();
        this.cropBottom = 0;
        this.cropLeft = 0;
        this.cropRight = 0;
        this.cropTop = 0;
        this.extendedProps = config;
        this.height = 40;
        this.rotation = 0;
        this.templateId = "";
        this.type = type;
        this.width = 40;
        this.x = 40;
        this.y = 40;
        this.zIndex = zIndex;
        this.scale = 1;
        switch (type) {
            case EnumElementType.FORM:
                this.height = 230;
                this.width = 400;
                break;
            case EnumElementType.IMAGE:
                this.height = 100;
                this.width = 100;
                break;
            case EnumElementType.LINE:
                this.height = 60;
                this.width = 60;
                break;
            case EnumElementType.MARKER:
                this.height = 50;
                this.width = 50;
                break;
            case EnumElementType.PDF:
                this.x = 0;
                this.y = 0;
                this.height = 300;
                this.width = 300;
                break;
            case EnumElementType.SHAPE:
                this.height = 100;
                this.width = 100;
                break;
            case EnumElementType.TEXT:
                this.height = 100;
                this.width = 100;
                break;
            case EnumElementType.THREE_D:
                this.height = 400;
                this.width = 400;
                break;
            case EnumElementType.VIDEO:
                this.height = 360;
                this.width = 640;
                break;
            default:
                break;
        }
    }
}
