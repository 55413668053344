export const file = {
    rpc: {
        insert: {
            funcId: 8001,
            funcName: "fileInsert",
        },
        inserted: {
            funcId: 8002,
            funcName: "fileInserted",
        },
        one: {
            funcId: 8003,
            funcName: "fileOne",
        },
        oneByTreeNode: {
            funcId: 8004,
            funcName: "fileOneByTreeNode",
        },
        update: {
            funcId: 8005,
            funcName: "fileUpdate",
        },
        updated: {
            funcId: 8006,
            funcName: "fileUpdated",
        },
        oneByFileId: {
            funcId: 8007,
            funcName: "fileOneByFileId",
        },
    },
    serviceId: 80,
    serviceVersion: 1,
};
