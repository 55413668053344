/**
 * Create an asynchronous operation whose start has been delayed.
 *
 * @param {DeferredPromiseExecutor} executor - A callback used to initialize the deferred promise.
 * @returns {IDeferredPromise} An asynchronous operation whose start has been delayed.
 */
export function defer(executor) {
    // Initialize local attributes
    let _done = false, _resolve = () => void 0, _reject = () => void 0, _started = false;
    // Instanciate a promise that does nothing for the moment.
    const _promise = new Promise((resolve, reject) => {
        // Assign Promise's resolve/reject to the local resolve/reject attributes
        _resolve = resolve;
        _reject = reject;
    }).finally(() => {
        // Update flags
        _started = false;
        _done = true;
        // Reset resolve/reject
        _resolve = () => void 0;
        _reject = () => void 0;
    });
    /**
     * Attaches a callback to the Promise for only its rejection.
     */
    // Added an underscore because "catch" is a reserved keyword
    const _catch = function (onRejected) {
        return _promise.catch(onRejected);
    };
    /**
     * Attaches a callback to the Promise that is invoked when it is settled (fulfilled or rejected).
     * The resolved value cannot be modified from the callback.
     */
    // Added an underscore because "finally" is a reserved keyword
    const _finally = function (onFinally) {
        return _promise.finally(onFinally);
    };
    /**
     * Attaches callbacks to the Promise for its resolution and/or rejection.
     */
    // Added an underscore only to be similar to _catch and _finally
    const _then = function (onFullfilled, onRejected) {
        return _promise.then(onFullfilled, onRejected);
    };
    /**
     * Starts the Promise.
     */
    function start() {
        // Update flags
        _started = true;
        _done = false;
        // Call the Promise executor
        executor(_resolve, _reject);
        // Return the Promise
        return _promise;
    }
    return {
        // Symbol
        [Symbol.toStringTag]: "Promise",
        // Attributes
        get done() {
            return _done;
        },
        get promise() {
            return _promise;
        },
        get started() {
            return _started;
        },
        // Methods
        catch: _catch,
        finally: _finally,
        resolve: _resolve,
        reject: _reject,
        start,
        then: _then,
    };
}
