import { JobStatusEnum } from "./JobStatusEnum";
export var ProcessRunStatusEnum;
(function (ProcessRunStatusEnum) {
    ProcessRunStatusEnum["DRAFT"] = "DRAFT";
    ProcessRunStatusEnum["READY"] = "READY";
    ProcessRunStatusEnum["IN_PROGRESS"] = "INPRG";
    ProcessRunStatusEnum["PASS"] = "PASS";
    ProcessRunStatusEnum["FAIL"] = "FAIL";
})(ProcessRunStatusEnum || (ProcessRunStatusEnum = {}));
/**
 * Return run status based on status flag end timestamp
 *
 * @param {JobStatusEnum} jobStatus - Status of the jobProcess container
 * @param {boolean} pass - Flag that tells that the run completed with pass
 * @param {boolean} fail - Flag that tells that the run completed with fail
 * @param {number} startedOn - Timestamp that shows if the process as start to run
 *
 * @returns {ProcessRunStatusEnum} - status
 */
export function getJobProcessRunStatus(jobStatus, pass, fail, startedOn, option) {
    if (jobStatus === JobStatusEnum.DRAFT) {
        return ProcessRunStatusEnum.DRAFT;
    }
    else if (pass > 0 && !option) {
        // is not Batch
        return ProcessRunStatusEnum.PASS;
    }
    else if (option && option.quantityProduced && option.jobQty && option.quantityProduced >= option.jobQty) {
        // is Batch
        return ProcessRunStatusEnum.PASS;
    }
    else if (pass === 0 && fail > 0) {
        return ProcessRunStatusEnum.FAIL;
    }
    else if (startedOn) {
        return ProcessRunStatusEnum.IN_PROGRESS;
    }
    else {
        return ProcessRunStatusEnum.READY;
    }
}
