import { theme } from "@aos/react-components";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Backdrop, CircularProgress, List, ListSubheader, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import TrainingsPendingCardHeader from "../utilities/TrainingsPendingCardHeader";

import { useTrainingCertificateContext } from "./TrainingCertificateContext";
import TrainingPendingCard from "./TrainingPendingCard";
import TrainingCertificateFormDialog from "./trainingCertificateFormDialog/TrainingCertificateFormDialog";

const useStyles = makeStyles({
    root: {
        marginTop: "3px",
        padding: "0px 12px 0px 10px",
        height: "calc(100vh - 213px)", // Header (64px) + Tabs (68px) + margins (23px) + add certificate (58px)
        overflow: "auto",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 4,
    },
});

const TrainingCertificateTab: FC = () => {
    const classes = useStyles();
    const translate = useTranslate();

    const { Observer, entities, hasNext, isDataLoading, observerId } = useTrainingCertificateContext();

    return (
        <div id={"trainingCertificateTabId"}>
            <div className={classes.root} id="userTrainingTabId">
                <Backdrop className={classes.backdrop} open={isDataLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <List
                    disablePadding={true}
                    /* ----------------------------------------------------------------------------------------------- */
                    /* HEADER ---------------------------------------------------------------------------------------- */
                    /* ----------------------------------------------------------------------------------------------- */
                    subheader={
                        <ListSubheader disableGutters={true}>
                            <TrainingsPendingCardHeader
                                columnTitle={translate("training.trainingCertificateTrainer")}
                                useContext={useTrainingCertificateContext}
                            />
                        </ListSubheader>
                    }
                >
                    {/* ----------------------------------------------------------------------------------------------- */}
                    {/* TRAINING ROWS --------------------------------------------------------------------------------- */}
                    {/* ----------------------------------------------------------------------------------------------- */}
                    {entities.map((trainingPending, index) => (
                        <div key={`trainingsPending${index}`}>
                            <TrainingPendingCard index={index} trainingPending={trainingPending} />
                            {trainingPending.trainingPendingId === observerId && !isDataLoading && hasNext && <Observer />}
                        </div>
                    ))}
                </List>
            </div>

            <TrainingCertificateFormDialog />
        </div>
    );
};

export default TrainingCertificateTab;
