import { useEllipsisTooltip } from "@kortex/aos-ui/hooks/useEllipsisTooltip";
import { Collapse, IconButton, TableCell, TableRow, Typography, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import LaunchIcon from "@material-ui/icons/Launch";
import React, { FC, MouseEvent } from "react";

const useStyles = makeStyles({
    tableCellCommunique: {
        paddingBottom: 0,
        paddingTop: 0,
    },
    tableCellTypography: {
        maxWidth: "250px",
    },
    typography: {
        overflow: "hidden",
        paddingRight: "20px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    },
    typographyCommunique: {
        maxWidth: "672px", // Drawer max width (700px) - padding (2 * 10px) - table cell padding (2 * 4px)
        padding: "10px",
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
});

interface IOwnProps {
    actionLabel: string;
    communique: string;
    isDraft: boolean;
    onGoToStep: (event: MouseEvent<HTMLButtonElement>) => void;
    onDeleteCommunique: () => void;
    stepLabel: string;
    trainingCommuniqueProcessVersion: string;
    id: string;
}

const ProcessTrainingCommuniqueRow: FC<IOwnProps> = (props) => {
    const { actionLabel, communique, isDraft, onGoToStep, onDeleteCommunique, stepLabel, trainingCommuniqueProcessVersion, id } = props;

    const classes = useStyles();
    const [EllipsisTooltipActionLabel, actionLabelRef] = useEllipsisTooltip();
    const [EllipsisTooltipStepLabel, stepLabelRef] = useEllipsisTooltip();

    const [open, setOpen] = React.useState(false);

    const handleCollapseOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        setOpen(!open);
    };

    const handleDeleteCommunique = (): void => {
        onDeleteCommunique();
    };

    return (
        <>
            <TableRow>
                {/* GOTO STEP BUTTON */}
                <TableCell>
                    <IconButton onClick={onGoToStep} size="small">
                        <LaunchIcon id={`goToCommuniqueIcon${id}Id`} />
                    </IconButton>
                </TableCell>
                {/* ACTION LABEL */}
                <TableCell className={classes.tableCellTypography}>
                    <EllipsisTooltipActionLabel label={actionLabel}>
                        <Typography className={classes.typography} ref={actionLabelRef} variant="body2" id={`actionLabelCommunique${id}Id`}>
                            {actionLabel}
                        </Typography>
                    </EllipsisTooltipActionLabel>
                </TableCell>
                {/* STEP LABEL */}
                <TableCell className={classes.tableCellTypography}>
                    <EllipsisTooltipStepLabel label={stepLabel}>
                        <Typography className={classes.typography} ref={stepLabelRef} variant="body2" id={`stepLabelCommunique${id}Id`}>
                            {stepLabel}
                        </Typography>
                    </EllipsisTooltipStepLabel>
                </TableCell>
                {/* Process version */}
                <TableCell className={classes.tableCellTypography}>
                    <EllipsisTooltipStepLabel label={stepLabel}>
                        <Typography
                            className={classes.typography}
                            ref={stepLabelRef}
                            variant="body2"
                            id={`trainingCommuniqueProcessVersion${id}Id`}
                        >
                            {trainingCommuniqueProcessVersion}
                        </Typography>
                    </EllipsisTooltipStepLabel>
                </TableCell>
                {/* Arrow collapse */}
                <TableCell className={classes.tableCellTypography}>
                    <IconButton onClick={handleCollapseOpen} size="small">
                        {open ? <ArrowUpIcon id={`collapseIcon${id}Id`} /> : <ArrowDownIcon id={`uncollapseIcon${id}Id`} />}
                    </IconButton>
                </TableCell>
                {/* DELETE: COMMUNIQUE */}
                <TableCell className={classes.tableCellTypography}>
                    {isDraft && (
                        <IconButton onClick={handleDeleteCommunique} size="small">
                            <DeleteIcon id={`deleteCommuniqueIcon${id}Id`} />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
            {/* EXPAND: COMMUNIQUE */}
            <TableRow>
                <TableCell className={classes.tableCellCommunique} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit={true}>
                        <Typography className={classes.typographyCommunique} variant="body2" id={`communique${id}Id`}>
                            {communique}
                        </Typography>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default ProcessTrainingCommuniqueRow;
