import { FailureTicketForkState, IJobUiModel, IReworkUiModel } from "@kortex/aos-common";
import { Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    outputContainer: {
        height: "-webkit-fill-available",
        padding: "32px",
        width: "-webkit-fill-available",
    },
    outputText: {
        marginBottom: "32px",
        textAlign: "center",
    },
    outputTitle: {
        marginBottom: "32px",
    },
});

export interface IOwnProps {
    actionState: FailureTicketForkState;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function FailureTicketForkPlayer(props: IOwnProps): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();
    const [createdJob, setCreatedJob] = useState<IJobUiModel | undefined>(undefined);
    const [createdFailureTicket, setCreatedFailureTicket] = useState<IReworkUiModel | undefined>(undefined);

    useEffect((): void => {
        setCreatedFailureTicket(props.actionState.createdFailureTicket);
        setCreatedJob(props.actionState.createdJob);
    }, [props.actionState]);

    return (
        <div className={classes.outputContainer}>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.failureTicketCreate.createFailureTicket")}
            </Typography>
            <Typography className={classes.outputText} variant="h3">
                {`${translate("player.failureTicketCreate.createFailureTicket")}: ${createdFailureTicket?.reworkId}`}
            </Typography>
            <Typography className={classes.outputText} variant="h3">
                {`${translate("player.failureTicketCreate.createdJob")}: ${createdJob?.jobId}`}
            </Typography>
        </div>
    );
}
