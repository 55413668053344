import { useContext, useEffect, useState } from "react";
import shortid from "shortid";

import { KeybindManagerContext } from "./useKeybindManager";

interface IOptions {
    /**
     * If `true`, the event will be moved at the end of the the stack (highest priority) each time `enabled` is updated.
     */
    important?: boolean;
}

/**
 * Stop any key event (`useKeybind`, `useKeybindCopyPaste`) that was created before this hook from being triggered when `enabled` is `true`.
 * `enabled` is `true` by default.
 */
export function useStopKeybindPropagation(enabled = true, options: IOptions = {}): void {
    const { important = false } = options;

    const { pushLayer, removeLayer, setLayerPropagation } = useContext(KeybindManagerContext);
    const [id] = useState(shortid.generate());

    /**
     * On mount, add the layer to the keybind manager.
     * On unmount, remove the layer from the keybind manager.
     */
    useEffect(() => {
        pushLayer(id, enabled);

        return (): void => {
            removeLayer(id);
        };
    }, []);

    /**
     * Update propagation when `enabled` is updated.
     * If `important` is true, it will instead remove the current layer and create a new one at the end of the stack.
     */
    useEffect((): void | (() => void) => {
        if (important && enabled) {
            pushLayer(id, enabled);

            return (): void => {
                removeLayer(id);
            };
        } else setLayerPropagation(id, enabled);
    }, [enabled, important]);
}
