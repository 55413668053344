import { IWorkInstructionsElementConfig, TWorkInstructionsExtendedConfig } from "@kortex/aos-common";
import { createContext } from "react";

import { IWorkInstructionsElementMenuItem } from "./elementMenuItem";

interface IWorkInstructionsEditorContext {
    elementMenuItems: IWorkInstructionsElementMenuItem[];
    setElementMenuItems: (items: IWorkInstructionsElementMenuItem[]) => void;
    updateElement: (element: IWorkInstructionsElementConfig<TWorkInstructionsExtendedConfig>) => void;
}

export const WorkInstructionsEditorContext = createContext<IWorkInstructionsEditorContext>({
    elementMenuItems: [],
    setElementMenuItems: () => void 0,
    updateElement: () => void 0,
});
