import { greyPalette, magentaPalette, theme } from "@aos/react-components";
import {
    EnumElementType,
    IWorkInstructionsElementConfig,
    IWorkInstructionsFormItemState,
    IWorkInstructionsState,
    IWorkInstructionsVideoProps,
    ProcessActionStepWorkInstructions,
    TWorkInstructionsExtendedConfig,
    WorkInstructionsStepState,
} from "@kortex/aos-common";
import { deepClone } from "@kortex/utilities";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import WorkInstructionsElement from "../../../../../components/pages/ProcessEditor/ProcessEditor/ActionEditors/WorkInstructions/Elements/Element/WorkInstructionsElement";
import { TEXTBOX_SPECIFIC_NAME } from "../../../../../components/pages/ProcessEditor/ProcessEditor/ActionEditors/WorkInstructions/Elements/Form/FormInputs/Textbox/WorkInstructionsFormInputTextbox";
import WorkInstructionsVideoViewer from "../../../../../components/pages/ProcessEditor/ProcessEditor/ActionEditors/WorkInstructions/Elements/Video/Viewer/WorkInstructionsVideoViewer";
import { normalizeSVGDimension } from "../../../../../utilitites/normalizeSVGDimension";

import { WorkInstructionsPlayerTrainingCommuniqueDialog } from "./TrainingCommuniqueDialog";

const SVG_RATIO = 1.6;
const SVG_WIDTH = 1280;
const SVG_HEIGHT = 800;
const V_PADDING = 24;
const H_PADDING = 16;

const useStyles = makeStyles({
    mainContainer: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%",
    },
    reference: {
        width: "400px",
        marginTop: "30px",
    },
    videoRenderer: {
        top: "0px",
        left: "0px",
        position: "absolute",
        pointerEvents: "none",
    },
    trainingCommuniqueBorder: {
        border: `10px solid ${magentaPalette["magenta"]}`,
        pointerEvents: "none", // allows mouse event to go through absolute-positioned element
        position: "absolute",
    },
});

export interface IWorkInstructionsPlayerProps {
    actionProps: ProcessActionStepWorkInstructions;
    actionState?: IWorkInstructionsState;
    disabled?: boolean;

    onFocusNextButton: () => void;
    onStateChange: (state: IWorkInstructionsState) => void;
}

function WorkInstructionsPlayer(props: IWorkInstructionsPlayerProps): JSX.Element {
    const classes = useStyles();
    const { actionProps, actionState, disabled = false, onFocusNextButton, onStateChange } = props;

    const [trainingCommuniqueDialogOpened, setTrainingCommuniqueDialogOpened] = useState<boolean>(false);
    const [mediaSize, setMediaSize] = useState<number>(0);
    const [ratio, setRatio] = useState<number>(0);
    const [textboxes, setTextboxes] = useState<HTMLElement[]>([]);

    const mediaContainerElement = useRef<HTMLDivElement | null>(null);

    const [wiRefId, setWiRefId] = useState("");

    /**
     * Resize the player size when the window is resized
     */
    useEffect((): (() => void) => {
        updateMediaSize();

        window.addEventListener("resize", updateMediaSize);

        return (): void => {
            window.removeEventListener("resize", updateMediaSize);
        };
    }, []);

    useEffect(() => {
        // Display training communique dialog
        if (actionState?.trainingCommuniqueViewed === false) setTrainingCommuniqueDialogOpened(true);
    }, [actionState?.trainingCommuniqueViewed]);

    /**
     * Update the indexOrder when actionProps.workInstructionElements elements is new or refreshed
     */
    useEffect((): void => {
        // If we have an empty WI, no need to prioritize
        if (actionProps.config.workInstructionElements.length === 0) {
            return;
        }

        // Check the first element, if the id is the same
        if (wiRefId === actionProps.config.workInstructionElements[0]._id) {
            return;
        }

        // It's different, let's go, compute elements in the page to focus on the first input element with the index set
        setWiRefId(actionProps.config.workInstructionElements[0]._id);
        if (mediaContainerElement && mediaContainerElement.current) {
            let textboxes: HTMLElement[] = [];

            // Finds all form textboxes from the DOM
            mediaContainerElement.current.querySelectorAll(`[name^="${TEXTBOX_SPECIFIC_NAME}"]`).forEach((element: HTMLElement) => {
                // Do not consider negative tab index
                if (!(element.tabIndex < 0)) {
                    textboxes.push(element);
                }
            });

            // These textboxes will be prioritzed for the tabulation
            const orderedTextboxes = textboxes.filter((textbox) => textbox.tabIndex > 0);

            // Textboxes without a tab index will be placed at the end of the list
            const unorderedTextboxes = textboxes.filter((textbox) => !textbox.tabIndex); // tabIndex equal to 0 or undefined

            // Order them by index
            textboxes = orderedTextboxes.sort((a, b) => a.tabIndex - b.tabIndex).concat(unorderedTextboxes);

            // Focus the first textbox element in the player
            const firstTextbox = textboxes.find((textbox) => textbox.tabIndex >= 0);
            if (firstTextbox && !disabled) {
                firstTextbox.focus();
            }

            // Auto-focus next button if there are no textboxes in the WI
            if (!textboxes.length) {
                onFocusNextButton();
            }

            setTextboxes(textboxes);
        }
    }, [actionProps.config.workInstructionElements]);

    /**
     * Updates the media size
     */
    const updateMediaSize = (): void => {
        const mediaSize =
            mediaContainerElement && mediaContainerElement.current
                ? Math.min(mediaContainerElement.current.clientHeight - V_PADDING, mediaContainerElement.current.clientWidth / SVG_RATIO) -
                  H_PADDING
                : 0;
        setMediaSize(mediaSize);
        setRatio(mediaSize / SVG_HEIGHT);
    };

    /**
     * handles the value change of text fiels
     *
     * @param {string} formId - the form id
     * @returns {(formItemState: IWorkInstructionsFormItemState) => undefined}
     */
    const handleFormElementStateChanged =
        (formId: string): ((formItemState: IWorkInstructionsFormItemState) => void) =>
        (formItemState: IWorkInstructionsFormItemState): void => {
            if (!actionState) return void 0;

            const actionStateCopy = deepClone(actionState);
            const elementIndex = actionStateCopy.workInstructionElementsState.findIndex((element) => element._id === formId);

            if (elementIndex !== -1) {
                const itemIndex = actionStateCopy.workInstructionElementsState[elementIndex].formItemState.findIndex(
                    (item) => item._id === formItemState._id
                );

                if (itemIndex !== -1) {
                    actionStateCopy.workInstructionElementsState[elementIndex].formItemState[itemIndex] = formItemState;
                    onStateChange(actionStateCopy);
                }
            }
        };

    /**
     * Selects the next form textbox in the player
     *
     * @param {string} id - form textbox ID
     */
    const handleSelectNext = (id: string): void => {
        const index = textboxes.findIndex((textbox) => textbox.id === id);

        if (index === -1 || disabled) {
            return;
        } else if (index === textboxes.length - 1) {
            onFocusNextButton();
        } else {
            textboxes[index + 1].focus();
        }
    };

    const handleTrainingCommuniqueDialogClose = (): void => {
        setTrainingCommuniqueDialogOpened(false);

        if (actionState) onStateChange({ ...actionState, trainingCommuniqueViewed: true });
    };

    return (
        <div className={classes.mainContainer} ref={mediaContainerElement}>
            {Boolean(actionProps.config.trainingCommunique) && Boolean(actionState?.trainingCommuniqueRequired) ? (
                <Box
                    className={classes.trainingCommuniqueBorder}
                    height={normalizeSVGDimension(mediaSize)}
                    id="workInstructionsPlayerTrainingBorder"
                    width={normalizeSVGDimension(mediaSize * SVG_RATIO)}
                />
            ) : null}
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={normalizeSVGDimension(mediaSize * SVG_RATIO)}
                height={normalizeSVGDimension(mediaSize)}
                viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
            >
                <rect
                    width={SVG_WIDTH}
                    height={SVG_HEIGHT}
                    x="0"
                    y="0"
                    fill={theme.palette.common.white}
                    stroke={greyPalette[500]}
                    strokeWidth="0.8"
                />
                {/* ALL ELEMENTS EXCEPT VIDEO */}
                {actionProps &&
                    actionProps.config.workInstructionElements
                        .filter(
                            (element: IWorkInstructionsElementConfig<TWorkInstructionsExtendedConfig>): boolean =>
                                element.type !== EnumElementType.VIDEO && element.type !== EnumElementType.THREE_D
                        )
                        .sort((a, b) => a.zIndex - b.zIndex)
                        .map(
                            (elementProps: IWorkInstructionsElementConfig<TWorkInstructionsExtendedConfig>, index: number): JSX.Element => {
                                const elementsState = actionState
                                    ? actionState.workInstructionElementsState?.find(
                                          (elementState): boolean => elementState._id === elementProps._id
                                      )
                                    : undefined;
                                const elementStateDef: WorkInstructionsStepState = elementsState
                                    ? elementsState
                                    : { _id: "", formItemState: [], textItemState: { text: "" }, markerItemState: { text: "" } };
                                return (
                                    <g key={index}>
                                        <WorkInstructionsElement
                                            disabled={disabled}
                                            playMode={true}
                                            selected={false}
                                            elementProps={elementProps}
                                            elementState={elementStateDef}
                                            onFormElementStateChanged={handleFormElementStateChanged(elementProps._id)}
                                            onEnterPressed={handleSelectNext}
                                        />
                                    </g>
                                );
                            }
                        )}
            </svg>
            {/* Video renders can"t be embedded into svg when in play mode */}
            {/* VIDEO ELEMENTS */}
            <div
                className={classes.videoRenderer}
                style={{
                    width: `${mediaSize * SVG_RATIO}px`,
                    height: `${mediaSize}px`,
                }}
            >
                {actionProps &&
                    actionProps.config.workInstructionElements
                        .filter(
                            (elementProps: IWorkInstructionsElementConfig<TWorkInstructionsExtendedConfig>): boolean =>
                                elementProps.type === EnumElementType.VIDEO
                        )
                        .sort((a, b) => a.zIndex - b.zIndex)
                        .map((elementProps: IWorkInstructionsElementConfig<IWorkInstructionsVideoProps>, index: number): JSX.Element => {
                            return <WorkInstructionsVideoViewer key={index} ratio={ratio} videoProps={elementProps} />;
                        })}
            </div>
            <WorkInstructionsPlayerTrainingCommuniqueDialog
                communique={actionProps.config.trainingCommunique}
                onClose={handleTrainingCommuniqueDialogClose}
                open={trainingCommuniqueDialogOpened}
            />
        </div>
    );
}

export default WorkInstructionsPlayer;
