import { ReworkStatusEnum } from "../../../enum/ReworkStatusEnum";
export function createReworkModel() {
    return {
        reworkId: 0,
        trackingId: "",
        trackingInstances: "",
        originalResultMasterId: 0,
        originalResultProcessId: 0,
        resultProcessFailId: 0,
        originalJobId: 0,
        reworkResultMasterId: 0,
        reworkResultProcessId: 0,
        reworkJobId: 0,
        reworkStatus: ReworkStatusEnum.NEW,
        closedOn: 0,
        reworkItemStatusId: 0,
        quantity: 0,
        partNumber: "",
        userId: 0,
        yieldType: "FPY",
        rootCauseId: 0,
    };
}
