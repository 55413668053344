import { KortexTextField, theme } from "@aos/react-components";
import { IReworkItemStatusDbModel, MIN_TO_MS, isFieldEmpty } from "@kortex/aos-common";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Typography, makeStyles } from "@material-ui/core";
import EndIcon from "@material-ui/icons/NotInterested";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesReworkItemStatus, useEntitiesTreeProcess } from "../../../../../redux/effects";
import { getParentNodesPath } from "../../../../../utilitites/getParentNodes";

const useStyles = makeStyles({
    root: {},
    dialogTitle: {
        display: "flex",
    },
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    mainIcon: {
        fontSize: "40px",
        marginRight: "20px",
        color: theme.palette.error[500],
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 30px",
    },
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
    },
    buttonIcons: {
        marginRight: "10px",
    },
    mainForm: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
    },
    description: {
        margin: "15px 0px 5px 0px",
        color: theme.palette.primary[500],
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: "15px",
        maxHeight: "200px",
        overflowY: "auto",
        minHeight: "100px",
        marginBottom: "20px",
    },
    statusContainer: {
        display: "flex",
        alignItems: "flex-start",
        paddingBottom: "5px",
    },
    select: {
        width: "200px",
        margin: "45px 50px 0px 0px",
    },
    hours: {
        width: "75px",
        margin: "5px 0px 0px 5px",
    },
    workTime: {
        paddingBottom: "18px",
    },
    comments: {
        flexGrow: 1,
        margin: "20px 0px",
    },
    error: {
        marginTop: "20px",
        color: theme.palette.error[500],
    },
    endIcon: {
        color: theme.palette.grey[400],
        paddingLeft: "10px",
    },
});

export interface IFailureTicketLogAddItem {
    reworkItemStatusId: number;
    comments: string;
    workingTime: number;
}

interface IOwnProps {
    open: boolean;
    processTreeNodeId?: number;
    onSave: (addLog: IFailureTicketLogAddItem) => void;
    onClose: () => void;
}

export default function AddFailureTicketStatusLogDialog(props: IOwnProps): JSX.Element {
    const { open, processTreeNodeId, onSave, onClose } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [validate, setValidate] = useState<boolean>(false);
    const [selectedReworkItemStatus, setSelectedReworkItemStatus] = useState<IReworkItemStatusDbModel | undefined>(undefined);
    const [comments, setComments] = useState<string>("");
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);

    const [reworkItemStatuses, setReworkItemStatuses] = useState<IReworkItemStatusDbModel[]>([]);

    const allReworkItemStatus = useEntitiesReworkItemStatus();
    const allNodes = useEntitiesTreeProcess();

    const language = useSelectorLanguage();
    /**
     * Effect triggered when rework item status changed in store
     */
    useEffect((): void => {
        if (processTreeNodeId) {
            const parentNodes = getParentNodesPath(processTreeNodeId, allNodes);
            let nodeReworkItemStatus: IReworkItemStatusDbModel[] = [];

            for (const node of parentNodes) {
                const reworkItemStatus = allReworkItemStatus.filter((status) => {
                    return status.treeNodeId === node.treeNodeId && !status.archived;
                });
                nodeReworkItemStatus = nodeReworkItemStatus.concat(reworkItemStatus);
            }

            setReworkItemStatuses(nodeReworkItemStatus);
        } else {
            setReworkItemStatuses(allReworkItemStatus);
        }
    }, [allReworkItemStatus]);

    /**
     * Effect triggered when rework item status changed in store
     */
    useEffect((): void => {
        if (minutes >= 60) {
            const extraHours = Math.floor(minutes / 60);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Don't know why the jours and minutes are not treated correctly.  They are concatenate instead off added.
            const totalHours: number = parseInt(hours) + parseInt(extraHours);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore I give up! RIP: JMA 05-01-2021
            const totalMinutes: number = parseInt(minutes) % 60;
            setHours(totalHours);
            setMinutes(totalMinutes);
        }
    }, [hours, minutes]);

    /**
     * handles the send report
     */
    const handleSave = (): void => {
        setValidate(true);
        if (onSave) {
            // check fields
            if (selectedReworkItemStatus && comments !== "") {
                // we are valid to send!!!
                const workingTime = (hours * 60 + minutes) * MIN_TO_MS;
                onSave({ reworkItemStatusId: selectedReworkItemStatus.reworkItemStatusId, comments, workingTime });
                handleClose();

                setSelectedReworkItemStatus(undefined);
                setValidate(false);
                setComments("");
            }
        }
    };

    /**
     * handles the close
     */
    const handleClose = (): void => {
        setSelectedReworkItemStatus(undefined);
        setValidate(false);
        setComments("");

        onClose();
    };

    /**
     * Handle the item status change
     */
    const handleReworkItemStatusChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSelectedReworkItemStatus(
            allReworkItemStatus.find((reworkItem) => reworkItem.reworkItemStatusId === parseInt(e.target.value, 10))
        );
    };

    /**
     * Handle comment change
     */
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setComments(e.target.value);
    };

    /**
     * Handle hours changed
     */
    const handleHoursChanged = (value: number): void => {
        if (isNaN(value) || isFieldEmpty(value.toString()) === true) {
            setHours(0);
        } else {
            setHours(value);
        }
    };

    /**
     * Handle minutes changed
     */
    const handleMinutesChanged = (value: number): void => {
        if (isNaN(value) || isFieldEmpty(value.toString()) === true) {
            setMinutes(0);
        } else {
            setMinutes(value);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} disableAutoFocus={true} fullWidth={true} maxWidth="lg">
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogTitle}>
                        <div className={classes.dialogDescription}>
                            <Typography variant="h2">{translate("rework.addWorkLog")}</Typography>
                        </div>
                    </div>
                    <div id="failedDialogFormId" className={classes.mainForm}>
                        <div className={classes.statusContainer}>
                            <KortexTextField
                                value={selectedReworkItemStatus ? selectedReworkItemStatus.reworkItemStatusId : ""}
                                label={translate("rework.status")}
                                className={classes.select}
                                onChange={handleReworkItemStatusChange}
                                error={
                                    validate && !selectedReworkItemStatus?.reworkItemStatusId
                                        ? translate("rework.statusRequired")
                                        : undefined
                                }
                                TextFieldProps={{
                                    id: "reworkStatusId",
                                    required: true,
                                    select: true,
                                }}
                            >
                                {[
                                    <MenuItem key="reworkStatusSelectStatus" value={0} disabled={true}>
                                        <Typography>{`${translate("general.select")} ...`}</Typography>
                                    </MenuItem>,
                                ].concat(
                                    reworkItemStatuses
                                        .filter((status) => status.archived === false)
                                        .sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: "base" }))
                                        .map((status, index): JSX.Element => {
                                            return (
                                                <MenuItem key={index} value={status.reworkItemStatusId} id={`${status.title}Id`}>
                                                    {status.title}
                                                    {status.automaticClose && <EndIcon className={classes.endIcon} />}
                                                </MenuItem>
                                            );
                                        })
                                )}
                            </KortexTextField>
                            <div>
                                <Typography className={classes.workTime} variant="subtitle2">
                                    {translate("rework.workingTime")}
                                </Typography>
                                <div>
                                    <KortexTextField
                                        TextFieldProps={{
                                            id: "reworkInputHoursId",
                                        }}
                                        value={hours}
                                        label={translate("general.hours")}
                                        className={classes.hours}
                                        onChanged={handleHoursChanged}
                                        variant="outlined"
                                        type="number"
                                    />
                                    <KortexTextField
                                        TextFieldProps={{
                                            id: "reworkInputMinutesId",
                                        }}
                                        value={minutes}
                                        label={translate("general.minutes")}
                                        className={classes.hours}
                                        onChanged={handleMinutesChanged}
                                        variant="outlined"
                                        type="number"
                                        max={59}
                                    />
                                </div>
                            </div>
                        </div>
                        <KortexTextField
                            value={comments}
                            label={translate("rework.comments")}
                            className={classes.comments}
                            onChange={handleCommentChange}
                            error={validate && comments === "" ? translate("rework.commentsRequired") : undefined}
                            TextFieldProps={{
                                id: "reworkCommentsId",
                                multiline: true,
                                required: true,
                                rows: 10,
                            }}
                        />

                        {selectedReworkItemStatus?.automaticClose === true && (
                            <Typography color={"primary"} variant="h6">
                                {translate("rework.willCloseTicket")}
                            </Typography>
                        )}
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        id="closeButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                        className={classes.dialogButtons}
                    >
                        {translate("general.close")}
                    </Button>
                    <Button
                        id="proceedButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleSave}
                        className={classes.dialogButtons}
                    >
                        {translate("general.save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
