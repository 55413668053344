import { PlayerProcessHubConnectionStatusEnum, ProcessState, upsertObjectFromArray } from "@kortex/aos-common";

import { IPlayerState, PlayerActions, PlayerActionType } from "./player-types";

export const initialState: IPlayerState = {
    actionsState: [],
    historyInfo: [],
    hubConnection: {
        canRetry: false,
        reconnectionAttemptCount: 0,
        status: PlayerProcessHubConnectionStatusEnum.OK,
    },
    playNextEnabled: false,
    processState: new ProcessState(),
};

export function playerReducer(state = initialState, action: PlayerActions): IPlayerState {
    switch (action.type) {
        case PlayerActionType.INIT_STATE:
            return {
                ...initialState,
                ...action.value,
            };
        case PlayerActionType.UPDATE_PROCESS_STATE:
            return {
                ...state,
                processState: action.value,
            };
        case PlayerActionType.UPDATE_ACTION_STATE:
            const updatedActionState = action.value;

            return {
                ...state,
                actionsState: upsertObjectFromArray(state.actionsState, updatedActionState, function (actionsState) {
                    return actionsState.actionId === this.actionId;
                }),
            };
        case PlayerActionType.SET_UI_ACTION_PROPS:
            return {
                ...state,
                uiActionProps: action.value,
            };
        case PlayerActionType.SET_HISTORY:
            return {
                ...state,
                historyInfo: action.value,
            };
        case PlayerActionType.SET_HUB_CONNECTION:
            return {
                ...state,
                hubConnection: action.value,
            };
        case PlayerActionType.ENABLE_PLAY_NEXT:
            return {
                ...state,
                playNextEnabled: action.value,
            };
        default:
            return state;
    }
}
