import { KortexTextField } from "@aos/react-components";
import { EnumTrackingType, IInputConfig, ILocMessage, ProcessActionStepInput } from "@kortex/aos-common";
import { Button, Checkbox, makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography/Typography";
import * as React from "react";
import { useEffect, useState } from "react";

import VariablePicker from "../../../../../../components/core/VariablePicker/VariablePicker";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import KortexTextFieldLocation from "../../../../../core/KortexTextFieldLocation";
import { IActionStepProps } from "../IActionStepProps";

const useStyles = makeStyles({
    buttonElement: {
        height: "56px",
        marginBottom: "20px",
        marginTop: "5px",
        width: "150px",
    },
    formFlexRow: {
        display: "flex",
        height: "90px", // textfield / button (56px) + error message (16px) + margins
    },
    formFlexColumn: {
        display: "flex",
        flexDirection: "row",
    },
    formLabel: {
        marginBottom: "20px",
        marginLeft: "5px",
        marginRight: "20px",
        width: "100%",
    },
    formGrow: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
    },
    formRegex: {
        flex: 1,
        marginBottom: "20px",
        marginLeft: "5px",
        marginRight: "20px",
    },
    formSelect: {
        marginBottom: "20px",
        marginLeft: "5px",
        marginRight: "20px",
        width: "240px",
    },
    groupHeader: {
        marginBottom: "20px",
    },
    root: {
        boxSizing: "border-box",
        height: "100%",
        padding: "16px",
    },
    checkLock: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingLeft: "20px",
        paddingRight: "10px",
    },
});

let timer: ReturnType<typeof setTimeout>;

function startTimer(cb: () => void): void {
    timer = setTimeout(cb, 2000);
}

function clearTimer(): void {
    clearTimeout(timer);
}

export default function ActInputStepEditor(props: IActionStepProps<ProcessActionStepInput>): JSX.Element {
    const { actionStepProps, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [inputProps, setInputProps] = useState(actionStepProps);
    const [testSNRegex, setTestSNRegex] = useState<string>("");
    const [errorSNRegex, setErrorSNRegex] = useState<string>("");
    const [errorSNValueRegex, setErrorSNValueRegex] = useState<string>("");
    const [testRefRegex, setTestRefRegex] = useState<string>("");
    const [errorRefRegex, setErrorRefRegex] = useState<string>("");
    const [errorRefValueRegex, setErrorRefValueRegex] = useState<string>("");
    const [prePopulatedError, setPrePopulatedError] = useState<string>("");

    const readOnly = !userCanWrite(userAccessLevel);
    const trackingIdDisabled = readOnly || inputProps.config.serialType !== EnumTrackingType.MANUAL;
    const trackingIdRegexDisabled =
        trackingIdDisabled ||
        (inputProps.config.prepopulatedSerialNumber?.defaultMessage !== "" && inputProps.config.prepopulatedSerialNumberLock);

    /**
     * On change of actionStepProps
     */
    useEffect((): void => {
        setInputProps(actionStepProps);

        if (actionStepProps.config.prepopulatedSerialNumber?.defaultMessage === "") {
            setPrePopulatedError(translate("action.input.prepopulatedValueError"));
        }
    }, [actionStepProps]);

    /**
     * Handle change of keys based on traduction for inputProps
     *
     * @param {IInputConfig} propKey - Key of inputProps
     */
    const handleConfigLocChange =
        (propKey: keyof IInputConfig): ((newValue: ILocMessage) => void) =>
        (newValue: ILocMessage): void => {
            if (newValue !== inputProps[propKey]) {
                setInputProps((prevState) => {
                    const updatedProps = {
                        ...prevState,
                        config: {
                            ...prevState.config,
                            [propKey]: newValue,
                        },
                    };

                    clearTimer();

                    startTimer((): void => {
                        props.onChanged(updatedProps);
                    });

                    return updatedProps;
                });
            }
        };

    /**
     * Handle change of PrepopulationLock
     */
    const handlePrepopulatedLock = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        const updatedProps = { ...inputProps, config: { ...inputProps.config, prepopulatedSerialNumberLock: checked } };
        props.onChanged(updatedProps);
    };

    /**
     * Handle change inputProps
     *
     * @param {IInputConfig} propKey - Key of inputProps
     */
    const handleConfigValueChange =
        (propKey: keyof IInputConfig): ((value: string) => void) =>
        (value: string): void => {
            if (value !== inputProps[propKey]) {
                setInputProps((prevState) => {
                    const updatedProps = {
                        ...prevState,
                        config: {
                            ...prevState.config,
                            [propKey]: value,
                        },
                    };

                    clearTimer();
                    startTimer((): void => {
                        props.onChanged(updatedProps);
                    });

                    return updatedProps;
                });
            }
        };

    /**
     * Handle change on Test Value REGEX
     *
     * @param {string} value - New REGEX value
     */
    const handleSNTestRegexValueChange = (value: string): void => {
        setErrorSNValueRegex("");
        setTestSNRegex(value);
    };

    /**
     * Start REGEX test
     */
    const handleSNRegexTest = (): void => {
        try {
            const regExp = new RegExp(inputProps.config.serialRegex);
            const res = regExp.test(testSNRegex);

            if (res) {
                setErrorSNValueRegex("");
            } else {
                setErrorSNValueRegex(translate("action.input.regex.nomatch"));
            }
        } catch (err) {
            // Invalid Regex
            setErrorSNRegex(translate("action.input.regex.invalid"));
            setErrorSNValueRegex(translate("action.input.regex.nomatch"));
        }
    };

    /**
     * Handle change on Test Value REGEX
     *
     * @param {string} value - New REGEX value
     */
    const handleRefTestRegexValueChange = (value: string): void => {
        setErrorRefValueRegex("");
        setTestRefRegex(value);
    };

    /**
     * Start REGEX test
     */
    const handleRefRegexTest = (): void => {
        try {
            const regExp = new RegExp(inputProps.config.trackingRegex);

            if (regExp.test(testRefRegex)) {
                setErrorRefValueRegex("");
            } else {
                setErrorRefValueRegex(translate("action.input.regex.nomatch"));
            }
        } catch (err) {
            // Invalid Regex
            setErrorRefRegex(translate("action.input.regex.invalid"));
            setErrorRefValueRegex(translate("action.input.regex.nomatch"));
        }
    };

    /* Removed for now
            <Typography variant="h6" className={classes.form}>
                <FormattedMessage id="action.input.general" />
            </Typography>

            <KortexOutlinedTextField
                select={true}
                label={translate("action.input.manualPlay")}
                value={"E"}
                className={classes.form}
            >
                <MenuItem value={"E"}>
                    <FormattedMessage id="action.input.enabled" />
                </MenuItem>
                <MenuItem value={"D"}>
                    <FormattedMessage id="action.input.disabled" />
                </MenuItem>
            </KortexOutlinedTextField>

            <KortexOutlinedTextField
                select={true}
                label={translate("action.input.schedulerPlay")}
                value={"E"}
                className={classes.form}
            >
                <MenuItem value={"E"}>
                    <FormattedMessage id="action.input.enabled" />
                </MenuItem>
                <MenuItem value={"D"}>
                    <FormattedMessage id="action.input.disabled" />
                </MenuItem>
            </KortexOutlinedTextField>


    */

    return (
        <Paper className={classes.root}>
            {/* GENERAL */}

            {/* TRACKING ID */}
            <Typography variant="h6" className={classes.groupHeader}>
                {translate("action.input.trackingId")}
            </Typography>

            <KortexTextField
                changedDelayMS={0}
                className={classes.formSelect}
                InputProps={{ readOnly }}
                label={translate("action.input.type")}
                onChanged={handleConfigValueChange("serialType")}
                value={inputProps.config.serialType}
                TextFieldProps={{
                    select: true,
                    disabled: readOnly,
                }}
            >
                <MenuItem value={EnumTrackingType.NONE}>
                    <Typography>{translate("action.input.disabled")}</Typography>
                </MenuItem>
                <MenuItem value={EnumTrackingType.MANUAL}>
                    <Typography>{translate("action.input.manualEntry")}</Typography>
                </MenuItem>
                <MenuItem value={EnumTrackingType.AUTO_GENERATED}>
                    <Typography>{translate("action.input.autoGenerated")}</Typography>
                </MenuItem>
            </KortexTextField>

            <KortexTextFieldLocation
                classes={{
                    textField: classes.formLabel,
                }}
                disabled={trackingIdDisabled}
                label={translate("action.input.trackingIdLabel")}
                locMessage={inputProps.config.serialNumberLabelLoc}
                onChange={handleConfigLocChange("serialNumberLabelLoc")}
                showVariablePicker={true}
                userAccessLevel={userAccessLevel}
            />

            <div className={classes.formFlexColumn}>
                <div className={classes.formGrow}>
                    <KortexTextFieldLocation
                        classes={{
                            textField: classes.formLabel,
                        }}
                        disabled={trackingIdDisabled}
                        label={translate("action.input.prepopulatedValue")}
                        locMessage={inputProps.config.prepopulatedSerialNumber ?? { id: "", defaultMessage: "" }}
                        onChange={handleConfigLocChange("prepopulatedSerialNumber")}
                        showVariablePicker={true}
                        userAccessLevel={userAccessLevel}
                    />
                </div>
                <div className={classes.checkLock}>
                    <Typography>{translate("action.input.prepopulatedValueLock")}</Typography>
                    <Checkbox
                        checked={inputProps.config.prepopulatedSerialNumberLock}
                        onChange={handlePrepopulatedLock}
                        disabled={trackingIdDisabled}
                    />
                </div>
                <div>
                    {inputProps?.config.prepopulatedSerialNumber?.defaultMessage === "" &&
                    inputProps.config.prepopulatedSerialNumberLock === true ? (
                        <Typography variant={"body2"} color={"error"}>
                            {prePopulatedError}
                        </Typography>
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            <div className={classes.formFlexRow}>
                <VariablePicker
                    disabled={trackingIdRegexDisabled}
                    KortexTextFieldProps={{
                        changedDelayMS: 500,
                        className: classes.formRegex,
                        disableValidation: true,
                        error: errorSNRegex,
                        label: translate("action.input.regexValidation"),
                    }}
                    onChange={handleConfigValueChange("serialRegex")}
                    userAccessLevel={userAccessLevel}
                    value={inputProps.config.serialRegex}
                />

                <KortexTextField
                    changedDelayMS={500}
                    className={classes.formRegex}
                    disableValidation={true}
                    error={errorSNValueRegex}
                    InputProps={{ disabled: trackingIdDisabled }}
                    label={translate("action.input.regexTest")}
                    onChanged={handleSNTestRegexValueChange}
                    value={testSNRegex}
                    TextFieldProps={{
                        disabled: trackingIdRegexDisabled,
                    }}
                />

                <Button
                    className={classes.buttonElement}
                    color="secondary"
                    id="testTrackingNumberRegexId"
                    onClick={handleSNRegexTest}
                    variant="outlined"
                    disabled={trackingIdRegexDisabled}
                >
                    <Typography>{translate("action.input.regexTestButton")}</Typography>
                </Button>
            </div>

            {/* BATCH PROCESSING */}
            <Typography variant="h6" className={classes.groupHeader}>
                {translate("action.input.batchProcessing")}
            </Typography>
            <KortexTextField
                changedDelayMS={0}
                className={classes.formSelect}
                InputProps={{ readOnly }}
                label={translate("action.input.enableBatchProcessing")}
                onChanged={handleConfigValueChange("enableBatchProcessing")}
                TextFieldProps={{
                    select: true,
                    disabled: readOnly,
                }}
                value={inputProps.config.enableBatchProcessing ? 1 : 0}
            >
                <MenuItem value={0}>
                    <Typography>{translate("action.input.disabled")}</Typography>
                </MenuItem>
                <MenuItem value={1}>
                    <Typography>{translate("action.input.enabled")}</Typography>
                </MenuItem>
            </KortexTextField>
            {inputProps.config.enableBatchProcessing ? (
                <KortexTextField
                    changedDelayMS={500}
                    className={classes.formRegex}
                    min={1}
                    max={1000}
                    type={"number"}
                    InputProps={{ readOnly }}
                    label={translate("action.input.maxBatchQty")}
                    onChanged={handleConfigValueChange("maxBatchQty")}
                    value={inputProps.config.maxBatchQty ? inputProps.config.maxBatchQty : 1}
                    TextFieldProps={{
                        disabled: readOnly,
                    }}
                />
            ) : (
                <></>
            )}
            {/* REFERENCE ID */}
            <Typography variant="h6" className={classes.groupHeader}>
                {translate("action.input.referenceId")}
            </Typography>

            <KortexTextField
                changedDelayMS={0}
                className={classes.formSelect}
                InputProps={{ readOnly }}
                label={translate("action.input.type")}
                onChanged={handleConfigValueChange("trackingType")}
                TextFieldProps={{
                    select: true,
                    disabled: readOnly,
                }}
                value={inputProps.config.trackingType}
            >
                <MenuItem value={EnumTrackingType.NONE}>
                    <Typography>{translate("action.input.disabled")}</Typography>
                </MenuItem>
                <MenuItem value={EnumTrackingType.MANUAL}>
                    <Typography>{translate("action.input.enabled")}</Typography>
                </MenuItem>
            </KortexTextField>

            <KortexTextFieldLocation
                classes={{
                    textField: classes.formLabel,
                }}
                label={translate("action.input.referenceIdLabel")}
                locMessage={inputProps.config.trackingNumberLabelLoc}
                onChange={handleConfigLocChange("trackingNumberLabelLoc")}
                showVariablePicker={true}
                userAccessLevel={userAccessLevel}
            />

            <div className={classes.formFlexRow}>
                <VariablePicker
                    KortexTextFieldProps={{
                        changedDelayMS: 500,
                        className: classes.formRegex,
                        disableValidation: true,
                        error: errorRefRegex,
                        label: translate("action.input.regexValidation"),
                    }}
                    onChange={handleConfigValueChange("trackingRegex")}
                    userAccessLevel={userAccessLevel}
                    value={inputProps.config.trackingRegex}
                />

                <KortexTextField
                    changedDelayMS={500}
                    className={classes.formRegex}
                    disableValidation={true}
                    error={errorRefValueRegex}
                    InputProps={{ readOnly }}
                    label={translate("action.input.regexTest")}
                    onChanged={handleRefTestRegexValueChange}
                    value={testRefRegex}
                    TextFieldProps={{
                        disabled: readOnly,
                    }}
                />

                <Button
                    id="testReferenceIdRegexId"
                    variant="outlined"
                    color="secondary"
                    onClick={handleRefRegexTest}
                    className={classes.buttonElement}
                    disabled={readOnly}
                >
                    <Typography>{translate("action.input.regexTestButton")}</Typography>
                </Button>
            </div>
        </Paper>
    );
}
