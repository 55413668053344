import { EnumShapeTypes, IWorkInstructionsElementConfig, IWorkInstructionsShapeConfig } from "@kortex/aos-common";
import * as React from "react";

const OCTAGON_DEFAULT_ROTATION_RADIAN = 0.25 * Math.PI; // 45 degrees
const OCTAGON_OUTER_ANGLE_RADIAN = 0.125 * Math.PI; // 22.5 degrees
const OCTAGON_SIDES = 8;

export interface IOwnProps {
    shapeProps: IWorkInstructionsElementConfig<IWorkInstructionsShapeConfig>;
}

export default function WorkInstructionsShape(props: IOwnProps): JSX.Element {
    const { cropBottom, cropLeft, cropRight, cropTop, extendedProps, height, rotation, width, x, y } = props.shapeProps;
    const { border, borderColor, color, type } = extendedProps;
    const finalHeight = height - cropBottom - cropTop;
    const finalWidth = width - cropRight - cropLeft;

    /**
     * Render the shape according to its type
     */
    const renderShape = (): JSX.Element | undefined => {
        let points = "";

        switch (type) {
            case EnumShapeTypes.ARROW:
                return (
                    <polygon
                        id="workInstructionsShapeArrowId"
                        fill={color}
                        points={`${finalWidth / 3},0 
                            ${finalWidth / 3},${finalHeight / 3} 
                            ${finalWidth},${finalHeight / 3} 
                            ${finalWidth},${(2 * finalHeight) / 3} 
                            ${finalWidth / 3},${(2 * finalHeight) / 3} 
                            ${finalWidth / 3},${finalHeight} 
                            0,${finalHeight / 2}`}
                        stroke={borderColor}
                        strokeWidth={border}
                    />
                );

            case EnumShapeTypes.CIRCLE:
                return (
                    <ellipse
                        cx={finalWidth / 2}
                        cy={finalHeight / 2}
                        fill={color}
                        id="workInstructionsShapeCircleId"
                        rx={finalWidth / 2}
                        ry={finalHeight / 2}
                        stroke={borderColor}
                        strokeWidth={border}
                    />
                );

            case EnumShapeTypes.OCTAGON:
                for (let n = 0; n < OCTAGON_SIDES; ++n) {
                    points =
                        points +
                        `${(
                            (finalWidth / 2) * Math.cos((2 * Math.PI * n) / OCTAGON_SIDES + OCTAGON_OUTER_ANGLE_RADIAN) +
                            finalWidth / 2
                        ).toString()}, 
                        ${(
                            (finalHeight / 2) * Math.sin((2 * Math.PI * n) / OCTAGON_SIDES + OCTAGON_OUTER_ANGLE_RADIAN) +
                            finalHeight / 2
                        ).toString()} `;
                }
                return (
                    <polygon
                        fill={color}
                        id="workInstructionsShapeOctagonId"
                        points={points}
                        rotate={OCTAGON_DEFAULT_ROTATION_RADIAN}
                        stroke={borderColor}
                        strokeWidth={border}
                    />
                );

            case EnumShapeTypes.TRIANGLE:
                return (
                    <polygon
                        fill={color}
                        points={`${finalWidth / 2},${0} 
                            ${0},${finalHeight / Math.tan(Math.PI / 4)} 
                            ${finalWidth},${finalHeight / Math.tan(Math.PI / 4)}`}
                        stroke={borderColor}
                        strokeWidth={border}
                    />
                );

            case EnumShapeTypes.RECTANGLE:
                return (
                    <rect
                        fill={color}
                        height={finalHeight}
                        id="workInstructionsShapeRectangleId"
                        stroke={borderColor}
                        strokeWidth={border}
                        width={finalWidth}
                    />
                );
        }
    };

    return (
        <g id="workInstructionsShapeId" transform={`translate(${x},${y}) rotate(${rotation} ${finalWidth / 2} ${finalHeight / 2})`}>
            {renderShape()}
        </g>
    );
}
