import { isResultSettingMetadataTypeMetadata } from "./isResultSettingMetadataTypeMetadata";
import { isResultSettingMetadataTypePropertyObject } from "./isResultSettingMetadataTypePropertyObject";
/**
 * Get all properties from a metadata object and all its children (recursively)
 */
export function getAllMetadataProperties(currentMetadata, allMetadata, currentParentId = 0, path = []) {
    const accumulator = [];
    for (const property of allMetadata) {
        if (isResultSettingMetadataTypeMetadata(property) || property.parentId !== currentMetadata.resultSettingMetadataId) {
            continue;
        }
        if (isResultSettingMetadataTypePropertyObject(property)) {
            const nextMetadata = allMetadata.find((item) => item.resultSettingMetadataId === property.objectId);
            if (nextMetadata) {
                accumulator.push(...getAllMetadataProperties(nextMetadata, allMetadata, currentMetadata.resultSettingMetadataId, path.concat(property.resultSettingMetadataId)));
            }
        }
        else {
            accumulator.push({
                path,
                property: {
                    ...property,
                    parentId: currentParentId,
                },
            });
        }
    }
    return accumulator;
}
