import { greyPalette, theme } from "@aos/react-components";
import { IUserRoleDbModel, newUserRole, UserEditorRightsEnum } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Fab, makeStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { useEntitiesUsersRoles } from "../../../../redux/effects";
import { userRoleDelete, userRoleInsert } from "../../../../redux/user-manager/users-thunks-user-role";
import { IUserRightsProps, userCanInsert } from "../../../../utilitites/IUserRights";

import RoleCard from "./RoleCard";
import RoleEditor from "./RoleEditor";
import RoleEditorSearchBar, { defaultFilters, IFilters } from "./RoleEditorSearchBar";

const useStyles = makeStyles({
    root: {
        padding: "16px",
        display: "grid",
        gridTemplateColumns: "33% 1fr",
        columnGap: "12px",
    },
    mainContainer: {
        height: "100%",
    },
    listContainer: {
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        height: "100%",
        position: "relative",
    },
    editorContainer: {
        display: "flex",
    },
    cardSpacer: {
        flexGrow: 2,
    },
    cardFooter: {
        backgroundColor: greyPalette[200],
        color: greyPalette[600],
        borderTop: "solid 1px",
        borderTopColor: greyPalette[400],
        flexFlow: "row",
        flexDirection: "row",
        display: "flex",
        padding: "0px",
    },
    menuItem: {
        width: "68px",
    },
    processPage: {
        margin: "0px",
        borderRadius: "0px",
        overflowY: "auto",
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 190px)",
    },
    endBox: {
        width: "100%",
        minHeight: "5px",
        borderBottom: "1px",
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        borderTop: "0px",
    },
    searchContainer: {
        justifyItems: "center",
        marginBottom: "3px",
        padding: "10px",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    fab: {
        position: "absolute",
        bottom: "30px",
        right: "30px",
    },
});

function RoleEditorPage(props: IUserRightsProps<UserEditorRightsEnum>): JSX.Element {
    const { userAccessLevel } = props;
    const classes = useStyles();
    const roles = useEntitiesUsersRoles();
    const endOfListRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useThunkDispatch();

    const [selectedRole, setSelectedRole] = useState<IUserRoleDbModel | undefined>();
    const [filters, setFilters] = useState<IFilters>(defaultFilters);

    /*
     * Effect trigged when roles are init
     */
    useEffect((): void => {
        // On init, set selected to first role
        if (!selectedRole && roles.length > 1) {
            setSelectedRole(roles[0]);
        } else {
            setSelectedRole(roles.find((role) => role.userRoleId === selectedRole?.userRoleId));
        }
    }, [roles]);

    /**
     * Handle called when the role is selected
     *
     * @param {IUserRoleDbModel} role - Selected role
     */
    const handleSelectRole = (role: IUserRoleDbModel): void => {
        setSelectedRole(role);
    };

    /**
     * Called when the filters change. Sets local filters state
     *
     * @param {IFilters} newFilters - newly set filters
     */
    const handleFiltersChange = (newFilters: IFilters): void => {
        setFilters(newFilters);
    };

    /**
     * Tests a single jobProcess against the filters
     *
     * @param {IUserRoleDbModel} role - object to test
     * @returns {boolean} - true if job passed filters
     */
    const applyFilters = (role: IUserRoleDbModel): boolean => {
        if (role) {
            if (filters.plainText !== "") {
                // create string that contains all searchable params (Work Order, Reference ID, Part Number and Processes' name)
                const searchString = `${role.name}`;

                // check if string contains the searched plainText
                if (!searchString.toLowerCase().includes(filters.plainText.toLowerCase())) {
                    return false;
                }
            }
        }
        return true;
    };

    // General scroll to element function

    /**
     * Add a new empty role
     */
    const handleAddRole = async (): Promise<void> => {
        const newRole = newUserRole();
        newRole.name = "New Role " + Date.now().toString();
        const newInsertedRole = await dispatch(userRoleInsert(newRole));
        if (newInsertedRole) {
            setSelectedRole(newInsertedRole);
            endOfListRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    /**
     * Handle deletion of role.. only when no user is currently using the role
     *
     * @param {IUserRoleDbModel} roleToDelete - role to delete
     */
    const handleDeleteRole = (roleToDelete: IUserRoleDbModel): void => {
        const index = roles.indexOf(roleToDelete);
        dispatch(userRoleDelete(roleToDelete));
        const indexToShow = index > 1 ? index - 1 : 0;
        setSelectedRole(roles[indexToShow]);
    };

    return (
        <div id="settingsRolesPageId" className={classes.root}>
            <div className={classes.mainContainer} id="rolePageId">
                <div className={classes.listContainer}>
                    <Paper className={classes.searchContainer}>
                        <RoleEditorSearchBar enabledFilters={{ plainText: true }} onFiltersChange={handleFiltersChange} />
                    </Paper>

                    <div className={classes.processPage}>
                        {roles &&
                            roles
                                .filter((role): boolean => applyFilters(role))
                                .map((role, index): JSX.Element => {
                                    return (
                                        <RoleCard
                                            key={index}
                                            index={index}
                                            isSelected={role.userRoleId === selectedRole?.userRoleId}
                                            role={role}
                                            onSelection={handleSelectRole}
                                        />
                                    );
                                })}
                        <div ref={endOfListRef}></div>
                    </div>
                    {/* END SPACER*/}
                    <div className={classes.cardSpacer} />
                    <Fab
                        id="insertUserRoleButtonId"
                        color="secondary"
                        className={classes.fab}
                        onClick={handleAddRole}
                        disabled={!userCanInsert(userAccessLevel)}
                    >
                        <AddIcon />
                    </Fab>
                </div>
            </div>
            {selectedRole && (
                <div className={classes.editorContainer}>
                    <RoleEditor role={selectedRole} userAccessLevel={userAccessLevel} onDelete={handleDeleteRole} />
                </div>
            )}
        </div>
    );
}

export default RoleEditorPage;
