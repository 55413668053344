import { Box, Collapse, Table, TableCell, TableRow } from "@material-ui/core";
import React from "react";

import WorkInstructionsBomStepItemsTableBody from "./Body/WorkInstructionsBomItemsTableBody";
import WorkInstructionsBomStepItemsTableHeader from "./Header/WorkInstructionsBomItemsTableHeader";

interface IOwnProps {
    displayStepItems: boolean;
    expanded: boolean;
    tableId: string;
}

function WorkInstructionsBomItemsTable(props: IOwnProps): JSX.Element {
    const { displayStepItems, expanded, tableId } = props;
    return (
        <TableRow>
            <TableCell colSpan={6}>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Box>
                        <Table id={tableId}>
                            <WorkInstructionsBomStepItemsTableHeader />
                            <WorkInstructionsBomStepItemsTableBody displayStepItems={displayStepItems} />
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
}

export default WorkInstructionsBomItemsTable;
