import {
    OrUndefined,
    ProcessId,
    UnwrapAOSPayload,
    UserAllRes,
    UserGetTrainedUsersReq,
    UserGetTrainedUsersRes,
    UserOneRes,
    UserPasswordExpiryStateRes,
    UserValidateRes,
} from "@kortex/aos-common";
import { Snackbar } from "@kortex/aos-ui/components/layout/snackbarConfigurator";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { setUserListAction, userInsertedAction, userUpdatedAction } from "./users-actions";

// thunks

/**
 * Retrieves users
 *
 * @param {IStandardThunkOptions} [options] - options
 */
export function userGetAll(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<UserAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<UserAllRes>> {
        if (!skipLookup) {
            if (fetchedOnce.was(userGetAll)) {
                return getState().user.users;
            }
        }

        return api.services.users
            .getAll(emptyObject)()
            .then((users) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(userGetAll);

                    dispatch(setUserListAction([...users]));
                }

                return users;
            });
    };
}

export function userGet(userToGet: APIPayload<"users", "getOne">): StandardThunk<OrUndefined<UnwrapAOSPayload<UserOneRes>>> {
    return async (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<UnwrapAOSPayload<UserOneRes>>> => {
        const user = getState().user.users.find((user) => user.userId === userToGet.userId);
        if (user) {
            return user;
        }

        return api.services.users
            .getOne(userToGet)()
            .then((userGet) => {
                dispatch(userUpdatedAction([userGet]));
                return userGet;
            });
    };
}

export function userGetPasswordExpiryState(
    userToGet: APIPayload<"users", "getPasswordExpiryState">
): StandardThunk<UnwrapAOSPayload<UserPasswordExpiryStateRes>> {
    return (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<UserPasswordExpiryStateRes>> => {
        return api.services.users.getPasswordExpiryState(userToGet)();
    };
}

export function userUpdate(updatedUser: APIPayload<"users", "update">, forcePasswordUpdateFlag?: boolean): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.users
                .update(updatedUser)()
                .then((updatedUser) => {
                    dispatch(userUpdatedAction([updatedUser]));
                });
            if (forcePasswordUpdateFlag !== undefined) {
                await api.services.users.passwordUpdateByAdmin({ forceUpdate: forcePasswordUpdateFlag, userId: updatedUser.userId })();
            }
        } catch (error) {
            handleAPIError(error, dispatch);
            throw error;
        }
    };
}

export function userInsert(insertedUser: APIPayload<"users", "insert">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.users
                .insert(insertedUser)()
                .then((insertedUser) => {
                    dispatch(userInsertedAction(insertedUser));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            throw error;
        }
    };
}

export function userPasswordUpdateByAdmin(
    userId: APIPayload<"users", "passwordUpdateByAdmin">["userId"],
    password: APIPayload<"users", "passwordUpdateByAdmin">["password"],
    forceUpdate: APIPayload<"users", "passwordUpdateByAdmin">["forceUpdate"],
    successMessage: string
): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.users
                .passwordUpdateByAdmin({ userId, password, forceUpdate })()
                .then(() => {
                    Snackbar.success(successMessage);
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            throw error;
        }
    };
}

export function userPasswordUpdateByUser(
    userId: APIPayload<"users", "passwordUpdateByUser">["userId"],
    password: APIPayload<"users", "passwordUpdateByUser">["password"],
    forceUpdate: APIPayload<"users", "passwordUpdateByUser">["forceUpdate"],
    successMessage: string
): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.users
                .passwordUpdateByUser({ userId, password, forceUpdate })()
                .then(() => {
                    Snackbar.success(successMessage);
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            throw error;
        }
    };
}

export function userValidate(userName: string, password: string): StandardThunk<UnwrapAOSPayload<UserValidateRes>["success"]> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<UserValidateRes>["success"]> => {
        try {
            return api.services.users
                .validate({ userName, password })()
                .then((res) => {
                    return res.success;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            return false;
        }
    };
}

/**
 * Fetch all users trained for specified process
 */
export function trainingGetTrainedUsers(
    processId: ProcessId,
    filters: Omit<UnwrapAOSPayload<UserGetTrainedUsersReq>, "processId"> = {}
): StandardThunk<UnwrapAOSPayload<UserGetTrainedUsersRes>> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<UserGetTrainedUsersRes>> {
        return api.services.users
            .getTrainedUsers({ processId, ...filters })()
            .then(
                ([...trainedUsers]) => trainedUsers,
                (error) => {
                    handleAPIError(error, dispatch);

                    return [];
                }
            );
    };
}
