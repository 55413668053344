import { TreeWorkZoneAllRes, UnwrapAOSPayload } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions, OrUndefined, Unpack } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { setTreeWorkZoneAction, treeWorkZoneInsertedAction, treeWorkZoneUpdatedAction } from "./tree-actions";

// thunks

/**
 * Retrieves tree work zones
 *
 * @param {IStandardThunkOptions} [options] - options
 */
export function treeWorkZoneGet(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<TreeWorkZoneAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TreeWorkZoneAllRes>> {
        try {
            if (!skipLookup) {
                if (fetchedOnce.was(treeWorkZoneGet)) {
                    return getState().tree.workZones;
                }
            }

            return api.services.treeWorkZone
                .getAll(emptyObject)()
                .then((workZones) => {
                    if (!skipDispatch) {
                        fetchedOnce.seal(treeWorkZoneGet);

                        dispatch(setTreeWorkZoneAction([...workZones]));
                    }

                    return workZones;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            return [];
        }
    };
}

/**
 * Insert tree work zone in store
 *
 * @param {APIPayload<"treeWorkZone", "insert">} insertedTreeWorkZone - inserted tree workZone
 */
export function treeWorkZoneInsert(
    insertedTreeWorkZone: APIPayload<"treeWorkZone", "insert">
): StandardThunk<OrUndefined<Unpack<AppState["tree"]["workZones"]>>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<Unpack<AppState["tree"]["workZones"]>>> => {
        try {
            return api.services.treeWorkZone
                .insert(insertedTreeWorkZone)()
                .then((insertedTreeWorkZone) => {
                    if (insertedTreeWorkZone) {
                        dispatch(treeWorkZoneInsertedAction(insertedTreeWorkZone));
                        return insertedTreeWorkZone;
                    }

                    return void 0;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return void 0;
    };
}

/**
 * Update tree work zone in store
 *
 * @param {APIPayload<"treeWorkZone", "update">} updatedTreeWorkZone - updated tree workZone
 */
export function treeWorkZoneUpdate(updatedTreeWorkZone: APIPayload<"treeWorkZone", "update">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.treeWorkZone
                .update(updatedTreeWorkZone)()
                .then((updatedTreeWorkZone) => {
                    dispatch(treeWorkZoneUpdatedAction([...updatedTreeWorkZone]));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}
