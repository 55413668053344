// Kortex
import { IOperatorJobProcessInfo, IReworkItemStatusDbModel } from "@kortex/aos-common";
import { theme, KortexTextField } from "@aos/react-components";
// React
import * as React from "react";
import { useState, useEffect } from "react";
// mUI
import { Button, Dialog, DialogActions, DialogContent, makeStyles, MenuItem, Typography } from "@material-ui/core";
import EndIcon from "@material-ui/icons/NotInterested";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { getParentNodesPath } from "../../../../../utilitites/getParentNodes";
import { useEntitiesReworkItemStatus, useEntitiesTreeProcess } from "../../../../../redux/effects";
import { useTranslate } from "../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    root: {},
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
    },
    mainIcon: {
        fontSize: "40px",
        marginRight: "20px",
        color: theme.palette.primary[500],
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 30px",
    },
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
        marginRight: "40px",
        width: "100%",
    },
    processTitle: {
        color: theme.palette.primary[500],
    },
    comments: {
        flexGrow: 1,
        margin: "20px 0px",
    },
    select: {
        width: "200px",
    },
    endIcon: {
        color: theme.palette.grey[400],
        paddingLeft: "10px",
    },
});

interface IOwnProps {
    open: boolean;
    jobProcess?: IOperatorJobProcessInfo;
    processTreeNodeId?: number;

    onSave: (newReworkItemStatusId: number, comments: string) => void;
}

export const ReworkLogDialog = (props: IOwnProps): JSX.Element => {
    const { open, jobProcess, processTreeNodeId, onSave } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const allReworkItemStatus = useEntitiesReworkItemStatus();
    const allNodes = useEntitiesTreeProcess();

    const [selectedReworkItemStatus, setSelectedReworkItemStatus] = useState<IReworkItemStatusDbModel | undefined>(undefined);
    const [comments, setComments] = useState<string>("");
    const [validate, setValidate] = useState<boolean>(false);
    const [reworkItemStatuses, setReworkItemStatuses] = useState<IReworkItemStatusDbModel[]>([]);

    /**
     * Effect triggered when rework item status changed in store
     */
    useEffect((): void => {
        if (processTreeNodeId) {
            const parentNodes = getParentNodesPath(processTreeNodeId, allNodes);
            let nodeReworkItemStatus: IReworkItemStatusDbModel[] = [];

            for (const node of parentNodes) {
                const reworkItemStatus = allReworkItemStatus.filter((status) => {
                    return status.treeNodeId === node.treeNodeId && !status.archived;
                });
                nodeReworkItemStatus = nodeReworkItemStatus.concat(reworkItemStatus);
            }

            setReworkItemStatuses(nodeReworkItemStatus);
        }
    }, [allReworkItemStatus]);

    /**
     * handle the save
     */
    const handleSave = (): void => {
        setValidate(true);
        if (selectedReworkItemStatus) {
            onSave(selectedReworkItemStatus?.reworkItemStatusId, comments);
            setValidate(false);
        }
    };

    /**
     * handles the comments change event
     *
     */
    const handleCommentsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setComments(e.target.value);
    };

    /**
     * Handle the item status change
     */
    const handleReworkItemStatusChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSelectedReworkItemStatus(
            allReworkItemStatus.find((reworkItem) => reworkItem.reworkItemStatusId === parseInt(e.target.value, 10))
        );
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} disableAutoFocus={true} fullWidth={true}>
                <DialogContent className={classes.dialogContent}>
                    <CheckCircleIcon className={classes.mainIcon} />
                    <div className={classes.dialogDescription}>
                        <Typography id="processReworkLogMessageId" variant="h4">
                            {translate("rework.addWorkLog")}
                        </Typography>
                        <br />
                        {jobProcess && (
                            <Typography variant="subtitle2" className={classes.processTitle}>
                                {translate("rework.updateReworkInformation")}
                            </Typography>
                        )}
                        <br />
                        <KortexTextField
                            value={selectedReworkItemStatus ? selectedReworkItemStatus.reworkItemStatusId : ""}
                            label={translate("rework.status")}
                            className={classes.select}
                            onChange={handleReworkItemStatusChange}
                            error={
                                validate && !selectedReworkItemStatus?.reworkItemStatusId ? translate("rework.statusRequired") : undefined
                            }
                            TextFieldProps={{
                                id: "reworkStatusId",
                                required: true,
                                select: true,
                            }}
                        >
                            {[
                                <MenuItem key="reworkStatusSelectStatus" value={0} disabled>
                                    <Typography>{`${translate("general.select")} ...`}</Typography>
                                </MenuItem>,
                            ].concat(
                                reworkItemStatuses
                                    .filter((status) => status.archived === false)
                                    .map((status, index): JSX.Element => {
                                        return (
                                            <MenuItem key={index} value={status.reworkItemStatusId} id={`${status.title}Id`}>
                                                {status.title}
                                                {status.automaticClose && <EndIcon className={classes.endIcon} />}
                                            </MenuItem>
                                        );
                                    })
                            )}
                        </KortexTextField>
                        <KortexTextField
                            value={comments}
                            label={translate("rework.comments")}
                            className={classes.comments}
                            onChange={handleCommentsChange}
                            error={validate && comments === "" ? translate("rework.commentsRequired") : undefined}
                            TextFieldProps={{
                                id: "reworkCommentsId",
                                multiline: true,
                                required: true,
                                rows: 10,
                            }}
                        />
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        id="proceedButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleSave}
                        className={classes.dialogButtons}
                    >
                        {translate("general.save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
