import { TreeFileAllRes, TreeFileInsertReq, TreeFileInsertRes, TreeFileUpdateReq, UnwrapAOSPayload } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { IStandardThunkOptions, OrUndefined } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { setTreeFileAction, treeFileInsertedAction, treeFileUpdatedAction } from "./tree-actions";

// thunks

/**
 * Retrieves tree files
 *
 * @param {IStandardThunkOptions} [options] - options
 */
export function treeFileGet(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<TreeFileAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TreeFileAllRes>> {
        try {
            if (!skipLookup) {
                if (fetchedOnce.was(treeFileGet)) {
                    return getState().tree.files;
                }
            }

            return api.services.treeFile
                .getAll(emptyObject)()
                .then((files) => {
                    if (!skipDispatch) {
                        fetchedOnce.seal(treeFileGet);

                        dispatch(setTreeFileAction([...files]));
                    }

                    return files;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
        return [];
    };
}

/**
 * Insert tree file in store
 *
 * @param {object} treeFile - inserted tree file
 */
export function treeFileInsert(
    treeFile: UnwrapAOSPayload<TreeFileInsertReq>
): StandardThunk<OrUndefined<UnwrapAOSPayload<TreeFileInsertRes>>> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<UnwrapAOSPayload<TreeFileInsertRes>>> => {
        try {
            return api.services.treeFile
                .insert(treeFile)()
                .then((insertedTreeFile) => {
                    if (insertedTreeFile) {
                        dispatch(treeFileInsertedAction([...insertedTreeFile]));
                        return insertedTreeFile;
                    }

                    return void 0;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return void 0;
    };
}

/**
 * Update tree file in store
 *
 * @param {object} updatedTreeFile - updated tree file
 */
export function treeFileUpdate(updatedTreeFile: UnwrapAOSPayload<TreeFileUpdateReq>): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.treeFile
                .update(updatedTreeFile)()
                .then((updatedTreeFile) => {
                    dispatch(treeFileUpdatedAction([...updatedTreeFile]));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}
