/**
 * Creates a new training certificate trainer model
 */
export function newTrainingCertificateProcessModel(options) {
    const { processId, trainingCertificateId } = options;
    return {
        trainingCertificateProcessId: 0,
        processId,
        trainingCertificateId,
    };
}
