import { getElectronAPIClient } from "../../utilitites/desktopUtils";
import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload, APIResponse } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { setConnectionListAction, setLanguageAction, setRouteAction, setUpdateReadyAction, toggleAppDrawerAction } from "./general-actions";
import { SetLanguageAction, SetRouteAction, ToggleAppDrawerAction } from "./general-types";

export function restartAndUpdate(): void {
    getElectronAPIClient().services.general.desktopStartUpdate({})();
}

/**
 * Set Connection List
 */
export function connectionList(options?: IStandardThunkOptions): StandardThunk<AppState["general"]["connectionList"]> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<AppState["general"]["connectionList"]> {
        if (!skipLookup) {
            if (fetchedOnce.was(connectionList)) {
                return getState().general.connectionList;
            }
        }

        return api.services.general
            .connectionList(emptyObject)()
            .then((list) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(connectionList);
                    dispatch(setConnectionListAction(list));
                }

                return list;
            });
    };
}

/**
 * Set language
 *
 */
export function setLanguage(language: SetLanguageAction["value"]): StandardThunk<void> {
    return async function (dispatch: StandardDispatch): Promise<void> {
        dispatch(setLanguageAction(language));
    };
}

/**
 * Set route
 *
 * @param {string} route - route
 */
export function setRoute(route: SetRouteAction["value"]): StandardThunk<void> {
    return async function (dispatch: StandardDispatch): Promise<void> {
        dispatch(setRouteAction(route));
    };
}

/**
 * Set update ready
 *
 */
export function setUpdateReady(): StandardThunk<void> {
    return async function (dispatch: StandardDispatch): Promise<void> {
        dispatch(setUpdateReadyAction());
    };
}

/**
 * Toggle app drawer
 *
 * @param {ToggleAppDrawerAction} isOpen - is app drawer opened
 *
 */
export function toggleAppDrawer(isOpen: ToggleAppDrawerAction["value"]): StandardThunk<void> {
    return async function (dispatch: StandardDispatch): Promise<void> {
        dispatch(toggleAppDrawerAction(isOpen));
    };
}

/**
 * Translate From the Server
 *
 * @param {APIPayload<"general", "translate">} params - parameters
 */
export function translateServer(params: APIPayload<"general", "translate">): StandardThunk<APIResponse<"general", "translate">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"general", "translate">> {
        return api.services.general
            .translate(params)()
            .then((res) => {
                return res;
            })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                return { result: params.text, target: params.target };
            });
    };
}

export function applyPatch(): StandardThunk<APIResponse<"uncategorized", "patch">> {
    return function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"uncategorized", "patch">> {
        return api.services.uncategorized.patch(emptyObject)();
    };
}

export function log(payload: APIPayload<"general", "log">): StandardThunk<APIResponse<"general", "log">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"uncategorized", "patch">> {
        return api.services.general
            .log(payload)()
            .then((res) => {
                return res;
            })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                return error;
            });
    };
}
