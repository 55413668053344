import { KortexTextField } from "@aos/react-components";
import { ConnectorRestConfig, EnumRequestResponse, EnumRestMethod, IRestParams, ProcessEditorRightsEnum } from "@kortex/aos-common";
import { MenuItem, makeStyles } from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../../../../../../hooks/useTranslate";
import { IUserRightsProps, userCanWrite } from "../../../../../../../../utilitites/IUserRights";
import LogicalAndIcon from "../../../../../../../core/Icons/LogicalAnd/LogicalAnd";
import LogicalOrIcon from "../../../../../../../core/Icons/LogicalOr/LogicalOr";
import VariablePicker from "../../../../../../../core/VariablePicker/VariablePicker";
import ConnectorRestParamTabs, { EnumParamTabs } from "../RestParamTabs/ConnectorRestParamTabs";

const useStyles = makeStyles({
    configRow: {
        alignItems: "flex-end",
        display: "inline-flex",
        marginTop: "16px",
    },
    requestResponseSelect: {
        marginRight: "32px",
        marginTop: "16px",
        width: "180px",
    },
    root: {
        display: "grid",
    },
    select: {
        marginRight: "32px",
        marginTop: "16px",
        width: "120px",
    },
    speedDial: {
        bottom: "38px",
        position: "fixed",
        right: "48px",
    },
    restUrlTextField: {
        flex: 1,
    },
});

interface IRestSpeedDialActions {
    icon: JSX.Element;
    name: string;
    value: EnumParamTabs;
}

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    onChange: (actionStepProps: ConnectorRestConfig) => void;
    restProps: ConnectorRestConfig;
}

export default function ConnectorRestEditor(props: IOwnProps): JSX.Element {
    const { onChange, restProps, userAccessLevel } = props;
    const { accept, contentType, method, params, url } = restProps;

    const classes = useStyles();
    const translate = useTranslate();

    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [speedDialOpen, setSpeedDialOpen] = useState<boolean>(false);

    const ACTIONS: IRestSpeedDialActions[] = [
        { icon: <LogicalAndIcon />, name: translate("action.connector.rest.params.query"), value: EnumParamTabs.query },
        { icon: <LogicalOrIcon />, name: translate("action.connector.rest.params.header"), value: EnumParamTabs.header },
        { icon: <LogicalOrIcon />, name: translate("action.connector.rest.params.body"), value: EnumParamTabs.body },
    ];
    const bodyDisabled = restProps.method === EnumRestMethod.GET || restProps.method === EnumRestMethod.DELETE;
    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Called when the Rest method is changed
     * Hide content type selection and body tab and when GET or DELETE is chosen
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - change event data
     */
    const handleMethodChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const value = event.target.value as EnumRestMethod;

        if (value === EnumRestMethod.GET || value === EnumRestMethod.DELETE) {
            if (selectedTab === EnumParamTabs.body) {
                setSelectedTab(EnumParamTabs.query);
            }

            onChange({
                ...restProps,
                method: value,
                params: {
                    ...params,
                    body: [],
                },
            });
        } else if ((method === EnumRestMethod.GET || method === EnumRestMethod.DELETE) && contentType === EnumRequestResponse.APP_XML) {
            onChange({
                ...restProps,
                method: value,
                params: {
                    ...params,
                    body: [{ key: "", value: "" }],
                },
            });
        } else {
            onChange({ ...restProps, method: value });
        }
    };

    /**
     * Called when a param (key or value) is changed
     *
     * @param {IRestParams} updatedParams - updated param values
     */
    const handleParamChange = (updatedParams: IRestParams): void => {
        onChange({
            ...restProps,
            params: updatedParams,
        });
    };

    /**
     * Called when content type is changed
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - change data event
     */
    const handleContentTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const value = event.target.value as EnumRequestResponse;

        if (value === EnumRequestResponse.APP_JSON) {
            onChange({
                ...restProps,
                contentType: value,
                params: {
                    ...params,
                    body: [],
                },
            });
        } else {
            onChange({
                ...restProps,
                contentType: value,
                params: {
                    ...params,
                    body: [{ key: "", value: "" }],
                },
            });
        }
    };

    /**
     * Called when accept (response) type is changed
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - change data event
     */
    const handleAcceptChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChange({ ...restProps, accept: event.target.value as EnumRequestResponse });
    };

    /**
     * Called when a Speed Dial action is clicked
     * Add a param in the corresponding tab and select that tab
     *
     * @param {EnumParamTabs} action - selected action
     */
    const handleSpeedDialActionClick =
        (action: EnumParamTabs): (() => void) =>
        (): void => {
            const clone = { ...params };
            clone[EnumParamTabs[action]].push({ key: "", value: "" });

            setSelectedTab(action);
            onChange({
                ...restProps,
                params: clone,
            });
        };

    /**
     * Called to open/close the Speed Dial (toggle)
     */
    const handleSpeedDialOpen = (): void => {
        setSpeedDialOpen(!speedDialOpen);
    };

    /**
     * Called when selecting a new tab
     *
     * @param {number} index - selected tab index
     */
    const handleTabChange = (index: number): void => {
        setSelectedTab(index);
    };

    /**
     * Called when the Rest URL textfield is blurred
     *
     * @param {string} value - updated value
     */
    const handleUrlBlur = (value: string): void => {
        if (url !== value) {
            onChange({ ...restProps, url: value });
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.configRow}>
                <KortexTextField
                    className={classes.select}
                    label={translate("action.connector.rest.method")}
                    onChange={handleMethodChange}
                    TextFieldProps={{
                        disabled: readOnly,
                        select: true,
                    }}
                    value={method}
                >
                    <MenuItem value={EnumRestMethod.GET}>{translate("action.connector.rest.method.get")}</MenuItem>
                    <MenuItem value={EnumRestMethod.POST}>{translate("action.connector.rest.method.post")}</MenuItem>
                    <MenuItem value={EnumRestMethod.PUT}>{translate("action.connector.rest.method.put")}</MenuItem>
                    <MenuItem value={EnumRestMethod.DELETE}>{translate("action.connector.rest.method.delete")}</MenuItem>
                </KortexTextField>
                <VariablePicker
                    onBlur={handleUrlBlur}
                    KortexTextFieldProps={{
                        label: translate("action.connector.rest.url"),
                        className: classes.restUrlTextField,
                    }}
                    userAccessLevel={userAccessLevel}
                    value={url}
                />
            </div>
            <div className={classes.configRow}>
                <KortexTextField
                    className={classes.requestResponseSelect}
                    label={translate("action.connector.rest.responseType")}
                    onChange={handleAcceptChange}
                    TextFieldProps={{
                        disabled: readOnly,
                        select: true,
                    }}
                    value={accept}
                >
                    <MenuItem value={EnumRequestResponse.APP_JSON}>
                        {translate("action.connector.rest.requestResponseType.appJSON")}
                    </MenuItem>
                    <MenuItem value={EnumRequestResponse.APP_XML}>{translate("action.connector.rest.requestResponseType.appXML")}</MenuItem>
                </KortexTextField>
                {(method === EnumRestMethod.POST || method === EnumRestMethod.PUT) && (
                    <KortexTextField
                        className={classes.requestResponseSelect}
                        label={translate("action.connector.rest.contentType")}
                        onChange={handleContentTypeChange}
                        TextFieldProps={{
                            disabled: readOnly,
                            select: true,
                        }}
                        value={contentType}
                    >
                        <MenuItem value={EnumRequestResponse.APP_JSON}>
                            {translate("action.connector.rest.requestResponseType.appJSON")}
                        </MenuItem>
                        <MenuItem value={EnumRequestResponse.APP_JSON_RAW}>
                            {translate("action.connector.rest.requestResponseType.appJSONRaw")}
                        </MenuItem>
                        <MenuItem value={EnumRequestResponse.APP_XML}>
                            {translate("action.connector.rest.requestResponseType.appXML")}
                        </MenuItem>
                    </KortexTextField>
                )}
            </div>
            <ConnectorRestParamTabs
                bodyDisabled={bodyDisabled}
                contentType={contentType}
                onParamChange={handleParamChange}
                onTabChange={handleTabChange}
                params={params}
                selectedTab={selectedTab}
                userAccessLevel={userAccessLevel}
            />
            <SpeedDial
                ariaLabel="RestParamSpeedDial"
                className={classes.speedDial}
                hidden={readOnly}
                icon={<SpeedDialIcon />}
                onClick={handleSpeedDialOpen}
                open={speedDialOpen}
            >
                {ACTIONS.filter(
                    (action: IRestSpeedDialActions): boolean =>
                        !(action.value === EnumParamTabs.body && (bodyDisabled || contentType !== EnumRequestResponse.APP_JSON))
                ).map(
                    (action: IRestSpeedDialActions): JSX.Element => (
                        <SpeedDialAction
                            icon={action.icon}
                            key={action.name}
                            tooltipOpen={true}
                            tooltipTitle={action.name}
                            onClick={handleSpeedDialActionClick(action.value)}
                        />
                    )
                )}
            </SpeedDial>
        </div>
    );
}
