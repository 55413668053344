import { greyPalette, secondaryPalette } from "@aos/react-components";
import { ActionBlockSizeEnum, ElementConnectorSourceEnum, ICoords, ICoordsPair, connectorPath } from "@kortex/aos-common";
import { useKeybind } from "@kortex/aos-ui/hooks/useKeybind";
import * as React from "react";
import { Ref, useCallback, useEffect, useRef, useState } from "react";

import { snapToValue } from "../../../utilitites/math/math";

const handlePinSize = 20;
const handlePinHalfSize = handlePinSize / 2;
const snapGridRes = ActionBlockSizeEnum.GRID_RESOLUTION;
const RAD_TO_DEG = 180 / Math.PI;
const VERY_HIGH_VALUE = 10000;
export const SHIFT_MODIFIER_FACTOR = 5;
export const ROTATE_HANDLE_OFFSET = 20;
export const MINIMUM_SCALE = 0.5;

export enum EnumTransformType {
    NONE,
    DRAW_CONNECTOR,
    ROTATE,
    SIZE_NORTH,
    SIZE_WEST,
    SIZE_EAST,
    SIZE_SOUTH,
    SIZE_NORTH_WEST,
    SIZE_NORTH_EAST,
    SIZE_SOUTH_WEST,
    SIZE_SOUTH_EAST,
    SCALE,
    TRANSLATE_ELEMENT,
    TRANSLATE_POINT,
}

export interface ITransformInfo {
    transformType: EnumTransformType;
    diffX: number;
    diffY: number;
    diffWidth: number;
    diffHeight: number;
    diffCropLeft: number;
    diffCropRight: number;
    diffCropTop: number;
    diffCropBottom: number;
    diffRotation: number;
    diffPointIndex: number;
    diffPointX: number;
    diffPointY: number;
    diffScale: number;
}

const initTransformInfo: ITransformInfo = {
    transformType: EnumTransformType.NONE,
    diffX: 0,
    diffY: 0,
    diffWidth: 0,
    diffHeight: 0,
    diffCropLeft: 0,
    diffCropRight: 0,
    diffCropTop: 0,
    diffCropBottom: 0,
    diffRotation: 0,
    diffPointIndex: -1,
    diffPointX: 0,
    diffPointY: 0,
    diffScale: 0,
};

export interface IConnector {
    coordsPair: ICoordsPair;
    curved?: boolean;
    source?: ElementConnectorSourceEnum;
}

export interface IDrawConnectorEvent {
    event: React.MouseEvent<SVGElement | SVGUseElement, MouseEvent>;
    source: ElementConnectorSourceEnum;
}

export interface IDraggableElementManipulator {
    // Own props
    x: number;
    y: number;
    width: number;
    height: number;
    cropLeft: number;
    cropRight: number;
    cropTop: number;
    cropBottom: number;
    rotation: number;
    points: ICoords[];
    scale: number;
    connectors?: IConnector[];

    canvasScale: number;
    origin: ICoords | null;

    showResizeControl?: boolean;
    showCornerResizeControl?: boolean;
    showRotateControl?: boolean;
    showScaleControl?: boolean;
    cropMode?: boolean;
    eleRef?: Ref<SVGGElement>;
    disabled?: boolean;
    showGuideLines?: boolean;

    drawConnectorEvent?: IDrawConnectorEvent;
    translationEvent?: React.MouseEvent<SVGElement | SVGUseElement | SVGCircleElement | SVGRectElement, MouseEvent>;
    next?: boolean;

    onTransformStart?: () => void;
    onTransformCompleted?: (transformInfo: ITransformInfo) => void;
}

export default function DraggableElementManipulator(props: IDraggableElementManipulator): JSX.Element {
    const {
        x,
        y,
        width,
        height,
        cropLeft,
        cropRight,
        cropTop,
        cropBottom,
        rotation,
        scale = 1,
        connectors,
        points,
        showResizeControl,
        canvasScale,
        showCornerResizeControl,
        showRotateControl,
        showScaleControl,
        cropMode,
        eleRef,
        origin,
        disabled,
        showGuideLines,
        translationEvent,
        drawConnectorEvent,
        onTransformStart,
        onTransformCompleted,
        next = true,
    } = props;

    const finalWidth = (width - cropLeft - cropRight) * scale;
    const finalHeight = (height - cropTop - cropBottom) * scale;
    const aspectRatio = finalHeight / finalWidth;

    const [mouseOriginPos, setMouseOriginPos] = useState({ x: 0, y: 0 });
    const [mouseMovement, setMouseMovement] = useState({ diffX: 0, diffY: 0, diffAngleRad: 0 });
    const [captureMouseMove, setCaptureMouseMove] = useState(false);
    const [mouseUpPressed, setMouseUpPressed] = useState(false);
    const [transformInfo, setTransformInfo] = useState(initTransformInfo);
    const transformControl = useRef(null);

    const previewShadowBoxWidth =
        finalWidth + transformInfo.diffScale * width + transformInfo.diffWidth - transformInfo.diffCropLeft - transformInfo.diffCropRight;
    const previewShadowBoxHeight =
        finalHeight +
        transformInfo.diffScale * height +
        transformInfo.diffHeight -
        transformInfo.diffCropTop -
        transformInfo.diffCropBottom;

    /**
     * Handles transform mouse down event to trigger start of of a new transformation
     *
     * @param {EnumTransformType} transformType - EnumMoveType Transformation type
     * @param {number} diffPointIndex - index of selected point
     */
    const handleTransformMouseDown =
        (
            transformType: EnumTransformType,
            diffPointIndex = -1
        ): ((e: React.MouseEvent<SVGElement | SVGUseElement | SVGCircleElement | SVGRectElement, MouseEvent>) => void) =>
        (e: React.MouseEvent<SVGElement | SVGUseElement | SVGCircleElement | SVGRectElement, MouseEvent>): void => {
            e.stopPropagation();

            if (onTransformStart) {
                onTransformStart();
            }

            setMouseOriginPos({ x: e.pageX, y: e.pageY });
            setTransformInfo({ ...transformInfo, diffPointIndex, transformType: transformType });
            setCaptureMouseMove(true);
        };

    /**
     * Effect that triggers a translation if an event is received as prop and if there is no transformation currently being handled
     */
    useEffect((): void => {
        if (translationEvent && transformInfo.transformType === EnumTransformType.NONE) {
            handleTransformMouseDown(EnumTransformType.TRANSLATE_ELEMENT)(translationEvent);
        }
    }, [translationEvent]);

    /**
     * Effect that triggers a draw if an event is received as prop and if there is no transformation currently being handled
     */
    useEffect((): void => {
        if (drawConnectorEvent && transformInfo.transformType === EnumTransformType.NONE) {
            handleTransformMouseDown(EnumTransformType.DRAW_CONNECTOR)(drawConnectorEvent.event);
        }
    }, [drawConnectorEvent]);

    /**
     * Effect that registers keydown event for incremental translation with arrows
     */
    useKeybind(
        "ArrowUp",
        (event) => {
            if (onTransformStart) {
                onTransformStart();
            }

            let step = snapGridRes;

            if (event.shiftKey) {
                step *= SHIFT_MODIFIER_FACTOR;
            }

            setTransformInfo({
                ...transformInfo,
                diffY: transformInfo.diffY - step,
                transformType: EnumTransformType.TRANSLATE_ELEMENT,
            });
        },
        {
            disabled,
            next: next,
            onKeyUp: () => setMouseUpPressed(true),
            repeat: true,
        }
    );

    useKeybind(
        "ArrowDown",
        (event) => {
            if (onTransformStart) {
                onTransformStart();
            }

            let step = snapGridRes;

            if (event.shiftKey) {
                step *= SHIFT_MODIFIER_FACTOR;
            }

            setTransformInfo({
                ...transformInfo,
                diffY: transformInfo.diffY + step,
                transformType: EnumTransformType.TRANSLATE_ELEMENT,
            });
        },
        {
            disabled,
            next: next,
            onKeyUp: () => setMouseUpPressed(true),
            repeat: true,
        }
    );

    useKeybind(
        "ArrowLeft",
        (event) => {
            if (onTransformStart) {
                onTransformStart();
            }

            let step = snapGridRes;

            if (event.shiftKey) {
                step *= SHIFT_MODIFIER_FACTOR;
            }

            setTransformInfo({
                ...transformInfo,
                diffX: transformInfo.diffX - step,
                transformType: EnumTransformType.TRANSLATE_ELEMENT,
            });
        },
        {
            disabled,
            next,
            onKeyUp: () => setMouseUpPressed(true),
            repeat: true,
        }
    );

    useKeybind(
        "ArrowRight",
        (event) => {
            if (onTransformStart) {
                onTransformStart();
            }

            let step = snapGridRes;

            if (event.shiftKey) {
                step *= SHIFT_MODIFIER_FACTOR;
            }

            setTransformInfo({
                ...transformInfo,
                diffX: transformInfo.diffX + step,
                transformType: EnumTransformType.TRANSLATE_ELEMENT,
            });
        },
        {
            disabled,
            next,
            onKeyUp: () => setMouseUpPressed(true),
            repeat: true,
        }
    );

    /**
     * Effect to register mouseup event when captureMouseMove becomes enabled
     * The mouseup event completly stops to transform process
     */
    useEffect((): void | (() => void | undefined) => {
        const handleMouseMove = (e: MouseEvent): void => {
            const diffX = (e.pageX - mouseOriginPos.x) * canvasScale;
            const diffY = (e.pageY - mouseOriginPos.y) * canvasScale;

            const rect = (transformControl.current as unknown as HTMLElement).getBoundingClientRect();
            const diffXFromCenter = (e.pageX - (rect.left + rect.width / 2)) * canvasScale;
            const diffYFromCenter = (e.pageY - (rect.top + rect.height / 2)) * canvasScale;

            // compute angle difference only if the element is being rotated
            let diffAngleRad = 0;
            if (transformInfo.transformType === EnumTransformType.ROTATE) {
                diffAngleRad = Math.atan(diffXFromCenter / diffYFromCenter) * RAD_TO_DEG + rotation + (diffYFromCenter > 0 ? 180 : 0);
            }

            setMouseMovement({ diffX, diffY, diffAngleRad });
        };

        if (captureMouseMove) {
            document.addEventListener("mousemove", handleMouseMove);
        } else {
            document.removeEventListener("mousemove", handleMouseMove);
        }

        return (): void => {
            document.removeEventListener("mousemove", handleMouseMove);
        };
    }, [captureMouseMove]);

    /**
     * Effect to complete transformation when mouseUpPressed becomes true
     */
    useEffect((): void => {
        if (mouseUpPressed) {
            // reset state booleans
            setCaptureMouseMove(false);
            setMouseUpPressed(false);

            // reset transform info state
            setTransformInfo(initTransformInfo);

            // reset mouse movement state
            setMouseMovement({ diffX: 0, diffY: 0, diffAngleRad: 0 });

            if (didTransformInfoChange()) {
                // propagate change
                if (onTransformCompleted) {
                    onTransformCompleted(transformInfo);
                }
            }
        }
    }, [mouseUpPressed]);

    // Effect that add/removes mouseup eventListener upon mount/dismount
    useEffect(() => {
        const handleMouseUp = (): void => {
            setMouseUpPressed(true);
        };

        document.addEventListener("mouseup", handleMouseUp);

        return (): void => {
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, []);

    /**
     * Effect executed each time mousemove set a new mouse position
     * Execution of transformations (size, rotation) calculated based on mouse position
     */
    useEffect((): void => {
        switch (transformInfo.transformType) {
            case EnumTransformType.SIZE_NORTH:
                {
                    const yDiff = -snapToValue(mouseMovement.diffY * Math.cos((rotation * Math.PI) / 180), snapGridRes);
                    const xDiff = snapToValue(mouseMovement.diffX * Math.sin((rotation * Math.PI) / 180), snapGridRes);

                    if (height + yDiff + xDiff >= 20) {
                        if (cropMode) {
                            setTransformInfo({
                                ...transformInfo,
                                diffCropTop: -yDiff - xDiff,
                                diffY: -yDiff - xDiff,
                            });
                        } else {
                            setTransformInfo({
                                ...transformInfo,
                                diffHeight: snapToValue(yDiff + xDiff, snapGridRes),
                                diffY: -yDiff - xDiff,
                            });
                        }
                    }
                }
                break;
            case EnumTransformType.SIZE_SOUTH:
                {
                    const yDiff = snapToValue(mouseMovement.diffY * Math.cos((rotation * Math.PI) / 180), snapGridRes);
                    const xDiff = -snapToValue(mouseMovement.diffX * Math.sin((rotation * Math.PI) / 180), snapGridRes);

                    if (height + yDiff + xDiff >= 20) {
                        if (cropMode) {
                            setTransformInfo({
                                ...transformInfo,
                                diffCropBottom: -yDiff - xDiff,
                            });
                        } else {
                            setTransformInfo({
                                ...transformInfo,
                                diffHeight: yDiff + xDiff,
                            });
                        }
                    }
                }
                break;
            case EnumTransformType.SIZE_WEST:
                {
                    const yDiff = -snapToValue(mouseMovement.diffY * Math.sin((rotation * Math.PI) / 180), snapGridRes);
                    const xDiff = -snapToValue(mouseMovement.diffX * Math.cos((rotation * Math.PI) / 180), snapGridRes);

                    if (width + yDiff + xDiff >= 20) {
                        if (cropMode) {
                            setTransformInfo({
                                ...transformInfo,
                                diffX: -yDiff - xDiff,
                                diffCropLeft: -yDiff - xDiff,
                            });
                        } else {
                            setTransformInfo({
                                ...transformInfo,
                                diffWidth: yDiff + xDiff,
                                diffX: -yDiff - xDiff,
                            });
                        }
                    }
                }
                break;
            case EnumTransformType.SIZE_EAST:
                {
                    const yDiff = snapToValue(mouseMovement.diffY * Math.sin((rotation * Math.PI) / 180), snapGridRes);
                    const xDiff = snapToValue(mouseMovement.diffX * Math.cos((rotation * Math.PI) / 180), snapGridRes);

                    if (width + yDiff + xDiff >= 20) {
                        if (cropMode) {
                            setTransformInfo({
                                ...transformInfo,
                                diffCropRight: -yDiff - xDiff,
                            });
                        } else {
                            setTransformInfo({
                                ...transformInfo,
                                diffWidth: yDiff + xDiff,
                            });
                        }
                    }
                }
                break;
            case EnumTransformType.SIZE_NORTH_WEST:
                {
                    const yDiff = -snapToValue(mouseMovement.diffY * Math.cos((rotation * Math.PI) / 180), snapGridRes);
                    const xDiff = snapToValue(mouseMovement.diffX * Math.sin((rotation * Math.PI) / 180), snapGridRes);
                    const totalWidthDiff = yDiff + xDiff;
                    const totalHeightDiff = totalWidthDiff * aspectRatio;

                    if (height + yDiff + xDiff >= 20 && width + yDiff + xDiff >= 20) {
                        setTransformInfo({
                            ...transformInfo,
                            diffCropLeft: (cropLeft / width) * (width + totalWidthDiff) - cropLeft,
                            diffCropRight: (cropRight / width) * (width + totalWidthDiff) - cropRight,
                            diffCropTop: (cropTop / height) * (height + totalHeightDiff) - cropTop,
                            diffCropBottom: (cropBottom / height) * (height + totalHeightDiff) - cropBottom,
                            diffWidth: totalWidthDiff,
                            diffX: -yDiff - xDiff,
                            diffHeight: totalHeightDiff,
                            diffY: (-yDiff - xDiff) * aspectRatio,
                        });
                    }
                }
                break;
            case EnumTransformType.SIZE_NORTH_EAST:
                {
                    const yDiff = -snapToValue(mouseMovement.diffY * Math.cos((rotation * Math.PI) / 180), snapGridRes);
                    const xDiff = snapToValue(mouseMovement.diffY * Math.sin((rotation * Math.PI) / 180), snapGridRes);
                    const totalWidthDiff = yDiff + xDiff;
                    const totalHeightDiff = totalWidthDiff * aspectRatio;

                    if (height + yDiff + xDiff >= 20 && width + yDiff + xDiff >= 20) {
                        setTransformInfo({
                            ...transformInfo,
                            diffCropLeft: (cropLeft / width) * (width + totalWidthDiff) - cropLeft,
                            diffCropRight: (cropRight / width) * (width + totalWidthDiff) - cropRight,
                            diffCropTop: (cropTop / height) * (height + totalHeightDiff) - cropTop,
                            diffCropBottom: (cropBottom / height) * (height + totalHeightDiff) - cropBottom,
                            diffWidth: totalWidthDiff,
                            diffHeight: totalHeightDiff,
                            diffY: (-yDiff - xDiff) * aspectRatio,
                        });
                    }
                }
                break;
            case EnumTransformType.SIZE_SOUTH_WEST:
                {
                    const yDiff = snapToValue(mouseMovement.diffY * Math.cos((rotation * Math.PI) / 180), snapGridRes);
                    const xDiff = -snapToValue(mouseMovement.diffX * Math.sin((rotation * Math.PI) / 180), snapGridRes);
                    const totalWidthDiff = yDiff + xDiff;
                    const totalHeightDiff = totalWidthDiff * aspectRatio;

                    if (height + totalWidthDiff >= 20 && width + totalWidthDiff >= 20) {
                        setTransformInfo({
                            ...transformInfo,
                            diffCropLeft: (cropLeft / width) * (width + totalWidthDiff) - cropLeft,
                            diffCropRight: (cropRight / width) * (width + totalWidthDiff) - cropRight,
                            diffCropTop: (cropTop / height) * (height + totalHeightDiff) - cropTop,
                            diffCropBottom: (cropBottom / height) * (height + totalHeightDiff) - cropBottom,
                            diffWidth: totalWidthDiff,
                            diffX: -yDiff - xDiff,
                            diffHeight: totalHeightDiff,
                        });
                    }
                }
                break;
            case EnumTransformType.SIZE_SOUTH_EAST:
                {
                    const yDiff = snapToValue(mouseMovement.diffY * Math.cos((rotation * Math.PI) / 180), snapGridRes);
                    const xDiff = -snapToValue(mouseMovement.diffX * Math.sin((rotation * Math.PI) / 180), snapGridRes);
                    const totalWidthDiff = yDiff + xDiff;
                    const totalHeightDiff = totalWidthDiff * aspectRatio;

                    if (height + totalWidthDiff >= 20 && width + totalWidthDiff >= 20) {
                        setTransformInfo({
                            ...transformInfo,
                            diffCropLeft: (cropLeft / width) * (width + totalWidthDiff) - cropLeft,
                            diffCropRight: (cropRight / width) * (width + totalWidthDiff) - cropRight,
                            diffCropTop: (cropTop / height) * (height + totalHeightDiff) - cropTop,
                            diffCropBottom: (cropBottom / height) * (height + totalHeightDiff) - cropBottom,
                            diffWidth: totalWidthDiff,
                            diffHeight: totalHeightDiff,
                        });
                    }
                }
                break;
            case EnumTransformType.TRANSLATE_ELEMENT:
                {
                    setTransformInfo({
                        ...transformInfo,
                        diffY: snapToValue(mouseMovement.diffY, snapGridRes),
                        diffX: snapToValue(mouseMovement.diffX, snapGridRes),
                    });
                }
                break;
            case EnumTransformType.TRANSLATE_POINT:
                {
                    setTransformInfo({
                        ...transformInfo,
                        diffPointY: snapToValue(mouseMovement.diffY, snapGridRes),
                        diffPointX: snapToValue(mouseMovement.diffX, snapGridRes),
                    });
                }
                break;
            case EnumTransformType.ROTATE:
                {
                    const diffAngle = -snapToValue(mouseMovement.diffAngleRad, snapGridRes);
                    setTransformInfo({
                        ...transformInfo,
                        diffRotation: diffAngle,
                    });
                }
                break;
            case EnumTransformType.SCALE:
                {
                    // if the mouse is above the diagonal use the width, otherwise use the height
                    let newScale = 1;
                    const ratio = width / height;
                    const above = mouseMovement.diffY < mouseMovement.diffX / ratio;
                    if (above) {
                        newScale = (mouseMovement.diffX + width * scale) / width;
                    } else {
                        newScale = (mouseMovement.diffY + height * scale) / height;
                    }

                    // make sure newScale is above maximum value
                    newScale = newScale < MINIMUM_SCALE ? MINIMUM_SCALE : newScale;

                    setTransformInfo({
                        ...transformInfo,
                        diffScale: newScale - scale,
                    });
                }
                break;
            case EnumTransformType.DRAW_CONNECTOR:
                {
                    setTransformInfo({
                        ...transformInfo,
                        diffPointIndex: 0,
                        diffPointY: snapToValue(mouseMovement.diffY, snapGridRes),
                        diffPointX: snapToValue(mouseMovement.diffX, snapGridRes),
                    });
                }
                break;
            default:
                break;
        }
    }, [mouseMovement]);

    /**
     * Compares the transform current values to the default transform values
     * Returns true if any of its values have changed, else false
     * Transform type is not verified
     */
    const didTransformInfoChange = useCallback((): boolean => {
        for (const key in transformInfo) {
            if (key !== "transformType" && transformInfo[key] !== initTransformInfo[key]) {
                return true;
            }
        }

        return false;
    }, [transformInfo]);

    return (
        <g ref={eleRef} style={{ cursor: "move", display: disabled ? "none" : undefined }}>
            {/* TRANSFORM CONTROLS */}
            <defs>
                <path id="marker" d="M16 48 L32 24 L32 0 L0 0 L0 24 Z" />
                <g id="handle">
                    <rect fill="transparent" height="20" width="20" x="0" y="0" />
                    <rect fill={secondaryPalette[500]} height="10" width="10" x="5" y="5" />
                </g>
            </defs>

            <g transform={`translate(${x}, ${y}) rotate(${rotation} ${finalWidth / 2} ${finalHeight / 2})`}>
                {/* BLUE OUTLINE */}
                <rect
                    id="rectangleOutlineId"
                    cursor="move"
                    ref={transformControl}
                    width={finalWidth}
                    height={finalHeight}
                    fill="none"
                    onMouseDown={handleTransformMouseDown(EnumTransformType.TRANSLATE_ELEMENT)}
                    stroke={secondaryPalette[500]}
                    strokeWidth="2"
                />

                {/* WHITE OUTLINE */}
                <rect
                    cursor="move"
                    width={finalWidth}
                    height={finalHeight}
                    fill="none"
                    onMouseDown={handleTransformMouseDown(EnumTransformType.TRANSLATE_ELEMENT)}
                    stroke="transparent"
                    strokeWidth="6"
                />

                {/* SIZE CONTROLS */}
                {showResizeControl && (
                    <g>
                        {/* NORTH */}
                        <use
                            id="resizeNorthId"
                            xlinkHref="#handle"
                            x={finalWidth / 2 - handlePinHalfSize}
                            y={-handlePinHalfSize}
                            onMouseDown={handleTransformMouseDown(EnumTransformType.SIZE_NORTH)}
                            cursor="ns-resize"
                        />

                        {/* SOUTH */}
                        <use
                            id="resizeSouthId"
                            xlinkHref="#handle"
                            x={finalWidth / 2 - handlePinHalfSize}
                            y={finalHeight - handlePinHalfSize}
                            onMouseDown={handleTransformMouseDown(EnumTransformType.SIZE_SOUTH)}
                            cursor="ns-resize"
                        />

                        {/* WEST */}
                        <use
                            id="resizeWestId"
                            xlinkHref="#handle"
                            x={-handlePinHalfSize}
                            y={finalHeight / 2 - handlePinHalfSize}
                            onMouseDown={handleTransformMouseDown(EnumTransformType.SIZE_WEST)}
                            cursor="ew-resize"
                        />

                        {/* EAST */}
                        <use
                            id="resizeEastId"
                            xlinkHref="#handle"
                            x={finalWidth - handlePinHalfSize}
                            y={finalHeight / 2 - handlePinHalfSize}
                            onMouseDown={handleTransformMouseDown(EnumTransformType.SIZE_EAST)}
                            cursor="ew-resize"
                        />
                    </g>
                )}

                {/* CORNER RESIZE CONTROLS */}
                {showCornerResizeControl && (
                    <g>
                        {/* NORTH WEST */}
                        <use
                            id="sizeNorthWestId"
                            x={-handlePinHalfSize}
                            y={-handlePinHalfSize}
                            xlinkHref="#handle"
                            onMouseDown={handleTransformMouseDown(EnumTransformType.SIZE_NORTH_WEST)}
                            cursor="nwse-resize"
                        />

                        {/* NORTH EAST */}
                        <use
                            id="sizeNorthEastId"
                            x={finalWidth - handlePinHalfSize}
                            y={-handlePinHalfSize}
                            xlinkHref="#handle"
                            onMouseDown={handleTransformMouseDown(EnumTransformType.SIZE_NORTH_EAST)}
                            cursor="nesw-resize"
                        />

                        {/* SOUTH WEST */}
                        <use
                            id="sizeSouthWestId"
                            x={-handlePinHalfSize}
                            y={finalHeight - handlePinHalfSize}
                            xlinkHref="#handle"
                            onMouseDown={handleTransformMouseDown(EnumTransformType.SIZE_SOUTH_WEST)}
                            cursor="nesw-resize"
                        />

                        {/* SOUTH EAST */}
                        <use
                            id="sizeSouthEastId"
                            x={finalWidth - handlePinHalfSize}
                            y={finalHeight - handlePinHalfSize}
                            xlinkHref="#handle"
                            onMouseDown={handleTransformMouseDown(EnumTransformType.SIZE_SOUTH_EAST)}
                            cursor="nwse-resize"
                        />
                    </g>
                )}

                {/* ROTATION CONTROL */}
                {showRotateControl && (
                    <circle
                        id="rotateHandleId"
                        cx={finalWidth / 2}
                        cy={-20}
                        r="5"
                        fill={secondaryPalette[500]}
                        onMouseDown={handleTransformMouseDown(EnumTransformType.ROTATE)}
                        cursor="crosshair"
                    />
                )}

                {/* SCALE CONTROL */}
                {showScaleControl && (
                    <use
                        id="scaleHandleId"
                        x={finalWidth}
                        y={finalHeight}
                        xlinkHref="#handle"
                        onMouseDown={handleTransformMouseDown(EnumTransformType.SCALE)}
                        cursor="nwse-resize"
                    />
                )}
            </g>

            <g transform={`translate(${x}, ${y})`}>
                {/* POINTS TRANSFORM */}
                {points &&
                    points.map((point, pointIndex): JSX.Element => {
                        return (
                            <circle
                                key={pointIndex}
                                cx={point.x - x}
                                cy={point.y - y}
                                r="7"
                                onMouseDown={handleTransformMouseDown(EnumTransformType.TRANSLATE_POINT, pointIndex)}
                                fill={secondaryPalette[500]}
                                cursor="crosshair"
                            />
                        );
                    })}
                {/* LINE PREVIEW */}
                {points.length > 0 &&
                    (transformInfo.diffPointX !== 0 || transformInfo.diffPointY !== 0) &&
                    transformInfo.diffPointIndex !== -1 &&
                    !drawConnectorEvent && (
                        <line
                            x1={points[transformInfo.diffPointIndex].x - x + transformInfo.diffPointX}
                            y1={points[transformInfo.diffPointIndex].y - y + transformInfo.diffPointY}
                            x2={origin ? origin.x - x : points[points.length - 1 - transformInfo.diffPointIndex].x - x}
                            y2={origin ? origin.y - y : points[points.length - 1 - transformInfo.diffPointIndex].y - y}
                            stroke={greyPalette[500]}
                            strokeWidth={1}
                        />
                    )}

                {/* CONNECTOR PREVIEW (WHEN MOVING THE CONNECTOR ITSELF) */}
                {points.length > 0 &&
                    (transformInfo.diffPointX !== 0 || transformInfo.diffPointY !== 0) &&
                    transformInfo.diffPointIndex !== -1 &&
                    origin &&
                    drawConnectorEvent && (
                        <path
                            d={
                                drawConnectorEvent.source === "input"
                                    ? connectorPath(
                                          points[transformInfo.diffPointIndex].x - x + transformInfo.diffPointX,
                                          points[transformInfo.diffPointIndex].y - y + transformInfo.diffPointY,
                                          origin.x - x,
                                          origin.y - y
                                      )
                                    : connectorPath(
                                          origin.x - x,
                                          origin.y - y,
                                          points[transformInfo.diffPointIndex].x - x + transformInfo.diffPointX,
                                          points[transformInfo.diffPointIndex].y - y + transformInfo.diffPointY
                                      )
                            }
                            fill="none"
                            stroke={greyPalette[500]}
                            strokeWidth={1}
                        />
                    )}

                {/* CONNECTORS PREVIEW (WHEN TRANSLATING AN ELEMENT WITH CONNECTORS */}
                {(transformInfo.transformType === EnumTransformType.NONE ||
                    transformInfo.transformType === EnumTransformType.TRANSLATE_ELEMENT) &&
                    connectors &&
                    connectors.map(
                        (connectors: IConnector, index: number): JSX.Element =>
                            connectors.curved ? (
                                <path
                                    d={
                                        connectors.source === "input"
                                            ? connectorPath(
                                                  connectors.coordsPair.x1 - x,
                                                  connectors.coordsPair.y1 - y,
                                                  connectors.coordsPair.x2 - x + transformInfo.diffX,
                                                  connectors.coordsPair.y2 - y + transformInfo.diffY
                                              )
                                            : connectorPath(
                                                  connectors.coordsPair.x1 - x + transformInfo.diffX,
                                                  connectors.coordsPair.y1 - y + transformInfo.diffY,
                                                  connectors.coordsPair.x2 - x,
                                                  connectors.coordsPair.y2 - y
                                              )
                                    }
                                    fill="none"
                                    key={index}
                                    stroke={greyPalette[500]}
                                    strokeWidth={1}
                                />
                            ) : (
                                <line
                                    key={index}
                                    x1={connectors.coordsPair.x1 - x + transformInfo.diffX}
                                    y1={connectors.coordsPair.y1 - y + transformInfo.diffY}
                                    x2={connectors.coordsPair.x2 - x}
                                    y2={connectors.coordsPair.y2 - y}
                                    stroke={greyPalette[500]}
                                    strokeWidth={1}
                                />
                            )
                    )}
            </g>

            {/* PREVIEW SHADOW BOX */}
            <g
                style={{ pointerEvents: "none" }}
                transform={`translate(${x + transformInfo.diffX}, ${y + transformInfo.diffY})
                    rotate(${rotation + transformInfo.diffRotation} ${finalWidth / 2} ${finalHeight / 2})`}
                display={
                    transformInfo.diffX !== 0 || // applies when moving shape with keyboard
                    transformInfo.diffY !== 0 ||
                    // applies when moving shape with mouse
                    (captureMouseMove && mouseMovement.diffX !== 0 && mouseMovement.diffY !== 0)
                        ? "initial"
                        : "none"
                }
            >
                {/* Guidelines */}
                {showGuideLines && (
                    <React.Fragment>
                        <line
                            x1={-VERY_HIGH_VALUE}
                            x2={VERY_HIGH_VALUE}
                            y1={"0"}
                            y2={"0"}
                            strokeDasharray="5, 5"
                            strokeWidth="1px"
                            stroke={greyPalette[300]}
                        />
                        <line
                            x1={-VERY_HIGH_VALUE}
                            x2={VERY_HIGH_VALUE}
                            y1={previewShadowBoxHeight}
                            y2={previewShadowBoxHeight}
                            strokeDasharray="5, 5"
                            strokeWidth="1px"
                            stroke={greyPalette[300]}
                        />
                        <line
                            x1={"0"}
                            x2={"0"}
                            y1={VERY_HIGH_VALUE}
                            y2={-VERY_HIGH_VALUE}
                            strokeDasharray="5, 5"
                            strokeWidth="1px"
                            stroke={greyPalette[300]}
                        />
                        <line
                            x1={previewShadowBoxWidth}
                            x2={previewShadowBoxWidth}
                            y1={VERY_HIGH_VALUE}
                            y2={-VERY_HIGH_VALUE}
                            strokeDasharray="5, 5"
                            strokeWidth="1px"
                            stroke={greyPalette[300]}
                        />
                    </React.Fragment>
                )}

                {/* Shadow box */}
                <rect
                    width={previewShadowBoxWidth}
                    height={previewShadowBoxHeight}
                    fill="transparent"
                    stroke={greyPalette[500]}
                    strokeWidth="1px"
                />
            </g>
        </g>
    );
}
