import { IReworksFiltersOptions, IReworkUiModel, ReworkStatusEnum } from "@kortex/aos-common";

import {
    removeFalsyPropsExceptBoolean,
    formatStringFilterForFilterOptions,
    formatStringArrayFilterForFilterOptions,
    formatDateFilterForFilterOptions,
} from "../../../../utilitites/filterSearchBar";
import { IFilters } from "../FailureTicketSearchBar";

export const verifyIfFiltersAreEmpty = (filters: IFilters): boolean => {
    return !Object.keys(filters).some(
        (key) => !(!filters[key] || (typeof filters[key] === "object" && Object.keys(filters[key]).length === 0))
    );
};

export const convertUiFiltersToBackendFilters = (uiFilters: IFilters): IReworksFiltersOptions => {
    // Proceed with the call to the backend
    return removeFalsyPropsExceptBoolean({
        optimizedQuery: true,
        omitArchived: !uiFilters.showArchived,
        masterSearch: formatStringFilterForFilterOptions(uiFilters.plainText),
        reworkStatus: formatStringArrayFilterForFilterOptions(uiFilters.ticketStatuses) as Array<string> | undefined,
        reworkItemStatus: formatStringArrayFilterForFilterOptions(uiFilters.itemStatuses) as Array<string> | undefined,
        reference: formatStringFilterForFilterOptions(uiFilters.reference),
        createdDateFrom: formatDateFilterForFilterOptions(uiFilters.createdDateFrom),
        createdDateTo: formatDateFilterForFilterOptions(uiFilters.createdDateTo),
        showScheduled: uiFilters.showScheduled,
        yieldType: uiFilters.yieldType,
    });
};

/**
 * Tests a single failure ticket against the filters
 *
 * @param {IReworkUiModel} failureTicket - failure ticket to test
 * @returns {boolean} - true if failure ticket passed filters
 */
export const applyFilters = (failureTicket: IReworkUiModel, filters: IFilters): boolean => {
    // If job is archive and filter showArchived is disabled.
    if (failureTicket.reworkStatus === ReworkStatusEnum.ARCH && !filters.showArchived) return false;

    // If it reached thus far, it means all filters has been passed.
    return true;
};
