// common
import { IJobProcessOfTheReworkModel, ITokenDecoded } from "@kortex/aos-common";
// react
import React, { useEffect, useState } from "react";
// redux
import { secondaryPalette } from "@aos/react-components";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, Typography } from "@material-ui/core";

import { userCanInsert } from "../../../../../utilitites/IUserRights";

import FailureTicketJobProcessCard from "./FailureTicketJobProcessCard";
import FailureTicketJobRoutingCard from "./FailureTicketJobRoutingCard";

const useStyles = makeStyles({
    noProcessOrRoutingAvailable: {
        color: secondaryPalette[500],
        textAlign: "center",
    },
});

interface IOwnProps {
    allReworkJobsProcess?: IJobProcessOfTheReworkModel[];
    userInfo?: ITokenDecoded;
    key: number;
    onProcessSelected?: (process: IJobProcessOfTheReworkModel) => void;
}

export default function FailureTicketJobProcessCardContainer(props: IOwnProps): JSX.Element {
    const { allReworkJobsProcess, userInfo, onProcessSelected } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [selectedJobRoutingId, setSelectedJobRoutingId] = useState<number | undefined>(undefined);
    const [selectedJobRoutingOrderId, setSelectedJobRoutingOrderId] = useState<number | undefined>(undefined);
    const [selectedJobProcess, setSelectedJobProcess] = useState<IJobProcessOfTheReworkModel | undefined>(undefined);
    const [isDragDisabled, setIsDragDisabled] = useState<boolean>(true);

    /**
     * Effect triggered when job is updated.
     */
    useEffect((): void => {
        // job process is dragDisabled

        setIsDragDisabled(
            !(userCanInsert(userInfo?.roleRights.rework) /*&& job.status !== JobStatusEnum.DONE && job.status !== JobStatusEnum.ARCHIVED */)
        );
    }, [userInfo]);

    /**
     * Handle called when the user select process within a job
     *
     * @param {IJobProcessOfTheReworkModel} jobProcess - selected process
     */
    const handleSelectProcess = (jobProcess: IJobProcessOfTheReworkModel): void => {
        setSelectedJobRoutingId(jobProcess.routingId);
        setSelectedJobRoutingOrderId(jobProcess.orderId);
        setSelectedJobProcess(jobProcess);
        if (onProcessSelected) {
            onProcessSelected(jobProcess);
        }
    };

    const isJobProcessListUndefinedOrEmpty = !allReworkJobsProcess || allReworkJobsProcess.length == 0;

    return (
        <React.Fragment>
            {isJobProcessListUndefinedOrEmpty && (
                <KortexPanelCard isSelected={false}>
                    <Typography className={classes.noProcessOrRoutingAvailable} variant="body1" id="JobProcessListEmptyId">
                        {translate("scheduler.AddNewProcessOrRouting")}
                    </Typography>
                </KortexPanelCard>
            )}
            {!isJobProcessListUndefinedOrEmpty &&
                allReworkJobsProcess &&
                allReworkJobsProcess
                    .sort((process1, process2) => process1.orderId - process2.orderId)
                    .reduce((accumulator: JSX.Element[], jobProcess, index) => {
                        const isRouting = jobProcess.routingId !== 0;
                        if (isRouting) {
                            const prevIsRouting =
                                index === 0 ? false : allReworkJobsProcess[index - 1].routingId === allReworkJobsProcess[index].routingId;
                            const prevIsSameRouting =
                                index === 0 ? false : allReworkJobsProcess[index - 1].orderId === allReworkJobsProcess[index].orderId;
                            if (!prevIsRouting || !prevIsSameRouting) {
                                // Create a list of process inside of routing
                                const jobProcessListInRouting: IJobProcessOfTheReworkModel[] = [];

                                for (let counter = index; counter < allReworkJobsProcess.length; counter++) {
                                    if (allReworkJobsProcess[counter].orderId === jobProcess.orderId) {
                                        jobProcessListInRouting.push(allReworkJobsProcess[counter]);
                                    }
                                }

                                accumulator.push(
                                    <FailureTicketJobRoutingCard
                                        jobProcessRouting={jobProcess}
                                        jobProcessListInRouting={jobProcessListInRouting}
                                        isSelected={
                                            selectedJobRoutingId === jobProcess.routingId &&
                                            selectedJobRoutingOrderId === jobProcess.orderId
                                        }
                                        onSelection={handleSelectProcess}
                                        isDragDisabled={isDragDisabled}
                                        userInfo={userInfo}
                                        key={index}
                                    />
                                );
                            }
                        } else {
                            const isJobProcessCardSelected = Boolean(
                                selectedJobProcess && jobProcess.jobProcessId === selectedJobProcess.jobProcessId
                            );
                            accumulator.push(
                                <FailureTicketJobProcessCard
                                    isSelected={isJobProcessCardSelected}
                                    jobProcess={jobProcess}
                                    isDragDisabled={isDragDisabled}
                                    userInfo={userInfo}
                                    onSelection={handleSelectProcess}
                                    key={index}
                                />
                            );
                        }
                        return accumulator;
                    }, [])}
        </React.Fragment>
    );
}
