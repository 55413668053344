export function getFollowUpCount(item) {
    let total = 0;
    let totalWithoutOverconsumption = 0;
    for (const followUps of Object.values(item.followUp)) {
        for (const followUp of followUps) {
            total += followUp.quantity;
            if (!followUp.isOverconsumption) {
                totalWithoutOverconsumption += followUp.quantity;
            }
        }
    }
    return [total, totalWithoutOverconsumption];
}
