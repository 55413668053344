export class ActionBaseConfig {
    constructor(processActionId, processId, type, label, inputs, outputs, posX = 0, posY = 0) {
        this.label = "";
        this.posX = 0;
        this.posY = 0;
        this.inputs = [];
        this.outputs = [];
        this.processActionId = processActionId;
        this.processId = processId;
        this.type = type;
        this.label = label;
        this.inputs = inputs;
        this.outputs = outputs;
        this.posX = posX;
        this.posY = posY;
    }
}
