export const task = {
    rpc: {
        getOne: {
            funcId: 101,
            funcName: "taskOne",
        },
        getAll: {
            funcId: 102,
            funcName: "taskAll",
        },
        insert: {
            funcId: 103,
            funcName: "taskInsert",
        },
        update: {
            funcId: 104,
            funcName: "taskUpdate",
        },
        delete: {
            funcId: 105,
            funcName: "taskDelete",
        },
        inserted: {
            funcId: 106,
            funcName: "taskInserted",
        },
        updated: {
            funcId: 107,
            funcName: "taskUpdated",
        },
        deleted: {
            funcId: 108,
            funcName: "taskDeleted",
        },
    },
    serviceId: 100,
    serviceVersion: 1,
};
