var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function SortAlphaUp(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M2.47 5.048l1.061 1.061 2.432-2.432v19.573h1.5v-19.573l2.432 2.432 1.061-1.061-4.243-4.243-4.243 4.243z M14.25 14.625h4.736l-5.111 4.722v2.028h6.75v-1.5h-5.111l5.111-4.722v-2.028h-6.375v1.5z M16.041 7.875h2.794l0.875 2.625h1.581l-2.75-8.25h-2.206l-2.75 8.25h1.581zM17.416 3.75h0.044l0.875 2.625h-1.794z", id: "icons-sort-alpha-up" })));
}
