// krc
import { greyPalette, secondaryPalette, theme } from "@aos/react-components";
import { getFormattedDate, IFailureLogUi } from "@kortex/aos-common";
// react
import * as React from "react";
// mUI
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
// Component
import KortexLabelText from "@kortex/aos-ui/components/core/KortexLabelText";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

import ProcessIcon from "../../../../core/Icons/Process/Process";

const useStyles = makeStyles({
    card: {
        padding: "5px",
        display: "flex",
    },
    iconContainer: {
        color: greyPalette[500],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    cardFailDescription: {
        width: "100%",
        padding: "0 8px",
        display: "grid",
        alignItems: "center",
    },
    logInfoContainer: {
        width: "100%",
    },
    logInfoLine1: {
        display: "flex",
    },
    logInfoLine2: {
        display: "flex",
    },
    failInfoContainer: {
        width: "100%",
        borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
    commentContainer: {
        paddingLeft: "15px",
        width: "auto",
    },
    commentLabel: {
        color: secondaryPalette[500],
    },
    comment: {
        width: "auto",
    },
    failDescriptionContainer: {
        display: "flex",
    },
    textShort: {
        margin: "5px 15px",
        width: "auto",
    },
    textLarge: {
        margin: "5px 15px",
        width: "auto",
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontSize: "1rem",
        fontWeight: "bold",
    },
});

interface IOwnProps {
    index: number;
    workLog: IFailureLogUi;
}

export default function FailureLogCard(props: IOwnProps): JSX.Element {
    const { index, workLog } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const intl = useIntl();

    const resultFailFromExecution = workLog.resultMasterId;

    // Render
    return (
        <KortexPanelCard key={index} isSelected={false} padding={10}>
            <div className={classes.card} id={`reworkLogCard${index}Id`}>
                <div className={classes.iconContainer}>{resultFailFromExecution ? <ProcessIcon /> : <EditIcon />}</div>
                <div className={classes.cardFailDescription}>
                    <Typography variant="h2" className={classes.title}>
                        {translate("rework.failureLog")}
                    </Typography>
                    {/* Log info */}
                    <div className={classes.logInfoContainer}>
                        {/* Log info line 1*/}
                        <div className={classes.logInfoLine1}>
                            <KortexLabelText
                                label={translate("rework.date")}
                                text={workLog.createdOn ? getFormattedDate(workLog.createdOn, true) : ""}
                                className={classes.textShort}
                                id={`reworkDate${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("rework.reworkItemStatus")}
                                text={workLog.reworkItemStatus.title}
                                className={classes.textShort}
                                id={`reworkItemStatusLog${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("rework.user")}
                                text={workLog.rework.user.fullName}
                                className={classes.textShort}
                                id={`user${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("player.runDetailsDialog.runType")}
                                text={
                                    intl.messages?.[workLog.description]
                                        ? translate(workLog.description)
                                        : translate(`player.failed.${workLog.description}`)
                                }
                                className={classes.textLarge}
                                id={`runType${index}Id`}
                            />
                        </div>
                        {/* Log info line 2*/}
                        <div className={classes.logInfoLine2}>
                            <KortexLabelText
                                label={translate("rework.process")}
                                text={workLog.rework.process.treeNodeProcess.label}
                                className={classes.textLarge}
                                id={`processLog${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("processUpdate.processAction")}
                                text={workLog.processAction.label}
                                className={classes.textLarge}
                                id={`actionName${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("player.step")}
                                text={workLog.processAction.stepLabel}
                                className={classes.textLarge}
                                id={`actionStepName${index}Id`}
                            />
                        </div>
                    </div>
                    {/* Fail info */}
                    <div className={classes.failInfoContainer}>
                        <div key={index} className={classes.failInfoContainer}>
                            {/* Fail info from execution */}
                            {workLog.resultFailProblems.map(
                                (resultFailProb, indexFailInfo): JSX.Element => (
                                    <div key={indexFailInfo}>
                                        <div className={classes.failDescriptionContainer}>
                                            <KortexLabelText
                                                label={translate("treeview.menu.failureType")}
                                                text={resultFailProb.failureType.title}
                                                className={classes.textShort}
                                                id={`failureTypeLog${indexFailInfo}Id`}
                                            />
                                            <KortexLabelText
                                                label={translate("player.symptom")}
                                                text={resultFailProb.failureSubType.title}
                                                className={classes.textShort}
                                                id={`symptomLog${indexFailInfo}Id`}
                                            />
                                        </div>
                                        <div className={classes.commentContainer}>
                                            <div className={classes.commentLabel}>{translate("player.comments")}</div>
                                            <div className={classes.comment} id={`commentLog${indexFailInfo}Id`}>
                                                {resultFailProb.comments.length > 0 ? resultFailProb.comments : translate("general.na")}
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </KortexPanelCard>
    );
}
