import { ActionTypeEnum, SequencerActionStatusEnum, SequencerActionPlayCompletedPayloadTypeEnum } from "../../enums";
import { deepClone, KortexSequencerDebugger } from "../../utilities";
const debug = KortexSequencerDebugger.core.fail;
export const SEQUENCER_ACTION_FAIL_DEFAULT_STATE = {};
function create({}) {
    const _state = SEQUENCER_ACTION_FAIL_DEFAULT_STATE;
    function assertValidConfig(config) {
        debug("config validation:%o", config);
        debug("config validation:successful");
    }
    async function play(config) {
        debug("play:%o", config);
        debug("play:completed");
        return {
            payload: {
                output: null,
                type: SequencerActionPlayCompletedPayloadTypeEnum.NEXT,
            },
            status: SequencerActionStatusEnum.COMPLETED,
        };
    }
    async function stop() {
        debug("stop");
    }
    return {
        get state() {
            return deepClone(_state);
        },
        get type() {
            return ActionTypeEnum.FAIL;
        },
        assertValidConfig,
        play,
        stop,
    };
}
export const SequencerActionFailFactory = {
    create,
};
