export function getActionsBoundingBox(actions) {
    const box = {
        x1: Number.MAX_VALUE,
        x2: Number.MIN_VALUE,
        y1: Number.MAX_VALUE,
        y2: Number.MIN_VALUE,
        width: 0,
        height: 0,
    };
    for (const action of actions) {
        box.x1 = Math.min(box.x1, action.posX);
        box.x2 = Math.max(box.x2, action.posX + 205 + 30); // Need to add box width + margin
        box.y1 = Math.min(box.y1, action.posY);
        box.y2 = Math.max(box.y2, action.posY + 120 + 30); // Need to add box height + margin
    }
    box.width = Math.abs(box.x2 - box.x1);
    box.height = Math.abs(box.y2 - box.y1);
    return box;
}
