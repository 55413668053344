import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { IBomFollowUp } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { CircularProgress, makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

import { serviceMap, ServiceVariant } from "../../utils";

const useStyles = makeStyles({
    dialogContent: {
        display: "grid",
        gridGap: "5px",
    },
    quantityTextField: {
        width: "150px",
    },
    traceability: {
        display: "flex",
        alignItems: "center",
    },
});

interface IOwnProps {
    followUp: IBomFollowUp;
    onClose: () => void;
    open: boolean;
    variant: ServiceVariant;
}

const EditTraceabilityDialog: FC<IOwnProps> = (props) => {
    const { followUp, onClose, open, variant } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const [justification, setJustification] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [traceability, setTraceability] = useState<string>(followUp.traceability);

    const confirmButtonDisabled = loading || traceability.length === 0 || justification.length < 3;

    /**
     * Resets the fields when the dialog is opened
     */
    useEffect(() => {
        if (open) {
            setJustification("");
            setTraceability(followUp.traceability);
        }
    }, [open]);

    /**
     * Closes the dialog
     */
    const handleClose = (): void => {
        onClose();
    };

    /**
     * Proceeds to edit the follow-up
     */
    const handleConfirm = (): void => {
        setLoading(true);

        dispatch(
            serviceMap[variant].editItem({
                bomFollowUpId: followUp.bomFollowUpId,
                justification,
                traceability,
            })
        )
            .then(handleClose)
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Updates the justification (inner state)
     */
    const handleJustificationChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setJustification(event.target.value);
    };

    /**
     * Updates the traceability (inner state)
     */
    const handleTraceabilityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setTraceability(event.target.value);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={confirmButtonDisabled}
            onCancel={handleClose}
            onConfirm={handleConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("bomPage.bomTable.edit"),
            }}
            textLabelsIcons={{
                proceedButtonIcon: loading ? <CircularProgress /> : undefined,
            }}
        >
            <div className={classes.dialogContent}>
                {/* TRACEABILITY */}
                <div className={classes.traceability}>
                    <KortexTextField
                        label={translate("bomPage.bomTable.traceability")}
                        onChange={handleTraceabilityChange}
                        TextFieldProps={{
                            autoComplete: "off",
                            id: "editTraceabilityDialogTraceabilityId",
                            required: true,
                        }}
                        value={traceability}
                        variant="outlined"
                    />
                </div>

                {/* JUSTIFICATION */}
                <KortexTextField
                    label={translate("bomPage.bomTable.justification")}
                    onChange={handleJustificationChange}
                    TextFieldProps={{
                        autoComplete: "off",
                        id: "editTraceabilityDialogJustificationId",
                        multiline: true,
                        required: true,
                        rows: 5,
                    }}
                    value={justification}
                    variant="outlined"
                />
            </div>
        </KortexDialogConfirmation>
    );
};

export default EditTraceabilityDialog;
