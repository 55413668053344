import { UserDbModel, IUserDbModel } from "@kortex/aos-common";
import { KortexTextField } from "@aos/react-components";
import { Checkbox, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesUsers } from "../../../../../redux/effects";

const useStyles = makeStyles({
    root: {
        overflowY: "hidden",
    },
    searchField: {
        margin: 0,
        width: "100%",
        position: "sticky",
        top: 0,
    },
    users: {
        display: "flex",
        flexFlow: "wrap",
        overflowY: "auto",
        height: "calc(100vh - 310px)",
    },
    user: {
        width: "290px",
    },
});

interface IOwnProps {
    onUserSelect: (selectedUsers: UserDbModel[]) => void;
    userFilter: () => (user: IUserDbModel) => boolean;
}

export default function UserSelector(props: IOwnProps): JSX.Element {
    const { userFilter, onUserSelect } = props;

    const classes = useStyles();
    const users = useEntitiesUsers();
    const translate = useTranslate();

    const [selectedUsers, setSelectedUser] = useState<UserDbModel[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<UserDbModel[]>([]);
    const [searchValue, setSearchValue] = useState<string>("");

    // call onUserSelect prop when selected users change
    useEffect((): void => {
        onUserSelect(selectedUsers);
    }, [selectedUsers]);

    // includes only the requested used based on filter sent to parent
    useEffect((): void => {
        const usersFiltered = users
            .filter(userFilter())
            .filter((user) => {
                if (searchValue !== "") {
                    return getUserFullName(user).toLocaleLowerCase("en").includes(searchValue.toLocaleLowerCase("en"));
                } else {
                    return true;
                }
            })
            .sort((a, b) => getUserFullName(a).localeCompare(getUserFullName(b), "en", { sensitivity: "base" }));
        setFilteredUsers(usersFiltered);
    }, [users, searchValue]);

    /**
     * Get first name and last name of user
     */
    const getUserFullName = (user: UserDbModel): string => `${user.lastName}, ${user.firstName}`;

    /**
     * When a user is clicked, add/remove it from the selected user list
     *
     * @param {UserDbModel} clickUser - clicked user
     */
    const handleUserClick =
        (clickUser: UserDbModel): (() => void) =>
        (): void => {
            const index = selectedUsers.findIndex((user) => user.userId === clickUser.userId);

            if (index === -1) {
                // User was not selected, add it
                setSelectedUser([...selectedUsers, clickUser]);
            } else {
                // User was already selected
                const selectedUsersList = [...selectedUsers];
                selectedUsersList.splice(index, 1);
                setSelectedUser(selectedUsersList);
            }
        };

    /**
     * Change the search value
     *
     * @param {string} value - updated value
     */
    const handleSearchValueChange = (value: string): void => {
        setSearchValue(value);
    };

    return (
        <div className={classes.root}>
            <KortexTextField
                changedDelayMS={500}
                className={classes.searchField}
                onChanged={handleSearchValueChange}
                TextFieldProps={{
                    placeholder: translate("process.versioning.reviewRequestSearchUser"),
                }}
                value={searchValue}
            />
            <div className={classes.users}>
                {filteredUsers.map((user, index) => {
                    const selected = selectedUsers.includes(user);
                    return (
                        <div className={classes.user} key={index}>
                            <MenuItem button={true} dense={true} onClick={handleUserClick(user)} selected={selectedUsers.includes(user)}>
                                <Checkbox checked={selected} />
                                <Typography variant="body2">{getUserFullName(user)}</Typography>
                            </MenuItem>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
