var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
// react
import { useEffect } from "react";
import * as React from "react";
// mui
import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Cancel from "@material-ui/icons/Cancel";
import Done from "@material-ui/icons/Done";
import { theme } from "../mui/theme";
import { greyPalette } from "../mui/palettes";
// Style
var useStyles = makeStyles({
    dialogTitle: __assign(__assign({}, theme.typography.h5), { alignItems: "center", display: "flex", backgroundColor: theme.palette.primary[500], color: theme.palette.common.white, padding: "20px" }),
    dialogContent: __assign(__assign({}, theme.typography.body2), { padding: "20px", height: "100%" }),
    dialogActions: (_a = {
            display: "flex",
            flexDirection: "column-reverse"
        },
        _a[theme.breakpoints.up("sm")] = {
            flexDirection: "row",
        },
        _a.margin = "0px",
        _a.padding = "8px 4px",
        _a.backgroundColor = greyPalette[200],
        _a),
    dialogButtons: {
        margin: "5px",
    },
    buttonIcons: {
        marginRight: "10px",
    },
});
function KortexDialogConfirmation(props) {
    var _a, _b;
    var classes = useStyles(props);
    var closeOnEscape = props.closeOnEscape, open = props.open, textLabels = props.textLabels, dialogProps = props.dialogProps, children = props.children, cancelDisabled = props.cancelDisabled, confirmDisabled = props.confirmDisabled, textLabelsIcons = props.textLabelsIcons;
    /**
     * Function called when the user presses a button inside the dialog
     *
     * @param {boolean} confirm - true to call onConfirm, false to call onCancel
     */
    var handleConfirmation = function (confirm) {
        return function () {
            if (confirm && props.onConfirm) {
                props.onConfirm();
            }
            else if (!confirm && props.onCancel) {
                props.onCancel();
            }
        };
    };
    // Effect Called when some prop change (dependencies of handleKeyDown)
    useEffect(function () {
        // Function called when a key is pressed. Handles the Enter and Escape keys
        var handleKeydown = function (e) {
            if (open && !confirmDisabled && e.key == "Enter") {
                e.preventDefault();
                handleConfirmation(true)();
            }
            else if (open && closeOnEscape && e.key == "Escape") {
                e.preventDefault();
                handleConfirmation(false)();
            }
        };
        if (open) {
            window.addEventListener("keydown", handleKeydown);
        }
        return function () {
            window.removeEventListener("keydown", handleKeydown);
        };
    }, [open, closeOnEscape, confirmDisabled, props.onConfirm, props.onCancel]);
    return (React.createElement(Dialog, __assign({ open: open, disableAutoFocus: true, disableEscapeKeyDown: true, fullWidth: true }, dialogProps),
        React.createElement(DialogTitle, { className: classes.dialogTitle, disableTypography: true }, textLabelsIcons === null || textLabelsIcons === void 0 ? void 0 :
            textLabelsIcons.titleIcon,
            textLabels.titleLabel),
        React.createElement(DialogContent, { className: classes.dialogContent }, children),
        React.createElement(DialogActions, { className: classes.dialogActions },
            props.onCancel && (React.createElement(Button, { variant: "contained", color: "default", id: "cancelButtonId", onClick: handleConfirmation(false), className: classes.dialogButtons, disabled: cancelDisabled }, (_a = textLabelsIcons === null || textLabelsIcons === void 0 ? void 0 : textLabelsIcons.cancelButtonIcon) !== null && _a !== void 0 ? _a : React.createElement(Cancel, { className: classes.buttonIcons }),
                textLabels.cancelButtonLabel)),
            props.onConfirm && (React.createElement(Button, { className: classes.dialogButtons, color: "secondary", disabled: confirmDisabled, id: "proceedButtonId", onClick: handleConfirmation(true), variant: "contained" }, (_b = textLabelsIcons === null || textLabelsIcons === void 0 ? void 0 : textLabelsIcons.proceedButtonIcon) !== null && _b !== void 0 ? _b : React.createElement(Done, { className: classes.buttonIcons }),
                textLabels.proceedButtonLabel)))));
}
export default KortexDialogConfirmation;
