export const ProcessSystemVariableIdentifier = {
    // Action
    SYS_ACTION_NAME: "SYS_ACTION_NAME",
    SYS_ACTION_STEP: "SYS_ACTION_STEP",
    SYS_ACTION_STEP_NAME: "SYS_ACTION_STEP_NAME",
    // Fail
    SYS_FAIL_ACTION_NAME: "SYS_FAIL_ACTION_NAME",
    SYS_FAIL_ACTION_STEP: "SYS_FAIL_ACTION_STEP",
    SYS_FAIL_ACTION_STEP_NAME: "SYS_FAIL_ACTION_STEP_NAME",
    SYS_FAIL_DESCRIPTION: "SYS_FAIL_DESCRIPTION",
    SYS_FAIL_TIMESTAMP: "SYS_FAIL_TIMESTAMP",
    // Local date
    SYS_LOCAL_DATE: "SYS_LOCAL_DATE",
    SYS_LOCAL_DAY: "SYS_LOCAL_DAY",
    SYS_LOCAL_MONTH: "SYS_LOCAL_MONTH",
    SYS_LOCAL_TIME: "SYS_LOCAL_TIME",
    SYS_LOCAL_TIMESTAMP: "SYS_LOCAL_TIMESTAMP",
    SYS_LOCAL_YEAR: "SYS_LOCAL_YEAR",
    // Process
    SYS_PROCESS_NAME: "SYS_PROCESS_NAME",
    SYS_PROCESS_REFERENCE_ID: "SYS_PROCESS_REFERENCE_ID",
    SYS_PROCESS_STATUS: "SYS_PROCESS_STATUS",
    SYS_PROCESS_VERSION: "SYS_PROCESS_VERSION",
    // Scheduler
    SYS_SCHEDULER_JOB_PART_NUMBER: "SYS_SCHEDULER_JOB_PART_NUMBER",
    SYS_SCHEDULER_JOB_QUANTITY: "SYS_SCHEDULER_JOB_QUANTITY",
    SYS_SCHEDULER_JOB_REFERENCE: "SYS_SCHEDULER_JOB_REFERENCE",
    SYS_SCHEDULER_JOB_WORK_ORDER: "SYS_SCHEDULER_JOB_WORK_ORDER",
    SYS_SCHEDULER_JOB_PROCESS_QUANTITY: "SYS_SCHEDULER_JOB_PROCESS_QUANTITY",
    // System
    SYS_TRACKING_ID: "SYS_TRACKING_ID",
    SYS_BATCH_ENABLE: "SYS_BATCH_ENABLE",
    SYS_BATCH_QUANTITY: "SYS_BATCH_QUANTITY",
    SYS_BATCH_FAIL_QUANTITY: "SYS_BATCH_FAIL_QUANTITY",
    // User
    SYS_USER_CODE: "SYS_USER_CODE",
    SYS_USER_NAME: "SYS_USER_NAME",
};
