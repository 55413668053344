import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    TrainingGetUserIdsOfTrainingsByOriginalProcessIdsReq,
    TrainingGetUserIdsOfTrainingsByOriginalProcessIdsRes,
    TrainingInsertedNotif,
    TrainingUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { trainingGetUserIdsOfTrainingsByOriginalProcessIds } from "./getUserIdsOfTrainingsByOriginalProcessIds";
import { trainingInserted } from "./inserted";
import { trainingUpdated } from "./updated";

interface IAOSClientServiceWrappedTraining {
    onInserted: AOSClientServiceNotificationWrapped<TrainingInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<TrainingUpdatedNotif>;
    getUserIdsOfTrainingsByOriginalProcessIds: AOSClientServiceRequestWrapped<
        TrainingGetUserIdsOfTrainingsByOriginalProcessIdsReq,
        TrainingGetUserIdsOfTrainingsByOriginalProcessIdsRes
    >;
}

type AOSClientServiceWrappedTraining = IAOSClientServiceWrappedTraining;

// ADD_API_CALL Formation
export function generateTrainingServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedTraining {
    return {
        onInserted: AOSClientServiceWrapperUI.notification(trainingInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(trainingUpdated(router, notifier)),
        getUserIdsOfTrainingsByOriginalProcessIds: AOSClientServiceWrapperUI.request(
            trainingGetUserIdsOfTrainingsByOriginalProcessIds(router)
        ),
    };
}

generateTrainingServiceClient.serviceId = 134;
