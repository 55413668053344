import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    TrainingCertificationPendingDeleteReq,
    TrainingCertificationPendingDeleteRes,
    TrainingCertificationPendingDeletedNotif,
    TrainingCertificationPendingGetAllByUserIdReq,
    TrainingCertificationPendingGetAllByUserIdRes,
    TrainingCertificationPendingGetAllReq,
    TrainingCertificationPendingGetAllRes,
    TrainingCertificationPendingInsertedNotif,
    TrainingCertificationSignReq,
    TrainingCertificationSignRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { trainingCertificationPendingDelete } from "./delete";
import { trainingCertificationPendingDeleted } from "./deleted";
import { trainingCertificationPendingGetAll } from "./getAll";
import { trainingCertificationPendingGetAllByUserId } from "./getAllByUserId";
import { trainingCertificationPendingInserted } from "./inserted";
import { trainingCertificationSign } from "./trainingCertificationSign";

interface IAOSClientServiceWrappedTrainingCertificationPending {
    delete: AOSClientServiceRequestWrapped<TrainingCertificationPendingDeleteReq, TrainingCertificationPendingDeleteRes>;
    getAll: AOSClientServiceRequestWrapped<TrainingCertificationPendingGetAllReq, TrainingCertificationPendingGetAllRes>;
    getAllByUserId: AOSClientServiceRequestWrapped<
        TrainingCertificationPendingGetAllByUserIdReq,
        TrainingCertificationPendingGetAllByUserIdRes
    >;
    trainingCertificationSign: AOSClientServiceRequestWrapped<TrainingCertificationSignReq, TrainingCertificationSignRes>;
    onDeleted: AOSClientServiceNotificationWrapped<TrainingCertificationPendingDeletedNotif>;
    onInserted: AOSClientServiceNotificationWrapped<TrainingCertificationPendingInsertedNotif>;
}

type AOSClientServiceWrappedTrainingCertificationPending = IAOSClientServiceWrappedTrainingCertificationPending;

// ADD_API_CALL Root cause
export function generateTrainingCertificationPendingServiceClient(
    router: IRouterClient,
    notifier: EventEmitter
): AOSClientServiceWrappedTrainingCertificationPending {
    return {
        delete: AOSClientServiceWrapperUI.request(trainingCertificationPendingDelete(router)),
        getAll: AOSClientServiceWrapperUI.request(trainingCertificationPendingGetAll(router)),
        getAllByUserId: AOSClientServiceWrapperUI.request(trainingCertificationPendingGetAllByUserId(router)),
        trainingCertificationSign: AOSClientServiceWrapperUI.request(trainingCertificationSign(router)),
        onInserted: AOSClientServiceWrapperUI.notification(trainingCertificationPendingInserted(router, notifier)),
        onDeleted: AOSClientServiceWrapperUI.notification(trainingCertificationPendingDeleted(router, notifier)),
    };
}

generateTrainingCertificationPendingServiceClient.serviceId = 136;
