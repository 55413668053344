import { SUGGESTION_EN, SUGGESTION_FR } from "./suggestion";
import { EXPLANATION_EN, EXPLANATION_FR } from "./explanation";
import { TITLE_EN, TITLE_FR } from "./title";
export const KEY_ERROR_LOCALE_EN = {
    ...TITLE_EN,
    ...EXPLANATION_EN,
    ...SUGGESTION_EN,
};
export const KEY_ERROR_LOCALE_FR = {
    ...TITLE_FR,
    ...EXPLANATION_FR,
    ...SUGGESTION_FR,
};
