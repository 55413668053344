/**
 * Creates a new training certificate trainee model
 *
 */
export function newTrainingCertificateTraineeModel(options) {
    const { electronicSignatureId, trainingCertificateId } = options;
    return {
        trainingCertificateTraineeId: 0,
        electronicSignatureId,
        trainingCertificateId,
    };
}
