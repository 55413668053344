import { useEffect, useState } from "react";

/**
 * Debounce hook
 * The returned debounced value won't match the input value until the
 * debounced delay value is not reached
 *
 * @param {?} value - Value to be debounced
 * @param {number} delay - Debounced delay
 * @returns {?} debounce Debounced value
 */
export function useDebounce<T>(value: T, delay: number): T {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect((): (() => void) => {
        // Update debounced value after delay
        const handler = setTimeout((): void => {
            setDebouncedValue(value);
        }, delay);

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return (): void => {
            clearTimeout(handler);
        };
    }, [value, delay]); // Only re-call effect if value or delay changes

    return debouncedValue;
}
