var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
// react
import { useEffect } from "react";
import * as React from "react";
// mui
import { DialogContent, DialogTitle, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { theme } from "../mui/theme";
// Style
var useStyles = makeStyles({
    dialogTitle: __assign(__assign({}, theme.typography.h5), { display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: theme.palette.primary[500], color: theme.palette.common.white, padding: "5px 10px" }),
    dialogContent: __assign(__assign({}, theme.typography.body2), { padding: "20px", height: "100%" }),
    dialogActions: (_a = {
            display: "flex",
            flexDirection: "column-reverse"
        },
        _a[theme.breakpoints.up("sm")] = {
            flexDirection: "row",
        },
        _a),
    dialogButtons: {
        margin: "5px",
    },
    buttonIcons: {
        marginRight: "10px",
    },
});
var Transition = React.forwardRef(function (props, ref) { return React.createElement(Slide, __assign({ direction: "up" }, props, { ref: ref })); });
Transition.displayName = "SlideUp";
function KortexDialogDrawer(props) {
    var classes = useStyles(props);
    var open = props.open, textLabels = props.textLabels, dialogProps = props.dialogProps, children = props.children;
    /**
     * Called when mounted
     */
    useEffect(function () {
        window.addEventListener("keydown", handleKeydown);
        return function () {
            window.removeEventListener("keydown", handleKeydown);
        };
    });
    /**
     * Function called when the user presses the close icon
     */
    var handleClose = function () {
        props.onClose();
    };
    /**
     * Function called when the Escape key is pressed.
     */
    var handleKeydown = function (e) {
        if (props.open && props.closeOnEscape && e.key === "Escape") {
            e.preventDefault();
            handleClose();
        }
    };
    return (React.createElement(Dialog, __assign({ fullScreen: true, open: open, 
        // @ts-ignore; React.ForwardRef is a pain in the ass
        TransitionComponent: Transition, disableEscapeKeyDown: true }, dialogProps),
        React.createElement(DialogTitle, { className: classes.dialogTitle, disableTypography: true },
            textLabels.titleLabel,
            React.createElement(IconButton, { color: "inherit", onClick: handleClose, "aria-label": "Close" },
                React.createElement(CloseIcon, { fontSize: "large" }))),
        React.createElement(DialogContent, { className: classes.dialogContent }, children)));
}
export default KortexDialogDrawer;
