import { KortexOutlinedTextField, KortexTextField, theme } from "@aos/react-components";
import {
    EnumActionStatus,
    IFailureTypeDbModel,
    IOperatorJobProcessInfo,
    IPlayerReworkInfo,
    IProcessUiModel,
    IResultFailProblemModel,
    IReworkItemStatusDbModel,
    IStepFailure,
    ReworkYieldType,
    TreeNodeId,
} from "@kortex/aos-common";
import NotificationsCenterDialog from "@kortex/aos-ui/components/pages/NotificationsCenter/NotificationsCenterDialog";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { reworkGetInfoBeforeCreate } from "@kortex/aos-ui/redux/rework-manager/rework-thunks";
import { Button, Dialog, DialogActions, DialogContent, Fab, MenuItem, Typography, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EndIcon from "@material-ui/icons/NotInterested";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import RemoveIcon from "@material-ui/icons/Remove";
import WarningIcon from "@material-ui/icons/Warning";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import {
    useEntitiesFailureSymptoms,
    useEntitiesFailureTypes,
    useEntitiesReworkItemStatus,
    useEntitiesTreeProcess,
} from "../../../../../redux/effects";
import { useSelectorPlayer } from "../../../../../redux/selectors";
import { getParentNodesPath } from "../../../../../utilitites/getParentNodes";
import { isFailedByManualStop } from "../../../../../utilitites/isFailedByManualStop";
import YieldTypeSelector from "../../../YieldTypeSelector/YieldTypeSelector";
import RunDetailsDialogContent from "../RunDetailsDialog/RunDetailsDialogContent";

const useStyles = makeStyles({
    dialogTitle: {
        display: "grid",
        gridAutoColumns: "10% 85% 5%",
        gridAutoFlow: "column",
    },
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    mainIcon: {
        fontSize: "40px",
        marginRight: "20px",
        color: theme.palette.error[500],
    },
    buttonIcon: {
        paddingRight: "10px",
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 20px",
    },
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
    },
    mainForm: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
    },
    failure: {
        marginTop: "15px",
        backgroundColor: theme.palette.grey.A100,
        padding: "10px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
    },
    failureRow: {
        display: "flex",
        flexDirection: "row",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    failureBatch: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "10px",
        paddingRight: "10px",
        alignItems: "center",
    },
    deleteIcon: {
        position: "absolute",
        top: "-5px",
        right: "-10px",
    },
    problemContainer: {
        display: "flex",
        marginTop: "20px",
        width: "100%",
    },
    problems: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    problem: {
        flexGrow: 1,
        display: "flex",
        marginTop: "15px",
        width: "100%",
    },
    addIcon: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.grey[500],
        width: "60px",
        cursor: "pointer",
    },
    removeIcon: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.grey[500],
        width: "60px",
        cursor: "pointer",
    },
    text: {
        margin: "5px 0px",
        color: theme.palette.primary[500],
    },
    description: {
        margin: "15px 0px 5px 0px",
        color: theme.palette.primary[500],
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: "15px",
        maxHeight: "200px",
        overflowY: "auto",
        marginBottom: "20px",
    },
    box: {
        margin: "15px 0px 5px 0px",
        color: theme.palette.primary[500],
        border: `1px solid ${theme.palette.grey[200]}`,
        padding: "15px",
        marginBottom: "20px",
    },
    boxRow: {
        display: "flex",
    },
    boxRowTitle: {
        display: "flex",
        flexDirection: "column",
        margin: "5px 0px 20px 10px",
    },
    select: {
        width: "200px",
        margin: "5px 15px 0px 0px",
    },
    comments: {
        flexGrow: 1,
        margin: "5px 5px 0px 0px",
    },
    error: {
        marginTop: "20px",
        color: theme.palette.error[500],
    },
    endIcon: {
        color: theme.palette.grey[400],
        paddingLeft: "10px",
    },
    yieldSelectorContainer: {
        display: "flex",
        flexDirection: "row",
        padding: "5px 0",
    },
});

interface IDefaultProblem extends IResultFailProblemModel {
    valid: boolean;
}

const defaultProblem: IDefaultProblem = {
    resultMasterId: 0,
    failureTypeId: 0,
    failureSubTypeId: 0,
    comments: "",
    valid: false,
};

interface IOwnProps {
    dryRunMode: boolean;
    jobProcessInfo?: IOperatorJobProcessInfo;
    open: boolean;
    process: IProcessUiModel | null;
    processTreeNodeId?: TreeNodeId;
    reworkId?: number;
    runWithoutJobProcess?: boolean;

    onCancel?: () => void;
    onClose?: () => void;
    onRetry?: () => void;
    onSendReport?: (failures: IStepFailure[], reworkInfo?: IPlayerReworkInfo, continueRun?: boolean) => void;
}

export default function PlayerStepFailedDialog(props: IOwnProps): JSX.Element {
    const {
        dryRunMode,
        jobProcessInfo,
        process,
        processTreeNodeId,
        onCancel,
        onClose,
        onRetry,
        onSendReport,
        reworkId,
        runWithoutJobProcess,
    } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const allFailureSymptoms = useEntitiesFailureSymptoms();
    const allFailureTypes = useEntitiesFailureTypes();
    const allNodes = useEntitiesTreeProcess();
    const allReworkItemStatus = useEntitiesReworkItemStatus();
    const playerState = useSelectorPlayer();

    const [failureQty, setFailureQty] = useState<number>(1);
    const [failures, setFailures] = useState<IStepFailure[]>([]);
    const [failureTypes, setFailureTypes] = useState<IFailureTypeDbModel[]>([]);
    const [invalidQty, setInvalidQty] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(props.open);
    const [reworkItemStatuses, setReworkItemStatuses] = useState<IReworkItemStatusDbModel[]>([]);
    const [scannedFailureTimestamps, setScannedFailureTimestamps] = useState<number[]>([]);
    const [validate, setValidate] = useState<boolean>(false);
    const [yieldType, setYieldType] = useState<ReworkYieldType>("FPY");

    const actionBaseState = playerState.actionsState[playerState.actionsState.length - 1]?.baseStates;
    const continueIfFail = Boolean(playerState.uiActionProps?.stepProps.continueIfFail);
    const displayProblemForm = !dryRunMode;
    const isManualFailure = displayProblemForm && props.open;
    const canRetry = !isManualFailure && Boolean(playerState.uiActionProps?.stepProps.canRetry); // TODO: Remove isManualFailure when we want to implement RETRY on manual failures
    const maxFailQty = playerState.processState.batchEnable
        ? playerState.processState.batchQty - (playerState.processState.inFailPath ? 0 : playerState.processState.failQty)
        : 1;

    /**
     * Opens the dialog when a new non-manual error occurs (and step does not continue on fail)
     */
    useEffect(() => {
        if (
            actionBaseState &&
            !isFailedByManualStop(actionBaseState.failure) &&
            actionBaseState.failure.time > 0 &&
            !scannedFailureTimestamps.includes(actionBaseState.failure.time) &&
            !continueIfFail &&
            actionBaseState.showFailForm
        ) {
            setOpen(true);
            setScannedFailureTimestamps(scannedFailureTimestamps.concat([actionBaseState.failure.time]));
        }
    }, [actionBaseState?.failure.time]);

    // Update inner state on prop change
    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    // Reset data when opening dialog
    useEffect(() => {
        if (open) {
            setFailureQty(1);
            setYieldType("FPY");
        }
    }, [open]);

    /**
     * Effect triggered on failure qty changes, sets the array to represent said failures
     */
    useEffect((): void => {
        if (failures.length < failureQty) {
            // add new failures
            const newFailures = [...failures];

            for (let i = 0; i < failureQty - failures.length; i++) {
                newFailures.push({
                    reworkItemStatusId: 0,
                    problems: [{ ...defaultProblem }],
                    qty: 1,
                    manualFailure: false,
                    batchEnable: false,
                    trackingId: "",
                    reworkId: reworkId ?? 0,
                });
            }

            setFailures(newFailures);
        }
    }, [failureQty]);

    /**
     * Effect triggered when failure types changed in store
     */
    useEffect((): void => {
        if (processTreeNodeId && allFailureTypes.length > 0) {
            const parentNodes = getParentNodesPath(processTreeNodeId, allNodes);
            let nodeFailureTypes: IFailureTypeDbModel[] = [];

            for (const node of parentNodes) {
                const failureTypes = allFailureTypes.filter((failureType) => {
                    return failureType.treeNodeId === node.treeNodeId && !failureType.archived;
                });
                nodeFailureTypes = nodeFailureTypes.concat(failureTypes);
            }

            setFailureTypes(nodeFailureTypes);
        }
    }, [allFailureTypes, processTreeNodeId]);

    /**
     * Effect triggered when rework item status changed in store
     */
    useEffect((): void => {
        if (processTreeNodeId && allReworkItemStatus.length > 0) {
            const parentNodes = getParentNodesPath(processTreeNodeId, allNodes);
            let nodeReworkItemStatus: IReworkItemStatusDbModel[] = [];

            for (const node of parentNodes) {
                const reworkItemStatus = allReworkItemStatus.filter((status) => {
                    return status.treeNodeId === node.treeNodeId && !status.archived;
                });
                nodeReworkItemStatus = nodeReworkItemStatus.concat(reworkItemStatus);
            }

            setReworkItemStatuses(nodeReworkItemStatus);
        }
    }, [allReworkItemStatus, processTreeNodeId]);

    /**
     * handles the retry
     */
    const handleRetry = (): void => {
        setOpen(false);
        onRetry?.();
    };

    /**
     * handles the send report
     */
    const handleSendReport = async (): Promise<void> => {
        if (dryRunMode) {
            setOpen(false);
            onSendReport?.([]);

            return void 0;
        }

        setValidate(true);

        if (onSendReport) {
            let formValid = true;
            let totalQty = 0;

            // check that all types and symptoms are filled and quantities are greater than 0
            for (const failure of failures) {
                if (failure.qty < 1) {
                    formValid = false;
                }

                totalQty += failure.qty;

                for (const problem of failure.problems) {
                    if (problem.failureTypeId === 0 || problem.failureSubTypeId === 0) {
                        formValid = false;
                    }
                }

                // Check if reworkItemStatus has been filled
                if (failure.reworkItemStatusId === 0) {
                    formValid = false;
                }
            }

            // check quantities
            if (totalQty > maxFailQty) {
                formValid = false;
                setInvalidQty(true);
            } else {
                setInvalidQty(false);
            }

            if (formValid) {
                let partNumber = "";
                let originalJobId: number | undefined;
                let originalJobRefId = "";
                const continueRun = failures.every((failure) =>
                    Boolean(reworkItemStatuses.find((status) => failure.reworkItemStatusId === status.reworkItemStatusId)?.continue)
                );

                if (continueRun) {
                    const entity = await dispatch(
                        reworkGetInfoBeforeCreate({
                            jobId: jobProcessInfo?.jobId,
                            trackingId: playerState.processState.serialNumber,
                        })
                    );

                    if (entity?.found) {
                        partNumber = entity.entities?.[0]?.partNumber ?? "";
                        originalJobId = entity.originalJobId;
                        originalJobRefId = entity.originalJobRefId;
                    }
                }

                const reworkInfo: IPlayerReworkInfo = {
                    trackingId: playerState.processState.serialNumber,
                    batchEnable: playerState.processState.batchEnable,
                    partNumber,
                    originalJobId,
                    originalJobRefId,
                    processId: process?.processId ?? 0,
                    yieldType: continueRun ? "ASSY" : yieldType,
                    currentStepIndex: playerState.processState.currentStepIndex,
                    processActionId: playerState.actionsState[playerState.actionsState.length - 1].actionId ?? 0,
                    resultMasterId: playerState.processState.resultMasterId,
                };

                // we are valid to send!!!
                setOpen(false);
                onSendReport(failures, reworkInfo, continueRun);

                setFailures([
                    {
                        reworkItemStatusId: 0,
                        problems: [{ ...defaultProblem }],
                        qty: 1,
                        batchEnable: false,
                        manualFailure: false,
                        trackingId: "",
                    },
                ]);
                setFailureQty(1);
                setValidate(false);
            }
        }
    };

    /**
     * handles the send report
     */
    const handleCancel = (): void => {
        setOpen(false);
        onCancel?.();
    };

    /**
     * handles the send report
     */
    const handleClose = (): void => {
        setOpen(false);
        onClose?.();
    };

    /**
     * Sets the problem Failure Type
     *
     * @param {number} failureIndex - failure index
     * @param {number} index - problem index
     */
    const setProblemType =
        (failureIndex: number, index: number): ((e: React.ChangeEvent) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newFailures = [...failures];
            newFailures[failureIndex].problems[index].failureTypeId = parseInt(e.target.value, 10);
            setFailures(newFailures);
        };

    /**
     * Sets the problem Failure Sub Type
     *
     * @param {number} failureIndex - failure index
     * @param {number} index - problem index
     */
    const setProblemSubType =
        (failureIndex: number, index: number): ((e: React.ChangeEvent) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newFailures = [...failures];
            newFailures[failureIndex].problems[index].failureSubTypeId = parseInt(e.target.value, 10);
            setFailures(newFailures);
        };

    /**
     * Sets the problem Failure Comments
     *
     * @param {number} failureIndex - failure index
     * @param {number} index - problem index
     */
    const setProblemComments =
        (failureIndex: number, index: number): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newFailures = [...failures];
            newFailures[failureIndex].problems[index].comments = e.target.value;
            setFailures(newFailures);
        };

    /**
     * Sets the problem status
     *
     */
    const setStatus =
        (failureIndex: number): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newFailures = [...failures];
            newFailures[failureIndex].reworkItemStatusId = parseInt(e.target.value, 10);
            setFailures(newFailures);
        };

    /**
     * Sets the problem quantity
     */
    const setQuantity =
        (failureIndex: number): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newFailures = [...failures];
            const val = parseInt(e.target.value, 10);
            if (val > 0) {
                newFailures[failureIndex].qty = val;
            } else {
                newFailures[failureIndex].qty = 0;
            }
            setFailures(newFailures);
        };

    /**
     * Sets the problem Failure Comments
     */
    const addProblem =
        (failureIndex: number): (() => void) =>
        (): void => {
            const newFailures = [...failures];
            newFailures[failureIndex].problems.push({ ...defaultProblem });
            setFailures(newFailures);
        };

    /**
     * Sets the problem Failure Comments
     *
     * @param {number} failureIndex - the failure index
     * @param {number} index - the problem index
     */
    const removeProblem =
        (failureIndex: number, index: number): (() => void) =>
        (): void => {
            // don't delete the add button
            if (index < failures[failureIndex].problems.length - 1) {
                const newFailures = [...failures];
                newFailures[failureIndex].problems.splice(index, 1);
                setFailures(newFailures);
            }
        };

    /**
     * handles add failure qty
     */
    const handleAddFailure = (): void => {
        setFailureQty(failureQty + 1);
    };

    /**
     * handles delete failure
     */
    const handleDeleteFailure = (index: number) => (): void => {
        const newFailures = [...failures];
        newFailures.splice(index, 1);
        setFailures(newFailures);
        setFailureQty(failureQty - 1);
    };

    const handleYieldTypeChange = (value: ReworkYieldType): void => {
        setYieldType(value);
    };

    return (
        <div id="failedDialogId">
            <Dialog open={open} disableAutoFocus={true} fullWidth={true} maxWidth="lg">
                <DialogContent className={classes.dialogContent}>
                    {/* HEADER */}
                    <div className={classes.dialogTitle}>
                        <WarningIcon className={classes.mainIcon} />
                        <div className={classes.dialogDescription}>
                            {displayProblemForm ? (
                                <Typography id="processFailedReportMessageId" variant="h4">
                                    {translate("player.processFailureReport")}
                                </Typography>
                            ) : (
                                <Typography id="processFailedMessageId" variant="h4">
                                    {translate("player.processFailed")}
                                </Typography>
                            )}
                        </div>
                        {<NotificationsCenterDialog backgroundWhite={true} />}
                    </div>

                    {/* FAILED DIALOG FORM */}
                    <div id="failedDialogFormId" className={classes.mainForm}>
                        <React.Fragment>
                            <Typography variant="h6" className={classes.text}>
                                <div>
                                    {actionBaseState?.failure.actionName ?? actionBaseState?.description} -{" "}
                                    {actionBaseState?.failure.actionStepName ??
                                        actionBaseState?.stepsState.find(
                                            (step) =>
                                                step.status === EnumActionStatus.PLAYING ||
                                                step.status === EnumActionStatus.WAITING_USER_NEXT
                                        )?.description}
                                </div>
                            </Typography>

                            {/* RUN DETAILS DIALOG */}
                            {Boolean(playerState) && (
                                <RunDetailsDialogContent
                                    playerState={playerState}
                                    jobProcessInfo={jobProcessInfo}
                                    process={process}
                                    reworkId={reworkId}
                                    runWithoutJobProcess={runWithoutJobProcess}
                                />
                            )}
                            {/* DETAIL OF THE ORIGIN OF THE TYPE OF FAILURE */}
                            <div className={classes.description}>
                                <pre>
                                    {translate(
                                        Boolean(actionBaseState?.failure?.description)
                                            ? actionBaseState.failure.description
                                            : "player.failed.PROCESS_MANUALLY_STOPPED_BY_USER"
                                    )}
                                </pre>
                            </div>
                        </React.Fragment>

                        {/* FAILURE INFO */}
                        {displayProblemForm && (
                            <div className={classes.box}>
                                <div className={classes.boxRowTitle}>
                                    <Typography variant="body2">{translate("player.failed.addFailuresTitle")}</Typography>
                                    {!isManualFailure && (
                                        <Typography color="error" variant="caption">
                                            {translate("player.reworkStatusItemDisabled")}
                                        </Typography>
                                    )}
                                </div>
                                {maxFailQty > 1 && (
                                    <Button
                                        id="addReworkButtonId"
                                        variant="contained"
                                        color="secondary"
                                        disabled={failureQty >= maxFailQty}
                                        onClick={handleAddFailure}
                                        className={classes.dialogButtons}
                                    >
                                        <AddIcon className={classes.buttonIcon} /> {translate("player.addFailures")}
                                    </Button>
                                )}
                                {invalidQty && (
                                    <div className={classes.error}>
                                        {translate("player.failed.qtyError")} {maxFailQty}
                                    </div>
                                )}
                                {failures.map((failure, failureIndex) => (
                                    <div className={classes.failure} key={failureIndex}>
                                        {maxFailQty > 1 && (
                                            <Fab
                                                className={classes.deleteIcon}
                                                onClick={handleDeleteFailure(failureIndex)}
                                                size="small"
                                                color="secondary"
                                            >
                                                <DeleteIcon />
                                            </Fab>
                                        )}
                                        <div className={classes.failureRow}>
                                            <KortexOutlinedTextField
                                                id="reworkStatusId"
                                                value={failures[failureIndex].reworkItemStatusId}
                                                label={translate("player.status")}
                                                select={true}
                                                className={classes.select}
                                                required={true}
                                                onChange={setStatus(failureIndex)}
                                                error={validate && failures[failureIndex].reworkItemStatusId === 0}
                                            >
                                                <MenuItem value={0} disabled={true}>
                                                    <Typography>{`${translate("player.select")} ...`}</Typography>
                                                </MenuItem>

                                                {reworkItemStatuses.map(
                                                    (status, index): JSX.Element => (
                                                        <MenuItem
                                                            disabled={!isManualFailure && status.continue}
                                                            id={`${status.title}Id`}
                                                            key={`playerStepFailedDialogReworkStatusItem${index}`}
                                                            value={status.reworkItemStatusId}
                                                        >
                                                            {status.title}
                                                            {status.automaticClose && <EndIcon className={classes.endIcon} />}
                                                            {status.continue && <PlayArrowIcon className={classes.endIcon} />}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </KortexOutlinedTextField>
                                            <KortexTextField
                                                label={translate("player.failed.failedQty")}
                                                InputProps={{
                                                    id: "failedQuantityInputId",
                                                    error: invalidQty,
                                                }}
                                                TextFieldProps={{
                                                    id: "reworkFailedQtyId",
                                                }}
                                                onChange={setQuantity(failureIndex)}
                                                type={"number"}
                                                min={1}
                                                value={failure.qty}
                                            />
                                        </div>
                                        <div className={classes.failureRow}>
                                            <div className={classes.problemContainer}>
                                                <div className={classes.problems}>
                                                    {failures[failureIndex].problems.map(
                                                        (problem, problemIndex): JSX.Element => (
                                                            <div key={problemIndex} className={classes.problem}>
                                                                <KortexOutlinedTextField
                                                                    id="reworkFailureTypeId"
                                                                    value={problem.failureTypeId}
                                                                    label={translate("player.type")}
                                                                    select={true}
                                                                    className={classes.select}
                                                                    required={true}
                                                                    error={validate && problem.failureTypeId === 0}
                                                                    onChange={setProblemType(failureIndex, problemIndex)}
                                                                >
                                                                    <MenuItem value={0} disabled={true}>
                                                                        <Typography>{`${translate("player.select")} ...`}</Typography>
                                                                    </MenuItem>
                                                                    {failureTypes.map(
                                                                        (type, TIndex): JSX.Element => (
                                                                            <MenuItem
                                                                                key={TIndex}
                                                                                value={type.failureTypeId}
                                                                                id={`failureType${TIndex}Id`}
                                                                            >
                                                                                {type.title}
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </KortexOutlinedTextField>
                                                                <KortexOutlinedTextField
                                                                    id="reworkFailureSubTypeId"
                                                                    value={problem.failureSubTypeId}
                                                                    label={translate("player.symptom")}
                                                                    select={true}
                                                                    className={classes.select}
                                                                    required={true}
                                                                    error={validate && problem.failureSubTypeId === 0}
                                                                    onChange={setProblemSubType(failureIndex, problemIndex)}
                                                                >
                                                                    <MenuItem value={0} disabled={true}>
                                                                        <Typography>{`${translate("player.select")} ...`}</Typography>
                                                                    </MenuItem>
                                                                    {allFailureSymptoms
                                                                        .filter(
                                                                            (symptoms): boolean =>
                                                                                symptoms.failureTypeId === problem.failureTypeId &&
                                                                                !symptoms.archived
                                                                        )
                                                                        .map(
                                                                            (symptoms, STIndex): JSX.Element => (
                                                                                <MenuItem
                                                                                    key={STIndex}
                                                                                    value={symptoms.failureSubTypeId}
                                                                                    id={`failureSymptom${STIndex}Id`}
                                                                                >
                                                                                    {symptoms.title}
                                                                                </MenuItem>
                                                                            )
                                                                        )}
                                                                </KortexOutlinedTextField>
                                                                <KortexTextField
                                                                    label={translate("player.comments")}
                                                                    className={classes.comments}
                                                                    InputProps={{
                                                                        id: "failedCommentsInputId",
                                                                        multiline: true,
                                                                    }}
                                                                    onChange={setProblemComments(failureIndex, problemIndex)}
                                                                    value={problem.comments}
                                                                />
                                                                {problemIndex === failures[failureIndex].problems.length - 1 ? (
                                                                    <div className={classes.addIcon} onClick={addProblem(failureIndex)}>
                                                                        <AddIcon />
                                                                        <Typography>{translate("player.add")}</Typography>
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={classes.removeIcon}
                                                                        onClick={removeProblem(failureIndex, problemIndex)}
                                                                    >
                                                                        <RemoveIcon />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        {/* YIELD */}
                        <div className={classes.box}>
                            <div className={classes.boxRowTitle}>
                                <Typography variant="body2">{translate("player.failed.selectYieldType")}</Typography>
                            </div>
                            <YieldTypeSelector
                                classes={{ container: classes.yieldSelectorContainer }}
                                onChange={handleYieldTypeChange}
                                value={yieldType}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    {/* 
                        DIALOG BUTTONS
                            - dry run           --> close
                            - manual failure    --> cancel, fail
                            - sequencer failure --> report
                    */}
                    <div id="failedDialogActionId">
                        {isManualFailure && (
                            <Button
                                id="cancelButtonId"
                                variant="outlined"
                                color="secondary"
                                onClick={handleCancel}
                                className={classes.dialogButtons}
                            >
                                {translate("player.cancel")}
                            </Button>
                        )}
                        {canRetry && (
                            <Button
                                variant="contained"
                                color="inherit"
                                id="retryButtonId"
                                onClick={handleRetry}
                                className={classes.dialogButtons}
                            >
                                {translate("player.retry")}
                            </Button>
                        )}
                        {dryRunMode ? (
                            <Button
                                id="closeButtonId"
                                variant="contained"
                                color="secondary"
                                onClick={handleClose}
                                className={classes.dialogButtons}
                            >
                                {translate("player.close")}
                            </Button>
                        ) : (
                            <Button
                                id="proceedButtonId"
                                variant="contained"
                                color="secondary"
                                onClick={handleSendReport}
                                className={classes.dialogButtons}
                            >
                                {translate("player.fail")}
                            </Button>
                        )}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
