export var KhronoEventEnum;
(function (KhronoEventEnum) {
    KhronoEventEnum[KhronoEventEnum["ACTION_CONFIG_INTERPOLATION"] = 1] = "ACTION_CONFIG_INTERPOLATION";
    KhronoEventEnum[KhronoEventEnum["ACTION_FAILURE"] = 2] = "ACTION_FAILURE";
    KhronoEventEnum[KhronoEventEnum["ACTION_GROUP_FAILURE"] = 3] = "ACTION_GROUP_FAILURE";
    KhronoEventEnum[KhronoEventEnum["ACTION_STATE_CHANGE"] = 4] = "ACTION_STATE_CHANGE";
    KhronoEventEnum[KhronoEventEnum["ACTION_STATUS_CHANGE"] = 5] = "ACTION_STATUS_CHANGE";
    KhronoEventEnum[KhronoEventEnum["HOOK"] = 6] = "HOOK";
    KhronoEventEnum[KhronoEventEnum["REMOTE_ACTION_COMPLETION"] = 7] = "REMOTE_ACTION_COMPLETION";
    KhronoEventEnum[KhronoEventEnum["REMOTE_ACTION_CONFIRMATION"] = 8] = "REMOTE_ACTION_CONFIRMATION";
    KhronoEventEnum[KhronoEventEnum["SEQUENCER_FAILURE"] = 9] = "SEQUENCER_FAILURE";
    KhronoEventEnum[KhronoEventEnum["VARIABLE_CHANGE"] = 10] = "VARIABLE_CHANGE";
})(KhronoEventEnum || (KhronoEventEnum = {}));
