var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function Runner(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M17,3 C17.5522847,3 18,3.44771525 18,4 L18,14 C18,14.5522847 17.5522847,15 17,15 L12.5,15 L12.5010222,17.0632721 C13.2033337,17.2444328 13.7561797,17.797527 13.9369863,18.4999808 L19,18.5 L19,19.5 L13.9367279,19.5010222 C13.7143512,20.3631171 12.9315516,21 12,21 C11.0684484,21 10.2856488,20.3631171 10.0632721,19.5010222 L5,19.5 L5,18.5 L10.0630137,18.4999808 C10.2439063,17.7971927 10.7971927,17.2439063 11.4999808,17.0630137 L11.5,15 L7,15 C6.44771525,15 6,14.5522847 6,14 L6,4 C6,3.44771525 6.44771525,3 7,3 L17,3 Z M12,18 C11.4477153,18 11,18.4477153 11,19 C11,19.5522847 11.4477153,20 12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 Z M15,5 L9,5 C8.48716416,5 8.06449284,5.38604019 8.00672773,5.88337887 L8,6 L8,12 C8,12.5128358 8.38604019,12.9355072 8.88337887,12.9932723 L9,13 L15,13 C15.5128358,13 15.9355072,12.6139598 15.9932723,12.1166211 L16,12 L16,6 C16,5.44771525 15.5522847,5 15,5 Z M11,7 L14,9 L11,11 L11,7 Z", id: "icons-runner" })));
}
