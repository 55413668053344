/**
 * Creates a new training model
 *
 * @param {ProcessId} processId - processId
 * @param {UserId} userId - userId
 */
export function newTrainingModel(processId, userId, options = {}) {
    const { certified = false, originalProcessId = processId, status = "active", trained = false, trainingCertificateTraineeId = null, trainingCertificationId = null, } = options;
    return {
        trainingId: 0,
        processId,
        userId,
        trained,
        certified,
        originalProcessId,
        trainingCertificateTraineeId,
        trainingCertificationId,
        status,
        archivedOn: null,
    };
}
