import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default function Text(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                d="M5,18 L5,20 L19,20 L19,18 L5,18 Z M9.5,13.8 L14.5,13.8 L15.4,16 L17.5,16 L12.75,5 L11.25,5 L6.5,16 L8.6,16 L9.5,13.8 Z M12,6.98 L13.87,12 L10.13,12 L12,6.98 Z"
                id="icons-text"
            />
        </SvgIcon>
    );
}
