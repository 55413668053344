// reflex blue #00147a
export var primaryPalette = {
    50: "#e0e3ef",
    100: "#b3b9d7",
    200: "#808abd",
    300: "#4d5ba2",
    400: "#26378e",
    500: "#00147a",
    600: "#001272",
    700: "#000e67",
    800: "#000b5d",
    900: "#00064a",
    A100: "#7e80ff",
    A200: "#4b4eff",
    A400: "#181cff",
    A700: "#0005fd",
};
// digital blue #0060ff
export var secondaryPalette = {
    50: "#e0ecff",
    100: "#b3cfff",
    200: "#80b0ff",
    300: "#4d90ff",
    400: "#2678ff",
    500: "#0060ff",
    600: "#0058ff",
    700: "#004eff",
    800: "#0044ff",
    900: "#012892",
    A100: "#ffffff",
    A200: "#f2f4ff",
    A400: "#0060ff",
    A700: "#a6b2ff",
};
// Red error color #f9423c
export var errorPalette = {
    50: "#f9423c",
    100: "#fdc6c5",
    200: "#fca19e",
    300: "#fb7b77",
    400: "#fa5e59",
    500: "#f9423c",
    600: "#f83c36",
    700: "#f7332e",
    800: "#f62b27",
    900: "#f51d1a",
    A100: "#ffffff",
    A200: "#fff4f4",
    A400: "#ffc2c1",
    A700: "#ffa9a7",
};
// Yellow warning error color #FFD966
export var warningPalette = {
    50: "#FFFAED",
    100: "#FFF4D1",
    200: "#FFECB3",
    300: "#FFE494",
    400: "#FFDF7D",
    500: "#FFD966",
    600: "#FFD55E",
    700: "#FFCF53",
    800: "#FFCA49",
    900: "#FFC038",
    A100: "#ffffff",
    A200: "#FFFFFF",
    A400: "#FFF5E1",
    A700: "#FFEDC8",
};
// Green success color #43a047
export var successPalette = {
    50: "#e8f4e9",
    100: "#c7e3c8",
    200: "#a1d0a3",
    300: "#7bbd7e",
    400: "#5fae63",
    500: "#43a047",
    600: "#3d9840",
    700: "#348e37",
    800: "#2c842f",
    900: "#1e7320",
    A100: "#b0ffb2",
    A200: "#7dff80",
    A400: "#4aff4e",
    A700: "#30ff35",
};
// Green success color #43a047
export var greyPalette = {
    50: "#F7FAFD",
    100: "#F2F5F7",
    200: "#EBEEF0",
    300: "#DDE0E2",
    400: "#BABDBF",
    500: "#9B9EA0",
    600: "#727577",
    700: "#5E6163",
    800: "#3F4244",
    900: "#1F2122",
    A100: "#F3F8FE",
    A200: "#C4DFFA",
    A400: "#8BC5FF",
    A700: "#72B8FF",
};
export var rainbowPalette = {
    10: primaryPalette[500],
    11: secondaryPalette[500],
    12: greyPalette[500],
    13: greyPalette[600],
    14: "#000000",
    15: "#FFFFFF",
    100: "#515EBB",
    101: "#99A0D7",
    200: "#1E88E5",
    201: "#66B5FB",
    300: "#00A9FF",
    301: "#85CEFF",
    400: "#26C6DA",
    401: "#80DEEA",
    500: "#9CCC65",
    501: "#C5E1A5",
    600: "#66BB6A",
    601: "#A5D6A7",
    700: "#C0CA33",
    701: "#E6EE9C",
    800: "#D8AB23",
    801: "#FFE082",
    900: "#FF7043",
    901: "#FFAB91",
    1000: "#E25449",
    1001: "#FF8675",
    1100: "#EF5350",
    1101: "#EF9A9A",
    1200: "#AD5145",
    1201: "#E27F70",
    1300: "#8D6E63",
    1301: "#BCAAA4",
    1400: "#7B8A95",
    1401: "#C6CCD0",
    1500: "#D5D5D5",
    1501: "#DDE0E2", // grey
};
export var kinovaPalette = {
    kinovaBlack: "#1D1D1B",
    kinovaGray: "#A2AAAD",
    kinovaWebGray: "#BAC0C4",
    kinovaBlue: "#001489",
    kinovaAssistiveBlue: "#4D6EFF",
    kinovaGreen: "#436E68",
};
export var blackToWhitePalette = {
    black: "#000000",
    darkGray3: "#666666",
    darkGray2: "#999999",
    gray: "#CCCCCC",
    lightGray1: "#d9d9d9",
    lightGray3: "#f3f3f3",
    white: "#FFFFFF",
};
export var redBerryPalette = {
    redBerry: "#980000",
    redBerryLight3: "#E6B8AF",
    redBerryLight2: "#DD7E6B",
    redBerryLight1: "#CC4125",
    redBerryDark1: "#A61C00",
    redBerryDark2: "#85200C",
    redBerryDark3: "#5B0F00",
};
export var redPalette = {
    red: "#FF0000",
    redLight3: "#F4CCCC",
    redLight2: "#EA9999",
    redLight1: "#E06666",
    redDark1: "#CC0000",
    redDark2: "#990000",
    redDark3: "#660000",
};
export var orangePalette = {
    orange: "#FF9900",
    orangeLight3: "#FCE5CD",
    orangeLight2: "#F9CB9C",
    orangeLight1: "#F6B26B",
    orangeDark1: "#E69138",
    orangeDark2: "#B45F06",
    orangeDark3: "#783F04",
};
export var yellowPalette = {
    yellow: "#FFFF00",
    yellowLight3: "#FFF2CC",
    yellowLight2: "#FFE599",
    yellowLight1: "#FFD966",
    yellowDark1: "#F1C232",
    yellowDark2: "#BF9000",
    yellowDark3: "#7F6000",
};
export var greenPalette = {
    green: "#00FF00",
    greenLight3: "#D9EAD3",
    greenLight2: "#B6D7A8",
    greenLight1: "#93C47D",
    greenDark1: "#6AA84F",
    greenDark2: "#38761D",
    greenDark3: "#274E13",
};
export var cyanPalette = {
    cyan: "#00FFFF",
    cyanLight3: "#D0E0E3",
    cyanLight2: "#A2C4C9",
    cyanLight1: "#76A5Af",
    cyanDark1: "#45818E",
    cyanDark2: "#134F5C",
    cyanDark3: "#0C343D",
};
export var cornflowerBluePalette = {
    cornflowerBlue: "#4A86E8",
    cornflowerBlueLight3: "#C9DAF8",
    cornflowerBlueLight2: "#A4C2F4",
    cornflowerBlueLight1: "#6D9EEB",
    cornflowerBlueDark1: "#3C78D8",
    cornflowerBlueDark2: "#1155CC",
    cornflowerBlueDark3: "#1C4587",
};
export var bluePalette = {
    blue: "#0000FF",
    blueLight3: "#CFE2F3",
    blueLight2: "#9FC5E8",
    blueLight1: "#6FA8DC",
    blueDark1: "#3D85C6",
    blueDark2: "#0B5394",
    blueDark3: "#073763",
};
export var purplePalette = {
    purple: "#9900FF",
    purpleLight3: "#D9D2E9",
    purpleLight2: "#B4A7D6",
    purpleLight1: "#8E7CC3",
    purpleDark1: "#674EA7",
    purpleDark2: "#351C75",
    purpleDark3: "#20124D",
};
export var magentaPalette = {
    magenta: "#FF00FF",
    magentaLight3: "#EAD1DC",
    magentaLight2: "#D5A6BD",
    magentaLight1: "#C27BA0",
    magentaDark1: "#A64D79",
    magentaDark2: "#741B47",
    magentaDark3: "#4C1130",
};
export var kinova_Royal_Ligth_Dark_Palettes = [
    kinovaPalette,
    blackToWhitePalette,
    redBerryPalette,
    redPalette,
    orangePalette,
    yellowPalette,
    greenPalette,
    cyanPalette,
    cornflowerBluePalette,
    bluePalette,
    purplePalette,
    magentaPalette,
];
