export var EnumProgressRunSelection;
(function (EnumProgressRunSelection) {
    EnumProgressRunSelection["UNSELECT"] = "UNS";
    EnumProgressRunSelection["PLAY_NEW"] = "NEW";
    EnumProgressRunSelection["PLAY_FROM_HISTORY"] = "HIS";
})(EnumProgressRunSelection || (EnumProgressRunSelection = {}));
export class InputState {
    constructor() {
        this.serialNumber = "";
        this.trackingNumber = "";
        this.retryComment = false;
        this.batchQty = 1;
        this.batchNew = true;
        this.quantities = {
            failed: 0,
            passed: 0,
            total: 0,
            started: 0,
        };
        this.error = {
            serialNumberRegexError: false,
            trackingNumberRegexError: false,
            serialNumberMissing: false,
            trackingNumberMissing: false,
            previousProcessNotRun: false,
            invalidBatchQty: false,
            selectProgressRuns: false,
        };
        this.serialNumberLock = false;
        this.selectedProgressRun = EnumProgressRunSelection.UNSELECT;
    }
    toJSON() {
        return {
            batchNew: this.batchNew,
            batchQty: this.batchQty,
            error: this.error,
            inProgressRun: this.inProgressRun,
            quantities: this.quantities,
            retryComment: this.retryComment,
            selectedProgressRun: this.selectedProgressRun,
            serialNumber: this.serialNumber,
            serialNumberLock: this.serialNumberLock,
            trackingNumber: this.trackingNumber,
        };
    }
}
