import { ActionTypeEnum, ConditionOperandTypeEnum, ConditionOperatorLogicalEnum, FailureCodeEnum, FailureSourceEnum, SequencerActionPlayCompletedPayloadTypeEnum, SequencerActionStatusEnum, } from "../../../enums";
import { CoreActionConfigError, } from "../../../interfaces";
import { localeKeys } from "../../../locales";
import { KortexSequencerDebugger, deepClone, defer, isPromiseStarted } from "../../../utilities";
import { isConditionAdvanced, isConditionBasic } from "../../../utilities/condition-type-predicates";
import { interpolateActionConfig } from "../../../utilities/interpolate-action-config";
import { createConditionString, resolveCondition } from "../../condition";
const debug = KortexSequencerDebugger.core.wait.condition;
export const SEQUENCER_ACTION_WAIT_CONDITION_DEFAULT_STATE = {};
function create({ variableManager, variableNamespace }) {
    let _config, _handleVariableChange, _paused = false, _play;
    const _state = SEQUENCER_ACTION_WAIT_CONDITION_DEFAULT_STATE;
    function _completed() {
        if (_handleVariableChange) {
            debug("play:completed:unsubscribing from variable change event");
            variableManager.off("change", _handleVariableChange);
        }
        _paused = false;
    }
    function _fail(failure) {
        debug("fail:%o", failure);
        return _play?.resolve({
            payload: failure,
            status: SequencerActionStatusEnum.FAILED,
        });
    }
    async function _generateCondition(conditions, resolve) {
        let conditionString = "";
        let variablesToWatch = [];
        for (const condition of conditions) {
            if (isConditionAdvanced(condition)) {
                conditionString += condition;
            }
            else {
                try {
                    conditionString += createConditionString(condition, variableManager.getAllVars(variableNamespace));
                }
                catch (error) {
                    resolve({
                        payload: {
                            action: {
                                state: {},
                            },
                            code: FailureCodeEnum.CONFIGURATION_ERROR,
                            error,
                            key: error.key,
                            source: FailureSourceEnum.ACTION,
                        },
                        status: SequencerActionStatusEnum.FAILED,
                    });
                    throw error;
                }
                variablesToWatch.push(condition.left.value);
                if (condition.right.type === ConditionOperandTypeEnum.VARIABLE) {
                    variablesToWatch.push(condition.right.value);
                }
            }
        }
        variablesToWatch = variablesToWatch.filter(_onlyUnique);
        return [conditionString, variablesToWatch];
    }
    function _onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }
    async function _testCondition(conditionString) {
        let invalidCondition = false;
        const result = await resolveCondition(conditionString).catch((error) => {
            invalidCondition = true;
            _fail({
                code: FailureCodeEnum.CONFIGURATION_ERROR,
                action: {
                    state: SEQUENCER_ACTION_WAIT_CONDITION_DEFAULT_STATE,
                },
                error,
                key: error.key,
                source: FailureSourceEnum.ACTION,
            });
            return false;
        });
        if (invalidCondition) {
            return true;
        }
        if (result) {
            debug("play:completed:condition statisfied");
            _play?.resolve({
                payload: {
                    output: null,
                    type: SequencerActionPlayCompletedPayloadTypeEnum.NEXT,
                },
                status: SequencerActionStatusEnum.COMPLETED,
            });
        }
        else {
            debug("play:condition not statisfied");
        }
        return result;
    }
    async function _waitCondition(conditions, resolve) {
        return _generateCondition(conditions, resolve)
            .then(async ([conditionString, variablesToWatch]) => {
            debug("play:full condition:%s", conditionString);
            if (await _testCondition(conditionString)) {
                return void 0;
            }
            debug("play:condition not statisfied:subscribing to variable change event:watched variables:%o", variablesToWatch);
            _handleVariableChange = async (namespace, identifier) => {
                debug("play:variable changed:%s %s", namespace, identifier);
                if (namespace === variableNamespace) {
                    for (const variableIdentifier of variablesToWatch) {
                        if (variableIdentifier === identifier) {
                            debug("play:changed variable (%s) matches with a watched variable", identifier);
                            await _generateCondition(conditions, resolve).then(async ([conditionString]) => {
                                await _testCondition(conditionString);
                            });
                            break;
                        }
                    }
                }
            };
            variableManager.on("change", _handleVariableChange);
            return void 0;
        })
            .catch(() => void 0);
    }
    function assertValidConfig(config) {
        debug("config validation:%o", config);
        if (!config.conditions) {
            debug("config validation:error:conditions is undefined");
            throw new CoreActionConfigError(localeKeys.action.core.waitCondition.validateUndefinedConditions, "Config validation error: 'conditions' is undefined.");
        }
        for (const [index, condition] of config.conditions.entries()) {
            if (isConditionBasic(condition)) {
                if (condition.left?.type === ConditionOperandTypeEnum.VARIABLE && !condition.left?.value?.trim()) {
                    debug("config validation:error:condition #%d:left operand:variable identifier is undefined", index);
                    throw new CoreActionConfigError(localeKeys.action.core.waitCondition.validateUndefinedLeftOperandVariableIdentifier, `Config validation error: condition #${index}: left operand variable identifier is undefined.`);
                }
                if (condition.right?.type === ConditionOperandTypeEnum.VARIABLE && !condition.right?.value?.trim()) {
                    debug("config validation:error:condition #%d:right operand:variable identifier is undefined", index);
                    throw new CoreActionConfigError(localeKeys.action.core.waitCondition.validateUndefinedRightOperandVariableIdentifier, `Config validation error: condition #${index}: right operand variable identifier is undefined.`);
                }
                if (index === 0 && condition.logical !== ConditionOperatorLogicalEnum.NA) {
                    debug("config validation:error:condition #%d:logical operator should be NA", index);
                    throw new CoreActionConfigError(localeKeys.action.core.waitCondition.validateInvalidLogicalOperator, `Config validation error: condition #${index}: logical operator should be NA.`);
                }
                if (index !== 0 &&
                    condition.logical !== ConditionOperatorLogicalEnum.AND &&
                    condition.logical !== ConditionOperatorLogicalEnum.OR) {
                    debug("config validation:error:condition #%d:logical operator should be AND or OR", index);
                    throw new CoreActionConfigError(localeKeys.action.core.waitCondition.validateInvalidLogicalOperator, `Config validation error:condition #${index}: logical operator should be AND or OR.`);
                }
            }
        }
        debug("config validation:successful");
    }
    async function pause() {
        debug("pause");
        if (!isPromiseStarted(_play)) {
            debug("pause:error:action is not playing");
            return void 0;
        }
        debug("pause:unsubscribing to variable change event");
        variableManager.off("change", _handleVariableChange);
        _paused = true;
        debug("pause:action is paused");
    }
    async function play(config) {
        debug("play:%o", config);
        if (isPromiseStarted(_play)) {
            debug("play:fail:action is already in progress");
            return {
                payload: {
                    action: {
                        state: {},
                    },
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.action.core.waitCondition.playAlreadyInProgress,
                    source: FailureSourceEnum.ACTION,
                },
                status: SequencerActionStatusEnum.FAILED,
            };
        }
        _config = interpolateActionConfig(config, variableManager.getAllVars(variableNamespace));
        _play = defer((resolve) => _waitCondition(_config.conditions, resolve));
        _play.promise.finally(() => {
            _completed();
        });
        return _play.promise;
    }
    async function resume() {
        debug("resume");
        if (!(_paused && isPromiseStarted(_play))) {
            debug("resume:error:action is not paused");
            return void 0;
        }
        _paused = false;
        debug("resume:action resumed");
        _waitCondition(_config.conditions, _play.resolve);
    }
    async function stop(causeFailure) {
        debug("stop");
        if (causeFailure) {
            return _fail({
                code: FailureCodeEnum.STOP,
                action: {
                    state: SEQUENCER_ACTION_WAIT_CONDITION_DEFAULT_STATE,
                },
                key: localeKeys.action.core.waitCondition.stop,
                source: FailureSourceEnum.ACTION,
            });
        }
        return _play?.resolve({
            payload: null,
            status: SequencerActionStatusEnum.STOPPED,
        });
    }
    return {
        get state() {
            return deepClone(_state);
        },
        get type() {
            return ActionTypeEnum.WAIT_CONDITION;
        },
        assertValidConfig,
        pause,
        play,
        resume,
        stop,
    };
}
export const SequencerActionWaitConditionFactory = {
    create,
};
