import { ActionTypeEnum, FailureCodeEnum, FailureSourceEnum, SequencerActionPlayCompletedPayloadTypeEnum, SequencerActionStatusEnum, } from "../../enums";
import { ACTION_GROUP_CONTEXT_SEPARATOR, CoreActionConfigError, } from "../../interfaces";
import { localeKeys } from "../../locales";
import { KortexSequencerDebugger, deepClone, defer, isNullOrUndefined, isPromiseStarted, unwrapVariableIdentifier, } from "../../utilities";
import { interpolateActionConfig } from "../../utilities/interpolate-action-config";
const debug = KortexSequencerDebugger.core.subProgram;
export const SEQUENCER_ACTION_SUB_PROGRAM_DEFAULT_STATE = {
    playing: false,
};
function create({ context, index, variableManager, variableNamespace }) {
    let _play, _state = deepClone(SEQUENCER_ACTION_SUB_PROGRAM_DEFAULT_STATE), _stateChanged = async () => void 0;
    function _fail(failure) {
        debug("fail:%o", failure);
        return _play?.resolve({
            payload: failure,
            status: SequencerActionStatusEnum.FAILED,
        });
    }
    function assertValidConfig(config) {
        debug("validate %o", config);
        for (const subProgramInputVariableIdentifier in config.inputsMapping) {
            const parentVariableIdentifier = config.inputsMapping[subProgramInputVariableIdentifier].identifier;
            if (isNullOrUndefined(parentVariableIdentifier) || parentVariableIdentifier.trim() === "") {
                throw new CoreActionConfigError(localeKeys.action.core.subProgram.validateInputInvalidParentVariableIdentifier, "Parent variable identifier assigned to input is undefined");
            }
        }
        for (const subProgramOutputVariableIdentifier in config.outputsMapping) {
            const parentVariableIdentifier = config.outputsMapping[subProgramOutputVariableIdentifier].identifier;
            if (isNullOrUndefined(parentVariableIdentifier) || parentVariableIdentifier.trim() === "") {
                throw new CoreActionConfigError(localeKeys.action.core.subProgram.validateOutputInvalidParentVariableIdentifier, "Parent variable identifier assigned to output is undefined");
            }
        }
    }
    async function onStateChange(cb) {
        debug("on state change:callback saved");
        _stateChanged = cb;
    }
    async function play(config) {
        debug("play", config);
        if (isPromiseStarted(_play)) {
            debug("play:fail:action is already in progress");
            return {
                payload: {
                    action: {
                        state: {},
                    },
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.action.core.set.playAlreadyInProgress,
                    source: FailureSourceEnum.ACTION,
                },
                status: SequencerActionStatusEnum.FAILED,
            };
        }
        const unwrapInputsMapping = Object.entries(config.inputsMapping).reduce((acc, [key, value]) => {
            acc[key] = { ...value, identifier: unwrapVariableIdentifier(value.identifier) };
            return acc;
        }, {});
        const unwrapOutputsMapping = Object.entries(config.outputsMapping).reduce((acc, [key, value]) => {
            acc[key] = { ...value, identifier: unwrapVariableIdentifier(value.identifier) };
            return acc;
        }, {});
        const interpolatedConfig = interpolateActionConfig({ ...config, inputsMapping: unwrapInputsMapping, outputsMapping: unwrapOutputsMapping }, variableManager.getAllVars(variableNamespace));
        _play = defer(async (resolve) => {
            const subProgramNamespace = variableNamespace + ACTION_GROUP_CONTEXT_SEPARATOR + context;
            if (_state.playing) {
                await setState({ playing: false });
                for (const subProgramVariableIdentifier in interpolatedConfig.outputsMapping) {
                    const output = interpolatedConfig.outputsMapping[subProgramVariableIdentifier];
                    const subProgramVariable = variableManager.getVar(subProgramNamespace, subProgramVariableIdentifier);
                    if (subProgramVariable === undefined) {
                        return resolve({
                            payload: {
                                code: FailureCodeEnum.CONFIGURATION_ERROR,
                                action: {
                                    state: SEQUENCER_ACTION_SUB_PROGRAM_DEFAULT_STATE,
                                },
                                key: localeKeys.action.core.subProgram.playOutputUndefined,
                                source: FailureSourceEnum.ACTION,
                            },
                            status: SequencerActionStatusEnum.FAILED,
                        });
                    }
                    await variableManager.setVar(variableNamespace, {
                        identifier: output.identifier,
                        type: output.type,
                        value: subProgramVariable.value,
                    });
                }
                return resolve({
                    payload: {
                        output: null,
                        type: SequencerActionPlayCompletedPayloadTypeEnum.NEXT,
                    },
                    status: SequencerActionStatusEnum.COMPLETED,
                });
            }
            for (const subProgramInputVariableIdentifier in interpolatedConfig.inputsMapping) {
                const input = interpolatedConfig.inputsMapping[subProgramInputVariableIdentifier];
                const parentVariableValue = variableManager.getVar(variableNamespace, input.identifier);
                if (parentVariableValue === undefined) {
                    if (input.required) {
                        return resolve({
                            payload: {
                                code: FailureCodeEnum.CONFIGURATION_ERROR,
                                action: {
                                    state: SEQUENCER_ACTION_SUB_PROGRAM_DEFAULT_STATE,
                                },
                                key: localeKeys.action.core.subProgram.playInputUndefined,
                                source: FailureSourceEnum.ACTION,
                            },
                            status: SequencerActionStatusEnum.FAILED,
                        });
                    }
                    else {
                        await variableManager.setVar(subProgramNamespace, {
                            identifier: subProgramInputVariableIdentifier,
                            type: input.type,
                            value: input.defaultValue,
                        });
                    }
                }
                else {
                    await variableManager.setVar(subProgramNamespace, {
                        identifier: subProgramInputVariableIdentifier,
                        type: input.type,
                        value: parentVariableValue.value,
                    });
                }
            }
            await setState({ playing: true });
            return resolve({
                payload: {
                    output: index,
                    type: SequencerActionPlayCompletedPayloadTypeEnum.BRANCH_INDEX,
                },
                status: SequencerActionStatusEnum.COMPLETED,
            });
        });
        return _play.promise;
    }
    async function setState(state, feedback, triggerOnStateChange = true) {
        debug("set state:%o", state);
        const previousState = deepClone(_state);
        const notifyChange = triggerOnStateChange && state.playing !== undefined && state.playing !== _state.playing;
        _state = {
            ..._state,
            ...state,
        };
        if (notifyChange) {
            debug("set state:state is changed, trigger state changed callback");
            _stateChanged({
                feedback,
                previousState,
                state: _state,
            });
        }
        return {
            status: SequencerActionStatusEnum.CONTINUE,
            payload: _state,
        };
    }
    async function stop(causeFailure) {
        debug("stop");
        if (causeFailure) {
            return _fail({
                code: FailureCodeEnum.STOP,
                action: {
                    state: SEQUENCER_ACTION_SUB_PROGRAM_DEFAULT_STATE,
                },
                key: localeKeys.action.core.subProgram.stop,
                source: FailureSourceEnum.ACTION,
            });
        }
        return _play?.resolve({
            payload: null,
            status: SequencerActionStatusEnum.STOPPED,
        });
    }
    return {
        get state() {
            return deepClone(_state);
        },
        get type() {
            return ActionTypeEnum.SUB_PROGRAM;
        },
        assertValidConfig,
        onStateChange,
        play,
        setState,
        stop,
    };
}
export const SequencerActionSubProgramFactory = {
    create,
};
