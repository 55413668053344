import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    OrganizationSettingsAllReq,
    OrganizationSettingsAllRes,
    OrganizationSettingsInsertReq,
    OrganizationSettingsInsertRes,
    OrganizationSettingsInsertedNotif,
    OrganizationSettingsUpdateReq,
    OrganizationSettingsUpdateRes,
    OrganizationSettingsUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { settingOrganizationAll } from "./all";
import { settingOrganizationInsert } from "./insert";
import { settingOrganizationInserted } from "./inserted";
import { settingOrganizationUpdate } from "./update";
import { settingOrganizationUpdated } from "./updated";

interface IAOSClientServiceWrappedOrganizationSettings {
    getAll: AOSClientServiceRequestWrapped<OrganizationSettingsAllReq, OrganizationSettingsAllRes>;
    insert: AOSClientServiceRequestWrapped<OrganizationSettingsInsertReq, OrganizationSettingsInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<OrganizationSettingsInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<OrganizationSettingsUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<OrganizationSettingsUpdateReq, OrganizationSettingsUpdateRes>;
}

type AOSClientServiceWrappedOrganizationSettings = IAOSClientServiceWrappedOrganizationSettings;

// ADD_API_CALL SettingOrganization
export function generateSettingOrganizationServiceClient(
    router: IRouterClient,
    notifier: EventEmitter
): AOSClientServiceWrappedOrganizationSettings {
    return {
        getAll: AOSClientServiceWrapperUI.request(settingOrganizationAll(router)),
        insert: AOSClientServiceWrapperUI.request(settingOrganizationInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(settingOrganizationInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(settingOrganizationUpdated(router, notifier)),
        update: AOSClientServiceWrapperUI.request(settingOrganizationUpdate(router)),
    };
}

generateSettingOrganizationServiceClient.serviceId = 10;
