import { theme } from "@aos/react-components";
import { IGetAllResBase } from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { InView } from "react-intersection-observer"; // TODO: Replace InView by useInView

import { IStandardThunkOptions } from "../redux/app.types";
import { StandardThunk } from "../redux/store";

import { useThunkDispatch } from "./useThunkDispatch";

const useStyles = makeStyles({
    intersectionObserver: {
        backgroundColor: theme.palette.primary.main,
        width: "100%",
    },
});

export type ObserverId = number | string;

export type ObserverProps = Omit<ConstructorParameters<typeof InView>[0], "onChange">;

export type UseListObserver<Payload, Filters extends {} = {}> = {
    entities: Payload[];
    filters: Filters;
    hasNext: boolean;
    isDataLoading: boolean;
    Observer: FC<ObserverProps>;
    observerId: ObserverId | undefined;
    setFilters: React.Dispatch<React.SetStateAction<Filters>>;
};

type Entities<Payload, Filters extends {} = {}> = (
    downloadedEndCallback?: (value: IGetAllResBase<Payload>) => void,
    limit?: number,
    offset?: number,
    filters?: Filters
) => Payload[];

const REQUEST_LIMIT = 20;
const NUMBER_OF_ITEM_BEFORE_NEXT_FETCH = 5;

export function useListObserver<Payload, Filters extends {} = {}>(
    useEntitiesFn: Entities<Payload, Filters>,
    getId: (entity: Payload | undefined) => ObserverId,
    thunkFn: (
        limit?: number,
        offset?: number,
        filters?: Filters,
        options?: IStandardThunkOptions
    ) => StandardThunk<Readonly<IGetAllResBase<Payload>>>,
    // FIXME: (entitiesFilters) These filters will be the initial value of a state.
    // Change name of parameter to 'initialEntitiesFilters'?
    // Should the component that uses this hook control the filter state instead?
    entitiesFilters = {} as Filters,
    entityRequestLimit = REQUEST_LIMIT,
    entityCountBeforeNextFetch = NUMBER_OF_ITEM_BEFORE_NEXT_FETCH
): UseListObserver<Payload, Filters> {
    // Hooks
    const classes = useStyles();
    const dispatch = useThunkDispatch();

    // Internal state
    const [fetchOffset, setFetchOffset] = useState<number>(0);
    const [hasNext, setHasNext] = useState<boolean>(true);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

    // Exported state
    const [filters, setFilters] = useState<Filters>(entitiesFilters);

    // Fetch data
    const entities = useEntitiesFn(dataFetchedCb, entityRequestLimit, 0, filters);

    const observerId =
        entities && entities.length > entityCountBeforeNextFetch
            ? getId(entities[entities.length - entityCountBeforeNextFetch - 1])
            : undefined;

    /**
     *
     */
    useEffect(() => {
        // Fetch a new list, restarting at offset 0
        fetchData(0);
    }, [filters]);

    /**
     *
     */
    function dataFetchedCb(value: IGetAllResBase<Payload>): void {
        const { chunkSize = 0, nextCursor = 0 } = value;

        // Compute has next?
        setHasNext(chunkSize >= entityRequestLimit);
        setFetchOffset(nextCursor);
        setIsDataLoading(false);
    }

    /**
     *
     */
    function fetchData(offset: number): void {
        setIsDataLoading(true);
        setHasNext(true);
        dispatch(thunkFn(entityRequestLimit, offset, filters, { redux: { skipDispatch: false, skipLookup: true } })).then(dataFetchedCb);
    }

    /**
     *
     */
    const handleIntersectionObserverOnChange = (inView: boolean): void => {
        if (!inView) {
            return void 0;
        }

        if (entities.length > 0) {
            fetchData(fetchOffset);
        }
    };

    /**
     *
     */
    const Observer: FC<ObserverProps> = (props) => (
        <InView {...props} as="div" className={classes.intersectionObserver} onChange={handleIntersectionObserverOnChange}>
            <>{/* Keep this Fragment tag to prevent TypeScript error */}</>
        </InView>
    );

    return { entities, filters, hasNext, isDataLoading, Observer, observerId, setFilters };
}
