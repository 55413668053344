import {
    IProcessUiModel,
    ProcessApprovalStatusFilter,
    ProcessId,
    ProcessUiModel,
    RepositoryEditorRightsEnum,
    StorageEditorsRightsEnum,
    getApprovedAndNotRetiredProcessVersions,
    getApprovedProcessVersions,
    getProcessVersionsWithoutDraft,
    getReleasedOrRetiredProcessVersions,
    getReleasedProcessVersions,
    getTrainingRequirementProcessVersions,
} from "@kortex/aos-common";

interface IOptions {
    excludedProcessIds?: ProcessId[];
    userAccessLevel?: RepositoryEditorRightsEnum | StorageEditorsRightsEnum;
    variant?: ProcessApprovalStatusFilter;
}

/**
 * Filter and order process versions
 */
export const filterAndOrderVersions = (processVersions: ProcessUiModel[], options: IOptions = {}): ProcessUiModel[] => {
    const { excludedProcessIds = [], userAccessLevel, variant } = options;

    // Apply variant filter and sort them
    let versionsWithVariant: IProcessUiModel[];

    switch (variant) {
        case ProcessApprovalStatusFilter.RELEASED_RETIRED:
            versionsWithVariant = getReleasedOrRetiredProcessVersions(processVersions);
            break;
        case ProcessApprovalStatusFilter.RELEASED:
            versionsWithVariant = getReleasedProcessVersions(processVersions);
            break;
        case ProcessApprovalStatusFilter.APPROVED:
        case ProcessApprovalStatusFilter.APPROVED_AND_DEFAULT_RELEASED:
            versionsWithVariant = getApprovedProcessVersions(processVersions);
            break;
        case ProcessApprovalStatusFilter.APPROVED_AND_NOT_RETIRED:
            versionsWithVariant = getApprovedAndNotRetiredProcessVersions(processVersions);
            break;
        case ProcessApprovalStatusFilter.APPROVED_AND_NOT_RETIRED_RELEASED_SELECTED_BY_DEFAULT:
            versionsWithVariant = getApprovedAndNotRetiredProcessVersions(processVersions);
            break;
        case ProcessApprovalStatusFilter.TRAINING_REQUIREMENT:
            versionsWithVariant = getTrainingRequirementProcessVersions(processVersions);
            break;
        case ProcessApprovalStatusFilter.WITHOUT_DRAFT:
            versionsWithVariant = getProcessVersionsWithoutDraft(processVersions);
            break;
        default:
            versionsWithVariant = processVersions;
    }

    if (excludedProcessIds.length) {
        versionsWithVariant = versionsWithVariant.filter(({ processId }) => !excludedProcessIds.includes(processId));
    }

    versionsWithVariant = versionsWithVariant.sort((version1, version2) => version1.processId - version2.processId);

    // If there is a draft version, remove it from the list
    let draftVersion;

    if (variant === ProcessApprovalStatusFilter.ALL) {
        draftVersion = versionsWithVariant.shift();
    }

    // Reverse the order of the versions, so that the more recent versions are displayed first
    versionsWithVariant.reverse();

    // Bring the draft back at the beggining if:
    // - a draft version exists
    // - there are no other versions (so that the picker has at least 1 choice)
    // - permissions are not defined (show everything if there is no permission requirements. TODO: most component using ProcessVersionPicker do not pass the permissions as props... might need to change it in the future)
    if (draftVersion && (!versionsWithVariant.length || !userAccessLevel || userAccessLevel > RepositoryEditorRightsEnum.AUTHENTICATED)) {
        versionsWithVariant.unshift(draftVersion);
    }

    return versionsWithVariant;
};
