import { useContext, useEffect, useState } from "react";
import shortid from "shortid";

import { Callback, EventTypeKey, Id, Key } from "./event";
import { KeybindManagerContext } from "./useKeybindManager";

interface IOptions {
    /**
     * The `alt` key must also be pressed to trigger the callback.
     */
    alt?: boolean;

    /**
     * The `ctrl` key must also be pressed to trigger the callback.
     */
    ctrl?: boolean;

    /**
     * Both the `ctrl` and `shift` keys must be pressed to trigger the event.
     */
    ctrlAndShift?: boolean;

    /**
     * If `true`, the callback will not be called.
     */
    disabled?: boolean;

    /**
     * If `true`, trigger the next event bound to the same key. Only works if `disabled` is `true`.
     */
    next?: boolean;

    /**
     * Add a callback that will be called when the key is released.
     */
    onKeyUp?: Callback;

    /**
     * If `true`, the callback will be called repeatedly as long as the key is pressed.
     */
    repeat?: boolean;

    /**
     * The `shift` key must also be pressed to trigger the callback.
     */
    shift?: boolean;

    /**
     * The default action of the vent will not be taken as it normally would be.
     */
    preventDefault?: boolean;

    /**
     * Prevent further propagation of the current event in the capturing and bubbling phases.
     */
    stopPropagation?: boolean;
}

/**
 * Create a key event.
 */
export function useKeybind(key: Key, callback: Callback, options: IOptions = {}): void {
    const {
        alt = false,
        ctrl = false,
        ctrlAndShift = false,
        disabled = false,
        next = false,
        onKeyUp,
        repeat = false,
        preventDefault = false,
        shift = false,
        stopPropagation = false,
    } = options;

    const { emit, getLastKeyId, layers, off, on, pushKey, pushLayer, removeKey, removeLayer } = useContext(KeybindManagerContext);
    const [id] = useState(shortid.generate());

    /**
     * Get last ID of the event that was bound to the key
     */
    const getLastId = (indexModifier = 0): Id => getLastKeyId(key, indexModifier);

    /**
     * Check if key event should not be triggered.
     * Returns `true` if a `useStopKeybindPropagation` hook is present in the layer stack (FILO).
     */
    const isStopped = (): boolean => {
        for (let i = layers.length - 1; i >= 0; i--) {
            const [currentId, stop] = layers[i];

            if (currentId === id) return false;
            if (stop) return true;
        }

        return false;
    };

    /**
     * Trigger the callback if all conditions are statisfied.
     */
    const keyListener = (event: KeyboardEvent, eventType: EventTypeKey): void => {
        // Validation & options
        if (!repeat && event.repeat) return void 0;
        if (isStopped()) return void 0;
        if (preventDefault) event.preventDefault();
        if (stopPropagation) event.stopPropagation();
        if (disabled) {
            if (next) emit(getLastId(1), eventType, event); // Trigger the next key event
            return void 0;
        }
        if (alt && !event.altKey) return void 0;
        if (ctrl && !event.ctrlKey) return void 0;
        if (shift && !event.shiftKey) return void 0;
        if (ctrlAndShift && (!event.ctrlKey || !event.shiftKey)) return void 0;

        // Callback
        if (eventType === "keydown") callback(event);
        else onKeyUp?.(event);
    };

    /**
     * On mount/unmount, add/remove the key and ID to the keybind manager
     */
    useEffect(() => {
        pushKey(key, id);
        pushLayer(id);

        return (): void => {
            removeKey(key, id);
            removeLayer(id);
        };
    }, []);

    /**
     * Manage the keydown and keyup events
     */
    useEffect(() => {
        // Key down
        const keyDownListener: Callback = (event) => keyListener(event, "keydown");
        on(id, "keydown", keyDownListener);

        // Key up
        let keyUpListener: Callback | null = null;

        if (onKeyUp) {
            keyUpListener = (event): void => keyListener(event, "keyup");
            on(id, "keyup", keyUpListener);
        }

        // Destructor
        return (): void => {
            off(id, "keydown", keyDownListener);
            if (keyUpListener) off(id, "keyup", keyUpListener);
        };
    }, [callback, disabled, onKeyUp]);
}
