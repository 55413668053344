import { EventEmitter } from "@kortex/utilities";
import shortid from "shortid";
import { getTime } from "../../../../utilities";
import { ActionBaseState } from "./ActionBaseState";
import { EnumActionStatus } from "./ActionEnums";
import { ActionStepState } from "./ActionStepState";
export class ActionBaseGenerator {
    constructor() {
        // Action common props and states
        this._id = shortid.generate();
        this.processId = 0;
        this.state = new ActionBaseState(EnumActionStatus.IDLE, "", getTime());
        this.pauseTimestamp = 0;
        this.canBePaused = false;
        // Events
        this.eventHandler = new EventEmitter();
        // Action specific props and states
        this.steps = [];
        this.extendedState = [];
    }
    /**
     * inits the step states
     */
    initStepStates() {
        this.state.stepsState = [];
        this.extendedState = [];
        for (const step of this.steps) {
            this.state.stepsState.push(new ActionStepState(step.label ?? this.props.type, step.standardTimeSec, step.canRetry, step.minTimeSec));
        }
    }
    toJSON() {
        throw new Error("Method not implemented.");
    }
}
