import { ProcessVariableStorer } from "../../../../../Process/ProcessVariable";
import { ParserJson } from "./Json/ParserJson";
export const PARSER_TYPE = {
    JSON: "json",
    REGEX: "regex",
};
export function createStepConfigParser() {
    return {
        parser: new ParserJson(),
        source: "",
        storeTo: new ProcessVariableStorer(),
        type: PARSER_TYPE.JSON,
    };
}
