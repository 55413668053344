import {
    IJobProcessOfTheReworkModel,
    IResultFailProblemModel,
    IReworkDbModel,
    IReworkItemStatusDbModel,
    IReworkUiModel,
    IRootCauseDbModel,
    LogInfo,
} from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export interface IReworkState {
    reworks: IReworkUiModel[];
    reworkItemStatus: IReworkItemStatusDbModel[];
    reworkLogs: LogInfo[];
    reworkJobsProcess: IJobProcessOfTheReworkModel[];
    rootCause: IRootCauseDbModel[];
}
export type ReworkState = IReworkState;

/* ------------------------------------------------ 
 ------------------ REWORKS ------------------------
 --------------------------------------------------*/
export enum ReworkActionType {
    INSERT_REWORK = "@@REWORK/INSERT_REWORK",
    UPDATE_REWORKS = "@@REWORK/UPDATE_REWORKS",
    UPDATE_REWORK = "@@REWORK/UPDATE_REWORK",
    UPDATE_REWORK_FAILURE_PROBLEMS = "@@REWORK/UPDATE_REWORKS_FAILURE_PROBLEMS",
    DELETE_REWORK = "@@REWORK/DELETE_REWORK",
}

export type ReworkInsertedAction = ComplexAction<ReworkActionType.INSERT_REWORK, IReworkState["reworks"]>;
export type ReworkUpdatedAction = ComplexAction<ReworkActionType.UPDATE_REWORK, IReworkState["reworks"]>;
export type ReworkDeletedAction = ComplexAction<ReworkActionType.DELETE_REWORK, IReworkDbModel>;

export type SetReworkListAction = ComplexAction<ReworkActionType.UPDATE_REWORKS, IReworkState["reworks"]>;
export type SetFailureProblemsListAction = ComplexAction<ReworkActionType.UPDATE_REWORK_FAILURE_PROBLEMS, IResultFailProblemModel[]>;

/* ------------------------------------------------ 
 ------------- REWORK ITEM STATUS -----------------
 --------------------------------------------------*/

export enum ReworkItemStatusActionType {
    INSERT_REWORK_ITEM_STATUS = "@@REWORK_ITEM_STATUS/INSERT_REWORK_ITEM_STATUS",
    UPDATE_REWORK_ITEM_STATUS = "@@REWORK_ITEM_STATUS/UPDATE_REWORK_ITEM_STATUS",
    UPDATE_REWORK_ITEM_STATUSES = "@@REWORK_ITEM_STATUS/UPDATE_REWORK_ITEM_STATUSES",
    DELETE_REWORK_ITEM_STATUS = "@@REWORK_ITEM_STATUS/DELETE_REWORK_ITEM_STATUS",
}

export type ReworkItemStatusInsertedAction = ComplexAction<
    ReworkItemStatusActionType.INSERT_REWORK_ITEM_STATUS,
    IReworkItemStatusDbModel[]
>;
export type ReworkItemStatusUpdatedAction = ComplexAction<
    ReworkItemStatusActionType.UPDATE_REWORK_ITEM_STATUS,
    IReworkState["reworkItemStatus"]
>;
export type ReworkItemStatusDeletedAction = ComplexAction<ReworkItemStatusActionType.DELETE_REWORK_ITEM_STATUS, IReworkItemStatusDbModel>;

export type SetReworkItemStatusListAction = ComplexAction<
    ReworkItemStatusActionType.UPDATE_REWORK_ITEM_STATUSES,
    IReworkState["reworkItemStatus"]
>;

/* ------------------------------------------------ 
 ---------------- REWORK LOG ----------------------
 --------------------------------------------------*/

export enum ReworkLogActionType {
    INSERT_REWORK_LOG = "@@REWORK_LOG/INSERT_REWORK_LOG",
    UPDATE_REWORK_LOG = "@@REWORK_LOG/UPDATE_REWORK_LOG",
    UPDATE_REWORK_LOGS = "@@REWORK_LOG/UPDATE_REWORK_LOGS",
}

export type ReworkLogInsertedAction = ComplexAction<ReworkLogActionType.INSERT_REWORK_LOG, IReworkState["reworkLogs"]>;
export type ReworkLogUpdatedAction = ComplexAction<ReworkLogActionType.UPDATE_REWORK_LOG, IReworkState["reworkLogs"]>;

export type SetReworkLogListAction = ComplexAction<ReworkLogActionType.UPDATE_REWORK_LOGS, IReworkState["reworkLogs"]>;

/* ------------------------------------------------ 
 ------------ REWORK JOB PROCESS ------------------
 --------------------------------------------------*/
export enum ReworkJobProcessActionType {
    INSERT_REWORK_JOB_PROCESS = "@@REWORK_JOB_PROCESS/INSERT_REWORK_JOB_PROCESS",
    UPDATE_REWORK_JOB_PROCESS = "@@REWORK_JOB_PROCESS/UPDATE_REWORK_JOB_PROCESS",
    UPDATE_REWORK_JOBS_PROCESS = "@@REWORK_JOBS_PROCESS/UPDATE_REWORK_JOBS_PROCESS",
    DELETE_REWORK_JOB_PROCESS = "@@REWORK_JOB_PROCESS/DELETE_REWORK_JOB_PROCESS",
}

export type ReworkJobProcessInsertedAction = ComplexAction<
    ReworkJobProcessActionType.INSERT_REWORK_JOB_PROCESS,
    IReworkState["reworkJobsProcess"]
>;
export type ReworkJobProcessUpdatedAction = ComplexAction<
    ReworkJobProcessActionType.UPDATE_REWORK_JOB_PROCESS,
    IReworkState["reworkJobsProcess"]
>;
export type ReworkJobProcessDeletedAction = ComplexAction<
    ReworkJobProcessActionType.DELETE_REWORK_JOB_PROCESS,
    IReworkState["reworkJobsProcess"]
>;
export type SetReworkJobProcessListAction = ComplexAction<
    ReworkJobProcessActionType.UPDATE_REWORK_JOBS_PROCESS,
    IReworkState["reworkJobsProcess"]
>;

/* ------------------------------------------------ 
 ------------- ROOT CAUSE -----------------
 --------------------------------------------------*/

export enum RootCauseActionType {
    INSERT_ROOT_CAUSE = "@@ROOT_CAUSE/INSERT_ROOT_CAUSE",
    SET_ROOT_CAUSE = "@@ROOT_CAUSE/SET_ROOT_CAUSE",
    UPDATE_ROOT_CAUSE = "@@ROOT_CAUSE/UPDATE_ROOT_CAUSE",
}

export type RootCauseInsertedAction = ComplexAction<RootCauseActionType.INSERT_ROOT_CAUSE, IRootCauseDbModel>;
export type RootCauseUpdatedAction = ComplexAction<RootCauseActionType.UPDATE_ROOT_CAUSE, IRootCauseDbModel>;

export type SetRootCauseListAction = ComplexAction<RootCauseActionType.SET_ROOT_CAUSE, IReworkState["rootCause"]>;

/* ------------------------------------------------ 
 ---------------------- ALL -----------------------
 --------------------------------------------------*/
export type ReworkActions =
    | ReworkInsertedAction
    | ReworkUpdatedAction
    | ReworkDeletedAction
    | SetReworkListAction
    | SetFailureProblemsListAction
    | ReworkItemStatusInsertedAction
    | ReworkItemStatusUpdatedAction
    | ReworkItemStatusDeletedAction
    | SetReworkItemStatusListAction
    | ReworkLogInsertedAction
    | ReworkLogUpdatedAction
    | SetReworkLogListAction
    | ReworkJobProcessInsertedAction
    | ReworkJobProcessUpdatedAction
    | ReworkJobProcessDeletedAction
    | SetReworkJobProcessListAction
    | RootCauseInsertedAction
    | RootCauseUpdatedAction
    | SetRootCauseListAction;
