export const restAPIRoot = "/api";
// TRANSLATE
export var EnumRESTPaths;
(function (EnumRESTPaths) {
    EnumRESTPaths["ROOT"] = "/api";
    // api-users
    EnumRESTPaths["LOGIN"] = "/login";
    // api-reporting
    EnumRESTPaths["STORE_RESULTS"] = "/storeResults";
    EnumRESTPaths["UPSERT_RESULT_SETTING_ITEM"] = "/upsertResultSettingItem";
    EnumRESTPaths["GET_RESULT_SETTING_ITEMS"] = "/getResultSettingItems";
    // api-file-manager
    EnumRESTPaths["GET_FILE_INFO"] = "/getFileInfo";
    EnumRESTPaths["INSERT_FILE_INFO"] = "/insertFileInfo";
    // api-scheduler
    EnumRESTPaths["GET_JOB"] = "/getJob";
    EnumRESTPaths["GET_JOB_PROCESS"] = "/getJobProcess";
    // api-runner
    EnumRESTPaths["RUNNER_SET_STATE"] = "/runnerSetState";
    EnumRESTPaths["RUNNER_GET_STATES"] = "/runnerGetState";
    EnumRESTPaths["RUNNER_DISCONNECT"] = "/runnerDisconnect";
    EnumRESTPaths["RUNNER_TO_CLIENT_EVENT"] = "/runnerToClientEvent";
    EnumRESTPaths["CLIENT_TO_RUNNER_EVENT"] = "/clientToRunnerEvent";
    EnumRESTPaths["RUNNER_START_PROCESS"] = "/runnerStartProcess";
    EnumRESTPaths["RUNNER_STOP_PROCESS"] = "/runnerStopProcess";
    EnumRESTPaths["RUNNER_PAUSE_PROCESS"] = "/runnerPauseProcess";
    EnumRESTPaths["RUNNER_FAIL_STEP"] = "/runnerFailStep";
    EnumRESTPaths["RUNNER_RESUME_PROCESS"] = "/runnerResumeProcess";
    //RUNNER_UPDATE_HUB_HOST = "/updateRunnerHubHost",
    EnumRESTPaths["RUNNER_RETRY_PROCESS"] = "/runnerRetryProcess";
    // api-process
    EnumRESTPaths["UPDATE_PROCESS"] = "/updateProcess";
    EnumRESTPaths["GET_PROCESS_TREE"] = "/getProcessTree";
    EnumRESTPaths["GET_RUN_QUANTITY_PROCESS_FROM_TRACKING_NUMBER"] = "/getRunQuantityProcessFromTrackingNumber";
    EnumRESTPaths["GET_JOB_PROCESS_ALREADY_EXECUTED"] = "/getJobProcessAlreadyExecuted";
    EnumRESTPaths["CHECK_PREVIOUS_PROCESS"] = "/checkPreviousProcess";
    EnumRESTPaths["INSERT_PROCESS_RESULTS"] = "/insertProcessResults";
    EnumRESTPaths["UPDATE_PROCESS_RELEASE_DATES"] = "/updateProcessReleaseDates";
    EnumRESTPaths["GET_DATA_STORE_READ_ITEM"] = "/getDataStoreReadItem";
    EnumRESTPaths["DEKSTOP_UPDATE_READY"] = "/desktopUpdateReady";
})(EnumRESTPaths || (EnumRESTPaths = {}));
export var EnumNotificationRESTPaths;
(function (EnumNotificationRESTPaths) {
    EnumNotificationRESTPaths["CANARY"] = "/canary";
})(EnumNotificationRESTPaths || (EnumNotificationRESTPaths = {}));
export var EnumResponseStatus;
(function (EnumResponseStatus) {
    EnumResponseStatus["SUCCESS"] = "SUCCESS";
    EnumResponseStatus["ERROR"] = "ERROR";
    EnumResponseStatus["LOGIN_REQUIRED"] = "LOGIN_REQUIRED";
    EnumResponseStatus["UNAUTHORIZED"] = "UNAUTHORIZED";
})(EnumResponseStatus || (EnumResponseStatus = {}));
export const VERSION_EMPTY = { major: 0, minor: 0, build: 0, patch: 0, pre: "", meta: "" };
export var ConnectionListChangedNotifTypeEnum;
(function (ConnectionListChangedNotifTypeEnum) {
    ConnectionListChangedNotifTypeEnum["INSERT"] = "I";
    ConnectionListChangedNotifTypeEnum["DELETE"] = "D";
})(ConnectionListChangedNotifTypeEnum || (ConnectionListChangedNotifTypeEnum = {}));
