import { KortexDialogConfirmation, theme } from "@aos/react-components";
import { useEntitiesNotifications } from "@kortex/aos-ui/redux/effects";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import * as React from "react";
import { TNotification } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Button } from "@material-ui/core";
import { allNotificationsDelete, notificationDelete } from "@kortex/aos-ui/redux/notifications-center-manager/notifications-thunks";
import NotificationsIcon from "@material-ui/icons/Notifications";

import NotificationCard from "./NotificationCard";

const useStyles = makeStyles({
    /* ICON */
    iconContainer: {
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
    },
    notificationIconButton: {
        padding: "0",
    },
    notificationIconWhite: {
        height: "32px",
        width: "32px",
        color: theme.palette.common.white,
    },
    notificationIconBlack: {
        height: "32px",
        width: "32px",
        color: theme.palette.common.black,
    },
    /* DIALOG */
    dialogContainer: {
        height: "90%",
        width: "80%",
    },
    mainContainer: {
        height: "100%",
        columnGap: "12px",
        display: "flex",
    },
    notifiacationsList: {
        borderRadius: "0px",
        overflow: "auto",
        backgroundColor: theme.palette.common.white,
        width: "100%",
    },
    notifiacationsListContainer: {
        height: "90%",
        overflow: "auto",
    },
    notifiacationsListFooter: {
        paddingTop: "10px",
    },
});

export interface INotificationsCenterDialogProps {
    backgroundWhite?: boolean;
}

export default function NotificationsCenterDialog(props: INotificationsCenterDialogProps): JSX.Element {
    const { backgroundWhite = false } = props;
    const classes = useStyles();
    const allNotifications = useEntitiesNotifications();
    const translate = useTranslate();
    const notificationIcon = backgroundWhite ? classes.notificationIconBlack : classes.notificationIconWhite;

    const [open, setOpen] = useState(false);

    /**
     * handle the poper click over
     */
    const handleClickButton = (): void => {
        setOpen(true);
    };

    /**
     * Called when the user close the dialog
     */
    const handleClose = (): void => {
        setOpen(false);
    };

    /**
     * Handle called when the user select the button delete on the card
     *
     * @param {TNotification} notificationInfo - Selected notification
     */
    const handleDeleteNotification = (notificationInfo: TNotification): void => {
        notificationDelete(notificationInfo);
    };

    /**
     * Handle called when the user select the button delete all
     */
    const handleDeleteAllNotification = (): void => {
        allNotificationsDelete();
    };

    return (
        <div>
            {/* ICON NOTIFICATION */}
            <div className={classes.iconContainer}>
                <Button
                    disableRipple={false}
                    aria-owns={open ? "simple-popper" : undefined}
                    aria-haspopup={true}
                    className={classes.notificationIconButton}
                    onClick={handleClickButton}
                    id={"notificationIconHeaderId"}
                >
                    <NotificationsIcon className={notificationIcon} />
                </Button>
            </div>

            {/* DIALOG NOTIFICATION CENTER */}
            {open && (
                <KortexDialogConfirmation
                    open={open}
                    dialogProps={{
                        fullWidth: false,
                        maxWidth: "xl",
                        id: "dialogNotificationsCenterId",
                        classes: { paper: classes.dialogContainer },
                    }}
                    textLabels={{
                        titleLabel: translate("notificationCenter.notificationCard.titleDilaog"),
                        cancelButtonLabel: translate("general.close"),
                    }}
                    onCancel={handleClose}
                    closeOnEscape={true}
                >
                    <div className={classes.mainContainer} id={"containerDialogNotificationsCenterId"}>
                        {/* NOTIFICATIONS LIST */}
                        <div className={classes.notifiacationsList} id={"notificationsListId"}>
                            <div className={classes.notifiacationsListContainer}>
                                {open &&
                                    allNotifications.map((notification, index): JSX.Element => {
                                        return (
                                            <NotificationCard
                                                key={index}
                                                index={index}
                                                notification={notification}
                                                onDelete={handleDeleteNotification}
                                            />
                                        );
                                    })}
                            </div>
                            {/* FOOTER */}
                            <div className={classes.notifiacationsListFooter}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleDeleteAllNotification}
                                    id={"buttonDeleteAllNotificationId"}
                                >
                                    {translate("general.clearAll")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </KortexDialogConfirmation>
            )}
        </div>
    );
}
