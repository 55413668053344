import { KortexDialogConfirmation, KortexTextField, greyPalette, theme } from "@aos/react-components";
import {
    EnumFontStyles,
    FormItemTypeEnum,
    IWorkInstructionsElementConfig,
    IWorkInstructionsFormConfig,
    IWorkInstructionsFormItemConfig,
    IWorkInstructionsTextConfig,
    ProcessEditorRightsEnum,
    ProcessVariableStoringMethod,
    WorkInstructionsFormItemConfig,
} from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { deepClone } from "@kortex/utilities";
import { Box, ClickAwayListener, ListItemText, Paper } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import * as React from "react";
import { useEffect, useState } from "react";

import ApprovalIcon from "../../../../../../../../../components/core/Icons/Approval/Approval";
import CheckboxIcon from "../../../../../../../../../components/core/Icons/Checkbox/Checkbox";
import ChecklistIcon from "../../../../../../../../../components/core/Icons/Checklist/Checklist";
import DropdownIcon from "../../../../../../../../../components/core/Icons/Dropdown/Dropdown";
import RadioIcon from "../../../../../../../../../components/core/Icons/Radio/Radio";
import TextIcon from "../../../../../../../../../components/core/Icons/Text/Text";
import TextfieldIcon from "../../../../../../../../../components/core/Icons/Textfield/Textfield";
import VariablePicker from "../../../../../../../../../components/core/VariablePicker/VariablePicker";
import { useTranslate } from "../../../../../../../../../hooks/useTranslate";
import { IUserRightsProps, userCanWrite } from "../../../../../../../../../utilitites/IUserRights";
import WorkInstructionsFormInputLabelToolbar from "../../Label/WorkInstructionsFormInputLabelToolbar";

const useStyles = makeStyles({
    root: {
        height: "100%",
        display: "flex",
        position: "relative",
        overflow: "hidden",
    },
    displayBlock: {
        display: "block",
        pointerEvents: "none",
    },
    emptySelectionLabel: {
        textAlign: "center",
        color: greyPalette[500],
    },
    formListItem: {
        borderWidth: "0px 1px 1px 1px",
        borderColor: greyPalette[300],
        borderStyle: "solid",
        "&:nth-child(1)": {
            borderWidth: "1px",
        },
        display: "block",
    },
    formListItemSelected: {
        borderColor: greyPalette[300],
        borderStyle: "solid",
        borderWidth: "1px",
        backgroundColor: greyPalette[300],
        display: "block",
    },
    noPadding: {
        padding: "0px",
    },
    previewContainer: {
        backgroundColor: greyPalette[50],
        borderRadius: "3px",
        boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
        flex: 1,
        margin: "5px 5px 5px 3px",
        position: "relative",
    },
    toolbarContainer: {
        padding: "5px 5px 5px 3px",
    },
    elementsToolbar: {
        justifySelf: "start",
        padding: "20px",
    },
    toolbarHorizontalList: {
        display: "inline-flex",
        flexDirection: "row",
        padding: "0px",
    },
    toolbarListItem: {
        width: "61px",
        color: greyPalette[600],
        "&:hover": {
            color: theme.palette.secondary.main,
        },
    },
    boldText: {
        fontWeight: "bold",
    },
    previewList: {
        overflowY: "scroll",
        height: "550px",
    },
    settingsBottomContainer: {
        width: "100%",
        backgroundColor: greyPalette[200],
        display: "flex",
        borderTop: `1px solid ${greyPalette[500]}`,
    },
    settingsContainer: {
        flex: 1,
        backgroundColor: greyPalette[50],
        borderRadius: "3px",
        margin: "5px 5px 5px 20px",
        boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    settingsTopContainer: {
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        padding: "10px",
        flex: "auto",
    },
    speedDial: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    speedDialButton: {
        boxShadow: "0px 4px 5px 0px",
    },
    speedDialContainer: {
        alignItems: "center",
    },
    speedDialFab: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    tooltipMaxWidth: {
        width: "95px",
    },
    textField: {
        width: "100%",
        margin: "0px 16px 16px 0px",
    },
    fullWidth: {
        width: "100%",
    },
    disabled: {
        pointerEvents: "none",
    },
    noMargin: {
        margin: "0px",
    },
});

const SPEED_DIAL_ICONS: Record<FormItemTypeEnum, React.ReactNode> = {
    [FormItemTypeEnum.TEXTBOX]: <TextfieldIcon />,
    [FormItemTypeEnum.TEXTBOXLIST]: <TextfieldIcon />,
    [FormItemTypeEnum.LABEL]: <TextIcon />,
    [FormItemTypeEnum.DROPDOWN]: <DropdownIcon />,
    [FormItemTypeEnum.RADIO]: <RadioIcon />,
    [FormItemTypeEnum.CHECKLIST]: <ChecklistIcon />,
    [FormItemTypeEnum.CHECKBOX]: <CheckboxIcon />,
    [FormItemTypeEnum.APPROVAL]: <ApprovalIcon />,
};

const BASE_PADDING = 8;
const ITEM_DEFAULT_HEIGHT = 48;
const LABEL_DEFAULT_HEIGHT = 36;
const TEXTFIELD_DEFAULT_HEIGHT = 68;

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    open: boolean;
    actionFormElement: IWorkInstructionsElementConfig<IWorkInstructionsFormConfig>;
    onCancel: () => void;
    onSave: (element: IWorkInstructionsElementConfig<IWorkInstructionsFormConfig>) => void;
}

export default function WorkInstructionsFormEditor(props: IOwnProps): JSX.Element {
    const { open, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [elementEditorOpen, setElementEditorOpen] = useState(false);
    const [selectedFormItemIndex, setSelectedFormItemIndex] = useState(-1);
    const [selectedFormItem, setSelectedFormItem] = useState<IWorkInstructionsFormItemConfig | null>(null);
    const [editedAction, setEditedAction] = useState(deepClone(props.actionFormElement));
    const [isDialOpen, setIsDialOpen] = useState(false);
    const [regexInvalid, setRegexInvalid] = useState(false);

    const { displayBlock, fullWidth } = classes;
    const { formItems } = editedAction ? editedAction.extendedProps : { formItems: [] };

    const readOnly = !userCanWrite(userAccessLevel);

    useStopKeybindPropagation(open);

    /**
     * Copy props into a local state
     */
    useEffect((): void => {
        setEditedAction(deepClone(props.actionFormElement));
    }, [props.actionFormElement]);

    /**
     * Called when the selected Form item changes.
     * Sets the selectedFormItem
     */
    useEffect((): void => {
        if (selectedFormItemIndex >= 0) {
            setSelectedFormItem(formItems[selectedFormItemIndex]);
        } else {
            setSelectedFormItem(null);
        }
    }, [selectedFormItemIndex]);

    /**
     * Called when the dialog opens and closes.
     * Removes form item selection.
     */
    useEffect((): void => {
        if (!open) {
            setSelectedFormItemIndex(-1);
        }
    }, [open]);

    /**
     * Computes height of form based on its elements
     *
     * @returns {number}
     */
    const calcFormHeight = (): number => {
        let height = 0;

        for (const item of formItems) {
            height += BASE_PADDING; // padding between elements

            // static elements
            height += item.type === FormItemTypeEnum.LABEL ? LABEL_DEFAULT_HEIGHT : 0;
            height += item.type === FormItemTypeEnum.TEXTBOX ? TEXTFIELD_DEFAULT_HEIGHT : 0;
            height += item.type === FormItemTypeEnum.CHECKBOX ? ITEM_DEFAULT_HEIGHT : 0;
            height += item.type === FormItemTypeEnum.APPROVAL ? TEXTFIELD_DEFAULT_HEIGHT : 0;

            // dynamic elements
            height += item.type === FormItemTypeEnum.CHECKLIST ? LABEL_DEFAULT_HEIGHT + ITEM_DEFAULT_HEIGHT * item.subValues.length : 0;
            height += item.type === FormItemTypeEnum.RADIO ? LABEL_DEFAULT_HEIGHT + ITEM_DEFAULT_HEIGHT * item.subValues.length : 0;
            height += item.type === FormItemTypeEnum.DROPDOWN ? TEXTFIELD_DEFAULT_HEIGHT : 0;

            // * 3 + 20 for default height of 3 items and padding.
            height += item.type === FormItemTypeEnum.TEXTBOXLIST ? TEXTFIELD_DEFAULT_HEIGHT + ITEM_DEFAULT_HEIGHT * 3 + 20 : 0;
        }
        return height + BASE_PADDING;
    };

    /**
     * Called when the List input changes. Splits input into multiple values.
     *
     * @param {string} subValueString - A comma-separated string to split
     */
    const onSubValueStringChange = (subValueString: string): void => {
        let currentIndex = 0;
        let previousIndex = 0;
        const newArray = [];

        do {
            currentIndex = subValueString.indexOf(",", previousIndex);
            if (currentIndex !== -1) {
                const subString = subValueString.slice(previousIndex, currentIndex).trim();
                if (subString !== "") {
                    newArray.push(subString);
                }
                previousIndex = currentIndex + 1;
            }
        } while (currentIndex !== -1);

        const lastSubString = subValueString.slice(previousIndex, subValueString.length).trim();

        if (lastSubString !== "") {
            newArray.push(lastSubString);
        }

        const formItemsCopy = [...formItems];
        formItemsCopy[selectedFormItemIndex].subValues = newArray;
        formItemsCopy[selectedFormItemIndex].subValueString = subValueString;

        setEditedAction({ ...editedAction, extendedProps: { formItems: formItemsCopy } });
    };

    /**
     * Called when an input changes. Modifies the Form
     *
     * @param {string} key - Key to differenciate input that changed
     * @returns {(value: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => undefined}
     */
    const onChangeFormBaseInfo =
        (key: keyof IWorkInstructionsFormItemConfig): ((value: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void) =>
        (value: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
            const formItemsCopy = [...formItems];
            let saveValue = true;

            if (key === "formatRegex") {
                // Validate Regex
                try {
                    new RegExp(value.target.value);
                    setRegexInvalid(false);
                } catch {
                    // Invalid Regex
                    setRegexInvalid(true);
                    saveValue = false;
                }
            }

            if (saveValue) {
                // TODO: check if casting is require on next typescript version
                // @ts-expect-error - should be ok
                formItemsCopy[selectedFormItemIndex][key] = value.target.value;

                setEditedAction({ ...editedAction, extendedProps: { formItems: formItemsCopy } });
            }
        };

    /**
     * Called when a variable picker input changes. Modifies the Form
     *
     * @param {string} key - Key to differenciate input that changed
     * @returns {(value: string, store: ProcessVariableStoringMethod) => undefined}
     */
    const onChangeFormVariableInfo =
        (
            key: keyof IWorkInstructionsFormItemConfig
        ): ((value: string | boolean, storingMethod?: ProcessVariableStoringMethod, defaultRegex?: string) => void) =>
        (value: string | boolean, storingMethod?: ProcessVariableStoringMethod, defaultRegex?: string): void => {
            const formItemsCopy = [...formItems];

            if (key === "storeTo" && storingMethod && typeof value === "string") {
                formItemsCopy[selectedFormItemIndex].storeTo = {
                    identifier: value,
                    storingMethod,
                };

                if (formItemsCopy[selectedFormItemIndex].type === FormItemTypeEnum.TEXTBOX && defaultRegex) {
                    formItemsCopy[selectedFormItemIndex].formatRegex = defaultRegex;
                }
            } else {
                // TODO: check if casting is require on next typescript version
                formItemsCopy[selectedFormItemIndex][key as string] = value;
            }

            setEditedAction({ ...editedAction, extendedProps: { formItems: formItemsCopy } });
        };

    /**
     * Called when a label changes for a textbox.
     */
    const onChangeFormElementLabelInfo =
        (): ((value: IWorkInstructionsTextConfig) => void) =>
        (value: IWorkInstructionsTextConfig): void => {
            const formItemsCopy = [...formItems];

            formItemsCopy[selectedFormItemIndex].labelProps = value;

            setEditedAction({ ...editedAction, extendedProps: { formItems: formItemsCopy } });
        };

    /**
     * Called when the Required checkbox changes
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - Unused
     * @param {boolean} checked - new checkbox state
     */
    const onRequiredCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        const formItemsCopy = [...formItems];
        formItemsCopy[selectedFormItemIndex].required = checked;

        setEditedAction({ ...editedAction, extendedProps: { formItems: formItemsCopy } });
    };

    /**
     * Called when the Multiline checkbox changes (Textbox specific param)
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - Unused
     * @param {boolean} checked - new checkbox state
     */
    const onMultilineCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        const formItemsCopy = [...formItems];
        formItemsCopy[selectedFormItemIndex].multiline = checked;

        setEditedAction({ ...editedAction, extendedProps: { formItems: formItemsCopy } });
    };

    /**
     * Called when an item in the form is deleted
     */
    const deleteFormItem = (): void => {
        setEditedAction({
            ...editedAction,
            extendedProps: {
                formItems: [...formItems.slice(0, selectedFormItemIndex), ...formItems.slice(selectedFormItemIndex + 1, formItems.length)],
            },
        });
        setSelectedFormItemIndex(selectedFormItemIndex - 1);
    };

    /**
     * Called when an item is inserted into the form
     *
     * @param {FormItemTypeEnum} type - Type of item to insert
     */
    const insertFormItem =
        (type: FormItemTypeEnum): (() => void) =>
        (): void => {
            const typeString = FormItemTypeEnum[type];
            let newItem = [
                new WorkInstructionsFormItemConfig(
                    FormItemTypeEnum[type],
                    translate("action.workInstructions.form.inputType." + typeString.toLowerCase())
                ),
            ];

            // Customize default label values based on the type of input
            switch (type) {
                case FormItemTypeEnum.TEXTBOX:
                    newItem = [
                        Object.assign({}, newItem[0], {
                            labelProps: {
                                ...newItem[0].labelProps,
                                backgroundColor: "#FFFFFFFF",
                                textColor: "#5E6163",
                                fontStyle: EnumFontStyles.SMALL,
                                padding: "0px",
                            },
                        }),
                    ];
                    break;
            }

            setIsDialOpen(false);
            setSelectedFormItemIndex(editedAction.extendedProps.formItems.length);
            setEditedAction({ ...editedAction, extendedProps: { formItems: [...formItems, ...newItem] } });
        };

    /**
     * Saves form in the Hub
     */
    const handleSaveElement = (): void => {
        props.onSave({ ...editedAction, height: calcFormHeight() });
    };

    /**
     * Renders a specific form item
     *
     * @param {IWorkInstructionsFormItemConfig} item - Item properties
     * @returns {object}
     */
    const renderFormItem = (item: IWorkInstructionsFormItemConfig): JSX.Element => {
        switch (item.type) {
            case FormItemTypeEnum.LABEL:
                return (
                    <Box>
                        <Typography>{item.label}</Typography>
                    </Box>
                );
            case FormItemTypeEnum.TEXTBOX:
                return (
                    <KortexTextField
                        label={item.label}
                        value={item.defaultValue}
                        className={fullWidth}
                        InputProps={{ disabled: readOnly }}
                    />
                );
            case FormItemTypeEnum.TEXTBOXLIST:
                return (
                    <>
                        <KortexTextField
                            label={item.label}
                            value={item.defaultValue}
                            className={fullWidth}
                            InputProps={{ disabled: readOnly }}
                        />
                        <List>
                            {item.subValues &&
                                item.subValues.map((item: string, index: number): JSX.Element => {
                                    return (
                                        <ListItem key={index} button>
                                            <ListItemText primary={item}></ListItemText>
                                        </ListItem>
                                    );
                                })}
                        </List>
                    </>
                );
            case FormItemTypeEnum.CHECKBOX:
                return (
                    <FormControlLabel control={<Checkbox checked={Boolean(item.defaultValue)} disabled={readOnly} />} label={item.label} />
                );
            case FormItemTypeEnum.CHECKLIST:
                return (
                    <div>
                        <Typography variant="subtitle1">{item.label}</Typography>
                        <div>
                            {item.subValues.map((subValue: string, index: number): JSX.Element => {
                                return (
                                    <div key={index}>
                                        <FormControlLabel
                                            value={subValue}
                                            control={<Checkbox checked={false} disabled={readOnly} />}
                                            label={<Typography variant="subtitle1">{subValue}</Typography>}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            case FormItemTypeEnum.RADIO:
                return (
                    <FormControl className={displayBlock}>
                        <Typography variant="subtitle1">{item.label}</Typography>
                        <RadioGroup name={item.label} value={item.defaultValue}>
                            {item.subValues.map((subValue: string, index: number): JSX.Element => {
                                return (
                                    <FormControlLabel
                                        key={index}
                                        value={subValue}
                                        control={<Radio checked={false} disabled={readOnly} />}
                                        label={<Typography variant="subtitle1">{subValue}</Typography>}
                                    />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                );
            case FormItemTypeEnum.DROPDOWN:
                return (
                    <KortexTextField
                        TextFieldProps={{
                            disabled: readOnly,
                            select: true,
                        }}
                        label={item.label}
                        className={fullWidth}
                        value={item.defaultValue}
                    >
                        {item.subValues.map((subValue: string, index: number): JSX.Element => {
                            return (
                                <MenuItem key={index} value={subValue}>
                                    {subValue}
                                </MenuItem>
                            );
                        })}
                    </KortexTextField>
                );
            case FormItemTypeEnum.APPROVAL:
                return (
                    <KortexTextField
                        label={item.label}
                        type="password"
                        value=""
                        className={fullWidth}
                        TextFieldProps={{
                            autoComplete: "new-password",
                            disabled: readOnly,
                        }}
                    />
                );
            default:
                return <div />;
        }
    };

    /**
     * Stores the dragged element's id on drag start event
     *
     * @param {IWorkInstructionsFormItemConfig} elem - Element being dragged
     */
    const handleDragStart =
        (elem: IWorkInstructionsFormItemConfig): ((event: React.DragEvent<HTMLElement>) => void) =>
        (event: React.DragEvent<HTMLElement>): void => {
            event.dataTransfer.setData("text/plain", elem._id);
        };

    /**
     * When the drag completes, swap both elements in the form
     *
     * @param {IWorkInstructionsFormItemConfig} elem - Element on which the drag ends
     */
    const handleDragDrop =
        (elem: IWorkInstructionsFormItemConfig): ((event: React.DragEvent<HTMLElement>) => void) =>
        (event: React.DragEvent<HTMLElement>): void => {
            // swap elements
            const draggedElementIndex = formItems.findIndex((item): boolean => item._id === event.dataTransfer.getData("text/plain"));
            const droppedOnIndex = formItems.findIndex((item): boolean => item._id === elem._id);

            const formItemsCopy = [...formItems];
            formItemsCopy[draggedElementIndex] = { ...formItems[droppedOnIndex] };
            formItemsCopy[droppedOnIndex] = { ...formItems[draggedElementIndex] };

            setSelectedFormItemIndex(droppedOnIndex);
            setEditedAction({ ...editedAction, extendedProps: { formItems: formItemsCopy } });
        };

    /**
     * Called when dragging over elements. Required to allow D&D
     *
     * @param {React.DragEvent<HTMLElement>} e - Drag event
     */
    const handleFormItemDragOver = (e: React.DragEvent<HTMLElement>): void => {
        e.preventDefault();
    };

    /**
     * Called when an item is clicked on. Selects the item.
     *
     * @param {number} index - Clicked item's index
     */
    const handleFormItemClick =
        (index: number): (() => void) =>
        (): void => {
            setSelectedFormItemIndex(index);
        };

    /**
     * Called when the speedDial is clicked. Opens/closes the speedDial.
     *
     * @param {boolean} open - True to open, false to close
     */
    const handleOpenCloseDial =
        (open: boolean): (() => void) =>
        (): void => {
            setIsDialOpen(open);
        };

    /**
     * Close form editor
     */
    const handleCloseElementEditor = (): void => {
        setElementEditorOpen(false);
    };

    /**
     * Display editor according to the edited element's type
     */
    const displayLabelToolbar = (): JSX.Element => {
        return selectedFormItem?.type === FormItemTypeEnum.TEXTBOX ? (
            <div className={classes.toolbarContainer}>
                <Paper className={classes.elementsToolbar}>
                    <WorkInstructionsFormInputLabelToolbar
                        labelProps={selectedFormItem ? selectedFormItem.labelProps : undefined}
                        onCancel={handleCloseElementEditor}
                        onSave={onChangeFormElementLabelInfo()}
                        open={elementEditorOpen}
                        userAccessLevel={userAccessLevel}
                    />
                </Paper>
            </div>
        ) : (
            <></>
        );
    };

    return (
        <KortexDialogConfirmation
            confirmDisabled={readOnly}
            open={open}
            dialogProps={{
                maxWidth: "md",
            }}
            onCancel={props.onCancel}
            onConfirm={handleSaveElement}
            textLabels={{
                titleLabel: translate("action.workInstructions.form.title"),
                cancelButtonLabel: translate("action.workInstructions.editDialog.cancel"),
                proceedButtonLabel: translate("action.workInstructions.editDialog.save"),
            }}
            closeOnEscape={true}
        >
            {displayLabelToolbar()}

            <div className={classes.root}>
                {/** Left column displaying form items */}
                <div className={classes.previewContainer}>
                    {/** Speed dial button to add form items */}
                    <ClickAwayListener onClickAway={handleOpenCloseDial(false)}>
                        <SpeedDial
                            ariaLabel=""
                            open={isDialOpen}
                            icon={<SpeedDialIcon />}
                            classes={{ fab: classes.speedDialFab, actions: classes.speedDialContainer }}
                            className={classes.speedDial}
                            style={{ display: readOnly ? "none" : undefined }}
                            onClick={handleOpenCloseDial(!isDialOpen)}
                        >
                            {Object.values(FormItemTypeEnum).map((elementType): JSX.Element => {
                                return (
                                    <SpeedDialAction
                                        classes={{ fab: classes.speedDialButton, staticTooltipLabel: classes.tooltipMaxWidth }}
                                        key={elementType}
                                        icon={SPEED_DIAL_ICONS[elementType]}
                                        tooltipTitle={translate(`action.workInstructions.form.inputType.${elementType.toLowerCase()}`)}
                                        tooltipOpen={true}
                                        onClick={insertFormItem(elementType as FormItemTypeEnum)}
                                    />
                                );
                            })}
                        </SpeedDial>
                    </ClickAwayListener>
                    {/** List displaying form items */}
                    <div className={classes.previewList}>
                        <List classes={{ padding: classes.noPadding }}>
                            {formItems.map((formItem: IWorkInstructionsFormItemConfig, index: number): JSX.Element => {
                                return (
                                    <ListItem
                                        disabled={readOnly}
                                        key={index}
                                        className={index === selectedFormItemIndex ? classes.formListItemSelected : classes.formListItem}
                                        onClick={handleFormItemClick(index)}
                                        draggable={true}
                                        onDragStart={handleDragStart(formItem)}
                                        onDrop={handleDragDrop(formItem)}
                                        onDragOver={handleFormItemDragOver}
                                    >
                                        {<div className={classes.disabled}>{renderFormItem(formItem)}</div>}
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                </div>

                {/** Right Settings column */}
                <div className={classes.settingsContainer}>
                    {/** Top container in the right Settings column */}
                    {selectedFormItem && (
                        <div className={classes.settingsTopContainer}>
                            <KortexTextField
                                TextFieldProps={{
                                    disabled: readOnly,
                                    select: true,
                                }}
                                label={translate("action.workInstructions.form.inputType")}
                                className={classes.textField}
                                value={selectedFormItem.type}
                                onChange={onChangeFormBaseInfo("type")}
                            >
                                <MenuItem value={FormItemTypeEnum.LABEL}>
                                    {translate("action.workInstructions.form.inputType.label")}
                                </MenuItem>
                                <MenuItem value={FormItemTypeEnum.TEXTBOX}>
                                    {translate("action.workInstructions.form.inputType.textbox")}
                                </MenuItem>
                                <MenuItem value={FormItemTypeEnum.TEXTBOXLIST}>
                                    {translate("action.workInstructions.form.inputType.textboxlist")}
                                </MenuItem>
                                <MenuItem value={FormItemTypeEnum.CHECKBOX}>
                                    {translate("action.workInstructions.form.inputType.checkbox")}
                                </MenuItem>
                                <MenuItem value={FormItemTypeEnum.CHECKLIST}>
                                    {translate("action.workInstructions.form.inputType.checklist")}
                                </MenuItem>
                                <MenuItem value={FormItemTypeEnum.RADIO}>
                                    {translate("action.workInstructions.form.inputType.radio")}
                                </MenuItem>
                                <MenuItem value={FormItemTypeEnum.DROPDOWN}>
                                    {translate("action.workInstructions.form.inputType.dropdown")}
                                </MenuItem>
                                <MenuItem value={FormItemTypeEnum.APPROVAL}>
                                    {translate("action.workInstructions.form.inputType.approval")}
                                </MenuItem>
                            </KortexTextField>

                            <div id="workInstructionFormElementSettingsLabelContainerId">
                                <VariablePicker
                                    KortexTextFieldProps={{
                                        label: translate("action.workInstructions.form.label"),
                                        className: classes.textField,
                                    }}
                                    value={selectedFormItem.label}
                                    onChange={onChangeFormVariableInfo("label")}
                                    userAccessLevel={userAccessLevel}
                                />
                            </div>

                            {(selectedFormItem.type === FormItemTypeEnum.CHECKLIST ||
                                selectedFormItem.type === FormItemTypeEnum.RADIO ||
                                selectedFormItem.type === FormItemTypeEnum.DROPDOWN ||
                                selectedFormItem.type === FormItemTypeEnum.TEXTBOXLIST) && (
                                <div id="workInstructionsFormElementSettingsListContainerId">
                                    <VariablePicker
                                        KortexTextFieldProps={{
                                            label: translate("action.workInstructions.form.list"),
                                            className: classes.textField,
                                            TextFieldProps: {
                                                placeholder: "e.g. option1,option2,${variable}",
                                            },
                                        }}
                                        value={selectedFormItem.subValueString}
                                        onChange={onSubValueStringChange}
                                        userAccessLevel={userAccessLevel}
                                    />
                                </div>
                            )}

                            {selectedFormItem.type === FormItemTypeEnum.TEXTBOXLIST && (
                                <>
                                    <div id="workInstructionsFormElementSettingsMinItemsContainerId">
                                        <VariablePicker
                                            KortexTextFieldProps={{
                                                label: translate("action.workInstructions.form.minItems"),
                                                className: classes.textField,
                                            }}
                                            value={selectedFormItem.minItems.toString()}
                                            onChange={onChangeFormVariableInfo("minItems")}
                                            userAccessLevel={userAccessLevel}
                                        />
                                    </div>

                                    <div id="workInstructionsFormElementSettingsMaxItemsContainerId">
                                        <VariablePicker
                                            KortexTextFieldProps={{
                                                label: translate("action.workInstructions.form.maxItems"),
                                                className: classes.textField,
                                            }}
                                            value={selectedFormItem.maxItems.toString()}
                                            onChange={onChangeFormVariableInfo("maxItems")}
                                            userAccessLevel={userAccessLevel}
                                        />
                                    </div>
                                </>
                            )}

                            {selectedFormItem.type === FormItemTypeEnum.APPROVAL && (
                                <div id="workInstructionsFormElementSettingsApprovalGroupContainerId">
                                    <KortexTextField
                                        TextFieldProps={{
                                            disabled: readOnly,
                                            select: true,
                                        }}
                                        label={translate("action.workInstructions.form.approvalGroupId")}
                                        className={classes.textField}
                                        value={selectedFormItem.approvalGroupId}
                                        onChange={onChangeFormBaseInfo("approvalGroupId")}
                                    >
                                        {/* TODO: Replace menu items by registered groups */}
                                        <MenuItem value={"Medical"}>Medical Supervisor</MenuItem>
                                        <MenuItem value={"Standard"}>Standard Supervisor</MenuItem>
                                        <MenuItem value={"All"}>All Supervisor</MenuItem>
                                    </KortexTextField>
                                </div>
                            )}

                            {selectedFormItem.type !== FormItemTypeEnum.APPROVAL && selectedFormItem.type !== FormItemTypeEnum.LABEL && (
                                <div id="workInstructionsFormElementSettingsStoreToContainerId">
                                    <VariablePicker
                                        KortexTextFieldProps={{
                                            label: translate("action.workInstructions.form.storeTo"),
                                            className: classes.textField,
                                        }}
                                        value={selectedFormItem.storeTo.identifier}
                                        onChange={onChangeFormVariableInfo("storeTo")}
                                        storingMethod={selectedFormItem.storeTo.storingMethod}
                                        userAccessLevel={userAccessLevel}
                                    />
                                </div>
                            )}

                            {/** Default Value inputs in the Settings column.*/}
                            {[
                                FormItemTypeEnum.TEXTBOX,
                                FormItemTypeEnum.RADIO,
                                FormItemTypeEnum.DROPDOWN,
                                FormItemTypeEnum.TEXTBOXLIST,
                            ].includes(selectedFormItem.type) && (
                                <div id="workInstructionsFormElementSettingsDefaultValueContainerId">
                                    <VariablePicker
                                        KortexTextFieldProps={{
                                            label: translate("action.workInstructions.form.defaultValue"),
                                            className: classes.textField,
                                        }}
                                        value={selectedFormItem.defaultValue}
                                        onChange={onChangeFormVariableInfo("defaultValue")}
                                        userAccessLevel={userAccessLevel}
                                    />
                                </div>
                            )}

                            {[FormItemTypeEnum.TEXTBOX, FormItemTypeEnum.TEXTBOXLIST].includes(selectedFormItem.type) && (
                                <React.Fragment>
                                    <div id="workInstructionsFormElementSettingsTabIndexContainerId">
                                        <VariablePicker
                                            KortexTextFieldProps={{
                                                label: translate("action.workInstructions.form.tabIndex"),
                                                className: classes.textField,
                                            }}
                                            value={selectedFormItem.tabIndex}
                                            onChange={onChangeFormVariableInfo("tabIndex")}
                                            userAccessLevel={userAccessLevel}
                                        />
                                    </div>

                                    <div id="workInstructionsFormElementSettingsFormatRegexContainerId">
                                        <VariablePicker
                                            value={selectedFormItem.formatRegex}
                                            KortexTextFieldProps={{
                                                className: classes.textField,
                                                error: regexInvalid
                                                    ? translate("action.workInstructions.form.error.invalidRegexError")
                                                    : "",
                                                label: translate("action.workInstructions.form.format"),
                                            }}
                                            onChange={onChangeFormVariableInfo("formatRegex")}
                                            userAccessLevel={userAccessLevel}
                                        />
                                    </div>

                                    <div id="workInstructionsFormElementSettingsFormatErrorMessageContainerId">
                                        <VariablePicker
                                            KortexTextFieldProps={{
                                                className: classes.textField,
                                                label: translate("action.workInstructions.form.formatErrorMessage"),
                                            }}
                                            onChange={onChangeFormVariableInfo("formatErrorMessage")}
                                            userAccessLevel={userAccessLevel}
                                            value={selectedFormItem.formatErrorMessage}
                                        />
                                    </div>
                                    {selectedFormItem.type !== FormItemTypeEnum.TEXTBOXLIST && (
                                        <FormControlLabel
                                            className={classes.noMargin}
                                            control={
                                                <Checkbox
                                                    checked={selectedFormItem.multiline}
                                                    disabled={readOnly}
                                                    onChange={onMultilineCheckedChange}
                                                />
                                            }
                                            label={translate("action.workInstructions.form.multiline")}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    )}
                    {/** Greyed bottom container in the Settings column */}
                    {selectedFormItem && (
                        <div className={classes.settingsBottomContainer}>
                            {selectedFormItem.type !== FormItemTypeEnum.LABEL && (
                                <FormControlLabel
                                    className={classes.noMargin}
                                    control={
                                        <Checkbox
                                            id="workInstructionsFormElementRequiredCheckboxId"
                                            checked={selectedFormItem.required}
                                            disabled={readOnly}
                                            onChange={onRequiredCheckedChange}
                                        />
                                    }
                                    label={translate("action.workInstructions.form.required")}
                                />
                            )}
                            <div className={classes.fullWidth} />
                            <IconButton disabled={readOnly} onClick={deleteFormItem}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    )}
                    {/** Label displayed when no items are selected */}
                    {!selectedFormItem && (
                        <span className={classes.emptySelectionLabel}>{translate("action.workInstructions.form.emptySelection")}</span>
                    )}
                </div>
            </div>
        </KortexDialogConfirmation>
    );
}
