const REGEX_VARIABLE_IDENTIFIER_WRAPPER_START = "\\$\\{";
const REGEX_VARIABLE_IDENTIFIER_WRAPPER_END = "\\}";
/**
 * Regex to find variable identifiers in strings
 * Use: "Find ${identifier} in this string" --> ${identifier}
 */
const REGEX_VARIABLE_IDENTIFIER_WRAPPED = new RegExp(`${REGEX_VARIABLE_IDENTIFIER_WRAPPER_START}[a-zA-Z0-9_][a-zA-Z0-9_\\.\\[\\]\/]*?${REGEX_VARIABLE_IDENTIFIER_WRAPPER_END}`);
const REGEX_VARIABLE_IDENTIFIER_PARENT_OBJECT = new RegExp(/(?<=\$\{)[a-zA-Z0-9_][a-zA-Z0-9_\/]*(?=(\.|\[))/);
/**
 * Regex to unwrap a variable identifier
 * Use: "Find ${identifier} in this string" --> identifier
 */
const REGEX_VARIABLE_IDENTIFIER_UNWRAPPED = new RegExp(`(?<=${REGEX_VARIABLE_IDENTIFIER_WRAPPER_START})[a-zA-Z0-9_][a-zA-Z0-9_\\.\\[\\]\\/]*?(?=${REGEX_VARIABLE_IDENTIFIER_WRAPPER_END})`);
/**
 * Unwraps a variable identifier
 */
export function unwrapVariableIdentifier(identifier) {
    return identifier.match(REGEX_VARIABLE_IDENTIFIER_UNWRAPPED)?.[0] ?? identifier;
}
/**
 * Find all variable identifiers in a template string (JSONPath compatible).
 * Ex: "Here is a string with variables ${var1} ${var2.prop1} ${var2.prop2}" --> ["var1", "var2"]
 *
 * @returns {string[]} Unique variable identifiers
 */
export function findVariableIdentifiers(templateString) {
    // Array of identifiers that will be returned
    const variableIdentifiers = [];
    // Regex to find identifiers
    const findWrappedVariableIdentifiersRegex = new RegExp(REGEX_VARIABLE_IDENTIFIER_WRAPPED.source, "gm");
    // Find all wrapped variable identifiers
    // Repeat loop each time a new variable identifier is found
    let wrappedVariableIdentifiers;
    while ((wrappedVariableIdentifiers = findWrappedVariableIdentifiersRegex.exec(templateString)) !== null) {
        // Find if there is a parent variable (jsonpath)
        const parentVariableIdentifier = REGEX_VARIABLE_IDENTIFIER_PARENT_OBJECT.exec(wrappedVariableIdentifiers[0])?.[0];
        if (parentVariableIdentifier !== undefined && parentVariableIdentifier.length) {
            // Parent identifier found
            variableIdentifiers.push(parentVariableIdentifier);
        }
        else {
            // Regular identifier
            variableIdentifiers.push(unwrapVariableIdentifier(wrappedVariableIdentifiers[0]));
        }
    }
    // Return unique identifiers
    return variableIdentifiers.filter((value, index, self) => self.indexOf(value) === index);
}
