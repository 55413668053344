import { KortexTextField, theme } from "@aos/react-components";
import {
    EnumUserStatus,
    GroupEditorRightsEnum,
    IUserDbModel,
    IUserRoleDbModel,
    KanbanRightsEnum,
    ProcessApprovalRightsEnum,
    ProcessEditorRightsEnum,
    ProcessPlayerRightsEnum,
    ProcessReleaseRightsEnum,
    ReportsRightsEnum,
    RepositoryEditorRightsEnum,
    ReworkRightsEnum,
    RoleEditorRightsEnum,
    SchedulerOperatorRightsEnum,
    SchedulerRightsEnum,
    StorageEditorsRightsEnum,
    SystemRightsEnum,
    TrainingRightsEnum,
    UserEditorRightsEnum,
    getRightLevelLabelKey,
} from "@kortex/aos-common";
import { useStoreState } from "@kortex/aos-ui/hooks/useStoreState";
import { userCanInsert, userCanWrite } from "@kortex/aos-ui/utilitites/IUserRights";
import { IconButton, MenuItem, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import * as React from "react";
import { useEffect, useState } from "react";

import KortexLabelIcon from "../../../../components/core/KortexLabelIcon";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useEntitiesUsers } from "../../../../redux/effects";
import { userRoleUpdate } from "../../../../redux/user-manager/UserRoleActions";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    group: {
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        height: "calc(100vh - 190px)",
        overflowY: "auto",
    },
    itemGroup: {
        marginTop: "10px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
    },
    items: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        overflowY: "auto",
    },
    textField: {
        marginRight: 16,
        marginBottom: 16,
        width: 220,
    },
    groupText: {
        paddingBottom: "10px",
    },
    userName: {
        margin: "10px",
        padding: "10px",
        border: "1px solid",
        borderColor: theme.palette.grey[300],
        borderRadius: "5px",
    },
});

interface IOwnProps {
    role: IUserRoleDbModel;
    userAccessLevel?: number;

    onDelete?: (role: IUserRoleDbModel) => void;
    onUpdate?: (role: IUserRoleDbModel) => void;
}

export default function RoleEditor(props: IOwnProps): JSX.Element {
    const { userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const users = useEntitiesUsers();
    const userCanEdit = userCanWrite(userAccessLevel);
    const userCanInsertOrDelete = userCanInsert(userAccessLevel);

    const [usersWithRole, setUsersWithRole] = useState<IUserDbModel[]>([]);

    const [role, setRole] = useStoreState<IUserRoleDbModel>(props.role, userRoleUpdate, {
        debouncingTimeInMs: 1000,
    });

    /**
     * Updates when role or users changed
     */
    useEffect((): void => {
        if (users && role) {
            const usersFound = users.reduce((accumulator: IUserDbModel[], user) => {
                if (user.roles.find((searchedRoleId) => searchedRoleId === role.userRoleId)) {
                    accumulator.push(user);
                }
                return accumulator;
            }, []);
            setUsersWithRole(usersFound);
        } else {
            setUsersWithRole([]);
        }
    }, [role, users]);

    /**
     * Handle changes to role info
     */
    const handleRoleChange =
        (roleProps: keyof IUserRoleDbModel): ((value: string) => void) =>
        (value: string): void => {
            if (role) {
                const toUpdateRole: IUserRoleDbModel = { ...role, [roleProps]: value };
                setRole(toUpdateRole, undefined, { noDeboucing: true });
            }
        };

    /**
     * Sends decimals value to database
     *
     * @returns {Promise<undefined>} - updates process variable
     */
    const handleRoleChangeBlur =
        (roleProps: keyof IUserRoleDbModel): ((event: React.FocusEvent<HTMLInputElement>) => void) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            handleRoleChange(roleProps)(event.target.value);
        };

    /**
     * Handle deletion of role.. only when no user is currently using the role
     */
    const handleDeleteRole = (): void => {
        if (role && usersWithRole.length === 0 && props.onDelete) {
            props.onDelete(role);
        }
    };

    /**
     * Return key string to translate approval rights
     */
    const getApprovalRightLevelLabelKey = (level: ProcessApprovalRightsEnum): string => {
        switch (level) {
            case ProcessApprovalRightsEnum.AUTHENTICATED:
                return "accessLevel.none";
            case ProcessApprovalRightsEnum.READ:
                return "settings.roles.approval.reviewOnly";
            case ProcessApprovalRightsEnum.WRITE:
                return "settings.roles.approval.reviewAndApprove";
            default:
                return "general.na";
        }
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.root}>
                {role && (
                    <div className={classes.group}>
                        {/* ROLE DETAILS */}
                        <div id="roleEditorDetailsId" className={classes.itemGroup}>
                            <Typography className={classes.groupText} variant={"h5"}>
                                {translate("settings.roles.details")}
                            </Typography>
                            <div className={classes.items}>
                                <KortexTextField
                                    label={translate("settings.roles.name")}
                                    value={role.name}
                                    className={classes.textField}
                                    variant="standard"
                                    onChanged={handleRoleChange("name")}
                                    onBlur={handleRoleChangeBlur("name")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        id: "roleNameId",
                                    }}
                                    changedDelayMS={500}
                                />
                            </div>
                        </div>
                        {/* ROLE PERMISSION */}
                        <div id="roleEditorPermissionsId" className={classes.itemGroup}>
                            <Typography className={classes.groupText} variant={"h5"}>
                                {translate("settings.roles.permissions")}
                            </Typography>
                            <div className={classes.items}>
                                {/* SYSTEM */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.system")}
                                    onChanged={handleRoleChange("system")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        id: "systemId",
                                        select: true,
                                    }}
                                    InputProps={{
                                        id: "systemInputId",
                                    }}
                                    value={role.system}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${SystemRightsEnum.AUTHENTICATED}`}
                                        value={SystemRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(SystemRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem id={`roleEditorPermisssionOptionId${SystemRightsEnum.READ}`} value={SystemRightsEnum.READ}>
                                        {translate(getRightLevelLabelKey(SystemRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem id={`roleEditorPermisssionOptionId${SystemRightsEnum.WRITE}`} value={SystemRightsEnum.WRITE}>
                                        {translate(getRightLevelLabelKey(SystemRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* USER EDITOR */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.userEditor")}
                                    value={role.userEditor}
                                    onChanged={handleRoleChange("userEditor")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "userEditorId",
                                    }}
                                    InputProps={{
                                        id: "userEditorInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${UserEditorRightsEnum.AUTHENTICATED}`}
                                        value={UserEditorRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(UserEditorRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${UserEditorRightsEnum.READ}`}
                                        value={UserEditorRightsEnum.READ}
                                    >
                                        {translate(getRightLevelLabelKey(UserEditorRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${UserEditorRightsEnum.WRITE}`}
                                        value={UserEditorRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(UserEditorRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${UserEditorRightsEnum.INSERT}`}
                                        value={UserEditorRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(UserEditorRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${UserEditorRightsEnum.ARCHIVE}`}
                                        value={UserEditorRightsEnum.ARCHIVE}
                                    >
                                        {translate(getRightLevelLabelKey(UserEditorRightsEnum.ARCHIVE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* GROUP EDITOR */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.groupEditor")}
                                    value={role.groupEditor}
                                    onChanged={handleRoleChange("groupEditor")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "groupEditorId",
                                    }}
                                    InputProps={{
                                        id: "groupEditorInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${GroupEditorRightsEnum.AUTHENTICATED}`}
                                        value={GroupEditorRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(GroupEditorRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${GroupEditorRightsEnum.READ}`}
                                        value={GroupEditorRightsEnum.READ}
                                    >
                                        {translate(getRightLevelLabelKey(GroupEditorRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${GroupEditorRightsEnum.WRITE}`}
                                        value={GroupEditorRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(GroupEditorRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${GroupEditorRightsEnum.INSERT}`}
                                        value={GroupEditorRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(GroupEditorRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${GroupEditorRightsEnum.ARCHIVE}`}
                                        value={GroupEditorRightsEnum.ARCHIVE}
                                    >
                                        {translate(getRightLevelLabelKey(GroupEditorRightsEnum.ARCHIVE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* ROLE EDITOR */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.roleEditor")}
                                    value={role.roleEditor}
                                    onChanged={handleRoleChange("roleEditor")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "roleEditorId",
                                    }}
                                    InputProps={{
                                        id: "roleEditorInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${RoleEditorRightsEnum.AUTHENTICATED}`}
                                        value={RoleEditorRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(RoleEditorRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${RoleEditorRightsEnum.READ}`}
                                        value={RoleEditorRightsEnum.READ}
                                    >
                                        {translate(getRightLevelLabelKey(RoleEditorRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${RoleEditorRightsEnum.WRITE}`}
                                        value={RoleEditorRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(RoleEditorRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${RoleEditorRightsEnum.INSERT}`}
                                        value={RoleEditorRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(RoleEditorRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${RoleEditorRightsEnum.ARCHIVE}`}
                                        value={RoleEditorRightsEnum.ARCHIVE}
                                    >
                                        {translate(getRightLevelLabelKey(RoleEditorRightsEnum.ARCHIVE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* REPORTING */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.reporting")}
                                    value={role.reports}
                                    onChanged={handleRoleChange("reports")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "reportingId",
                                    }}
                                    InputProps={{
                                        id: "reportingInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ReportsRightsEnum.AUTHENTICATED}`}
                                        value={ReportsRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(ReportsRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem id={`roleEditorPermisssionOptionId${ReportsRightsEnum.READ}`} value={ReportsRightsEnum.READ}>
                                        {translate(getRightLevelLabelKey(ReportsRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* STORAGE MANAGER */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.storageManager")}
                                    value={role.storageEditor}
                                    onChanged={handleRoleChange("storageEditor")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "fileManagerId",
                                    }}
                                    InputProps={{
                                        id: "fileManagerInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${StorageEditorsRightsEnum.AUTHENTICATED}`}
                                        value={StorageEditorsRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(StorageEditorsRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${StorageEditorsRightsEnum.READ}`}
                                        value={StorageEditorsRightsEnum.READ}
                                    >
                                        {translate(getRightLevelLabelKey(StorageEditorsRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${StorageEditorsRightsEnum.WRITE}`}
                                        value={StorageEditorsRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(StorageEditorsRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${StorageEditorsRightsEnum.INSERT}`}
                                        value={StorageEditorsRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(StorageEditorsRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${StorageEditorsRightsEnum.ARCHIVE}`}
                                        value={StorageEditorsRightsEnum.ARCHIVE}
                                    >
                                        {translate(getRightLevelLabelKey(StorageEditorsRightsEnum.ARCHIVE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* PROCESS REPOSITORY */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.processRepository")}
                                    value={role.repositoryEditor}
                                    onChanged={handleRoleChange("repositoryEditor")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "processRepositoryId",
                                    }}
                                    InputProps={{
                                        id: "processRepositoryInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${RepositoryEditorRightsEnum.AUTHENTICATED}`}
                                        value={RepositoryEditorRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(RepositoryEditorRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${RepositoryEditorRightsEnum.READ}`}
                                        value={RepositoryEditorRightsEnum.READ}
                                    >
                                        {translate(getRightLevelLabelKey(RepositoryEditorRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${RepositoryEditorRightsEnum.WRITE}`}
                                        value={RepositoryEditorRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(RepositoryEditorRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${RepositoryEditorRightsEnum.INSERT}`}
                                        value={RepositoryEditorRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(RepositoryEditorRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${RepositoryEditorRightsEnum.ARCHIVE}`}
                                        value={RepositoryEditorRightsEnum.ARCHIVE}
                                    >
                                        {translate(getRightLevelLabelKey(RepositoryEditorRightsEnum.ARCHIVE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* PROCESS EDITOR */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.processEditor")}
                                    value={role.processEditor}
                                    onChanged={handleRoleChange("processEditor")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "processEditor/RoutingId",
                                    }}
                                    InputProps={{
                                        id: "processEditor/RoutingInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessEditorRightsEnum.AUTHENTICATED}`}
                                        value={ProcessEditorRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(ProcessEditorRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessEditorRightsEnum.READ}`}
                                        value={ProcessEditorRightsEnum.READ}
                                    >
                                        {translate(getRightLevelLabelKey(ProcessEditorRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessEditorRightsEnum.WRITE}`}
                                        value={ProcessEditorRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(ProcessEditorRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessEditorRightsEnum.INSERT}`}
                                        value={ProcessEditorRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(ProcessEditorRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessEditorRightsEnum.ARCHIVE}`}
                                        value={ProcessEditorRightsEnum.ARCHIVE}
                                    >
                                        {translate(getRightLevelLabelKey(ProcessEditorRightsEnum.ARCHIVE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* PROCESS PLAYER */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.processPlayer")}
                                    value={role.processPlayer}
                                    onChanged={handleRoleChange("processPlayer")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "processPlayerId",
                                    }}
                                    InputProps={{
                                        id: "processPlayerInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessPlayerRightsEnum.AUTHENTICATED}`}
                                        value={ProcessPlayerRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(ProcessPlayerRightsEnum.AUTHENTICATED, true)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessPlayerRightsEnum.WRITE}`}
                                        value={ProcessPlayerRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(ProcessPlayerRightsEnum.WRITE, true)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* PROCESS APPROVAL */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.processApproval")}
                                    value={role.processApproval}
                                    onChanged={handleRoleChange("processApproval")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "processApprovalId",
                                    }}
                                    InputProps={{
                                        id: "processApprovalInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessApprovalRightsEnum.AUTHENTICATED}`}
                                        value={ProcessApprovalRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getApprovalRightLevelLabelKey(ProcessApprovalRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessApprovalRightsEnum.READ}`}
                                        value={ProcessApprovalRightsEnum.READ}
                                    >
                                        {translate(getApprovalRightLevelLabelKey(ProcessApprovalRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessApprovalRightsEnum.WRITE}`}
                                        value={ProcessApprovalRightsEnum.WRITE}
                                    >
                                        {translate(getApprovalRightLevelLabelKey(ProcessApprovalRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* PROCESS RELEASE */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.processRelease")}
                                    value={role.processRelease}
                                    onChanged={handleRoleChange("processRelease")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "processReleaseId",
                                    }}
                                    InputProps={{
                                        id: "processReleaseInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessReleaseRightsEnum.AUTHENTICATED}`}
                                        value={ProcessReleaseRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(ProcessReleaseRightsEnum.AUTHENTICATED, true)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ProcessApprovalRightsEnum.WRITE}`}
                                        value={ProcessReleaseRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(ProcessReleaseRightsEnum.WRITE, true)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* SCHEDULER */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.scheduler")}
                                    value={role.scheduler}
                                    onChanged={handleRoleChange("scheduler")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "schedulerId",
                                    }}
                                    InputProps={{
                                        id: "schedulerInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${SchedulerRightsEnum.AUTHENTICATED}`}
                                        value={SchedulerRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(SchedulerRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${SchedulerRightsEnum.READ}`}
                                        value={SchedulerRightsEnum.READ}
                                    >
                                        {translate(getRightLevelLabelKey(SchedulerRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${SchedulerRightsEnum.WRITE}`}
                                        value={SchedulerRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(SchedulerRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${SchedulerRightsEnum.INSERT}`}
                                        value={SchedulerRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(SchedulerRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${SchedulerRightsEnum.ARCHIVE}`}
                                        value={SchedulerRightsEnum.ARCHIVE}
                                    >
                                        {translate(getRightLevelLabelKey(SchedulerRightsEnum.ARCHIVE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* SCHEDULER OPERATOR */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.workSchedule")}
                                    value={role.schedulerOperator}
                                    onChanged={handleRoleChange("schedulerOperator")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "workScheduleId",
                                    }}
                                    InputProps={{
                                        id: "workScheduleInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${SchedulerOperatorRightsEnum.AUTHENTICATED}`}
                                        value={SchedulerOperatorRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(SchedulerOperatorRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${SchedulerOperatorRightsEnum.READ}`}
                                        value={SchedulerOperatorRightsEnum.READ}
                                    >
                                        {translate(getRightLevelLabelKey(SchedulerOperatorRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* REWORK */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.reworks")}
                                    value={role.rework}
                                    onChanged={handleRoleChange("rework")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "reworksId",
                                    }}
                                    InputProps={{
                                        id: "reworksInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ReworkRightsEnum.AUTHENTICATED}`}
                                        value={ReworkRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(ReworkRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem id={`roleEditorPermisssionOptionId${ReworkRightsEnum.READ}`} value={ReworkRightsEnum.READ}>
                                        {translate(getRightLevelLabelKey(ReworkRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem id={`roleEditorPermisssionOptionId${ReworkRightsEnum.WRITE}`} value={ReworkRightsEnum.WRITE}>
                                        {translate(getRightLevelLabelKey(ReworkRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ReworkRightsEnum.INSERT}`}
                                        value={ReworkRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(ReworkRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${ReworkRightsEnum.ARCHIVE}`}
                                        value={ReworkRightsEnum.ARCHIVE}
                                    >
                                        {translate(getRightLevelLabelKey(ReworkRightsEnum.ARCHIVE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* KANBAN */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.kanban")}
                                    value={role.kanban}
                                    onChanged={handleRoleChange("kanban")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "kanbanId",
                                    }}
                                    InputProps={{
                                        id: "kanbanInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${KanbanRightsEnum.AUTHENTICATED}`}
                                        value={KanbanRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(KanbanRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${KanbanRightsEnum.INSERT}`}
                                        value={KanbanRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(KanbanRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* TRAINING */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.training")}
                                    value={role.training}
                                    onChanged={handleRoleChange("training")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "trainingId",
                                    }}
                                    InputProps={{
                                        id: "trainingInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${TrainingRightsEnum.AUTHENTICATED}`}
                                        value={TrainingRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(TrainingRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${TrainingRightsEnum.READ}`}
                                        value={TrainingRightsEnum.READ}
                                    >
                                        {translate(getRightLevelLabelKey(TrainingRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${TrainingRightsEnum.WRITE}`}
                                        value={TrainingRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(TrainingRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${TrainingRightsEnum.INSERT}`}
                                        value={TrainingRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(TrainingRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${TrainingRightsEnum.ARCHIVE}`}
                                        value={TrainingRightsEnum.ARCHIVE}
                                    >
                                        {translate(getRightLevelLabelKey(TrainingRightsEnum.ARCHIVE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                                {/* BOM */}
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    label={translate("settings.roles.bom")}
                                    value={role.bom}
                                    onChanged={handleRoleChange("bom")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        select: true,
                                        id: "bomId",
                                    }}
                                    InputProps={{
                                        id: "bomInputId",
                                    }}
                                >
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${TrainingRightsEnum.AUTHENTICATED}`}
                                        value={TrainingRightsEnum.AUTHENTICATED}
                                    >
                                        {translate(getRightLevelLabelKey(TrainingRightsEnum.AUTHENTICATED)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${TrainingRightsEnum.READ}`}
                                        value={TrainingRightsEnum.READ}
                                    >
                                        {translate(getRightLevelLabelKey(TrainingRightsEnum.READ)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${TrainingRightsEnum.WRITE}`}
                                        value={TrainingRightsEnum.WRITE}
                                    >
                                        {translate(getRightLevelLabelKey(TrainingRightsEnum.WRITE)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${TrainingRightsEnum.INSERT}`}
                                        value={TrainingRightsEnum.INSERT}
                                    >
                                        {translate(getRightLevelLabelKey(TrainingRightsEnum.INSERT)).toUpperCase()}
                                    </MenuItem>
                                    <MenuItem
                                        id={`roleEditorPermisssionOptionId${TrainingRightsEnum.ARCHIVE}`}
                                        value={TrainingRightsEnum.ARCHIVE}
                                    >
                                        {translate(getRightLevelLabelKey(TrainingRightsEnum.ARCHIVE)).toUpperCase()}
                                    </MenuItem>
                                </KortexTextField>
                            </div>
                        </div>
                        {/* ROLE ASSIGNED */}
                        <div id="roleEditorAssignedToId" className={classes.itemGroup}>
                            <Typography className={classes.groupText} variant={"h5"}>
                                {translate("settings.roles.assignTo")}
                            </Typography>
                            <div className={classes.items}>
                                {usersWithRole
                                    .filter((user) => user.status === EnumUserStatus.ACTIVE) // Keep only active users
                                    .sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)) // Sort alphabetically
                                    .map(
                                        (user, index): JSX.Element => (
                                            <div key={index} className={classes.userName}>
                                                {user.firstName + " " + user.lastName}
                                            </div>
                                        )
                                    )}
                            </div>
                        </div>
                    </div>
                )}
            </Paper>
            <div>
                <IconButton onClick={handleDeleteRole} disabled={!userCanInsertOrDelete}>
                    <KortexLabelIcon label={translate("general.delete")}>
                        <DeleteIcon />
                    </KortexLabelIcon>
                </IconButton>
            </div>
        </div>
    );
}
