import { SequencerError } from "./sequencer-error";
export class UndefinedRemoteMethodError extends SequencerError {
    constructor(key, message = "Undefined remote methods for remote action.") {
        super(key, message);
    }
    get name() {
        return "UndefinedRemoteMethodError";
    }
    get originalError() {
        return super.originalError;
    }
}
