import React, { FC, useEffect } from "react";

import { useBomContext } from "../../context";

const BomJob: FC = () => {
    const { search } = useBomContext();

    useEffect(() => {
        // ...
    }, [search.value]);

    return <div>Job</div>;
};

export default BomJob;
