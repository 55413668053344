import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { bomClearBoms, bomGetByTrackingId } from "@kortex/aos-ui/redux/bom-manager/bom-thunks";
import React, { FC, PropsWithChildren, createContext, useContext, useState } from "react";

export type SearchType = "job" | "partNumber" | "traceability" | "trackingId" | "adjustmentType";
export type AdjustmentType = "overconsumption" | "replacement" | "standard" | "update";

interface ISearch {
    type: SearchType | "none";
    value: string;
}

interface IBomContext {
    generate: (type: SearchType, value: string) => void;
    loading: boolean;
    search: ISearch;
}

const BomContext = createContext<IBomContext>({
    generate: () => void 0,
    loading: false,
    search: {
        type: "none",
        value: "",
    },
});

type BomProviderProps = PropsWithChildren<{}>;

export const BomProvider: FC<BomProviderProps> = (props) => {
    const { children } = props;

    const dispatch = useThunkDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<ISearch>({
        type: "none",
        value: "",
    });

    const generate = (type: SearchType, value: string): void => {
        if (type && value.trim()) {
            // Empty BOMs from redux
            dispatch(bomClearBoms());

            setSearch({ type, value });
            setLoading(true);

            switch (type) {
                case "trackingId":
                    dispatch(bomGetByTrackingId(value)).finally(() => setLoading(false));
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <BomContext.Provider
            value={{
                generate,
                loading,
                search,
            }}
        >
            {children}
        </BomContext.Provider>
    );
};

export const useBomContext = (): IBomContext => useContext<IBomContext>(BomContext);
