import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    GetFromErpAndCreateJobsReq,
    GetFromErpAndCreateJobsRes,
    JobAllProcessReq,
    JobAllProcessRes,
    JobAllReq,
    JobAllRes,
    JobArchiveAllCompletedNotif,
    JobArchiveAllCompletedReq,
    JobArchiveAllCompletedRes,
    JobDeleteJobProcessReq,
    JobDeleteJobProcessRes,
    JobDeleteJobRoutingReq,
    JobDeleteJobRoutingRes,
    JobInsertJobProcessReq,
    JobInsertJobProcessRes,
    JobInsertJobRoutingReq,
    JobInsertJobRoutingRes,
    JobInsertReq,
    JobInsertRes,
    JobInsertedNotif,
    JobJobProcessReq,
    JobJobProcessRes,
    JobOneReq,
    JobOneRes,
    JobProgressReq,
    JobProgressRes,
    JobUpdateJobProcessReq,
    JobUpdateJobProcessRes,
    JobUpdateJobProcessStatusReq,
    JobUpdateJobProcessStatusRes,
    JobUpdateReq,
    JobUpdateRes,
    JobUpdateStatusReq,
    JobUpdateStatusRes,
    JobUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { jobAll } from "./all";
import { jobAllProcess } from "./allProcess";
import { jobArchiveAllCompleted } from "./archiveAllCompleted";
import { jobArchivedAllCompleted } from "./archivedAllCompleted";
import { jobProcessDelete } from "./deleteJobProcess";
import { jobRoutingDelete } from "./deleteJobRouting";
import { getFromErpAndCreateJobs } from "./getFromErpAndCreateJobs";
import { jobInsert } from "./insert";
import { jobProcessInsert } from "./insertJobProcess";
import { jobRoutingInsert } from "./insertJobRouting";
import { jobInserted } from "./inserted";
import { jobJobProcess } from "./jobProcess";
import { jobOne } from "./one";
import { jobProgress } from "./progress";
import { jobUpdate } from "./update";
import { jobProcessUpdate } from "./updateJobProcess";
import { jobUpdateJobProcessStatus } from "./updateJobProcessStatus";
import { jobUpdateStatus } from "./updateStatus";
import { jobUpdated } from "./updated";

interface IAOSClientServiceWrappedJob {
    archiveAllCompleted: AOSClientServiceRequestWrapped<JobArchiveAllCompletedReq, JobArchiveAllCompletedRes>;
    archivedAllCompleted: AOSClientServiceNotificationWrapped<JobArchiveAllCompletedNotif>;
    deleteJobProcess: AOSClientServiceRequestWrapped<JobDeleteJobProcessReq, JobDeleteJobProcessRes>;
    deleteJobRouting: AOSClientServiceRequestWrapped<JobDeleteJobRoutingReq, JobDeleteJobRoutingRes>;
    getAll: AOSClientServiceRequestWrapped<JobAllReq, JobAllRes>;
    getAllProcess: AOSClientServiceRequestWrapped<JobAllProcessReq, JobAllProcessRes>;
    getProcess: AOSClientServiceRequestWrapped<JobJobProcessReq, JobJobProcessRes>;
    getOne: AOSClientServiceRequestWrapped<JobOneReq, JobOneRes>;
    insert: AOSClientServiceRequestWrapped<JobInsertReq, JobInsertRes>;
    insertJobProcess: AOSClientServiceRequestWrapped<JobInsertJobProcessReq, JobInsertJobProcessRes>;
    insertJobRouting: AOSClientServiceRequestWrapped<JobInsertJobRoutingReq, JobInsertJobRoutingRes>;
    onInserted: AOSClientServiceNotificationWrapped<JobInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<JobUpdatedNotif>;
    progress: AOSClientServiceRequestWrapped<JobProgressReq, JobProgressRes>;
    update: AOSClientServiceRequestWrapped<JobUpdateReq, JobUpdateRes>;
    updateJobProcess: AOSClientServiceRequestWrapped<JobUpdateJobProcessReq, JobUpdateJobProcessRes>;
    updateJobProcessStatus: AOSClientServiceRequestWrapped<JobUpdateJobProcessStatusReq, JobUpdateJobProcessStatusRes>;
    updateStatus: AOSClientServiceRequestWrapped<JobUpdateStatusReq, JobUpdateStatusRes>;
    getFromErpAndCreateJobs: AOSClientServiceRequestWrapped<GetFromErpAndCreateJobsReq, GetFromErpAndCreateJobsRes>;
}

type AOSClientServiceWrappedJob = IAOSClientServiceWrappedJob;

// ADD_API_CALL Job
export function generateJobServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedJob {
    return {
        archiveAllCompleted: AOSClientServiceWrapperUI.request(jobArchiveAllCompleted(router)),
        archivedAllCompleted: AOSClientServiceWrapperUI.notification(jobArchivedAllCompleted(router, notifier)),
        deleteJobProcess: AOSClientServiceWrapperUI.request(jobProcessDelete(router)),
        deleteJobRouting: AOSClientServiceWrapperUI.request(jobRoutingDelete(router)),
        getAll: AOSClientServiceWrapperUI.request(jobAll(router)),
        getAllProcess: AOSClientServiceWrapperUI.request(jobAllProcess(router)),
        getOne: AOSClientServiceWrapperUI.request(jobOne(router)),
        getProcess: AOSClientServiceWrapperUI.request(jobJobProcess(router)),
        insert: AOSClientServiceWrapperUI.request(jobInsert(router)),
        insertJobProcess: AOSClientServiceWrapperUI.request(jobProcessInsert(router)),
        insertJobRouting: AOSClientServiceWrapperUI.request(jobRoutingInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(jobInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(jobUpdated(router, notifier)),
        progress: AOSClientServiceWrapperUI.request(jobProgress(router)),
        update: AOSClientServiceWrapperUI.request(jobUpdate(router)),
        updateJobProcess: AOSClientServiceWrapperUI.request(jobProcessUpdate(router)),
        updateJobProcessStatus: AOSClientServiceWrapperUI.request(jobUpdateJobProcessStatus(router)),
        updateStatus: AOSClientServiceWrapperUI.request(jobUpdateStatus(router)),
        getFromErpAndCreateJobs: AOSClientServiceWrapperUI.request(getFromErpAndCreateJobs(router)),
    };
}

generateJobServiceClient.serviceId = 30;
