export function create() {
    let _subProgramsInput = {};
    function getInput(subProgramContext) {
        return _subProgramsInput[subProgramContext];
    }
    function reset() {
        _subProgramsInput = {};
    }
    function setInput(subProgramContext, subProgramInputActionGroupId) {
        _subProgramsInput[subProgramContext] = subProgramInputActionGroupId;
    }
    return {
        getInput,
        setInput,
        reset,
    };
}
