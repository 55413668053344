const VARIABLE_IDENTIFIER_REGEX = /^[a-zA-Z0-9_][a-zA-Z0-9_\/]*$/;
const validIdentifierRegex = /^\${(?:\${.*?}|[^${}]+)*}$/;
const REGEX_BRACE_EMPTY = new RegExp("\\$\\{\\s*\\}");
export const VARIABLE_IDENTIFIER_WRAPPER_START = "${";
export const VARIABLE_IDENTIFIER_WRAPPER_END = "}";
export const REGEX_VARIABLE_IDENTIFIER_WRAPPER_START = "\\$\\{";
export const REGEX_VARIABLE_IDENTIFIER_WRAPPER_END = "\\}";
export const REGEX_VARIABLE_IDENTIFIER_WRAPPED = new RegExp(`${REGEX_VARIABLE_IDENTIFIER_WRAPPER_START}[a-zA-Z0-9_][a-zA-Z0-9_\\.\\[\\]\/]*?${REGEX_VARIABLE_IDENTIFIER_WRAPPER_END}`);
export const REGEX_VARIABLE_IDENTIFIER_UNWRAPPED = new RegExp(`(?<=${REGEX_VARIABLE_IDENTIFIER_WRAPPER_START})[a-zA-Z0-9_][a-zA-Z0-9_\\.\\[\\]\\/]*?(?=${REGEX_VARIABLE_IDENTIFIER_WRAPPER_END})`);
export function wrapVariableIdentifier(identifier) {
    return VARIABLE_IDENTIFIER_WRAPPER_START + identifier + VARIABLE_IDENTIFIER_WRAPPER_END;
}
export function unwrapVariableIdentifier(identifier) {
    return identifier?.replace(new RegExp("^\\$\\{(.+)\\}$", "g"), "$1") ?? identifier;
}
function countCharacterOccurrences(str, char) {
    const regex = new RegExp(char, "g");
    const matches = str.match(regex);
    return matches ? matches.length : 0;
}
function removeCharacters(str) {
    const charactersToRemove = [REGEX_VARIABLE_IDENTIFIER_WRAPPER_START, REGEX_VARIABLE_IDENTIFIER_WRAPPER_END];
    for (const char of charactersToRemove) {
        const regex = new RegExp(char, "g");
        str = str.replace(regex, "");
    }
    return str;
}
export function validateVariableIdentifier(identifier) {
    const dollarOpeningBrace = countCharacterOccurrences(identifier, REGEX_VARIABLE_IDENTIFIER_WRAPPER_START);
    const closingBrace = countCharacterOccurrences(identifier, REGEX_VARIABLE_IDENTIFIER_WRAPPER_END);
    const nestingValidation = dollarOpeningBrace > 1 && closingBrace > 1 && dollarOpeningBrace === closingBrace ? validIdentifierRegex.test(identifier) : true;
    if (dollarOpeningBrace > 0 && dollarOpeningBrace === closingBrace && nestingValidation && !REGEX_BRACE_EMPTY.test(identifier)) {
        return VARIABLE_IDENTIFIER_REGEX.test(removeCharacters(identifier));
    }
    else {
        return VARIABLE_IDENTIFIER_REGEX.test(identifier);
    }
}
