var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function Checklist(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z M4,4 L4,20 L20,20 L20,4 L4,4 Z M6,7 L18,7 L18,8 L6,8 L6,7 Z M6,9.25 L18,9.25 L18,10.25 L6,10.25 L6,9.25 Z M6,11.5 L18,11.5 L18,12.5 L6,12.5 L6,11.5 Z M6,13.75 L18,13.75 L18,14.75 L6,14.75 L6,13.75 Z M6,16 L18,16 L18,17 L6,17 L6,16 Z", id: "icons-checklist" })));
}
