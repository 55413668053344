import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { IReworkUiModel, JobStatusEnum, createJobUiModel } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { jobInsert } from "@kortex/aos-ui/redux/scheduler-manager/scheduler-thunks-job";
import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    detailContent: {
        display: "flex",
        flexDirection: "row",
        padding: "5px 0",
    },
    createNewJobField: {
        margin: "5px",
        width: "170px",
    },
});

export interface ICreateReworkJobDialogProps {
    open: boolean;
    rework: IReworkUiModel;
    onClose: () => void;
}

export default function CreateReworkJobDialog(props: ICreateReworkJobDialogProps): JSX.Element {
    const { open, rework, onClose } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();

    const [reworkJobRefId, setReworkJobRefId] = useState<string>("");
    const [errorReworkJobRefId, setErrorReworkJobRefId] = useState<boolean>(false);
    const [reworkJobReferenceId, setReworkJobReferenceId] = useState<string>(
        rework?.jobRework?.referenceId
            ? rework?.jobRework?.referenceId
            : rework?.jobOfFailure?.referenceId
            ? rework?.jobOfFailure?.referenceId
            : ""
    );
    const [reworkJobPartNumber, setReworkJobPartNumber] = useState<string>(rework.partNumber ?? "");

    /**
     * Handle form schedule Job ref id change
     */
    const handleScheduleReworkJobRefIdChanged = (value: string): void => {
        setReworkJobRefId(value);
    };

    /**
     * handle form reference id change
     */
    const handleScheduleReworkJobReferenceIdChanged = (value: string): void => {
        setReworkJobReferenceId(value);
    };

    /**
     * handle form part number change
     */
    const handleScheduleReworkJobPartNumberChanged = (value: string): void => {
        setReworkJobPartNumber(value);
    };

    /**
     * handle request to create job
     */
    const handleCreate = async (): Promise<void> => {
        let formValid = true;

        if (reworkJobRefId === "") {
            formValid = false;
            setErrorReworkJobRefId(true);
        } else {
            setErrorReworkJobRefId(false);
        }

        if (formValid) {
            const reworkJob = createJobUiModel();
            reworkJob.qty = 1;
            reworkJob.status = JobStatusEnum.READY;
            reworkJob.infoJSON.trackingNumbersList = [rework.trackingId];
            reworkJob.reworkId = rework.reworkId;
            reworkJob.referenceId = reworkJobReferenceId;
            reworkJob.partNumber = reworkJobPartNumber;
            reworkJob.jobRefId = reworkJobRefId;

            await dispatch(jobInsert([reworkJob]));
            setReworkJobPartNumber("");
            setReworkJobRefId("");
            setReworkJobReferenceId("");
            handleCloseDialog();
        }
    };

    /**
     * handle close dialog
     */
    const handleCloseDialog = (): void => {
        setReworkJobPartNumber("");
        setReworkJobRefId("");
        setReworkJobReferenceId("");
        onClose();
    };

    return (
        <KortexDialogConfirmation
            open={open}
            dialogProps={{
                fullWidth: false,
                maxWidth: "sm",
                id: "dialogCreateReworkJobId",
            }}
            textLabels={{
                titleLabel: translate("rework.createNewJob"),
                proceedButtonLabel: translate("general.create"),
                cancelButtonLabel: translate("general.cancel"),
            }}
            onConfirm={handleCreate}
            onCancel={handleCloseDialog}
            closeOnEscape={true}
        >
            <div id="createfailureTicketJobFormId" className={classes.detailContent}>
                <KortexTextField
                    label={translate("scheduler.jobRefId")}
                    value={reworkJobRefId}
                    className={classes.createNewJobField}
                    InputProps={{
                        id: "failureTicketJobInputId",
                        required: true,
                        error: errorReworkJobRefId,
                    }}
                    TextFieldProps={{ required: true }}
                    onChanged={handleScheduleReworkJobRefIdChanged}
                />
                <KortexTextField
                    label={translate("scheduler.referenceId")}
                    value={reworkJobReferenceId}
                    className={classes.createNewJobField}
                    InputProps={{
                        id: "reworkDetailReferenceInputId",
                    }}
                    onChanged={handleScheduleReworkJobReferenceIdChanged}
                />
                <KortexTextField
                    label={translate("rework.partNumber")}
                    value={reworkJobPartNumber}
                    className={classes.createNewJobField}
                    InputProps={{
                        id: "reworkDetailPartNumberInputId",
                    }}
                    onChanged={handleScheduleReworkJobPartNumberChanged}
                />
            </div>
        </KortexDialogConfirmation>
    );
}
