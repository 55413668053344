var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Wait(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M17.4615385,8.25184615 L17.4615385,4.38461538 L18.1538462,4.38461538 C18.5361971,4.38461538 18.8461538,4.07465867 18.8461538,3.69230769 C18.8461538,3.30995671 18.5361971,3 18.1538462,3 L5.69230769,3 C5.30995671,3 5,3.30995671 5,3.69230769 C5,4.07465867 5.30995671,4.38461538 5.69230769,4.38461538 L6.38461538,4.38461538 L6.38461538,8.25184615 C6.38362049,8.61925187 6.52971467,8.97177265 6.79030769,9.23076923 L9.55953846,12 L6.79030769,14.7692308 C6.52971467,15.0282274 6.38362049,15.3807481 6.38461538,15.7481538 L6.38461538,19.6153846 L5.69230769,19.6153846 C5.30995671,19.6153846 5,19.9253413 5,20.3076923 C5,20.6900433 5.30995671,21 5.69230769,21 L18.1538462,21 C18.5361971,21 18.8461538,20.6900433 18.8461538,20.3076923 C18.8461538,19.9253413 18.5361971,19.6153846 18.1538462,19.6153846 L17.4615385,19.6153846 L17.4615385,15.7481538 C17.4625334,15.3807481 17.3164392,15.0282274 17.0558462,14.7692308 L14.2866154,12 L17.0558462,9.23076923 C17.3164392,8.97177265 17.4625334,8.61925187 17.4615385,8.25184615 Z M16.0769231,7.901686 L11.9230769,12 L7.76923077,7.901686 L7.76923077,5.07692308 L16.0769231,5.07692308 L16.0769231,7.901686 Z M7.76923077,16.098314 L11.9230769,12 L16.0769231,16.098314 L16.0769231,18.9230769 L7.76923077,18.9230769 L7.76923077,16.098314 Z", id: "icons-wait" })));
}
export default Wait;
