import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    TrainingPendingDeleteReq,
    TrainingPendingDeleteRes,
    TrainingPendingDeletedNotif,
    TrainingPendingGetAllByUserIdReq,
    TrainingPendingGetAllByUserIdRes,
    TrainingPendingGetAllReq,
    TrainingPendingGetAllRes,
    TrainingPendingInsertedNotif,
    TrainingPendingTrainingCertificateSignReq,
    TrainingPendingTrainingCertificateSignRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { trainingPendingDelete } from "./delete";
import { trainingPendingDeleted } from "./deleted";
import { trainingPendingGetAll } from "./getAll";
import { trainingPendingGetAllByUserId } from "./getAllByUserId";
import { trainingPendingInserted } from "./inserted";
import { trainingPendingTrainingCertificateSign } from "./trainingCertificateSign";

interface IAOSClientServiceWrappedTrainingPending {
    delete: AOSClientServiceRequestWrapped<TrainingPendingDeleteReq, TrainingPendingDeleteRes>;
    getAll: AOSClientServiceRequestWrapped<TrainingPendingGetAllReq, TrainingPendingGetAllRes>;
    getAllByUserId: AOSClientServiceRequestWrapped<TrainingPendingGetAllByUserIdReq, TrainingPendingGetAllByUserIdRes>;
    trainingCertificateSign: AOSClientServiceRequestWrapped<
        TrainingPendingTrainingCertificateSignReq,
        TrainingPendingTrainingCertificateSignRes
    >;
    onDeleted: AOSClientServiceNotificationWrapped<TrainingPendingDeletedNotif>;
    onInserted: AOSClientServiceNotificationWrapped<TrainingPendingInsertedNotif>;
}

type AOSClientServiceWrappedTrainingPending = IAOSClientServiceWrappedTrainingPending;

// ADD_API_CALL Root cause
export function generateTrainingPendingServiceClient(
    router: IRouterClient,
    notifier: EventEmitter
): AOSClientServiceWrappedTrainingPending {
    return {
        delete: AOSClientServiceWrapperUI.request(trainingPendingDelete(router)),
        getAll: AOSClientServiceWrapperUI.request(trainingPendingGetAll(router)),
        getAllByUserId: AOSClientServiceWrapperUI.request(trainingPendingGetAllByUserId(router)),
        trainingCertificateSign: AOSClientServiceWrapperUI.request(trainingPendingTrainingCertificateSign(router)),
        onInserted: AOSClientServiceWrapperUI.notification(trainingPendingInserted(router, notifier)),
        onDeleted: AOSClientServiceWrapperUI.notification(trainingPendingDeleted(router, notifier)),
    };
}

generateTrainingPendingServiceClient.serviceId = 133;
