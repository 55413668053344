import { IReportTagEntryDbModel, IReportTagColumnDbModel, IReportTagValueDbModel } from "@kortex/aos-common";
import { KortexTextField } from "@aos/react-components";
import { Fab, makeStyles, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import * as React from "react";

const COLUMN_WIDTH = 180;

const useStyles = makeStyles({
    root: {
        display: "grid",
        padding: "16px",
        height: "100%",
        overflow: "scroll",
    },
    entryRow: {
        display: "flex",
    },
    column: {
        marginRight: "16px",
        width: "180px",
    },
    textField: {
        width: "100%",
        margin: "0px",
    },
    table: {
        minWidth: "600px",
    },
    insertButton: {
        position: "absolute",
        right: "32px",
        bottom: "32px",
    },
});

export interface ITagEditorProps {
    tagColumns: IReportTagColumnDbModel[];
    tagColumnValues: IReportTagValueDbModel[];
    tagColumnEntries: IReportTagEntryDbModel[];

    onTagEntryChanged?: (reportTagEntry: IReportTagEntryDbModel) => void;
    onTagEntryInsert?: () => void;
}

export default function TagEntriesEditor(props: ITagEditorProps): JSX.Element {
    /**
     * COMPONENT PROPS
     */
    const { tagColumnEntries } = props;

    /**
     * COMPONENT STATES
     */
    const classes = useStyles();

    /**
     * Handle change event of a tag entry property
     *
     * @param {number} tagEntryId - Updated Tag entry id
     * @param {string} valueKey - Updated Tag entry property
     */
    const handleEntryValueChanged =
        (tagEntryId: number, valueKey: string): ((value: string) => void) =>
        (value: string): void => {
            const tagEntry = tagColumnEntries.find((tagEntry): boolean => tagEntry.reportTagEntryId === tagEntryId);

            if (!tagEntry) {
                return;
            }

            if (props.onTagEntryChanged) {
                props.onTagEntryChanged({ ...tagEntry, [valueKey]: value });
            }
        };

    /**
     * Hand insert event of a tag entry
     */
    const handleInsertTagEntry = (): void => {
        if (props.onTagEntryInsert) {
            props.onTagEntryInsert();
        }
    };

    return (
        <Paper className={classes.root}>
            <Table className={classes.table} style={{ width: props.tagColumns.length * COLUMN_WIDTH }} size="small">
                <TableHead>
                    <TableRow>
                        {props.tagColumns.map((tagColumn, index): JSX.Element => {
                            return <TableCell key={index}>{tagColumn.label}</TableCell>;
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.tagColumnEntries.map((tagEntry, tagEntryIndex): JSX.Element => {
                        return (
                            /**
                             * ENTRY ROW
                             */
                            <TableRow key={tagEntryIndex}>
                                {props.tagColumns.map((tagColumn, tagColumnIndex): JSX.Element => {
                                    return (
                                        <TableCell key={tagColumnIndex}>
                                            <KortexTextField
                                                variant="standard"
                                                className={classes.textField}
                                                TextFieldProps={{
                                                    select: true,
                                                }}
                                                value={tagEntry["reportTagValueId" + (tagColumnIndex + 1)] || 0}
                                                onChanged={handleEntryValueChanged(
                                                    tagEntry.reportTagEntryId || -1,
                                                    "reportTagValueId" + (tagColumnIndex + 1)
                                                )}
                                            >
                                                {props.tagColumnValues
                                                    .filter(
                                                        (tagValue): boolean => tagValue.reportTagColumnId === tagColumn.reportTagColumnId
                                                    )
                                                    .map((tagValue, tagValueIndex): JSX.Element => {
                                                        return (
                                                            <MenuItem key={tagValueIndex} value={tagValue.reportTagValueId}>
                                                                {tagValue.value}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </KortexTextField>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <Fab color="secondary" className={classes.insertButton} onClick={handleInsertTagEntry}>
                <AddIcon />
            </Fab>
        </Paper>
    );
}
