import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    TreeFileAllReq,
    TreeFileAllRes,
    TreeFileInsertReq,
    TreeFileInsertRes,
    TreeFileInsertedNotif,
    TreeFileUpdateReq,
    TreeFileUpdateRes,
    TreeFileUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { treeFileAll } from "./all";
import { treeFileInsert } from "./insert";
import { treeFileInserted } from "./inserted";
import { treeFileUpdate } from "./update";
import { treeFileUpdated } from "./updated";

interface IAOSClientServiceWrappedTreeFile {
    getAll: AOSClientServiceRequestWrapped<TreeFileAllReq, TreeFileAllRes>;
    insert: AOSClientServiceRequestWrapped<TreeFileInsertReq, TreeFileInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<TreeFileInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<TreeFileUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<TreeFileUpdateReq, TreeFileUpdateRes>;
}

type AOSClientServiceWrappedTreeFile = IAOSClientServiceWrappedTreeFile;

// ADD_API_CALL Tree file
export function generateTreeFileServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedTreeFile {
    return {
        getAll: AOSClientServiceWrapperUI.request(treeFileAll(router)),
        insert: AOSClientServiceWrapperUI.request(treeFileInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(treeFileInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(treeFileUpdated(router, notifier)),
        update: AOSClientServiceWrapperUI.request(treeFileUpdate(router)),
    };
}

generateTreeFileServiceClient.serviceId = 50;
