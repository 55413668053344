import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

function LineDashed(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                d="M2,13.2222222 L4.22222222,13.2222222 L4.22222222,11 L2,11 L2,13.2222222 Z M6.44444444,13.2222222 L8.66666667,13.2222222 L8.66666667,11 L6.44444444,11 L6.44444444,13.2222222 Z M10.8888889,13.2222222 L13.1111111,13.2222222 L13.1111111,11 L10.8888889,11 L10.8888889,13.2222222 Z M15.3333333,13.2222222 L17.5555556,13.2222222 L17.5555556,11 L15.3333333,11 L15.3333333,13.2222222 Z M19.7777778,13.2222222 L22,13.2222222 L22,11 L19.7777778,11 L19.7777778,13.2222222 Z"
                id="icons-dashed-line"
            />
        </SvgIcon>
    );
}

export default LineDashed;
