import { IProcessVariable, ProcessActionStepSet, ProcessVariableType } from "@kortex/aos-common";
import { Paper, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import VariablePicker from "../../../../../core/VariablePicker/VariablePicker";
import { IActionStepProps } from "../IActionStepProps";

const DEFAULT_VARIABLE: IProcessVariable = {
    identifier: "",
    defaultValue: "",
    decimals: 0,
    type: ProcessVariableType.STRING,
};

const useStyles = makeStyles({
    root: {
        height: "100%",
        padding: "16px",
        boxSizing: "border-box",
    },
    content: {
        display: "flex",
        gap: "10px",
    },
    setValueField: {
        flex: 0.8,
        margin: "5px",
    },
    setStoreToField: {
        flex: 0.2,
        margin: "5px",
    },
});

export default function ActSetStepEditor(props: IActionStepProps<ProcessActionStepSet>): JSX.Element {
    const { actionStepProps, userAccessLevel, onChanged } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [variable, setVariable] = useState<IProcessVariable>(actionStepProps.config.variables[0] ?? DEFAULT_VARIABLE);

    /**
     * Updates "Equation" inner state on props change
     */
    useEffect((): void => {
        if (actionStepProps.config.variables[0]) {
            setVariable(actionStepProps.config.variables[0]);
        }
    }, [actionStepProps.config.variables]);

    /**
     * Called when value textfield is blurred
     *
     * @param {string} updatedValue - textfield value
     */
    const handleValueBlur = (updatedValue: string): void => {
        if (updatedValue !== variable.defaultValue) {
            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    variables: [
                        {
                            ...variable,
                            defaultValue: updatedValue,
                        },
                    ],
                },
            });
        }
    };

    /**
     * Called when storeTo textfield is changed
     *
     * @param {string} identifier - textfield value
     */
    const handleStoreToChange = (identifier: string): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                variables: [
                    {
                        ...variable,
                        identifier,
                    },
                ],
            },
        });
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.content}>
                <VariablePicker
                    onBlur={handleValueBlur}
                    KortexTextFieldProps={{
                        label: translate("action.set.value"),
                        TextFieldProps: { id: "setValueId", multiline: true, className: classes.setValueField },
                    }}
                    userAccessLevel={userAccessLevel}
                    value={`${variable?.defaultValue ?? ""}`}
                />
                <VariablePicker
                    onChange={handleStoreToChange}
                    KortexTextFieldProps={{
                        label: translate("action.set.storeTo"),
                        TextFieldProps: { id: "setStoreTo", className: classes.setStoreToField },
                    }}
                    userAccessLevel={userAccessLevel}
                    value={variable?.identifier}
                />
            </div>
        </Paper>
    );
}
