import { makeStyles } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles({
    wrapper: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
    },
    label: {
        textAlign: "center",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    label: string;
    children: JSX.Element;
    fontSize?: number;
}

export default function KortexLabelIcon(props: IOwnProps): JSX.Element {
    const { children, label, fontSize = 10 } = props;
    const classes = useStyles(props);

    return (
        <div className={classes.wrapper}>
            {children}
            <span className={classes.label} style={{ fontSize: `${fontSize}px` }}>
                {label}
            </span>
        </div>
    );
}
