export const training = {
    rpc: {
        inserted: {
            funcId: 13401,
            funcName: "trainingInserted",
        },
        updated: {
            funcId: 13402,
            funcName: "trainingUpdated",
        },
        getUserIdsOfTrainingsByOriginalProcessIds: {
            funcId: 13403,
            funcName: "trainingGetUserIdsOfTrainingsByOriginalProcessIds",
        },
    },
    serviceId: 134,
    serviceVersion: 1,
};
