/**
 * Create a new file with default values
 */
export function newFileInfo(fileId, treeNodeId) {
    return {
        fileId,
        fileInfoId: 0,
        tooltip: "",
        treeNodeId,
    };
}
/**
 * Verfication to make sure an instance of fileInfo is valid
 */
export function isFileInfoValid(fileInfo) {
    return Boolean(fileInfo.fileId && fileInfo.treeNodeId);
}
