import { theme } from "@aos/react-components";
import { getWorkInstructionsStepCount, IProcessDbModel, ProcessActionWorkInstruction } from "@kortex/aos-common";
import { createGenerateClassName, MuiThemeProvider, StylesProvider } from "@material-ui/core";
import ServerStyleSheets from "@material-ui/styles/ServerStyleSheets/ServerStyleSheets";
import Debug from "debug";
import jsPDF from "jspdf";
import * as React from "react";
import ReactDOMServer from "react-dom/server";
import { IntlProvider, WrappedComponentProps } from "react-intl";
import { Provider } from "react-redux";

import { en as enMessages } from "../../../locales/en";
import { fr as frMessages } from "../../../locales/fr";
import { store } from "../../../redux/store";
import { svgToCanvas } from "../../../utilitites/svgToCanvas";

import PrintApproval from "./PrintApproval";
import PrintFrontPage from "./PrintFrontPage";
import PrintInstruction from "./PrintInstruction";
import PrintProcessFlow from "./PrintProcessFlow";
import PrintRevisionHistory from "./PrintRevisionHistory";
import PrintTableOfContent from "./PrintTableOfContent";
import { IPrintProcessDocumentProps, pageHeight, pageWidth } from "./printUtils";

const debug = Debug("kortex:aos:print");

const TABLE_OF_CONTENT_MAX_ROW = 18;

const generateClassName = createGenerateClassName({
    productionPrefix: "pdf",
    seed: "pdf",
});

type IAllProps = IPrintProcessDocumentProps & WrappedComponentProps;

export async function printProcessDocumentPDF(props: IAllProps): Promise<void> {
    const { intl, processInfo, processVersions, selectedVersionId, dynamicSections, treeNode, userList, userGroups, tasks } = props;
    const { locale } = intl;
    const locMessages = locale === "en" ? enMessages : frMessages;

    if (!processInfo || !dynamicSections || !treeNode) {
        return;
    }

    const sheetsRegistry = new ServerStyleSheets();
    const pdf = new jsPDF({ orientation: "landscape" });
    const pageCount = 4 + getWorkInstructionsStepCount(processInfo.actions) + dynamicSections.revisionHistoryPages.length;
    let pageIndex = 0;

    // CREATE TITLE PAGE
    let data = injectStyle(
        ReactDOMServer.renderToString(
            sheetsRegistry.collect(
                <IntlProvider locale={locale} messages={locMessages} key={locale}>
                    <StylesProvider generateClassName={generateClassName}>
                        <MuiThemeProvider theme={theme}>
                            <PrintFrontPage
                                pageIndex={pageIndex++}
                                title={(treeNode && treeNode.label) || ""}
                                totalPage={pageCount}
                                version={processInfo}
                            />
                        </MuiThemeProvider>
                    </StylesProvider>
                </IntlProvider>
            )
        ),
        sheetsRegistry
    );
    let canvas = await svgToCanvas(data, pageWidth, pageHeight);
    let imgData = canvas.toDataURL("image/jpeg", 1.0);

    // @ts-expect-error - might need to change this line
    pdf.addImage(imgData, "JPEG", 0, 0);

    // CREATE TABLE OF CONTENT
    let startIndex = 0;
    for (let i = 0; i < Math.ceil(dynamicSections.tableOfContent.length / TABLE_OF_CONTENT_MAX_ROW); i++) {
        pdf.addPage();
        data = injectStyle(
            ReactDOMServer.renderToString(
                sheetsRegistry.collect(
                    <IntlProvider locale={locale} messages={locMessages} key={locale}>
                        <MuiThemeProvider theme={theme}>
                            <PrintTableOfContent
                                key={i}
                                pageIndex={pageIndex}
                                totalPage={pageCount}
                                pagesTitle={dynamicSections.tableOfContent}
                                version={processInfo}
                                startIndex={startIndex}
                                howMany={TABLE_OF_CONTENT_MAX_ROW}
                            />
                        </MuiThemeProvider>
                    </IntlProvider>
                )
            ),
            sheetsRegistry
        );
        canvas = await svgToCanvas(data, pageWidth, pageHeight);
        imgData = canvas.toDataURL("image/jpeg", 1.0);

        // @ts-expect-error - might need to change this line
        pdf.addImage(imgData, "JPEG", 0, 0);
        pageIndex += 1;
        startIndex = startIndex + TABLE_OF_CONTENT_MAX_ROW;
    }

    // CREATE PROCESS FLOW PAGE
    pdf.addPage();

    data = injectStyle(
        ReactDOMServer.renderToString(
            sheetsRegistry.collect(
                <Provider store={store}>
                    <IntlProvider locale={locale} messages={locMessages} key={locale}>
                        <MuiThemeProvider theme={theme}>
                            <PrintProcessFlow
                                pageIndex={pageIndex++}
                                totalPage={pageCount}
                                processInfo={processInfo}
                                treeNode={treeNode}
                                version={processInfo}
                            />
                        </MuiThemeProvider>
                    </IntlProvider>
                </Provider>
            )
        ),
        sheetsRegistry
    );

    canvas = await svgToCanvas(data, pageWidth, pageHeight);
    imgData = canvas.toDataURL("image/jpeg", 1.0);

    // @ts-expect-error - might need to change this line
    pdf.addImage(imgData, "JPEG", 0, 0);

    // CREATE INSTRUCTION PAGES
    let pageNumber = 0;

    for (const action of processInfo.actions.filter((actionItem): boolean => actionItem.type === "core-work-instructions")) {
        for (const actionStep of (action as ProcessActionWorkInstruction).steps) {
            pdf.addPage();

            data = injectStyle(
                ReactDOMServer.renderToString(
                    sheetsRegistry.collect(
                        <Provider store={store}>
                            <IntlProvider locale={locale} messages={locMessages} key={locale}>
                                <MuiThemeProvider theme={theme}>
                                    <PrintInstruction
                                        key={pageNumber}
                                        pageIndex={pageIndex++}
                                        totalPage={pageCount}
                                        formStepProps={actionStep}
                                        isDraft={processInfo.isDraft}
                                    />
                                </MuiThemeProvider>
                            </IntlProvider>
                        </Provider>
                    )
                ),
                sheetsRegistry
            );

            try {
                canvas = await svgToCanvas(data, pageWidth, pageHeight);
            } catch (error) {
                debug("error", error);
            }

            imgData = canvas.toDataURL("image/jpeg", 1.0);
            pageNumber++;

            // @ts-expect-error - might need to change this line
            pdf.addImage(imgData, "JPEG", 0, 0);
        }
    }

    // CREATE REVISION HISTORY
    for (const page of dynamicSections.revisionHistoryPages) {
        let index = 0;
        pdf.addPage();

        data = injectStyle(
            ReactDOMServer.renderToString(
                sheetsRegistry.collect(
                    <IntlProvider locale={locale} messages={locMessages} key={locale}>
                        <MuiThemeProvider theme={theme}>
                            <PrintRevisionHistory
                                key={index}
                                index={index}
                                pageIndex={pageIndex++}
                                totalPage={pageCount}
                                processVersions={processVersions}
                                selectedVersionId={selectedVersionId}
                                startIndex={page.startIndex}
                                howMany={page.howMany}
                                userList={userList}
                            />
                        </MuiThemeProvider>
                    </IntlProvider>
                )
            ),
            sheetsRegistry
        );

        canvas = await svgToCanvas(injectStyle(data, sheetsRegistry), pageWidth, pageHeight);
        imgData = canvas.toDataURL("image/jpeg", 1.0);

        // @ts-expect-error - might need to change this line
        pdf.addImage(imgData, "JPEG", 0, 0);
        index += 1;
    }

    // CREATE APPROVAL
    pdf.addPage();

    data = injectStyle(
        ReactDOMServer.renderToString(
            sheetsRegistry.collect(
                <IntlProvider locale={locale} messages={locMessages} key={locale}>
                    <MuiThemeProvider theme={theme}>
                        {processVersions && selectedVersionId !== undefined && (
                            <PrintApproval
                                userList={userList}
                                userGroups={userGroups}
                                tasks={tasks}
                                pageIndex={pageIndex++}
                                totalPage={pageCount}
                                version={processVersions.find(
                                    (version: IProcessDbModel): boolean => version.processId === selectedVersionId
                                )}
                            />
                        )}
                    </MuiThemeProvider>
                </IntlProvider>
            )
        ),
        sheetsRegistry
    );
    canvas = await svgToCanvas(data, pageWidth, pageHeight);
    imgData = canvas.toDataURL("image/jpeg", 1.0);

    // @ts-expect-error - might need to change this line
    pdf.addImage(imgData, "JPEG", 0, 0);

    pdf.save("process.pdf");
}

/**
 * Inject CSS style information into html document
 *
 * @param {string} htmlDoc - Html document
 * @param {ServerStyleSheets} sheetsRegistry - CSS style style sheet
 *
 * @returns {string} - Html document with embeded CSS style
 */
function injectStyle(htmlDoc: string, sheetsRegistry: ServerStyleSheets): string {
    return htmlDoc.replace("<style></style>", `<style>${sheetsRegistry.toString()}</style>`);
}
