import { IProcessUiModel } from "@kortex/aos-common";
import { ProcessStatus, getProcessStatus } from "@kortex/aos-ui/utilitites/getProcessStatus";

export const getCurrentStatusLabel = (process: IProcessUiModel): string => {
    const status = getProcessStatus(process);

    switch (status) {
        case ProcessStatus.DRAFT:
            return "process.versioning.currentDraft";
        case ProcessStatus.WAITING_APPROVAL:
            return "process.versioning.awaiting";
        case ProcessStatus.REJECTED:
            return "process.versioning.rejected";
        case ProcessStatus.APPROVED:
            return "process.versioning.approved";
        case ProcessStatus.RELEASED:
            return "process.versioning.released";
        case ProcessStatus.RETIRED:
            return "process.versioning.retired";
        case ProcessStatus.CANCELLED:
            return "process.versioning.cancelled";
        case ProcessStatus.HOLD:
            return "process.versioning.onHold";
        case ProcessStatus.ARCHIVED:
            return "process.versioning.archived";
        case ProcessStatus.UNKNOWN:
        default:
            return "processRepository.unknown";
    }
};
