import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    UserRoleAllReq,
    UserRoleAllRes,
    UserRoleDeleteReq,
    UserRoleDeleteRes,
    UserRoleDeletedNotif,
    UserRoleInsertReq,
    UserRoleInsertRes,
    UserRoleInsertedNotif,
    UserRoleUpdateReq,
    UserRoleUpdateRes,
    UserRoleUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { userRoleAll } from "./all";
import { userRoleInsert } from "./insert";
import { userRoleInserted } from "./inserted";
import { userRoleUpdate } from "./update";
import { userRoleUpdated } from "./updated";
import { userRoleDelete } from "./delete";
import { userRoleDeleted } from "./deleted";

interface IAOSClientServiceWrappedUserRoles {
    delete: AOSClientServiceRequestWrapped<UserRoleDeleteReq, UserRoleDeleteRes>;
    getAll: AOSClientServiceRequestWrapped<UserRoleAllReq, UserRoleAllRes>;
    insert: AOSClientServiceRequestWrapped<UserRoleInsertReq, UserRoleInsertRes>;
    onDeleted: AOSClientServiceNotificationWrapped<UserRoleDeletedNotif>;
    onInserted: AOSClientServiceNotificationWrapped<UserRoleInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<UserRoleUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<UserRoleUpdateReq, UserRoleUpdateRes>;
}

type AOSClientServiceWrappedUserRoles = IAOSClientServiceWrappedUserRoles;

// ADD_API_CALL UserRole
export function generateUserRoleServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedUserRoles {
    return {
        delete: AOSClientServiceWrapperUI.request(userRoleDelete(router)),
        getAll: AOSClientServiceWrapperUI.request(userRoleAll(router)),
        insert: AOSClientServiceWrapperUI.request(userRoleInsert(router)),
        onDeleted: AOSClientServiceWrapperUI.notification(userRoleDeleted(router, notifier)),
        onInserted: AOSClientServiceWrapperUI.notification(userRoleInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(userRoleUpdated(router, notifier)),
        update: AOSClientServiceWrapperUI.request(userRoleUpdate(router)),
    };
}

generateUserRoleServiceClient.serviceId = 21;
