import { getSequencerActionCore } from "../core-actions";
import { ClientUpdateTypeEnum, FailureCodeEnum, FailureSourceEnum, KhronoEventEnum, KhronoHookSourceEnum, SequencerActionStatusEnum, } from "../enums";
import { ActionHookOnFailCircularError, } from "../interfaces";
import { Khrono } from "../khrono";
import { localeKeys } from "../locales";
import { KortexSequencerDebugger, canActionOutputBeSetToVariable, isActionCompleted, isActionFailed, isActionIdle, isCoreActionFlowError, isSequencerActionRemote, isSequencerError, isStoreOutputToDefined, unwrapVariableIdentifier, waitAfter, } from "../utilities";
const debug = KortexSequencerDebugger.action;
export const SequencerAction = {
    create: (factoryConfig) => {
        debug("new action created:%o", factoryConfig);
        const { actionConfig, context, delayAfterHookMs, hooks = {}, index, remote, type, variableManager, variableNamespace, } = factoryConfig;
        if (actionConfig.storeOutputTo) {
            actionConfig.storeOutputTo.identifier = unwrapVariableIdentifier(actionConfig.storeOutputTo.identifier);
        }
        const _action = getSequencerActionCore(type, index, variableManager, variableNamespace, remote, context);
        let _stateChanged = async (payload) => {
            debug("%d:%s:state changed:%o", index, _action.type, payload);
            Khrono.createEvent({
                payload: {
                    previousState: payload.previousState,
                    state: payload.state,
                },
                type: KhronoEventEnum.ACTION_STATE_CHANGE,
            });
            return _runSequencerActionHook("onStateChange", payload);
        }, _status = SequencerActionStatusEnum.IDLE, _statusChanged = async (payload) => {
            debug("%d:%s:status changed:%s:%o", index, _action.type, SequencerActionStatusEnum[payload.status], payload.payload);
        };
        (() => _action.assertValidConfig(actionConfig))();
        _action.onStateChange?.(_stateChanged);
        async function _complete(payload) {
            switch (payload.status) {
                case SequencerActionStatusEnum.COMPLETED:
                    debug("%d:%s:completed:success:%o", index, _action.type, payload);
                    if (isStoreOutputToDefined(actionConfig) && canActionOutputBeSetToVariable(payload.payload)) {
                        try {
                            debug("%d:%s:completed:set action output (%s) to variable (%o)", index, _action.type, payload.payload.output, actionConfig.storeOutputTo);
                            await variableManager.setVar(variableNamespace, {
                                value: payload.payload.output,
                                identifier: actionConfig.storeOutputTo.identifier,
                                type: actionConfig.storeOutputTo.type,
                            });
                        }
                        catch (error) {
                            debug("%d:%s:completed:set action output to variable:error:%o", index, _action.type, error);
                            return _fail({
                                action: {
                                    config: factoryConfig,
                                    state: {},
                                },
                                code: FailureCodeEnum.VARIABLE_MANAGER_ERROR,
                                error,
                                key: localeKeys.action.setActionOutputVariableManagerError,
                                source: FailureSourceEnum.ACTION,
                            });
                        }
                    }
                    return _updateStatus(payload);
                case SequencerActionStatusEnum.FAILED:
                    debug("%d:%s:completed:fail:%o", index, _action.type, payload);
                    return _fail({
                        ...payload.payload,
                        action: {
                            config: factoryConfig,
                            state: payload.payload.action.state,
                        },
                    });
                case SequencerActionStatusEnum.STOPPED:
                    return _updateStatus({ status: SequencerActionStatusEnum.STOPPED, payload: null });
            }
        }
        async function _fail(failure) {
            debug("%d:%s:fail:%o", index, _action.type, failure);
            Khrono.createEvent({
                payload: failure,
                type: KhronoEventEnum.ACTION_FAILURE,
            });
            return _updateStatus({ status: SequencerActionStatusEnum.FAILED, payload: failure }).then(async () => {
                if (isStoreOutputToDefined(actionConfig) &&
                    canActionOutputBeSetToVariable(failure.action.config.actionConfig.continueIfFail)) {
                    debug("%d:%s:fail:set action output (%s) to variable (%o)", index, _action.type, failure.action.config.actionConfig.continueIfFail.output, actionConfig.storeOutputTo);
                    await variableManager.setVar(variableNamespace, {
                        value: failure.action.config.actionConfig.continueIfFail.output,
                        identifier: actionConfig.storeOutputTo.identifier,
                        type: actionConfig.storeOutputTo.type,
                    });
                }
                if (!actionConfig.retryable) {
                    return unsubscribe();
                }
            });
        }
        async function _handleCoreActionError(error) {
            debug("%d:%s:core action error:%o", index, _action.type, error);
            return _fail({
                action: {
                    config: factoryConfig,
                    state: {},
                },
                code: isCoreActionFlowError(error) ? FailureCodeEnum.FLOW_ERROR : FailureCodeEnum.CRITICAL,
                error,
                key: isSequencerError(error) ? error.key : localeKeys.action.coreActionError,
                source: FailureSourceEnum.ACTION,
            });
        }
        function _runSequencerActionHook(key, payload) {
            debug("%d:%s:run hook:%o", index, _action.type, { key, payload });
            let initPromise = Promise.resolve();
            if (hooks[key]) {
                debug("%d:%s:run hook:%s is defined:executing hook...", index, _action.type, key);
                initPromise = initPromise.then(() => waitAfter(Khrono.createHookEvent({
                    hook: key,
                    source: KhronoHookSourceEnum.ACTION,
                }, hooks[key], undefined, payload), delayAfterHookMs));
                initPromise.catch((error) => {
                    if (key === "onFail") {
                        debug("%d:%s:run hook:error:%s not implemented, so throwing an error", index, _action.type, key);
                        throw new ActionHookOnFailCircularError({
                            action: {
                                config: factoryConfig,
                                state: {},
                            },
                            code: FailureCodeEnum.CRITICAL,
                            error,
                            key: localeKeys.action.hookErrorOnFail,
                            source: FailureSourceEnum.ACTION,
                        });
                    }
                    debug("%d:%s:run hook:fail:%s failed (error thrown or promised rejected)", index, _action.type, key);
                    return _fail({
                        action: {
                            config: factoryConfig,
                            state: {},
                        },
                        code: FailureCodeEnum.IMPLEMENTER_HOOK_ERROR,
                        error,
                        key: localeKeys.action.hookError,
                        source: FailureSourceEnum.ACTION,
                    });
                });
            }
            return initPromise;
        }
        async function _setState(state, feedback) {
            debug("%d:%s:set state:%o", index, _action.type, state);
            return _action.setState
                ? _action
                    .setState(state, feedback)
                    .then((output) => {
                    switch (output.status) {
                        case SequencerActionStatusEnum.CONTINUE:
                            return void 0;
                        case SequencerActionStatusEnum.FAILED:
                            return _fail({
                                ...output.payload,
                                action: {
                                    config: factoryConfig,
                                    state: {},
                                },
                            });
                    }
                })
                : _fail({
                    action: {
                        config: factoryConfig,
                        state: {},
                    },
                    code: FailureCodeEnum.SET_STATE_ERROR,
                    key: localeKeys.action.setStateNotDefined,
                    source: FailureSourceEnum.ACTION,
                });
        }
        async function _updateStatus(payload, hook) {
            debug("%d:%s:status update:%o", index, _action.type, payload);
            if (_status === payload.status) {
                return void 0;
            }
            debug("%d:%s:status update:status is different, updating...", index, _action.type);
            Khrono.createEvent({
                payload: {
                    previousStatus: _status,
                    status: payload.status,
                },
                type: KhronoEventEnum.ACTION_STATUS_CHANGE,
            });
            _status = payload.status;
            let defaultHook = hook;
            if (!defaultHook) {
                switch (payload.status) {
                    case SequencerActionStatusEnum.COMPLETED:
                        defaultHook = "onComplete";
                        break;
                    case SequencerActionStatusEnum.FAILED:
                        defaultHook = "onFail";
                        break;
                    case SequencerActionStatusEnum.PAUSED:
                        defaultHook = "onPause";
                        break;
                    case SequencerActionStatusEnum.PLAYING:
                        defaultHook = "onPlay";
                        break;
                    case SequencerActionStatusEnum.STOPPED:
                        defaultHook = "onStopped";
                        break;
                    case SequencerActionStatusEnum.STOPPING:
                        defaultHook = "onStop";
                        break;
                }
            }
            return (defaultHook ? _runSequencerActionHook(defaultHook, payload?.payload) : Promise.resolve()).then(() => _runSequencerActionHook("onStatusChange", payload).then(() => _statusChanged(payload)));
        }
        async function clientUpdate(payload) {
            debug("%d:%s:client update:%o", index, _action.type, payload);
            switch (payload.type) {
                case ClientUpdateTypeEnum.PLAY_NEXT:
                    return resume();
                case ClientUpdateTypeEnum.REMOTE_EVENT:
                    if (isSequencerActionRemote(_action)) {
                        return _action.clientUpdate(payload.payload).then(async (res) => {
                            if (res.status === SequencerActionStatusEnum.FAILED) {
                                return _fail({
                                    ...res.payload,
                                    action: {
                                        config: factoryConfig,
                                        state: res.payload.action.state,
                                    },
                                });
                            }
                        }, (error) => _fail({
                            action: {
                                config: factoryConfig,
                                state: {},
                            },
                            code: FailureCodeEnum.CONFIGURATION_ERROR,
                            error,
                            key: localeKeys.action.nonRemoteActionReceivingRemoteEvent,
                            source: FailureSourceEnum.ACTION,
                        }));
                    }
                    return _fail({
                        action: {
                            config: factoryConfig,
                            state: {},
                        },
                        code: FailureCodeEnum.CONFIGURATION_ERROR,
                        key: localeKeys.action.nonRemoteActionReceivingRemoteEvent,
                        source: FailureSourceEnum.ACTION,
                    });
                case ClientUpdateTypeEnum.UPDATE_STATE:
                    return _setState(payload.payload, payload.feedback);
            }
        }
        function getIndex() {
            return index;
        }
        function onStateChange(cb) {
            if (_action.onStateChange) {
                _stateChanged = async (state) => {
                    debug("%d:%s:state changed:%o", index, _action.type, state);
                    return _runSequencerActionHook("onStateChange", state).then(() => cb(state));
                };
                _action.onStateChange(_stateChanged);
            }
        }
        function onStatusChange(cb) {
            _statusChanged = async (payload) => {
                debug("%d:%s:status changed:%s:%o", index, _action.type, SequencerActionStatusEnum[payload.status], payload);
                return cb(payload);
            };
        }
        async function pause() {
            debug("%d:%s:pause", index, _action.type);
            if (_action.pause) {
                if (_status === SequencerActionStatusEnum.PLAYING) {
                    return _updateStatus({ status: SequencerActionStatusEnum.PAUSED, payload: null }).then(async () => _action.pause
                        ? _action.pause().catch(_handleCoreActionError)
                        : _fail({
                            action: {
                                config: factoryConfig,
                                state: {},
                            },
                            code: FailureCodeEnum.CRITICAL,
                            key: localeKeys.action.pauseNotDefined,
                            source: FailureSourceEnum.ACTION,
                        }));
                }
                else {
                    debug("%d:%s:pause:fail:action is not playing", index, _action.type);
                }
            }
            else {
                debug("%d:%s:pause:fail:pause not defined", index, _action.type);
            }
        }
        async function play() {
            debug("%d:%s:play", index, _action.type);
            if (isActionIdle(_status) || isActionCompleted(_status) || (isActionFailed(_status) && actionConfig.continueIfFail)) {
                Khrono.snapshotAction(index, _action.state, _status, variableNamespace, variableManager.getAllVars);
                try {
                    await _updateStatus({ status: SequencerActionStatusEnum.PLAYING, payload: null });
                    return _action.play(actionConfig).then((payload) => _complete(payload), _handleCoreActionError);
                }
                catch (error) {
                    _fail({
                        action: {
                            config: factoryConfig,
                            state: {},
                        },
                        code: FailureCodeEnum.VARIABLE_MANAGER_ERROR,
                        error,
                        key: localeKeys.variableManager.getAllError,
                        source: FailureSourceEnum.ACTION,
                    });
                }
            }
            else {
                debug("%d:%s:play:fail:action is not idle", index, _action.type);
            }
        }
        async function resume() {
            debug("%d:%s:resume", index, _action.type);
            if (_action.resume) {
                if ([SequencerActionStatusEnum.PAUSED].includes(_status)) {
                    return _updateStatus({ status: SequencerActionStatusEnum.PLAYING, payload: null }, "onResume").then(async () => _action.resume
                        ? _action.resume().catch(_handleCoreActionError)
                        : _fail({
                            action: {
                                config: factoryConfig,
                                state: {},
                            },
                            code: FailureCodeEnum.CRITICAL,
                            key: localeKeys.action.resumeNotDefined,
                            source: FailureSourceEnum.ACTION,
                        }));
                }
                else {
                    debug("%d:%s:resume:fail:action is not paused", index, _action.type);
                }
            }
            else {
                debug("%d:%s:resume:fail:resume is not defined", index, _action.type);
            }
        }
        async function stop(causeFailure = false) {
            debug("%d:%s:stop", index, _action.type);
            if ([SequencerActionStatusEnum.PAUSED, SequencerActionStatusEnum.PLAYING].includes(_status)) {
                return _updateStatus({
                    payload: null,
                    status: SequencerActionStatusEnum.STOPPING,
                }).then(() => _action.stop(causeFailure).catch(_handleCoreActionError));
            }
            else {
                debug("%d:%s:stop:fail:action is not in progress", index, _action.type);
            }
        }
        function isPausable() {
            return Boolean(_action.pause);
        }
        function unsubscribe(event) {
            debug("%d:%s:unsbscribe", index, _action.type);
            switch (event?.type) {
                case "general":
                    if (event.event === "onStateChange") {
                        onStateChange(async () => void 0);
                    }
                    else if (event.event === "onStatusChange") {
                        onStatusChange(async () => void 0);
                    }
                    break;
                case "hook":
                    hooks[event.event] = undefined;
                    break;
                default:
                    hooks.onComplete = undefined;
                    hooks.onFail = undefined;
                    hooks.onPause = undefined;
                    hooks.onPlay = undefined;
                    hooks.onResume = undefined;
                    hooks.onStateChange = undefined;
                    hooks.onStatusChange = undefined;
                    hooks.onStop = undefined;
                    onStateChange(async () => void 0);
                    onStatusChange(async () => void 0);
            }
        }
        return {
            get config() {
                return actionConfig;
            },
            get core() {
                return _action;
            },
            get status() {
                return _status;
            },
            set status(status) {
                _status = status;
            },
            get type() {
                return type;
            },
            clientUpdate,
            getIndex,
            isPausable,
            onStateChange,
            onStatusChange,
            play,
            stop,
            pause,
            resume,
            unsubscribe,
        };
    },
};
