import { KortexTextField, theme, warningPalette } from "@aos/react-components";
import {
    ApiErrorSubCodes,
    EnumUserStatus,
    IUserDbModel,
    IUserGroupDbModel,
    IUserRoleDbModel,
    UserEditorRightsEnum,
    UserGroupStatusEnum,
    assertSubCode,
    isApiError,
    isFirstNameValid,
    isLastNameValid,
    isPasswordValid,
    isUserLanguageValid,
    isUserNameValid,
    isUserRoleListValid,
    isUserValid,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    Input,
    MenuItem,
    Paper,
    Select,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import PasswordResetIcon from "../../../../components/core/Icons/PasswordReset/PasswordReset";
import KortexLabel from "../../../../components/core/KortexLabel";
import KortexLabelIcon from "../../../../components/core/KortexLabelIcon";
import appConfigs from "../../../../configs/app";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useEntitiesUsersGroups, useEntitiesUsersRoles } from "../../../../redux/effects";
import { userGetPasswordExpiryState, userPasswordUpdateByAdmin } from "../../../../redux/user-manager/users-thunks-user";
import { IUserRightsProps, userCanWrite } from "../../../../utilitites/IUserRights";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    userEditorForm: {
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
        height: "calc(100vh - 162px)",
        overflowY: "auto",
    },
    itemGroup: {
        marginTop: "10px",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
    },
    items: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        overflowY: "auto",
    },
    item: {
        display: "flex",
        flexDirection: "column",
        width: "250px",
        marginRight: "16px",
        marginBottom: "16px",
    },
    chipSelectItem: {
        display: "flex",
        flexDirection: "column",
        width: "40%",
        marginRight: "16px",
        marginBottom: "16px",
    },
    textField: {
        width: "220px",
    },
    userNameField: {
        width: "220px",
    },
    userNameFieldError: {
        width: "220px",
        borderBottom: `2px solid ${theme.palette.error[500]}`,
    },
    groupText: {
        paddingBottom: "10px",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },
    chip: {
        margin: theme.spacing(0.5),
        "&:hover": {
            cursor: "pointer",
        },
        padding: "10px",
        color: theme.palette.grey[700],
    },
    iconButton: {
        padding: "0px",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0)",
        },
    },
    passwordResetIconContainer: {
        marginBottom: "30px",
        marginTop: "5px",
    },
    actionButton: {
        minWidth: "80px",
        marginRight: "5px",
    },
    actionContainer: {
        display: "flex",
        alignItems: "flex-start",
    },
    actionIcon: {
        maxWidth: "50px",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0)",
        },
    },
    passwordTextField: {
        width: "330px",
    },
    invalidText: {
        color: theme.palette.error[500],
        fontSize: "0.7em",
        marginLeft: "6px",
        marginTop: "3px",
    },
    horizontal: {
        display: "flex",
        alignItems: "center",
    },
    warningIcon: {
        color: warningPalette[600],
        marginRight: "10px",
    },
    spacer: {
        marginBottom: "30px",
    },
});

const INPUT_DELAY_MS = 500;

// Chip selection menu styles
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            // Desired total height.
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: "250px",
        },
    },
};

export type IUserSaveOptions = {
    temporaryPassword?: string;
    forcePasswordUpdateFlag?: boolean;
};

interface IOwnProps extends IUserRightsProps<UserEditorRightsEnum> {
    userToEdit: IUserDbModel;
    onCancel: () => void;
    onSaveUser: (user: IUserDbModel, options?: IUserSaveOptions) => Promise<void>;
    onEditedUserChanged: (modedUser: IUserDbModel, options?: IUserSaveOptions) => void;
}

export default function UserEditor(props: IOwnProps): JSX.Element {
    const { userAccessLevel, userToEdit, onEditedUserChanged, onSaveUser } = props;
    const userCanEdit = userCanWrite(userAccessLevel);
    const variant: "create" | "update" = !userToEdit.userId ? "create" : "update";

    const allUserRoles: IUserRoleDbModel[] = useEntitiesUsersRoles();
    const allUserGroups: IUserGroupDbModel[] = useEntitiesUsersGroups();
    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const [user, setUser] = useState<IUserDbModel>(userToEdit);
    const [passwordResetOpen, setPasswordResetOpen] = useState<boolean>(false);
    const [working, setWorking] = useState<boolean>(false);
    const [password, setPassword] = useState("");
    const [retypedPassword, setRetypedPassword] = useState("");
    const [passwordMismatchError, setPasswordMismatchError] = useState("");
    const [passwordInvalidError, setPasswordInvalidError] = useState("");
    const [usernameUnavailableError, setUsernameUnavailableError] = useState<boolean>(false);
    const [usercodeUnavailableError, setUsercodeUnavailableError] = useState<boolean>(false);
    const [userPasswordExpired, setUserPasswordExpired] = useState(false);

    /**
     * Fetch password expiry date and refreshes user info display when new user selected from list
     */
    useEffect(() => {
        // Fetches the user's password expiry state
        if (variant === "update") {
            dispatch(userGetPasswordExpiryState({ userId: userToEdit.userId })).then((res) => {
                setUserPasswordExpired(res.expired);
            });
        }
        setUser(userToEdit);
        validatePasswords(password, retypedPassword);
    }, [userToEdit]);

    /**
     * Resets usernameUnavailableError when userName input is changed
     */
    useEffect((): void => {
        setUsernameUnavailableError(false);
    }, [user.userName]);

    /**
     * Resets usercodeUnavailableError when userCode input is changed
     */
    useEffect((): void => {
        setUsercodeUnavailableError(false);
    }, [user.userCode]);

    /**
     * Renders Chip component for each selected role in popup menu
     *
     * @param {number[]} selected - Selected Role IDs array
     */
    const renderSelectedRolesChips = (selected: number[]): JSX.Element => (
        <div className={classes.chips}>
            {(selected as number[]).map((value) => {
                const userRole: IUserRoleDbModel | undefined = allUserRoles.find((role) => role.userRoleId === value);
                return <Chip key={userRole?.userRoleId} label={userRole?.name} className={classes.chip} />;
            })}
        </div>
    );

    /**
     * Renders Chip component for each selected group in popup menu
     *
     * @param {number[]} selected - Selected Group IDs array
     */
    const renderSelectedGroupsChips = (selected: number[]): JSX.Element => (
        <div className={classes.chips}>
            {(selected as number[]).map((value) => {
                const userGroup: IUserGroupDbModel | undefined = allUserGroups.find((group) => group.userGroupId === value);
                if (userGroup) {
                    return <Chip key={userGroup?.userGroupId} label={getGroupName(userGroup)} className={classes.chip} />;
                }
                return null;
            })}
        </div>
    );
    /**
     * Return group name string and append "(archived)" if applicable
     *
     * @param {IUserGroupDbModel} group - Group for which you want to get name
     *
     * @returns {string} - Group name string to display
     */
    const getGroupName = (group: IUserGroupDbModel): string =>
        group.status === UserGroupStatusEnum.ARCHIVED ? `${group.name} (${translate("settings.groups.archived")})` : group.name;

    /**
     * Handles a change in the Password textField
     *
     * @param {React.ChangeEvent} event - change event
     */
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);

        validatePasswords(event.target.value, retypedPassword);
    };

    /**
     * Handles a change in the Retyped Password textField
     *
     * @param {React.ChangeEvent} event - change event
     */
    const handleRetypedPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRetypedPassword(event.target.value);

        validatePasswords(password, event.target.value);
    };

    /**
     * Sets local state errors for password validation
     *
     * @param {string} password - typed password to check
     * @param {string} retypedPassword - retyped password to check
     */
    const validatePasswords = (password: string, retypedPassword: string): void => {
        setPasswordMismatchError(password !== retypedPassword ? translate("settings.userSettings.passwordsMismatchError") : "");
        setPasswordInvalidError(!isPasswordValid(password) ? translate("settings.userSettings.invalidPasswordError") : "");
    };

    /**
     * Handles manual password reset of a user
     */
    const handleResetPassword = (): void => {
        dispatch(
            userPasswordUpdateByAdmin(props.userToEdit.userId, password, true, translate("settings.userSettings.passwordUpdateCompleted"))
        );
        handleClosePasswordResetDialog();
    };

    /**
     * Open password dialog
     */
    const handleOpenPasswordResetDialog = (): void => {
        setPasswordResetOpen(true);
    };

    /**
     * Close password dialog
     */
    const handleClosePasswordResetDialog = (): void => {
        setPasswordResetOpen(false);
    };

    /**
     * Handles saving of user
     */
    const handleSaveNewUser = (): void => {
        // Create user to save from state
        const savedUser: IUserDbModel = {
            userId: userToEdit.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            userName: user.userName,
            email: user.email ?? "",
            cell: user.cell ?? "",
            status: user.status as EnumUserStatus,
            roles: user.roles,
            groups: user.groups,
            userCode: user.userCode ?? "",
            language: user.language,
        };

        // Check if user is valid
        if (!isUserValid(savedUser)) {
            return;
        }

        // Create options parameter and append temporary password if we are creating a new user
        const options: IUserSaveOptions = {};
        if (variant === "create") {
            options.temporaryPassword = password;
        }

        // save the user
        setWorking(true);
        onSaveUser(savedUser, options)
            .catch((error) => {
                if (isApiError(error) && assertSubCode(error, ApiErrorSubCodes.USER_INSERT_DUPLICATE_USERNAME)) {
                    setUsernameUnavailableError(true);
                }
                if (isApiError(error) && assertSubCode(error, ApiErrorSubCodes.USER_INSERT_DUPLICATE_USERCODE)) {
                    setUsercodeUnavailableError(true);
                }
            })
            .finally(() => setWorking(false));
    };

    /**
     * Called when user info is selected
     *
     * @param {string} userProps - indicates which field is changed
     */
    const handleUserChangeSelect =
        (userProps: keyof IUserDbModel): ((e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
            if (variant === "update") {
                if (userProps === "roles") {
                    setUser({ ...user, [userProps]: e.target.value as unknown as number[] });

                    if (!e.target.value.length) {
                        return;
                    }
                }
                onEditedUserChanged({ ...user, [userProps]: e.target.value }, {});
            } else if (variant === "create") {
                setUser({ ...user, [userProps]: e.target.value });
            }
        };

    /**
     * Handles user property editing
     *
     * @param {keyof IUserDbModel} userProps - which property we are editing
     */

    const handleUserChange =
        (userProps: keyof IUserDbModel): ((value: string) => void) =>
        (value: string): void => {
            if (variant === "update") {
                // return if value is same as original one
                if (user[userProps] === value) {
                    return;
                }

                // create updatedUser to pass to action handler
                const updatedUser = { ...user, [userProps]: value };

                // Check if user is valid
                if (!isUserValid(updatedUser)) {
                    return;
                }

                if (userProps === "userName") {
                    setUsernameUnavailableError(false);
                    onSaveUser(updatedUser, {}).catch((error) => {
                        if (isApiError(error) && assertSubCode(error, ApiErrorSubCodes.USER_INSERT_DUPLICATE_USERNAME)) {
                            setUsernameUnavailableError(true);
                        }
                    });

                    setUser(updatedUser);
                    return;
                }

                if (userProps === "userCode") {
                    setUsercodeUnavailableError(false);
                    onSaveUser(updatedUser, {}).catch((error) => {
                        if (isApiError(error) && assertSubCode(error, ApiErrorSubCodes.USER_INSERT_DUPLICATE_USERCODE)) {
                            setUsercodeUnavailableError(true);
                        }
                    });

                    setUser(updatedUser);
                    return;
                }

                onEditedUserChanged(updatedUser, {});
            } else if (variant === "create") {
                setUser({ ...user, [userProps]: value });
            }
        };

    // Disable Save Button when user does not have permissions or form is invalid
    const disableSaveButton =
        !userCanEdit ||
        !isUserNameValid(user.userName) ||
        !isFirstNameValid(user.firstName) ||
        !isLastNameValid(user.lastName) ||
        !isUserRoleListValid(user.roles) ||
        Boolean(passwordInvalidError) ||
        Boolean(passwordMismatchError);

    // Email verification regex
    const emailVerificationRegex =
        /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // Phone verification regex
    const phoneVerificationRegex = /^$|^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    return (
        <div className={classes.root}>
            <Paper className={classes.container}>
                <div id="userEditorPanelInfoId" className={classes.userEditorForm}>
                    {/* USER DETAILS */}
                    <div id="userEditorDetailsId" className={classes.itemGroup}>
                        <Typography className={classes.groupText} variant="h5">
                            {translate("settings.userSettings.userDetails")}
                        </Typography>

                        <div className={classes.items}>
                            <div className={classes.item}>
                                <KortexLabel label={translate("settings.userSettings.firstName")} variant="compact" />
                                <KortexTextField
                                    className={classes.textField}
                                    onChanged={handleUserChange("firstName")}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    variant="standard"
                                    TextFieldProps={{
                                        disabled: !userCanEdit || usernameUnavailableError || usercodeUnavailableError,
                                        id: "firstNameId",
                                    }}
                                    minLength={2}
                                    maxLength={50}
                                    standardErrorMsgs={{ invalidLength: translate("settings.userSettings.invalidLength_2_50") }}
                                    value={user.firstName}
                                />
                            </div>
                            <div className={classes.item}>
                                <KortexLabel label={translate("settings.userSettings.lastName")} variant="compact" />
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    onChanged={handleUserChange("lastName")}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    TextFieldProps={{
                                        disabled: !userCanEdit || usernameUnavailableError || usercodeUnavailableError,
                                        id: "lastNameId",
                                    }}
                                    minLength={2}
                                    maxLength={50}
                                    standardErrorMsgs={{ invalidLength: translate("settings.userSettings.invalidLength_2_50") }}
                                    value={user.lastName}
                                />
                            </div>
                            <div className={classes.item}>
                                <KortexLabel label={translate("settings.userSettings.username")} variant="compact" />
                                <KortexTextField
                                    className={usernameUnavailableError ? classes.userNameFieldError : classes.userNameField}
                                    variant="standard"
                                    onChanged={handleUserChange("userName")}
                                    minLength={2}
                                    maxLength={50}
                                    standardErrorMsgs={{ invalidLength: translate("settings.userSettings.invalidLength_2_50") }}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    TextFieldProps={{
                                        disabled: !userCanEdit || (!usernameUnavailableError && usercodeUnavailableError),
                                        id: "userNameId",
                                    }}
                                    value={user.userName}
                                />
                                {usernameUnavailableError && (
                                    <div className={classes.invalidText}>{translate("settings.userSettings.usernameUnavailableError")}</div>
                                )}
                            </div>
                            <div className={classes.item}>
                                <KortexLabel label={translate("settings.userSettings.code")} variant="compact" />
                                <KortexTextField
                                    className={usercodeUnavailableError ? classes.userNameFieldError : classes.userNameField}
                                    variant="standard"
                                    onChanged={handleUserChange("userCode")}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    TextFieldProps={{
                                        disabled: !userCanEdit || (usernameUnavailableError && !usercodeUnavailableError),
                                        id: "userCodeId",
                                    }}
                                    maxLength={50}
                                    standardErrorMsgs={{ tooLong: translate("settings.userSettings.invalidLength_0_50") }}
                                    value={user.userCode ?? ""}
                                />
                                {usercodeUnavailableError && (
                                    <div className={classes.invalidText}>{translate("settings.userSettings.usercodeUnavailableError")}</div>
                                )}
                            </div>
                            <div className={classes.item}>
                                <KortexLabel label={translate("settings.userSettings.language")} variant="compact" />
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    error={
                                        isUserLanguageValid(user.language) ? undefined : translate("settings.userSettings.invalidLanguage")
                                    }
                                    onChange={handleUserChangeSelect("language")}
                                    TextFieldProps={{
                                        disabled: !userCanEdit || usernameUnavailableError || usercodeUnavailableError,
                                        id: "languageId",
                                        select: true,
                                    }}
                                    value={user.language}
                                >
                                    {appConfigs.acceptedLanguages.map((lang) => (
                                        <MenuItem key={lang} value={lang}>
                                            {translate(lang)}
                                        </MenuItem>
                                    ))}
                                </KortexTextField>
                            </div>
                        </div>
                    </div>
                    {/* CONTACT INFORMATION */}
                    <div id="userEditorContactId" className={classes.itemGroup}>
                        <Typography className={classes.groupText} variant="h5">
                            {translate("settings.userSettings.contactInformation")}
                        </Typography>
                        <div className={classes.items}>
                            <div className={classes.item}>
                                <KortexLabel label={translate("settings.userSettings.email")} variant="compact" />
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    onChanged={handleUserChange("email")}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    TextFieldProps={{
                                        disabled: !userCanEdit || usernameUnavailableError || usercodeUnavailableError,
                                        id: "emailId",
                                    }}
                                    regex={emailVerificationRegex}
                                    standardErrorMsgs={{ regexNoMatch: translate("settings.userSettings.invalidEmail") }}
                                    value={user.email ?? ""}
                                />
                            </div>
                            <div className={classes.item}>
                                <KortexLabel label={translate("settings.userSettings.cell")} variant="compact" />
                                <KortexTextField
                                    className={classes.textField}
                                    variant="standard"
                                    onChanged={handleUserChange("cell")}
                                    changedDelayMS={INPUT_DELAY_MS}
                                    TextFieldProps={{
                                        disabled: !userCanEdit || usernameUnavailableError || usercodeUnavailableError,
                                        id: "cellId",
                                    }}
                                    regex={phoneVerificationRegex}
                                    standardErrorMsgs={{ regexNoMatch: translate("settings.userSettings.invalidCell") }}
                                    value={user.cell ?? ""}
                                />
                            </div>
                        </div>
                    </div>
                    {/* GROUPS, ROLES & PERMISSIONS */}
                    <div id="userEditorGroupsRolePermissionsId" className={classes.itemGroup}>
                        <Typography className={classes.groupText} variant="h5">
                            {translate("settings.userSettings.groupsRolesPermissions")}
                        </Typography>
                        <div className={classes.items}>
                            <div className={classes.chipSelectItem}>
                                <KortexLabel label={translate("settings.userSettings.roles")} variant="compact" />
                                <FormControl>
                                    <Select
                                        id="userRolesSelectId"
                                        disabled={!userCanEdit || usernameUnavailableError || usercodeUnavailableError}
                                        multiple={true}
                                        error={!isUserRoleListValid(user.roles)}
                                        value={user.roles}
                                        onChange={handleUserChangeSelect("roles")}
                                        input={<Input id="userRolesInputId" />}
                                        renderValue={renderSelectedRolesChips}
                                        MenuProps={MenuProps}
                                    >
                                        {allUserRoles.map((role) => (
                                            <MenuItem key={role.userRoleId} value={role.userRoleId}>
                                                {role.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div className={classes.invalidText}>
                                    {user.roles.length ? undefined : translate("settings.userSettings.invalid")}
                                </div>
                            </div>
                            <div className={classes.chipSelectItem}>
                                <KortexLabel label={translate("settings.userSettings.groups")} variant="compact" />
                                <FormControl>
                                    <Select
                                        id="userGroupsSelectId"
                                        disabled={!userCanEdit || usernameUnavailableError || usercodeUnavailableError}
                                        multiple={true}
                                        value={user.groups}
                                        onChange={handleUserChangeSelect("groups")}
                                        input={<Input id="userGroupsInputId" />}
                                        renderValue={renderSelectedGroupsChips}
                                        MenuProps={MenuProps}
                                    >
                                        {allUserGroups
                                            .filter(
                                                (group) =>
                                                    group.status !== UserGroupStatusEnum.ARCHIVED || user.groups.includes(group.userGroupId)
                                            )
                                            .map((group) => (
                                                <MenuItem key={group.userGroupId} value={group.userGroupId}>
                                                    {getGroupName(group)}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    {/* USER STATUS */}
                    <div id="userEditorStatusId" className={clsx(classes.itemGroup, classes.spacer)}>
                        <div className={classes.item}>
                            <Typography className={classes.groupText} variant="h5">
                                {translate("settings.userSettings.status")}
                            </Typography>
                            <KortexLabel label={translate("settings.userSettings.userStatus")} variant="compact" />
                            <KortexTextField
                                className={classes.textField}
                                variant="standard"
                                value={user.status}
                                onChanged={handleUserChange("status")}
                                changedDelayMS={INPUT_DELAY_MS}
                                TextFieldProps={{
                                    disabled: !userCanEdit || usernameUnavailableError || usercodeUnavailableError,
                                    select: true,
                                    id: "userStatusId",
                                }}
                            >
                                <MenuItem value={EnumUserStatus.ACTIVE}>{translate("settings.userSettings.status.active")}</MenuItem>
                                <MenuItem value={EnumUserStatus.ARCHIVED}>{translate("settings.userSettings.status.archived")}</MenuItem>
                                <MenuItem value={EnumUserStatus.DISABLED}>{translate("settings.userSettings.status.disabled")}</MenuItem>
                            </KortexTextField>
                        </div>
                    </div>
                    {/* TEMPORARY PASSWORD */}
                    {variant === "create" && (
                        <div className={classes.itemGroup}>
                            <>
                                <Typography className={classes.groupText} variant="h5">
                                    {translate("settings.userSettings.password")}
                                </Typography>
                                <KortexTextField
                                    className={classes.passwordTextField}
                                    label={translate("settings.userSettings.password")}
                                    onChange={handlePasswordChange}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        id: "passwordId",
                                        required: true,
                                    }}
                                    value={password}
                                    error={passwordInvalidError}
                                    type="password"
                                />
                                <KortexTextField
                                    className={classes.passwordTextField}
                                    label={translate("settings.userSettings.retypedPassword")}
                                    onChange={handleRetypedPasswordChange}
                                    TextFieldProps={{
                                        disabled: !userCanEdit,
                                        id: "retypedPasswordId",
                                        required: true,
                                    }}
                                    value={retypedPassword}
                                    error={passwordMismatchError}
                                    type="password"
                                />
                                <Typography className={classes.spacer}>{translate("settings.userSettings.passwordHint")}</Typography>
                                {/* SAVE + CANCEL ACTIONS */}
                                <div className={classes.actionContainer}>
                                    <Button variant="outlined" onClick={props.onCancel} color="secondary" className={classes.actionButton}>
                                        {translate("general.cancel")}
                                    </Button>
                                    <Button
                                        id="saveUserId"
                                        className={classes.actionButton}
                                        disabled={disableSaveButton}
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleSaveNewUser}
                                    >
                                        {working ? <CircularProgress color="inherit" /> : translate("general.save")}
                                    </Button>
                                </div>
                            </>
                        </div>
                    )}
                </div>
            </Paper>
            {/* RESET PASSWORD ACTION */}
            <div className={classes.passwordResetIconContainer}>
                <IconButton
                    id="resetButtonId"
                    className={classes.actionIcon}
                    onClick={handleOpenPasswordResetDialog}
                    disabled={!userCanEdit}
                >
                    <KortexLabelIcon label={translate("settings.userSettings.resetPassword")}>
                        <PasswordResetIcon />
                    </KortexLabelIcon>
                </IconButton>
            </div>

            {/* RESET PASSWORD DIALOG */}
            <Dialog open={passwordResetOpen}>
                <DialogTitle>{translate("settings.userSettings.resetPassword")}</DialogTitle>
                <DialogContent>
                    <div id="userPasswordResetDialogId" className={classes.itemGroup}>
                        <>
                            <KortexTextField
                                className={classes.passwordTextField}
                                label={translate("settings.userSettings.password")}
                                onChange={handlePasswordChange}
                                TextFieldProps={{
                                    disabled: !userCanEdit,
                                    id: "passwordId",
                                    required: true,
                                }}
                                value={password}
                                error={passwordInvalidError}
                                type="password"
                            />
                            <KortexTextField
                                className={classes.passwordTextField}
                                label={translate("settings.userSettings.retypedPassword")}
                                onChange={handleRetypedPasswordChange}
                                TextFieldProps={{
                                    disabled: !userCanEdit,
                                    id: "retypedPasswordId",
                                    required: true,
                                }}
                                value={retypedPassword}
                                error={passwordMismatchError}
                                type="password"
                            />

                            <Typography className={classes.spacer}>{translate("settings.userSettings.passwordHint")}</Typography>
                        </>
                        {variant === "update" && userPasswordExpired && (
                            <div className={classes.horizontal}>
                                <WarningIcon className={classes.warningIcon} />
                                <Typography>{translate("settings.userSettings.passwordExpired")}</Typography>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleClosePasswordResetDialog}>
                        {translate("general.cancel")}
                    </Button>
                    <Button
                        id="proceedButtonId"
                        disabled={!userCanEdit || Boolean(passwordInvalidError) || Boolean(passwordMismatchError)}
                        variant="contained"
                        color="secondary"
                        onClick={handleResetPassword}
                    >
                        {translate("settings.userSettings.resetPassword")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
