import { useEffect, useState } from "react";

interface IOptions {
    preventDefault?: boolean;
    stopPropagation?: boolean;
}

export function useKeyPressed(trackedKeyCode: number, disabled?: boolean, options?: IOptions): boolean {
    const [keyPressed, setKeyPressed] = useState<boolean>(false);

    // Effect that handles the eventListener based on the disabled prop
    useEffect((): (() => void) | undefined => {
        const preventDefault = Boolean(options?.preventDefault);
        const stopPropagation = Boolean(options?.stopPropagation);

        const handleKeydown = (e: KeyboardEvent): void => {
            if (e.keyCode === trackedKeyCode) {
                // Apply options
                if (preventDefault) e.preventDefault();
                if (stopPropagation) e.stopPropagation();

                // Update state
                if (!e.repeat) setKeyPressed(true);
            }
        };

        const handleKeyup = (e: KeyboardEvent): void => {
            if (e.keyCode === trackedKeyCode) {
                // Apply options
                if (preventDefault) e.preventDefault();
                if (stopPropagation) e.stopPropagation();

                // Update state
                setKeyPressed(false);
            }
        };

        if (disabled) {
            window.removeEventListener("keyup", handleKeyup);
            window.removeEventListener("keydown", handleKeydown);

            setKeyPressed(false);
            return;
        } else {
            window.addEventListener("keydown", handleKeydown);
            window.addEventListener("keyup", handleKeyup);
        }

        // destructor removes event listeners
        return (): void => {
            window.removeEventListener("keyup", handleKeyup);
            window.removeEventListener("keydown", handleKeydown);
        };
    }, [disabled]);

    if (disabled) {
        return false;
    }

    return keyPressed;
}
