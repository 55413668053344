var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// react
import * as React from "react";
import { useEffect, useState } from "react";
// mui
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { IconKinovaLogo } from "../icons";
import { theme } from "../mui/theme";
import { rootDefaults } from "../mui/rootDefaults";
var useStyles = makeStyles({
    root: __assign(__assign({}, rootDefaults), { display: "flex", flexDirection: "column", flexGrow: 1, backgroundColor: theme.palette.common.white, width: "100%" }),
    menuHeader: {
        display: "flex",
        flexDirection: "row",
        height: "64px",
        width: "100%",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
    },
    list: {
        width: "100%",
        backgroundColor: theme.palette.common.white,
    },
    regularItem: {
        backgroundColor: "transparent",
        color: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
        "&$selectedItem": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            "&$selectedItem:hover": {
                backgroundColor: theme.palette.secondary.main,
            },
            "&$selectedItem": {
                backgroundColor: theme.palette.primary.main,
            },
        },
        height: "50px",
        borderBottom: "1px solid ".concat(theme.palette.grey[200]),
    },
    selectedItem: {},
    icon: {
        color: "inherit",
        marginRight: 0,
    },
    iconContainer: {
        color: theme.palette.common.white,
        margin: "16px",
        padding: "0px",
    },
    label: {
        color: "inherit",
        whiteSpace: "pre",
    },
    menuVersion: {
        color: theme.palette.primary.main,
        padding: "10px",
    },
    subSpacer: {
        width: "16px",
    },
    subItem: {
        backgroundColor: theme.palette.grey[200],
    },
    logoContainer: {
        cursor: "pointer",
        height: "25px",
        minWidth: "180px",
    },
    verticalSpace: {
        flexGrow: 1,
    },
});
function KortexSideMenu(props) {
    var classes = useStyles(props);
    var configList = props.configList, currentPage = props.currentPage, onChangeMenuItem = props.onChangeMenuItem, onCloseMenu = props.onCloseMenu, onGoToHomePage = props.onGoToHomePage, version = props.version;
    var _a = useState(""), subOpen = _a[0], setSubOpen = _a[1];
    /* Here, we check if we find the page in a menu,
     * if so, we simply open the menu item and highligh
     * the sub menu item
     */
    useEffect(function () {
        var findPage = currentPage.replace("/", ""); // Remove leading slash
        if (findPage) {
            configList.map(function (menuItem) {
                if (menuItem.children) {
                    menuItem.children.map(function (subItem) {
                        if (subItem.id === findPage) {
                            setSubOpen(menuItem.id); // If found, set the subOpen to the parent
                        }
                    });
                }
            });
        }
    }, [configList, currentPage]);
    /**
     * This function simply call the corresponding callbacks
     *
     * @param {string} path - contains the route path
     */
    var handleRouteUpdate = function (path) {
        return function () {
            onChangeMenuItem(path);
        };
    };
    /**
     * Callback when the user click on a menu item that contains child
     *
     * @param {IMenuItem} menuId - contains the route path
     */
    var handleMenuClick = function (menuId) {
        return function () {
            if (subOpen === menuId.id) {
                setSubOpen("");
            }
            else {
                setSubOpen(menuId.id);
            }
        };
    };
    /**
     * Calling close menu callback
     */
    var handleDrawerToggle = function () {
        onCloseMenu();
    };
    /**
     * Calling go to home callback
     */
    var handleGoToHomePage = function () {
        if (onGoToHomePage) {
            onGoToHomePage();
        }
    };
    return (React.createElement("div", { className: classes.root, id: "mainMenuPanelId" },
        React.createElement("div", { className: classes.menuHeader },
            React.createElement(IconButton, { id: "menuButtonId", className: classes.iconContainer, "aria-label": "Menu", onClick: handleDrawerToggle },
                React.createElement(CloseIcon, null)),
            React.createElement("div", { id: "testGoToHomeId", className: classes.logoContainer, onClick: handleGoToHomePage },
                React.createElement(IconKinovaLogo, { fill: theme.palette.common.white, height: 25, width: 148 }))),
        React.createElement(List, { disablePadding: true, className: classes.list }, configList.map(function (menuItem) {
            if (!menuItem.hideFromMenu) {
                return (React.createElement("div", { key: menuItem.id },
                    React.createElement(ListItem, { id: "".concat(menuItem.id, "Id"), key: menuItem.id, button: true, selected: currentPage === "/".concat(menuItem.id), "data-menu-item-id": menuItem.id, onClick: !menuItem.children ? handleRouteUpdate("/".concat(menuItem.id)) : handleMenuClick(menuItem), classes: {
                            root: classes.regularItem,
                            selected: classes.selectedItem,
                        } },
                        React.createElement(ListItemIcon, { className: classes.icon }, menuItem.icon),
                        React.createElement(ListItemText, { classes: { primary: classes.label } }, menuItem.label),
                        menuItem.children ? (subOpen === menuItem.id ? (React.createElement(ExpandLess, { className: classes.icon })) : (React.createElement(ExpandMore, { className: classes.icon }))) : null),
                    // If parent, expand sub menu
                    menuItem.children ? (React.createElement(List, { disablePadding: true, className: classes.subItem },
                        React.createElement(Collapse, { in: subOpen === menuItem.id, timeout: "auto", unmountOnExit: true }, menuItem.children.map(function (subItem) {
                            return (React.createElement(ListItem, { id: "".concat(subItem.id, "Id"), key: subItem.id, button: true, selected: currentPage === "/".concat(subItem.id), "data-menu-item-id": subItem.id, onClick: handleRouteUpdate("/".concat(subItem.id)), classes: {
                                    root: classes.regularItem,
                                    selected: classes.selectedItem,
                                } },
                                React.createElement("div", { className: classes.subSpacer }),
                                React.createElement(ListItemIcon, { className: classes.icon }, subItem.icon),
                                React.createElement(ListItemText, { classes: { primary: classes.label } }, subItem.label)));
                        })))) : null));
            }
            else {
                return undefined;
            }
        })),
        React.createElement("div", { className: classes.verticalSpace }),
        version && (React.createElement("div", { id: "versionStringId", className: classes.menuVersion }, version))));
}
export default KortexSideMenu;
