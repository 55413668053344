import {
    ITaskInfoProcessApproval,
    IUserDbModel,
    IUserGroupDbModel,
    ProcessAction,
    ProcessActionStep,
    ProcessUiModel,
    TTask,
    TaskTypeEnum,
    TreeNodeDbModel,
} from "@kortex/aos-common";

export const pageWidth = 1122;
export const pageHeight = 794;
export const pageRatio = pageWidth / pageHeight;
export const pageMargin = 20;

export interface IRevisionHistoryTableProps {
    startIndex: number;
    howMany: number;
}

export interface IPrintProcessDocumentDynamicSections {
    tableOfContent: string[];
    revisionHistoryPages: IRevisionHistoryTableProps[];
}

export interface IPrintProcessDocumentProps {
    processInfo?: ProcessUiModel;
    treeNode?: TreeNodeDbModel;
    processVersions?: ProcessUiModel[];
    selectedVersionId?: number;
    dynamicSections?: IPrintProcessDocumentDynamicSections;
    userList: IUserDbModel[];
    userGroups: IUserGroupDbModel[];
    tasks: TTask<TaskTypeEnum.PROCESS_APPROVAL, ITaskInfoProcessApproval>[];
}

export const fontStyle: React.CSSProperties = {
    fontFamily: "Helvetica",
};

export function pageY(pageIndex: number): number {
    return pageMargin + (pageMargin + pageHeight) * pageIndex;
}

export function extractStepNameList(actions: ProcessAction[]): string[] {
    const stepLabels: string[] = [];

    for (const action of actions.filter((actionItem): boolean => actionItem.type === "core-work-instructions")) {
        for (const step of action.steps) {
            stepLabels.push((step as ProcessActionStep).label);
        }
    }

    return stepLabels;
}
