import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    ReworkLogAllReq,
    ReworkLogAllRes,
    ReworkLogInsertReq,
    ReworkLogInsertRes,
    ReworkLogInsertedNotif,
    ReworkLogOneReq,
    ReworkLogOneRes,
    ReworkLogUpdateReq,
    ReworkLogUpdateRes,
    ReworkLogUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { reworkLogAll } from "./all";
import { reworkLogOne } from "./one";
import { reworkLogInsert } from "./insert";
import { reworkLogUpdate } from "./update";
import { reworkLogInserted } from "./inserted";
import { reworkLogUpdated } from "./updated";

interface IAOSClientServiceWrappedReworkLog {
    getAll: AOSClientServiceRequestWrapped<ReworkLogAllReq, ReworkLogAllRes>;
    getOne: AOSClientServiceRequestWrapped<ReworkLogOneReq, ReworkLogOneRes>;
    insert: AOSClientServiceRequestWrapped<ReworkLogInsertReq, ReworkLogInsertRes>;
    update: AOSClientServiceRequestWrapped<ReworkLogUpdateReq, ReworkLogUpdateRes>;
    onInserted: AOSClientServiceNotificationWrapped<ReworkLogInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<ReworkLogUpdatedNotif>;
}

type AOSClientServiceWrappedReworkLog = IAOSClientServiceWrappedReworkLog;

export function generateReworkLogServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedReworkLog {
    return {
        getAll: AOSClientServiceWrapperUI.request(reworkLogAll(router)),
        getOne: AOSClientServiceWrapperUI.request(reworkLogOne(router)),
        insert: AOSClientServiceWrapperUI.request(reworkLogInsert(router)),
        update: AOSClientServiceWrapperUI.request(reworkLogUpdate(router)),
        onInserted: AOSClientServiceWrapperUI.notification(reworkLogInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(reworkLogUpdated(router, notifier)),
    };
}

generateReworkLogServiceClient.serviceId = 32;
