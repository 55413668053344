var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function ElseIf(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("polygon", { id: "icons-else-if", transform: "translate(17.115909, 6.973701) rotate(45) translate(-17.115909, -6.973701) ", points: "14.2592443 4.02415036 16.6776409 6.44254703 13.6361639 9.48402406 15.135781 10.9836412 18.1772581 7.94216418 20.5956547 10.3605608 20.5956547 4.02415036" })));
}
export default ElseIf;
