export function defer(executor) {
    let done = false;
    let resolve = () => new Error("promise not defined");
    let reject = () => new Error("promise not defined");
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
        return executor(resolve, reject);
    }).finally(() => {
        done = true;
    });
    return {
        get done() {
            return done;
        },
        get promise() {
            return promise;
        },
        resolve,
        reject,
    };
}
