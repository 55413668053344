export const general = {
    rpc: {
        connectionList: {
            funcId: 1,
            funcName: "connectionList",
        },
        connectionListChanged: {
            funcId: 2,
            funcName: "connectionListChanged",
        },
        translate: {
            funcId: 3,
            funcName: "translate",
        },
        desktopUpdateAvailable: {
            funcId: 4,
            funcName: "desktopUpdateAvailable",
        },
        desktopStartUpdate: {
            funcId: 5,
            funcName: "desktopStartUpdate",
        },
        log: {
            funcId: 6,
            funcName: "log",
        },
    },
    serviceId: 1,
    serviceVersion: 1,
};
