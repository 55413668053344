import { IFailureTypeDbModel, IFailureSubTypeDbModel } from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export enum FailureActionType {
    FAILURE_TYPE_FETCH_ALL = "@@FAILURE/TYPE/FETCH/ALL",
    FAILURE_TYPE_NOTIFICATION_INSERTED = "@@FAILURE/TYPE/NOTIFICATION/INSERTED",
    FAILURE_TYPE_NOTIFICATION_UPDATED = "@@FAILURE/TYPE/NOTIFICATION/UPDATED",
    FAILURE_SYMPTOM_FETCH_ALL = "@@FAILURE/SYMPTOM/FETCH/ALL",
    FAILURE_SYMPTOM_NOTIFICATION_INSERTED = "@@FAILURE/SYMPTOM/NOTIFICATION/INSERTED",
    FAILURE_SYMPTOM_NOTIFICATION_UPDATED = "@@FAILURE/SYMPTOM/NOTIFICATION/UPDATED",
}

interface IFailureState {
    symptoms: IFailureSubTypeDbModel[];
    types: IFailureTypeDbModel[];
}

export type FailureState = IFailureState;

export type failureTypesInsertedAction = ComplexAction<FailureActionType.FAILURE_TYPE_NOTIFICATION_INSERTED, IFailureTypeDbModel[]>;
export type failureTypesUpdatedAction = ComplexAction<FailureActionType.FAILURE_TYPE_NOTIFICATION_UPDATED, IFailureTypeDbModel[]>;
export type failureSymptomsInsertedAction = ComplexAction<
    FailureActionType.FAILURE_SYMPTOM_NOTIFICATION_INSERTED,
    IFailureSubTypeDbModel[]
>;
export type failureSymptomsUpdatedAction = ComplexAction<FailureActionType.FAILURE_SYMPTOM_NOTIFICATION_UPDATED, IFailureSubTypeDbModel[]>;

export type setFailureTypesListAction = ComplexAction<FailureActionType.FAILURE_TYPE_FETCH_ALL, IFailureTypeDbModel[]>;
export type setFailureSymptomsListAction = ComplexAction<FailureActionType.FAILURE_SYMPTOM_FETCH_ALL, IFailureSubTypeDbModel[]>;

export type FailureActions =
    | failureTypesInsertedAction
    | failureTypesUpdatedAction
    | failureSymptomsInsertedAction
    | failureSymptomsUpdatedAction
    | setFailureTypesListAction
    | setFailureSymptomsListAction;
