import { IBomFollowUp, IWoBomComplete } from "@kortex/aos-common";

import { BareAction, ComplexAction } from "../app.types";

export interface IBomState {
    boms: IWoBomComplete[];
}

export enum BomActionType {
    // General
    CLEAR = "@@BOM/CLEAR",
    // Bom
    SET_BOMS = "@@BOM/SET_BOMS",
    // Item
    EDIT_ITEM = "@@BOM/EDIT_ITEM",
    INSERT_ITEM = "@@BOM/INSERT_ITEM",
    MULTIPLE_TRACEABILITIES_ITEM = "@@BOM/MULTIPLE_TRACEABILITIES_ITEM",
    OVERCONSUME_ITEM = "@@BOM/OVERCONSUME_ITEM",
    REPLACE_ITEM = "@@BOM/REPLACE_ITEM",
    // Serialized item
    INSERT_SERIALIZED_ITEM = "@@BOM/INSERT_SERIALIZED_ITEM",
}

export type BomState = IBomState;

// General
export type BomsClearAction = BareAction<BomActionType.CLEAR>;
// Bom
export type BomSetAction = ComplexAction<BomActionType.SET_BOMS, IWoBomComplete[]>;
// Item
export type BomItemEditedAction = ComplexAction<BomActionType.EDIT_ITEM, IBomFollowUp>;
export type BomItemInsertedAction = ComplexAction<BomActionType.INSERT_ITEM, IBomFollowUp>;
export type BomItemMultipleTraceabilitiesAction = ComplexAction<BomActionType.MULTIPLE_TRACEABILITIES_ITEM, IBomFollowUp[]>;
export type BomItemOverconsumedAction = ComplexAction<BomActionType.OVERCONSUME_ITEM, IBomFollowUp>;
export type BomItemReplacedAction = ComplexAction<BomActionType.REPLACE_ITEM, IBomFollowUp[]>;
// Item Serialized
export type BomSerializedItemInsertedAction = ComplexAction<BomActionType.INSERT_SERIALIZED_ITEM, IBomFollowUp>;

export type BomActions =
    | BomsClearAction
    | BomSetAction
    | BomItemEditedAction
    | BomItemInsertedAction
    | BomItemMultipleTraceabilitiesAction
    | BomItemOverconsumedAction
    | BomItemReplacedAction
    | BomSerializedItemInsertedAction;
