export const failureTypes = {
    rpc: {
        getOne: {
            funcId: 7000,
            funcName: "failureTypesGetOne",
        },
        getAll: {
            funcId: 7001,
            funcName: "failureTypesGetAll",
        },
        insert: {
            funcId: 7002,
            funcName: "failureTypesInsert",
        },
        update: {
            funcId: 7003,
            funcName: "failureTypesUpdate",
        },
        inserted: {
            funcId: 7005,
            funcName: "failureTypesInserted",
        },
        updated: {
            funcId: 7006,
            funcName: "failureTypesUpdated",
        },
    },
    serviceId: 70,
    serviceVersion: 1,
};
