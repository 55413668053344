import {
    WorkScheduleActionType,
    JobProcessesUpdatedAction,
    SetJobProcessesListAction,
    JobProcessesInsertedAction,
    ClearWorkScheduleAction,
    JobProcessesDeletedAction,
    UpdateJobProcessesListAction,
} from "./work-schedule-types";

/**
 * Returns an action
 *
 */
export function clearWorkScheduleAction(): ClearWorkScheduleAction {
    return { type: WorkScheduleActionType.CLEAR_WORK_Schedule };
}

/**
 * Returns an action
 *
 * @param {JobProcessesInsertedAction} jobProcesses - value of the action
 */
export function jobProcessesInsertedAction(jobProcesses: JobProcessesInsertedAction["value"]): JobProcessesInsertedAction {
    return { type: WorkScheduleActionType.INSERT_JOB_PROCESS, value: jobProcesses };
}

/**
 * Returns an action
 *
 * @param {JobProcessesDeletedAction} jobProcesses - value of the action
 */
export function jobProcessesDeletedAction(jobProcesses: JobProcessesInsertedAction["value"]): JobProcessesDeletedAction {
    return { type: WorkScheduleActionType.DELETE_JOB_PROCESS, value: jobProcesses };
}

/**
 * Returns an action
 *
 * @param {JobProcessesUpdatedAction} jobProcesses - value of the action
 */
export function jobProcessesUpdatedAction(jobProcesses: JobProcessesUpdatedAction["value"]): JobProcessesUpdatedAction {
    return { type: WorkScheduleActionType.UPDATE_JOB_PROCESS, value: jobProcesses };
}

/**
 * Returns an action
 *
 * @param {SetJobProcessesListAction} jobProcesses - value of the action
 */
export function setJobProcessListAction(jobProcesses: SetJobProcessesListAction["value"]): SetJobProcessesListAction {
    return { type: WorkScheduleActionType.UPDATE_JOB_PROCESSES, value: jobProcesses };
}

/**
 * Returns an action
 *
 * @param {UpdateJobProcessesListAction} jobProcesses - value of the action
 */
export function updateJobProcessListAction(jobProcesses: UpdateJobProcessesListAction["value"]): UpdateJobProcessesListAction {
    return { type: WorkScheduleActionType.UPDATE_JOB_PROCESS_LIST, value: jobProcesses };
}
