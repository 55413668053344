import { en as kortexSequencerLocaleEn } from "@aos/process-sequencer";
import { KEY_ERROR_LOCALE_EN } from "@kortex/aos-common";

import { ILocalIntl } from "./ILocale";

export const en: ILocalIntl = {
    ...kortexSequencerLocaleEn,
    ...KEY_ERROR_LOCALE_EN,

    "globals.appname": "KINOVA",

    en: "English",
    fr: "French",

    // GENERAL
    "general.general": "General",
    "general.archive": "Archive",
    "general.add": "Add",
    "general.unarchive": "Unarchive",
    "general.cancel": "Cancel",
    "general.date": "Date",
    "general.new": "New",
    "general.resume": "Resume",
    "general.proceed": "Proceed",
    "general.language": "Language",
    "general.save": "Save",
    "general.select": "Select",
    "general.clear": "Clear",
    "general.clearAll": "Clear All",
    "general.delete": "Delete",
    "general.deleteAll": "Delete All",
    "general.yes": "Yes",
    "general.no": "No",
    "general.na": "N/A",
    "general.close": "Close",
    "general.finish": "Finish",
    "general.seconds": "Seconds",
    "general.minutes": "Minutes",
    "general.hours": "Hours",
    "general.days": "Days",
    "general.weeks": "Weeks",
    "general.months": "Months",
    "general.years": "Years",
    "general.update": "Update",
    "general.purge": "Purge",
    "general.none": "None",
    "general.id": "Id",
    "general.pass": "Pass",
    "general.fail": "Fail",
    "general.create": "Create",
    "general.true": "True",
    "general.false": "False",
    "general.warning": "Warning",
    "general.electronicSignatureConsent":
        "By executing this electronic signature, I agree and understand that it is the legal equivalent of my handwritten signature and I consent to be legally bound to this agreement. I further agree my signature on this document is as valid as if I signed the document in writing.",
    "general.confirm": "Confirm",
    "general.selectAll": "Select All",
    "general.search": "Search",
    "general.searchUser": "Search User",
    "general.selectUser": "Select users to add in the training",
    "general.userName": "User Name",
    "general.process": "Process",
    "general.processes": "Processes",
    "general.from": "From",
    "general.to": "To",
    "general.createdOn": "Creation Date",
    "general.clearFilters": "Clear Filters",
    "general.required": "Required",

    // ACCESS LEVEL
    "accessLevel.write": "Write",
    "accessLevel.read": "Read",
    "accessLevel.none": "None",
    "accessLevel.authenticated": "Authenticated",
    "accessLevel.insert": "Insert",
    "accessLevel.archive": "Archive",

    // UNITS
    "units.short.minutes": "min",
    "units.short.seconds": "sec",

    /**
     * APP LAYOUT
     */
    "appLayout.updateAndRestart": "Update and restart",
    "appLayout.reconnecting": "Reconnecting...",
    "appLayout.updateAvailable": "A new update is now ready to install.",

    /**
     * FUNCTION - CONDITIONS
     */
    // GENERAL
    "action.condition.delete": "Delete",
    "action.condition.logical": "Logical",
    "action.condition.logical.and": "AND",
    "action.condition.logical.or": "OR",
    "action.condition.preview": "Preview",
    // EXPERT MODE
    "action.condition.expert": "Expert mode",
    "action.condition.expert.condition": "Condition",
    // SIMPLIFIED MODE
    "action.condition.simplified": "Simplified mode",
    "action.condition.simplified.comparison": "Comparison",
    "action.condition.simplified.comparison.equals": "equals",
    "action.condition.simplified.comparison.notEqual": "not equal",
    "action.condition.simplified.comparison.greaterThan": "greater than",
    "action.condition.simplified.comparison.lessThan": "less than",
    "action.condition.simplified.comparison.greaterThanOrEqualTo": "greater than or equal to",
    "action.condition.simplified.comparison.lessThanOrEqualTo": "less than or equal to",
    "action.condition.simplified.leftOperand": "Left operand",
    "action.condition.simplified.rightOperand": "Right operand",
    "action.condition.simplified.typeMismatch": "Condition and variable types do not match",
    "action.condition.simplified.type": "Type",
    "action.condition.simplified.type.date": "Date",
    "action.condition.simplified.type.numeric": "Numeric",
    "action.condition.simplified.type.text": "Text",
    "action.condition.simplified.type.time": "Time",

    /**
     * FUNCTION - LOOP
     */
    "action.loop.conditions": "Conditions",
    "action.loop.configurations": "Configurations",
    "action.loop.incrementVariable": "Increment variable",
    "action.loop.alwaysDoOnce": "Always do once",
    "action.loop.resetVariable": "Reset variable on first loop",
    "action.loop.preview": "Preview (Will loop if condition is TRUE)",

    /**
     * FUNCTION - ROUTING PROCESS
     */
    "action.routingProcess.process": "Process",
    "action.routingProcess.processVersion": "Process Version",
    "action.routingProcess.selectProcess": "Select a process",
    "action.routingProcess.selectVersion": "Select a version",
    "action.routingProcess.checkPrevious": "Check previous process completion (if any)",

    /**
     * FUNCTION - CONNECTOR
     */
    // GENERAL
    "action.connector.failOnCommandFailure": "Fail process if command fails",
    "action.connector.storeTo": "Store to",
    "action.connector.storeConsoleOutputTo": "Store console output to",
    "action.connector.type": "Type",
    "action.connector.type.file": "File",
    "action.connector.type.rest": "REST",
    "action.connector.type.shell": "Shell",
    // FILE
    "action.connector.file.action": "Action",
    "action.connector.file.action.read": "Read",
    "action.connector.file.action.read.fileType": "File type",
    "action.connector.file.action.read.fileType.binary": "Binary",
    "action.connector.file.action.read.fileType.text": "Text",
    "action.connector.file.action.write": "Write",
    "action.connector.file.action.write.data": "Data",
    "action.connector.file.filePath": "File path",

    // REST
    "action.connector.rest.contentType": "Content type",
    "action.connector.rest.key": "Key",
    "action.connector.rest.method": "Method",
    "action.connector.rest.method.delete": "DELETE",
    "action.connector.rest.method.get": "GET",
    "action.connector.rest.method.post": "POST",
    "action.connector.rest.method.put": "PUT",
    "action.connector.rest.params.body": "Body",
    "action.connector.rest.params.header": "Header",
    "action.connector.rest.params.headers": "Headers",
    "action.connector.rest.params.query": "Query",
    "action.connector.rest.params.queries": "Queries",
    "action.connector.rest.requestResponseType.appJSON": "Application/JSON",
    "action.connector.rest.requestResponseType.appJSONRaw": "Application/JSON-Raw",
    "action.connector.rest.requestResponseType.appXML": "Application/XML",
    "action.connector.rest.responseType": "Response type",
    "action.connector.rest.url": "URL",
    "action.connector.rest.value": "Value",

    // INPUT
    "action.input.general": "General",
    "action.input.enabled": "Enabled",
    "action.input.disabled": "Disabled",
    "action.input.type": "Type",
    "action.input.trackingId": "Tracking ID",
    "action.input.referenceId": "Reference ID",
    "action.input.allowBatch": "Allow Batch",
    "action.input.schedulerPlay": "Scheduler Play",
    "action.input.manualPlay": "Manual Play",
    "action.input.regexValidation": "Validation Regex",
    "action.input.regexTest": "Test Value",
    "action.input.regexTestButton": "TEST REGEX",
    "action.input.manualEntry": "Manual Entry",
    "action.input.autoGenerated": "Auto Generated",
    "action.input.trackingIdLabel": "Label",
    "action.input.prepopulatedValue": "Pre-populated value",
    "action.input.prepopulatedValueLock": "Lock",
    "action.input.prepopulatedValueError": "The field will be locked, but there is no pre-populated value",
    "action.input.referenceIdLabel": "Label",
    "action.input.yes": "Yes",
    "action.input.no": "No",
    "action.input.batchProcessing": "Batch Processing",
    "action.input.enableBatchProcessing": "Enable Batch Processing",
    "action.input.maxBatchQty": "Max. Batch Qty",
    "action.input.quantity": "Quantity",
    "action.input.regex.nomatch": "No match",
    "action.input.regex.invalid": "Invalid regex",
    "action.input.job": "Job",

    // SHELL
    "action.connector.shell.command": "Command",
    "action.connector.shell.workingDirectory": "Working directory",

    /**
     * FUNCTION - EDITOR
     */
    "action.editor.addStep": "Add step",
    "action.editor.copy": "Copy",
    "action.editor.delete": "Delete",
    "action.editor.edit": "Edit",
    "action.editor.read": "Read",
    "action.editor.paste": "Paste",
    "action.editor.redo": "Redo",
    "action.editor.undo": "Undo",
    "action.editor.actionEditor": "Action Editor",
    "action.editor.untitled": "Untitled",

    /**
     * FUNCTION - STEP EDITOR
     */
    "action.stepEditor.electronicSignatureApproval": "Electronic Signature Approval",
    "action.stepEditor.electronicSignatureApprovalContext": "Approval Agreement Text",
    "action.stepEditor.electronicSignatureApprovalContext.error": "Text length must be greater than 10 characters",
    "action.stepEditor.autoPlayNext": "Play the next step when finished",
    "action.stepEditor.cancel": "Cancel",
    "action.stepEditor.createAnother": "Create another",
    "action.stepEditor.label": "Label",
    "action.stepEditor.label.error": "Label must have at least 3 characters.",
    "action.stepEditor.minimumTime": "Minimum time",
    "action.stepEditor.none": "None",
    "action.stepEditor.save": "Save",
    "action.stepEditor.targetTime": "Target time",
    "action.stepEditor.title": "Step settings",
    "action.stepEditor.canRetry": "Can retry",
    "action.stepEditor.continueIfFail": "Continue if fail",
    "action.stepEditor.validationGroup": "Validation group",

    /**
     * MATH - EDITOR
     */
    "action.math.equation": "Equation",
    "action.math.storeTo": "Store to",

    /**
     * MESSAGE - EDITOR
     */
    "action.message.message": "Message",
    "action.message.storeTo": "Store to",
    "action.message.level": "Message Level",
    "action.message.error": "Error",
    "action.message.informative": "Informative",
    "action.message.warning": "Warning",

    /**
     * SET - EDITOR
     */
    "action.set.value": "Value",
    "action.set.storeTo": "Store to",

    /**
     * FUNCTION - WORK INSTRUCTIONS
     */
    // STEP
    "action.workInstructions.stepModifier.trainingCommunique": "A training communiqué is assigned to this step.",
    // BOM
    "action.workInstructions.bom.addToStep": "Add to Step",
    "action.workInstructions.bom.bom": "BOM",
    "action.workInstructions.bom.bomLoadError": "An error occurred while loading the BOM.",
    "action.workInstructions.bom.information": "Information",
    "action.workInstructions.bom.itemBom": "BOM Items",
    "action.workInstructions.bom.itemStepBom": "Step Items",
    "action.workInstructions.bom.itemBubbleNumber": "#",
    "action.workInstructions.bom.itemPartNumber": "Part Number",
    "action.workInstructions.bom.quantityItemBom": "Quantity",
    "action.workInstructions.bom.revision": "Rev:",
    "action.workInstructions.bom.serialize": "Serialize",
    "action.workInstructions.bom.deserialize": "Deserialize",
    "action.workInstructions.bom.value": "Value",
    // ELEMENT TOOLBAR
    "action.workInstructions.toolbar.form": "Form",
    "action.workInstructions.toolbar.image": "Image",
    "action.workInstructions.toolbar.line": "Line",
    "action.workInstructions.toolbar.marker": "Marker",
    "action.workInstructions.toolbar.mobile": "Mobile",
    "action.workInstructions.toolbar.pdf": "PDF",
    "action.workInstructions.toolbar.preview": "Preview",
    "action.workInstructions.toolbar.saveTemplate": "Save",
    "action.workInstructions.toolbar.selectTemplate": "Select",
    "action.workInstructions.toolbar.shape": "Shape",
    "action.workInstructions.toolbar.text": "Text",
    "action.workInstructions.toolbar.communique": "Communiqué",
    "action.workInstructions.toolbar.label": "Label",
    "action.workInstructions.toolbar.video": "Video",
    // ELEMENT MENU
    "action.workInstructions.elementMenu.back": "Send to back",
    "action.workInstructions.elementMenu.backward": "Move backward",
    "action.workInstructions.elementMenu.delete": "Delete",
    "action.workInstructions.elementMenu.edit": "Edit",
    "action.workInstructions.elementMenu.forward": "Move forward",
    "action.workInstructions.elementMenu.front": "Bring to front",
    // EDIT DIALOG (GENERAL)
    "action.workInstructions.editDialog.cancel": "Cancel",
    "action.workInstructions.editDialog.confirm": "Confirm",
    "action.workInstructions.editDialog.save": "Save",
    // FORM
    "action.workInstructions.form.approvalGroupId": "Approval group",
    "action.workInstructions.form.emptySelection": "Select an element to edit",
    "action.workInstructions.form.label": "Label",
    "action.workInstructions.form.list": "List",
    "action.workInstructions.form.storeTo": "Store to",
    "action.workInstructions.form.title": "Form settings",
    "action.workInstructions.form.format": "Format (regex)",
    "action.workInstructions.form.formatErrorMessage": "Format error message",
    "action.workInstructions.form.required": "Required",
    "action.workInstructions.form.defaultValue": "Default Value",
    "action.workInstructions.form.multiline": "Multiline",
    "action.workInstructions.form.inputType": "Input type",
    "action.workInstructions.form.inputType.approval": "Approval",
    "action.workInstructions.form.inputType.checkbox": "Checkbox",
    "action.workInstructions.form.inputType.checklist": "Checklist",
    "action.workInstructions.form.inputType.dropdown": "Dropdown",
    "action.workInstructions.form.inputType.label": "Label",
    "action.workInstructions.form.inputType.radio": "Radio button",
    "action.workInstructions.form.inputType.textbox": "Textbox",
    "action.workInstructions.form.inputType.textboxlist": "Textbox List",
    "action.workInstructions.form.error.required": "This field is required",
    "action.workInstructions.form.error.invalidFormat": "Invalid format",
    "action.workInstructions.form.error.invalidRegexError": "Invalid regex",
    "action.workInstructions.form.error.minItems": "Not enough items. The minimum is: ",
    "action.workInstructions.form.error.maxItems": "Too many items. The maximum is: ",
    "action.workInstructions.form.tabIndex": "Tab index",
    "action.workInstructions.form.formItemLabel": "Form item label",
    "action.workInstructions.form.minItems": "Minimum items",
    "action.workInstructions.form.maxItems": "Maximum items",
    // IMAGE
    "action.workInstructions.image.title": "Image selection",
    "action.workInstructions.image.select": "Select",
    "action.workInstructions.image.cancel": "Cancel",
    "action.workInstructions.image.zoom": "Zoom",
    "action.workInstructions.image.editTooltip": "Edit tooltip",
    "action.workInstructions.image.tooltipEditor.title": "Edit tooltip",
    "action.workInstructions.image.tooltipEditor.originalTooltip": "Original tooltip",
    "action.workInstructions.image.tooltipEditor.customTooltip": "Custom tooltip",
    // LINE
    "action.workInstructions.line.color": "Color",
    "action.workInstructions.line.dash": "Dash",
    "action.workInstructions.line.end": "End",
    "action.workInstructions.line.start": "Start",
    "action.workInstructions.line.thickness": "Thickness",
    "action.workInstructions.line.title": "Line settings",
    // MARKER
    "action.workInstructions.marker.arrowCount": "Arrows",
    "action.workInstructions.marker.backgroundColor": "Background color",
    "action.workInstructions.marker.shape": "Shape",
    "action.workInstructions.marker.shape.circle": "Circle",
    "action.workInstructions.marker.shape.rectangle": "Rectangle",
    "action.workInstructions.marker.shape.triangle": "Triangle",
    "action.workInstructions.marker.text": "Text",
    "action.workInstructions.marker.textColor": "Text color",
    "action.workInstructions.marker.title": "Marker settings",
    // PDF
    "action.workInstructions.pdf.allPagesStart": "All (",
    "action.workInstructions.pdf.allPagesEnd": ")",
    "action.workInstructions.pdf.asDocument": "As Document",
    "action.workInstructions.pdf.asSteps": "As Steps",
    "action.workInstructions.pdf.importOptions": "Import Options",
    "action.workInstructions.pdf.title": "PDF selection",
    "action.workInstructions.pdf.select": "Select",
    "action.workInstructions.pdf.cancel": "Cancel",
    "action.workInstructions.pdf.pageTitle": "Pages",
    "action.workInstructions.pdf.page": " page",
    "action.workInstructions.pdf.pages": " pages",
    "action.workInstructions.pdf.error.invalidFormat": "Invalid format",
    "action.workInstructions.pdf.error.invalidRange": "Pages out of bounds",
    // SHAPE
    "action.workInstructions.shape.border": "Border",
    "action.workInstructions.shape.border.none": "None",
    "action.workInstructions.shape.borderColor": "Border color",
    "action.workInstructions.shape.color": "Color",
    "action.workInstructions.shape.title": "Shape settings",
    "action.workInstructions.shape.type": "Type",
    "action.workInstructions.shape.type.arrow": "Arrow",
    "action.workInstructions.shape.type.circle": "Circle",
    "action.workInstructions.shape.type.octagon": "Octagon",
    "action.workInstructions.shape.type.rectangle": "Rectangle",
    "action.workInstructions.shape.type.triangle": "Triangle",
    // TEMPLATE EDITOR
    "action.workInstructions.template.editor.applyToAll": "Apply changes to all steps using this template",
    "action.workInstructions.template.editor.color": "Color",
    "action.workInstructions.template.editor.description": "Description",
    "action.workInstructions.template.editor.descriptionRequired": "A description is required.",
    "action.workInstructions.template.editor.label": "Label",
    "action.workInstructions.template.editor.labelRequired": "A label is required.",
    "action.workInstructions.template.editor.newTemplate": "New template",
    "action.workInstructions.template.editor.overwrite": "Overwrite",
    "action.workInstructions.template.editor.save": "Save",
    "action.workInstructions.template.editor.saveAs": "Save as",
    "action.workInstructions.template.editor.title": "Template settings",
    // TEMPLATE SELECTOR
    "action.workInstructions.template.selector.delete": "Delete",
    "action.workInstructions.template.selector.search": "Search",
    "action.workInstructions.template.selector.select": "Select",
    "action.workInstructions.template.selector.title": "Template selection",
    // TRAINING COMMUNIQUE EDITOR
    "action.workInstructions.player.trainingCommuniqueEditorTitle": "Changes have been applied to this step.",
    "action.workInstructions.toolbar.trainingCommuniqueEditorLabel": "Type the list of changes applied to this step.",
    "action.workInstructions.toolbar.trainingCommuniqueEditorTitle": "Training Communiqué",
    // TEXT
    "action.workInstructions.text.backgroundColor": "Background color",
    "action.workInstructions.text.border": "Border",
    "action.workInstructions.text.border.none": "None",
    "action.workInstructions.text.borderColor": "Border color",
    "action.workInstructions.text.emptyTextError": "Must contain at least 1 character.",
    "action.workInstructions.text.style": "Style",
    "action.workInstructions.text.style.header1": "Header 1",
    "action.workInstructions.text.style.header2": "Header 2",
    "action.workInstructions.text.style.header3": "Header 3",
    "action.workInstructions.text.style.normal": "Normal",
    "action.workInstructions.text.style.small": "Small",
    "action.workInstructions.text.style.title": "Title",
    "action.workInstructions.text.horizontalAlignment": "Horizontal alignment",
    "action.workInstructions.text.horizontalAlignment.center": "Center",
    "action.workInstructions.text.horizontalAlignment.left": "Left",
    "action.workInstructions.text.horizontalAlignment.right": "Right",
    "action.workInstructions.text.padding": "Padding",
    "action.workInstructions.text.padding.none": "None",
    "action.workInstructions.text.text": "Text",
    "action.workInstructions.text.textColor": "Text color",
    "action.workInstructions.text.title": "Text settings",
    "action.workInstructions.text.verticalAlignment": "Vertical alignment",
    "action.workInstructions.text.verticalAlignment.bottom": "Bottom",
    "action.workInstructions.text.verticalAlignment.middle": "Middle",
    "action.workInstructions.text.verticalAlignment.top": "Top",
    // VIDEO
    "action.workInstructions.video.title": "Video selection",
    "action.workInstructions.video.select": "Select",
    "action.workInstructions.video.cancel": "Cancel",

    /**
     * ACTION - PARSER
     */
    "action.parser.json": "JSON",
    "action.parser.jsonPath": "Path",
    "action.parser.jsonReference": "Reference",
    "action.parser.jsonReferenceClipboardMessage": "Copied to clipboard",
    "action.parser.jsonReferenceDescription": "Description",
    "action.parser.jsonReferenceExample": "Example",
    "action.parser.jsonReferenceOperator": "Operator",
    "action.parser.jsonReferenceOperator1": "$",
    "action.parser.jsonReferenceOperator2": "@",
    "action.parser.jsonReferenceOperator3": ".",
    "action.parser.jsonReferenceOperator4": "..",
    "action.parser.jsonReferenceOperator5": "*",
    "action.parser.jsonReferenceOperator6": "[]",
    "action.parser.jsonReferenceOperator7": "[,]",
    "action.parser.jsonReferenceOperator8": "[start:end:step]",
    "action.parser.jsonReferenceOperator9": "?()",
    "action.parser.jsonReferenceOperator10": "()",
    "action.parser.jsonReferenceOperatorDescription1": "The root object/element",
    "action.parser.jsonReferenceOperatorDescription2": "The current object/element",
    "action.parser.jsonReferenceOperatorDescription3": "Child member operator",
    "action.parser.jsonReferenceOperatorDescription4": "Recursive descendant operator",
    "action.parser.jsonReferenceOperatorDescription5": "Wildcard matching all objects/elements regardless their names",
    "action.parser.jsonReferenceOperatorDescription6": "Subscript operator",
    "action.parser.jsonReferenceOperatorDescription7": "Union operator for alternate names or array indices as a set",
    "action.parser.jsonReferenceOperatorDescription8": "Array slice operator",
    "action.parser.jsonReferenceOperatorDescription9": "Applies a filter (script) expression via static evaluation",
    "action.parser.jsonReferenceOperatorDescription10": "Script expression via static evaluation",
    "action.parser.jsonReferencePath": "Path",
    "action.parser.jsonReferencePath1": "$.store.book[*].author",
    "action.parser.jsonReferencePath2": "$..author",
    "action.parser.jsonReferencePath3": "$.store.*",
    "action.parser.jsonReferencePath4": "$.store..price",
    "action.parser.jsonReferencePath5": "$..book[2]",
    "action.parser.jsonReferencePath6": "$..book[(@.length-1)]",
    "action.parser.jsonReferencePath7": "$..book[-1:]",
    "action.parser.jsonReferencePath8": "$..book[0,1]",
    "action.parser.jsonReferencePath9": "$..book[:2]",
    "action.parser.jsonReferencePath10": "$..book[?(@.isbn)]",
    "action.parser.jsonReferencePath11": "$..book[?(@.price<10)]",
    "action.parser.jsonReferencePath12": "$..book[?(@.price==8.95)]",
    "action.parser.jsonReferencePath13": "$..book[?(@.price<30 && @.category=='fiction')]",
    "action.parser.jsonReferencePath14": "$..*",
    "action.parser.jsonReferencePathDescription1": "The authors of all books in the store",
    "action.parser.jsonReferencePathDescription2": "All authors",
    "action.parser.jsonReferencePathDescription3": "All things in store",
    "action.parser.jsonReferencePathDescription4": "The price of everything in the store",
    "action.parser.jsonReferencePathDescription5": "The third book",
    "action.parser.jsonReferencePathDescription6": "The last book (via script subscript)",
    "action.parser.jsonReferencePathDescription7": "The last book (via slice)",
    "action.parser.jsonReferencePathDescription8": "The first two books (via subscript union)",
    "action.parser.jsonReferencePathDescription9": "The first two books (via subscript array slice)",
    "action.parser.jsonReferencePathDescription10": "Filter all books with isbn number",
    "action.parser.jsonReferencePathDescription11": "Filter all books cheaper than 10",
    "action.parser.jsonReferencePathDescription12": "Filter all books that cost 8.95",
    "action.parser.jsonReferencePathDescription13": "Filter all fiction books cheaper than 30",
    "action.parser.jsonReferencePathDescription14": "All members of JSON structure",
    "action.parser.jsonReferenceSyntax": "Syntax",
    "action.parser.jsonTest": "JSON to test",
    "action.parser.jsonTestObject": "JSON object",
    "action.parser.jsonTestResult": "JSON test result",
    "action.parser.source": "Source string",
    "action.parser.regex": "Regex",
    "action.parser.regexEnd": "Regex (End)",
    "action.parser.regexStart": "Regex (Start)",
    "action.parser.regexAction": "Action",
    "action.parser.regexExtract": "Extract",
    "action.parser.regexExtractFirstMatch": "This functionality extracts only the first occurrence that satisfies the regular expressions.",
    "action.parser.regexFlags": "Flags",
    "action.parser.regexFlagsEnd": "Flags (End)",
    "action.parser.regexFlagsStart": "Flags (Start)",
    "action.parser.regexSubstitution": "Substitution",
    "action.parser.regexValidation": "Validation",
    "action.parser.storeTo": "Store to",
    "action.parser.test": "Test",
    "action.parser.type": "Type",

    /**
     * ACTION - TIME
     */
    "action.time.conversion": "Conversion",
    "action.time.convertDateToTimestamp": "Date to timestamp",
    "action.time.convertTimestampToDate": "Timestamp to date",
    "action.time.countdown": "Countdown",
    "action.time.countdownCreated": "A new countdown has been created",
    "action.time.function": "Function",
    "action.time.getDayFromTimestamp": "Day from timestamp",
    "action.time.getHoursFromTimestamp": "Hours from timestamp",
    "action.time.getMinutesFromTimestamp": "Minutes from timestamp",
    "action.time.getMonthFromTimestamp": "Month from timestamp",
    "action.time.getSecondsFromTimestamp": "Seconds from timestamp",
    "action.time.getTimestamp": "Get timestamp",
    "action.time.getYearFromTimestamp": "Year from timestamp",
    "action.time.startTimer": "Start",
    "action.time.stopTimer": "Stop",
    "action.time.storeTo": "Store to",
    "action.time.time": "Time",
    "action.time.timer": "Timer",
    "action.time.timerCreated": "A new timer has been created",
    "action.time.type": "Type",
    "action.time.value": "Value",
    "action.time.wait": "Wait",

    /**
     * ACTION - failureTicketFork
     */
    "action.failureTicketFork.workOrder": "Rework Work Order",

    /**
     * BOM ASSIGNMENT DILAOG
     */
    "bomAssignmentDialog.bomId": "BOM ID",
    "bomAssignmentDialog.bomRev": "BOM Revision",
    "bomAssignmentDialog.selectNodes": "Select the folders and processes that will be updated:",
    "bomAssignmentDialog.unselectAll": "UNSELECT ALL",
    "bomAssignmentDialog.onlyUpdatesDraft": "* This change will only affect draft processes.",

    /**
     * BOM PAGE
     */
    "bomPage.adjustmentType": "Adjustment Type",
    "bomPage.adjustmentType.overconsumption": "Overconsumption",
    "bomPage.adjustmentType.replacement": "Replacement",
    "bomPage.adjustmentType.standard": "Standard",
    "bomPage.adjustmentType.update": "Update",
    "bomPage.bomTable.addTraceability": "Add",
    "bomPage.bomTable.bubbleNumber": "#",
    "bomPage.bomTable.description": "Description",
    "bomPage.bomTable.edit": "Edit",
    "bomPage.bomTable.justification": "Justification",
    "bomPage.bomTable.location": "Location",
    "bomPage.bomTable.materialLocation": "Material Location",
    "bomPage.bomTable.lotSerialType": "Lot/Serial Type",
    "bomPage.bomTable.multipleTraceabilities": "Multiple Traceabilities",
    "bomPage.bomTable.originalTraceability": "Original Traceability",
    "bomPage.bomTable.overconsumption": "Overconsumption",
    "bomPage.bomTable.partNumber": "Part Number",
    "bomPage.bomTable.process": "Process",
    "bomPage.bomTable.processAction": "Action",
    "bomPage.bomTable.processActionStep": "Step",
    "bomPage.bomTable.quantity": "Quantity",
    "bomPage.bomTable.previousQuantity": "Previous Quantity",
    "bomPage.bomTable.newQuantity": "New Quantity",
    "bomPage.bomTable.quantitySum": "The sum of the quantities must be equal to",
    "bomPage.bomTable.replacement": "Replacement",
    "bomPage.bomTable.searchPartNumberOrDescription": "Search a part number or a description",
    "bomPage.bomTable.traceability": "Traceability",
    "bomPage.bomTable.previousTraceability": "Previous Traceability",
    "bomPage.bomTable.serialNumber": "Serial Number",
    "bomPage.bomTable.newTraceability": "New Traceability",
    "bomPage.bomTable.newSerialNumber": "New Serial Number",
    "bomPage.bomTable.history": "History",
    "bomPage.bomTable.type": "Type",
    "bomPage.bomTable.unit": "Unit",
    "bomPage.bomTable.warningItemQuantityExceeded":
        "This item has a quantity greater than indicated in the BOM (overconsumptions not included).",
    "bomPage.bomTable.warningItemQuantityExpected": "Expected:",
    "bomPage.bomTable.warningItemQuantityReceived": "Received:",

    "bomPage.bomTable.editedSerialNumber": "Edited serial number",
    "bomPage.bomTable.editedTraceability": "Edited traceability",
    "bomPage.bomTable.multiTraceability": "Multi Traceability",
    "bomPage.bomTable.overconsumedTraceability": "Overconsumed traceability",
    "bomPage.bomTable.ReplacedSerialNumber": "Replaced serial number",
    "bomPage.bomTable.ReplacedTraceability": "Replaced traceability",
    "bomPage.bomTable.CreatedSerialNumber": "Created serial number",
    "bomPage.bomTable.CreatedTraceability": "Created traceability",
    "bomPage.bomTable.serialNumberDissociated": "Serial number dissociated",
    "bomPage.bomTable.serialNumberReassociated": "Serial number reassociated",

    "bomPage.export": "Export",
    "bomPage.generate": "Generate",
    "bomPage.noData": "No data has been loaded. Select a search type, enter a value and click Generate.",
    "bomPage.nonTraceableItems": "Non-Traceable Items",
    "bomPage.searchType": "Search Type",
    "bomPage.searchType.job": "Job",
    "bomPage.searchType.partNumber": "Part Number",
    "bomPage.searchType.traceability": "Traceability",
    "bomPage.searchType.trackingId": "Tracking ID",
    "bomPage.searchType.adjustmentType": "Type Adjustment",
    "bomPage.traceableItems": "Traceable Items",
    "bomPage.value": "Value",

    /**
     * CORE COMPONENTS
     */
    "coreComponents.languageSelector.selectLanguage": "Select Language",
    "coreComponents.languageSelector.noLanguagesAvailable": "No languages available",

    "coreComponents.KortexTextFieldLocation.close": "Close",
    "coreComponents.KortexTextFieldLocation.mainLanguage": "Main language",
    "coreComponents.KortexTextFieldLocation.save": "Save",
    "coreComponents.KortexTextFieldLocation.secondaryLanguages": "Secondary languages",
    "coreComponents.KortexTextFieldLocation.title": "Translation fields",
    "coreComponents.KortexTextFieldLocation.translateAll": "Translate all",

    /**
     * INFO PAGE
     */
    "info.unknown": "Unknown",
    "info.fullName": "Full Name",
    "info.userName": "Username",
    "info.hub": "Hub",
    "info.runner": "Runner",
    "info.storage": "Storage",
    "info.ui": "UI APP",
    "info.notConnected": "Not Connected",
    "info.connection": "Connection",
    "info.version": "Version",
    "info.host": "Host",

    /**
     * KORTEX-REACT-COMPONENTS
     */
    // COLOR PICKER
    "kortexReactComponent.kortexColorPicker.opacity": "Opacity",

    /**
     * PROCESS LOCALIZATION PAGE
     */
    "processLocalization.actionLabel": "Action label",
    "processLocalization.actionType": "Action type",
    "processLocalization.cancel": "Cancel",
    "processLocalization.componentType": "Component type",
    "processLocalization.default": "(default)",
    "processLocalization.deutsch": "DE",
    "processLocalization.english": "EN",
    "processLocalization.french": "FR",
    "processLocalization.processTrackingLabel": "Process tracking label",
    "processLocalization.textElement": "Text element",
    "processLocalization.save": "Save",
    "processLocalization.stepLabel": "Step label",
    "processLocalization.title": "Process localization",

    /**
     * LOGIN
     */
    "login.advsettings": "Advanced Settings",
    "login.connect": "Connect",
    "login.instance": "Instance",
    "login.language": "Language",
    "login.password": "Password",
    "login.username": "Username",

    /**
     * MAIN MENU
     */
    "menu.bom": "BOM",
    "menu.dashboards": "Dashboards",
    "menu.file.manager": "File Manager",
    "menu.process.editor": "Process & Routing",
    "menu.process.player": "Player",
    "menu.process.scheduler": "Scheduler",
    "menu.process.operatorScheduler": "Work Schedule",
    "menu.reworks": "Failure Tickets",
    "menu.reports": "Reports",
    "menu.tasks": "Tasks",
    "menu.info": "Info",
    "menu.approvals": "Approvals",
    "menu.kanban": "Kanban",
    "menu.settings": "Settings",
    "menu.settings.users": "Users",
    "menu.settings.groups": "Groups",
    "menu.settings.roles": "Roles",
    "menu.settings.tags": "Tags",
    "menu.settings.workzones": "Work Zones",
    "menu.settings.globals": "Global Settings",
    "menu.training": "Training",

    /**
     * PROCESS CHANGELOG
     */
    "processChangelog.emptyMessage": "Aucun changement enregistré jusqu'à présent",

    /**
     * PROCESS TRAINING COMMUNIQUÉ DRAWER
     */
    "processEditor.trainingCommunique.action": "Action",
    "processEditor.trainingCommunique.step": "Step",
    "processEditor.trainingCommunique.stepNumber": "Step #",
    "processEditor.trainingCommunique.version": "Process version",

    /**
     * PROCESS VALIDATION
     */
    "processEditor.validation.action": "Action",
    "processEditor.validation.error": "Error",
    "processEditor.validation.errorFound": "Error(s) found!",
    "processEditor.validation.errorFoundSubmitVersion": "Validation: Error(s) found!",
    "processEditor.validation.na": "N/A",
    "processEditor.validation.processNotFound": "Could not find process...",
    "processEditor.validation.step": "Step",
    "processEditor.validation.success": "Success! No error found!",
    "processEditor.validation.successSubmitVersion": "Validation: Success!",
    "processEditor.validation.validating": "Validating...",
    "processEditor.validation.errorCode.process.inputNotFound": "INPUT not found",
    "processEditor.validation.errorCode.process.outputNotFound": "OUTPUT not found",
    "processEditor.validation.errorCode.process.outputNotFoundFailPath": "OUTPUT not found on fail path",
    "processEditor.validation.errorCode.processAction.emptyField": "Empty field: ",
    "processEditor.validation.errorCode.processAction.invalidActionId": "Could not find the following action: ",
    "processEditor.validation.errorCode.processAction.invalidVariable": "Could not find the following variable: ",
    "processEditor.validation.errorCode.processAction.missingLoopReturnInput": "Missing link on a LOOP",
    "processEditor.validation.errorCode.processAction.missingOutputLink": "Missing link on an action",
    "processEditor.validation.errorCode.processAction.typeMismatch": "Type mismatch: ",
    "processEditor.validation.errorCode.processAction.valueNotValid": "Value is not valid",

    /**
     * PROCESS VARIABLE MANAGER
     */
    "processVariableManager.addVariable": "Add Variable",
    "processVariableManager.appendValue": "Append",
    "processVariableManager.clear": "Clear",
    "processVariableManager.close": "Close",
    "processVariableManager.currentValue": "Current Value",
    "processVariableManager.delete": "Delete",
    "processVariableManager.edit": "Edit",
    "processVariableManager.decimals": "Decimals",
    "processVariableManager.defaultValue": "Default Value",
    "processVariableManager.deleteDisabledMessage": "This variable cannot be deleted since it is used by the following actions:",
    "processVariableManager.invalidIdentifier": "Invalid identifier: it must only contain letters, numbers or underscores.",
    "processVariableManager.reservedIdentifier": "Invalid identifier: SYS_ is reserved for system variables.",
    "processVariableManager.overwriteValue": "Overwrite",
    "processVariableManager.search": "Search",
    "processVariableManager.select": "Select",
    "processVariableManager.title": "Process variable manager",
    "processVariableManager.type": "Type",
    "processVariableManager.type.boolean": "True / False",
    "processVariableManager.type.json": "JSON",
    "processVariableManager.type.number": "Number",
    "processVariableManager.type.string": "Text",
    "processVariableManager.valueFalse": "False",
    "processVariableManager.valueTrue": "True",
    "processVariableManager.variable": "Variable",
    "processVariableManager.variableIdentifier": "Identifier",
    "processVariableManager.whereUsed": "Where Used",
    "processVariableManager.duplicateVariable": "Duplicate variable:",
    "processVariableManager.showSystemVariable": "Show system variables",

    /**
     * PROCESS INFO
     */
    "processInfo.close": "Close",
    "processInfo.processInfo": "Process Info",
    "processInfo.processName": "Process Name",
    "processInfo.standardTime": "Standard Time",
    "processInfo.productReference": "Product Reference",
    "processInfo.processId": "Process Id",
    "processInfo.example": "e.g. item1,item2,item3",
    "processInfo.bom": "BOM",
    "processInfo.bomId": "ID",
    "processInfo.bomRevision": "Revision",

    /**
     * PROCESS ACTION SELECTOR
     */
    "processActionSelector.cancel": "Cancel",
    "processActionSelector.hideInactiveActions": "Hide inactive actions",
    "processActionSelector.select": "Select",
    "processActionSelector.dialogTitle": "Select an action",

    // PROCESS EDITOR SIDE MENU

    // PREVIEW PANE
    "previewPane.noPreviewAvailable": "No Preview Available",

    // PDF PLAYER
    "pdfPlayer.page": "Page ",
    "pdfPlayer.pageOf": " of ",

    // PLAYER
    "player.electronicSignatureRequired": "Electronic Signature Required",
    "player.of": "remaining out of",
    "player.add": "Add",
    "player.addFailures": "Add Failures",
    "player.selectRelatedProcess": "Select the associated process",
    "player.processSteps": "Process steps",
    "player.processCompleted": "Process Successfully Completed!",
    "player.processMessageCircularProgress":
        "Wait until all of the collected data is saved to the database before you can start another run for this process.",

    "player.processStopped": "Process Stopped!",
    "player.processFailed": "Process Failed to Complete!",
    "player.processFailureReport": "Process Failure Report",
    "player.processVersion": "Version",
    "player.processVersionDraft": "Draft",
    "player.manualFailure": "Manual Failure",
    "player.runAgain": "Run Again",
    "player.close": "Close",
    "player.apply": "Apply",
    "player.stop": "Stop",
    "player.runAgainText": "Run process again upon successful completion",
    "player.paused": "Paused",
    "player.step": "Step",
    "player.steps": "Steps",
    "player.send": "Send",
    "player.retry": "Retry",
    "player.report": "Report",
    "player.status": "Status",
    "player.select": "Select",
    "player.type": "Type",
    "player.symptom": "Symptom",
    "player.comments": "Comments",
    "player.conditionPlayer.condition": "Condition",
    "player.conditionPlayer.substitution": "Substitution",
    "player.conditionPlayer.result": "Result",
    "player.mathPlayer.equation": "Equation",
    "player.mathPlayer.substitution": "Substitution",
    "player.mathPlayer.result": "Result",
    "player.parserPlayer.result": "Result",
    "player.inputPlayer.invalidEmpty": "Invalid: Empty value",
    "player.inputPlayer.invalidRegex": "Invalid: REGEX",
    "player.inputPlayer.previousProcessNotRun": "Previous process not run",
    "player.inputPlayer.invalidBatchQty": "Invalid Batch Quantity",
    "player.loop.doOnce": "Do Loop Once",
    "player.loop.maxCount": "Maximum Loop Count",
    "player.loop.count": "Current Loop Count",
    "player.setPlayer.tilte": "Assigning a value to a variable",
    "player.newVersionWarning": "New version released on",
    "player.changeLog": "Change log",
    "player.sign": "Submit Signature",
    "player.stepMessage": "Enter your message",
    "player.user": "User",
    "player.password": "Password",
    "player.infoExecution": "Info",
    "player.addComment": "Comment",
    "player.bomFollowUp": "Follow-Up",
    "player.startExecution": "Start",
    "player.stopExecution": "Stop",
    "player.rewindStep": "Go back",
    "player.nextStep": "Next",
    "player.pauseExecution": "Pause",
    "player.failExecution": "Failure Ticket",
    "player.trainingCommunique": "Training Communique",
    "player.notTrainedForProcess":
        "You are not trained and/or certified for this process. A trainer or a trained and certified operator must accompany you.",
    "player.trainingCompletionExplanation": "An electronic signature is required to complete the training.",
    "player.trainingCompletionTitle": "Training",
    "player.traineeElectronicSignatureRequiredContextTrained": "I confirm that I have read the changes applied to this process.",
    "player.trainerElectronicSignatureRequiredContext":
        "I confirm that I will accompany the operator in training throughout the execution of the process.",
    "player.trainerOrTrainedAndCertifiedOperator": "Trainer or trained and certified operator",
    "player.trainerRequired": "Trained operator required",
    "player.selectTrainedOperator": "Select a user...",
    "player.validate": "Validate",
    "player.validationRequired": "Validation required",
    "player.validationRequiredByTrainedUser": "A trained operator must validate the step.",
    "player.messageDialogTitleEnterMessage": "Process step",
    "player.messageDialogTitleProcessRetryMessage": "Justify the re-execution of the process for this serial number:",
    "player.stopPlayerTitleConfirmation": "Stop Process ?",
    "player.stopPlayerConfirmation": "Are you sure you want to stop the execution of the process?",
    "player.cancel": "Cancel",
    "player.confirm": "Confirm",
    "player.continue": "Continue",
    "player.wrongCredentials": "Wrong credentials",
    "player.wrongPassword": "Wrong password",
    "player.fail": "Fail",
    "player.failureTicketCreate.createFailureTicket": "Creating failure ticket",
    "player.failureTicketCreate.createdJob": "Creating a job",
    "player.failPathTitle": "Fail path",
    "player.failPathMessage": "Continue to finalize the failure.",
    "player.runnerHubFailureDialog.attempt": "The system attempts to retry the operation... attempt #",
    "player.runnerHubFailureDialog.autoRetry": "A system operation could not be completed.",
    "player.runnerHubFailureDialog.autoRetryUnsuccessful": "The system could not complete the operation.",
    "player.runnerHubFailureDialog.manualRetry": "Do you want to retry or stop running the process?",
    "player.runnerHubFailureDialog.retry": "Retry",
    "player.runnerHubFailureDialog.stop": "Stop",
    "player.runnerHubFailureDialog.title": "Server Error",

    "player.bom.bom": "BOM",
    "player.bom.step": "Steps",
    "player.bom.stepItems": "Step Items",
    "player.bom.bubbleNumber": "#",
    "player.bom.partNumber": "Part Number",
    "player.bom.quantity": "Quantity",
    "player.bom.qty": "Qty",
    "player.bom.description": "Description",
    "player.bom.location": "Location",
    "player.bom.materialLocation": "Material Location",
    "player.bom.traceability": "Traceability",
    "player.bom.newTraceability": "New Traceability",
    "player.bom.justification": "Justification",
    "player.bom.unit": "Unit",
    "player.bom.edit": "Edit",
    "player.bom.editItemTraceability": "Edit the traceability of the item",
    "player.bom.editItemSerialNumber": "Edit the serial number of the item",
    "player.bom.multiTraceability": "Multiple Traceability",
    "player.bom.addNewLine": "Add New Line",
    "player.bom.overconsumption": "Overconsumption",
    "player.bom.replacement": "Replacement",
    "player.bom.actionName": "Action Name",
    "player.bom.stepName": "Step Name",
    "player.bom.serialNumber": "Serial Number",
    "player.bom.newSerialNumber": "New Serial Number",
    "player.bom.processBom": "Process BOM",
    "player.bom.itemSerialized": "Serialized Item",
    "player.bom.loadingBomFollowUp": "Loading BOM follow-up",
    "player.bom.errorLoadingBomFollowUp": "Error loading BOM follow-up",

    "player.failed.PROCESS_MANUALLY_STOPPED_BY_USER": "The process was manually stopped by the user.",
    "player.failed.SEQUENCER_ACTION_CORE_REMOTE_STOP": "The process was manually stopped by the user.",
    "player.failed.PROCESS_FAILED_NO_ACTION_OUPUT": "The process failed because there is a missing connection between actions.",
    "player.failed.PROCESS_FAILED_INVALID_RELEASE_DATES": "The process cannot be played because it is retired or not released.",
    "player.failed.PROCESS_FAILED_JOB_PROCESS_NOT_READY": "The process cannot be played because it is not ready or in progress.",
    "player.failed.MANUAL_STEP_PROCESS_FAIL": "The process was failed manually by the user.",
    "player.failed.STEP_PROCESS_FAIL": "The process was failed manually by the user.",
    "player.failed.MANUAL_FAILURE_TICKET": "The failure ticket is manually created.",
    "player.failed.FAILURE_TICKET_CREATED_WITHOUT_INTERRUPTING_EXECUTION": "Failure ticket created without interrupting execution.",
    "player.failed.failedQty": "Failed Quantity",
    "player.failed.qtyError": "Quantity should be less or equal than ",
    "player.failed.trackingId": "Tracking Id",
    "player.failed.partNumber": "Part Number",
    "player.failed.originalJobRefId": "Job (original)",
    "player.failed.job": "Job (failure ticket)",
    "player.failed.enterTrackingTitle": "Enter tracking number and hit search to get available informations",
    "player.failed.selectProcessTitle": "Select process to get associated item status, failure type and symptoms",
    "player.failed.addFailuresTitle": "Add each failure details associated to the failing part",
    "player.failed.connector.shell": "A shell command failed.",
    "player.failed.connector.rest": "A rest command failed.",
    "player.failed.failureTicket.forkError": "The action was unable to create a rework and a job.",
    "player.failed.failureTicket.requireJobAndRework": "This action requires to run with rework and a job.",
    "player.failed.input.unsupportedTrackingType": "Unsupported tracking type.",
    "player.failed.parser.jsonParseError": "A value could not be parsed by the action.",
    "player.failed.parser.jsonNoMatch": "The query could not find any match.",
    "player.failed.stop.triggerFailure": "The process has been stopped by the action Stop.",
    "player.failed.selectYieldType": "Select a yield type",
    "player.failed.processName": "Selected process",

    "player.runDetailsDialog.runDetails": "Run Details",
    "player.runDetailsDialog.currentDetails": "Current Run Informations",
    "player.runDetailsDialog.single": "Single",
    "player.runDetailsDialog.batch": "Batch",
    "player.runDetailsDialog.singleOrBatch": "Single or Batch",
    "player.runDetailsDialog.trackingNumber": "Tracking Number",
    "player.runDetailsDialog.maxQty": "Maximum Quantity",
    "player.runDetailsDialog.newProducts": "New Products",
    "player.runDetailsDialog.totalQty": "Total Quantity",
    "player.runDetailsDialog.inProgressQty": "In Progress Quantity",
    "player.runDetailsDialog.failedQty": "Failed Quantity",
    "player.runDetailsDialog.moreInfo": "More Informations",
    "player.runDetailsDialog.runType": "Run Type",
    "player.runDetailsDialog.testRun": "Test Run",
    "player.runDetailsDialog.dryRun": "Dry Run",
    "player.runDetailsDialog.normalRun": "Normal Run",
    "player.runDetailsDialog.runFromJobProcess": "Run from Job Process",
    "player.runDetailsDialog.isRework": "Is Rework",
    "player.runDetailsDialog.process": "Process",
    "player.runDetailsDialog.processName": "Name",
    "player.runDetailsDialog.releaseStatus": "Release Status",
    "player.runDetailsDialog.versionStatus": "Version Status",
    "player.runDetailsDialog.releasedOn": "Released On",
    "player.runDetailsDialog.jobProcess": "Job Process",

    /**
     * PROCESS EDITOR
     */
    "processEditor.delete": "Delete",
    "processEditor.deleteConfirmationLabel": "Do you really want to delete this action?",
    "processEditor.deleteCommuniqueConfirmationLabel": "Do you really want to delete this communique?",
    "processEditor.deleteConfirmationCancel": "Cancel",
    "processEditor.deleteConfirmationConfirm": "Confirm",
    "processEditor.deleteConfirmationTitle": "Delete Confirmation",
    "processEditor.edit": "Edit",
    "processEditor.disconnect": "Disconnect",
    "processEditor.read": "Read",
    "processEditor.start": "Start",
    "processEditor.openProcess": "Open process",
    "processEditor.nonCopyableActions": "The following actions cannot be copied: Fail, Input and Output.",
    "processEditor.copyError": "Copying failed.",

    // PROCESS EDITOR SIDE MENU
    "processEditorSideMenu.info": "Info",
    "processEditorSideMenu.versioning": "Versioning",
    "processEditorSideMenu.variables": "Variables",
    "processEditorSideMenu.actions": "Actions",
    "processEditorSideMenu.translations": "Translations",
    "processEditorSideMenu.changeLogs": "Logs",
    "processEditorSideMenu.dryRun": "Dry Run",
    "processEditorSideMenu.testRun": "Test Run",
    "processEditorSideMenu.run": "Run",
    "processEditorSideMenu.communique": "Communiqué",
    "processEditorSideMenu.validate": "Validate",

    /**
     * PROCESS REPOSITORY
     */
    "processRepository.archiveDialogArchiveDisabledMessage":
        "This process cannot be archived because it is released or used by at least one active job or routing.",
    "processRepository.archiveDialogCancel": "Cancel",
    "processRepository.archiveDialogConfirm": "Confirm",
    "processRepository.archiveDialogConfirmation": "Do you want to archive this folder/process?",
    "processRepository.unarchiveDialogConfirmation": "Do you want to activate this folder/process?",
    "processRepository.archiveDialogTitle": "Archive confirmation",
    "processRepository.folder": "Folder",
    "processRepository.insertFolder": "Folder",
    "processRepository.insertProcess": "Process",
    "processRepository.insertRouting": "Routing",
    "processRepository.process": "Process",
    "processRepository.routing": "Routing",
    "processRepository.unknown": "Unknown",
    "processRepository.whereUsed": "Where used: ",
    "processRepository.whereUsedClose": "Close",
    "processRepository.filter.processAndRouting": "Process & Routing",

    /**
     * PROCESS WHERE USED
     */
    "processWhereUsed.approvalStatus": "Approval status",
    "processWhereUsed.empty": "This process is not used.",
    "processWhereUsed.emptyActive": "This process is only used by inactive routing(s) and/or job(s).",
    "processWhereUsed.jobProcessStatus": "Process Status",
    "processWhereUsed.jobStatus": "Job Status",
    "processWhereUsed.jobs": "Jobs",
    "processWhereUsed.partNumber": "Part number",
    "processWhereUsed.processAction": "Action",
    "processWhereUsed.processLabel": "Name",
    "processWhereUsed.processVersion": "Version",
    "processWhereUsed.releaseStatus": "Release status",
    "processWhereUsed.routings": "Routings",
    "processWhereUsed.showInactive": "Show inactive",
    "processWhereUsed.reference": "Reference",
    "processWhereUsed.refId": "Job",

    /**
     * PROCESS VARIABLE COPY DIALOG
     */
    "processVariableCopyDialog.buttonClose": "Close",
    "processVariableCopyDialog.duplicate": "Duplicated",
    "processVariableCopyDialog.explanation":
        "The following variables could not be copied since they already existed in this process, but they have different properties.",
    "processVariableCopyDialog.original": "Original",
    "processVariableCopyDialog.title": "Warning - duplicated variables",
    "processVariableCopyDialog.variableIdentifier": "Identifier",
    "processVariableCopyDialog.variableDecimals": "Decimals",
    "processVariableCopyDialog.variableDefaultValue": "Default value",
    "processVariableCopyDialog.variableType": "Type",

    /**
     * PROCESS UPDATE
     */
    "processUpdate.approvalStatus": "Approval status",
    "processUpdate.empty": "This process version is currently not used in any jobs.",
    "processUpdate.emptyActive": "This process version is currently only used by inactive job(s).",
    "processUpdate.jobProcessStatus": "Process Status",
    "processUpdate.jobStatus": "Job Status",
    "processUpdate.jobs": "Jobs",
    "processUpdate.partNumber": "Part number",
    "processUpdate.processAction": "Action",
    "processUpdate.processLabel": "Name",
    "processUpdate.processVersion": "Version",
    "processUpdate.releaseStatus": "Release status",
    "processUpdate.showInactive": "Show inactive",
    "processUpdate.reference": "Reference",
    "processUpdate.refId": "Job",
    "processUpdate.remaining": "Remaining",
    "processUpdate.clearAll": "Clear All",
    "processUpdate.selectAll": "Select All",

    /**
     * REPO BREADCRUMB
     */
    "repoBreadCrumbs.cancel": "Cancel",
    "repoBreadCrumbs.select": "Select",
    "repoBreadCrumbs.selectProcess": "Select a process or routing",
    "repoBreadCrumbs.noProcessSelect": "No process selected",
    "repoBreadCrumbs.titleProcess": "Manage Process & Routing ",
    "repoBreadCrumbs.backRouting": "Back to routing",
    "repoBreadCrumbs.draftVersionDifferentThanLatestReleaseVersion": "Draft is different than latest released version.",
    "repoBreadCrumbs.draftVersionDifferentThanLatestApprovalVersion": "Draft is different than latest in-approval version.",
    "repoBreadCrumbs.draftVersionDifferentThanLatestReleaseAndApprovalVersion":
        "Draft is different than latest release and in-approval versions.",
    "repoBreadCrumbs.draftVersionDifferentNoteOnIgnoredDifferences": "Actions position ignored",

    /**
     * REWORK
     */
    "rework.rework": "Rework",
    "rework.clearFilters": "Clear Filters",
    "rework.reworkStatus": "Ticket Status",
    "rework.reworkItemStatus": "Item Status",
    "rework.partNumber": "Part #",
    "rework.failureTicketNumber": "Ticket #",
    "rework.trackingId": "Tracking #",
    "rework.trackingInstance": "Tracking instance",
    "rework.date": "Date",
    "rework.reworkDetails": "Ticket Details",
    "rework.failureDescription": "Failure Description",
    "rework.failureItemState": "Item State",
    "rework.problems": "Problems",
    "rework.comments": "Comments",
    "rework.commentsRequired": "A comment is required.",
    "rework.trackinIdRequired": "A tracking Id is required.",
    "rework.partNumberRequired": "A part number is required.",
    "rework.process": "Process",
    "rework.close": "Close",
    "rework.play": "Play",
    "rework.cancel": "Cancel",
    "rework.createNewJob": "Create New Job",
    "rework.useOrginalJob": "Use Original Job",
    "rework.useExistingJob": "Use existing original job or create new job?",
    "rework.createJob": "Job Creation",
    "rework.resolution": "Resolution Details",
    "rework.quantity": "Quantity",
    "rework.workingTime": "Working Time",
    "rework.failureInfo": "Failure Info",
    "rework.yieldType": "Yield Type",
    "rework.FPY": "FPY",
    "rework.FPYTooltip": "Non-conforming products during production",
    "rework.TPY": "TPY",
    "rework.TPYTooltip": "Non-compliant products after completion of its manufacturing process",
    "rework.RMA": "RMA",
    "rework.RMATooltip": "Customer return",
    "rework.ASSY": "ASSY",
    "rework.ASSYTooltip": "Assembly",
    "rework.rootCause": "RootCause",

    "rework.from": "From",
    "rework.to": "To",
    "rework.createdOn": "Creation Date",

    "rework.details": "Details",
    "rework.schedule": "Schedule",
    "rework.run": "Run",

    "rework.status.NEW": "New",
    "rework.status.SCHE": "Scheduled",
    "rework.status.INPRG": "In Progress",
    "rework.status.HOLD": "On Hold",
    "rework.status.DONE": "Completed",
    "rework.status.ARCH": "Archive",

    "rework.optionShowArchived": "Show Archived Reworks",
    "rework.optionShowScheduled": "Show Scheduled Reworks",
    "rework.delete": "Delete",

    "rework.logs": "Logs",
    "rework.failureLog": "Initial failure",
    "rework.symptomLog": "Symptom update",
    "rework.workLog": "Work Log",
    "rework.dateTime": "Date - Time",
    "rework.user": "User",
    "rework.reworkItemStatusId": "Item Status",
    "rework.addWorkLog": "Add Work Log",
    "rework.updateSymptom": "Update symptom",
    "rework.ProcessReworkItem": "Process to rework the item",
    "rework.addProcessFromOriginalJob": "Process from original job",
    "rework.addFailPathProcess": "Fail path process of the routing",
    "rework.addProcess": "Process",
    "rework.addRouting": "Routing",
    "rework.updateReworkInformation": "Update rework information",
    "rework.typeSymptom": "Type",
    "rework.symptom": "Symptômes",

    "rework.status": "Item Status",
    "rework.statusRequired": "A status is required.",
    "rework.jobRefId": "Original Job",
    "rework.scheduledJobRefId": "Failure Ticket Job",
    "rework.runnedBy": "Runned by",
    "rework.LoadingReworks": "Loading more failure tickets...",
    "rework.AddNewReworkSuggestion": "No failure tickets. Add a new one",
    "rework.NoReworksFound": "Sorry, no failure tickets found",
    "rework.ScrollToLoadMoreItems": "Scroll to load more failure tickets",
    "rework.NoMoreReworks": "No more failure tickets",

    "rework.willCloseTicket": "** This item status will automatically close the ticket",

    "scheduler.cancel": "Cancel",
    "scheduler.dryRun": "Dry Run",
    "scheduler.insertJob": "Job",
    "scheduler.insertProcess": "Process",
    "scheduler.insertRouting": "Routing",
    "scheduler.select": "Select",
    "scheduler.selectProcess": "Select Process",
    "scheduler.selectRouting": "Select Routing",
    "scheduler.preview": "Preview",
    "scheduler.play": "Run",
    "scheduler.run": "Run",
    "scheduler.tracking": "Tracking #",
    "scheduler.trackingInstances": "# Instances",
    "scheduler.trackingList": "Tracking # list",
    "scheduler.delete": "Delete",
    "scheduler.jobStatusDraft": "Draft",
    "scheduler.jobStatusReady": "Ready",
    "scheduler.jobStatusInProgress": "In Progress",
    "scheduler.jobStatusOnHold": "On Hold",
    "scheduler.jobStatusDone": "Completed",
    "scheduler.jobStatusArchived": "Archived",
    "scheduler.jobStatusCancelled": "Canceled",
    "scheduler.jobStatusNotReleased": "Not released",
    "scheduler.jobStatusRetired": "Retired",
    "scheduler.jobReleasedOn": "released on",
    "scheduler.jobReleasedSince": "released since",
    "scheduler.jobRetiredOn": "retired on",
    "scheduler.jobReleasedFrom": "released from",
    "scheduler.jobReleasedTo": "to",
    "scheduler.jobStatusUnknown": "Unknown",
    "scheduler.jobProcessRunStatusDraft": "Draft",
    "scheduler.jobProcessRunStatusReady": "Ready",
    "scheduler.jobProcessRunStatusInProgress": "In Progress",
    "scheduler.jobProcessRunStatusPass": "Succeed",
    "scheduler.jobProcessRunStatusFail": "Failed",
    "scheduler.jobProcessRunStatusUnknown": "Unknown",
    "scheduler.steps": "Step(s)",
    "scheduler.optionShowArchived": "Show Archived Jobs",
    "scheduler.optionActiveJobProcessOnly": "Show active work only",
    "scheduler.status": "Status",
    "scheduler.quantity": "Quantity",
    "scheduler.quantityInProgress": "Progress",
    "scheduler.quantityRemainingToStart": "Remaining",
    "scheduler.quantityPass": "Passed",
    "scheduler.quantityFail": "Failed",
    "scheduler.customer": "Customer",
    "scheduler.planner": "Planner",
    "scheduler.elapsedTime": "Elapsed Time",
    "scheduler.plannedOn": "Planned Date",
    "scheduler.scheduledOn": "Scheduled Date",
    "scheduler.jobScheduledOn": "WO scheduled date",
    "scheduler.jobProcessScheduledOn": "Process scheduled date",
    "scheduler.requestedOn": "Requested Date",
    "scheduler.group": "Group",
    "scheduler.jobRefId": "Job",
    "scheduler.referenceId": "Reference",
    "scheduler.partNumber": "Part Number",
    "scheduler.workZone": "Work Zone",
    "scheduler.completion": "Completion",
    "scheduler.from": "From",
    "scheduler.to": "To",
    "scheduler.clearFilters": "Clear Filters",
    "scheduler.progress": "Progress",
    "scheduler.currentProcess": "Current Process",
    "scheduler.unassigned": "Unassigned",
    "scheduler.jobSettings": "Job Settings",
    "scheduler.reworkSettings": "Failure Tickets Settings",
    "scheduler.close": "Close",
    "scheduler.checkPrevious": "Check Previous",
    "scheduler.processNotReleased": "Process is not released and active, process cannot be played",
    "scheduler.processRetired":
        "Process is retired, process cannot be played. Update the version in the scheduler or purge this version for this job.",
    "scheduler.processNotFound": "Process not found, process cannot be played",
    "scheduler.processNotValid": "Process not valid, process cannot be played",
    "scheduler.LoadingJobs": "Loading more jobs...",
    "scheduler.AddNewJobSuggestion": "No jobs. Add a new one",
    "scheduler.NoJobFound": "Sorry, no jobs found",
    "scheduler.ScrollToLoadMoreItems": "Scroll to load more jobs",
    "scheduler.NoMoreJobs": "No more jobs",
    "scheduler.NoJobSelected": "Select a job for more details",
    "scheduler.AddNewProcessOrRouting": "Add a routing / process",
    "scheduler.NoPlanner": "No planner",
    "scheduler.archiveAllCompletedJobs": "Archive all completed jobs",
    "scheduler.getJobsFromErp": "Get jobs from ERP",

    /**
     * STORAGE MANAGER
     */
    "storagemanager.folder": "Folder",
    "storagemanager.insertFolder": "Folder",
    "storagemanager.insertFile": "File",
    "storage.whereUsedDialog.processName": "Process Name",
    "storage.whereUsedDialog.processVersion": "Process Version",
    "storage.whereUsedDialog.actionName": "Action Name",
    "storage.whereUsedDialog.actionType": "Action Type",
    "storage.whereUsedDialog.processPath": "Process Path",
    "storage.whereUsedDialog.processActionType.core-work-instructions": "Work Instruction",
    "storage.whereUsedDialog.close": "Close",
    "storage.success": "Successfully uploaded file",
    "storage.error.upload": "Could not upload file: ",
    "storage.error.invalid.file": "File is invalid",
    "storage.error.max.size": "File exceeds maximum size",
    "storage.error.upload.server": "Can't reach file server",
    "storage.error.compression": "Error compressing image file",

    "treeview.dropFileToUpload": "Drop file(s) to upload",
    "treeview.or": "or",
    "treeview.edit": "Edit",
    "treeview.rename": "Rename",
    "treeview.selectFile": "Select File",
    "treeview.emptyFolder": "Folder is empty",
    "treeview.optionShowArchived": "Show archived",
    "treeview.archive": "Archive",
    "treeview.active": "Activate",
    "treeview.cascadeArchiveDialogHeader": "Archive",
    "treeview.cascadeArchiveDialogMessage": "This action will archive/unarchive all the files within the folder, do you want to proceed?",
    "treeview.cascadeArchiveDialogProceed": "Proceed",
    "treeview.cascadeArchiveDialogCancel": "Cancel",
    "treeview.menu.assignBom": "Assign BOM info",
    "treeview.menu.assignTag": "Assign Tag",
    "treeview.menu.whereUsed": "Where used",
    "treeview.menu.failureType": "Failure Type",
    "treeview.menu.editTooltip": "Edit tooltip",
    "treeview.menu.editTooltip.customTooltip": "Custom tooltip",
    "treeview.menu.editTooltip.originalTooltip": "Original tooltip",
    "treeview.menu.editTooltip.title": "Edit tooltip",
    "treeview.menu.editTooltip.tooltip": "Tooltip",
    "treeview.menu.reworkItemStatus": "Failure Tickets Configuration",
    "treeview.menu.rootCause": "Root cause",
    "treeview.menu.bomFollowUpSymptom": "BOM Follow-up Symptom",
    "treeview.tagSelectorHeader": "Tag Selector",
    "treeview.bomAssignmentHeader": "BOM assignment",
    "treeview.optionShowFilter": "Show",

    /**
     * PROCESS
     */
    // PROCESS EDITOR
    "porcess.messageError.updtateAction": "Modification not saved. Process version is not a draft.",
    "porcess.messageError.errorSystem": "Modification not saved. System error, contact support",

    // FUNCTION BLOCK
    "process.functionBlock.connector.label": "Connector",
    "process.functionBlock.connector.description": "Hardware and software communication interface",
    // PRINTING
    "process.printing.approvals": "Approvals",
    "process.printing.approvalDate": "Date",
    "process.printing.approvalGroup": "Group",
    "process.printing.approverName": "Name",
    "process.printing.approverSignature": "Signature",
    "process.printing.author": "Author",
    "process.printing.cancel": "Cancel",
    "process.printing.code": "Code",
    "process.printing.description": "Description",
    "process.printing.draft": "DRAFT",
    "process.printing.export": "Export",
    "process.printing.preview": "Process PDF Preview",
    "process.printing.printDate": "Printed on",
    "process.printing.processFlow": "Process flow",
    "process.printing.tableOfContent": "Table of content",
    "process.printing.revisionHistory": "Revision history",
    "process.printing.version": "Version",
    "process.printing.uncontrolledPrintedCopy": "Uncontrolled printed copy",

    // VERSIONING
    "process.versioning.action": "Action",
    "process.versioning.actionReleaseDateUpdate": "Update release/retire date(s)",
    "process.versioning.actionCancelledVersion": "Cancelled",
    "process.versioning.actionVersionOnHold": "On hold",
    "process.versioning.archive": "Archive",
    "process.versioning.archived": "Archived",
    "process.versioning.approval": "Approval",
    "process.versioning.approvals": "Approvals",
    "process.versioning.recover": "Recover",
    "process.versioning.recoverVersionDialogCancel": "Cancel",
    "process.versioning.recoverVersionDialogConfirm": "Confirm",
    "process.versioning.recoverVersionDialogMessage":
        "Do you really want to recover this version? All changes applied to the draft version will be lost.",
    "process.versioning.versionInformation": "Version Information",
    "process.versioning.approvalInformation": "Approval Information",
    "process.versioning.releaseInformation": "Release Information",
    "process.versioning.submitNewVersion": "Submit New Version",
    "process.versioning.approvedBy": "Approved by",
    "process.versioning.approvalGroup": "Approval group",
    "process.versioning.approvalStatus": "Approval status",
    "process.versioning.requestedBy": "Requested by",
    "process.versioning.requestedDate": "Requested Date",
    "process.versioning.approve": "Approve",
    "process.versioning.approved": "Approved",
    "process.versioning.approvedAutomatically": "Approved automatically",
    "process.versioning.awaiting": "Awaiting",
    "process.versioning.changelog": "Change Log",
    "process.versioning.clearFilters": "Clear filters",
    "process.versioning.currentDraft": "Current draft",
    "process.versioning.cancel": "Cancel",
    "process.versioning.cancelled": "Cancelled",
    "process.versioning.hold": "Hold",
    "process.versioning.invalidDate": "Invalid date",
    "process.versioning.invalidRouting": "The routing cannot be submitted. It contains unapproved processes.",
    "process.versioning.major": "Major",
    "process.versioning.minor": "Minor",
    "process.versioning.onHold": "On hold",
    "process.versioning.pdf": "PDF",
    "process.versioning.referenceId": "Reference ID",
    "process.versioning.reject": "Reject",
    "process.versioning.rejectVersionDialogMessage": "Do you really want to reject this version?",
    "process.versioning.rejectVersionDialogReason": "Comment",
    "process.versioning.rejectVersionDialogReasonCommentRequired": "A comment is required.",
    "process.versioning.rejected": "Rejected",
    "process.versioning.release": "Release",
    "process.versioning.releaseScheduled": "Scheduled",
    "process.versioning.released": "Released",
    "process.versioning.releasedDate": "Release date",
    "process.versioning.releasedDateFrom": "From",
    "process.versioning.releasedDateTo": "To",
    "process.versioning.releasedDateAfterRetireDate": "Release date must be earlier than the retire date",
    "process.versioning.releasedDateEqualToRetireDate": "Release date and retire date cannot be the same",
    "process.versioning.releasedBy": "Released by",
    "process.versioning.updatedBy": "Updated by",
    "process.versioning.updatedDate": "Updated Date",
    "process.versioning.releaseStatus": "Release status",
    "process.versioning.reviewRequest": "Review request",
    "process.versioning.reviewRequestCancel": "Cancel",
    "process.versioning.reviewRequestConfirm": "Confirm",
    "process.versioning.reviewRequestSearchUser": "Search a user",
    "process.versioning.retired": "Retired",
    "process.versioning.retiredDate": "Retire date",
    "process.versioning.retiredDateFrom": "From",
    "process.versioning.retiredDateTo": "To",
    "process.versioning.retiredDateBeforeReleasedDate": "Retire date must be later than the release date",
    "process.versioning.showArchived": "Show archived",
    "process.versioning.statusNone": "...",
    "process.versioning.submit": "Submit",
    "process.versioning.submitDisabledAnotherPendingVersion": "Another version is already waiting to be approved or released.",
    "process.versioning.submittedBy": "Submitted by",
    "process.versioning.submissionDate": "Submission date",
    "process.versioning.submissionDateFrom": "From",
    "process.versioning.submissionDateTo": "To",
    "process.versioning.training": "Training",
    "process.versioning.trainingRequirementCopyIcon":
        "The level of change selected when submitting this version is None.\nHowever, since trainings were copied from another process, the level of change was also copied.",
    "process.versioning.trainingRequirementCopyWarning":
        "The level of change of the process from which the trainings will be copied\nwill determine the level of change of this version (since you selected None).",
    "process.versioning.level": "Level of Change",
    "process.versioning.level.select": "Select a level of change...",
    "process.versioning.level.none": "None",
    "process.versioning.level.reading": "Reading",
    "process.versioning.level.training": "Training",
    "process.versioning.level.certification": "Certification",
    "process.versioning.processTrainingCopy": "Copy trainings from another process",
    "process.versioning.update": "Update",
    "process.versioning.updateVersion": "Version Scheduled Update",
    "process.versioning.version": "Version",
    "process.versioning.processVersioning": "Process versioning",
    "process.versioning.showHistory": "Release History",
    "process.versioning.signedBy": "Signed by",
    "process.versioning.comments": "Comments",
    "process.versioning.reviewDone": "Review Done",
    "process.versioning.whereUsed": "Where used",
    "process.versioning.whereUsedClose": "Close",
    "process.versioning.whereUsedTitle": "Where used: ",
    "process.versioning.minimumApprovers": "Minimum required approvals",
    "process.versioning.selectPeopleToNotify": "Select people to notify",
    "process.versioning.refusedBy": "Rejected By",
    "process.versioning.selectAll": "Select all",
    "process.versioning.emptyDate": "yyyy-mm-dd",

    "process.versioning.areYouSureDialog.title": "Submitting a new process version",
    "process.versioning.areYouSureDialog.approvalGroupsNotAllSelected": "Not all approval groups have been selected.",
    "process.versioning.areYouSure.question": "Are you sure you want to proceed?",
    "process.versioning.areYouSureDialog.missingTrainingCommunique": "No training communiqué was written.",

    /* FAILURE TYPE DIALOG */
    "failureTypeDialog.toInsert": " to insert",
    "failureTypeDialog.close": "Close",
    "failureTypeDialog.add": "Add",
    "failureTypeDialog.addSpecial": "Add Special",
    "failureTypeDialog.failureTypesFor": "Failure Types for ",
    "failureTypeDialog.failureTypes": "Failure Types",
    "failureTypeDialog.failureSymptoms": "Failure Symptoms",
    "failureTypeDialog.addInstructions": "Add in CSV format for multiple insert, Add Special for Format TYPE - SYMPTOMS, csv supported",
    "failureTypeDialog.optionShowArchived": "Show archived",
    "reworkItemStatusDialog.itemsToInsert": "Items to insert",

    /* REWORK ITEM STATUS DIALOG */
    "reworkItemStatusDialog.add": "Add",
    "reworkItemStatusDialog.addSpecial": "Add Special",
    "reworkItemStatusDialog.reworkItemStatusFor": "Failure Tickets Configuration For ",
    "reworkItemStatusDialog.reworkItemStatus": "Failure Tickets Configuration",
    "reworkItemStatusDialog.failureTicketsExplanation":
        "Select Completed and/or Archive to automatically close or achive ticket on creation",
    "reworkItemStatusDialog.addInstructions":
        "Add in CSV format for multiple insert, Add Special for Failure Ticket Configuration, csv supported",
    "reworkItemStatusDialog.optionShowArchived": "Show archived",
    "reworkItemStatusDialog.automaticClose": "Completed",
    "reworkItemStatusDialog.automaticArchive": "Archive",
    "reworkItemStatusDialog.itemState": "Item State",
    "reworkItemStatusDialog.continue": "Continue",
    "reworkItemStatusDialog.continueTooltip":
        "When checked, the execution of the process will not be stopped.\nThis status will not be available if the process does not fail manually.",
    "player.reworkStatusItemDisabled": "Some statuses may be unavailable because the process was not stopped manually.",

    /* ROOT CAUSE DIALOG */
    "rootCauseDialog.rootCauseConfiguration": "Root cause configuration for",
    "rootCauseDialog.rootCause": "Root Cause",
    "rootCauseDialog.description": "Description",

    /* BOM FOLLOW-UP SYMPTOM DIALOG */
    "bomFollowUpSymptomDialog.bomFollowUpSymptomConfiguration": "Symptom configuration for",
    "bomFollowUpSymptomDialog.bomFollowUpSymptom": "BOM Follow-up Symptom",
    "bomFollowUpSymptomDialog.description": "Description",

    /**
     * DATA STORE ACTION
     */
    "action.datastore.basicValueSettings": "Basic Value Settings",
    "action.datastore.tableSettings": "Table Settings",
    "action.datastore.cancel": "Cancel",
    "action.datastore.save": "Save",
    "action.datastore.column": "Column",
    "action.datastore.MaxColumnReached": "Maximum columns count reached",
    "action.datastore.table": "Table",
    "action.datastore.valueTableId": "Table",
    "action.datastore.groupId": "Group",
    "action.datastore.subGroupId": "Sub Group",
    "action.datastore.results": "Process Results",
    "action.datastore.key": "Key",
    "action.datastore.value": "Value",
    "action.datastore.delete": "Delete",
    "action.datastore.actionType": "Action Type",
    "action.datastore.actionType.write": "Write",
    "action.datastore.actionType.read": "Read",
    "action.datastore.storageType": "Storage Type",
    "action.datastore.storageType.basicValue": "Basic Value",
    "action.datastore.storageType.table": "Table Value",
    "action.datastore.storageType.localFile": "Local File",
    "action.datastore.storageType.cloudFile": "Cloud File",
    "action.datastore.storageType.results": "Process Results",
    "action.datastore.storageType.status": "Process Status",
    "action.datastore.group.name": "Name",
    "action.datastore.group.type": "Type",
    "action.datastore.group.unit": "Unit",
    "action.datastore.group.metadata": "Metadata",
    "action.datastore.group.value": "Value",
    "action.datastore.group.child": "Child Link",
    "action.datastore.group.parent": "Parent Link",
    "action.datastore.table.name.error": "Invalid format",
    "action.datastore.table.columnsCount.error": "Columns count has exceeded the limit",
    "action.datastore.dupicated.error": "Name should be unique",
    "action.datastore.reqColumn": "Requested Column",
    "action.datastore.readItem.table": "Table",
    "action.datastore.readItem.valueTableId": "Table",
    "action.datastore.readItem.tag": "Key",
    "action.datastore.readItem.value": "Value",
    "action.datastore.readItem.metadata": "Metadata",
    "action.datastore.readItem.trackingId": "Tracking Id",
    "action.datastore.readItem.jobRefId": "Job",
    "action.datastore.readItem.refId": "Job",
    "action.datastore.readItem.groupId": "Group Id",
    "action.datastore.readItem.subGroupId": "Sub Group Id",
    "action.datastore.readItem.allColumn": "All Column",
    "action.datastore.readItem.userName": "User Name",
    "action.datastore.readItem.label": "Process Name",
    "action.datastore.readItem.version": "Process Version",
    "action.datastore.readItem.pass": "Pass",
    "action.datastore.readItem.fail": "Fail",
    "action.datastore.readItem.zoneIdsJSON": "Work Stations",
    "action.datastore.readItem.qtyPlayed": "Quantity Played",
    "action.datastore.storeTo": "Store To",
    "action.datastore.source": "Source",
    "action.datastore.noAction": "No DataStore Action inserted",
    "action.datastore.tabGroups": "Groups",
    "action.datastore.metadata": "Metadata",
    "action.datastore.tabMetadata": "Metadata",
    "action.datastore.property": "Property",
    "action.datastore.propertyType.object": "Object",
    "action.datastore.propertyType.value": "Value",
    "action.datastore.selectMetadata": "Select a metadata",
    "action.datastore.noMetadata": "None",
    "action.datastore.defaultMetadataLabel": "metadata",
    "action.datastore.defaultMetadataPropertyLabel": "key",
    "action.datastore.metadata.property": "Property",
    "action.datastore.metadata.type": "Type",
    "action.datastore.metadata.metadata": "Metadata",

    /**
     * ACTION STOP PROCESS
     */
    "action.stop.process.trigger.rework": "Trigger rework process",
    "action.stop.process.yes": "Yes",
    "action.stop.process.no": "No",

    /**
     * ACTION DEFINITIONS
     */
    "action.workInstructions.label": "Work Instruction",
    "action.workInstructions.description": "User interface to build work instruction or user feedback",
    "action.robotControl.label": "Robot Control",
    "action.robotControl.description": "Kinova's robot family high level control",
    "action.condition.label": "Condition",
    "action.condition.description": "Condition statement to branch process",
    "action.condition.failOnFalse": "Fail path on false",
    "action.loop.label": "Loop",
    "action.loop.maxLoopCount": "Max Loop Count",
    "action.loop.description": "Loop on condition statement",
    "action.math.label": "Math",
    "action.math.description": "Execute mathematical operations",
    "action.failureTicketCreate.label": "Fork Failure Ticket",
    "action.failureTicketCreate.description": "Fork failure ticket/job to new failure ticket/job",
    "action.message.label": "Message",
    "action.message.description": "Send a user message",

    "action.time.label": "Time",
    "action.time.description": "Time management, delays, countdown, timespan",
    "action.parser.label": "Parser",
    "action.set.description": "Create a variable or store a value in a variable",
    "action.set.label": "Set",
    "action.parser.description": "Parse text input with regex",
    "action.stop.label": "Stop",
    "action.stop.description": "Stop/Fail a process",
    "action.fail.label": "Fail",
    "action.fail.description": "Failing path following failure of a process",
    "action.email.label": "Email",
    "action.email.description": "Send user notification by email or text messages",
    "action.remoteTask.label": "Remote Task",
    "action.remoteTask.description": "Execute remote process",
    "action.connector.label": "Connector",
    "action.connector.description": "Hardware and software communication interface",
    "action.routingProcess.label": "Routing Process",
    "action.routingProcess.description": "Process to be added in a routing",
    "action.routingFail.label": "Fail/Clean up",
    "action.routingFail.description": "Failing/CleanUp path in a routing",
    "action.input.label": "Input",
    "action.input.description": "",
    "action.input.batchNew": "New production items",
    "action.output.label": "Output",
    "action.output.description": "",
    "action.dataStore.label": "Data Store",
    "action.dataStore.description": "Stores or reads data from the database",
    "action.undefinedAction.description": "Undefined action",
    "action.undefinedAction.label": "Undefined",

    "action.failureTicketCreate.workOrder": "Reference (Work Order)",

    /**
     * TRAINING
     */
    "training.userTraining": "User Training",
    "training.processTraining": "Process training",
    "training.trainingCertificate": "Training Certificate",
    "training.traningName": "Training name",
    "training.trainingVersion": "Version",
    "training.latestApprovedVersion": "Latest Approved Version",
    "training.lastVersion": "Last version",
    "training.history": "History",
    "training.process": "Process",
    "training.userName": "Name",
    "training.trained": "Trained",
    "training.certified": "Certified",
    "training.processSelectedWithoutRequirement": "Selected process does not have any training requirement. Select another one.",
    "training.selectProcess": "Select process",
    "training.addProcessTraining": "Add process training",
    "training.searchProcessTraining": "Search process training",
    "training.trainingHistory": "Training history",
    "training.currentlyReleasedProcessVersion": "Version",
    "training.previousProcessVerisonWithTrainingRequirement": "Previous version requiring training:",
    "training.previousProcessVerisonWithTrainingRequirement.version": "Version:",
    "training.levelOfChange": "Level of change",
    "training.levelOfChange.none": "None",
    "training.levelOfChange.reading": "Reading",
    "training.levelOfChange.training": "Training",
    "training.levelOfChange.certification": "Certification",
    "training.archiveDate": "Training Archive Date",
    "training.trainingDate": "Training Date",
    "training.originalVersion": "Original Version",
    "training.moreOptions.history": "History",
    "training.moreOptions.maintain": "Maintain",
    "training.moreOptions.noLongerMaintain": "No Longer Maintain",
    "training.moreOptions.archive": "Archive",
    "training.tooltipTrainingStatusDeprecated": "This training will not be maintained for the next version.",
    "training.processName": "Process Name",
    "training.readingCertificateSignatureContext": "I certify that I have read the processes listed.",
    "training.trainingCertificateNoData": "No data has been loaded. Select a process, select users and click Confirm.",
    "training.trainingCertificateFormDialogTitle": "Training Certificate Form",
    "training.addTrainingCertificate": "Create Training Certificate",
    "training.trainingCertificateTrainer": "Trainer",
    "training.trainingCertificateTrainers": "Trainers",
    "training.trainingCertificateMainTrainer": "Main Trainer",
    "training.trainingCertificateTrainee": "Trained User",
    "training.trainingProcess": "Training Processes",
    "training.trainingCertificateReference": "Reference",
    "training.trainingDuration": "Training Duration",
    "training.trainingCertificateTraineeSignatureContext": "I certify that I have participated in this training.",
    "training.trainingCertificateTrainerSignatureContext": "I certify that I have provided this training.",
    "training.trainingCertificateTrainerNotQualified":
        "(main trainer) is not qualified to create a training certificate for the following process:",
    "training.trainingCertificationCertifiedUserSignatureContext": "I certify that I have participated in the training certification.",
    "training.detail": "Details",
    "training.processVersion": "Process Version",
    "training.numberOfReadingsToDo": "Number Of Readings To Do",
    "training.lateSince": "Late Since",
    "training.showArchivedTrainings": "Show Archived Trainings",
    "training.getUsersWithTraining": "Get all users who have a training on selected processes.",
    "training.addUserToLatestVersion": "Add a user to the latest approved version",
    "training.addUserToHistoryVersion": "Add a user to the selected version in history",

    // CERTIFICATION
    "training.trainingCertification": "Training Certification",
    "training.readingCertificate": "Reading Certificate",
    "training.addTrainingCertification": "Create Training Certification",
    "training.trainingCertificationFormDialogTitle": "Training Certification Form",
    "training.trainingCertificationCertifiedUser": "Certified User",
    "training.processesTrainingCertification": "Processes",
    "training.trainingCertificationReference": "Reference",
    "training.trainingCertificationCriteriaCheckbox": "The certification criteria were met",
    "training.trainingCertificationComment": "Comment",
    "training.trainingCertificationAuditor": "Auditor",
    "training.trainingCertificationCriteria":
        "Wears personal protective equipment.\nProperly disposes of dangerous products and product residues.\nActs safely towards himself and others.\nUses and adequately informs their work documentation.\nFinds the information required in appropriate manufacturing procedures.\nChecks the calibration status of its work equipment and the expiration date of the products.\nEnsures that ESD is adequately managed\nDescribes the key steps of their work. Can explain the reasons and impact of their operations. Knows the main problems that may arise.\nPerforms the task according to specifications and uses tools, equipment appropriately.\nSelf-checks their work paying particular attention to critical inspection points.",
    "training.trainingCertificationAuditorNotQualified": "You are not qualified to create a certification for the following process:",
    "training.trainingCertificationAuditorSignatureContext":
        "I certify that I have accompanied and assessed that the assembler is certified.",
    "training.warningMessagePending": "This user already has pending trainings for the following processes:",
    "training.warningMessageTrained": "This user is already trained on the following processes:",

    "training.tooltipButtonHistory": "The button allows the selected user to view their training history for all process versions.",
    "training.tooltipHeaderVersionTraining": "This version corresponds to the process version for which the training was given.",
    "training.tooltipHeaderCurrentVersion":
        "The current version corresponds to the latest released version and not retired. This indicates that it is a version currently in use.",
    "training.tooltipHeaderLastVersion":
        "The latest version corresponds to the latest approved version, not released and not retired of a process requiring training. This indicates that it is a version currently being deployed. If no version is indicated, it means that the version is identical to the current version.",
    "training.tooltipAddProcessTraining": "Add process training(s).",
    "training.tooltipLateTraining": "Late",
    "training.tooltipUpToDateTraining": "Up To Date",
    "training.tooltipUpcomingTraining": "Upcoming training. A new version of the process is currently being deployed.",
    "training.tooltipUpcomingTrainingReady": "Trained for upcoming process version",
    "training.tooltipNotMaintainedTraining": "Not Maintained",
    "training.tooltipArchivedTraining": "Archived",
    "training.tooltipOriginalVersion": "Version at which this training was created.",
    "training.tooltipReadingCertificate": "A training certificate is required for this version.",
    "training.archiveConfirmationDialog.archiveTraining": "Archive Training",
    "training.archiveConfirmationDialog.areYouSure": "Do you really want to archive this training?",

    /**
     * TEAM SETTINGS
     */
    // GLOBAL SETTINGS
    "settings.globalSettings.approval.allowSelfProcessApproval": "Allow process self-approval",
    "settings.globalSettings.approval.allowSelfRoutingApproval": "Allow routing self-approval",
    "settings.globalSettings.approval.automatic": "Automatic",
    "settings.globalSettings.approval.contextText": "Contextual Text",
    "settings.globalSettings.approval.manual": "Manual",
    "settings.globalSettings.approval.lockPendingProcess": "Lock editing of process",
    "settings.globalSettings.approval.lockPendingProcessNo": "No",
    "settings.globalSettings.approval.lockPendingProcessYes": "Yes",
    "settings.globalSettings.approval.lockPendingRouting": "Lock editing of routing",
    "settings.globalSettings.approval.process": "Process",
    "settings.globalSettings.approval.routing": "Routing",
    "settings.globalSettings.approval.electronicSignature": "Electronic Signature",
    "settings.globalSettings.approval.title": "Versioning Approval",
    "settings.globalSettings.release.contextText": "Contextual Text",
    "settings.globalSettings.release.title": "Releasing Approval",
    "settings.globalSettings.release.process": "Process",
    "settings.globalSettings.release.routing": "Routing",
    "settings.globalSettings.billing": "Billing",
    "settings.globalSettings.billing.billingContact": "Billing Contact",
    "settings.globalSettings.billing.billingHistory": "BILLING HISTORY",
    "settings.globalSettings.billing.currentMonth": "Current month",
    "settings.globalSettings.billing.disableAccount": "DISABLE ACCOUNT",
    "settings.globalSettings.billing.nextBillingDate": "Next Billing Date",
    "settings.globalSettings.billing.status": "Status",
    "settings.globalSettings.generalSettings": "General Settings",
    "settings.globalSettings.generalSettings.allowLoginByUserCode": "Allow Login by user code",
    "settings.globalSettings.generalSettings.organizationName": "Organization Name",
    "settings.globalSettings.generalSettings.itEmail": "IT Email",
    "settings.globalSettings.localization": "Localization",
    "settings.globalSettings.localization.addLanguage": "Add language",
    "settings.globalSettings.localization.mainLanguage": "Main language",
    "settings.globalSettings.localization.secondaryLanguages": "Secondary languages",
    "settings.globalSettings.mainContact": "Main contact",
    "settings.globalSettings.mainContact.mainContact": "Main Contact",
    "settings.globalSettings.userAndPasswordTitle": "User And Password",
    "settings.globalSettings.userAndPassword.passwordSettings": "Password Settings",
    "settings.globalSettings.userAndPassword.passwordRetriesLimit": "Retries Limit",
    "settings.globalSettings.userAndPassword.passwordAccountLockTime": "Account Lock Time",
    "settings.globalSettings.userAndPassword.passwordExpiresTimeSec": "Expiration Delay",
    "settings.globalSettings.userAndPassword.passwordChangeOnFirstLogin": "Change On First Login",
    "settings.globalSettings.trainingTitle": "Training",
    "settings.globalSettings.enabled": "Enabled",
    "settings.globalSettings.trainingInactivityTimeout": "Inactivity Timeout",
    "settings.globalSettings.trainingInactivityValidationExcludedGroups": "Groups Excluded From Inactivity Validation",
    "settings.globalSettings.trainingInactivityValidationFrequency": "Inactivity Validation Frequency",
    "settings.globalSettings.trainingInactivityValidationDate": "Inactivity Validation Date",
    "settings.globalSettings.patch.apply": "Apply",
    "settings.globalSettings.patch.title": "Patch",

    "settings.tag.column": "Column",
    "settings.tag.value": "Value",

    "settings.roles.name": "Role Name",
    "settings.roles.system": "System",
    "settings.roles.userEditor": "User Editor",
    "settings.roles.groupEditor": "Group Editor",
    "settings.roles.roleEditor": "Role Editor",
    "settings.roles.reporting": "Reporting",
    "settings.roles.storageManager": "Storage Manager",
    "settings.roles.processRepository": "Process Repository",
    "settings.roles.processEditor": "Process / Routing Editor",
    "settings.roles.processPlayer": "Process Player",
    "settings.roles.processApproval": "Process Review & Approval",
    "settings.roles.processRelease": "Process Release",
    "settings.roles.scheduler": "Scheduler",
    "settings.roles.workSchedule": "Work Schedule",
    "settings.roles.kanban": "Kanban",
    "settings.roles.reworks": "Failure Tickets",
    "settings.roles.details": "Role Details",
    "settings.roles.permissions": "Role Permissions",
    "settings.roles.assignTo": "Assigned To",
    "settings.roles.approval.reviewOnly": "Review Only",
    "settings.roles.approval.reviewAndApprove": "Review and Approve",
    "settings.roles.training": "Training",
    "settings.roles.bom": "BOM",

    // USER SETTINGS
    "settings.userSettings.cell": "Cell",
    "settings.userSettings.contactInformation": "Contact Information",
    "settings.userSettings.code": "User Code",
    "settings.userSettings.details": "Details",
    "settings.userSettings.done": "Done",
    "settings.userSettings.email": "Email",
    "settings.userSettings.firstName": "First Name",
    "settings.userSettings.forceUserToChangePasswordLabel": "Force user to set a new password",
    "settings.userSettings.groups": "Groups",
    "settings.userSettings.groupsRolesPermissions": "Groups, Roles & Permissions",
    "settings.userSettings.invalid": "Invalid",
    "settings.userSettings.invalidCell": "Must respect XXX-XXX-XXXX(XX) pattern",
    "settings.userSettings.invalidEmail": "Must respect x@y.com pattern",
    "settings.userSettings.invalidLanguage": "Please choose a language",
    "settings.userSettings.invalidLength_0_50": "Must containt between 0 and 50 characters",
    "settings.userSettings.invalidLength_2_50": "Must containt between 2 and 50 characters",
    "settings.userSettings.invalidPasswordError": "The password doesn't match the requirements",
    "settings.userSettings.invalidRoles": "Must have at least 1 role",
    "settings.userSettings.language": "Language",
    "settings.userSettings.lastName": "Last Name",
    "settings.userSettings.name": "Name",
    "settings.userSettings.optionShowArchived": "Show Archived Users",
    "settings.userSettings.password": "Temporary Password",
    "settings.userSettings.passwordExpired": "This user's password is expired",
    "settings.userSettings.passwordHint":
        "Password must contain at least 1 capital letter, 1 lowercase letter, 1 number and have at least 8 caracters",
    "settings.userSettings.passwordsMismatchError": "Password and retyped password must be identical",
    "settings.userSettings.passwordUpdateCompleted": "Password update completed",
    "settings.userSettings.resetPassword": "Reset Password",
    "settings.userSettings.retypedPassword": "Confirm Temporary Password",
    "settings.userSettings.role": "Role",
    "settings.userSettings.roles": "Roles",
    "settings.userSettings.status": "Status",
    "settings.userSettings.status.active": "Active",
    "settings.userSettings.status.disabled": "Disabled",
    "settings.userSettings.status.archived": "Archived",
    "settings.userSettings.userDetails": "User Details",
    "settings.userSettings.userStatus": "User Status",
    "settings.userSettings.username": "Username",
    "settings.userSettings.usernameUnavailableError": "This username is already taken.  Please select another one before continuing",
    "settings.userSettings.usercodeUnavailableError": "This usercode is already taken. Please select another one before continuing",
    "settings.userSettings.exportEmail": "Export",

    // GROUP SETTINGS
    "settings.groups.attachedTo": "Attached To",
    "settings.groups.assignedTo": "Assigned To",
    "settings.groups.groupDetails": "Group Details",
    "settings.groups.name": "Name",
    "settings.groups.isApprover": "Is Approver",
    "settings.groups.isTrainer": "Trainer",
    "settings.groups.trainedAfterReading": "Trained After Reading",
    "settings.groups.approvalMandatory": "Approval Mandatory",
    "settings.groups.approverQty": "Approver Qty",
    "settings.groups.status": "Status",
    "settings.groups.yes": "Yes",
    "settings.groups.no": "No",
    "settings.groups.users": "Users",
    "settings.groups.processes": "Processes",
    "settings.groups.active": "Active",
    "settings.groups.archived": "Archived",
    "settings.groups.cannotArchive": "You cannot archive this group because processes with pending approvals are attached to it.",
    "settings.groups.showArchived": "Show Archived",

    /**
     * PROCESS VERSION PICKER
     */
    "processVersionPicker.draft": "Draft",
    "processVersionPicker.latest": "Latest",
    "processVersionPicker.noVersionAvailable": "No version available",
    "processVersionPicker.noApprovedVersionAvailable": "No approved version available",
    "processVersionPicker.noReleasedVersionAvailable": "No released version available",

    /**
     * APPLICATION HEADER
     */
    // CONNECTION STATUS
    "connectionStatus.account": "Account",
    "connectionStatus.language": "Language",
    "connectionStatus.logout": "Logout",

    /**
     * REPORTING PAGE
     */
    "reporting.type.summary": "Process Summary",
    "reporting.type.process": "Process",
    "reporting.type.process.step": "Process Step",
    "reporting.type.rework": "Failure Tickets",
    "reporting.type.data.store.value": "Data Store Values",
    "reporting.type.data.store.table": "Data Store Tables",
    "reporting.type.audit.trail": "Audit Trail",
    "reporting.type.electronic.signature": "Electronic Signatures",
    "reporting.type.entity": "Entity",
    "reporting.type.training": "Training",
    "reporting.type.trainingReadingCertificate": "Reading Certificate",
    "reporting.type.trainingTrainingCertificate": "Training Certificate",
    "reporting.type.trainingCertification": "Certification",
    "reporting.from": "From",
    "reporting.to": "To",
    "reporting.tracking.id": "Tracking Id",
    "reporting.action.generate": "Generate",
    "reporting.action.export": "Export",
    "reporting.rows.per.page": "Rows per",
    "reporting.table": "Table",
    "reporting.report.type": "Report Type",
    "reporting.job.ref.id": "Job",
    "reporting.testRun": "Test Run",
    "reporting.testRunIncludes": "Includes",
    "reporting.testRunOnly": "Only",
    "reporting.tooMuchRecordDialog": "Too many records to download",
    "reporting.tooMuchRecordDialog1": "Too many records to download: ",
    "reporting.tooMuchRecordDialog2": ". Only first ",
    "reporting.tooMuchRecordDialog3": " record available. Use export csv function to get all the records.",
    "reporting.groupData": "Group data",
    "reporting.showFilterDate": "Show date",
    "reporting.showArchived": "Show Archived",
    "reporting.certificateFilters": "Training Certificate",
    "reporting.readingCertificateFilters": "Reading Certificate",
    "reporting.trainer": "Trainer",
    "reporting.reference": "Reference",
    "reporting.certificationFilters": "Certification",
    "reporting.auditor": "Auditor",
    "reporting.tag": "Tag",

    "reporting.tableheader.processId": "Process ID",
    "reporting.tableheader.processName": "Process",
    "reporting.tableheader.total": "Total",
    "reporting.tableheader.totalPass": "Total Pass",
    "reporting.tableheader.totalFail": "Total Fail",
    "reporting.tableheader.avgTimeDHMS": "Avg Time",
    "reporting.tableheader.reportTagEntry": "Report Tag Entry",
    "reporting.tableheader.jobRefId": "Job",
    "reporting.tableheader.startedOnDateTimeUTC": "Started On",
    "reporting.tableheader.completedOnDateTimeUTC": "Completed On",
    "reporting.tableheader.trackingId": "Tracking Id",
    "reporting.tableheader.refId": "Ref Id",
    "reporting.tableheader.userName": "User Name",
    "reporting.tableheader.workStation": "Work Station",
    "reporting.tableheader.pass": "Pass",
    "reporting.tableheader.fail": "Fail",
    "reporting.tableheader.isTestRunYNBOOL": "Test Run",
    "reporting.tableheader.isProcessReworkYNBOOL": "Rework",
    "reporting.tableheader.durationDHMS": "Duration",
    "reporting.tableheader.processVersion": "Process Version",
    "reporting.tableheader.actionType": "Action Type",
    "reporting.tableheader.actionName": "Action Name",
    "reporting.tableheader.actionStepName": "Action Step Name",
    "reporting.tableheader.actionStepStartedOnDateTimeUTC": "Started On",
    "reporting.tableheader.actionStepDurationDHMS": "Time",
    "reporting.tableheader.actionStepComment": "Comment",
    "reporting.tableheader.actionStepApprovedBy": "Approved By",
    "reporting.tableheader.actionStepApprovedOnUTC": "Approved On",
    "reporting.tableheader.approvalDate": "Approval Date",
    "reporting.tableheader.approvedBy": "Approved By",
    "reporting.tableheader.context": "Context",
    "reporting.tableheader.releasedDateUTC": "Released Date",
    "reporting.tableheader.retiredDateUTC": "Retired Date",
    "reporting.tableheader.electronicSignatureDateUTC": "Electronic Signature Date",
    "reporting.tableheader.sysAuditTrailId": "Id",
    "reporting.tableheader.tableName": "Table Name",
    "reporting.tableheader.entryId": "Entry Id",
    "reporting.tableheader.modificationType": "Type",
    "reporting.tableheader.modificationInfo": "Info",
    "reporting.tableheader.previousValue": "Previous Value",
    "reporting.tableheader.modifiedOnDateTimeUTC": "Modified On",
    "reporting.tableheader.modifiedByUserName": "Modified By",
    "reporting.tableheader.description": "Description",
    "reporting.tableheader.failedOnDateTimeUTC": "Failed On",
    "reporting.tableheader.failureStatus": "Failure Status",
    "reporting.tableheader.reworkStatus": "Status",
    "reporting.tableheader.reworkItemStatus": "Item Status",
    "reporting.tableheader.reworkComment": "Failure Ticket Comment",
    "reporting.tableheader.resolution": "Resolution",
    "reporting.tableheader.failureType": "Failure Type",
    "reporting.tableheader.symptomType": "Symptom",
    "reporting.tableheader.problemComment": "Problem Comment",
    "reporting.tableheader.runBy": "Run by",
    "reporting.tableheader.title": "Titre",
    "reporting.tableheader.reference": "Reference",
    "reporting.tableheader.formedUsersLength": "Number Users",
    "reporting.tableheader.requestedBy": "Requested By",
    "reporting.tableheader.scheduledOnDateUTC": "Scheduled On",
    "reporting.tableheader.requestedCompletedByDateUTC": "Request to Complete By",
    // Entity
    "reporting.tableheader.trackingInstance": "Tracking instance BATCH",
    "reporting.tableheader.partNumber": "Part Number",
    "reporting.tableheader.status": "Status",
    "reporting.tableheader.batchEnableTFBOOL": "BATCH Enable",
    "reporting.tableheader.completed": "Completed",
    "reporting.tableheader.cycleTimeDHMS": "Cycle time",
    "reporting.tableheader.assemblyDurationDHMS": "Assembly Duration",
    "reporting.tableheader.reworkDurationDHMS": "Rework Duration",
    "reporting.tableheader.passedQuantity": "Passed Quantity",
    "reporting.tableheader.faildedQuantity": "Failed Quantity",

    "reporting.electronicSignature.type": "Type",
    "reporting.electronicSignature.type.processApproval": "Process Approval",
    "reporting.electronicSignature.type.processRelease": "Process Release",
    "reporting.electronicSignature.type.processRunApproval": "Process Run Approval",
    "reporting.electronicSignature.type.processStepApproval": "Process Step Approval",
    "reporting.electronicSignature.type.processTrainingCoachRequired": "Execution Supervisor",
    "reporting.electronicSignature.type.processTrainingCompleted": "Reading Certificate by Execution",
    "reporting.electronicSignature.type.readingCertificate": "Manual Reading Certificate",
    "reporting.electronicSignature.type.trainingCertificate": "Training Certificate",
    "reporting.electronicSignature.type.trainingCertification": "Certification",

    // Training
    "reporting.tableheader.trainedTFBOOL": "Trained",
    "reporting.tableheader.certifiedTFBOOL": "Certified",
    "reporting.tableheader.createdOnDateTimeUTC": "Created On",
    "reporting.tableheader.version": "Version",
    "reporting.tableheader.versionSearch": "Version of Search",
    "reporting.tableheader.versionCertificate": "Process Version",
    "reporting.tableheader.dateCertificateDateTimeUTC": "Certificate Date",
    "reporting.tableheader.dateCertificationDateTimeUTC": "Certification Date",
    "reporting.tableheader.versionCertification": "Process Version",
    "reporting.tableheader.dateTimeUTC": "Date",
    "reporting.tableheader.dateSignatureDateTimeUTC": "Signature Date",
    "reporting.tableheader.trainerName": "Trainer",
    "reporting.tableheader.trainerDateTimeUTCArray": "Trainer Signature Date",
    "reporting.tableheader.trainerContext": "Trainer Context",
    "reporting.tableheader.archivedOnUTC": "Archive Date",
    "reporting.tableheader.trainingArchiveDateUTC": "Training Archive Date",
    "reporting.tableheader.auditorName": "Auditor",
    "reporting.tableheader.auditorDateTimeUTC": "Auditor's Signature Date",
    "reporting.tableheader.auditorContext": "Auditor Context",
    "reporting.tableheader.type": "Type",
    "reporting.tableheader.processCopiedFrom": "Copied Process",
    "reporting.tableheader.processCopiedVersion": "Copied Process Version",

    /**
     * KORTEX SEARCH FIELD
     */
    "kortexSearchField.placeholder": "Search",

    /**
     * Password Changed Dialog
     */
    "passwordChangedDialog.dialogTitle": "Your password is expired!",
    "passwordChangedDialog.oldPassword": "Old Password",
    "passwordChangedDialog.newPassword": "New Password",
    "passwordChangedDialog.retypePassword": "Retype Password",
    "passwordChangedDialog.submit": "Submit",

    /**
     * selectProgressRunInfo
     */
    "selectProgressRunInfo.lastRunFailed": "Execution failed, do you want to resume this execution?",
    "selectProgressRunInfo.runInProgress": "Execution stopped, do you want to resume this execution?",
    "selectProgressRunInfo.startTime": "Start Time",
    "selectProgressRunInfo.stopTime": "Stop Time",
    "selectProgressRunInfo.trackingInstanceList": "Tracking instance list",

    /**
     * Task
     */
    "tasks.statusOpen": "Open",
    "tasks.statusClose": "Close",
    "tasks.statusUnknown": "Unknown",
    "tasks.group": "Group",
    "tasks.reviewer": "Reviewer",
    "tasks.from": "From",
    "tasks.to": "To",
    "tasks.clearFilters": "Clear Filters",
    "tasks.optionShowArchived": "Show archived",
    "tasks.status": "Status",
    "tasks.requestedOn": "Requested Date",
    "tasks.taskType.processApproval": "Process Approval",
    "tasks.taskType.processReview": "Process Review",
    "tasks.taskType.unknown": "Unknown Task",
    "tasks.taskStatus.open": "Open",
    "tasks.taskStatus.close": "Close",
    "tasks.taskStatus.archive": "Archive",
    "tasks.taskStatus.unknown": "Unknown Status",

    "tasks.taskCard.status": "Status",
    "tasks.taskCard.reference": "Reference",
    "tasks.taskCard.submitted": "Submitted date",
    "tasks.taskCard.requestedBy": "Submitted by",
    "tasks.taskCard.processName": "Process name",
    "tasks.taskCard.processId": "Process id",
    "tasks.taskCard.processPath": "Process path",
    "tasks.taskCard.approverGroup": "Approval group",
    "tasks.taskCard.reviewer": "Reviewer",
    "tasks.taskCard.comments": "Comments",
    "tasks.taskCard.requestedCompletedBy": "Requested Date",
    "tasks.taskCard.title": "Title",

    /**
     * VARIABLE PICKER
     */
    "variablePicker.append": "(Append)",

    /**
     * SPECIFIC WIDGETS
     */

    /**
     * DATA TABLE
     */
    "datatable.textLabels.body.noMatch": "No records, press generate to get new report",
    "datatable.textLabels.body.loading": "Loading results....",
    "datatable.textLabels.body.toolTip": "Sort",

    "datatable.textLabels.pagination.next": "Next Page",
    "datatable.textLabels.pagination.previous": "Previous Page",
    "datatable.textLabels.pagination.rowsPerPage": "Rows per page:",
    "datatable.textLabels.pagination.displayRows": "of",

    "datatable.textLabels.toolbar.search": "Search",
    "datatable.textLabels.toolbar.downloadCsv": "Download CSV",
    "datatable.textLabels.toolbar.print": "Print",
    "datatable.textLabels.toolbar.viewColumns": "View Columns",
    "datatable.textLabels.toolbar.filterTable": "Filter Table",

    "datatable.textLabels.filter.all": "All",
    "datatable.textLabels.filter.title": "FILTERS",
    "datatable.textLabels.filter.reset": "RESET",

    "datatable.textLabels.viewColumns.title": "Show Columns",
    "datatable.textLabels.viewColumns.titleAria": "Show/Hide Table Columns",

    "datatable.textLabels.selectedRows.text": "row(s) selected",
    "datatable.textLabels.selectedRows.delete": "Delete",
    "datatable.textLabels.selectedRows.deleteAria": "Delete Selected Rows",

    /*
        NOTIFICATION CENTER
    */
    "notificationCenter.notificationCard.titleDilaog": "Notification center",
    "notificationCenter.notificationCard.title": "Title",
    "notificationCenter.notificationCard.explanation": "Explanation",
    "notificationCenter.notificationCard.suggestion": "Suggestion",
    "notificationCenter.details": "Details",
    "notificationCenter.severity": "Severity",
    "notificationCenter.date": "Date",

    /*
        SCANNER
    */
    "scanner.codeListPlaceholder": "Scan an item",
    "scanner.error.invalidCode": "Invalid code: ",
    "scanner.error.invalidItem": "Invalid item: ",
    "scanner.error.invalidKanban": "Invalid kanban: ",
    "scanner.error.invalidTable": "Invalid table: ",
    "scanner.error.noItem": "No item was sent",
    "scanner.error.noKanban": "No kanban was sent",
    "scanner.error.noTable": "No table was sent",
    "scanner.error.noValidItem": "No valid item",
    "scanner.error.kanbanAlreadyScanned": "A kanban has already been scanned",
    "scanner.error.tableAlreadyScanned": "A table has already been scanned",
    "scanner.item": "Item",
    "scanner.kanban": "Kanban",
    "scanner.lotNumber": "Lot #",
    "scanner.quantity": "Qty",
    "scanner.scanItem": "Scan an item",
    "scanner.scanKanban": "Scan a kanban",
    "scanner.scanTable": "Scan a table",
    "scanner.send": "SEND",
    "scanner.sentSuccessfully": "Sent successfully",
    "scanner.table": "Table",
};
