import { theme } from "@aos/react-components";
import { makeStyles } from "@material-ui/core";

import { APP_TOP_BAR_HEIGHT } from "../../../../configs/app";

export const globalSettingsStyles = makeStyles({
    root: {
        height: `calc(100vh - ${APP_TOP_BAR_HEIGHT}px)`,
    },
    tab: {
        padding: "16px",
        margin: "16px",
        overflowY: "auto",
        height: "100%",
    },
    textFieldExtraSmall: {
        marginRight: "16px",
        width: "100px",
    },
    textFieldSmall: {
        marginRight: "16px",
        width: "220px",
    },
    textField: {
        marginRight: "16px",
        minWidth: "300px",
    },
    textArea: {
        display: "block",
        width: "100%",
    },
    textAreaInner: {
        width: "100%",
    },
    textFieldLarge: {
        marginRight: "16px",
        width: "266px",
    },
    divider: {
        marginTop: "16px",
        marginBottom: "16px",
    },
    langList: {
        color: theme.palette.grey[600],
    },
});
