import {
    SetElectronicSignatureListAction,
    ElectronicSignatureInsertedAction,
    ElectronicSignatureUpdatedAction,
    ElectronicSignatureActionType,
} from "./electronic-signature-types";

/**
 * Returns an action
 *
 * @param {SetElectronicSignatureListAction} electronicSignatures - value of the action
 */
export function setElectronicSignatureListAction(
    electronicSignatures: SetElectronicSignatureListAction["value"]
): SetElectronicSignatureListAction {
    return { type: ElectronicSignatureActionType.UPDATE_ELECTRONIC_SIGNATURES, value: electronicSignatures };
}

/**
 * Returns an action
 *
 * @param {ElectronicSignatureInsertedAction} electronicSignature - value of the action
 */
export function electronicSignatureInsertedAction(
    electronicSignature: ElectronicSignatureInsertedAction["value"]
): ElectronicSignatureInsertedAction {
    return { type: ElectronicSignatureActionType.INSERT_ELECTRONIC_SIGNATURE, value: electronicSignature };
}

/**
 * Returns an action
 *
 * @param {ElectronicSignatureUpdatedAction} electronicSignature - value of the action
 */
export function electronicSignatureUpdatedAction(
    electronicSignature: ElectronicSignatureUpdatedAction["value"]
): ElectronicSignatureUpdatedAction {
    return { type: ElectronicSignatureActionType.UPDATE_ELECTRONIC_SIGNATURE, value: electronicSignature };
}
