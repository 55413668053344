import * as React from "react";
import { useEffect, useState } from "react";

import KortexSearchField from "../../../core/KortexSearchField";

export interface IFilters {
    plainText: string;
}

export interface IEnabledFilters {
    plainText?: boolean;
}

interface IOwnProps {
    enabledFilters?: IEnabledFilters;
    onFiltersChange: (filters: IFilters) => void;
}

export const defaultFilters: IFilters = {
    plainText: "",
};

export default function ProcessRepositorySearchBar(props: IOwnProps): JSX.Element {
    const { onFiltersChange } = props;
    const [filters, setFilters] = useState<IFilters>(defaultFilters);

    /**
     * When filters change, execute onChange cb
     */
    useEffect((): void => {
        onFiltersChange(filters);
    }, [filters]);

    /**
     *
     * Called when the text in the searchbar changes
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        setFilters({ ...filters, plainText: val });
    };

    return (
        <div>
            <KortexSearchField
                InputProps={{ id: "processRepositorySearchBarId" }}
                onChange={handleSearchTextChange}
                hideFiltersButton={true}
            />
        </div>
    );
}
