export const trainingCertificate = {
    rpc: {
        getAllTrainingCertificate: {
            funcId: 13201,
            funcName: "trainingCertificateGetAllTrainingCertificate",
        },
        insert: {
            funcId: 13202,
            funcName: "trainingCertificateInsert",
        },
    },
    serviceId: 132,
    serviceVersion: 1,
};
