import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    FailureTypesAllReq,
    FailureTypesAllRes,
    FailureTypesInsertReq,
    FailureTypesInsertRes,
    FailureTypesInsertedNotif,
    FailureTypesUpdatedNotif,
    FailureTypesUpdateReq,
    FailureTypesUpdateRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { failureTypesAll } from "./all";
import { failureTypesInsert } from "./insert";
import { failureTypesUpdate } from "./update";
import { failureTypesInserted } from "./inserted";
import { failureTypesUpdated } from "./updated";

interface IAOSClientServiceWrappedFailureTypes {
    getAll: AOSClientServiceRequestWrapped<FailureTypesAllReq, FailureTypesAllRes>;
    insert: AOSClientServiceRequestWrapped<FailureTypesInsertReq, FailureTypesInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<FailureTypesInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<FailureTypesUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<FailureTypesUpdateReq, FailureTypesUpdateRes>;
}

type AOSClientServiceWrappedFailureTypes = IAOSClientServiceWrappedFailureTypes;

// ADD_API_CALL Failure Types
export function generateFailureTypesServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedFailureTypes {
    return {
        getAll: AOSClientServiceWrapperUI.request(failureTypesAll(router)),
        insert: AOSClientServiceWrapperUI.request(failureTypesInsert(router)),
        update: AOSClientServiceWrapperUI.request(failureTypesUpdate(router)),
        onInserted: AOSClientServiceWrapperUI.notification(failureTypesInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(failureTypesUpdated(router, notifier)),
    };
}

generateFailureTypesServiceClient.serviceId = 70;
