import {
    ClearTreesAction,
    SetEditedTreeProcessNodeIdAction,
    SetTreeFileAction,
    SetTreeProcessAction,
    SetTreeWorkZoneAction,
    TreeActionType,
    TreeFileInsertedAction,
    TreeFileUpdatedAction,
    TreeProcessInsertedAction,
    TreeProcessUpdatedAction,
    TreeWorkZoneInsertedAction,
    TreeWorkZoneUpdatedAction,
} from "./tree-types";

/**
 * Returns an action
 *
 */
export function clearTreesAction(): ClearTreesAction {
    return { type: TreeActionType.CLEAR_TREES };
}

/**
 * Returns an action
 *
 * @param {SetEditedTreeProcessNodeIdAction} treeNodeId - value of the action
 */
export function setEditedTreeProcessNodeIdAction(treeNodeId: SetEditedTreeProcessNodeIdAction["value"]): SetEditedTreeProcessNodeIdAction {
    return { type: TreeActionType.SET_EDITED_TREE_PROCESS_NODE_ID, value: treeNodeId };
}

/**
 * Returns an action
 *
 * @param {SetTreeFileAction} node - value of the action
 */
export function setTreeFileAction(node: SetTreeFileAction["value"]): SetTreeFileAction {
    return { type: TreeActionType.SET_FILES, value: node };
}

/**
 * Returns an action
 *
 * @param {SetTreeProcessAction} node - value of the action
 */
export function setTreeProcessAction(node: SetTreeProcessAction["value"]): SetTreeProcessAction {
    return { type: TreeActionType.SET_PROCESSES, value: node };
}

/**
 * Returns an action
 *
 * @param {SetTreeWorkZoneAction} node - value of the action
 */
export function setTreeWorkZoneAction(node: SetTreeWorkZoneAction["value"]): SetTreeWorkZoneAction {
    return { type: TreeActionType.SET_WORK_ZONES, value: node };
}

/**
 * Returns an action
 *
 * @param {TreeFileInsertedAction} node - value of the action
 */
export function treeFileInsertedAction(node: TreeFileInsertedAction["value"]): TreeFileInsertedAction {
    return { type: TreeActionType.INSERT_FILE, value: node };
}

/**
 * Returns an action
 *
 * @param {TreeFileUpdatedAction} node - value of the action
 */
export function treeFileUpdatedAction(node: TreeFileUpdatedAction["value"]): TreeFileUpdatedAction {
    return { type: TreeActionType.UPDATE_FILE, value: node };
}

/**
 * Returns an action
 *
 * @param {TreeProcessInsertedAction} node - value of the action
 */
export function treeProcessInsertedAction(node: TreeProcessInsertedAction["value"]): TreeProcessInsertedAction {
    return { type: TreeActionType.INSERT_PROCESS, value: node };
}

/**
 * Returns an action
 *
 * @param {TreeProcessUpdatedAction} node - value of the action
 */
export function treeProcessUpdatedAction(node: TreeProcessUpdatedAction["value"]): TreeProcessUpdatedAction {
    return { type: TreeActionType.UPDATE_PROCESS, value: node };
}

/**
 * Returns an action
 *
 * @param {TreeWorkZoneInsertedAction} node - value of the action
 */
export function treeWorkZoneInsertedAction(node: TreeWorkZoneInsertedAction["value"]): TreeWorkZoneInsertedAction {
    return { type: TreeActionType.INSERT_WORK_ZONE, value: node };
}

/**
 * Returns an action
 *
 * @param {TreeWorkZoneUpdatedAction} node - value of the action
 */
export function treeWorkZoneUpdatedAction(node: TreeWorkZoneUpdatedAction["value"]): TreeWorkZoneUpdatedAction {
    return { type: TreeActionType.UPDATE_WORK_ZONE, value: node };
}
