export const processActionSettings = {
    rpc: {
        all: {
            funcId: 6101,
            funcName: "processActionSettingsAll",
        },
        update: {
            funcId: 6102,
            funcName: "processActionSettingsUpdate",
        },
        updated: {
            funcId: 6103,
            funcName: "processActionSettingsUpdated",
        },
    },
    serviceId: 61,
    serviceVersion: 1,
};
