import { ConnectorShellConfig, ProcessEditorRightsEnum, ProcessVariableStorer, ProcessVariableStoringMethod } from "@kortex/aos-common";
import { Checkbox, Typography, makeStyles } from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import * as React from "react";

import VariablePicker from "../../../../../../../../components/core/VariablePicker/VariablePicker";
import { useTranslate } from "../../../../../../../../hooks/useTranslate";
import { IUserRightsProps, userCanWrite } from "../../../../../../../../utilitites/IUserRights";
import ShellCommandList from "../ShellCommandList/ConnectorShellCommandList";

const useStyles = makeStyles({
    checkboxLabel: {
        cursor: "pointer",
    },
    failOnCommandFailure: {
        alignItems: "center",
        display: "inline-flex",
        justifyContent: "flex-end",
        width: "100%",
    },
    row: {
        alignItems: "flex-end",
        display: "inline-flex",
        width: "100%",
    },
    speedDial: {
        bottom: "38px",
        position: "fixed",
        right: "48px",
    },
    workingDirectoryTextField: {
        marginTop: "16px",
        flex: 1,
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    onChange: (actionStepProps: ConnectorShellConfig) => void;
    shellProps: ConnectorShellConfig;
}

export default function ConnectorShellEditor(props: IOwnProps): JSX.Element {
    const { onChange, shellProps, userAccessLevel } = props;
    const { commands, consoleOutputStoreTo, failOnCommandFailure } = shellProps;

    const classes = useStyles();
    const translate = useTranslate();

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Called when adding a command to the list with the speed dial
     */
    const handleAddCommand = (): void => {
        const clone = [...commands];
        clone.push("");

        onChange({ ...shellProps, commands: clone });
    };

    /**
     * Called when a command in the list is changed
     *
     * @param {string[]} commands - updated command array
     */
    const handleCommandChange = (commands: string[]): void => {
        onChange({ ...shellProps, commands });
    };

    /**
     * Called when the "console output store to" textfield value is changed
     *
     * @param {string} identifier - updated working directory
     * @param {ProcessVariableStoringMethod} storingMethod - variable storing method
     */
    const handleConsoleOutputStoreToChange = (identifier: string, storingMethod?: ProcessVariableStoringMethod): void => {
        const method = storingMethod ? storingMethod : consoleOutputStoreTo.storingMethod;

        if (method !== consoleOutputStoreTo.storingMethod || identifier !== consoleOutputStoreTo.identifier) {
            onChange({
                ...shellProps,
                consoleOutputStoreTo: new ProcessVariableStorer(identifier, storingMethod),
            });
        }
    };

    /**
     * Called when the working directory textfield value is changed
     *
     * @param {string} workingDirectory - updated working directory
     */
    const handleWorkingDirectoryChange = (workingDirectory: string): void => {
        onChange({ ...shellProps, workingDirectory });
    };

    /**
     * Handle change of failOnCommandFailure checkbox
     */
    const handleFailOnCommandFailureChecked = (): void => {
        onChange({
            ...shellProps,
            failOnCommandFailure: !shellProps.failOnCommandFailure,
        });
    };

    return (
        <React.Fragment>
            <div className={classes.row}>
                <VariablePicker
                    onChange={handleWorkingDirectoryChange}
                    KortexTextFieldProps={{
                        className: classes.workingDirectoryTextField,
                        label: translate("action.connector.shell.workingDirectory"),
                    }}
                    userAccessLevel={userAccessLevel}
                    value={shellProps.workingDirectory}
                />
                <VariablePicker
                    onChange={handleConsoleOutputStoreToChange}
                    storingMethod={consoleOutputStoreTo.storingMethod}
                    KortexTextFieldProps={{
                        // className: classes.storeTo,
                        label: translate("action.connector.storeConsoleOutputTo"),
                    }}
                    userAccessLevel={userAccessLevel}
                    value={consoleOutputStoreTo.identifier}
                />
            </div>
            <div className={classes.failOnCommandFailure}>
                <Checkbox defaultChecked={failOnCommandFailure} onChange={handleFailOnCommandFailureChecked} disabled={readOnly} />
                <Typography className={classes.checkboxLabel} onClick={handleFailOnCommandFailureChecked}>
                    {translate("action.connector.failOnCommandFailure")}
                </Typography>
            </div>
            <ShellCommandList commands={commands} onChange={handleCommandChange} userAccessLevel={userAccessLevel} />
            <SpeedDial
                ariaLabel="ShellCommandSpeedDial"
                className={classes.speedDial}
                hidden={readOnly}
                icon={<SpeedDialIcon />}
                onClick={handleAddCommand}
                open={false}
            >
                {/* To prevent error, SpeedDial requires a children with ButtonProps */}
                <SpeedDialAction icon={<div />} tooltipOpen={false} tooltipTitle="" onClick={undefined} />
            </SpeedDial>
        </React.Fragment>
    );
}
