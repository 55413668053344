import { greyPalette } from "@aos/react-components";
import {
    IWorkInstructionsElementConfig,
    ProcessActionStepWorkInstructions,
    TWorkInstructionsExtendedConfig,
    WorkInstructionsStepState,
} from "@kortex/aos-common";
import * as React from "react";

import { normalizeSVGDimension } from "../../../utilitites/normalizeSVGDimension";
import WorkInstructionsElement from "../ProcessEditor/ProcessEditor/ActionEditors/WorkInstructions/Elements/Element/WorkInstructionsElement";

import { IPage, PrintBasePage } from "./PrintBasePage";
import PrintDraftWatermark from "./PrintDraftWaterMark";
import { pageWidth } from "./printUtils";

const DEFAULT_WORK_INSTRUCTION_ELEMENT_STATE: WorkInstructionsStepState = {
    _id: "",
    formItemState: [],
    textItemState: { text: "" },
    markerItemState: { text: "" },
};
const SVG_RATIO = 1.6;

export interface IPrintInstructionProps extends IPage {
    formStepProps: ProcessActionStepWorkInstructions;
    isDraft: boolean;
}

export default function PrintInstruction(props: IPrintInstructionProps): JSX.Element {
    const { formStepProps, isDraft, ...pageInfo } = props;
    return (
        <PrintBasePage {...pageInfo}>
            <g>
                <TestInstruction actionStepProps={formStepProps} />
                <foreignObject x="0" y="0" width="100%" height="100%">
                    <div xmlns="http://www.w3.org/1999/xhtml">
                        <PrintDraftWatermark isDraft={isDraft} />
                    </div>
                </foreignObject>
            </g>
        </PrintBasePage>
    );
}

// **** TEST ****
function TestInstruction({ actionStepProps }: { actionStepProps: ProcessActionStepWorkInstructions }): JSX.Element {
    const instructionWidth = pageWidth - 20;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="10"
            y="40"
            width={normalizeSVGDimension(instructionWidth)}
            height={normalizeSVGDimension(instructionWidth / SVG_RATIO)}
            viewBox={"0 0 1280 800"}
        >
            <defs>
                <path id="marker" d="M16 48 L32 24 L32 0 L0 0 L0 24 Z" />
            </defs>

            {actionStepProps.config.workInstructionElements.map(
                (elementProps: IWorkInstructionsElementConfig<TWorkInstructionsExtendedConfig>, index): JSX.Element => (
                    <g key={index}>
                        <WorkInstructionsElement
                            selected={false}
                            elementProps={elementProps}
                            elementState={DEFAULT_WORK_INSTRUCTION_ELEMENT_STATE}
                            playMode={false}
                        />
                    </g>
                )
            )}
            <rect width="100%" height="100%" x="0" y="0" fill="transparent" stroke={greyPalette[500]} strokeWidth="2" />
        </svg>
    );
}
