import { IOrgSettingDbModel } from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export enum SettingOrganizationActionType {
    SETTING_ORGANIZATION_FETCH_ALL = "@@SETTINGS/ORGANIZATION/FETCH/ALL",
    SETTING_ORGANIZATION_NOTIFICATION_INSERTED = "@@SETTINGS/ORGANIZATION/NOTIFICATION/INSERTED",
    SETTING_ORGANIZATION_NOTIFICATION_UPDATED = "@@SETTINGS/ORGANIZATION/NOTIFICATION/UPDATED",
}

interface IOrganizationState {
    settingOrganization: IOrgSettingDbModel[];
}

export type SettingOrganizationState = IOrganizationState;

export type SettingOrganizationInsertedAction = ComplexAction<
    SettingOrganizationActionType.SETTING_ORGANIZATION_NOTIFICATION_INSERTED,
    IOrgSettingDbModel
>;
export type SettingOrganizationUpdatedAction = ComplexAction<
    SettingOrganizationActionType.SETTING_ORGANIZATION_NOTIFICATION_UPDATED,
    IOrgSettingDbModel[]
>;

export type SetSettingOrganizationListAction = ComplexAction<
    SettingOrganizationActionType.SETTING_ORGANIZATION_FETCH_ALL,
    IOrgSettingDbModel[]
>;

export type SettingOrganizationActions =
    | SettingOrganizationInsertedAction
    | SettingOrganizationUpdatedAction
    | SetSettingOrganizationListAction;
