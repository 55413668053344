import { TreeFileInsertType } from "@kortex/aos-common";
import Compressor from "compressorjs";

import * as CloudStorage from "../storage";

/**
 * Compression quality, the lower the value the lowest the quality
 * Only applied to JPEG images
 * Possible values are from 0 to 1
 * It is not recommended to stay between 0.6 and 0.8
 */
const COMPRESSION_QUALITY = 0.6;

/**
 * PNG are converted to JPEG images by the compressor
 * This value defines which size must be the PNG in order to get converted
 * by the compressor. PNG with size below this value won't be compressed at all
 * 0 means all PNGs will be converted to JPEGs
 */
const CONVERT_SIZE_BYTES = 0;

/**
 * Maximum file size that can be posted to cloud storage
 */
export const MAX_FILE_SIZE_BYTES = 5 * 1000000; // 5MB

/**
 * Upload a file to the storage server and store file information into database
 *
 * @param {File} file - File blob (data)
 * @param {TreeFileInsertType} fileType - File type
 *
 * @returns {string} - Generated unique file id returned by the storage server
 */
export function uploadFile(file: File, fileType: TreeFileInsertType): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
        if (!file || !file.size) {
            return reject(new Error("storage.error.invalid.file"));
        }

        try {
            // If file of type image, we compress before uploading
            if (fileType === TreeFileInsertType.IMAGE) {
                const compressedFile = await compressImageFile(file);
                resolve(await postFile(compressedFile));
            } else {
                resolve(await postFile(file));
            }
        } catch (error) {
            reject(error);
        }
    });
}

/**
 *
 * Post file to cloud storage server
 *
 * @param {File} file - File to be posted to cloud server
 *
 * @returns {string} - Generated unique file id returned by the storage server
 */
function postFile(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const url = `${CloudStorage.get().host}/upload`;
        const formData = new FormData();

        // Checked at post to consider image file compression
        if (file.size > MAX_FILE_SIZE_BYTES) {
            return reject(new Error("storage.error.max.size"));
        }

        formData.append("uploadedFile", file);

        fetch(url, {
            method: "POST",
            body: formData,
        })
            .then(async (req) => {
                const json = (await req.json()) as { fileId: string };
                resolve(json.fileId);
            })
            .catch((err): void => {
                console.error(err);
                reject(new Error("storage.error.upload.server"));
            });
    });
}

/**
 *
 * Compress jpeg image file, and converts .png into .jpg files
 *
 * @param {File} sourceFile - Image file to be compressed
 *
 * @returns {File} - Compressed file
 */
function compressImageFile(sourceFile: File): Promise<File> {
    return new Promise<File>((resolve, reject) => {
        // Start compression
        try {
            // Define compressor success function
            const success = (compressedFile: File): void => {
                resolve(compressedFile);
            };

            // Define compressor error function
            const error = (error: Error): void => {
                console.error(error);
                reject(new Error("Error compressing image file, invalid image source"));
            };

            new Compressor(sourceFile, { quality: COMPRESSION_QUALITY, convertSize: CONVERT_SIZE_BYTES, success, error });
        } catch (error) {
            reject(new Error("Error compressing image file, invalid image source"));
        }
    });
}
