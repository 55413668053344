import { ProcessUiModel } from "@kortex/aos-common";

/**
 * Sorts a process list by the process versions
 */
export function sortProcessByVersions(processList: ProcessUiModel[]): ProcessUiModel[] {
    return processList.sort((version1, version2) => {
        const version1Parts = (version1.version || "").split(".").map((version: string): number => parseInt(version, 10));
        const version2Parts = (version2.version || "").split(".").map((version: string): number => parseInt(version, 10));

        for (const [index, part] of version1Parts.entries()) {
            if (part < version2Parts[index]) {
                return 1;
            }
            if (part > version2Parts[index]) {
                return -1;
            }
        }

        return 0;
    });
}

/**
 * Get the latest process version from an array of processes
 */
export function getLastestProcessVersion(processVersions: ProcessUiModel[] = []): string {
    const releaseVersion = processVersions.filter((processVersion): boolean => !processVersion.isDraft);

    if (releaseVersion.length) {
        const orderedVersions = sortProcessByVersions(releaseVersion);

        if (orderedVersions[0]?.version) {
            return orderedVersions[0].version;
        }
    }

    return "0.0";
}
