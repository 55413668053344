export function createResultFailUiModel() {
    return {
        resultMasterId: 0,
        resultProcessFailId: 0,
        reworkItemStatusId: 0,
        processActionId: 0,
        processActionStepIndex: -1,
        quantity: 0,
        description: "",
        failedOn: 0,
        processActionLabel: "",
        processActionStepLabel: "",
    };
}
