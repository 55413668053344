var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Switch(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M13.9285714,17.1428571 L13.9285714,21 L10.0714286,21 L10.0714286,17.1428571 L13.9285714,17.1428571 Z M21,17.1428571 L21,21 L17.1428571,21 L17.1428571,17.1428571 L21,17.1428571 Z M6.85714286,17.1428571 L6.85714286,21 L3,21 L3,17.1428571 L6.85714286,17.1428571 Z M12.7221254,8.14285714 L12.7221254,11.1199195 L19.7142857,11.1199195 L19.7142857,15.8571429 L18.912892,15.8571429 L18.912892,11.9891348 L12.7221254,11.9891348 L12.7221254,15.8571429 L11.9207317,15.8571429 L11.9207317,11.9891348 L5.72996516,11.9891348 L5.72996516,15.8571429 L4.92857143,15.8571429 L4.92857143,11.1199195 L11.9207317,11.1199195 L11.9207317,8.14285714 L12.7221254,8.14285714 Z M13.9285714,3 L13.9285714,6.85714286 L10.0714286,6.85714286 L10.0714286,3 L13.9285714,3 Z", id: "icons-switch", transform: "translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) " })));
}
export default Switch;
