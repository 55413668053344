import { newProcessActionSettingsWorkInstruction } from "./IProcessActionSettingsWorkInstruction";
/**
 * Creates a new database model for process action  settings
 */
export function newProcessActionSettingsDbModel(type) {
    switch (type) {
        // The only available type for now is Work Instruction, remove default when other types are available
        default:
            return {
                processActionSettingsId: 0,
                settings: newProcessActionSettingsWorkInstruction(),
                type,
            };
    }
}
