import {
    FileInfoDbModel,
    IGetAllJobProcessesRes,
    IGetAllJobsRes,
    IGetAllReworksRes,
    IJobsFiltersOptions,
    IOperatorJobProcessesFiltersOptions,
    IReworkUiModel,
    IReworksFiltersOptions,
    ITreeNodeDbModel,
    IUserTrainingFilters,
    TNotification,
    TTaskProcessApproval,
    TTaskProcessReview,
    TTaskUnknown,
    TaskTypeEnum,
    TrainingCertificationPendingGetAllResponse,
    TrainingPendingGetAllRequest,
    TrainingPendingGetAllResponse,
    TrainingReadingCertificatePendingsGetResponse,
    UserId,
} from "@kortex/aos-common";
import { Mutex } from "async-mutex";
import { useEffect, useState } from "react";

import { useThunkDispatch } from "../hooks/useThunkDispatch";

import { OrUndefined } from "./app.types";
import { bomFollowUpSymptomGetAll } from "./bom-follow-up/bom-follow-up-symptom-thunks";
import { electronicSignatureGetAll } from "./electronic-signature-manager/electronic-signature-thunks";
import { getFailureSymptoms } from "./failures-manager/failures-thunks-symptom";
import { getFailureTypes } from "./failures-manager/failures-thunks-type";
import { fileGetByFileId } from "./file-manager/file-thunks-file";
import { connectionList } from "./general-manager/general-thunks-general";
import { notificationsGetAll } from "./notifications-center-manager/notifications-thunks";
import { processActionSettingsGetAll } from "./process-manager/process-thunks-process-action-settings";
import { resultSettingItemsGetAll } from "./result-setting-manager/result-setting-item-thunks";
import { resultSettingMetadataGetAll } from "./result-setting-manager/result-setting-metadata-thunks";
import { reworkItemStatusGetAll } from "./rework-manager/rework-item-status-thunks";
import { reworkJobProcessGetAll } from "./rework-manager/rework-job-process-thunks";
import { reworkLogGetAll } from "./rework-manager/rework-log-thunks";
import { reworkGetAll } from "./rework-manager/rework-thunks";
import { rootCauseGetAll } from "./rework-manager/root-cause-thunks";
import { jobGetAllJob } from "./scheduler-manager/scheduler-thunks-job";
import {
    SelectorGroupsOptions,
    SelectorTasksOptions,
    useSelectorBomFollowUpSymptom,
    useSelectorConnectionList,
    useSelectorElectronicSignature,
    useSelectorFailureSymptoms,
    useSelectorFailureTypes,
    useSelectorFiles,
    useSelectorJobProcesses,
    useSelectorJobs,
    useSelectorNotification,
    useSelectorProcessActionSettings,
    useSelectorResultSettingItems,
    useSelectorResultSettingMetadata,
    useSelectorReworkItemStatus,
    useSelectorReworkJobsProcess,
    useSelectorReworkLogs,
    useSelectorReworks,
    useSelectorRootCause,
    useSelectorSettingOrganizations,
    useSelectorTasks,
    useSelectorTrainingCertificationPendingList,
    useSelectorTrainingCertificationPendingOfUser,
    useSelectorTrainingPendingList,
    useSelectorTrainingPendingOfUser,
    useSelectorTrainingReadingCertificatePendings,
    useSelectorTreeFile,
    useSelectorTreeProcess,
    useSelectorTreeWorkZone,
    useSelectorUserSession,
    useSelectorUserTrainings,
    useSelectorUsers,
    useSelectorUsersGroups,
    useSelectorUsersRoles,
} from "./selectors";
import { settingOrganizationGetAll } from "./setting-organization-manager/setting-organization-thunks-settings";
import { taskGetAll } from "./task-manager/task-thunks-task";
import {
    trainingCertificationPendingGetAll,
    trainingCertificationPendingGetAllByUserId,
} from "./training-certification-pending-manager/training-certification-pending-thunks";
import { trainingPendingGetAll, trainingPendingGetAllByUserId } from "./training-pending-manager/training-pending-thunks";
import { trainingReadingCertificatePendingGetAll } from "./training-reading-certificate-pending-manager/training-reading-certificate-pending-thunks";
import { treeFileGet } from "./tree-manager/tree-thunks-file";
import { treeProcessGet } from "./tree-manager/tree-thunks-process";
import { treeWorkZoneGet } from "./tree-manager/tree-thunks-work-zone";
import { userGetAll } from "./user-manager/users-thunks-user";
import { userGroupGetAll } from "./user-manager/users-thunks-user-group";
import { userRoleGetAll } from "./user-manager/users-thunks-user-role";
import { userTrainingGetAllByUserId } from "./user-training-manager/user-training-thunks";
import { jobProcessGetAllActiveJobProcesses } from "./work-scheduler-manager/work-schedule-thunks-job";

/**
 * Ensures sequential async api calls when using entities
 *
 * @param {Function} who - who needs to run exclusively
 * @param {() => void} what - what needs to run exclusively
 */
const runExclusive = ((): ((who: Function, what: () => void) => void) => {
    const exclusive = new WeakMap<Function, Mutex["runExclusive"]>();

    return function (who: Function, what: () => void): void {
        if (!exclusive.has(who)) {
            exclusive.set(who, Mutex.prototype.runExclusive.bind(new Mutex()));
        }

        (exclusive.get(who) as Mutex["runExclusive"])(what);
    };
})();

/**
 * hook to keep connection list in sync; when notification are available
 */
export function useEntitiesConnectionList(): ReturnType<typeof useSelectorConnectionList> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesConnectionList, async () => dispatch(connectionList()));
    }, []);

    return useSelectorConnectionList();
}

/**
 * hook to keep electronic signature in sync; when notification are available
 */
export function useEntitiesElectronicSignature(): ReturnType<typeof useSelectorElectronicSignature> {
    const dispatch = useThunkDispatch();
    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesElectronicSignature, async () => dispatch(electronicSignatureGetAll()));
    }, []);

    return useSelectorElectronicSignature();
}

/**
 * hook to keep failure symptoms in sync; when notification are available
 */
export function useEntitiesFailureSymptoms(): ReturnType<typeof useSelectorFailureSymptoms> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesFailureSymptoms, async () => dispatch(getFailureSymptoms()));
    }, []);

    return useSelectorFailureSymptoms();
}

/**
 * hook to keep failure types in sync; when notification are available
 */
export function useEntitiesFailureTypes(): ReturnType<typeof useSelectorFailureTypes> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesFailureTypes, async () => dispatch(getFailureTypes()));
    }, []);

    return useSelectorFailureTypes();
}

/**
 * hook to keep organization settings in sync; when notification are available
 */
export function useEntitiesSettingOrganizations(): ReturnType<typeof useSelectorSettingOrganizations> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesSettingOrganizations, async () => dispatch(settingOrganizationGetAll()));
    }, []);

    return useSelectorSettingOrganizations();
}

/**
 * hook to keep result setting item in sync; when notification are available
 */
export function useEntitiesResultSettingItems(): ReturnType<typeof useSelectorResultSettingItems> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesResultSettingItems, async () => dispatch(resultSettingItemsGetAll()));
    }, []);

    return useSelectorResultSettingItems();
}

/**
 * hook to keep result setting metadata in sync; when notification are available
 */
export function useEntitiesResultSettingMetadata(): ReturnType<typeof useSelectorResultSettingMetadata> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesResultSettingMetadata, async () => dispatch(resultSettingMetadataGetAll()));
    }, []);

    return useSelectorResultSettingMetadata();
}

/**
 * hook to keep reworks in sync; when notification are available
 */
export function useEntitiesReworks(
    withArchive: boolean,
    downloadedEndCallback?: useEntitiesReworksDownloadedEndCallback,
    limit?: number,
    offset?: number,
    filterOptions?: IReworksFiltersOptions
): ReturnType<typeof useSelectorReworks> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesReworks, async () => {
            return dispatch(reworkGetAll({ redux: { skipLookup: true, skipDispatch: false } }, limit, offset, filterOptions)).then(
                (value) => downloadedEndCallback && downloadedEndCallback(value)
            );
        });
    }, []);

    return useSelectorReworks();
}

/**
 * hook to keep rework details in sync; when notification are available
 */
export function useEntitiesReworkDetails(
    reworkId: number,
    downloadedEndCallback?: useEntitiesReworksDownloadedEndCallback,
    limit?: number,
    offset?: number,
    filterOptions?: IReworksFiltersOptions
): ReturnType<typeof useSelectorReworks> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesReworks, async () => {
            return dispatch(reworkGetAll({ redux: { skipLookup: true, skipDispatch: false } }, limit, offset, filterOptions)).then(
                (value) => downloadedEndCallback && downloadedEndCallback(value)
            );
        });
    }, []);

    return useSelectorReworks();
}

/**
 * hook to keep tasks in sync; when notification are available
 */
export function useEntitiesJobs(
    downloadedEndCallback?: useEntitiesJobsDownloadedEndCallback,
    limit?: number,
    offset?: number,
    filterOptions?: IJobsFiltersOptions
): ReturnType<typeof useSelectorJobs> {
    const dispatch = useThunkDispatch();
    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesJobs, async () => {
            return dispatch(jobGetAllJob({ redux: { skipLookup: true, skipDispatch: false } }, limit, offset, filterOptions)).then(
                (value) => downloadedEndCallback && downloadedEndCallback(value)
            );
        });
    }, []);

    return useSelectorJobs();
}

/**
 * hook to keep job Processes in sync; when notification are available
 */
export function useEntitiesJobProcessesOperators(
    downloadedEndCallback?: useEntitiesJobProcessesDownloadedEndCallback,
    limit?: number,
    offset?: number,
    filterOptions?: IOperatorJobProcessesFiltersOptions
): ReturnType<typeof useSelectorJobProcesses> {
    const dispatch = useThunkDispatch();
    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesJobProcessesOperators, async () => {
            return dispatch(jobProcessGetAllActiveJobProcesses(limit, offset, filterOptions as IOperatorJobProcessesFiltersOptions)).then(
                (value) => downloadedEndCallback && downloadedEndCallback(value)
            );
        });
    }, []);

    return useSelectorJobProcesses();
}

/**
 * hook to keep reworks in sync; when notification are available
 */
export function useEntitiesReworkLogs(rework: IReworkUiModel): ReturnType<typeof useSelectorReworkLogs> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesReworkLogs, async () =>
            dispatch(reworkLogGetAll(rework, { redux: { skipLookup: true, skipDispatch: false } }))
        );
    }, [rework]);

    return useSelectorReworkLogs().sort((logA, logB) => (logB.log.createdOn ?? 0) - (logA.log.createdOn ?? 0));
}

/**
 * hook to keep rework jobs process in sync; when notification are available
 */
export function useEntitiesReworkJobsProcess(reworkId: number): ReturnType<typeof useSelectorReworkJobsProcess> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesReworkJobsProcess, async () =>
            dispatch(reworkJobProcessGetAll(reworkId, true, 0, { redux: { skipLookup: true, skipDispatch: false } }))
        );
    }, []);

    return useSelectorReworkJobsProcess();
}

/**
 * hook to keep reworks in sync; when notification are available
 */
export function useEntitiesReworkItemStatus(): ReturnType<typeof useSelectorReworkItemStatus> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesReworkItemStatus, async () => dispatch(reworkItemStatusGetAll()));
    }, []);

    return useSelectorReworkItemStatus();
}

/**
 * hook to keep bom follow-up symptom in sync; when notification are available
 */
export function useEntitiesBomFollowUpSymptom(treeNodeId: number): ReturnType<typeof useSelectorBomFollowUpSymptom> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesBomFollowUpSymptom, async () => dispatch(bomFollowUpSymptomGetAll(treeNodeId)));
    }, []);

    return useSelectorBomFollowUpSymptom();
}

/**
 * hook to keep root cause in sync; when notification are available
 */
export function useEntitiesRootCause(treeNodeId: number): ReturnType<typeof useSelectorRootCause> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesRootCause, async () => dispatch(rootCauseGetAll(treeNodeId)));
    }, []);

    return useSelectorRootCause();
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesUserTrainings(filterOptions: IUserTrainingFilters): [ReturnType<typeof useSelectorUserTrainings>, boolean] {
    const dispatch = useThunkDispatch();
    const [loading, setLoading] = useState(false);

    useEffect((): (() => void) | void => {
        setLoading(true);
        runExclusive(useEntitiesUserTrainings, async () => {
            if (filterOptions.userId) {
                dispatch(userTrainingGetAllByUserId(filterOptions)).finally(() => setLoading(false));
            }
        });
    }, []);

    return [useSelectorUserTrainings(), loading];
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesTrainingPendingList(
    downloadedEndCallback: UseEntitiesTrainingsPendingOfUserDownloadedEndCallback,
    limit: number,
    offset: number,
    filterOptions: TrainingPendingGetAllRequest["filterOptions"]
): ReturnType<typeof useSelectorTrainingPendingList> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesTrainingPendingList, async () => {
            return dispatch(trainingPendingGetAll(limit, offset, filterOptions, { redux: { skipLookup: true, skipDispatch: false } })).then(
                (value) => downloadedEndCallback && downloadedEndCallback(value)
            );
        });
    }, []);

    return useSelectorTrainingPendingList();
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesTrainingsPendingOfUser(userId: UserId): ReturnType<typeof useSelectorTrainingPendingOfUser> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesTrainingsPendingOfUser, async () =>
            dispatch(trainingPendingGetAllByUserId(userId, { redux: { skipLookup: true, skipDispatch: false } }))
        );
    }, []);

    return useSelectorTrainingPendingOfUser();
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesTrainingsCertificationPendingOfUser(
    userId: UserId
): ReturnType<typeof useSelectorTrainingCertificationPendingOfUser> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesTrainingsCertificationPendingOfUser, async () =>
            dispatch(trainingCertificationPendingGetAllByUserId(userId, { redux: { skipLookup: true, skipDispatch: false } }))
        );
    }, []);

    return useSelectorTrainingCertificationPendingOfUser();
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesTrainingCertificationPendingList(
    downloadedEndCallback: UseEntitiesTrainingCertificationsPendingOfUserDownloadedEndCallback,
    limit: number,
    offset: number,
    filterOptions: TrainingPendingGetAllRequest["filterOptions"]
): ReturnType<typeof useSelectorTrainingCertificationPendingList> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesTrainingPendingList, async () => {
            return dispatch(
                trainingCertificationPendingGetAll(limit, offset, filterOptions, { redux: { skipLookup: true, skipDispatch: false } })
            ).then((value) => downloadedEndCallback && downloadedEndCallback(value));
        });
    }, []);

    return useSelectorTrainingCertificationPendingList();
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesTrainingCertificationsPendingOfUser(userId: UserId): ReturnType<typeof useSelectorTrainingPendingOfUser> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesTrainingsPendingOfUser, async () =>
            dispatch(trainingPendingGetAllByUserId(userId, { redux: { skipLookup: true, skipDispatch: false } }))
        );
    }, []);

    return useSelectorTrainingPendingOfUser();
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesTrainingReadingCertificatePendings(
    downloadedEndCallback: UseEntitiesTrainingReadingCertificatePendingsDownloadedEndCallback,
    limit: number,
    offset: number,
    filterOptions: TrainingPendingGetAllRequest["filterOptions"]
): ReturnType<typeof useSelectorTrainingReadingCertificatePendings> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesTrainingReadingCertificatePendings, async () => {
            return dispatch(
                trainingReadingCertificatePendingGetAll(limit, offset, filterOptions, { redux: { skipLookup: true, skipDispatch: false } })
            ).then((value) => downloadedEndCallback && downloadedEndCallback(value));
        });
    }, []);

    return useSelectorTrainingReadingCertificatePendings();
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesTreeFile(onLoad?: (treeNodes: readonly ITreeNodeDbModel[]) => void): ReturnType<typeof useSelectorTreeFile> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesTreeFile, () => dispatch(treeFileGet()).then(onLoad));
    }, []);

    return useSelectorTreeFile();
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesTreeProcess(): ReturnType<typeof useSelectorTreeProcess> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesTreeProcess, async () => dispatch(treeProcessGet()));
    }, []);

    return useSelectorTreeProcess();
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesTreeWorkZone(): ReturnType<typeof useSelectorTreeWorkZone> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesTreeWorkZone, async () => dispatch(treeWorkZoneGet()));
    }, []);

    return useSelectorTreeWorkZone();
}

/**
 * hook to keep users in sync; when notification are available
 */
export function useEntitiesUsers(): ReturnType<typeof useSelectorUsers> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesUsers, async () => dispatch(userGetAll()));
    }, []);

    return useSelectorUsers();
}

/**
 * hook to keep user groups in sync; when notification are available
 */
export function useEntitiesUsersGroups(options?: SelectorGroupsOptions): ReturnType<typeof useSelectorUsersGroups> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesUsersGroups, async () => dispatch(userGroupGetAll()));
    }, []);

    return useSelectorUsersGroups(options);
}

/**
 * hook to keep user roles in sync; when notification are available
 */
export function useEntitiesUsersRoles(): ReturnType<typeof useSelectorUsersRoles> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesUsersRoles, async () => dispatch(userRoleGetAll()));
    }, []);

    return useSelectorUsersRoles();
}

/**
 * hook to get user session
 */
export function useEntitiesUserSession(): ReturnType<typeof useSelectorUserSession> {
    return useSelectorUserSession();
}

/**
 * hook to keep tasks in sync; when notification are available
 */
export function useEntitiesTasks(option?: SelectorTasksOptions): TTaskUnknown[];
export function useEntitiesTasks<T extends SelectorTasksOptions<TaskTypeEnum.PROCESS_APPROVAL>>(option?: T): TTaskProcessApproval[];
export function useEntitiesTasks<T extends SelectorTasksOptions<TaskTypeEnum.PROCESS_REVIEW>>(option?: T): TTaskProcessReview[];
export function useEntitiesTasks<T extends SelectorTasksOptions>(
    options?: T
): TTaskUnknown[] | TTaskProcessApproval[] | TTaskProcessReview[] {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesTasks, async () => dispatch(taskGetAll()));
    }, []);

    return useSelectorTasks(options);
}

export interface useEntitiesJobsDownloadedEndCallback {
    (value: Readonly<IGetAllJobsRes>): void;
}

export interface useEntitiesJobProcessesDownloadedEndCallback {
    (value: Readonly<IGetAllJobProcessesRes>): void;
}

export interface useEntitiesReworksDownloadedEndCallback {
    (value: Readonly<IGetAllReworksRes>): void;
}

export interface UseEntitiesTrainingsPendingOfUserDownloadedEndCallback {
    (value: Readonly<TrainingPendingGetAllResponse>): void;
}

export interface UseEntitiesTrainingCertificationsPendingOfUserDownloadedEndCallback {
    (value: Readonly<TrainingCertificationPendingGetAllResponse>): void;
}

export interface UseEntitiesTrainingReadingCertificatePendingsDownloadedEndCallback {
    (value: Readonly<TrainingReadingCertificatePendingsGetResponse>): void;
}

/**
 * hook to keep tasks in sync; when notification are available
 */
export function useEntitiesProcessActionSettings(): ReturnType<typeof useSelectorProcessActionSettings> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useSelectorProcessActionSettings, async () => dispatch(processActionSettingsGetAll()));
    }, []);

    return useSelectorProcessActionSettings();
}

/**
 * hook to keep tasks in sync; when notification are available
 */
export function useEntitiesNotifications(): TNotification[] {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntitiesNotifications, async () => dispatch(notificationsGetAll()));
    }, []);

    return useSelectorNotification();
}

/**
 * hook to keep a single file in sync; when notification are available
 */
export function useEntityFileByFileId(fileId: FileInfoDbModel["fileId"]): OrUndefined<FileInfoDbModel> {
    const dispatch = useThunkDispatch();

    useEffect((): (() => void) | void => {
        runExclusive(useEntityFileByFileId, async () => dispatch(fileGetByFileId({ fileId })));
    }, []);

    return useSelectorFiles().find((file) => file.fileId === fileId);
}
