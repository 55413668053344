var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Form(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { id: "icons-form", fill: "currentColor", d: "M19.7830178,2 C21.0076676,2 22.0039261,2.99606299 21.9999884,4.22047244 L21.9999884,19.7795276 C21.9999884,21.003937 21.0037298,22 19.77908,22 L4.22090834,22 C2.99625853,22 2,21.003937 2,19.7755906 L2,4.22047244 C2,2.99606299 2.99625853,2 4.22090834,2 L19.7830178,2 Z M4.25,3.25 C3.69771525,3.25 3.25,3.69771525 3.25,4.25 L3.25,19.75 C3.25,20.3022847 3.69771525,20.75 4.25,20.75 L19.75,20.75 C20.3022847,20.75 20.75,20.3022847 20.75,19.75 L20.75,4.25 C20.75,3.69771525 20.3022847,3.25 19.75,3.25 L4.25,3.25 Z M18.5384615,9.34710744 L5.4375,9.34710744 L5.4375,6 L18.5384615,6 L18.5384615,9.34710744 Z M18.5384615,14.8264463 L5.4375,14.8264463 L5.4375,11.4793388 L18.5384615,11.4793388 L18.5384615,14.8264463 Z M5.55769231,16.5619835 L12,16.5619835 L12,18.0743802 L5.55769231,18.0743802 L5.55769231,16.5619835 Z" })));
}
export default Form;
