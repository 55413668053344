import shortid from "shortid";
export var EnumTrackingType;
(function (EnumTrackingType) {
    EnumTrackingType["NONE"] = "N";
    EnumTrackingType["MANUAL"] = "M";
    EnumTrackingType["AUTO_GENERATED"] = "A";
})(EnumTrackingType || (EnumTrackingType = {}));
export function createStepConfigInput() {
    return {
        serialNumberLabelLoc: { id: shortid.generate(), defaultMessage: "Tracking Number" },
        serialType: EnumTrackingType.MANUAL,
        serialGenerationPattern: "",
        serialRegex: "",
        serialPictureId: "",
        prepopulatedSerialNumber: { id: shortid.generate(), defaultMessage: "" },
        prepopulatedSerialNumberLock: false,
        trackingNumberLabelLoc: { id: shortid.generate(), defaultMessage: "Reference ID" },
        trackingType: EnumTrackingType.NONE,
        trackingGenerationPattern: "",
        trackingRegex: "",
        trackingPictureId: "",
        enableBatchProcessing: false,
        maxBatchQty: 0,
    };
}
