export const DATASTORE_TABLE_VALUE_COLUMN_MAXIMUM_COUNT = 16;
export var DataStoreActionTypeEnum;
(function (DataStoreActionTypeEnum) {
    DataStoreActionTypeEnum[DataStoreActionTypeEnum["READ"] = 0] = "READ";
    DataStoreActionTypeEnum[DataStoreActionTypeEnum["WRITE"] = 1] = "WRITE";
})(DataStoreActionTypeEnum || (DataStoreActionTypeEnum = {}));
export var DataStoreStorageTypeEnum;
(function (DataStoreStorageTypeEnum) {
    DataStoreStorageTypeEnum[DataStoreStorageTypeEnum["KEY_VALUE"] = 0] = "KEY_VALUE";
    DataStoreStorageTypeEnum[DataStoreStorageTypeEnum["TABLE"] = 1] = "TABLE";
    DataStoreStorageTypeEnum[DataStoreStorageTypeEnum["LOCAL_FILE"] = 2] = "LOCAL_FILE";
    DataStoreStorageTypeEnum[DataStoreStorageTypeEnum["AOS_FILE"] = 3] = "AOS_FILE";
    DataStoreStorageTypeEnum[DataStoreStorageTypeEnum["RESULTS"] = 4] = "RESULTS";
    DataStoreStorageTypeEnum[DataStoreStorageTypeEnum["STATUS"] = 5] = "STATUS";
    DataStoreStorageTypeEnum[DataStoreStorageTypeEnum["METADATA"] = 6] = "METADATA";
})(DataStoreStorageTypeEnum || (DataStoreStorageTypeEnum = {}));
export var DataStoreStorageOutputFormatEnum;
(function (DataStoreStorageOutputFormatEnum) {
    DataStoreStorageOutputFormatEnum["CSV"] = "CSV";
    DataStoreStorageOutputFormatEnum["JSON"] = "JSON";
})(DataStoreStorageOutputFormatEnum || (DataStoreStorageOutputFormatEnum = {}));
export function createStepConfigDataStore() {
    return {
        actionType: DataStoreActionTypeEnum.WRITE,
        storageType: DataStoreStorageTypeEnum.KEY_VALUE,
        storeOnFail: false,
        // Key value pair settings
        keyValueProps: [],
        // Table settings
        tableValueProps: [],
        readItemProps: [],
        // AOS cloud file settings
        aosFileProps: [],
    };
}
