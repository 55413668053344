import { greyPalette, theme } from "@aos/react-components";
import { IProcessVariable } from "@kortex/aos-common";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, makeStyles } from "@material-ui/core";
import Cancel from "@material-ui/icons/Cancel";
import React from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";

import { ProcessVariableCopyDialogPanel } from "./ProcessVariableCopyDialogPanel";

const useStyles = makeStyles({
    dialogTitle: {
        ...theme.typography.h5,
        display: "flex",
        backgroundColor: theme.palette.primary[500],
        color: theme.palette.common.white,
        padding: "20px",
    },
    explanation: {
        flex: 1,
        marginBottom: "20px",
    },
    dialogActions: {
        backgroundColor: greyPalette[200],
        margin: "0px",
        padding: "8px 4px",
    },
    dialogButtons: {
        margin: "5px",
    },
    buttonIcons: {
        marginRight: "10px",
    },
});

interface IProps {
    onClose: () => void;
    variableDuplicatesWithDifferentProps: IProcessVariable[];
    variables: IProcessVariable[];
}

export function ProcessVariableCopyDialog(props: IProps): JSX.Element {
    const { onClose, variableDuplicatesWithDifferentProps, variables } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <Dialog fullWidth={true} id="ProcessVariableCopyDialogId" maxWidth="md" open={Boolean(variableDuplicatesWithDifferentProps.length)}>
            <DialogTitle className={classes.dialogTitle} disableTypography={true}>
                {translate("processVariableCopyDialog.title")}
            </DialogTitle>
            <DialogContent>
                <Typography className={classes.explanation} variant="h4">
                    {translate("processVariableCopyDialog.explanation")}
                </Typography>
                {variableDuplicatesWithDifferentProps.map(
                    (duplicate, index): JSX.Element => (
                        <ProcessVariableCopyDialogPanel
                            duplicate={duplicate}
                            key={"ProcessVariableCopyDialogExpand" + index}
                            original={variables.find((variable) => variable.identifier === duplicate.identifier) as IProcessVariable}
                        />
                    )
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    className={classes.dialogButtons}
                    color="default"
                    id="ProcessVariableCopyDialogIdCloseButtonId"
                    onClick={onClose}
                    variant="contained"
                >
                    <Cancel className={classes.buttonIcons} />
                    {translate("processInfo.close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
