import { KortexDialogConfirmation, KortexTextField, theme } from "@aos/react-components";
import {
    EnumResultSettingRole,
    EnumResultSettingType,
    IResultSettingItemDbModel,
    isRecursiveMetadata,
    isResultSettingMetadataTypeMetadata,
    isResultSettingMetadataTypePropertyObject,
    ProcessEditorRightsEnum,
    ResultSettingMetadataId,
    ResultSettingMetadataMetadata,
    ResultSettingMetadataPropertyObject,
    ResultSettingMetadataPropertyPrimitive,
    ResultSettingMetadataTypeEnum,
    TABLE_NAME_VALID_REGEX,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import {
    AppBar,
    List,
    ListItem,
    makeStyles,
    MenuItem,
    Paper,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
} from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon/SpeedDialIcon";
import React, { useEffect, useState } from "react";

import GroupIcon from "../../../../../../../components/core/Icons/Group/Group";
import SubGroupIcon from "../../../../../../../components/core/Icons/SubGroup/SubGroup";
import { useTranslate } from "../../../../../../../hooks/useTranslate";
import { resultSettingItemUpsert } from "../../../../../../../redux/result-setting-manager/result-setting-item-thunks";
import { resultSettingMetadataUpsert } from "../../../../../../../redux/result-setting-manager/result-setting-metadata-thunks";
import { useSelectorResultSettingItems, useSelectorResultSettingMetadata } from "../../../../../../../redux/selectors";
import { IUserRightsProps, userCanWrite } from "../../../../../../../utilitites/IUserRights";

const INPUT_DELAY_MS = 500;
const METADATA_NONE = -1;

const useStyle = makeStyles({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "16px",
        minWidth: "80%",
    },
    tableList: {
        position: "relative",
    },
    list: {
        height: "400px",
        overflowY: "scroll",
    },
    speedDial: {
        position: "absolute",
        right: "16px",
        bottom: "16px",
    },
    speedDialFab: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    speedDialButton: {
        boxShadow: "0px 4px 5px 0px",
    },
});

const defaultGroupItem = {
    disabled: false,
    itemOrder: 0,
    label: "Group",
    role: EnumResultSettingRole.VALUE,
    type: EnumResultSettingType.GROUP,
    unit: "",
};

const defaultSubGroupItem = {
    disabled: false,
    itemOrder: 0,
    label: "SubGroup",
    role: EnumResultSettingRole.VALUE,
    type: EnumResultSettingType.SUB_GROUP,
    unit: "",
};

const defaultMetadata: ResultSettingMetadataMetadata = {
    label: "Metadata",
    type: ResultSettingMetadataTypeEnum.METADATA,
    resultSettingMetadataId: 0,
};

const defaultMetadataProperty: ResultSettingMetadataPropertyPrimitive = {
    label: "key",
    parentId: 0,
    resultSettingMetadataId: 0,
    type: ResultSettingMetadataTypeEnum.PROPERTY_PRIMITIVE,
};

const TAB_GROUP = 0;
const TAB_METADATA = 1;
type TabIndex = typeof TAB_GROUP | typeof TAB_METADATA;

interface IDataStoreKeyValueSettingsProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    open: boolean;
    onCancel: () => void;
    onSave: () => void;
}

export default function DataStoreKeyValueSettings(props: IDataStoreKeyValueSettingsProps): JSX.Element {
    /**
     * COMPONENT PROPS
     */
    const { open, onCancel, onSave, userAccessLevel } = props;

    /**
     * COMPONENT STATES
     */
    const readOnly = !userCanWrite(userAccessLevel);
    const classes = useStyle();
    const translate = useTranslate();

    const dispatch = useThunkDispatch();
    const resultSettingItems = useSelectorResultSettingItems();
    const resultSettingMetadata = useSelectorResultSettingMetadata();

    const [groupItems, setGroupItems] = useState<IResultSettingItemDbModel[]>([]);
    const [subGroupItems, setSubGroupItems] = useState<IResultSettingItemDbModel[]>([]);

    const [selectedItemId, setSelectedItemId] = useState(-1);

    const [speedDialOpen, setSpeedDialOpen] = useState(false);
    const [itemError, setItemError] = useState<string | undefined>(undefined);
    const [subItemError, setSubItemError] = useState<string | undefined>(undefined);
    const [subItemErrorId, setSubItemErrorId] = useState<number | undefined>(undefined);

    const [selectedTab, setSelectedTab] = useState<TabIndex>(TAB_GROUP);

    /**
     * Effect triggered when the dialog open state change
     */
    useEffect((): void => {
        if (open) {
            setGroupItems(resultSettingItems.filter((item) => item.type === EnumResultSettingType.GROUP));
            setSubGroupItems(resultSettingItems.filter((item) => item.type === EnumResultSettingType.SUB_GROUP));
        }
    }, [open, resultSettingItems]);

    /**
     * Returns if a group label is duplicated or not
     *
     * @param {number} id - item id
     * @param {string} label - item label
     */
    const isGroupLabelDuplicated = (id: number, label: string): boolean =>
        groupItems.findIndex(
            (tableItem): boolean => tableItem.resultSettingItemId !== id && tableItem.label.toLowerCase() === label.toLowerCase()
        ) > -1;
    /**
     * Returns if a sub group label is duplicated or not
     *
     * @param {number} subGroupId - sub group id
     * @param {string} label - group label
     */
    const isSubGroupDuplicated = (subGroupId: number, label: string): boolean => {
        const subGroup = subGroupItems.find((subGroupItem): boolean => subGroupItem.resultSettingItemId === subGroupId);

        if (!subGroup) {
            return false;
        }

        return (
            subGroupItems.findIndex(
                (subGroupItem): boolean =>
                    subGroupItem.parentId === (subGroup.parentId || -1) &&
                    subGroupItem.resultSettingItemId !== subGroupId &&
                    subGroupItem.label.toLowerCase() === label.toLowerCase()
            ) > -1
        );
    };

    /**
     * Returns true if a metadata property key is a duplicate
     *
     * @param {ResultSettingMetadataId} resultSettingMetadataId - sub group id
     * @param {string} label - metadata property label
     */
    const isMetadataLabelDuplicated = (resultSettingMetadataId: ResultSettingMetadataId, label: string): boolean => {
        const metadataToValidate = resultSettingMetadata.find((item) => item.resultSettingMetadataId === resultSettingMetadataId);

        if (!metadataToValidate) {
            return false;
        }

        return resultSettingMetadata
            .filter(
                (item) =>
                    item.resultSettingMetadataId !== metadataToValidate.resultSettingMetadataId &&
                    (isResultSettingMetadataTypeMetadata(metadataToValidate)
                        ? isResultSettingMetadataTypeMetadata(item) // Source is a metadata, then validate only with other metadata
                        : !isResultSettingMetadataTypeMetadata(item) && item.parentId === metadataToValidate?.parentId) // Source is a property, then validate only with other siblings from same object
            )
            .some((property) => property.label.toLowerCase() === label.toLowerCase());
    };

    /**
     * Handle insert group click event
     */
    const handleInsertGroupClick = async (): Promise<void> => {
        handleDialMenuToggle();

        try {
            dispatch(resultSettingItemUpsert(defaultGroupItem));
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Handle insert sub group event
     */
    const handleInsertSubGroupClick = async (): Promise<void> => {
        if (selectedItemId <= 0) {
            return;
        }

        handleDialMenuToggle();

        try {
            dispatch(resultSettingItemUpsert({ ...defaultSubGroupItem, parentId: selectedItemId }));
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Handle insert metadata click event
     */
    const handleInsertMetadataClick = async (): Promise<void> => {
        dispatch(
            resultSettingMetadataUpsert({
                ...defaultMetadata,
                label: translate("action.datastore.defaultMetadataLabel"),
            })
        );
    };

    /**
     * Handle insert metadata property event
     */
    const handleInsertMetadataPropertyClick = async (): Promise<void> => {
        const selectedMetadata = resultSettingMetadata.find((metadata) => metadata.resultSettingMetadataId === selectedItemId);

        if (!selectedMetadata) {
            return void 0;
        }

        try {
            dispatch(
                resultSettingMetadataUpsert({
                    ...defaultMetadataProperty,
                    label: translate("action.datastore.defaultMetadataPropertyLabel"),
                    parentId: selectedMetadata.resultSettingMetadataId,
                })
            );
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Handle select table click event
     *
     * @param {number} tableId - Table Id that will be selected
     */
    const handleSelectGroupIdClick =
        (tableId: number): (() => void) =>
        (): void => {
            setSelectedItemId(tableId);
        };

    /**
     * Handle group/metadata textfield blur event
     */
    const handleItemOnBlur = (): void => {
        setItemError(undefined);
    };

    /**
     * Handle group label change event
     *
     * @param {number} groupId - Id of the group to be updated
     */
    const handleGroupLabelChange =
        (groupId: number): ((event: React.ChangeEvent) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const duplicated = isGroupLabelDuplicated(groupId, event.target.value);

            if (duplicated) {
                setItemError(translate("action.datastore.dupicated.error"));
                return;
            } else if (itemError) {
                setItemError(undefined);
            }
        };

    /**
     * Handle metadata label change event
     *
     * @param {number} resultSettingMetadataId - ID of the metadata to be updated
     */
    const handleMetadataLabelChange =
        (resultSettingMetadataId: ResultSettingMetadataId): ((event: React.ChangeEvent) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (isMetadataLabelDuplicated(resultSettingMetadataId, event.target.value)) {
                setItemError(translate("action.datastore.dupicated.error"));
                return void 0;
            } else if (itemError) {
                setItemError(undefined);
            }
        };

    /**
     * Handle group label changed event
     *
     * @param {number} groupId - Id of the group to be updated
     */
    const handleGroupLabelChanged =
        (groupId: number): ((value: string) => void) =>
        (value: string): void => {
            const tableItem = groupItems.find((tableItem): boolean => tableItem.resultSettingItemId === groupId);
            const duplicated = isGroupLabelDuplicated(groupId, value);

            if (!tableItem || duplicated) {
                return;
            }

            dispatch(resultSettingItemUpsert({ ...tableItem, label: value }));
        };

    /**
     * Handle metadata label changed event
     *
     * @param {number} resultSettingMetadataId - Id of the metadata to be updated
     */
    const handleMetadataLabelChanged =
        (resultSettingMetadataId: ResultSettingMetadataId): ((value: string) => void) =>
        (value: string): void => {
            const item = resultSettingMetadata.find((tableItem): boolean => tableItem.resultSettingMetadataId === resultSettingMetadataId);

            if (!item || isMetadataLabelDuplicated(resultSettingMetadataId, value)) {
                return void 0;
            }

            dispatch(resultSettingMetadataUpsert({ ...item, label: value }));
        };

    /**
     * Handle sub group textfield blur event
     */
    const handleSubGroupOnBlur = (): void => {
        setSubItemError(undefined);
        setSubItemErrorId(undefined);
    };

    /**
     * Handle sub group label change event
     *
     * @param {number} subGroupId - Id of the group to be updated
     */
    const handleSubGroupLabelChange =
        (subGroupId: number): ((event: React.ChangeEvent) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const duplicated = isSubGroupDuplicated(subGroupId, event.target.value);

            if (duplicated) {
                setSubItemError(translate("action.datastore.dupicated.error"));
                setSubItemErrorId(subGroupId);
                return;
            } else if (subItemError) {
                setSubItemError(undefined);
            }
        };

    /**
     * Handle column label change event
     *
     * @param {number} subGroupId - SubGroup Id that will be updated by the event
     * @param {string} propKey - Property to be updated
     */
    const handleSubGroupPropChanged =
        (subGroupId: number, propKey: keyof IResultSettingItemDbModel): ((value: number | string) => void) =>
        (value: number | string): void => {
            const subGroupItem = subGroupItems.find((subGroupItem): boolean => subGroupItem.resultSettingItemId === subGroupId);
            const duplicated = propKey === "label" ? isSubGroupDuplicated(subGroupId, value as string) : false;

            if (!subGroupItem || duplicated) {
                return void 0;
            }

            if (propKey === "resultSettingMetadataId" && value === METADATA_NONE) {
                // If NONE is selected for a metadata ID, then set it undefined
                dispatch(resultSettingItemUpsert({ ...subGroupItem, resultSettingMetadataId: undefined }));

                return void 0;
            }

            dispatch(resultSettingItemUpsert({ ...subGroupItem, [propKey]: value }));
        };

    /**
     * Handle column label change event
     *
     * @param {ResultSettingMetadataId} resultSettingMetadataId - index of metadata property that will be updated by the event
     * @param {string} key - Property to be updated
     */
    const handleMetadataPropertyChanged =
        (
            resultSettingMetadataId: ResultSettingMetadataId,
            key:
                | keyof ResultSettingMetadataMetadata
                | keyof ResultSettingMetadataPropertyObject
                | keyof ResultSettingMetadataPropertyPrimitive
        ): ((value: string) => void) =>
        (value: string): void => {
            const metadataItem = resultSettingMetadata.find((item) => item.resultSettingMetadataId === resultSettingMetadataId);

            if (!metadataItem) {
                return void 0;
            }

            if (key === "label" && isMetadataLabelDuplicated(metadataItem.resultSettingMetadataId, value)) {
                return void 0;
            }

            metadataItem[key] = value;

            if (key === "type" && isResultSettingMetadataTypePropertyObject(metadataItem)) {
                metadataItem.objectId = null;
            }

            dispatch(resultSettingMetadataUpsert(metadataItem));
        };

    /**
     * Handle speed dial menu toggle event
     */
    const handleDialMenuToggle = (): void => {
        setSpeedDialOpen(!speedDialOpen);
    };

    /**
     * Changes the selected tab
     */
    const handleTabChange = (_: React.ChangeEvent<{}>, value: TabIndex): void => {
        setSelectedTab(value);
    };

    /**
     * Renders the group tab
     */
    const renderGroupTab = (): JSX.Element[] =>
        groupItems.map(
            (groupItem, index): JSX.Element => (
                <ListItem
                    key={index}
                    button={true}
                    onClick={handleSelectGroupIdClick(groupItem.resultSettingItemId || -1)}
                    selected={groupItem.resultSettingItemId === selectedItemId}
                >
                    <GroupIcon />
                    {groupItem.resultSettingItemId === selectedItemId && (
                        <KortexTextField
                            variant="standard"
                            value={groupItem.label}
                            onChanged={handleGroupLabelChanged(groupItem.resultSettingItemId)}
                            onChange={handleGroupLabelChange(groupItem.resultSettingItemId)}
                            onBlur={handleItemOnBlur}
                            changedDelayMS={INPUT_DELAY_MS}
                            TextFieldProps={{
                                disabled: readOnly,
                                id: `groupName${index}Id`,
                            }}
                            regex={TABLE_NAME_VALID_REGEX}
                            standardErrorMsgs={{ regexNoMatch: translate("action.datastore.table.name.error") }}
                            error={itemError}
                        />
                    )}
                    {groupItem.resultSettingItemId !== selectedItemId && <div id={`groupName${index}Id`}>{groupItem.label}</div>}
                </ListItem>
            )
        );

    /**
     * Renders the metadata tab
     */
    const renderMetadataTab = (): JSX.Element[] =>
        resultSettingMetadata
            .filter((item) => isResultSettingMetadataTypeMetadata(item))
            .map(
                (metadaItem, index): JSX.Element => (
                    <ListItem
                        key={index}
                        button={true}
                        onClick={handleSelectGroupIdClick(metadaItem.resultSettingMetadataId || -1)}
                        selected={metadaItem.resultSettingMetadataId === selectedItemId}
                    >
                        <GroupIcon />
                        {metadaItem.resultSettingMetadataId === selectedItemId && (
                            <KortexTextField
                                variant="standard"
                                value={metadaItem.label}
                                onChanged={handleMetadataLabelChanged(metadaItem.resultSettingMetadataId)}
                                onChange={handleMetadataLabelChange(metadaItem.resultSettingMetadataId)}
                                onBlur={handleItemOnBlur}
                                changedDelayMS={INPUT_DELAY_MS}
                                TextFieldProps={{
                                    disabled: readOnly,
                                }}
                                regex={TABLE_NAME_VALID_REGEX}
                                standardErrorMsgs={{ regexNoMatch: translate("action.datastore.table.name.error") }}
                                error={itemError}
                            />
                        )}
                        {metadaItem.resultSettingMetadataId !== selectedItemId && <div>{metadaItem.label}</div>}
                    </ListItem>
                )
            );

    /**
     * Renders the sub groups
     */
    const renderSubGroups = (): JSX.Element[] =>
        subGroupItems
            .filter((subGroupItem): boolean => subGroupItem.parentId === selectedItemId)
            .map(
                (subGroupItem, index): JSX.Element => (
                    <TableRow key={index}>
                        <TableCell>
                            <KortexTextField
                                value={subGroupItem.label}
                                onBlur={handleSubGroupOnBlur}
                                onChange={handleSubGroupLabelChange(subGroupItem.resultSettingItemId || -1)}
                                onChanged={handleSubGroupPropChanged(subGroupItem.resultSettingItemId || -1, "label")}
                                changedDelayMS={INPUT_DELAY_MS}
                                TextFieldProps={{
                                    disabled: readOnly,
                                    id: `subGroupItemLabel${index}Id`,
                                }}
                                regex={TABLE_NAME_VALID_REGEX}
                                standardErrorMsgs={{ regexNoMatch: translate("action.datastore.table.name.error") }}
                                error={subGroupItem.resultSettingItemId === subItemErrorId ? subItemError : undefined}
                            />
                        </TableCell>
                        <TableCell>
                            <KortexTextField
                                TextFieldProps={{
                                    disabled: readOnly,
                                    select: true,
                                }}
                                value={subGroupItem.role}
                                onChanged={handleSubGroupPropChanged(subGroupItem.resultSettingItemId || -1, "role")}
                                changedDelayMS={INPUT_DELAY_MS}
                            >
                                <MenuItem value={EnumResultSettingRole.VALUE}>{translate("action.datastore.group.value")}</MenuItem>
                                <MenuItem value={EnumResultSettingRole.CHILD_ITEM}>{translate("action.datastore.group.child")}</MenuItem>
                                <MenuItem value={EnumResultSettingRole.PARENT_ITEM}>{translate("action.datastore.group.parent")}</MenuItem>
                            </KortexTextField>
                        </TableCell>
                        <TableCell>
                            <KortexTextField
                                value={subGroupItem.unit}
                                onChanged={handleSubGroupPropChanged(subGroupItem.resultSettingItemId || -1, "unit")}
                                changedDelayMS={INPUT_DELAY_MS}
                                TextFieldProps={{
                                    disabled: readOnly,
                                }}
                            />
                        </TableCell>
                        <TableCell>
                            <KortexTextField
                                value={subGroupItem.resultSettingMetadataId || -1}
                                onChanged={handleSubGroupPropChanged(subGroupItem.resultSettingItemId || -1, "resultSettingMetadataId")}
                                changedDelayMS={INPUT_DELAY_MS}
                                TextFieldProps={{
                                    disabled: readOnly,
                                    select: true,
                                }}
                            >
                                {[
                                    <MenuItem value={-1} key={-1}>
                                        {translate("action.datastore.noMetadata")}
                                    </MenuItem>,
                                ].concat(
                                    resultSettingMetadata
                                        .filter((item) => isResultSettingMetadataTypeMetadata(item))
                                        .map((item, index) => (
                                            <MenuItem key={index} value={item.resultSettingMetadataId}>
                                                {item.label}
                                            </MenuItem>
                                        ))
                                )}
                            </KortexTextField>
                        </TableCell>
                    </TableRow>
                )
            );

    /**
     * Renders the metadata properties
     */
    const renderMetadataProperties = (): JSX.Element[] => {
        const selectedMetadataProperties = resultSettingMetadata.filter(
            (item) => !isResultSettingMetadataTypeMetadata(item) && item.parentId === selectedItemId
        );

        return selectedMetadataProperties.map(
            (property, index): JSX.Element => (
                <TableRow key={index}>
                    <TableCell>
                        <KortexTextField
                            value={property.label}
                            onBlur={handleSubGroupOnBlur}
                            onChange={handleMetadataLabelChange(property.resultSettingMetadataId || -1)}
                            onChanged={handleMetadataPropertyChanged(property.resultSettingMetadataId, "label")}
                            changedDelayMS={INPUT_DELAY_MS}
                            TextFieldProps={{
                                disabled: readOnly,
                            }}
                            regex={TABLE_NAME_VALID_REGEX}
                            standardErrorMsgs={{ regexNoMatch: translate("action.datastore.table.name.error") }}
                            error={property.resultSettingMetadataId === subItemErrorId ? subItemError : undefined}
                        />
                    </TableCell>
                    <TableCell>
                        <KortexTextField
                            TextFieldProps={{
                                disabled: readOnly,
                                select: true,
                            }}
                            value={property.type}
                            onChanged={handleMetadataPropertyChanged(property.resultSettingMetadataId, "type")}
                            changedDelayMS={INPUT_DELAY_MS}
                        >
                            <MenuItem value={ResultSettingMetadataTypeEnum.PROPERTY_OBJECT}>
                                {translate("action.datastore.propertyType.object")}
                            </MenuItem>
                            <MenuItem value={ResultSettingMetadataTypeEnum.PROPERTY_PRIMITIVE}>
                                {translate("action.datastore.propertyType.value")}
                            </MenuItem>
                        </KortexTextField>
                    </TableCell>
                    <TableCell>
                        {isResultSettingMetadataTypePropertyObject(property) && (
                            <KortexTextField
                                value={property.objectId || -1}
                                onChanged={handleMetadataPropertyChanged(property.resultSettingMetadataId, "objectId")}
                                changedDelayMS={INPUT_DELAY_MS}
                                TextFieldProps={{
                                    disabled: readOnly,
                                    select: true,
                                }}
                            >
                                {(property.objectId
                                    ? []
                                    : [
                                          <MenuItem value={-1} key={-1}>
                                              {translate("action.datastore.selectMetadata")}
                                          </MenuItem>,
                                      ]
                                ).concat(
                                    resultSettingMetadata
                                        .filter(
                                            (item) =>
                                                isResultSettingMetadataTypeMetadata(item) &&
                                                !isRecursiveMetadata(item.resultSettingMetadataId, selectedItemId, resultSettingMetadata)
                                        )
                                        .map((item, index) => (
                                            <MenuItem value={item.resultSettingMetadataId} key={index}>
                                                {item.label}
                                            </MenuItem>
                                        ))
                                )}
                            </KortexTextField>
                        )}
                    </TableCell>
                </TableRow>
            )
        );
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={readOnly}
            textLabels={{
                titleLabel: translate("action.datastore.basicValueSettings"),
                cancelButtonLabel: translate("action.datastore.cancel"),
                proceedButtonLabel: translate("action.datastore.save"),
            }}
            open={open}
            onConfirm={onSave}
            onCancel={onCancel}
            dialogProps={{
                id: "insert-menu",
                fullScreen: false,
                maxWidth: "lg",
            }}
        >
            <div className={classes.root}>
                {/* TABLE SETTINGS */}

                <Paper className={classes.tableList}>
                    <AppBar position="static">
                        <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
                            <Tab label="Groups" />
                            <Tab label="Metadata" />
                        </Tabs>
                    </AppBar>
                    <List className={classes.list}>{selectedTab === TAB_GROUP ? renderGroupTab() : renderMetadataTab()}</List>
                    <SpeedDial
                        ariaLabel=""
                        open={speedDialOpen}
                        icon={<SpeedDialIcon id="speedDialId" />}
                        classes={{ fab: classes.speedDialFab }}
                        className={classes.speedDial}
                        hidden={readOnly}
                        onClick={handleDialMenuToggle}
                        tabIndex={1}
                    >
                        {selectedTab === TAB_GROUP && (
                            <SpeedDialAction
                                classes={{ fab: classes.speedDialButton }}
                                icon={<SubGroupIcon id="subGroupButtonId" />}
                                tooltipTitle={translate("action.datastore.subGroupId")}
                                tooltipOpen={true}
                                onClick={handleInsertSubGroupClick}
                            />
                        )}
                        {selectedTab === TAB_GROUP && (
                            <SpeedDialAction
                                classes={{ fab: classes.speedDialButton }}
                                icon={<GroupIcon id="groupButtonId" />}
                                tooltipTitle={translate("action.datastore.groupId")}
                                tooltipOpen={true}
                                onClick={handleInsertGroupClick}
                            />
                        )}
                        {selectedTab === TAB_METADATA && (
                            <SpeedDialAction
                                classes={{ fab: classes.speedDialButton }}
                                icon={<SubGroupIcon />}
                                tooltipTitle={translate("action.datastore.property")}
                                tooltipOpen={true}
                                onClick={handleInsertMetadataPropertyClick}
                            />
                        )}
                        {selectedTab === TAB_METADATA && (
                            <SpeedDialAction
                                classes={{ fab: classes.speedDialButton }}
                                icon={<SubGroupIcon />}
                                tooltipTitle={translate("action.datastore.metadata")}
                                tooltipOpen={true}
                                onClick={handleInsertMetadataClick}
                            />
                        )}
                    </SpeedDial>
                </Paper>

                {/* COLUMN SETTINGS */}
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {selectedTab === TAB_GROUP ? (
                                    <>
                                        <TableCell>{translate("action.datastore.group.name")}</TableCell>
                                        <TableCell>{translate("action.datastore.group.type")}</TableCell>
                                        <TableCell>{translate("action.datastore.group.unit")}</TableCell>
                                        <TableCell>{translate("action.datastore.group.metadata")}</TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell>{translate("action.datastore.metadata.property")}</TableCell>
                                        <TableCell>{translate("action.datastore.metadata.type")}</TableCell>
                                        <TableCell>{translate("action.datastore.metadata.metadata")}</TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>{selectedTab === TAB_GROUP ? renderSubGroups() : renderMetadataProperties()}</TableBody>
                    </Table>
                </Paper>
            </div>
        </KortexDialogConfirmation>
    );
}
