import { getTimestampFromDateStr, TimeUnitsEnum } from "@kortex/aos-common";

export const removeFalsyPropsExceptBoolean = (obj: Object): Object => {
    for (const propName in obj) {
        if (!(obj[propName] || obj[propName] === false)) {
            delete obj[propName];
        }
    }
    return obj;
};

export const formatStringFilterForFilterOptions = (filterValue: string): string | undefined => {
    const trimValue = filterValue.trim();
    return trimValue.length > 0 ? trimValue : undefined;
};

export const formatStringArrayFilterForFilterOptions = (
    filterValue: Array<number> | Array<string>
): Array<number> | Array<string> | undefined => {
    return filterValue.length > 0 ? filterValue : undefined;
};

export const formatDateFilterForFilterOptions = (filterValue: string): number | undefined => {
    const trimValue = filterValue.trim();
    return trimValue.length > 0 ? getTimestampFromDateStr(filterValue, TimeUnitsEnum.MILLISECOND) : undefined;
};
