import { IKortexTextFieldProps, KortexTextField, theme } from "@aos/react-components";
import { ReworkYieldType } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { MenuItem, Tooltip, Typography, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles({
    container: {
        // To be overwritten
    },
    item: {
        // To be overwritten
    },
    itemLabel: {
        // To be overwritten
    },
    selector: {
        width: "150px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IYieldTypeSelectorProps {
    classes: Partial<ReturnType<typeof useStyles>>;
    onChange: (value: ReworkYieldType) => void;
    value: ReworkYieldType;
    defaultValue?: ReworkYieldType;
    KortexTextFieldProps?: IKortexTextFieldProps["TextFieldProps"];
}

const YieldTypeSelector: FC<IYieldTypeSelectorProps> = (props) => {
    const { defaultValue, onChange, value, KortexTextFieldProps } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChange(event.target.value as ReworkYieldType);
    };

    return (
        <div className={classes.container}>
            <KortexTextField
                className={classes.selector}
                label={translate("rework.yieldType")}
                onChange={handleChange}
                TextFieldProps={{
                    defaultValue,
                    id: "failureTicketYieldSelector",
                    select: true,
                    ...KortexTextFieldProps,
                }}
                value={value}
                variant="standard"
            >
                <MenuItem className={classes.item} id="failureTicketYieldSelectorFPY" value="FPY">
                    <Tooltip classes={{ tooltip: classes.tooltip }} placement="right" title={translate("rework.FPYTooltip")}>
                        <Typography className={classes.itemLabel}>{translate("rework.FPY")}</Typography>
                    </Tooltip>
                </MenuItem>
                <MenuItem className={classes.item} id="failureTicketYieldSelectorTPY" value="TPY">
                    <Tooltip classes={{ tooltip: classes.tooltip }} placement="right" title={translate("rework.TPYTooltip")}>
                        <Typography className={classes.itemLabel}>{translate("rework.TPY")}</Typography>
                    </Tooltip>
                </MenuItem>
                <MenuItem className={classes.item} id="failureTicketYieldSelectorRMA" value="RMA">
                    <Tooltip classes={{ tooltip: classes.tooltip }} placement="right" title={translate("rework.RMATooltip")}>
                        <Typography className={classes.itemLabel}>{translate("rework.RMA")}</Typography>
                    </Tooltip>
                </MenuItem>
                <MenuItem className={classes.item} id="failureTicketYieldSelectorASSY" value="ASSY">
                    <Tooltip classes={{ tooltip: classes.tooltip }} placement="right" title={translate("rework.ASSYTooltip")}>
                        <Typography className={classes.itemLabel}>{translate("rework.ASSY")}</Typography>
                    </Tooltip>
                </MenuItem>
            </KortexTextField>
        </div>
    );
};

export default YieldTypeSelector;
