import shortid from "shortid";
import { ProcessVariableStorer } from "../../../../../../../Process/ProcessVariable";
import { WorkInstructionsTextConfig } from "../../Text/WorkInstructionsTextConfig";
export var FormItemTypeEnum;
(function (FormItemTypeEnum) {
    FormItemTypeEnum["APPROVAL"] = "APPROVAL";
    FormItemTypeEnum["CHECKBOX"] = "CHECKBOX";
    FormItemTypeEnum["CHECKLIST"] = "CHECKLIST";
    FormItemTypeEnum["DROPDOWN"] = "DROPDOWN";
    FormItemTypeEnum["LABEL"] = "LABEL";
    FormItemTypeEnum["TEXTBOXLIST"] = "TEXTBOXLIST";
    FormItemTypeEnum["RADIO"] = "RADIO";
    FormItemTypeEnum["TEXTBOX"] = "TEXTBOX";
})(FormItemTypeEnum || (FormItemTypeEnum = {}));
export class WorkInstructionsFormItemConfig {
    constructor(type, label) {
        this._id = shortid.generate();
        this.approvalGroupId = "";
        this.label = label;
        this.subValues = [];
        this.storeTo = new ProcessVariableStorer();
        this.subValueString = "";
        this.type = type;
        this.defaultValue = "";
        this.required = false;
        this.formatRegex = "";
        this.formatErrorMessage = "";
        this.multiline = false;
        this.maxItems = -1;
        this.minItems = -1;
        this.tabIndex = "";
        this.labelProps = new WorkInstructionsTextConfig();
    }
}
