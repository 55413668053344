export var EnumUserStatus;
(function (EnumUserStatus) {
    EnumUserStatus["ACTIVE"] = "A";
    EnumUserStatus["DISABLED"] = "I";
    EnumUserStatus["ARCHIVED"] = "D";
})(EnumUserStatus || (EnumUserStatus = {}));
export function createUserModel() {
    return {
        userId: 0,
        userName: "",
        firstName: "",
        lastName: "",
        userCode: "",
        email: "",
        cell: "",
        status: EnumUserStatus.ACTIVE,
        roles: [],
        groups: [],
        language: "",
    };
}
