var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStyles, makeStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { DateTimePicker as MuiDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as React from "react";
import { IconButton, InputAdornment } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
var styles = createStyles({
    datePicker: {
        flex: 1,
        margin: "5px",
    },
    datePickerInput: {
        padding: "5px",
        fontSize: "1rem",
    },
    datePickerLabel: {
        display: "inline-block",
        marginTop: "0px",
    },
    iconSmall: {
        width: "16px",
        height: "16px",
    },
    root: {
        MinWidth: "140px",
    },
});
var useStyles = makeStyles(function () { return styles; });
function KortexDateTimePicker(props) {
    var DateTimePickerProps = props.DateTimePickerProps, label = props.label, language = props.language, onChange = props.onChange, value = props.value;
    var classes = useStyles();
    return (React.createElement(MuiPickersUtilsProvider, { libInstance: moment, locale: language, utils: MomentUtils },
        React.createElement(MuiDateTimePicker, __assign({ allowKeyboardControl: true, className: classes.datePicker, clearable: true, emptyLabel: " ", format: "YYYY-MM-DD HH:mm", InputProps: {
                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                    React.createElement(IconButton, null,
                        React.createElement(CalendarToday, { className: classes.iconSmall })))),
                inputProps: {
                    "aria-readonly": true,
                    className: classes.datePickerInput,
                },
            }, label: React.createElement("span", { className: classes.datePickerLabel }, label), onChange: onChange, value: Boolean(value) ? value : null }, DateTimePickerProps))));
}
export default KortexDateTimePicker;
