"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deepClone = void 0;
const constants_1 = require("./constants");
function deepClone(source) {
    if (source === undefined || source === null) {
        return source;
    }
    if (typeof source !== constants_1.TYPEOF_OBJECT) {
        return source;
    }
    const obj = Array.isArray(source) ? [] : {};
    for (const propName of Object.keys(source)) {
        const prop = source[propName];
        if (typeof prop === constants_1.TYPEOF_OBJECT) {
            obj[propName] = deepClone(prop);
        }
        else {
            obj[propName] = prop;
        }
    }
    return obj;
}
exports.deepClone = deepClone;
