import { ITreeNodeDbModel } from "@kortex/aos-common";

/**
 * Gets all parent nodes of the provided node id. [0] is the node itself, skip to only get parent
 *
 * @param {number} childNodeId - child node id to retreive parent
 * @param {ITreeNodeDbModel[]} list - list of nodes to search for
 *
 * @returns {ITreeNodeDbModel[]} list of parent nodes including itself
 */
export function getParentNodesPath(childNodeId: number, list: ITreeNodeDbModel[]): ITreeNodeDbModel[] {
    const childNode = list.find((repoNode): boolean => repoNode.treeNodeId === childNodeId);
    if (!childNode) {
        return [];
    }
    const parentNode = list.find((repoNode) => repoNode.treeNodeId === childNode.parentId);
    if (parentNode && parentNode.parentId) {
        return [...getParentNodesPath(parentNode.treeNodeId, list), childNode];
    } else {
        if (parentNode) {
            return [parentNode, childNode];
        } else if (childNode) {
            return [childNode];
        } else {
            return [];
        }
    }
}

/**
 * Gets a node parent name string
 *
 * @param {number} childNodeId - child node id to retreive parent
 * @param {ITreeNodeDbModel[]} treeNodes - list of nodes to search for
 *
 * @returns {string} parent name string, "" if not found
 */
export function getParentNodeName(childNodeId: number, treeNodes: ITreeNodeDbModel[]): string {
    const parentNode = treeNodes.find((repoNode) => repoNode.treeNodeId === childNodeId);
    return parentNode ? parentNode.label : "";
}

/**
 * Gets node parent name string
 *
 * @param {number} childNodeId - child node id to retreive parent path
 * @param {ITreeNodeDbModel[]} treeNodes - list of nodes to search for
 *
 * @returns {string} parent name string, "" if not found
 */
export function getParentPathString(childNodeId: number | undefined, treeNodes: ITreeNodeDbModel[]): string {
    // Build path
    if (!childNodeId) {
        return "";
    }

    const processPathArray = getParentNodesPath(childNodeId, treeNodes);
    let pathStr = "";
    for (const path of processPathArray) {
        pathStr += `/${path.label}`;
    }
    return pathStr;
}

/**
 * Gets all child nodes of the provided node id. [0] is the node itself, skip to only get child
 *
 * @param {number} parentNodeId - parent node id to retrieve children
 * @param {ITreeNodeDbModel[]} list - list of nodes to search for
 *
 * @returns {ITreeNodeDbModel[]} list of parent nodes including itself
 */
export function getChildrenNodesPath(parentNodeId: number, list: ITreeNodeDbModel[]): ITreeNodeDbModel[] {
    const childNode = list.find((node) => node.treeNodeId === parentNodeId);

    if (!childNode) {
        return [];
    }

    const parentNode = list.find((node) => node.parentId === parentNodeId);

    if (parentNode && parentNode.parentId) {
        return [...getChildrenNodesPath(parentNode.treeNodeId, list), childNode];
    } else {
        if (parentNode) {
            return [parentNode, childNode];
        } else if (childNode) {
            return [childNode];
        } else {
            return [];
        }
    }
}
