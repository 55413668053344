import { MessageState } from "@kortex/aos-common";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
    outputContainer: {
        height: "-webkit-fill-available",
        padding: "32px",
        width: "-webkit-fill-available",
    },
    outputText: {
        marginBottom: "32px",
        textAlign: "center",
    },
    outputTitle: {
        marginBottom: "32px",
    },
});

export interface IOwnProps {
    actionState: MessageState;
}

export default function MessagePlayer(props: IOwnProps): JSX.Element {
    const classes = useStyles();

    return (
        <div className={classes.outputContainer}>
            <Typography className={classes.outputTitle} variant="h1">
                {props.actionState.message}
            </Typography>
        </div>
    );
}
