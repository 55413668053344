export const trainingPending = {
    rpc: {
        getAllByUserId: {
            funcId: 13301,
            funcName: "trainingPendingGetAllByUserId",
        },
        trainingCertificateSign: {
            funcId: 13302,
            funcName: "trainingPendingTrainingCertificateSign",
        },
        inserted: {
            funcId: 13303,
            funcName: "trainingPendingInserted",
        },
        getAll: {
            funcId: 13304,
            funcName: "trainingPendingGetAll",
        },
        delete: {
            funcId: 13305,
            funcName: "trainingPendingDelete",
        },
        deleted: {
            funcId: 13306,
            funcName: "trainingPendingDeleted",
        },
    },
    serviceId: 133,
    serviceVersion: 1,
};
