import { ConditionBaseConfig, ConditionMode, ConditionType } from "@kortex/aos-common";

/**
 * Returns the loop that will be executed in the player
 *
 * @param {ConditionBaseConfig[]} conditions - conditions
 *
 * @returns {string} - concatenated condition
 */
export const getConditionsPreview = (conditions: ConditionBaseConfig[]): string => {
    let conditionString = "";

    conditions.forEach((condition: ConditionBaseConfig): void => {
        if (condition.mode === ConditionMode.SIMPLIFIED) {
            let leftOperand = "";
            let rightOperand = "";

            switch (condition.simplified.type) {
                case ConditionType.STRING:
                    leftOperand = `'${condition.simplified.leftOperand}'`;
                    rightOperand = `'${condition.simplified.rightOperand}'`;
                    break;
                case ConditionType.NUMBER:
                    leftOperand = `${condition.simplified.leftOperand}`;
                    rightOperand = `${condition.simplified.rightOperand}`;
                    break;
                case ConditionType.DATE:
                    leftOperand = `Date('${condition.simplified.leftOperand}')`;
                    rightOperand = `Date('${condition.simplified.rightOperand}')`;
                    break;
                default:
                    break;
            }
            conditionString += leftOperand + condition.simplified.comparisonOperator + rightOperand;
        } else {
            conditionString += ("(" + condition.expert + ")").trim() + " ";
        }

        conditionString += condition.logicalOperator + " ";
    });

    return conditionString.trim();
};
