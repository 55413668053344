import { KortexTextField } from "@aos/react-components";
import {
    ConnectorFileConfig,
    ConnectorRestConfig,
    ConnectorShellConfig,
    EnumConnectorType,
    ProcessActionStepConnector,
    ProcessVariableStorer,
    ProcessVariableStoringMethod,
    TConnectorExtConfig,
} from "@kortex/aos-common";
import { MenuItem, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import VariablePicker from "../../../../../core/VariablePicker/VariablePicker";
import { IActionStepProps } from "../IActionStepProps";

import ConnectorFileEditor from "./File/FileEditor/ConnectorFileEditor";
import ConnectorRestEditor from "./Rest/RestEditor/ConnectorRestEditor";
import ConnectorShellEditor from "./Shell/ShellEditor/ConnectorShellEditor";

const useStyles = makeStyles({
    dataType: {
        width: "120px",
    },
    root: {
        height: "calc(100vh - 139px)", // Action header(75px), margins(32px), connector editor padding (32px)
        padding: "16px",
    },
    row: {
        display: "inline-flex",
        width: "100%",
    },
    storeTo: {
        flex: 1,
    },
});

export default function ConnectorEditor(props: IActionStepProps<ProcessActionStepConnector>): JSX.Element {
    const { actionStepProps, onChanged, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    if (!actionStepProps) {
        return <div />;
    }

    const { extendedProps, storeTo, type } = actionStepProps.config;

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Handles the changes applied to the connector props of the step
     *
     * @param {TConnectorExtConfig} extProps - connector props
     */
    const handleExtPropsChange = (extProps: TConnectorExtConfig): void => {
        onChanged({ ...actionStepProps, config: { ...actionStepProps.config, extendedProps: extProps } });
    };

    /**
     * Called when storeTo textfield is changed
     *
     * @param {string} identifier - textfield value
     * @param {ProcessVariableStoringMethod} storingMethod - overwrite or append
     */
    const handleStoreToChange = (identifier: string, storingMethod?: ProcessVariableStoringMethod): void => {
        const method = storingMethod ? storingMethod : storeTo.storingMethod;
        if (method !== storeTo.storingMethod || identifier !== storeTo.identifier) {
            onChanged({
                ...actionStepProps,
                config: { ...actionStepProps.config, storeTo: new ProcessVariableStorer(identifier, storingMethod) },
            });
        }
    };

    /**
     * Called when conenctor type is changed
     * Changes the extended props to selected connector type
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - change event data
     */
    const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const updatedType = event.target.value as EnumConnectorType;
        let updatedExtProps: TConnectorExtConfig | undefined;

        switch (updatedType) {
            case EnumConnectorType.FILE:
                updatedExtProps = new ConnectorFileConfig();
                break;
            case EnumConnectorType.REST:
                updatedExtProps = new ConnectorRestConfig();
                break;
            case EnumConnectorType.SHELL:
                updatedExtProps = new ConnectorShellConfig();
                break;
        }

        onChanged({
            ...actionStepProps,
            config: { ...actionStepProps.config, extendedProps: updatedExtProps, type: updatedType },
        });
    };

    /**
     * Render the selected connector editor
     */
    const renderEditor = (): JSX.Element => {
        switch (type) {
            case EnumConnectorType.FILE:
                return (
                    <ConnectorFileEditor
                        onChange={handleExtPropsChange}
                        fileProps={extendedProps as ConnectorFileConfig}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case EnumConnectorType.REST:
                return (
                    <ConnectorRestEditor
                        onChange={handleExtPropsChange}
                        restProps={extendedProps as ConnectorRestConfig}
                        userAccessLevel={userAccessLevel}
                    />
                );
            case EnumConnectorType.SHELL:
                return (
                    <ConnectorShellEditor
                        onChange={handleExtPropsChange}
                        shellProps={extendedProps as ConnectorShellConfig}
                        userAccessLevel={userAccessLevel}
                    />
                );
            default:
                return <div />;
        }
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.row} id="connector-editor-main-row">
                <KortexTextField
                    className={classes.dataType}
                    label={translate("action.connector.type")}
                    onChange={handleTypeChange}
                    TextFieldProps={{
                        disabled: readOnly,
                        select: true,
                    }}
                    value={type}
                >
                    <MenuItem value={EnumConnectorType.FILE}>{translate("action.connector.type.file")}</MenuItem>
                    <MenuItem value={EnumConnectorType.REST}>{translate("action.connector.type.rest")}</MenuItem>
                    <MenuItem value={EnumConnectorType.SHELL}>{translate("action.connector.type.shell")}</MenuItem>
                </KortexTextField>
                {type !== EnumConnectorType.UNDEF && (
                    <VariablePicker
                        onChange={handleStoreToChange}
                        storingMethod={storeTo.storingMethod}
                        KortexTextFieldProps={{
                            className: classes.storeTo,
                            label: translate("action.connector.storeTo"),
                        }}
                        userAccessLevel={userAccessLevel}
                        value={storeTo.identifier}
                    />
                )}
            </div>
            {renderEditor()}
        </Paper>
    );
}
