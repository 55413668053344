import { theme } from "@aos/react-components";
import { UserId } from "@kortex/aos-common";
import UserSelectorDialog from "@kortex/aos-ui/components/core/UserSelectorDialog/UserSelectorDialog";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { IProcessTrainingPanelSide } from "@kortex/aos-ui/redux/process-training-manager/process-training-types";
import { useSelectorUsersRolesRights } from "@kortex/aos-ui/redux/selectors";
import { userCanInsert } from "@kortex/aos-ui/utilitites/IUserRights";
import { Button, List, ListItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { useProcessTrainingContext } from "../context";

import ProcessTrainingHeader from "./processTrainingHeader";
import ProcessTrainingTrainings from "./processTrainingTrainings";

const useStyles = makeStyles({
    root: {
        padding: "0px 10px 10px 10px",
        height: "calc(100vh - 260px)", // Header (64px) + Tabs (68px) + margins (23px) + RepoBreadCrumbs (45px) + containerFab (60px)
        overflow: "auto",
    },
    listItemContainerButton: {
        justifyContent: "center",
    },
    containerButton: {
        marginTop: "10%",
    },
    text: {
        color: theme.palette.primary[500],
        fontSize: "1.2rem",
    },
    selectButton: {
        minWidth: "200px",
        height: "60px",
    },
    fabRow: {
        display: "flex",
        paddingRight: "27px",
    },
    containerFab: {
        display: "flex",
        height: "60px",
        justifyContent: "end",
    },
    containerFabLeft: {
        flex: 2,
    },
    containerFabRight: {
        flex: 1,
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
    },
});

function ProcessTrainingContent(): JSX.Element {
    const { insertTrainings, processTrainings, setProcessRepositoryOpened } = useProcessTrainingContext();
    const classes = useStyles();
    const translate = useTranslate();
    const userRoleRightsTraining = useSelectorUsersRolesRights("training");

    const preselectedLeftUserIds = processTrainings.left.trainings.map((training) => training.userId);
    const preselectedRightUserIds = processTrainings.right.trainings.map((training) => training.userId);
    const readOnly = !userCanInsert(userRoleRightsTraining);

    const handleDialogConfirm =
        (panel: IProcessTrainingPanelSide): ((userIds: UserId[]) => void) =>
        (userIds: UserId[]): void => {
            insertTrainings(panel, userIds);
        };

    const openProcessRepository = (): void => {
        setProcessRepositoryOpened(true);
    };

    return (
        <div>
            <div className={classes.root} id={"trainingProcessTrainingListId"}>
                <List subheader={<ProcessTrainingHeader />}>
                    {processTrainings.treeNodeId && processTrainings.left.originalProcessId ? (
                        <ProcessTrainingTrainings />
                    ) : (
                        <div className={classes.containerButton}>
                            <ListItem className={classes.listItemContainerButton}>
                                {processTrainings.treeNodeId && !processTrainings.left.originalProcessId ? (
                                    <Typography className={classes.text} id="processSelectedWithoutRequirementId">
                                        {translate("training.processSelectedWithoutRequirement")}
                                    </Typography>
                                ) : null}
                            </ListItem>
                            <ListItem className={classes.listItemContainerButton}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.selectButton}
                                    onClick={openProcessRepository}
                                    id={"openRepositoryId"}
                                >
                                    <Typography>{translate("training.selectProcess")}</Typography>
                                </Button>
                            </ListItem>
                        </div>
                    )}
                </List>
            </div>
            <div className={classes.fabRow}>
                <div className={`${classes.containerFab} ${classes.containerFabLeft}`}>
                    {processTrainings.left.originalProcessId ? (
                        <UserSelectorDialog
                            disabled={readOnly}
                            excludedUserIdsFromTheList={preselectedLeftUserIds}
                            onConfirmDialog={handleDialogConfirm("left")}
                            tootltip={<Typography>{translate("training.addUserToLatestVersion")}</Typography>}
                        />
                    ) : null}
                </div>
                <div className={`${classes.containerFab} ${classes.containerFabRight}`}>
                    {processTrainings.right.originalProcessId ? (
                        <UserSelectorDialog
                            disabled={readOnly}
                            excludedUserIdsFromTheList={preselectedRightUserIds}
                            onConfirmDialog={handleDialogConfirm("right")}
                            tootltip={<Typography>{translate("training.addUserToHistoryVersion")}</Typography>}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default ProcessTrainingContent;
