import * as React from "react";
import { Typography, FormControl, Select, Input, MenuItem, Checkbox } from "@material-ui/core";
import { secondaryPalette, primaryPalette } from "@aos/react-components";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { IUserDbModel, IUserGroupDbModel, TaskStatusEnum, UserGroupStatusEnum } from "@kortex/aos-common";
import { useEffect } from "react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import { useEntitiesUsersGroups } from "../../../redux/effects";
import KortexSearchField from "../../core/KortexSearchField";
import { useTranslate } from "../../../hooks/useTranslate";
import DateTimeFromToFilters from "../Scheduler/SchedulerJobListAndSearch/DateTimeFromToFilters";

import { getTaskStatusKey } from "./TasksCommon";

export interface IFilters {
    plainText: string;
    status: string[];
    requester: string[];
    group: string[];
    requestedDateFrom: string;
    requestedDateTo: string;
    showArchived: boolean;
}

// Used to indicate which filters to display
export interface IEnabledFilters {
    plainText?: boolean;
    status?: boolean;
    requester?: boolean;
    group?: boolean;
    requestedOn?: boolean;
    showArchived?: boolean;
}

export const defaultFilters: IFilters = {
    plainText: "",
    status: [],
    requester: [],
    group: [],
    requestedDateFrom: "",
    requestedDateTo: "",
    showArchived: false,
};

const defaultEnabledFilters: IEnabledFilters = {
    plainText: true,
    status: true,
    requester: true,
    group: true,
    requestedOn: true,
    showArchived: true,
};

const useStyles = makeStyles({
    searchBox: {
        width: "100%",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    filterInput: {
        flex: 2,
    },
    filterInputSelect: {
        padding: "5px",
    },
    filterDateInput: {
        flex: 1,
    },
    clearFilterLabel: {
        color: secondaryPalette[500],
        "&:hover": {
            color: primaryPalette[500],
        },
    },
});

interface IOwnProps {
    usersList?: IUserDbModel[];
    enabledFilters?: IEnabledFilters;
    onFiltersChange: (filters: IFilters) => void;
}

export default function TasksSearchBar(props: IOwnProps): JSX.Element {
    const { enabledFilters = defaultEnabledFilters, onFiltersChange } = props;

    const classes = useStyles();
    const groups = useEntitiesUsersGroups();
    const translate = useTranslate();

    const [filters, setFilters] = useState<IFilters>(defaultFilters);

    /**
     * When filters change, execute onChange cb
     */
    useEffect((): void => {
        onFiltersChange(filters);
    }, [filters]);

    /**
     * Called when the inner filters change
     *
     * @param {string} key - indicates which filter changed
     */
    const handleFilterChange =
        (key: keyof IFilters): ((e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
            setFilters({ ...filters, [key]: e.target.value });
        };

    /**
     * Handle called when the card header item is updated
     *
     * @param {keyof IFilters} key - key of that job that changed
     */
    const handleDateFilterChange =
        (key: keyof IFilters): ((date: MaterialUiPickersDate) => void) =>
        (date: MaterialUiPickersDate): void => {
            setFilters({ ...filters, [key]: date ? String(date) : "" });
        };

    /**
     * Called when the Show Archived button is toggled
     */
    const handleToggleArchived = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, showArchived: e.target.checked });
    };

    /**
     * Called when filters change. Checks if filters equal their default value (plainText is excluded from check)
     */
    const isFilterActive = (): boolean => {
        let active = false;
        Object.keys(filters).forEach((key): void => {
            if (key !== "plainText" && filters[key] !== defaultFilters[key]) {
                active = true;
            }
        });
        return active;
    };

    /**
     * Called when clicking the Clear Filter label
     */
    const handleClearFilters = (): void => {
        setFilters(defaultFilters);
    };

    /**
     * Called when the text in the searchbar changes
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        setFilters({ ...filters, plainText: val });
    };

    return (
        <KortexSearchField className={classes.searchBox} onChange={handleSearchTextChange} isFilterActive={isFilterActive()}>
            <React.Fragment>
                {enabledFilters.status && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("tasks.status")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("status")}
                                value={filters.status}
                            >
                                {Object.values(TaskStatusEnum).map((key: TaskStatusEnum): JSX.Element => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                            {translate(getTaskStatusKey(key))}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {enabledFilters.group && groups.length && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("tasks.group")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("group")}
                                value={filters.group}
                            >
                                {groups
                                    .filter((group) => group.status !== UserGroupStatusEnum.ARCHIVED)
                                    .map((group: IUserGroupDbModel, index: number): JSX.Element => {
                                        return (
                                            <MenuItem key={index} value={group.name}>
                                                {group.name}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {enabledFilters.requester && props.usersList && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("tasks.reviewer")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("requester")}
                                value={filters.requester}
                            >
                                {props.usersList.map((planner: IUserDbModel, index: number): JSX.Element => {
                                    return (
                                        <MenuItem key={index} value={planner.userName}>
                                            {planner.userName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {enabledFilters.requestedOn && (
                    <DateTimeFromToFilters
                        filterLabel={"tasks.requestedOn"}
                        ValueFrom={filters.requestedDateFrom}
                        ValueTo={filters.requestedDateTo}
                        onChangeFrom={handleDateFilterChange("requestedDateFrom")}
                        onChangeTo={handleDateFilterChange("requestedDateTo")}
                    />
                )}
                {enabledFilters.showArchived && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("tasks.optionShowArchived")}</Typography>
                        <Checkbox checked={filters.showArchived} onChange={handleToggleArchived} />
                    </div>
                )}
                <div className={classes.optionsItem}>
                    <div className={classes.filterLabel} />
                    <Typography onClick={handleClearFilters} className={classes.clearFilterLabel}>
                        {translate("tasks.clearFilters")}
                    </Typography>
                </div>
            </React.Fragment>
        </KortexSearchField>
    );
}
