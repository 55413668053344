import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { ITreeNodeDbModel, TreeNodeId, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { useProcessEditorContext } from "@kortex/aos-ui/components/pages/ProcessEditor/ProcessEditor/context";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { treeProcessUpdateBom } from "@kortex/aos-ui/redux/tree-manager/tree-thunks-process";
import { client } from "@kortex/aos-ui/utilitites/kortex-client/client";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { BomAssignmentDialogChildNodes } from "./childNodes";

const useStyles = makeStyles({
    textFieldContainer: {
        marginBottom: "20px",
    },
});

interface IProps {
    onClose?: () => void;
    open: boolean;
    treeNode: ITreeNodeDbModel; // TODO: make context
}

export default function BomAssignmentDialog(props: IProps): JSX.Element {
    const { onClose, open, treeNode } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();

    const [bomId, setBomId] = useState<string>("");
    const [bomRev, setBomRev] = useState<string>("");
    const [editedNode, setEditedNode] = useState<ITreeNodeDbModel | undefined>(undefined);
    const [selectedNodes, setSelectedNodes] = useState<TreeNodeId[]>([]);

    const isNodeFolder = treeNode.nodeType === TreeNodeNodeTypeEnum.FOLDER;
    const { updateBom } = useProcessEditorContext();

    /**
     * Update local BOM ID and revision when component is opened
     */
    useEffect((): void => {
        if (open && treeNode) {
            setEditedNode(treeNode);

            // Get draft process
            if (treeNode.nodeType === TreeNodeNodeTypeEnum.PROCESS || treeNode.nodeType === TreeNodeNodeTypeEnum.ROUTING) {
                client.services.process
                    .getDraftVersion({ treeNodeId: treeNode.treeNodeId })()
                    .then((process) => {
                        setBomId(process.bomId);
                        setBomRev(process.bomRev);
                    });
            } else if (treeNode.nodeType === TreeNodeNodeTypeEnum.FOLDER) {
                setBomId(treeNode.bomId);
                setBomRev(treeNode.bomRev);

                // Get all child nodes
                client.services.process.getAllDraftsFromParentTreeNode({ treeNodeId: treeNode.treeNodeId })();
            }
        } else {
            setEditedNode(undefined);
            setBomId("");
            setBomRev("");
        }
    }, [open]);

    const handleCancelBomAssignmentDialog = (): void => {
        onClose?.();
    };

    const handleConfirmBomAssignmentDialog = (): void => {
        if (editedNode) {
            dispatch(
                treeProcessUpdateBom({
                    bomId,
                    bomRev,
                    treeNodeIds: isNodeFolder ? selectedNodes : [editedNode.treeNodeId],
                })
            ).then(() => {
                updateBom(bomId, bomRev, true);
            });
        }

        onClose?.();
    };

    const handleBomIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setBomId(event.target.value);
    };

    const handleBomRevChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setBomRev(event.target.value);
    };

    const handleSelectedNodeChanged = (selectedNodes: TreeNodeId[]): void => {
        setSelectedNodes(selectedNodes);
    };

    return (
        <KortexDialogConfirmation
            open={open}
            dialogProps={{
                fullWidth: true,
                id: "bomAssignmentDialogId",
                maxWidth: isNodeFolder ? "md" : "xs",
            }}
            textLabels={{
                titleLabel: translate("treeview.bomAssignmentHeader"),
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.proceed"),
            }}
            onCancel={handleCancelBomAssignmentDialog}
            onConfirm={handleConfirmBomAssignmentDialog}
            closeOnEscape={true}
        >
            <div className={classes.textFieldContainer}>
                <KortexTextField
                    InputProps={{
                        id: "bomIdId",
                    }}
                    label={translate("bomAssignmentDialog.bomId")}
                    onChange={handleBomIdChange}
                    value={bomId}
                    variant="standard"
                />
                <KortexTextField
                    InputProps={{
                        id: "bomRevId",
                    }}
                    label={translate("bomAssignmentDialog.bomRev")}
                    onChange={handleBomRevChange}
                    value={bomRev}
                    variant="standard"
                />
            </div>
            {isNodeFolder ? (
                <BomAssignmentDialogChildNodes
                    diff={{ bomId, bomRev }}
                    onSelectedNodesChanged={handleSelectedNodeChanged}
                    selectedNodes={selectedNodes}
                    treeNodeId={treeNode.treeNodeId}
                />
            ) : (
                <></>
            )}
        </KortexDialogConfirmation>
    );
}
