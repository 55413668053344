import { errorPalette } from "@aos/react-components";
import { IWorkInstructionsFormInputConfig } from "@kortex/aos-common";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    errorLabel: {}, // injected
    asterisk: {
        color: errorPalette[500],
        paddingLeft: "3px",
    },
    flex: {
        display: "flex",
    },
});

export interface IOwnProps extends IWorkInstructionsFormInputConfig {
    classes?: Pick<ReturnType<typeof useStyles>, "errorLabel">;
}

export default function WorkInstructionsFormInputChecklist(props: IOwnProps): JSX.Element {
    const { itemState, itemProps } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    const [checklistItemsState, setChecklistItemsState] = useState<boolean[]>([]);

    const displayedLabel = itemState.label ? itemState.label : itemProps.label;

    /**
     * Specifically used for checklist input type
     */
    useEffect((): void => {
        if (checklistItemsState.length === 0 && itemState.items && itemState.items.length) {
            if (itemState.value === "true") {
                // value is true, all checkboxes are true
                setChecklistItemsState(itemState.items.map(() => true));
            } else if (itemState.value.split(",").length !== checklistItemsState.length) {
                // wrong number of items, we need to initialize
                setChecklistItemsState(itemState.items.map(() => false));
            } else {
                // set values
                setChecklistItemsState(itemState.value.split(",").map((value) => value === "true"));
            }
        } else {
            if (itemState.items && checklistItemsState.length === itemState.items.length && checklistItemsState.every((value) => value)) {
                // everyone is true, checklist is true
                onStateChanged("true");
            } else {
                onStateChanged(checklistItemsState.toString());
            }
        }
    }, [checklistItemsState]);

    /**
     * Effect called when the state of a different component is loaded, for refresh
     */
    useEffect((): void => {
        setChecklistItemsState([]);
    }, [itemState._id]);

    /**
     * Called when a checkbox in a checklist is clicked. Specifically used for checklist input type
     *
     * @param {number} index - Index of the checkbox that changed
     */
    const handleChecklistChange =
        (index: number): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const copy = [...checklistItemsState];
            copy[index] = e.target.checked;
            setChecklistItemsState(copy);
        };

    /**
     * Handles checklist state change
     * Its state is changed each time a checkbox from the list is checked or unchecked
     *
     * @param {string} value - checkbox value
     */
    const onStateChanged = (value: string): void => {
        props.onStateChanged({ ...props.itemState, value });
    };

    return (
        <React.Fragment>
            <div className={classes.flex}>
                <Typography variant="subtitle1">{displayedLabel}</Typography>
                {itemProps.required && (
                    <Typography className={classes.asterisk} variant="subtitle1">
                        {"*"}
                    </Typography>
                )}
            </div>
            {itemState.errorKey && (
                <div id="error-key-checklist" className={classes.errorLabel}>
                    {translate("action.workInstructions.form.error." + itemState.errorKey)}
                </div>
            )}
            {itemState.items &&
                itemState.items.map((item, index) => {
                    return (
                        <div key={index}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disableRipple={true}
                                        onChange={handleChecklistChange(index)}
                                        checked={checklistItemsState[index] ? checklistItemsState[index] : false}
                                    />
                                }
                                label={<Typography variant="subtitle1">{item}</Typography>}
                                value={item}
                            />
                        </div>
                    );
                })}
        </React.Fragment>
    );
}
