export var SequencerActionStatusEnum;
(function (SequencerActionStatusEnum) {
    SequencerActionStatusEnum[SequencerActionStatusEnum["COMPLETED"] = 1] = "COMPLETED";
    SequencerActionStatusEnum[SequencerActionStatusEnum["CONTINUE"] = 2] = "CONTINUE";
    SequencerActionStatusEnum[SequencerActionStatusEnum["FAILED"] = 3] = "FAILED";
    SequencerActionStatusEnum[SequencerActionStatusEnum["IDLE"] = 4] = "IDLE";
    SequencerActionStatusEnum[SequencerActionStatusEnum["PAUSED"] = 5] = "PAUSED";
    SequencerActionStatusEnum[SequencerActionStatusEnum["PLAYING"] = 6] = "PLAYING";
    SequencerActionStatusEnum[SequencerActionStatusEnum["STOPPED"] = 7] = "STOPPED";
    SequencerActionStatusEnum[SequencerActionStatusEnum["STOPPING"] = 8] = "STOPPING";
})(SequencerActionStatusEnum || (SequencerActionStatusEnum = {}));
