import { TreeNodeDbModel } from "@kortex/aos-common";

import {
    ClearProcesses,
    ProcessActionDeletedAction,
    ProcessActionInsertedAction,
    ProcessActionSettingsUpdatedAction,
    ProcessActionStepDeletedAction,
    ProcessActionStepInsertedAction,
    ProcessActionStepUpdatedAction,
    ProcessActionTypeRedux,
    ProcessActionUpdatedAction,
    ProcessInsertedAction,
    ProcessLookupTableFromTreeNodeIdUpdated,
    ProcessUpdatedAction,
    ProcessesLookupTableFromTreeNodesIdUpdated,
    ProcessesUpdatedAction,
    SetCopiedProcessActionAction,
    SetCopiedProcessActionStepAction,
    SetEditedProcessIdAction,
    SetProcessActionSettingsAction,
    SetProcessesAction,
} from "./process-types";

/**
 * Returns an action
 *
 */
export function clearProcessesAction(): ClearProcesses {
    return { type: ProcessActionTypeRedux.CLEAR_PROCESSES };
}

/**
 * Returns an action
 *
 * @param {ProcessActionDeletedAction} actions - deleted process actions
 */
export function processActionDeletedAction(actions: ProcessActionDeletedAction["value"]): ProcessActionDeletedAction {
    return { type: ProcessActionTypeRedux.DELETE_PROCESS_ACTION, value: actions };
}

/**
 * Returns an action
 *
 * @param {ProcessActionStepDeletedAction} action - deleted process action step
 */
// FIXME: This action is not since we do not have a step-specific state in Redux.
//        At the moment, we only update the process action when a step is deleted.
export function processActionStepDeletedAction(action: ProcessActionStepDeletedAction["value"]): ProcessActionStepDeletedAction {
    return { type: ProcessActionTypeRedux.DELETE_PROCESS_ACTION_STEP, value: action };
}

/**
 * Returns an action
 *
 * @param {ProcessActionInsertedAction} actions - inserted process actions
 */
export function processActionInsertedAction(actions: ProcessActionInsertedAction["value"]): ProcessActionInsertedAction {
    return { type: ProcessActionTypeRedux.INSERT_PROCESS_ACTION, value: actions };
}

/**
 * Returns an action
 *
 * @param {ProcessActionStepInsertedAction} action - inserted process actions
 */
export function processActionStepInsertedAction(action: ProcessActionStepInsertedAction["value"]): ProcessActionStepInsertedAction {
    return { type: ProcessActionTypeRedux.INSERT_PROCESS_ACTION_STEP, value: action };
}

/**
 * Returns an action
 *
 * @param {ProcessActionUpdatedAction} actions - updated actions
 */
export function processActionUpdatedAction(actions: ProcessActionUpdatedAction["value"]): ProcessActionUpdatedAction {
    return { type: ProcessActionTypeRedux.UPDATE_PROCESS_ACTION, value: actions };
}

/**
 * Returns an action
 *
 * @param {ProcessActionStepUpdatedAction} action - updated action
 */
export function processActionStepUpdatedAction(action: ProcessActionStepUpdatedAction["value"]): ProcessActionStepUpdatedAction {
    return { type: ProcessActionTypeRedux.UPDATE_PROCESS_ACTION_STEP, value: action };
}

/**
 * Returns an action
 *
 * @param {ProcessInsertedAction} process - inserted process
 */
export function processInsertedAction(process: ProcessInsertedAction["value"]): ProcessInsertedAction {
    return { type: ProcessActionTypeRedux.INSERT_PROCESS, value: process };
}

/**
 * Returns an action
 *
 * @param {ProcessUpdatedAction} process - updated process
 */
export function processUpdatedAction(process: ProcessUpdatedAction["value"]): ProcessUpdatedAction {
    return { type: ProcessActionTypeRedux.UPDATE_PROCESS, value: process };
}

/**
 * Returns an action
 *
 * @param {ProcessesUpdatedAction} processes - updated process
 */
export function processesUpdatedAction(processes: ProcessesUpdatedAction["value"]): ProcessesUpdatedAction {
    return { type: ProcessActionTypeRedux.UPDATE_PROCESSES, value: processes };
}

/**
 * Returns an action
 *
 * @param {ProcessActionSettingsUpdatedAction} process - updated process action settings
 */
export function processActionSettingsUpdatedAction(
    process: ProcessActionSettingsUpdatedAction["value"]
): ProcessActionSettingsUpdatedAction {
    return { type: ProcessActionTypeRedux.UPDATE_PROCESS_ACTION_SETTINGS, value: process };
}

/**
 * Returns an action
 *
 * @param {SetCopiedProcessActionAction} processAction - process action to copy
 */
export function setCopiedProcessAction(processAction: SetCopiedProcessActionAction["value"]): SetCopiedProcessActionAction {
    return { type: ProcessActionTypeRedux.SET_COPIED_PROCESS_ACTION, value: processAction };
}

/**
 * Returns an action
 *
 * @param {SetCopiedProcessActionStepAction} processActionStep - process action step to copy
 */
export function setCopiedProcessActionStepAction(
    processActionStep: SetCopiedProcessActionStepAction["value"]
): SetCopiedProcessActionStepAction {
    return { type: ProcessActionTypeRedux.SET_COPIED_PROCESS_ACTION_STEP, value: processActionStep };
}

/**
 * Returns an action
 *
 * @param {SetEditedProcessIdAction} process - process
 */
export function setEditedProcessIdAction(process: SetEditedProcessIdAction["value"]): SetEditedProcessIdAction {
    return { type: ProcessActionTypeRedux.SET_EDITED_PROCESS_ID, value: process };
}

/**
 * Returns an action
 *
 * @param {SetProcessActionSettingsAction} process - process action settings
 */
export function setProcessActionSettingsAction(process: SetProcessActionSettingsAction["value"]): SetProcessActionSettingsAction {
    return { type: ProcessActionTypeRedux.SET_PROCESS_ACTION_SETTINGS, value: process };
}

/**
 * Returns an action
 *
 * @param {SetProcessesAction} processes - processes
 */
export function setProcessesAction(processes: SetProcessesAction["value"]): SetProcessesAction {
    return { type: ProcessActionTypeRedux.SET_PROCESSES, value: processes };
}

/**
 * Return the new lookup table of relation between tree node Id
 *
 * @param {TreeNodeDbModel} treeNodeId - tree node to add to the lookup table
 */
export function setProcessLookupTableFromTreeNodeIdUpdated(
    treeNodeId: TreeNodeDbModel["treeNodeId"]
): ProcessLookupTableFromTreeNodeIdUpdated {
    return { type: ProcessActionTypeRedux.UPDATE_PROCESS_LOOKUP_FROM_TREENODEID, value: treeNodeId };
}

/**
 * Return the new lookup table of relation between tree nodes Id
 *
 * @param {TreeNodeDbModel[]} treeNodesId - tree nodes to add to the lookup table
 */
export function setProcessesLookupTableFromTreeNodesIdUpdated(
    treeNodesId: TreeNodeDbModel["treeNodeId"][]
): ProcessesLookupTableFromTreeNodesIdUpdated {
    return { type: ProcessActionTypeRedux.UPDATE_PROCESSES_LOOKUP_FROM_TREENODESID, value: treeNodesId };
}
