import { util } from "@kortex/aos-api-client";
import { APIResponse, EnumResponseStatus } from "@kortex/aos-common";
import Debug from "debug";
import { ThunkDispatch } from "redux-thunk";

import { Snackbar } from "../components/layout/snackbarConfigurator";

import { IReduxAction } from "./IReduxAction";
import { EnumPageTypes, GeneralActionType } from "./general-manager/general-types";
import { handleApiErrorNotification } from "./handleNotification";
import { clearProcessesAction } from "./process-manager/process-actions";
import { clearTreesAction } from "./tree-manager/tree-actions";
import { clearUserSessionAction } from "./user-manager/users-actions";

const debug = Debug("kortex:handle:api:error");

export function handleAPIError(res: APIResponse<unknown>, dispatch: ThunkDispatch<unknown, unknown, IReduxAction>): boolean {
    switch (res.status) {
        case EnumResponseStatus.LOGIN_REQUIRED:
            debug(res);

            dispatch({ type: GeneralActionType.SET_ROUTE, value: EnumPageTypes.LOGIN });
            dispatch(clearUserSessionAction());
            dispatch(clearProcessesAction());
            dispatch(clearTreesAction());
            return false;
        case EnumResponseStatus.ERROR:
            debug(res);
            Snackbar.error(res.errorMessage);
            return false;
        case EnumResponseStatus.UNAUTHORIZED:
            debug(res);
            Snackbar.error(res.errorMessage);
            return false;
        default:
            if (res instanceof util.errors.ApiError) {
                handleApiErrorNotification(res);
            } else {
                debug("%o", res);
                Snackbar.error(JSON.stringify(res));
            }

            return true;
    }
}
