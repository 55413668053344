import { KhronoEventEnum, KhronoExternalCallbackEnum, KhronoHookSourceEnum, KhronoSnapshotCallbackSourceEnum, SequencerActionStatusEnum, } from "../enums";
import { assertDefined, deepClone, defer, KortexSequencerDebugger, timestamp } from "../utilities";
const debug = KortexSequencerDebugger.khrono;
export const EXTERNAL_CALLBACKS_DEFAULT = {
    [KhronoExternalCallbackEnum.ON_EVENT]: () => void 0,
    [KhronoExternalCallbackEnum.ON_CURRENT_ACTION_CHANGE]: () => void 0,
    [KhronoExternalCallbackEnum.ON_SEQUENCE_CHANGE]: () => void 0,
    [KhronoExternalCallbackEnum.ON_SEQUENCE_END]: () => void 0,
    [KhronoExternalCallbackEnum.ON_SNAPSHOT_END]: () => void 0,
    [KhronoExternalCallbackEnum.ON_SNAPSHOT_START]: () => void 0,
};
export const HISTORY_SEQUENCE_ID_MAIN = "main";
export const SNAPSHOT_CALLBACKS_DEFAULT = {
    [KhronoSnapshotCallbackSourceEnum.ACTION_GROUP]: () => 0,
    [KhronoSnapshotCallbackSourceEnum.VARIABLE_MANAGER]: () => [],
};
export const KHRONO_DEFAULT_SEQUENCE = {
    actions: [],
    prePlayEvents: [],
    stats: {
        end: null,
        start: null,
    },
    initVariables: [],
};
export const KHRONO_DEFAULT_HISTORY = {
    getSequencesId: function () {
        return Object.keys(this.sequences);
    },
    sequences: {
        main: KHRONO_DEFAULT_SEQUENCE,
    },
};
export const KHRONO_DEFAULT_STATS = {
    failureCount: 0,
    totalDurationMs: 0,
    pauseCount: 0,
    pauseDurationMs: 0,
    playDurationMs: 0,
};
let _enabled = false, _history = deepClone(KHRONO_DEFAULT_HISTORY), _externalCallback = deepClone(EXTERNAL_CALLBACKS_DEFAULT), _snapshotCallback = deepClone(SNAPSHOT_CALLBACKS_DEFAULT);
function _actionStatusChanged(event) {
    const currentKhronoAction = _getCurrentAction();
    if (!currentKhronoAction) {
        debug("_actionStatusChanged:error:could not get current Khrono action");
        console.error("_actionStatusChanged:error:could not get current Khrono action");
        return void 0;
    }
    switch (event.payload.status) {
        case SequencerActionStatusEnum.FAILED:
            currentKhronoAction.stats.failureCount += 1;
            break;
        case SequencerActionStatusEnum.PAUSED:
            currentKhronoAction.stats.pauseCount += 1;
            break;
    }
    const lastStatusEvent = _getCurrentAction()
        ?.events?.reverse()
        ?.find((event) => _isStatusEvent(event));
    if (lastStatusEvent) {
        const duration = event.timestamp.now - lastStatusEvent.timestamp.now;
        switch (lastStatusEvent.payload.status) {
            case SequencerActionStatusEnum.PAUSED:
                currentKhronoAction.stats.pauseDurationMs += duration;
                break;
            case SequencerActionStatusEnum.PLAYING:
                currentKhronoAction.stats.playDurationMs += duration;
                break;
        }
        currentKhronoAction.stats.totalDurationMs += duration;
    }
}
function _createEvent(event) {
    if (event.type === KhronoEventEnum.ACTION_STATUS_CHANGE) {
        _actionStatusChanged(event);
    }
    const currentKhronoAction = _getCurrentAction();
    if (currentKhronoAction) {
        currentKhronoAction.events.push(event);
        currentKhronoAction.events = currentKhronoAction.events.sort((event1, event2) => event1.timestamp.now - event2.timestamp.now);
    }
    else {
        _history.sequences.main.prePlayEvents.push(event);
        _history.sequences.main.prePlayEvents = _history.sequences.main.prePlayEvents.sort((event1, event2) => event1.timestamp.now - event2.timestamp.now);
    }
    _externalCallback[KhronoExternalCallbackEnum.ON_EVENT](event);
    if (currentKhronoAction) {
        _externalCallback[KhronoExternalCallbackEnum.ON_CURRENT_ACTION_CHANGE](currentKhronoAction);
    }
    _externalCallback[KhronoExternalCallbackEnum.ON_SEQUENCE_CHANGE](HISTORY_SEQUENCE_ID_MAIN, _history.sequences.main);
}
function _getCurrentAction() {
    return _history.sequences.main.actions[_history.sequences.main.actions.length - 1];
}
function _isStatusEvent(event) {
    return event.type === KhronoEventEnum.ACTION_STATUS_CHANGE;
}
export function createEvent(event) {
    if (!isEnabled()) {
        return void 0;
    }
    const newEvent = deepClone({
        ...event,
        timestamp: timestamp(),
    });
    _createEvent(newEvent);
}
export async function createHookEvent(payload, hook, hookCatchCb, ...hookParams) {
    if (!isEnabled()) {
        return hook(...hookParams)?.catch(hookCatchCb);
    }
    const timestampStart = timestamp();
    return hook(...hookParams)
        .catch(hookCatchCb)
        .then(async () => {
        const newEvent = deepClone({
            payload: {
                ...payload,
                timestampEnd: timestamp(),
            },
            timestamp: timestampStart,
            type: KhronoEventEnum.HOOK,
        });
        return _createEvent(newEvent);
    });
}
export function createRemoteActionCompletionEvent(executor) {
    if (!isEnabled()) {
        return defer(executor);
    }
    const timestampStart = timestamp();
    const deferedPromise = defer(executor);
    deferedPromise.promise.then(async () => {
        const newEvent = deepClone({
            payload: {
                timestampEnd: timestamp(),
            },
            timestamp: timestampStart,
            type: KhronoEventEnum.REMOTE_ACTION_COMPLETION,
        });
        return _createEvent(newEvent);
    });
    return deferedPromise;
}
export async function createRemoteActionConfirmationEvent(remoteEvent, fn, fnCatchCb, ...fnParams) {
    if (!isEnabled()) {
        return fn(...fnParams).catch(fnCatchCb);
    }
    const timestampStart = timestamp();
    return fn(...fnParams)
        .catch(fnCatchCb)
        .then(async () => {
        const newEvent = deepClone({
            payload: {
                remoteEvent,
                timestampEnd: timestamp(),
            },
            timestamp: timestampStart,
            type: KhronoEventEnum.REMOTE_ACTION_CONFIRMATION,
        });
        return _createEvent(newEvent);
    });
}
export function getHistory() {
    return deepClone(_history);
}
export function init(enabled) {
    reset();
    _enabled = enabled;
    debug(`Khrono is ${_enabled ? "enabled" : "disabled"}`);
}
export function isEnabled() {
    return _enabled;
}
export function reset() {
    _history = deepClone(KHRONO_DEFAULT_HISTORY);
    _externalCallback = deepClone(EXTERNAL_CALLBACKS_DEFAULT);
    _snapshotCallback = deepClone(SNAPSHOT_CALLBACKS_DEFAULT);
}
export function onSnapshot(source, cb) {
    _snapshotCallback[source] = cb;
}
export function setSequence(id, sequence) {
    _history.sequences[id] = sequence;
}
export function setExternalCallback(type, cb) {
    _externalCallback[type] = cb;
}
export function setHistory(sequence, sequenceId = HISTORY_SEQUENCE_ID_MAIN) {
    _history.sequences[sequenceId] = sequence;
}
export function setMainSequenceStat(stats) {
    if (!isEnabled()) {
        return void 0;
    }
    for (const key of Object.keys(stats)) {
        _history.sequences.main.stats[key] = stats[key];
    }
    _externalCallback[KhronoExternalCallbackEnum.ON_SEQUENCE_CHANGE](HISTORY_SEQUENCE_ID_MAIN, _history.sequences.main);
    if (stats.end) {
        _externalCallback[KhronoExternalCallbackEnum.ON_SEQUENCE_END](HISTORY_SEQUENCE_ID_MAIN, _history.sequences.main);
    }
}
export function snapshotAction(actionIndex, state, status, variableNamespace, getVariables, next) {
    if (!isEnabled()) {
        return void 0;
    }
    const newTimestamp = timestamp();
    const snapshot = {
        state,
        status,
        timestamp: newTimestamp,
        variables: getVariables(variableNamespace),
    };
    let khronoAction;
    if (next === undefined) {
        khronoAction = deepClone({
            actionGroupId: _snapshotCallback[KhronoSnapshotCallbackSourceEnum.ACTION_GROUP](),
            index: actionIndex,
            events: [],
            snapshotStart: snapshot,
            snapshotEnd: null,
            stats: KHRONO_DEFAULT_STATS,
        });
        _history.sequences.main.actions.push(khronoAction);
    }
    else {
        khronoAction = _getCurrentAction();
        if (!khronoAction) {
            debug("snapshot:error:could not get current Khrono action");
            console.error("snapshot:error:could not get current Khrono action");
            return void 0;
        }
        else {
            khronoAction.snapshotEnd = {
                ...snapshot,
                next,
            };
        }
    }
    _externalCallback[next === undefined ? KhronoExternalCallbackEnum.ON_SNAPSHOT_START : KhronoExternalCallbackEnum.ON_SNAPSHOT_END](khronoAction.actionGroupId, khronoAction.index, snapshot);
    if (khronoAction) {
        _externalCallback[KhronoExternalCallbackEnum.ON_CURRENT_ACTION_CHANGE](khronoAction);
    }
    _externalCallback[KhronoExternalCallbackEnum.ON_SEQUENCE_CHANGE](HISTORY_SEQUENCE_ID_MAIN, _history.sequences.main);
}
export function snapshotVariables() {
    if (!isEnabled()) {
        return void 0;
    }
    let variables;
    try {
        variables = _snapshotCallback[KhronoSnapshotCallbackSourceEnum.VARIABLE_MANAGER]();
        assertDefined(variables);
    }
    catch (error) {
        debug("error:%O", error);
        console.error(error);
        variables = [];
    }
    _history.sequences.main.initVariables = variables;
}
export const services = {
    get enums() {
        return {
            get KhronoEventEnum() {
                return KhronoEventEnum;
            },
            get KhronoHookSourceEnum() {
                return KhronoHookSourceEnum;
            },
        };
    },
    get history() {
        return getHistory();
    },
    isEnabled,
    onCurrentActionChange: (cb) => {
        setExternalCallback(KhronoExternalCallbackEnum.ON_CURRENT_ACTION_CHANGE, cb);
    },
    onEvent: (cb) => {
        setExternalCallback(KhronoExternalCallbackEnum.ON_EVENT, cb);
    },
    onSequenceChange: (cb) => {
        setExternalCallback(KhronoExternalCallbackEnum.ON_SEQUENCE_CHANGE, cb);
    },
    onSequenceEnd: (cb) => {
        setExternalCallback(KhronoExternalCallbackEnum.ON_SEQUENCE_END, cb);
    },
    onSnapshotEnd: (cb) => {
        setExternalCallback(KhronoExternalCallbackEnum.ON_SNAPSHOT_END, cb);
    },
    onSnapshotStart: (cb) => {
        setExternalCallback(KhronoExternalCallbackEnum.ON_SNAPSHOT_START, cb);
    },
    reset,
    setHistory,
};
