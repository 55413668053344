const TYPEOF_OBJECT = "object";
export function deepClone(source) {
    if (source === undefined || source === null) {
        return source;
    }
    if (typeof source !== TYPEOF_OBJECT) {
        return source;
    }
    const obj = Array.isArray(source) ? [] : {};
    for (const propName of Object.keys(source)) {
        const prop = source[propName];
        if (typeof prop === TYPEOF_OBJECT) {
            obj[propName] = deepClone(prop);
        }
        else {
            obj[propName] = prop;
        }
    }
    return obj;
}
