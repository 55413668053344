import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    ReworkAllReq,
    ReworkAllRes,
    ReworkDeleteReq,
    ReworkDeleteRes,
    ReworkDeletedNotif,
    ReworkInsertReq,
    ReworkInsertRes,
    ReworkInsertedNotif,
    ReworkOneReq,
    ReworkOneRes,
    ReworkUpdateReq,
    ReworkUpdateRes,
    ReworkUpdatedNotif,
    ReworkGetInfoBeforeCreateRes,
    ReworkGetInfoBeforeCreateReq,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { reworkAll } from "./all";
import { reworkOne } from "./one";
import { reworkInsert } from "./insert";
import { reworkUpdate } from "./update";
import { reworkGetInfoBeforeCreate } from "./getInfoBeforeCreate";
import { reworkInserted } from "./inserted";
import { reworkUpdated } from "./updated";
import { reworkDelete } from "./delete";
import { reworkDeleted } from "./deleted";

interface IAOSClientServiceWrappedRework {
    delete: AOSClientServiceRequestWrapped<ReworkDeleteReq, ReworkDeleteRes>;
    getAll: AOSClientServiceRequestWrapped<ReworkAllReq, ReworkAllRes>;
    getOne: AOSClientServiceRequestWrapped<ReworkOneReq, ReworkOneRes>;
    insert: AOSClientServiceRequestWrapped<ReworkInsertReq, ReworkInsertRes>;
    update: AOSClientServiceRequestWrapped<ReworkUpdateReq, ReworkUpdateRes>;
    getInfoBeforeCreate: AOSClientServiceRequestWrapped<ReworkGetInfoBeforeCreateReq, ReworkGetInfoBeforeCreateRes>;
    onDeleted: AOSClientServiceNotificationWrapped<ReworkDeletedNotif>;
    onInserted: AOSClientServiceNotificationWrapped<ReworkInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<ReworkUpdatedNotif>;
}

type AOSClientServiceWrappedRework = IAOSClientServiceWrappedRework;

// ADD_API_CALL Rework
export function generateReworkServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedRework {
    return {
        delete: AOSClientServiceWrapperUI.request(reworkDelete(router)),
        getAll: AOSClientServiceWrapperUI.request(reworkAll(router)),
        getOne: AOSClientServiceWrapperUI.request(reworkOne(router)),
        insert: AOSClientServiceWrapperUI.request(reworkInsert(router)),
        update: AOSClientServiceWrapperUI.request(reworkUpdate(router)),
        getInfoBeforeCreate: AOSClientServiceWrapperUI.request(reworkGetInfoBeforeCreate(router)),
        onDeleted: AOSClientServiceWrapperUI.notification(reworkDeleted(router, notifier)),
        onInserted: AOSClientServiceWrapperUI.notification(reworkInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(reworkUpdated(router, notifier)),
    };
}

generateReworkServiceClient.serviceId = 31;
