import { rainbowPalette } from "@aos/react-components";
import { IWorkInstructionsElementConfig, IWorkInstructionsPDFConfig, TValidRotationAngle } from "@kortex/aos-common";
import * as React from "react";
import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";

import { normalizeSVGDimension } from "../../../../../../../../utilitites/normalizeSVGDimension";
import * as CloudStorage from "../../../../../../../../utilitites/storage";
import PdfPlayer, { PDF_PLAYER_HEIGHT } from "../../../../../../../core/PdfPlayer/PdfPlayer";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

const BORDER_SIZE = 1;

export interface IOwnProps {
    pdfProps: IWorkInstructionsElementConfig<IWorkInstructionsPDFConfig>;
    onSave?: (element: IWorkInstructionsElementConfig<IWorkInstructionsPDFConfig>) => void;
}

export default function WorkInstructionsPDF(props: IOwnProps): JSX.Element {
    const { cropBottom, cropLeft, cropRight, extendedProps, cropTop, height, rotation, width, x, y } = props.pdfProps;
    const finalHeight = height - cropBottom - cropTop;
    const finalWidth = width - cropRight - cropLeft;
    const [pageNumberPDF, setPageNumberPDF] = useState<number>(1);
    const [rotateAnglePDF, setRotateAnglePDF] = useState<0 | 90 | 180 | 270>(0);
    const [pdfFile, setPdfFile] = useState("");

    /**
     * Effect triggered when PDF URL has changed
     * Load PDF in URI base64 string to be compatible with Electron
     */
    useEffect((): void => {
        if (!extendedProps || !extendedProps.url) {
            return;
        }
        fetch(`${CloudStorage.get().host}/${extendedProps.url}`).then((response): void => {
            if (response.ok) {
                response.blob().then((blob): void => {
                    const reader = new FileReader();

                    reader.onload = (): void => {
                        setPdfFile(reader.result as string);
                    };

                    reader.readAsDataURL(blob);
                });
            }
        });
    }, [extendedProps.url]);

    /**
     * Handle request to change page
     *
     * @param {number} pageNumber - New page to display
     */
    const handlePdfPageChange = (pageNumber: number): void => {
        setPageNumberPDF(pageNumber);
    };

    /**
     * Handle request to rotate document
     *
     * @param {TValidRotationAngle} rotateAngle - Rotate Angle
     */
    const handlePdfPageRotate = (rotateAngle: TValidRotationAngle): void => {
        setRotateAnglePDF(rotateAngle);
        if (props.onSave) {
            props.onSave({
                ...props.pdfProps,
                extendedProps: {
                    ...extendedProps,
                    rotate: rotateAngle,
                },
            });
        }
    };

    return (
        <g transform={`translate(${x},${y}) rotate(${rotation} ${finalWidth / 2} ${finalHeight / 2})`}>
            <svg height={normalizeSVGDimension(height)} width={normalizeSVGDimension(width)} x="0" xmlns="http://www.w3.org/2000/svg" y="0">
                <rect fill={rainbowPalette[15]} x="0" y="0" width={width} height={height} stroke={rainbowPalette[14]} strokeWidth="1px" />
                <foreignObject width={width} height={PDF_PLAYER_HEIGHT}>
                    <PdfPlayer
                        allPages={props.pdfProps.extendedProps.allPages}
                        totalPages={props.pdfProps.extendedProps.totalPages}
                        selectedPages={props.pdfProps.extendedProps.selectedPages}
                        onPageChange={handlePdfPageChange}
                        onPageRotate={handlePdfPageRotate}
                    />
                </foreignObject>
                <foreignObject
                    x={BORDER_SIZE}
                    y={PDF_PLAYER_HEIGHT}
                    width={width - BORDER_SIZE * 2}
                    height={height - PDF_PLAYER_HEIGHT - BORDER_SIZE}
                >
                    <Document file={pdfFile} rotate={rotateAnglePDF}>
                        <Page height={height - 50} pageNumber={pageNumberPDF} />
                    </Document>
                </foreignObject>
            </svg>
        </g>
    );
}
