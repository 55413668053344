import { primaryPalette, secondaryPalette } from "@aos/react-components";
import { IUserGroupDbModel, IUserRoleDbModel, UserGroupId, UserGroupStatusEnum, UserRoleId } from "@kortex/aos-common";
import { SearchUserIcon } from "@kortex/aos-ui/components/core/Icons/searchUser";
import KortexSearchField from "@kortex/aos-ui/components/core/KortexSearchField";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesUsersGroups, useEntitiesUsersRoles } from "@kortex/aos-ui/redux/effects";
import { FormControl, Input, MenuItem, Select, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles({
    clearFilterLabel: {
        color: secondaryPalette[500],
        "&:hover": {
            color: primaryPalette[500],
        },
    },
    filterInput: {
        flex: 2,
    },
    filterInputSelect: {
        padding: "5px",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },

    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    searchBox: {
        width: "100%",
    },
});
interface IUserFilter {
    masterSearch?: string;
    userGroups: UserGroupId[];
    userRoles: UserRoleId[];
}
export type UserFilters = IUserFilter;

interface IOwnProps {
    filters: UserFilters;
    onFilterChange: React.Dispatch<React.SetStateAction<Partial<UserFilters>>>;
}

function UserSearchBar(props: IOwnProps): JSX.Element {
    const { filters, onFilterChange } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const allUserGroups = useEntitiesUsersGroups();
    const allUserRoles = useEntitiesUsersRoles();

    /**
     * Called when the inner filters change
     *
     * @param {string} key - indicates which filter changed
     */
    const handleFilterChange =
        (key: keyof UserFilters): ((e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
            onFilterChange((prevState) => ({
                ...prevState,
                [key]: e.target.value,
            }));
        };

    /**
     * Called when filters change. Checks if filters equal their default value (plainText is excluded from check)
     */
    const isFilterActive = (): boolean =>
        ["masterSearch", "userGroups", "userRoles"].some((key: keyof UserFilters) => filters[key]?.length);

    /**
     * Called when the text in the searchbar changes
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        onFilterChange((prevState) => ({
            ...prevState,
            masterSearch: val,
        }));
    };

    /**
     * Called when clicking the Clear Filter label
     */
    const handleClearFilters = (): void => {
        onFilterChange((prevState) => ({
            ...prevState,
            masterSearch: "",
            userGroups: [],
            userRoles: [],
        }));
    };

    return (
        <KortexSearchField
            className={classes.searchBox}
            icon={<SearchUserIcon />}
            isFilterActive={isFilterActive()}
            onChange={handleSearchTextChange}
            placeholder={translate("general.searchUser")}
            InputProps={{
                id: "userListSearchBarId",
            }}
        >
            <React.Fragment>
                {/* ROLES FILTER */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("settings.userSettings.roles")}</Typography>
                    <FormControl className={classes.filterInput}>
                        <Select
                            inputProps={{
                                className: classes.filterInputSelect,
                            }}
                            multiple={true}
                            input={<Input id="select-multiple-chip" />}
                            onChange={handleFilterChange("userRoles")}
                            value={filters.userRoles}
                        >
                            {allUserRoles.map(
                                (role: IUserRoleDbModel, index: number): JSX.Element => (
                                    <MenuItem key={index} value={role.userRoleId}>
                                        {role.name}
                                    </MenuItem>
                                )
                            )}
                        </Select>
                    </FormControl>
                </div>
                {/* GROUPS FILTER */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("settings.userSettings.groups")}</Typography>
                    <FormControl className={classes.filterInput}>
                        <Select
                            inputProps={{
                                className: classes.filterInputSelect,
                            }}
                            multiple={true}
                            input={<Input id="select-multiple-chip" />}
                            onChange={handleFilterChange("userGroups")}
                            value={filters.userGroups}
                        >
                            {allUserGroups
                                .filter((group) => group.status !== UserGroupStatusEnum.ARCHIVED)
                                .map(
                                    (group: IUserGroupDbModel, index: number): JSX.Element => (
                                        <MenuItem key={index} value={group.userGroupId}>
                                            {group.name}
                                        </MenuItem>
                                    )
                                )}
                        </Select>
                    </FormControl>
                </div>

                <div className={classes.optionsItem}>
                    <div className={classes.filterLabel} />
                    <Typography onClick={handleClearFilters} className={classes.clearFilterLabel}>
                        {translate("scheduler.clearFilters")}
                    </Typography>
                </div>
            </React.Fragment>
        </KortexSearchField>
    );
}

export default UserSearchBar;
