import { TTaskUnknown } from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export enum TaskActionType {
    INSERT_TASK = "@@TASK/INSERT_TASK",
    SET_TASK_LIST = "@@TASK/SET_TASK_LIST",
    UPDATE_TASK = "@@TASK/UPDATE_TASK",
    DELETE_TASK = "@@TASK/DELETE_TASK",
}

interface ITaskState {
    tasks: TTaskUnknown[];
}

export type TaskState = ITaskState;

export type SetTaskListAction = ComplexAction<TaskActionType.SET_TASK_LIST, TaskState["tasks"]>;
export type TaskInsertedAction = ComplexAction<TaskActionType.INSERT_TASK, TaskState["tasks"]>;
export type TaskUpdatedAction = ComplexAction<TaskActionType.UPDATE_TASK, TaskState["tasks"]>;
export type TaskDeletedAction = ComplexAction<TaskActionType.DELETE_TASK, TaskState["tasks"]>;

export type TaskActions = SetTaskListAction | TaskInsertedAction | TaskUpdatedAction | TaskDeletedAction;
