import { FileInfoDbModel, FileInsertRes, FileUpdateRes, UnwrapAOSPayload } from "@kortex/aos-common";

import { APIPayload } from "../../utilitites/kortex-client/client";
import { StandardThunk, AppState, StandardDispatch } from "../store";
import { handleAPIError } from "../handleAPIError";
import { OrUndefined, Unpack } from "../app.types";
import { treeFileInsertedAction } from "../tree-manager/tree-actions";

import { filesUpdatedAction, filesInsertedAction } from "./file-actions";

// thunks

/**
 * Get a file
 *
 * @param {APIPayload<"file", "getOne">} fileToGet - file to get
 */
export function fileGet(fileToGet: APIPayload<"file", "getOne">): StandardThunk<OrUndefined<FileInfoDbModel>> {
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<Unpack<AppState["file"]["files"]>>> {
        // Search in store first
        const file = getState().file.files.find((file) => file.fileInfoId === fileToGet.fileInfoId);

        if (file) {
            return file;
        }

        // Search in database
        return api.services.file
            .getOne(fileToGet)()
            .then((file) => {
                if (file) {
                    dispatch(filesUpdatedAction([file]));
                    return file;
                }

                return void 0;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return void 0;
            });
    };
}

/**
 * Get a file by file ID
 *
 * @param {APIPayload<"file", "getOneByFileId">} fileToGet - file to get
 */
export function fileGetByFileId(fileToGet: APIPayload<"file", "getOneByFileId">): StandardThunk<OrUndefined<FileInfoDbModel>> {
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<OrUndefined<Unpack<AppState["file"]["files"]>>> {
        // Search in store first
        const file = getState().file.files.find((file) => file.fileId === fileToGet.fileId);

        if (file) {
            return file;
        }

        // Search in database
        return api.services.file
            .getOneByFileId(fileToGet)()
            .then((file) => {
                if (file) {
                    dispatch(filesUpdatedAction([file]));
                    return file;
                }

                return void 0;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return void 0;
            });
    };
}

/**
 * Get a file by tree node ID
 *
 * @param {APIPayload<"file", "getOneByTreeNode">} fileToGet - file to get
 */
export function fileGetByTreeNode(
    fileToGet: APIPayload<"file", "getOneByTreeNode">
): StandardThunk<OrUndefined<OrUndefined<FileInfoDbModel>>> {
    return async function (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api }): Promise<OrUndefined<FileInfoDbModel>> {
        // Search in store first
        const file = getState().file.files.find((file) => file.treeNodeId === fileToGet.treeNodeId);

        if (file) {
            return file;
        }

        // Search in database
        return api.services.file
            .getOneByTreeNode(fileToGet)()
            .then((file) => {
                if (file) {
                    dispatch(filesUpdatedAction([file]));
                    return file;
                }

                return void 0;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return void 0;
            });
    };
}

/**
 * Insert a file
 *
 * @param {APIPayload<"file", "insert">} files - process to insert
 */
export function fileInsert(files: APIPayload<"file", "insert">): StandardThunk<UnwrapAOSPayload<FileInsertRes>> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<FileInsertRes>> => {
        return api.services.file
            .insert(files)()
            .then((insertedFiles) => {
                if (insertedFiles && insertedFiles.length) {
                    dispatch(filesInsertedAction(insertedFiles.map((file) => file.file)));
                    dispatch(treeFileInsertedAction(insertedFiles.map((file) => file.node)));
                    return insertedFiles;
                }

                return [];
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

/**
 * Update a file
 *
 * @param {APIPayload<"file", "update">} files - process to insert
 */
export function filesUpdate(files: APIPayload<"file", "update">): StandardThunk<UnwrapAOSPayload<FileUpdateRes>> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<FileUpdateRes>> => {
        return api.services.file
            .update(files)()
            .then((updatedFiles) => {
                if (updatedFiles && updatedFiles.length) {
                    const fileCopies = [...updatedFiles];

                    dispatch(filesUpdatedAction(fileCopies));

                    return fileCopies;
                }

                return [];
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}
