import { IPlayerReworkInfo, IProcessPlayerProcessAnswer, IReworkUiModel, IStepFailure } from "@kortex/aos-common";

import { isDesktopMode } from "../../utilitites/desktopUtils";
import { APIPayload, APIResponse } from "../../utilitites/kortex-client/client";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";

import {
    enablePlayNextAction,
    initStateAction,
    updateActionStateAction,
    updateHistoryAction,
    updateHubConnectionStatusAction,
    updateProcessStateAction,
    updateUiActionPropsAction,
} from "./player-actions";
import {
    PlayerEnablePlayNextAction,
    PlayerInitStateAction,
    PlayerUpdateActionStateAction,
    PlayerUpdateHistoryAction,
    PlayerUpdateHubConnectionAction,
    PlayerUpdateProcessStateAction,
    PlayerUpdateUiActionPropsAction,
} from "./player-types";

//------------------------------------------------------------------------------------------------------------------
// Commands
//------------------------------------------------------------------------------------------------------------------

/**
 * Get the history of the playing process
 */
export function processPlayerGetHistory(): StandardThunk<APIResponse<"processPlayer", "getHistory"> | undefined> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api, apiDesktop }
    ): Promise<APIResponse<"processPlayer", "getHistory"> | undefined> => {
        try {
            if (isDesktopMode) {
                return apiDesktop.services.processPlayer.getHistory({ runnerId: "" })();
            } else {
                return api.services.processPlayer.getHistory({ runnerId: "" })();
            }
        } catch (error) {
            handleAPIError(error, dispatch);
        }
        return undefined;
    };
}

/**
 * Play a process
 *
 * @param {APIPayload<"processPlayer", "play">} info - info of the process to play
 */
export function processPlayerPlay(info: APIPayload<"processPlayer", "play">): StandardThunk<IProcessPlayerProcessAnswer | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api, apiDesktop }
    ): Promise<IProcessPlayerProcessAnswer | undefined> {
        try {
            if (isDesktopMode) {
                return apiDesktop.services.processPlayer.play({
                    ...info,
                    clientId: apiDesktop.patch.apiToken?.clientId ?? "",
                    userConnectionHandle: "", // Provided by the server (Hub/Desktop)
                    userId: apiDesktop.patch.apiToken?.userId ?? -1,
                    userName: apiDesktop.patch.apiToken?.userName ?? "",
                    userCode: apiDesktop.patch.apiToken?.userCode ?? "",
                })();
            } else {
                return api.services.processPlayer.play(info)();
            }
        } catch (error) {
            handleAPIError(error, dispatch);
        }
        return undefined;
    };
}

/**
 * Update Process State
 *
 * @param {APIPayload<"processPlayer", "updateProcessState">} processState - process state
 */
export function processPlayerUpdateProcessState(processState: APIPayload<"processPlayer", "updateProcessState">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api, apiDesktop }): Promise<void> => {
        try {
            // Disable the "Play Next" button
            dispatch(enablePlayNextAction(false));

            if (isDesktopMode) {
                await apiDesktop.services.processPlayer.updateProcessState(processState)({ timeout: 10_000 });
            } else {
                await api.services.processPlayer.updateProcessState(processState)({ timeout: 10_000 });
            }
        } catch (error) {
            handleAPIError(error, dispatch);

            // Enable the "Play Next" button if there was an error
            dispatch(enablePlayNextAction(true));
        }
        return undefined;
    };
}

/**
 * Stop a process
 */
export function processPlayerStop(): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api, apiDesktop }): Promise<void> => {
        try {
            if (isDesktopMode) {
                await apiDesktop.services.processPlayer.stop({ runnerId: "" })();
            } else {
                await api.services.processPlayer.stop({ runnerId: "" })();
            }
        } catch (error) {
            handleAPIError(error, dispatch);
        }
        return void 0;
    };
}

/**
 * Send fail info
 */
export function processPlayerFailStep(failures: APIPayload<"processPlayer", "failStep">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api, apiDesktop }): Promise<void> => {
        try {
            if (isDesktopMode) {
                await apiDesktop.services.processPlayer.failStep(failures)();
            } else {
                await api.services.processPlayer.failStep(failures)();
            }
        } catch (error) {
            handleAPIError(error, dispatch);
        }
        return void 0;
    };
}

/**
 * Pause a process
 */
export function processPlayerPause(): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api, apiDesktop }): Promise<void> => {
        try {
            if (isDesktopMode) {
                await apiDesktop.services.processPlayer.pause({ runnerId: "" })();
            } else {
                await api.services.processPlayer.pause({ runnerId: "" })();
            }
        } catch (error) {
            handleAPIError(error, dispatch);
        }
        return void 0;
    };
}

/**
 * Resume a process
 */
export function processPlayerResume(): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api, apiDesktop }): Promise<void> => {
        try {
            if (isDesktopMode) {
                await apiDesktop.services.processPlayer.resume({ runnerId: "" })();
            } else {
                await api.services.processPlayer.resume({ runnerId: "" })();
            }
        } catch (error) {
            handleAPIError(error, dispatch);
        }
        return void 0;
    };
}

/**
 * Retry a process
 */
export function processPlayerRetry(): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api, apiDesktop }): Promise<void> => {
        try {
            if (isDesktopMode) {
                await apiDesktop.services.processPlayer.retry({ runnerId: "" })();
            } else {
                await api.services.processPlayer.retry({ runnerId: "" })();
            }
        } catch (error) {
            handleAPIError(error, dispatch);
        }
        return void 0;
    };
}

/**
 * Insert one rework
 */
export function processPlayerInsertRework(failures: IStepFailure[], reworkInfo: IPlayerReworkInfo): StandardThunk<IReworkUiModel[]> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IReworkUiModel[]> => {
        try {
            return [...(await api.services.processPlayer.insertRework({ failures, reworkInfo })())];
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return [];
        }
    };
}

//------------------------------------------------------------------------------------------------------------------
// Notifications
//------------------------------------------------------------------------------------------------------------------
export function playerEnablePlayNext(actionState: PlayerEnablePlayNextAction["value"]): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(enablePlayNextAction(actionState));
    };
}

export function playerInitState(actionState: PlayerInitStateAction["value"]): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(initStateAction(actionState));
    };
}

export function playerUpdateActionState(actionState: PlayerUpdateActionStateAction["value"]): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(updateActionStateAction(actionState));
    };
}

export function playerUpdateHistory(actionState: PlayerUpdateHistoryAction["value"]): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(updateHistoryAction(actionState));
    };
}

export function playerUpdateHubConnection(actionState: PlayerUpdateHubConnectionAction["value"]): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(updateHubConnectionStatusAction(actionState));
    };
}

export function playerUpdateProcessState(actionState: PlayerUpdateProcessStateAction["value"]): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(updateProcessStateAction(actionState));
    };
}

export function playerUpdateUiActionProps(actionState: PlayerUpdateUiActionPropsAction["value"]): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(updateUiActionPropsAction(actionState));
    };
}

/**
 * Get Runner Dashboard Info
 */
/*export function processRunnerGetDashboardInfo(): StandardThunk<IDashboardInfo | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api, apiDesktop }): Promise<IDashboardInfo | undefined> => {
        try {
            if (isDesktopMode) {
                const answer = await apiDesktop.services.processPlayer.getDashboardInfo({ runnerId: "" })();
                return answer.dashboardInfo;
            } else {
                const answer = await api.services.processPlayer.getDashboardInfo({ runnerId: "" })();
                return answer.dashboardInfo;
            }
        } catch (error) {
            handleAPIError(error, dispatch);
        }
        return undefined;
    };
}*/
