import { EnumRequestResponse, IRestKeyValue, IRestParams, ProcessEditorRightsEnum } from "@kortex/aos-common";
import { greyPalette, theme } from "@aos/react-components";
import { AppBar, makeStyles, Tab, Tabs } from "@material-ui/core";
import * as React from "react";

import ConnectorRestParamList from "../RestParamList/ConnectorRestParamList";
import VariablePicker from "../../../../../../../core/VariablePicker/VariablePicker";
import { IUserRightsProps } from "../../../../../../../../utilitites/IUserRights";
import { useTranslate } from "../../../../../../../../hooks/useTranslate";
import { ILocale } from "../../../../../../../../locales/ILocale";

const useStyles = makeStyles({
    bodyTexField: {
        width: "100%",
    },
    paramList: {
        backgroundColor: greyPalette[100],
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        marginTop: "32px",
    },
    type: {
        width: "140px",
    },
});

// Tabs will be displayed in this order
// Keep lower scale for properties
export enum EnumParamTabs {
    query = 0,
    header = 1,
    body = 2,
}

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    bodyDisabled?: boolean;
    contentType: EnumRequestResponse;
    onParamChange: (params: IRestParams) => void;
    onTabChange: (index: number) => void;
    params: IRestParams;
    selectedTab: number;
}

export default function ConnectorRestParamTabs(props: IOwnProps): JSX.Element {
    const { bodyDisabled, contentType, onParamChange, onTabChange, params, selectedTab, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    /**
     * Called when body is change with normal textfield instead of key/param fields
     *
     * @param {string} value - change event data
     */
    const handleBodyTextBlur = (value: string): void => {
        if (params.body[0].value !== value) {
            onParamChange({ ...params, body: [{ key: "", value }] });
        }
    };

    /**
     * Called when a param (key or value) is changed
     *
     * @param {IRestKeyValue[]} updatedParams - updated param key/value
     */
    const handleParamChange = (updatedParams: IRestKeyValue[]): void => {
        onParamChange({ ...params, [EnumParamTabs[selectedTab]]: updatedParams });
    };

    /**
     * Called when selected tab is changed
     *
     */
    const handleTabChange = (event: React.ChangeEvent<{}>, index: number): void => {
        onTabChange(index);
    };

    /**
     * Renders the parameter list
     */
    const renderList = (): JSX.Element => {
        if (selectedTab === EnumParamTabs.body && contentType !== EnumRequestResponse.APP_JSON) {
            return (
                <VariablePicker
                    onBlur={handleBodyTextBlur}
                    KortexTextFieldProps={{
                        className: classes.bodyTexField,
                        TextFieldProps: {
                            rows: 20,
                            multiline: true,
                        },
                    }}
                    userAccessLevel={userAccessLevel}
                    value={params.body[0].value}
                />
            );
        } else {
            return (
                <ConnectorRestParamList
                    onChange={handleParamChange}
                    params={params[EnumParamTabs[selectedTab]]}
                    userAccessLevel={userAccessLevel}
                />
            );
        }
    };

    /**
     * Renders the Rest parameters tabs (AppBar)
     */
    const renderTabs = (): JSX.Element[] => {
        const labels: (keyof ILocale)[] = [
            "action.connector.rest.params.queries",
            "action.connector.rest.params.headers",
            "action.connector.rest.params.body",
        ];

        return Object.keys(EnumParamTabs)
            .reduce((arr: string[], key: string): string[] => {
                // Get keys of EnumParamTabs - must filter unwanted keys (such as 0, 1, 2) with reduce()
                if (!arr.includes(key)) {
                    arr.push(EnumParamTabs[key]);
                }
                return arr;
            }, [])
            .filter(
                (tab: string): boolean => !(bodyDisabled && tab === "body") // Remove "body" tab if necessary
            )
            .map((tab: string, index: number): JSX.Element => {
                return (
                    <Tab key={index} label={translate(labels[index]) + (params[tab].length > 0 ? " (" + params[tab].length + ")" : "")} />
                );
            });
    };

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs onChange={handleTabChange} value={selectedTab}>
                    {renderTabs()}
                </Tabs>
            </AppBar>
            {renderList()}
        </div>
    );
}
