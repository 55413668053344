var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { KortexTextField } from "../KortexTextField";
var useStyles = makeStyles({
    root: {
        width: "140px",
    },
});
var TimeField;
(function (TimeField) {
    TimeField[TimeField["HOURS"] = 0] = "HOURS";
    TimeField[TimeField["MINUTES"] = 1] = "MINUTES";
    TimeField[TimeField["SECONDS"] = 2] = "SECONDS";
    TimeField[TimeField["NA"] = 3] = "NA";
})(TimeField || (TimeField = {}));
function KortexTimeField(props) {
    var classes = useStyles(props);
    var format = props.format, onBlur = props.onBlur, onChange = props.onChange, _a = props.seconds, seconds = _a === void 0 ? 0 : _a, textLabels = props.textLabels, _b = props.variant, variant = _b === void 0 ? "standard" : _b, _c = props.disabled, disabled = _c === void 0 ? false : _c;
    var textFieldProps = {
        className: props.className,
        id: props.id,
        style: props.style,
        disabled: disabled,
    };
    var _d = useState("00"), hoursField = _d[0], setHoursField = _d[1];
    var _e = useState("00"), minutesField = _e[0], setMinutesField = _e[1];
    var _f = useState(TimeField.NA), previouslyModifiedTimeField = _f[0], setPreviouslyModifiedTimeField = _f[1];
    var _g = useState("00"), secondsField = _g[0], setSecondsField = _g[1];
    var _h = useState(TimeField.NA), selectedTimeField = _h[0], setSelectedTimeField = _h[1];
    var secondsPropChanged = false; // this var allows us to make sure we don't trigger onchange when we receive seconds prop
    /**
     * Converts time to hours/minutes/seconds whenever "seconds" props is changed
     */
    useEffect(function () {
        secondsPropChanged = true;
        convertSecondsToHMS(seconds > 0 ? seconds : 0);
    }, [seconds]);
    /**
     * Updates seconds whenever a field is change
     */
    useEffect(function () {
        if (onChange && !secondsPropChanged) {
            onChange(convertHMSToSeconds());
        }
    }, [hoursField, minutesField, secondsField]);
    /**
     * Add zero(s) to selected time field
     */
    var fillWithZeros = function (time) {
        var str = time;
        while (str.length < 2) {
            str = "0" + str;
        }
        return str;
    };
    /**
     * Converts seconds to hours/minutes/seconds
     */
    var convertSecondsToHMS = function (secs) {
        setHoursField(fillWithZeros(Math.floor(secs / 3600).toString()));
        setMinutesField(fillWithZeros(Math.floor((secs % 3600) / 60).toString()));
        setSecondsField(fillWithZeros(((secs % 3600) % 60).toString()));
    };
    /**
     * Converts hours/minutes/seconds to seconds
     */
    var convertHMSToSeconds = function () {
        return parseInt(hoursField, 10) * 3600 + parseInt(minutesField, 10) * 60 + parseInt(secondsField, 10);
    };
    /**
     * Return the appropriate format's field separators
     */
    var getSeparators = function () {
        switch (format) {
            case "hms":
                return ["h ", "m ", "s"];
            case ":":
            default:
                return [":", ":", ""];
        }
    };
    /**
     * Calls "select" function on field click
     */
    var handleOnClick = function (event) {
        select(event);
    };
    /**
     * Sets time to selected time field
     */
    var setTimeToSelectedField = function (newValue) {
        switch (selectedTimeField) {
            case TimeField.HOURS:
                setHoursField(newValue);
                break;
            case TimeField.MINUTES:
                setMinutesField(newValue);
                break;
            case TimeField.SECONDS:
                setSecondsField(newValue);
                break;
        }
    };
    /**
     * Gets time from selected field
     */
    var getTimeFromSelectedField = function () {
        switch (selectedTimeField) {
            case TimeField.HOURS:
                return hoursField;
            case TimeField.MINUTES:
                return minutesField;
            case TimeField.SECONDS:
                return secondsField;
            case TimeField.NA:
                return "";
        }
    };
    /**
     * Calls props's onBlur function on blur
     */
    var handleTimeBlur = function () {
        if (onBlur) {
            onBlur(convertHMSToSeconds());
        }
    };
    /**
     * Change time or selected field when keys.
     * Handles the following keys:
     * 1 to 9 and numpad 1 to 9;
     * Enter, Backspace, Delete, Tab;
     * Left and right arrows;
     */
    var handleKeyDown = function (event) {
        event.preventDefault();
        event.persist();
        secondsPropChanged = false;
        if (selectedTimeField !== TimeField.NA) {
            // 0 to 9 and Numpad0 to Numpad9
            if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
                switch (selectedTimeField) {
                    case TimeField.HOURS:
                        setTimeToSelectedField(previouslyModifiedTimeField === selectedTimeField ? validateTime(hoursField[1] + event.key) : "0" + event.key);
                        break;
                    case TimeField.MINUTES:
                        setTimeToSelectedField(previouslyModifiedTimeField === selectedTimeField ? validateTime(minutesField[1] + event.key) : "0" + event.key);
                        break;
                    case TimeField.SECONDS:
                        setTimeToSelectedField(previouslyModifiedTimeField === selectedTimeField ? validateTime(secondsField[1] + event.key) : "0" + event.key);
                        break;
                }
                setTimeout(function () {
                    // To prevent the cursor to be placed at the end of the input
                    if (previouslyModifiedTimeField === selectedTimeField) {
                        setPreviouslyModifiedTimeField(TimeField.NA);
                        select(event, selectedTimeField + 1);
                    }
                    else {
                        setPreviouslyModifiedTimeField(selectedTimeField);
                        select(event, selectedTimeField);
                    }
                }, 0);
            }
            // Tab or Enter
            else if (event.keyCode === 9 || event.keyCode === 13) {
                setPreviouslyModifiedTimeField(TimeField.NA);
                setTimeout(function () {
                    // To prevent the cursor to be placed at the end of the input
                    select(event, selectedTimeField + 1);
                }, 0);
            }
            // Backspace or Delete
            else if (event.keyCode === 8 || event.keyCode === 46) {
                if (getTimeFromSelectedField() === "00") {
                    setTimeout(function () {
                        // To prevent the cursor to be placed at the end of the input
                        if ((event.keyCode === 8 && selectedTimeField === TimeField.HOURS) ||
                            (event.keyCode === 46 && selectedTimeField === TimeField.SECONDS)) {
                            event.target.blur();
                        }
                        else {
                            select(event, selectedTimeField + (event.keyCode === 8 ? -1 : 1));
                        }
                    }, 0);
                }
                else if (getTimeFromSelectedField()[0] === "0") {
                    setTimeToSelectedField("00");
                    setTimeout(function () {
                        // To prevent the cursor to be placed at the end of the input
                        select(event, selectedTimeField);
                    }, 0);
                }
                else {
                    setTimeToSelectedField("0" + getTimeFromSelectedField()[0]);
                    setTimeout(function () {
                        // To prevent the cursor to be placed at the end of the input
                        select(event, selectedTimeField);
                    }, 0);
                }
                setPreviouslyModifiedTimeField(TimeField.NA);
            }
            // Left Arrow
            else if (event.keyCode === 37) {
                setPreviouslyModifiedTimeField(TimeField.NA);
                setTimeout(function () {
                    // To prevent the cursor to be placed at the end of the input
                    if (selectedTimeField === TimeField.HOURS) {
                        event.target.blur();
                    }
                    else {
                        select(event, selectedTimeField - 1);
                    }
                }, 0);
            }
            // Right Arrow
            else if (event.keyCode === 39) {
                setPreviouslyModifiedTimeField(TimeField.NA);
                setTimeout(function () {
                    // To prevent the cursor to be placed at the end of the input
                    select(event, selectedTimeField + 1);
                }, 0);
            }
        }
    };
    /**
     * Selects a time field depending on the click's cursor position.
     * Can also select the desired time field with "selectedField" prop if no mouse click is involved (ex: Enter, Tab, etc.).
     */
    var select = function (event, selectedField) {
        var target = event.target;
        if (selectedField && selectedField === TimeField.NA) {
            setSelectedTimeField(TimeField.NA);
            target.blur();
            return;
        }
        var timeString = target.value;
        if (timeString) {
            var hoursSeparator = timeString.indexOf(separators[0]);
            var minutesSeparator = timeString.lastIndexOf(separators[1]);
            var secondsSeparator = timeString.lastIndexOf(separators[2]);
            var cursorPosition = 0;
            switch (selectedField) {
                case TimeField.HOURS:
                    cursorPosition = hoursSeparator;
                    break;
                case TimeField.MINUTES:
                    cursorPosition = minutesSeparator;
                    break;
                case TimeField.SECONDS:
                    cursorPosition = secondsSeparator;
                    break;
                default:
                    cursorPosition = target.selectionStart || 0;
            }
            if (cursorPosition <= hoursSeparator) {
                target.setSelectionRange(0, hoursSeparator);
                setSelectedTimeField(TimeField.HOURS);
            }
            else if (cursorPosition > hoursSeparator && cursorPosition <= minutesSeparator) {
                target.setSelectionRange(hoursSeparator + separators[0].length, minutesSeparator);
                setSelectedTimeField(TimeField.MINUTES);
            }
            else if (cursorPosition > minutesSeparator) {
                target.setSelectionRange(minutesSeparator + separators[1].length, secondsSeparator);
                setSelectedTimeField(TimeField.SECONDS);
            }
        }
    };
    /**
     * Verifies that minutes/seconds value is not higher than 59
     */
    var validateTime = function (time) {
        if (selectedTimeField === TimeField.MINUTES || selectedTimeField === TimeField.SECONDS) {
            return parseInt(time, 10) > 59 ? "59" : time;
        }
        else {
            return time;
        }
    };
    var separators = getSeparators();
    return (React.createElement("div", { className: classes.root },
        React.createElement(KortexTextField, { label: textLabels ? textLabels.inputLabel : undefined, TextFieldProps: __assign({ onClick: handleOnClick }, textFieldProps), onBlur: handleTimeBlur, onKeyDown: handleKeyDown, value: hoursField + separators[0] + minutesField + separators[1] + secondsField + separators[2], variant: variant })));
}
export default KortexTimeField;
