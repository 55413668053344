export const uncategorized = {
    rpc: {
        getResultSettingItem: {
            funcId: 9000,
            funcName: "getResultSettingItem",
        },
        patch: {
            funcId: 9001,
            funcName: "patch",
        },
        upsertResultSettingItem: {
            funcId: 9002,
            funcName: "upsertResultSettingItem",
        },
        getResultSettingMetadata: {
            funcId: 9003,
            funcName: "getResultSettingMetadata",
        },
        upsertResultSettingMetadata: {
            funcId: 9004,
            funcName: "upsertResultSettingMetadata",
        },
        resultSettingItemUpserted: {
            funcId: 9005,
            funcName: "resultSettingItemUpserted",
        },
        resultSettingMetadataUpserted: {
            funcId: 9006,
            funcName: "resultSettingMetadataUpserted",
        },
    },
    serviceId: 90,
    serviceVersion: 1,
};
