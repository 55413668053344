import { KortexDialogConfirmation, greyPalette, theme } from "@aos/react-components";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Checkbox, List, ListSubheader, Typography, makeStyles } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React, { FC } from "react";

const useStyles = makeStyles({
    cardContent: {
        display: "grid",
        gridTemplateColumns: "20% 50% 10% 10% 10%;",
        alignItems: "center",
    },
    column: {
        alignItems: "center",
        display: "flex",
        height: "58px",
        justifyContent: "center",
    },
    checkboxRoot: {
        pointerEvents: "none",
    },
    text: {
        color: greyPalette[700],
    },
    typographyQuestion: {
        marginBottom: "20px",
    },
});

interface IOwnProps {
    certified: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    open: boolean;
    processLabel: string;
    processVersion: string;
    trained: boolean;
    userName: string;
}

const TrainingArchiveConfirmationDialog: FC<IOwnProps> = (props) => {
    const { certified, onCancel, onConfirm, open, processLabel, processVersion, trained, userName } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            dialogProps={{
                maxWidth: "lg",
            }}
            onCancel={onCancel}
            onConfirm={onConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("training.archiveConfirmationDialog.archiveTraining"),
            }}
        >
            <Typography className={classes.typographyQuestion} variant="h6">
                {translate("training.archiveConfirmationDialog.areYouSure")}
            </Typography>
            <List
                disablePadding={true}
                subheader={
                    <ListSubheader disableGutters={true}>
                        <KortexPanelCard
                            hover={false}
                            isSelected={false}
                            padding={0}
                            spaceBetweenCard={1}
                            statusColor={theme.palette.common.white}
                        >
                            <div className={classes.cardContent}>
                                {/* USER NAME */}
                                <div className={`${classes.column}`}>
                                    <Typography className={classes.text}>{translate("training.userName").toUpperCase()}</Typography>
                                </div>
                                {/* PROCESS TRAINING NAME */}
                                <div className={classes.column}>
                                    <Typography className={classes.text}>{translate("training.process").toUpperCase()}</Typography>
                                </div>
                                {/* TRAINED */}
                                <div className={classes.column}>
                                    <Typography className={classes.text}>{translate("training.trained").toUpperCase()}</Typography>
                                </div>
                                {/* CERTIFIED */}
                                <div className={classes.column}>
                                    <Typography className={classes.text}>{translate("training.certified").toUpperCase()}</Typography>
                                </div>
                                {/* VERSION */}
                                <div className={classes.column}>
                                    <Typography className={classes.text}>
                                        {translate("training.currentlyReleasedProcessVersion").toUpperCase()}
                                    </Typography>
                                </div>
                            </div>
                        </KortexPanelCard>
                    </ListSubheader>
                }
            >
                <KortexPanelCard hover={false} isSelected={false} padding={0} spaceBetweenCard={1} statusColor={theme.palette.common.white}>
                    <div className={classes.cardContent} id="userTrainingArchiveConfirmationDialogCardId">
                        {/* USER NAME */}
                        <div className={`${classes.column}`}>
                            <Typography id="userTrainingArchiveConfirmationDialogUserNameId" variant="body1">
                                {userName}
                            </Typography>
                        </div>
                        {/* PROCESS LABEL */}
                        <div className={classes.column}>
                            <Typography id="userTrainingArchiveConfirmationDialogProcessLabelId" variant="body1">
                                {processLabel}
                            </Typography>
                        </div>
                        {/* STATUS */}
                        <div className={classes.column}>
                            <Checkbox
                                checked={trained}
                                checkedIcon={<CheckBoxIcon />}
                                classes={{
                                    root: classes.checkboxRoot,
                                }}
                                disableRipple={true}
                                icon={<CheckBoxOutlineBlankIcon />}
                                id="userTrainingArchiveConfirmationDialogTrainedId"
                            />
                        </div>
                        <div className={classes.column}>
                            <Checkbox
                                checked={certified}
                                checkedIcon={<CheckBoxIcon />}
                                classes={{
                                    root: classes.checkboxRoot,
                                }}
                                disableRipple={true}
                                icon={<CheckBoxOutlineBlankIcon />}
                                inputProps={{
                                    readOnly: true,
                                }}
                                id="userTrainingArchiveConfirmationDialogCertifiedId"
                            />
                        </div>
                        {/* VERSION */}
                        <div className={classes.column}>
                            <Typography className={classes.text} id="userTrainingArchiveConfirmationDialogProcessVersionId" variant="body1">
                                {processVersion}
                            </Typography>
                        </div>
                    </div>
                </KortexPanelCard>
            </List>
        </KortexDialogConfirmation>
    );
};

export default TrainingArchiveConfirmationDialog;
