import { IRestKeyValue, ProcessEditorRightsEnum } from "@kortex/aos-common";
import { List } from "@material-ui/core";
import * as React from "react";

import ConnectorRestParam from "../RestParam/ConnectorRestParam";
import { IUserRightsProps } from "../../../../../../../../utilitites/IUserRights";

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    onChange: (params: IRestKeyValue[]) => void;
    params: IRestKeyValue[];
}

type IAllProps = IOwnProps;

export default function ConnectorRestParamList(props: IAllProps): JSX.Element {
    const { onChange, params, userAccessLevel } = props;

    /**
     * Called when a param is blurred and the value has changed
     *
     * @param {number} index - param index
     */
    const handleBlur =
        (index: number): ((param: IRestKeyValue) => void) =>
        (param: IRestKeyValue): void => {
            const clone = [...params];
            clone[index] = param;
            onChange(clone);
        };

    /**
     * Called when deleting a param
     *
     * @param {number} index - param index
     */
    const handleDelete =
        (index: number): (() => void) =>
        (): void => {
            const clone = [...params];
            clone.splice(index, 1);
            onChange(clone);
        };

    return (
        <React.Fragment>
            <List>
                {params.map(
                    (param: IRestKeyValue, index: number): JSX.Element => (
                        <ConnectorRestParam
                            key={index.toString()}
                            onBlur={handleBlur(index)}
                            onDelete={handleDelete(index)}
                            param={{
                                key: param.key,
                                value: param.value,
                            }}
                            userAccessLevel={userAccessLevel}
                        />
                    )
                )}
            </List>
        </React.Fragment>
    );
}
