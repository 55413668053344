import { greyPalette } from "@aos/react-components";
import { IUserTrainingTrainingsHistory, ProcessApprovalStatusFilter, ProcessTrainingRequirementEnum, getDateStr } from "@kortex/aos-common";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import ProcessVersionPicker from "@kortex/aos-ui/components/core/ProcessVersionPicker/ProcessVersionPicker";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { noop } from "@kortex/utilities";
import { Checkbox, Typography, makeStyles } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React, { createRef } from "react";

import { getTrainingRequirementLocale } from "../../utilities";

const COLUMN_COUNT = 7;
const GRID_TEMPLATE_COLUMNS = ((): string => {
    let gridTemplateColumns = "";

    for (let i = 0; i < COLUMN_COUNT; i++) {
        gridTemplateColumns += `${(100 / COLUMN_COUNT).toFixed(2)}% `;
    }

    return gridTemplateColumns;
})();

const useStyles = makeStyles({
    cardContent: {
        display: "grid",
        gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
        alignItems: "center",
    },
    text: {
        color: greyPalette[700],
    },
    statusTrained: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "58px",
    },
    checkBoxTrainingRoot: {
        pointerEvents: "none",
    },
    statusCertified: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "58px",
    },
    processTrainingVersionContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "58px",
    },
});

interface IOwnProps {
    trainingHistory: IUserTrainingTrainingsHistory;
    index: number;
}

export default function UserTrainingHistoryCard(props: IOwnProps): JSX.Element {
    const { trainingHistory, index } = props;

    const ref = createRef<HTMLDivElement>();
    const classes = useStyles();
    const translate = useTranslate();

    const getTrainingDate = (): string => {
        if (trainingHistory.electronicSignatures.certificate) return getDateStr(trainingHistory.electronicSignatures.certificate.createdOn);

        if (
            trainingHistory.process.trainingRequirement === ProcessTrainingRequirementEnum.READING &&
            trainingHistory.electronicSignatures.results[0]
        )
            return getDateStr(trainingHistory.electronicSignatures.results[0].createdOn);

        return "";
    };

    return (
        <KortexPanelCard isSelected={false} padding={0} spaceBetweenCard={1} hover={false}>
            <div className={classes.cardContent} id={`userTrainingHistoryCard${index}Id`} ref={ref}>
                {/* ORIGINAL VERSION */}
                <div className={classes.processTrainingVersionContent}>
                    <ProcessVersionPicker
                        latest={false}
                        onVersionChange={noop}
                        processId={trainingHistory.originalProcess.processId}
                        readOnly={true}
                        treeNodeId={trainingHistory.process.treeNodeId}
                        showPurgeIcon={false}
                        variant={ProcessApprovalStatusFilter.WITHOUT_DRAFT}
                    />
                </div>
                {/* STATUS */}
                <div className={classes.statusTrained}>
                    <Checkbox
                        checked={trainingHistory.training.trained}
                        checkedIcon={<CheckBoxIcon />}
                        classes={{ root: classes.checkBoxTrainingRoot }}
                        icon={<CheckBoxOutlineBlankIcon />}
                        id={`userTrainingHistoryCard${index}TrainedCheckboxId`}
                        inputProps={{ readOnly: true }}
                    />
                </div>
                <div className={classes.statusCertified}>
                    <Checkbox
                        checked={trainingHistory.training.certified}
                        checkedIcon={<CheckBoxIcon />}
                        classes={{ root: classes.checkBoxTrainingRoot }}
                        icon={<CheckBoxOutlineBlankIcon />}
                        id={`checkboxCertifiedHistory${index}Id`}
                        inputProps={{ readOnly: true }}
                    />
                </div>
                {/* TRAINING REQUIREMENT */}
                <div className={classes.processTrainingVersionContent}>
                    <Typography className={classes.text} id={`userTrainingHistoryCard${index}LevelOfChangeId`}>
                        {translate(getTrainingRequirementLocale(trainingHistory.process.trainingRequirement)).toUpperCase()}
                    </Typography>
                </div>
                {/* VERSION */}
                <div className={classes.processTrainingVersionContent}>
                    <Typography className={classes.text} id={`userTrainingHistoryCard${index}VersionId`}>
                        {trainingHistory.process.version}
                    </Typography>
                </div>
                {/* TRAINING DATE */}
                <div className={classes.processTrainingVersionContent}>
                    <Typography className={classes.text} id={`userTrainingHistoryCard${index}UpdatedOnId`}>
                        {getTrainingDate()}
                    </Typography>
                </div>
                {/* ARCHIVE DATE */}
                <div className={classes.processTrainingVersionContent}>
                    {trainingHistory.training.archivedOn ? (
                        <Typography className={classes.text} id={`userTrainingHistoryCard${index}ArchivedOnId`}>
                            {trainingHistory.training.archivedOn && getDateStr(trainingHistory.training.archivedOn)}
                        </Typography>
                    ) : null}
                </div>
            </div>
        </KortexPanelCard>
    );
}
