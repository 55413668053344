export const symptomLog = {
    rpc: {
        getAll: {
            funcId: 3401,
            funcName: "symptomLogAll",
        },
        insert: {
            funcId: 3402,
            funcName: "symptomLogInsert",
        },
        inserted: {
            funcId: 3404,
            funcName: "symptomLogInserted",
        },
    },
    serviceId: 34,
    serviceVersion: 1,
};
