import React, { useEffect, useState } from "react";
import { Typography, Checkbox, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { secondaryPalette } from "@aos/react-components";

import KortexSearchField from "../../../core/KortexSearchField";
import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    searchBox: {
        padding: "5px",
    },
    showArchivedFilter: {
        display: "flex",
        alignItems: "center",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
});

export interface IGroupFilters {
    plainText: string;
    status: string[];
    showArchived: boolean;
}

export const defaultGroupFilters: IGroupFilters = {
    plainText: "",
    status: [],
    showArchived: true,
};

interface IOwnProps {
    onFiltersChange: (filters: IGroupFilters) => void;
}

export default function GroupSearchBar(props: IOwnProps): JSX.Element {
    const { onFiltersChange } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [filters, setFilters] = useState<IGroupFilters>(defaultGroupFilters);

    /**
     * When filters change, execute onFiltersChange cb
     */
    useEffect((): void => {
        onFiltersChange(filters);
    }, [filters]);

    /**
     * Handle Show Archived button toggle
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - Change event
     */
    const handleToggleArchived = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, showArchived: event.target.checked });
    };

    /**
     * Handle filters change. Checks if filters equal their default value (plainText is excluded from check)
     */
    const isFilterActive = (): boolean => {
        return Object.keys(filters).some((key) => key !== "plainText" && filters[key] !== defaultGroupFilters[key]);
    };

    /**
     * Set plain text filter
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        setFilters({ ...filters, plainText: val });
    };

    return (
        <Paper>
            <KortexSearchField className={classes.searchBox} onChange={handleSearchTextChange} isFilterActive={isFilterActive()}>
                <React.Fragment>
                    {/* SHOW ARCHIVED USERS FILTER */}
                    <div className={classes.showArchivedFilter}>
                        <Typography className={classes.filterLabel}>{translate("settings.groups.showArchived")}</Typography>
                        <Checkbox checked={filters.showArchived} onChange={handleToggleArchived} />
                    </div>
                </React.Fragment>
            </KortexSearchField>
        </Paper>
    );
}
