export class EmailState {
    constructor() {
        this.to = "";
        this.from = "";
        this.subject = "";
        this.text = "";
        this.html = "";
    }
    toJSON() {
        return {
            to: this.to,
            from: this.from,
            subject: this.subject,
            text: this.text,
            html: this.html,
        };
    }
}
