import { mangleURL } from "@kortex/aos-common";

import { client } from "./kortex-client/client";

/**
 * Gets a url parameter or all of them
 */
export const getExportURL = (): string => {
    return mangleURL((client.router.transport.protocol as WebSocket).url)
        .toHTTP()
        .replace(/\/$/, "");
};
