export var TreeNodeModuleTypeEnum;
(function (TreeNodeModuleTypeEnum) {
    TreeNodeModuleTypeEnum["PROCESS"] = "PR";
    TreeNodeModuleTypeEnum["FILE_STORAGE"] = "FS";
    TreeNodeModuleTypeEnum["WORK_ZONE"] = "WZ";
})(TreeNodeModuleTypeEnum || (TreeNodeModuleTypeEnum = {}));
export var TreeNodeNodeTypeEnum;
(function (TreeNodeNodeTypeEnum) {
    // TreeNodeModuleTypeEnum.FILE_STORAGE
    TreeNodeNodeTypeEnum["CAD"] = "CAD";
    TreeNodeNodeTypeEnum["FOLDER"] = "F";
    TreeNodeNodeTypeEnum["IMAGE"] = "IMG";
    TreeNodeNodeTypeEnum["PDF"] = "PDF";
    TreeNodeNodeTypeEnum["SOURCE_CODE"] = "SC";
    TreeNodeNodeTypeEnum["SVG"] = "SVG";
    TreeNodeNodeTypeEnum["TEXT"] = "TXT";
    TreeNodeNodeTypeEnum["VIDEO"] = "VID";
    // TreeNodeModuleTypeEnum.PROCESS
    TreeNodeNodeTypeEnum["PROCESS"] = "T";
    TreeNodeNodeTypeEnum["ROUTING"] = "R";
    //
    TreeNodeNodeTypeEnum["UNKNOWN"] = "UNK";
})(TreeNodeNodeTypeEnum || (TreeNodeNodeTypeEnum = {}));
/**
 * Create a new instance of an ITreeNodeDbModel
 *
 * @param {TreeNodeNodeTypeEnum} nodeType - Node Type
 * @param {TreeNodeModuleTypeEnum} moduleType - Node module type
 * @param {string} label - Label
 * @param {number} [index] - Index for ordering
 * @param {TreeNodeId} [parentId] - Parent Id of the generated TreeNode 0 = no parent
 * @param {boolean} [archived] - Whether element is archived or not
 * @param {string} [bomId] - BOM ID
 * @param {string} [bomRev] - BOM revision
 * @returns {TreeNodeDbModel} Generated instance of ITreeNodeDbModel
 */
export function newTreeNode(nodeType, moduleType, label, index = 0, parentId = 0, archived = false, bomId = "", bomRev = "") {
    return {
        archived,
        bomId,
        bomRev,
        index,
        label,
        moduleType,
        nodeType,
        parentId,
        treeNodeId: 0,
    };
}
