export const trainingCertificationPending = {
    rpc: {
        getAllByUserId: {
            funcId: 13601,
            funcName: "trainingCertificationPendingGetAllByUserId",
        },
        trainingCertificationSign: {
            funcId: 13602,
            funcName: "trainingCertificationPendingTrainingCertificationSign",
        },
        inserted: {
            funcId: 13603,
            funcName: "trainingCertificationPendingInserted",
        },
        getAll: {
            funcId: 13604,
            funcName: "trainingCertificationPendingGetAll",
        },
        delete: {
            funcId: 13605,
            funcName: "trainingCertificationPendingDelete",
        },
        deleted: {
            funcId: 13606,
            funcName: "trainingCertificationPendingDeleted",
        },
    },
    serviceId: 136,
    serviceVersion: 1,
};
