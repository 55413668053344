import {
    ProcessTrainingActionType,
    ProcessTrainingClearAction,
    ProcessTrainingSetProcessAction,
    ProcessTrainingUpdateProcessAction,
    ProcessTrainingUpsertedTrainingAction,
} from "./process-training-types";

export function processTrainingClear(): ProcessTrainingClearAction {
    return { type: ProcessTrainingActionType.CLEAR_PROCESS_TRAINING };
}

export function processTrainingSetProcessAction(
    processTrainings: ProcessTrainingSetProcessAction["value"]
): ProcessTrainingSetProcessAction {
    return { type: ProcessTrainingActionType.SET_PROCESS, value: processTrainings };
}

export function processTrainingUpdateProcessAction(
    processTrainings: ProcessTrainingUpdateProcessAction["value"]
): ProcessTrainingUpdateProcessAction {
    return { type: ProcessTrainingActionType.UPDATE_PROCESS, value: processTrainings };
}

export function processTrainingUpsertedTrainingAction(
    trainings: ProcessTrainingUpsertedTrainingAction["value"]
): ProcessTrainingUpsertedTrainingAction {
    return { type: ProcessTrainingActionType.UPSERT_TRAININGS, value: trainings };
}
