import {
    UnwrapAOSPayload,
    FailureTypesInsertReq,
    FailureTypesInsertRes,
    FailureTypesUpdateReq,
    FailureTypesAllReq,
} from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { StandardThunk, AppState, StandardDispatch } from "../store";
import { normalizeStandardThunkeReduxOptions, fetchedOnce } from "../utils";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";

import { setFailureTypesListAction, failureTypesUpdatedAction, failureTypesInsertedAction } from "./failures-actions";

// thunks

/**
 * Retrieves failure types
 *
 * @param {IStandardThunkOptions} [options] - options
 */
export function getFailureTypes(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<FailureTypesAllReq>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<FailureTypesAllReq>> {
        if (!skipLookup) {
            if (fetchedOnce.was(getFailureTypes)) {
                return getState().failure.types;
            }
        }

        return api.services.failureTypes
            .getAll(emptyObject)()
            .then((list) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(getFailureTypes);
                    dispatch(setFailureTypesListAction([...list]));
                }

                return list;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

/**
 * Update failure types
 *
 * @param {APIPayload<"failureTypes", "update">} [toUpdateFailureTypes] - To Update Failure Type
 */
export function failureTypesUpdate(toUpdateFailureTypes: UnwrapAOSPayload<FailureTypesUpdateReq>): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.failureTypes
                .update(toUpdateFailureTypes)()
                .then((updatedFailureTypes) => {
                    dispatch(failureTypesUpdatedAction([...updatedFailureTypes]));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}

/**
 * Insert failure type
 *
 * @param {APIPayload<"failureTypes", "insert">} toInsertFailureTypes - To Update Failure Type
 */
export function failureTypesInsert(
    toInsertFailureTypes: UnwrapAOSPayload<FailureTypesInsertReq>
): StandardThunk<UnwrapAOSPayload<FailureTypesInsertRes>> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<FailureTypesInsertRes>> => {
        return api.services.failureTypes
            .insert(toInsertFailureTypes)({ timeout: 10_000 })
            .then((insertedFailureTypes) => {
                dispatch(failureTypesInsertedAction([...insertedFailureTypes]));
                return insertedFailureTypes;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}
