import { BomId, BomRevision, IBom } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { OrUndefined } from "@kortex/aos-ui/redux/app.types";
import { handleAPIError } from "@kortex/aos-ui/redux/handleAPIError";
import { client } from "@kortex/aos-ui/utilitites/kortex-client/client";
import React, { FC, PropsWithChildren, createContext, useContext, useState } from "react";

type ProcessEditorBom = OrUndefined<Omit<IBom, "items"> & Partial<Pick<IBom, "items">>>;

// Context for process editor
interface IProcessEditorContext {
    bom: ProcessEditorBom;
    bomLoading: boolean;
    loadBom: () => Promise<void>;
    updateBom: (bomId?: BomId, revision?: BomRevision, load?: boolean) => Promise<void>;
}

export const ProcessEditorContext = createContext<IProcessEditorContext>({
    bom: undefined,
    bomLoading: false,
    loadBom: async () => void 0,
    updateBom: async () => void 0,
});

type ProcessEditorProviderProps = PropsWithChildren<{}>;

export const ProcessEditorProvider: FC<ProcessEditorProviderProps> = (props) => {
    const dispatch = useThunkDispatch();

    const { children } = props;
    const [bom, setBom] = useState<ProcessEditorBom>(undefined);
    const [bomLoading, setBomLoading] = useState(false);

    const loadBom = async (bomId = bom?.bomId, revision = bom?.revision): Promise<void> => {
        if (!bomId || !revision) return void 0;

        setBomLoading(true);

        // Process has a BOM ID and revision, but the BOM (from context) has not been loaded yet
        return client.services.erp
            .getBom({ bomId, revision })({ timeout: 15000 })
            .then(
                (res) => setBom(res),
                (error) => {
                    handleAPIError(error, dispatch);
                }
            )
            .finally(() => {
                setBomLoading(false);
            });
    };

    const updateBom = async (bomId = "", revision = "", load = false): Promise<void> => {
        setBom({
            bomId,
            items: undefined,
            revision,
        });

        if (!bomId || !revision || !load) return void 0;

        return loadBom(bomId, revision);
    };

    return (
        <ProcessEditorContext.Provider
            value={{
                bom,
                bomLoading,
                loadBom,
                updateBom,
            }}
        >
            {children}
        </ProcessEditorContext.Provider>
    );
};

export const useProcessEditorContext = (): IProcessEditorContext => useContext<IProcessEditorContext>(ProcessEditorContext);
