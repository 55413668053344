import { fromItemCode } from "@kortex/aos-common";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

const useStyles = makeStyles({
    deleteIcon: {
        maxWidth: "24px",
        minWidth: "24px",
    },
    tableRow: {
        height: "64px",
    },
    typography: {
        overflowX: "hidden",
    },
    typographyItem: {
        maxWidth: "85px",
        minWidth: "85px",
    },
    typographyLotNumber: {
        maxWidth: "85px",
        minWidth: "85px",
    },
    typographyQuantity: {
        maxWidth: "28px",
        minWidth: "28px",
    },
});

interface IItemCodeProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    code: string;
    index: number;
    onDelete: () => void;
}

function ItemCode(props: IItemCodeProps): JSX.Element {
    const { code, index, onDelete } = props;
    const classes = useStyles(props);

    const [item, lotNumber, quantity] = fromItemCode(code);

    return (
        <TableRow className={classes.tableRow}>
            <TableCell>
                <Typography
                    className={`${classes.typography} ${classes.typographyItem}`}
                    color="inherit"
                    id={`itemCodeTableItemId${index}`}
                >
                    {item}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    className={`${classes.typography} ${classes.typographyLotNumber}`}
                    color="inherit"
                    id={`itemCodeTableLotNumberId${index}`}
                >
                    {lotNumber}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography
                    className={`${classes.typography} ${classes.typographyQuantity}`}
                    color="inherit"
                    id={`itemCodeTableQuantityId${index}`}
                >
                    {quantity}
                </Typography>
            </TableCell>
            <TableCell onClick={onDelete}>
                <DeleteIcon className={classes.deleteIcon} id={`itemCodeTableDeleteItemId${index}`} />
            </TableCell>
        </TableRow>
    );
}

export default ItemCode;
