export const failureSymptoms = {
    rpc: {
        getOne: {
            funcId: 7100,
            funcName: "failureSymptomsGetOne",
        },
        getAll: {
            funcId: 7101,
            funcName: "failureSymptomsGetAll",
        },
        insert: {
            funcId: 7102,
            funcName: "failureSymptomsInsert",
        },
        update: {
            funcId: 7103,
            funcName: "failureSymptomsUpdate",
        },
        inserted: {
            funcId: 7105,
            funcName: "failureSymptomsInserted",
        },
        updated: {
            funcId: 7106,
            funcName: "failureSymptomsUpdated",
        },
    },
    serviceId: 71,
    serviceVersion: 1,
};
