import { greyPalette } from "@aos/react-components";
import { getWorkInstructionsStepCount, IProcessDbModel, isWorkInstructions, ProcessAction } from "@kortex/aos-common";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";

import PrintApproval from "./PrintApproval";
import PrintFrontPage from "./PrintFrontPage";
import PrintInstruction from "./PrintInstruction";
import PrintProcessFlow from "./PrintProcessFlow";
import PrintRevisionHistory from "./PrintRevisionHistory";
import PrintTableOfContent from "./PrintTableOfContent";
import {
    extractStepNameList,
    IPrintProcessDocumentDynamicSections,
    IPrintProcessDocumentProps,
    IRevisionHistoryTableProps,
    pageHeight,
    pageMargin,
    pageWidth,
} from "./printUtils";

const DEFAULT_PAGE_COUNT = 4;
const REVISION_HISTORY_MAX_ROW = 13;
const TABLE_OF_CONTENT_MAX_ROW = 18;

interface IOwnProps {
    selectedVersionId?: number;
    onDynamicSectionChange: (dynamicSection: IPrintProcessDocumentDynamicSections) => void;
}

type IAllProps = IOwnProps & IPrintProcessDocumentProps;

export default function PrintProcessDocument(props: IAllProps): JSX.Element {
    const { processInfo, processVersions, selectedVersionId, onDynamicSectionChange, treeNode, userList, userGroups, tasks } = props;

    if (!processInfo) {
        return <div />;
    }

    const translate = useTranslate();

    const [revisionHistoryPages, setRevisionHistoryPages] = useState<IRevisionHistoryTableProps[]>([]);
    const [pageCount, setPageCount] = useState<number>(
        DEFAULT_PAGE_COUNT + getWorkInstructionsStepCount(processInfo.actions as ProcessAction[])
    );
    const [tableOfContent, setTableOfContent] = useState<string[]>([]);
    let pageIndex = 0;

    useEffect((): void => {
        const revisionHistoryPageCount = generateRevisionHistory();
        const newTableOfContent = [];
        newTableOfContent.push(translate("process.printing.processFlow"));
        extractStepNameList(processInfo.actions as ProcessAction[]).map((stepName) => newTableOfContent.push(stepName));
        newTableOfContent.push(translate("process.printing.revisionHistory"));
        newTableOfContent.push(translate("process.printing.approvals"));

        setPageCount(
            1 + Math.ceil(newTableOfContent.length / TABLE_OF_CONTENT_MAX_ROW) + newTableOfContent.length + revisionHistoryPageCount
        );
        setTableOfContent(newTableOfContent);
    }, [processVersions, selectedVersionId]);

    useEffect((): void => {
        onDynamicSectionChange({ tableOfContent, revisionHistoryPages });
    }, [revisionHistoryPages, tableOfContent]);

    const generateTableOfContent = (): JSX.Element[] => {
        const tableOfContentPageCount = Math.ceil(tableOfContent.length / TABLE_OF_CONTENT_MAX_ROW);
        const pages: JSX.Element[] = [];
        let startIndex = 0;

        for (let i = 0; i < tableOfContentPageCount; i++) {
            pages.push(
                <PrintTableOfContent
                    key={i}
                    pageIndex={pageIndex++}
                    totalPage={pageCount}
                    pagesTitle={tableOfContent}
                    version={processInfo}
                    startIndex={startIndex}
                    howMany={TABLE_OF_CONTENT_MAX_ROW}
                />
            );
            startIndex = startIndex + TABLE_OF_CONTENT_MAX_ROW;
        }

        return pages;
    };

    const generateRevisionHistory = (): number => {
        const newRevisionHistory: IRevisionHistoryTableProps[] = [];
        let pages = 0;

        if (processVersions) {
            for (let i = 0; i < processVersions.length; i += REVISION_HISTORY_MAX_ROW, pages++) {
                newRevisionHistory.push({
                    startIndex: i,
                    howMany: REVISION_HISTORY_MAX_ROW,
                });
            }
        }

        setRevisionHistoryPages(newRevisionHistory);
        return pages;
    };

    const handleRevisionPagesOverflow = (index: number): void => {
        revisionHistoryPages[index].howMany -= 1;
        if (revisionHistoryPages[index + 1]) {
            revisionHistoryPages[index + 1].startIndex -= 1;
        }
        setRevisionHistoryPages(revisionHistoryPages);
    };

    /**
     * Find all Work Instruction actions from process and return pages for each step
     */
    const renderWorkInstructionPages = (): JSX.Element[] => {
        const workInstructionPages: JSX.Element[] = [];

        if (!processInfo) {
            return workInstructionPages;
        }

        for (const action of processInfo.actions) {
            if (isWorkInstructions(action)) {
                workInstructionPages.push(
                    ...action.steps.map((step, index) => (
                        <PrintInstruction
                            formStepProps={step}
                            isDraft={processInfo.isDraft}
                            key={`printPdfWorkInstructionPage${index}`}
                            pageIndex={pageIndex++}
                            totalPage={pageCount}
                        />
                    ))
                );
            }
        }

        return workInstructionPages;
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={greyPalette[500]}
            width="100%"
            height="100%"
            viewBox={`0 0 ${pageWidth + pageMargin * 2} ${(pageHeight + pageMargin) * pageCount + pageMargin}`}
        >
            <rect x="0" y="0" width="100%" height="100%" fill={greyPalette[500]} />

            {/* FRONT PAGE */}
            <PrintFrontPage
                title={(treeNode && treeNode.label) || ""}
                version={processInfo}
                pageIndex={pageIndex++}
                totalPage={pageCount}
            />

            {/* TABLE OF CONTENT */}
            {generateTableOfContent()}

            {/* PROCESS FLOW */}
            <PrintProcessFlow pageIndex={pageIndex++} totalPage={pageCount} processInfo={processInfo} version={processInfo} />

            {/* PROCESS ACTIONS */}
            {renderWorkInstructionPages()}

            {/* CHANGE LOG */}
            {revisionHistoryPages.map((revisionHistory, index): JSX.Element => {
                return (
                    <PrintRevisionHistory
                        key={index}
                        index={index}
                        pageIndex={pageIndex++}
                        totalPage={pageCount}
                        processVersions={processVersions}
                        selectedVersionId={selectedVersionId}
                        startIndex={revisionHistory.startIndex}
                        howMany={revisionHistory.howMany}
                        onOverflow={handleRevisionPagesOverflow}
                        userList={userList}
                    />
                );
            })}

            {/* APPROVAL */}
            {processVersions && selectedVersionId !== undefined && (
                <PrintApproval
                    userList={userList}
                    userGroups={userGroups}
                    tasks={tasks}
                    pageIndex={pageIndex++}
                    totalPage={pageCount}
                    version={processVersions.find((version: IProcessDbModel): boolean => version.processId === selectedVersionId)}
                />
            )}
        </svg>
    );
}
