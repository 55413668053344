import { ServiceManagerClient, WSClient, Kinova } from "@kortex/aos-api-client";
import { getRPCHubInfo, UserGroupInsertReq, UserGroupInsertRes } from "@kortex/aos-common";

import { objectFromUTF8Array, utf8ArrayFromObject, utf8ArrayToKortexApiErrorObject } from "../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const userGroupInsert = (router: WSClient["router"]) =>
    ServiceManagerClient.getRR<UserGroupInsertReq, UserGroupInsertRes, Kinova.Api.IError>(getRPCHubInfo("userGroup")("insert"), {
        router,
        serializer: {
            decode: objectFromUTF8Array,
            encode: utf8ArrayFromObject,
            err: {
                decode: utf8ArrayToKortexApiErrorObject,
            },
        },
    });
