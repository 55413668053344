import { LogTypeEnum, removeObjectFromArray, upsertObjectFromArray } from "@kortex/aos-common";

import {
    ReworkActionType,
    ReworkActions,
    ReworkItemStatusActionType,
    ReworkJobProcessActionType,
    ReworkLogActionType,
    ReworkState,
    RootCauseActionType,
} from "./rework-types";

const initialState: ReworkState = {
    reworks: [],
    reworkItemStatus: [],
    reworkLogs: [],
    reworkJobsProcess: [],
    rootCause: [],
};

export function reworkReducer(state: ReworkState = initialState, action: ReworkActions): ReworkState {
    switch (action.type) {
        // ------------------ REWORK ---------------------------------------
        case ReworkActionType.UPDATE_REWORKS:
            return {
                ...state,
                reworks: [...action.value],
            };
        case ReworkActionType.INSERT_REWORK:
            return {
                ...state,
                reworks: upsertObjectFromArray(state.reworks, action.value, function (rework) {
                    return rework.reworkId === this.reworkId;
                }),
            };
        case ReworkActionType.DELETE_REWORK:
            return {
                ...state,
                reworks: state.reworks.filter((rework) => rework.reworkId !== action.value.reworkId),
            };
        case ReworkActionType.UPDATE_REWORK:
            if (!action.value.length) {
                return state;
            }
            return {
                ...state,
                reworks: upsertObjectFromArray(state.reworks, action.value, function (rework) {
                    return rework.reworkId === this.reworkId;
                }),
            };
        // ------------------ REWORK ITEM STATUS ----------------------------
        case ReworkItemStatusActionType.UPDATE_REWORK_ITEM_STATUSES:
            return {
                ...state,
                reworkItemStatus: [...action.value],
            };
        case ReworkItemStatusActionType.INSERT_REWORK_ITEM_STATUS:
            return {
                ...state,
                reworkItemStatus: upsertObjectFromArray(state.reworkItemStatus, action.value, function (reworkItemStatus) {
                    return reworkItemStatus.reworkItemStatusId === this.reworkItemStatusId;
                }),
            };
        case ReworkItemStatusActionType.DELETE_REWORK_ITEM_STATUS:
            return {
                ...state,
                reworkItemStatus: state.reworkItemStatus.filter(
                    (reworkItemStatus) => reworkItemStatus.reworkItemStatusId !== action.value.reworkItemStatusId
                ),
            };
        case ReworkItemStatusActionType.UPDATE_REWORK_ITEM_STATUS:
            if (!action.value.length) {
                return state;
            }
            return {
                ...state,
                reworkItemStatus: upsertObjectFromArray(state.reworkItemStatus, action.value, function (reworkItemStatus) {
                    return reworkItemStatus.reworkItemStatusId === this.reworkItemStatusId;
                }),
            };
        // ------------------ REWORK LOG ------------------------------------
        case ReworkLogActionType.UPDATE_REWORK_LOGS:
            return {
                ...state,
                reworkLogs: [...action.value],
            };
        case ReworkLogActionType.INSERT_REWORK_LOG:
            return {
                ...state,
                reworkLogs: upsertObjectFromArray(state.reworkLogs, action.value, function (log) {
                    return log.type === LogTypeEnum.WORK_LOG
                        ? log.log.reworkLogId === (this.type === LogTypeEnum.WORK_LOG ? this.log.reworkLogId : 0)
                        : log.type === LogTypeEnum.FAILURE_LOG
                        ? log.log.resultProcessFailId === (this.type === LogTypeEnum.FAILURE_LOG ? this.log.resultProcessFailId : 0)
                        : log.log.symptomLogId === (this.type === LogTypeEnum.SYMPTOM_LOG ? this.log.symptomLogId : 0);
                }),
            };
        case ReworkLogActionType.UPDATE_REWORK_LOG:
            if (!action.value.length) {
                return state;
            }
            return {
                ...state,
                reworkLogs: upsertObjectFromArray(state.reworkLogs, action.value, function (log) {
                    return log.type === LogTypeEnum.WORK_LOG
                        ? log.log.reworkLogId === (this.type === LogTypeEnum.WORK_LOG ? this.log.reworkLogId : 0)
                        : log.type === LogTypeEnum.FAILURE_LOG
                        ? log.log.resultProcessFailId === (this.type === LogTypeEnum.FAILURE_LOG ? this.log.resultProcessFailId : 0)
                        : log.log.symptomLogId === (this.type === LogTypeEnum.SYMPTOM_LOG ? this.log.symptomLogId : 0);
                }),
            };
        // ------------------ JOB PROCESS REWORK ----------------------------
        case ReworkJobProcessActionType.UPDATE_REWORK_JOBS_PROCESS:
            return {
                ...state,
                reworkJobsProcess: [...action.value],
            };
        case ReworkJobProcessActionType.INSERT_REWORK_JOB_PROCESS:
            return {
                ...state,
                reworkJobsProcess: upsertObjectFromArray(state.reworkJobsProcess, action.value, function (jobProcess) {
                    return jobProcess.jobProcessId === this.jobProcessId;
                }),
            };
        case ReworkJobProcessActionType.DELETE_REWORK_JOB_PROCESS:
            return {
                ...state,
                reworkJobsProcess: removeObjectFromArray(state.reworkJobsProcess, action.value, function (jobProcess) {
                    return jobProcess.jobProcessId === this.jobProcessId;
                }),
            };
        // ------------------ ROOT CAUSE ----------------------------
        case RootCauseActionType.SET_ROOT_CAUSE:
            return {
                ...state,
                rootCause: [...action.value],
            };
        case RootCauseActionType.INSERT_ROOT_CAUSE:
            return {
                ...state,
                rootCause: upsertObjectFromArray(state.rootCause, action.value, function (rootCause) {
                    return rootCause.rootCauseId === this.rootCauseId;
                }),
            };
        case RootCauseActionType.UPDATE_ROOT_CAUSE:
            return {
                ...state,
                rootCause: upsertObjectFromArray(state.rootCause, action.value, function (rootCause) {
                    return rootCause.rootCauseId === this.rootCauseId;
                }),
            };
        default:
            return state;
    }
}
