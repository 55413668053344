import { IKortexSpeedDialProps, KortexSpeedDial, theme } from "@aos/react-components";
import {
    FileInfoDbModel,
    IFileInfoDbModel,
    ProcessApprovalStatusFilter,
    StorageEditorsRightsEnum,
    TreeFileInsertType,
    TreeNodeDbModel,
    TreeNodeNodeTypeEnum,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Button, Dialog, DialogActions, DialogContent, Grid, Paper, Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import FileIcon from "@material-ui/icons/DescriptionOutlined";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import Debug from "debug";
import * as React from "react";
import { useEffect, useState } from "react";
import { AutoSizer } from "react-virtualized";

import { useTranslate } from "../../../hooks/useTranslate";
import { useEntitiesTreeFile } from "../../../redux/effects";
import { fileGetByFileId, fileGetByTreeNode, fileInsert } from "../../../redux/file-manager/file-thunks-file";
import { treeFileInsert, treeFileUpdate } from "../../../redux/tree-manager/tree-thunks-file";
import { IUserRightsProps, userCanInsert } from "../../../utilitites/IUserRights";
import * as CloudStorage from "../../../utilitites/storage";
import { uploadFile } from "../../../utilitites/uploadFile/uploadFile";
import PreviewPane from "../../core/PreviewPane/PreviewPane";
import TreeView, { AcceptedFileType, TreeViewVariantFile, TreeViewVariantFolder } from "../../core/TreeView/TreeView";
import { useSnackbar } from "../../layout/snackbarConfigurator";

const debug = Debug("kortex:ui:file-manager");

const TREEVIEW_OVERSCAN_ROW_COUNT = 20;

const useStyles = makeStyles({
    mainContainer: {
        height: "100%",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "16px",
    },
    previewPane: {
        overflow: "scroll",
        minHeight: "10px",
        minWidth: "10px",
    },
    hidden: {
        display: "none",
    },
    containerFolder: {
        position: "relative",
        width: "100%",
    },
    containerRight: {
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateRows: "1fr 1fr",
        gridRowGap: "16px",
    },
    containerFile: {
        height: "100%",
        width: "100%",
    },
    containerPreview: {
        position: "relative",
    },
    speedDial: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    speedDialFab: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    speedDialActionFab: {
        width: "35px",
        height: "35px",
        boxShadow: "0px 4px 5px 0px",
        backgroundColor: theme.palette.grey[300],
    },
    speedDialTooltipLabel: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[50],
    },
    optionsContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    option: {
        display: "flex",
        alignItems: "center",
    },
    whereUsedContainer: {},
    whereUsedItemHeader: {
        padding: "10px",
        color: theme.palette.secondary.main,
    },
    whereUsedItemList: {
        padding: "10px",
    },
});

interface IStorageManagerProps extends IUserRightsProps<StorageEditorsRightsEnum> {
    // Own props
    acceptedFileType?: AcceptedFileType;
    preSelectedFileId?: string;
    fileFilter?: TreeNodeNodeTypeEnum;
    onFileSelected?: (file?: IFileInfoDbModel) => void;
    onFolderSelected?: (treeNodeId?: number) => void;
}

/*interface IWhereUsedProcessActionInfo {
    processActionId: number;
    processActionName: string;
    processActionType: string;
    processName: string;
    processVersion: string;
    processPath: string;
}*/

type IAllProps = IStorageManagerProps;

export default function StorageManager(props: IAllProps): JSX.Element {
    const { acceptedFileType = "all", fileFilter, onFileSelected, preSelectedFileId, userAccessLevel, onFolderSelected } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const snackbar = useSnackbar();
    const translate = useTranslate();

    const [fileList, setFileList] = useState<TreeNodeDbModel[]>([]);
    const [previewText, setPreviewText] = useState<string | undefined>(undefined);
    const [selectedFolderTreeNode, setSelectedFolderTreeNode] = useState<TreeNodeDbModel | undefined>(undefined);
    const [selectedFileTreeNodes, setSelectedFileTreeNodes] = useState<TreeNodeDbModel[]>([]);
    const [selectedFileInfo, setSelectedFileInfo] = useState<IFileInfoDbModel | undefined>(undefined);
    const [dragMode, setDragMode] = useState<boolean>(false);
    const [showArchived, setShowArchived] = useState<boolean>(false);
    const [whereUsedDialogOpen, setWhereUsedDialogOpen] = useState<boolean>(false);

    const treeNodes = useEntitiesTreeFile(async (nodes) => {
        if (!nodes?.length || !preSelectedFileId) {
            return;
        }

        const preSelectedFileTreeNodeId = (await dispatch(fileGetByFileId({ fileId: preSelectedFileId })))?.treeNodeId;
        const selectedFile = nodes.find((node) => node.treeNodeId === preSelectedFileTreeNodeId);

        if (selectedFile) {
            const folder = nodes.find((node) => node.treeNodeId === selectedFile.parentId);

            if (folder) {
                handleSelectFolderRow([folder], false);
                handleSelectFileRow([selectedFile], false);
            }
        }
    });

    /**
     * Effect triggered when treeNodes is modified
     */
    useEffect(() => {
        refreshFileListView();
    }, [treeNodes]);

    /**
     * Effect triggered when selectedFolderTreeNode is modified
     */
    useEffect(() => {
        refreshFileListView();
    }, [selectedFolderTreeNode]);

    /**
     * Effect triggered at each component render
     */
    useEffect((): (() => void) => {
        document.addEventListener("paste", handlePaste);

        return (): void => {
            document.removeEventListener("paste", handlePaste);
        };
    });

    /**
     * Refresh file list views based on selected folder
     */
    const refreshFileListView = (): void => {
        setDragMode(false);
        setFileList(
            treeNodes.filter(
                (file): boolean =>
                    file.nodeType !== TreeNodeNodeTypeEnum.FOLDER &&
                    file.parentId === (selectedFolderTreeNode && selectedFolderTreeNode.treeNodeId) &&
                    (file.nodeType === fileFilter || !fileFilter)
            )
        );
    };

    /**
     * Upload a file to the storage server and store file information into database
     *
     * @param {number} parentId - ParentId of the file
     * @param {File} file - File blob (data)
     * @param {TreeFileInsertType} fileType - File type
     */
    const handleUploadFile = (parentId: TreeNodeDbModel["treeNodeId"], file: File, fileType: TreeFileInsertType): void => {
        uploadFile(file, fileType)
            .then((fileId) => {
                dispatch(fileInsert([{ fileId, label: file.name, parentTreeNodeId: parentId, type: fileType }])).then((files) => {
                    if (files?.length) {
                        snackbar.success(translate("storage.success"));

                        handleSelectFileRow([files[0].node]);
                    }
                });
            })
            .catch((err: Error) => {
                snackbar.error(translate("storage.error.upload") + " " + translate(err.message));
            });
    };

    /**
     * Handle row menu select of a folder
     *
     * @param {TreeNodeDbModel[]} nodes - contains the node id of the folder selected
     */
    const handleSelectFolderRow = (nodes: TreeNodeDbModel[], triggerCallback = true): void => {
        if (triggerCallback && onFolderSelected) onFolderSelected(nodes.length > 0 ? nodes[0].treeNodeId : undefined);

        if (nodes.length === 1 && nodes[0] !== selectedFolderTreeNode) handleSelectFileRow([], false);

        setSelectedFolderTreeNode(nodes.length > 0 ? nodes[0] : undefined);
    };

    /**
     * Handle row menu select of a folder
     *
     * @param {TreeNodeDbModel[]} nodes - contains the node id of the file selected
     */
    const handleSelectFileRow = (nodes: TreeNodeDbModel[], triggerCallback = true): void => {
        setSelectedFileTreeNodes(nodes);

        if (nodes.length === 1) {
            dispatch(fileGetByTreeNode({ treeNodeId: nodes[0].treeNodeId })).then((file) => {
                setSelectedFileInfo(file);

                if (file) {
                    if (nodes[0].nodeType === TreeNodeNodeTypeEnum.TEXT) {
                        fetch(`${CloudStorage.get().host}/${file.fileId}`) // Call the fetch function passing the url of the API as a parameter
                            .then(
                                (response) => response.text().then((text) => setPreviewText(text)),
                                (err) => debug(err)
                            );
                    }

                    if (triggerCallback && onFileSelected) onFileSelected(file);
                }
            });
        } else if (!nodes.length) {
            setSelectedFileInfo(undefined);
        }
    };

    /**
     * Handler menu request to insert a new folder
     */
    const handleMenuInsertFolder = (parentId: TreeNodeDbModel["treeNodeId"]): void => {
        dispatch(
            treeFileInsert([{ label: translate("storagemanager.folder"), parentTreeNodeId: parentId, type: TreeFileInsertType.FOLDER }])
        ).then((nodes) => {
            setSelectedFolderTreeNode(nodes ? nodes[0] : undefined);
        });
    };

    /**
     * Handler to update the requested node
     *
     * @param {TreeNodeDbModel} treeNode - element to be updated
     */
    const handleUpdateNode = (treeNode: TreeNodeDbModel): void => {
        dispatch(treeFileUpdate([treeNode]));
    };

    /**
     * Handler to insert the requested node
     *
     * @param {number} parentTreeNodeId - Parent tree node ID
     * @param {number} fileId - file ID
     * @param {TreeFileInsertType} type - Inserted file type
     * @param {string} fileName - Insertedfile name
     */
    const handleInsertFile = (
        parentTreeNodeId: TreeNodeDbModel["treeNodeId"],
        fileId: FileInfoDbModel["fileId"],
        type: TreeFileInsertType,
        fileName: TreeNodeDbModel["label"]
    ): void => {
        dispatch(fileInsert([{ label: fileName, fileId, parentTreeNodeId: parentTreeNodeId, type }])).then((upsertedFiles) => {
            if (upsertedFiles?.length) {
                setSelectedFileTreeNodes([upsertedFiles[0].node]);
                setSelectedFileInfo(upsertedFiles[0].file);

                onFileSelected?.(upsertedFiles[0].file);
            }

            setDragMode(false);
        });
    };

    /**
     * Handle paste event for copy/paste fonction
     */
    const handlePaste = (event: ClipboardEvent): void => {
        if (!selectedFolderTreeNode || !selectedFolderTreeNode.treeNodeId) {
            alert("A folder must be selected before paste an image file");
            return;
        }

        if (event.clipboardData) {
            const items: DataTransferItemList = event.clipboardData.items;
            if (!items) {
                return;
            }

            // DataTransferItemList does not support iterators
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < items.length; i++) {
                const item = items[i];

                if (item.type.indexOf("image") !== -1) {
                    const blob = item.getAsFile();
                    if (blob) {
                        handleUploadFile(selectedFolderTreeNode.treeNodeId, blob, TreeNodeNodeTypeEnum.IMAGE);
                        event.preventDefault();
                    }
                }
            }
        }
    };

    /**
     * Handle request to cancel the drag mode
     */
    const handleCancelDragMode = (): void => {
        setDragMode(false);
    };

    /**
     * Handle request to open menu file using the speed dial (FAB) menu
     */
    const handleOpenMenuFile = (): void => {
        if (selectedFolderTreeNode) {
            setDragMode(true);
        }
    };

    /**
     * Handle request to open menu folder using the speed dial (FAB) menu
     */
    const handleOpenMenuFolder = (): void => {
        if (selectedFolderTreeNode) {
            handleMenuInsertFolder(selectedFolderTreeNode.treeNodeId || 0);
        }
    };

    /**
     * Handle request to show or hide archived files
     */
    const handleOptionShowArchived = (): void => {
        setShowArchived(!showArchived);
    };

    /**
     * Handle where used callback, list the process action that uses the tree node artifact selected
     *
     */
    // TODO: Remake this function, not working as intended
    const handleWhereUsed = (/*treeNodeId: TreeNodeDbModel["treeNodeId"]*/): void => {
        /*const req = await props.asyncGetProcessListFromTreeNodeId(treeNodeId);
        if (req) {
            const whereUsedList: IWhereUsedProcessActionInfo[] = [];
            for (const processAction of req.processActionList) {
                const fetchProcessResponse = await apiClient.process.fetchProcess(processAction.processId, undefined);
                let processPath = "";
                if (fetchProcessResponse.processWithActions.treeNode) {
                    const treeNodeId = fetchProcessResponse.processWithActions.treeNode.treeNodeId;
                    let foundNode = props.repoNodes.find((node: ITreeNodeDbModel): boolean => node.treeNodeId === treeNodeId);

                    if (foundNode) {
                        processPath += foundNode.label;
                    }
                    while (foundNode && foundNode.parentId != 0) {
                        const parentId = foundNode.parentId;
                        foundNode = props.repoNodes.find((node: ITreeNodeDbModel): boolean => node.treeNodeId === parentId);
                        if (foundNode) {
                            processPath = foundNode.label + " / " + processPath;
                        }
                    }
                }

                const whereUsedProcessActionInfo: IWhereUsedProcessActionInfo = {
                    processActionId: processAction.processActionId ? processAction.processActionId : 0,
                    processActionName: processAction.label,
                    processName:
                        (fetchProcessResponse.processWithActions.treeNode && fetchProcessResponse.processWithActions.treeNode.label) || "",
                    processActionType: processAction.type,
                    processVersion: fetchProcessResponse.processWithActions.process.version
                        ? fetchProcessResponse.processWithActions.process.version
                        : "",
                    processPath,
                };
                whereUsedList.push(whereUsedProcessActionInfo);
            }
            setWhereUsedProcessActionList(whereUsedList);
            setWhereUsedDialogOpen(true);
        }*/
    };

    /**
     * Archives a node
     */
    const handleArchiveNode = (archived: TreeNodeDbModel["archived"], nodeIds: TreeNodeDbModel["treeNodeId"][]): void => {
        for (const id of nodeIds) {
            const node = treeNodes.find((node) => node.treeNodeId === id);
            if (node) {
                dispatch(treeFileUpdate([{ ...node, archived }]));
            }
        }
    };

    /**
     * Handle request to close the where used dialog
     */
    const handleWhereUsedDialogClose = (): void => {
        setWhereUsedDialogOpen(false);
    };

    // Defines the addActions given to SpeedDial
    const addActions: Pick<IKortexSpeedDialProps, "actions"> = {
        actions: [
            {
                callback: handleOpenMenuFolder,
                icon: <FolderIcon id="storageAddFolderButtonId" />,
                label: translate("storagemanager.insertFolder"),
                classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
            },
            {
                callback: handleOpenMenuFile,
                icon: <FileIcon id="storageAddFileButtonId" />,
                label: translate("storagemanager.insertFile"),
                classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
            },
        ],
    };

    return (
        <div className={classes.mainContainer} id="StorageManagerPageId">
            {/* FOLDER LIST PANE */}
            {
                <Paper id="menuListPaneId" className={classes.containerFolder}>
                    <AutoSizer>
                        {({ height, width }): JSX.Element => (
                            <TreeView
                                variant={TreeViewVariantFolder}
                                nodeList={treeNodes}
                                height={height}
                                width={width}
                                overscanRowCount={TREEVIEW_OVERSCAN_ROW_COUNT}
                                parentNode={selectedFolderTreeNode}
                                onNodesSelected={handleSelectFolderRow}
                                selectedNodes={selectedFolderTreeNode ? [selectedFolderTreeNode] : []}
                                onUpdateNode={handleUpdateNode}
                                onArchive={handleArchiveNode}
                                onInsertFile={handleInsertFile}
                                userAccessLevel={userAccessLevel}
                                enableAttributeArchive={true}
                                showArchived={showArchived}
                                showProcessVersion={ProcessApprovalStatusFilter.NONE}
                            />
                        )}
                    </AutoSizer>
                    {userCanInsert(userAccessLevel) && (
                        <KortexSpeedDial
                            ariaLabel=""
                            SpeedDialProps={{
                                className: classes.speedDial,
                                FabProps: {
                                    disabled: !selectedFolderTreeNode,
                                },
                            }}
                            actions={addActions.actions}
                        />
                    )}
                </Paper>
            }
            <div className={classes.containerRight}>
                {/* FILE LIST PANE */}
                <Paper id="fileListPaneId" className={classes.containerFile}>
                    <AutoSizer>
                        {({ height, width }): JSX.Element => (
                            <TreeView
                                acceptedFileType={acceptedFileType}
                                variant={TreeViewVariantFile}
                                nodeList={fileList}
                                height={height}
                                width={width}
                                multiSelect={true}
                                overscanRowCount={TREEVIEW_OVERSCAN_ROW_COUNT}
                                dragMode={dragMode}
                                parentNode={selectedFolderTreeNode}
                                selectedNodes={selectedFileTreeNodes}
                                onNodesSelected={handleSelectFileRow}
                                onArchive={handleArchiveNode}
                                onUpdateNode={handleUpdateNode}
                                onInsertFile={handleInsertFile}
                                onCloseMenu={handleCancelDragMode}
                                userAccessLevel={userAccessLevel}
                                enableAttributeArchive={true}
                                showArchived={showArchived}
                                onWhereUsed={handleWhereUsed}
                                showProcessVersion={ProcessApprovalStatusFilter.NONE}
                            />
                        )}
                    </AutoSizer>
                </Paper>

                {/* PREVIEW PANE */}
                <Paper id="previewPaneId" className={classes.containerPreview}>
                    {selectedFileTreeNodes.length > 0 && selectedFileInfo && (
                        <PreviewPane
                            fileType={selectedFileTreeNodes[0].nodeType}
                            fileId={selectedFileInfo.fileId}
                            text={previewText ? previewText : ""}
                        />
                    )}
                </Paper>
                {/* OPTIONS */}
                <div className={classes.optionsContainer}>
                    <div className={classes.option}>
                        {translate("treeview.optionShowArchived")} <Checkbox checked={showArchived} onChange={handleOptionShowArchived} />
                    </div>
                </div>
            </div>
            <Dialog open={whereUsedDialogOpen} disableAutoFocus={true} fullWidth={true} maxWidth={"lg"}>
                <DialogContent className={classes.whereUsedContainer}>
                    <Grid container={true}>
                        <Grid className={classes.whereUsedItemHeader} item={true} xs={2}>
                            <Typography variant="h6">{translate("storage.whereUsedDialog.processName")}</Typography>
                        </Grid>
                        <Grid className={classes.whereUsedItemHeader} item={true} xs={2}>
                            <Typography variant="h6">{translate("storage.whereUsedDialog.processVersion")}</Typography>
                        </Grid>
                        <Grid className={classes.whereUsedItemHeader} item={true} xs={2}>
                            <Typography variant="h6">{translate("storage.whereUsedDialog.actionName")}</Typography>
                        </Grid>
                        <Grid className={classes.whereUsedItemHeader} item={true} xs={2}>
                            <Typography variant="h6">{translate("storage.whereUsedDialog.actionType")}</Typography>
                        </Grid>
                        <Grid className={classes.whereUsedItemHeader} item={true} xs={4}>
                            <Typography variant="h6">{translate("storage.whereUsedDialog.processPath")}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="showWhereUsedOkButtonId" variant="contained" color="primary" onClick={handleWhereUsedDialogClose}>
                        {translate("storage.whereUsedDialog.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
