import { UserGroupStatusEnum } from "../interfaces/models";
export function newUserGroup() {
    return {
        userGroupId: 0,
        name: "GroupName",
        isApprover: false,
        approvalMandatory: false,
        approverQty: 0,
        status: UserGroupStatusEnum.ACTIVE,
        isTrainer: false,
        trainedAfterReading: false,
    };
}
