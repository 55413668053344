import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { IUserDbModel, ProcessId, UserDbModel, UserId } from "@kortex/aos-common";
import { useClientService } from "@kortex/aos-ui/hooks/useClientService";
import { useKeybind, useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { Checkbox, CircularProgress, Divider, InputAdornment, Typography, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import { TextFieldSelect } from "../TextFieldSelect";

const useStyles = makeStyles({
    circularProgress: {
        height: "25px !important",
        width: "25px !important",
    },
    consentSection: {
        alignItems: "center",
        display: "flex",
        marginBottom: "20px",
    },
    formContainer: {
        alignItems: "stretch",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        marginBottom: "15px",
        marginTop: "15px",
        padding: "0 30px",
    },
    spacer: {
        flex: 1,
    },
    textfield: {
        margin: 0,
        marginBottom: "20px",
    },
    trainerSelectorDefaultOption: {
        display: "flex",
    },
    typographyContext: {
        marginTop: "20px",
    },
    typographyUserNotTrained: {
        marginBottom: "20px",
    },
});

interface IOwnProps {
    onClose: () => void;
    onValidate: (validatedByUserId: number, username: string, password: string, consented: boolean, context: string) => void;
    open: boolean;
    processId: ProcessId;
}

const NO_USER_SELECTED_ID = -1;

function TrainerValidationDialog(props: IOwnProps): JSX.Element {
    const { onValidate, onClose, open, processId } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const getTrainedUsers = useClientService("users", "getTrainedUsers");

    const [consentChecked, setConsentChecked] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loginError, setLoginError] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [selectedTrainerId, setSelectedTrainerId] = useState<UserId>(NO_USER_SELECTED_ID);
    const [trainers, setTrainers] = useState<UserDbModel[]>([]);

    const validateButtonDisabled = selectedTrainerId === NO_USER_SELECTED_ID || password === "" || !consentChecked;

    useStopKeybindPropagation(open);

    /**
     * Key down event - Enter
     * Validate the user and password
     */
    useKeybind("Enter", () => handleUserValidation(), {
        disabled: !open && validateButtonDisabled,
        preventDefault: true,
        stopPropagation: true,
    });

    /**
     * Update inner states when dialog opens or closes
     */
    useEffect((): void => {
        if (!open) {
            setPassword("");
            setConsentChecked(false);
            setSelectedTrainerId(NO_USER_SELECTED_ID);

            return void 0;
        }

        setLoading(true);

        getTrainedUsers({ processId, certified: true })
            .then((trainedUsers) => {
                if (!trainedUsers) {
                    setTrainers([]);
                    return;
                }

                setTrainers(trainedUsers as IUserDbModel[]);
            })
            .finally(() => setLoading(false));
    }, [open]);

    /**
     * Handles user validation
     */
    const handleUserValidation = (): void => {
        const trainer = trainers.find((trainer) => trainer.userId === selectedTrainerId);

        if (trainer) {
            onValidate(
                trainer.userId,
                trainer.userName,
                password,
                consentChecked,
                translate("player.trainerElectronicSignatureRequiredContext")
            );
            setPassword("");
        } else {
            setLoginError(translate("player.wrongCredentials"));
        }
    };

    /**
     * Handles password change
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - event info
     */
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
    };

    /**
     * Handles user change
     *
     * @param {string} value - value
     */
    const handleUserChange = (value: number): void => {
        setSelectedTrainerId(value);
    };

    /**
     * handle close event
     */
    const handleCloseDialog = (): void => {
        onClose();
        setLoginError("");
    };

    /**
     * Called when the consent checkbox is checked/unchecked
     */
    const handleConsent = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        setConsentChecked(checked);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={validateButtonDisabled}
            onCancel={handleCloseDialog}
            onConfirm={handleUserValidation}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("player.close"),
                proceedButtonLabel: translate("player.sign"),
                titleLabel: translate("player.trainerRequired"),
            }}
        >
            <div className={classes.formContainer} id={"TrainerValidationDialogId"}>
                <Typography className={classes.typographyUserNotTrained}>{translate("player.notTrainedForProcess")}</Typography>
                <Divider />
                <Typography className={`${classes.typographyUserNotTrained} ${classes.typographyContext}`}>
                    {translate("player.trainerElectronicSignatureRequiredContext")}
                </Typography>
                <TextFieldSelect
                    KortexTextFieldProps={{
                        className: classes.textfield,
                        label: translate("player.trainerOrTrainedAndCertifiedOperator"),
                        TextFieldProps: {
                            disabled: loading,
                            id: "usernameInputId",
                            placeholder: translate("player.selectTrainedOperator"),
                        },
                        InputProps: {
                            endAdornment: loading ? (
                                <InputAdornment position="end">
                                    <CircularProgress className={classes.circularProgress} />
                                </InputAdornment>
                            ) : null,
                        },
                    }}
                    items={trainers.map((user) => {
                        const label = user.firstName + " " + user.lastName;

                        return {
                            component: <Typography>{label}</Typography>,
                            filterCallback: (value: string): boolean =>
                                value ? label.toLocaleLowerCase().includes(value.toLocaleLowerCase()) : true,
                            textFieldValue: label,
                            value: user.userId,
                        };
                    })}
                    onChange={handleUserChange}
                    value={selectedTrainerId}
                />
                <KortexTextField
                    className={classes.textfield}
                    error={loginError}
                    label={translate("player.password")}
                    onChange={handlePasswordChange}
                    TextFieldProps={{
                        autoComplete: "current-password",
                        id: "passwordInputId",
                    }}
                    type="password"
                    value={password}
                    variant="outlined"
                />
                <div className={classes.consentSection}>
                    <Checkbox checked={consentChecked} onChange={handleConsent} id={"checkboxConsentId"} />
                    <Typography>{translate("general.electronicSignatureConsent")}</Typography>
                </div>
            </div>
        </KortexDialogConfirmation>
    );
}

export default TrainerValidationDialog;
