import { IFailureTypeDbModel, IResultFailProblemOfFailureLog, IReworkItemStatusDbModel } from "@kortex/aos-common";
import { theme, KortexTextField, KortexOutlinedTextField } from "@aos/react-components";
import { DialogActions, Button, DialogContent, Dialog, makeStyles, MenuItem, Typography } from "@material-ui/core";
import EndIcon from "@material-ui/icons/NotInterested";
import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";

import { getParentNodesPath } from "../../../../../utilitites/getParentNodes";
import {
    useEntitiesFailureTypes,
    useEntitiesFailureSymptoms,
    useEntitiesTreeProcess,
    useEntitiesReworkItemStatus,
} from "../../../../../redux/effects";
import { useTranslate } from "../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    dialogTitle: {
        display: "flex",
    },
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 20px",
    },
    failure: {
        marginTop: "15px",
        backgroundColor: theme.palette.grey.A100,
        padding: "10px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
    },
    failureRow: {
        display: "flex",
        flexDirection: "row",
        paddingLeft: "10px",
        paddingRight: "10px",
    },
    problemContainer: {
        display: "flex",
        width: "100%",
    },
    problems: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    problem: {
        flexGrow: 1,
        display: "flex",
        marginTop: "15px",
        width: "100%",
    },
    text: {
        margin: "5px 0px",
        color: theme.palette.primary[500],
    },
    boxRowTitle: {
        display: "flex",
        margin: "5px 0px 20px 10px",
    },
    select: {
        width: "200px",
        margin: "5px 15px 0px 0px",
    },
    comments: {
        marginTop: "15px",
        width: "100%",
    },
    error: {
        marginTop: "20px",
        color: theme.palette.error[500],
    },
    endIcon: {
        color: theme.palette.grey[400],
        paddingLeft: "10px",
    },
});

interface IOwnProps {
    open: boolean;
    reworkItemStatusId: number;
    problems: IResultFailProblemOfFailureLog[];
    treeNodeId: number;

    onSave: (reworkItemStatusId: number, problems: IResultFailProblemOfFailureLog[]) => void;
    onClose: () => void;
}

export default function UpdateSymptomDialog(props: IOwnProps): JSX.Element {
    const { open, reworkItemStatusId: reworkItemStatusIdProps = 0, problems: problemsProps = [], treeNodeId } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const allReworkItemStatus = useEntitiesReworkItemStatus();
    const allFailureTypes = useEntitiesFailureTypes();
    const allFailureSymptoms = useEntitiesFailureSymptoms();
    const allNodes = useEntitiesTreeProcess();
    const language = useSelectorLanguage();

    const [validate, setValidate] = useState<boolean>(false);
    const [failureTypes, setFailureTypes] = useState<IFailureTypeDbModel[]>([]);
    const [reworkItemStatuses, setReworkItemStatuses] = useState<IReworkItemStatusDbModel[]>([]);

    const [reworkItemStatusId, setReworkItemStatusId] = useState<number>(reworkItemStatusIdProps);
    const [problems, setProblems] = useState<IResultFailProblemOfFailureLog[]>(problemsProps);
    const [comments, setComments] = useState<string[]>([]);

    // Reset data when opening dialog
    useEffect(() => {
        // add new failures
        setReworkItemStatusId(reworkItemStatusIdProps);
        setProblems(problemsProps);
        setComments(problems.map((prob) => prob.comments));
    }, [open]);

    /**
     * Effect triggered when failure types changed in store
     */
    useEffect((): void => {
        if (treeNodeId && allFailureTypes.length > 0) {
            const parentNodes = getParentNodesPath(treeNodeId, allNodes);
            let nodeFailureTypes: IFailureTypeDbModel[] = [];

            for (const node of parentNodes) {
                const failureTypes = allFailureTypes.filter((failureType) => {
                    return failureType.treeNodeId === node.treeNodeId && !failureType.archived;
                });
                nodeFailureTypes = nodeFailureTypes.concat(failureTypes);
            }

            setFailureTypes(nodeFailureTypes);
        } else {
            //to manage old data from previous versions AOS 1.12.0 don't have treeNodeId if the failure ticket is created manually.
            setFailureTypes(allFailureTypes);
        }
    }, [allFailureTypes, treeNodeId]);

    /**
     * Effect triggered when rework item status changed in store
     */
    useEffect((): void => {
        if (treeNodeId && allReworkItemStatus.length > 0) {
            const parentNodes = getParentNodesPath(treeNodeId, allNodes);
            let nodeReworkItemStatus: IReworkItemStatusDbModel[] = [];
            for (const node of parentNodes) {
                const reworkItemStatus = allReworkItemStatus.filter((status) => {
                    return status.treeNodeId === node.treeNodeId && !status.archived;
                });
                nodeReworkItemStatus = nodeReworkItemStatus.concat(reworkItemStatus);
            }

            setReworkItemStatuses(nodeReworkItemStatus);
        } else {
            //to manage old data from previous versions AOS 1.12.0 don't have treeNodeId if the failure ticket is created manually.
            setReworkItemStatuses(allReworkItemStatus);
        }
    }, [allReworkItemStatus, treeNodeId]);

    /**
     * handles the send new symptom
     */
    const handleSendReport = (): void => {
        setValidate(true);
        let formValid = true;
        for (const problem of problems) {
            if (problem.failureType.failureTypeId === 0 || problem.failureSubType.failureSubTypeId === 0) {
                formValid = false;
            }
        }

        // Check if reworkItemStatus has been filled
        if (reworkItemStatusId === 0) {
            formValid = false;
        }

        // Check if comments has been filled
        for (const comment of comments) {
            if (comment === "") {
                formValid = false;
            }
        }

        if (formValid) {
            // we are valid to send!!!
            props.onSave(reworkItemStatusId, problems);
            handleClose();

            setValidate(false);
        }
    };

    /**
     * handles close
     */
    const handleClose = (): void => {
        props.onClose();
    };

    /**
     * Sets the problem status
     *
     */
    const setStatus = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setReworkItemStatusId(parseInt(e.target.value));
    };

    /**
     * Sets the problem Failure Type
     *
     * @param {number} index - problem index
     */
    const setProblemType =
        (index: number): ((e: React.ChangeEvent) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newProblems = [...problems];
            newProblems[index].failureType.failureTypeId = parseInt(e.target.value, 10);
            newProblems[index].failureSubType.failureSubTypeId = 0;
            setProblems(newProblems);
        };

    /**
     * Sets the problem Failure Sub Type
     *
     * @param {number} index - problem index
     */
    const setProblemSubType =
        (index: number): ((e: React.ChangeEvent) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newProblems = [...problems];
            newProblems[index].failureSubType.failureSubTypeId = parseInt(e.target.value, 10);
            setProblems(newProblems);
        };

    /**
     * Sets the problem Failure Comments
     *
     * @param {number} index - problem index
     */
    const setProblemComments =
        (index: number): ((e: React.ChangeEvent<HTMLInputElement>) => void) =>
        (e: React.ChangeEvent<HTMLInputElement>): void => {
            const newProblems = [...problems];
            newProblems[index].comments = e.target.value;
            setProblems(newProblems);
            setComments[newProblems[index].comments];
        };

    return (
        <Dialog open={open} disableAutoFocus={true} fullWidth={true} maxWidth="lg">
            <DialogContent className={classes.dialogContent}>
                <div className={classes.dialogTitle}>
                    <div className={classes.boxRowTitle}>
                        <Typography variant="h2">{translate("rework.updateSymptom")}</Typography>
                    </div>
                </div>
                <div id="symptomDialogFormId" className={classes.failure} key={0}>
                    {reworkItemStatusId > 0 && problems.length > 0 && (
                        <div>
                            <div className={classes.failureRow} key={0}>
                                <KortexOutlinedTextField
                                    id="reworkStatusId"
                                    value={reworkItemStatusId}
                                    label={translate("rework.status")}
                                    select={true}
                                    className={classes.select}
                                    required={true}
                                    onChange={setStatus}
                                    error={validate && reworkItemStatusId === 0}
                                >
                                    <MenuItem value={0} disabled>
                                        <Typography>{`${translate("general.select")} ...`}</Typography>
                                    </MenuItem>

                                    {reworkItemStatuses
                                        ?.sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: "base" }))
                                        .map((status, index): JSX.Element => {
                                            return (
                                                <MenuItem key={index} value={status.reworkItemStatusId} id={`${status.title}Id`}>
                                                    {status.title}
                                                    {status.automaticClose && <EndIcon className={classes.endIcon} />}
                                                </MenuItem>
                                            );
                                        })}
                                </KortexOutlinedTextField>
                            </div>
                            <div className={classes.failureRow}>
                                <div className={classes.problemContainer}>
                                    <div className={classes.problems}>
                                        {problems.map((problem, problemIndex): JSX.Element => {
                                            return (
                                                <div key={problemIndex}>
                                                    <div className={classes.problem}>
                                                        {/* Type */}
                                                        <KortexOutlinedTextField
                                                            id="reworkSymptomTypeId"
                                                            value={problem.failureType.failureTypeId}
                                                            label={translate("rework.typeSymptom")}
                                                            select={true}
                                                            className={classes.select}
                                                            required={true}
                                                            error={validate && problem.failureType.failureTypeId === 0}
                                                            onChange={setProblemType(problemIndex)}
                                                        >
                                                            <MenuItem value={0} disabled>
                                                                <Typography>{`${translate("general.select")} ...`}</Typography>
                                                            </MenuItem>
                                                            {failureTypes
                                                                ?.sort((a, b) =>
                                                                    a.title.localeCompare(b.title, language, {
                                                                        sensitivity: "base",
                                                                    })
                                                                )
                                                                .map((type, TIndex): JSX.Element => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={TIndex}
                                                                            value={type.failureTypeId}
                                                                            id={`symptomType${TIndex}Id`}
                                                                        >
                                                                            {type.title}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                        </KortexOutlinedTextField>
                                                        {/* Symptom */}
                                                        <KortexOutlinedTextField
                                                            id="reworkSymptomId"
                                                            value={problem.failureSubType.failureSubTypeId}
                                                            label={translate("rework.symptom")}
                                                            select={true}
                                                            className={classes.select}
                                                            required={true}
                                                            error={validate && problem.failureSubType.failureSubTypeId === 0}
                                                            onChange={setProblemSubType(problemIndex)}
                                                        >
                                                            <MenuItem value={0} disabled>
                                                                <Typography>{`${translate("general.select")} ...`}</Typography>
                                                            </MenuItem>
                                                            {allFailureSymptoms
                                                                .filter(
                                                                    (symptoms): boolean =>
                                                                        symptoms.failureTypeId === problem.failureType.failureTypeId &&
                                                                        !symptoms.archived
                                                                )
                                                                .sort((a, b) =>
                                                                    a.title.localeCompare(b.title, language, {
                                                                        sensitivity: "base",
                                                                    })
                                                                )
                                                                .map((symptoms, STIndex): JSX.Element => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={STIndex}
                                                                            value={symptoms.failureSubTypeId}
                                                                            id={`failureSymptom${STIndex}Id`}
                                                                        >
                                                                            {symptoms.title}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                        </KortexOutlinedTextField>
                                                    </div>
                                                    <div>
                                                        {/* Comments */}
                                                        <KortexTextField
                                                            label={translate("rework.comments")}
                                                            className={classes.comments}
                                                            onChange={setProblemComments(problemIndex)}
                                                            value={comments.length > 0 ? comments[problemIndex] : ""}
                                                            error={
                                                                validate && (comments.length < 1 || comments[problemIndex] === "")
                                                                    ? translate("rework.commentsRequired")
                                                                    : undefined
                                                            }
                                                            TextFieldProps={{
                                                                id: "reworkCommentsId",
                                                                multiline: true,
                                                                required: true,
                                                                rows: 10,
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
            {/* BUTTON DIALOG*/}
            <DialogActions className={classes.dialogActions}>
                <div id="symptomDialogActionId">
                    <Button
                        id="closeButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                        className={classes.dialogButtons}
                    >
                        {translate("general.close")}
                    </Button>
                    <Button
                        id="proceedButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleSendReport}
                        className={classes.dialogButtons}
                    >
                        {translate("general.create")}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
