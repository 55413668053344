import {
    BomFollowUpSymptomActionType,
    BomFollowUpSymptomInsertedAction,
    BomFollowUpSymptomUpdatedAction,
    SetBomFollowUpSymptomListAction,
} from "./bom-follow-up-types";

/* ------------------------------------------------ 
 ------------- BOM FOLLOW-UP SYMPTOM --------------
 --------------------------------------------------*/
/**
 * Returns an action
 *
 * @param {SetBomFollowUpSymptomListAction} bomFollowUpSymptomsType - value of the action
 */
export function setBomFollowUpSymptomListAction(
    bomFollowUpSymptomsType: SetBomFollowUpSymptomListAction["value"]
): SetBomFollowUpSymptomListAction {
    return { type: BomFollowUpSymptomActionType.SET_BOM_FOLLOW_UP_SYMPTOM, value: bomFollowUpSymptomsType };
}

/**
 * Returns an action
 *
 * @param {BomFollowUpSymptomInsertedAction} bomFollowUpSymptom - value of the action
 */
export function bomFollowUpSymptomInsertedAction(
    bomFollowUpSymptom: BomFollowUpSymptomInsertedAction["value"]
): BomFollowUpSymptomInsertedAction {
    return { type: BomFollowUpSymptomActionType.INSERT_BOM_FOLLOW_UP_SYMPTOM, value: bomFollowUpSymptom };
}

/**
 * Returns an action
 *
 * @param {BomFollowUpSymptomUpdatedAction} bomFollowUpSymptom - value of the action
 */
export function bomFollowUpSymptomUpdatedAction(
    bomFollowUpSymptom: BomFollowUpSymptomUpdatedAction["value"]
): BomFollowUpSymptomUpdatedAction {
    return { type: BomFollowUpSymptomActionType.UPDATE_BOM_FOLLOW_UP_SYMPTOM, value: bomFollowUpSymptom };
}
