import * as React from "react";
import { Typography, FormControl, Select, Input, MenuItem, Checkbox, ListItemIcon } from "@material-ui/core";
import EndIcon from "@material-ui/icons/NotInterested";
import { secondaryPalette, KortexTextField, primaryPalette, theme } from "@aos/react-components";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { ReworkStatusEnum, ReworkYieldType } from "@kortex/aos-common";
import { useEffect } from "react";

import { useEntitiesReworkItemStatus } from "../../../redux/effects";
import KortexSearchField from "../../core/KortexSearchField";
import { useTranslate } from "../../../hooks/useTranslate";

export interface IFilters {
    plainText: string;
    ticketStatuses: string[];
    itemStatuses: number[];
    reference: string;
    createdDateFrom: string;
    createdDateTo: string;
    showScheduled: boolean;
    showArchived: boolean;
    yieldType: ReworkYieldType[];
}

// Used to indicate which filters to display
export interface IEnabledFilters {
    plainText?: boolean;
    ticketStatuses?: boolean;
    itemStatuses?: boolean;
    reference?: boolean;
    createdDate?: boolean;
    showScheduled?: boolean;
    showArchived?: boolean;
    yieldType?: boolean;
}

export const defaultFilters: IFilters = {
    plainText: "",
    ticketStatuses: [],
    itemStatuses: [],
    reference: "",
    createdDateFrom: "",
    createdDateTo: "",
    showScheduled: true,
    showArchived: false,
    yieldType: [],
};

const useStyles = makeStyles({
    searchBox: {
        width: "100%",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    filterInput: {
        flex: 2,
    },
    filterDateInput: {
        flex: 1,
    },
    clearFilterLabel: {
        color: secondaryPalette[500],
        "&:hover": {
            color: primaryPalette[500],
        },
    },
    endIcon: {
        color: theme.palette.grey[400],
        paddingLeft: "10px",
    },
});

interface IOwnProps {
    onFiltersChange: (filters: IFilters) => void;

    // Here, the clear filter is used to indicate the search bar to clear the filter and then update the parent with the
    // new filter sets... It is the reponsability of the parent to clear the boolean after.  This is not recommended but
    // it is the only clean was found to get this functionnality
    clearFilters: boolean;
}

export default function FailureTicketSearchBar(props: IOwnProps): JSX.Element {
    const { onFiltersChange, clearFilters } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const allReworkItemStatus = useEntitiesReworkItemStatus();

    const [filters, setFilters] = useState<IFilters>(defaultFilters);

    const defaultEnabledFilters: IEnabledFilters = {
        plainText: true,
        ticketStatuses: true,
        itemStatuses: true,
        reference: true,
        createdDate: true,
        showScheduled: true,
        showArchived: true,
        yieldType: true,
    };

    /**
     * When filters change, execute onChange cb
     */
    useEffect((): void => {
        onFiltersChange(filters);
    }, [filters]);

    /**
     * When filters change, execute onChange cb
     */
    useEffect((): void => {
        if (clearFilters === true) {
            setFilters(defaultFilters); // Clear filters
        }
    }, [clearFilters]);

    /**
     * Called when the inner filters change
     *
     * @param {string} key - indicates which filter changed
     */
    const handleFilterChange =
        (key: keyof IFilters): ((e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
            setFilters({ ...filters, [key]: e.target.value });
        };

    /**
     * Called when filters change. Checks if filters equal their default value (plainText is excluded from check)
     */
    const isFilterActive = (): boolean => {
        let active = false;
        Object.keys(filters).forEach((key): void => {
            if (key !== "plainText" && filters[key] !== defaultFilters[key]) {
                active = true;
            }
        });
        return active;
    };

    /**
     * Called when clicking the Clear Filter label
     */
    const handleClearFilters = (): void => {
        setFilters(defaultFilters);
    };

    /**
     * Called when the text in the searchbar changes
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        setFilters({ ...filters, plainText: val });
    };

    /**
     * Called when the Show Scheduled button is toggled
     */
    const handleToggleScheduled = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, showScheduled: e.target.checked });
    };

    /**
     * Called when the Show Archived button is toggled
     */
    const handleToggleArchived = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, showArchived: e.target.checked });
    };

    return (
        <KortexSearchField
            className={classes.searchBox}
            onChange={handleSearchTextChange}
            isFilterActive={isFilterActive()}
            clearInput={clearFilters}
        >
            <React.Fragment>
                <div id={"filterViewId"}>
                    {defaultEnabledFilters.ticketStatuses && (
                        <div className={classes.optionsItem}>
                            <Typography className={classes.filterLabel}>{translate("rework.reworkStatus")}</Typography>
                            <FormControl className={classes.filterInput} id={"ticketStatusId"}>
                                <Select
                                    multiple={true}
                                    input={<Input id="select-multiple-chip" />}
                                    onChange={handleFilterChange("ticketStatuses")}
                                    value={filters.ticketStatuses}
                                >
                                    {Object.values(ReworkStatusEnum).map((key: ReworkStatusEnum): JSX.Element => {
                                        return (
                                            <MenuItem key={key} value={key}>
                                                {translate(`rework.status.${key}`)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {defaultEnabledFilters.itemStatuses && (
                        <div className={classes.optionsItem}>
                            <Typography className={classes.filterLabel}>{translate("rework.reworkItemStatus")}</Typography>
                            <FormControl className={classes.filterInput}>
                                <Select
                                    multiple={true}
                                    input={<Input id="select-multiple-chip" />}
                                    onChange={handleFilterChange("itemStatuses")}
                                    value={filters.itemStatuses}
                                    renderValue={(selected: number[]): JSX.Element => (
                                        <div>
                                            {selected.map((value, index): JSX.Element => {
                                                const status = allReworkItemStatus.find((status) => status.reworkItemStatusId === value);
                                                return (
                                                    <div key={index} className={classes.optionsItem}>
                                                        {status?.title}
                                                        {status?.automaticClose && (
                                                            <ListItemIcon>
                                                                <EndIcon className={classes.endIcon} />
                                                            </ListItemIcon>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                >
                                    {allReworkItemStatus.map((status, index): JSX.Element => {
                                        return (
                                            <MenuItem key={index} value={status.reworkItemStatusId}>
                                                {status.title}
                                                {status.automaticClose && (
                                                    <ListItemIcon>
                                                        <EndIcon className={classes.endIcon} />
                                                    </ListItemIcon>
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {defaultEnabledFilters.yieldType && (
                        <div className={classes.optionsItem}>
                            <Typography className={classes.filterLabel}>{translate("rework.yieldType")}</Typography>
                            <FormControl className={classes.filterInput}>
                                <Select
                                    input={<Input id="select-multiple-chip" />}
                                    multiple={true}
                                    onChange={handleFilterChange("yieldType")}
                                    value={filters.yieldType}
                                >
                                    <MenuItem value={"FPY"}>{translate("rework.FPY")}</MenuItem>
                                    <MenuItem value={"TPY"}>{translate("rework.TPY")}</MenuItem>
                                    <MenuItem value={"RMA"}>{translate("rework.RMA")}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    {defaultEnabledFilters.reference && (
                        <div className={classes.optionsItem}>
                            <Typography className={classes.filterLabel}>{translate("scheduler.referenceId")}</Typography>
                            <KortexTextField
                                className={classes.filterInput}
                                onChange={handleFilterChange("reference")}
                                value={filters.reference}
                                variant={"standard"}
                                type={"text"}
                            />
                        </div>
                    )}
                    {defaultEnabledFilters.createdDate && (
                        <div className={classes.optionsItem}>
                            <Typography className={classes.filterLabel}>{translate("rework.createdOn")}</Typography>
                            <KortexTextField
                                className={classes.filterDateInput}
                                onChange={handleFilterChange("createdDateFrom")}
                                value={filters.createdDateFrom}
                                variant={"standard"}
                                type={"date"}
                                label={translate("rework.from")}
                            />
                            <KortexTextField
                                className={classes.filterDateInput}
                                onChange={handleFilterChange("createdDateTo")}
                                value={filters.createdDateTo}
                                variant={"standard"}
                                type={"date"}
                                label={translate("rework.to")}
                            />
                        </div>
                    )}
                    {defaultEnabledFilters.showScheduled && (
                        <div className={classes.optionsItem}>
                            <Typography className={classes.filterLabel}>{translate("rework.optionShowScheduled")}</Typography>
                            <Checkbox checked={filters.showScheduled} onChange={handleToggleScheduled} />
                        </div>
                    )}
                    {defaultEnabledFilters.showArchived && (
                        <div className={classes.optionsItem}>
                            <Typography className={classes.filterLabel}>{translate("rework.optionShowArchived")}</Typography>
                            <Checkbox checked={filters.showArchived} onChange={handleToggleArchived} id={"checkBoxShowArchiveId"} />
                        </div>
                    )}

                    <div className={classes.optionsItem}>
                        <div className={classes.filterLabel} />
                        <Typography onClick={handleClearFilters} className={classes.clearFilterLabel}>
                            {translate("rework.clearFilters")}
                        </Typography>
                    </div>
                </div>
            </React.Fragment>
        </KortexSearchField>
    );
}
