import { ProcessActionStepFailureTicketFork } from "@kortex/aos-common";
import { Paper, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import VariablePicker from "../../../../../core/VariablePicker/VariablePicker";
import { IActionStepProps } from "../IActionStepProps";

const useStyles = makeStyles({
    root: {
        height: "100%",
        padding: "16px",
        boxSizing: "border-box",
    },
    field: {
        marginBottom: "20px",
        marginLeft: "5px",
        marginRight: "20px",
        width: "400px",
    },
});

export default function ActFailureTicketForkStepEditor(props: IActionStepProps<ProcessActionStepFailureTicketFork>): JSX.Element {
    const { actionStepProps, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [workOrder, setWorkOrder] = useState<string>("");

    /**
     * Updates field when updated in props
     */
    useEffect((): void => {
        if (actionStepProps.config.workOrder !== workOrder) {
            setWorkOrder(actionStepProps.config.workOrder);
        }
    }, [actionStepProps]);

    /**
     * Called when message textfield workOrder is blurred
     *
     * @param {string} workOrder - textfield value
     */
    const handleWorkOrderBlur = (workOrder: string): void => {
        if (workOrder !== actionStepProps.config.workOrder) {
            props.onChanged({
                ...actionStepProps,
                config: {
                    workOrder,
                },
            });
        }
    };

    return (
        <Paper className={classes.root}>
            <VariablePicker
                onBlur={handleWorkOrderBlur}
                KortexTextFieldProps={{ className: classes.field, label: translate("action.failureTicketCreate.workOrder") }}
                userAccessLevel={userAccessLevel}
                value={workOrder}
            />
        </Paper>
    );
}
