import { VariableTypeEnum } from "@aos/variable-manager";
import * as jsonpath from "jsonpath";
import { nanoid } from "nanoid";
import { InterpolationError } from "../interfaces";
import { localeKeys } from "../locales";
import { deepClone } from "./deep-clone";
import { isObject } from "./is-object";
import { REGEX_VARIABLE_IDENTIFIER_WRAPPED, wrapVariableIdentifier } from "./wrap-variable-identifer";
export const VARIABLE_IDENTIFIER_PARENT_OBJECT_REGEX = new RegExp(/(?<=\$\{)[a-zA-Z0-9_][a-zA-Z0-9_\/]*(?=(\.|\[))/);
const TEMPORARY_VARIABLE_IDENTIFIER_PREFIX = "_TEMP_VAR_";
function _generateTemporaryVariableIdentifier() {
    return TEMPORARY_VARIABLE_IDENTIFIER_PREFIX + nanoid().replace(/-/g, "_");
}
function _interpolate(templateString, variables) {
    let templateStringCopy = templateString.slice();
    try {
        for (const variable of variables) {
            const identifier = variable.identifier.replace("/", "\\/");
            templateStringCopy = templateStringCopy.replace(new RegExp(`\\$\\{${identifier}\\}`, "gm"), Array.isArray(variable.value) || typeof variable.value === "object" ? JSON.stringify(variable.value) : `${variable.value}`);
        }
        return templateStringCopy;
    }
    catch (error) {
        throw new InterpolationError(localeKeys.utils.interpolationError, "Interpolation error.", error);
    }
}
export function interpolate(templateString, variables) {
    const variablesCopy = deepClone(variables);
    let result = templateString.slice();
    let wrappedVariableIdentifiers = REGEX_VARIABLE_IDENTIFIER_WRAPPED.exec(result);
    let wrappedLastVariableIdentifier = wrappedVariableIdentifiers?.[wrappedVariableIdentifiers.length - 1];
    while (wrappedLastVariableIdentifier !== undefined) {
        const startStringCopy = result.slice();
        const parentVariableIdentifier = VARIABLE_IDENTIFIER_PARENT_OBJECT_REGEX.exec(wrappedLastVariableIdentifier)?.[0];
        if (parentVariableIdentifier !== undefined && parentVariableIdentifier.length) {
            const jsonPathResult = jsonpath.query(JSON.parse(_interpolate(wrapVariableIdentifier(parentVariableIdentifier), variablesCopy)), wrappedLastVariableIdentifier
                .slice(2, -1)
                .replace(parentVariableIdentifier, "$"))[0];
            if (typeof jsonPathResult === "object") {
                const temporaryVariableIdentifier = _generateTemporaryVariableIdentifier();
                variablesCopy.push({
                    identifier: temporaryVariableIdentifier,
                    schemaKey: "",
                    type: VariableTypeEnum.OBJECT,
                    value: JSON.stringify(jsonPathResult),
                });
                result = result.replace(wrappedLastVariableIdentifier, wrapVariableIdentifier(temporaryVariableIdentifier));
            }
            else {
                result = result.replace(wrappedLastVariableIdentifier, String(jsonPathResult));
            }
        }
        else {
            const interpolationResult = _interpolate(wrappedLastVariableIdentifier, variablesCopy);
            const interpolationResultToObject = interpolate.parse(interpolationResult);
            if (typeof interpolationResultToObject === "object") {
                const temporaryVariableIdentifier = _generateTemporaryVariableIdentifier();
                variablesCopy.push({
                    identifier: temporaryVariableIdentifier,
                    schemaKey: "",
                    type: VariableTypeEnum.OBJECT,
                    value: JSON.stringify(interpolationResultToObject),
                });
                result = result.replace(wrappedLastVariableIdentifier, temporaryVariableIdentifier);
            }
            else {
                result = result.replace(wrappedLastVariableIdentifier, interpolationResult);
                if (result === wrappedLastVariableIdentifier) {
                    throw new InterpolationError(localeKeys.utils.interpolationUnknownVariableError, "Tried to interpolate a variable that does not exist.");
                }
            }
        }
        if (startStringCopy === result) {
            throw new InterpolationError(localeKeys.utils.interpolationUnknownVariableError, "Tried to interpolate an undefined variable.");
        }
        wrappedVariableIdentifiers = REGEX_VARIABLE_IDENTIFIER_WRAPPED.exec(result);
        wrappedLastVariableIdentifier = wrappedVariableIdentifiers?.[wrappedVariableIdentifiers.length - 1];
    }
    const tempVars = variablesCopy.filter((variable) => variable.identifier.startsWith(TEMPORARY_VARIABLE_IDENTIFIER_PREFIX));
    for (const tempVar of tempVars) {
        result = _interpolate(result.replace(tempVar.identifier, wrapVariableIdentifier(tempVar.identifier)), tempVars);
    }
    return result;
}
interpolate.parse = function (value) {
    if (value.trim() === "") {
        return value;
    }
    const startsWithZero = value.startsWith("0");
    const isDigit = value.startsWith("0.");
    if (startsWithZero && !isDigit) {
        return value;
    }
    else {
        if (isNaN(+value)) {
            if (value === "true") {
                return true;
            }
            else if (value === "false") {
                return false;
            }
            try {
                const valueToObject = JSON.parse(value);
                if (isObject(valueToObject)) {
                    return valueToObject;
                }
            }
            catch (_) {
            }
        }
        else {
            return +value;
        }
    }
    return value;
};
