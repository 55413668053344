import { ActionTypeEnum } from "../enums";
export function isSequencerActionGroupConfigCondition(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.CONDITION;
}
export function isSequencerActionGroupConfigFail(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.FAIL;
}
export function isSequencerActionGroupConfigGoto(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.GO_TO;
}
export function isSequencerActionGroupConfigInput(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.INPUT;
}
export function isSequencerActionGroupConfigLoop(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.LOOP;
}
export function isSequencerActionGroupConfigMessage(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.MESSAGE;
}
export function isSequencerActionGroupConfigSet(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.SET;
}
export function isSequencerActionGroupConfigSubProgram(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.SUB_PROGRAM;
}
export function isSequencerActionGroupConfigThread(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.THREAD;
}
export function isSequencerActionGroupConfigTimer(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.TIMER;
}
export function isSequencerActionGroupConfigWaitCondition(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.WAIT_CONDITION;
}
export function isSequencerActionGroupConfigWaitTime(sequencerActionGroup) {
    return sequencerActionGroup.config.type === ActionTypeEnum.WAIT_TIME;
}
