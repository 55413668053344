import { KortexTextField } from "@aos/react-components";
import { IWorkInstructionsFormInputConfig, WorkInstructionsFormErrorKey } from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useState } from "react";
import shortId from "shortid";

import { useTranslate } from "../../../../../../../../../../hooks/useTranslate";

export const TEXTBOX_SPECIFIC_NAME = "workInstructionsFormInputTextboxName";

const useStyles = makeStyles({
    fullWidth: {
        width: "100%",
    },
});

export interface IOwnProps extends IWorkInstructionsFormInputConfig {
    autoFocus?: boolean;
    onEnterPressed?: (id: string) => void;
}

export default function WorkInstructionsFormInputTextbox(props: IOwnProps): JSX.Element {
    const { autoFocus, itemProps, itemState, onEnterPressed } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [id] = useState<string>(shortId.generate());

    const displayedLabel = itemState.label ? itemState.label : itemProps.label;
    const regex = itemState.regex ? new RegExp(itemState.regex) : undefined;
    const regexNoMatch = itemState.regexError ? itemState.regexError : translate("action.workInstructions.form.error.invalidFormat");
    const requiredErrorMessage =
        itemState.errorKey === WorkInstructionsFormErrorKey.REQUIRED ? translate("action.workInstructions.form.error.required") : "";
    const tabIndex = itemProps.tabIndex ? parseInt(itemProps.tabIndex) : 0;

    /**
     * Called when a user interacts with the input
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - change event triggered
     */
    const onStateChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (itemState.errorKey === WorkInstructionsFormErrorKey.REQUIRED) {
            itemState.errorKey = ""; // To clear the "required" error message when user types in the text field
        }

        props.onStateChanged({ ...props.itemState, value: event.target.value });
    };

    /**
     * Called when a user presses a key in the TextField
     */
    const handleKeyDown = (event: React.KeyboardEvent): void => {
        event.stopPropagation();

        if ((event.key === "Enter" || event.key === "Tab") && !itemProps.multiline && onEnterPressed) {
            event.preventDefault();
            onEnterPressed(id);
        }
    };

    return (
        <KortexTextField
            label={displayedLabel}
            onChange={onStateChanged}
            onKeyDown={handleKeyDown}
            value={itemState.value}
            regex={regex}
            standardErrorMsgs={{ regexNoMatch }}
            error={requiredErrorMessage}
            TextFieldProps={{
                required: itemProps.required,
                multiline: itemProps.multiline,
                autoFocus,
                classes: {
                    root: classes.fullWidth,
                },
            }}
            InputProps={{
                inputProps: {
                    id,
                    name: TEXTBOX_SPECIFIC_NAME + shortId(), //Make input name unique to prevent chrome autofill
                    tabIndex,
                },
            }}
        />
    );
}
