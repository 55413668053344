import { SetState } from "@kortex/aos-common";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    outputContainer: {
        height: "-webkit-fill-available",
        padding: "32px",
        width: "-webkit-fill-available",
    },
    outputText: {
        marginBottom: "32px",
        textAlign: "center",
    },
    outputTitle: {
        marginBottom: "32px",
    },
});

export interface IOwnProps {
    actionState: SetState;
}

export default function SetPlayer(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <div className={classes.outputContainer}>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.setPlayer.tilte")}
            </Typography>
        </div>
    );
}
