import { KortexDialogConfirmation } from "@aos/react-components";
import { IUserDbModel, ProcessApprovalRightsEnum, ProcessUiModel, TaskFactory, UserDbModel } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import React, { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesUsersRoles } from "../../../../../redux/effects";
import { taskInsert } from "../../../../../redux/task-manager/task-thunks-task";

import UserSelector from "./UserSelector";

interface IOwnProps {
    onCancel: () => void;
    onConfirm: () => void;
    opened: boolean;
    processId: ProcessUiModel["processId"];
    referenceId: string;
}

export default function ProcessApprovalReviewRequestDialog(props: IOwnProps): JSX.Element {
    const { onCancel, onConfirm, opened, processId, referenceId } = props;

    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const roles = useEntitiesUsersRoles();

    const [selectedUsers, setSelectedUsers] = useState<UserDbModel[]>([]);

    const confirmDisabled = !Boolean(selectedUsers.length);

    /**
     * When Cancel button is clicked, call onCancel
     */
    const handleCancel = (): void => {
        onCancel();
    };

    /**
     * When Confirm button is clicked, call onConfirm
     */
    const handleConfirm = (): void => {
        if (!confirmDisabled) {
            dispatch(
                taskInsert(
                    selectedUsers.map((user) => {
                        const task = TaskFactory.createProcessReview({
                            info: { reviewerId: user.userId },
                            linkId: processId,
                            referenceId: referenceId,
                        });
                        return task.model;
                    })
                )
            );
            onConfirm();
        }
    };

    /**
     * Reset state when dialog is closed
     */
    useEffect((): void => {
        if (!opened) {
            setSelectedUsers([]);
        }
    }, [opened]);

    /**
     * User filter to get only those that have reviewer role
     */
    const userFilter =
        () =>
        (user: IUserDbModel): boolean => {
            // Check each user roles to find if one of the role includes process review
            for (const roleId of user.roles) {
                // Get role
                const findRole = roles.find((role) => role.userRoleId === roleId);
                if (findRole) {
                    // Check if reviewer minimal rights
                    if (findRole.processApproval >= ProcessApprovalRightsEnum.READ) {
                        return true;
                    }
                }
            }
            return false;
        };
    /**
     * Handles the user selection
     *
     * @param {number[]} selectedUsers - Selected User array
     */
    const handleUserSelect = (selectedUsers: UserDbModel[]): void => {
        setSelectedUsers(selectedUsers);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={confirmDisabled}
            dialogProps={{
                id: "processVersioningRequestReviewDialogId",
                maxWidth: "md",
            }}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("process.versioning.reviewRequestCancel"),
                proceedButtonLabel: translate("process.versioning.reviewRequestConfirm"),
                titleLabel: translate("process.versioning.reviewRequest"),
            }}
        >
            <UserSelector onUserSelect={handleUserSelect} userFilter={userFilter} />
        </KortexDialogConfirmation>
    );
}
