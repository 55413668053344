import { KortexTextField, primaryPalette, secondaryPalette } from "@aos/react-components";
import {
    ITrainingCertificationPending,
    ITrainingPending,
    TrainingCertificationPendingGetAllFilters,
    TrainingPendingGetAllFilters,
} from "@kortex/aos-common";
import KortexSearchField from "@kortex/aos-ui/components/core/KortexSearchField";
import { UseListObserver } from "@kortex/aos-ui/hooks/useListObserver";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";

const useStyles = makeStyles({
    clearFilterLabel: {
        color: secondaryPalette[500],
        "&:hover": {
            color: primaryPalette[500],
        },
    },
    filterInput: {
        flex: 2,
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    searchBox: {
        width: "100%",
    },
    filterDateInput: {
        flex: 1,
    },
});

interface IOwnProps {
    titleTrainerFilter: string;
    useContext: () =>
        | UseListObserver<ITrainingPending, TrainingPendingGetAllFilters>
        | UseListObserver<ITrainingCertificationPending, TrainingCertificationPendingGetAllFilters>;
}

const TrainingCertificateSearchBar: FC<IOwnProps> = (props) => {
    const { titleTrainerFilter, useContext } = props;
    const classes = useStyles();
    const translate = useTranslate();

    const { filters, setFilters } = useContext();

    /**
     * Called when the inner filters change
     *
     * @param {string} key - indicates which filter changed
     */
    const handleFilterChange =
        (
            key: keyof TrainingPendingGetAllFilters
        ): ((e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
            const newFilters = deepClone(filters);
            setFilters({
                ...newFilters,
                [key]: e.target.value,
            });
        };

    /**
     * Called when the text in the searchbar changes
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        const newFilters = deepClone(filters);
        setFilters({ ...newFilters, userName: val });
    };

    /**
     * Called when clicking the Clear Filter label
     */
    const handleClearFilters = (): void => {
        setFilters({});
    };

    return (
        <KortexSearchField
            className={classes.searchBox}
            InputProps={{ id: "trainingCertificateSearchBarId" }}
            onChange={handleSearchTextChange}
            hideFiltersButton={false}
            placeholder={translate("general.searchUser")}
            isFilterActive={true}
        >
            <React.Fragment>
                {/* PROCESS NAME */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("general.process")}</Typography>
                    <KortexTextField
                        className={classes.filterInput}
                        onChange={handleFilterChange("processName")}
                        value={filters.processName ?? ""}
                        variant={"standard"}
                        type={"text"}
                    />
                </div>

                {/* TRAINER OR AUDITOR NAME */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{titleTrainerFilter}</Typography>
                    <KortexTextField
                        className={classes.filterInput}
                        onChange={handleFilterChange("trainerName")}
                        value={filters.trainerName ?? ""}
                        variant={"standard"}
                        type={"text"}
                    />
                </div>

                {/* REFERENCE */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("training.trainingCertificateReference")}</Typography>
                    <KortexTextField
                        className={classes.filterInput}
                        onChange={handleFilterChange("reference")}
                        value={filters.reference ?? ""}
                        variant={"standard"}
                        type={"text"}
                    />
                </div>

                {/* DATE */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("general.date")}</Typography>
                    <KortexTextField
                        className={classes.filterDateInput}
                        onChange={handleFilterChange("dateFrom")}
                        value={filters.dateFrom ?? ""}
                        variant={"standard"}
                        type={"date"}
                        label={translate("general.from")}
                    />
                    <KortexTextField
                        className={classes.filterDateInput}
                        onChange={handleFilterChange("dateTo")}
                        value={filters.dateTo ?? ""}
                        variant={"standard"}
                        type={"date"}
                        label={translate("general.to")}
                    />
                </div>

                <div className={classes.optionsItem}>
                    <div className={classes.filterLabel} />
                    <Typography onClick={handleClearFilters} className={classes.clearFilterLabel}>
                        {translate("scheduler.clearFilters")}
                    </Typography>
                </div>
            </React.Fragment>
        </KortexSearchField>
    );
};

export default TrainingCertificateSearchBar;
