// krc
import { IReworkUiModel, ITokenDecoded, JobStatusEnum } from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core/styles";
// react
import * as React from "react";
import { useEffect, useState } from "react";

import FailureTicketDetailsButtons from "./FailureTicketDetailsButtons";
import FailureTicketDetailsInfos from "./FailureTicketDetailsInfos";
import FailureTicketJobProcess from "./FailureTicketJobProcess/FailureTicketJobProcess";
import { FailureTicketDetailsRootCauseSelector } from "./RootCauseSelector";
import FailureTicketDetailsWorkLog from "./WorkLog/FailureTicketDetailsWorkLog";
import { FailureTicketDetailsYieldSelector } from "./YieldSelector";

const useStyles = makeStyles({
    detailContent: {
        display: "flex",
        flexDirection: "row",
        padding: "5px 0",
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
    },
    selectorContainer: {
        display: "flex",
    },
});

interface IOwnProps {
    rework: IReworkUiModel;
    userInfo?: ITokenDecoded;

    onDelete: (rework: IReworkUiModel) => void;
    onClose: () => void;
}

export default function FailureTicketDetails(props: IOwnProps): JSX.Element {
    const { rework, userInfo } = props;
    const classes = useStyles();

    const [activeJobIdOfFailureTicket, setActiveJobIdOfFailureTicket] = useState<number>(0);

    useEffect(() => {
        if (rework.jobRework && rework.jobRework.status !== (JobStatusEnum.DRAFT || JobStatusEnum.READY || JobStatusEnum.IN_PROGRESS)) {
            setActiveJobIdOfFailureTicket(rework.reworkJobId);
        } else if (
            rework.jobOfFailure &&
            rework.jobOfFailure.status !== (JobStatusEnum.DRAFT || JobStatusEnum.READY || JobStatusEnum.IN_PROGRESS)
        ) {
            setActiveJobIdOfFailureTicket(rework.originalJobId);
        } else {
            setActiveJobIdOfFailureTicket(0);
        }
    }, [rework]);

    /**
     * toggle the dialog
     */
    const toggleDialog = (): void => {
        props.onClose();
    };

    /**
     * handles the delete rework action
     */
    const handleDeleteRework = (): void => {
        props.onDelete(rework);
    };

    return (
        <div>
            {/* Ticket Details */}
            <div className={classes.detailContent} id="detailContentId">
                {/* Failure Ticket Details Infos */}
                <FailureTicketDetailsInfos rework={rework} />
                {/* Failure Ticket Buttons */}
                <FailureTicketDetailsButtons rework={rework} onClose={toggleDialog} onDelete={handleDeleteRework} userInfo={userInfo} />
            </div>
            <div className={classes.selectorContainer} id="failureTicketDetailsSelectors">
                {/* Yield */}
                <FailureTicketDetailsYieldSelector rework={rework} userInfo={userInfo} />
                {/* Root Cause */}
                <FailureTicketDetailsRootCauseSelector rework={rework} userInfo={userInfo} />
            </div>
            {/* Work Logs */}
            <FailureTicketDetailsWorkLog rework={rework} userInfo={userInfo} />
            {/* Rework Job Process */}
            {activeJobIdOfFailureTicket > 0 && (
                <FailureTicketJobProcess rework={rework} userInfo={userInfo} activeJobId={activeJobIdOfFailureTicket} />
            )}
        </div>
    );
}
