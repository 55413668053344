import { getDateStr, ReworkStatusEnum, ITokenDecoded, IReworkUiModel, JobReworkInfo } from "@kortex/aos-common";
import { greyPalette, rainbowPalette, theme, warningPalette } from "@aos/react-components";
import { Grid, IconButton } from "@material-ui/core";
import ArchiveIcon from "@material-ui/icons/Archive";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import { makeStyles } from "@material-ui/core/styles";
import BuildIcon from "@material-ui/icons/BuildOutlined";
import React, { useEffect, useState } from "react";
import { deepClone } from "@kortex/utilities";

import { useStoreState } from "../../../hooks/useStoreState";
import { userCanArchive } from "../../../utilitites/IUserRights";
import KortexPanelCard from "../../core/KortexPanelCard";
import { useTranslate } from "../../../hooks/useTranslate";
import KortexLabelText from "../../core/KortexLabelText";
import { reworkUpdate } from "../../../redux/rework-manager/rework-thunks";

const useStyles = makeStyles({
    root: {
        paddingTop: "5px",
    },
    processPanelCardAllContainer: {
        display: "flex",
    },
    processPanelCardContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    iconContainer: {
        color: greyPalette[500],
    },
    text: {
        display: "flex",
        width: "150px",
    },
    groupInfo: {
        margin: "0px 5px 0 5px",
        rowGap: "8px",
    },
    jobIcon: {
        width: "16px",
        height: "16px",
        color: theme.palette.grey[500],
    },
    archiveButton: {
        display: "flex",
        flexDirection: "column",
    },
});

interface IOwnProps {
    index: number;
    isSelected: boolean;
    rework: IReworkUiModel;
    userInfo?: ITokenDecoded;

    onSelection?: (rework: IReworkUiModel) => void;
}

export default function FailureTicketCard(props: IOwnProps): JSX.Element {
    const { index, userInfo } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [rework, setRework] = useStoreState(props.rework, reworkUpdate, { debouncingTimeInMs: 1000 });
    const [jobInfos, setJobInfos] = useState<JobReworkInfo | undefined>(undefined);

    const ref = React.createRef<HTMLDivElement>();

    /**
     * Scroll into view when card is selected
     */
    useEffect(() => {
        if (props?.isSelected) {
            const position = ref?.current?.getBoundingClientRect() as DOMRect;
            if (position?.top > innerHeight - 100) {
                ref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            } else if (position.bottom - 200 < 0) {
                ref?.current?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                });
            }
        }
    }, [props?.isSelected]);

    useEffect(() => {
        setJobInfos(rework.jobRework ? rework.jobRework : rework.jobOfFailure ? rework.jobOfFailure : undefined);
    }, [rework]);

    /**
     * Handle selection of the rework
     *
     * @param {IReworkUiModel} rework - rework selected
     */
    const handleSelectRework =
        (rework: IReworkUiModel): (() => void) =>
        (): void => {
            if (props.onSelection) {
                props.onSelection(rework);
            }
        };

    /**
     * get the rework status color
     *
     * @param {ReworkStatusEnum} status - rework status
     */
    const getReworkStatusColor = (status: ReworkStatusEnum): string => {
        switch (status) {
            case ReworkStatusEnum.NEW:
            case ReworkStatusEnum.SCHE:
                return rainbowPalette[300]; // Blue
            case ReworkStatusEnum.INPRG:
                return rainbowPalette[400]; // Blue Cyan 400
            case ReworkStatusEnum.HOLD:
                return warningPalette[600]; // Orange
            case ReworkStatusEnum.DONE:
                return rainbowPalette[600]; // Green
            case ReworkStatusEnum.ARCH:
                return rainbowPalette[601]; // Dark Blue 400
            default:
                return theme.palette.common.black; // Black
        }
    };

    /**
     * handle archive failure ticket
     */
    const handleArchiveCard = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.stopPropagation();
        const curStatus = rework.reworkStatus;
        if (curStatus === ReworkStatusEnum.DONE || curStatus === ReworkStatusEnum.ARCH) {
            const newReworkModel: IReworkUiModel = deepClone(rework);
            newReworkModel.reworkStatus = curStatus === ReworkStatusEnum.DONE ? ReworkStatusEnum.ARCH : ReworkStatusEnum.DONE;
            setRework(newReworkModel);
        }
    };

    return (
        <React.Fragment>
            {rework && (
                <div className={classes.root}>
                    <KortexPanelCard
                        key={index}
                        isSelected={props.isSelected}
                        spaceBetweenCard={1}
                        padding={10}
                        variant="none"
                        onSelect={handleSelectRework(rework)}
                        statusColor={getReworkStatusColor(rework.reworkStatus)}
                    >
                        <React.Fragment>
                            <div ref={ref} className={classes.processPanelCardAllContainer} id={`FailureTicketCard${index}Id`}>
                                <BuildIcon className={classes.iconContainer} />
                                <div className={classes.processPanelCardContainer}>
                                    <Grid container={true} className={classes.groupInfo}>
                                        {/* FAILURE TICKET NUMBER */}
                                        <Grid item={true}>
                                            <KortexLabelText
                                                label={translate("rework.failureTicketNumber")}
                                                text={rework.reworkId.toString()}
                                                className={classes.text}
                                                id={`numberFailureTicket${index}Id`}
                                            />
                                        </Grid>
                                        {/* FAILURE TICKET STATUS */}
                                        <Grid item={true}>
                                            <KortexLabelText
                                                label={translate("rework.reworkStatus")}
                                                text={translate(`rework.status.${ReworkStatusEnum[rework.reworkStatus]}`)}
                                                className={classes.text}
                                                id={`statusFailureTicket${index}Id`}
                                            />
                                        </Grid>
                                        {/* ITEM STATUS */}
                                        <Grid item={true}>
                                            <KortexLabelText
                                                label={translate("rework.reworkItemStatus")}
                                                text={rework.reworkItemStatus.title}
                                                className={classes.text}
                                                id={`itemStatusFailureTicket${index}Id`}
                                            />
                                        </Grid>
                                        {/* TRACKING ID */}
                                        <Grid item={true}>
                                            <KortexLabelText
                                                label={translate("rework.trackingId")}
                                                text={rework.trackingId ? rework.trackingId : ""}
                                                className={classes.text}
                                                id={`trackingIdFailureTicket${index}Id`}
                                            />
                                        </Grid>
                                        {/* PART NUMBER */}
                                        <Grid item={true}>
                                            <KortexLabelText
                                                label={translate("rework.partNumber")}
                                                text={(rework.partNumber || jobInfos?.partNumber) ?? translate("general.na")}
                                                className={classes.text}
                                                id={`partNumberFailureTicket${index}Id`}
                                            />
                                        </Grid>
                                        {/* FAILURE TICKET JOB */}
                                        <Grid item={true}>
                                            <KortexLabelText
                                                label={translate("rework.scheduledJobRefId")}
                                                text={jobInfos?.jobRefId ?? translate("general.na")}
                                                className={classes.text}
                                                id={`jobFailureTicket${index}Id`}
                                            />
                                        </Grid>
                                        {/* CREATE ON */}
                                        <Grid item={true}>
                                            <KortexLabelText
                                                label={translate("rework.date")}
                                                text={getDateStr(rework.createdOn)}
                                                className={classes.text}
                                                id={`dateFailureTicket${index}Id`}
                                            />
                                        </Grid>
                                        {/* QTY ITEM FAIL */}
                                        <Grid item={true}>
                                            <KortexLabelText
                                                label={translate("rework.quantity")}
                                                text={`${rework.quantity}`}
                                                className={classes.text}
                                                id={`quantityFailureTicket${index}Id`}
                                            />
                                        </Grid>
                                        {/* TRACKING INSTANCE */}
                                        <Grid item={true}>
                                            <KortexLabelText
                                                label={translate("rework.trackingInstance")}
                                                text={rework.trackingInstances}
                                                className={classes.text}
                                                id={`trackingInstanceFailureTicket${index}Id`}
                                            />
                                        </Grid>
                                        {/* JOB REFENCE */}
                                        <Grid item={true}>
                                            <KortexLabelText
                                                label={translate("scheduler.referenceId")}
                                                text={jobInfos?.referenceId ?? translate("general.na")}
                                                className={classes.text}
                                                id={`jobReferenceFailureTicket${index}Id`}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={classes.archiveButton}>
                                    {((userCanArchive(userInfo?.roleRights.rework) && rework.reworkStatus === ReworkStatusEnum.DONE) ||
                                        rework.reworkStatus === ReworkStatusEnum.ARCH) && (
                                        <IconButton onClick={handleArchiveCard} id={"buttonArchiveFailureTicketId"}>
                                            {rework.reworkStatus === ReworkStatusEnum.DONE && <ArchiveIcon />}
                                            {rework.reworkStatus === ReworkStatusEnum.ARCH && <UnarchiveIcon />}
                                        </IconButton>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    </KortexPanelCard>
                    {/* ARCHIVE BUTTON */}
                </div>
            )}
        </React.Fragment>
    );
}
