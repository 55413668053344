import { ActionTypeEnum, SequencerActionPlayCompletedPayloadTypeEnum, SequencerActionStatusEnum } from "../../enums";
import { CoreActionConfigError } from "../../interfaces";
import { localeKeys } from "../../locales";
import { KortexSequencerDebugger, deepClone, isObject, isString } from "../../utilities";
import { interpolateActionConfig } from "../../utilities/interpolate-action-config";
const debug = KortexSequencerDebugger.core.message;
export const SEQUENCER_ACTION_MESSAGE_DEFAULT_STATE = {};
function create({ variableManager, variableNamespace }) {
    let _stateChanged = async () => void 0;
    function assertValidConfig(config) {
        debug("config validation:%o", config);
        if (!isString(config.message) || config.message.trim() === "") {
            debug("config validation:error:message is either undefined or empty");
            throw new CoreActionConfigError(localeKeys.action.core.message.validateInvalidMessage, "Config validation error: message is either undefined or empty.");
        }
        debug("config validation:successful");
    }
    async function onStateChange(cb) {
        debug("on state change:callback saved");
        _stateChanged = cb;
    }
    async function play(config) {
        debug("play", config);
        const interpolatedConfig = interpolateActionConfig(config, variableManager.getAllVars(variableNamespace));
        await _stateChanged({
            previousState: {},
            state: {},
            feedback: {
                level: interpolatedConfig.level,
                message: isObject(interpolatedConfig.message)
                    ? JSON.stringify(interpolatedConfig.message)
                    : String(interpolatedConfig.message),
            },
        });
        return {
            payload: {
                output: null,
                type: SequencerActionPlayCompletedPayloadTypeEnum.NEXT,
            },
            status: SequencerActionStatusEnum.COMPLETED,
        };
    }
    async function stop() {
        debug("stop");
    }
    return {
        get state() {
            return deepClone(SEQUENCER_ACTION_MESSAGE_DEFAULT_STATE);
        },
        get type() {
            return ActionTypeEnum.MESSAGE;
        },
        assertValidConfig,
        onStateChange,
        play,
        stop,
    };
}
export const SequencerActionMessageFactory = {
    create,
};
