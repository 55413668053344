import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceRequestWrapped,
    TrainingReadingCertificateGetPendingsReq,
    TrainingReadingCertificateGetPendingsRes,
    TrainingReadingCertificateSignReq,
    TrainingReadingCertificateSignRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { trainingReadingCertificateGetPendings } from "./getPendings";
import { trainingReadingCertificateSign } from "./sign";

interface IAOSClientServiceWrappedUserTraining {
    // TRAINING READING CERTIFICATE
    getPendings: AOSClientServiceRequestWrapped<TrainingReadingCertificateGetPendingsReq, TrainingReadingCertificateGetPendingsRes>;
    sign: AOSClientServiceRequestWrapped<TrainingReadingCertificateSignReq, TrainingReadingCertificateSignRes>;
}

type AOSClientServiceWrappedUserTraining = IAOSClientServiceWrappedUserTraining;

// ADD_API_CALL Training Reading Certificate
export function generateTrainingReadingCertificateServiceClient(
    router: IRouterClient,
    _: EventEmitter
): AOSClientServiceWrappedUserTraining {
    return {
        // TRAINING READING CERTIFICATE
        getPendings: AOSClientServiceWrapperUI.request(trainingReadingCertificateGetPendings(router)),
        sign: AOSClientServiceWrapperUI.request(trainingReadingCertificateSign(router)),
    };
}

generateTrainingReadingCertificateServiceClient.serviceId = 137;
