import { EnumUserStatus } from "@kortex/aos-common";

import { useTranslate } from "../hooks/useTranslate";

/**
 * Formats the user's status into a friendly, displayabled and translated string
 *
 * @param {EnumUserStatus} status - status enum
 */
export function formatUserStatus(status: EnumUserStatus, translate: ReturnType<typeof useTranslate>): string {
    switch (status) {
        case EnumUserStatus.ACTIVE:
            return translate("settings.userSettings.status.active");
        case EnumUserStatus.ARCHIVED:
            return translate("settings.userSettings.status.archived");
        case EnumUserStatus.DISABLED:
            return translate("settings.userSettings.status.disabled");
        default:
            console.error("Unknown user status received");
            return "";
    }
}
