import { greyPalette, secondaryPalette, theme } from "@aos/react-components";
import { IGetAllReworksRes, IReworkInfo, IReworkUiModel, IReworksFiltersOptions, IStepFailure, areSimilar } from "@kortex/aos-common";
import { useKeybind } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { reworkDelete, reworkGetAll, reworkInsert } from "@kortex/aos-ui/redux/rework-manager/rework-thunks";
import { userCanInsert } from "@kortex/aos-ui/utilitites/IUserRights";
import { CircularProgress, Fab, Paper, Typography, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import * as React from "react";
import { useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { InView } from "react-intersection-observer"; // TODO: Replace InView by useInView

import { useTranslate } from "../../../hooks/useTranslate";
import { useEntitiesReworks } from "../../../redux/effects";
import { useSelectorUserSession } from "../../../redux/selectors";
import FailedDialog from "../../core/ProcessPlayer/Dialogs/FailedDialog/FailedDialog";

import FailureTicketCard from "./FailureTicketCard";
import FailureTicketDetails from "./FailureTicketDetails/FailureTicketDetails";
import FailureTicketSearchBar, { IFilters, defaultFilters } from "./FailureTicketSearchBar";
import { applyFilters, convertUiFiltersToBackendFilters, verifyIfFiltersAreEmpty } from "./utilities/utilities";

const useStyles = makeStyles({
    mainContainer: {
        height: "calc(100% - 16px)",
        display: "flex",
        flexDirection: "row",
    },
    pageContainer: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 84px)", // Header (64px) + margins (20px)
        width: "100%",
        margin: "10px",
        position: "relative",
    },
    cardSpacer: {
        flexGrow: 2,
    },
    cardFooter: {
        backgroundColor: greyPalette[200],
        color: greyPalette[600],
        borderTop: "solid 1px",
        borderTopColor: greyPalette[400],
        flexFlow: "row",
        flexDirection: "row",
        display: "flex",
        padding: "0px",
    },
    menuItem: {
        width: "68px",
    },
    processPage: {
        margin: "0px",
        borderRadius: "0px",
        overflow: "auto",
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },
    endBox: {
        width: "100%",
        minHeight: "5px",
        borderBottom: "1px",
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        borderTop: "0px",
    },
    searchContainer: {
        justifyItems: "center",
        marginBottom: "3px",
        padding: "10px",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    speedDial: {
        position: "fixed",
        bottom: "20px",
        right: "20px",
    },
    dialogContent: {
        height: "60vh",
        width: "80vw",
        maxWidth: "1102px",
    },
    fab: {
        position: "absolute",
        bottom: "30px",
        right: "30px",
    },
    selectedPage: {
        margin: "10px",
        padding: "15px",
        borderRadius: "0px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 110px)",
        width: "100%",
    },
    containerListFeedback: {
        width: "100%",
        height: "150px",
        marginTop: "10px",
    },
    loadingUserFeedback: {
        color: secondaryPalette[500],
        textAlign: "center",
    },
    intersectionObserver: {
        width: "100%",
    },
});

const REWORK_REQUEST_LIMIT = 20;
const NUMBER_OF_ITEM_BEFORE_NEXT_FETCH = REWORK_REQUEST_LIMIT / 4;

export default function FailureTicketPage(): JSX.Element {
    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const userInfo = useSelectorUserSession();

    const [filters, setFilters] = useState<IFilters>(defaultFilters);
    const failureTicketsFromStore = useEntitiesReworks(
        false,
        (value) => downloadedEndCallback && downloadedEndCallback(value),
        REWORK_REQUEST_LIMIT,
        0,
        {
            optimizedQuery: true,
            omitArchived: !filters.showArchived,
        }
    );
    const [failureTickets, setFailureTickets] = useState<IReworkUiModel[]>(failureTicketsFromStore);
    const [filtersClear, clearFilters] = useState<boolean>(false);
    const [selectedFailureTicketInfo, setSelectedFailureTicketInfo] = useState<IReworkUiModel | undefined>();
    const [selectedNewFailureTicketInfo, setSelectedNewFailureTicketInfo] = useState<IReworkUiModel | undefined>();

    const [hasNext, setHasNext] = useState<boolean>(true);
    const [fetchOffset, setFetchOffset] = useState<number>(0);
    const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
    const [failedDialogOpened, setFailedDialogOpened] = useState<boolean>(false);

    /**
     * Key down event - Arrow down
     * Select the previous ticket
     */
    useKeybind(
        "ArrowDown",
        () => {
            if (selectedFailureTicketInfo) {
                const index = failureTickets.findIndex((job) => job.reworkId === selectedFailureTicketInfo?.reworkId);

                if (index >= 0 && index < failureTickets.length) {
                    setSelectedFailureTicketInfo(failureTickets[index + 1]);
                }
            } else {
                setSelectedFailureTicketInfo(failureTickets[0]);
            }
        },
        { preventDefault: true, stopPropagation: true }
    );

    /**
     * Key down event - Enter
     * Select the next ticket
     */
    useKeybind(
        "ArrowUp",
        () => {
            if (selectedFailureTicketInfo) {
                const index = failureTickets.findIndex((job) => job.reworkId === selectedFailureTicketInfo?.reworkId);

                if (index > 0) {
                    setSelectedFailureTicketInfo(failureTickets[index - 1]);
                }
            } else {
                setSelectedFailureTicketInfo(failureTickets[0]);
            }
        },
        { preventDefault: true, stopPropagation: true }
    );

    useEffect((): void => {
        const orderedAndFilteredFailureTicketList = getFailureTicketsFiltered(failureTicketsFromStore);
        setFailureTickets(orderedAndFilteredFailureTicketList);
    }, [failureTicketsFromStore]);

    useEffect((): void => {
        if (selectedNewFailureTicketInfo) {
            setSelectedNewFailureTicketInfo(undefined);
        }
    }, [selectedNewFailureTicketInfo]);

    /**
     * When filters FT changed, refresh it
     */
    useEffect((): void => {
        if (selectedNewFailureTicketInfo) {
            setSelectedFailureTicketInfo(failureTickets.find((ft) => ft.reworkId === selectedNewFailureTicketInfo.reworkId));
        } else {
            // Verify that the item is still there, otherwise, close the panel
            setSelectedFailureTicketInfo(failureTickets.find((ft) => ft.reworkId === selectedFailureTicketInfo?.reworkId));
        }
    }, [failureTickets]);

    const downloadedEndCallback = (value: Readonly<IGetAllReworksRes>): void => {
        // Compute has next?
        setHasNext((value.chunkSize as number) >= REWORK_REQUEST_LIMIT);
        setFetchOffset(value.nextCursor as number);

        // Verify is there is no value returned
        if (value.chunkSize === 0 && value.prevCursor === 0) {
            setFailureTickets([]);
        }

        // Verify is there is only one value returned. If so auto-select it.
        if (value.chunkSize === 1 && value.prevCursor === 0) {
            setSelectedFailureTicketInfo(value.data[0]);
        }

        setDataIsLoading(false);
    };

    const getFailureTicketsFiltered = (failureTicketList: IReworkUiModel[]): IReworkUiModel[] => {
        return failureTicketList.filter((failureTicket) => applyFilters(failureTicket, filters));
    };

    /**
     * Handle called when the user select a failure ticket
     *
     * @param {IReworkUiModel} reworkInfo - Selected process
     */
    const handleSelectFailureTicket = (reworkInfo: IReworkUiModel): void => {
        setSelectedFailureTicketInfo(reworkInfo);
    };

    /**
     * Handle called when the user unselect failure ticket
     */
    const handleClose = (): void => {
        setSelectedFailureTicketInfo(undefined);
    };

    /**
     * Called when the filters change. Sets local filters state
     *
     * @param {IFilters} newFilters - newly set filters
     */
    const handleFiltersChange = (newFilters: IFilters): void => {
        // verify if filters has changed
        if (areSimilar(filters, newFilters)) return;

        setDataIsLoading(true);
        setFailureTickets([]);
        getFilteredReworksFromBackEnd(newFilters);
        setFilters(newFilters);
    };

    const getFilteredReworksFromBackEnd = (newFilters: IFilters): void => {
        // Restart at 0 Offset the query
        setHasNext(true);
        getReworksListPagination(REWORK_REQUEST_LIMIT, 0, convertUiFiltersToBackendFilters(newFilters));
    };

    const fetchNextReworksList = (): void => {
        setDataIsLoading(true);
        getReworksListPagination(REWORK_REQUEST_LIMIT, fetchOffset, convertUiFiltersToBackendFilters(filters));
    };

    const getReworksListPagination = (
        reworkRequestLimit: number,
        reworkRequestOffset: number,
        filterOptions: IReworksFiltersOptions
    ): void => {
        dispatch(
            reworkGetAll({ redux: { skipLookup: true, skipDispatch: false } }, reworkRequestLimit, reworkRequestOffset, {
                ...filterOptions,
                optimizedQuery: true,
            })
        ).then((value) => downloadedEndCallback && downloadedEndCallback(value));
    };

    /**
     * handle the delete
     *
     * @param {IReworkUiModel} rework - the rework to delete
     */
    const handleDelete = (rework: IReworkUiModel): void => {
        dispatch(reworkDelete(rework));
    };

    // handle Add Rework
    const handleAddFailureTicket = (): void => {
        clearFilters(true);

        // Add
        setFailedDialogOpened(true);
    };

    const handleSendReportFailed = (failures: IStepFailure[], reworkInfo: IReworkInfo): void => {
        dispatch(reworkInsert(failures, reworkInfo)).then((reworks) => {
            setSelectedNewFailureTicketInfo(reworks.length > 0 ? reworks[0] : undefined);
        });
    };

    const handleCloseFailedDialog = (): void => {
        setFailedDialogOpened(false);
    };

    const handleCancelFailedDialog = (): void => {
        setFailedDialogOpened(false);
    };

    /**
     * Display "No job found if filters are used. Otherwise, return "Add new Job."
     *
     * @returns {Typography} - formatted message to display
     */
    const displayListFeedback = (): JSX.Element => {
        return (
            <div className={classes.containerListFeedback}>
                <Typography className={classes.loadingUserFeedback} variant="body1">
                    {dataIsLoading && (
                        <>
                            {translate("rework.LoadingReworks")}
                            <br></br>
                            <CircularProgress color="secondary" />
                        </>
                    )}
                    {!dataIsLoading &&
                        failureTickets &&
                        failureTickets.length == 0 &&
                        translate(verifyIfFiltersAreEmpty(filters) ? "rework.AddNewReworkSuggestion" : "rework.NoReworksFound")}
                    {!dataIsLoading &&
                        failureTickets &&
                        failureTickets.length > REWORK_REQUEST_LIMIT &&
                        translate(hasNext ? "rework.ScrollToLoadMoreItems" : "rework.NoMoreReworks")}
                </Typography>
            </div>
        );
    };

    const handleIntersectionObserverOnChange = (inView: boolean): void => {
        if (!inView) {
            return;
        }
        if (failureTickets.length > 0) {
            fetchNextReworksList();
        }
    };

    const observerReworkId =
        failureTickets && failureTickets.length > NUMBER_OF_ITEM_BEFORE_NEXT_FETCH
            ? failureTickets[failureTickets.length - NUMBER_OF_ITEM_BEFORE_NEXT_FETCH - 1].reworkId
            : undefined;

    return (
        <>
            <div className={classes.mainContainer} id="FailureTicketsPageId">
                <div className={classes.pageContainer}>
                    {/* SEARCH CONTAINER */}
                    <Paper className={classes.searchContainer}>
                        <FailureTicketSearchBar onFiltersChange={handleFiltersChange} clearFilters={filtersClear} />
                    </Paper>

                    {/* FAILURE TICKETS CARDS VIEW */}
                    <div className={classes.processPage} id={"failureTicketsCardsViewId"}>
                        {failureTickets.map((reworkInfo, index): JSX.Element => {
                            return (
                                <React.Fragment key={reworkInfo.reworkId}>
                                    <FailureTicketCard
                                        key={index}
                                        index={index}
                                        isSelected={reworkInfo.reworkId === selectedFailureTicketInfo?.reworkId}
                                        rework={reworkInfo}
                                        onSelection={handleSelectFailureTicket}
                                        userInfo={userInfo}
                                    />
                                    {reworkInfo.reworkId === observerReworkId && !dataIsLoading && hasNext && (
                                        <InView
                                            as="div"
                                            onChange={handleIntersectionObserverOnChange}
                                            className={classes.intersectionObserver}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                        {displayListFeedback()}
                    </div>
                    {userCanInsert(userInfo?.roleRights.rework) && (
                        <Fab id="addFailureTicketButtonId" color="secondary" className={classes.fab} onClick={handleAddFailureTicket}>
                            <AddIcon />
                        </Fab>
                    )}
                </div>
                {/* FAILURE TICKETS SELECTED SHOW FAILURE TICKER DETAILS*/}
                {selectedFailureTicketInfo && (
                    <Paper className={classes.selectedPage}>
                        {selectedFailureTicketInfo && (
                            <FailureTicketDetails
                                rework={selectedFailureTicketInfo}
                                onDelete={handleDelete}
                                onClose={handleClose}
                                userInfo={userInfo}
                            />
                        )}
                    </Paper>
                )}
                {/* END SPACER */}
            </div>

            {/* DIALOG USED TO CREATE AN MANUAL FAILURE TICKET */}
            <FailedDialog
                canRetry={false}
                dryRunMode={false}
                onClose={handleCloseFailedDialog}
                onCancel={handleCancelFailedDialog}
                onSendReport={handleSendReportFailed}
                open={failedDialogOpened}
                maxFailQty={10}
                runWithoutJobProcess={true}
                manualFailure={true}
            ></FailedDialog>
        </>
    );
}
