import { secondaryPalette } from "@aos/react-components";
import { IReworkUiModel, ITokenDecoded } from "@kortex/aos-common";
import { RootCauseSelector } from "@kortex/aos-ui/components/core/RootCauseSelector";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { reworkUpdate } from "@kortex/aos-ui/redux/rework-manager/rework-thunks";
import { userCanWrite } from "@kortex/aos-ui/utilitites/IUserRights";
import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";

const useStyles = makeStyles({
    item: {
        fontSize: "1rem",
    },
});

interface IYieldSelectorProps {
    rework: IReworkUiModel;
    userInfo?: ITokenDecoded;
}

const FailureTicketDetailsYieldSelector: FC<IYieldSelectorProps> = (props) => {
    const { rework, userInfo } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();

    const handleRootCauseChange = (rootCauseId: number): void => {
        dispatch(
            reworkUpdate({
                ...rework,
                rootCauseId,
            })
        );
    };

    return (
        <RootCauseSelector
            classes={{ item: classes.item }}
            KortexTextFieldProps={{
                InputLabelProps: {
                    style: { color: secondaryPalette[500] },
                },
                size: "small",
                disabled: !userCanWrite(userInfo?.roleRights.rework),
            }}
            onChange={handleRootCauseChange}
            reworkId={rework.reworkId}
            treeNodeId={rework.process?.treeNodeId ?? 0} // need a condition because old invalid data could cause problems
            value={rework.rootCauseId}
        />
    );
};

export default FailureTicketDetailsYieldSelector;
