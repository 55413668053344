import { rainbowPalette, theme, warningPalette } from "@aos/react-components";
import {
    GroupEditorRightsEnum,
    IProcessApprovalGroupDbModel,
    IUserGroupDbModel,
    newUserGroup,
    UserGroupStatusEnum,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Fab, makeStyles, Paper } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";

import KortexLabelText from "../../../../components/core/KortexLabelText";
import KortexPanelCard from "../../../../components/core/KortexPanelCard";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useEntitiesTreeProcess, useEntitiesUsersGroups } from "../../../../redux/effects";
import { useSelectorProcesses } from "../../../../redux/selectors";
import { processApprovalGroupGetAll } from "../../../../redux/user-manager/users-thunks-processApproval-group";
import { userGroupInsert } from "../../../../redux/user-manager/users-thunks-user-group";
import { IUserRightsProps, userCanInsert } from "../../../../utilitites/IUserRights";

import GroupEditorForm from "./GroupEditorForm";
import GroupSearchBar, { defaultGroupFilters, IGroupFilters as IGroupFilter } from "./GroupSearchBar";

const useStyles = makeStyles({
    root: {
        display: "grid",
        gridTemplateColumns: "40% 1fr",
        gridColumnGap: "12px",
        padding: "16px",
    },
    leftPanel: {
        height: "calc(100vh - 92px)", // Header (64px) + Top & Bottom margins (32px)
    },

    listContainer: {
        position: "relative",
    },
    groupList: {
        marginTop: "10px",
        borderRadius: "0px",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 180px)", // Header (64px) + Top & Bottom margins (32px) + SearchBar (84px)
    },
    rightPanel: {
        height: "calc(100vh - 124px)",
        margin: "0px 16px 16px 0px",
        padding: "16px",
        overflowY: "auto",
    },
    searchContainer: {
        padding: "10px",
    },

    groupCard: {
        padding: "0px 8px",
    },
    fab: {
        position: "absolute",
        bottom: "20px",
        right: "20px",
    },
    cardContent: {
        display: "grid",
        gridAutoFlow: "column",
    },
});

export default function GroupEditor(props: IUserRightsProps<GroupEditorRightsEnum>): JSX.Element {
    const { userAccessLevel } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const groups = useEntitiesUsersGroups();
    const processes = useSelectorProcesses();
    const treeNodes = useEntitiesTreeProcess();

    const [groupToEdit, setGroupToEdit] = useState<IUserGroupDbModel | undefined>(undefined);
    const [groupFilters, setGroupFilters] = useState<IGroupFilter>(defaultGroupFilters);
    const [processApprovalGroups, setProcessApprovalGroups] = useState<IProcessApprovalGroupDbModel[]>([]);

    // Fetch all linked process approval groups when new group is selected
    useEffect(() => {
        if (groupToEdit) {
            // Get all process approval groups from Db
            dispatch(processApprovalGroupGetAll(groupToEdit.userGroupId)).then((processApprovalGroupsFromDb) => {
                // store in new array because processApprovalGroupsFromDb is readOnly
                setProcessApprovalGroups([...processApprovalGroupsFromDb]);
            });
        }
    }, [groupToEdit]);

    /**
     * Called when the filters change. Sets local filters state
     *
     * @param {IGroupFilter} newFilters - newly set filters
     */
    const handleFiltersChange = (newFilters: IGroupFilter): void => {
        setGroupFilters(newFilters);
    };

    /**
     * Tests a single user against the filters
     *
     * @param {IUserGroupDbModel} group - group to test
     * @returns {boolean} - true if group passed
     */
    const applyFilters = (group: IUserGroupDbModel): boolean => {
        if (groupFilters.plainText !== "") {
            // check if string contains the searched plainText
            if (!`${group.name}`.toLowerCase().includes(groupFilters.plainText.toLowerCase())) {
                return false;
            }
        }

        // check Archived
        if (!groupFilters.showArchived && group.status === UserGroupStatusEnum.ARCHIVED) {
            return false;
        }
        return true;
    };

    /**
     * Formats the userGroups's status into a friendly, displayabled and translated string
     *
     * @param {UserGroupStatusEnum} status - status enum
     *
     * @returns {string} - status
     */
    const formatUserGroupStatus = (status: UserGroupStatusEnum): string => {
        switch (status) {
            case UserGroupStatusEnum.ACTIVE:
                return translate("settings.groups.active");
            case UserGroupStatusEnum.ARCHIVED:
                return translate("settings.groups.archived");
            default:
                return "";
        }
    };

    /**
     * Handles group to edit selection
     *
     * @param {IUserGroupDbModel} group - Group To Edit
     */
    const handleEditGroup =
        (group: IUserGroupDbModel): (() => void) =>
        (): void => {
            setGroupToEdit(group);
        };

    /**
     * Handles insertion of new User Group
     */
    const handleInsertUserGroup = (): void => {
        const newGroup = newUserGroup();
        newGroup.name += groups.length.toString();
        setGroupToEdit(newGroup);
        dispatch(userGroupInsert(newGroup)).then((groupInserted) => setGroupToEdit(groupInserted));
    };

    /**
     * Determines which string will be used to pass to color prop of Kortex Panel Card
     *
     * @param {IUserGroupDbModel} group - User Group
     *
     * @returns {string} value for color prop
     */
    const statusColor = (group: IUserGroupDbModel): string => {
        return group.status === UserGroupStatusEnum.ACTIVE
            ? rainbowPalette[600]
            : group.status === UserGroupStatusEnum.ARCHIVED
            ? theme.palette.error[500]
            : warningPalette[600];
    };

    return (
        <div id="settingsGroupsPageId" className={classes.root}>
            {/* LEFT PANEL */}
            <Paper className={classes.leftPanel} id="groupsListId">
                {/* SEARCH BOX */}
                <div>
                    <GroupSearchBar onFiltersChange={handleFiltersChange} />
                </div>

                {/* GROUP LIST */}
                <div className={classes.listContainer}>
                    <div className={classes.groupList}>
                        {groups
                            .sort((groupItemA, groupItemB): number => (groupItemA.name < groupItemB.name ? -1 : 1))
                            .map((groupItem, index): JSX.Element => {
                                if (applyFilters(groupItem)) {
                                    return (
                                        <div className={classes.groupCard} key={index}>
                                            <KortexPanelCard
                                                onSelect={handleEditGroup(groupItem)}
                                                isSelected={groupToEdit ? groupToEdit.userGroupId === groupItem.userGroupId : false}
                                                statusColor={statusColor(groupItem)}
                                            >
                                                <div className={classes.cardContent}>
                                                    <KortexLabelText label={translate("settings.groups.name")} text={groupItem.name} />
                                                    <KortexLabelText
                                                        label={translate("settings.groups.isApprover")}
                                                        text={translate(`settings.groups.${groupItem.isApprover ? "yes" : "no"}`)}
                                                    />
                                                    <KortexLabelText
                                                        label={translate("settings.groups.approvalMandatory")}
                                                        text={translate(`settings.groups.${groupItem.approvalMandatory ? "yes" : "no"}`)}
                                                    />
                                                    <KortexLabelText
                                                        label={translate("settings.groups.approverQty")}
                                                        text={groupItem.approverQty.toString()}
                                                    />
                                                    <KortexLabelText
                                                        label={translate("settings.groups.status")}
                                                        text={formatUserGroupStatus(groupItem.status)}
                                                    />
                                                </div>
                                            </KortexPanelCard>
                                        </div>
                                    );
                                } else {
                                    return <React.Fragment key={index} />;
                                }
                            })}
                    </div>
                    {/* INSERT BUTTON */}
                    {userCanInsert(userAccessLevel) && (
                        <Fab id="addGroupButtonId" color="secondary" className={classes.fab} onClick={handleInsertUserGroup}>
                            <AddIcon />
                        </Fab>
                    )}
                </div>
            </Paper>

            {/* RIGHT PANEL */}
            <Paper className={classes.rightPanel} id="groupEditorPaperId">
                {groupToEdit && (
                    <GroupEditorForm
                        userAccessLevel={userAccessLevel}
                        groupToEdit={groupToEdit}
                        processApprovalGroups={processApprovalGroups}
                        processes={processes}
                        treeNodes={treeNodes}
                    />
                )}
            </Paper>
        </div>
    );
}
