import { EventEmitter } from "events";

import { ServiceManagerClient, WSClient, util } from "@kortex/aos-api-client";
import { ITransportClientOptions } from "@kortex/aos-api-client/definitions/client/ITransportClient";
import { DebugCallbackObject, DebugDirection, DebugType, IDebugOptions } from "@kortex/aos-api-client/definitions/utils/Debugger";
import { debug } from "debug";

import { Unpromisify } from "../../redux/app.types";

import { generateBomFollowUpServiceClient } from "./api/bomFollowUp";
import { generateBomFollowUpSymptomServiceClient } from "./api/bomFollowUpSymptom";
import { generateElectronicSignatureServiceClient } from "./api/electronicSignature";
import { generateErpServiceClient } from "./api/erp";
import { generateFailureSymptomsServiceClient } from "./api/failureSymptoms";
import { generateFailureTypesServiceClient } from "./api/failureTypes";
import { generateFileServiceClient } from "./api/file";
import { generateGeneralServiceClient } from "./api/general";
import { generateJobServiceClient } from "./api/job";
import { generateKanbanServiceClient } from "./api/kanban";
import { generateProcessServiceClient } from "./api/process";
import { generateProcessActionSettingsServiceClient } from "./api/processActionSettings";
import { generateProcessApprovalGroupServiceClient } from "./api/processApprovalGroups";
import { generateProcessPlayerServiceClient } from "./api/processPlayer";
import { generateProcessTrainingServiceClient } from "./api/processTraining";
import { generateReportServiceClient } from "./api/report";
import { generateReportTagServiceClient } from "./api/reportTag";
import { generateReworkServiceClient } from "./api/rework";
import { generateReworkItemStatusServiceClient } from "./api/reworkItemStatus";
import { generateReworkJobProcessServiceClient } from "./api/reworkJobProcess";
import { generateReworkLogServiceClient } from "./api/reworkLog";
import { generateRootCauseServiceClient } from "./api/rootCause";
import { generateSettingOrganizationServiceClient } from "./api/settingOrganization";
import { generateSymptomLogServiceClient } from "./api/symptomLog";
import { generateTaskServiceClient } from "./api/tasks";
import { generateTrainingServiceClient } from "./api/training";
import { generateTrainingCertificateServiceClient } from "./api/trainingCertificate";
import { generateTrainingCertificationServiceClient } from "./api/trainingCertification";
import { generateTrainingCertificationPendingServiceClient } from "./api/trainingCertificationPending";
import { generateTrainingPendingServiceClient } from "./api/trainingPending";
import { generateTrainingReadingCertificateServiceClient } from "./api/trainingReadingCertificate";
import { generateTreeFileServiceClient } from "./api/treeFile";
import { generateTreeProcessServiceClient } from "./api/treeProcess";
import { generateTreeWorkZoneServiceClient } from "./api/treeWorkZone";
import { generateUncategorisedServiceClient } from "./api/uncategorised";
import { generateUserGroupServiceClient } from "./api/userGroups";
import { generateUserRoleServiceClient } from "./api/userRoles";
import { generateUserTrainingServiceClient } from "./api/userTraining";
import { generateUserServiceClient } from "./api/users";
import { generateWorkScheduleServiceClient } from "./api/workSchedule";
import { generateErpSettingsServiceClient } from "./api/erpSettings";

const debugApiError = debug("kortex:api:error");
const debugApiNotifier = debug("kortex:api:notification");
const debugApiRequestRX = debug("kortex:api:request:rx");
const debugApiRequestTX = debug("kortex:api:request:tx");
const debugApiRequestTXError = debug("kortex:api:request:tx:error");

export const KortexErrors = util.errors;

class AOSWSClient extends WSClient {
    public ee = new EventEmitter();

    // ADD_API_CALL
    public services: {
        bomFollowUp: ReturnType<typeof generateBomFollowUpServiceClient>;
        bomFollowUpSymptom: ReturnType<typeof generateBomFollowUpSymptomServiceClient>;
        electronicSignature: ReturnType<typeof generateElectronicSignatureServiceClient>;
        erp: ReturnType<typeof generateErpServiceClient>;
        erpSettings: ReturnType<typeof generateErpSettingsServiceClient>;
        failureSymptoms: ReturnType<typeof generateFailureSymptomsServiceClient>;
        failureTypes: ReturnType<typeof generateFailureTypesServiceClient>;
        file: ReturnType<typeof generateFileServiceClient>;
        general: ReturnType<typeof generateGeneralServiceClient>;
        job: ReturnType<typeof generateJobServiceClient>;
        kanban: ReturnType<typeof generateKanbanServiceClient>;
        process: ReturnType<typeof generateProcessServiceClient>;
        processActionSettings: ReturnType<typeof generateProcessActionSettingsServiceClient>;
        processApprovalGroups: ReturnType<typeof generateProcessApprovalGroupServiceClient>;
        processPlayer: ReturnType<typeof generateProcessPlayerServiceClient>["ui"];
        processTraining: ReturnType<typeof generateProcessTrainingServiceClient>;
        report: ReturnType<typeof generateReportServiceClient>;
        reportTag: ReturnType<typeof generateReportTagServiceClient>;
        rework: ReturnType<typeof generateReworkServiceClient>;
        reworkItemStatus: ReturnType<typeof generateReworkItemStatusServiceClient>;
        reworkJobProcess: ReturnType<typeof generateReworkJobProcessServiceClient>;
        reworkLog: ReturnType<typeof generateReworkLogServiceClient>;
        rootCause: ReturnType<typeof generateRootCauseServiceClient>;
        settingOrganization: ReturnType<typeof generateSettingOrganizationServiceClient>;
        symptomLog: ReturnType<typeof generateSymptomLogServiceClient>;
        workSchedule: ReturnType<typeof generateWorkScheduleServiceClient>;
        tasks: ReturnType<typeof generateTaskServiceClient>;
        training: ReturnType<typeof generateTrainingServiceClient>;
        trainingCertificate: ReturnType<typeof generateTrainingCertificateServiceClient>;
        trainingCertification: ReturnType<typeof generateTrainingCertificationServiceClient>;
        trainingCertificationPending: ReturnType<typeof generateTrainingCertificationPendingServiceClient>;
        trainingPending: ReturnType<typeof generateTrainingPendingServiceClient>;
        trainingReadingCertificate: ReturnType<typeof generateTrainingReadingCertificateServiceClient>;
        treeFile: ReturnType<typeof generateTreeFileServiceClient>;
        treeProcess: ReturnType<typeof generateTreeProcessServiceClient>;
        treeWorkZone: ReturnType<typeof generateTreeWorkZoneServiceClient>;
        uncategorized: ReturnType<typeof generateUncategorisedServiceClient>;
        users: ReturnType<typeof generateUserServiceClient>;
        userGroups: ReturnType<typeof generateUserGroupServiceClient>;
        userRoles: ReturnType<typeof generateUserRoleServiceClient>;
        userTraining: ReturnType<typeof generateUserTrainingServiceClient>;
    };

    public constructor(options?: { debug?: boolean; transport?: ITransportClientOptions }) {
        super(options);

        // ADD_API_CALL
        this.services = {
            bomFollowUp: generateBomFollowUpServiceClient(this.router, this.ee),
            bomFollowUpSymptom: generateBomFollowUpSymptomServiceClient(this.router, this.ee),
            electronicSignature: generateElectronicSignatureServiceClient(this.router, this.ee),
            erp: generateErpServiceClient(this.router, this.ee),
            erpSettings: generateErpSettingsServiceClient(this.router, this.ee),
            failureSymptoms: generateFailureSymptomsServiceClient(this.router, this.ee),
            failureTypes: generateFailureTypesServiceClient(this.router, this.ee),
            file: generateFileServiceClient(this.router, this.ee),
            general: generateGeneralServiceClient(this.router, this.ee),
            job: generateJobServiceClient(this.router, this.ee),
            kanban: generateKanbanServiceClient(this.router),
            process: generateProcessServiceClient(this.router, this.ee),
            processActionSettings: generateProcessActionSettingsServiceClient(this.router, this.ee),
            processApprovalGroups: generateProcessApprovalGroupServiceClient(this.router),
            processPlayer: generateProcessPlayerServiceClient(this.router, this.ee).ui,
            processTraining: generateProcessTrainingServiceClient(this.router, this.ee),
            report: generateReportServiceClient(this.router),
            reportTag: generateReportTagServiceClient(this.router),
            rework: generateReworkServiceClient(this.router, this.ee),
            reworkItemStatus: generateReworkItemStatusServiceClient(this.router, this.ee),
            reworkJobProcess: generateReworkJobProcessServiceClient(this.router, this.ee),
            reworkLog: generateReworkLogServiceClient(this.router, this.ee),
            rootCause: generateRootCauseServiceClient(this.router, this.ee),
            settingOrganization: generateSettingOrganizationServiceClient(this.router, this.ee),
            symptomLog: generateSymptomLogServiceClient(this.router),
            workSchedule: generateWorkScheduleServiceClient(this.router, this.ee),
            tasks: generateTaskServiceClient(this.router, this.ee),
            training: generateTrainingServiceClient(this.router, this.ee),
            trainingCertificate: generateTrainingCertificateServiceClient(this.router),
            trainingCertification: generateTrainingCertificationServiceClient(this.router),
            trainingCertificationPending: generateTrainingCertificationPendingServiceClient(this.router, this.ee),
            trainingPending: generateTrainingPendingServiceClient(this.router, this.ee),
            trainingReadingCertificate: generateTrainingReadingCertificateServiceClient(this.router, this.ee),
            treeFile: generateTreeFileServiceClient(this.router, this.ee),
            treeProcess: generateTreeProcessServiceClient(this.router, this.ee),
            treeWorkZone: generateTreeWorkZoneServiceClient(this.router, this.ee),
            uncategorized: generateUncategorisedServiceClient(this.router, this.ee),
            users: generateUserServiceClient(this.router, this.ee),
            userGroups: generateUserGroupServiceClient(this.router, this.ee),
            userRoles: generateUserRoleServiceClient(this.router, this.ee),
            userTraining: generateUserTrainingServiceClient(this.router, this.ee),
        };

        if (options && options.debug) {
            // TODO:
            // WSClient.Utils.Debugger.applyToClientService(this.services.users, this);
        }

        // Register All Notification Callbacks
        // ADD_API_CALL
        for (const serviceId of [
            generateBomFollowUpServiceClient.serviceId,
            generateBomFollowUpSymptomServiceClient.serviceId,
            generateElectronicSignatureServiceClient.serviceId,
            generateErpServiceClient.serviceId,
            generateErpSettingsServiceClient.serviceId,
            generateFailureSymptomsServiceClient.serviceId,
            generateFailureTypesServiceClient.serviceId,
            generateFileServiceClient.serviceId,
            generateGeneralServiceClient.serviceId,
            generateJobServiceClient.serviceId,
            generateKanbanServiceClient.serviceId,
            generateProcessActionSettingsServiceClient.serviceId,
            generateProcessApprovalGroupServiceClient.serviceId,
            generateProcessPlayerServiceClient.serviceId,
            generateProcessServiceClient.serviceId,
            generateProcessTrainingServiceClient.serviceId,
            generateReportServiceClient.serviceId,
            generateReportTagServiceClient.serviceId,
            generateReworkServiceClient.serviceId,
            generateReworkItemStatusServiceClient.serviceId,
            generateReworkJobProcessServiceClient.serviceId,
            generateReworkLogServiceClient.serviceId,
            generateRootCauseServiceClient.serviceId,
            generateSettingOrganizationServiceClient.serviceId,
            generateSymptomLogServiceClient.serviceId,
            generateWorkScheduleServiceClient.serviceId,
            generateTaskServiceClient.serviceId,
            generateTrainingServiceClient.serviceId,
            generateTrainingCertificateServiceClient.serviceId,
            generateTrainingPendingServiceClient.serviceId,
            generateTrainingCertificationServiceClient.serviceId,
            generateTrainingCertificationPendingServiceClient.serviceId,
            generateTreeFileServiceClient.serviceId,
            generateTreeProcessServiceClient.serviceId,
            generateTreeWorkZoneServiceClient.serviceId,
            generateUncategorisedServiceClient.serviceId,
            generateUserGroupServiceClient.serviceId,
            generateUserRoleServiceClient.serviceId,
            generateUserServiceClient.serviceId,
            generateUserTrainingServiceClient.serviceId,
        ]) {
            this.router.registerNotificationCallback(serviceId, (deviceId, notificationIdentifier, payload): boolean => {
                return this.ee.emit(`notif/${deviceId}/${notificationIdentifier}`, payload);
            });
        }
    }
}

export const client = new AOSWSClient({
    debug: true,
});

export type AOSKortexClient = typeof client;

export type APIPayload<T extends keyof AOSKortexClient["services"], K extends keyof AOSKortexClient["services"][T]> = Parameters<
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    AOSKortexClient["services"][T][K]
>[0];

export type APIResponse<T extends keyof AOSKortexClient["services"], K extends keyof AOSKortexClient["services"][T]> = Unpromisify<
    ReturnType<
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ReturnType<AOSKortexClient["services"][T][K]>
    >
>;

client.router.on("error", (err) => debugApiError("%o", err instanceof KortexErrors.ApiError ? err.toJSON() : err));

function debuggerCallback(err: unknown, data: DebugCallbackObject): void {
    if (err) {
        debugApiRequestTXError("%o", data);

        return;
    }

    if (data.type === "notification") {
        debugApiNotifier("%o", data);

        return;
    }

    if (data.type === "request") {
        if (data.direction === "rx") {
            debugApiRequestRX("%o", data);

            return;
        }

        if (data.direction === "tx") {
            debugApiRequestTX("%o", data);

            return;
        }
    }
}

/**
 * Returns debugger filters
 */
function getDebuggerCallbackFilters(): IDebugOptions {
    const apiDebugFilters: string[] = window.localStorage.debugApi ? window.localStorage.debugApi.split(",") : [];
    const apiDebugOptions: IDebugOptions = {
        direction: undefined,
        type: undefined,
        include: undefined,
        exclude: undefined,
    };

    apiDebugOptions.direction = apiDebugFilters
        .filter((what) => what.match(/^d\([rt]x\)$/))
        .map((pattern) => pattern.replace(/^d\((.*)\)$/, "$1"))
        .shift() as DebugDirection;

    apiDebugOptions.type = apiDebugFilters
        .filter((what) => what.match(/^t\(notification|request\)$/))
        .map((pattern) => pattern.replace(/^t\((.*)\)$/, "$1"))
        .shift() as DebugType;

    apiDebugOptions.exclude = apiDebugFilters
        .filter((what) => !what.match(/^[dt]\(.*\)$/))
        .filter((what) => what.match(/^-/))
        .map((what) => what.replace(/^-(.*)/, "$1"))
        .sort();

    apiDebugOptions.include = apiDebugFilters
        .filter((what) => !what.match(/^[dt]\(.*\)$/))
        .filter((what) => what.match(/^[^-]/))
        .sort();

    if (!apiDebugOptions.include.length) {
        const { direction, type, exclude } = apiDebugOptions;

        if (direction || type || exclude.length) {
            apiDebugOptions.include.push("*");
        }
    }

    return apiDebugOptions;
}

ServiceManagerClient.on("debug", WSClient.Utils.Debugger.generateDebuggerCallback(debuggerCallback, getDebuggerCallbackFilters));
