import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { greyPalette, secondaryPalette } from "../mui/palettes";
var useStyles = makeStyles({
    root: {
        display: "flex",
        fontWeight: 300,
        margin: "4px 0px",
        cursor: "pointer",
    },
});
function KortexLabel(props) {
    var classes = useStyles(props);
    var color = props.variant && props.variant === "standard" ? secondaryPalette[500] : greyPalette[500];
    return (React.createElement("span", { className: classes.root, id: props.id, style: { color: color, cursor: !props.disabled && props.onClick ? "pointer" : "inherit" }, onClick: props.disabled ? void 0 : props.onClick }, props.label));
}
export default KortexLabel;
