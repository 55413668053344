import { TNotification } from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export enum NotificationActionType {
    INSERT_NOTIFICATION = "@@NOTIFICATION/INSERT_NOTIFICATION",
    SET_NOTIFICATION_LIST = "@@NOTIFICATION/SET_NOTIFICATION_LIST",
    DELETE_NOTIFICATION = "@@NOTIFICATION/DELETE_NOTIFICATION",
    DELETE_ALL_NOTIFICATIONS = "@@NOTIFICATION/DELETE_ALL_NOTIFICATIONS",
}

interface INotificationState {
    notifications: TNotification[];
}

export type NotificationState = INotificationState;

export type SetNotificationListAction = ComplexAction<NotificationActionType.SET_NOTIFICATION_LIST, NotificationState["notifications"]>;
export type NotificationInsertedAction = ComplexAction<NotificationActionType.INSERT_NOTIFICATION, NotificationState["notifications"]>;
export type NotificationDeletedAction = ComplexAction<NotificationActionType.DELETE_NOTIFICATION, TNotification>;
export type AllNotificationsDeletedAction = ComplexAction<NotificationActionType.DELETE_ALL_NOTIFICATIONS, []>;

export type NotificationActions =
    | SetNotificationListAction
    | NotificationInsertedAction
    | NotificationDeletedAction
    | AllNotificationsDeletedAction;
