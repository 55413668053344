import { ICoords, IWorkInstructionsElementConfig, IWorkInstructionsMarkerConfig, IWorkInstructionsMarkerState } from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import WorkInstructionsShape from "../Shape/WorkInstructionsShape";

const ARROWHEAD_LENGTH = 20;
const MARKER_LINE_THICKNESS = 5;
const MARKER_LINE_SHADOW_THICKNESS = 5;
const TOTAL_PADDING_LEFT_AND_RIGHT = 32;
const TOTAL_PADDING_TOP_AND_BOTTOM = 32;

const useStyles = makeStyles({
    textContainer: {
        display: "table",
        padding: "16px",
    },
    text: {
        display: "table-cell",
        textAlign: "center",
        verticalAlign: "middle",
        whiteSpace: "pre-wrap",
    },
});

export interface IOwnProps {
    disabled?: boolean;
    markerProps: IWorkInstructionsElementConfig<IWorkInstructionsMarkerConfig>;
    markerState: IWorkInstructionsMarkerState;
}

export default function WorkInstructionsMarker(props: IOwnProps): JSX.Element {
    const classes = useStyles();
    const { disabled, markerProps } = props;
    const { extendedProps, height, rotation, width, x, y } = markerProps;
    const { arrows, backgroundColor, labelLoc, shape, textColor } = extendedProps;

    return (
        <g>
            {/* ARROWS */}
            {arrows.map((arrow: ICoords, index: number): JSX.Element => {
                let angle = Math.atan2(arrow.y - height / 2 - y, arrow.x - width / 2 - x) + Math.PI / 2;
                angle = angle < 0 ? 2 * Math.PI + angle : angle;

                return (
                    <g key={index}>
                        {/* Line */}
                        <polyline
                            fill="none"
                            id="workInstructionsMarkerLineId"
                            points={`${x + width / 2},${y + height / 2} ${arrow.x},${arrow.y}`}
                            stroke={backgroundColor}
                            strokeWidth={MARKER_LINE_THICKNESS}
                        />

                        {/* Line shadow (used to capture clicks on a thicker line) */}
                        <polyline
                            id="workInstructionsMarkerLineShadowId"
                            points={`${x + width / 2},${y + height / 2} ${arrow.x},${arrow.y}`}
                            style={{ cursor: disabled ? "default" : "pointer" }}
                            stroke="transparent"
                            strokeWidth={MARKER_LINE_SHADOW_THICKNESS}
                        />

                        {/* Arrow */}
                        <polyline
                            fill="none"
                            id="workInstructionsMarkerArrowheadId"
                            points={`${arrow.x + ARROWHEAD_LENGTH * Math.sin(-angle - Math.PI / 5)},${
                                arrow.y + ARROWHEAD_LENGTH * Math.cos(-angle - Math.PI / 5)
                            }
                                    ${arrow.x},${arrow.y}
                                    ${arrow.x + ARROWHEAD_LENGTH * Math.sin(-angle + Math.PI / 5)},${
                                        arrow.y + ARROWHEAD_LENGTH * Math.cos(-angle + Math.PI / 5)
                                    }`}
                            stroke={backgroundColor}
                            strokeWidth={MARKER_LINE_THICKNESS}
                        />
                    </g>
                );
            })}
            {/* MARKER */}
            <WorkInstructionsShape
                shapeProps={{
                    ...props.markerProps,
                    extendedProps: {
                        border: "0px",
                        borderColor: backgroundColor,
                        color: backgroundColor,
                        type: shape,
                    },
                }}
            />
            {/* TEXT */}
            <g id="workInstructionsMarkerId" transform={`translate(${x},${y}) rotate(${rotation} ${width / 2} ${height / 2})`}>
                <foreignObject height={height} width={width} x="0" y="0">
                    <div
                        className={classes.textContainer}
                        style={{ height: height - TOTAL_PADDING_TOP_AND_BOTTOM }}
                        xmlns="http://www.w3.org/1999/xhtml"
                    >
                        <span
                            className={classes.text}
                            id="workInstructionsMarkerSpanId"
                            style={{
                                color: textColor,
                                width: width - TOTAL_PADDING_LEFT_AND_RIGHT,
                            }}
                        >
                            {props.markerState.text ? props.markerState.text : labelLoc.defaultMessage}
                        </span>
                    </div>
                </foreignObject>
                {/* This rect is used to patch the foreignObject hover setting the offsetX and Y to 0. See AOS-1087 */}
                <rect fill="transparent" height={height} width={width} />
            </g>
        </g>
    );
}
