import { IRootCauseDbModel, RootCauseAllRes, UnwrapAOSPayload } from "@kortex/aos-common";

import { APIPayload } from "../../utilitites/kortex-client/client";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";

import { rootCauseInsertedAction, rootCauseUpdatedAction, setRootCauseListAction } from "./rework-actions";

/**
 * Get all root cause
 */
export function rootCauseGetAll(treeNodeId: number): StandardThunk<UnwrapAOSPayload<RootCauseAllRes>> {
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<RootCauseAllRes>> {
        return api.services.rootCause
            .getAll({ treeNodeId })()
            .then((rootCause) => {
                dispatch(setRootCauseListAction([...rootCause]));

                return rootCause;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

/**
 * Insert one root cause
 */
export function rootCauseInsert(rootCause: APIPayload<"rootCause", "insert">): StandardThunk<IRootCauseDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IRootCauseDbModel | undefined> => {
        try {
            return api.services.rootCause
                .insert(rootCause)()
                .then((rootCauseInserted) => {
                    dispatch(rootCauseInsertedAction(rootCauseInserted));
                    return rootCauseInserted;
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }

            return undefined;
        }
    };
}

/**
 * Update root cause
 */
export function rootCauseUpdate(rootCause: APIPayload<"rootCause", "update">): StandardThunk<IRootCauseDbModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IRootCauseDbModel | undefined> => {
        try {
            return api.services.rootCause
                .update(rootCause)()
                .then((rootCauseUpdated) => {
                    dispatch(rootCauseUpdatedAction(rootCauseUpdated));
                    return rootCauseUpdated;
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }

            return undefined;
        }
    };
}
