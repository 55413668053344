import shortid from "shortid";
import { EnumShapeTypes } from "../Shape/WorkInstructionsShapeConfig";
export class WorkInstructionsMarkerConfig {
    constructor() {
        this.arrows = [];
        this.backgroundColor = "#000000";
        this.labelLoc = { id: shortid.generate(), defaultMessage: "A" };
        this.shape = EnumShapeTypes.CIRCLE;
        this.textColor = "#FFFFFF";
    }
}
