export const report = {
    rpc: {
        getSummary: {
            funcId: 4000,
            funcName: "reportGetSummary",
        },
        getProcessResult: {
            funcId: 4001,
            funcName: "reportGetProcessResult",
        },
        getProcessStepResult: {
            funcId: 4002,
            funcName: "reportGetProcessStepResult",
        },
        getRework: {
            funcId: 4003,
            funcName: "reportGetRework",
        },
        getAudit: {
            funcId: 4004,
            funcName: "reportGetAudit",
        },
        getDataStoreValue: {
            funcId: 4005,
            funcName: "reportGetDataStoreValue",
        },
        getDataStoreTable: {
            funcId: 4006,
            funcName: "reportGetDataStoreTable",
        },
        getElectronicSignature: {
            funcId: 4007,
            funcName: "reportGetElectronicSignature",
        },
        getEntity: {
            funcId: 4009,
            funcName: "reportGetEntity",
        },
        getTraining: {
            funcId: 4010,
            funcName: "reportGetTraining",
        },
    },
    serviceId: 40,
    serviceVersion: 1,
};
