import { MessageLevelEnum } from "../../../../../enum/MessageLevelEnum";
export class MessageState {
    constructor() {
        this.message = "";
        this.level = MessageLevelEnum.INFO;
    }
    toJSON() {
        return {
            level: this.level,
            message: this.message,
        };
    }
}
