import { IBomFollowUp } from "@kortex/aos-common";

export function sortWoBomTableItemFollowUpRows(bomFollowUps: IBomFollowUp[]): IBomFollowUp[] {
    return bomFollowUps.sort((a, b) => {
        // Ensure that overconsumption follow-ups come last
        if (a.isOverconsumption && !b.isOverconsumption) return 1;
        if (!a.isOverconsumption && b.isOverconsumption) return -1;
        return 0;
    });
}
