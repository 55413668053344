import {
    ProcessTrainingGetTrainingsByProcessIdFilters,
    ProcessTrainingRequirementEnum,
    TrainingId,
    TrainingStatus,
    UserId,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { processTrainingClear } from "@kortex/aos-ui/redux/process-training-manager/process-training-actions";
import {
    processTrainingGetLatestApprovedProcess,
    processTrainingGetTrainingsByProcessId,
    processTrainingInsertTrainings,
    processTrainingUpdateTrainingStatus,
} from "@kortex/aos-ui/redux/process-training-manager/process-training-thunks";
import { IProcessTrainingPanelSide, IProcessTrainingState } from "@kortex/aos-ui/redux/process-training-manager/process-training-types";
import { useSelectorProcessTrainings } from "@kortex/aos-ui/redux/selectors";
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";

type ProcessTrainingFilters = Required<Omit<ProcessTrainingGetTrainingsByProcessIdFilters, "processId">>;

const DEFAULT_FILTERS: ProcessTrainingFilters = {
    masterSearch: "",
    userGroups: [],
    userRoles: [],
};

const DEFAULT_PROCESS_TRAININGS: IProcessTrainingState = {
    left: {
        label: "",
        originalProcessId: 0,
        originalProcessTrainingRequirement: ProcessTrainingRequirementEnum.NONE,
        originalProcessVersion: "",
        processId: 0,
        trainingRequirement: ProcessTrainingRequirementEnum.NONE,
        trainings: [],
        treeNodeId: 0,
        version: "",
    },
    right: {
        label: "",
        originalProcessId: 0,
        originalProcessTrainingRequirement: ProcessTrainingRequirementEnum.NONE,
        originalProcessVersion: "",
        processId: 0,
        trainingRequirement: ProcessTrainingRequirementEnum.NONE,
        trainings: [],
        treeNodeId: 0,
        version: "",
    },
    treeNodeId: 0,
};

// Context for selected training process
interface IProcessTrainingContext {
    filters: ProcessTrainingFilters;
    insertTrainings: (panel: IProcessTrainingPanelSide, userIds: UserId[]) => Promise<void>;
    loading: boolean;
    processRepositoryOpened: boolean;
    processTrainings: IProcessTrainingState;
    selectProcess: (processId: number) => Promise<void>;
    selectTreeNode: (treeNodeId: number) => Promise<void>;
    setFilters: React.Dispatch<React.SetStateAction<ProcessTrainingFilters>>;
    setProcessRepositoryOpened: React.Dispatch<React.SetStateAction<boolean>>;
    updateTrainingStatus: (trainingId: TrainingId, status: TrainingStatus) => Promise<void>;
}

export const ProcessTrainingContext = createContext<IProcessTrainingContext>({
    filters: DEFAULT_FILTERS,
    insertTrainings: async () => void 0,
    loading: false,
    processRepositoryOpened: false,
    processTrainings: DEFAULT_PROCESS_TRAININGS,
    selectProcess: async () => void 0,
    selectTreeNode: async () => void 0,
    setFilters: () => void 0,
    setProcessRepositoryOpened: () => void 0,
    updateTrainingStatus: async () => void 0,
});

export const ProcessTrainingProvider = (props: PropsWithChildren<{}>): JSX.Element => {
    const [filters, setFilters] = useState<ProcessTrainingFilters>(DEFAULT_FILTERS);
    const [loadingLeft, setLoadingLeft] = useState<boolean>(false);
    const [loadingRight, setLoadingRight] = useState<boolean>(false);
    const [processRepositoryOpened, setProcessRepositoryOpened] = useState<boolean>(false);

    const dispatch = useThunkDispatch();
    const processTrainings = useSelectorProcessTrainings();

    /**
     * Clear Redux's process training state when leaving the tab
     */
    useEffect(() => {
        return () => {
            dispatch(processTrainingClear());
        };
    }, []);

    /**
     * Fetch process trainings on filters update
     */
    useEffect(() => {
        if (processTrainings.left.originalProcessId) {
            load("left", dispatch(processTrainingGetLatestApprovedProcess(processTrainings.left.treeNodeId, filters))).then(() => {
                if (processTrainings.right.originalProcessId) {
                    load("right", dispatch(processTrainingGetTrainingsByProcessId(processTrainings.right.originalProcessId, filters)));
                }
            });
        }
    }, [filters]);

    /**
     * Wrapper for a promise. The `loading` states will be updated before the execution of the promise
     * and when the promise is completed.
     */
    const load = async function <T>(panel: IProcessTrainingPanelSide, promise: Promise<T>): Promise<T> {
        if (panel === "left") setLoadingLeft(true);
        else setLoadingRight(true);

        return promise.finally(() => {
            if (panel === "left") setLoadingLeft(false);
            else setLoadingRight(false);
        });
    };

    /**
     * Add a training to the process.
     */
    const insertTrainings = async (panel: IProcessTrainingPanelSide, userIds: UserId[]): Promise<void> => {
        const originalProcessId = processTrainings[panel].originalProcessId;

        if (!originalProcessId) return void 0;

        await load(panel, dispatch(processTrainingInsertTrainings(originalProcessId, userIds)));
    };

    /**
     * Select a process. Trainings from selected process will be displayed in the right panel.
     */
    const selectProcess = async (processId: number): Promise<void> => {
        await load("right", dispatch(processTrainingGetTrainingsByProcessId(processId, filters)));
    };

    /**
     * Select the tree node for the process training.
     * Trainings from the latest approved process of that tree node will be fetched and displayed in the left panel.
     */
    const selectTreeNode = async (treeNodeId: number): Promise<void> => {
        await load("left", dispatch(processTrainingGetLatestApprovedProcess(treeNodeId, filters)));
    };

    /**
     * Update the status of a training
     */
    const updateTrainingStatus = async (trainingId: TrainingId, status: TrainingStatus): Promise<void> => {
        await load("left", dispatch(processTrainingUpdateTrainingStatus(trainingId, status)));
    };

    return (
        <ProcessTrainingContext.Provider
            value={{
                filters,
                insertTrainings,
                loading: loadingLeft || loadingRight,
                processRepositoryOpened,
                processTrainings,
                selectProcess,
                selectTreeNode,
                setFilters,
                setProcessRepositoryOpened,
                updateTrainingStatus,
            }}
        >
            {props.children}
        </ProcessTrainingContext.Provider>
    );
};

export const useProcessTrainingContext = (): IProcessTrainingContext => useContext<IProcessTrainingContext>(ProcessTrainingContext);
