export var JobStatusEnum;
(function (JobStatusEnum) {
    JobStatusEnum["DRAFT"] = "DRAFT";
    JobStatusEnum["READY"] = "READY";
    JobStatusEnum["IN_PROGRESS"] = "INPRG";
    JobStatusEnum["ON_HOLD"] = "HOLD";
    JobStatusEnum["DONE"] = "DONE";
    JobStatusEnum["ARCHIVED"] = "ARCH";
    JobStatusEnum["CANCELLED"] = "CANC";
    JobStatusEnum["ERROR"] = "ERR";
    JobStatusEnum["NONE"] = "NONE";
})(JobStatusEnum || (JobStatusEnum = {}));
export var JobProcessStatusEnum;
(function (JobProcessStatusEnum) {
    JobProcessStatusEnum["DRAFT"] = "DRAFT";
    JobProcessStatusEnum["READY"] = "READY";
    JobProcessStatusEnum["IN_PROGRESS"] = "INPRG";
    JobProcessStatusEnum["ON_HOLD"] = "HOLD";
    JobProcessStatusEnum["DONE"] = "DONE";
    JobProcessStatusEnum["ARCHIVED"] = "ARCH";
    JobProcessStatusEnum["CANCELLED"] = "CANC";
})(JobProcessStatusEnum || (JobProcessStatusEnum = {}));
/**
 * Convert a job status to process job status
 *
 * @param {JobStatusEnum} status - Process status to be converted
 *
 * @returns {JobProcessStatusEnum} - Job process status
 */
export function convertJobStatusToJobProcessStatus(status) {
    switch (status) {
        case JobStatusEnum.READY:
            return JobProcessStatusEnum.READY;
        case JobStatusEnum.IN_PROGRESS:
            return JobProcessStatusEnum.IN_PROGRESS;
        case JobStatusEnum.ON_HOLD:
            return JobProcessStatusEnum.ON_HOLD;
        case JobStatusEnum.ARCHIVED:
            return JobProcessStatusEnum.ARCHIVED;
        case JobStatusEnum.DONE:
            return JobProcessStatusEnum.DONE;
        case JobStatusEnum.CANCELLED:
            return JobProcessStatusEnum.CANCELLED;
        case JobStatusEnum.DRAFT:
            return JobProcessStatusEnum.DRAFT;
        default:
            return JobProcessStatusEnum.DRAFT;
    }
}
