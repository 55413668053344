import { theme } from "@aos/react-components";
import { IProcessUiModel } from "@kortex/aos-common";
import { statusColor } from "@kortex/aos-ui/components/pages/ProcessEditor/ProcessEditor/ProcessApproval/ProcessApprovalCard";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { ProcessStatus, getProcessStatus } from "@kortex/aos-ui/utilitites/getProcessStatus";
import { Tooltip, makeStyles } from "@material-ui/core";
import IconArchive from "@material-ui/icons/Archive";
import IconWaitingApproval from "@material-ui/icons/AssignmentIndOutlined";
import IconRetired from "@material-ui/icons/AssignmentLateOutlined";
import IconApproved from "@material-ui/icons/AssignmentTurnedInOutlined";
import IconCanceled from "@material-ui/icons/CancelOutlined";
import IconDraft from "@material-ui/icons/CreateOutlined";
import IconRejected from "@material-ui/icons/NotInterestedOutlined";
import IconHold from "@material-ui/icons/PanToolOutlined";
import IconReleased from "@material-ui/icons/ScheduleOutlined";
import WarningIcon from "@material-ui/icons/Warning";
import React, { FC } from "react";

import { getCurrentStatusLabel } from "./utils";

const useStyles = makeStyles({
    icon: {
        height: "16px",
        padding: "4px 10px 4px 4px",
        width: "16px",
    },
    iconPurge: {
        width: "16px",
        height: "16px",
        padding: "4px 10px 4px 0px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
});

interface IOwnProps {
    process: IProcessUiModel;
    showPurgeIcon?: boolean;
}

/**
 * Return the icons based on status (with the good color)
 */
const StatusIcon: FC<IOwnProps> = (props): JSX.Element => {
    const { process, showPurgeIcon = false } = props;

    const classes = useStyles();
    const translate = useTranslate();

    let IconStatusElement: JSX.Element | undefined = undefined;
    let IconStatusToolTip: JSX.Element | undefined = undefined;
    let IconPurgeToolTip: JSX.Element | undefined = undefined;

    const status = getProcessStatus(process);
    const color = statusColor(process);

    switch (status) {
        case ProcessStatus.DRAFT:
            IconStatusElement = <IconDraft className={classes.icon} style={{ color }} />;
            break;
        case ProcessStatus.WAITING_APPROVAL:
            IconStatusElement = <IconWaitingApproval className={classes.icon} style={{ color }} />;
            break;
        case ProcessStatus.REJECTED:
            IconStatusElement = <IconRejected className={classes.icon} style={{ color }} />;
            break;
        case ProcessStatus.APPROVED:
            IconStatusElement = <IconApproved className={classes.icon} style={{ color }} />;
            break;
        case ProcessStatus.RELEASED:
            IconStatusElement = <IconReleased className={classes.icon} style={{ color }} />;
            break;
        case ProcessStatus.RETIRED:
            IconStatusElement = <IconRetired className={classes.icon} style={{ color }} />;
            break;
        case ProcessStatus.CANCELLED:
            IconStatusElement = <IconCanceled className={classes.icon} style={{ color }} />;
            break;
        case ProcessStatus.HOLD:
            IconStatusElement = <IconHold className={classes.icon} style={{ color }} />;
            break;
        case ProcessStatus.ARCHIVED:
            IconStatusElement = <IconArchive className={classes.icon} style={{ color }} />;
            break;
        case ProcessStatus.UNKNOWN:
        default:
        // Do nothing
    }

    if (IconStatusElement) {
        IconStatusToolTip = (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={translate(getCurrentStatusLabel(process))} placement="top">
                {IconStatusElement}
            </Tooltip>
        );
    }

    if (status === ProcessStatus.RETIRED && showPurgeIcon) {
        IconPurgeToolTip = (
            <Tooltip classes={{ tooltip: classes.tooltip }} title={translate("general.purge")} placement="top">
                <WarningIcon className={classes.iconPurge} />
            </Tooltip>
        );
    }

    return (
        <>
            {IconStatusToolTip}
            {IconPurgeToolTip}
        </>
    );
};

export default StatusIcon;
