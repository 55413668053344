export var SequencerStatusEnum;
(function (SequencerStatusEnum) {
    SequencerStatusEnum[SequencerStatusEnum["COMPLETED"] = 1] = "COMPLETED";
    SequencerStatusEnum[SequencerStatusEnum["IDLE"] = 2] = "IDLE";
    SequencerStatusEnum[SequencerStatusEnum["PAUSED"] = 3] = "PAUSED";
    SequencerStatusEnum[SequencerStatusEnum["PLAYING"] = 4] = "PLAYING";
    SequencerStatusEnum[SequencerStatusEnum["STOPPING"] = 5] = "STOPPING";
    SequencerStatusEnum[SequencerStatusEnum["FAILED"] = 6] = "FAILED";
    SequencerStatusEnum[SequencerStatusEnum["STOPPED"] = 7] = "STOPPED";
})(SequencerStatusEnum || (SequencerStatusEnum = {}));
