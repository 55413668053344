import { IKortexSpeedDialProps, KortexSpeedDial, KortexTextField, greyPalette, theme } from "@aos/react-components";
import {
    ComparisonOperator,
    ConditionBaseConfig,
    ConditionMode,
    LogicalOperator,
    ProcessActionStepCondition,
    ProcessVariableType,
} from "@kortex/aos-common";
import { Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import LogicalAndIcon from "../../../../../core/Icons/LogicalAnd/LogicalAnd";
import LogicalOrIcon from "../../../../../core/Icons/LogicalOr/LogicalOr";
import { IActionStepProps } from "../IActionStepProps";

import ConditionRowExpert from "./ConditionRowExpert";
import ConditionRowSimplified from "./ConditionRowSimplified";
import { getConditionsPreview } from "./Helpers";

const useStyles = makeStyles({
    conditionListContainer: {
        height: "calc(100vh - 196px)", // Header (75px), margins (32px), condition preview textfield (89px)
        overflowY: "auto",
        width: "100%",
    },
    conditionList: {
        overflowY: "auto",
    },
    conditionRow: {
        marginRight: "16px",
    },
    logicalOperatorSelect: {
        width: "100%",
    },
    menuIcon: {
        alignSelf: "center",
        height: "48px",
        width: "48px",
    },
    root: {
        height: "100%",
        display: "grid",
        justifyItems: "center",
    },
    speedDial: {
        position: "fixed",
        bottom: "32px",
        right: "48px",
    },
    speedDialActionFab: {
        width: "35px",
        height: "35px",
        boxShadow: "0px 4px 5px 0px",
        backgroundColor: theme.palette.grey[300],
    },
    speedDialTooltipLabel: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[50],
    },
    previewBoxContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
    previewBox: {
        margin: "16px",
        flexGrow: 1,
    },
    previewSpacer: {
        width: "125px",
    },
    tableCellComparison: {
        width: "150px",
    },
    tableCellLogical: {
        width: "75px",
    },
    tableCellMenuIcon: {
        width: "48px",
    },
    tableCellOperand: {
        width: "350px",
    },
    tableCellType: {
        width: "100px",
    },
    tableHeader: {
        backgroundColor: greyPalette[200],
    },
});

export default function ConditionEditor(props: IActionStepProps<ProcessActionStepCondition>): JSX.Element {
    const { actionStepProps, onChanged, userAccessLevel, lastStep } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);
    const [conditionMenuAnchorEl, setConditionMenuAnchorEl] = useState<HTMLElement | undefined>(undefined);
    const conditions = actionStepProps && actionStepProps.config.conditions ? actionStepProps.config.conditions : [];
    let failOnFalse = actionStepProps && actionStepProps.config.failOnFalse ? actionStepProps.config.failOnFalse : false;
    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Edit the condition state and update the parent component
     *
     * @param {number} index - index of updated condition
     */
    const handleConditionChange =
        (index: number): ((updatedCondition: ConditionBaseConfig) => void) =>
        (updatedCondition: ConditionBaseConfig): void => {
            const conditionsCopy = [...conditions];
            conditionsCopy[index] = updatedCondition;

            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    conditions: conditionsCopy,
                },
            });
        };

    /**
     * Deletes a condition row
     */
    const handleDeleteRow = (): void => {
        const conditionsCopy = [...conditions];

        conditionsCopy.splice(selectedRowIndex, 1);
        conditionsCopy[conditionsCopy.length - 1].logicalOperator = LogicalOperator.NA;

        setSelectedRowIndex(-1);

        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                conditions: conditionsCopy,
            },
        });
    };

    /**
     * Close the 3dots menu
     */
    const handleConditionMenuClose = (): void => {
        setConditionMenuAnchorEl(undefined);
    };

    /**
     * Opens a menu when the 3dots icon is clicked
     *
     * @param {number} index - condition's row index
     */
    const handleMenuIconClick =
        (index: number): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            setSelectedRowIndex(index);
            setConditionMenuAnchorEl(event.currentTarget);
        };

    /**
     * Handles logical operator change
     *
     * @param {number} index - index the updated logical operator
     */
    const handleLogicalOperatorChange =
        (index: number): ((event: React.ChangeEvent<HTMLSelectElement>) => void) =>
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            const conditionsCopy = [...conditions];
            conditionsCopy[index].logicalOperator = event.target.value as LogicalOperator;

            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    conditions: conditionsCopy,
                },
            });
        };

    /**
     * Called when clicking on a speed dial action
     * Add a condition row with the selected logical operator
     *
     * @param {LogicalOperator} action - selected logical operator
     */
    const handleSpeedDialActionClick =
        (action: LogicalOperator): (() => void) =>
        (): void => {
            const conditionsCopy = [...conditions];

            conditionsCopy[conditions.length - 1].logicalOperator = action;
            conditionsCopy.push(new ConditionBaseConfig());

            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    conditions: conditionsCopy,
                },
            });
        };

    /*
     * Handles the change of mode (simplified or expert)
     * Also resets all condition properties
     */
    const handleToggleMode = (): void => {
        if (conditions[selectedRowIndex].mode === ConditionMode.SIMPLIFIED) {
            conditions[selectedRowIndex].mode = ConditionMode.EXPERT;
        } else {
            conditions[selectedRowIndex].mode = ConditionMode.SIMPLIFIED;
        }

        resetCondition(selectedRowIndex);
        setSelectedRowIndex(-1);
    };

    /**
     * Reset all condition properties to default values
     *
     * @param {number} index - condition's row index
     */
    const resetCondition = (index: number): void => {
        const conditionsCopy = [...conditions];

        conditionsCopy[index].expert = "";
        conditionsCopy[index].simplified = {
            type: ProcessVariableType.STRING,
            leftOperand: "",
            comparisonOperator: ComparisonOperator.EQUALS,
            rightOperand: "",
        };

        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                conditions: conditionsCopy,
            },
        });
    };

    // Defines the addActions given to SpeedDial
    const addActions: Pick<IKortexSpeedDialProps, "actions"> = {
        actions: [
            {
                callback: handleSpeedDialActionClick(LogicalOperator.AND),
                icon: <LogicalAndIcon />,
                label: translate("action.condition.logical.and"),
                classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
            },
            {
                callback: handleSpeedDialActionClick(LogicalOperator.OR),
                icon: <LogicalOrIcon />,
                label: translate("action.condition.logical.or"),
                classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
            },
        ],
    };
    /**
     * Called when the Batch New checkbox changes
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - Unused
     * @param {boolean} checked - new checkbox state
     */
    const onFailToFalseCheckedChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        failOnFalse = checked;

        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                failOnFalse,
            },
        });
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.conditionListContainer}>
                {/* CONDITION TABLE */}
                <Table>
                    <TableBody>
                        {/* LOGICAL OPERATOR */}
                        {conditions.map(
                            (condition: ConditionBaseConfig, index: number): JSX.Element => (
                                <TableRow key={index}>
                                    <TableCell className={classes.tableCellLogical}>
                                        {index !== 0 && (
                                            <KortexTextField
                                                className={classes.logicalOperatorSelect}
                                                label={translate("action.condition.logical")}
                                                onChange={handleLogicalOperatorChange(index - 1)}
                                                TextFieldProps={{
                                                    disabled: readOnly,
                                                    select: true,
                                                }}
                                                value={conditions[index - 1].logicalOperator}
                                                variant="standard"
                                            >
                                                <MenuItem value={LogicalOperator.AND}>{translate("action.condition.logical.and")}</MenuItem>
                                                <MenuItem value={LogicalOperator.OR}>{translate("action.condition.logical.or")}</MenuItem>
                                            </KortexTextField>
                                        )}
                                    </TableCell>
                                    {/* SIMPLIFIED CONDITION */}
                                    {condition.mode === ConditionMode.SIMPLIFIED && (
                                        <ConditionRowSimplified
                                            condition={condition}
                                            onChange={handleConditionChange(index)}
                                            userAccessLevel={userAccessLevel}
                                        />
                                    )}
                                    {/* EXPERT CONDITION */}
                                    {condition.mode === ConditionMode.EXPERT && (
                                        <ConditionRowExpert
                                            condition={condition}
                                            onChange={handleConditionChange(index)}
                                            userAccessLevel={userAccessLevel}
                                        />
                                    )}
                                    {/* MENU ICON */}
                                    <TableCell className={classes.tableCellMenuIcon}>
                                        <IconButton className={classes.menuIcon} disabled={readOnly} onClick={handleMenuIconClick(index)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </Paper>
            {/* CONDITION PREVIEW */}
            <div className={classes.previewBoxContainer}>
                <KortexTextField
                    className={classes.previewBox}
                    label={translate("action.condition.preview")}
                    TextFieldProps={{
                        disabled: true,
                    }}
                    value={getConditionsPreview(conditions)}
                />
                {lastStep && (
                    <FormControlLabel
                        control={
                            <Checkbox color="secondary" checked={failOnFalse} onChange={onFailToFalseCheckedChange} disabled={readOnly} />
                        }
                        label={translate("action.condition.failOnFalse")}
                        labelPlacement="end"
                    />
                )}
                <div className={classes.previewSpacer} />
            </div>
            {/* SPEED DIAL */}
            {!readOnly && (
                <KortexSpeedDial
                    ariaLabel="ConditionSpeedDial"
                    SpeedDialProps={{ className: classes.speedDial }}
                    actions={addActions.actions}
                />
            )}
            {/* MENU */}
            <Menu anchorEl={conditionMenuAnchorEl} onClose={handleConditionMenuClose} open={Boolean(conditionMenuAnchorEl)}>
                <MenuItem onClick={handleToggleMode} onMouseDown={handleConditionMenuClose}>
                    {selectedRowIndex !== -1 &&
                        (conditions[selectedRowIndex].mode === ConditionMode.SIMPLIFIED
                            ? translate("action.condition.expert")
                            : translate("action.condition.simplified"))}
                </MenuItem>
                <MenuItem onClick={handleDeleteRow} onMouseDown={handleConditionMenuClose} disabled={conditions.length === 1}>
                    {translate("action.condition.delete")}
                </MenuItem>
            </Menu>
        </div>
    );
}
