import { UnwrapAOSPayload, FailureSymptomsAllRes, FailureSymptomsUpdateReq, FailureSymptomsInsertReq } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { StandardThunk, AppState, StandardDispatch } from "../store";
import { normalizeStandardThunkeReduxOptions, fetchedOnce } from "../utils";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";

import { setFailureSymptomsListAction, failureSymptomsUpdatedAction, failureSymptomsInsertedAction } from "./failures-actions";

// thunks

/**
 * Retrieves failure symptoms
 *
 * @param {IStandardThunkOptions} [options] - options
 */
export function getFailureSymptoms(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<FailureSymptomsAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<FailureSymptomsAllRes>> {
        if (!skipLookup) {
            if (fetchedOnce.was(getFailureSymptoms)) {
                return getState().failure.symptoms;
            }
        }

        return api.services.failureSymptoms
            .getAll(emptyObject)()
            .then((list) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(getFailureSymptoms);
                    dispatch(setFailureSymptomsListAction([...list]));
                }

                return list;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

/**
 * Update failure symptoms
 *
 * @param {APIPayload<"failureSymptoms", "update">} toUpdateFailureSymptoms - To Update Failure Type
 */
export function failureSymptomsUpdate(toUpdateFailureSymptoms: UnwrapAOSPayload<FailureSymptomsUpdateReq>): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.failureSymptoms
                .update(toUpdateFailureSymptoms)()
                .then((updatedFailureSymptoms) => {
                    dispatch(failureSymptomsUpdatedAction([...updatedFailureSymptoms]));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}

/**
 * Insert failure symptoms
 *
 * @param {APIPayload<"failureTypes", "insert">} [toInsertFailureSymptoms] - To Update Failure Type
 */
export function failureSymptomsInsert(toInsertFailureSymptoms: UnwrapAOSPayload<FailureSymptomsInsertReq>): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.failureSymptoms
                .insert(toInsertFailureSymptoms)({ timeout: 10_000 })
                .then((insertedFailureSymptoms) => {
                    dispatch(failureSymptomsInsertedAction([...insertedFailureSymptoms]));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}
