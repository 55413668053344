import {
    SchedulerActionType,
    JobUpdatedAction,
    SetJobListAction,
    JobInsertedAction,
    ClearSchedulerAction,
    ArchiveCompletedJobsAction,
} from "./scheduler-types";

/**
 * Returns an action
 *
 */
export function clearSchedulerAction(): ClearSchedulerAction {
    return { type: SchedulerActionType.CLEAR_SCHEDULER };
}

/**
 * Returns an action
 *
 * @param {JobUpdatedAction} jobs - value of the action
 */
export function jobInsertedAction(jobs: JobInsertedAction["value"]): JobInsertedAction {
    return { type: SchedulerActionType.INSERT_JOB, value: jobs };
}

/**
 * Returns an action
 *
 * @param {JobUpdatedAction} jobs - value of the action
 */
export function jobUpdatedAction(jobs: JobUpdatedAction["value"]): JobUpdatedAction {
    return { type: SchedulerActionType.UPDATE_JOB, value: jobs };
}

/**
 * Returns an action
 *
 * @param {SetJobListAction} jobs - value of the action
 */
export function setJobListAction(jobs: SetJobListAction["value"]): SetJobListAction {
    return { type: SchedulerActionType.UPDATE_JOBS, value: jobs };
}

/**
 * Returns an action
 *
 * @param {ArchiveCompletedJobsAction} archivedJobs - value of the action
 */
export function archiveCompletedJobsAction(archivedJobs: ArchiveCompletedJobsAction["value"]): ArchiveCompletedJobsAction {
    return { type: SchedulerActionType.ARCHIVE_COMPLETED_JOBS, value: archivedJobs };
}
