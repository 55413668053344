import {
    ITrainingReadingCertificatePending,
    ITrainingReadingCertificatePendingFilters,
    TrainingId,
    TrainingPendingGetAllFilters,
    UserId,
} from "@kortex/aos-common";
import { ObserverId, ObserverProps, useListObserver } from "@kortex/aos-ui/hooks/useListObserver";
import { useEntitiesTrainingReadingCertificatePendings, useEntitiesUsersGroups } from "@kortex/aos-ui/redux/effects";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import { trainingReadingCertificatePendingGetAll } from "@kortex/aos-ui/redux/training-reading-certificate-pending-manager/training-reading-certificate-pending-thunks";
import React, { PropsWithChildren, createContext, useContext, useState } from "react";

interface ITraining {
    processName: string;
    processVersion: string;
    trainingId: TrainingId;
}

interface IReadingCertificateContext {
    filters: Partial<ITrainingReadingCertificatePendingFilters>;
    hasNext: boolean;
    isDataLoading: boolean;
    isReadingCertificateEnabled: (userId: UserId) => boolean;
    Observer: React.FC<ObserverProps>;
    observerId: ObserverId | undefined;
    plainTextFilter: string | undefined;
    readingCertificatePendings: ITrainingReadingCertificatePending[];
    selectedTrainings: ITraining[];
    setSelectedTraining: React.Dispatch<React.SetStateAction<ITraining[]>>;
    setFilters: React.Dispatch<React.SetStateAction<Partial<TrainingPendingGetAllFilters> | undefined>>;
    setPlainTextFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ReadingCertificateContext = createContext<IReadingCertificateContext>({
    filters: {},
    hasNext: false,
    isDataLoading: false,
    isReadingCertificateEnabled: () => false,
    Observer: () => null,
    observerId: undefined,
    plainTextFilter: undefined,
    readingCertificatePendings: [],
    selectedTrainings: [],
    setSelectedTraining: () => void 0,
    setFilters: () => undefined,
    setPlainTextFilter: () => undefined,
});

export const ReadingCertificateProvider = (props: PropsWithChildren<{}>): JSX.Element => {
    const session = useSelectorUserSession();
    const activeTrainedAfterReadingUserGroups = useEntitiesUsersGroups({ isActive: true, trainedAfterReading: true });

    const [plainTextFilter, setPlainTextFilter] = useState<string | undefined>(undefined);
    const [selectedTrainings, setSelectedTraining] = useState<ITraining[]>([]);

    const {
        entities: readingCertificatePendings,
        filters,
        hasNext,
        isDataLoading,
        Observer,
        observerId,
        setFilters,
    } = useListObserver(
        useEntitiesTrainingReadingCertificatePendings,
        (pending) => pending?.user.userId ?? 0,
        trainingReadingCertificatePendingGetAll,
        {}
    );

    const isReadingCertificateEnabled = (userId: UserId): boolean =>
        Boolean(
            // User can only sign for their own trainings
            session!.userId === userId &&
                // User is part of an active group in which users are trained after reading
                session!.userGroups.some((userGroupId) =>
                    activeTrainedAfterReadingUserGroups.some((group) => group.userGroupId === userGroupId)
                )
        );

    return (
        <ReadingCertificateContext.Provider
            value={{
                filters,
                hasNext,
                isDataLoading,
                isReadingCertificateEnabled,
                Observer,
                observerId,
                plainTextFilter,
                readingCertificatePendings,
                selectedTrainings,
                setSelectedTraining,
                setFilters,
                setPlainTextFilter,
            }}
        >
            {props.children}
        </ReadingCertificateContext.Provider>
    );
};

export const useReadingCertificateContext = (): IReadingCertificateContext =>
    useContext<IReadingCertificateContext>(ReadingCertificateContext);
