import { KortexTextField, theme } from "@aos/react-components";
import {
    DataStoreActionTypeEnum,
    DataStoreStorageOutputFormatEnum,
    DataStoreStorageTypeEnum,
    IDataStoreKeyValueConfig,
    IDataStoreReadItemConfig,
    IDataStoreTableValueConfig,
    ProcessActionStepDataStore,
    ProcessVariableStorer,
} from "@kortex/aos-common";
import { ClickAwayListener, IconButton, Menu, MenuItem, Paper, Typography, makeStyles } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import React, { useEffect, useRef, useState } from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import { IActionStepProps } from "../IActionStepProps";

import DataStoreKeyValueItemEditorWrite from "./DataStoreKeyValueEditor/DataStoreKeyValueItemEditorWrite/DataStoreKeyValueItemEditorWrite";
import DataStoreKeyValueSettings from "./DataStoreKeyValueEditor/DataStoreKeyValueSettings";
import DataStoreReadItemEditor from "./DataStoreReadItemEditor/DataStoreReadItemEditor";
import { dataStoreReadItemList } from "./DataStoreReadItemEditor/DataStoreReadItemHelper";
import DataStoreTableValueItemEditor from "./DataStoreTableValueEditor/DataStoreTableValueItemEditor";
import DataStoreTableValueSettings from "./DataStoreTableValueEditor/DataStoreTableValueSettings";

const useStyles = makeStyles({
    root: {
        height: "calc(100vh - 108px)",
        boxSizing: "border-box",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        rowGap: "16px",
    },
    header: {
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        alignItems: "center",
    },
    formControl: {
        width: "180px",
        marginRight: "16px",
        backgroundColor: "white",
    },
    mainContainer: {
        padding: "8px",
        overflowY: "scroll",
    },
    speedDial: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    speedDialFab: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    speedDialButton: {
        width: "35px",
        height: "35px",
        boxShadow: "0px 4px 5px 0px",
    },
    emptyAction: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: "40px",
    },
});

export default function DataStoreEditor(props: IActionStepProps<ProcessActionStepDataStore>): JSX.Element {
    const { actionStepProps, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Component states
     */
    const [actionType, setActionType] = useState<DataStoreActionTypeEnum>(actionStepProps.config.actionType);
    const [storageType, setStorageType] = useState<DataStoreStorageTypeEnum>(actionStepProps.config.storageType);
    const [actionChanged, setActionChanged] = useState(false);
    const [expandedMetadataIndexes, setExpandedMetadaIndexes] = useState<number[]>([]);
    const [menuSettingsOpen, setMenuSettingsOpen] = useState(false);
    const [keyValueSettingsOpen, setKeyValueSettingsOpen] = useState(false);
    const [tableValueSettingsOpen, setTableValueSettingsOpen] = useState(false);

    const [fabOpen, setFabOpen] = useState(false);

    const menuSettingRef = useRef(null);

    /**
     * Effect that update local state when props are
     * updated from parent
     */
    useEffect((): void => {
        setActionType(actionStepProps.config.actionType);
        setStorageType(actionStepProps.config.storageType);
    }, [actionStepProps]);

    /**
     * Effect that trigger action props update to database
     */
    useEffect((): void => {
        if (actionChanged) {
            props.onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    actionType,
                    storageType,
                },
            });
            setActionChanged(false);
        }
    }, [actionChanged]);

    /**
     * Handle user change of store action type (read / write)
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e - InputEvent
     */
    const handleActionTypeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setActionType(parseInt(e.target.value));
        setActionChanged(true);
    };

    /***** WRITE ******/

    /**
     * Handle table value changed event
     *
     * @param {IDataStoreTableValueConfig} tableValueProps - updated table value props
     * @param {number} tableValueIndex - table value index
     */
    const handleChangeWriteTableValue = (tableValueProps: IDataStoreTableValueConfig, tableValueIndex: number): void => {
        const updatedTableValueProps = [...actionStepProps.config.tableValueProps];

        updatedTableValueProps.splice(tableValueIndex, 1, tableValueProps);

        props.onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                tableValueProps: updatedTableValueProps,
            },
        });
    };

    /**
     * Handle deletion of a table value props
     *
     * @param {number} deletedIndex - deleted table value index
     */
    const handleDeleteWriteTableValue = (deletedIndex: number): void => {
        const updatedKeyValueProps = [...actionStepProps.config.tableValueProps];

        updatedKeyValueProps.splice(deletedIndex, 1);

        props.onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                tableValueProps: updatedKeyValueProps,
            },
        });
    };

    /**
     * Handle key value change event
     *
     * @param {IDataStoreKeyValueConfig} keyValueProps - updated key value props
     */
    const handleChangeWriteKeyValue = (keyValueProps: IDataStoreKeyValueConfig, keyValueIndex: number): void => {
        const updatedKeyValueProps = [...actionStepProps.config.keyValueProps];

        updatedKeyValueProps.splice(keyValueIndex, 1, keyValueProps);

        props.onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                keyValueProps: updatedKeyValueProps,
            },
        });
    };

    /**
     * Handle deletion of a key value props
     *
     * @param {number} deletedIndex - deleted key value index
     */
    const handleDeleteWriteKeyValue = (deletedIndex: number): void => {
        const updatedKeyValueProps = [...actionStepProps.config.keyValueProps];

        updatedKeyValueProps.splice(deletedIndex, 1);

        props.onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                keyValueProps: updatedKeyValueProps,
            },
        });
    };

    /**
     * Handle insertion of a new key value props
     */
    const handleInsertWriteKeyValue = (): void => {
        const newKeyValueProps: IDataStoreKeyValueConfig = { groupId: -1, tag: "", value: "", subGroupId: -1 };
        const updatedKeyValueProps = [...actionStepProps.config.keyValueProps, newKeyValueProps];
        props.onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                keyValueProps: updatedKeyValueProps,
            },
        });
        setFabOpen(false);
    };

    /**
     * Handle insertion of a new table value props
     */
    const handleInsertWriteTableValue = (): void => {
        const newTableValueProps: IDataStoreTableValueConfig = { tableId: -1, columnsValue: [] };
        const updatedTableValueProps = [...actionStepProps.config.tableValueProps, newTableValueProps];
        props.onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                tableValueProps: updatedTableValueProps,
            },
        });
        setFabOpen(false);
    };

    /***** READ ******/

    /**
     * Handle key value change event
     *
     * @param {IDataStoreReadItemConfig} readItemProps - updated key value props
     */
    const handleChangeReadItem = (readItemProps: IDataStoreReadItemConfig, keyValueIndex: number): void => {
        const updatedReadItemProps = [...actionStepProps.config.readItemProps];

        updatedReadItemProps.splice(keyValueIndex, 1, readItemProps);

        props.onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                readItemProps: updatedReadItemProps,
            },
        });
    };

    /**
     * Handle insertion of a new key value read props
     *
     * @param {DataStoreStorageTypeEnum} storageType - Type of storage to insert item.  See enum for list
     */
    const handleInsertReadItem = (storageType: DataStoreStorageTypeEnum) => (): void => {
        // If not yet present, create it
        if (actionStepProps.config.readItemProps === undefined) {
            actionStepProps.config.readItemProps = [];
        }

        // Add to it
        const newReadItemProps: IDataStoreReadItemConfig = {
            storageType,
            filters: [],
            // FIXME: Change the value of reqColumn to an appropriate one
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            reqColumn: "",
            storeTo: new ProcessVariableStorer(),
            storeOutputFormat: DataStoreStorageOutputFormatEnum.CSV,
        };

        const updatedReadItemProps = [...actionStepProps.config.readItemProps, newReadItemProps];
        props.onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                readItemProps: updatedReadItemProps,
            },
        });
        setFabOpen(false);
    };

    /**
     * Handle deletion of a read item props
     *
     * @param {number} deletedIndex - deleted read item index
     */
    const handleDeleteReadItem = (deletedIndex: number): void => {
        const updatedReadItemProps = [...actionStepProps.config.readItemProps];

        updatedReadItemProps.splice(deletedIndex, 1);

        props.onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                readItemProps: updatedReadItemProps,
            },
        });
    };

    /**
     * Opens/closes the settings menu
     */
    const handleOpenSettingsMenu =
        (opened: boolean): (() => void) =>
        (): void => {
            setMenuSettingsOpen(opened);
        };

    /**
     * Handles saveing settings for the key value
     */
    const handleSaveSettingKeyValue = (): void => {
        handleOpenSettingKeyValue(false)();
    };

    /**
     * Handle open settings for the key value
     */
    const handleOpenSettingKeyValue =
        (opened: boolean): (() => void) =>
        (): void => {
            setMenuSettingsOpen(false);
            setKeyValueSettingsOpen(opened);
            setExpandedMetadaIndexes([]);
        };

    /**
     * Handle open settings for the table value
     */
    const handleOpenSettingTable =
        (opened: boolean): (() => void) =>
        (): void => {
            setMenuSettingsOpen(false);
            setTableValueSettingsOpen(opened);
        };

    /**
     * Handle close of the main FAB button
     */
    const handleCloseFab = (): void => {
        setFabOpen(false);
    };

    /**
     * Handle toggle of the main FAB button
     */
    const handleToggleFab = (): void => {
        setFabOpen(!fabOpen);
    };

    /**
     * Handles the expansion of a metadata object
     */
    const handleMetadataExpand = (expanded: boolean, index: number): void => {
        const indexOfIndex = expandedMetadataIndexes.indexOf(index);

        if (
            expanded &&
            indexOfIndex === -1 // Metadata is collapsed, so expand
        ) {
            setExpandedMetadaIndexes([...expandedMetadataIndexes, index]);
        } else if (
            !expanded &&
            indexOfIndex !== -1 // Metadata is already expanded, so collapse
        ) {
            const copy = expandedMetadataIndexes.slice();
            copy.splice(indexOfIndex, 1);
            setExpandedMetadaIndexes(copy);
        }
    };

    return (
        <div className={classes.root}>
            {/*TOP*/}
            <div className={classes.header}>
                {/* STORE ACTION TYPE */}
                <KortexTextField
                    className={classes.formControl}
                    label={translate("action.datastore.actionType")}
                    onChange={handleActionTypeChange}
                    TextFieldProps={{
                        disabled: readOnly,
                        select: true,
                    }}
                    value={actionType}
                >
                    <MenuItem value={DataStoreActionTypeEnum.READ}>{translate("action.datastore.actionType.read")}</MenuItem>
                    <MenuItem value={DataStoreActionTypeEnum.WRITE}>{translate("action.datastore.actionType.write")}</MenuItem>
                </KortexTextField>

                <div />
                {!readOnly && (
                    <IconButton ref={menuSettingRef} onClick={handleOpenSettingsMenu(true)}>
                        <SettingsIcon id={"settingIconId"} />
                    </IconButton>
                )}
            </div>

            {/*MIDDLE CARD*/}
            <Paper className={classes.mainContainer}>
                {((actionType === DataStoreActionTypeEnum.WRITE &&
                    actionStepProps.config.keyValueProps.length === 0 &&
                    actionStepProps.config.tableValueProps.length === 0) ||
                    (actionType === DataStoreActionTypeEnum.READ && actionStepProps?.config.readItemProps?.length === 0)) && (
                    <Typography variant={"h6"} className={classes.emptyAction}>
                        {translate("action.datastore.noAction")}
                    </Typography>
                )}
                {actionType === DataStoreActionTypeEnum.WRITE &&
                    actionStepProps.config.keyValueProps.map((keyValueItem, index): JSX.Element => {
                        return (
                            <DataStoreKeyValueItemEditorWrite
                                index={index}
                                key={index}
                                keyValueIndex={index}
                                keyValueProps={keyValueItem}
                                metadataExpanded={expandedMetadataIndexes.includes(index)}
                                onKeyValuePropsChanged={handleChangeWriteKeyValue}
                                onKeyValuePropsDeleted={handleDeleteWriteKeyValue}
                                onMetadataExpand={handleMetadataExpand}
                                userAccessLevel={userAccessLevel}
                            />
                        );
                    })}
                {actionType === DataStoreActionTypeEnum.WRITE &&
                    actionStepProps.config.tableValueProps.map((keyValueItem, index): JSX.Element => {
                        return (
                            <DataStoreTableValueItemEditor
                                key={index}
                                tableValueIndex={index}
                                tableValueProps={keyValueItem}
                                onTableValuePropsChanged={handleChangeWriteTableValue}
                                onTableValuePropsDeleted={handleDeleteWriteTableValue}
                                userAccessLevel={userAccessLevel}
                            />
                        );
                    })}
                {actionType === DataStoreActionTypeEnum.READ &&
                    actionStepProps.config.readItemProps &&
                    actionStepProps.config.readItemProps.map((readItemProp, index): JSX.Element => {
                        return (
                            <DataStoreReadItemEditor
                                id={"data-store-item-editor-" + index}
                                key={index}
                                readItemIndex={index}
                                readItemProps={readItemProp}
                                onReadItemPropsChanged={handleChangeReadItem}
                                onReadItemPropsDeleted={handleDeleteReadItem}
                                userAccessLevel={userAccessLevel}
                            />
                        );
                    })}
            </Paper>

            {/* TOP RIGHT SETTINGS */}
            <Menu anchorEl={menuSettingRef.current} open={menuSettingsOpen} onClose={handleOpenSettingsMenu(false)}>
                <MenuItem onClick={handleOpenSettingKeyValue(true)}>{translate("action.datastore.storageType.basicValue")}</MenuItem>
                <MenuItem onClick={handleOpenSettingTable(true)}>{translate("action.datastore.storageType.table")}</MenuItem>
            </Menu>

            <DataStoreKeyValueSettings
                open={keyValueSettingsOpen}
                onCancel={handleOpenSettingKeyValue(false)}
                onSave={handleSaveSettingKeyValue}
                userAccessLevel={userAccessLevel}
            />
            <DataStoreTableValueSettings
                open={tableValueSettingsOpen}
                onClose={handleOpenSettingTable(false)}
                userAccessLevel={userAccessLevel}
            />

            {/* BOTTOM SPEED DIAL MENU */}
            {!readOnly && (
                <ClickAwayListener onClickAway={handleCloseFab}>
                    <SpeedDial
                        ariaLabel=""
                        open={fabOpen}
                        icon={<SpeedDialIcon id="speedDialDataStoreWriteId" />}
                        classes={{ fab: classes.speedDialFab }}
                        className={classes.speedDial}
                        onClick={handleToggleFab}
                    >
                        {/* --- WRITE --- */}
                        {actionType === DataStoreActionTypeEnum.WRITE && (
                            <SpeedDialAction
                                classes={{ fab: classes.speedDialButton }}
                                key={1}
                                icon={<SpeedDialIcon id="addTableValueId" />}
                                tooltipTitle={translate("action.datastore.storageType.table")}
                                tooltipOpen={true}
                                onClick={handleInsertWriteTableValue}
                            />
                        )}
                        {actionType === DataStoreActionTypeEnum.WRITE && (
                            <SpeedDialAction
                                classes={{ fab: classes.speedDialButton }}
                                key={2}
                                icon={<SpeedDialIcon id="addBasicValueId" />}
                                tooltipTitle={translate("action.datastore.storageType.basicValue")}
                                tooltipOpen={true}
                                onClick={handleInsertWriteKeyValue}
                            />
                        )}
                        {/* --- READ --- */}
                        {actionType === DataStoreActionTypeEnum.READ &&
                            dataStoreReadItemList.map((item, index): JSX.Element => {
                                return (
                                    <SpeedDialAction
                                        classes={{ fab: classes.speedDialButton }}
                                        key={10 + index /* 10 is to make it unique */}
                                        icon={<SpeedDialIcon id={`dataStoreReadItemInsert${item.nameString}Id`} />}
                                        tooltipTitle={translate(`action.datastore.storageType.${item.nameString}`)}
                                        tooltipOpen={true}
                                        onClick={handleInsertReadItem(item.storageType)}
                                    />
                                );
                            })}
                    </SpeedDial>
                </ClickAwayListener>
            )}
        </div>
    );
}
