const username = "admin";

export const LONG_TEXT_INPUT_MAX = 100;
export const MEDIUM_TEXT_INPUT_MAX = 50;
export const SESSION_INACTIVITY_TIMEOUT = 60 * 1000;
export const CONNECTION_INACTIVITY_TIMEOUT = 2 * 1000;
export const CONNECTION_KEEPALIVE_TIMEOUT = 600;
export const SHORT_TEXT_INPUT_MAX = 25;
export const VERY_SHORT_TEXT_INPUT_MAX = 10;
export const PASSWORD_MAX_LENGTH = 50;
export const USERNAME_MAX_LENGTH = 50;

export const APP_TOP_BAR_HEIGHT = 126; // in Px

export const appConfigs = {
    appTitle: "Kortex™ AOS",
    acceptedLanguages: ["en", "fr"],
    capabilitiesPath: "/capabilities/",
    defaultCardListCount: 10,
    defaultColor: 0,
    defaultLanguage: "en",
    homePage: "homePage",
    username,
    maxCanvasWidth: 1280,
};

export default appConfigs;
