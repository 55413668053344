import {
    IJobProgress,
    IJobProcessRun,
    getJobProcessRunStatus,
    IJobProductProgress,
    JobDbModelKey,
    getElapsedTimeStr,
} from "@kortex/aos-common";
import { theme } from "@aos/react-components";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography, IconButton, Collapse } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ReworkIcon from "@material-ui/icons/BuildOutlined";
import { KortexTextField } from "@aos/react-components";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useContext, useEffect, useState } from "react";

import { jobProgress } from "../../../../redux/scheduler-manager/scheduler-thunks-job";
import { kortexTheme } from "../../../theme";
import { IReduxAction } from "../../../../redux/IReduxAction";
import { useTranslate } from "../../../../hooks/useTranslate";
import { getJobProcessRunStatusColor, getJobProcessRunStatusLabelKey } from "../SchedulerData";
import { SchedulerContext } from "../utilities";

const useStyles = makeStyles({
    root: {},
    dialog: {
        padding: "0px",
    },
    dialogContent: {
        backgroundColor: theme.palette.grey[200],
        margin: "0px",
        padding: "0px",
        "&:first-child": {
            padding: "0px",
        },
    },
    paperDialog: {
        minHeight: "50vh",
        maxHeight: "90vh",
    },
    dialogActions: {
        backgroundColor: theme.palette.grey[200],
        margin: "0px",
        padding: "10px",
    },
    contentRow: {
        backgroundColor: theme.palette.grey["A100"],
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0px",
    },
    contentColumn: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.common.white,
        padding: "0px",
    },
    headerRow: {
        backgroundColor: theme.palette.grey[200],
    },
    jobRow: {
        backgroundColor: theme.palette.common.white,
        border: "1px solid",
        borderColor: theme.palette.grey[300],
    },
    columnField: {
        padding: "10px",
        width: "150px",
        margin: "0px",
    },
    columnFieldLarge: {
        padding: "10px",
        width: "250px",
        margin: "0px",
    },
    text: {
        color: theme.palette.grey[600],
    },
    textGreyed: {
        color: theme.palette.grey[300],
    },
    progressColor: {
        width: "10px",
        height: "60px",
        border: "1px solid",
        borderColor: theme.palette.common.white,
    },
    progressColorNoBorder: {
        width: "10px",
        height: "60px",
    },
    unassigned: {
        color: theme.palette.grey[600],
        textAlign: "center",
        padding: "10px",
        width: "150px",
        margin: "0px",
    },
    reworkIcon: {
        width: "20px",
        height: "20px",
        color: theme.palette.grey[400],
    },
    dialogHeaderTitle: { ...kortexTheme.KortexDialogTitle },
});

interface IOwnProps {
    isDialogOpen: boolean;
    onDialogClose: () => void;
    getJobProgress?: (job: JobDbModelKey) => Promise<IJobProgress | undefined>;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, IReduxAction>): Pick<IOwnProps, "getJobProgress"> => ({
    getJobProgress: (job: JobDbModelKey): Promise<IJobProgress | undefined> => {
        return dispatch(jobProgress(job));
    },
});

function SchedulerJobTrackingDialog(props: IOwnProps): JSX.Element {
    const { isDialogOpen } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [jobProgress, setJobProgress] = useState<IJobProgress | undefined>(undefined);
    const [expanded, setExpanded] = useState<number>(-1);

    const [job] = useContext(SchedulerContext);

    // Render nothing if no job is selected
    if (!job) {
        return <></>;
    }

    useEffect((): void => {
        if (props.getJobProgress) {
            async function getJobProgress(): Promise<void> {
                if (job && props.getJobProgress) {
                    const jobProgress = await props.getJobProgress(job);
                    setJobProgress(jobProgress);
                }
            }
            getJobProgress();
        }
    }, [job]);

    /**
     * handles the close dialog
     */
    const handleDialogClose = (): void => {
        props.onDialogClose();
    };

    /**
     * handles expand of the dialog row
     *
     * @param {number} index - index of the row to expand/collaps
     */
    const handleExpandClick =
        (index: number): (() => void) =>
        (): void => {
            if (index === expanded) {
                setExpanded(-1);
            } else {
                setExpanded(index);
            }
        };

    /**
     * Return the HTML element about the process run
     *
     * @param {number} index - unique index for rendering
     * @param {IJobProcessRun} processRun - contains the info about he runned process
     *
     */
    const getProcessRunElement = (index: number, processRun: IJobProcessRun): JSX.Element => {
        /* eslint-disable react/prop-types */
        return (
            <div key={index} className={classes.contentRow}>
                <div className={classes.progressColorNoBorder} />
                <div
                    className={classes.progressColor}
                    style={{
                        backgroundColor: getJobProcessRunStatusColor(
                            getJobProcessRunStatus(job.status, processRun.pass, processRun.fail, processRun.startedOn)
                        ),
                    }}
                />
                <KortexTextField
                    className={classes.columnFieldLarge}
                    variant="standard"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        className: classes.text,
                        endAdornment: processRun.reworkId !== 0 && <ReworkIcon className={classes.reworkIcon} />,
                    }}
                    value={processRun.processName}
                />
                <KortexTextField
                    className={classes.columnField}
                    variant="standard"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        className: classes.text,
                    }}
                    value={translate(
                        getJobProcessRunStatusLabelKey(
                            getJobProcessRunStatus(job.status, processRun.pass, processRun.fail, processRun.startedOn)
                        )
                    )}
                />
                <KortexTextField
                    className={classes.columnField}
                    variant="standard"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        className: classes.text,
                    }}
                    value={getElapsedTimeStr(processRun.durationMSec)}
                />
                <KortexTextField
                    className={classes.columnField}
                    variant="standard"
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        className: classes.text,
                    }}
                    value={processRun.userName}
                />
            </div>
        );
        /* eslint-enable react/prop-types */
    };

    /**
     * Return the HTML element about the job product progress
     *
     * @param {number} index - unique index for rendering
     * @param {IJobProductProgress} productProgress - contains the info about he product progress process
     *
     */
    const getProductProgressElement = (index: number, productProgress: IJobProductProgress): JSX.Element => {
        /* eslint-disable react/prop-types */
        return (
            <React.Fragment key={index}>
                <div className={[classes.contentRow, classes.jobRow].join(" ")}>
                    <div
                        className={classes.progressColor}
                        style={{ backgroundColor: getJobProcessRunStatusColor(productProgress.status) }}
                    />
                    <div className={classes.progressColorNoBorder} />
                    <KortexTextField
                        className={classes.columnFieldLarge}
                        variant="standard"
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            className: productProgress.trackingNumber ? classes.text : classes.textGreyed,
                        }}
                        value={productProgress.trackingNumber !== "" ? productProgress.trackingNumber : translate("scheduler.unassigned")}
                    />
                    <KortexTextField
                        className={classes.columnField}
                        variant="standard"
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            className: classes.text,
                        }}
                        value={translate(getJobProcessRunStatusLabelKey(productProgress.status))}
                    />
                    <KortexTextField
                        className={classes.columnField}
                        variant="standard"
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            className: classes.text,
                        }}
                        value={productProgress.qty}
                    />
                    <KortexTextField
                        className={classes.columnField}
                        variant="standard"
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            className: classes.text,
                        }}
                        value={`${productProgress.progressInPercent} %`}
                    />
                    <KortexTextField
                        className={classes.columnFieldLarge}
                        variant="standard"
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            className: classes.text,
                        }}
                        value={productProgress.lastProcessRun}
                    />
                    {productProgress.productProcessRun.length > 0 && (
                        <IconButton onClick={handleExpandClick(index)} aria-expanded={expanded === index}>
                            {expanded === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    )}
                </div>
                <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                    {productProgress.productProcessRun.map((processRun: IJobProcessRun, index): JSX.Element => {
                        return getProcessRunElement(index, processRun);
                    })}
                </Collapse>
            </React.Fragment>
        );
        /* eslint-enable react/prop-types */
    };

    return (
        <div className={classes.root}>
            <Dialog
                classes={{
                    root: classes.dialog,
                    paper: classes.paperDialog,
                }}
                open={isDialogOpen}
                maxWidth="xl"
                fullScreen={false}
                onClose={handleDialogClose}
            >
                <DialogContent
                    classes={{
                        root: classes.dialogContent,
                    }}
                >
                    <div className={classes.contentColumn}>
                        <div className={[classes.contentRow, classes.headerRow].join(" ")}>
                            <div className={classes.progressColorNoBorder} />
                            <div className={classes.progressColorNoBorder} />
                            <Typography variant="subtitle2" className={[classes.columnFieldLarge, classes.text].join(" ")}>
                                {translate("scheduler.tracking")}
                            </Typography>
                            <Typography variant="subtitle2" className={[classes.columnField, classes.text].join(" ")}>
                                {translate("scheduler.status")}
                            </Typography>
                            <Typography variant="subtitle2" className={[classes.columnField, classes.text].join(" ")}>
                                {translate("scheduler.quantity")}
                            </Typography>
                            <Typography variant="subtitle2" className={[classes.columnField, classes.text].join(" ")}>
                                {translate("scheduler.progress")}
                            </Typography>
                            <Typography variant="subtitle2" className={[classes.columnFieldLarge, classes.text].join(" ")}>
                                {translate("scheduler.currentProcess")}
                            </Typography>
                        </div>
                        {jobProgress &&
                            jobProgress.productProgress.map((productProgress, index): JSX.Element => {
                                return getProductProgressElement(index, productProgress);
                            })}
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button variant={"contained"} color="secondary" onClick={handleDialogClose}>
                        {translate("scheduler.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default connect(null, mapDispatchToProps)(SchedulerJobTrackingDialog);
