var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Set(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M16,13 C17.8639271,13 19.4300871,14.2748927 19.8740452,16.0002435 L22,16 L22,18 L19.8737865,18.0007613 C19.429479,19.7256022 17.8635652,21 16,21 C14.1364348,21 12.570521,19.7256022 12.1262135,18.0007613 L2,18 L2,16 L12.1259548,16.0002435 C12.5699129,14.2748927 14.1360729,13 16,13 Z M16,15 C14.8954305,15 14,15.8954305 14,17 C14,18.1045695 14.8954305,19 16,19 C17.1045695,19 18,18.1045695 18,17 C18,15.8954305 17.1045695,15 16,15 Z M8,3 C9.86392711,3 11.4300871,4.27489272 11.8740452,6.00024347 L22,6 L22,8 L11.8737865,8.00076134 C11.429479,9.72560224 9.86356525,11 8,11 C6.13643475,11 4.57052105,9.72560224 4.12621352,8.00076134 L2,8 L2,6 L4.12595483,6.00024347 C4.56991294,4.27489272 6.13607289,3 8,3 Z M8,5 C6.8954305,5 6,5.8954305 6,7 C6,8.1045695 6.8954305,9 8,9 C9.1045695,9 10,8.1045695 10,7 C10,5.8954305 9.1045695,5 8,5 Z", id: "icons-set" })));
}
export default Set;
