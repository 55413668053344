import { UnwrapAOSPayload, ReworkJobProcessAllRes } from "@kortex/aos-common";
import { APIPayload } from "@kortex/aos-ui/utilitites/kortex-client/client";

import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { jobProcessReworkDeletedAction, setReworkJobProcessListAction } from "./rework-actions";

export function reworkJobProcessGetAll(
    reworkId: number,
    onlyJobProcessRework: boolean,
    jobId: number,
    options?: IStandardThunkOptions
): StandardThunk<UnwrapAOSPayload<ReworkJobProcessAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ReworkJobProcessAllRes>> {
        if (!skipLookup && onlyJobProcessRework) {
            if (fetchedOnce.was(reworkJobProcessGetAll)) {
                return getState().rework.reworkJobsProcess;
            }
        }

        return api.services.reworkJobProcess
            .getAll({ reworkId, onlyJobProcessRework, jobId })()
            .then((reworkJobProcess) => {
                if (!skipDispatch && onlyJobProcessRework) {
                    fetchedOnce.seal(reworkJobProcessGetAll);

                    dispatch(setReworkJobProcessListAction([...reworkJobProcess]));
                }

                return reworkJobProcess;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

export function insertReworkJobProcess(
    req: APIPayload<"reworkJobProcess", "insert">
): StandardThunk<UnwrapAOSPayload<ReworkJobProcessAllRes>> {
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ReworkJobProcessAllRes>> {
        return api.services.reworkJobProcess
            .insert(req)()
            .then((reworkJobProcess) => {
                dispatch(setReworkJobProcessListAction([...reworkJobProcess]));

                return reworkJobProcess;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

export function deleteReworkJobProcess(req: APIPayload<"reworkJobProcess", "delete">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        api.services.reworkJobProcess
            .delete(req)()
            .then((reworkJobProcess) => {
                dispatch(jobProcessReworkDeletedAction([...reworkJobProcess]));
            })
            .catch((reason) => {
                // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }
            });
    };
}
