/**
 * Creates a new training certification process model
 */
export function newTrainingCertificationProcessModel(options) {
    const { processId, trainingCertificationId } = options;
    return {
        trainingCertificationProcessId: 0,
        processId,
        trainingCertificationId,
    };
}
