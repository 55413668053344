import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    RootCauseAllReq,
    RootCauseAllRes,
    RootCauseInsertReq,
    RootCauseInsertRes,
    RootCauseInsertedNotif,
    RootCauseUpdateReq,
    RootCauseUpdateRes,
    RootCauseUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { rootCauseAll } from "./all";
import { rootCauseInsert } from "./insert";
import { rootCauseInserted } from "./inserted";
import { rootCauseUpdate } from "./update";
import { rootCauseUpdated } from "./updated";

interface IAOSClientServiceWrappedRootCause {
    getAll: AOSClientServiceRequestWrapped<RootCauseAllReq, RootCauseAllRes>;
    insert: AOSClientServiceRequestWrapped<RootCauseInsertReq, RootCauseInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<RootCauseInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<RootCauseUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<RootCauseUpdateReq, RootCauseUpdateRes>;
}

type AOSClientServiceWrappedRootCause = IAOSClientServiceWrappedRootCause;

// ADD_API_CALL Root cause
export function generateRootCauseServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedRootCause {
    return {
        getAll: AOSClientServiceWrapperUI.request(rootCauseAll(router)),
        insert: AOSClientServiceWrapperUI.request(rootCauseInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(rootCauseInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(rootCauseUpdated(router, notifier)),
        update: AOSClientServiceWrapperUI.request(rootCauseUpdate(router)),
    };
}

generateRootCauseServiceClient.serviceId = 36;
