import { Kinova } from "@kortex/aos-api-client";
import { AOSClientServiceRequest, AOSClientServiceWrapper, AOSPayload } from "@kortex/aos-common";

import { get, set } from "../../storage";

export { objectFromUTF8Array, utf8ArrayFromObject } from "@kortex/aos-common";

/**
 * Converts a Kortex API Error object to UTF8 array
 *
 * @param {Kinova.Api.IError} error - error to convert
 */
export function kortexApiErrorObjectToUTF8Array(error: Kinova.Api.IError): Uint8Array {
    return Kinova.Api.Error.encode(error).finish();
}

/**
 * Converts an UTF8 array to Kortex API Error object
 *
 * @param {Uint8Array} payload - payload
 */
export function utf8ArrayToKortexApiErrorObject(payload: Uint8Array): Kinova.Api.IError {
    return Kinova.Api.Error.decode(payload).toJSON();
}

/**
 * Converts a Kortex API Notification object to UTF8 array
 *
 * @param {Kinova.Api.Common.INotificationHandle} notificationHandle - notification
 */
export function kortexApiNotificationHandleObjectToUTF8Array(notificationHandle: Kinova.Api.Common.INotificationHandle): Uint8Array {
    return Kinova.Api.Common.NotificationHandle.encode(notificationHandle).finish();
}

/**
 * Converts an UTF8 array to Kortex API Notification object
 *
 * @param {Uint8Array} payload - payload
 */
export function utf8ArrayToKortexApiNotificationHandleObject(payload: Uint8Array): Kinova.Api.Common.INotificationHandle {
    return Kinova.Api.Common.NotificationHandle.decode(payload).toJSON();
}

/**
 * AOS Service wrapper for UI
 */
export const AOSClientServiceWrapperUI = {
    notification: AOSClientServiceWrapper.notification,
    request: <RequestPayload extends object, ResponsePayload extends object>(
        service: AOSClientServiceRequest<AOSPayload<RequestPayload>, AOSPayload<ResponsePayload>>
    ): AOSClientServiceRequest<RequestPayload, ResponsePayload> =>
        AOSClientServiceWrapper.request(
            service,
            (payload) => {
                if (payload.security) {
                    payload.security.token = get().token;
                }

                return payload;
            },
            (token) => {
                if (token) {
                    set({ token });
                }
            }
        ),
};
