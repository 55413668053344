export class SetState {
    constructor() {
        this.variables = [];
    }
    toJSON() {
        return {
            variables: this.variables,
        };
    }
}
