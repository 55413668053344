import {
    UserTrainingActionType,
    UserTrainingClear,
    UserTrainingInsertedAction,
    UserTrainingSetAction,
    UserTrainingSetTrainingFiltersAction,
    UserTrainingUpdatedAction,
} from "./user-training-types";

export function userTrainingClear(): UserTrainingClear {
    return { type: UserTrainingActionType.CLEAR_USER_TRAININGS };
}

export function userTrainingInsertedAction(trainings: UserTrainingInsertedAction["value"]): UserTrainingInsertedAction {
    return { type: UserTrainingActionType.INSERT_USER_TRAININGS, value: trainings };
}

export function userTrainingSetAction(trainings: UserTrainingSetAction["value"]): UserTrainingSetAction {
    return { type: UserTrainingActionType.SET_USER_TRAININGS, value: trainings };
}

export function userTrainingSetTrainingFiltersAction(
    filters: Partial<UserTrainingSetTrainingFiltersAction["value"]>
): UserTrainingSetTrainingFiltersAction {
    return { type: UserTrainingActionType.SET_TRAINING_FILTERS, value: filters };
}

export function userTrainingUpdatedAction(trainings: UserTrainingUpdatedAction["value"]): UserTrainingUpdatedAction {
    return { type: UserTrainingActionType.UPDATE_USER_TRAININGS, value: trainings };
}
