// react
import * as React from "react";
// mui
import { AppBar, IconButton, makeStyles, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
// kortex
import { IconKinovaLogo, KortexConnectionStatus, rootDefaults, theme } from "@aos/react-components";

import appConfig from "../../../configs/app";
import { useTranslate } from "../../../hooks/useTranslate";
import { ILocale } from "../../../locales/ILocale";
import { useSelectorConnectionList } from "../../../redux/selectors";
import RunnerIcon from "../../core/Icons/Runner/Runner";
import TasksStatus from "../../core/TasksStatus/TasksStatus";
import NotificationsCenterDialog from "../../pages/NotificationsCenter/NotificationsCenterDialog";

// Media breakpoints
export const breakpoints = {
    notificationBar: 960,
    logo: 500,
};

const useStyles = makeStyles({
    root: {
        ...rootDefaults,
        backgroundColor: theme.palette.primary.main,
        flexGrow: 1,
    },
    logoContainer: {
        cursor: "pointer",
        height: "25px",
        minWidth: "180px",
    },
    iconContainer: {
        color: theme.palette.common.white,
        margin: 0,
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    runnerIcon: {
        width: "40px",
        height: "40px",
        paddingTop: "2px",
        paddingRight: "5px",
    },
    connected: {
        color: theme.palette.common.white,
    },
    unconnected: {
        color: theme.palette.grey[600],
    },
    smallLogo: {
        marginTop: "4px",
    },
    mainBar: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "64px",
        padding: "0px 10px",
    },
    toolContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        height: "64px",
        padding: "0px 10px",
    },
    barSpacer: {
        width: "10px",
    },
});

interface IOwnProps {
    loading: boolean;
    language: string;
    username: string;

    onLogout?: () => void;
    onDrawerToggle?: () => void;
    onGoToHomePage?: () => void;
    onGoToUsersPage?: () => void;
    onGoToTasksPage?: () => void;
    onLanguageChange?: (language: string) => void;
}

export default function Header(props: IOwnProps): JSX.Element {
    const { language, loading, username } = props;
    const actualUser = { username };

    const classes = useStyles();
    const translate = useTranslate();

    const connectionList = useSelectorConnectionList();

    /***
     * Callback for drawer toggle
     */
    const handleDrawerToggle = (): void => {
        if (props.onDrawerToggle) {
            props.onDrawerToggle();
        }
    };

    /***
     * Callback for language change request
     */
    const handleLanguageChange = (newLanguage: string): void => {
        if (props.onLanguageChange) {
            props.onLanguageChange(newLanguage);
        }
    };

    /***
     * Callback for logout request
     */
    const handleLogout = (): void => {
        if (props.onLogout) {
            props.onLogout();
        }
    };

    /***
     * Callback for go to users page request
     */
    const handleGoToUsersPage = (): void => {
        if (props.onGoToUsersPage) {
            props.onGoToUsersPage();
        }
    };

    /***
     * Callback for go to users page request
     */
    const handleGoToHomePage = (): (() => void) => (): void => {
        if (props.onGoToHomePage) {
            props.onGoToHomePage();
        }
    };
    /***
     * Callback for go to home page request
     */
    const handleGoToTasksPage = (): void => {
        if (props.onGoToTasksPage) {
            props.onGoToTasksPage();
        }
    };

    return (
        <AppBar position="static">
            <Toolbar disableGutters={true}>
                <IconButton
                    id="headerMenuButtonId"
                    className={classes.iconContainer}
                    color="inherit"
                    aria-label="Menu"
                    onClick={handleDrawerToggle}
                >
                    <MenuIcon />
                </IconButton>
                <div id="testGoToHomeId" className={classes.logoContainer} onClick={handleGoToHomePage()}>
                    <IconKinovaLogo height={25} width={148} />
                </div>
                <div className={classes.mainBar}>
                    {/* <NotificationsCenter/> */}
                    <NotificationsCenterDialog />

                    <div className={classes.barSpacer} />

                    {/* <NotificationStatus/> */}
                    <TasksStatus onGoToTasksPage={handleGoToTasksPage} />

                    <div className={classes.barSpacer} />

                    <RunnerIcon
                        className={[classes.runnerIcon, connectionList.runner.length ? classes.connected : classes.unconnected].join(" ")}
                    />
                    <KortexConnectionStatus
                        language={language}
                        isLoading={loading}
                        user={actualUser}
                        onLogout={handleLogout}
                        onLanguageChange={handleLanguageChange}
                        onGoToUsersPage={handleGoToUsersPage}
                        acceptedLanguages={appConfig.acceptedLanguages.map((lang: keyof ILocale): { key: string; label: string } => {
                            return { key: lang, label: translate(lang) };
                        })}
                        textLabels={{
                            leftButton: translate("connectionStatus.account"),
                            rightButton: translate("connectionStatus.logout"),
                            language: translate("connectionStatus.language"),
                        }}
                    />
                </div>
            </Toolbar>
        </AppBar>
    );
}
