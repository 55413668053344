var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _HookOnFailCircularError_context;
import { SequencerError } from "./sequencer-error";
export class HookOnFailCircularError extends SequencerError {
    constructor(context, message = "onFail hook threw an error") {
        super(context.key, message, context.error);
        _HookOnFailCircularError_context.set(this, void 0);
        __classPrivateFieldSet(this, _HookOnFailCircularError_context, context, "f");
    }
    get context() {
        return __classPrivateFieldGet(this, _HookOnFailCircularError_context, "f");
    }
}
_HookOnFailCircularError_context = new WeakMap();
export class SequencerHookOnFailCircularError extends HookOnFailCircularError {
    constructor(context, message) {
        super(context, message);
    }
    get name() {
        return "SequencerHookOnFailCircularError";
    }
}
export class ActionGroupHookOnFailCircularError extends HookOnFailCircularError {
    constructor(context, message) {
        super(context, message);
    }
    get name() {
        return "ActionGroupHookOnFailCircularError";
    }
}
export class ActionHookOnFailCircularError extends HookOnFailCircularError {
    constructor(context, message) {
        super(context, message);
    }
    get name() {
        return "ActionHookOnFailCircularError";
    }
}
