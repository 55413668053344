import { IUserTraining, IUserTrainingFilters, ProcessEditorRightsEnum } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useEntitiesUserTrainings } from "@kortex/aos-ui/redux/effects";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import { userTrainingClear } from "@kortex/aos-ui/redux/user-training-manager/user-training-actions";
import { userTrainingGetAllByUserId } from "@kortex/aos-ui/redux/user-training-manager/user-training-thunks";
import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";

interface IUserTrainingContext {
    canAccessProcessEditor: boolean;
    historyTreeNodeIdSelected: number;
    isDataLoading: boolean;
    openHistoryDialog: boolean;
    openRepositoryDialog: boolean;
    setHistoryTreeNodeIdSelected: React.Dispatch<React.SetStateAction<number>>;
    setOpenHistoryDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenRepositoryDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setTrainingFilters: (updatedFilters: Partial<IUserTrainingFilters>) => void;
    trainingFilters: IUserTrainingFilters;
    userTrainings: IUserTraining[];
}

const UserTrainingContext = createContext<IUserTrainingContext>({
    canAccessProcessEditor: false,
    historyTreeNodeIdSelected: 0,
    isDataLoading: false,
    openHistoryDialog: false,
    openRepositoryDialog: false,
    setHistoryTreeNodeIdSelected: (): void => void 0,
    setOpenHistoryDialog: (): void => void 0,
    setOpenRepositoryDialog: (): void => void 0,
    setTrainingFilters: (): void => void 0,
    trainingFilters: {
        processName: "",
        showArchived: false,
        userId: 0,
    },
    userTrainings: [],
});

export const UserTrainingProvider = (props: PropsWithChildren<{}>): JSX.Element => {
    // Hooks
    const dispatch = useThunkDispatch();
    const session = useSelectorUserSession();
    const [userTrainings, isEntitiesLoading] = useEntitiesUserTrainings({
        processName: "",
        showArchived: false,
        userId: session!.userId,
    });

    // States
    const [trainingFilters, setTrainingFilters] = useState<IUserTrainingFilters>({
        processName: "",
        showArchived: false,
        userId: session!.userId,
    });
    const [historyTreeNodeIdSelected, setHistoryTreeNodeIdSelected] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false);
    const [openRepositoryDialog, setOpenRepositoryDialog] = useState<boolean>(false);

    // Constants
    const canAccessProcessEditor = Boolean(session!.roleRights.processEditor >= ProcessEditorRightsEnum.READ);

    // Effects
    useEffect(() => {
        // Clear redux on unmount
        return () => {
            dispatch(userTrainingClear());
        };
    }, []);

    // Functions
    const updateFilters = (updatedFilters: Partial<IUserTrainingFilters>): void => {
        setIsLoading(true);

        // Update our filters
        setTrainingFilters((prevState) => {
            const updatedState = { ...prevState, ...updatedFilters };

            if (updatedState.userId) {
                // Send filters to redux
                dispatch(userTrainingGetAllByUserId(updatedState)).finally(() => setIsLoading(false));
            }

            return updatedState;
        });
    };

    return (
        <UserTrainingContext.Provider
            value={{
                canAccessProcessEditor,
                historyTreeNodeIdSelected,
                isDataLoading: isEntitiesLoading || isLoading,
                openHistoryDialog,
                openRepositoryDialog,
                setHistoryTreeNodeIdSelected,
                setOpenHistoryDialog,
                setOpenRepositoryDialog,
                setTrainingFilters: updateFilters,
                trainingFilters,
                userTrainings,
            }}
        >
            {props.children}
        </UserTrainingContext.Provider>
    );
};

export const useUserTrainingContext = (): IUserTrainingContext => useContext<IUserTrainingContext>(UserTrainingContext);
