export const trainingCertification = {
    rpc: {
        getAllTrainingCertification: {
            funcId: 13501,
            funcName: "trainingCertificationGetAllTrainingCertification",
        },
        insert: {
            funcId: 13502,
            funcName: "trainingCertificationInsert",
        },
    },
    serviceId: 135,
    serviceVersion: 1,
};
