import { ProcessApprovalStatusFilter, ProcessId, ProcessUiModel, TreeNodeId } from "@kortex/aos-common";

interface ICurrentVersion {
    process?: ProcessUiModel;
    version: string;
}

/**
 * Return currently selected process version
 */
export const getCurrentVersion = (
    processVersions: ProcessUiModel[],
    treeNodeId?: TreeNodeId,
    processId?: ProcessId,
    variant?: ProcessApprovalStatusFilter,
    latest?: boolean,
    translate: (key: string) => string = (key: string): string => key
): ICurrentVersion => {
    // Check if process array is empty
    if (!processVersions.length) {
        switch (variant) {
            case ProcessApprovalStatusFilter.APPROVED:
                return { version: translate("processVersionPicker.noApprovedVersionAvailable") };
            case ProcessApprovalStatusFilter.RELEASED:
                return { version: translate("processVersionPicker.noReleasedVersionAvailable") };
            default:
                return { version: translate("processVersionPicker.noVersionAvailable") };
        }
    }

    // Check for latest
    if (latest) {
        if (processVersions[0])
            return {
                process: processVersions[0],
                version: `${translate("processVersionPicker.latest")} (${processVersions[0].version})`,
            };
    } else if (processId) {
        const process = processVersions.find((process) => process.processId === processId);
        if (process) return { process, version: process.version };
    } else {
        // Find by tree node ID
        const process = processVersions.find((process) => process.treeNodeId === treeNodeId);
        if (process) return { process, version: process.version };
    }

    // Nothing found
    return {
        process: undefined,
        version: "",
    };
};
