export const user = {
    rpc: {
        getOne: {
            funcId: 2000,
            funcName: "userOne",
        },
        getAll: {
            funcId: 2001,
            funcName: "userAll",
        },
        insert: {
            funcId: 2002,
            funcName: "userInsert",
        },
        update: {
            funcId: 2003,
            funcName: "userUpdate",
        },
        inserted: {
            funcId: 2004,
            funcName: "userInserted",
        },
        updated: {
            funcId: 2005,
            funcName: "userUpdated",
        },
        login: {
            funcId: 2006,
            funcName: "userLogin",
        },
        passwordUpdateByAdmin: {
            funcId: 2007,
            funcName: "userPasswordUpdateByAdmin",
        },
        passwordUpdateByUser: {
            funcId: 2010,
            funcName: "userPasswordUpdateByUser",
        },
        validate: {
            funcId: 2008,
            funcName: "userValidate",
        },
        getPasswordExpiryState: {
            funcId: 2009,
            funcName: "userPasswordExpiryState",
        },
        getTrainedUsers: {
            funcId: 2011,
            funcName: "userGetTrainedUsers",
        },
    },
    serviceId: 20,
    serviceVersion: 1,
};
