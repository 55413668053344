import { errorPalette } from "@aos/react-components";
import { FormItemTypeEnum, IWorkInstructionsFormInputConfig } from "@kortex/aos-common";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import WorkInstructionsFormInputApproval from "./Approval/WorkInstructionsFormInputApproval";
import WorkInstructionsFormInputCheckbox from "./Checkbox/WorkInstructionsFormInputCheckbox";
import WorkInstructionsFormInputChecklist from "./Checklist/WorkInstructionsFormInputChecklist";
import WorkInstructionsFormInputDropdown from "./Dropdown/WorkInstructionsFormInputDropdown";
import WorkInstructionsFormInputRadio from "./Radio/WorkInstructionsFormInputRadio";
import WorkInstructionsFormInputTextbox from "./Textbox/WorkInstructionsFormInputTextbox";
import WorkInstructionsFormInputTextboxList from "./TextboxList/WorkInstructionsFormInputTextBoxList";

const useStyles = makeStyles({
    errorLabel: {
        color: errorPalette[500],
        fontSize: "0.7rem",
    },
    fullWidth: {
        width: "100%",
    },
});

export interface IOwnProps extends IWorkInstructionsFormInputConfig {
    autoFocus?: boolean;
    onEnterPressed?: (id: string) => void;
}

export default function WorkInstructionFormInput(props: IOwnProps): JSX.Element {
    const classes = useStyles();
    const { autoFocus, onEnterPressed, ...forwardedProps } = props;
    const displayedLabel = props.itemState.label ? props.itemState.label : props.itemProps.label;

    switch (props.itemProps.type) {
        case FormItemTypeEnum.TEXTBOX:
            return <WorkInstructionsFormInputTextbox {...forwardedProps} autoFocus={autoFocus} onEnterPressed={onEnterPressed} />;
        case FormItemTypeEnum.CHECKBOX:
            return <WorkInstructionsFormInputCheckbox {...forwardedProps} classes={{ errorLabel: classes.errorLabel }} />;
        case FormItemTypeEnum.CHECKLIST:
            return <WorkInstructionsFormInputChecklist {...forwardedProps} classes={{ errorLabel: classes.errorLabel }} />;
        case FormItemTypeEnum.RADIO:
            return <WorkInstructionsFormInputRadio {...forwardedProps} classes={{ errorLabel: classes.errorLabel }} />;
        case FormItemTypeEnum.DROPDOWN:
            return <WorkInstructionsFormInputDropdown {...forwardedProps} autoFocus={autoFocus} />;
        case FormItemTypeEnum.APPROVAL:
            return <WorkInstructionsFormInputApproval {...forwardedProps} autoFocus={autoFocus} />;
        case FormItemTypeEnum.TEXTBOXLIST:
            return <WorkInstructionsFormInputTextboxList {...forwardedProps} autoFocus={autoFocus} onEnterPressed={onEnterPressed} />;
        default:
            // FormItemTypeEnum.LABEL
            return <Typography variant="subtitle1">{displayedLabel}</Typography>;
    }
}
