import { theme } from "@aos/react-components";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Backdrop, CircularProgress, List, ListSubheader, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import TrainingPendingCard from "../utilities/TrainingPendingCard";
import TrainingsPendingCardHeader from "../utilities/TrainingsPendingCardHeader";

import { useTrainingCertificationContext } from "./TrainingCertificationContext";
import TrainingCertificationFormDialog from "./trainingCertificationFormDialog/TrainingCertificationFormDialog";

const useStyles = makeStyles({
    root: {
        marginTop: "3px",
        padding: "0px 12px 0px 10px",
        height: "calc(100vh - 213px)", // Header (64px) + Tabs (68px) + margins (23px) + add certification (58px)
        overflow: "auto",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 4,
    },
});

const TrainingCertificationTab: FC = () => {
    const classes = useStyles();
    const translate = useTranslate();

    const { Observer, entities, hasNext, isDataLoading, observerId } = useTrainingCertificationContext();

    return (
        <div id={"trainingCertificationTabId"}>
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={isDataLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <List
                    disablePadding={true}
                    /* ----------------------------------------------------------------------------------------------- */
                    /* HEADER ---------------------------------------------------------------------------------------- */
                    /* ----------------------------------------------------------------------------------------------- */
                    subheader={
                        <ListSubheader disableGutters={true}>
                            <TrainingsPendingCardHeader
                                columnTitle={translate("training.trainingCertificationAuditor")}
                                useContext={useTrainingCertificationContext}
                            />
                        </ListSubheader>
                    }
                >
                    {/* ----------------------------------------------------------------------------------------------- */}
                    {/* TRAINING ROWS --------------------------------------------------------------------------------- */}
                    {/* ----------------------------------------------------------------------------------------------- */}
                    {entities.map(({ auditorName, certifiedUser, date, trainingCertificationPendingId, reference, processes }, index) => (
                        <div key={`trainingCertificationPending${index}`}>
                            <TrainingPendingCard
                                index={index}
                                date={date}
                                pendingId={trainingCertificationPendingId}
                                processLabelList={processes.map((process) => process.label)}
                                reference={reference}
                                trainerName={auditorName}
                                type={"certification"}
                                userName={`${certifiedUser.firstName} ${certifiedUser.lastName}`}
                            />
                            {trainingCertificationPendingId === observerId && !isDataLoading && hasNext && <Observer />}
                        </div>
                    ))}
                </List>
            </div>

            <TrainingCertificationFormDialog />
        </div>
    );
};

export default TrainingCertificationTab;
