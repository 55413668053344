import {
    bomEditItem,
    bomEditSerializedItem,
    bomInsertFollowUp,
    bomInsertFollowUpSerializedItem,
    bomMultipleTraceabilitiesItem,
    bomOverconsumeItem,
    bomOverconsumeNonTraceableItem,
    bomReplaceItem,
    bomReplaceSerializedItem,
} from "@kortex/aos-ui/redux/bom-manager/bom-thunks";
import {
    processPlayerBomFollowUpEditItem,
    processPlayerBomFollowUpEditSerializedItem,
    processPlayerBomFollowUpInsertFollowUp,
    processPlayerBomFollowUpInsertFollowUpSerializedItem,
    processPlayerBomFollowUpMultipleTraceabilitiesItem,
    processPlayerBomFollowUpOverconsumeItem,
    processPlayerBomFollowUpOverconsumeNonTraceableItem,
    processPlayerBomFollowUpReplaceItem,
    processPlayerBomFollowUpReplaceSerializedItem,
} from "@kortex/aos-ui/redux/player-manager/player-thunk-bom-followUp";

const bomFollowUpServiceMap = {
    editItem: bomEditItem,
    editSerializedItem: bomEditSerializedItem,
    insertFollowUp: bomInsertFollowUp,
    insertSerializedItemFollowUp: bomInsertFollowUpSerializedItem,
    overconsumeItem: bomOverconsumeItem,
    overconsumeNonTraceableItem: bomOverconsumeNonTraceableItem,
    replaceItem: bomReplaceItem,
    replaceSerializedItem: bomReplaceSerializedItem,
    multipleTraceabilities: bomMultipleTraceabilitiesItem,
} as const;

const playerBomFollowUpServiceMap = {
    editItem: processPlayerBomFollowUpEditItem,
    editSerializedItem: processPlayerBomFollowUpEditSerializedItem,
    insertFollowUp: processPlayerBomFollowUpInsertFollowUp,
    insertSerializedItemFollowUp: processPlayerBomFollowUpInsertFollowUpSerializedItem,
    overconsumeItem: processPlayerBomFollowUpOverconsumeItem,
    overconsumeNonTraceableItem: processPlayerBomFollowUpOverconsumeNonTraceableItem,
    replaceItem: processPlayerBomFollowUpReplaceItem,
    replaceSerializedItem: processPlayerBomFollowUpReplaceSerializedItem,
    multipleTraceabilities: processPlayerBomFollowUpMultipleTraceabilitiesItem,
} as const;

export type ServiceVariant = "bom" | "player";

export const serviceMap = {
    bom: bomFollowUpServiceMap,
    player: playerBomFollowUpServiceMap,
} as const;
