import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default function LogicalAnd(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M12.5908928,17.058177 C13.9647718,17.3326093 15,18.5454094 15,20 C15,21.6568542 13.6568542,23 12,23 C10.3431458,23 9,21.6568542 9,20 C9,18.5454174 10.0352168,17.3326226 11.4090847,17.0581815 L11.4090847,12.898147 L5.77449308,9.24568743 C5.60951847,9.14221166 5.50004406,8.96021169 5.50004406,8.7529702 C5.49997478,8.74986879 5.49999688,8.74676632 5.50004406,8.74366315 L5.50004406,6.95852817 C4.08116571,6.72050728 3,5.48651353 3,4 C3,2.34314575 4.34314575,1 6,1 C7.65685425,1 9,2.34314575 9,4 C9,5.42231032 8.01020957,6.61344269 6.68185218,6.92217366 L6.68185218,8.43815653 L11.9999887,11.8854834 L17.3181253,8.43815653 L17.3181253,6.92216841 C15.9897792,6.61342921 15,5.42230226 15,4 C15,2.34314575 16.3431458,1 18,1 C19.6568542,1 21,2.34314575 21,4 C21,5.48652141 19.9188228,6.72052036 18.4999334,6.95853195 L18.4999334,8.74366315 C18.4999806,8.74676632 18.5000027,8.74986879 18.4999997,8.75297026 C18.4999334,8.96021169 18.390459,9.14221166 18.2254844,9.24568743 L12.5908928,12.898147 L12.5908928,17.058177 Z"
                id="Path-Copy"
                transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) "
            />
        </SvgIcon>
    );
}
