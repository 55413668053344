import { identity, pickBy } from "lodash";
/**
 * Clean an object, removing all undefined values
 *
 * @param {object} object - object to clean
 * @param {boolean} [removeAllFalsyValues] - If true, removes all undefined, "", 0, null (etc...) values.
 * If false, removes only undefined values.
 */
export function cleanedObject(object, removeAllFalsyValues) {
    return pickBy(object, removeAllFalsyValues ? identity : (prop) => prop !== undefined);
}
