export const workSchedule = {
    rpc: {
        getAll: {
            funcId: 3501,
            funcName: "workScheduleJobProcessAll",
        },
        updated: {
            funcId: 3502,
            funcName: "workSchedulejobProcessUpdated",
        },
        deleted: {
            funcId: 3503,
            funcName: "workSchedulejobProcessDeleted",
        },
        inserted: {
            funcId: 3504,
            funcName: "workSchedulejobProcessInserted",
        },
    },
    serviceId: 35,
    serviceVersion: 1,
};
