import { DraftProcessFromParentTreeNode, ITreeNodeDbModel, TreeNodeId, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { client } from "@kortex/aos-ui/utilitites/kortex-client/client";
import { Button, Checkbox, CircularProgress, Typography, makeStyles } from "@material-ui/core";
import CollapseIcon from "@material-ui/icons/ExpandLess";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import { TreeItem, TreeView } from "@material-ui/lab";
import React, { FC, JSX, useEffect, useState } from "react";

import ProcessIcon from "../../../Icons/Process/Process";

const useStyles = makeStyles({
    spacer: {
        flex: 1,
    },
    treeViewContainer: {
        maxHeight: "500px",
        overflowY: "auto",
    },
    treeItemLabel: {
        display: "flex",
        gap: "5px",
        padding: "5px",
    },
    treeItemLabelCheckbox: {
        marginLeft: "10px",
        padding: 0,
    },
    treeItemLabelRightSection: {
        display: "inline-flex",
        justifyContent: "end",
    },
    treeItemLabelSpacer: {
        flex: 1,
    },
    typographyContainer: {
        alignItems: "baseline",
        display: "flex",
        marginBottom: "10px",
    },
    typographyWarning: {
        marginBottom: "10px",
    },
});

interface IOwnProps {
    diff?: Partial<Pick<ITreeNodeDbModel, "bomId" | "bomRev">>; // To compare with each node
    onSelectedNodesChanged: (selectedNodes: TreeNodeId[]) => void;
    selectedNodes: TreeNodeId[];
    treeNodeId: TreeNodeId;
}

const BomAssignmentDialogChildNodes: FC<IOwnProps> = (props) => {
    const { diff = {}, onSelectedNodesChanged, selectedNodes, treeNodeId } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [nodes, setNodes] = useState<DraftProcessFromParentTreeNode[]>([]);
    const [toggleSelectAll, setToggleSelectAll] = useState<boolean>(false);

    /**
     * Fetch nodes on tree node update
     */
    useEffect((): void => {
        client.services.process
            .getAllDraftsFromParentTreeNode({ treeNodeId })()
            .then((res) => {
                setNodes([...res]);

                // Automatically select all nodes by default
                setToggleSelectAll(false); // reset
                onSelectedNodesChanged(res.map((node) => node.treeNode.treeNodeId));
            });
    }, [treeNodeId]);

    /**
     * Prevent expanding the tree item on click
     */
    const handleItemClick =
        (id: TreeNodeId): ((event: React.MouseEvent<Element, MouseEvent>) => void) =>
        (event: React.MouseEvent<Element, MouseEvent>): void => {
            event.preventDefault();
            event.stopPropagation();

            const selectedNodesCopy = [...selectedNodes];

            if (selectedNodes.includes(id)) {
                selectedNodesCopy.splice(selectedNodesCopy.indexOf(id), 1);
            } else {
                selectedNodesCopy.push(id);
            }

            onSelectedNodesChanged(selectedNodesCopy);
        };

    /**
     * Render recursively nodes
     */
    const renderTree = (node: DraftProcessFromParentTreeNode): JSX.Element => {
        const childNodes = nodes.filter((current) => current.treeNode.parentId === node.treeNode.treeNodeId);
        const id = node.treeNode.treeNodeId.toString();
        const isFolderNode = node.treeNode.nodeType === TreeNodeNodeTypeEnum.FOLDER;
        const labelBomId = isFolderNode ? node.treeNode.bomId : node.process?.bomId;
        const labelBomRev = isFolderNode ? node.treeNode.bomRev : node.process?.bomRev;
        const isBomIdDifferent = Boolean(diff.bomId) && diff.bomId !== labelBomId;
        const isBomRevDifferent = Boolean(diff.bomRev) && diff.bomRev !== labelBomRev;

        return (
            <TreeItem
                collapseIcon={<CollapseIcon />}
                expandIcon={<ExpandIcon />}
                key={id}
                label={
                    <div className={classes.treeItemLabel}>
                        {isFolderNode ? <FolderIcon /> : node.treeNode.nodeType === TreeNodeNodeTypeEnum.PROCESS ? <ProcessIcon /> : null}
                        <Typography color={isBomIdDifferent || isBomRevDifferent ? "error" : undefined}>{node.treeNode.label}</Typography>
                        <div className={classes.treeItemLabelSpacer} />
                        <div className={classes.treeItemLabelRightSection}>
                            <Typography color={isBomIdDifferent ? "error" : undefined}>{labelBomId}</Typography>
                            {labelBomId && (
                                <Typography color={isBomRevDifferent ? "error" : undefined}>&nbsp;{`(${labelBomRev})`}</Typography>
                            )}
                            <Checkbox
                                className={classes.treeItemLabelCheckbox}
                                checked={selectedNodes.includes(node.treeNode.treeNodeId)}
                            />
                        </div>
                    </div>
                }
                nodeId={id}
                onLabelClick={handleItemClick(node.treeNode.treeNodeId)}
            >
                {childNodes.map((current) => renderTree(current))}
            </TreeItem>
        );
    };

    const handleToggleSelectAll = (): void => {
        setToggleSelectAll((prevState) => {
            onSelectedNodesChanged(prevState ? nodes.map((node) => node.treeNode.treeNodeId) : []);
            return !prevState;
        });
    };

    return (
        <>
            <div className={classes.typographyContainer}>
                <div>
                    <Typography variant="h6">{translate("bomAssignmentDialog.selectNodes")}</Typography>
                    <Typography className={classes.typographyWarning} color="textSecondary" variant="caption">
                        {translate("bomAssignmentDialog.onlyUpdatesDraft")}
                    </Typography>
                </div>
                <div className={classes.spacer} />
                <Button color="secondary" onClick={handleToggleSelectAll} variant="contained">
                    {toggleSelectAll ? translate("general.selectAll") : translate("bomAssignmentDialog.unselectAll")}
                </Button>
            </div>

            <div className={classes.treeViewContainer}>
                {nodes.length ? (
                    <TreeView defaultExpanded={nodes.map((node) => node.treeNode.treeNodeId.toString())}>{renderTree(nodes[0])}</TreeView>
                ) : (
                    <CircularProgress hidden={true} />
                )}
            </div>
        </>
    );
};

export default BomAssignmentDialogChildNodes;
