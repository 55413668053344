import { TreeNodeNodeTypeEnum } from "../interfaces/models/treeNode/ITreeNodeDbModel";
export class TreeViewNodeView {
    constructor(baseNode) {
        this.isSelected = false;
        this.hasChildren = false;
        this.hasSubFolder = false;
        this.isExpanded = false;
        this.children = [];
        this.isHidden = false;
        this.level = 0;
        this.order = 0;
        this.baseNode = baseNode;
    }
    toJSON() {
        return { ...this };
    }
}
/**
 * Set node depth level recursively to all childs
 *
 * @param {number} level - level to start with
 * @param {object[]} childs - childs to move
 */
export function setLevelRecur(level, childs) {
    const nextLevel = level + 1;
    for (const child of childs) {
        child.level = nextLevel;
        setLevelRecur(nextLevel, child.children);
    }
}
/**
 * Convert flat list of nodes to hierarchical list (nested parent/children)
 *
 * @param {object[]} list - list to convert
 * @returns {object[]} - converted tree
 */
export function listToTree(list) {
    const map = {};
    let node;
    const roots = [];
    let i;
    for (i = 0; i < list.length; i++) {
        let index = 0;
        if (list[i].baseNode.treeNodeId !== undefined) {
            index = list[i].baseNode.treeNodeId;
        }
        map[index] = i; // initialize the map
        // reset properties that will be set when their children are iterated over
        list[i].children = []; // initialize the children
        list[i].hasChildren = false;
        list[i].hasSubFolder = false;
    }
    for (i = 0; i < list.length; i++) {
        node = list[i];
        if (node.baseNode.parentId) {
            const parent = list[map[node.baseNode.parentId]];
            if (parent) {
                parent.children.push(node);
                parent.hasChildren = true;
                if (!parent.hasSubFolder) {
                    parent.hasSubFolder = node.baseNode.nodeType === TreeNodeNodeTypeEnum.FOLDER;
                }
            }
        }
        else {
            node.isExpanded = true; // Root must always be expanded
            roots.push(node);
        }
    }
    setLevelRecur(-1, roots);
    return roots.sort((a, b) => a.order - b.order);
}
/**
 * Convert hierarchicaly nested list to an ordered flat list
 *
 * @param {object[]} tree - tree to convert
 * @returns {object[]} - converted list
 */
export function treeToList(tree) {
    let flatList = [];
    for (const node of tree) {
        if (node.isHidden) {
            continue;
        }
        flatList.push(node);
        if (node.hasChildren && node.isExpanded) {
            flatList = flatList.concat(treeToList(node.children));
        }
    }
    return flatList;
}
export function getParentPath(childId, list) {
    const childNode = list.find((repoNode) => repoNode.treeNodeId === childId);
    if (!childNode) {
        return [];
    }
    const parentNode = list.find((repoNode) => repoNode.treeNodeId === childNode.parentId);
    if (parentNode && parentNode.parentId) {
        return [...getParentPath(parentNode.treeNodeId, list), childNode.label];
    }
    else {
        return [childNode.label];
    }
}
export function sortList(a, b) {
    return a.baseNode.label.toLowerCase() > b.baseNode.label.toLowerCase() ? 1 : -1;
}
