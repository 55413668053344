import React, { PropsWithChildren, createContext, useContext, useState } from "react";

interface ITrainingPageContext {
    customHeaderComponent: JSX.Element | null;
    setCustomHeaderComponent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
}

const TrainingPageContext = createContext<ITrainingPageContext>({
    customHeaderComponent: null,
    setCustomHeaderComponent: () => void 0,
});

export const TrainingPageProvider = (props: PropsWithChildren<{}>): JSX.Element => {
    const [customHeaderComponent, setCustomHeaderComponent] = useState<JSX.Element | null>(null);

    return (
        <TrainingPageContext.Provider value={{ customHeaderComponent, setCustomHeaderComponent }}>
            {props.children}
        </TrainingPageContext.Provider>
    );
};

export const useTrainingPageContext = (): ITrainingPageContext => useContext<ITrainingPageContext>(TrainingPageContext);
