export const runner = {
    rpc: {
        login: {
            funcId: 20000,
            funcName: "runnerLogin",
        },
        storeResult: {
            funcId: 20001,
            funcName: "runnerStoreResult",
        },
        getProcessTree: {
            funcId: 20002,
            funcName: "runnerGetProcessTree",
        },
        getJob: {
            funcId: 20003,
            funcName: "runnerGetJob",
        },
        getJobProcess: {
            funcId: 20004,
            funcName: "runnerGetJobProcess",
        },
        checkPreviousProcess: {
            funcId: 20005,
            funcName: "runnerCheckPreviousProcess",
        },
        getRunQuantityProcessFromTrackingNumber: {
            funcId: 20006,
            funcName: "runnerGetRunQuantityProcessFromTrackingNumber",
        },
        getDataStoreReadItem: {
            funcId: 20007,
            funcName: "runnerGetDataStoreReadItem",
        },
        getProcess: {
            funcId: 20008,
            funcName: "runnerGetProcess",
        },
        getProcessLatestVersion: {
            funcId: 20009,
            funcName: "runnerGetProcessLatestVersion",
        },
        getInProgressRun: {
            funcId: 20010,
            funcName: "runnerGetInProgressRun",
        },
        getResultSettingItem: {
            funcId: 20011,
            funcName: "runnerGetResultSettingItem",
        },
        getResultSettingMetadata: {
            funcId: 20012,
            funcName: "runnerGetResultSettingMetadata",
        },
        sendEmail: {
            funcId: 20013,
            funcName: "runnerSendEmail",
        },
        getJobProcessAlreadyExecuted: {
            funcId: 20014,
            funcName: "runnerGetJobProcessAlreadyExecuted",
        },
        sendNotification: {
            funcId: 20015,
            funcName: "runnerSendNotification",
        },
        validateTraining: {
            funcId: 20016,
            funcName: "runnerValidateTraining",
        },
        validateTrainer: {
            funcId: 20017,
            funcName: "runnerValidateTrainer",
        },
        signTraining: {
            funcId: 20018,
            funcName: "runnerSignTraining",
        },
        getResultTrainings: {
            funcId: 20019,
            funcName: "runnerGetResultTrainings",
        },
        getReworkItemStatus: {
            funcId: 20020,
            funcName: "runnerGetReworkItemStatus",
        },
        validateUserIsTrainer: {
            funcId: 20021,
            funcName: "runnerValidateUserIsTrainer",
        },
    },
    serviceId: 200,
    serviceVersion: 1,
};
