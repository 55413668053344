// krc
import { KortexLabelIcon } from "@aos/react-components";
import {
    IResultFailProblemOfFailureLog,
    IReworkLogDbModel,
    IReworkUiModel,
    ITokenDecoded,
    LogTypeEnum,
    ReworkStatusEnum,
    TLogFailureLog,
} from "@kortex/aos-common";
// mui
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography/Typography";
import AddIcon from "@material-ui/icons/Add";
import AutorenewSharpIcon from "@material-ui/icons/AutorenewSharp";
// react
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { reworkLogInsert, symptomLogInsert } from "@kortex/aos-ui/redux/rework-manager/rework-log-thunks";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesReworkLogs } from "../../../../../redux/effects";
import { userCanWrite } from "../../../../../utilitites/IUserRights";

import AddFailureTicketStatusLogDialog, { IFailureTicketLogAddItem } from "./AddFailureTicketStatusLogDialog";
import FailureLogCard from "./FailureLogCard";
import SymptomLogCard from "./SymptomLogCard";
import UpdateSymptomDialog from "./UpdateSymptomDialog";
import WorkLogCard from "./WorkLogCard";

const useStyles = makeStyles({
    workLogContent: {
        display: "grid",
    },
    headerContent: {
        display: "flex",
        flexDirection: "row",
        padding: "5px 0",
    },
    title: {
        display: "flex",
        alignItems: "center",
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
    },
    button: {
        marginBottom: "5px",
        display: "flex",
        justifyContent: "flex-start",
    },

    buttonContent: {
        flexFlow: "row",
        flexDirection: "row",
        display: "flex",
        paddingLeft: "5px",
    },
    menuItemButtonReworkProcess: {
        marginLeft: "8px",
        width: "auto",
    },
});

interface IOwnProps {
    rework: IReworkUiModel;
    userInfo?: ITokenDecoded;
}

export default function FailureTicketDetailsWorkLog(props: IOwnProps): JSX.Element {
    const { rework, userInfo } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();

    const [reworkAddDialogOpen, setReworkAddDialogOpen] = useState<boolean>(false);
    const [updateSymptomDialogOpen, setUpdateSymptomDialogOpen] = useState<boolean>(false);
    const allReworkLogs = useEntitiesReworkLogs(rework);

    const failureLog = allReworkLogs.find((log): log is TLogFailureLog => log.type === LogTypeEnum.FAILURE_LOG);

    /**
     * handles the close rework log dialog
     */
    const handleCloseReworkLogDialog = (): void => {
        setReworkAddDialogOpen(false);
    };

    /**
     * handles the close rework log dialog
     */
    const handleCloseUpdateSymptomDialog = (): void => {
        setUpdateSymptomDialogOpen(false);
    };

    /**
     * handles the open rework log dialog
     */
    const handleOpenReworkLogDialog = (): void => {
        setReworkAddDialogOpen(true);
    };

    /**
     * handles the open update symptom dialog
     */
    const handleOpenUpdateSymptomDialog = (): void => {
        setUpdateSymptomDialogOpen(true);
    };

    /**
     * handle save rework log
     */
    const handleSaveReworkLog = (addLog: IFailureTicketLogAddItem): void => {
        const newReworkLog: IReworkLogDbModel = {
            reworkLogId: 0,
            reworkItemStatusId: addLog.reworkItemStatusId,
            comments: addLog.comments,
            workTime: addLog.workingTime,
            reworkId: rework.reworkId,
            userId: userInfo?.userId ?? 0,
            createdOn: Date.now(),
            updatedOn: Date.now(),
            jobProcessId: 0,
            processId: 0,
            resultMasterId: 0,
        };

        dispatch(reworkLogInsert(newReworkLog));
        setReworkAddDialogOpen(false);
    };

    /**
     * handle save symptom updated
     */
    const handleSaveSymptomUpdated = (reworkItemStatusId: number, problems: IResultFailProblemOfFailureLog[]): void => {
        dispatch(symptomLogInsert({ reworkId: rework.reworkId, reworkItemStatusId: reworkItemStatusId, problems: problems }));
        setUpdateSymptomDialogOpen(false);
    };

    return (
        <div>
            {/* Work Logs */}
            <div className={classes.workLogContent} id={"workLogContentId"}>
                {/* Work Logs Title & Buttons */}
                <div className={classes.headerContent}>
                    <Typography className={classes.title} variant="h2">
                        {translate("rework.logs")}
                    </Typography>
                    {userCanWrite(userInfo?.roleRights.rework) && rework.reworkStatus !== ReworkStatusEnum.ARCH && (
                        <List className={classes.buttonContent}>
                            <ListItem
                                id="addWorkLogButtonId"
                                className={classes.menuItemButtonReworkProcess}
                                button={true}
                                onClick={handleOpenReworkLogDialog}
                            >
                                <KortexLabelIcon label={translate("rework.addWorkLog")}>
                                    <AddIcon />
                                </KortexLabelIcon>
                            </ListItem>
                            <ListItem
                                id="updateSymptomButtonId"
                                className={classes.menuItemButtonReworkProcess}
                                button={true}
                                onClick={handleOpenUpdateSymptomDialog}
                            >
                                <KortexLabelIcon label={translate("rework.updateSymptom")}>
                                    <AutorenewSharpIcon />
                                </KortexLabelIcon>
                            </ListItem>
                        </List>
                    )}
                </div>
                {/* Work Logs Cards */}
                {allReworkLogs.map((row, rowIndex): JSX.Element | null =>
                    row.type === LogTypeEnum.FAILURE_LOG ? (
                        <FailureLogCard key={rowIndex} index={rowIndex} workLog={row.log} />
                    ) : row.type === LogTypeEnum.WORK_LOG ? (
                        <WorkLogCard
                            key={rowIndex}
                            index={rowIndex}
                            workLog={row.log}
                            originalResultMasterId={rework.originalResultMasterId}
                        />
                    ) : row.type === LogTypeEnum.SYMPTOM_LOG ? (
                        <SymptomLogCard key={rowIndex} index={rowIndex} symptomLog={row.log} />
                    ) : null
                )}
            </div>
            {/* Dialog to add Work log */}
            {reworkAddDialogOpen && failureLog && (
                <AddFailureTicketStatusLogDialog
                    open={reworkAddDialogOpen}
                    processTreeNodeId={failureLog.log.rework.process.treeNodeProcess.treeNodeId}
                    onClose={handleCloseReworkLogDialog}
                    onSave={handleSaveReworkLog}
                />
            )}
            {/* Dialog to Update symptom */}
            {updateSymptomDialogOpen && failureLog && (
                <UpdateSymptomDialog
                    open={updateSymptomDialogOpen}
                    reworkItemStatusId={failureLog.log.reworkItemStatus.reworkItemStatusId}
                    problems={failureLog.log.resultFailProblems}
                    treeNodeId={failureLog.log.rework.process.treeNodeProcess.treeNodeId}
                    onClose={handleCloseUpdateSymptomDialog}
                    onSave={handleSaveSymptomUpdated}
                />
            )}
        </div>
    );
}
