export const treeWorkZone = {
    rpc: {
        all: {
            funcId: 5201,
            funcName: "treeWorkZoneAll",
        },
        insert: {
            funcId: 5202,
            funcName: "treeWorkZoneInsert",
        },
        inserted: {
            funcId: 5203,
            funcName: "treeWorkZoneInserted",
        },
        update: {
            funcId: 5204,
            funcName: "treeWorkZoneUpdate",
        },
        updated: {
            funcId: 5205,
            funcName: "treeWorkZoneUpdated",
        },
    },
    serviceId: 52,
    serviceVersion: 1,
};
