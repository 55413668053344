import { ErpSettingsActions, ErpSettingsActionType, ErpSettingsState } from "./erp-settings-types";

export const initialState: ErpSettingsState = {
    erpSettings: undefined,
};

/**
 * Failures reducer
 *
 * @param {IErpSettingsState} state - state
 * @param {ErpSettingActions} action - action
 */
export function erpSettingsReducer(state = initialState, action: ErpSettingsActions): ErpSettingsState {
    switch (action.type) {
        case ErpSettingsActionType.ERP_SETTINGS_FETCH: {
            return {
                ...state,
                erpSettings: action.value,
            };
        }
        default: {
            return state;
        }
    }
}
