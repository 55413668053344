import { UserId } from "@kortex/aos-common";
import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { Checkbox, Divider, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useKeybind } from "@kortex/aos-ui/hooks/useKeybind";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";

import { useTranslate } from "../../../hooks/useTranslate";

const useStyles = makeStyles({
    consentSection: {
        alignItems: "center",
        display: "flex",
        marginBottom: "20px",
    },
    formContainer: {
        alignItems: "stretch",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        marginBottom: "15px",
        marginTop: "15px",
        padding: "0 30px",
    },
    textfield: {
        margin: 0,
        marginBottom: "20px",
    },
    typographyContext: {
        marginTop: "20px",
    },
    typographyUserNotTrained: {
        marginBottom: "20px",
    },
});

interface IOwnProps {
    onCompleteTraining: (userId: UserId, username: string, password: string, consented: boolean, context: string) => void;
    open: boolean;
}

function TrainingCompletionDialog(props: IOwnProps): JSX.Element {
    const { onCompleteTraining, open } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const session = useSelectorUserSession();

    const [consentChecked, setConsentChecked] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");

    const context = translate("player.traineeElectronicSignatureRequiredContextTrained");
    const validateButtonDisabled = password === "" || !consentChecked;

    /**
     * Key down event - Enter
     * Validate the user and password
     */
    useKeybind("Enter", () => {
        if (!validateButtonDisabled) {
            handleUserValidation();
        }
    });

    /**
     * Update inner states when dialog opens or closes
     */
    useEffect((): void => {
        if (!open) {
            setPassword("");
            setConsentChecked(false);
        }
    }, [open]);

    /**
     * Handles user validation
     */
    const handleUserValidation = (): void => {
        if (session) {
            onCompleteTraining(session.userId, session.userName, password, consentChecked, context);
            setPassword("");
        }
    };

    /**
     * Handles password change
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - event info
     */
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPassword(event.target.value);
    };

    /**
     * Called when the consent checkbox is checked/unchecked
     */
    const handleConsent = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        setConsentChecked(checked);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={validateButtonDisabled}
            onConfirm={handleUserValidation}
            open={open}
            textLabels={{
                proceedButtonLabel: translate("player.sign"),
                titleLabel: translate("player.trainingCompletionTitle"),
            }}
        >
            <div className={classes.formContainer} id={"trainingCompletionDialogId"}>
                <Typography className={classes.typographyUserNotTrained}>{translate("player.trainingCompletionExplanation")}</Typography>
                <Divider />
                <Typography className={`${classes.typographyUserNotTrained} ${classes.typographyContext}`}>{context}</Typography>
                <KortexTextField
                    className={classes.textfield}
                    label={translate("player.trainerOrTrainedAndCertifiedOperator")}
                    TextFieldProps={{
                        disabled: true,
                        id: "usernameInputId",
                    }}
                    value={session?.fullName ?? ""}
                />
                <KortexTextField
                    className={classes.textfield}
                    label={translate("player.password")}
                    onChange={handlePasswordChange}
                    TextFieldProps={{
                        autoComplete: "current-password",
                        autoFocus: true,
                        id: "passwordInputId",
                    }}
                    type="password"
                    value={password}
                    variant="outlined"
                />
                <div className={classes.consentSection}>
                    <Checkbox checked={consentChecked} onChange={handleConsent} id={"checkboxConsentId"} />
                    <Typography>{translate("general.electronicSignatureConsent")}</Typography>
                </div>
            </div>
        </KortexDialogConfirmation>
    );
}

export default TrainingCompletionDialog;
