import { theme } from "@aos/react-components";
import { getScannerCodeType, isCharAllowedForScannerCode, isScannerCodeValid, ScannerCodeTypeEnum } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { client } from "@kortex/aos-ui/utilitites/kortex-client/client";
import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import { useSnackbar } from "../../layout/snackbarConfigurator";

import { Codes } from "./Codes";

const useStyles = makeStyles({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    root: {
        backgroundColor: theme.palette.grey[200],
        display: "grid",
        padding: "10px 10px 0 10px",
    },
    sendButton: {
        borderRadius: "10px",
        width: "100%",
    },
    sendButtonContainer: {
        borderRadius: "10px",
        padding: "10px",
        width: "75%",
    },
    sendButtonRow: {
        alignItems: "flex-end",
        display: "flex",
        justifyContent: "center",
    },
});

let valueCache = "";

function ScannerPage(): JSX.Element {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const translate = useTranslate();

    const [items, setItems] = useState<string[]>([]);
    const [kanban, setKanban] = useState<string | null>(null);
    const [table, setTable] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isSendButtonDisabled = !(
        (
            items.length >= 1 && // At least have 1 item code
            Boolean(kanban) && // Exactly 1 kanban code
            Boolean(table)
        ) // Exactly 1 table code
    );

    /**
     * Clear cache on mount/unmount
     */
    useEffect(() => {
        valueCache = "";

        return () => {
            valueCache = "";
        };
    }, []);

    /**
     * Add key down event to document, which will enable the scanner
     */
    useEffect((): (() => void) | void => {
        document.addEventListener("keydown", handleKeyDown);

        return (): void => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [items, kanban, table]);

    /**
     * Add a code to the list depending on its type
     */
    const handleKeyDown = (event: KeyboardEvent): void => {
        // Keep F12 for debugging purposes
        if (event.key === "F12") {
            return void 0;
        }

        event.preventDefault();
        event.stopPropagation();

        // ENTER --> add the code
        if (event.key === "Enter") {
            // Validate the code
            if (!isScannerCodeValid(valueCache)) {
                snackbar.error(`${translate("scanner.error.invalidCode")}${valueCache}`);

                // Empty the cache
                valueCache = "";

                return void 0;
            }

            // Add the code to a list
            switch (getScannerCodeType(valueCache)) {
                case ScannerCodeTypeEnum.ITEM:
                    setItems([valueCache, ...items]); // Insert item at the beginning of the list
                    break;
                case ScannerCodeTypeEnum.KANBAN:
                    if (!kanban) {
                        setKanban(valueCache);
                    } else {
                        // Reset kanban list
                        setKanban(null);

                        // Error - a kanban has already been scanned
                        snackbar.error(translate("scanner.error.kanbanAlreadyScanned"));
                    }
                    break;
                case ScannerCodeTypeEnum.TABLE:
                    if (!table) {
                        setTable(valueCache);
                    } else {
                        // Reset table list
                        setTable(null);

                        // Error - a table has already been scanned
                        snackbar.error(translate("scanner.error.tableAlreadyScanned"));
                    }
                    break;
                default:
                    break;
            }

            // Empty the cache
            valueCache = "";
        } else if (isCharAllowedForScannerCode(event.key) /* Prevent keys like Shift and Ctrl to be added to the cache */) {
            // Add value to the cache
            valueCache += event.key;
        }
    };

    /**
     * Remove a code from a list
     */
    const onDelete = (type: ScannerCodeTypeEnum, index = 0): void => {
        switch (type) {
            case ScannerCodeTypeEnum.ITEM:
                const itemsCopy = items.slice();
                itemsCopy.splice(index, 1);
                setItems(itemsCopy);
                break;
            case ScannerCodeTypeEnum.KANBAN:
                setKanban(null);
                break;
            case ScannerCodeTypeEnum.TABLE:
                setTable(null);
                break;
            default:
                break;
        }
    };

    /**
     * Send codes to hub
     */
    const onSend = (): void => {
        if (isSendButtonDisabled) {
            return void 0;
        }

        setLoading(true);

        client.services.kanban
            .insert({
                items,
                kanban: kanban as string,
                table: table as string,
            })()
            .then((res) => {
                if (res.success === true) {
                    reset();
                    snackbar.success(translate("scanner.sentSuccessfully"));
                } else {
                    snackbar.error(`${translate(res.error.localeKey)}${res.error.invalidCode ?? ""}`);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    /**
     * Reset the code lists
     */
    const reset = (): void => {
        setItems([]);
        setKanban(null);
        setTable(null);
    };

    return (
        <div className={classes.root} id="scannerPageId">
            <Codes items={items} kanban={kanban} onDelete={onDelete} table={table} />
            <div className={classes.sendButtonRow}>
                <div className={classes.sendButtonContainer}>
                    <Button
                        className={classes.sendButton}
                        color="secondary"
                        disabled={isSendButtonDisabled}
                        id="scannerPageSendButtonId"
                        onClick={onSend}
                        variant="contained"
                    >
                        {translate("scanner.send")}
                    </Button>
                </div>
            </div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="secondary" />
            </Backdrop>
        </div>
    );
}

export default ScannerPage;
