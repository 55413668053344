import { ProcessType } from "../interfaces/models/process/IProcessDbModel";
import { newProcessUiModel } from "../interfaces/models/process/IProcessUiModel";
/**
 * Create a new process with its actions
 *
 * @param {number} treeNodeId - tree node ID
 * @param {ProcessType} type - process type
 */
export function newProcessWithActions(treeNodeId, type = ProcessType.PROCESS) {
    return {
        process: newProcessUiModel(treeNodeId, type),
        actions: [],
        releaseLogs: [],
        approvalGroups: [],
    };
}
