import { UnwrapAOSPayload, JobProcessesAllRes, IOperatorJobProcessesFiltersOptions } from "@kortex/aos-common";

import { StandardThunk, AppState, StandardDispatch } from "../store";
import { handleAPIError } from "../handleAPIError";

import { setJobProcessListAction, updateJobProcessListAction } from "./work-schedule-actions";

/**
 * Get all job processes
 */
export function jobProcessGetAllActiveJobProcesses(
    limit?: number,
    offset?: number,
    filterOptions?: IOperatorJobProcessesFiltersOptions
): StandardThunk<UnwrapAOSPayload<JobProcessesAllRes>> {
    return async function (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<JobProcessesAllRes>> {
        return api.services.workSchedule
            .getAllJobProcesses({
                limit: limit,
                offset: offset,
                filterOptions: filterOptions,
            })()
            .then((jobProcesses) => {
                if (offset === 0) {
                    dispatch(setJobProcessListAction([...jobProcesses.data]));
                } else {
                    dispatch(updateJobProcessListAction([...jobProcesses.data]));
                }
                return jobProcesses;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return { data: [] };
            });
    };
}
