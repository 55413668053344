import { AOSError } from "../aosError";
export class ExecutionError extends AOSError {
    //---------------------------------------------------------
    // Public
    //---------------------------------------------------------
    constructor(...params) {
        super(...params);
    }
    get name() {
        return "ExecutionError";
    }
}
