var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function Filters(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M12.1260175,16 C12.5700603,14.2747728 14.1361606,13 16,13 C17.8638394,13 19.4299397,14.2747728 19.8739825,16 L22,16 L22,18 L19.8739825,18 C19.4299397,19.7252272 17.8638394,21 16,21 C14.1361606,21 12.5700603,19.7252272 12.1260175,18 L2,18 L2,16 L12.1260175,16 Z M16,19 C17.1045695,19 18,18.1045695 18,17 C18,15.8954305 17.1045695,15 16,15 C14.8954305,15 14,15.8954305 14,17 C14,18.1045695 14.8954305,19 16,19 Z M4.12601749,6 C4.57006028,4.27477279 6.13616057,3 8,3 C9.86383943,3 11.4299397,4.27477279 11.8739825,6 L22,6 L22,8 L11.8739825,8 C11.4299397,9.72522721 9.86383943,11 8,11 C6.13616057,11 4.57006028,9.72522721 4.12601749,8 L2,8 L2,6 L4.12601749,6 Z M8,9 C9.1045695,9 10,8.1045695 10,7 C10,5.8954305 9.1045695,5 8,5 C6.8954305,5 6,5.8954305 6,7 C6,8.1045695 6.8954305,9 8,9 Z", id: "filters-icon" })));
}
