// krc
import { KortexLabelIcon } from "@aos/react-components";
import { IJobProcessOfTheReworkModel, IReworkUiModel, ITokenDecoded, ReworkStatusEnum, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { userCanInsert } from "@kortex/aos-ui/utilitites/IUserRights";
import { Typography } from "@material-ui/core";
// mui
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useEntitiesReworkJobsProcess } from "@kortex/aos-ui/redux/effects";
import { deleteReworkJobProcess } from "@kortex/aos-ui/redux/rework-manager/rework-job-process-thunks";
import { List, ListItem, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import JobIcon from "@material-ui/icons/WorkOutlineOutlined";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import ProcessIcon from "../../../../core/Icons/Process/Process";
import RoutingIcon from "../../../../core/Icons/Routing/Routing";
import AddReworkJobProcessDialog from "../DialogJob/AddReworkJobProcessDialog";
import AddReworkJobProcessFromOriginalJobDialog from "../DialogJob/AddReworkJobProcessFromOriginalJobDialog";

import FailureTicketJobProcessCardContainer from "./FailureTicketJobProcessCardContainer";

const useStyles = makeStyles({
    reworkJobProcessContent: {
        display: "grid",
        padding: "5px 0",
    },
    headerContent: {
        display: "flex",
        flexDirection: "row",
        padding: "5px 0",
    },
    buttonContent: {
        flexFlow: "row",
        flexDirection: "row",
        display: "flex",
        paddingLeft: "5px",
    },
    title: {
        display: "flex",
        alignItems: "center",
    },
    menuItemLargeButtonReworkProcess: {
        padding: "5px",
        width: "85px",
    },
    menuItemButtonReworkProcess: {
        padding: "0px",
        width: "70px",
    },
});

interface IOwnProps {
    rework: IReworkUiModel;
    activeJobId: number;
    userInfo?: ITokenDecoded;
}

export default function FailureTicketJobProcess(props: IOwnProps): JSX.Element {
    const { rework, activeJobId, userInfo } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();
    const allReworkJobsProcess = useEntitiesReworkJobsProcess(rework.reworkId);
    const [repoDialogOpen, setRepoDialogOpen] = useState<boolean>(false);
    const [processSelectorOriginalJobListOpen, setProcessSelectorOriginalJobListOpen] = useState<boolean>(false);
    const [selectedJobProcess, setSelectedJobProcess] = useState<IJobProcessOfTheReworkModel | undefined>(undefined);
    const [filterTypeRepoDialog, setFilterTypeRepoDialog] = useState<TreeNodeNodeTypeEnum | undefined>(undefined);

    /**
     * update rework logs on change
     */
    useEffect((): void => {
        setSelectedJobProcess(undefined);
        handleIsDeletable();
    }, [rework]);

    /**
     * handle request to open the dialog
     */
    const handleProcessSelectorOriginalJobListDialogOpen = (): void => {
        setProcessSelectorOriginalJobListOpen(true);
    };

    /**
     * handle repo selection, to complete the insertion of the selected process in the original job
     */
    const handleScheduleProcessReworkUseOriginalJobDialogOpen = (): void => {
        setFilterTypeRepoDialog(TreeNodeNodeTypeEnum.PROCESS);
        setRepoDialogOpen(true);
    };

    /**
     * handle repo selection, to complete the insertion of the selected routing in the original job
     */
    const handleScheduleRoutingReworkUseOriginalJobDialogOpen = (): void => {
        setFilterTypeRepoDialog(TreeNodeNodeTypeEnum.ROUTING);
        setRepoDialogOpen(true);
    };

    /**
     * handle close repo selection,
     */
    const handleCloseRepoDialog = (): void => {
        setRepoDialogOpen(false);
    };

    /**
     * handle close Process Selector Original Job selection,
     */
    const handleProcessSelectorOriginalJobCloseDialog = (): void => {
        setProcessSelectorOriginalJobListOpen(false);
    };

    /**
     * Job process, handle called when process or routing is selected for check if is deletable.
     */
    const handleIsDeletable = (): boolean => {
        // true for disabled the DeleteIcon
        // if false the process selected is not started, the process can be deleted.
        return selectedJobProcess &&
            selectedJobProcess.qtyStarted === 0 &&
            selectedJobProcess.qtyFailed === 0 &&
            selectedJobProcess.qtyPassed === 0
            ? false
            : true;
    };

    /**
     * Job process , handle called when the user hit delete to delete a process within a job header item is updated
     */
    const handleDeleteProcess = (): void => {
        if (selectedJobProcess) {
            dispatch(deleteReworkJobProcess(selectedJobProcess));
            setSelectedJobProcess(undefined);
        }
    };

    /**
     * Job process, handle that is called when the user select a process in the job
     *
     * @param {IJobProcessOfTheReworkModel} process - process selected
     */
    const handleOnProcessSelected = (process: IJobProcessOfTheReworkModel): void => {
        setSelectedJobProcess(process);
    };

    return (
        <div className={classes.reworkJobProcessContent} id={"reworkProcessContentId"}>
            {/* Rework process Logs Title And Buttons */}
            <div className={classes.headerContent}>
                <Typography className={classes.title} variant="h2">
                    {translate("rework.ProcessReworkItem")}
                </Typography>
                {userCanInsert(userInfo?.roleRights.rework) && rework.reworkStatus !== ReworkStatusEnum.ARCH && (
                    <List className={classes.buttonContent}>
                        <ListItem
                            className={classes.menuItemLargeButtonReworkProcess}
                            button={true}
                            onClick={handleProcessSelectorOriginalJobListDialogOpen}
                            id={"buttonAddProcessFromOriginalJobId"}
                        >
                            <KortexLabelIcon label={translate("rework.addProcessFromOriginalJob")}>
                                <JobIcon />
                            </KortexLabelIcon>
                        </ListItem>
                        <ListItem
                            className={classes.menuItemButtonReworkProcess}
                            button={true}
                            onClick={handleScheduleProcessReworkUseOriginalJobDialogOpen}
                            id={"buttonAddProcessId"}
                        >
                            <KortexLabelIcon label={translate("rework.addProcess")}>
                                <ProcessIcon />
                            </KortexLabelIcon>
                        </ListItem>
                        <ListItem
                            className={classes.menuItemButtonReworkProcess}
                            button={true}
                            onClick={handleScheduleRoutingReworkUseOriginalJobDialogOpen}
                            id={"buttonAddRoutingId"}
                        >
                            <KortexLabelIcon label={translate("rework.addRouting")}>
                                <RoutingIcon />
                            </KortexLabelIcon>
                        </ListItem>
                        <ListItem
                            className={classes.menuItemButtonReworkProcess}
                            button={true}
                            disabled={handleIsDeletable()}
                            onClick={handleDeleteProcess}
                            id={"buttonDeleteProcessId"}
                        >
                            <KortexLabelIcon label={translate("scheduler.delete")}>
                                <DeleteIcon />
                            </KortexLabelIcon>
                        </ListItem>
                    </List>
                )}
            </div>
            {/* Rework process Logs Cards */}
            <FailureTicketJobProcessCardContainer
                allReworkJobsProcess={allReworkJobsProcess}
                onProcessSelected={handleOnProcessSelected}
                userInfo={userInfo}
                key={rework.reworkId}
            />

            {/*
                        -------------- DIALOG --------------
            */}
            {/* Dialog to add process or routing rework in the original job */}
            <AddReworkJobProcessDialog
                repoDialogOpen={repoDialogOpen}
                rework={rework}
                activeJobIdOfFailureTicket={activeJobId}
                onRepoHandleCloseDialogClose={handleCloseRepoDialog}
                filterTypeRepoDialog={filterTypeRepoDialog}
            />

            {/* Dialog to add process rework from original job or fail path process of the routing*/}
            <AddReworkJobProcessFromOriginalJobDialog
                processSelectorOriginalJobListOpen={processSelectorOriginalJobListOpen}
                rework={rework}
                activeJobIdOfFailureTicket={activeJobId}
                onHandleDialogClose={handleProcessSelectorOriginalJobCloseDialog}
            />
        </div>
    );
}
