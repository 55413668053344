import {
    ReworkActionType,
    ReworkDeletedAction,
    ReworkInsertedAction,
    ReworkItemStatusActionType,
    ReworkItemStatusDeletedAction,
    ReworkItemStatusInsertedAction,
    ReworkItemStatusUpdatedAction,
    ReworkJobProcessActionType,
    ReworkJobProcessDeletedAction,
    ReworkJobProcessInsertedAction,
    ReworkJobProcessUpdatedAction,
    ReworkLogActionType,
    ReworkLogInsertedAction,
    ReworkLogUpdatedAction,
    ReworkUpdatedAction,
    RootCauseActionType,
    RootCauseInsertedAction,
    RootCauseUpdatedAction,
    SetReworkItemStatusListAction,
    SetReworkJobProcessListAction,
    SetReworkListAction,
    SetReworkLogListAction,
    SetRootCauseListAction,
} from "./rework-types";

/* ------------------------------------------------ 
 ------------------ REWORK ------------------------
 --------------------------------------------------*/
/**
 * Returns an action
 *
 * @param {SetReworkListAction} reworks - value of the action
 */
export function setReworkListAction(reworks: SetReworkListAction["value"]): SetReworkListAction {
    return { type: ReworkActionType.UPDATE_REWORKS, value: reworks };
}

/**
 * Returns an action
 *
 * @param {ReworkInsertedAction} reworks - value of the action
 */
export function reworkInsertedAction(reworks: ReworkInsertedAction["value"]): ReworkInsertedAction {
    return { type: ReworkActionType.INSERT_REWORK, value: reworks };
}

/**
 * Returns an action
 *
 * @param {ReworkUpdatedAction} rework - value of the action
 */
export function reworkUpdatedAction(rework: ReworkUpdatedAction["value"]): ReworkUpdatedAction {
    return { type: ReworkActionType.UPDATE_REWORK, value: rework };
}

/**
 * Returns an action
 *
 * @param {ReworkDeletedAction} rework - value of the action
 */
export function reworkDeletedAction(rework: ReworkDeletedAction["value"]): ReworkDeletedAction {
    return { type: ReworkActionType.DELETE_REWORK, value: rework };
}

/* ------------------------------------------------ 
 ------------- REWORK ITEM STATUS -----------------
 --------------------------------------------------*/
/**
 * Returns an action
 *
 * @param {SetReworkItemStatusListAction} reworkItemStatuss - value of the action
 */
export function setReworkItemStatusListAction(reworkItemStatuss: SetReworkItemStatusListAction["value"]): SetReworkItemStatusListAction {
    return { type: ReworkItemStatusActionType.UPDATE_REWORK_ITEM_STATUSES, value: reworkItemStatuss };
}

/**
 * Returns an action
 *
 * @param {ReworkItemStatusInsertedAction} reworkItemStatus - value of the action
 */
export function reworkItemStatusInsertedAction(reworkItemStatus: ReworkItemStatusInsertedAction["value"]): ReworkItemStatusInsertedAction {
    return { type: ReworkItemStatusActionType.INSERT_REWORK_ITEM_STATUS, value: reworkItemStatus };
}

/**
 * Returns an action
 *
 * @param {ReworkItemStatusUpdatedAction} reworkItemStatus - value of the action
 */
export function reworkItemStatusUpdatedAction(reworkItemStatus: ReworkItemStatusUpdatedAction["value"]): ReworkItemStatusUpdatedAction {
    return { type: ReworkItemStatusActionType.UPDATE_REWORK_ITEM_STATUS, value: reworkItemStatus };
}

/**
 * Returns an action
 *
 * @param {ReworkItemStatusDeletedAction} reworkItemStatus - value of the action
 */
export function reworkItemStatusDeletedAction(reworkItemStatus: ReworkItemStatusDeletedAction["value"]): ReworkItemStatusDeletedAction {
    return { type: ReworkItemStatusActionType.DELETE_REWORK_ITEM_STATUS, value: reworkItemStatus };
}

/* ------------------------------------------------ 
 ---------------- REWORK LOG ----------------------
 --------------------------------------------------*/
/**
 * Returns an action
 *
 * @param {SetReworkLogListAction} reworkLogs - value of the action
 */
export function setReworkLogListAction(reworkLogs: SetReworkLogListAction["value"]): SetReworkLogListAction {
    return { type: ReworkLogActionType.UPDATE_REWORK_LOGS, value: reworkLogs };
}

/**
 * Returns an action
 *
 * @param {ReworkLogInsertedAction} reworkLog - value of the action
 */
export function reworkLogInsertedAction(reworkLog: ReworkLogInsertedAction["value"]): ReworkLogInsertedAction {
    return { type: ReworkLogActionType.INSERT_REWORK_LOG, value: reworkLog };
}

/**
 * Returns an action
 *
 * @param {ReworkLogUpdatedAction} reworkLog - value of the action
 */
export function reworkLogUpdatedAction(reworkLog: ReworkLogUpdatedAction["value"]): ReworkLogUpdatedAction {
    return { type: ReworkLogActionType.UPDATE_REWORK_LOG, value: reworkLog };
}

/* ------------------------------------------------ 
 ------------ REWORK JOB PROCESS ------------------
 --------------------------------------------------*/
/**
 * Returns an action
 *
 * @param {SetReworkJobProcessListAction} reworkJobsProcess - value of the action
 */
export function setReworkJobProcessListAction(reworkJobsProcess: SetReworkJobProcessListAction["value"]): SetReworkJobProcessListAction {
    return { type: ReworkJobProcessActionType.UPDATE_REWORK_JOBS_PROCESS, value: reworkJobsProcess };
}

/**
 * Returns an action
 *
 * @param {ReworkJobProcessInsertedAction} reworkJobProcess - value of the action
 */
export function jobProcessReworkInsertedAction(reworkJobProcess: ReworkJobProcessInsertedAction["value"]): ReworkJobProcessInsertedAction {
    return { type: ReworkJobProcessActionType.INSERT_REWORK_JOB_PROCESS, value: reworkJobProcess };
}

/**
 * Returns an action
 *
 * @param {ReworkJobProcessUpdatedAction} reworkJobProcess - value of the action
 */
export function jobProcessReworkUpdatedAction(reworkJobProcess: ReworkJobProcessUpdatedAction["value"]): ReworkJobProcessUpdatedAction {
    return { type: ReworkJobProcessActionType.UPDATE_REWORK_JOB_PROCESS, value: reworkJobProcess };
}

/**
 * Returns an action
 *
 * @param {ReworkJobProcessDeletedAction} reworkJobProcess - value of the action
 */
export function jobProcessReworkDeletedAction(reworkJobProcess: ReworkJobProcessDeletedAction["value"]): ReworkJobProcessDeletedAction {
    return { type: ReworkJobProcessActionType.DELETE_REWORK_JOB_PROCESS, value: reworkJobProcess };
}

/* ------------------------------------------------ 
 ------------- ROOT CAUSE -----------------
 --------------------------------------------------*/
/**
 * Returns an action
 *
 * @param {SetRootCauseListAction} rootCausesType - value of the action
 */
export function setRootCauseListAction(rootCausesType: SetRootCauseListAction["value"]): SetRootCauseListAction {
    return { type: RootCauseActionType.SET_ROOT_CAUSE, value: rootCausesType };
}

/**
 * Returns an action
 *
 * @param {RootCauseInsertedAction} rootCause - value of the action
 */
export function rootCauseInsertedAction(rootCause: RootCauseInsertedAction["value"]): RootCauseInsertedAction {
    return { type: RootCauseActionType.INSERT_ROOT_CAUSE, value: rootCause };
}

/**
 * Returns an action
 *
 * @param {RootCauseUpdatedAction} rootCause - value of the action
 */
export function rootCauseUpdatedAction(rootCause: RootCauseUpdatedAction["value"]): RootCauseUpdatedAction {
    return { type: RootCauseActionType.UPDATE_ROOT_CAUSE, value: rootCause };
}
