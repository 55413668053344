// krc
import { getDateStr, IReworkUiModel, ReworkStatusEnum } from "@kortex/aos-common";
import { theme } from "@aos/react-components";
// mui
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography/Typography";
import ProcessSchedulerIcon from "@material-ui/icons/TodayOutlined";
import ManualIcon from "@material-ui/icons/PanToolOutlined";
// react
import React, { useEffect, useState } from "react";
import KortexLabelText from "@kortex/aos-ui/components/core/KortexLabelText";

import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    detailContent: {
        display: "flex",
        flexDirection: "row",
        padding: "5px 0",
    },
    headerContent: {
        display: "flex",
        flexDirection: "row",
        padding: "5px 0",
    },
    title: {
        display: "flex",
        alignItems: "center",
    },
    text: {
        display: "flex",
        minWidth: "150px",
        padding: "7px",
    },
    statusIcon: {
        width: "16px",
        height: "16px",
        color: theme.palette.grey[500],
        paddingLeft: "20px",
    },
});

interface IOwnProps {
    rework: IReworkUiModel;
}

export default function FailureTicketDetailsInfos(props: IOwnProps): JSX.Element {
    const { rework } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [manualFailureTicket, setManualFailureTicket] = useState<boolean>(false);

    /**
     * failure ticket is manual failure ticket
     */
    useEffect((): void => {
        setManualFailureTicket(!rework.reworkResultMasterId);
    }, [rework]);

    return (
        <div>
            {/* Tichet Details info */}
            <div>
                <div className={classes.headerContent}>
                    <Typography className={classes.title} variant="h2">{`${translate("rework.reworkDetails")} `}</Typography>
                    {manualFailureTicket && <ManualIcon className={classes.statusIcon} />}
                    {rework.jobRework ? <ProcessSchedulerIcon className={classes.statusIcon} /> : <></>}
                </div>
                <Grid container={true}>
                    {/* FAILURE TICKET NUMBER */}
                    <Grid item={true}>
                        <KortexLabelText
                            label={translate("rework.failureTicketNumber")}
                            text={rework.reworkId.toString()}
                            className={classes.text}
                            id={"numberFailureTicketId"}
                        />
                    </Grid>
                    {/* FAILURE TICKET STATUS */}
                    <Grid item={true}>
                        <KortexLabelText
                            label={translate("rework.reworkStatus")}
                            text={translate(`rework.status.${ReworkStatusEnum[rework.reworkStatus]}`)}
                            className={classes.text}
                            id={"statusFailureTicketId"}
                        />
                    </Grid>
                    {/* ITEM STATUS */}
                    <Grid item={true}>
                        <KortexLabelText
                            label={translate("rework.reworkItemStatus")}
                            text={rework.reworkItemStatus.title}
                            className={classes.text}
                            id={"itemStatusFailureTicketId"}
                        />
                    </Grid>
                    {/* TRACKING ID */}
                    <Grid item={true}>
                        <KortexLabelText
                            label={translate("rework.trackingId")}
                            text={rework.trackingId ? rework.trackingId : ""}
                            className={classes.text}
                            id={"trackingIdFailureTicketId"}
                        />
                    </Grid>
                    {/* PART NUMBER */}
                    <Grid item={true}>
                        <KortexLabelText
                            label={translate("rework.partNumber")}
                            text={
                                (rework.partNumber || rework.jobOfFailure?.partNumber || rework.jobRework?.partNumber) ??
                                translate("general.na")
                            }
                            className={classes.text}
                            id={"partNumberFailureTicketId"}
                        />
                    </Grid>
                    {/* FAILURE TICKET JOB */}
                    <Grid item={true}>
                        <KortexLabelText
                            label={translate("rework.scheduledJobRefId")}
                            text={rework.jobRework?.jobRefId ?? translate("general.na")}
                            className={classes.text}
                            id={"jobFailureTicketId"}
                        />
                    </Grid>
                    {/* CREATE ON */}
                    <Grid item={true}>
                        <KortexLabelText
                            label={translate("rework.date")}
                            text={getDateStr(rework.createdOn)}
                            className={classes.text}
                            id={"dateFailureTicketId"}
                        />
                    </Grid>
                    {/* QTY ITEM FAIL */}
                    <Grid item={true}>
                        <KortexLabelText
                            label={translate("rework.quantity")}
                            text={`${rework.quantity}`}
                            className={classes.text}
                            id={"quantityFailureTicketId"}
                        />
                    </Grid>
                    {/* TRACKING INSTANCE */}
                    <Grid item={true}>
                        <KortexLabelText
                            label={translate("rework.trackingInstance")}
                            text={rework.trackingInstances}
                            className={classes.text}
                            id={"trackingInstanceFailureTicketId"}
                        />
                    </Grid>
                    {/* original job */}
                    <Grid item={true}>
                        <KortexLabelText
                            label={translate("rework.jobRefId")}
                            text={rework.jobOfFailure?.jobRefId ?? translate("general.na")}
                            className={classes.text}
                            id={"originalJobRefFailureTicketId"}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
