var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Condition(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M4.06043828,16.8703327 L2,14.7497277 L2.72845617,14 L4.78889445,16.1206049 L6.84933272,14 L7.57778889,14.7497277 L5.51735062,16.8703327 L7.57778889,18.9909376 L6.84933272,19.7406654 L4.78889445,17.6200604 L2.72845617,19.7406654 L2,18.9909376 L4.06043828,16.8703327 Z M4.0982065,11.6344839 L2,9.47015796 L2.69514152,8.75344674 L4.4000572,10.5112646 L7.80103953,6.00216775 L8.57778889,6.62460154 L4.83464355,11.5868383 L4.0982065,11.6344839 Z M11.1612473,14.3396698 L22,14.3396698 L22,16.17342 L11.1612473,16.17342 L11.1612473,14.3396698 Z M11.1612473,6.00216775 L22,6.00216775 L22,7.83591796 L11.1612473,7.83591796 L11.1612473,6.00216775 Z M11.1612473,17.6746707 L16.1637485,17.6746707 L16.1637485,19.5084209 L11.1612473,19.5084209 L11.1612473,17.6746707 Z M11.1612473,9.33716858 L16.9974987,9.33716858 L16.9974987,11.1709188 L11.1612473,11.1709188 L11.1612473,9.33716858 Z", id: "icons-condition" })));
}
export default Condition;
