import { IErpSettingsDbModel } from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export enum ErpSettingsActionType {
    ERP_SETTINGS_FETCH = "@@ERP_SETTINGS_FETCH",
}

interface IErpSettingsState {
    erpSettings: IErpSettingsDbModel | undefined;
}

export type ErpSettingsState = IErpSettingsState;

export type SetErpSettingsListAction = ComplexAction<ErpSettingsActionType.ERP_SETTINGS_FETCH, IErpSettingsDbModel | undefined>;

export type ErpSettingsActions = SetErpSettingsListAction;
