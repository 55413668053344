import { KortexTextField, primaryPalette, secondaryPalette } from "@aos/react-components";
import { ITrainingReadingCertificatePendingFilters } from "@kortex/aos-common";
import KortexSearchField from "@kortex/aos-ui/components/core/KortexSearchField";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { deepClone } from "@kortex/utilities";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";

import { useReadingCertificateContext } from "../../context";

const useStyles = makeStyles({
    clearFilterLabel: {
        color: secondaryPalette[500],
        "&:hover": {
            color: primaryPalette[500],
        },
    },
    filterDateInput: {
        flex: 1,
    },
    filterInput: {
        flex: 2,
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    searchBox: {
        width: "100%",
    },
});

const UserSearchBar: FC = () => {
    const { filters, setFilters } = useReadingCertificateContext();
    const classes = useStyles();
    const translate = useTranslate();

    const handleFilterChange =
        (
            key: keyof ITrainingReadingCertificatePendingFilters
        ): ((e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
            const newFilters = deepClone(filters);
            setFilters({
                ...newFilters,
                [key]: e.target.value,
            });
        };

    const handleSearchTextChange = (userName: string): void => {
        setFilters((prevState) => ({ ...prevState, userName }));
    };

    const handleClearFilters = (): void => {
        setFilters({});
    };

    return (
        <KortexSearchField
            className={classes.searchBox}
            InputProps={{ id: "readingCertificateSearchBarId" }}
            onChange={handleSearchTextChange}
            hideFiltersButton={false}
            placeholder={translate("general.searchUser")}
            isFilterActive={true}
        >
            <React.Fragment>
                {/* PROCESS NAME */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("general.process")}</Typography>
                    <KortexTextField
                        className={classes.filterInput}
                        onChange={handleFilterChange("processName")}
                        value={filters.processName ?? ""}
                        variant={"standard"}
                        type={"text"}
                    />
                </div>

                {/* PROCESS VERSION */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("training.processVersion")}</Typography>
                    <KortexTextField
                        className={classes.filterInput}
                        onChange={handleFilterChange("processVersion")}
                        value={filters.processVersion ?? ""}
                        variant={"standard"}
                        type={"text"}
                    />
                </div>

                {/* DATE */}
                <div className={classes.optionsItem}>
                    <Typography className={classes.filterLabel}>{translate("general.date")}</Typography>
                    <KortexTextField
                        className={classes.filterDateInput}
                        onChange={handleFilterChange("dateFrom")}
                        value={filters.dateFrom ?? ""}
                        variant={"standard"}
                        type={"date"}
                        label={translate("general.from")}
                    />
                    <KortexTextField
                        className={classes.filterDateInput}
                        onChange={handleFilterChange("dateTo")}
                        value={filters.dateTo ?? ""}
                        variant={"standard"}
                        type={"date"}
                        label={translate("general.to")}
                    />
                </div>

                <div className={classes.optionsItem}>
                    <div className={classes.filterLabel} />
                    <Typography onClick={handleClearFilters} className={classes.clearFilterLabel}>
                        {translate("scheduler.clearFilters")}
                    </Typography>
                </div>
            </React.Fragment>
        </KortexSearchField>
    );
};

export default UserSearchBar;
