import { createAOSPayload } from "./AOSPayload";
/**
 * Wrap one client command service
 *
 * @param {object} service - service
 */
function wrapClientServiceCommandClient(service) {
    // Create a wrapper for command service
    const wrappedService = async (cb) => service(async (req, res) => cb({
        header: req.header,
        payload: req.payload.payload,
    }, res));
    wrappedService.funcId = service.funcId;
    wrappedService.serviceId = service.serviceId;
    wrappedService.serviceVersion = service.serviceVersion;
    wrappedService.trigger = service.trigger;
    return wrappedService;
}
/**
 * Wrap one server command service
 *
 * @param {object} service - service
 */
function wrapClientServiceCommandServer(service) {
    // Create a wrapper for the request service
    return (payload, connectionHandle) => async (options) => 
    // Get the storage token, wrap the payload, and call the service
    // Return the unwrap the payload
    await (await service(createAOSPayload(payload), connectionHandle)(options)).payload;
}
/**
 * Wrap one client notification service
 *
 * @param {object} service - service
 */
function wrapClientServiceNotification(service) {
    // Create a wrapper for the notification service
    const wrappedService = (requestPayload, cb) => async (options) => 
    // Unwrap the notification payload
    service(requestPayload, (response) => cb(response.payload))(options);
    // Assign service properties to the wrapped one
    wrappedService.funcId = service.funcId;
    wrappedService.serviceId = service.serviceId;
    wrappedService.serviceVersion = service.serviceVersion;
    return wrappedService;
}
/**
 * Wrap one client request service
 *
 * @param {object} service - service
 * @param {Function} payloadModifier - callback to modify the payload (ex: update the token)
 * @param {Function} tokenResCb - callback for token received from response
 */
function wrapClientServiceRequest(service, payloadModifier, tokenResCb, onError = () => void 0) {
    // Create a wrapper for the request service
    const wrappedService = (payload) => async (options) => {
        // Call the service
        const servicePromise = service(createAOSPayload(payload, payloadModifier))(options);
        // Attach a callback for error handling
        servicePromise.catch(onError);
        // Wait for the service response
        const response = await servicePromise;
        // Callback to set the token from response to client storage
        tokenResCb(response.security?.token);
        // Return the unwrap the payload
        return response.payload;
    };
    // Assign service properties to the wrapped one
    wrappedService.funcId = service.funcId;
    wrappedService.serviceId = service.serviceId;
    wrappedService.serviceVersion = service.serviceVersion;
    return wrappedService;
}
// Client service wrap helper
export const AOSClientServiceWrapper = {
    commandClient: wrapClientServiceCommandClient,
    commandServer: wrapClientServiceCommandServer,
    notification: wrapClientServiceNotification,
    request: wrapClientServiceRequest,
};
