import { EnumActionStatus } from "./ActionEnums";
export class ActionStepState {
    constructor(description, targetTime, canRetry, minimumTime) {
        this.status = EnumActionStatus.IDLE;
        this.description = "Action Description";
        this.startTime = 0;
        this.endTime = 0;
        this.targetTime = 0;
        this.pauseDuration = 0;
        this.canRetry = false;
        this.isCompleted = false;
        this.failures = [];
        this.electronicSignatureId = 0;
        this.minimumTime = 0;
        this.approverId = 0;
        this.approvedOn = 0;
        this.comment = "";
        this.description = description;
        this.startTime = 0;
        this.endTime = 0;
        this.targetTime = targetTime;
        this.canRetry = canRetry;
        this.minimumTime = minimumTime;
    }
}
