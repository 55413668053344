import { IResultSettingItemDbModel, ResultSettingMetadataUiModel } from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export interface IResultSettingState {
    resultSettingItems: IResultSettingItemDbModel[];
    resultSettingMetadata: ResultSettingMetadataUiModel[];
}

export enum ResultSettingActionType {
    UPSERT_RESULT_SETTING_ITEM = "@@RESULT_SETTING/UPSERT_RESULT_SETTING_ITEM",
    UPSERT_RESULT_SETTING_METADATA = "@@RESULT_SETTING/UPSERT_RESULT_SETTING_METADATA",
    SET_RESULT_SETTING_ITEM_LIST = "@@RESULT_SETTING/SET_RESULT_SETTING_ITEM_LIST",
    SET_RESULT_SETTING_METADATA_LIST = "@@RESULT_SETTING/SET_RESULT_SETTING_METADATA_LIST",
}

export type ResultSettingState = IResultSettingState;

// Result setting items
export type ResultSettingItemUpsertedAction = ComplexAction<
    ResultSettingActionType.UPSERT_RESULT_SETTING_ITEM,
    IResultSettingItemDbModel[]
>;
export type SetResultSettingItemListAction = ComplexAction<
    ResultSettingActionType.SET_RESULT_SETTING_ITEM_LIST,
    IResultSettingItemDbModel[]
>;

// Result setting metadata
export type ResultSettingMetadataUpsertedAction = ComplexAction<
    ResultSettingActionType.UPSERT_RESULT_SETTING_METADATA,
    ResultSettingMetadataUiModel[]
>;
export type SetResultSettingMetadataListAction = ComplexAction<
    ResultSettingActionType.SET_RESULT_SETTING_METADATA_LIST,
    ResultSettingMetadataUiModel[]
>;

export type ResultSettingActions =
    | SetResultSettingItemListAction
    | SetResultSettingMetadataListAction
    | ResultSettingItemUpsertedAction
    | ResultSettingMetadataUpsertedAction;
