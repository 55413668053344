import { config } from "winston";
export const LogLevels = {
    EMERG: "emerg",
    ALERT: "alert",
    CRIT: "crit",
    ERROR: "error",
    WARNING: "warning",
    NOTICE: "notice",
    INFO: "info",
    DEBUG: "debug",
};
export const logLevelsMap = {
    [LogLevels.EMERG]: config.syslog.levels.emerg,
    [LogLevels.ALERT]: config.syslog.levels.alert,
    [LogLevels.CRIT]: config.syslog.levels.crit,
    [LogLevels.ERROR]: config.syslog.levels.error,
    [LogLevels.WARNING]: config.syslog.levels.warning,
    [LogLevels.NOTICE]: config.syslog.levels.notice,
    [LogLevels.INFO]: config.syslog.levels.info,
    [LogLevels.DEBUG]: config.syslog.levels.debug,
};
