export const kanban = {
    rpc: {
        insert: {
            funcId: 14001,
            funcName: "kanbanInsert",
        },
    },
    serviceId: 140,
    serviceVersion: 1,
};
