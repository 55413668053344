import { TNotification } from "@kortex/aos-common";

import { AppState, StandardDispatch, StandardThunk, store } from "../store";

import { allNotificationsDeletedAction, notificationDeletedAction, notificationInsertedAction } from "./notification-actions";

// thunks
/**
 * Retrieves notifications
 */
export function notificationsGetAll(): StandardThunk<TNotification[]> {
    return async function (_: StandardDispatch, getState: () => AppState): Promise<TNotification[]> {
        return getState().notification.notifications;
    };
}

/**
 * Delete notification
 */
export function notificationDelete(notification: TNotification): void {
    store.dispatch(notificationDeletedAction(notification));
}

/**
 * Delete all notifications
 */
export function allNotificationsDelete(): void {
    store.dispatch(allNotificationsDeletedAction());
}

/**
 * Insert notification
 */
export function notificationInsert(notification: TNotification): void {
    const allNotification = notificationsGetAll();

    // Limitation of the number of notification recorded in redux.
    if (allNotification.length >= 25) {
        notificationDelete(allNotification[0]);
    }

    store.dispatch(notificationInsertedAction([notification]));
}
