var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Diamond(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("rect", { id: "icons-diamond", transform: "translate(11.899495, 11.899495) rotate(-315.000000) translate(-11.899495, -11.899495) ", x: "4.89949494", y: "4.89949494", width: "14", height: "14" })));
}
export default Diamond;
