import { ApprovalStatusEnum } from "../interfaces/models";
/**
 * Check if process is currently approved and release (and active)
 *
 * @param {IProcessDbModel} process - process to look at
 *
 * @returns {boolean} Return true if process is approved, released and still active
 */
export function isProcessReleased(process) {
    return (process.versionStatus === ApprovalStatusEnum.APPROVED && Boolean(process.releasedOn && process.releasedOn <= new Date().getTime()));
}
/**
 * Check if process is currently retired
 *
 * @param {IProcessDbModel} process - process to look at
 *
 * @returns {boolean} Return true if process is retired
 */
export function isProcessRetired(process) {
    return Boolean(process.retiredOn && process.retiredOn <= Date.now());
}
/**
 * Get the version of the process
 *
 * @param {IProcessDbModel} process - process to get version
 *
 * @returns {number} Process version, 0 if not versionned
 */
export function getProcessVersion(process) {
    const version = process.version ? parseFloat(process.version) : 0;
    return isNaN(version) ? 0 : version;
}
/**
 * Verify if a process in the list if not from the same treeNode
 *
 * @param {IProcessDbModel[]} processList - process list to look at
 *
 * @returns {boolean} true if from different node, false if all from the same node
 */
export function isProcessListFromDifferentNode(processList) {
    if (processList.length === 0) {
        return false;
    }
    for (let counter = 1; counter < processList.length; counter++) {
        if (processList[counter].treeNodeId !== processList[0].treeNodeId) {
            return true;
        }
    }
    return false;
}
/**
 * Get the latest approved, release and still active version from the provided process list
 *
 * @param {IProcessDbModel[]} processList - process list to look at
 *
 * @returns {IProcessDbModel | undefined} Process found or undefined if not found
 */
export function getLatestReleasedVersion(processList) {
    let retProcess = undefined;
    for (let counter = 0; counter < processList.length; counter++) {
        if (retProcess === undefined) {
            retProcess = getProcessVersion(processList[counter]) > 0 ? processList[counter] : undefined;
        }
        else {
            const processVersion = getProcessVersion(processList[counter]);
            if (processVersion > parseFloat(retProcess.version ? retProcess.version : "0")) {
                retProcess = processList[counter];
            }
        }
    }
    return retProcess;
}
export var ProcessReleaseStatus;
(function (ProcessReleaseStatus) {
    ProcessReleaseStatus[ProcessReleaseStatus["ARCHIVED"] = 0] = "ARCHIVED";
    ProcessReleaseStatus[ProcessReleaseStatus["AWAITING"] = 1] = "AWAITING";
    ProcessReleaseStatus[ProcessReleaseStatus["CANCELLED"] = 2] = "CANCELLED";
    ProcessReleaseStatus[ProcessReleaseStatus["HOLD"] = 3] = "HOLD";
    ProcessReleaseStatus[ProcessReleaseStatus["NONE"] = 4] = "NONE";
    ProcessReleaseStatus[ProcessReleaseStatus["RELEASE_SCHEDULED"] = 5] = "RELEASE_SCHEDULED";
    ProcessReleaseStatus[ProcessReleaseStatus["RELEASED"] = 6] = "RELEASED";
    ProcessReleaseStatus[ProcessReleaseStatus["RETIRED"] = 7] = "RETIRED";
})(ProcessReleaseStatus || (ProcessReleaseStatus = {}));
export function getProcessReleaseStatus(process) {
    if (process.archived) {
        return ProcessReleaseStatus.ARCHIVED;
    }
    if (process.versionStatus === ApprovalStatusEnum.CANCELLED) {
        return ProcessReleaseStatus.CANCELLED;
    }
    if (process.versionStatus === ApprovalStatusEnum.HOLD) {
        return ProcessReleaseStatus.HOLD;
    }
    if (process.versionStatus === ApprovalStatusEnum.APPROVED) {
        if (process.retiredOn && process.retiredOn <= new Date().getTime()) {
            return ProcessReleaseStatus.RETIRED;
        }
        else if (process.releasedOn) {
            if (process.releasedOn <= new Date().getTime()) {
                return ProcessReleaseStatus.RELEASED;
            }
            return ProcessReleaseStatus.RELEASE_SCHEDULED;
        }
        return ProcessReleaseStatus.AWAITING;
    }
    return ProcessReleaseStatus.NONE;
}
