import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceRequestWrapped,
    ReportGetAuditReq,
    ReportGetAuditRes,
    ReportGetDataStoreTableReq,
    ReportGetDataStoreTableRes,
    ReportGetDataStoreValueReq,
    ReportGetDataStoreValueRes,
    ReportGetElectronicSignatureReq,
    ReportGetElectronicSignatureRes,
    ReportGetProcessResultReq,
    ReportGetProcessResultRes,
    ReportGetProcessStepResultReq,
    ReportGetProcessStepResultRes,
    ReportGetReworkReq,
    ReportGetReworkRes,
    ReportGetSummaryReq,
    ReportGetSummaryRes,
    ReportGetEntityReq,
    ReportGetEntityRes,
    ReportGetTrainingReq,
    ReportGetTrainingRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { reportGetElectronicSignature } from "./getElectronicSignature";
import { reportGetSummary } from "./getSummary";
import { reportGetProcessResult } from "./getProcessResult";
import { reportGetProcessStepResult } from "./getProcessStepResult";
import { reportGetRework } from "./getRework";
import { reportGetAuditTrail } from "./getAudit";
import { reportGetDataStoreValue } from "./getDataStoreValue";
import { reportGetDataStoreTable } from "./getDataStoreTable";
import { reportGetEntity } from "./getEntity";
import { reportGetTraining } from "./getTraining";

interface IAOSClientServiceWrappedReport {
    getAudit: AOSClientServiceRequestWrapped<ReportGetAuditReq, ReportGetAuditRes>;
    getDataStoreTable: AOSClientServiceRequestWrapped<ReportGetDataStoreTableReq, ReportGetDataStoreTableRes>;
    getDataStoreValue: AOSClientServiceRequestWrapped<ReportGetDataStoreValueReq, ReportGetDataStoreValueRes>;
    getElectronicSignature: AOSClientServiceRequestWrapped<ReportGetElectronicSignatureReq, ReportGetElectronicSignatureRes>;
    getProcessResult: AOSClientServiceRequestWrapped<ReportGetProcessResultReq, ReportGetProcessResultRes>;
    getProcessStepResult: AOSClientServiceRequestWrapped<ReportGetProcessStepResultReq, ReportGetProcessStepResultRes>;
    getRework: AOSClientServiceRequestWrapped<ReportGetReworkReq, ReportGetReworkRes>;
    getSummary: AOSClientServiceRequestWrapped<ReportGetSummaryReq, ReportGetSummaryRes>;
    getEntity: AOSClientServiceRequestWrapped<ReportGetEntityReq, ReportGetEntityRes>;
    getTraining: AOSClientServiceRequestWrapped<ReportGetTrainingReq, ReportGetTrainingRes>;
}

type AOSClientServiceWrappedReport = IAOSClientServiceWrappedReport;

// ADD_API_CALL Rework
export function generateReportServiceClient(router: IRouterClient): AOSClientServiceWrappedReport {
    return {
        getAudit: AOSClientServiceWrapperUI.request(reportGetAuditTrail(router)),
        getDataStoreTable: AOSClientServiceWrapperUI.request(reportGetDataStoreTable(router)),
        getDataStoreValue: AOSClientServiceWrapperUI.request(reportGetDataStoreValue(router)),
        getElectronicSignature: AOSClientServiceWrapperUI.request(reportGetElectronicSignature(router)),
        getProcessResult: AOSClientServiceWrapperUI.request(reportGetProcessResult(router)),
        getProcessStepResult: AOSClientServiceWrapperUI.request(reportGetProcessStepResult(router)),
        getRework: AOSClientServiceWrapperUI.request(reportGetRework(router)),
        getSummary: AOSClientServiceWrapperUI.request(reportGetSummary(router)),
        getEntity: AOSClientServiceWrapperUI.request(reportGetEntity(router)),
        getTraining: AOSClientServiceWrapperUI.request(reportGetTraining(router)),
    };
}

generateReportServiceClient.serviceId = 40;
