export const bomFollowUpSymptom = {
    rpc: {
        getAll: {
            funcId: 3801,
            funcName: "bomFollowUpSymptomAll",
        },
        insert: {
            funcId: 3802,
            funcName: "bomFollowUpSymptomInsert",
        },
        inserted: {
            funcId: 3803,
            funcName: "bomFollowUpSymptomInserted",
        },
        update: {
            funcId: 3804,
            funcName: "bomFollowUpSymptomUpdate",
        },
        updated: {
            funcId: 3805,
            funcName: "bomFollowUpSymptomUpdated",
        },
    },
    serviceId: 38,
    serviceVersion: 1,
};
