import { greyPalette, theme } from "@aos/react-components";
import { IProcessVariable } from "@kortex/aos-common";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    accordion: {
        marginBottom: "20px",
    },
    accordionDetails: {
        width: "100%",
    },
    accordionSummaryTitle: {
        backgroundColor: theme.palette.grey[300],
        minHeight: "32px",
    },
    accordionSummaryContent: {
        alignItems: "center",
        display: "inline-flex",
        width: "100%",
    },
    tableCell: {
        padding: "12px",
    },
    tableHeader: {
        backgroundColor: greyPalette[200],
    },
    variableIdentifier: {
        flex: 1,
    },
});

interface IProps {
    duplicate: IProcessVariable;
    original: IProcessVariable;
}

export function ProcessVariableCopyDialogPanel(props: IProps): JSX.Element {
    const { duplicate, original } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [expanded, setExpanded] = useState<boolean>(true);

    /**
     * Toggle the expansion of the accordion
     */
    const toggleExpand = (): void => {
        setExpanded(!expanded);
    };

    return (
        <Accordion className={classes.accordion} expanded={expanded}>
            <AccordionSummary className={classes.accordionSummaryTitle} onClick={toggleExpand}>
                <div className={classes.accordionSummaryContent}>
                    <Typography className={classes.variableIdentifier} variant="h4">
                        {duplicate.identifier}
                    </Typography>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.accordionDetails}>
                    <Table>
                        <TableHead className={classes.tableHeader}>
                            <TableRow>
                                <TableCell className={classes.tableCell} />
                                <TableCell className={classes.tableCell}>
                                    <Typography variant={"body2"}>{translate("processVariableCopyDialog.variableType")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant={"body2"}>{translate("processVariableCopyDialog.variableDecimals")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant={"body2"}>{translate("processVariableCopyDialog.variableDefaultValue")}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* ORIGINAL VARIABLE */}
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant={"body2"}>{translate("processVariableCopyDialog.original")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant={"body2"}>{original.type}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant={"body2"}>{original.decimals}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant={"body2"}>{original.defaultValue}</Typography>
                                </TableCell>
                            </TableRow>
                            {/* DUPLICATED VARIABLE */}
                            <TableRow>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant={"body2"}>{translate("processVariableCopyDialog.duplicate")}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant={"body2"} color={duplicate.type !== original.type ? "error" : undefined}>
                                        {duplicate.type}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography variant={"body2"} color={duplicate.decimals !== original.decimals ? "error" : undefined}>
                                        {duplicate.decimals}
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Typography
                                        variant={"body2"}
                                        color={duplicate.defaultValue !== original.defaultValue ? "error" : undefined}
                                    >
                                        {duplicate.defaultValue}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
