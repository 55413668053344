import { greyPalette } from "@aos/react-components";
import { getElapsedTimeStr, getFormattedDate } from "@kortex/aos-common";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "moment/locale/fr";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableOptions } from "mui-datatables";
import React, { useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";

const ROWS_PER_PAGE = [10, 25, 100];
const FORMATED_DATE_KEY = "UTC";
const FORMATED_DATE_KEY_ARRAY = "UTCArray";
const ELAPSED_TIME_KEY = "DHMS";
const TRUE_FALSE_BOOLEAN = "TFBOOL";
const YES_NO_BOOLEAN = "YNBOOL";

const useStyle = makeStyles({
    root: {
        display: "grid",
        gridTemplateRows: "1fr auto",
    },
    reportTablePane: {
        overflow: "auto",
    },
    accordionSummary: {
        minHeight: "30px",
        margin: "0px",
        padding: "0px",
        alignItems: "center",
        "&.Mui-expanded": {
            minHeight: "30px",
            margin: "0px",
            padding: "0px",
            alignItems: "center",
        },
    },
    lineCard: {
        border: `1px solid ${greyPalette[300]}`,
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    outputText: {
        marginBottom: "32px",
        textAlign: "center",
    },
    pannelDetails: {
        display: "block",
        padding: "0",
    },
});

export interface IReportingDataTableProps {
    reportName?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    results: any[];
    isLoading: boolean;
    index: number;

    tableBodyMaxHeight?: string;
    onClickRow?: (rowClicked: object) => void;
}

export default function ReportingDataTable(props: IReportingDataTableProps): JSX.Element {
    /**
     * Component props defition
     */
    const { reportName, results, isLoading, tableBodyMaxHeight, index } = props;

    const translate = useTranslate();

    const [expend, setExpend] = useState<boolean>(true);

    const tableHeaders: string[] = results && results.length > 0 ? Object.keys(results[0]) : [];
    const tableHeadersTranslated: MUIDataTableColumnDef[] = tableHeaders.map((header) => {
        const columnDef: MUIDataTableColumnDef = {
            name: header,
            label: translate(`reporting.tableheader.${header}`, header),
            options: {
                filter: true,
                sort: true,
            },
        };
        if (header.substring(header.length - FORMATED_DATE_KEY_ARRAY.length) === FORMATED_DATE_KEY_ARRAY) {
            // if column name contain the suffix FORMATED_DATE_KEY_ARRAY format the date to UTCArray
            if (columnDef.options) {
                columnDef.options.customBodyRender = (value: number[]): string => {
                    let trainersDateTime = undefined;
                    if (value) {
                        for (const createdOn of value) {
                            if (!trainersDateTime) {
                                trainersDateTime = `${getFormattedDate(createdOn, true)}`;
                            } else {
                                trainersDateTime = `${trainersDateTime}, ${getFormattedDate(createdOn, true)}`;
                            }
                        }
                    }

                    return trainersDateTime ?? "";
                };
            }
        } else if (header.substring(header.length - FORMATED_DATE_KEY.length) === FORMATED_DATE_KEY) {
            // if column name contain the suffix FORMATED_DATE_KEY format the date to UTC
            if (columnDef.options) {
                columnDef.options.customBodyRender = (value): string => {
                    return value !== "" ? getFormattedDate(parseInt(value), true) : "";
                };
            }
        } else if (header.substring(header.length - ELAPSED_TIME_KEY.length) === ELAPSED_TIME_KEY) {
            // if column name contain the suffix ELAPSED_TIME_KEY format the elapsed time to "days(d) hours(h) minutes(m) second(s)"
            if (columnDef.options) {
                columnDef.options.customBodyRender = (value): string => {
                    return value !== "" ? getElapsedTimeStr(parseInt(value)) : "";
                };
            }
        } else if (header.substring(header.length - TRUE_FALSE_BOOLEAN.length) === TRUE_FALSE_BOOLEAN) {
            // if column name contain the suffix TRUE_FALSE_BOOLEAN replace the value boolean of the database by True or False
            if (columnDef.options) {
                columnDef.options.customBodyRender = (value): string => {
                    return value != 0 ? translate("general.true") : translate("general.false");
                };
            }
        } else if (header.substring(header.length - YES_NO_BOOLEAN.length) === YES_NO_BOOLEAN) {
            // if column name contain the suffix YES_NO_BOOLEAN replace the value boolean of the database by Yes or No
            if (columnDef.options) {
                columnDef.options.customBodyRender = (value): string => {
                    return value === 1 ? translate("general.yes") : translate("general.no");
                };
            }
        }

        return columnDef;
    });

    const handleRowClick = (rowData: string[]): void => {
        if (props.onClickRow) {
            props.onClickRow(rowData);
        }
    };

    const tableOptions: MUIDataTableOptions = {
        tableBodyMaxHeight: tableBodyMaxHeight,
        filter: true,
        filterType: "dropdown",
        responsive: "standard",
        fixedHeader: true,
        rowsPerPageOptions: ROWS_PER_PAGE,
        caseSensitive: false,
        onRowClick: handleRowClick,
        textLabels: {
            body: {
                noMatch: isLoading ? translate("datatable.textLabels.body.loading") : translate("datatable.textLabels.body.noMatch"),
                toolTip: translate("datatable.textLabels.body.toolTip"),
            },
            pagination: {
                next: translate("datatable.textLabels.pagination.next"),
                previous: translate("datatable.textLabels.pagination.previous"),
                rowsPerPage: translate("datatable.textLabels.pagination.rowsPerPage"),
                displayRows: translate("datatable.textLabels.pagination.displayRows"),
            },
            toolbar: {
                search: translate("datatable.textLabels.toolbar.search"),
                downloadCsv: translate("datatable.textLabels.toolbar.downloadCsv"),
                print: translate("datatable.textLabels.toolbar.print"),
                viewColumns: translate("datatable.textLabels.toolbar.viewColumns"),
                filterTable: translate("datatable.textLabels.toolbar.filterTable"),
            },
            filter: {
                all: translate("datatable.textLabels.filter.all"),
                title: translate("datatable.textLabels.filter.title"),
                reset: translate("datatable.textLabels.filter.reset"),
            },
            viewColumns: {
                title: translate("datatable.textLabels.viewColumns.title"),
                titleAria: translate("datatable.textLabels.viewColumns.titleAria"),
            },
            selectedRows: {
                text: translate("datatable.textLabels.selectedRows.text"),
                delete: translate("datatable.textLabels.selectedRows.delete"),
                deleteAria: translate("datatable.textLabels.selectedRows.deleteAria"),
            },
        },
    };

    /**
     * Component states definitions
     */

    const classes = useStyle();

    /**
     * Handel expend
     */
    const handleExpend = (): void => {
        setExpend(!expend);
    };

    return (
        <Paper id="reportingTableId" className={classes.root}>
            <Accordion expanded={expend}>
                <AccordionSummary
                    classes={{ content: classes.accordionSummary, root: classes.accordionSummary }}
                    onClick={handleExpend}
                    id={`expend${index}Id`}
                >
                    <div>{expend ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
                    <Typography variant="h5">{reportName}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.pannelDetails}>
                    <div className={classes.reportTablePane}>
                        <MUIDataTable title={""} columns={tableHeadersTranslated} data={results} options={tableOptions} />
                    </div>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
}
