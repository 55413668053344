import { hasOutput, isLoop } from "../processAction";
/**
 * Calls the callback for each action that is connected with an other action in the process, starting with the Input.
 */
export function forEachLinkedProcessAction(process, cb) {
    // Find the input action
    const input = process.actions.find((action) => action.type === "core-input");
    if (input) {
        const scannedActionsId = []; // List of action already scanned
        // Scanned normal path (starting with Input)
        scanLinkedProcessAction(input, process, scannedActionsId, 0, cb);
        // Scan fail path
        const failAction = process.actions.find((action) => action.type === "core-fail");
        if (failAction) {
            scanLinkedProcessAction(failAction, process, scannedActionsId, 0, cb);
        }
    }
}
/**
 * Resursively scans connected process actions and call the callback
 *
 * @param {ProcessAction} current - current action
 * @param {ProcessUiModel} process - current action's process
 * @param {number[]} scannedActionsId - list of IDs of already scanned action
 * @param {number} actionIndex - action index
 * @param {(action: ProcessAction , actionIndex: number) => void} [cb] - callback to execute for each action
 */
function scanLinkedProcessAction(current, process, scannedActionsId, actionIndex, cb) {
    // Check if this action as been already scanned
    if (scannedActionsId.includes(current.processActionId)) {
        // Yes, escape
        return void 0;
    }
    // Call callback
    cb(current, actionIndex);
    // Add the action to the list of scanned action, to avoid infinite recursive loop
    scannedActionsId.push(current.processActionId);
    // If action does not have any output, skip scan
    if (!hasOutput(current)) {
        return void 0;
    }
    // Check all outputs from action
    for (const output of getSortedProcessActionOutputs(current)) {
        // Check the next action link to scan
        if (output.remoteIds[0]?.isReturn) {
            continue; // If it is a loop "Return" input, skip this iteration
        }
        const nextProcessActionId = output.remoteIds[0]?.actionId;
        if (nextProcessActionId) {
            // Check if the action can be found in the process action list
            const nextProcessAction = process.actions.find((action) => action.processActionId === nextProcessActionId);
            if (nextProcessAction) {
                // Recursivity! (O_o)
                scanLinkedProcessAction(nextProcessAction, process, scannedActionsId, actionIndex + 1, cb);
            }
        }
    }
}
/**
 * Return sorted outputs from process action
 */
function getSortedProcessActionOutputs(processAction) {
    // If the action is a loop, return the output list reversed
    // output[1] --> first action inside the loop
    // output[0] --> first action outside the loop
    if (isLoop(processAction)) {
        return [processAction.outputs[1], processAction.outputs[0]];
    }
    return processAction.outputs;
}
