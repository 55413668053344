import { Kinova, util } from "@kortex/aos-api-client";
import { differenceInMinutes, format } from "date-fns";
import { TimeBaseSettingEnum } from "../interfaces/models";
import { DAY_TO_HOUR, DAY_TO_MS, HOUR_TO_MIN, HOUR_TO_MS, MIN_TO_MS, MIN_TO_SEC, MONTH_TO_MS, MS_TO_SEC, SEC_TO_MS, WEEK_TO_MS, YEAR_TO_MS, } from "../shared/constants";
const ApiError = util.errors.ApiError;
export var TimeUnitsEnum;
(function (TimeUnitsEnum) {
    TimeUnitsEnum["SECOND"] = "s";
    TimeUnitsEnum["MILLISECOND"] = "ms";
})(TimeUnitsEnum || (TimeUnitsEnum = {}));
export const TABLE_NAME_VALID_REGEX = /^[a-zA-Z]+[A-Za-z0-9_]*$/;
export const TABLE_COLUMN_NAME_VALID_REGEX = TABLE_NAME_VALID_REGEX;
/**
 * Checks if the api error's subcode is a specified ApiErrorSubCode
 *
 * @param {ApiError} error - error to check
 * @param {ApiErrorSubCodes} subCode - subCode to assert
 */
export function assertSubCode(error, subCode) {
    return error instanceof ApiError && error.is(Kinova.Api.ErrorCodes.ERROR_DEVICE, subCode);
}
/**
 * Gets ticks for specific date or current date
 *
 * @param {Date} [date] - date to be converted
 *
 * @returns {number}
 */
export function getTime(date) {
    return date ? date.getTime() : new Date().getTime();
}
/**
 * Gets ISO formated date portion from Date object ie: "YYYY-MM-DD"
 *
 * @param {Date} [date] - date to be converted
 *
 * @returns {string}
 */
export function getDate(date = new Date()) {
    return date.toISOString().slice(0, 10);
}
/**
 * Gets ISO formated datetime portion from Date ticks value ie: "YYYY-MM-DD"
 *
 * @param {number} [dateTicks] - ticks to be converted (in MS)
 * @param {boolean} [withTime] - add time (HH:mm:ss) to the date string
 *
 * @returns {string}
 */
export function getDateStr(dateTicks = new Date().getTime(), withTime = false) {
    if (dateTicks === 0) {
        return "";
    }
    return format(dateTicks, withTime ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd");
}
/**
 * Gets ISO formated datetime portion from Date ticks value ie: "YYYY-MM-DD HH:MM:SS"
 *
 * @param {number} [dateTicks] - ticks to be converted (in MS)
 *
 * @returns {string}
 */
export function getDateTimeStr(dateTicks = new Date().getTime()) {
    if (dateTicks === 0) {
        return "";
    }
    return format(dateTicks, "yyyy-MM-dd HH:mm:ss");
}
/**
 * Gets difference in minutes from a date and current date
 *
 * @param {number} [dateTicks] - ticks to be converted
 *
 * @returns {number}
 */
export function getDifferenceInMinutes(dateTicks) {
    return differenceInMinutes(new Date(), new Date(dateTicks));
}
/**
 * Gets ISO formated time string from a date ticks value "HH:MM:SS"
 *
 * @param {number} durationMs - ticks to be converted
 *
 * @returns {string}
 */
export function getTimeStr(durationMs) {
    const hrs = Math.floor((durationMs / HOUR_TO_MS) % DAY_TO_HOUR).toString();
    const mins = Math.floor((durationMs / MIN_TO_MS) % HOUR_TO_MIN).toString();
    const secs = Math.floor((durationMs * MS_TO_SEC) % MIN_TO_SEC).toString();
    return padNumber(hrs) + ":" + padNumber(mins) + ":" + padNumber(secs);
}
/**
 * Gets ISO formated local time string from a date ticks value "HH:MM:SS"
 *
 * @param {number} date - Input date to extract time from
 *
 * @returns {string} Formated time string
 */
export function getLocalTimeStr(date = Date.now()) {
    return new Date(date).toLocaleTimeString("en-US", { hour12: false }).slice(0, 8);
}
/**
 * Pad string number value example '1' = '01'
 *
 * @param {string} number - Value to be padded
 *
 * @returns {string} padded value
 */
function padNumber(number) {
    return ("00" + number).slice(-2);
}
/**
 * Gets formated elapsed time string from a number of seconds
 *
 * @param {number} ms - number of elapsed mseconds
 *
 * @returns {string}
 */
export function getElapsedTimeStr(ms) {
    const seconds = ms * MS_TO_SEC;
    const numDays = Math.floor(((seconds % 31536000) % 2628000) / 86400);
    const numHours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    const numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    const numSeconds = roundTo((((seconds % 31536000) % 86400) % 3600) % 60);
    return ((numDays > 0 ? numDays + "d " : "") +
        (numHours > 0 ? numHours + "h " : "") +
        (numMinutes < 10 ? "0" + numMinutes : numMinutes) +
        "m " +
        (numSeconds < 10 ? "0" + numSeconds : numSeconds) +
        "s");
}
/**
 * Async delay
 *
 * @param {number} delayMs - delay in milliseconds
 *
 * @returns {Promise}
 */
export const delayAsync = (delayMs) => new Promise((resolve) => setTimeout(resolve, delayMs));
/**
 * Validates email address
 *
 * @param {string} email - email string
 *
 * @returns {boolean} is valid
 */
export function isEmailValid(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
/**
 * Determines whether phone is valid
 *
 * @param {string} phone - phone number
 *
 * @returns {boolean} true if text valid
 */
export function isPhoneValid(phone) {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(String(phone).toLowerCase());
}
/**
 * Return Time based on unit and value
 *
 * @param {TimeBaseSettingEnum} [timeBaseSetting] - unit of the value... Days, Minutes, Deconds, Hours, etc... See TimeBaseSettingEnum
 * @param {number} [value] - value in unit
 *
 * @returns {number|undefined} undefined if unit not found, otherwise the time in MS
 */
export function getTimeFromUnitValue(timeBaseSetting, value) {
    switch (timeBaseSetting) {
        case TimeBaseSettingEnum.SECOND:
            return value * SEC_TO_MS;
        case TimeBaseSettingEnum.MINUTE:
            return value * MIN_TO_MS;
        case TimeBaseSettingEnum.HOUR:
            return value * HOUR_TO_MS;
        case TimeBaseSettingEnum.DAY:
            return value * DAY_TO_MS;
        case TimeBaseSettingEnum.WEEK:
            return value * WEEK_TO_MS;
        case TimeBaseSettingEnum.MONTH:
            return value * MONTH_TO_MS;
        case TimeBaseSettingEnum.YEAR:
            return value * YEAR_TO_MS;
        default:
            return Number.NaN;
    }
}
/**
 * Determines whether text is valid
 *
 * @param {string} [text] - text string to be verified
 * @param {number} [minLen] - text minimum length (a 0 accecpts empty string)
 * @param {number} [maxLen] - text maximum length
 *
 * @returns {boolean} true if text valid
 */
export function isTextValid(text, minLen, maxLen) {
    return Boolean((minLen === 0 && !text) || (text && text.length >= minLen && text.length <= maxLen));
}
/**
 * Determines whether password is valid
 *
 * @param {string} [password] - password string to be verified
 *
 * @returns {boolean} true if password valid
 */
export function isPasswordValid(password) {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinimumLength = /.{8,}/.test(password);
    return hasUpperCase && hasLowerCase && hasNumber && hasMinimumLength;
}
/**
 * Determines whether password is expired
 *
 * @param {number} lastPasswordUpdateTimestamp - password timestamp to be verified
 *
 * @returns {boolean} true if password is expired
 */
export function isPasswordExpired(lastPasswordUpdateTimestamp, passwordExpirationDelayInMs) {
    const now = Date.now();
    return now > lastPasswordUpdateTimestamp + passwordExpirationDelayInMs;
}
/**
 * Trims tex
 *
 * @param {string} text - text to be trimmed
 *
 * @returns {string} trimmed text
 */
export function trimText(text, valueWhenUndefined) {
    return text ? text.trim() : valueWhenUndefined;
}
export function indexOfWithAttr(array, attr, value) {
    for (let i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}
/**
 * Round number to specified decimals
 *
 * @param {number} num - Number to round
 * @param {number} decimals - number of decimals to keep
 *
 * @returns {number}- Rounded Number
 */
export function roundTo(num, decimals = 0) {
    if (num.toString().includes("e")) {
        return +`${Math.round(num)}e-${decimals}`;
    }
    return +`${Math.round(parseFloat(`${num}e+${decimals}`))}e-${decimals}`;
}
/**
 * Return the timestamp in ms from the given date
 *
 * @param {string} dateString - String containing the date (Format YYYY-MM-DD)
 * @param {TimeUnitsEnum} unit - unit of the returned timestamp
 *
 * @returns {number}- Timestamp of the given date
 */
export function getTimestampFromDateStr(dateString, unit) {
    if (dateString) {
        const date = new Date(dateString);
        const timestampMS = date.getTime();
        return unit === TimeUnitsEnum.SECOND ? timestampMS * MS_TO_SEC : timestampMS;
    }
    return 0;
}
/**
 * Returns a date from the given timestamp
 *
 * @param {number} ms - Timestamp in milliseconds
 *
 * @returns {Date | null} - Date of the timestamp
 */
export function getDateFromTimestamp(ms) {
    return new Date(ms);
}
/**
 * Convert date timestamp to string
 *
 * @param {number} timestamp - date timestamp
 *
 * @returns {string} string
 */
export function formatDate(timestamp) {
    const d = new Date(timestamp);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();
    if (month.length < 2) {
        month = `0${month}`;
    }
    if (day.length < 2) {
        day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
}
/**
 * Returns a date format from the given locale
 *
 * @param {boolean} withTime - Add time to format
 *
 * @returns {string} - Date format
 */
export function getDateFormat(withTime) {
    return withTime ? "YYYY-MM-DD HH:mm" : "YYYY-MM-DD";
}
/**
 * Returns the year from a timestamp
 *
 * @param {number} ms - Milliseconds
 *
 * @returns {number} - Year (YYYY)
 */
export function getYearFromTimestamp(ms) {
    return new Date(ms).getFullYear();
}
/**
 * Returns the month from a timestamp
 *
 * @param {number} ms - Milliseconds
 *
 * @returns {number} - Month (MM)
 */
export function getMonthFromTimestamp(ms) {
    return new Date(ms).getMonth() + 1; // getMonth returns 0 to 11
}
/**
 * Returns the date from a timestamp
 *
 * @param {number} ms - Milliseconds
 *
 * @returns {number} - Day (DD)
 */
export function getDayFromTimestamp(ms) {
    return new Date(ms).getDate();
}
/**
 * Returns the hours from a timestamp
 *
 * @param {number} ms - Milliseconds
 *
 * @returns {number} - Hours (HH)
 */
export function getHoursFromTimestamp(ms) {
    return new Date(ms).getHours();
}
/**
 * Returns the minutes from a timestamp
 *
 * @param {number} ms - Milliseconds
 *
 * @returns {number} - Minutes (mm)
 */
export function getMinutesFromTimestamp(ms) {
    return new Date(ms).getMinutes();
}
/**
 * Returns the seconds from a timestamp
 *
 * @param {number} ms - Milliseconds
 *
 * @returns {number} - Seconds (ss)
 */
export function getSecondsFromTimestamp(ms) {
    return new Date(ms).getSeconds();
}
/**
 * Returns a formatted date time
 *
 * @param {number} ms - time in milliseconds
 * @param {boolean} withTime - Add time to format
 *
 * @returns {string} - Date format
 */
export function getFormattedDate(ms, withTime) {
    if (ms) {
        const formatter = { year: "numeric", month: "2-digit", day: "2-digit", hourCycle: "h23" };
        if (withTime) {
            formatter["hour"] = "numeric";
            formatter["minute"] = "numeric";
            formatter["second"] = "numeric";
        }
        const date = new Intl.DateTimeFormat("en-ca", formatter);
        return date.format(new Date(ms));
    }
    return "";
}
/**
 * Gets the difference in milliseconds between the time on the local computer and Universal Coordinated Time (UTC).
 */
export function getTimezoneOffset(date) {
    return -(date.getTimezoneOffset() * MIN_TO_MS);
}
/**
 * Type guard for Kortex ApiErrors
 *
 * @param {any} error - error object to type guard
 */
export function isApiError(error) {
    return error instanceof ApiError;
}
/**
 * Valication of database table name
 *
 * @param {string} tableName - table name to be valided
 *
 * @returns {boolean} - Is table name valid
 */
export function isTableNameValid(tableName) {
    return TABLE_NAME_VALID_REGEX.test(tableName);
}
/**
 * Valication of database table column name
 *
 * @param {string} columnName - Name of table column
 *
 * @returns {boolean} - Is table column name valid
 */
export function isTableColumnNameValid(columnName) {
    return TABLE_COLUMN_NAME_VALID_REGEX.test(columnName);
}
/**
 * Check if string is empty
 *
 * @param {string} str - string
 */
export function isFieldEmpty(str) {
    return !Boolean(str.trim().length);
}
/**
 * Return printable user name (string)
 */
export function getUserName(user, withUserName) {
    let name = user.firstName + " " + user.lastName;
    if (withUserName) {
        name += " (" + user.userName + ")";
    }
    return name;
}
