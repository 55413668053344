// krc
import { getFormattedDate, ISymptomLogUi } from "@kortex/aos-common";
import { greyPalette, secondaryPalette, theme } from "@aos/react-components";
// react
import * as React from "react";
// mUI
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
// Component
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import KortexLabelText from "@kortex/aos-ui/components/core/KortexLabelText";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
    card: {
        padding: "5px",
        display: "flex",
    },
    iconContainer: {
        color: greyPalette[500],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    cardFailDescription: {
        width: "100%",
        padding: "0 8px",
        display: "grid",
        alignItems: "center",
    },
    logInfoContainer: {
        width: "100%",
    },
    logInfoLine1: {
        display: "flex",
    },
    failInfoContainer: {
        width: "100%",
        borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
    commentContainer: {
        paddingLeft: "15px",
        width: "auto",
    },
    commentLabel: {
        color: secondaryPalette[500],
    },
    comment: {
        width: "auto",
    },
    failDescriptionContainer: {
        display: "flex",
    },
    textShort: {
        margin: "5px 15px",
        width: "auto",
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontSize: "1rem",
        fontWeight: "bold",
    },
});

interface IOwnProps {
    index: number;
    symptomLog: ISymptomLogUi;
}

export default function SymptomLogCard(props: IOwnProps): JSX.Element {
    const { index, symptomLog } = props;

    const classes = useStyles();
    const translate = useTranslate();

    // Render
    return (
        <KortexPanelCard key={index} isSelected={false} padding={10}>
            <div className={classes.card} id={`reworkLogCard${index}Id`}>
                <div className={classes.iconContainer}>{<EditIcon />}</div>

                <div className={classes.cardFailDescription}>
                    <Typography variant="h2" className={classes.title}>
                        {translate("rework.symptomLog")}
                    </Typography>
                    {/* Log info */}
                    <div className={classes.logInfoContainer}>
                        {/* Log info line 1*/}
                        <div className={classes.logInfoLine1}>
                            <KortexLabelText
                                label={translate("rework.date")}
                                text={symptomLog.createdOn ? getFormattedDate(symptomLog.createdOn, true) : ""}
                                className={classes.textShort}
                                id={`reworkDate${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("rework.reworkItemStatus")}
                                text={symptomLog.reworkItemStatus.title}
                                className={classes.textShort}
                                id={`reworkItemStatusLog${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("rework.user")}
                                text={symptomLog.user.fullName}
                                className={classes.textShort}
                                id={`user${index}Id`}
                            />
                        </div>
                    </div>
                    {/* Fail info */}
                    <div className={classes.failInfoContainer}>
                        <div key={index} className={classes.failInfoContainer}>
                            {/* Fail info from execution */}
                            <div>
                                <div className={classes.failDescriptionContainer}>
                                    <KortexLabelText
                                        label={translate("rework.typeSymptom")}
                                        text={symptomLog.failureType.title}
                                        className={classes.textShort}
                                        id={`failureTypeLog${index}Id`}
                                    />
                                    <KortexLabelText
                                        label={translate("rework.symptom")}
                                        text={symptomLog.failureSubType.title}
                                        className={classes.textShort}
                                        id={`symptomLog${index}Id`}
                                    />
                                </div>
                                <div className={classes.commentContainer}>
                                    <div className={classes.commentLabel}>{translate("player.comments")}</div>
                                    <div className={classes.comment} id={`commentLog${index}Id`}>
                                        {symptomLog.comments.length > 0 ? symptomLog.comments : translate("general.na")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </KortexPanelCard>
    );
}
