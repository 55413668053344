import { KortexTextField, primaryPalette, secondaryPalette } from "@aos/react-components";
import MomentUtils from "@date-io/moment";
import { ApprovalStatusEnum, IUserGroupDbModel, getDateFormat, getDateFromTimestamp, getTimestampFromDateStr } from "@kortex/aos-common";
import { Checkbox, FormControl, Input, MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesUsersGroups } from "../../../../../redux/effects";
import { useSelectorLanguage } from "../../../../../redux/selectors";
import KortexSearchField from "../../../../core/KortexSearchField";

import { getProcessVersionStatusLabelKey } from "./ProcessApprovalCard";

export interface IFilters {
    approvalGroup: string[];
    approvalStatus: string[];
    approvedBy: string;
    changelog: string;
    plainText: string;
    referenceId: string;
    releasedBy: string;
    releasedDateFrom: number;
    releasedDateTo: number;
    releaseStatus: string[];
    retiredDateFrom: number;
    retiredDateTo: number;
    showArchived: boolean;
    submittedBy: string;
    submissionDateFrom: number;
    submissionDateTo: number;
    version: string;
}

// Used to indicate which filters to display
export interface IEnabledFilters {
    approvalGroup: boolean;
    approvalStatus: boolean;
    approvedBy: boolean;
    changelog: boolean;
    plainText: boolean;
    referenceId: boolean;
    releasedBy: boolean;
    releasedDate: boolean;
    releaseStatus: boolean;
    retiredDate: boolean;
    showArchived: boolean;
    submittedBy: boolean;
    submissionDate: boolean;
    version: boolean;
}

export const defaultFilters: IFilters = {
    approvalGroup: [],
    approvalStatus: [],
    approvedBy: "",
    changelog: "",
    plainText: "",
    referenceId: "",
    releasedBy: "",
    releasedDateFrom: 0,
    releasedDateTo: 0,
    releaseStatus: [],
    retiredDateFrom: 0,
    retiredDateTo: 0,
    showArchived: false,
    submittedBy: "",
    submissionDateFrom: 0,
    submissionDateTo: 0,
    version: "",
};

const defaultEnabledFilters: IEnabledFilters = {
    approvalGroup: true, //
    approvalStatus: true, //
    approvedBy: true,
    changelog: true, //
    plainText: true, //
    referenceId: true, //
    releasedBy: true, //
    releasedDate: true, //
    releaseStatus: true,
    retiredDate: true, //
    showArchived: true, //
    submittedBy: true, //
    submissionDate: true,
    version: true, //
};

const useStyles = makeStyles({
    clearFilterLabel: {
        color: secondaryPalette[500],
        "&:hover": {
            color: primaryPalette[500],
        },
    },
    datePickerInput: {
        paddingLeft: "5px",
        paddingRight: "5px",
        width: "140px",
    },
    datePickerLabel: {
        color: secondaryPalette[500],
        display: "inline-block",
        marginTop: "5px",
    },
    filterDateInput: {
        flex: 1,
    },
    filterInput: {
        flex: 2,
    },
    filterInputSelect: {
        padding: "5px",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    searchBox: {
        width: "100%",
    },
});

interface IOwnProps {
    enabledFilters?: IEnabledFilters;
    onFiltersChange: (filters: IFilters) => void;
}

export default function ProcessApprovalSearchBar(props: IOwnProps): JSX.Element {
    const { enabledFilters = defaultEnabledFilters, onFiltersChange } = props;

    const classes = useStyles();
    const groups = useEntitiesUsersGroups();
    const language = useSelectorLanguage();
    const translate = useTranslate();

    const [filters, setFilters] = useState<IFilters>(defaultFilters);

    /**
     * When filters change, execute onChange cb
     */
    useEffect((): void => {
        onFiltersChange(filters);
    }, [filters]);

    /**
     * Called when the inner filters change
     *
     * @param {string} key - indicates which filter changed
     */
    const handleFilterChange =
        (key: keyof IFilters): ((e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
            setFilters({ ...filters, [key]: e.target.value });
        };

    /**
     * Handles the release and retire date changes
     *
     * @param {string} key - release date or retire date
     */
    const handleDateFilterChange =
        (key: keyof IFilters): ((date: MaterialUiPickersDate) => void) =>
        (date: MaterialUiPickersDate): void => {
            setFilters({
                ...filters,
                [key]: date ? getTimestampFromDateStr(String(date)) : 0,
            });
        };

    /**
     * Called when the Show Archived button is toggled
     */
    const handleToggleArchived = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, showArchived: e.target.checked });
    };

    /**
     * Called when filters change. Checks if filters equal their default value (plainText is excluded from check)
     */
    const isFilterActive = (): boolean => {
        let active = false;
        Object.keys(filters).forEach((key): void => {
            if (key !== "plainText" && filters[key] !== defaultFilters[key]) {
                active = true;
            }
        });
        return active;
    };

    /**
     * Called when clicking the Clear Filter label
     */
    const handleClearFilters = (): void => {
        setFilters(defaultFilters);
    };

    /**
     * Called when the text in the searchbar changes
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        setFilters({ ...filters, plainText: val });
    };

    return (
        <KortexSearchField className={classes.searchBox} onChange={handleSearchTextChange} isFilterActive={isFilterActive()}>
            <React.Fragment>
                {enabledFilters.approvalGroup && groups.length && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.approvalGroup")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("approvalGroup")}
                                value={filters.approvalGroup}
                            >
                                {groups.map((group: IUserGroupDbModel, index: number): JSX.Element => {
                                    return (
                                        <MenuItem key={index} value={group.name}>
                                            {group.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {enabledFilters.approvalStatus && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.approvalStatus")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("approvalStatus")}
                                value={filters.approvalStatus}
                            >
                                {Object.values(ApprovalStatusEnum).map((key: ApprovalStatusEnum, index: number): JSX.Element => {
                                    return (
                                        <MenuItem key={index} value={key}>
                                            {translate(getProcessVersionStatusLabelKey(key))}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {enabledFilters.approvedBy && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.approvedBy")}</Typography>
                        <KortexTextField
                            className={classes.filterInput}
                            onChange={handleFilterChange("approvedBy")}
                            value={filters.approvedBy}
                            variant="standard"
                        />
                    </div>
                )}
                {enabledFilters.changelog && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.changelog")}</Typography>
                        <KortexTextField
                            className={classes.filterInput}
                            onChange={handleFilterChange("changelog")}
                            value={filters.changelog}
                            variant="standard"
                        />
                    </div>
                )}
                {enabledFilters.referenceId && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.referenceId")}</Typography>
                        <KortexTextField
                            className={classes.filterInput}
                            onChange={handleFilterChange("referenceId")}
                            value={filters.referenceId}
                            variant="standard"
                        />
                    </div>
                )}
                {enabledFilters.releasedBy && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.releasedBy")}</Typography>
                        <KortexTextField
                            className={classes.filterInput}
                            onChange={handleFilterChange("releasedBy")}
                            value={filters.releasedBy}
                            variant="standard"
                        />
                    </div>
                )}
                {enabledFilters.releasedDate && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.releasedDate")}</Typography>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                            <DatePicker
                                allowKeyboardControl={true}
                                className={classes.filterDateInput}
                                clearable={true}
                                emptyLabel={translate("process.versioning.emptyDate")}
                                format={getDateFormat()}
                                InputProps={{ className: classes.datePickerInput }}
                                label={<span className={classes.datePickerLabel}>{translate("process.versioning.releasedDateFrom")}</span>}
                                onChange={handleDateFilterChange("releasedDateFrom")}
                                value={filters.releasedDateFrom ? getDateFromTimestamp(filters.releasedDateFrom) : null}
                                variant="dialog"
                                clearLabel={translate("general.clear")}
                                cancelLabel={translate("general.cancel")}
                                okLabel={translate("general.select")}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                            <DatePicker
                                allowKeyboardControl={true}
                                className={classes.filterDateInput}
                                clearable={true}
                                emptyLabel={translate("process.versioning.emptyDate")}
                                format={getDateFormat()}
                                InputProps={{ className: classes.datePickerInput }}
                                label={<span className={classes.datePickerLabel}>{translate("process.versioning.releasedDateTo")}</span>}
                                onChange={handleDateFilterChange("releasedDateTo")}
                                value={filters.releasedDateTo ? getDateFromTimestamp(filters.releasedDateTo) : null}
                                variant="dialog"
                                clearLabel={translate("general.clear")}
                                cancelLabel={translate("general.cancel")}
                                okLabel={translate("general.select")}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                )}
                {enabledFilters.releaseStatus && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.releaseStatus")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("releaseStatus")}
                                value={filters.releaseStatus}
                            >
                                {[
                                    translate("process.versioning.awaiting"),
                                    translate("process.versioning.released"),
                                    translate("process.versioning.retired"),
                                    "...",
                                    // Archived not added here because it has its own filter
                                ].map(
                                    (key: string, index: number): JSX.Element => (
                                        <MenuItem key={index} value={key}>
                                            {key}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </div>
                )}
                {enabledFilters.retiredDate && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.retiredDate")}</Typography>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                            <DatePicker
                                allowKeyboardControl={true}
                                className={classes.filterDateInput}
                                clearable={true}
                                emptyLabel={translate("process.versioning.emptyDate")}
                                format={getDateFormat()}
                                InputProps={{ className: classes.datePickerInput }}
                                label={<span className={classes.datePickerLabel}>{translate("process.versioning.retiredDateFrom")}</span>}
                                onChange={handleDateFilterChange("retiredDateFrom")}
                                value={filters.retiredDateFrom ? getDateFromTimestamp(filters.retiredDateFrom) : null}
                                variant="dialog"
                                clearLabel={translate("general.clear")}
                                cancelLabel={translate("general.cancel")}
                                okLabel={translate("general.select")}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                            <DatePicker
                                allowKeyboardControl={true}
                                className={classes.filterDateInput}
                                clearable={true}
                                emptyLabel={translate("process.versioning.emptyDate")}
                                format={getDateFormat()}
                                InputProps={{ className: classes.datePickerInput }}
                                label={<span className={classes.datePickerLabel}>{translate("process.versioning.retiredDateTo")}</span>}
                                onChange={handleDateFilterChange("retiredDateTo")}
                                value={filters.retiredDateTo ? getDateFromTimestamp(filters.retiredDateTo) : null}
                                variant="dialog"
                                clearLabel={translate("general.clear")}
                                cancelLabel={translate("general.cancel")}
                                okLabel={translate("general.select")}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                )}
                {enabledFilters.submittedBy && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.submittedBy")}</Typography>
                        <KortexTextField
                            className={classes.filterInput}
                            onChange={handleFilterChange("submittedBy")}
                            value={filters.submittedBy}
                            variant="standard"
                        />
                    </div>
                )}
                {enabledFilters.submissionDate && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.submissionDate")}</Typography>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                            <DatePicker
                                allowKeyboardControl={true}
                                className={classes.filterDateInput}
                                clearable={true}
                                emptyLabel={translate("process.versioning.emptyDate")}
                                format={getDateFormat()}
                                InputProps={{ className: classes.datePickerInput }}
                                label={
                                    <span className={classes.datePickerLabel}>{translate("process.versioning.submissionDateFrom")}</span>
                                }
                                onChange={handleDateFilterChange("submissionDateFrom")}
                                value={filters.submissionDateFrom ? getDateFromTimestamp(filters.submissionDateFrom) : null}
                                variant="dialog"
                                clearLabel={translate("general.clear")}
                                cancelLabel={translate("general.cancel")}
                                okLabel={translate("general.select")}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                            <DatePicker
                                allowKeyboardControl={true}
                                className={classes.filterDateInput}
                                clearable={true}
                                emptyLabel={translate("process.versioning.emptyDate")}
                                format={getDateFormat()}
                                InputProps={{ className: classes.datePickerInput }}
                                label={<span className={classes.datePickerLabel}>{translate("process.versioning.submissionDateTo")}</span>}
                                onChange={handleDateFilterChange("submissionDateTo")}
                                value={filters.submissionDateTo ? getDateFromTimestamp(filters.submissionDateTo) : null}
                                variant="dialog"
                                clearLabel={translate("general.clear")}
                                cancelLabel={translate("general.cancel")}
                                okLabel={translate("general.select")}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                )}
                {enabledFilters.version && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.version")}</Typography>
                        <KortexTextField
                            className={classes.filterInput}
                            onChange={handleFilterChange("version")}
                            value={filters.version}
                            variant="standard"
                        />
                    </div>
                )}
                {enabledFilters.showArchived && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("process.versioning.showArchived")}</Typography>
                        <Checkbox checked={filters.showArchived} onChange={handleToggleArchived} />
                    </div>
                )}
                <div className={classes.optionsItem}>
                    <div className={classes.filterLabel} />
                    <Typography onClick={handleClearFilters} className={classes.clearFilterLabel}>
                        {translate("process.versioning.clearFilters")}
                    </Typography>
                </div>
            </React.Fragment>
        </KortexSearchField>
    );
}
