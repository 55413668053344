import { greyPalette, theme } from "@aos/react-components";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Tooltip, Typography, makeStyles } from "@material-ui/core";
import React from "react";

const COLUMN_COUNT = 7;
const GRID_TEMPLATE_COLUMNS = ((): string => {
    let gridTemplateColumns = "";

    for (let i = 0; i < COLUMN_COUNT; i++) {
        gridTemplateColumns += `${(100 / COLUMN_COUNT).toFixed(2)}% `;
    }

    return gridTemplateColumns;
})();

const useStyles = makeStyles({
    cardContent: {
        display: "grid",
        gridTemplateColumns: GRID_TEMPLATE_COLUMNS,
        alignItems: "center",
    },
    text: {
        color: greyPalette[700],
        textAlign: "center",
    },
    tooltips: {
        fontSize: "1.1rem",
        backgroundColor: greyPalette[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
    },
    statusTrained: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "58px",
    },
    statusCertified: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "58px",
    },
    processTrainingVersionContent: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "58px",
        whiteSpace: "normal",
    },
});

export default function UserTrainingHistoryCardHeader(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    return (
        <KortexPanelCard isSelected={false} padding={0} spaceBetweenCard={1} hover={false}>
            <div className={classes.cardContent} id={"userTrainingCardHeaderId"}>
                {/* ORIGINAL VERSION */}
                <Tooltip classes={{ tooltip: classes.tooltips }} title={translate("training.tooltipOriginalVersion")} placement="top">
                    <div className={classes.processTrainingVersionContent}>
                        <Typography className={classes.text}>{translate("training.originalVersion").toUpperCase()}</Typography>
                    </div>
                </Tooltip>
                {/* STATUS */}
                <div className={classes.statusTrained}>
                    <Typography className={classes.text}>{translate("training.trained").toUpperCase()}</Typography>
                </div>
                <div className={classes.statusCertified}>
                    <Typography className={classes.text}>{translate("training.certified").toUpperCase()}</Typography>
                </div>
                {/* LEVEL OF CHANGE */}
                <div className={classes.processTrainingVersionContent}>
                    <Typography className={classes.text}>{translate("training.levelOfChange").toUpperCase()}</Typography>
                </div>
                {/* VERSION */}
                <Tooltip classes={{ tooltip: classes.tooltips }} title={translate("training.tooltipHeaderVersionTraining")} placement="top">
                    <div className={classes.processTrainingVersionContent}>
                        <Typography className={classes.text}>{translate("training.trainingVersion").toUpperCase()}</Typography>
                    </div>
                </Tooltip>
                {/* TRAINING DATE */}
                <div className={classes.processTrainingVersionContent}>
                    <Typography className={classes.text}>{translate("training.trainingDate").toUpperCase()}</Typography>
                </div>
                {/* ARCHIVED ON */}
                <div className={classes.processTrainingVersionContent}>
                    <Typography className={classes.text}>{translate("training.archiveDate").toUpperCase()}</Typography>
                </div>
            </div>
        </KortexPanelCard>
    );
}
