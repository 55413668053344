import { ProcessActionType } from "@kortex/aos-common";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import FailureTicketIcon from "@material-ui/icons/CallSplit";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import FailIcon from "@material-ui/icons/ErrorOutlineOutlined";
import LoopIcon from "@material-ui/icons/Loop";
import MessageIcon from "@material-ui/icons/MessageOutlined";
import * as React from "react";

import ConditionIcon from "../../../../../components/core/Icons/Condition/Condition";
import InputIcon from "../../../../../components/core/Icons/Input/Input";
import InstructionsIcon from "../../../../../components/core/Icons/Instructions/Instructions";
import MathIcon from "../../../../../components/core/Icons/Math/Math";
import NotificationsIcon from "../../../../../components/core/Icons/Notifications/Notifications";
import OutputIcon from "../../../../../components/core/Icons/Output/Output";
import ParserIcon from "../../../../../components/core/Icons/Parser/Parser";
import ProcessIcon from "../../../../../components/core/Icons/Process/Process";
import RobotIcon from "../../../../../components/core/Icons/Robot/Robot";
import StopIcon from "../../../../../components/core/Icons/Stop/Stop";
import TimeManagementIcon from "../../../../../components/core/Icons/TimeManagement/TimeManagement";
import ActionDefIcon from "../../../../core/Icons/Action/Action";

interface IOwnProps {
    type: ProcessActionType;
}

export default function ActionIcon(props: SvgIconProps & IOwnProps): JSX.Element {
    const { type, ...noTypeProps } = props;

    switch (type) {
        case "core-input":
            return <InputIcon {...noTypeProps} />;
        case "core-output":
            return <OutputIcon {...noTypeProps} />;
        case "core-work-instructions":
            return <InstructionsIcon {...noTypeProps} />;
        case "core-math":
            return <MathIcon {...noTypeProps} />;
        case "core-message":
            return <MessageIcon {...noTypeProps} />;
        case "core-failure-ticket-create":
            return <FailureTicketIcon {...noTypeProps} />;
        case "core-email":
            return <NotificationsIcon {...noTypeProps} />;
        case "core-condition":
            return <ConditionIcon {...noTypeProps} />;
        case "core-loop":
            return <LoopIcon {...noTypeProps} />;
        case "core-parser":
            return <ParserIcon {...noTypeProps} />;
        case "core-time":
            return <TimeManagementIcon {...noTypeProps} />;
        case "core-connector":
            return <CompareArrowsIcon {...noTypeProps} />;
        case "core-stop-process":
            return <StopIcon {...noTypeProps} />;
        case "core-fail":
            return <FailIcon {...noTypeProps} />;
        case "core-robot":
            return <RobotIcon {...noTypeProps} />;
        case "core-routing-process":
            return <ProcessIcon {...noTypeProps} />;
        case "core-routing-fail":
            return <FailIcon {...noTypeProps} />;
    }
    return <ActionDefIcon {...noTypeProps} />;
}
