import { IUserDbModel } from "@kortex/aos-common";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { theme } from "@aos/react-components";

import UserCard from "./UserCard";

const useStyles = makeStyles({
    userList: {
        marginTop: "10px",
        overflowY: "auto",
        backgroundColor: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
    },
});

interface IOwnProps {
    users: IUserDbModel[];
    onUserSelection: (user: IUserDbModel) => void;
    applyFilters: (user: IUserDbModel) => boolean;
}

export default function UserList(props: IOwnProps): JSX.Element {
    const { users, applyFilters, onUserSelection } = props;
    const classes = useStyles();
    const [selectedUser, setSelectedUser] = useState<IUserDbModel>();

    const handleSelection = (user: IUserDbModel): void => {
        onUserSelection(user);
        setSelectedUser(user);
    };

    return (
        <div className={classes.userList}>
            {users.map((user, index): JSX.Element => {
                if (applyFilters(user)) {
                    return (
                        <UserCard
                            key={index}
                            index={index}
                            isSelected={user.userId === selectedUser?.userId}
                            user={user}
                            onSelection={handleSelection}
                        />
                    );
                } else {
                    return <React.Fragment key={index} />;
                }
            })}
        </div>
    );
}
