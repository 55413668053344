var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Else(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M12.5529846,5.98013212 L17.0335034,10.4606509 L13.613374,10.4606509 L13.7091321,12.9101321 L13.7128997,12.9127215 L13.7101321,12.9141321 L13.7119682,12.9487591 L12.9151321,13.6461321 L8.28498089,17.9100225 L8.28498089,25.9801321 L5.98013212,25.9801321 L5.98013212,17.038153 L11.4921321,11.9421321 L11.4925951,10.4606509 L8.07246577,10.4606509 L12.5529846,5.98013212 Z", id: "icons-else", transform: "translate(11.506818, 15.980132) rotate(-270.000000) translate(-11.506818, -15.980132) " })));
}
export default Else;
