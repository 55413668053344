import { greyPalette, secondaryPalette } from "@aos/react-components";
import React from "react";

interface IOwnProps {
    label?: string;
    variant?: "regular" | "compact";
    styleLabel?: React.CSSProperties;
    id?: string;
}

export default function KortexLabel(props: IOwnProps): JSX.Element {
    const { id = "kortexLabelId", label, styleLabel, variant } = props;
    const color = variant && variant === "compact" ? secondaryPalette[500] : greyPalette[500];
    const labelStyle: React.CSSProperties = {
        fontSize: `${12 / 14}rem`,
        display: "flex",
        ...styleLabel,
    };
    return (
        <span id={id} style={{ color, ...labelStyle }}>
            {label}
        </span>
    );
}
