import { IProcessTrainings, ProcessTrainingRequirementEnum, upsertObjectFromArray } from "@kortex/aos-common";
import { deepClone } from "@kortex/utilities";

import { IProcessTrainingState, ProcessTrainingActions, ProcessTrainingActionType } from "./process-training-types";

const DEFAULT_PANEL: IProcessTrainings = {
    label: "",
    originalProcessId: 0,
    originalProcessTrainingRequirement: ProcessTrainingRequirementEnum.NONE,
    originalProcessVersion: "",
    processId: 0,
    trainingRequirement: ProcessTrainingRequirementEnum.NONE,
    trainings: [],
    treeNodeId: 0,
    version: "",
};

const initialState: IProcessTrainingState = {
    left: { ...DEFAULT_PANEL },
    right: { ...DEFAULT_PANEL },
    treeNodeId: 0,
};

export function processTrainingReducer(state: IProcessTrainingState = initialState, action: ProcessTrainingActions): IProcessTrainingState {
    switch (action.type) {
        case ProcessTrainingActionType.CLEAR_PROCESS_TRAINING:
            return { ...initialState };
        case ProcessTrainingActionType.SET_PROCESS: {
            const { panel, ...processTrainings } = action.value;

            if (!processTrainings?.treeNodeId) return state;

            if (panel === "right") {
                return removeArchivedTrainings({
                    ...state,
                    right: processTrainings,
                });
            }

            return removeArchivedTrainings({
                ...state,
                left: processTrainings,
                right:
                    state.right.originalProcessId === processTrainings.originalProcessId || state.treeNodeId !== processTrainings.treeNodeId
                        ? { ...DEFAULT_PANEL }
                        : state.right,
                treeNodeId: processTrainings.treeNodeId,
            });
        }
        case ProcessTrainingActionType.UPDATE_PROCESS: {
            const stateCopy = deepClone(state);

            // Do not update state if received value do not have the same tree node ID
            if (!action.value.treeNodeId || action.value.treeNodeId !== stateCopy.treeNodeId) return state;

            // Left
            if (action.value.originalProcessId >= stateCopy.left.originalProcessId) stateCopy.left = { ...action.value };

            // Right
            if (action.value.originalProcessId === stateCopy.right.originalProcessId) {
                stateCopy.right = { ...action.value };
            }

            return removeArchivedTrainings(stateCopy);
        }
        case ProcessTrainingActionType.UPSERT_TRAININGS: {
            const stateCopy = deepClone(state);

            for (const training of action.value) {
                if (training.processId === state.left.processId) {
                    stateCopy.left.trainings = upsertObjectFromArray(stateCopy.left.trainings, training, function (t) {
                        return t.trainingId === this.trainingId;
                    });
                }

                if (training.processId === state.right.processId) {
                    stateCopy.right.trainings = upsertObjectFromArray(stateCopy.right.trainings, training, function (t) {
                        return t.trainingId === this.trainingId;
                    });
                }
            }

            return removeArchivedTrainings(stateCopy);
        }
        default:
            return state;
    }
}

function removeArchivedTrainings(state: IProcessTrainingState): IProcessTrainingState {
    state.left.trainings = state.left.trainings.filter((training) => training.status !== "archived");
    state.right.trainings = state.right.trainings.filter((training) => training.status !== "archived");
    return state;
}
