import {
    IPlayerActionBaseState,
    IPlayerProcessHistory,
    IPlayerUIActionProps,
    IProcessState,
    PlayerProcessHubConnection,
} from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export enum PlayerActionType {
    ENABLE_PLAY_NEXT = "@@PLAYER/ENABLE_PLAY_NEXT",
    INIT_STATE = "@@PLAYER/INIT_STATE",
    SET_HISTORY = "@@PLAYER/SET_HISTORY",
    SET_HUB_CONNECTION = "@@PLAYER/SET_HUB_CONNECTION",
    SET_UI_ACTION_PROPS = "@@PLAYER/SET_UI_ACTION_PROPS",
    UPDATE_ACTION_STATE = "@@PLAYER/UPDATE_ACTION_STATE",
    UPDATE_PROCESS_STATE = "@@PLAYER/UPDATE_PROCESS_STATE",
}

export interface IPlayerState {
    actionsState: IPlayerActionBaseState[];
    historyInfo: IPlayerProcessHistory[];
    hubConnection: PlayerProcessHubConnection;
    playNextEnabled: boolean;
    processState: IProcessState;
    uiActionProps?: IPlayerUIActionProps;
}

export type PlayerEnablePlayNextAction = ComplexAction<PlayerActionType.ENABLE_PLAY_NEXT, boolean>;
export type PlayerInitStateAction = ComplexAction<PlayerActionType.INIT_STATE, Partial<IPlayerState>>;
export type PlayerUpdateActionStateAction = ComplexAction<PlayerActionType.UPDATE_ACTION_STATE, IPlayerActionBaseState>;
export type PlayerUpdateHistoryAction = ComplexAction<PlayerActionType.SET_HISTORY, IPlayerProcessHistory[]>;
export type PlayerUpdateHubConnectionAction = ComplexAction<PlayerActionType.SET_HUB_CONNECTION, PlayerProcessHubConnection>;
export type PlayerUpdateProcessStateAction = ComplexAction<PlayerActionType.UPDATE_PROCESS_STATE, IProcessState>;
export type PlayerUpdateUiActionPropsAction = ComplexAction<PlayerActionType.SET_UI_ACTION_PROPS, IPlayerUIActionProps>;

export type PlayerActions =
    | PlayerEnablePlayNextAction
    | PlayerInitStateAction
    | PlayerUpdateActionStateAction
    | PlayerUpdateHistoryAction
    | PlayerUpdateHubConnectionAction
    | PlayerUpdateProcessStateAction
    | PlayerUpdateUiActionPropsAction;
