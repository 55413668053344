import { EnumActionStatus } from "./ActionEnums";
export class ActionBaseState {
    constructor(status, description, startTime) {
        this.comment = "";
        this.description = "Action Description";
        this.executionCount = 0;
        this.executionStepIndex = 0;
        this.failure = {
            actionId: 0,
            actionName: "",
            actionStep: 0,
            actionStepName: "",
            code: -1,
            description: "",
            time: 0,
            canRetry: false,
        };
        this.isCompleted = false;
        this.pauseDuration = 0;
        this.startTime = 0;
        this.endTime = 0;
        this.status = EnumActionStatus.IDLE;
        this.stepsState = [];
        this.reworkItemStatusId = 0;
        this.problems = [];
        this.showFailForm = false;
        this.status = status;
        this.description = description;
        this.startTime = startTime;
    }
}
