import { VariableTypeEnum } from "@aos/variable-manager";
import { ActionTypeEnum, FailureCodeEnum, FailureSourceEnum, SequencerActionPlayCompletedPayloadTypeEnum, SequencerActionStatusEnum, } from "../../enums";
import { CoreActionConfigError, } from "../../interfaces";
import { localeKeys } from "../../locales";
import { KortexSequencerDebugger, deepClone, defer, isBoolean, isNumber, isObject, isPromiseStarted, isString, unwrapVariableIdentifier, validateVariableIdentifier, } from "../../utilities";
import { interpolateActionConfig } from "../../utilities/interpolate-action-config";
const debug = KortexSequencerDebugger.core.set;
export const SEQUENCER_ACTION_SET_DEFAULT_STATE = {};
const STRING_VALUE_CONVERTION_MAP = {
    [VariableTypeEnum.BOOLEAN]: (value) => (value === "true" ? true : value === "false" ? false : Boolean(value)),
    [VariableTypeEnum.NUMBER]: (value) => +value,
    [VariableTypeEnum.OBJECT]: (value) => value,
    [VariableTypeEnum.TEXT]: (value) => value,
};
function create({ variableManager, variableNamespace }) {
    let _play;
    const _state = SEQUENCER_ACTION_SET_DEFAULT_STATE;
    function _convertValueToVariableType(variable) {
        const clone = deepClone(variable);
        if (isString(clone.value) && clone.type !== VariableTypeEnum.TEXT) {
            if (Array.isArray(clone.value)) {
                for (let i = 0; i < clone.value.length; i++) {
                    clone.value[i] = STRING_VALUE_CONVERTION_MAP[clone.type](clone.value[i]);
                }
            }
            else {
                clone.value = STRING_VALUE_CONVERTION_MAP[clone.type](clone.value);
            }
        }
        return clone;
    }
    function _fail(failure) {
        debug("fail:%o", failure);
        return _play?.resolve({
            payload: failure,
            status: SequencerActionStatusEnum.FAILED,
        });
    }
    function assertValidConfig(config) {
        debug("validate:%o", config);
        for (const variable of config.variables) {
            if (!validateVariableIdentifier(variable.identifier.trim())) {
                debug("config validation:error:variable identifier is  not valid");
                throw new CoreActionConfigError(localeKeys.action.core.set.validateUndefinedVariableIdentifier, "Config validation error: variable identifier is not valid");
            }
            if (!(isBoolean(variable.value) || isNumber(variable.value) || isString(variable.value) || isObject(variable.value))) {
                debug("config validation:error:invalid value type");
                throw new CoreActionConfigError(localeKeys.action.core.set.validateInvalidValueType, "Config validation error: invalid value type.");
            }
        }
        debug("validate:successful");
    }
    async function play(config) {
        debug("play:%o", config);
        if (isPromiseStarted(_play)) {
            debug("play:fail:action is already in progress");
            return {
                payload: {
                    action: {
                        state: {},
                    },
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.action.core.set.playAlreadyInProgress,
                    source: FailureSourceEnum.ACTION,
                },
                status: SequencerActionStatusEnum.FAILED,
            };
        }
        _play = defer(async (resolve) => {
            try {
                debug("play:set %o", config.variables);
                for (const variable of interpolateActionConfig({
                    variables: config.variables
                        .filter((variable) => isString(variable.identifier) && variable.identifier.trim() !== "")
                        .map((variable) => ({
                        ...variable,
                        identifier: unwrapVariableIdentifier(variable.identifier),
                    })),
                }, variableManager.getAllVars(variableNamespace)).variables) {
                    await variableManager.setVar(variableNamespace, _convertValueToVariableType(variable));
                }
            }
            catch (error) {
                debug("play:fail:variable manager error");
                return _fail({
                    code: FailureCodeEnum.VARIABLE_MANAGER_ERROR,
                    action: {
                        state: SEQUENCER_ACTION_SET_DEFAULT_STATE,
                    },
                    error,
                    key: error.key,
                    source: FailureSourceEnum.ACTION,
                });
            }
            debug("play:completed");
            return resolve({
                payload: {
                    output: null,
                    type: SequencerActionPlayCompletedPayloadTypeEnum.NEXT,
                },
                status: SequencerActionStatusEnum.COMPLETED,
            });
        });
        return _play.promise;
    }
    async function stop(causeFailure) {
        debug("stop");
        if (causeFailure) {
            return _fail({
                code: FailureCodeEnum.STOP,
                action: {
                    state: SEQUENCER_ACTION_SET_DEFAULT_STATE,
                },
                key: localeKeys.action.core.set.stop,
                source: FailureSourceEnum.ACTION,
            });
        }
        return _play?.resolve({
            payload: null,
            status: SequencerActionStatusEnum.STOPPED,
        });
    }
    return {
        get state() {
            return deepClone(_state);
        },
        get type() {
            return ActionTypeEnum.SET;
        },
        assertValidConfig,
        play,
        stop,
    };
}
export const SequencerActionSetFactory = {
    create,
};
