import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    ReworkJobProcessAllReq,
    ReworkJobProcessAllRes,
    ReworkJobProcessInsertReq,
    ReworkJobProcessInsertRes,
    ReworkJobProcessDeleteReq,
    ReworkJobProcessDeleteRes,
    ReworkJobProcessInsertedNotif,
    ReworkJobProcessDeletedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { reworkJobProcessAll } from "./all";
import { reworkJobProcessInsert } from "./insert";
import { reworkJobProcessDelete } from "./delete";
import { reworkJobProcessInserted } from "./inserted";
import { reworkJobProcessDeleted } from "./deleted";

interface IAOSClientServiceWrappedReworkJobProcess {
    getAll: AOSClientServiceRequestWrapped<ReworkJobProcessAllReq, ReworkJobProcessAllRes>;
    insert: AOSClientServiceRequestWrapped<ReworkJobProcessInsertReq, ReworkJobProcessInsertRes>;
    delete: AOSClientServiceRequestWrapped<ReworkJobProcessDeleteReq, ReworkJobProcessDeleteRes>;
    onInserted: AOSClientServiceNotificationWrapped<ReworkJobProcessInsertedNotif>;
    onDeleted: AOSClientServiceNotificationWrapped<ReworkJobProcessDeletedNotif>;
}

type AOSClientServiceWrappedReworkJobProcess = IAOSClientServiceWrappedReworkJobProcess;

export function generateReworkJobProcessServiceClient(
    router: IRouterClient,
    notifier: EventEmitter
): AOSClientServiceWrappedReworkJobProcess {
    return {
        getAll: AOSClientServiceWrapperUI.request(reworkJobProcessAll(router)),
        insert: AOSClientServiceWrapperUI.request(reworkJobProcessInsert(router)),
        delete: AOSClientServiceWrapperUI.request(reworkJobProcessDelete(router)),
        onInserted: AOSClientServiceWrapperUI.notification(reworkJobProcessInserted(router, notifier)),
        onDeleted: AOSClientServiceWrapperUI.notification(reworkJobProcessDeleted(router, notifier)),
    };
}

generateReworkJobProcessServiceClient.serviceId = 33;
