import {
    TrainingCertificationPendingActionType,
    TrainingCertificationPendingListClear,
    TrainingCertificationPendingListDeletedAction,
    TrainingCertificationPendingListInsertedAction,
    TrainingCertificationPendingListSetAction,
    TrainingCertificationPendingOfUserClear,
    TrainingCertificationPendingOfUserDeletedAction,
    TrainingCertificationPendingOfUserInsertedAction,
    TrainingCertificationPendingOfUserSetAction,
} from "./training-certification-pending-types";

// TRAINING PENDING OF USER
export function trainingCertificationPendingOfUserClear(): TrainingCertificationPendingOfUserClear {
    return { type: TrainingCertificationPendingActionType.CLEAR_TRAINING_CERTIFICATION_PENDING_OF_USER };
}
export function trainingCertificationPendingOfUserInsertedAction(
    trainingCertificationPending: TrainingCertificationPendingOfUserInsertedAction["value"]
): TrainingCertificationPendingOfUserInsertedAction {
    return {
        type: TrainingCertificationPendingActionType.INSERT_TRAINING_CERTIFICATION_PENDING_OF_USER,
        value: trainingCertificationPending,
    };
}
export function trainingCertificationPendingOfUserSetAction(
    trainingCertificationPending: TrainingCertificationPendingOfUserSetAction["value"]
): TrainingCertificationPendingOfUserSetAction {
    return { type: TrainingCertificationPendingActionType.SET_TRAINING_CERTIFICATION_PENDING_OF_USER, value: trainingCertificationPending };
}
export function trainingCertificationPendingOfUserDeletedAction(
    actions: TrainingCertificationPendingOfUserDeletedAction["value"]
): TrainingCertificationPendingOfUserDeletedAction {
    return { type: TrainingCertificationPendingActionType.DELETE_TRAINING_CERTIFICATION_PENDING_OF_USER, value: actions };
}

// TRAINING PENDING LIST
export function trainingCertificationPendingListClear(): TrainingCertificationPendingListClear {
    return { type: TrainingCertificationPendingActionType.CLEAR_TRAINING_CERTIFICATION_PENDING_LIST };
}
export function trainingCertificationPendingListInsertedAction(
    trainingCertificationPending: TrainingCertificationPendingListInsertedAction["value"]
): TrainingCertificationPendingListInsertedAction {
    return { type: TrainingCertificationPendingActionType.INSERT_TRAINING_CERTIFICATION_PENDING_LIST, value: trainingCertificationPending };
}
export function trainingCertificationPendingListSetAction(
    trainingCertificationPending: TrainingCertificationPendingListSetAction["value"]
): TrainingCertificationPendingListSetAction {
    return { type: TrainingCertificationPendingActionType.SET_TRAINING_CERTIFICATION_PENDING_LIST, value: trainingCertificationPending };
}
export function trainingCertificationPendingListDeletedAction(
    actions: TrainingCertificationPendingListDeletedAction["value"]
): TrainingCertificationPendingListDeletedAction {
    return { type: TrainingCertificationPendingActionType.DELETE_TRAINING_CERTIFICATION_PENDING_LIST, value: actions };
}
