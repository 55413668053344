import { EventEmitter } from "events";

import { Kinova, ServiceManagerClient, WSClient } from "@kortex/aos-api-client";
import { TrainingCertificationPendingDeletedNotif, getRPCHubInfo } from "@kortex/aos-common";

import { emptyObject } from "../constants";
import {
    objectFromUTF8Array,
    utf8ArrayFromObject,
    utf8ArrayToKortexApiErrorObject,
    utf8ArrayToKortexApiNotificationHandleObject,
} from "../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const trainingCertificationPendingDeleted = (router: WSClient["router"], notifier: EventEmitter) =>
    ServiceManagerClient.getNR<
        emptyObject,
        Kinova.Api.Common.INotificationHandle,
        TrainingCertificationPendingDeletedNotif,
        Kinova.Api.IError
    >(getRPCHubInfo("trainingCertificationPending")("deleted"), {
        notifications: notifier,
        router,
        serializer: {
            decode: utf8ArrayToKortexApiNotificationHandleObject,
            encode: utf8ArrayFromObject,
            err: {
                decode: utf8ArrayToKortexApiErrorObject,
            },
            notifier: objectFromUTF8Array,
        },
    });
