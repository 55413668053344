export function createBomFollowUp(bomFollowUp) {
    const { bomFollowUpId = 0, partNumber, processActionStepId = 0, quantity, isOverconsumption = false, jobRefId = "", traceability, trackingId, trackingInstances = "0", } = bomFollowUp;
    return {
        bomFollowUpId,
        partNumber,
        processActionStepId,
        quantity,
        isOverconsumption,
        jobRefId,
        traceability,
        trackingId,
        trackingInstances,
    };
}
