import { upsertObjectFromArray } from "@kortex/aos-common";

import { SettingOrganizationState, SettingOrganizationActions, SettingOrganizationActionType } from "./setting-organization-types";

export const initialState: SettingOrganizationState = {
    settingOrganization: [],
};

/**
 * Failures reducer
 *
 * @param {SettingOrganizationState} state - state
 * @param {SettingOrganizationActions} action - action
 */
export function settingOrganizationReducer(state = initialState, action: SettingOrganizationActions): SettingOrganizationState {
    switch (action.type) {
        case SettingOrganizationActionType.SETTING_ORGANIZATION_FETCH_ALL: {
            return {
                ...state,
                settingOrganization: action.value,
            };
        }
        // FIXME: once we have multiple setting, implement properly
        case SettingOrganizationActionType.SETTING_ORGANIZATION_NOTIFICATION_INSERTED: {
            return {
                ...state,
                settingOrganization: upsertObjectFromArray(state.settingOrganization, action.value, function () {
                    // force insertion; always
                    return false;
                }),
            };
        }
        // FIXME: once we have multiple setting, implement properly
        case SettingOrganizationActionType.SETTING_ORGANIZATION_NOTIFICATION_UPDATED: {
            return {
                ...state,
                settingOrganization: upsertObjectFromArray(state.settingOrganization, action.value, function (_, settingIndex) {
                    // always update first element
                    return !Boolean(settingIndex);
                }),
            };
        }
        default: {
            return state;
        }
    }
}
