import { rainbowPalette, theme, warningPalette } from "@aos/react-components";
import { IJobUiModel, JobProcessStatusEnum, JobStatusEnum, ProcessRunStatusEnum } from "@kortex/aos-common";

import { ILocale } from "../../../locales/ILocale";

/**
 * Return Label key to be use by intl for each job status
 *
 * @param {JobStatusEnum} status - Status to be converted
 *
 * @returns {string} - Key to be use by intl
 */
export function getJobStatusLabelKey(status: JobStatusEnum): keyof ILocale {
    switch (status) {
        case JobStatusEnum.DRAFT:
            return "scheduler.jobStatusDraft";
        case JobStatusEnum.READY:
            return "scheduler.jobStatusReady";
        case JobStatusEnum.IN_PROGRESS:
            return "scheduler.jobStatusInProgress";
        case JobStatusEnum.ON_HOLD:
            return "scheduler.jobStatusOnHold";
        case JobStatusEnum.DONE:
            return "scheduler.jobStatusDone";
        case JobStatusEnum.ARCHIVED:
            return "scheduler.jobStatusArchived";
        case JobStatusEnum.CANCELLED:
            return "scheduler.jobStatusCancelled";
        default:
            return "scheduler.jobStatusUnknown";
    }
}

/**
 * Return Label key to be use by intl for each job process status
 *
 * @param {JobProcessStatusEnum} status - Status to be converted
 *
 * @returns {string} - Key to be use by intl
 */
export function getJobProcessStatusLabelKey(status: JobProcessStatusEnum): keyof ILocale {
    switch (status) {
        case JobProcessStatusEnum.DRAFT:
            return "scheduler.jobStatusDraft";
        case JobProcessStatusEnum.READY:
            return "scheduler.jobStatusReady";
        case JobProcessStatusEnum.IN_PROGRESS:
            return "scheduler.jobStatusInProgress";
        case JobProcessStatusEnum.ON_HOLD:
            return "scheduler.jobStatusOnHold";
        case JobProcessStatusEnum.DONE:
            return "scheduler.jobStatusDone";
        case JobProcessStatusEnum.ARCHIVED:
            return "scheduler.jobStatusArchived";
        case JobProcessStatusEnum.CANCELLED:
            return "scheduler.jobStatusCancelled";
        default:
            return "scheduler.jobStatusUnknown";
    }
}

/**
 * Return color to be use for each job status
 *
 * @param {JobStatusEnum} status - Status to get color
 *
 * @returns {string} - color
 */
export function getJobStatusColor(status?: JobStatusEnum): string {
    switch (status) {
        case JobStatusEnum.DRAFT:
            return theme.palette.grey[500]; // Grey 500
        case JobStatusEnum.READY:
            return rainbowPalette[300]; // Blue
        case JobStatusEnum.IN_PROGRESS:
            return rainbowPalette[400]; // Blue Cyan 400
        case JobStatusEnum.ON_HOLD:
            return warningPalette[600]; // Orange
        case JobStatusEnum.DONE:
            return rainbowPalette[600]; // Green
        case JobStatusEnum.ARCHIVED:
            return theme.palette.common.black; // Black
        default:
            return theme.palette.common.white; // White
    }
}

/**
 * Return color to be use for each job process status
 *
 * @param {JobStatusEnum} status - Status to get color
 *
 * @returns {string} - color
 */
export function getJobProcessStatusColor(status?: JobProcessStatusEnum): string {
    switch (status) {
        case JobProcessStatusEnum.DRAFT:
            return theme.palette.grey[500]; // Grey 500
        case JobProcessStatusEnum.READY:
            return rainbowPalette[300]; // Blue
        case JobProcessStatusEnum.IN_PROGRESS:
            return rainbowPalette[400]; // Blue Cyan 400
        case JobProcessStatusEnum.ON_HOLD:
            return warningPalette[600]; // Orange
        case JobProcessStatusEnum.DONE:
            return rainbowPalette[600]; // Green
        case JobProcessStatusEnum.ARCHIVED:
            return theme.palette.common.black; // Black
        default:
            return theme.palette.common.white; // White
    }
}

/**
 * Return Label key to be use by intl for each process run status
 *
 * @param {ProcessRunStatusEnum} status - Status to be converted
 *
 * @returns {string} - Key to be use by intl
 */

export function getJobProcessRunStatusLabelKey(status: ProcessRunStatusEnum): keyof ILocale {
    switch (status) {
        case ProcessRunStatusEnum.DRAFT:
            return "scheduler.jobProcessRunStatusDraft";
        case ProcessRunStatusEnum.READY:
            return "scheduler.jobProcessRunStatusReady";
        case ProcessRunStatusEnum.IN_PROGRESS:
            return "scheduler.jobProcessRunStatusInProgress";
        case ProcessRunStatusEnum.PASS:
            return "scheduler.jobProcessRunStatusPass";
        case ProcessRunStatusEnum.FAIL:
            return "scheduler.jobProcessRunStatusFail";
        default:
            return "scheduler.jobProcessRunStatusUnknown";
    }
}

/**
 * Return color to be use for each process run (status)
 *
 * @param {ProcessRunStatusEnum} status - Process Run to get color
 *
 * @returns {string} - color
 */
export function getJobProcessRunStatusColor(status: ProcessRunStatusEnum): string {
    switch (status) {
        case ProcessRunStatusEnum.DRAFT:
            return theme.palette.grey[500]; // Grey 500
        case ProcessRunStatusEnum.READY:
            return rainbowPalette[300]; // Blue
        case ProcessRunStatusEnum.IN_PROGRESS:
            return rainbowPalette[400]; // Blue Cyan 400
        case ProcessRunStatusEnum.PASS:
            return rainbowPalette[600]; // Green
        case ProcessRunStatusEnum.FAIL:
            return rainbowPalette[1000]; // Red
        default:
            return theme.palette.common.black; // Black
    }
}

/**
 * Computes progress based on processes' status
 *
 * @param {IJobUiModel} job - job to compute completion progress in percentage
 *
 * @returns {number} percentage
 */
export const computeJobProgressCompletionPercentage = (job: IJobUiModel): number => {
    let doneProcessesCount = 0;
    let totalProcessesCount = 0;
    if (job) {
        if (job.status === JobStatusEnum.DONE) {
            return 100;
        }
        if (job.status === JobStatusEnum.DRAFT) {
            return 0;
        }
        job.processList.forEach((process): void => {
            if (process.reworkId === 0) {
                doneProcessesCount += process.qtyPassed;
                totalProcessesCount += process.qty;
            }
        });
        return totalProcessesCount ? Math.floor((doneProcessesCount / totalProcessesCount) * 100) : 0;
    }
    return 0;
};

/**
 * Return in progress and remaining job to start
 *
 * @returns {{ inProgress: number; remainingToStart: number }}  inProgress, remainingToStart
 */
export const GetJobInProgress = (
    qtyStarted: number,
    qtyTotal: number,
    qtyPass: number,
    qtyFail: number
): { inProgress: number; remainingToStart: number } => {
    let inProgress = qtyStarted - qtyPass - qtyFail;
    inProgress = inProgress >= 0 ? inProgress : 0;

    const remainingToStart = qtyTotal - inProgress - qtyPass;
    remainingToStart >= 0 ? remainingToStart : 0;

    return { inProgress, remainingToStart };
};
