/**
 * Creates a new training pending model
 *
 */
export function newTrainingPendingModel(options) {
    const { trainingCertificateId, type, userId } = options;
    return {
        trainingPendingId: 0,
        trainingCertificateId,
        type,
        userId,
    };
}
