import { greyPalette } from "@aos/react-components";
import { IJobProcessOfTheReworkModel, ITokenDecoded, ProcessApprovalStatusFilter } from "@kortex/aos-common";
import { Paper } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import RoutingIcon from "../../../../core/Icons/Routing/Routing";
import KortexPanelCard from "../../../../core/KortexPanelCard";
import ProcessVersionPicker from "../../../../core/ProcessVersionPicker/ProcessVersionPicker";

import FailureTicketJobProcessCard from "./FailureTicketJobProcessCard";

const useStyles = makeStyles({
    container: {
        marginTop: "6px",
    },
    processContainer: {
        marginLeft: "5px",
    },
    cardContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    spacer: {
        flexGrow: 1,
    },
    iconNameContainer: {
        color: greyPalette[500],
    },
    processPanelCardTypo: {
        marginLeft: "5px",
    },
});

interface IOwnProps {
    isSelected: boolean;
    jobProcessRouting: IJobProcessOfTheReworkModel;
    jobProcessListInRouting: IJobProcessOfTheReworkModel[];
    isDragDisabled: boolean;
    userInfo?: ITokenDecoded;

    onSelection?: (jobProcess: IJobProcessOfTheReworkModel) => void;
    onVersionChange?: (processId: number) => void;
    onUpdateProcess?: (jobProcess: IJobProcessOfTheReworkModel) => void;
}

export default function FailureTicketJobRoutingCard(props: IOwnProps): JSX.Element {
    const { onSelection, onVersionChange, jobProcessRouting, jobProcessListInRouting, isDragDisabled, isSelected, userInfo } = props;
    const classes = useStyles();

    /**
     * Called when the card is selected
     */
    const handleSelect = (): void => {
        if (onSelection) {
            onSelection(jobProcessRouting);
        }
    };

    /**
     * Called when the version changes
     *
     * @param {number} processId - processId of the selected version
     */
    const handleVersionChange = (processId: number): void => {
        if (onVersionChange) {
            onVersionChange(processId);
        }
    };

    return (
        <Paper elevation={2} className={classes.container}>
            <KortexPanelCard
                isSelected={props.isSelected}
                spaceBetweenCard={1}
                onSelect={handleSelect}
                padding={10}
                isDraggable={!isDragDisabled}
            >
                <div className={classes.cardContainer}>
                    <RoutingIcon className={classes.iconNameContainer} />
                    <Typography className={classes.processPanelCardTypo} color="primary" variant="h6">
                        {jobProcessRouting.routing.treeNodeProcess.label}
                    </Typography>
                    <div className={classes.spacer} />
                    <ProcessVersionPicker
                        onVersionChange={handleVersionChange}
                        latest={false}
                        processId={jobProcessRouting.routingId}
                        readOnly={true}
                        treeNodeId={jobProcessRouting.routing.treeNodeProcess.treeNodeId}
                        variant={ProcessApprovalStatusFilter.APPROVED}
                    />
                </div>
            </KortexPanelCard>
            <div className={classes.processContainer} id="jobRoutingCardId">
                {jobProcessListInRouting
                    .filter((jobProcess) => jobProcess.routingId === jobProcessRouting.routingId)
                    .map((jobProcess, index) => (
                        <FailureTicketJobProcessCard
                            key={index}
                            isSelected={isSelected}
                            jobProcess={jobProcess}
                            isDragDisabled={isDragDisabled}
                            userInfo={userInfo}
                        />
                    ))}
            </div>
        </Paper>
    );
}
