import { JobProcessStatusEnum } from "../../../enum/JobStatusEnum";
import { ProcessRunStatusEnum } from "../../../enum/ProcessRunStatusEnum";
import { DEFAULT_VERSION } from "../../../shared/constants";
export function createJobProcessDbModel(jobId) {
    const newJobProcess = {
        jobProcessId: 0,
        orderId: 0,
        reworkId: 0,
        jobId: jobId,
        routingId: 0,
        latestVersion: false,
        processId: 0,
        timeRun: 0,
        status: JobProcessStatusEnum.DRAFT,
        qty: 1,
        qtyStarted: 0,
        qtyPassed: 0,
        qtyFailed: 0,
        scheduledOn: 0,
        requestedOn: 0,
        startedOn: 0,
        endedOn: 0,
        groupIdsJSON: [],
        zoneIdsJSON: [],
        comments: "",
        checkPrevious: true,
        purgeVersion: false,
    };
    return newJobProcess;
}
export function createJobProcessUiModel(jobId) {
    const newJobProcess = {
        jobProcessId: 0,
        orderId: 0,
        reworkId: 0,
        jobId: jobId,
        routingId: 0,
        latestVersion: false,
        rework: {
            trackingId: "",
            trackingInstances: "",
        },
        routingName: "",
        processId: 0,
        version: DEFAULT_VERSION,
        processName: "",
        timeRun: 0,
        estimatedTime: 0,
        status: JobProcessStatusEnum.DRAFT,
        qty: 1,
        qtyStarted: 0,
        qtyPassed: 0,
        qtyFailed: 0,
        scheduledOn: 0,
        requestedOn: 0,
        startedOn: 0,
        endedOn: 0,
        groupIdsJSON: [],
        zoneIdsJSON: [],
        comments: "",
        checkPrevious: true,
        purgeVersion: false,
        treeNodeIdProcess: 0,
        treeNodeIdRouting: 0,
    };
    return newJobProcess;
}
/***
 * Create Empty new Job Product Progress
 *
 * @returns {IJobProductProgress} - Empty Job Product Progress
 */
export function createNewJobProductProgress() {
    const newJobProductProgress = {
        qty: 0,
        trackingNumber: "",
        status: ProcessRunStatusEnum.DRAFT,
        progressInPercent: 0,
        isCompleted: false,
        lastProcessRun: "",
        productProcessRun: [],
    };
    return newJobProductProgress;
}
/***
 * Create Empty new Process Run
 *
 * @returns {IJobProcessRun} - Empty Process Run
 */
export function createNewJobProcessRun() {
    const newJobProcessRun = {
        durationMSec: 0,
        batchQty: 0,
        fail: 0,
        pass: 0,
        jobProcessId: 0,
        reworkId: 0,
        processId: 0,
        processName: "",
        startedOn: 0,
        trackingNumber: "",
        userName: "",
    };
    return newJobProcessRun;
}
