import { theme } from "@aos/react-components";
import { IOperatorJobProcessInfo, IProcessUiModel } from "@kortex/aos-common";
import { Button, Dialog, DialogActions, DialogContent, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { IPlayerState } from "../../../../../redux/player-manager/player-types";

import RunDetailsDialogContent from "./RunDetailsDialogContent";

const useStyles = makeStyles({
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 30px",
    },
    title: {
        paddingBottom: "20px",
    },
});

interface IOwnProps {
    // Own Props
    open: boolean;
    playerState: IPlayerState;
    process: IProcessUiModel | null;
    jobProcessInfo?: IOperatorJobProcessInfo;
    reworkId?: number;
    runWithoutJobProcess?: boolean;

    onCancel: () => void;
}

export default function RunDetailsDialog(props: IOwnProps): JSX.Element {
    const { open, jobProcessInfo, process, playerState, reworkId, runWithoutJobProcess } = props;

    const classes = useStyles();
    const translate = useTranslate();

    /**
     * handle the continue
     */
    const handleCancel = (): void => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    return (
        <Dialog open={open} disableAutoFocus={true} fullWidth={true} maxWidth="lg">
            <DialogContent className={classes.dialogContent}>
                <Typography className={classes.title} variant="h4">
                    {translate("player.runDetailsDialog.runDetails")}
                </Typography>

                <RunDetailsDialogContent
                    playerState={playerState}
                    jobProcessInfo={jobProcessInfo}
                    process={process}
                    reworkId={reworkId}
                    runWithoutJobProcess={runWithoutJobProcess}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button id="cancelButtonId" variant="contained" color="secondary" onClick={handleCancel} className={classes.dialogButtons}>
                    <Typography>{translate("general.close")}</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
