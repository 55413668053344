export function createReworkItemStatusModel() {
    return {
        reworkItemStatusId: 0,
        treeNodeId: 0,
        title: "",
        automaticClose: false,
        automaticClosePass: false,
        automaticArchive: false,
        archived: false,
        continue: false,
    };
}
