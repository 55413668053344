import { IUserRoleDbModel } from "@kortex/aos-common";
import { greyPalette, KortexTextField, theme } from "@aos/react-components";
import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import RoleIcon from "@material-ui/icons/LockOutlined";

import { useTranslate } from "../../../../hooks/useTranslate";
import KortexPanelCard from "../../../core/KortexPanelCard";

const useStyles = makeStyles({
    textLarge: {
        margin: "5px",
        width: "170px",
    },
    processPanelCardAllContainer: {
        display: "flex",
        flexDirection: "row",
    },
    processPanelCardContainer: {
        display: "flex",
        flexDirection: "column",
    },
    processPanelCardTitle: {
        display: "flex",
        flexDirection: "row",
        color: theme.palette.primary[500],
    },
    processPanelCardTypo: {
        marginLeft: "5px",
        marginBottom: "8px",
    },
    processPanelCardText: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        flexFlow: "wrap",
    },
    text: {
        margin: "5px",
        width: "170px",
        color: theme.palette.primary.main,
    },
    cardContainer: {
        padding: "3px 6px",
    },
    iconContainer: {
        color: greyPalette[500],
    },
    listButtons: {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        color: theme.palette.primary[500],
        height: "100px",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        fontSize: "0.8rem",
    },
    listButton: {
        display: "flex",
        flexDirection: "column",
        color: theme.palette.primary[500],
        height: "100px",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "0.8rem",
        margin: "10px",
    },
});

interface IOwnProps {
    index: number;
    role: IUserRoleDbModel;
    isSelected: boolean;
    onSelection?: (role: IUserRoleDbModel) => void;
}

export default function RoleCard(props: IOwnProps): JSX.Element {
    const { index } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [role, setRole] = useState<IUserRoleDbModel | undefined>(undefined);

    /**
     * Update Role on props change
     */
    useEffect((): void => {
        if (props.role) {
            setRole(props.role);
        }
    }, [props.role]);

    /**
     * Handle selection of the role
     *
     * @param {IUserRoleDbModel} role - roleo selected
     */
    const handleSelectRole =
        (role: IUserRoleDbModel): (() => void) =>
        (): void => {
            if (props.onSelection) {
                props.onSelection(role);
            }
        };

    return (
        <React.Fragment>
            {role && (
                <div className={classes.cardContainer}>
                    <KortexPanelCard
                        key={index}
                        isSelected={props.isSelected}
                        padding={10}
                        onSelect={handleSelectRole(role)}
                        statusColor={theme.palette.grey[500]}
                    >
                        <div className={classes.processPanelCardAllContainer}>
                            <RoleIcon className={classes.iconContainer} />
                            <div className={classes.processPanelCardContainer}>
                                <div className={classes.processPanelCardText}>
                                    <KortexTextField
                                        label={translate("settings.roles.name")}
                                        value={role.name}
                                        className={classes.text}
                                        variant="standard"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        TextFieldProps={{
                                            id: "roleCardRoleNameId",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </KortexPanelCard>
                </div>
            )}
        </React.Fragment>
    );
}
