import { ServiceManagerClient, WSClient, Kinova } from "@kortex/aos-api-client";
import {
    getRPCHubInfo,
    UserTrainingGetTrainingHistoryByUserIdAndTreeNodeIdReq,
    UserTrainingGetTrainingHistoryByUserIdAndTreeNodeIdRes,
} from "@kortex/aos-common";

import { objectFromUTF8Array, utf8ArrayFromObject, utf8ArrayToKortexApiErrorObject } from "../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const trainingGetTrainingHistoryByUserIdAndTreeNodeId = (router: WSClient["router"]) =>
    ServiceManagerClient.getRR<
        UserTrainingGetTrainingHistoryByUserIdAndTreeNodeIdReq,
        UserTrainingGetTrainingHistoryByUserIdAndTreeNodeIdRes,
        Kinova.Api.IError
    >(getRPCHubInfo("userTraining")("getTrainingHistoryByUserIdAndTreeNodeId"), {
        router,
        serializer: {
            decode: objectFromUTF8Array,
            encode: utf8ArrayFromObject,
            err: {
                decode: utf8ArrayToKortexApiErrorObject,
            },
        },
    });
