import { KortexDateTimePicker, secondaryPalette } from "@aos/react-components";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import * as React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
});

interface IOwnProps {
    filterLabel: string;
    ValueFrom: string;
    ValueTo: string;
    onChangeFrom: (date: MaterialUiPickersDate) => void;
    onChangeTo: (date: MaterialUiPickersDate) => void;
    labelFrom?: string;
    labelTo?: string;
}

export default function DateTimeFromToFilters(props: IOwnProps): JSX.Element {
    const { filterLabel, ValueFrom, ValueTo, onChangeFrom, onChangeTo } = props;
    const labelFrom = props.labelFrom ?? "scheduler.from";
    const labelTo = props.labelTo ?? "scheduler.to";

    const classes = useStyles();
    const language = useSelectorLanguage();
    const translate = useTranslate();

    return (
        <div className={classes.optionsItem}>
            <Typography className={classes.filterLabel}>{translate(filterLabel)}</Typography>
            <KortexDateTimePicker
                label={translate(labelFrom)}
                language={language}
                onChange={onChangeFrom}
                value={Boolean(ValueFrom) ? ValueFrom : null}
                DateTimePickerProps={{
                    clearLabel: translate("general.clear"),
                    cancelLabel: translate("general.cancel"),
                    okLabel: translate("general.select"),
                }}
            />
            <KortexDateTimePicker
                label={translate(labelTo)}
                language={language}
                onChange={onChangeTo}
                value={Boolean(ValueTo) ? ValueTo : null}
                DateTimePickerProps={{
                    clearLabel: translate("general.clear"),
                    cancelLabel: translate("general.cancel"),
                    okLabel: translate("general.select"),
                }}
            />
        </div>
    );
}
