import {
    TrainingReadingCertificatePendingActionType,
    TrainingReadingCertificatePendingClearAction,
    TrainingReadingCertificatePendingDeletedAction,
    TrainingReadingCertificatePendingSetAction,
    TrainingReadingCertificatePendingTrainingDeletedAction,
    TrainingReadingCertificatePendingTrainingUpsertedAction,
    TrainingReadingCertificatePendingUpsertedAction,
} from "./training-reading-certificate-pending-types";

export function trainingReadingCertificatePendingClearAction(): TrainingReadingCertificatePendingClearAction {
    return { type: TrainingReadingCertificatePendingActionType.CLEAR_READING_CERTIFICATE_PENDING };
}

export function trainingReadingCertificatePendingUpsertedAction(
    trainingReadingCertificatePendings: TrainingReadingCertificatePendingUpsertedAction["value"]
): TrainingReadingCertificatePendingUpsertedAction {
    return {
        type: TrainingReadingCertificatePendingActionType.UPSERT_READING_CERTIFICATE_PENDING,
        value: trainingReadingCertificatePendings,
    };
}

export function trainingReadingCertificatePendingSetAction(
    trainingReadingCertificatePendings: TrainingReadingCertificatePendingSetAction["value"]
): TrainingReadingCertificatePendingSetAction {
    return {
        type: TrainingReadingCertificatePendingActionType.SET_READING_CERTIFICATE_PENDING,
        value: trainingReadingCertificatePendings,
    };
}

export function trainingReadingCertificatePendingDeletedAction(
    trainingReadingCertificatePendings: TrainingReadingCertificatePendingDeletedAction["value"]
): TrainingReadingCertificatePendingDeletedAction {
    return {
        type: TrainingReadingCertificatePendingActionType.DELETE_READING_CERTIFICATE_PENDING,
        value: trainingReadingCertificatePendings,
    };
}

export function trainingReadingCertificatePendingTrainingInsertedAction(
    trainingReadingCertificatePendings: TrainingReadingCertificatePendingTrainingUpsertedAction["value"]
): TrainingReadingCertificatePendingTrainingUpsertedAction {
    return {
        type: TrainingReadingCertificatePendingActionType.UPSERT_TRAINING_INTO_READING_CERTIFICATE_PENDING,
        value: trainingReadingCertificatePendings,
    };
}

export function trainingReadingCertificatePendingTrainingDeletedAction(
    trainingReadingCertificatePendings: TrainingReadingCertificatePendingTrainingDeletedAction["value"]
): TrainingReadingCertificatePendingTrainingDeletedAction {
    return {
        type: TrainingReadingCertificatePendingActionType.DELETE_TRAINING_FROM_READING_CERTIFICATE_PENDING,
        value: trainingReadingCertificatePendings,
    };
}
