export const treeProcess = {
    rpc: {
        all: {
            funcId: 5101,
            funcName: "treeProcessAll",
        },
        insert: {
            funcId: 5102,
            funcName: "treeProcessInsert",
        },
        inserted: {
            funcId: 5103,
            funcName: "treeProcessInserted",
        },
        update: {
            funcId: 5104,
            funcName: "treeProcessUpdate",
        },
        updated: {
            funcId: 5105,
            funcName: "treeProcessUpdated",
        },
        archive: {
            funcId: 5106,
            funcName: "treeProcessArchive",
        },
        updateBom: {
            funcId: 5107,
            funcName: "treeProcessUpdateBom",
        },
    },
    serviceId: 51,
    serviceVersion: 1,
};
