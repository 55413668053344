import Debug from "debug";

import { IStandardThunkOptions } from "./app.types";

export const debugThunk = (function (): (thunk: string, message: string, ...data: unknown[]) => void {
    const debug = Debug("kortex:thunk");

    return function (thunk: string, message: string, ...data: unknown[]): void {
        Boolean(data.length) ? debug(`${thunk} ${message}`, ...data) : debug(`${thunk} ${message}`);
    };
})();

export interface INotifier<T> {
    (): Map<T, boolean>;
    has(notification: T): boolean;
    register(notificaiton: T, cb: () => Promise<boolean>): Promise<void>;
    unregister(notificaiton: T, cb: () => Promise<boolean>): Promise<void>;
    _notifications: Map<T, boolean>;
}

/**
 * Returns clean "redux" options
 *
 * @param {IStandardThunkOptions} options - thunk options
 */
export function normalizeStandardThunkeReduxOptions<T extends IStandardThunkOptions>(
    options?: T
): Required<IStandardThunkOptions>["redux"] {
    if (!options || !options.redux) {
        return {
            skipDispatch: false,
            skipLookup: false,
        };
    }

    return {
        skipDispatch: Boolean(options.redux.skipDispatch),
        skipLookup: Boolean(options.redux.skipLookup),
    };
}

/**
 * helps with entities synchronization
 */
export const fetchedOnce = (function (): Readonly<{
    seal: (key: Function) => void;
    unsealAll: () => void;
    was: (key: Function) => boolean;
}> {
    let map = new WeakMap<Function, boolean>();

    return Object.freeze({
        seal: function seal(key: Function): void {
            map.set(key, true);
        },

        unsealAll: function unsealAll(): void {
            map = new WeakMap<Function, boolean>();
        },

        was: function was(key: Function): boolean {
            return Boolean(map.get(key));
        },
    });
})();
