import { TaskTypeEnum } from "../../interfaces/models";
import { Task } from "./Task";
import { TaskProcessApproval } from "./TaskProcessApproval";
import { TaskProcessReview } from "./TaskProcessReview";
/**
 * Create an empty user task object
 */
function newEmptyTaskModel() {
    return {
        groupId: 0,
        linkId: 0,
        referenceId: "",
        status: Task.STATUS.OPEN,
        userId: 0,
        taskId: 0,
        completedOn: 0,
        createdOn: 0,
        updatedOn: 0,
        comments: "",
    };
}
/**
 * Create a new process approval task
 */
function createProcessApproval(options) {
    return new TaskProcessApproval({
        ...{
            ...newEmptyTaskModel(),
            info: {
                approverInfo: [],
                qtyRequired: 0,
                status: TaskProcessApproval.APPROVAL_STATUS.AWAITING,
            },
            type: Task.TYPE.PROCESS_APPROVAL,
        },
        ...options,
    });
}
/**
 * Create a new process review task
 */
function createProcessReview(options) {
    return new TaskProcessReview({
        ...{
            ...newEmptyTaskModel(),
            info: {
                reviewerId: 0,
            },
            type: Task.TYPE.PROCESS_REVIEW,
        },
        ...options,
    });
}
export function TaskFactory(type, options) {
    switch (type) {
        case TaskTypeEnum.PROCESS_APPROVAL:
            return createProcessApproval(options);
        case TaskTypeEnum.PROCESS_REVIEW:
            return createProcessReview(options);
    }
    throw new TypeError("Invalid task type.");
}
TaskFactory.createProcessApproval = createProcessApproval;
TaskFactory.createProcessReview = createProcessReview;
