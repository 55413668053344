import { ISerializedItem, IWoBomItem, IWoBomTracked, ProcessActionStepId } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Menu, MenuItem, PopoverPosition, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";

import { ServiceVariant } from "../utils";

import { OverconsumptionDialog } from "./overconsumptionDialog";

type DialogType = "overconsumption";

interface IOwnProps {
    item: IWoBomItem;
    menuPosition?: PopoverPosition;
    onDialogClose?: () => void;
    onMenuClose?: () => void;
    processActionStepId?: ProcessActionStepId;
    serializedItem?: ISerializedItem;
    variant: ServiceVariant;
    woBom: IWoBomTracked;
}

const NonTraceableItemMenu: FC<IOwnProps> = (props) => {
    const { item, menuPosition, onDialogClose, onMenuClose, processActionStepId, variant, woBom } = props;

    const translate = useTranslate();

    const [dialogOpen, setDialogOpen] = useState<Record<DialogType, boolean>>({
        overconsumption: false,
    });

    /**
     * Opens or closes specified dialog
     */
    const handleDialogOpen =
        (type: DialogType, opened: boolean): (() => void) =>
        (): void => {
            setDialogOpen((prevState) => ({
                ...prevState,
                [type]: opened,
            }));

            if (opened) onMenuClose?.();
            else onDialogClose?.();
        };

    return (
        <>
            {/*************** MENU ***************/}
            <Menu
                anchorPosition={menuPosition}
                anchorReference="anchorPosition"
                id="traceabilityMenuId"
                onClose={onMenuClose}
                open={Boolean(menuPosition)}
            >
                <MenuItem
                    disabled={item.quantity === 0}
                    id="traceabilityMenuOverconsumptionId"
                    key="traceabilityMenuOverconsumptionId"
                    onClick={handleDialogOpen("overconsumption", true)}
                >
                    <Typography>{translate("bomPage.bomTable.overconsumption")}</Typography>
                </MenuItem>
            </Menu>
            {/*************** DIALOGS ***************/}
            <OverconsumptionDialog
                item={item}
                onClose={handleDialogOpen("overconsumption", false)}
                open={dialogOpen["overconsumption"]}
                processActionStepId={processActionStepId}
                variant={variant}
                woBom={woBom}
            />
        </>
    );
};

export default NonTraceableItemMenu;
