import { KortexDialogConfirmation } from "@aos/react-components";
import { IFileInfoDbModel, IWorkInstructionsElementConfig, IWorkInstructionsImageConfig, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../../../../../../../hooks/useTranslate";
import { useSelectorUserSession } from "../../../../../../../../../redux/selectors";
import * as CloudStorage from "../../../../../../../../../utilitites/storage";
import StorageManager from "../../../../../../../StorageManager/StorageManager";

const useStyles = makeStyles({
    storageDialog: {
        height: "90vh",
    },
});

export interface IOwnProps {
    imageProps: IWorkInstructionsElementConfig<IWorkInstructionsImageConfig>;
    maxHeight: number;
    maxWidth: number;
    open: boolean;
    onCancel: () => void;
    onSave: (imageProps: IWorkInstructionsElementConfig<IWorkInstructionsImageConfig>) => void;
}

export default function WorkInstructionsImageEditor(props: IOwnProps): JSX.Element {
    const { imageProps, maxHeight, maxWidth, open, onCancel, onSave } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const userInfo = useSelectorUserSession();

    const [selectedFile, setSelectedFile] = useState<IFileInfoDbModel | undefined>(undefined);

    useStopKeybindPropagation(open);

    /**
     * Called when dialog's cancel button is clicked
     */
    const handleCancel = (): void => {
        onCancel();
    };

    /**
     * Called when dialog's save button is clicked
     */
    const handleSave = (): void => {
        if (selectedFile) {
            const image = new Image();

            image.addEventListener("load", (): void => {
                const imageRatio = image.naturalWidth / image.naturalHeight;
                let finalWidth = image.naturalWidth;
                let finalHeight = image.naturalHeight;
                if (finalWidth > maxWidth) {
                    finalWidth = maxWidth;
                    finalHeight = finalWidth / imageRatio;
                }
                if (finalHeight > maxHeight) {
                    finalHeight = maxHeight;
                    finalWidth = finalHeight * imageRatio;
                }

                onSave({
                    ...imageProps,
                    width: finalWidth,
                    height: finalHeight,
                    extendedProps: {
                        ...imageProps.extendedProps,
                        url: selectedFile.fileId,
                    },
                });
            });

            image.src = `${CloudStorage.get().host}/${selectedFile.fileId}`;
        }
    };

    /**
     * Called when a file has been selected
     *
     * @param {IFileInfoDbModel} file - selected file from the storage
     */
    const handleFileSelected = (file: IFileInfoDbModel): void => {
        setSelectedFile(file);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            dialogProps={{
                classes: { paper: classes.storageDialog },
                fullWidth: true,
                maxWidth: "lg",
            }}
            onCancel={handleCancel}
            onConfirm={handleSave}
            open={open}
            textLabels={{
                titleLabel: translate("action.workInstructions.image.title"),
                cancelButtonLabel: translate("action.workInstructions.image.cancel"),
                proceedButtonLabel: translate("action.workInstructions.image.select"),
            }}
        >
            <StorageManager
                acceptedFileType="image"
                fileFilter={TreeNodeNodeTypeEnum.IMAGE}
                onFileSelected={handleFileSelected}
                preSelectedFileId={imageProps.extendedProps.url}
                userAccessLevel={userInfo?.roleRights.storageEditor}
            />
        </KortexDialogConfirmation>
    );
}
