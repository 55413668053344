import { IUserDbModel, EnumUserStatus } from "@kortex/aos-common";
import React from "react";
import { theme, rainbowPalette, warningPalette } from "@aos/react-components";
import { makeStyles, Grid } from "@material-ui/core";

import { useTranslate } from "../../../../hooks/useTranslate";
import KortexPanelCard from "../../../core/KortexPanelCard";
import KortexLabelText from "../../../core/KortexLabelText";
import { formatUserStatus } from "../../../../utilitites/formatUserStatus";

const useStyles = makeStyles({
    cardContainer: {
        padding: "0px 10px",
    },
    name: {
        width: "50%",
    },
    userName: {
        width: "35%",
    },
    status: {
        width: "15%",
    },
});

type IOwnProps = {
    index: number;
    user: IUserDbModel;
    isSelected: boolean;
    onSelection?: (user: IUserDbModel) => void;
};

export default function UserCard(props: IOwnProps): JSX.Element {
    const { index, user, onSelection } = props;
    const classes = useStyles();
    const translate = useTranslate();

    /**
     * Append different color on left side of card depending on user status
     */
    const statusColor =
        user.status === EnumUserStatus.ACTIVE
            ? rainbowPalette[600]
            : user.status === EnumUserStatus.ARCHIVED
            ? theme.palette.error[500]
            : warningPalette[600];

    /**
     * Handle selection of the user
     *
     * @param {IUserDbModel} user - user selected
     */
    const handleSelectUser =
        (user: IUserDbModel): (() => void) =>
        (): void => {
            if (onSelection) {
                onSelection(user);
            }
        };

    return (
        <div className={classes.cardContainer}>
            <KortexPanelCard
                key={index}
                isSelected={props.isSelected}
                padding={20}
                onSelect={handleSelectUser(user)}
                statusColor={statusColor}
            >
                <Grid container>
                    <Grid container item direction="row">
                        <Grid item className={classes.name}>
                            <KortexLabelText label={translate("settings.userSettings.name")} text={`${user.firstName} ${user.lastName}`} />
                        </Grid>
                        <Grid item className={classes.userName}>
                            <KortexLabelText label={translate("settings.userSettings.username")} text={user.userName} />
                        </Grid>
                        <Grid item className={classes.status}>
                            <KortexLabelText
                                label={translate("settings.userSettings.status")}
                                text={formatUserStatus(user.status, translate)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </KortexPanelCard>
        </div>
    );
}
