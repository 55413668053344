import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload, APIResponse } from "../../utilitites/kortex-client/client";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";

/**
 * Get Report TAG Entry List
 */
export function reportTagGetEntryList(): StandardThunk<APIResponse<"reportTag", "getEntryList">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"reportTag", "getEntryList">> {
        return api.services.reportTag
            .getEntryList(emptyObject)()
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Report Tag Entry Reports
 */
export function reportTagUpsertEntry(
    options: APIPayload<"reportTag", "upsertEntry">
): StandardThunk<APIResponse<"reportTag", "upsertEntry">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"reportTag", "upsertEntry">> {
        return api.services.reportTag
            .upsertEntry(options)()
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Report TAG Entry View List
 */
export function reportTagGetEntryViewList(): StandardThunk<APIResponse<"reportTag", "getEntryViewList">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"reportTag", "getEntryViewList">> {
        return api.services.reportTag
            .getEntryViewList(emptyObject)()
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Report TAG Column List
 */
export function reportTagGetColumnList(): StandardThunk<APIResponse<"reportTag", "getColumnList">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"reportTag", "getColumnList">> {
        return api.services.reportTag
            .getColumnList(emptyObject)()
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Report Tag Column Reports
 */
export function reportTagUpsertColumn(
    options: APIPayload<"reportTag", "upsertColumn">
): StandardThunk<APIResponse<"reportTag", "upsertColumn">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"reportTag", "upsertColumn">> {
        return api.services.reportTag
            .upsertColumn(options)()
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Report TAG Value List
 */
export function reportTagGetValueList(): StandardThunk<APIResponse<"reportTag", "getValueList">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"reportTag", "getValueList">> {
        return api.services.reportTag
            .getValueList(emptyObject)()
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Report Tag Value Reports
 */
export function reportTagUpsertValue(
    options: APIPayload<"reportTag", "upsertValue">
): StandardThunk<APIResponse<"reportTag", "upsertValue">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"reportTag", "upsertValue">> {
        return api.services.reportTag
            .upsertValue(options)()
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}
