export function createStepConfigWorkInstructions() {
    return {
        templateId: "",
        trainingCommunique: "",
        trainingCommuniqueProcessVersion: "",
        workInstructionElements: [],
        bomItems: {},
    };
}
export const formConfigHelpers = {
    updateLineElementBounderies: (elementProps) => {
        const minX = Math.min(...elementProps.extendedProps.arrowheads.map((arrowhead) => arrowhead.x));
        const minY = Math.min(...elementProps.extendedProps.arrowheads.map((arrowhead) => arrowhead.y));
        const maxX = Math.max(...elementProps.extendedProps.arrowheads.map((arrowhead) => arrowhead.x));
        const maxY = Math.max(...elementProps.extendedProps.arrowheads.map((arrowhead) => arrowhead.y));
        elementProps.x = minX;
        elementProps.y = minY;
        elementProps.width = Math.max(1, maxX - minX);
        elementProps.height = Math.max(1, maxY - minY);
    },
};
