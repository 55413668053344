import { KortexDialogConfirmation, theme } from "@aos/react-components";
import { ITreeNodeDbModel, ProcessId, RepositoryEditorRightsEnum, TreeNodeNodeTypeEnum, getParentPath } from "@kortex/aos-common";
import { Button, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FolderIcon from "@material-ui/icons/Folder";
import WarningIcon from "@material-ui/icons/Warning";
import * as React from "react";
import { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesTreeProcess } from "../../../../../redux/effects";
import { IUserRightsProps } from "../../../../../utilitites/IUserRights";
import ProcessVersionPicker from "../../../../core/ProcessVersionPicker/ProcessVersionPicker";
import ProcessRepository, { IOwnProps as IProcessRepositoryProps } from "../ProcessRepository";

import { BreadCrumb } from "./BreadCrumb";

const useStyles = makeStyles({
    breadCrumbsItem: {
        display: "contents",
    },
    breadCrumbsItemContainer: {
        alignItems: "center",
        display: "flex",
    },
    dialogContent: {
        height: "60vh",
        width: "100%",
    },
    disabled: {
        pointerEvents: "none",
    },
    root: {
        alignItems: "center",
        display: "flex",
    },
    typography: {
        fontSize: "1.2rem",
    },
    backButton: {
        minHeight: "0px",
        margin: "10px",
    },
    text: {
        color: theme.palette.primary[500],
        fontSize: "1.2rem",
        fontWeight: 400,
        lineHeight: "24px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "&:hover": {
            backgroundColor: theme.palette.grey[300],
            borderRadius: "5px",
            cursor: "pointer",
        },
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },
    versionPickerContainer: {
        display: "flex",
    },
});

interface IOwnProps extends IUserRightsProps<RepositoryEditorRightsEnum> {
    onSelectProcess: (treeNodeId: number, processId?: number) => void;
    onCancelSelectProcess?: () => void;
    onVersionChange: (versionId: ProcessId, latest?: boolean, changedOnLoad?: boolean) => void;
    onVersionsLoaded?: () => void;
    onBackFromRouting?: () => void;
    disabled?: boolean;
    opened?: boolean;
    processId?: number;
    style?: React.CSSProperties;
    treeNodeId?: number;
    inRouting?: boolean;
    isDraftDifferentThanLatestReleasedVersion?: boolean;
    isDraftDifferentThanLatestInApprovalVersion?: boolean;
    showOnlyBreadCrumbs?: boolean;
    processRepositoryProps?: Partial<Pick<IProcessRepositoryProps, "filterType" | "showVersionVariant" | "selectOnly">>;
    wrapBreadCrumbs?: boolean;
}

type IAllProps = IOwnProps & RouteComponentProps;

function RepoBreadCrumbs(props: IAllProps): JSX.Element {
    const {
        disabled = false,
        history,
        onSelectProcess,
        onVersionChange,
        onVersionsLoaded,
        opened,
        processId,
        style,
        treeNodeId,
        userAccessLevel,
        showOnlyBreadCrumbs,
        processRepositoryProps = {},
        wrapBreadCrumbs = false,
    } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const treeNodes = useEntitiesTreeProcess();

    const [parentPath, setParentPath] = useState<string[]>([]);
    const [processRepositoryOpen, setProcessRepositoryOpen] = useState<boolean>(Boolean(opened));
    const [selectedTreeNodeId, setSelectedTreeNodeId] = useState<number | undefined>(treeNodeId);
    const [selectedProcessId, setSelectedProcessId] = useState<number | undefined>(processId);

    /**
     * handles the closeselection dialog
     *
     * @param {boolean} open - true if open, else false
     */
    const handleProcessRepositoryOpen =
        (open: boolean): (() => void) =>
        (): void => {
            if (disabled) return void 0;

            setProcessRepositoryOpen(open);
        };

    /**
     * handles back from routing
     */
    const handleBackFromRouting = (): void => {
        if (props.onBackFromRouting) {
            props.onBackFromRouting();
        }
    };

    /**
     * handles the cancel of the dialog
     */
    const handleProcessRepositoryCancel = (): void => {
        if (props.onCancelSelectProcess) {
            props.onCancelSelectProcess();
        }

        setProcessRepositoryOpen(false);
    };

    /**
     * Handles the selection change
     *
     * @param {ITreeNodeDbModel[]} processes - selected processes
     */
    const handleSelectionChanged = (processes: ITreeNodeDbModel[], versions?: (number | undefined)[]): void => {
        setSelectedTreeNodeId(processes.length > 0 ? processes[0].treeNodeId : undefined);
        setSelectedProcessId(versions && versions[0]);
    };

    /**
     * Called when the user accepts the confirmation dialog (after he has selected a process).
     */
    const handleSelectProcessConfirmation = (): void => {
        if (!selectedTreeNodeId) return void 0;

        onSelectProcess(selectedTreeNodeId, selectedProcessId);

        setSelectedProcessId(selectedProcessId);

        const nodeView = treeNodes.filter((node) => node.treeNodeId === selectedTreeNodeId)[0];

        if (nodeView.nodeType === TreeNodeNodeTypeEnum.PROCESS || nodeView.nodeType === TreeNodeNodeTypeEnum.ROUTING) {
            history.push(location.search + "#" + selectedTreeNodeId);
        } else {
            history.push(location.search);
        }

        setProcessRepositoryOpen(false);
    };

    /**
     * Set parent path
     */
    useEffect((): void => {
        setParentPath(getParentPath(treeNodeId, treeNodes));
    }, [treeNodeId, treeNodes]);

    /**
     * Opens or closes the process repository on prop change
     */
    useEffect((): void => {
        setProcessRepositoryOpen(Boolean(opened));
    }, [opened]);

    /**
     * Updates inner state when selected process ID (prop) changes
     */
    useEffect((): void => {
        setSelectedProcessId(processId);
    }, [processId]);

    const getDraftVersionWarningElement = (): JSX.Element => {
        let tooltipTitle = "";
        if (props.isDraftDifferentThanLatestReleasedVersion && props.isDraftDifferentThanLatestInApprovalVersion) {
            tooltipTitle = translate("repoBreadCrumbs.draftVersionDifferentThanLatestReleaseAndApprovalVersion");
        } else if (props.isDraftDifferentThanLatestReleasedVersion) {
            tooltipTitle = translate("repoBreadCrumbs.draftVersionDifferentThanLatestReleaseVersion");
        } else if (props.isDraftDifferentThanLatestInApprovalVersion) {
            tooltipTitle = translate("repoBreadCrumbs.draftVersionDifferentThanLatestApprovalVersion");
        } // else is default empty string value

        if (tooltipTitle !== "") {
            tooltipTitle += `\n *** ${translate("repoBreadCrumbs.draftVersionDifferentNoteOnIgnoredDifferences")} ***`;
            return (
                <Tooltip classes={{ tooltip: classes.tooltip }} title={tooltipTitle} placement="bottom">
                    <IconButton color="secondary">
                        <WarningIcon />
                    </IconButton>
                </Tooltip>
            );
        }
        // Return empty Element
        return <></>;
    };

    return (
        <div className={`${classes.root} ${disabled ? classes.disabled : ""}`} id="repoBreadCrumbId" style={style}>
            {!(parentPath.length <= 0 && disabled) ? (
                <IconButton color="secondary" id="selectProcessRoutingDialogButtonId" onClick={handleProcessRepositoryOpen(true)}>
                    <FolderIcon />
                </IconButton>
            ) : null}
            {parentPath.length > 0 ? (
                <React.Fragment>
                    <div className={classes.breadCrumbsItemContainer} style={{ flexWrap: wrapBreadCrumbs ? "wrap" : undefined }}>
                        {parentPath.map((item, index) => (
                            <BreadCrumb
                                index={index}
                                key={`RepoBreadCrumbsItem${index}`}
                                label={item}
                                onClick={handleProcessRepositoryOpen(true)}
                                parentPathLength={parentPath.length}
                            />
                        ))}
                    </div>
                    {!showOnlyBreadCrumbs && <ChevronRightIcon />}
                    {treeNodeId && !showOnlyBreadCrumbs && (
                        <div className={classes.versionPickerContainer}>
                            <ProcessVersionPicker
                                onVersionChange={onVersionChange}
                                onVersionsLoaded={onVersionsLoaded}
                                processId={selectedProcessId}
                                treeNodeId={treeNodeId}
                                userAccessLevel={userAccessLevel}
                            />
                        </div>
                    )}
                </React.Fragment>
            ) : (
                <div className={classes.breadCrumbsItem} onClick={handleProcessRepositoryOpen(true)}>
                    <span className={classes.text} id="repoBreadCrumbsSelectProcessTextId">
                        {disabled ? translate("repoBreadCrumbs.noProcessSelect") : translate("repoBreadCrumbs.selectProcess")}
                    </span>
                </div>
            )}

            {!showOnlyBreadCrumbs && getDraftVersionWarningElement()}

            {props.inRouting && (
                <Button className={classes.backButton} variant="contained" onClick={handleBackFromRouting}>
                    {translate("repoBreadCrumbs.backRouting")}
                </Button>
            )}

            {/* TODO: Look if should be shared with process player and scheduler */}
            <KortexDialogConfirmation
                dialogProps={{
                    fullWidth: true,
                    maxWidth: "xl",
                    onBackdropClick: handleProcessRepositoryCancel,
                    keepMounted: false, // ensures the children are mounted on open and dismounted on close... useful for useForeground handling
                }}
                onCancel={handleProcessRepositoryCancel}
                onConfirm={handleSelectProcessConfirmation}
                closeOnEscape={true}
                open={processRepositoryOpen}
                textLabels={{
                    cancelButtonLabel: translate("repoBreadCrumbs.cancel"),
                    proceedButtonLabel: translate("repoBreadCrumbs.select"),
                    titleLabel: translate("repoBreadCrumbs.titleProcess"),
                }}
                confirmDisabled={!Boolean(selectedTreeNodeId)}
            >
                <div className={classes.dialogContent} id="processReposDialogId">
                    <ProcessRepository
                        onSelectionChanged={handleSelectionChanged}
                        preselectedTreeNodeId={selectedTreeNodeId}
                        userAccessLevel={userAccessLevel}
                        handleSelectProcessConfirmation={handleSelectProcessConfirmation}
                        {...processRepositoryProps}
                    />
                </div>
            </KortexDialogConfirmation>
        </div>
    );
}

export default withRouter(RepoBreadCrumbs);
