import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

function Diamond(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <rect
                id="icons-diamond"
                transform="translate(11.899495, 11.899495) rotate(-315.000000) translate(-11.899495, -11.899495) "
                x="4.89949494"
                y="4.89949494"
                width="14"
                height="14"
            />
        </SvgIcon>
    );
}

export default Diamond;
