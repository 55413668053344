import {
    getTimestampFromDateStr,
    IJobProcessUiModel,
    IJobsFiltersOptions,
    IJobUiModel,
    IUserDbModel,
    IUserGroupDbModel,
    TimeUnitsEnum,
} from "@kortex/aos-common";
import React from "react";

import {
    formatDateFilterForFilterOptions,
    formatStringArrayFilterForFilterOptions,
    formatStringFilterForFilterOptions,
    removeFalsyPropsExceptBoolean,
} from "../../../utilitites/filterSearchBar";

import { IFilters } from "./SchedulerJobListAndSearch/SchedulerSearchBar";

export const isGroupProcessInGroupFilter = (
    groupIdProcessList: number[],
    groupNameFilterList: string[],
    groupModelList: IUserGroupDbModel[]
): boolean => {
    // if itemList is empty, we don't apply filter.
    if (groupNameFilterList.length === 0) return true;

    return Boolean(
        groupNameFilterList.find((filterGroup) =>
            groupIdProcessList.find(
                (processGroupId) => groupModelList.find((groupItem) => groupItem.userGroupId === processGroupId)?.name === filterGroup
            )
        )
    );

    // let foundGroup = false;
    // // For each specified group in the filters
    // for (const filterGroup of groupNameFilterList) {
    //     // For each group for the process groups
    //     for (const ProcessGroupId of groupIdProcessList) {
    //         // Find the group model associated to the processGroupId
    //         const groupModel = groupModelList.find((groupItem): boolean => groupItem.userGroupId === ProcessGroupId);
    //         // Compare the name of the filter and the group name if found.
    //         if (groupModel && groupModel.name === filterGroup) {
    //             foundGroup = true;
    //             break;
    //         }
    //     }
    //     if (foundGroup) break;
    // }
    // return foundGroup;
};

export const isDateAfterFromFilter = (date: number, dateFrom: string): boolean => {
    // check if dateFrom exsits
    if (dateFrom == "") return true;

    // check if date is not 0 (defualt value)
    if (date === 0) return true;

    // check if date is after FromDate
    if (date >= getTimestampFromDateStr(dateFrom, TimeUnitsEnum.MILLISECOND)) return true;

    // Date is not after FromDate
    return false;
};

export const isDateBeforeToFilter = (date: number, dateTo: string): boolean => {
    // check if dateTo exsits
    if (dateTo == "") return true;

    // check if date is not 0 (no date specified (default value))
    if (date === 0) return true;

    // check if date is before dateTo
    if (date <= getTimestampFromDateStr(dateTo, TimeUnitsEnum.MILLISECOND)) return true;

    // Date is not before DateTo
    return false;
};

export const isDateBetweenTwoDatesFilter = (date: number, dateFrom: string, dateTo: string): boolean => {
    return isDateAfterFromFilter(date, dateFrom) && isDateBeforeToFilter(date, dateTo);
};

export const isItemInListFilter = (item: string, itemList: string[]): boolean => {
    // if itemList is empty, we don't apply filter.
    if (itemList.length === 0) return true;

    // verify if itemList include the item
    return itemList.includes(item);
};

export const isFilterSubstringOfValue = (value: string, filter: string): boolean => {
    // if filter is empty, we don't apply filter.
    if (filter == "") return true;

    // Verify if the filter value is a substring of the value
    return value.toLowerCase().includes(filter.toLowerCase());
};

export const isFilterInJobProcessToApply = (filters: IFilters): boolean => {
    return filters.group.length > 0 || filters.jobProcessScheduledDateFrom !== "" || filters.jobProcessScheduledDateTo !== "";
};

export const verifyIfFiltersAreEmpty = (filters: IFilters): boolean => {
    let isNewFiltersEmpty = true;
    for (const propName in filters) {
        if (
            !filters[propName as keyof IFilters] ||
            (typeof filters[propName as keyof IFilters] === "object" && Object.keys(filters[propName as keyof IFilters]).length === 0)
        ) {
            continue;
        } else {
            isNewFiltersEmpty = false;
            break;
        }
    }
    return isNewFiltersEmpty;
};

export const convertUiFiltersToBackendFilters = (
    uiFilters: IFilters,
    plannerModelList: IUserDbModel[],
    groupModelList: IUserGroupDbModel[]
): IJobsFiltersOptions => {
    const plannerUserIdList: number[] = [];
    uiFilters.planner.forEach((plannerUserName) => {
        const plannerUserId = plannerModelList.find((plannerModel) => plannerModel.userName === plannerUserName);
        if (plannerUserId) {
            plannerUserIdList.push(plannerUserId.userId);
        }
    });

    const groupIdList: number[] = [];
    uiFilters.group.forEach((groupDisplayName) => {
        const GroupId = groupModelList.find((groupModel) => groupModel.name === groupDisplayName);
        if (GroupId) {
            groupIdList.push(GroupId.userGroupId);
        }
    });

    // Proceed with the call to the backend
    return removeFalsyPropsExceptBoolean({
        activeJobProcessOnly: uiFilters.activeJobProcessOnly,
        optimizedQuery: true,
        masterSearch: formatStringFilterForFilterOptions(uiFilters.plainText),
        jobRefId: formatStringFilterForFilterOptions(uiFilters.jobRefId),
        partNumber: formatStringFilterForFilterOptions(uiFilters.partNumber),
        statuses: formatStringArrayFilterForFilterOptions(uiFilters.status) as Array<string> | undefined,
        scheduledDateFrom: formatDateFilterForFilterOptions(uiFilters.scheduledDateFrom),
        scheduledDateTo: formatDateFilterForFilterOptions(uiFilters.scheduledDateTo),
        requestedDateFrom: formatDateFilterForFilterOptions(uiFilters.requestedDateFrom),
        requestedDateTo: formatDateFilterForFilterOptions(uiFilters.requestedDateTo),
        plannedDateFrom: formatDateFilterForFilterOptions(uiFilters.plannedDateFrom),
        plannedDateTo: formatDateFilterForFilterOptions(uiFilters.plannedDateTo),
        referenceId: formatStringFilterForFilterOptions(uiFilters.reference),
        processesFilters: {
            scheduledDateFrom: formatDateFilterForFilterOptions(uiFilters.jobProcessScheduledDateFrom),
            ScheduledDateTo: formatDateFilterForFilterOptions(uiFilters.jobProcessScheduledDateTo),
            groups: formatStringArrayFilterForFilterOptions(groupIdList) as Array<number> | undefined,
        },
        planners: formatStringArrayFilterForFilterOptions(plannerUserIdList) as Array<number> | undefined,
    });
};

export const applyJobFilters = (job: IJobUiModel, filters: IFilters): boolean => {
    // Check part number
    if (!isFilterSubstringOfValue(job.partNumber, filters.partNumber)) return false;

    // Check job number
    if (!isFilterSubstringOfValue(job.jobRefId, filters.jobRefId)) return false;

    // Check reference
    if (!isFilterSubstringOfValue(job.referenceId, filters.reference)) return false;

    // check Status
    if (!isItemInListFilter(job.status, filters.status)) return false;

    // check PlannedDate
    if (!isDateBetweenTwoDatesFilter(job.plannedOn, filters.plannedDateFrom, filters.plannedDateTo)) return false;

    // check RequestedDate
    if (!isDateBetweenTwoDatesFilter(job.requestedOn, filters.requestedDateFrom, filters.requestedDateTo)) return false;

    // check jobScheduledDate
    if (!isDateBetweenTwoDatesFilter(job.scheduledOn, filters.scheduledDateFrom, filters.scheduledDateTo)) return false;

    // If we reach here, it is because the process passes all filters and the job should be proceed to the next filter
    return true;
};

export const applyProcessFilters = (processList: IJobProcessUiModel[], filters: IFilters, groupModelList: IUserGroupDbModel[]): boolean => {
    // make sure that there is filter to apply and a process list not empty
    if (!isFilterInJobProcessToApply(filters) || processList.length == 0) return true;

    // Assume that all JobProcesses are filtered out by default.
    let foundProcess = false;
    // When one is found, set the foundProcess flag to true and break the loop -> consider that we keep this job.
    // If the loop has ended normally, the foundProcess flag will still be set to false --> filterout this job
    for (const process of processList) {
        // Check processScheduledDate:
        // If filtered out, continue to the next process.
        // If filtered in, continue to the next filter to test
        if (!isDateBetweenTwoDatesFilter(process.scheduledOn, filters.jobProcessScheduledDateFrom, filters.jobProcessScheduledDateTo)) {
            continue;
        }

        // If our process is associated with groups.
        if (process.groupIdsJSON.length > 0) {
            // If filtered out, continue to the next process.
            // If filtered in, continue to the next filter to test
            if (!isGroupProcessInGroupFilter(process.groupIdsJSON, filters.group, groupModelList)) continue;
        }

        // If we reach here, it is because the process passes all filters and the job should be proceed to the next filter
        foundProcess = true;
        break;
    }
    return foundProcess;
};

export const applyPlainTextFilter = (
    job: IJobUiModel,
    plainTextFilter: string,
    plannerName: string,
    groupModelList: IUserGroupDbModel[]
): boolean => {
    if (!plainTextFilter) return true;

    // create string that contains all searchable params (Work Order, Reference ID, Part Number and Processes' name)
    let searchString = `${job.jobRefId} ${job.referenceId} ${job.partNumber} ${plannerName}
        ${job.customer} `;
    for (const process of job.processList) {
        // Add process name
        searchString += process.processName;
        // Add group
        if (process.groupIdsJSON && process.groupIdsJSON) {
            for (const group of process.groupIdsJSON) {
                const groupListItem = groupModelList.find((groupItem): boolean => groupItem.userGroupId === group);
                if (groupListItem !== undefined) {
                    searchString += groupListItem.name;
                }
            }
        }
    }

    // check if string contains the searched plainText
    return isFilterSubstringOfValue(searchString, plainTextFilter);
};

/**
 * Tests a single job against the filters
 *
 * @param {IJobUiModel} job - job to test
 * @returns {boolean} - true if job passed filters
 */
export const applyFilters = (
    job: IJobUiModel,
    filters: IFilters,
    plannerModelList: IUserDbModel[],
    groupModelList: IUserGroupDbModel[]
): boolean => {
    const planner = plannerModelList.find((planner): boolean => planner.userId === job.plannerId);
    const plannerName = planner ? planner.userName : "";

    // Try to order the filters by the probability of usage by the user to reduced computation.

    // Master search through all fields
    // check if string contains the searched plainText
    if (!applyPlainTextFilter(job, filters.plainText, plannerName, groupModelList)) return false;

    if (!applyJobFilters(job, filters)) return false;

    // check Planner
    if (!isItemInListFilter(plannerName, filters.planner)) return false;

    // If no processes pass the filters, filter out this job.
    if (!applyProcessFilters(job.processList, filters, groupModelList)) return false;

    // If it reached thus far, it means all filters has been passed.
    return true;
};

// Context for selected job
type SchedulerContext = [
    IJobUiModel | undefined, // Selected job
    (job: IJobUiModel | undefined) => void, // Set selected job
];

export const SchedulerContext = React.createContext<SchedulerContext>([undefined, (): void => void 0]);
