import { EnumUserStatus, IUserGroupDbModel, IUserRoleDbModel, UserGroupStatusEnum } from "@kortex/aos-common";
import * as React from "react";
import { useEffect, useState } from "react";
import { Typography, FormControl, Select, Input, MenuItem, Checkbox, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { secondaryPalette, primaryPalette } from "@aos/react-components";

import { useEntitiesUsersGroups, useEntitiesUsersRoles } from "../../../../redux/effects";
import KortexSearchField from "../../../core/KortexSearchField";
import { useTranslate } from "../../../../hooks/useTranslate";
import { formatUserStatus } from "../../../../utilitites/formatUserStatus";

export interface IUserFilters {
    plainText: string;
    status: string[];
    groups: number[];
    roles: number[];
    showArchived: boolean;
}

export const defaultUserFilters: IUserFilters = {
    plainText: "",
    status: [],
    groups: [],
    roles: [],
    showArchived: true,
};

const useStyles = makeStyles({
    searchBox: {
        padding: "5px",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    filterInput: {
        flex: 2,
    },
    filterInputSelect: {
        padding: "5px",
    },
    filterDateInput: {
        flex: 1,
    },
    clearFilterLabel: {
        color: secondaryPalette[500],
        "&:hover": {
            color: primaryPalette[500],
        },
    },
});

interface IOwnProps {
    onFiltersChange: (filters: IUserFilters) => void;
}

export default function UserSearchBar(props: IOwnProps): JSX.Element {
    const { onFiltersChange } = props;

    const classes = useStyles();
    const allUserGroups = useEntitiesUsersGroups();
    const allUserRoles = useEntitiesUsersRoles();
    const translate = useTranslate();

    const [filters, setFilters] = useState<IUserFilters>(defaultUserFilters);

    /**
     * When filters change, execute onFiltersChange cb
     */
    useEffect((): void => {
        onFiltersChange(filters);
    }, [filters]);

    /**
     * Called when the inner filters change
     *
     * @param {string} key - indicates which filter changed
     */
    const handleFilterChange =
        (key: keyof IUserFilters): ((e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => void) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>): void => {
            setFilters({ ...filters, [key]: e.target.value });
        };

    /**
     * Called when the Show Archived button is toggled
     */
    const handleToggleArchived = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, showArchived: e.target.checked });
    };

    /**
     * Called when filters change. Checks if filters equal their default value (plainText is excluded from check)
     */
    const isFilterActive = (): boolean => {
        let active = false;
        Object.keys(filters).forEach((key): void => {
            if (key !== "plainText" && filters[key] !== defaultUserFilters[key]) {
                active = true;
            }
        });
        return active;
    };

    /**
     * Called when clicking the Clear Filter label
     */
    const handleClearFilters = (): void => {
        setFilters(defaultUserFilters);
    };

    /**
     * Called when the text in the searchbar changes
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        setFilters({ ...filters, plainText: val });
    };

    return (
        <Paper>
            <KortexSearchField className={classes.searchBox} onChange={handleSearchTextChange} isFilterActive={isFilterActive()}>
                <React.Fragment>
                    {/* STATUS FILTER */}
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("scheduler.status")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("status")}
                                value={filters.status}
                            >
                                {Object.values(EnumUserStatus).map(
                                    (key: EnumUserStatus): JSX.Element => (
                                        <MenuItem key={key} value={key}>
                                            {formatUserStatus(key, translate)}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    {/* ROLES FILTER */}
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("settings.userSettings.roles")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("roles")}
                                value={filters.roles}
                            >
                                {allUserRoles.map(
                                    (role: IUserRoleDbModel, index: number): JSX.Element => (
                                        <MenuItem key={index} value={role.userRoleId}>
                                            {role.name}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    {/* GROUPS FILTER */}
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("settings.userSettings.groups")}</Typography>
                        <FormControl className={classes.filterInput}>
                            <Select
                                inputProps={{
                                    className: classes.filterInputSelect,
                                }}
                                multiple={true}
                                input={<Input id="select-multiple-chip" />}
                                onChange={handleFilterChange("groups")}
                                value={filters.groups}
                            >
                                {allUserGroups
                                    .filter((group) => group.status !== UserGroupStatusEnum.ARCHIVED)
                                    .map(
                                        (group: IUserGroupDbModel, index: number): JSX.Element => (
                                            <MenuItem key={index} value={group.userGroupId}>
                                                {group.name}
                                            </MenuItem>
                                        )
                                    )}
                            </Select>
                        </FormControl>
                    </div>

                    {/* SHOW ARCHIVED USERS FILTER */}
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("settings.userSettings.optionShowArchived")}</Typography>
                        <Checkbox checked={filters.showArchived} onChange={handleToggleArchived} />
                    </div>
                    {/* CLEAR FILTERS */}
                    <div className={classes.optionsItem}>
                        <div className={classes.filterLabel} />
                        <Typography onClick={handleClearFilters} className={classes.clearFilterLabel}>
                            {translate("scheduler.clearFilters")}
                        </Typography>
                    </div>
                </React.Fragment>
            </KortexSearchField>
        </Paper>
    );
}
