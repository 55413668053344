export var EnumRequestResponse;
(function (EnumRequestResponse) {
    EnumRequestResponse["APP_JSON"] = "application/json";
    EnumRequestResponse["APP_JSON_RAW"] = "application/json-raw";
    EnumRequestResponse["APP_XML"] = "application/xml";
})(EnumRequestResponse || (EnumRequestResponse = {}));
export var EnumRestMethod;
(function (EnumRestMethod) {
    EnumRestMethod["DELETE"] = "DELETE";
    EnumRestMethod["GET"] = "GET";
    EnumRestMethod["POST"] = "POST";
    EnumRestMethod["PUT"] = "PUT";
})(EnumRestMethod || (EnumRestMethod = {}));
export class ConnectorRestConfig {
    constructor() {
        this.accept = EnumRequestResponse.APP_JSON;
        this.contentType = EnumRequestResponse.APP_JSON;
        this.method = EnumRestMethod.GET;
        this.params = {
            body: [],
            header: [],
            query: [],
        };
        this.url = "";
    }
}
/**
 * Get each key and value of an array
 *
 * @param {object[]} keyValues - array of key and value to evaluate
 */
export function getRestParams(keyValues) {
    const result = {};
    if (!keyValues || keyValues.length <= 0) {
        return result;
    }
    for (const keyValue of keyValues) {
        result[keyValue.key] = keyValue.value;
    }
    return result;
}
