import * as Step from "../processActionStep";
//--------------------------------------------------------------
// Utilities
//--------------------------------------------------------------
export function createStep(type, params) {
    const { autoPlayNext = false, canRetry = false, config, continueIfFail = false, electronicSignatureApproval = false, electronicSignatureContext = "", label = "Step", minTimeSec = 0, previousStepId, processActionId = 0, processActionStepId = 0, standardTimeSec = 0, validationGroupId = null, } = params;
    return {
        processActionStepId,
        autoPlayNext,
        canRetry,
        config: { ...configMap[type](), ...config },
        continueIfFail,
        electronicSignatureApproval,
        electronicSignatureContext,
        label,
        minTimeSec,
        previousStepId,
        processActionId,
        standardTimeSec,
        validationGroupId,
    };
}
const configMap = {
    "core-condition": Step.createStepConfigCondition,
    "core-connector": Step.createStepConfigConnector,
    "core-data-store": Step.createStepConfigDataStore,
    "core-email": Step.createStepConfigEmail,
    "core-fail": Step.createStepConfigFail,
    "core-failure-ticket-create": Step.createStepConfigFailureTicketFork,
    "core-input": Step.createStepConfigInput,
    "core-loop": Step.createStepConfigLoop,
    "core-math": Step.createStepConfigMath,
    "core-message": Step.createStepConfigMessage,
    "core-output": Step.createStepConfigOutput,
    "core-parser": Step.createStepConfigParser,
    "core-robot": Step.createStepConfigRobot,
    "core-routing-fail": Step.createStepConfigRoutingFail,
    "core-routing-process": Step.createStepConfigRoutingProcess,
    "core-set": Step.createStepConfigSet,
    "core-stop-process": Step.createStepConfigStopProcess,
    "core-time": Step.createStepConfigTime,
    "core-unknown": () => ({}),
    "core-work-instructions": Step.createStepConfigWorkInstructions,
};
