import { KortexTextField, theme } from "@aos/react-components";
import { WoBomItems } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";
import { InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { FC, useState } from "react";

import { useWoBomTableContext } from "../context";
import { isBomItemNonTraceable, isBomItemTraceable } from "../utils";

import { WoBomTableItem } from "./item";

const useStyles = makeStyles({
    header: {
        backgroundColor: theme.palette.grey[300],
    },
    itemEven: {
        backgroundColor: theme.palette.grey[100],
    },
    itemOdd: {
        backgroundColor: theme.palette.grey[200],
    },
    searchTextField: {
        width: "600px",
    },
    spacer: {
        flex: 1,
    },
    tableCell: {
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
    tableTitleContainer: {
        alignItems: "center",
        display: "inline-flex",
        gap: "16px",
    },
});

type TraceabilityType = "traceable" | "non-traceable";

interface IOwnProps {
    itemFilter: TraceabilityType;
    title: React.ReactNode;
}

const WoBomTableItems: FC<IOwnProps> = (props) => {
    const { itemFilter, title } = props;

    const classes = useStyles();
    const { woBom } = useWoBomTableContext();
    const language = useSelectorLanguage();
    const translate = useTranslate();

    const [search, setSeach] = useState<string>("");

    /**
     * Returns a filtered list of the items based on whether or not they are traceable AND the value of the search
     */
    const getFilteredItems = (): WoBomItems => {
        const filteredItems: WoBomItems = {};
        const formattedSearch = search.toLowerCase();

        for (const partNumber of Object.keys(woBom.items).sort((a, b) => a.localeCompare(b, language, { sensitivity: "base" }))) {
            // Filter items based on search value
            if (
                formattedSearch &&
                !(
                    woBom.items[partNumber].partNumber.toLowerCase().includes(formattedSearch) ||
                    woBom.items[partNumber].description.toLowerCase().includes(formattedSearch)
                )
            ) {
                continue;
            }

            // Filter items based on whether or not they are traceable
            if (itemFilter === "non-traceable" && isBomItemTraceable(woBom.items[partNumber])) continue;
            if (itemFilter === "traceable" && isBomItemNonTraceable(woBom.items[partNumber])) continue;

            filteredItems[partNumber] = woBom.items[partNumber];
        }

        return filteredItems;
    };

    /**
     * Updates the search value
     */
    const handleSearch = (value: string): void => {
        setSeach(value);
    };

    return (
        <>
            <div className={classes.tableTitleContainer}>
                <Typography variant="h6">{title}</Typography>
                <div className={classes.spacer} />
                <KortexTextField
                    changedDelayMS={500}
                    className={classes.searchTextField}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    label={translate("general.search")}
                    onChanged={handleSearch}
                    TextFieldProps={{
                        id: `woBomTableSearch${title}Id`,
                        placeholder: translate("bomPage.bomTable.searchPartNumberOrDescription"),
                    }}
                    value={search}
                    variant="standard"
                />
            </div>
            <Table>
                <TableHead className={classes.header}>
                    <TableRow>
                        <TableCell align="left" className={classes.tableCell} width="10%">
                            <Typography variant="h6">{translate("bomPage.bomTable.partNumber")}</Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell} width="31%">
                            <Typography variant="h6">{translate("bomPage.bomTable.description")}</Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell} width="8%">
                            <Typography variant="h6">{translate("bomPage.bomTable.location")}</Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell} width="8%">
                            <Typography variant="h6">{translate("bomPage.bomTable.materialLocation")}</Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell} width="8%">
                            <Typography variant="h6">{translate("bomPage.bomTable.lotSerialType")}</Typography>
                        </TableCell>
                        <TableCell align="left" className={classes.tableCell} width="5%">
                            <Typography variant="h6">{translate("bomPage.bomTable.quantity")}</Typography>
                        </TableCell>
                        {itemFilter === "traceable" ? (
                            <>
                                <TableCell align="left" className={classes.tableCell} width="13%">
                                    <Typography variant="h6">{translate("bomPage.bomTable.traceability")}</Typography>
                                </TableCell>
                                <TableCell align="left" className={classes.tableCell} width="13%">
                                    <Typography variant="h6">{translate("bomPage.bomTable.serialNumber")}</Typography>
                                </TableCell>
                            </>
                        ) : (
                            <TableCell align="left" className={classes.tableCell} width="26%" />
                        )}
                        <TableCell align="left" className={classes.tableCell} width="4%" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(getFilteredItems()).map((item, index) => (
                        <WoBomTableItem
                            classes={{
                                root: index % 2 === 0 ? classes.itemEven : classes.itemOdd,
                            }}
                            index={index}
                            item={item}
                            key={`woBomTableItem${index}`}
                        />
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

export default WoBomTableItems;
