import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    ProcessActionSettingsAllReq,
    ProcessActionSettingsAllRes,
    ProcessActionSettingsUpdateReq,
    ProcessActionSettingsUpdateRes,
    ProcessActionSettingsUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { processActionSettingsAll } from "./all";
import { processActionSettingsUpdate } from "./update";
import { processActionSettingsUpdated } from "./updated";

interface IAOSClientServiceWrappedProcessActionSettings {
    getAll: AOSClientServiceRequestWrapped<ProcessActionSettingsAllReq, ProcessActionSettingsAllRes>;
    onUpdated: AOSClientServiceNotificationWrapped<ProcessActionSettingsUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<ProcessActionSettingsUpdateReq, ProcessActionSettingsUpdateRes>;
}

type AOSClientServiceWrappedProcessActionSettings = IAOSClientServiceWrappedProcessActionSettings;

// ADD_API_CALL Process action settings
export function generateProcessActionSettingsServiceClient(
    router: IRouterClient,
    notifier: EventEmitter
): AOSClientServiceWrappedProcessActionSettings {
    return {
        getAll: AOSClientServiceWrapperUI.request(processActionSettingsAll(router)),
        onUpdated: AOSClientServiceWrapperUI.notification(processActionSettingsUpdated(router, notifier)),
        update: AOSClientServiceWrapperUI.request(processActionSettingsUpdate(router)),
    };
}

generateProcessActionSettingsServiceClient.serviceId = 61;
