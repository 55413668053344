import { KortexDialogConfirmation, KortexTextField, KortexTimeField } from "@aos/react-components";
import { IUserDbModel, IUserGroupDbModel, ProcessActionStep, ProcessEditorRightsEnum, UserGroupStatusEnum } from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { Checkbox, Divider, FormControlLabel, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesUsers, useEntitiesUsersGroups } from "../../../../../redux/effects";
import { useSelectorUsersGroups } from "../../../../../redux/selectors";
import { userCanWrite } from "../../../../../utilitites/IUserRights";

const useStyles = makeStyles({
    createNext: {
        marginTop: "32px",
    },
    dialog: {
        padding: 0,
    },
    dialogContainer: {
        padding: 0,
    },
    dialogContent: {
        padding: 0,
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        flexGrow: 1,
        padding: 0,
    },
    settingsContainer: {
        display: "flex",
        flexDirection: "column",
    },
    timeField: {
        marginRight: "5px",
    },
    section: {
        display: "grid",
        padding: "15px 30px 15px 30px",
    },
    sectionFlow: {
        display: "flex",
    },
    section2cols: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
    },
});

interface IOwnProps {
    autoNextDisabled?: boolean;
    canRetryDisabled?: boolean;
    requiresUserValidationDisabled?: boolean;
    edit: boolean; // Set to true when editing the step, set to false when creating the step
    editedStep?: ProcessActionStep;
    open: boolean;
    onCancel: () => void;
    onSave: (step: Partial<ProcessActionStep>, createNext: boolean) => void;
    userAccessLevel?: ProcessEditorRightsEnum;
}

export default function ActionStepEditor(props: IOwnProps): JSX.Element {
    const {
        autoNextDisabled,
        canRetryDisabled,
        edit,
        editedStep,
        open,
        onCancel,
        onSave,
        requiresUserValidationDisabled,
        userAccessLevel,
    } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const groups = useEntitiesUsersGroups();
    const users = useEntitiesUsers();

    useStopKeybindPropagation(open, { important: true });

    const [currentStep, setCurrentStep] = useState<ProcessActionStep | undefined>(editedStep);
    const [dirty, setDirty] = useState<boolean>(false);

    const [autoPlayNext, setAutoPlayNext] = useState<boolean>(currentStep ? currentStep.autoPlayNext : false);
    const [canRetry, setCanRetry] = useState<boolean>(currentStep ? currentStep.canRetry : false);

    const [electronicSignatureApprovalDisabled, setElectronicSignatureApprovalDisabled] = useState<boolean>(false);
    const [electronicSignatureApproval, setElectronicSignatureApproval] = useState<boolean>(
        currentStep && currentStep.electronicSignatureApproval ? currentStep.electronicSignatureApproval : false
    );
    const [electronicSignatureContext, setElectronicSignatureContext] = useState<string>(
        currentStep && currentStep.electronicSignatureContext ? currentStep.electronicSignatureContext : ""
    );

    const [continueIfFail, setContinueIfFail] = useState<boolean>(currentStep ? Boolean(currentStep.continueIfFail) : false);
    const [createNext, setCreateNext] = useState<boolean>(false);
    const [minimumTimeSeconds, setMinimumTimeSeconds] = useState<number>(currentStep ? currentStep.minTimeSec : 0);
    const [label, setLabel] = useState<string>(currentStep ? currentStep.label : "Step");
    const [targetTimeSeconds, setTargetTimeSeconds] = useState<number>(currentStep ? currentStep.standardTimeSec : 0);
    const [selectedValidationGroupId, setSelectedValidationGroupId] = useState<number | null>(currentStep?.validationGroupId || 0);

    const [availableApprovalGroups, setAvailableApprovalGroups] = useState<ReturnType<typeof useSelectorUsersGroups>>([]);
    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Effect which updates step if not dirty
     */
    useEffect(() => {
        if (!dirty) {
            setCurrentStep(editedStep);
        }
    }, [editedStep]);

    /**
     * Effect which assign prop values to states
     */
    useEffect((): void => {
        if (currentStep) {
            setAutoPlayNext(currentStep.autoPlayNext);
            setCanRetry(currentStep.canRetry);
            setElectronicSignatureApproval(currentStep.electronicSignatureApproval ? currentStep.electronicSignatureApproval : false);
            setElectronicSignatureContext(currentStep.electronicSignatureContext ? currentStep.electronicSignatureContext : "");
            setContinueIfFail(Boolean(currentStep.continueIfFail));
            setMinimumTimeSeconds(currentStep.minTimeSec);
            setLabel(currentStep.label);
            setSelectedValidationGroupId(currentStep.validationGroupId || 0);
            setTargetTimeSeconds(currentStep.standardTimeSec);
        }
    }, [currentStep]);

    /**
     * Effect which assigns electronic signature disable
     */
    useEffect(() => {
        setElectronicSignatureApprovalDisabled(!selectedValidationGroupId || selectedValidationGroupId === 0);
    }, [selectedValidationGroupId]);

    /**
     * Effect which uncheck "Create another" when closing the step editor
     */
    useEffect((): void => {
        if (open) {
            validateSelectedValidationGroup();
        } else {
            setCreateNext(false);
        }
    }, [open]);

    /**
     * Resets selected validation group if it no longer exists, is archived or if it has no user
     */
    useEffect((): void => {
        // Find non-archived groups in which there is at least 1 user
        const groupsWithUsers = groups.filter((group: IUserGroupDbModel): boolean =>
            users.some(
                (user: IUserDbModel): boolean =>
                    group.userGroupId !== undefined &&
                    group.status !== UserGroupStatusEnum.ARCHIVED &&
                    user.groups.includes(group.userGroupId)
            )
        );

        setAvailableApprovalGroups(groupsWithUsers);
        validateSelectedValidationGroup();
    }, [groups, users]);

    /**
     * Resets selected validation group if no longer valid
     */
    const validateSelectedValidationGroup = (): void => {
        const selectedValidationGroup = groups.find((group: IUserGroupDbModel): boolean => group.userGroupId === selectedValidationGroupId);

        if (
            !selectedValidationGroup ||
            !users.some(
                (user: IUserDbModel): boolean =>
                    selectedValidationGroup.userGroupId !== undefined && user.groups.includes(selectedValidationGroup.userGroupId)
            )
        ) {
            setSelectedValidationGroupId(0);
        }
    };

    /**
     * Function which handles the creation of a new step
     */
    const handleSave = (): void => {
        if (!isStepNameValid() || (electronicSignatureApproval && !isApprovalContextValid())) {
            return;
        }

        onSave(
            {
                ...currentStep,
                autoPlayNext,
                canRetry,
                electronicSignatureApproval:
                    selectedValidationGroupId && selectedValidationGroupId > 0 ? electronicSignatureApproval : false,
                electronicSignatureContext: selectedValidationGroupId && electronicSignatureApproval ? electronicSignatureContext : "",
                continueIfFail,
                label: label,
                minTimeSec: minimumTimeSeconds,
                standardTimeSec: targetTimeSeconds,
                validationGroupId: selectedValidationGroupId,
            },
            createNext
        );
        setDirty(false);
    };

    /**
     * Handles the 'Auto play next' checkbox state changes
     */
    const handleAutoPlayNextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (autoNextDisabled) return void 0;
        setDirty(true);
        setAutoPlayNext(event.target.checked);
    };

    /**
     * Handles the 'Can Retry' checkbox state changes
     */
    const handleCanRetryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDirty(true);
        setCanRetry(event.target.checked);
    };

    /**
     * Handles the 'Electronic Signature Approval" checkbox state changes
     */
    const handleElectronicSignatureApprovalChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDirty(true);
        setElectronicSignatureApproval(event.target.checked);
    };

    /**
     * Handles the ElectronicSignatureContext checkbox state changes
     */
    const handleElectronicSignatureContextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setElectronicSignatureContext(event.target.value);
    };

    /**
     * Handles the 'Continue If Fail' checkbox state changes
     */
    const handleContinueFailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDirty(true);
        setContinueIfFail(event.target.checked);
    };

    /**
     * Handles the 'Create next' checkbox state changes
     */
    const handleCreateNextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDirty(true);
        setCreateNext(event.target.checked);
    };

    /**
     * Function which handles the value change of the step minimum time (in seconds)
     */
    const handleMinimumTimeSecondsChange = (seconds: number): void => {
        setDirty(true);
        setMinimumTimeSeconds(seconds);
    };

    /**
     * Function which handles the value change of the step target time (in seconds)
     */
    const handleTargetTimeSecondsChange = (seconds: number): void => {
        setDirty(true);
        setTargetTimeSeconds(seconds);
    };

    /**
     * Function which handles the value change of the step label
     */
    const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setDirty(true);
        setLabel(e.target.value);
    };

    /**
     * Called when a user presses a key in the TextField
     */
    const handleKeyDown = (event: React.KeyboardEvent): void => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleSave();
        }
    };

    /**
     * Function that validates the step label
     */
    const isStepNameValid = (): boolean => {
        return label.length >= 3;
    };

    /**
     * Function that validates the signature approval context text
     */
    const isApprovalContextValid = (): boolean => {
        return electronicSignatureApproval ? electronicSignatureContext.length >= 10 : false;
    };

    /**
     * Handles the selection of group for step validation
     */
    const handleSelectRequiredValidationGroupChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setDirty(true);
        setSelectedValidationGroupId(parseInt(event.target.value, 10));
    };

    /**
     * Get groups in which there is at least 1 user
     */
    const getUserGroupSelectItems = (): JSX.Element[] => {
        const groupItems = availableApprovalGroups // Generate menu items for each filtered groups
            .map(
                (group: IUserGroupDbModel, index: number): JSX.Element => (
                    <MenuItem key={index} value={group.userGroupId}>
                        {group.name}
                    </MenuItem>
                )
            );
        groupItems.unshift(
            <MenuItem key={availableApprovalGroups.length} value={0}>
                {translate("action.stepEditor.none")}
            </MenuItem>
        );
        // Add default choice
        return groupItems;
    };

    return (
        <KortexDialogConfirmation
            classes={{
                dialogContent: classes.dialogContent,
            }}
            open={open}
            dialogProps={{
                classes: {
                    container: classes.dialogContainer,
                    root: classes.dialog,
                },
                id: "actionStepEditorDialogId",
                fullScreen: false,
                maxWidth: "md",
            }}
            textLabels={{
                titleLabel: translate("action.stepEditor.title"),
                cancelButtonLabel: translate("action.stepEditor.cancel"),
                proceedButtonLabel: translate("action.stepEditor.save"),
            }}
            onCancel={onCancel}
            onConfirm={handleSave}
            closeOnEscape={true}
            confirmDisabled={readOnly}
        >
            <div className={classes.formContainer}>
                <div className={classes.section}>
                    <div>
                        <KortexTextField
                            label={translate("action.stepEditor.label")}
                            InputProps={{
                                id: "actionStepEditorLabelId",
                                autoFocus: true,
                            }}
                            error={isStepNameValid() ? "" : translate("action.stepEditor.label.error")}
                            onChange={handleNameChange}
                            TextFieldProps={{
                                disabled: readOnly,
                            }}
                            value={label}
                        />
                    </div>
                </div>
                <Divider />

                <div className={classNames(classes.section, classes.sectionFlow)}>
                    <KortexTimeField
                        format="hms"
                        id="actionStepEditorStandardTimeId"
                        onChange={handleTargetTimeSecondsChange}
                        seconds={targetTimeSeconds}
                        textLabels={{
                            inputLabel: translate("action.stepEditor.targetTime"),
                        }}
                        className={classes.timeField}
                        variant="outlined"
                        disabled={readOnly}
                    />
                    <KortexTimeField
                        format="hms"
                        id="actionStepEditorMinimumTimeId"
                        onChange={handleMinimumTimeSecondsChange}
                        seconds={minimumTimeSeconds}
                        textLabels={{
                            inputLabel: translate("action.stepEditor.minimumTime"),
                        }}
                        variant="outlined"
                        disabled={readOnly}
                    />
                </div>
                <Divider />
                <div className={classNames(classes.section, classes.section2cols)}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={autoPlayNext}
                                id="actionStepEditorAutoPlayNextId"
                                onChange={handleAutoPlayNextChange}
                                value="autoPlayNextChecked"
                            />
                        }
                        disabled={readOnly || autoNextDisabled}
                        label={translate("action.stepEditor.autoPlayNext")}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={canRetry}
                                id="actionStepEditorCanRetryId"
                                onChange={handleCanRetryChange}
                                value="canRetryChecked"
                            />
                        }
                        disabled={readOnly || canRetryDisabled}
                        label={translate("action.stepEditor.canRetry")}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={continueIfFail}
                                id="actionStepEditorContinueIfFail"
                                onChange={handleContinueFailChange}
                                value="continueIfFail"
                            />
                        }
                        disabled={readOnly || canRetryDisabled}
                        label={translate("action.stepEditor.continueIfFail")}
                    />
                    {/* Only enabled when creating the step (not visible when editing the step) */}
                    {!edit && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={createNext}
                                    id="actionStepEditorCreateAnotherId"
                                    onChange={handleCreateNextChange}
                                    value="createNextChecked"
                                />
                            }
                            label={translate("action.stepEditor.createAnother")}
                        />
                    )}
                </div>
                {availableApprovalGroups.length > 0 && (
                    <>
                        <Divider />
                        <div className={classes.section}>
                            <KortexTextField
                                InputProps={{
                                    id: "actionStepEditorValidationGroup",
                                }}
                                label={translate("action.stepEditor.validationGroup")}
                                onChange={handleSelectRequiredValidationGroupChange}
                                TextFieldProps={{
                                    disabled: readOnly || requiresUserValidationDisabled,
                                    select: true,
                                }}
                                value={selectedValidationGroupId ? selectedValidationGroupId : 0}
                            >
                                {getUserGroupSelectItems()}
                            </KortexTextField>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={electronicSignatureApproval}
                                        id="actionStepEditorElectronicSignatureApprovalId"
                                        onChange={handleElectronicSignatureApprovalChange}
                                        value="electronicSignatureApprovalChecked"
                                    />
                                }
                                disabled={readOnly || electronicSignatureApprovalDisabled}
                                label={translate("action.stepEditor.electronicSignatureApproval")}
                            />
                            {!electronicSignatureApprovalDisabled && electronicSignatureApproval && (
                                <KortexTextField
                                    label={translate("action.stepEditor.electronicSignatureApprovalContext")}
                                    InputProps={{
                                        id: "electronicSignatureContextId",
                                        autoFocus: true,
                                    }}
                                    error={
                                        isApprovalContextValid()
                                            ? ""
                                            : translate("action.stepEditor.electronicSignatureApprovalContext.error")
                                    }
                                    onKeyDown={handleKeyDown}
                                    onChange={handleElectronicSignatureContextChange}
                                    TextFieldProps={{
                                        disabled: readOnly,
                                        multiline: true,
                                        rows: 6,
                                    }}
                                    value={electronicSignatureContext}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </KortexDialogConfirmation>
    );
}
