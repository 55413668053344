// krc
import { getFormattedDate, getElapsedTimeStr, IWorkLog } from "@kortex/aos-common";
import { greyPalette, secondaryPalette, theme } from "@aos/react-components";
// react
import * as React from "react";
// mUI
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
// Component
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import KortexLabelText from "@kortex/aos-ui/components/core/KortexLabelText";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { Typography } from "@material-ui/core";

import ProcessIcon from "../../../../core/Icons/Process/Process";

const useStyles = makeStyles({
    card: {
        padding: "5px",
        display: "flex",
    },
    iconContainer: {
        color: greyPalette[500],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    cardFailDescription: {
        width: "100%",
        padding: "0 8px",
        display: "grid",
        alignItems: "center",
    },
    logInfoContainer: {
        width: "100%",
    },
    logInfoLine1: {
        display: "flex",
    },
    logInfoLine2: {
        display: "flex",
    },
    failInfoContainer: {
        width: "100%",
        borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
    commentContainer: {
        paddingLeft: "15px",
        width: "auto",
    },
    commentLabel: {
        color: secondaryPalette[500],
    },
    comment: {
        width: "auto",
    },
    failDescriptionContainer: {
        display: "flex",
    },
    textShort: {
        margin: "5px 15px",
        width: "auto",
    },
    textLarge: {
        margin: "5px 15px",
        width: "auto",
    },
    title: {
        display: "flex",
        alignItems: "center",
        fontSize: "1rem",
        fontWeight: "bold",
    },
});

interface IOwnProps {
    index: number;
    workLog: IWorkLog;
    originalResultMasterId: number;
}

export default function WorkLogCard(props: IOwnProps): JSX.Element {
    const { index, workLog, originalResultMasterId } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const logFromExecution = workLog.resultMasterId > 0;

    // Render
    return (
        <KortexPanelCard key={index} isSelected={false} padding={10}>
            <div className={classes.card} id={`reworkLogCard${index}Id`}>
                <div className={classes.iconContainer}>{logFromExecution ? <ProcessIcon /> : <EditIcon />}</div>
                <div className={classes.cardFailDescription}>
                    <Typography variant="h2" className={classes.title}>
                        {translate("rework.workLog")}
                    </Typography>
                    {/* Log info */}
                    <div className={classes.logInfoContainer}>
                        {/* Log info line 1*/}
                        <div className={classes.logInfoLine1}>
                            <KortexLabelText
                                label={translate("rework.date")}
                                text={workLog.createdOn ? getFormattedDate(workLog.createdOn, true) : ""}
                                className={classes.textShort}
                                id={`reworkDate${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("rework.reworkItemStatus")}
                                text={workLog.reworkItemStatus.title}
                                className={classes.textShort}
                                id={`reworkItemStatusLog${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("rework.user")}
                                text={workLog.user.fullName}
                                className={classes.textShort}
                                id={`user${index}Id`}
                            />
                            <KortexLabelText
                                label={translate("rework.workingTime")}
                                text={getElapsedTimeStr(workLog.workTime)}
                                className={classes.textShort}
                                id={`workingTime${index}Id`}
                            />
                            {/* Log info line 2*/}
                            {logFromExecution && (
                                <div className={classes.logInfoLine2}>
                                    <KortexLabelText
                                        label={translate("rework.process")}
                                        text={workLog.process.treeNodeProcess.label}
                                        className={classes.textLarge}
                                        id={`processLog${index}Id`}
                                    />
                                    {workLog.resultMasterId !== originalResultMasterId && (
                                        <KortexLabelText
                                            label={translate("player.runDetailsDialog.runType")}
                                            text={translate("player.processCompleted")}
                                            className={classes.textShort}
                                            id={`runType${index}Id`}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {/* Fail info */}
                    <div className={classes.failInfoContainer}>
                        <div className={classes.commentContainer}>
                            {/* Comment work log manual */}
                            <div className={classes.commentLabel}>{translate("player.comments")}</div>
                            <div className={classes.comment} id={`commentLog${index}Id`}>
                                {workLog.comments.length > 0 ? workLog.comments : translate("general.na")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </KortexPanelCard>
    );
}
