import jsonpath from "jsonpath";
import { ProcessEditorRightsEnum, TParserJson } from "@kortex/aos-common";
import { greyPalette, KortexTextField, rainbowPalette } from "@aos/react-components";
import {
    AppBar,
    Button,
    makeStyles,
    Popover,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@material-ui/core";
import SuccessIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import VariablePicker from "../../../../../../../components/core/VariablePicker/VariablePicker";
import { IUserRightsProps } from "../../../../../../../utilitites/IUserRights";
import { useTranslate } from "../../../../../../../hooks/useTranslate";

const JSONPATH_OPERATORS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const JSONPATH_EXAMPLES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

const useStyles = makeStyles({
    appBar: {
        marginBottom: "12px",
    },
    button: {
        marginLeft: "12px",
    },
    clickableReferenceCell: {
        cursor: "pointer",
    },
    clipboardInput: {
        left: "-9999px",
        position: "absolute",
        top: "-9999px",
    },
    jsonTestTextFieldContainer: {
        display: "flex",
        overflow: "auto",
    },
    line: {
        alignItems: "center",
        display: "flex",
        marginBottom: "16px",
    },
    lineTable: {
        display: "flex",
        marginBottom: "16px",
    },
    lineTextField: {
        flex: 1,
    },
    popoverMessage: {
        padding: "12px",
    },
    referenceSection: {
        flex: 1,
    },
    tableContainerLeft: {
        height: "440px",
        marginRight: "12px",
        overflow: "auto",
    },
    tableContainerRight: {
        height: "440px",
        overflow: "auto",
    },
    tableHeader: {
        backgroundColor: greyPalette[200],
    },
    tableTitle: {
        marginBottom: "12px",
    },
    iconSuccess: {
        color: rainbowPalette[600],
    },
    iconError: {
        color: rainbowPalette[1000],
    },
    iconSuccessError: {
        width: "25px",
        display: "flex",
        alignItems: "center",
        padding: "5px",
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    onChanged: (jsonProps: TParserJson) => void;
    jsonProps: TParserJson;
    userAccessLevel?: ProcessEditorRightsEnum;
}

export default function ParserJsonEditor(props: IOwnProps): JSX.Element {
    const { jsonProps, onChanged, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [clipboard, setClipboard] = useState<string>("");
    const [clipboardPopoverAnchor, setClipboardPopoverAnchor] = useState<HTMLElement | null>(null);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [jsonTestResult, setJsonTestResult] = useState<string>("");
    const [jsonTestResultStatus, setJsonTestResultStatus] = useState<boolean | undefined>(undefined);

    const clipboardRef = useRef<HTMLInputElement | null>(null);
    const popoverOpened = Boolean(clipboardPopoverAnchor);
    const popoverId = popoverOpened ? "simple-popover" : undefined;

    /**
     * Handles copy to clipboard
     */
    useEffect((): void => {
        if (clipboard !== "" && clipboardRef.current) {
            clipboardRef.current.select();
            clipboardRef.current.setSelectionRange(0, 99999);
            document.execCommand("copy");
        }
    }, [clipboard]);

    /**
     * Test the JSON string
     */
    const handleJsonTest = (): void => {
        try {
            const result = JSON.stringify(jsonpath.query(JSON.parse(jsonProps.testString), jsonProps.path));
            setJsonTestResult(result.slice(1, result.length - 1)); // Remove [ and ]
            setJsonTestResultStatus(true);
        } catch (error) {
            setJsonTestResult(error);
            setJsonTestResultStatus(false);
        }
    };

    /**
     * Handles textfield value changes
     *
     * @param {string} path - json path
     */
    const handlePathChange = (path: string): void => {
        onChanged({ ...jsonProps, path });
    };

    /**
     * Copy json reference text to clipboard
     *
     * @param {string} text - text
     */
    const handleReferenceTableClick =
        (text: string) =>
        (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>): void => {
            setClipboard(text);
            setClipboardPopoverAnchor(event.currentTarget);
            setTimeout((): void => {
                setClipboardPopoverAnchor(null);
            }, 750);
        };

    /**
     * Changes the selected tab
     */
    const handleTabChange = (event: React.ChangeEvent<{}>, index: number): void => {
        setSelectedTab(index);
    };

    /**
     * Handles textfield value changes
     *
     * @param {string} value - value
     */
    const handleTestStringChange = (value: string): void => {
        // Clean up test result if not done
        if (jsonTestResult !== "") {
            setJsonTestResult("");
            setJsonTestResultStatus(undefined);
        }

        onChanged({
            ...jsonProps,
            testString: value,
        });
    };

    return (
        <React.Fragment>
            <div className={classes.line}>
                {/* PATH */}
                <VariablePicker
                    KortexTextFieldProps={{
                        className: classes.lineTextField,
                        label: translate("action.parser.jsonPath"),
                        TextFieldProps: {
                            id: "parserJsonEditorJsonPathId",
                        },
                    }}
                    onChange={handlePathChange}
                    userAccessLevel={userAccessLevel}
                    value={jsonProps.path}
                />
                <Button
                    className={classes.button}
                    color="secondary"
                    id="parserJsonEditorJsonTestButtonId"
                    onClick={handleJsonTest}
                    variant="outlined"
                >
                    <Typography>{translate("action.parser.test")}</Typography>
                </Button>
            </div>
            <AppBar className={classes.appBar} position="static">
                <Tabs onChange={handleTabChange} value={selectedTab}>
                    <Tab key={0} label={translate("action.parser.jsonTest")} />
                    <Tab key={1} label={translate("action.parser.jsonReference")} />
                </Tabs>
            </AppBar>
            {/* JSON TEST TEXTFIELD */}
            {selectedTab === 0 && (
                <div>
                    <div className={classes.jsonTestTextFieldContainer}>
                        <KortexTextField
                            className={classes.lineTextField}
                            label={translate("action.parser.jsonTestResult")}
                            TextFieldProps={{
                                id: "parserJsonEditorJsonTestResultid",
                                multiline: true,
                            }}
                            InputProps={{
                                readOnly: true,
                            }}
                            value={jsonTestResult}
                        />
                        <div className={classes.iconSuccessError}>
                            {jsonTestResultStatus !== undefined && jsonTestResultStatus && (
                                <SuccessIcon className={classes.iconSuccess}></SuccessIcon>
                            )}
                            {jsonTestResultStatus !== undefined && !jsonTestResultStatus && (
                                <ErrorIcon className={classes.iconError}></ErrorIcon>
                            )}
                        </div>
                    </div>
                    <div className={classes.jsonTestTextFieldContainer}>
                        <KortexTextField
                            changedDelayMS={500}
                            className={classes.lineTextField}
                            label={translate("action.parser.jsonTestObject")}
                            TextFieldProps={{
                                id: "parserJsonEditorJsonTestStringId",
                                multiline: true,
                            }}
                            onChanged={handleTestStringChange}
                            value={jsonProps.testString}
                        />
                    </div>
                </div>
            )}
            {/* JSONPATH REFERENCE TABLES */}
            <div className={classes.lineTable}>
                {selectedTab === 1 && (
                    <React.Fragment>
                        {/* OPERATORS */}
                        <div className={classes.referenceSection}>
                            <Typography className={classes.tableTitle} variant="h4">
                                {translate("action.parser.jsonReferenceOperator")}
                            </Typography>
                            <div className={classes.tableContainerLeft}>
                                <Table>
                                    <TableHead className={classes.tableHeader}>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>{translate("action.parser.jsonReferenceOperator")}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{translate("action.parser.jsonReferenceDescription")}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {JSONPATH_OPERATORS.map((operator) => {
                                            const text = translate(`action.parser.jsonReferenceOperator${operator}`);
                                            return (
                                                <TableRow key={operator}>
                                                    <TableCell
                                                        className={classes.clickableReferenceCell}
                                                        onClick={handleReferenceTableClick(text)}
                                                    >
                                                        <Typography>{text}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>
                                                            {translate(`action.parser.jsonReferenceOperatorDescription${operator}`)}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                        {/* EXAMPLES */}
                        <div className={classes.referenceSection}>
                            <Typography className={classes.tableTitle} variant="h4">
                                {translate("action.parser.jsonReferenceExample")}
                            </Typography>
                            <div className={classes.tableContainerRight}>
                                <Table>
                                    <TableHead className={classes.tableHeader}>
                                        <TableRow>
                                            <TableCell>
                                                <Typography>{translate("action.parser.jsonReferenceExample")}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{translate("action.parser.jsonReferenceDescription")}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {JSONPATH_EXAMPLES.map((example) => {
                                            const text = translate(`action.parser.jsonReferencePath${example}`);
                                            return (
                                                <TableRow key={example}>
                                                    <TableCell
                                                        className={classes.clickableReferenceCell}
                                                        onClick={handleReferenceTableClick(text)}
                                                    >
                                                        <Typography>{text}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>
                                                            {translate(`action.parser.jsonReferencePathDescription${example}`)}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </div>
            <Popover
                id={popoverId}
                open={popoverOpened}
                anchorEl={clipboardPopoverAnchor}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Typography className={classes.popoverMessage}>{translate("action.parser.jsonReferenceClipboardMessage")}</Typography>
            </Popover>
            <input className={classes.clipboardInput} defaultValue={clipboard} id="clipboardInput" ref={clipboardRef} type="text" />
        </React.Fragment>
    );
}
