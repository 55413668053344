import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { CircularProgress, IconButton, TableCell, TableRow, Typography, makeStyles } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import React from "react";

import { useProcessEditorContext } from "../../../../context";

const useStyles = makeStyles({
    circularProgress: {
        height: "24px !important",
        margin: "12px",
        width: "24px !important",
    },
    tableCellContent: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
});

function WorkInstructionsBomReload(): JSX.Element {
    const classes = useStyles();
    const { bom, bomLoading, loadBom } = useProcessEditorContext();
    const translate = useTranslate();

    /**
     * Handles click on component
     */
    const handleReload = (): void => {
        if (!bom) return void 0;

        loadBom();
    };

    return (
        <TableRow>
            <TableCell colSpan={3}>
                <div className={classes.tableCellContent}>
                    {bomLoading ? (
                        <CircularProgress className={classes.circularProgress} />
                    ) : (
                        <>
                            <Typography color="error" variant="body1">
                                {translate("action.workInstructions.bom.bomLoadError")}
                            </Typography>
                            <Typography variant="caption">{`${bom?.bomId} (${translate(
                                "action.workInstructions.bom.revision"
                            )} ${bom?.revision})`}</Typography>
                            <IconButton onClick={handleReload}>
                                <RefreshIcon />
                            </IconButton>
                        </>
                    )}
                </div>
            </TableCell>
        </TableRow>
    );
}

export default WorkInstructionsBomReload;
