import { ServiceManagerClient, WSClient, Kinova } from "@kortex/aos-api-client";
import { getRPCHubInfo, UncategorizedGetResultSettingItemReq, UncategorizedGetResultSettingItemRes } from "@kortex/aos-common";

import { objectFromUTF8Array, utf8ArrayFromObject, utf8ArrayToKortexApiErrorObject } from "../helpers";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getResultSettingItem = (router: WSClient["router"]) =>
    ServiceManagerClient.getRR<UncategorizedGetResultSettingItemReq, UncategorizedGetResultSettingItemRes, Kinova.Api.IError>(
        getRPCHubInfo("uncategorized")("getResultSettingItem"),
        {
            router,
            serializer: {
                decode: objectFromUTF8Array,
                encode: utf8ArrayFromObject,
                err: {
                    decode: utf8ArrayToKortexApiErrorObject,
                },
            },
        }
    );
