export var ElectronicSignatureTypeEnum;
(function (ElectronicSignatureTypeEnum) {
    ElectronicSignatureTypeEnum["PROCESS_APPROVAL"] = "processApproval";
    ElectronicSignatureTypeEnum["PROCESS_RELEASE"] = "processRelease";
    ElectronicSignatureTypeEnum["PROCESS_RUN_APPROVAL"] = "processRunApproval";
    ElectronicSignatureTypeEnum["PROCESS_RUN_STEP_APPROVAL"] = "processRunStepApproval";
    ElectronicSignatureTypeEnum["PROCESS_TRAINING_COACH_REQUIRED"] = "processTrainingCoachRequired";
    ElectronicSignatureTypeEnum["PROCESS_TRAINING_COMPLETED"] = "processTrainingCompleted";
    ElectronicSignatureTypeEnum["READING_CERTIFICATE"] = "readingCertificate";
    ElectronicSignatureTypeEnum["TRAINING_CERTIFICATE"] = "trainingCertificate";
    ElectronicSignatureTypeEnum["TRAINING_CERTIFICATION"] = "trainingCertification";
})(ElectronicSignatureTypeEnum || (ElectronicSignatureTypeEnum = {}));
