export const runnerPlayer = {
    rpc: {
        play: {
            funcId: 30000,
            funcName: "runnerPlayerPlay",
        },
        stop: {
            funcId: 30001,
            funcName: "runnerPlayerStop",
        },
        pause: {
            funcId: 30002,
            funcName: "runnerPlayerPause",
        },
        resume: {
            funcId: 30003,
            funcName: "runnerPlayerResume",
        },
        retry: {
            funcId: 30004,
            funcName: "runnerPlayerRetry",
        },
        clientActionStateUpdate: {
            funcId: 30005,
            funcName: "runnerClientActionStateUpdate",
        },
        failStep: {
            funcId: 30006,
            funcName: "runnerPlayerFailStep",
        },
        getDashboardInfo: {
            funcId: 30007,
            funcName: "runnerGetDashboardInfo",
        },
        getHistory: {
            funcId: 30008,
            funcName: "runnerPlayerGetHistory",
        },
    },
    serviceId: 300,
    serviceVersion: 1,
};
