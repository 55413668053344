import { EntityStatusEnum } from "../../../enum/EntityStatusEnum";
export function createEntityModel() {
    return {
        // @ts-expect-error - entityUniqueId is automatically generated by the database. It needs to be null, else sequelize will throw an error.
        entityUniqueId: null,
        trackingId: "",
        trackingInstance: 0,
        partNumber: "",
        status: EntityStatusEnum.NEW,
        batchEnable: false,
        completed: false,
        passed: false,
        failed: false,
        inProgressResultMasterId: 0,
        completedProcessesId: "",
        startedOn: 0,
        completedOn: 0,
    };
}
