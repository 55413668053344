export const process = {
    rpc: {
        actionDelete: {
            funcId: 6001,
            funcName: "processActionDelete",
        },
        actionInsert: {
            funcId: 6002,
            funcName: "processActionInsert",
        },
        actionUpdate: {
            funcId: 6003,
            funcName: "processActionUpdate",
        },
        insert: {
            funcId: 6004,
            funcName: "processInsert",
        },
        inserted: {
            funcId: 6005,
            funcName: "processInserted",
        },
        one: {
            funcId: 6006,
            funcName: "processOne",
        },
        update: {
            funcId: 6007,
            funcName: "processUpdate",
        },
        updated: {
            funcId: 6008,
            funcName: "processUpdated",
        },
        variableDelete: {
            funcId: 6009,
            funcName: "processVariableDelete",
        },
        variableInsert: {
            funcId: 6010,
            funcName: "processVariableInsert",
        },
        variableUpdate: {
            funcId: 6011,
            funcName: "processVariableUpdate",
        },
        variableWhereUsed: {
            funcId: 6012,
            funcName: "processVariableWhereUsed",
        },
        versionAll: {
            funcId: 6013,
            funcName: "processVersionAll",
        },
        versionApprove: {
            funcId: 6014,
            funcName: "processVersionApprove",
        },
        versionArchive: {
            funcId: 6015,
            funcName: "processVersionArchive",
        },
        versionDraft: {
            funcId: 6016,
            funcName: "processVersionDraft",
        },
        versionInsert: {
            funcId: 6017,
            funcName: "processVersionInsert",
        },
        versionUpdateReleaseDates: {
            funcId: 6018,
            funcName: "processVersionUpdateReleaseDates",
        },
        actionUpdated: {
            funcId: 6019,
            funcName: "processActionUpdated",
        },
        actionInserted: {
            funcId: 6020,
            funcName: "processActionInserted",
        },
        actionDeleted: {
            funcId: 6021,
            funcName: "processActionDeleted",
        },
        actionInsertCopy: {
            funcId: 6022,
            funcName: "processActionInsertCopy",
        },
        whereUsed: {
            funcId: 6023,
            funcName: "processWhereUsed",
        },
        whereUsedAllVersions: {
            funcId: 6024,
            funcName: "processWhereUsedAllVersions",
        },
        versionRecover: {
            funcId: 6025,
            funcName: "processVersionRecover",
        },
        getLatestVersion: {
            funcId: 6026,
            funcName: "processGetLatestVersion",
        },
        versionAllParent: {
            funcId: 6027,
            funcName: "processVersionAllParent",
        },
        versionCancel: {
            funcId: 6028,
            funcName: "processVersionCancel",
        },
        versionHold: {
            funcId: 6029,
            funcName: "processVersionHold",
        },
        versionTrainingUpdate: {
            funcId: 6030,
            funcName: "processVersionTrainingUpdate",
        },
        standardTimeUpdate: {
            funcId: 6031,
            funcName: "processStandardTimeUpdate",
        },
        stepDelete: {
            funcId: 6032,
            funcName: "processActionStepDelete",
        },
        stepInsert: {
            funcId: 6033,
            funcName: "processActionStepInsert",
        },
        stepUpdate: {
            funcId: 6034,
            funcName: "processActionStepUpdate",
        },
        stepInsertCopy: {
            funcId: 6035,
            funcName: "processActionStepInsertCopy",
        },
        stepReorder: {
            funcId: 6036,
            funcName: "processActionStepReorder",
        },
        getAllDraftsFromParentTreeNode: {
            funcId: 6037,
            funcName: "processGetAllDraftsFromParentTreeNode",
        },
        updateJobProcessNewProcessVersion: {
            funcId: 6038,
            funcName: "processUpdateJobProcessNewProcessVersion",
        },
    },
    serviceId: 60,
    serviceVersion: 1,
};
