export var ActionTypeEnum;
(function (ActionTypeEnum) {
    ActionTypeEnum["CONDITION"] = "core-condition";
    ActionTypeEnum["FAIL"] = "core-fail";
    ActionTypeEnum["GO_TO"] = "core-go-to";
    ActionTypeEnum["INPUT"] = "core-input";
    ActionTypeEnum["LOOP"] = "core-loop";
    ActionTypeEnum["MESSAGE"] = "core-message";
    ActionTypeEnum["REMOTE"] = "core-remote";
    ActionTypeEnum["SET"] = "core-set";
    ActionTypeEnum["SUB_PROGRAM"] = "core-sub-program";
    ActionTypeEnum["THREAD"] = "core-thread";
    ActionTypeEnum["TIMER"] = "core-timer";
    ActionTypeEnum["WAIT_CONDITION"] = "core-wait-condition";
    ActionTypeEnum["WAIT_TIME"] = "core-wait-time";
})(ActionTypeEnum || (ActionTypeEnum = {}));
