import { VariableTypeEnum } from "@aos/variable-manager";
import { ActionTypeEnum, ConditionOperandTypeEnum, ConditionOperatorLogicalEnum, FailureCodeEnum, FailureSourceEnum, SequencerActionPlayCompletedPayloadTypeEnum, SequencerActionStatusEnum, } from "../../enums";
import { CoreActionConfigError, } from "../../interfaces";
import { localeKeys } from "../../locales";
import { KortexSequencerDebugger, deepClone, defer, isNumber, isPromiseStarted, unwrapVariableIdentifier, } from "../../utilities";
import { isConditionAdvanced, isConditionBasic } from "../../utilities/condition-type-predicates";
import { interpolateActionConfig } from "../../utilities/interpolate-action-config";
import { createConditionString, resolveCondition } from "../condition";
const debug = KortexSequencerDebugger.core.loop;
export const SEQUENCER_ACTION_LOOP_DEFAULT_STATE = {
    counter: 0,
};
const DEFAULT_CONDITION_STRING = "";
const DEFAULT_INCREMENT = 1;
function create({ variableManager, variableNamespace }) {
    let _conditionString = DEFAULT_CONDITION_STRING, _interpolatedConfig, _play, _state = SEQUENCER_ACTION_LOOP_DEFAULT_STATE, _stateChanged = async () => void 0;
    function _fail(failure) {
        return _play?.resolve({
            payload: failure,
            status: SequencerActionStatusEnum.FAILED,
        });
    }
    function _isConditionDefined() {
        return _conditionString !== DEFAULT_CONDITION_STRING;
    }
    function _isFirstIteration() {
        return _state.counter === 0;
    }
    function _isIncrementerDefined(incrementer) {
        return Boolean(incrementer?.variableIdentifier?.trim());
    }
    function _resetState() {
        _state = SEQUENCER_ACTION_LOOP_DEFAULT_STATE;
    }
    async function _testLoopCondition(conditionString, resolve) {
        let invalidCondition = false;
        const result = await resolveCondition(conditionString).catch((error) => {
            invalidCondition = true;
            resolve({
                payload: {
                    code: FailureCodeEnum.CONFIGURATION_ERROR,
                    action: {
                        state: _state,
                    },
                    error,
                    key: error.key,
                    source: FailureSourceEnum.ACTION,
                },
                status: SequencerActionStatusEnum.FAILED,
            });
            return false;
        });
        return { result, invalidCondition };
    }
    function assertValidConfig(config) {
        debug("config validation:%o", config);
        if (!config.conditions) {
            debug("config validation:error:conditions is undefined");
            throw new CoreActionConfigError(localeKeys.action.core.loop.validateUndefinedConditions, "Config validation error: 'conditions' is undefined.");
        }
        for (const [index, condition] of config.conditions.entries()) {
            if (isConditionBasic(condition)) {
                if (condition.left?.type === ConditionOperandTypeEnum.VARIABLE && !condition.left?.value?.trim()) {
                    debug("config validation:error:condition #%d:left operand:variable identifier is undefined", index);
                    throw new CoreActionConfigError(localeKeys.action.core.loop.validateUndefinedLeftOperandVariableIdentifier, `Config validation error: condition #${index}: left operand variable identifier is undefined.`);
                }
                if (condition.right?.type === ConditionOperandTypeEnum.VARIABLE && !condition.right?.value?.trim()) {
                    debug("config validation:error:condition #%d:right operand:variable identifier is undefined", index);
                    throw new CoreActionConfigError(localeKeys.action.core.loop.validateUndefinedRightOperandVariableIdentifier, `Config validation error: condition #${index}: right operand variable identifier is undefined.`);
                }
                if (index === 0 && condition.logical !== ConditionOperatorLogicalEnum.NA) {
                    debug("config validation:error:condition #%d:logical operator should be NA", index);
                    throw new CoreActionConfigError(localeKeys.action.core.loop.validateInvalidLogicalOperator, `Config validation error: condition #${index}:logical operator should be NA.`);
                }
                if (index !== 0 &&
                    condition.logical !== ConditionOperatorLogicalEnum.AND &&
                    condition.logical !== ConditionOperatorLogicalEnum.OR) {
                    debug("config validation:error:condition #%d:logical operator should be AND or OR", index);
                    throw new CoreActionConfigError(localeKeys.action.core.loop.validateInvalidLogicalOperator, `Config validation error: condition #${index}: logical operator should be AND or OR.`);
                }
            }
        }
        if (_isIncrementerDefined(config.incrementer)) {
            if (config.incrementer?.increment && typeof config.incrementer.increment !== "number") {
                debug("config validation:error:incrementer:increment type is not number or equal to 0");
                throw new CoreActionConfigError(localeKeys.action.core.loop.validateInvalidIncrementerIncrement, "Config validation error: increment type is not number.");
            }
            if (config.incrementer?.initialValue !== undefined && typeof config.incrementer.initialValue !== "number") {
                debug("config validation:error:incrementer:initial value type is not number");
                throw new CoreActionConfigError(localeKeys.action.core.loop.validateInvalidIncrementerInitialValue, "Config validation error incrementer's initial value type is not number.");
            }
        }
        debug("config validation:successful");
    }
    async function onStateChange(cb) {
        debug("on state change:callback saved");
        _stateChanged = cb;
    }
    async function play(config) {
        debug("play:%o", config);
        if (isPromiseStarted(_play)) {
            debug("play:fail:action already in progress");
            return {
                payload: {
                    action: {
                        state: _state,
                    },
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.action.core.loop.playAlreadyInProgress,
                    source: FailureSourceEnum.ACTION,
                },
                status: SequencerActionStatusEnum.FAILED,
            };
        }
        _play = defer(async (resolve) => {
            debug("play:iteration #%s", _state.counter);
            _conditionString = DEFAULT_CONDITION_STRING;
            const { conditions, incrementer, ...configToInterpolateImmediately } = config;
            _interpolatedConfig = interpolateActionConfig(configToInterpolateImmediately, variableManager.getAllVars(variableNamespace));
            const interpolatedIncrementer = incrementer
                ? interpolateActionConfig({ ...incrementer, variableIdentifier: unwrapVariableIdentifier(incrementer.variableIdentifier) }, variableManager.getAllVars(variableNamespace))
                : undefined;
            if (_interpolatedConfig.maxIterationCount > 0 && _state.counter >= _interpolatedConfig.maxIterationCount) {
                debug("play:iteration #%s:completed:maximum number of iteration reached (%d)", _state.counter, _interpolatedConfig.maxIterationCount);
                _resetState();
                return resolve({
                    payload: {
                        output: null,
                        type: SequencerActionPlayCompletedPayloadTypeEnum.NEXT,
                    },
                    status: SequencerActionStatusEnum.COMPLETED,
                });
            }
            if (_isIncrementerDefined(interpolatedIncrementer)) {
                if (_isFirstIteration()) {
                    debug("play:iteration #%s:set incrementer's initial value to variable:%o", _state.counter, interpolatedIncrementer);
                    if (interpolatedIncrementer.initialValue !== undefined) {
                        await variableManager.setVar(variableNamespace, {
                            identifier: interpolatedIncrementer.variableIdentifier,
                            schemaKey: "",
                            type: VariableTypeEnum.NUMBER,
                            value: interpolatedIncrementer.initialValue,
                        });
                    }
                    else if (!(await variableManager.getVar(variableNamespace, interpolatedIncrementer.variableIdentifier)?.value)) {
                        await variableManager.setVar(variableNamespace, {
                            identifier: interpolatedIncrementer.variableIdentifier,
                            schemaKey: "",
                            type: VariableTypeEnum.NUMBER,
                            value: 0,
                        });
                    }
                }
                else {
                    debug("play:iteration #%s:increment incrementer", _state.counter);
                    const valueToIncrement = await variableManager.getVar(variableNamespace, interpolatedIncrementer.variableIdentifier)
                        ?.value;
                    if (!isNumber(valueToIncrement)) {
                        debug("play:iteration #%s:increment incrementer:fail:increment value (%s) is not a number", _state.counter, valueToIncrement);
                        return resolve({
                            payload: {
                                code: FailureCodeEnum.CONFIGURATION_ERROR,
                                action: {
                                    state: _state,
                                },
                                key: localeKeys.action.core.loop.playIncremetedVariableNotNumber,
                                source: FailureSourceEnum.ACTION,
                            },
                            status: SequencerActionStatusEnum.FAILED,
                        });
                    }
                    const newValue = valueToIncrement + (interpolatedIncrementer?.increment ?? DEFAULT_INCREMENT);
                    await variableManager.setVar(variableNamespace, {
                        identifier: interpolatedIncrementer.variableIdentifier,
                        schemaKey: "",
                        type: VariableTypeEnum.NUMBER,
                        value: newValue,
                    });
                    debug("play:iteration #%s:increment incrementer:variable %s set to %d", _state.counter, interpolatedIncrementer.variableIdentifier, newValue);
                }
            }
            let conditionResult = false;
            for (const condition of interpolateActionConfig({ conditions }, variableManager.getAllVars(variableNamespace)).conditions) {
                if (isConditionAdvanced(condition)) {
                    _conditionString += condition;
                }
                else {
                    try {
                        _conditionString += createConditionString(condition, variableManager.getAllVars(variableNamespace));
                    }
                    catch (error) {
                        return resolve({
                            payload: {
                                action: {
                                    state: _state,
                                },
                                code: FailureCodeEnum.CONFIGURATION_ERROR,
                                error,
                                key: error.key,
                                source: FailureSourceEnum.ACTION,
                            },
                            status: SequencerActionStatusEnum.FAILED,
                        });
                    }
                }
            }
            if (_isFirstIteration()) {
                debug("play:iteration #%s:full condition:%s", _state.counter, _conditionString);
                if (_isConditionDefined()) {
                    if (_interpolatedConfig.doAtLeastOnce) {
                        setState({ counter: _state.counter + 1 });
                        debug("play:iteration #%s:completed:do at least once:continue loop", _state.counter);
                        return resolve({
                            payload: {
                                output: 0,
                                type: SequencerActionPlayCompletedPayloadTypeEnum.BRANCH_INDEX,
                            },
                            status: SequencerActionStatusEnum.COMPLETED,
                        });
                    }
                }
                debug("play:iteration #%s:solve condition (condition is defined and not 'do-while'", _state.counter);
                const resolvePayload = await _testLoopCondition(_conditionString, resolve);
                if (resolvePayload.invalidCondition) {
                    return void 0;
                }
                debug("play:iteration #%s:solve condition:condition result:%s", _state.counter, resolvePayload.result);
                conditionResult = resolvePayload.result;
            }
            else if (_isConditionDefined()) {
                const resolvePayload = await _testLoopCondition(_conditionString, resolve);
                if (resolvePayload.invalidCondition) {
                    return void 0;
                }
                debug("play:iteration #%s:solve condition:condition result:%s", _state.counter, resolvePayload.result);
                conditionResult = resolvePayload.result;
            }
            setState({ counter: _state.counter + 1 });
            if (_isConditionDefined()) {
                if (conditionResult) {
                    debug("play:iteration #%s:completed:condition statisfied:continue loop", _state.counter);
                    return resolve({
                        payload: {
                            output: 0,
                            type: SequencerActionPlayCompletedPayloadTypeEnum.BRANCH_INDEX,
                        },
                        status: SequencerActionStatusEnum.COMPLETED,
                    });
                }
                debug("play:iteration #%s:completed:condition is not statisfied:break loop", _state.counter);
                _resetState();
                return resolve({
                    payload: {
                        output: null,
                        type: SequencerActionPlayCompletedPayloadTypeEnum.NEXT,
                    },
                    status: SequencerActionStatusEnum.COMPLETED,
                });
            }
            debug("play:iteration #%s:completed:no condition:continue loop", _state.counter);
            return resolve({
                payload: {
                    output: 0,
                    type: SequencerActionPlayCompletedPayloadTypeEnum.BRANCH_INDEX,
                },
                status: SequencerActionStatusEnum.COMPLETED,
            });
        });
        return _play.promise;
    }
    async function setState(state, feedback, triggerOnStateChange = true) {
        debug("set state:%o", state);
        const previousState = deepClone(_state);
        const notifyChange = triggerOnStateChange && state.counter !== undefined && state.counter !== _state.counter;
        _state = {
            ..._state,
            ...state,
        };
        if (notifyChange) {
            debug("set state:state is changed, trigger state changed callback");
            _stateChanged({
                feedback,
                previousState,
                state: _state,
            });
        }
        return {
            status: SequencerActionStatusEnum.CONTINUE,
            payload: _state,
        };
    }
    async function stop(causeFailure) {
        debug("stop");
        if (causeFailure) {
            return _fail({
                code: FailureCodeEnum.STOP,
                action: {
                    state: _state,
                },
                key: localeKeys.action.core.loop.stop,
                source: FailureSourceEnum.ACTION,
            });
        }
        return _play?.resolve({
            payload: null,
            status: SequencerActionStatusEnum.STOPPED,
        });
    }
    return {
        get state() {
            return deepClone(_state);
        },
        get type() {
            return ActionTypeEnum.LOOP;
        },
        assertValidConfig,
        onStateChange,
        play,
        setState,
        stop,
    };
}
export const SequencerActionLoopFactory = {
    create,
};
