import { isOutput, isStopProcess } from "./types";
// -------------------------------------------------
// PROCESS ACTION OUTPUT LINK UTILITIES
// -------------------------------------------------
/**
 * Check if action has at least 1 output
 *
 * @param {ProcessAction} processAction - process action
 */
export function hasOutput(processAction) {
    return !(isOutput(processAction) || isStopProcess(processAction));
}
