import {
    ClearUserSessionAction,
    UserGroupInsertedAction,
    UserGroupUpdatedAction,
    UserRoleInsertedAction,
    UserRoleUpdatedAction,
    SetUserGroupListAction,
    SetUserRoleListAction,
    SetUserListAction,
    SetUserSessionAction,
    UserActionType,
    UserInsertedAction,
    UserUpdatedAction,
    UserRoleDeletedAction,
} from "./users-types";

/**
 * Returns an action
 */
export function clearUserSessionAction(): ClearUserSessionAction {
    return { type: UserActionType.CLEAR_USER_SESSION };
}

/**
 * Returns an action
 *
 * @param {UserGroupInsertedAction} group - value of the action
 */
export function userGroupInsertedAction(group: UserGroupInsertedAction["value"]): UserGroupInsertedAction {
    return { type: UserActionType.INSERT_USER_GROUP, value: group };
}

/**
 * Returns an action
 *
 * @param {UserGroupUpdatedAction} group - value of the action
 */
export function userGroupUpdatedAction(group: UserGroupUpdatedAction["value"]): UserGroupUpdatedAction {
    return { type: UserActionType.UPDATE_USER_GROUP, value: group };
}

/**
 * Returns an action
 *
 * @param {UserRoleInsertedAction} role - value of the action
 */
export function userRoleInsertedAction(role: UserRoleInsertedAction["value"]): UserRoleInsertedAction {
    return { type: UserActionType.INSERT_USER_ROLE, value: role };
}

/**
 * Returns an action
 *
 * @param {UserRoleUpdatedAction} role - value of the action
 */
export function userRoleUpdatedAction(role: UserRoleUpdatedAction["value"]): UserRoleUpdatedAction {
    return { type: UserActionType.UPDATE_USER_ROLE, value: role };
}

/**
 * Returns an action
 *
 * @param {UserRoleUpdatedAction} role - value of the action
 */
export function userRoleDeletedAction(role: UserRoleDeletedAction["value"]): UserRoleDeletedAction {
    return { type: UserActionType.DELETE_USER_ROLE, value: role };
}

/**
 * Returns an action
 *
 * @param {SetUserListAction} groups - value of the action
 */
export function setGroupListAction(groups: SetUserGroupListAction["value"]): SetUserGroupListAction {
    return { type: UserActionType.SET_USER_GROUP_LIST, value: groups };
}

/**
 * Returns an action
 *
 * @param {SetUserListAction} roles - value of the action
 */
export function setRoleListAction(roles: SetUserRoleListAction["value"]): SetUserRoleListAction {
    return { type: UserActionType.SET_USER_ROLE_LIST, value: roles };
}

/**
 * Returns an action
 *
 * @param {SetUserListAction} users - value of the action
 */
export function setUserListAction(users: SetUserListAction["value"]): SetUserListAction {
    return { type: UserActionType.SET_USER_LIST, value: users };
}

/**
 * Returns an action
 *
 * @param {SetUserSessionAction} session - value of the action
 */
export function setUserSessionAction(session: SetUserSessionAction["value"]): SetUserSessionAction {
    return { type: UserActionType.SET_USER_SESSION, value: session };
}

/**
 * Returns an action
 *
 * @param {UserInsertedAction} user - value of the action
 */
export function userInsertedAction(user: UserInsertedAction["value"]): UserInsertedAction {
    return { type: UserActionType.INSERT_USER, value: user };
}

/**
 * Returns an action
 *
 * @param {UserUpdatedAction} user - value of the action
 */
export function userUpdatedAction(user: UserUpdatedAction["value"]): UserUpdatedAction {
    return { type: UserActionType.UPDATE_USER, value: user };
}
