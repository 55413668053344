import { KortexDialogConfirmation, KortexOutlinedTextField, theme } from "@aos/react-components";
import {
    IRoutingProcessConfig,
    ITreeNodeDbModel,
    ProcessActionStepRoutingProcess,
    ProcessApprovalStatusFilter,
    ProcessEditorRightsEnum,
    TreeNodeNodeTypeEnum,
} from "@kortex/aos-common";
import { Checkbox, FormControlLabel, IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import { useEntitiesTreeProcess } from "../../../../../../redux/effects";
import { useSelectorProcesses, useSelectorUserSession } from "../../../../../../redux/selectors";
import { IUserRightsProps, userCanWrite } from "../../../../../../utilitites/IUserRights";
import ProcessVersionPicker from "../../../../../core/ProcessVersionPicker/ProcessVersionPicker";
import ProcessRepository from "../../../RepositoryManager/ProcessRepository";
import { IActionStepProps } from "../IActionStepProps";

const useStyles = makeStyles({
    dialogContent: {
        height: "60vh",
        maxWidth: "1102px",
        width: "80vw",
    },
    formControl: {
        height: "56px",
        whiteSpace: "nowrap",
        width: "300px",
    },
    line: {
        alignItems: "center",
        display: "inline-flex",
        marginBottom: "10px",
        width: "100%",
    },
    root: {
        height: "100%",
        padding: "10px",
    },
    textField: {
        backgroundColor: theme.palette.common.white,
        cursor: "pointer",
        marginRight: "10px",
        minWidth: "300px",
    },
});

type IAllProps = IActionStepProps<ProcessActionStepRoutingProcess> & IUserRightsProps<ProcessEditorRightsEnum>;

export default function RoutingProcessEditor(props: IAllProps): JSX.Element {
    const { actionStepProps, onChanged, userAccessLevel } = props;

    const classes = useStyles();
    const processes = useSelectorProcesses();
    const translate = useTranslate();
    const treeNodes = useEntitiesTreeProcess();
    const userInfo = useSelectorUserSession();

    const [processRepositoryDialogOpen, setProcessRepositoryDialogOpen] = useState<boolean>(false);
    const [latest, setLatest] = useState<IRoutingProcessConfig["latest"]>(Boolean(actionStepProps.config.latest));
    const [selectedProcessId, setSelectedProcessId] = useState<IRoutingProcessConfig["processId"]>(null);
    const [selectedTreeNodeId, setSelectedTreeNodeId] = useState<IRoutingProcessConfig["treeNodeId"]>(null);

    const selectedProcessLabel = treeNodes.find((node) => node.treeNodeId === actionStepProps.config.treeNodeId)?.label;
    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Closes the process repository dialog when the Cancel button is clicked
     */
    const handleProcessRepositoryDialogCancel = (): void => {
        setProcessRepositoryDialogOpen(false);
    };

    /**
     * Selects the process when the Confirm button is clicked and closes the process repository dialog
     */
    const handleProcessRepositoryDialogConfirm = async (): Promise<void> => {
        if (!selectedTreeNodeId) return;

        const processId: IRoutingProcessConfig["processId"] = selectedProcessId
            ? selectedProcessId
            : processes.find((process) => process.treeNodeId === selectedTreeNodeId)?.processId ?? null;

        if (processId !== actionStepProps.config.processId || selectedTreeNodeId !== actionStepProps.config.treeNodeId) {
            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    latest,
                    processId: latest ? null : processId,
                    treeNodeId: selectedTreeNodeId,
                },
            });
        }

        setProcessRepositoryDialogOpen(false);
    };

    /**
     * Opens or closes the process respository dialog
     *
     * @param {boolean} open - opened or closed
     */
    const handleProcessRepositoryDialogOpen =
        (open: boolean): (() => void) =>
        (): void => {
            if (readOnly) {
                return;
            }

            setProcessRepositoryDialogOpen(open);
        };

    /**
     * Selects the process
     *
     * @param {ITreeNodeDbModel[]} treeNodes - the process list
     */
    const handleSelectProcess = (
        treeNodes: ITreeNodeDbModel[],
        processIds: (number | undefined)[],
        latest: (boolean | undefined)[]
    ): void => {
        if (treeNodes.length) {
            setLatest(Boolean(latest[0]));
            setSelectedProcessId(processIds[0] ?? null);
            setSelectedTreeNodeId(treeNodes[0].treeNodeId);
        } else {
            setLatest(false);
            setSelectedProcessId(null);
            setSelectedTreeNodeId(null);
        }
    };

    /**
     * Selects the process version
     */
    const handleVersionChange = (processId: number, isLatestSelected: boolean): void => {
        // Do nothing if...
        if (
            readOnly ||
            (((!processId && !actionStepProps.config.processId) || // Both process IDs are equal to 0, null or undefined
                processId === actionStepProps.config.processId) && // Both process IDs are equal
                isLatestSelected === actionStepProps.config.latest) // Both "latest" are the same
        ) {
            return void 0;
        }

        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                latest: isLatestSelected,
                processId: isLatestSelected ? null : processId,
            },
        });
    };

    /**
     * Updates "checkPrevious" property of selected process
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - event containing the changes
     */
    const handleCheckPreviousChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                checkPrevious: checked,
            },
        });
    };

    /**
     * Updates inner states on props change
     */
    useEffect((): void => {
        setLatest(Boolean(actionStepProps.config.latest));
        setSelectedProcessId(actionStepProps.config.processId);
        setSelectedTreeNodeId(actionStepProps.config.treeNodeId);
    }, [actionStepProps.config.latest, actionStepProps.config.processId, actionStepProps.config.treeNodeId]);

    return (
        <div className={classes.root}>
            {/* PROCESS SELECTION */}
            <div className={classes.line}>
                <KortexOutlinedTextField
                    className={classes.textField}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton disabled={readOnly} disableRipple={true} onClick={handleProcessRepositoryDialogOpen(true)}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                        readOnly,
                    }}
                    label={translate("action.routingProcess.process")}
                    onClick={handleProcessRepositoryDialogOpen(true)}
                    placeholder={translate("action.routingProcess.selectProcess")}
                    value={selectedProcessLabel}
                />

                {actionStepProps.config.treeNodeId && (
                    <ProcessVersionPicker
                        latest={actionStepProps.config.latest}
                        supportLatest={true}
                        onVersionChange={handleVersionChange}
                        processId={actionStepProps.config.processId ?? undefined}
                        readOnly={readOnly}
                        treeNodeId={actionStepProps.config.treeNodeId}
                        variant={ProcessApprovalStatusFilter.APPROVED}
                    />
                )}
            </div>

            {/* CHECK PREVIOUS */}
            {Boolean(actionStepProps.config.treeNodeId) && (
                <div className={classes.line}>
                    <FormControlLabel
                        className={classes.formControl}
                        control={<Checkbox checked={actionStepProps.config.checkPrevious} onChange={handleCheckPreviousChange} />}
                        disabled={readOnly}
                        label={translate("action.routingProcess.checkPrevious")}
                    />
                </div>
            )}

            {/* PROCESS REPOSITORY */}
            <KortexDialogConfirmation
                closeOnEscape={true}
                dialogProps={{
                    fullWidth: false,
                    maxWidth: false,
                    onBackdropClick: handleProcessRepositoryDialogOpen(false),
                }}
                onCancel={handleProcessRepositoryDialogCancel}
                onConfirm={handleProcessRepositoryDialogConfirm}
                open={processRepositoryDialogOpen}
                textLabels={{
                    cancelButtonLabel: translate("repoBreadCrumbs.cancel"),
                    proceedButtonLabel: translate("repoBreadCrumbs.select"),
                    titleLabel: translate("repoBreadCrumbs.titleProcess"),
                }}
                confirmDisabled={!Boolean(selectedTreeNodeId)}
            >
                <div className={classes.dialogContent}>
                    <ProcessRepository
                        filterType={TreeNodeNodeTypeEnum.PROCESS}
                        onSelectionChanged={handleSelectProcess}
                        showLatestVersion={true}
                        showVersionVariant={ProcessApprovalStatusFilter.APPROVED}
                        userAccessLevel={userInfo && (userInfo.roleRights.repositoryEditor as number)}
                        preselectedTreeNodeId={selectedTreeNodeId ?? undefined}
                        handleSelectProcessConfirmation={handleProcessRepositoryDialogConfirm}
                    />
                </div>
            </KortexDialogConfirmation>
        </div>
    );
}
