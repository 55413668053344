import { IProcessUiModel, ProcessActionId } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { IPlayerState } from "@kortex/aos-ui/redux/player-manager/player-types";
import { useSelectorPlayer } from "@kortex/aos-ui/redux/selectors";
import { AppBar, CircularProgress, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

import { usePlayerContext } from "../../context";

import { PlayerControlsBom } from "./bom";
import { PlayerControlsTimeline } from "./timeline";

const useStyles = makeStyles({
    loading: { display: "flex", alignItems: "center", justifyContent: "center" },
    circularProgress: { margin: "15px" },
});

enum PlayerControlsTab {
    BOM,
    TIMELINE,
}

// FIXME: Rework this interface. This component should be independent of parent.
// Child components should be self-sufficient or use a context
interface IOwnProps {
    timelineProps: {
        onActionStepChanged: () => void;
        onRewindTo: (processActionId: ProcessActionId, stepIndex: number) => void;
        playerState: IPlayerState; // the actual player state
        playingHistoryIndex: number;
        processWithActions: IProcessUiModel | null;
        playingHistory?: boolean; // are we playing a history snaphot
    };
}

const PlayerControlsContent: FC<IOwnProps> = (props) => {
    const { timelineProps } = props;

    const translate = useTranslate();
    const classes = useStyles();
    const { bomStepItems, loadingBomFollowUp, woBom } = usePlayerContext();
    const { processState, uiActionProps } = useSelectorPlayer();
    const [selectedTab, setSelectedTab] = useState(processState.bom.enabled && Boolean(processState.serialNumber) ? 0 : 1);

    /**
     * Automatically change selected tab based on player state
     */
    useEffect(() => {
        if (
            !processState.isCompleted &&
            !processState.isStopped &&
            processState.bom.enabled &&
            Boolean(processState.serialNumber) &&
            (Object.keys(bomStepItems).length || !woBom)
        ) {
            setSelectedTab(PlayerControlsTab.BOM);
        } else {
            setSelectedTab(PlayerControlsTab.TIMELINE);
        }
    }, [
        bomStepItems,
        processState.bom.enabled,
        processState.isCompleted,
        processState.serialNumber,
        uiActionProps?.stepProps.processActionStepId,
        woBom,
    ]);

    const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number): void => {
        setSelectedTab(newValue);
    };

    return (
        <>
            <AppBar position="static">
                <Tabs onChange={handleTabChange} value={selectedTab}>
                    <Tab key={PlayerControlsTab.BOM} label={translate("player.bom.bom")} />
                    <Tab key={PlayerControlsTab.TIMELINE} label={translate("player.bom.step")} />
                </Tabs>
            </AppBar>
            {selectedTab === PlayerControlsTab.BOM &&
                (loadingBomFollowUp ? (
                    <div className={classes.loading}>
                        <CircularProgress className={classes.circularProgress} color="inherit" />
                        <Typography variant={"subtitle1"}>{translate("player.bom.loadingBomFollowUp")}</Typography>
                    </div>
                ) : (
                    <PlayerControlsBom />
                ))}
            {selectedTab === PlayerControlsTab.TIMELINE && <PlayerControlsTimeline {...timelineProps} />}
        </>
    );
};

export default PlayerControlsContent;
