import { EnumTemplateIcon } from "@kortex/aos-common";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

import BlankIcon from "../../../../../../../core/Icons/Blank/Blank";
import TemplateFullIcon from "../../../../../../../core/Icons/TemplateFull/TemplateFull";
import TemplateSidebarAndTitleIcon from "../../../../../../../core/Icons/TemplateSidebarAndTitle/TemplateSidebarAndTitle";

export interface IOwnProps {
    icon: EnumTemplateIcon;
}

export default function WorkInstructionsTemplateIcon(props: SvgIconProps & IOwnProps): JSX.Element {
    const { icon, ...noIconProps } = props;
    switch (icon) {
        case EnumTemplateIcon.BLANK:
            return <BlankIcon {...noIconProps} />;
        case EnumTemplateIcon.SIDE_BAR_AND_TITLE:
            return <TemplateSidebarAndTitleIcon {...noIconProps} />;
        case EnumTemplateIcon.FULL:
            return <TemplateFullIcon {...noIconProps} />;
    }
    return <BlankIcon {...noIconProps} />;
}
