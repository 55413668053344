import { KortexTextField } from "@aos/react-components";
import { MessageLevelEnum, ProcessActionStepMessage, ProcessVariableStorer, ProcessVariableStoringMethod } from "@kortex/aos-common";
import { MenuItem, Paper, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import VariablePicker from "../../../../../core/VariablePicker/VariablePicker";
import { IActionStepProps } from "../IActionStepProps";

const useStyles = makeStyles({
    root: {
        height: "100%",
        padding: "16px",
        boxSizing: "border-box",
    },
    formSelect: {
        marginBottom: "20px",
        marginLeft: "5px",
        marginRight: "20px",
        width: "240px",
    },
    message: {
        marginBottom: "20px",
        marginLeft: "5px",
        marginRight: "20px",
        width: "100%",
    },
});

export default function ActMessageStepEditor(props: IActionStepProps<ProcessActionStepMessage>): JSX.Element {
    const { actionStepProps, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [storeTo, setStoreTo] = useState<ProcessVariableStorer>(actionStepProps.config.storeTo);
    const [message, setMessage] = useState<string>(actionStepProps.config.message);
    const [level, setLevel] = useState<MessageLevelEnum>(actionStepProps.config.level);
    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Updates "Equation" inner state on props change
     */
    useEffect((): void => {
        setMessage(actionStepProps.config.message);
    }, [actionStepProps.config.message]);

    /**
     * Updates "Equation" inner state on props change
     */
    useEffect((): void => {
        setLevel(actionStepProps.config.level);
    }, [actionStepProps.config.level]);

    /**
     * Updates "StoreTo" inner state on props change
     */
    useEffect((): void => {
        setStoreTo(actionStepProps.config.storeTo);
    }, [actionStepProps.config.storeTo]);

    /**
     * Called when message textfield is blurred
     *
     * @param {string} updatedMesage - textfield value
     */
    const handleMesageBlur = (updatedMesage: string): void => {
        if (updatedMesage !== actionStepProps.config.message) {
            props.onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    message: updatedMesage,
                },
            });
        }
    };

    /**
     * Called when message level changed
     *
     * @param {MessageLevelEnum} level - textfield value
     */
    const handleLevelChange = (level: MessageLevelEnum): void => {
        if (level !== actionStepProps.config.level) {
            props.onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    level: level,
                },
            });
        }
    };

    /**
     * Called when storeTo textfield is changed
     *
     * @param {string} identifier - textfield value
     * @param {string} storingMethod - overwrite or append
     */
    const handleStoreToChange = (identifier: string, storingMethod: ProcessVariableStoringMethod): void => {
        if (storingMethod !== storeTo.storingMethod || identifier !== storeTo.identifier) {
            props.onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    storeTo: new ProcessVariableStorer(identifier, storingMethod),
                },
            });
        }
    };

    return (
        <Paper className={classes.root}>
            <VariablePicker
                onBlur={handleMesageBlur}
                KortexTextFieldProps={{ className: classes.message, label: translate("action.message.message") }}
                userAccessLevel={userAccessLevel}
                value={message}
            />
            <KortexTextField
                className={classes.formSelect}
                label={translate("action.message.level")}
                onChanged={handleLevelChange}
                value={level}
                TextFieldProps={{
                    select: true,
                    disabled: readOnly,
                }}
            >
                <MenuItem value={MessageLevelEnum.ERROR}>
                    <Typography>{translate("action.message.error")}</Typography>
                </MenuItem>
                <MenuItem value={MessageLevelEnum.INFO}>
                    <Typography>{translate("action.message.informative")}</Typography>
                </MenuItem>
                <MenuItem value={MessageLevelEnum.WARNING}>
                    <Typography>{translate("action.message.warning")}</Typography>
                </MenuItem>
            </KortexTextField>
            <VariablePicker
                onChange={handleStoreToChange}
                storingMethod={storeTo.storingMethod}
                KortexTextFieldProps={{ label: translate("action.message.storeTo") }}
                userAccessLevel={userAccessLevel}
                value={storeTo.identifier}
            />
        </Paper>
    );
}
