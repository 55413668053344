import { IWorkInstructionsElementConfig, IWorkInstructionsVideoProps } from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core";
import * as React from "react";
import ReactPlayer from "react-player";

import * as CloudStorage from "../../../../../../../../../utilitites/storage";

const useStyles = makeStyles({
    player: {
        pointerEvents: "all",
        position: "absolute",
    },
});

export interface IOwnProps {
    ratio?: number;
    videoProps: IWorkInstructionsElementConfig<IWorkInstructionsVideoProps>;
}

export default function WorkInstructionsVideoViewer(props: IOwnProps): JSX.Element {
    const { ratio = 1, videoProps } = props;

    const classes = useStyles();

    const { extendedProps, height, scale, width, x, y } = videoProps;
    const finalHeight = height * scale * ratio;
    const finalWidth = width * scale * ratio;

    return (
        <ReactPlayer
            className={classes.player}
            controls={true}
            height={finalHeight}
            style={{
                left: x * ratio,
                top: y * ratio,
            }}
            playing={true}
            url={`${CloudStorage.get().host}/${extendedProps.url}`}
            volume={0}
            width={finalWidth}
        />
    );
}
