import { forEachLinkedProcessAction, isWorkInstructions } from "../../../../../utilities";
/**
 * @param {ProcessUiModel} process - The process to go through. Only connected Work Instructions will be considered.
 * @param {Cb} cb - Call a callback for all Work Instructions step that include a training communique.
 *
 * @returns {ProcessActionStepWorkInstructions[]} Return a list of all Work Instructions steps that include a training communique.
 */
export function getWorkInstructionsStepsWithTrainingCommunique(process, cb = (step) => step) {
    const workInstructionSteps = [];
    forEachLinkedProcessAction(process, (action, actionIndex) => {
        if (!isWorkInstructions(action))
            return;
        for (const [stepIndex, step] of action.steps.entries())
            if (step.config.trainingCommunique)
                workInstructionSteps.push(cb(step, stepIndex, action, actionIndex));
    });
    return workInstructionSteps;
}
