import { KortexTextField, theme } from "@aos/react-components";
import { IOrgSettingDbModel, TimeBaseSettingEnum } from "@kortex/aos-common";
import { Checkbox, Typography, makeStyles } from "@material-ui/core";
import * as React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

import GlobalSettingElement from "./GlobalSettingElement";
import { globalSettingsStyles } from "./GlobalSettingsCommon";

interface IOwnProps {
    expanded: boolean;
    organizationInfo: IOrgSettingDbModel;
    userCanEdit: boolean;

    onUpdate?: <Key extends keyof IOrgSettingDbModel>(setting: Key, value: IOrgSettingDbModel[Key]) => void;
    onCollapseChange?: (settingName: string, expanded: boolean) => void;
}

export const useStyles = makeStyles({
    list: {
        display: "flex",
        flexDirection: "row",
    },
    listItem: {
        padding: "30px 0px",
    },
    divider: {
        padding: "10px 10px",
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        height: "130px",
    },
    optionsItem: {
        display: "flex",
        flexDirection: "row",
        padding: "5px",
    },
    optionItem: {
        display: "flex",
        flexDirection: "column",
        padding: "5px",
    },
});

function GlobalSettingUserAndPassword(props: IOwnProps): JSX.Element {
    const classesSettings = globalSettingsStyles();
    const classes = useStyles();
    const translate = useTranslate();

    const { organizationInfo, userCanEdit } = props;

    /**
     * Handle setting change
     *
     * @param {keyof IOrgSettingDbModel} setting - setting to be changed
     */
    const handleSettingChange =
        (setting: keyof IOrgSettingDbModel): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (props.onUpdate) {
                props.onUpdate(setting, event.target.value);
            }
        };

    /**
     * Handle setting checkbox change
     *
     * @param {keyof IOrgSettingDbModel} setting - setting to be changed
     */
    const handleSettingCbChange =
        (setting: keyof IOrgSettingDbModel): ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            if (props.onUpdate) {
                props.onUpdate(setting, checked);
            }
        };

    /**
     * Handle panel collapse change
     *
     * @param {boolean} expanded - true or false, if expanded or not
     */
    const handleCollapseChange = (expanded: boolean): void => {
        if (props.onCollapseChange) {
            props.onCollapseChange("GlobalSettingUserAndPassword", expanded);
        }
    };

    /**
     * Handle CB Changes of password expires
     *
     * @param {keyof IOrgSettingDbModel} setting - setting to be changed
     */
    const handlePasswordExpiresCbChange =
        (setting: TimeBaseSettingEnum): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (): void => {
            if (props.onUpdate) {
                props.onUpdate("passwordExpiresTimeUnit", setting);
            }
        };

    /**
     * Handle CB Changes of account lock time setting
     *
     * @param {keyof IOrgSettingDbModel} setting - setting to be changed
     */
    const handlePasswordAccountLockTimeCbChange =
        (setting: TimeBaseSettingEnum): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (): void => {
            if (props.onUpdate) {
                props.onUpdate("passwordAccountLockTimeUnit", setting);
            }
        };

    return (
        <GlobalSettingElement
            titleKey="settings.globalSettings.userAndPasswordTitle"
            expanded={props.expanded}
            onCollapseChange={handleCollapseChange}
            id={"userAndPasswordSettingsId"}
        >
            <div>
                <Typography variant={"h6"}>{translate("settings.globalSettings.userAndPassword.passwordSettings")}</Typography>
                <div className={classes.list}>
                    <div className={classes.listItem}>
                        <div className={classes.divider}>
                            <KortexTextField
                                className={classesSettings.textFieldExtraSmall}
                                label={translate("settings.globalSettings.userAndPassword.passwordRetriesLimit")}
                                variant={"standard"}
                                value={organizationInfo.passwordRetriesLimit}
                                onChange={handleSettingChange("passwordRetriesLimit")}
                                TextFieldProps={{
                                    disabled: !userCanEdit,
                                }}
                            />
                        </div>
                    </div>
                    <div className={classes.listItem}>
                        <div className={classes.divider}>
                            <KortexTextField
                                className={classesSettings.textFieldSmall}
                                label={translate("settings.globalSettings.userAndPassword.passwordAccountLockTime")}
                                variant={"standard"}
                                value={organizationInfo.passwordAccountLockTime}
                                onChange={handleSettingChange("passwordAccountLockTime")}
                                TextFieldProps={{
                                    disabled: !userCanEdit,
                                }}
                            />
                            <div className={classes.optionsItem}>
                                <div className={classes.optionItem}>
                                    <Typography variant={"caption"}>{translate("general.days")}</Typography>
                                    <Checkbox
                                        id="passwordAccountLockTimeCbDaysId"
                                        checked={organizationInfo.passwordAccountLockTimeUnit === TimeBaseSettingEnum.DAY}
                                        onChange={handlePasswordAccountLockTimeCbChange(TimeBaseSettingEnum.DAY)}
                                        disabled={!userCanEdit}
                                    />
                                </div>
                                <div className={classes.optionItem}>
                                    <Typography variant={"caption"}>{translate("general.hours")}</Typography>
                                    <Checkbox
                                        id="passwordAccountLockTimeCbHoursId"
                                        checked={organizationInfo.passwordAccountLockTimeUnit === TimeBaseSettingEnum.HOUR}
                                        onChange={handlePasswordAccountLockTimeCbChange(TimeBaseSettingEnum.HOUR)}
                                        disabled={!userCanEdit}
                                    />
                                </div>
                                <div className={classes.optionItem}>
                                    <Typography variant={"caption"}>{translate("general.minutes")}</Typography>
                                    <Checkbox
                                        id="passwordAccountLockTimeCbMinutesId"
                                        checked={organizationInfo.passwordAccountLockTimeUnit === TimeBaseSettingEnum.MINUTE}
                                        onChange={handlePasswordAccountLockTimeCbChange(TimeBaseSettingEnum.MINUTE)}
                                        disabled={!userCanEdit}
                                    />
                                </div>
                                <div className={classes.optionItem}>
                                    <Typography variant={"caption"}>{translate("general.seconds")}</Typography>
                                    <Checkbox
                                        id="passwordAccountLockTimeCbSecondsId"
                                        checked={organizationInfo.passwordAccountLockTimeUnit === TimeBaseSettingEnum.SECOND}
                                        onChange={handlePasswordAccountLockTimeCbChange(TimeBaseSettingEnum.SECOND)}
                                        disabled={!userCanEdit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.listItem}>
                        <div className={classes.divider}>
                            <KortexTextField
                                className={classesSettings.textFieldSmall}
                                label={translate("settings.globalSettings.userAndPassword.passwordExpiresTimeSec")}
                                variant={"standard"}
                                value={organizationInfo.passwordExpiresTime}
                                onChange={handleSettingChange("passwordExpiresTime")}
                                TextFieldProps={{
                                    disabled: !userCanEdit,
                                }}
                            />
                            <div className={classes.optionsItem}>
                                <div className={classes.optionItem}>
                                    <Typography variant={"caption"}>{translate("general.days")}</Typography>
                                    <Checkbox
                                        id="passwordExpiresTimeCbDaysId"
                                        checked={organizationInfo.passwordExpiresTimeUnit === TimeBaseSettingEnum.DAY}
                                        onChange={handlePasswordExpiresCbChange(TimeBaseSettingEnum.DAY)}
                                        disabled={!userCanEdit}
                                    />
                                </div>
                                <div className={classes.optionItem}>
                                    <Typography variant={"caption"}>{translate("general.hours")}</Typography>
                                    <Checkbox
                                        id="passwordExpiresTimeCbHoursId"
                                        checked={organizationInfo.passwordExpiresTimeUnit === TimeBaseSettingEnum.HOUR}
                                        onChange={handlePasswordExpiresCbChange(TimeBaseSettingEnum.HOUR)}
                                        disabled={!userCanEdit}
                                    />
                                </div>
                                <div className={classes.optionItem}>
                                    <Typography variant={"caption"}>{translate("general.minutes")}</Typography>
                                    <Checkbox
                                        id="passwordExpiresTimeCbMinutesId"
                                        checked={organizationInfo.passwordExpiresTimeUnit === TimeBaseSettingEnum.MINUTE}
                                        onChange={handlePasswordExpiresCbChange(TimeBaseSettingEnum.MINUTE)}
                                        disabled={!userCanEdit}
                                    />
                                </div>
                                <div className={classes.optionItem}>
                                    <Typography variant={"caption"}>{translate("general.seconds")}</Typography>
                                    <Checkbox
                                        id="passwordExpiresTimeCbSecondsId"
                                        checked={organizationInfo.passwordExpiresTimeUnit === TimeBaseSettingEnum.SECOND}
                                        onChange={handlePasswordExpiresCbChange(TimeBaseSettingEnum.SECOND)}
                                        disabled={!userCanEdit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.listItem}>
                        <div className={classes.divider}>
                            <Typography variant={"caption"}>
                                {translate("settings.globalSettings.userAndPassword.passwordChangeOnFirstLogin")}
                            </Typography>
                            <Checkbox
                                checked={organizationInfo.passwordChangeOnFirstLogin}
                                id="passwordChangeOnFirstLoginId"
                                onChange={handleSettingCbChange("passwordChangeOnFirstLogin")}
                                disabled={!userCanEdit}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </GlobalSettingElement>
    );
}

export default GlobalSettingUserAndPassword;
