import { ITrainingPending, TrainingCertificateId, TrainingPendingTrainingCertificateSignRequest, UserId } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesTrainingsPendingOfUser } from "@kortex/aos-ui/redux/effects";
import { clearTrainingPendingOfUser, trainingCertificateSign } from "@kortex/aos-ui/redux/training-pending-manager/training-pending-thunks";
import React, { FC, useEffect, useState } from "react";

import { UserValidationDialog } from "../../core/UserValidationDialog";

import TrainingCertificateContextSignature from "./TrainingCertificateContextSignature";

interface IOwnProps {
    userId: UserId;
}

export const TrainingCertificateSignatureDialog: FC<IOwnProps> = (props) => {
    const { userId } = props;

    const translate = useTranslate();

    const dispatch = useThunkDispatch();

    const trainingsPending = useEntitiesTrainingsPendingOfUser(userId);

    const [trainingPendingCount, setTrainingPendingCount] = useState<number>(0);
    const [selectedTrainingCertificateIds, setSelectedTrainingCertificateIds] = useState<TrainingCertificateId[]>([]);

    useEffect(() => {
        setTrainingPendingCount(trainingsPending.length - 1);
        if (trainingsPending.length > 0) {
            setSelectedTrainingCertificateIds(trainingsPending.map((trainingPending) => trainingPending.trainingCertificateId));
        }
    }, [trainingsPending]);

    /**
     * Handle called when the user cancel the dialog
     */
    const handleTrainerSignatureCancel = (): void => {
        dispatch(clearTrainingPendingOfUser());
    };

    /**
     * Handle called when the user select training certificate
     */
    const handelSelectedTrainingCertificateIds = (trainingCertificateIds: TrainingCertificateId[]): void => {
        setSelectedTrainingCertificateIds(trainingCertificateIds);
    };

    /**
     * Handle called when the trainee or trainer has signed the certificate
     */
    const handleTrainerHasSigned = async (): Promise<void> => {
        const trainingsCertificateSelected: TrainingPendingTrainingCertificateSignRequest = [];
        const unselectTrainingsCertificate: ITrainingPending[] = [];

        for (const trainingPending of trainingsPending) {
            if (selectedTrainingCertificateIds.includes(trainingPending.trainingCertificateId)) {
                trainingsCertificateSelected.push({
                    ...trainingPending,
                    context:
                        trainingPending.type === "trainee"
                            ? translate("training.trainingCertificateTraineeSignatureContext")
                            : translate("training.trainingCertificateTrainerSignatureContext"),
                });
            } else {
                unselectTrainingsCertificate.push(trainingPending);
            }
        }

        await dispatch(trainingCertificateSign({ trainingsCertificateSelected, unselectTrainingsCertificate }));
    };

    return (
        <UserValidationDialog
            open={trainingsPending.length > 0}
            isElectronicSignature={true}
            electronicSignatureContext={trainingsPending.map((trainingPending, index) => (
                <TrainingCertificateContextSignature
                    key={`${index}TrainingCertificateContextSignature`}
                    index={index}
                    onSelectedTrainingCertificateIds={handelSelectedTrainingCertificateIds}
                    selectedTrainingCertificateIds={selectedTrainingCertificateIds}
                    trainingPending={trainingPending}
                    trainingPendingCount={trainingPendingCount}
                />
            ))}
            onClose={handleTrainerSignatureCancel}
            preSelectedUserId={userId}
            onValidate={handleTrainerHasSigned}
        />
    );
};

export default TrainingCertificateSignatureDialog;
