import { KortexDialogConfirmation } from "@aos/react-components";
import { Typography } from "@material-ui/core";
import * as React from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";

interface IOwnProps {
    onCancel: () => void;
    onConfirm: () => void;
    opened: boolean;
}

export default function ProcessApprovalRecoverVersionDialog(props: IOwnProps): JSX.Element {
    const { onCancel, onConfirm, opened } = props;

    const translate = useTranslate();

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: onCancel,
            }}
            onCancel={onCancel}
            onConfirm={onConfirm}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("process.versioning.recoverVersionDialogCancel"),
                proceedButtonLabel: translate("process.versioning.recoverVersionDialogConfirm"),
                titleLabel: translate("process.versioning.recoverVersionDialogConfirm"),
            }}
        >
            <Typography>{translate("process.versioning.recoverVersionDialogMessage")}</Typography>
        </KortexDialogConfirmation>
    );
}
