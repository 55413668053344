import { getCleanScannerCode } from "@kortex/aos-common";
import { theme } from "@aos/react-components";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

const useStyles = makeStyles({
    code: {
        maxWidth: "200px",
        overflowX: "hidden",
    },
    spacer: {
        flexGrow: 1,
    },
    tableCellCode: {
        border: 0,
        display: "flex",
    },
    tableCellHeader: {
        backgroundColor: theme.palette.primary.main,
        border: `0px solid ${theme.palette.primary.main}`,
        color: theme.palette.common.white,
        maxHeight: "48px",
        padding: "0 16px",
    },
    tableContainer: {
        padding: 0,
    },
    tableRowBody: {
        height: "64px",
    },
    tableRowHead: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: "48px",
    },
    tableRowTableEmpty: {
        color: theme.palette.grey[500],
    },
});

interface ICodeTableLabels {
    header: string;
    empty: string;
}

interface ICodeTableProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    codes: string[];
    id?: string;
    labels: ICodeTableLabels;
    onDelete: (index: number) => void;
}

function CodeTable(props: ICodeTableProps): JSX.Element {
    const { codes, id, labels, onDelete } = props;
    const classes = useStyles(props);

    /**
     * Delete the code
     */
    const handleDelete =
        (index: number): (() => void) =>
        (): void => {
            onDelete(index);
        };

    return (
        <TableContainer className={classes.tableContainer} id={id}>
            <Table stickyHeader={true}>
                <TableHead>
                    <TableRow className={classes.tableRowHead}>
                        <TableCell className={classes.tableCellHeader}>
                            <Typography>{labels.header}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className={classes.tableRowBody}>
                        {codes.length ? (
                            codes.map((code, index) => (
                                <TableCell className={classes.tableCellCode} key={`code${index}`}>
                                    <Typography className={classes.code} color="inherit" id={`codeTableCodeId${index}`}>
                                        {getCleanScannerCode(code)}
                                    </Typography>
                                    <div className={classes.spacer} />
                                    <DeleteIcon onClick={handleDelete(index)} id={`codeTableDeleteCodeId${index}`} />
                                </TableCell>
                            ))
                        ) : (
                            <TableCell className={classes.tableRowTableEmpty}>
                                <Typography id="codeTableEmptyId">{labels.empty}</Typography>
                            </TableCell>
                        )}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CodeTable;
