import { greyPalette } from "@aos/react-components";
import { UserId } from "@kortex/aos-common";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Typography, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { useReadingCertificateContext } from "../../../context";

import { ReadingCertificateFormDialog } from "./readingCertificateFormDialog";

const useStyles = makeStyles({
    cardContent: {
        alignItems: "center",
        backgroundColor: greyPalette[200],
        display: "grid",
        gridTemplateColumns: "5% 33% 33% 28%",
    },
    column: {
        alignItems: "center",
        display: "flex",
        height: "58px",
        justifyContent: "center",
    },
    text: {
        color: greyPalette[700],
    },
});

interface IOwnProps {
    userId: UserId;
}

const ReadingCertificateTrainingHeaderCard: FC<IOwnProps> = (props) => {
    const { userId } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const { isReadingCertificateEnabled } = useReadingCertificateContext();

    const readingCertificateEnabled = isReadingCertificateEnabled(userId);

    return (
        <KortexPanelCard hover={false} isSelected={false} padding={0} spaceBetweenCard={1}>
            <div className={classes.cardContent} id="readingCertificateCardHeaderId">
                {/* READING CERTIFICATE */}
                <div className={classes.column}>{readingCertificateEnabled && <ReadingCertificateFormDialog />}</div>
                {/* PROCESS NAME */}
                <div className={classes.column}>
                    <Typography className={classes.text}>{translate("general.process").toUpperCase()}</Typography>
                </div>
                {/* VERSION */}
                <div className={classes.column}>
                    <Typography className={classes.text}>{translate("training.processVersion").toUpperCase()}</Typography>
                </div>
                {/* CREATED ON */}
                <div className={classes.column}>
                    <Typography className={classes.text}>{translate("training.lateSince").toUpperCase()}</Typography>
                </div>
            </div>
        </KortexPanelCard>
    );
};

export default ReadingCertificateTrainingHeaderCard;
