import { FileInfoDbModel } from "@kortex/aos-common";

import { ComplexAction } from "../app.types";

export enum FileActionType {
    INSERT_FILES = "@@FILE/INSERT_FILES",
    SET_FILES = "@@FILE/SET_FILES",
    UPDATE_FILES = "@@FILE/UPDATE_FILE",
}

interface IFileState {
    files: FileInfoDbModel[];
}

export type FileState = IFileState;

export type FilesInsertedAction = ComplexAction<FileActionType.INSERT_FILES, FileState["files"]>;
export type FileUpdatedAction = ComplexAction<FileActionType.UPDATE_FILES, FileState["files"]>;
export type SetFilesAction = ComplexAction<FileActionType.SET_FILES, FileState["files"]>;

export type FileActions = FilesInsertedAction | FileUpdatedAction | SetFilesAction;
