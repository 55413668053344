import { ITrainingReadingCertificatePending, TrainingId, UserId } from "@kortex/aos-common";

import { BareAction, ComplexAction } from "../app.types";

export interface TrainingReadingCertificatePendingState {
    readingCertificatePendings: ITrainingReadingCertificatePending[];
}

export enum TrainingReadingCertificatePendingActionType {
    CLEAR_READING_CERTIFICATE_PENDING = "@@TRAINING_READING_CERTIFICATE_PENDING/CLEAR",
    SET_READING_CERTIFICATE_PENDING = "@@TRAINING_READING_CERTIFICATE_PENDING/SET",
    UPSERT_READING_CERTIFICATE_PENDING = "@@TRAINING_READING_CERTIFICATE_PENDING/UPSERT",
    DELETE_READING_CERTIFICATE_PENDING = "@@TRAINING_READING_CERTIFICATE_PENDING/DELETE",
    UPSERT_TRAINING_INTO_READING_CERTIFICATE_PENDING = "@@TRAINING_READING_CERTIFICATE_PENDING/UPSERT_TRAINING",
    DELETE_TRAINING_FROM_READING_CERTIFICATE_PENDING = "@@TRAINING_READING_CERTIFICATE_PENDING/DELETE_TRAINING",
}

export type TrainingReadingCertificatePendingClearAction =
    BareAction<TrainingReadingCertificatePendingActionType.CLEAR_READING_CERTIFICATE_PENDING>;
export type TrainingReadingCertificatePendingUpsertedAction = ComplexAction<
    TrainingReadingCertificatePendingActionType.UPSERT_READING_CERTIFICATE_PENDING,
    ITrainingReadingCertificatePending[]
>;
export type TrainingReadingCertificatePendingSetAction = ComplexAction<
    TrainingReadingCertificatePendingActionType.SET_READING_CERTIFICATE_PENDING,
    ITrainingReadingCertificatePending[]
>;
export type TrainingReadingCertificatePendingDeletedAction = ComplexAction<
    TrainingReadingCertificatePendingActionType.DELETE_READING_CERTIFICATE_PENDING,
    UserId[]
>;
export type TrainingReadingCertificatePendingTrainingUpsertedAction = ComplexAction<
    TrainingReadingCertificatePendingActionType.UPSERT_TRAINING_INTO_READING_CERTIFICATE_PENDING,
    ITrainingReadingCertificatePending[]
>;
export type TrainingReadingCertificatePendingTrainingDeletedAction = ComplexAction<
    TrainingReadingCertificatePendingActionType.DELETE_TRAINING_FROM_READING_CERTIFICATE_PENDING,
    TrainingId[]
>;

export type TrainingReadingCertificatePendingAction =
    | TrainingReadingCertificatePendingClearAction
    | TrainingReadingCertificatePendingUpsertedAction
    | TrainingReadingCertificatePendingSetAction
    | TrainingReadingCertificatePendingDeletedAction
    | TrainingReadingCertificatePendingTrainingUpsertedAction
    | TrainingReadingCertificatePendingTrainingDeletedAction;
