import { TaskTypeEnum, TaskStatusEnum } from "@kortex/aos-common";
import { rainbowPalette, greyPalette } from "@aos/react-components";

import { ILocale } from "../../../locales/ILocale";

/**
 * Return Task type key (for translation) for the corresponding task type
 *
 * @param {TaskTypeEnum} type - Type to retreive key
 *
 * @returns {string} - translation key
 */
export function getTaskTypeKey(type: TaskTypeEnum): keyof ILocale {
    switch (type) {
        case TaskTypeEnum.PROCESS_APPROVAL:
            return "tasks.taskType.processApproval";
        case TaskTypeEnum.PROCESS_REVIEW:
            return "tasks.taskType.processReview";
        default:
            return "tasks.taskType.unknown";
    }
}

/**
 * Return Task status key (for translation) for the corresponding task status
 *
 * @param {TaskStatusEnum} status - Type to retreive key
 *
 * @returns {string} - translation key
 */
export function getTaskStatusKey(status: TaskStatusEnum): keyof ILocale {
    switch (status) {
        case TaskStatusEnum.OPEN:
            return "tasks.taskStatus.open";
        case TaskStatusEnum.CLOSE:
            return "tasks.taskStatus.close";
        case TaskStatusEnum.ARCHIVE:
            return "tasks.taskStatus.archive";
        default:
            return "tasks.taskStatus.unknown";
    }
}

/**
 * Return Task status color for the corresponding task status
 *
 * @param {TaskStatusEnum} status - Type to retreive color
 *
 * @returns {string} - color
 */
export function getTaskStatusColor(status: TaskStatusEnum): string {
    switch (status) {
        case TaskStatusEnum.CLOSE:
            return greyPalette[400];
        case TaskStatusEnum.OPEN:
            return rainbowPalette[300]; // Blue
        default:
            return greyPalette[400];
    }
}
