export class ParserState {
    constructor() {
        this.result = "";
    }
    toJSON() {
        return {
            result: this.result,
        };
    }
}
