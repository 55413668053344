import {
    ClientTypes,
    ConnectionListChangedNotifTypeEnum,
    IConnectionInfoUI,
    VERSION_EMPTY,
    getTimezoneOffset,
    upsertObjectFromArray,
} from "@kortex/aos-common";
import shortid from "shortid";

import { deleteArray } from "../utils.delete";

import { ConnectionState, EnumPageTypes, GeneralActionType, GeneralActions, GeneralState } from "./general-types";

let clientId = shortid.generate();

if (window.name) {
    clientId = window.name;
} else {
    window.name = clientId;
}

const initialState: GeneralState = {
    mobileOpen: false,
    currentPage: EnumPageTypes.NO_PAGE,
    clientId,
    language: "en",
    desktopUpdateReady: false,
    connectionList: { hub: { clientId: "hub", version: VERSION_EMPTY }, runner: [], ui: [] },
    uplink: {
        authenticated: false,
        state: ConnectionState.NONE,
        reconnecting: false,
    },
    timezoneOffset: getTimezoneOffset(new Date()),
};

export function generalReducer(state: GeneralState = initialState, action: GeneralActions): GeneralState {
    switch (action.type) {
        case GeneralActionType.UPLINK_AUTHENTICATED: {
            return {
                ...state,
                uplink: {
                    ...state.uplink,
                    authenticated: action.value,
                },
            };
        }
        case GeneralActionType.UPLINK_CLOSED:
        case GeneralActionType.UPLINK_CLOSING:
        case GeneralActionType.UPLINK_CONNECTED:
        case GeneralActionType.UPLINK_CONNECTING:
        case GeneralActionType.UPLINK_NONE: {
            return {
                ...state,
                uplink: {
                    ...state.uplink,
                    state:
                        {
                            [GeneralActionType.UPLINK_CLOSED]: ConnectionState.CLOSED,
                            [GeneralActionType.UPLINK_CLOSING]: ConnectionState.CLOSING,
                            [GeneralActionType.UPLINK_CONNECTED]: ConnectionState.CONNECTED,
                            [GeneralActionType.UPLINK_CONNECTING]: ConnectionState.CONNECTING,
                            [GeneralActionType.UPLINK_READY]: ConnectionState.READY,
                            [GeneralActionType.UPLINK_NONE]: ConnectionState.NONE,
                        }[action.type] ?? ConnectionState.NONE,
                },
            };
        }
        case GeneralActionType.UPLINK_READY: {
            return {
                ...state,
                uplink: {
                    ...state.uplink,
                    state: ConnectionState.READY,
                    reconnecting: false,
                },
            };
        }
        case GeneralActionType.RECONNECTING: {
            return {
                ...state,
                uplink: {
                    ...state.uplink,
                    authenticated: false,
                    reconnecting: true,
                },
            };
        }
        case GeneralActionType.RECONNECTING_FAILED: {
            return {
                ...state,
                uplink: {
                    ...state.uplink,
                    reconnecting: false,
                },
            };
        }
        case GeneralActionType.SET_ROUTE:
            return {
                ...state,
                currentPage: action.value,
            };
        case GeneralActionType.SET_UPDATE_READY:
            return {
                ...state,
                desktopUpdateReady: true,
            };
        case GeneralActionType.SET_LANGUAGE:
            return {
                ...state,
                language: action.value,
            };
        case GeneralActionType.SET_ALL_CONNECTION_INFO:
            return {
                ...state,
                connectionList: action.value,
            };
        case GeneralActionType.SET_CONNECTION_LIST_CHANGED_ACTION:
            if (action.value.changeType === ConnectionListChangedNotifTypeEnum.INSERT) {
                switch (action.value.connectionType) {
                    case ClientTypes.UI:
                        return {
                            ...state,
                            connectionList: {
                                ...state.connectionList,
                                ui: upsertObjectFromArray(
                                    state.connectionList.ui,
                                    action.value.connectionInfo as IConnectionInfoUI,
                                    function (connection) {
                                        return connection.clientId === this.clientId;
                                    }
                                ),
                            },
                        };
                    case ClientTypes.RUNNER:
                        return {
                            ...state,
                            connectionList: {
                                ...state.connectionList,
                                runner: upsertObjectFromArray(
                                    state.connectionList.runner,
                                    action.value.connectionInfo,
                                    function (connection) {
                                        return connection.clientId === this.clientId;
                                    }
                                ),
                            },
                        };
                    default:
                        return state;
                }
            } else if (action.value.changeType === ConnectionListChangedNotifTypeEnum.DELETE) {
                switch (action.value.connectionType) {
                    case ClientTypes.UI:
                        return {
                            ...state,
                            connectionList: {
                                ...state.connectionList,
                                ui: deleteArray(
                                    state.connectionList.ui,
                                    action.value.connectionInfo as IConnectionInfoUI,
                                    function (connection) {
                                        return connection.clientId === this.clientId;
                                    }
                                ),
                            },
                        };
                    case ClientTypes.RUNNER:
                        return {
                            ...state,
                            connectionList: {
                                ...state.connectionList,
                                runner: deleteArray(state.connectionList.runner, action.value.connectionInfo, function (connection) {
                                    return connection.clientId === this.clientId;
                                }),
                            },
                        };
                    default:
                        return state;
                }
            } else {
                return state;
            }
        case GeneralActionType.TOGGLE_APP_DRAWER:
            return {
                ...state,
                mobileOpen: action.value,
            };
        default:
            return state;
    }
}
