import { IProcessTrainings, TrainingDbModel, TreeNodeId } from "@kortex/aos-common";

import { BareAction, ComplexAction } from "../app.types";

export type IProcessTrainingPanelSide = "left" | "right";

interface ISetProcessTrainings extends IProcessTrainings {
    panel: IProcessTrainingPanelSide;
}

export interface IProcessTrainingState {
    left: IProcessTrainings;
    right: IProcessTrainings;
    treeNodeId: TreeNodeId;
}

export enum ProcessTrainingActionType {
    CLEAR_PROCESS_TRAINING = "@@PROCESS_TRAINING/CLEAR_PROCESS_TRAINING",
    SET_PROCESS = "@@PROCESS_TRAINING/SET_PROCESS",
    UPDATE_PROCESS = "@@PROCESS_TRAINING/UPDATE_PROCESS",
    UPSERT_TRAININGS = "@@PROCESS_TRAINING/INSERT_TRAININGS",
}

export type ProcessTrainingClearAction = BareAction<ProcessTrainingActionType.CLEAR_PROCESS_TRAINING>;
export type ProcessTrainingSetProcessAction = ComplexAction<ProcessTrainingActionType.SET_PROCESS, ISetProcessTrainings>;
export type ProcessTrainingUpdateProcessAction = ComplexAction<ProcessTrainingActionType.UPDATE_PROCESS, IProcessTrainings>;
export type ProcessTrainingUpsertedTrainingAction = ComplexAction<ProcessTrainingActionType.UPSERT_TRAININGS, TrainingDbModel[]>;

export type ProcessTrainingActions =
    | ProcessTrainingClearAction
    | ProcessTrainingSetProcessAction
    | ProcessTrainingUpdateProcessAction
    | ProcessTrainingUpsertedTrainingAction;
