export const WorkInstructionsMarkerErrorKey = {
    INVALID_FORMAT: "invalidFormat",
    NONE: "",
    REQUIRED: "required",
};
export class WorkInstructionsMarkerState {
    constructor(text) {
        this.text = text;
    }
}
