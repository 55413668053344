import {
    BomRightsEnum,
    GroupEditorRightsEnum,
    ITokenDecoded,
    KanbanRightsEnum,
    ProcessApprovalRightsEnum,
    ProcessEditorRightsEnum,
    ProcessPlayerRightsEnum,
    ReportsRightsEnum,
    ReworkRightsEnum,
    RoleEditorRightsEnum,
    SchedulerOperatorRightsEnum,
    SchedulerRightsEnum,
    StorageEditorsRightsEnum,
    SystemRightsEnum,
    TrainingRightsEnum,
    UserEditorRightsEnum,
} from "@kortex/aos-common";
import { Typography } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import ReportsIcon from "@material-ui/icons/AssessmentOutlined";
import TasksIcon from "@material-ui/icons/AssignmentIndOutlined";
import BomIcon from "@material-ui/icons/AssignmentOutlined";
import BuildIcon from "@material-ui/icons/BuildOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import SettingsTagsIcon from "@material-ui/icons/LabelOutlined";
import SettingsRolesIcon from "@material-ui/icons/LockOutlined";
import SettingsGroupsIcon from "@material-ui/icons/PeopleOutlined";
import FileManagerIcon from "@material-ui/icons/PermMediaOutlined";
import SettingsUsersIcon from "@material-ui/icons/PersonOutlined";
import FormationIcon from "@material-ui/icons/SchoolOutlined";
import { default as SettingsGlobalsIcon, default as SettingsIcon } from "@material-ui/icons/SettingsOutlined";
import ProcessSchedulerIcon from "@material-ui/icons/TodayOutlined";
import * as React from "react";
//import SettingsWorkzonesIcon from "@material-ui/icons/TextureOutlined";

import ProcessIcon from "../components/core/Icons/Process/Process";
import { useTranslate } from "../hooks/useTranslate";
import { EnumPageTypes } from "../redux/general-manager/general-types";

export interface IMenuItem {
    id: string;
    label: JSX.Element;
    icon: React.ReactElement<SvgIconProps>;
    children?: IMenuItem[];
    hideFromMenu?: boolean;
}

/**
 * Return default from page based on user access rights
 *
 * @param {ITokenDecoded} userInfo - user auth token
 * @returns {EnumPageTypes} User default front page
 */
export function defaultFrontPage(userInfo: ITokenDecoded, isAllRegistered: boolean): EnumPageTypes {
    if (!userInfo || !userInfo.roleRights) {
        return EnumPageTypes.NO_PAGE;
    } else if (userInfo.passwordExpired) {
        return EnumPageTypes.PASSWORD;
    } else if (!isAllRegistered) {
        return EnumPageTypes.INFO;
    } else if (userInfo.roleRights.processEditor >= ProcessEditorRightsEnum.READ) {
        return EnumPageTypes.PROCESS;
    } else if (userInfo.roleRights.processPlayer >= ProcessPlayerRightsEnum.WRITE) {
        return EnumPageTypes.PLAYER;
    } else if (userInfo.roleRights.scheduler >= SchedulerRightsEnum.WRITE) {
        return EnumPageTypes.SCHEDULER;
    } else if (userInfo.roleRights.scheduler >= SchedulerRightsEnum.READ) {
        return EnumPageTypes.OPERATOR_SCHEDULER;
    } else if (userInfo.roleRights.bom >= BomRightsEnum.READ) {
        return EnumPageTypes.BOM;
    } else if (userInfo.roleRights.processApproval >= ProcessApprovalRightsEnum.WRITE) {
        return EnumPageTypes.TASKS;
    } else if (userInfo.roleRights.storageEditor >= StorageEditorsRightsEnum.WRITE) {
        return EnumPageTypes.STORAGE_MANAGER;
    } else if (userInfo.roleRights.kanban >= KanbanRightsEnum.INSERT) {
        return EnumPageTypes.KANBAN;
    } else if (userInfo.roleRights.reports >= ReportsRightsEnum.READ) {
        return EnumPageTypes.REPORTS;
    } else if (
        userInfo.roleRights.userEditor >= UserEditorRightsEnum.READ ||
        userInfo.roleRights.groupEditor >= GroupEditorRightsEnum.READ ||
        userInfo.roleRights.roleEditor >= RoleEditorRightsEnum.READ ||
        userInfo.roleRights.system >= SystemRightsEnum.READ
    ) {
        return EnumPageTypes.SETTINGS;
    } else {
        return EnumPageTypes.NO_PAGE;
    }
}

/**
 * Generates main menu based on user access rights
 *
 * @param {ITokenDecoded} userInfo - user info containing the access rights
 *
 * @returns {object[]} userInfo - user main menu Main menu settings
 */
export function getUserMainMenu(userInfo?: ITokenDecoded): IMenuItem[] {
    const translate = useTranslate();

    const menuList: IMenuItem[] = [];

    if (!userInfo) {
        return menuList;
    }
    if (userInfo.roleRights.processEditor >= ProcessEditorRightsEnum.READ) {
        menuList.push({
            id: EnumPageTypes.PROCESS,
            label: <Typography>{translate("menu.process.editor")}</Typography>,
            icon: <ProcessIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.scheduler >= SchedulerRightsEnum.READ) {
        menuList.push({
            id: EnumPageTypes.SCHEDULER,
            label: <Typography>{translate("menu.process.scheduler")}</Typography>,
            icon: <ProcessSchedulerIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.schedulerOperator >= SchedulerOperatorRightsEnum.READ) {
        menuList.push({
            id: EnumPageTypes.OPERATOR_SCHEDULER,
            label: <Typography>{translate("menu.process.operatorScheduler")}</Typography>,
            icon: <ProcessSchedulerIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.bom >= BomRightsEnum.READ) {
        menuList.push({
            id: EnumPageTypes.BOM,
            label: <Typography>{translate("menu.bom")}</Typography>,
            icon: <BomIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.rework >= ReworkRightsEnum.READ) {
        menuList.push({
            id: EnumPageTypes.REWORKS,
            label: <Typography>{translate("menu.reworks")}</Typography>,
            icon: <BuildIcon />,
            hideFromMenu: false,
        });
    }

    // No rights for this page since everyone could have some task to do
    menuList.push({
        id: EnumPageTypes.TASKS,
        label: <Typography>{translate("menu.tasks")}</Typography>,
        icon: <TasksIcon />,
        hideFromMenu: false,
    });

    if (userInfo.roleRights.training >= TrainingRightsEnum.READ) {
        menuList.push({
            id: EnumPageTypes.TRAINING,
            label: <Typography>{translate("menu.training")}</Typography>,
            icon: <FormationIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.reports >= ReportsRightsEnum.READ) {
        menuList.push({
            id: EnumPageTypes.REPORTS,
            label: <Typography>{translate("menu.reports")}</Typography>,
            icon: <ReportsIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.storageEditor >= StorageEditorsRightsEnum.READ) {
        menuList.push({
            id: EnumPageTypes.STORAGE_MANAGER,
            label: <Typography>{translate("menu.file.manager")}</Typography>,
            icon: <FileManagerIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.kanban >= KanbanRightsEnum.INSERT) {
        menuList.push({
            id: EnumPageTypes.KANBAN,
            label: <Typography>{translate("menu.kanban")}</Typography>,
            icon: <ReportsIcon />,
            hideFromMenu: false,
        });
    }

    const menuSettingItems: IMenuItem[] = [];

    if (userInfo.roleRights.system >= SystemRightsEnum.READ) {
        menuSettingItems.push({
            id: EnumPageTypes.SETTINGS_GLOBALS,
            label: <Typography>{translate("menu.settings.globals")}</Typography>,
            icon: <SettingsGlobalsIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.userEditor >= UserEditorRightsEnum.READ) {
        menuSettingItems.push({
            id: EnumPageTypes.SETTINGS_USERS,
            label: <Typography>{translate("menu.settings.users")}</Typography>,
            icon: <SettingsUsersIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.groupEditor >= GroupEditorRightsEnum.READ) {
        menuSettingItems.push({
            id: EnumPageTypes.SETTINGS_GROUPS,
            label: <Typography>{translate("menu.settings.groups")}</Typography>,
            icon: <SettingsGroupsIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.roleEditor >= RoleEditorRightsEnum.READ) {
        menuSettingItems.push({
            id: EnumPageTypes.SETTINGS_ROLES,
            label: <Typography>{translate("menu.settings.roles")}</Typography>,
            icon: <SettingsRolesIcon />,
            hideFromMenu: false,
        });
    }

    if (userInfo.roleRights.system >= SystemRightsEnum.READ) {
        menuSettingItems.push({
            id: EnumPageTypes.SETTINGS_TAGS,
            label: <Typography>{translate("menu.settings.tags")}</Typography>,
            icon: <SettingsTagsIcon />,
            hideFromMenu: false,
        });
    }

    // To be enable when workzone will be available
    /*
        menuSettingItems.push({
            id: EnumPageTypes.SETTINGS_WORKZONES,
            label: <Typography>{translate("menu.settings.workzones")}</Typography>,
            icon: <SettingsWorkzonesIcon />,
            hideFromMenu: false,
        });*/

    if (menuSettingItems.length) {
        menuList.push({
            id: EnumPageTypes.SETTINGS,
            label: <Typography>{translate("menu.settings")}</Typography>,
            icon: <SettingsIcon />,
            hideFromMenu: false,
            children: menuSettingItems,
        });
    }

    menuList.push({
        id: EnumPageTypes.INFO,
        label: <Typography>{translate("menu.info")}</Typography>,
        icon: <InfoIcon />,
        hideFromMenu: false,
    });

    return menuList;
}
