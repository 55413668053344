import { theme } from "@aos/react-components";
import { EnumUserStatus, IUserDbModel, UserId } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesUsers } from "@kortex/aos-ui/redux/effects";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import React, { useState } from "react";

import UserSearchBar, { UserFilters } from "./UserSearchBar";

const DEFAULT_FILTER: UserFilters = {
    masterSearch: "",
    userGroups: [],
    userRoles: [],
};

const useStyles = makeStyles({
    dialogActions: {
        backgroundColor: theme.palette.grey[200],
        display: "flex",
        flexDirection: "column-reverse",
        margin: 0,
        padding: "10px",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 30px",
    },
    dialogContent: {
        ...theme.typography.body2,
        display: "flex",
        flexDirection: "column",
        height: "600px",
        padding: "20px",
    },
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
    },
    mainForm: {
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
    },
    pannelDetailsContent: {
        width: "100%",
    },
    tableCell: {
        padding: "12px",
    },
    tableHeader: {
        backgroundColor: theme.palette.grey[200],
    },
    buttons: {
        margin: "10px",
        padding: "10px",
    },
    fab: {
        margin: "5px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
    },
});

interface IUserSelectedDialogProps {
    disabled?: boolean;
    onConfirmDialog: (selectedUserIds: UserId[]) => void;
    excludedUserIdsFromTheList?: UserId[];
    multiSelect?: boolean;
    tootltip?: NonNullable<React.ReactNode>;
}

function UserSelectorDialog(props: IUserSelectedDialogProps): JSX.Element {
    const { disabled = false, onConfirmDialog, excludedUserIdsFromTheList = [], multiSelect = true, tootltip = "" } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [open, setOpen] = useState<boolean>(false);
    const [selectedUserIds, setSelectedUserIds] = useState<UserId[]>([]);
    const [filters, setFilters] = useState<UserFilters>(DEFAULT_FILTER);

    const users = useEntitiesUsers()
        .filter(
            (user) =>
                user.status === EnumUserStatus.ACTIVE && !excludedUserIdsFromTheList.includes(user.userId) && applyFilters(filters, user)
        )
        .sort((a, b) => {
            const nameA = a.userName.toUpperCase(); // ignore upper and lowercase
            const nameB = b.userName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });

    /**
     * Handle called when the user cancel the dialog
     */
    const handleCancel = (): void => {
        setOpen(false);
    };

    /**
     * Handle called when the user confir the dialog
     */
    const handleConfirm = (): void => {
        setOpen(false);
        onConfirmDialog(selectedUserIds);
    };

    /**
     * Opens dialog
     */
    const handleOpenDialog = (): void => {
        setSelectedUserIds([]);
        setFilters(DEFAULT_FILTER);
        setOpen(true);
    };

    /**
     * Handle called when the user is selected
     */
    const handleSelectUser =
        (userId: UserId): ((_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) =>
        (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            if (multiSelect) {
                const userIds = [...selectedUserIds];

                if (checked) {
                    userIds.push(userId);
                } else {
                    userIds.splice(userIds.indexOf(userId), 1);
                }

                setSelectedUserIds(userIds);
            } else {
                if (checked) {
                    setSelectedUserIds([userId]);
                } else {
                    setSelectedUserIds([]);
                }
            }
        };

    /**
     * Select all the user based on the currently filters
     */
    const selectAll = (): void => {
        setSelectedUserIds(users.map((user) => user.userId));
    };

    /**
     * Select all the jobProcess
     */
    const clearAll = (): void => {
        setSelectedUserIds([]);
    };

    return (
        <>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={tootltip}>
                <Fab
                    className={classes.fab}
                    size="medium"
                    id="userSelectorDialogButtonId"
                    color="secondary"
                    disabled={disabled}
                    onClick={handleOpenDialog}
                >
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Dialog disableAutoFocus={true} maxWidth="lg" open={open} fullWidth={true}>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogTitle}>
                        {/* TILTE */}
                        <div className={classes.dialogDescription}>
                            <Typography variant="h2">{translate("general.selectUser")}</Typography>
                        </div>
                        {/* SELECTION BUTTONS */}
                        <div>
                            <Button
                                id="selectAllButtonId"
                                variant="contained"
                                color="secondary"
                                onClick={selectAll}
                                className={classes.buttons}
                                startIcon={<SelectAllIcon />}
                                disabled={!multiSelect}
                            >
                                {translate("general.selectAll")}
                            </Button>
                            <Button
                                id="clearAllButtonId"
                                variant="contained"
                                color="secondary"
                                onClick={clearAll}
                                className={classes.buttons}
                                startIcon={<ClearAllIcon />}
                            >
                                {translate("general.clearAll")}
                            </Button>
                        </div>
                    </div>
                    {/* SEARCH BAR */}
                    <UserSearchBar filters={filters} onFilterChange={setFilters} />
                    {/* LIST USER */}
                    <div id="userSelectorDialogTableId" className={classes.mainForm}>
                        <Table className={classes.pannelDetailsContent}>
                            <TableHead className={classes.tableHeader}>
                                <TableRow>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body2">{translate("training.userName")}</Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Typography variant="body2">{translate("general.select")}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map(
                                    (user, index): JSX.Element => (
                                        <TableRow key={index}>
                                            <TableCell id={`userName${index}Id`} className={classes.tableCell}>
                                                <Typography variant="body2">{`${user.firstName} ${user.lastName}`}</Typography>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <Checkbox
                                                    checked={selectedUserIds.includes(user.userId)}
                                                    id={`userSelectorDialogCheckBoxSelectUserId${index}`}
                                                    onChange={handleSelectUser(user.userId)}
                                                    value={user.userId}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
                {/* DIALOG ACTIONS */}
                <DialogActions className={classes.dialogActions}>
                    <Button
                        className={classes.dialogButtons}
                        color="secondary"
                        id="userSelectorDialogCancelButtonId"
                        onClick={handleCancel}
                        variant="contained"
                    >
                        {translate("general.cancel")}
                    </Button>
                    <Button
                        className={classes.dialogButtons}
                        color="secondary"
                        disabled={disabled}
                        id="userSelectorDialogConfirmButtonId"
                        onClick={handleConfirm}
                        variant="contained"
                    >
                        {translate("general.confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default UserSelectorDialog;

/**
 * Tests a single user against the filters
 *
 * @param {UserFilters} filters - filter user to test
 * @param {IUserDbModel} user - user to test
 * @returns {boolean} - true if user passed
 */
const applyFilters = (filters: UserFilters, user: IUserDbModel): boolean => {
    const { userGroups = [], userRoles = [], masterSearch = "" } = filters;

    // Plain text
    if (masterSearch !== "") {
        const plainTextArray = masterSearch?.split(",") ?? "";
        let found = false;

        for (const text of plainTextArray) {
            // create string that contains all searchable params (firstName, lastName, userName, status, role names, gorup names)
            const searchString = `${user.firstName}${user.lastName}${user.userName}${user.userCode}`;
            // check if string contains the searched plainText
            if (text.length > 0 && searchString.toLowerCase().includes(text.toLowerCase())) {
                found = true;
            }
        }
        if (!found) {
            return false;
        }
    }

    // Groups
    if (userGroups.length && !userGroups.every((groupId) => user.groups.includes(groupId))) return false;

    // Roles
    if (userRoles.length && !userRoles.every((roleId) => user.roles.includes(roleId))) return false;

    return true;
};
