import { greyPalette, secondaryPalette, theme } from "@aos/react-components";
import {
    ActionBlockSizeEnum,
    ActionOutputLink,
    ElementConnectorSourceEnum,
    IProcessActionDbModel,
    getHeightIncrementToAlignGrid,
    getPositionAlignedGrid,
} from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";

import RectRounded from "../../../../../../components/core/Svg/RectRounded";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import ActionIcon from "../../ActionSelector/ActionIcon";
import { actionList } from "../../ActionSelector/ActionSelector";

const INPUT_OUTPUT_SHADOW_RADIUS = 16;

const useStyles = makeStyles({
    actionIcon: {
        color: theme.palette.common.white,
    },
    headerText: {
        pointerEvents: "none",
    },
    iconButton: {
        cursor: "pointer",
        height: "28px",
        width: "28px",
    },
    inputOutput: {
        color: theme.palette.common.white,
        fill: theme.palette.common.white,
    },
    moreVerIcon: {
        color: "white",
        position: "fixed",
    },
    userDescription: {
        fontSize: "16px",
        textAlign: "center",
    },
    userDescriptionContainer: {
        alignContent: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        margin: "0px 30px 0px 30px",
    },
    userDescriptionForeignObject: {
        pointerEvents: "none",
    },
});

interface IOwnProps {
    // Own Props
    actionProps: IProcessActionDbModel;
    disabled?: boolean;
    hideEditButton?: boolean;
    onBlockClick?: () => void;
    onBlockDoubleClick?: () => void;
    onBlockMouseDown?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void;
    onBlockMouseUp?: () => void;
    onInputOutputMouseDown?: (type: ElementConnectorSourceEnum, nodeIndex: number, event: React.MouseEvent<SVGElement, MouseEvent>) => void;
    onInputOutputMouseUp?: (type: ElementConnectorSourceEnum, nodeIndex: number) => void;
    onOpenActionMenu?: (actionElement: HTMLElement | SVGSVGElement) => void;
    selected?: boolean;
}

export default function ActionBlock(props: IOwnProps): JSX.Element {
    const {
        actionProps,
        disabled,
        hideEditButton,
        onBlockClick,
        onBlockDoubleClick,
        onBlockMouseDown,
        onBlockMouseUp,
        onInputOutputMouseDown,
        onInputOutputMouseUp,
        onOpenActionMenu,
        selected,
    } = props;
    const classes = useStyles();
    const translate = useTranslate();

    const multiplier = Math.max(actionProps.inputs.length, actionProps.outputs.length);
    const totalHeight = ActionBlockSizeEnum.DEFAULT_HEIGHT + multiplier * getHeightIncrementToAlignGrid(multiplier);
    const nodeInputHeight = (totalHeight - ActionBlockSizeEnum.HEADER_HEIGHT) / (1 + actionProps.inputs.length);
    const nodeOuputHeight = (totalHeight - ActionBlockSizeEnum.HEADER_HEIGHT) / (1 + actionProps.outputs.length);
    const nodeReturnHeight = totalHeight - ActionBlockSizeEnum.HEADER_HEIGHT + ActionBlockSizeEnum.RETURN_OFFSET;
    const mainColor = actionList[actionProps.type] ? actionList[actionProps.type].color + "AA" : greyPalette[50];
    const headerColor = actionList[actionProps.type] ? actionList[actionProps.type].color : greyPalette[50];
    const label = actionList[actionProps.type] ? translate(actionList[actionProps.type].label) : "N/A";

    const hasReturn = actionProps.type === "core-loop";

    /**
     * Handles click on Block
     */
    const handleBlockClick = (e: React.MouseEvent<SVGPathElement, MouseEvent>): void => {
        if (disabled) {
            return;
        }

        e.stopPropagation();
        e.preventDefault();

        if (onBlockClick) {
            onBlockClick();
        }
    };

    /**
     * Handles double click on block
     */
    const handleBlockDoubleClick = (e: React.MouseEvent<SVGPathElement, MouseEvent>): void => {
        //Disabled validation has been removed du to AOS-1353

        e.stopPropagation();
        e.preventDefault();

        onBlockDoubleClick?.();
    };

    /**
     * Handles mouse down on block
     */
    const handleBlockMouseDown = (e: React.MouseEvent<SVGPathElement, MouseEvent>): void => {
        if (disabled) {
            return;
        }

        e.stopPropagation();
        e.preventDefault();
        e.persist();

        if (onBlockMouseDown) {
            onBlockMouseDown(e);
        }
    };

    /**
     * Handles mouse up on block
     */
    const handleBlockMouseUp = (e: React.MouseEvent<SVGPathElement, MouseEvent>): void => {
        if (disabled) {
            return;
        }

        e.preventDefault();
        e.persist();

        if (onBlockMouseUp) {
            onBlockMouseUp();
        }
    };

    /**
     * Handles mouse enter on action link handle
     */
    const onLinkHandleMouseEnter = (e: React.MouseEvent<SVGUseElement | SVGCircleElement>): void => {
        if (!disabled) {
            e.currentTarget.style.color = theme.palette.grey[500];
        }
    };

    /**
     * Handles link handle mouse leave
     */
    const onLinkHandleMouseLeave = (e: React.MouseEvent<SVGUseElement | SVGCircleElement>): void => {
        if (!disabled) {
            e.currentTarget.style.color = theme.palette.common.white;
        }
    };

    /**
     * Handles mouse down on an action input or output
     *
     * @param {string} source - type of node (input or output)
     * @param {number} nodeIndex - Id of the node on which the mouse event is triggered
     */
    const handleInputOutputMouseDown =
        (source: ElementConnectorSourceEnum, nodeIndex: number): ((e: React.MouseEvent<SVGElement, MouseEvent>) => void) =>
        (e: React.MouseEvent<SVGElement, MouseEvent>): void => {
            if (disabled) {
                return;
            }

            e.stopPropagation();
            e.preventDefault();
            e.persist();

            if (onInputOutputMouseDown) {
                onInputOutputMouseDown(source, nodeIndex, e);
            }
        };

    /**
     * Handles mouse up on an action input or output
     *
     * @param {string} source - type of node (input or output)
     * @param {number} nodeIndex - Id of the node on which the mouse event is triggered
     */
    const handleInputOutputMouseUp =
        (source: ElementConnectorSourceEnum, nodeIndex: number): ((e: React.MouseEvent<SVGElement, MouseEvent>) => void) =>
        (e: React.MouseEvent<SVGElement, MouseEvent>): void => {
            if (disabled) {
                return;
            }

            e.preventDefault();
            e.preventDefault();

            if (onInputOutputMouseUp) {
                onInputOutputMouseUp(source, nodeIndex);
            }
        };

    /**
     * Opens action menu
     */
    const onActionMenu = (e: React.MouseEvent<HTMLElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        if (onOpenActionMenu) {
            onOpenActionMenu(e.currentTarget);
        }
    };

    return (
        <g
            id={"actionBlockId-" + actionProps.type}
            transform={`translate(${actionProps.posX},${actionProps.posY})`}
            style={{ cursor: disabled ? "default" : "grab" }}
        >
            {/* DROP SHADOW */}
            <RectRounded
                width={ActionBlockSizeEnum.DEFAULT_WIDTH}
                height={totalHeight}
                onDoubleClick={handleBlockDoubleClick}
                onMouseDown={handleBlockMouseDown}
                onMouseUp={handleBlockMouseUp}
                filter="url(#f1)"
                x={ActionBlockSizeEnum.SHADOW_OFFSET}
                y={ActionBlockSizeEnum.SHADOW_OFFSET}
                r1={ActionBlockSizeEnum.CORNER_RADIUS}
                r2={ActionBlockSizeEnum.CORNER_RADIUS}
                r3={ActionBlockSizeEnum.CORNER_RADIUS}
                r4={ActionBlockSizeEnum.CORNER_RADIUS}
            />

            {/* MAIN BOX */}
            <RectRounded
                width={ActionBlockSizeEnum.DEFAULT_WIDTH}
                height={totalHeight}
                style={{ fill: mainColor }}
                x={ActionBlockSizeEnum.SHADOW_OFFSET}
                y={ActionBlockSizeEnum.SHADOW_OFFSET}
                onDoubleClick={handleBlockDoubleClick}
                onMouseDown={handleBlockMouseDown}
                onMouseUp={handleBlockMouseUp}
                onClick={handleBlockClick}
                r1={ActionBlockSizeEnum.CORNER_RADIUS}
                r2={ActionBlockSizeEnum.CORNER_RADIUS}
                r3={ActionBlockSizeEnum.CORNER_RADIUS}
                r4={ActionBlockSizeEnum.CORNER_RADIUS}
                id="actionBlockMainBoxId"
            />

            {/* HEADER BOX */}
            <RectRounded
                width={ActionBlockSizeEnum.DEFAULT_WIDTH}
                height={ActionBlockSizeEnum.HEADER_HEIGHT}
                style={{ fill: headerColor }}
                x={ActionBlockSizeEnum.SHADOW_OFFSET}
                y={ActionBlockSizeEnum.SHADOW_OFFSET}
                onDoubleClick={handleBlockDoubleClick}
                onMouseDown={handleBlockMouseDown}
                onMouseUp={handleBlockMouseUp}
                r1={ActionBlockSizeEnum.CORNER_RADIUS}
                r2={ActionBlockSizeEnum.CORNER_RADIUS}
                id="actionBlockHeaderBoxId"
            />

            <g transform="translate(23 20)">
                <ActionIcon x="0" y="0" width="28" height="28" className={classes.actionIcon} type={actionProps.type} />
            </g>

            {/* HEADER TEXT */}
            <text
                textAnchor="middle"
                x={ActionBlockSizeEnum.DEFAULT_WIDTH / 2 + ActionBlockSizeEnum.SHADOW_OFFSET}
                y={ActionBlockSizeEnum.HEADER_HEIGHT}
                fontSize="16px"
                fill={theme.palette.common.white}
                className={classes.headerText}
            >
                {label}
            </text>

            {/* MENU BUTTON */}
            {!hideEditButton && (
                <foreignObject x="185" y="20" width="28" height="28">
                    <IconButton className={classes.iconButton} onClick={onActionMenu} id="actionMenuButtonId">
                        <MoreVertIcon className={classes.moreVerIcon} />
                    </IconButton>
                </foreignObject>
            )}

            {/* INPUTS */}
            {actionProps.inputs &&
                actionProps.inputs.map((node: ActionOutputLink, index: number): JSX.Element => {
                    return (
                        <g
                            key={node.id}
                            transform={`translate(${ActionBlockSizeEnum.INPUT_OFFSET},${getPositionAlignedGrid(
                                ActionBlockSizeEnum.HEADER_HEIGHT + ActionBlockSizeEnum.SHADOW_OFFSET + nodeInputHeight * (index + 1)
                            )})`}
                        >
                            {/* INPUT SHADOW */}
                            <circle
                                fill="transparent"
                                onMouseDown={handleInputOutputMouseDown(ElementConnectorSourceEnum.INPUT, index)}
                                onMouseUp={handleInputOutputMouseUp(ElementConnectorSourceEnum.INPUT, index)}
                                r={INPUT_OUTPUT_SHADOW_RADIUS}
                                style={{
                                    cursor: disabled ? "default" : "crosshair",
                                }}
                            />
                            {/* INPUT */}
                            <use
                                className={classes.inputOutput}
                                href="#actionNode"
                                id="actionBlockInputId"
                                onMouseEnter={onLinkHandleMouseEnter}
                                onMouseLeave={onLinkHandleMouseLeave}
                                onMouseDown={handleInputOutputMouseDown(ElementConnectorSourceEnum.INPUT, index)}
                                onMouseUp={handleInputOutputMouseUp(ElementConnectorSourceEnum.INPUT, index)}
                                style={{
                                    cursor: disabled ? "default" : "crosshair",
                                }}
                            />
                        </g>
                    );
                })}

            {/* RETURN INPUT */}
            {hasReturn && ( // Hum, not perfect, ok for now
                <g
                    transform={`translate(${ActionBlockSizeEnum.INPUT_OFFSET},${getPositionAlignedGrid(
                        ActionBlockSizeEnum.HEADER_HEIGHT + ActionBlockSizeEnum.SHADOW_OFFSET + nodeReturnHeight
                    )})`}
                >
                    <g transform={"translate(8,-10)"}>
                        <path d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z"></path>
                    </g>
                    {/* RETURN SHADOW */}
                    <circle
                        fill="transparent"
                        onMouseDown={handleInputOutputMouseDown(ElementConnectorSourceEnum.RETURN, 0)}
                        onMouseUp={handleInputOutputMouseUp(ElementConnectorSourceEnum.RETURN, 0)}
                        r={INPUT_OUTPUT_SHADOW_RADIUS}
                        style={{
                            cursor: disabled ? "default" : "crosshair",
                        }}
                    />
                    {/* RETURN */}
                    <use
                        className={classes.inputOutput}
                        href="#actionNode"
                        id="actionBlockReturnId"
                        onMouseEnter={onLinkHandleMouseEnter}
                        onMouseLeave={onLinkHandleMouseLeave}
                        onMouseDown={handleInputOutputMouseDown(ElementConnectorSourceEnum.RETURN, 0)}
                        onMouseUp={handleInputOutputMouseUp(ElementConnectorSourceEnum.RETURN, 0)}
                        style={{
                            cursor: disabled ? "default" : "crosshair",
                        }}
                    />
                </g>
            )}
            {/* OUTPUTS */}
            {actionProps.outputs &&
                actionProps.outputs.map((node: ActionOutputLink, index: number): JSX.Element => {
                    return (
                        <g
                            key={node.id}
                            transform={`translate(${ActionBlockSizeEnum.OUTPUT_OFFSET},${getPositionAlignedGrid(
                                ActionBlockSizeEnum.HEADER_HEIGHT + ActionBlockSizeEnum.SHADOW_OFFSET + nodeOuputHeight * (index + 1)
                            )})`}
                        >
                            {/* OUTPUT SHADOW */}
                            <circle
                                fill="transparent"
                                onMouseDown={handleInputOutputMouseDown(ElementConnectorSourceEnum.OUTPUT, index)}
                                onMouseUp={handleInputOutputMouseUp(ElementConnectorSourceEnum.OUTPUT, index)}
                                r={INPUT_OUTPUT_SHADOW_RADIUS}
                                style={{
                                    cursor: disabled ? "default" : "crosshair",
                                }}
                            />
                            {/* OUTPUT */}
                            <use
                                id="actionBlockOutputId"
                                className={classes.inputOutput}
                                style={{
                                    cursor: disabled ? "default" : "crosshair",
                                }}
                                href="#actionNode"
                                onMouseEnter={onLinkHandleMouseEnter}
                                onMouseLeave={onLinkHandleMouseLeave}
                                onMouseDown={handleInputOutputMouseDown(ElementConnectorSourceEnum.OUTPUT, index)}
                                onMouseUp={handleInputOutputMouseUp(ElementConnectorSourceEnum.OUTPUT, index)}
                            />
                        </g>
                    );
                })}

            {/* USER DESCRIPTION */}
            <foreignObject
                x={ActionBlockSizeEnum.SHADOW_OFFSET}
                y={ActionBlockSizeEnum.USER_DESCRIPTION_OFFSET}
                width={ActionBlockSizeEnum.DEFAULT_WIDTH}
                height={totalHeight - ActionBlockSizeEnum.HEADER_HEIGHT}
                className={classes.userDescriptionForeignObject}
            >
                <div xmlns="http://www.w3.org/1999/xhtml" className={classes.userDescriptionContainer}>
                    <span className={classes.userDescription} id="actionBlockTitleId">
                        {actionProps.label}
                    </span>
                </div>
            </foreignObject>

            {/* BORDER WHEN SELECTED */}
            {selected && (
                <rect
                    cursor={disabled ? "default" : "all-scroll"}
                    fill="none"
                    height={totalHeight}
                    stroke={secondaryPalette[500]}
                    strokeWidth="2"
                    transform={`translate(${ActionBlockSizeEnum.SHADOW_OFFSET},${ActionBlockSizeEnum.SHADOW_OFFSET})`}
                    width={ActionBlockSizeEnum.DEFAULT_WIDTH}
                />
            )}
        </g>
    );
}
