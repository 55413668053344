import { IOperatorJobProcessInfo, IReworkUiModel } from "@kortex/aos-common";
import { theme } from "@aos/react-components";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, makeStyles, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { IReduxAction } from "../../../../../redux/IReduxAction";
import { reworkUpdate } from "../../../../../redux/rework-manager/rework-thunks";
import { useTranslate } from "../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    root: {},
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
    },
    mainIcon: {
        fontSize: "40px",
        marginRight: "20px",
        color: theme.palette.primary[500],
    },
    runAgainCheck: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 30px",
    },
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
    },
    buttonIcons: {
        marginRight: "10px",
    },
    processTitle: {
        color: theme.palette.primary[500],
    },
    circularProgress: {
        margin: "3px",
    },
});

interface IOwnProps {
    // Own Props
    open: boolean;
    jobProcess?: IOperatorJobProcessInfo;
    runAgain: boolean;
    reworkId?: number;

    onRunAgain?: (runAgain: boolean) => void;
    onClose?: () => void;

    // mapDispatchToProps
    updateRework: (rework: IReworkUiModel) => Promise<void>;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, IReduxAction>): Pick<IOwnProps, "updateRework"> => ({
    updateRework: (rework: IReworkUiModel): Promise<void> => {
        return dispatch(reworkUpdate(rework));
    },
});

function SuccessfulDialog(props: IOwnProps): JSX.Element {
    const { open, jobProcess, runAgain, reworkId } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [runAgainCheckbox, setRunAgainCheckbox] = useState<boolean>(false);

    /**
     * handle the run again
     */
    const handleRunAgain = (): void => {
        if (props.onRunAgain) {
            props.onRunAgain(runAgainCheckbox);
        }
    };

    /**
     * handle the close
     */
    const handleClose = (): void => {
        if (props.onClose) {
            props.onClose();
        }
    };

    /**
     * Handles the 'Run Again" checkbox state changes
     */
    const handleRunAgainCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRunAgainCheckbox(event.target.checked);
    };

    /**
     * indicates if we can run again
     */
    const canRunAgain = (): boolean => {
        if (jobProcess && jobProcess.qtyStarted < jobProcess.qty && reworkId === 0) {
            if (jobProcess.qtyPassed === 0 && jobProcess.qty === 0) {
                return false;
            }
            if (jobProcess.qtyPassed >= jobProcess.qty) {
                return false;
            }

            return true;
        }
        return false;
    };

    return (
        <div className={classes.root} id="playerSuccessfulDialogId">
            <Dialog open={open} disableAutoFocus={true} fullWidth={true}>
                <DialogContent className={classes.dialogContent}>
                    <CheckCircleIcon className={classes.mainIcon} />
                    <div className={classes.dialogDescription}>
                        <Typography id="processSucceedMessageId" variant="h4">
                            {translate("player.processCompleted")}
                        </Typography>
                        <br />
                        {jobProcess && (
                            <Typography variant="subtitle2" className={classes.processTitle}>
                                {jobProcess.process.treeNodeProcess.label}
                            </Typography>
                        )}
                        {!runAgain && canRunAgain() && (
                            <div className={classes.runAgainCheck}>
                                <Checkbox value="checked" color="secondary" onChange={handleRunAgainCheck} id="runAgainCheckboxId" />{" "}
                                <Typography>{translate("player.runAgainText")}</Typography>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    {canRunAgain() && (
                        <Button
                            variant="contained"
                            color="inherit"
                            id="runAgainButtonId"
                            onClick={handleRunAgain}
                            className={classes.dialogButtons}
                        >
                            <Typography>{translate("player.runAgain")}</Typography>
                        </Button>
                    )}
                    <Button
                        id="proceedButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                        className={classes.dialogButtons}
                    >
                        <Typography>{translate("player.close")}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default connect(null, mapDispatchToProps)(SuccessfulDialog);
