import { upsertObjectFromArray } from "@kortex/aos-common";

import { ElectronicSignatureActions, ElectronicSignatureActionType, ElectronicSignatureState } from "./electronic-signature-types";

const initialState: ElectronicSignatureState = {
    electronicSignatures: [],
};

export function electronicSignatureReducer(
    state: ElectronicSignatureState = initialState,
    action: ElectronicSignatureActions
): ElectronicSignatureState {
    switch (action.type) {
        case ElectronicSignatureActionType.UPDATE_ELECTRONIC_SIGNATURES:
            return {
                ...state,
                electronicSignatures: [...action.value],
            };
        case ElectronicSignatureActionType.INSERT_ELECTRONIC_SIGNATURE:
            return {
                ...state,
                electronicSignatures: upsertObjectFromArray(state.electronicSignatures, action.value, function (electronicSignature) {
                    return electronicSignature.electronicSignatureId === this.electronicSignatureId;
                }),
            };
        case ElectronicSignatureActionType.UPDATE_ELECTRONIC_SIGNATURE:
            if (!action.value.length) {
                return state;
            }
            return {
                ...state,
                electronicSignatures: upsertObjectFromArray(state.electronicSignatures, action.value, function (electronicSignature) {
                    return electronicSignature.electronicSignatureId === this.electronicSignatureId;
                }),
            };
        default:
            return state;
    }
}
