var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function Blank(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("rect", { id: "icons-templates-blank", x: "2", y: "4", width: "20", height: "14" })));
}
