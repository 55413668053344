// Process Validation ------------------------------------------------------------
export var ProcessValidationProcessErrorKey;
(function (ProcessValidationProcessErrorKey) {
    ProcessValidationProcessErrorKey["INPUT_NOT_FOUND"] = "processEditor.validation.errorCode.process.inputNotFound";
    ProcessValidationProcessErrorKey["OUTPUT_NOT_FOUND"] = "processEditor.validation.errorCode.process.outputNotFound";
    ProcessValidationProcessErrorKey["OUTPUT_NOT_FOUND_FAILPATH"] = "processEditor.validation.errorCode.process.outputNotFoundFailPath";
})(ProcessValidationProcessErrorKey || (ProcessValidationProcessErrorKey = {}));
export var ProcessValidationProcessWarningKey;
(function (ProcessValidationProcessWarningKey) {
    ProcessValidationProcessWarningKey["SOME_WARNING"] = "processEditor.validation.errorCode.process.inputNotFound";
})(ProcessValidationProcessWarningKey || (ProcessValidationProcessWarningKey = {}));
// Process Action Validation -----------------------------------------------------
export var ProcessValidationProcessActionErrorKey;
(function (ProcessValidationProcessActionErrorKey) {
    ProcessValidationProcessActionErrorKey["EMPTY_FIELD"] = "processEditor.validation.errorCode.processAction.emptyField";
    ProcessValidationProcessActionErrorKey["INVALID_ACTION_ID"] = "processEditor.validation.errorCode.processAction.invalidActionId";
    ProcessValidationProcessActionErrorKey["INVALID_VARIABLE_IDENTIFIER"] = "processEditor.validation.errorCode.processAction.invalidVariable";
    ProcessValidationProcessActionErrorKey["MISSING_LOOP_RETURN_INPUT"] = "processEditor.validation.errorCode.processAction.missingLoopReturnInput";
    ProcessValidationProcessActionErrorKey["MISSING_OUTPUT_LINK"] = "processEditor.validation.errorCode.processAction.missingOutputLink";
    ProcessValidationProcessActionErrorKey["TYPE_MISMATCH"] = "processEditor.validation.errorCode.processAction.typeMismatch";
    ProcessValidationProcessActionErrorKey["VALUE_NOT_VALID"] = "processEditor.validation.errorCode.processAction.valueNotValid";
})(ProcessValidationProcessActionErrorKey || (ProcessValidationProcessActionErrorKey = {}));
export var ProcessValidationProcessActionWarningKey;
(function (ProcessValidationProcessActionWarningKey) {
    ProcessValidationProcessActionWarningKey["SOME_WARNING"] = "processEditor.validation.errorCode.processAction.emptyField";
})(ProcessValidationProcessActionWarningKey || (ProcessValidationProcessActionWarningKey = {}));
