import { util } from "@kortex/aos-api-server";
import { MessageLevelEnum } from "../../../../enum/MessageLevelEnum";
import { ExplanationKeyEnum, SuggestionKeyEnum, TitleKeyEnum } from "../locales";
export class AOSError extends util.errors.GenericError {
    constructor(description, originalError, argument) {
        super(description, originalError);
        this.argument = argument;
    }
    errorToJSON() {
        return {
            ...super.errorToJSON(),
            argument: this.argument,
        };
    }
    static reThrow(error, fnName) {
        throw this.concatPath(error, fnName);
    }
    static concatPath(error, fnName) {
        if (error instanceof AOSError) {
            error.description.path += "/" + fnName;
        }
        return error;
    }
}
AOSError.MessageLevelEnum = MessageLevelEnum;
AOSError.TitleKeyEnum = TitleKeyEnum;
AOSError.ExplanationKeyEnum = ExplanationKeyEnum;
AOSError.SuggestionKeyEnum = SuggestionKeyEnum;
