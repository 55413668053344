import { TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React from "react";

import { useTranslate } from "../../../../../../../../../../hooks/useTranslate";

function WorkInstructionsBomItemsTableHeader(): JSX.Element {
    const translate = useTranslate();

    return (
        <React.Fragment>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2">{translate("action.workInstructions.bom.itemPartNumber")}</Typography>
                    </TableCell>
                    <TableCell align="center" width="88px">
                        <Typography variant="body2">{translate("action.workInstructions.bom.quantityItemBom")}</Typography>
                    </TableCell>
                    <TableCell width="32px" />
                </TableRow>
            </TableHead>
        </React.Fragment>
    );
}

export default WorkInstructionsBomItemsTableHeader;
