/**
 * Gets a url parameter or all of them
 *
 * @param {string} k - search param
 */
export const getUrlParameter = (k: string): string => {
    const params: object = {};

    location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (s, k, v): string => {
        params[k] = v;
        return "";
    });

    return k ? params[k] : "";
};

export default getUrlParameter;
