import { KortexColorPicker, KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { EnumShapeTypes, IWorkInstructionsElementConfig, IWorkInstructionsShapeConfig } from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { MenuItem, makeStyles } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    row: {
        alignItems: "flex-end",
        display: "flex",
    },
    selectContainer: {
        marginRight: "32px",
    },
    select: {
        width: "120px",
    },
});

export interface IOwnProps {
    onCancel: () => void;
    onSave: (element: IWorkInstructionsElementConfig<IWorkInstructionsShapeConfig>) => void;
    open: boolean;
    shapeProps: IWorkInstructionsElementConfig<IWorkInstructionsShapeConfig>;
}

export default function WorkInstructionsShapeEditor(props: IOwnProps): JSX.Element {
    const { onCancel, onSave, open } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [shapeProps, setMarkerProps] = useState<IWorkInstructionsElementConfig<IWorkInstructionsShapeConfig>>(props.shapeProps);

    useStopKeybindPropagation(open);

    /**
     * Reassign shape props to local state whenever shape props are changed
     */
    useEffect((): void => {
        setMarkerProps(props.shapeProps);
    }, [props.shapeProps]);

    /**
     * Called when a marker property is changed
     *
     * @param {string} key - work instruction shape property
     * @returns {(event: React.ChangeEvent<HTMLSelectElement>) => undefined}
     */
    const handleChange =
        (key: keyof IWorkInstructionsShapeConfig): ((event: React.ChangeEvent<HTMLSelectElement>) => void) =>
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            setMarkerProps({
                ...shapeProps,
                extendedProps: {
                    ...shapeProps.extendedProps,
                    [key]: event.target.value,
                },
            });
        };

    /**
     * Called when marker's color is changed
     *
     * @param {string} key - work instruction shape property
     * @returns {(color: string) => undefined}
     */
    const handleColorChange =
        (key: keyof IWorkInstructionsShapeConfig): ((color: string) => void) =>
        (color: string): void => {
            setMarkerProps({
                ...shapeProps,
                extendedProps: {
                    ...shapeProps.extendedProps,
                    [key]: color,
                },
            });
        };

    /**
     * Called when dialog's Cancel button is clicked
     */
    const handleCancel = (): void => {
        onCancel();
    };

    /**
     * Called when dialog's Save button is clicked
     */
    const handleSave = (): void => {
        onSave(shapeProps);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            dialogProps={{
                fullWidth: true,
                maxWidth: "md",
            }}
            onCancel={handleCancel}
            onConfirm={handleSave}
            open={open}
            textLabels={{
                titleLabel: translate("action.workInstructions.shape.title"),
                cancelButtonLabel: translate("action.workInstructions.editDialog.cancel"),
                proceedButtonLabel: translate("action.workInstructions.editDialog.confirm"),
            }}
        >
            <div className={classes.row}>
                <KortexTextField
                    className={classes.selectContainer}
                    InputProps={{ className: classes.select }}
                    label={translate("action.workInstructions.shape.type")}
                    onChange={handleChange("type")}
                    TextFieldProps={{
                        select: true,
                    }}
                    value={shapeProps.extendedProps.type}
                >
                    <MenuItem value={EnumShapeTypes.ARROW}>{translate("action.workInstructions.shape.type.arrow")}</MenuItem>
                    <MenuItem value={EnumShapeTypes.CIRCLE}>{translate("action.workInstructions.shape.type.circle")}</MenuItem>
                    <MenuItem value={EnumShapeTypes.OCTAGON}>{translate("action.workInstructions.shape.type.octagon")}</MenuItem>
                    <MenuItem value={EnumShapeTypes.RECTANGLE}>{translate("action.workInstructions.shape.type.rectangle")}</MenuItem>
                    <MenuItem value={EnumShapeTypes.TRIANGLE}>{translate("action.workInstructions.shape.type.triangle")}</MenuItem>
                </KortexTextField>
                <div className={classes.selectContainer}>
                    <KortexColorPicker
                        color={shapeProps.extendedProps.color}
                        inputStyle={{ width: "110px" }}
                        onChange={handleColorChange("color")}
                        textLabels={{
                            inputLabel: translate("action.workInstructions.shape.color"),
                            opacityLabel: translate("kortexReactComponent.kortexColorPicker.opacity"),
                        }}
                        transparency={true}
                    />
                </div>
                <KortexTextField
                    className={classes.selectContainer}
                    InputProps={{ className: classes.select }}
                    label={translate("action.workInstructions.shape.border")}
                    onChange={handleChange("border")}
                    TextFieldProps={{
                        select: true,
                    }}
                    value={shapeProps.extendedProps.border}
                >
                    <MenuItem value={"0px"}>{translate("action.workInstructions.shape.border.none")}</MenuItem>
                    <MenuItem value={"1px"}>1px</MenuItem>
                    <MenuItem value={"2px"}>2px</MenuItem>
                    <MenuItem value={"3px"}>3px</MenuItem>
                    <MenuItem value={"4px"}>4px</MenuItem>
                    <MenuItem value={"8px"}>8px</MenuItem>
                    <MenuItem value={"12px"}>12px</MenuItem>
                    <MenuItem value={"18px"}>18px</MenuItem>
                    <MenuItem value={"24px"}>24px</MenuItem>
                </KortexTextField>
                <div className={classes.selectContainer}>
                    <KortexColorPicker
                        color={shapeProps.extendedProps.borderColor}
                        inputStyle={{ width: "110px" }}
                        onChange={handleColorChange("borderColor")}
                        textLabels={{
                            inputLabel: translate("action.workInstructions.shape.borderColor"),
                        }}
                    />
                </div>
            </div>
        </KortexDialogConfirmation>
    );
}
