import { interpolate } from "./interpolate";
import { isNullOrUndefined } from "./is-null-or-undefined";
import { isObject } from "./is-object";
export function deepInterpolatedClone(source, variables) {
    if (isNullOrUndefined(source)) {
        return source;
    }
    if (isObject(source)) {
        const obj = Array.isArray(source) ? [] : {};
        for (const key of Object.keys(source)) {
            const value = source[key];
            if (isObject(value)) {
                obj[key] = deepInterpolatedClone(value, variables);
            }
            else if (typeof value === "string") {
                let interpolatedValue = interpolate(value, variables);
                if (value !== interpolatedValue) {
                    interpolatedValue = interpolate.parse(interpolatedValue);
                }
                obj[key] = interpolatedValue;
            }
            else {
                obj[key] = value;
            }
        }
        return obj;
    }
    else {
        return source;
    }
}
