import {
    SetSettingOrganizationListAction,
    SettingOrganizationInsertedAction,
    SettingOrganizationUpdatedAction,
    SettingOrganizationActionType,
} from "./setting-organization-types";

/**
 * Returns an action
 *
 * @param {SetSettingOrganizationListAction} settings - value of the action
 */
export function setSettingOrganizationListAction(settings: SetSettingOrganizationListAction["value"]): SetSettingOrganizationListAction {
    return { type: SettingOrganizationActionType.SETTING_ORGANIZATION_FETCH_ALL, value: settings };
}

/**
 * Returns an action
 *
 * @param {SettingOrganizationInsertedAction} setting - value of the action
 */
export function settingOrganizationInsertedAction(setting: SettingOrganizationInsertedAction["value"]): SettingOrganizationInsertedAction {
    return { type: SettingOrganizationActionType.SETTING_ORGANIZATION_NOTIFICATION_INSERTED, value: setting };
}

/**
 * Returns an action
 *
 * @param {SettingOrganizationUpdatedAction} setting - value of the action
 */
export function settingOrganizationUpdatedAction(setting: SettingOrganizationUpdatedAction["value"]): SettingOrganizationUpdatedAction {
    return { type: SettingOrganizationActionType.SETTING_ORGANIZATION_NOTIFICATION_UPDATED, value: setting };
}
