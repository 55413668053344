import {
    IReworkGetInfoBeforeCreateRes,
    IReworkInfo,
    IReworkUiModel,
    IReworksFiltersOptions,
    IStepFailure,
    ReworkAllRes,
    ReworkDbModelKey,
    ReworkDeleteReq,
    UnwrapAOSPayload,
} from "@kortex/aos-common";

import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { reworkInsertedAction, reworkUpdatedAction, setReworkListAction } from "./rework-actions";

/**
 * Get all reworks
 */
export function reworkGetAll(
    options?: IStandardThunkOptions,
    limit?: number,
    offset?: number,
    filterOptions?: IReworksFiltersOptions
): StandardThunk<UnwrapAOSPayload<ReworkAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<ReworkAllRes>> {
        if (!skipLookup) {
            if (fetchedOnce.was(reworkGetAll)) {
                return { data: getState().rework.reworks };
            }
        }

        return api.services.rework
            .getAll({ withArchive: false, limit: limit, offset: offset, filterOptions })({ timeout: 60_000 /* 1 minute */ })
            .then((reworks) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(reworkGetAll);
                    if (offset === 0) {
                        dispatch(setReworkListAction([...reworks.data]));
                    } else {
                        dispatch(reworkUpdatedAction([...reworks.data]));
                    }
                }

                return reworks;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return { data: [] };
            });
    };
}

export function reworkUpdate(rework: APIPayload<"rework", "update">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.rework
                .update(rework)()
                .then((reworkUpdated) => {
                    dispatch(reworkUpdatedAction([reworkUpdated]));
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
    };
}

/**
 * Get all reworks with archive records
 */
export function reworkGetAllWithArchive(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<ReworkAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<ReworkAllRes>> {
        try {
            if (!skipLookup) {
                if (fetchedOnce.was(reworkGetAllWithArchive)) {
                    return { data: getState().rework.reworks };
                }
            }

            return api.services.rework
                .getAll({ withArchive: true })({ timeout: 300_000 /* 5 minutes */ })
                .then((reworks) => {
                    if (!skipDispatch) {
                        fetchedOnce.seal(reworkGetAllWithArchive);

                        dispatch(setReworkListAction([...reworks.data]));
                    }

                    return reworks;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            throw error;
        }
    };
}

/**
 * Get one rework
 */
export function reworkGetOne(rework: ReworkDbModelKey): StandardThunk<IReworkUiModel | undefined> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IReworkUiModel | undefined> => {
        try {
            const resp = await api.services.rework
                .getOne(rework)()
                .then((rework) => {
                    //dispatch(reworkUpdatedAction([rework]));
                    return rework;
                });
            return resp;
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return undefined;
        }
    };
}

/**
 * Insert one rework
 */
export function reworkInsert(failures: IStepFailure[], reworkInfo: IReworkInfo): StandardThunk<IReworkUiModel[]> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IReworkUiModel[]> => {
        try {
            const resp = await api.services.rework
                .insert({ failures, reworkInfo })()
                .then((reworks) => {
                    dispatch(reworkInsertedAction([...reworks]));
                    return [...reworks];
                });
            return resp;
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return [];
        }
    };
}

export function reworkDelete(rework: UnwrapAOSPayload<ReworkDeleteReq>): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.rework.delete(rework)();
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
    };
}

export function reworkGetInfoBeforeCreate(info: APIPayload<"rework", "getInfoBeforeCreate">): StandardThunk<IReworkGetInfoBeforeCreateRes> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<IReworkGetInfoBeforeCreateRes> => {
        try {
            return api.services.rework.getInfoBeforeCreate(info)();
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
            return {
                found: false,
                entities: undefined,
                originalJobRefId: "",
                originalJobId: 0,
            };
        }
    };
}
