export class DataStoreState {
    constructor() {
        this.result = [""];
    }
    toJSON() {
        return {
            result: this.result,
        };
    }
}
