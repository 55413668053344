import { IProcessUiModel } from "@kortex/aos-common";
import { KortexDialogConfirmation } from "@aos/react-components";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";

import ProcessUpdateVersion from "./ProcessUpdateVersion";

interface IOwnProps {
    opened: boolean;
    updateToProcess: IProcessUiModel;

    onClose: () => void;
    onConfirm: (jobProcessList: number[]) => void;
}

export default function ProcessUpdateVersionDialog(props: IOwnProps): JSX.Element {
    const { onClose, onConfirm, opened, updateToProcess } = props;

    const translate = useTranslate();
    const [jobProcessList, setJobProcessList] = useState<number[]>([]);

    /**
     * When user proceed, send to caller
     */
    const onProceed = (): void => {
        onConfirm(jobProcessList);
    };

    /**
     * Update Job Process List to send on ok
     */
    const onJobProcessListChanged = (jobProcesses: number[]): void => {
        setJobProcessList(jobProcesses);
    };

    return (
        <KortexDialogConfirmation
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: onClose,
            }}
            onCancel={onClose}
            onConfirm={onProceed}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.proceed"),
                titleLabel: translate("general.update") + ": " + updateToProcess?.version,
            }}
        >
            <ProcessUpdateVersion
                showEmptyMessage={true}
                treeNodeId={updateToProcess.treeNodeId}
                updateToProcess={updateToProcess}
                onUpdateJobProcessList={onJobProcessListChanged}
            />
        </KortexDialogConfirmation>
    );
}
