import { IReportDataRes, ReportTypeEnum, reportTypeListInfo } from "@kortex/aos-common";
import { useKeybind } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import "moment/locale/fr";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import {
    reportGetAuditTrail,
    reportGetDataStoreTable,
    reportGetDataStoreValue,
    reportGetElectronicSignature,
    reportGetEntity,
    reportGetProcessResult,
    reportGetProcessStepResult,
    reportGetRework,
    reportGetSummary,
    reportGetTraining,
} from "../../../redux/report-manager/report-thunks";
import { getExportURL } from "../../../utilitites/getExportURL";

import ReportingDataTable from "./ReportingDataTable";
import ReportingFilters, { DEFAULT_FILTERS, IReportingFilters } from "./ReportingFilters";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        height: "calc(100vh - 100px)",
        zIndex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "16px",
    },
    actionPane: {
        padding: "16px",
        display: "flex",
        flexDirection: "row",
    },
    filters: {
        flexGrow: 1,
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        marginRight: "10px",
        alignContent: "center",
    },
    button: {
        height: "20px",
    },
    buttonSpacer: {
        height: "10px",
    },
    downloadLink: {
        display: "none",
    },
    reportingDataTable: {
        overflow: "auto",
    },
});

function ReportingPage(): JSX.Element {
    /**
     * Component states definitions
     */
    const [reportingFilters, setReportingFilters] = useState(DEFAULT_FILTERS);
    const [refreshReport, setRefreshReport] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openTooMuchDataDialog, setOpenTooMuchDataDialog] = useState({ open: false, count: 0, returnCount: 0 });

    const classes = useStyles();
    const translate = useTranslate();
    const downloadLinkRef = useRef<HTMLAnchorElement | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [results, setResults] = useState<IReportDataRes[]>([]);

    const dispatch = useThunkDispatch();

    const currentPage = 0; // For now, let them fixed
    const rowsPerPage = 0;

    /**
     * Key down event - Enter
     * Refresh report
     */
    useKeybind("Enter", () => setRefreshReport(true));

    /**
     * Refresh report when refreshReport is changed to true
     */
    useEffect((): void => {
        if (refreshReport) {
            setRefreshReport(false);
            executeReport();
        }
    }, [refreshReport]);

    /**
     * Reset results when filters are changed
     */
    useEffect((): void => {
        setResults([]);
    }, [reportingFilters]);

    /**
     * Execute report based on current filters
     *
     * @param {boolean} isExport - Specify if the report should be in export mode
     */
    const executeReport = (isExport = false): void => {
        const {
            reportType,
            fromDate,
            toDate,
            trackingId,
            selectedDataStoreTableId,
            refJobId,
            showOnlyTestRun,
            showTestRun,
            electronicSignatureType,
            auditorId,
            processId,
            referenceCertificate,
            referenceCertification,
            trainerId,
            userId,
            groupData,
            showArchived,
            tag,
            trainingCertificateFromDate,
            trainingCertificateToDate,
            certificationFromDate,
            certificationToDate,
            readingCertificateFromDate,
            readingCertificateToDate,
        } = reportingFilters;

        setResults([]);
        setIsLoading(true);

        switch (reportType) {
            case ReportTypeEnum.PROCESS_SUMMARY:
                dispatch(
                    reportGetSummary({
                        fromDate: fromDate && fromDate.getTime(),
                        toDate: toDate && toDate.getTime(),
                        trackingId,
                        refJobId,
                        export: isExport,
                        skip: currentPage * rowsPerPage,
                        take: rowsPerPage,
                        showOnlyTestRun,
                        showTestRun,
                    })
                )
                    .then((results): void => {
                        handleReportRequestResult(isExport, [...results] || []);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                break;

            case ReportTypeEnum.PROCESS_DETAILS:
                dispatch(
                    reportGetProcessResult({
                        fromDate: fromDate && fromDate.getTime(),
                        toDate: toDate && toDate.getTime(),
                        trackingId,
                        refJobId,
                        export: isExport,
                        skip: currentPage * rowsPerPage,
                        take: rowsPerPage,
                        showOnlyTestRun,
                        showTestRun,
                    })
                )
                    .then((results): void => {
                        handleReportRequestResult(isExport, [...results] || []);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                break;

            case ReportTypeEnum.PROCESS_STEPS:
                dispatch(
                    reportGetProcessStepResult({
                        fromDate: fromDate && fromDate.getTime(),
                        toDate: toDate && toDate.getTime(),
                        trackingId,
                        refJobId,
                        export: isExport,
                        skip: currentPage * rowsPerPage,
                        take: rowsPerPage,
                        showOnlyTestRun,
                        showTestRun,
                    })
                )
                    .then((results): void => {
                        handleReportRequestResult(isExport, [...results] || []);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                break;

            case ReportTypeEnum.FAILURE_REPORT:
                dispatch(
                    reportGetRework({
                        fromDate: fromDate && fromDate.getTime(),
                        toDate: toDate && toDate.getTime(),
                        trackingId,
                        refJobId,
                        export: isExport,
                        skip: currentPage * rowsPerPage,
                        take: rowsPerPage,
                        showOnlyTestRun,
                        showTestRun,
                    })
                )
                    .then((results): void => {
                        handleReportRequestResult(isExport, [...results] || []);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                break;

            case ReportTypeEnum.DATA_STORE_VALUE:
                dispatch(
                    reportGetDataStoreValue({
                        fromDate: fromDate && fromDate.getTime(),
                        toDate: toDate && toDate.getTime(),
                        trackingId,
                        refJobId,
                        export: isExport,
                        skip: currentPage * rowsPerPage,
                        take: rowsPerPage,
                        showOnlyTestRun,
                        showTestRun,
                    })
                )
                    .then((results): void => {
                        handleReportRequestResult(isExport, [...results] || []);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                break;

            case ReportTypeEnum.DATA_STORE_TABLE:
                dispatch(
                    reportGetDataStoreTable({
                        fromDate: fromDate && fromDate.getTime(),
                        toDate: toDate && toDate.getTime(),
                        trackingId,
                        refJobId,
                        export: isExport,
                        tableId: selectedDataStoreTableId || 0,
                        skip: currentPage * rowsPerPage,
                        take: rowsPerPage,
                        showOnlyTestRun,
                        showTestRun,
                    })
                )
                    .then((results): void => {
                        handleReportRequestResult(isExport, [...results] || []);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                break;

            case ReportTypeEnum.AUDIT_TRAIL:
                dispatch(
                    reportGetAuditTrail({
                        fromDate: fromDate && fromDate.getTime(),
                        toDate: toDate && toDate.getTime(),
                        export: isExport,
                        skip: currentPage * rowsPerPage,
                        take: rowsPerPage,
                    })
                )
                    .then((results): void => {
                        handleReportRequestResult(isExport, [...results] || []);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                break;

            case ReportTypeEnum.ELECTRONIC_SIGNATURE:
                dispatch(
                    reportGetElectronicSignature({
                        fromDate: fromDate && fromDate.getTime(),
                        toDate: toDate && toDate.getTime(),
                        trackingId,
                        refJobId,
                        export: isExport,
                        skip: currentPage * rowsPerPage,
                        take: rowsPerPage,
                        showOnlyTestRun,
                        showTestRun,
                        electronicSignatureType,
                    })
                )
                    .then((results): void => {
                        handleReportRequestResult(isExport, [...results] || []);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                break;

            case ReportTypeEnum.ENTITY:
                dispatch(
                    reportGetEntity({
                        fromDate: fromDate && fromDate.getTime(),
                        toDate: toDate && toDate.getTime(),
                        trackingId,
                        // TODO: add field part number
                        export: isExport,
                        skip: currentPage * rowsPerPage,
                        take: rowsPerPage,
                        showOnlyTestRun,
                        showTestRun,
                    })
                )
                    .then((results): void => {
                        handleReportRequestResult(isExport, [...results] || []);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                break;

            case ReportTypeEnum.TRAINING:
                dispatch(
                    reportGetTraining({
                        fromDate: fromDate && fromDate.getTime(),
                        toDate: toDate && toDate.getTime(),
                        export: isExport,
                        skip: currentPage * rowsPerPage,
                        take: rowsPerPage,
                        userId: userId,
                        auditorId: auditorId,
                        processId: processId,
                        referenceCertificate: referenceCertificate,
                        referenceCertification: referenceCertification,
                        trainerId: trainerId,
                        groupData: groupData,
                        showArchived: showArchived,
                        tag: tag,
                        trainingCertificateFromDate: trainingCertificateFromDate && trainingCertificateFromDate.getTime(),
                        trainingCertificateToDate: trainingCertificateToDate && trainingCertificateToDate.getTime(),
                        certificationFromDate: certificationFromDate && certificationFromDate.getTime(),
                        certificationToDate: certificationToDate && certificationToDate.getTime(),
                        readingCertificateFromDate: readingCertificateFromDate && readingCertificateFromDate.getTime(),
                        readingCertificateToDate: readingCertificateToDate && readingCertificateToDate.getTime(),
                    })
                )
                    .then((results): void => {
                        handleReportRequestResult(isExport, [...results] || []);
                    })
                    .catch(() => {
                        setIsLoading(false);
                    });
                break;
            default:
                setIsLoading(false);
                break;
        }
    };

    /**
     * Handle the response of the report API call
     *
     * @param {boolean} isExport - Is export mode
     * @param {IReportDataRes[]} reportResults - Report result data
     */
    const handleReportRequestResult = (isExport: boolean, reportResults: IReportDataRes[]): void => {
        setIsLoading(false);

        if (isExport) {
            if (downloadLinkRef.current) {
                downloadLinkRef.current.href = `${getExportURL()}/download?filename=${reportResults[0].exportFilename}`;
                downloadLinkRef.current.click();
            }
        } else {
            let returnCount = 0;
            let count = 0;
            const tooMuchData = reportResults.find((result) => {
                if (result.returnCount !== result.count) {
                    count = result.count ?? 0;
                    returnCount = result.returnCount ?? 0;
                }
            });
            if (tooMuchData) {
                setOpenTooMuchDataDialog({ open: true, returnCount: returnCount, count: count });
            }
            setResults(reportResults);
        }
    };

    /**
     * Refresh reporting data based on filters value
     */
    const handleRefreshReportClick = (): void => {
        setRefreshReport(true);
    };

    /**
     * Handle report filter change event
     *
     * @param {IReportingFilters} updatedFilters - Report filters
     */
    const hangleFiltersChanged = (updatedFilters: IReportingFilters): void => {
        setResults([]);
        setReportingFilters(updatedFilters);
    };

    /**
     * Handle export button click event
     */
    const handleExportReport = (): void => {
        executeReport(true);
    };

    /**
     * Handle close of warning too much dialog button
     */
    const handleCloseWarningTooMuchDataDialog = (): void => {
        setOpenTooMuchDataDialog({ open: false, count: 0, returnCount: 0 });
    };

    return (
        <div className={classes.root} id="ReportsPageId">
            {/* ACTION BUTTONS */}
            <Paper className={classes.actionPane}>
                <div className={classes.buttons}>
                    <Button
                        id="reportGenerateButtonId"
                        className={classes.button}
                        disabled={isLoading}
                        variant="outlined"
                        color="primary"
                        onClick={handleRefreshReportClick}
                        size="small"
                    >
                        {translate("reporting.action.generate")}
                    </Button>
                    <div className={classes.buttonSpacer} />
                    <Button
                        id="reportExportButtonId"
                        className={classes.button}
                        disabled={isLoading}
                        variant="outlined"
                        color="primary"
                        onClick={handleExportReport}
                        size="small"
                    >
                        {translate("reporting.action.export")}
                    </Button>

                    {/* HIDDEN LINK TO TRIGGER EXPORT FILE DOWNLOAD */}
                    <a ref={downloadLinkRef} className={classes.downloadLink} target="_blank" />
                </div>
                <div className={classes.filters}>
                    <ReportingFilters onFiltersChanged={hangleFiltersChanged} />
                </div>
            </Paper>

            <div className={classes.reportingDataTable}>
                {results.map((result, index): JSX.Element => {
                    return (
                        <ReportingDataTable
                            tableBodyMaxHeight={results.length > 1 ? "485px" : undefined}
                            isLoading={isLoading}
                            results={result.results}
                            reportName={translate(
                                reportingFilters.reportType === ReportTypeEnum.TRAINING && reportingFilters.groupData
                                    ? "reporting.type.training"
                                    : reportTypeListInfo.find((typeInfo) => typeInfo.reportType === reportingFilters.reportType)
                                          ?.translateKeys[index]
                            )}
                            key={index}
                            index={index}
                        />
                    );
                })}
            </div>

            <Dialog open={openTooMuchDataDialog.open}>
                <DialogTitle>{translate("reporting.tooMuchRecordDialog")}</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {translate("reporting.tooMuchRecordDialog1")}
                        {openTooMuchDataDialog.count}
                        {translate("reporting.tooMuchRecordDialog2")}
                        {openTooMuchDataDialog.returnCount}
                        {translate("reporting.tooMuchRecordDialog3")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseWarningTooMuchDataDialog} color="primary">
                        {translate("general.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ReportingPage;
