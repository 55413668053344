import { decode } from "@msgpack/msgpack";
/**
 * Converts a given Uint8Array to object
 *
 * @param {Uint8Array} data - Uint8Array to parse
 * @returns {object}
 */
export function objectFromUTF8Array(data) {
    return decode(data);
}
