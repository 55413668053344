import {
    TaskAllRes,
    TaskDeleteReq,
    TaskDeleteRes,
    TaskInsertReq,
    TaskInsertRes,
    TaskUpdateReq,
    TaskUpdateRes,
    UnwrapAOSPayload,
} from "@kortex/aos-common";
import { ThunkDispatch } from "redux-thunk";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { IReduxAction } from "../IReduxAction";
import { IStandardThunkOptions } from "../app.types";
import { ConnectionState } from "../general-manager/general-types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { setTaskListAction, taskDeletedAction, taskInsertedAction, taskUpdatedAction } from "./task-actions";

// thunks
/**
 * Retrieves tasks
 */
export function taskGetAll(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<TaskAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (dispatch: StandardDispatch, getState: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<TaskAllRes>> {
        try {
            if (getState().general.uplink.state !== ConnectionState.READY) {
                return [];
            }
            if (!skipLookup) {
                if (fetchedOnce.was(taskGetAll)) {
                    return getState().task.tasks;
                }
            }

            return api.services.tasks
                .getAll(emptyObject)()
                .then((fetchedTasks) => {
                    if (!skipDispatch) {
                        fetchedOnce.seal(taskGetAll);
                        dispatch(setTaskListAction([...fetchedTasks]));
                    }

                    return fetchedTasks;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
        return [];
    };
}

export function taskInsert(tasksToInsert: UnwrapAOSPayload<TaskInsertReq>): StandardThunk<UnwrapAOSPayload<TaskInsertRes>> {
    return async (
        dispatch: ThunkDispatch<unknown, unknown, IReduxAction>,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TaskInsertRes>> => {
        try {
            return api.services.tasks
                .insert(tasksToInsert)()
                .then((insertedTasks) => {
                    dispatch(taskInsertedAction([...insertedTasks]));
                    return insertedTasks;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return [];
    };
}

export function taskUpdate(tasksToUpdate: UnwrapAOSPayload<TaskUpdateReq>): StandardThunk<UnwrapAOSPayload<TaskUpdateRes>> {
    return async (
        dispatch: ThunkDispatch<unknown, unknown, IReduxAction>,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TaskUpdateRes>> => {
        try {
            return api.services.tasks
                .update(tasksToUpdate)()
                .then((updatedTasks) => {
                    dispatch(taskUpdatedAction([...updatedTasks]));
                    return updatedTasks;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return [];
    };
}

export function taskDelete(tasksToDelete: UnwrapAOSPayload<TaskDeleteReq>): StandardThunk<UnwrapAOSPayload<TaskDeleteRes>> {
    return async (
        dispatch: ThunkDispatch<unknown, unknown, IReduxAction>,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TaskUpdateRes>> => {
        try {
            return api.services.tasks
                .delete(tasksToDelete)()
                .then((deletedTasks) => {
                    dispatch(taskDeletedAction([...deletedTasks]));
                    return deletedTasks;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return [];
    };
}
