import { secondaryPalette } from "@aos/react-components";
import {
    EnumElementType,
    IWorkInstructionsElementConfig,
    IWorkInstructionsFormConfig,
    IWorkInstructionsFormItemState,
    IWorkInstructionsImageConfig,
    IWorkInstructionsLineConfig,
    IWorkInstructionsMarkerConfig,
    IWorkInstructionsPDFConfig,
    IWorkInstructionsShapeConfig,
    IWorkInstructionsTextConfig,
    IWorkInstructionsVideoProps,
    TWorkInstructionsExtendedConfig,
    WorkInstructionsStepState,
} from "@kortex/aos-common";
import * as React from "react";

import WorkInstructionsForm from "../Form/WorkInstructionsForm";
import WorkInstructionsImage from "../Image/WorkInstructionsImage";
import WorkInstructionsLine from "../Line/WorkInstructionsLine";
import WorkInstructionsMarker from "../Marker/WorkInstructionsMarker";
import WorkInstructionsPDF from "../PDF/WorkInstructionsPDF";
import WorkInstructionsShape from "../Shape/WorkInstructionsShape";
import WorkInstructionsText from "../Text/WorkInstructionsText";
import WorkInstructionsVideo from "../Video/WorkInstructionsVideo";

const SELECTION_BOX_THICKNESS = "2px";

export interface IOwnProps {
    disabled?: boolean;
    elementProps: IWorkInstructionsElementConfig<TWorkInstructionsExtendedConfig>;
    onDoubleClick?: (event: React.MouseEvent<SVGGElement, MouseEvent>) => void;
    onMouseDown?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void;
    onMouseUp?: (event: React.MouseEvent<SVGElement, MouseEvent>) => void;
    selected?: boolean;
    elementState: WorkInstructionsStepState;
    onFormElementStateChanged?: (formItemState: IWorkInstructionsFormItemState) => void;
    onEnterPressed?: (id: string) => void;
    playMode?: boolean;
}

export default function WorkInstructionsElement(props: IOwnProps): JSX.Element {
    const {
        disabled,
        playMode,
        elementProps,
        elementState,
        onDoubleClick,
        onFormElementStateChanged,
        onMouseDown,
        onMouseUp,
        selected,
        onEnterPressed,
    } = props;

    const {
        _id,
        cropBottom,
        cropLeft,
        cropRight,
        cropTop,
        height,
        rotation,
        width,
        x,
        y,
        scale = 1, // defaulting scale to 1 ensures backward compatibility with scaleX and scaleY previous props
        type,
    } = elementProps;

    /**
     * Called when a doubleclick event is triggered on a WorkInstructions
     *
     * @param {React.MouseEvent<SVGGElement, MouseEvent>} event - mouse event data
     */
    const handleDoubleClick = (event: React.MouseEvent<SVGGElement, MouseEvent>): void => {
        if (playMode || !onDoubleClick || disabled) {
            return;
        }

        onDoubleClick(event);
    };

    /**
     * Called when user clicks on an element
     * Calls onMouseDown from props
     *
     * @param {React.MouseEvent<SVGElement, MouseEvent>} event - mouse event data
     */
    const handleMouseDown = (event: React.MouseEvent<SVGElement, MouseEvent>): void => {
        if (playMode || !onMouseDown || disabled) {
            return;
        }

        event.persist();
        onMouseDown(event);
    };

    /**
     * Called when a mouseup event is triggered on a WorkInstructions
     *
     * @param {React.MouseEvent<SVGElement, MouseEvent>} event - mouse event data
     */
    const handleMouseUp = (event: React.MouseEvent<SVGElement, MouseEvent>): void => {
        if (playMode || !onMouseUp || disabled) {
            return;
        }

        event.persist();
        onMouseUp(event);
    };

    /**
     * Renders a border if element is selected
     */
    const renderSelectedBorder = (): JSX.Element | undefined => {
        if (!selected || playMode || disabled) {
            return;
        }

        const finalHeight = (height - cropBottom - cropTop) * scale;
        const finalWidth = (width - cropRight - cropLeft) * scale;

        return (
            <rect
                cursor="all-scroll"
                fill="none"
                height={finalHeight}
                id="workInstructionsElementSelectionBox"
                stroke={secondaryPalette[500]}
                strokeWidth={SELECTION_BOX_THICKNESS}
                transform={`translate(${x},${y}) rotate(${rotation} ${finalWidth / 2} ${finalHeight / 2})`}
                width={finalWidth}
            />
        );
    };

    /**
     * Handles form elements state change
     *
     * @param {IWorkInstructionsFormItemState} formItemState - form element state
     */
    const handleStateChanged = (formItemState: IWorkInstructionsFormItemState): void => {
        if (onFormElementStateChanged) {
            onFormElementStateChanged(formItemState);
        }
    };

    /**
     * Render a Work Instruction element
     */
    const renderElement = (): JSX.Element | null => {
        switch (type) {
            case EnumElementType.IMAGE:
                return (
                    <WorkInstructionsImage
                        disabled={disabled}
                        id={_id}
                        playMode={playMode && !disabled}
                        imageProps={elementProps as IWorkInstructionsElementConfig<IWorkInstructionsImageConfig>}
                    />
                );
            case EnumElementType.TEXT:
                return (
                    <WorkInstructionsText
                        textState={elementState.textItemState}
                        textProps={elementProps as IWorkInstructionsElementConfig<IWorkInstructionsTextConfig>}
                    />
                );
            case EnumElementType.LINE:
                return (
                    <WorkInstructionsLine
                        lineProps={elementProps as IWorkInstructionsElementConfig<IWorkInstructionsLineConfig>}
                        disabled={disabled || playMode}
                    />
                );
            case EnumElementType.MARKER:
                return (
                    <WorkInstructionsMarker
                        markerState={elementState.markerItemState}
                        markerProps={elementProps as IWorkInstructionsElementConfig<IWorkInstructionsMarkerConfig>}
                        disabled={disabled || playMode}
                    />
                );
            case EnumElementType.PDF:
                return <WorkInstructionsPDF pdfProps={elementProps as IWorkInstructionsElementConfig<IWorkInstructionsPDFConfig>} />;
            case EnumElementType.SHAPE:
                return <WorkInstructionsShape shapeProps={elementProps as IWorkInstructionsElementConfig<IWorkInstructionsShapeConfig>} />;
            case EnumElementType.VIDEO:
                return <WorkInstructionsVideo videoProps={elementProps as IWorkInstructionsElementConfig<IWorkInstructionsVideoProps>} />;
            case EnumElementType.FORM:
                return (
                    <WorkInstructionsForm
                        disabled={!playMode || disabled}
                        elementProps={elementProps as IWorkInstructionsElementConfig<IWorkInstructionsFormConfig>}
                        formItemsStates={elementState.formItemState}
                        onEnterPressed={onEnterPressed}
                        onStateChanged={handleStateChanged}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <g
            cursor={playMode || disabled ? "default" : "all-scroll"}
            id="workInstructionsElementId"
            onDoubleClick={handleDoubleClick}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >
            {renderElement()}
            {renderSelectedBorder()}
        </g>
    );
}
