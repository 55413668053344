import { IOrgSettingDbModel } from "@kortex/aos-common";
import { useState } from "react";

import { EnumLocalStorageItem } from "../redux/EnumLocalStorageItem";

type localStorageTypes = string | IOrgSettingDbModel;

export function useLocalStorage(key: EnumLocalStorageItem.CLIENT_ID, initialValue: string): [string, (value: string) => void];
export function useLocalStorage(key: EnumLocalStorageItem.USER_NAME, initialValue: string): [string, (value: string) => void];
export function useLocalStorage(key: EnumLocalStorageItem.INSTANCE, initialValue: string): [string, (value: string) => void];
export function useLocalStorage(
    key: EnumLocalStorageItem.SETTINGS,
    initialValue: IOrgSettingDbModel
): [IOrgSettingDbModel, (value: IOrgSettingDbModel) => void];
export function useLocalStorage(
    key: EnumLocalStorageItem,
    initialValue: localStorageTypes
): [localStorageTypes, (value: localStorageTypes) => void] {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<localStorageTypes>((): localStorageTypes => {
        try {
            switch (key) {
                case EnumLocalStorageItem.USER_NAME:
                case EnumLocalStorageItem.INSTANCE:
                case EnumLocalStorageItem.CLIENT_ID:
                    if (!window.localStorage.getItem(key)) {
                        window.localStorage.setItem(key, `${initialValue}`);
                    }
                    break;
                case EnumLocalStorageItem.SETTINGS:
                    if (!window.localStorage.getItem(key)) {
                        window.localStorage.setItem(key, JSON.stringify(initialValue));
                    }
                    break;
                default:
                    console.error(`Programmer Error: undefined storage item: ${key}`);
                    throw `Programmer Error: undefined storage item: ${key}`;
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return window.localStorage.getItem(key);
        } catch (error) {
            throw new Error(`do things right buddy; ${error.message}`);
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: localStorageTypes | Function): void => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = typeof value === "function" ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            switch (key) {
                case EnumLocalStorageItem.INSTANCE:
                case EnumLocalStorageItem.CLIENT_ID:
                    window.localStorage.setItem(key, `${valueToStore}`);
                    break;
                case EnumLocalStorageItem.SETTINGS:
                    window.localStorage.setItem(key, JSON.stringify(valueToStore));
                    break;
            }
        } catch (error) {
            // A more advanced implementation would handle the error case
        }
    };

    return [storedValue, setValue];
}
