export const en = {
    KORTEX_SEQUENCER_ACTION_CORE_ACTION_ERROR: "An error occurred while running an action",
    KORTEX_SEQUENCER_ACTION_HOOK_ERROR: "An error occurred while running an action hook.",
    KORTEX_SEQUENCER_ACTION_HOOK_ON_FAILL_ERROR: "An error occurred while running an action's 'onFail' hook.",
    KORTEX_SEQUENCER_ACTION_INVALID_TYPE: "Action has an invalid type.",
    KORTEX_SEQUENCER_ACTION_PAUSE_NOT_DEFINED: "Tried to pause an action that cannot be paused.",
    KORTEX_SEQUENCER_ACTION_NON_REMOTE_ACTION_RECEIVING_REMOTE_EVENT: "A non-remote action received a remote event.",
    KORTEX_SEQUENCER_ACTION_RESUME_NOT_DEFINED: "Tried to resume an action that cannot be resumed.",
    KORTEX_SEQUENCER_ACTION_SET_ACTION_OUTPUT_VARIABLE_MANAGER_ERROR: "An error occurred while assigning the action output to a variable.",
    KORTEX_SEQUENCER_ACTION_SET_STATE_NOT_DEFINED: "Tried to update the state of an action that does not allow it.",
    KORTEX_SEQUENCER_ACTION_STOP: "Action has been stopped.",
    KORTEX_SEQUENCER_ACTION_GROUP_HOOK_ACTION_ERROR: "An error occurred while running an action hook.",
    KORTEX_SEQUENCER_ACTION_GROUP_HOOK_ON_FAILL_ERROR: "An error occurred while running an action group's 'onFail' hook.",
    KORTEX_SEQUENCER_ACTION_GROUP_FLOW_ERROR_CLIENT_UPDATE_NO_WAITING_ACTION: "Tried to play next action when current action is not waiting.",
    KORTEX_SEQUENCER_ACTION_GROUP_FLOW_ERROR_CLIENT_UPDATE_NOT_FAILED: "Tried to retry an action group that is not failed.",
    KORTEX_SEQUENCER_ACTION_GROUP_FLOW_ERROR_CLIENT_UPDATE_RETRY_CURRENT_ACTION_NOT_FAILED: "Tried to retry an action that is not failed.",
    KORTEX_SEQUENCER_ACTION_GROUP_NO_ACTION: "Action group has no action.",
    KORTEX_SEQUENCER_ACTION_GROUP_STOP: "Action group has been stopped.",
    KORTEX_SEQUENCER_ACTION_GROUP_UNDEFINED_SUB_PROGRAM: "Could not find specified sub program.",
    KORTEX_SEQUENCER_CORE_CONDITION_PLAY_ALREADY_PLAYING: "Action is already playing.",
    KORTEX_SEQUENCER_CORE_CONDITION_PLAY_FAILS_ON_ELSE: "Condition is configured to fail at the 'else' statement.",
    KORTEX_SEQUENCER_CORE_CONDITION_PLAY_INVALID_CONDITION: "Condition is invalid.",
    KORTEX_SEQUENCER_CORE_CONDITION_PLAY_VARIABLE_MANAGER_ERROR: "An error occurred in the variable manager.",
    KORTEX_SEQUENCER_CORE_CONDITION_STOP: "Action has been stopped.",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_INVALID_FIRST_STATEMENT: "Condition's first statement is not an 'if'.",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_INVALID_LOGICAL_OPERATOR: "Condition's logical operator is invalid.",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_NO_STATEMENT: "Condition has no statement.",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_TOO_MANY_IF_STATEMENTS: "Condition must only have one 'if' statement.",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_UNDEFINED_LEFT_OPERAND_VARIABLE_IDENTIFIER: "Condition's variable identifier is undefined (left operand).",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_UNDEFINED_RIGHT_OPERAND_VARIABLE_IDENTIFIER: "Condition's variable identifier is undefined (right operand).",
    KORTEX_SEQUENCER_CORE_LOOP_PLAY_ALREADY_PLAYING: "Action is already playing.",
    KORTEX_SEQUENCER_CORE_LOOP_PLAY_INCREMENTED_VARIABLE_NOT_NUMBER: "Incrementer's variable type is not 'number'.",
    KORTEX_SEQUENCER_CORE_LOOP_PLAY_INVALID_CONDITION: "Loop's condition is invalid.",
    KORTEX_SEQUENCER_CORE_LOOP_PLAY_VARIABLE_MANAGER_ERROR: "An error occurred in the variable manager.",
    KORTEX_SEQUENCER_CORE_LOOP_STOP: "Action has been stopped.",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_INVALID_INCREMENTER_INCREMENT: "Loop's increment value is invalid.",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_INVALID_INCREMENTER_INITIAL_VALUE: "The initial value of the loop incrementer is invalid.",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_INVALID_LOGICAL_OPERATOR: "The condition of the loop has an invalid logical operator.",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_UNDEFINED_CONDITIONS: "Loop has an invalid condition.",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_UNDEFINED_LEFT_OPERAND_VARIABLE_IDENTIFIER: "Loop's condition has an undefined variable identifier (left operand).",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_UNDEFINED_RIGHT_OPERAND_VARIABLE_IDENTIFIER: "Loop's condition has an undefined variable identifier (right operand).",
    SEQUENCER_ACTION_CORE_MESSAGE_VALIDATE_INVALID_MESSAGE: "Message is invalid.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_ALREADY_PAUSED: "Action is already paused.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_ALREADY_STARTED: "Remote action is already started.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_FAILED: "Remote action failed.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_NOT_PAUSED: "Remote action is not paused.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_NOT_STARTED: "Remote action is not started.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_CLIENT_UPDATE_START_NOT_REQUESTED_BY_ACTION: "Remote action did not request to start",
    KORTEX_SEQUENCER_CORE_REMOTE_CLIENT_UPDATE_ERROR: "An error occurred while communicating with the remote action.",
    KORTEX_SEQUENCER_CORE_REMOTE_PAUSE_ERROR: "An error occurred while pausing the remote action.",
    KORTEX_SEQUENCER_CORE_REMOTE_PAUSE_CONFIRMATION_TIMEOUT_ERROR: "Action's pause confirmation timeout has expired.",
    KORTEX_SEQUENCER_CORE_REMOTE_PLAY_ALREADY_PLAYING: "Action is already playing.",
    KORTEX_SEQUENCER_CORE_REMOTE_PLAY_ERROR: "An error occurred while starting the remote action.",
    KORTEX_SEQUENCER_CORE_REMOTE_PLAY_COMPLETION_TIMEOUT_ERROR: "Action's completion timeout has expired.",
    KORTEX_SEQUENCER_CORE_REMOTE_PLAY_CONFIRMATION_TIMEOUT_ERROR: "Action's start confirmation timeout has expired.",
    KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHOD_PLAY_UNDEFINED: "Remote action's 'play' method is undefined.",
    KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHOD_STOP_UNDEFINED: "Remote action's 'stop' method is undefined.",
    KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHOD_UNDEFINED: "Remote action has an undefined method.",
    KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHODS_UNDEFINED: "Remote action's methods are undefined.",
    KORTEX_SEQUENCER_CORE_REMOTE_RESUME_ERROR: "An error occurred while resuming the remote action.",
    KORTEX_SEQUENCER_CORE_REMOTE_RESUME_CONFIRMATION_TIMEOUT_ERROR: "Action resume confirmation timeout has expired.",
    KORTEX_SEQUENCER_CORE_REMOTE_SERVICE_NOT_READY: "Remote service is not ready.",
    KORTEX_SEQUENCER_CORE_REMOTE_START_ACTION_ERROR: "An error occurred while starting the remote action.",
    KORTEX_SEQUENCER_CORE_REMOTE_STOP: "Action has been stopped.",
    KORTEX_SEQUENCER_CORE_REMOTE_STOP_ERROR: "An error occurred while stopping the remote action.",
    KORTEX_SEQUENCER_CORE_REMOTE_STOP_CONFIRMATION_TIMEOUT_ERROR: "Action's stop confirmation timeout has expired.",
    KORTEX_SEQUENCER_CORE_SET_PLAY_ALREADY_PLAYING: "Action is already playing.",
    KORTEX_SEQUENCER_CORE_SET_PLAY_INVALID_VALUE_TYPE: "Variable's value type is invalid.",
    KORTEX_SEQUENCER_CORE_SET_PLAY_VARIABLE_MANAGER_SET_VARIABLE_ERROR: "An error occurred while setting the variable into the variable manager.",
    KORTEX_SEQUENCER_CORE_SET_STOP: "Action has been stopped.",
    KORTEX_SEQUENCER_CORE_SET_INVALID_VALUE_TYPE: "Variable's type is invalid.",
    KORTEX_SEQUENCER_CORE_SET_UNDEFINED_VARIABLE_IDENTIFIER: "Variable's identifier is undefined.",
    KORTEX_SEQUENCER_CORE_SUB_PROGRAM_PLAY_INPUT_UNDEFINED: "Sub program's input is undefined.",
    KORTEX_SEQUENCER_CORE_SUB_PROGRAM_PLAY_OUTPUT_UNDEFINED: "Sub program's output is undefined.",
    KORTEX_SEQUENCER_CORE_SUB_PROGRAM_STOP: "Action has been stopped.",
    KORTEX_SEQUENCER_CORE_SUB_PROGRAM_VALIDATE_INPUT_INVALID_PARENT_VARIABLE_IDENTIFIER: "Sub program input: variable identifier is undefined.",
    KORTEX_SEQUENCER_CORE_SUB_PROGRAM_VALIDATE_OUTPUT_INVALID_PARENT_VARIABLE_IDENTIFIER: "Sub program output: variable identifier is undefined.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_PLAY_ALREADY_PLAYING: "Action is already playing.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_PLAY_INVALID_CONDITION: "Condition is invalid.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_PLAY_VARIABLE_MANAGER_ERROR: "An error occurred in the variable manager.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_STOP: "Action has been stopped.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_INVALID_LOGICAL_OPERATOR: "Condition's logical operator is invalid.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_UNDEFINED_CONDITIONS: "Action requires a condition.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_UNDEFINED_LEFT_OPERAND_VARIABLE_IDENTIFIER: "Condition's variable identifier is undefined (left operand).",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_UNDEFINED_RIGHT_OPERAND_VARIABLE_IDENTIFIER: "Condition's variable identifier is undefined (right operand).",
    KORTEX_SEQUENCER_CORE_WAIT_TIME_PLAY_ALREADY_PLAYING: "Action is already playing.",
    KORTEX_SEQUENCER_CORE_WAIT_TIME_STOP: "Action has been stopped.",
    KORTEX_SEQUENCER_CORE_WAIT_TIME_VALIDATE_INVALID_MS: "The waiting time is invalid.",
    KORTEX_SEQUENCER_SEQUENCER_ACTION_GROUP_ALREADY_ADDED: "Action group with the same ID already exists.",
    KORTEX_SEQUENCER_SEQUENCER_BRANCH_ACTION_GROUP_NOT_FOUND: "Could not find action group with specified ID.",
    KORTEX_SEQUENCER_SEQUENCER_CURRENT_ACTION_GROUP_NOT_DEFINED: "Sequencer has no active action group.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_CLIENT_UPDATE_NO_CURRENT_ACTION_GROUP: "Could not update current action group: current action group is undefined.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_CLIENT_UPDATE_NOT_PLAYING: "Could not update current action group: current action group is not playing.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_CLIENT_UPDATE_RETRY_NOT_FAILED_ACTION: "Could not retry action group: current action group is not failed.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_HOOK_ACTION_REQUEST_ALREADY_IN_PROGRESS: "Hook is already in progress.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_PAUSE_NOT_PAUSABLE: "Sequencer cannot be paused.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_PAUSE_NOT_PLAYING: "Tried to pause the sequencer while it is not playing.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_PLAY_NOT_IDLE: "Tried to start the sequencer while it is not idle.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_RESET_SEQUENCER_STILL_ACTIVE: "Tried to reset the sequencer while is it still active.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_RESUME_NOT_PAUSED: "Tried to resume the sequencer while is it not paused.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_STOP_NOT_PLAYING_OR_PAUSED: "Tried to stop the sequencer while it is not playing or paused.",
    KORTEX_SEQUENCER_SEQUENCER_GET_VARIABLE_ERROR: "Could not get the variables from the variable manager.",
    KORTEX_SEQUENCER_SEQUENCER_HISTORY_ACTION_NOT_FOUND: "Could not find action from history.",
    KORTEX_SEQUENCER_SEQUENCER_HOOK_ERROR: "An error occurred while running an a sequencer hook.",
    KORTEX_SEQUENCER_SEQUENCER_HOOK_ERROR_ON_FAIL: "An error occurred while running sequencer's 'onFail' hook.",
    KORTEX_SEQUENCER_SEQUENCER_HOOK_ERROR_ON_FAIL_UNDEFINED: "Sequencer requires the 'onFail' hook to be defined.",
    KORTEX_SEQUENCER_SEQUENCER_INPUT_ACTION_GROUP_NOT_FOUND: "Could not find an Input action.",
    KORTEX_SEQUENCER_SEQUENCER_NEXT_ACTION_GROUP_NOT_FOUND: "Could not find specified next action to play.",
    KORTEX_SEQUENCER_SEQUENCER_SET_VARIABLE_ERROR: "An error occurred while setting a variable into the variable manager.",
    KORTEX_SEQUENCER_SEQUENCER_UNDEFINED_SUB_PROGRAM: "Could not find specified sub program.",
    KORTEX_SEQUENCER_SUB_PROGRAM_INPUT_ACTION_GROUP_NOT_FOUND: "Sub program does not have an Input action.",
    KORTEX_SEQUENCER_UTILS_INTERPOLATION_ERROR: "An error occurred while interpolating a template string.",
    KORTEX_SEQUENCER_UTILS_INTERPOLATE_UNKNOWN_VARIABLE_ERROR: "Tried to interpolate an undefined variable.",
    KORTEX_SEQUENCER_VARIABLE_MANAGER_GET_ALL_ERROR: "Could not get variables from the variable manager.",
    KORTEX_SEQUENCER_VARIABLE_MANAGER_INVALID_IDENTIFIER: "Variable identifier is invalid.",
    KORTEX_SEQUENCER_VARIABLE_MANAGER_SET_INVALID_VALUE_TYPE: "Tried to set a variable with an invalid value type.",
};
