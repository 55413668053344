export var EnumResultSettingType;
(function (EnumResultSettingType) {
    EnumResultSettingType["GROUP"] = "GR";
    EnumResultSettingType["SUB_GROUP"] = "SGR";
    EnumResultSettingType["TABLE"] = "TBL";
    EnumResultSettingType["COLUMN"] = "COL";
})(EnumResultSettingType || (EnumResultSettingType = {}));
export var EnumResultSettingRole;
(function (EnumResultSettingRole) {
    EnumResultSettingRole["VALUE"] = "VAL";
    EnumResultSettingRole["CHILD_ITEM"] = "CHILD";
    EnumResultSettingRole["PARENT_ITEM"] = "PARENT";
})(EnumResultSettingRole || (EnumResultSettingRole = {}));
