import { IKortexTextFieldProps, KortexTextField, theme } from "@aos/react-components";
import { IRootCauseDbModel } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { rootCauseGetAll } from "@kortex/aos-ui/redux/rework-manager/root-cause-thunks";
import { MenuItem, Tooltip, Typography, makeStyles } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

const useStyles = makeStyles({
    container: {
        // To be overwritten
    },
    item: {
        // To be overwritten
    },
    itemLabel: {
        overflow: "hidden",
        // To be overwritten
    },
    selector: {
        width: "320px",
        fontSize: "1rem",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
    },
});

interface IRootCauseSelectorProps {
    classes: Partial<ReturnType<typeof useStyles>>;
    onChange: (value: number) => void;
    value: number;
    treeNodeId: number;
    reworkId: number;
    KortexTextFieldProps?: IKortexTextFieldProps["TextFieldProps"];
}

const RootCauseSelector: FC<IRootCauseSelectorProps> = (props) => {
    const { onChange, value, treeNodeId, reworkId, KortexTextFieldProps } = props;

    const classes = useStyles(props);
    const translate = useTranslate();
    const dispatch = useThunkDispatch();

    const [rootCausesType, setRootCausesType] = useState<IRootCauseDbModel[]>([]);
    const [selectedRootCause, setSelectedRootCause] = useState<IRootCauseDbModel | undefined>(undefined);

    useEffect((): void => {
        setSelectedRootCause(undefined);
        dispatch(rootCauseGetAll(treeNodeId)).then((root) => {
            setRootCausesType([...root]);
        });
    }, [reworkId]);

    useEffect((): void => {
        if (value && rootCausesType) {
            setSelectedRootCause(rootCausesType.find((rootCause) => rootCause.rootCauseId === value));
        }
    }, [rootCausesType, value]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChange(parseInt(event.target.value, 10));
    };

    return (
        <div className={classes.container}>
            <KortexTextField
                className={classes.selector}
                label={translate("rework.rootCause")}
                onChange={handleChange}
                TextFieldProps={{
                    id: "failureTicketRootCauseSelector",
                    select: true,
                    ...KortexTextFieldProps,
                }}
                value={selectedRootCause?.rootCauseId ?? ""}
                variant="standard"
            >
                {rootCausesType &&
                    rootCausesType.map((rootCause, index): JSX.Element => {
                        return (
                            <MenuItem className={classes.item} key={index} value={rootCause.rootCauseId} id={`${rootCause.title}Id`}>
                                <Tooltip
                                    classes={{ tooltip: classes.tooltip }}
                                    placement="right"
                                    title={`${rootCause.description.length > 0 ? rootCause.description : translate("general.na")}`}
                                >
                                    <Typography className={classes.itemLabel}>{rootCause.title}</Typography>
                                </Tooltip>
                            </MenuItem>
                        );
                    })}
            </KortexTextField>
        </div>
    );
};

export default RootCauseSelector;
