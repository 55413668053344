import { KortexDialogConfirmation, KortexTextField, theme } from "@aos/react-components";
import {
    DATASTORE_TABLE_VALUE_COLUMN_MAXIMUM_COUNT,
    EnumResultSettingRole,
    EnumResultSettingType,
    IResultSettingItemDbModel,
    ProcessEditorRightsEnum,
    TABLE_NAME_VALID_REGEX,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon/SpeedDialIcon";
import React, { useEffect, useState } from "react";

import ColumnsIcon from "../../../../../../../components/core/Icons/Columns/Columns";
import DataStorageIcon from "../../../../../../../components/core/Icons/DataStorage/DataStorage";
import { useTranslate } from "../../../../../../../hooks/useTranslate";
import { resultSettingItemUpsert } from "../../../../../../../redux/result-setting-manager/result-setting-item-thunks";
import { useSelectorResultSettingItems } from "../../../../../../../redux/selectors";
import { IUserRightsProps, userCanWrite } from "../../../../../../../utilitites/IUserRights";

const INPUT_DELAY_MS = 500;

const useStyles = makeStyles({
    root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridColumnGap: "16px",
        minWidth: "80%",
    },
    tableList: {
        position: "relative",
    },
    list: {
        height: "400px",
        overflowY: "scroll",
    },
    speedDial: {
        position: "absolute",
        right: "16px",
        bottom: "16px",
    },
    speedDialFab: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    speedDialButton: {
        boxShadow: "0px 4px 5px 0px",
    },
    maximumColumns: {
        color: theme.palette.error[400],
        fontSize: "0.7em",
        marginLeft: "6px",
        marginTop: "3px",
    },
});

const defaultTableItem = {
    disabled: false,
    itemOrder: 0,
    label: "TableA",
    role: EnumResultSettingRole.VALUE,
    type: EnumResultSettingType.TABLE,
    unit: "",
};

const defaultColumnItem = {
    disabled: false,
    itemOrder: 0,
    label: "ColumnA",
    role: EnumResultSettingRole.VALUE,
    type: EnumResultSettingType.COLUMN,
    unit: "",
};

interface IDataStoreTableValueSettingsProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    open: boolean;
    onClose: () => void;
}

export default function DataStoreTableValueSettings(props: IDataStoreTableValueSettingsProps): JSX.Element {
    /**
     * COMPONENT PROPS
     */
    const { open, onClose, userAccessLevel } = props;

    /**
     * COMPONENT STATES
     */
    const translate = useTranslate();
    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const readOnly = !userCanWrite(userAccessLevel);
    const resultSettingItems = useSelectorResultSettingItems();

    const [tableItems, setTableItems] = useState<IResultSettingItemDbModel[]>([]);
    const [selectedTableId, setSelectedTableId] = useState(-1);
    const [selectedTableColumnsCountMaximumReached, setSelectedTableColumnsCountMaximumReached] = useState(false);
    const [displayColumnCountErrorMessage, setDisplayColumnCountErrorMessage] = useState(false);

    const [columnItems, setColumnItems] = useState<IResultSettingItemDbModel[]>([]);
    const [selectedColumnId, setSelectedColumnId] = useState(-1);

    const [speedDialOpen, setSpeedDialOpen] = useState(false);

    const [tableNameError, setTableNameError] = useState<string | undefined>(undefined);
    const [tableColumnNameError, setTableColumnNameError] = useState<string | undefined>(undefined);

    /**
     * Effect triggered when the dialog open state change
     */
    useEffect((): void => {
        if (open) {
            setTableItems(resultSettingItems.filter((item) => item.type === EnumResultSettingType.TABLE));
            setColumnItems(resultSettingItems.filter((item) => item.type === EnumResultSettingType.COLUMN));
        }
    }, [open, resultSettingItems]);

    /**
     * Verify if table name is unique
     *
     * @param {number} columnId - Table column id
     * @param {string} columnName - Table column name
     */
    const isTableColumnNameDuplicated = (columnId: number, columnName: string): boolean => {
        const sourceColumn = columnItems.find((columnItem): boolean => columnItem.resultSettingItemId === columnId);

        if (!sourceColumn) {
            return false;
        }
        return (
            columnItems.findIndex(
                (columnItem): boolean =>
                    columnItem.parentId === sourceColumn.parentId &&
                    columnItem.resultSettingItemId !== columnId &&
                    columnItem.label.toLowerCase() === columnName.toLowerCase()
            ) > -1
        );
    };

    /**
     * Verify if table name is unique
     *
     * @param {number} tableId - Table id
     * @param {string} tableName - Table name
     */
    const isTableNameDuplicated = (tableId: number, tableName: string): boolean => {
        return (
            tableItems.findIndex(
                (tableItem): boolean =>
                    tableItem.resultSettingItemId !== tableId && tableItem.label.toLowerCase() === tableName.toLowerCase()
            ) > -1
        );
    };

    /**
     * Handle insert table click event
     */
    const handleInsertTableClick = (): void => {
        dispatch(resultSettingItemUpsert(defaultTableItem)).then((resultSettingItem) => {
            if (resultSettingItem) {
                setTableItems([...tableItems, resultSettingItem]);
            }
        });
    };

    /**
     * Handle insert column event
     */
    const handleInsertColumnClick = (event: React.MouseEvent<HTMLElement>): void => {
        if (selectedTableId <= 0) {
            return;
        }

        // Verify if the maximum columns count has been reached. If so, return without adding a new column.
        if (selectedTableColumnsCountMaximumReached) {
            setDisplayColumnCountErrorMessage(true);
            // Prevent the event from the parent element to be triggered. ie: don't close the menu.
            event.stopPropagation();
            return;
        }

        dispatch(resultSettingItemUpsert({ ...defaultColumnItem, parentId: selectedTableId })).then((resultSettingItem) => {
            if (resultSettingItem) {
                setColumnItems([...columnItems, resultSettingItem]);
            }
        });
    };

    /**
     * Handle select table click event
     *
     * @param {number} tableId - Table Id that will be selected
     */
    const handleSelectTableIdClick =
        (tableId: number): (() => void) =>
        (): void => {
            setSelectedTableId(tableId);
            setSelectedTableColumnsCountMaximumReached(verifyIfTableColumnsCountMaximumIsReached(tableId));
            setDisplayColumnCountErrorMessage(false);
        };

    /**
     * Handle select table column click event
     *
     * @param {number} columnId - Column Id that will be selected
     */
    const handleSelectColumnIdClick =
        (columnId: number): (() => void) =>
        (): void => {
            setSelectedColumnId(columnId);
        };

    /**
     * Handle table name textfield blur event
     */
    const handleTableLabelBlur = (): void => {
        setTableNameError(undefined);
    };

    /**
     * Handle table label change event
     *
     * @param {number} tableId - Table Id of the label that will be changed
     */
    const handleTableLabelChange =
        (tableId: number): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const isDuplicated = isTableNameDuplicated(tableId, event.target.value);

            if (isDuplicated) {
                setTableNameError(translate("action.datastore.dupicated.error"));
            } else if (tableNameError) {
                setTableNameError(undefined);
            }
        };

    /**
     * Handle table label changed event
     *
     * @param {number} tableId - Table Id of the label that will be changed
     */
    const handleTableLabelChanged =
        (tableId: number): ((value: string) => void) =>
        (value: string): void => {
            const tableItem = tableItems.find((tableItem): boolean => tableItem.resultSettingItemId === tableId);
            const isDuplicated = isTableNameDuplicated(tableId, value);

            if (!tableItem || isDuplicated) {
                return;
            }

            dispatch(resultSettingItemUpsert({ ...tableItem, label: value })).then((resultSettingItem): void => {
                if (resultSettingItem) {
                    const tableIndex = tableItems.findIndex(
                        (tableItem): boolean => tableItem.resultSettingItemId === resultSettingItem.resultSettingItemId
                    );
                    tableItems.splice(tableIndex, 1, resultSettingItem);
                    setTableItems([...tableItems]);
                }
            });
        };

    /**
     * Handle table column name textfield blur event
     */
    const handleTableColumnLabelBlur = (): void => {
        setTableColumnNameError(undefined);
    };

    /**
     * Handle column label change event
     *
     * @param {number} columnId - Column that will be updated by the event
     */
    const handleColumnLabelChange =
        (columnId: number): ((event: React.ChangeEvent<HTMLInputElement>) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const isDuplicated = isTableColumnNameDuplicated(columnId, event.target.value);

            if (isDuplicated) {
                setTableColumnNameError(translate("action.datastore.dupicated.error"));
            } else if (tableColumnNameError) {
                setTableColumnNameError(undefined);
            }
        };

    /**
     * Handle column label changed event
     *
     * @param {number} columnId - Column that will be updated by the event
     */
    const handleColumnLabelChanged =
        (columnId: number): ((value: string) => void) =>
        (value: string): void => {
            const columnItem = columnItems.find((columnItem): boolean => columnItem.resultSettingItemId === columnId);
            const isDuplicated = isTableColumnNameDuplicated(columnId, value);

            if (!columnItem || isDuplicated) {
                return;
            }

            dispatch(resultSettingItemUpsert({ ...columnItem, label: value })).then((resultSettingItem): void => {
                if (resultSettingItem) {
                    const columnIndex = columnItems.findIndex(
                        (columnItem): boolean => columnItem.resultSettingItemId === resultSettingItem.resultSettingItemId
                    );
                    columnItems.splice(columnIndex, 1, resultSettingItem);
                    setColumnItems(columnItems);
                }
            });
        };

    /**
     * Handle speed dial menu toggle event
     */
    const handleDialMenuToggle = (): void => {
        setSpeedDialOpen(!speedDialOpen);
    };

    /**
     * Verify if the maximum columns count has been reached.
     */
    const verifyIfTableColumnsCountMaximumIsReached = (tableId: number): boolean => {
        const currentTableColumns = columnItems.filter((columnItem): boolean => columnItem.parentId === tableId);
        return currentTableColumns.length >= DATASTORE_TABLE_VALUE_COLUMN_MAXIMUM_COUNT;
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={readOnly}
            textLabels={{
                titleLabel: translate("action.datastore.tableSettings"),
                cancelButtonLabel: translate("action.datastore.cancel"),
                proceedButtonLabel: translate("action.datastore.save"),
            }}
            open={open}
            onConfirm={onClose}
            onCancel={onClose}
            dialogProps={{
                id: "insert-menu",
                fullScreen: false,
                maxWidth: "lg",
            }}
        >
            <div className={classes.root}>
                {/* TABLE SETTINGS */}

                <Paper className={classes.tableList}>
                    <List className={classes.list}>
                        {tableItems.map((tableItem, index): JSX.Element => {
                            return (
                                <ListItem key={index} button={true} onClick={handleSelectTableIdClick(tableItem.resultSettingItemId || -1)}>
                                    <DataStorageIcon />
                                    <div>
                                        {tableItem.resultSettingItemId === selectedTableId && (
                                            <KortexTextField
                                                variant="standard"
                                                value={tableItem.label}
                                                onBlur={handleTableLabelBlur}
                                                onChange={handleTableLabelChange(tableItem.resultSettingItemId)}
                                                onChanged={handleTableLabelChanged(tableItem.resultSettingItemId)}
                                                changedDelayMS={INPUT_DELAY_MS}
                                                TextFieldProps={{
                                                    disabled: readOnly,
                                                    id: `tableName${index}Id`,
                                                }}
                                                regex={TABLE_NAME_VALID_REGEX}
                                                standardErrorMsgs={{ regexNoMatch: translate("action.datastore.table.name.error") }}
                                                error={tableNameError}
                                            />
                                        )}
                                        {tableItem.resultSettingItemId === selectedTableId && displayColumnCountErrorMessage && (
                                            <div className={classes.maximumColumns}>{translate("action.datastore.MaxColumnReached")}</div>
                                        )}
                                    </div>
                                    {tableItem.resultSettingItemId !== selectedTableId && (
                                        <div id={`tableName${index}Id`}>{tableItem.label}</div>
                                    )}
                                </ListItem>
                            );
                        })}
                    </List>

                    <SpeedDial
                        ariaLabel=""
                        open={speedDialOpen}
                        icon={<SpeedDialIcon id="speedDialId" />}
                        classes={{ fab: classes.speedDialFab }}
                        className={classes.speedDial}
                        hidden={readOnly}
                        onClick={handleDialMenuToggle}
                    >
                        {selectedTableId > 0 && (
                            <SpeedDialAction
                                classes={{ fab: classes.speedDialButton }}
                                icon={<ColumnsIcon id="addColumnId" />}
                                tooltipTitle={translate("action.datastore.column")}
                                tooltipOpen={true}
                                onClick={handleInsertColumnClick}
                                FabProps={{
                                    disabled: selectedTableColumnsCountMaximumReached,
                                }}
                            />
                        )}
                        <SpeedDialAction
                            classes={{ fab: classes.speedDialButton }}
                            icon={<DataStorageIcon id="addTableId" />}
                            tooltipTitle={translate("action.datastore.table")}
                            tooltipOpen={true}
                            onClick={handleInsertTableClick}
                        />
                    </SpeedDial>
                </Paper>

                {/* COLUMN SETTINGS */}

                <Paper>
                    <List className={classes.list}>
                        {columnItems
                            .filter((columnItem): boolean => columnItem.parentId === selectedTableId)
                            .map((columnItem, index): JSX.Element => {
                                return (
                                    <ListItem
                                        key={index}
                                        button={true}
                                        disabled={readOnly}
                                        onClick={handleSelectColumnIdClick(columnItem.resultSettingItemId || -1)}
                                    >
                                        <ColumnsIcon />
                                        {columnItem.resultSettingItemId === selectedColumnId && (
                                            <KortexTextField
                                                variant="standard"
                                                value={columnItem.label}
                                                onBlur={handleTableColumnLabelBlur}
                                                onChange={handleColumnLabelChange(columnItem.resultSettingItemId)}
                                                onChanged={handleColumnLabelChanged(columnItem.resultSettingItemId)}
                                                changedDelayMS={INPUT_DELAY_MS}
                                                regex={TABLE_NAME_VALID_REGEX}
                                                standardErrorMsgs={{ regexNoMatch: translate("action.datastore.table.name.error") }}
                                                error={tableColumnNameError}
                                                TextFieldProps={{
                                                    id: `columnName${index}Id`,
                                                }}
                                            />
                                        )}
                                        {columnItem.resultSettingItemId !== selectedColumnId && (
                                            <div id={`columnName${index}Id`}>{columnItem.label}</div>
                                        )}
                                    </ListItem>
                                );
                            })}
                    </List>
                </Paper>
            </div>
        </KortexDialogConfirmation>
    );
}
