import { ProcessVariableType } from "./variable/IProcessVariable";
export const ProcessApprovalStatusFilter = {
    NONE: "none",
    ALL: "all",
    APPROVED: "approved",
    RELEASED: "released",
    RELEASED_RETIRED: "relOrRet",
    APPROVED_AND_NOT_RETIRED: "appro&NotRet",
    TRAINING_REQUIREMENT: "trainingRequirement",
    WITHOUT_DRAFT: "withoutDraft",
    APPROVED_AND_DEFAULT_RELEASED: "appro&DefaultReleased",
    APPROVED_AND_NOT_RETIRED_RELEASED_SELECTED_BY_DEFAULT: "appro&NotRetReleasedSelectedByDefault",
};
export const ProcessType = {
    PROCESS: "P",
    ROUTING: "R",
};
export var ApprovalStatusEnum;
(function (ApprovalStatusEnum) {
    ApprovalStatusEnum["APPROVED"] = "A";
    ApprovalStatusEnum["AWAITING"] = "W";
    ApprovalStatusEnum["REJECTED"] = "R";
    ApprovalStatusEnum["HOLD"] = "H";
    ApprovalStatusEnum["CANCELLED"] = "C";
})(ApprovalStatusEnum || (ApprovalStatusEnum = {}));
export var ProcessTrainingRequirementEnum;
(function (ProcessTrainingRequirementEnum) {
    ProcessTrainingRequirementEnum[ProcessTrainingRequirementEnum["NONE"] = 0] = "NONE";
    ProcessTrainingRequirementEnum[ProcessTrainingRequirementEnum["TRAINING"] = 1] = "TRAINING";
    ProcessTrainingRequirementEnum[ProcessTrainingRequirementEnum["READING"] = 2] = "READING";
    ProcessTrainingRequirementEnum[ProcessTrainingRequirementEnum["CERTIFICATION"] = 3] = "CERTIFICATION";
})(ProcessTrainingRequirementEnum || (ProcessTrainingRequirementEnum = {}));
/**
 * Generate a default regex based on variable type
 *
 * @param {object} processVariable - Variable information
 *
 * @returns {string} - Default regex based on variable
 */
export function getVariableDefaultRegex(processVariable) {
    switch (processVariable.type) {
        case ProcessVariableType.NUMBER:
            if (processVariable.decimals === 0) {
                return "^\\d+$";
            }
            else {
                return `^\\d*\\.{1}\\d{0,${processVariable.decimals}}$`;
            }
        case ProcessVariableType.BOOLEAN:
            return "^(?:Tru|Fals)e$";
        case ProcessVariableType.JSON:
            return "^\\{.*\\}$";
        default:
            return "";
    }
}
