import { KortexDialogConfirmation, KortexTextField, theme } from "@aos/react-components";
import { IRootCauseDbModel, TreeNodeDbModel } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { rootCauseInsert, rootCauseUpdate } from "@kortex/aos-ui/redux/rework-manager/root-cause-thunks";
import { useSelectorLanguage } from "@kortex/aos-ui/redux/selectors";
import { getParentPathString } from "@kortex/aos-ui/utilitites/getParentNodes";
import { deepClone } from "@kortex/utilities";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    ListItem,
    ListItemText,
    Typography,
    makeStyles,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ArchiveIcon from "@material-ui/icons/Archive";
import EditIcon from "@material-ui/icons/Edit";
import AddPlusIcon from "@material-ui/icons/PlaylistAdd";
import UnarchiveIcon from "@material-ui/icons/Unarchive";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesRootCause, useEntitiesTreeProcess } from "../../../../../redux/effects";

const useStyles = makeStyles({
    list: {
        display: "flex",
        flexDirection: "column",
    },
    groupType: {
        display: "flex",
        flexDirection: "column",
        padding: "0 20px",
    },
    itemGroup: {
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        border: `1px solid ${theme.palette.grey[200]}`,
    },
    listOfItem: {
        width: "100%",
        overflowY: "auto",
    },
    listHeader: {
        display: "flex",
        fontWeight: "bold",
        color: theme.palette.primary[500],
    },
    title: {
        width: "40%",
        margin: "0px 3px",
    },
    description: {
        width: "60%",
        margin: "0px 3px",
    },

    item: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0 16px",
    },
    itemParent: {
        width: "100%",
        color: theme.palette.grey[400],
    },

    // button
    itemAction: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        width: "100%",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    tilteArchived: {
        color: theme.palette.grey[400],
        width: "40%",
    },
    descriptionArchived: {
        color: theme.palette.grey[400],
        width: "60%",
    },
});

interface IOwnProps {
    onClose: () => void;
    opened: boolean;
    treeNode: TreeNodeDbModel;
}

export default function RootCauseDialog(props: IOwnProps): JSX.Element {
    const { onClose, opened, treeNode } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();

    const allNodes = useEntitiesTreeProcess();
    const rootCausesType = useEntitiesRootCause(treeNode.treeNodeId);
    const translate = useTranslate();
    const language = useSelectorLanguage();

    const [selectedRootCause, setSelectedRootCause] = useState<IRootCauseDbModel | undefined>(undefined);
    const [selectedEditRootCause, setSelectedEditRootCause] = useState<IRootCauseDbModel | undefined>(undefined);

    const [rootCauseTilteToInsert, setRootCauseTilteToInsert] = useState("");
    const [rootCauseDescriptionToInsert, setRootCauseDescriptionToInsert] = useState("");
    const [insertDialogOpen, setInsertDialogOpen] = useState(false);

    const [showArchived, setShowArchived] = useState<boolean>(false);

    /**
     * Handle change of root cause
     *
     * @param {IRootCauseDbModel} rootCause - selected root cause
     */
    const handleRootCauseChange =
        (rootCause: IRootCauseDbModel): (() => void) =>
        (): void => {
            setSelectedRootCause(rootCause);
        };

    /**
     * Handle rename of root cause
     *
     * @param {IRootCauseDbModel} rootCause - root cause to rename
     */
    const handleRootCauseRename =
        (rootCause: IRootCauseDbModel): ((value: string) => void) =>
        (value: string): void => {
            const newItem = { ...rootCause };
            newItem.title = value;
            dispatch(rootCauseUpdate(newItem));
        };

    /**
     * Handle update description of root cause
     *
     * @param {IRootCauseDbModel} rootCause - root cause to update description
     */
    const handleRootCauseUpdateDescription =
        (rootCause: IRootCauseDbModel): ((value: string) => void) =>
        (value: string): void => {
            const newItem = { ...rootCause };
            newItem.description = value;
            dispatch(rootCauseUpdate(newItem));
        };

    /**
     * Handle select to edition root cause.
     *
     * @param {IRootCauseDbModel | undefined } rootCause - root cause to edit
     */
    const handleEditRootCause =
        (rootCause?: IRootCauseDbModel): (() => void) =>
        (): void => {
            if (rootCause) {
                if (!selectedEditRootCause) {
                    setSelectedEditRootCause(rootCause);
                } else {
                    setSelectedEditRootCause(undefined);
                }
            } else {
                setSelectedEditRootCause(undefined);
            }
        };

    /**
     * Handle insertion root cause
     */
    const handleInsertRootCause = (): void => {
        setRootCauseTilteToInsert("");
        setRootCauseDescriptionToInsert("");
        setInsertDialogOpen(true);
    };

    /**
     * Handle insertion of root cause
     */
    const handleInsertDialogAdd = (): void => {
        dispatch(
            rootCauseInsert({
                title: rootCauseTilteToInsert,
                description: rootCauseDescriptionToInsert,
                treeNodeId: treeNode.treeNodeId,
                archived: false,
            })
        );
    };

    /**
     * Handle close of insertion dialog.
     */
    const handleInsertDialogClose = (): void => {
        setInsertDialogOpen(false);
    };

    /**
     * Handle change tilte of the insertion
     *
     * @param {string} value - new value to store
     *
     */
    const onInsertTilteChanged = (value: string): void => {
        setRootCauseTilteToInsert(value);
    };

    /**
     * Handle change tilte of the description
     *
     * @param {string} value - new value to store
     *
     */
    const onInsertDescriptionChanged = (value: string): void => {
        setRootCauseDescriptionToInsert(value);
    };

    /**
     * Handle Archive/UnArchive of root cause
     */
    const handleArchiveRootCause = (): void => {
        if (selectedRootCause) {
            const rootCauseToUpdate = deepClone(selectedRootCause);
            rootCauseToUpdate.archived = !selectedRootCause.archived;

            dispatch(rootCauseUpdate(rootCauseToUpdate));
            if (rootCauseToUpdate.archived && !showArchived) {
                setSelectedRootCause(undefined);
            }
        }
    };

    /**
     * Handle select/unselect of the option show archive
     */
    const handleOptionShowArchived = (): void => {
        setShowArchived(!showArchived);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={true}
            dialogProps={{
                fullWidth: true,
                maxWidth: "md",
                onBackdropClick: onClose,
            }}
            onCancel={onClose}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("general.close"),
                titleLabel: `${translate("rootCauseDialog.rootCauseConfiguration")} ${treeNode.label}`,
            }}
        >
            <div className={classes.list}>
                <div className={classes.groupType}>
                    <div className={classes.itemGroup}>
                        <List className={classes.listOfItem}>
                            <ListItem className={classes.listHeader}>
                                <div className={classes.title}>{translate("rootCauseDialog.rootCause")}</div>
                                <div className={classes.description}>{translate("rootCauseDialog.description")}</div>
                            </ListItem>

                            {rootCausesType
                                .sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: "base" }))
                                .map((rootCause): JSX.Element => {
                                    if (rootCause.treeNodeId === treeNode.treeNodeId && (showArchived || !rootCause.archived)) {
                                        return selectedEditRootCause === rootCause ? (
                                            <div key={rootCause.rootCauseId} className={classes.item}>
                                                <KortexTextField
                                                    className={classes.title}
                                                    value={rootCause.title}
                                                    key={rootCause.rootCauseId}
                                                    onChanged={handleRootCauseRename(rootCause)}
                                                    variant={"standard"}
                                                    onBlur={handleEditRootCause()}
                                                    TextFieldProps={{ className: classes.title }}
                                                />
                                                <KortexTextField
                                                    className={classes.description}
                                                    value={rootCause.description}
                                                    key={rootCause.rootCauseId}
                                                    onChanged={handleRootCauseUpdateDescription(rootCause)}
                                                    variant={"standard"}
                                                    onBlur={handleEditRootCause()}
                                                    TextFieldProps={{ className: classes.description }}
                                                />
                                            </div>
                                        ) : (
                                            <ListItem
                                                button
                                                className={classes.item}
                                                selected={rootCause.rootCauseId === selectedRootCause?.rootCauseId}
                                                key={rootCause.rootCauseId}
                                                onClick={handleRootCauseChange(rootCause)}
                                                onDoubleClick={handleEditRootCause(rootCause)}
                                            >
                                                <ListItemText
                                                    primary={rootCause.title}
                                                    className={rootCause.archived ? classes.tilteArchived : classes.title}
                                                />
                                                <ListItemText
                                                    primary={rootCause.description}
                                                    className={rootCause.archived ? classes.descriptionArchived : classes.description}
                                                />
                                            </ListItem>
                                        );
                                    } else {
                                        return <></>;
                                    }
                                })}
                            {/* PARENT NODE FAILURE TYPES */}
                            {rootCausesType
                                .sort((a, b) => a.title.localeCompare(b.title, language, { sensitivity: "base" }))
                                .map((rootCause): JSX.Element => {
                                    if (rootCause.treeNodeId !== treeNode.treeNodeId && (showArchived || !rootCause.archived)) {
                                        return (
                                            <ListItem
                                                button
                                                className={classes.itemParent}
                                                selected={rootCause.rootCauseId === selectedRootCause?.rootCauseId}
                                                key={rootCause.rootCauseId}
                                                onClick={handleRootCauseChange(rootCause)}
                                            >
                                                <ListItemText primary={rootCause.title} className={classes.title} />
                                                <ListItemText
                                                    primary={rootCause.description}
                                                    className={classes.description}
                                                    secondary={getParentPathString(treeNode.treeNodeId, allNodes)}
                                                />
                                            </ListItem>
                                        );
                                    } else {
                                        return <></>;
                                    }
                                })}
                        </List>
                    </div>
                    <div className={classes.itemAction}>
                        <IconButton id="insertRootCauseId" color="primary" onClick={handleInsertRootCause}>
                            <AddPlusIcon />
                        </IconButton>
                        <IconButton
                            id="editRootCauseId"
                            color="primary"
                            onClick={handleEditRootCause(selectedRootCause)}
                            disabled={!selectedRootCause || selectedRootCause.treeNodeId !== treeNode.treeNodeId}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            id="archiveRootCauseId"
                            color="primary"
                            onClick={handleArchiveRootCause}
                            disabled={!selectedRootCause || selectedRootCause.treeNodeId !== treeNode.treeNodeId}
                        >
                            {selectedRootCause && !selectedRootCause.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                        </IconButton>
                    </div>
                </div>
                <div className={classes.itemAction}>
                    <div className={classes.optionsItem}>
                        <Typography>{`${translate("reworkItemStatusDialog.optionShowArchived")} `}</Typography>
                        <Checkbox checked={showArchived} onChange={handleOptionShowArchived} />
                    </div>
                </div>
            </div>

            {/* INSERT DIALOG */}
            <Dialog open={insertDialogOpen} disableAutoFocus={true} fullWidth={true} maxWidth="md">
                <DialogContent>
                    <div>
                        <List className={classes.listOfItem}>
                            <ListItem className={classes.listHeader}>
                                <div>{translate("rootCauseDialog.rootCause")}</div>
                                <div className={classes.description}>{translate("rootCauseDialog.description")}</div>
                            </ListItem>
                            <div className={classes.item}>
                                <KortexTextField
                                    TextFieldProps={{
                                        id: "insertNameId",
                                    }}
                                    InputProps={{
                                        autoFocus: true,
                                    }}
                                    label={translate("rootCauseDialog.rootCause")}
                                    onChanged={onInsertTilteChanged}
                                    value={rootCauseTilteToInsert}
                                    className={classes.title}
                                    variant={"standard"}
                                />
                                <KortexTextField
                                    TextFieldProps={{
                                        id: "insertDescriptionId",
                                    }}
                                    label={translate("rootCauseDialog.description")}
                                    onChanged={onInsertDescriptionChanged}
                                    value={rootCauseDescriptionToInsert}
                                    className={classes.description}
                                    variant={"standard"}
                                />
                            </div>
                        </List>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button id="addButtonId" variant="contained" color="secondary" onClick={handleInsertDialogAdd}>
                        <Typography>{translate("general.add")}</Typography>
                    </Button>
                    <Button id="closeButtonId" variant="contained" color="secondary" onClick={handleInsertDialogClose}>
                        <Typography>{translate("general.close")}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </KortexDialogConfirmation>
    );
}
