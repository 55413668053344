import {
    GeneralActionType,
    ReconnectingFailed,
    SetConnectionListAction,
    SetConnectionListChangedAction,
    SetLanguageAction,
    SetReconnecting,
    SetRouteAction,
    SetUpdateReadyAction,
    ToggleAppDrawerAction,
    UplinkAuthenticated,
    UplinkClosed,
    UplinkClosing,
    UplinkConnected,
    UplinkConnecting,
    UplinkReady,
} from "./general-types";

export function setConnectionListAction(connectionList: SetConnectionListAction["value"]): SetConnectionListAction {
    return { type: GeneralActionType.SET_ALL_CONNECTION_INFO, value: connectionList };
}

export function setConnectionListChangedAction(connection: SetConnectionListChangedAction["value"]): SetConnectionListChangedAction {
    return { type: GeneralActionType.SET_CONNECTION_LIST_CHANGED_ACTION, value: connection };
}

export function setLanguageAction(lang: SetLanguageAction["value"]): SetLanguageAction {
    return { type: GeneralActionType.SET_LANGUAGE, value: lang };
}

export function setRouteAction(route: SetRouteAction["value"]): SetRouteAction {
    return { type: GeneralActionType.SET_ROUTE, value: route };
}

export function setUpdateReadyAction(): SetUpdateReadyAction {
    return { type: GeneralActionType.SET_UPDATE_READY, value: true };
}

export function toggleAppDrawerAction(isOpen: ToggleAppDrawerAction["value"]): ToggleAppDrawerAction {
    return { type: GeneralActionType.TOGGLE_APP_DRAWER, value: isOpen };
}

export function setUplinkClosed(): UplinkClosed {
    return { type: GeneralActionType.UPLINK_CLOSED };
}

export function setUplinkClosing(): UplinkClosing {
    return { type: GeneralActionType.UPLINK_CLOSING };
}

export function setUplinkAuthenticated(value: UplinkAuthenticated["value"]): UplinkAuthenticated {
    return { type: GeneralActionType.UPLINK_AUTHENTICATED, value };
}

export function setUplinkConnected(): UplinkConnected {
    return { type: GeneralActionType.UPLINK_CONNECTED };
}

export function setUplinkConnecting(): UplinkConnecting {
    return { type: GeneralActionType.UPLINK_CONNECTING };
}

export function setUplinkReady(): UplinkReady {
    return { type: GeneralActionType.UPLINK_READY };
}

export function setReconnecting(): SetReconnecting {
    return { type: GeneralActionType.RECONNECTING };
}

export function reconnectingFailed(): ReconnectingFailed {
    return { type: GeneralActionType.RECONNECTING_FAILED };
}
