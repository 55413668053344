import { ActionTypeEnum } from "../enums";
export function isSequencerActionCondition(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.CONDITION;
}
export function isSequencerActionFail(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.FAIL;
}
export function isSequencerActionGoto(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.GO_TO;
}
export function isSequencerActionInput(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.INPUT;
}
export function isSequencerActionLoop(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.LOOP;
}
export function isSequencerActionMessage(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.MESSAGE;
}
export function isSequencerActionRemote(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.REMOTE;
}
export function isSequencerActionSet(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.SET;
}
export function isSequencerActionSubProgram(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.SUB_PROGRAM;
}
export function isSequencerActionThread(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.THREAD;
}
export function isSequencerActionTimer(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.TIMER;
}
export function isSequencerActionWaitCondition(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.WAIT_CONDITION;
}
export function isSequencerActionWaitTime(sequencerAction) {
    return sequencerAction.type === ActionTypeEnum.WAIT_TIME;
}
