import {
    ProcessId,
    ProcessTrainingGetTrainingsByProcessIdFilters,
    ProcessTrainingGetTrainingsByProcessIdRes,
    ProcessTrainingInsertRes,
    ProcessTrainingUpdateStatusRes,
    TrainingId,
    TrainingStatus,
    TreeNodeId,
    UnwrapAOSPayload,
    UserId,
} from "@kortex/aos-common";

import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";

import {
    processTrainingClear as clear,
    processTrainingSetProcessAction,
    processTrainingUpsertedTrainingAction,
} from "./process-training-actions";

//-------------------------------------------------------------
// LEFT PANEL
//-------------------------------------------------------------
/**
 * Get trainings from the latest approved process of a tree node.
 * It will update the left panel of process trainings.
 */
export function processTrainingGetLatestApprovedProcess(
    treeNodeId: TreeNodeId,
    filters: Omit<ProcessTrainingGetTrainingsByProcessIdFilters, "processId">
): StandardThunk<UnwrapAOSPayload<ProcessTrainingGetTrainingsByProcessIdRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ProcessTrainingGetTrainingsByProcessIdRes> | undefined> {
        return api.services.processTraining
            .getLatestApprovedProcess({ treeNodeId, ...filters })()
            .then((processTrainings) => {
                dispatch(processTrainingSetProcessAction({ panel: "left", ...processTrainings }));

                return processTrainings;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return undefined;
            });
    };
}

//-------------------------------------------------------------
// RIGHT PANEL
//-------------------------------------------------------------
/**
 * Get trainings from a process.
 * It will update the left panel of process trainings.
 */
export function processTrainingGetTrainingsByProcessId(
    processId: ProcessId,
    filters: Omit<ProcessTrainingGetTrainingsByProcessIdFilters, "processId">
): StandardThunk<UnwrapAOSPayload<ProcessTrainingGetTrainingsByProcessIdRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ProcessTrainingGetTrainingsByProcessIdRes> | undefined> {
        return api.services.processTraining
            .getTrainingsByProcessId({ processId, ...filters })()
            .then((processTrainings) => {
                dispatch(processTrainingSetProcessAction({ panel: "right", ...processTrainings }));

                return processTrainings;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return undefined;
            });
    };
}

//-------------------------------------------------------------
// BOTH PANELS
//-------------------------------------------------------------
/**
 * Clear training page
 */
export function processTrainingClear(): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(clear());
    };
}

/**
 * Add a training to a process
 */
export function processTrainingInsertTrainings(
    processId: ProcessId,
    userIds: UserId[]
): StandardThunk<UnwrapAOSPayload<ProcessTrainingInsertRes>> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<ProcessTrainingInsertRes>> => {
        return api.services.processTraining
            .insert({ processId, userIds })()
            .then((trainingsInserted) => {
                dispatch(processTrainingUpsertedTrainingAction([...trainingsInserted]));

                return trainingsInserted;
            })
            .catch((reason) => {
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }

                return [];
            });
    };
}

/**
 * Update the status of training
 */
export function processTrainingUpdateTrainingStatus(
    trainingId: TrainingId,
    status: TrainingStatus
): StandardThunk<UnwrapAOSPayload<ProcessTrainingUpdateStatusRes> | undefined> {
    return async (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ProcessTrainingUpdateStatusRes> | undefined> => {
        return api.services.processTraining
            .updateStatus({ trainingId, status })()
            .then((trainingsInserted) => {
                dispatch(processTrainingUpsertedTrainingAction([trainingsInserted]));

                return trainingsInserted;
            })
            .catch((reason) => {
                if (!handleAPIError(reason, dispatch)) {
                    throw reason;
                }

                return undefined;
            });
    };
}
