import { errorPalette, greyPalette, theme, warningPalette } from "@aos/react-components";
import { ITrainingPending, TrainingPendingId, getDateStr } from "@kortex/aos-common";
import KortexPanelCard from "@kortex/aos-ui/components/core/KortexPanelCard";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useEntitiesUserSession } from "@kortex/aos-ui/redux/effects";
import { trainingPendingDelete } from "@kortex/aos-ui/redux/training-pending-manager/training-pending-thunks";
import { userCanInsert } from "@kortex/aos-ui/utilitites/IUserRights";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { FC, createRef, useState } from "react";

const useStyles = makeStyles({
    card: {
        width: "100%",
    },
    cardContent: {
        display: "grid",
        gridTemplateColumns: "31% 37% 10% 20% 2%",
        alignItems: "center",
    },
    text: {
        color: greyPalette[700],
    },
    tooltip: {
        fontSize: "1.1rem",
        backgroundColor: greyPalette[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
    },
    column: {
        alignItems: "center",
        display: "flex",
        height: "58px",
        justifyContent: "center",
    },
    buttonContent: {
        display: "flex",
        justifyContent: "space-evenly",
    },
    accordionSummary: {
        margin: "0px",
        padding: "0px",
        "&.Mui-expanded": {
            margin: "0px",
            padding: "0px",
        },
    },
    trainingCertificateTrainerList: {
        marginTop: "5px",
        color: greyPalette[700],
    },
    lineCard: {
        border: `1px solid ${greyPalette[300]}`,
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
});

interface IOwnProps {
    trainingPending: ITrainingPending;
    index: number;
}

const TrainingPendingCard: FC<IOwnProps> = (props) => {
    const { trainingPending, index } = props;

    const ref = createRef<HTMLDivElement>();
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();
    const userInfo = useEntitiesUserSession();
    const [expend, setExpend] = useState<boolean>(false);

    const trainingStatusColor =
        trainingPending.date && Date.now() - trainingPending.date < 604800000 // 7 days in millisecond
            ? warningPalette[500] // Yellow
            : errorPalette[500]; // Red

    /**
     * Delete training pending
     */
    const handleDeleteTrainingPending =
        (trainingPendingId: TrainingPendingId): ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) =>
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            event.stopPropagation();
            dispatch(trainingPendingDelete({ trainingPendingId }));
        };

    /**
     * Handel expend
     */
    const handleExpend = (): void => {
        setExpend(!expend);
    };

    return (
        <Accordion expanded={expend}>
            <AccordionSummary
                classes={{ content: classes.accordionSummary, root: classes.accordionSummary }}
                onClick={handleExpend}
                id={`expend${index}Id`}
            >
                <div className={classes.lineCard}>
                    {expend ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    <KortexPanelCard
                        statusColor={trainingStatusColor}
                        isSelected={false}
                        padding={0}
                        spaceBetweenCard={1}
                        className={classes.card}
                        variant="flat"
                        hover={false}
                    >
                        <div className={classes.cardContent} id={`trainingPendingCard${index}Id`} ref={ref}>
                            {/* USER NAME */}
                            <div className={`${classes.column} `}>
                                <Typography className={classes.text} id={`trainingPendingCardUserName${index}Id`}>
                                    {`${trainingPending.user.firstName} ${trainingPending.user.lastName}`}
                                </Typography>
                            </div>
                            {/* TRAINING CERTIFICATE REFERENCE */}
                            <div className={classes.column}>
                                <Typography className={classes.text} id={`trainingPendingCardTrainingCertificateReference${index}Id`}>
                                    {trainingPending.trainingCertificateReference}
                                </Typography>
                            </div>
                            {/* TRAINING CERTIFICATE DATE */}
                            <div className={classes.column}>
                                <Typography className={classes.text} id={`trainingPendingCardTrainingCertificateDate${index}Id`}>
                                    {getDateStr(trainingPending.date)}
                                </Typography>
                            </div>
                            {/* MAIN TRAINER */}
                            <div className={classes.column}>
                                <Typography className={classes.text} id={`trainingPendingCardMainTrainer${index}Id`}>
                                    {trainingPending.trainersName[0]}
                                </Typography>
                            </div>
                            {/* DELETE ICON */}
                            <div className={classes.buttonContent}>
                                <Tooltip classes={{ tooltip: classes.tooltip }} title={translate("general.delete")} placement="left">
                                    <IconButton
                                        onClick={handleDeleteTrainingPending(trainingPending.trainingPendingId)}
                                        size="small"
                                        disabled={!userCanInsert(userInfo?.roleRights.training)}
                                    >
                                        <DeleteIcon id={`trainingPendingDeleteIcon${trainingPending.trainingPendingId}Id`} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </KortexPanelCard>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    {/* PROCESS NAME */}
                    <Typography variant={"h5"} className={classes.text}>
                        {`${translate("general.processes")} :`}
                    </Typography>
                    {trainingPending.processes.map((process, indexProcess) => (
                        <Typography
                            variant={"body1"}
                            className={classes.text}
                            key={`process${indexProcess}`}
                            id={`${index}processNameId${indexProcess}`}
                        >
                            {`${process.label} (${process.version})`}
                        </Typography>
                    ))}

                    {/* TRAINER NAME */}
                    <Typography variant={"h5"} className={classes.trainingCertificateTrainerList}>
                        {`${translate("training.trainingCertificateTrainers")} :`}
                    </Typography>
                    {trainingPending.trainersName.map((trainer, indexTrainer) => (
                        <Typography
                            variant={"body1"}
                            className={classes.text}
                            key={`trainer${indexTrainer}`}
                            id={`${index}trainer${indexTrainer}Id`}
                        >
                            {trainer}
                        </Typography>
                    ))}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default TrainingPendingCard;
