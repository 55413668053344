export const rework = {
    rpc: {
        getOne: {
            funcId: 3100,
            funcName: "reworkOne",
        },
        getAll: {
            funcId: 3101,
            funcName: "reworkAll",
        },
        insert: {
            funcId: 3102,
            funcName: "reworkInsert",
        },
        update: {
            funcId: 3103,
            funcName: "reworkUpdate",
        },
        getInfoBeforeCreate: {
            funcId: 3104,
            funcName: "getInfoBeforeCreate",
        },
        inserted: {
            funcId: 3105,
            funcName: "reworkInserted",
        },
        updated: {
            funcId: 3106,
            funcName: "reworkUpdated",
        },
        delete: {
            funcId: 3107,
            funcName: "reworkDelete",
        },
        deleted: {
            funcId: 3108,
            funcName: "reworkDeleted",
        },
        fork: {
            funcId: 3109,
            funcName: "reworkFork",
        },
    },
    serviceId: 31,
    serviceVersion: 1,
};
