import { theme } from "@aos/react-components";
import { ProcessApprovalStatusFilter, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { noop } from "@kortex/utilities";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import * as React from "react";

import { RepoBreadCrumbs } from "../../ProcessEditor/RepositoryManager/RepoBreadCrumbs";

import { useProcessTrainingContext } from "./context";
import { ProcessTrainingContent } from "./processTrainingContent";

const useStyles = makeStyles({
    root: {
        marginTop: "3px",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
    },
});

function ProcessTrainingTab(): JSX.Element {
    const classes = useStyles();
    const { loading, selectTreeNode, processRepositoryOpened, processTrainings, setProcessRepositoryOpened } = useProcessTrainingContext();

    /**
     * handle cancel select process to close dialog
     */
    const handleCancelSelectProcess = (): void => {
        setProcessRepositoryOpened(false);
    };

    return (
        <div className={classes.root} id="processTrainingTabId">
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div>
                <RepoBreadCrumbs
                    onSelectProcess={selectTreeNode}
                    onCancelSelectProcess={handleCancelSelectProcess}
                    onVersionChange={noop}
                    opened={processRepositoryOpened}
                    processId={processTrainings.left.originalProcessId || undefined}
                    treeNodeId={processTrainings.treeNodeId || undefined}
                    inRouting={false}
                    showOnlyBreadCrumbs={true}
                    processRepositoryProps={{
                        filterType: TreeNodeNodeTypeEnum.PROCESS,
                        selectOnly: true,
                        showVersionVariant: ProcessApprovalStatusFilter.APPROVED,
                    }}
                />
                <ProcessTrainingContent />
            </div>
        </div>
    );
}

export default ProcessTrainingTab;
