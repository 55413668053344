import { ProcessApprovalGroupAllRes, UnwrapAOSPayload } from "@kortex/aos-common";

import { AppState, StandardDispatch, StandardThunk } from "../store";

// thunks

/**
 * Retrieves process approval groups linked to user group
 *
 * @param {number} userGroupId - group for which we want to get associated processApprovalGroups
 */
export function processApprovalGroupGetAll(userGroupId: number): StandardThunk<UnwrapAOSPayload<ProcessApprovalGroupAllRes>> {
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ProcessApprovalGroupAllRes>> {
        return api.services.processApprovalGroups
            .getAll({ userGroupId })()
            .then((groups) => groups);
    };
}
