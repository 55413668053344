import { KortexTextField, greyPalette, theme } from "@aos/react-components";
import {
    IProcessUiModel,
    ITaskInfoProcessReview,
    TTaskUnknown,
    Task,
    TaskStatusEnum,
    TaskTypeEnum,
    formatDate,
    getDateTimeStr,
    getParentPath,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { deepClone } from "@kortex/utilities";
import { IconButton, Typography, makeStyles } from "@material-ui/core";
import ProcessReviewIcon from "@material-ui/icons/AssignmentIndOutlined";
import ProcessApprovalIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import CloseIcon from "@material-ui/icons/Close";
import SubmitIcon from "@material-ui/icons/Publish";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import { useEntitiesTreeProcess, useEntitiesUserSession, useEntitiesUsers, useEntitiesUsersGroups } from "../../../redux/effects";
import { taskUpdate } from "../../../redux/task-manager/task-thunks-task";
import KortexLabelText from "../../core/KortexLabelText";
import KortexPanelCard from "../../core/KortexPanelCard";

import { getTaskStatusColor, getTaskStatusKey, getTaskTypeKey } from "./TasksCommon";

const useStyles = makeStyles({
    textLarge: {
        margin: "5px",
        width: "170px",
    },
    taskMainContainer: {
        display: "flex",
        flexDirection: "row",
        overflowY: "auto",
    },
    taskAllContainer: {
        display: "flex",
        flexDirection: "column",
    },
    taskContainer: {
        paddingTop: "10px",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.grey[100],
    },
    taskIconContainer: {
        display: "flex",
        flexDirection: "column",
    },
    taskTitle: {
        display: "flex",
        flexDirection: "row",
        color: theme.palette.primary[500],
    },
    taskTitleTypo: {
        marginLeft: "5px",
        marginBottom: "8px",
    },
    taskCardText: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-start",
        flexFlow: "wrap",
    },
    taskCommentsContainer: {
        padding: "5px 10px",
        display: "flex",
        flexDirection: "row",
        maxHeight: "500px",
        overflowY: "auto",
    },
    taskNewCommentContainer: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: theme.palette.common.white,
        border: theme.palette.grey[300],
        borderRadius: "10px",
        margin: "10px",
    },
    taskCommentContainer: {
        display: "flex",
        flexDirection: "column",
    },
    taskCommentBlock: {
        padding: "10px",
    },
    taskCommentHeaderContainer: {
        display: "flex",
        flexDirection: "row",
    },
    taskCommentBold: {
        fontWeight: "bold",
        paddingRight: "10px",
    },
    taskComment: {
        fontWeight: "normal",
        paddingRight: "10px",
    },
    taskCommentComment: {
        fontWeight: "normal",
        paddingTop: "5px",
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
    textSmall: {
        margin: "5px",
        minWidth: "100px",
    },
    text: {
        margin: "5px",
        minWidth: "170px",
    },
    chatText: {
        margin: "5px",
        display: "flex",
        flexGrow: 1,
    },
    textLong: {
        margin: "5px",
        minWidth: "350px",
    },
    cardContainer: {},
    iconContainer: {
        height: "40px",
        width: "40px",
        color: greyPalette[500],
        position: "relative",
    },
    iconTop: {
        color: greyPalette[500],
    },
    iconMini: {
        color: greyPalette[500],
        height: "32px",
        width: "32px",
    },
    spacer: {
        flexGrow: 1,
    },
});

interface ITaskComment {
    userId: number;
    timestamp: number;
    type: string;
    comment: string;
}

interface IOwnProps {
    index: number;
    task: TTaskUnknown;
    isSelected: boolean;
    process?: IProcessUiModel;
    onSelection?: (task: TTaskUnknown) => void;
    variant?: "mini" | "regular";
}

export default function TaskCardProcess(props: IOwnProps): JSX.Element {
    const { index, task, process } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();
    const treeNodes = useEntitiesTreeProcess();
    const userSession = useEntitiesUserSession();
    const users = useEntitiesUsers();
    const groups = useEntitiesUsersGroups();

    const [processId, setProcessId] = useState<number | undefined>(undefined);
    const [processName, setProcessName] = useState<string | undefined>(undefined);
    const [processPath, setProcessPath] = useState<string | undefined>(undefined);
    const [requestedByUser, setRequestedByUser] = useState<string>("");
    const [approvalGroup, setApprovalGroup] = useState<string>("");
    const [reviewByUser, setReviewByUser] = useState<string>("");
    const [comments, setComments] = useState<string>("");
    const [commentObjList, setCommentObjList] = useState<ITaskComment[]>([]);
    const [isMini, setIsMini] = useState<boolean>(false);

    /**
     * Effect to construct all the necessary info for a task
     */
    useEffect((): void => {
        if (isMini !== (props.variant === "mini")) {
            setIsMini(props.variant === "mini");
        }

        const requester = users.find((user) => user.userId === task.userId);
        setRequestedByUser(requester ? `${requester.firstName} ${requester.lastName}` : "");

        if (task.type === TaskTypeEnum.PROCESS_REVIEW) {
            const reviewer = users.find((user) => user.userId === (task.info as ITaskInfoProcessReview).reviewerId);
            setReviewByUser(reviewer ? `${reviewer.firstName} ${reviewer.lastName}` : "");
        }

        const approvalGroup = groups.find((group) => group.userGroupId === task.groupId);
        setApprovalGroup(approvalGroup ? approvalGroup.name : "");

        if (!props.variant) {
            if (process) {
                setProcessId(process.processId);

                const treeNode = treeNodes.find((treeNode) => treeNode.treeNodeId === process.treeNodeId);
                if (treeNode) {
                    setProcessName(treeNode.label);
                    // Build path
                    const processPathArray = getParentPath(treeNode.treeNodeId, treeNodes);
                    let pathStr = "";
                    for (const path of processPathArray) {
                        pathStr += `/${path}`;
                    }
                    setProcessPath(pathStr);
                }
            } else {
                setProcessName(undefined);
                setProcessPath(undefined);
                setProcessId(undefined);
            }
        }
    }, [task, users, groups, props.variant, process]);

    /**
     * Effect to build comments list of comments change or on variant change
     */
    useEffect((): void => {
        // task.Comments
        // Format comments objects likes a chat room
        if (!task.comments) {
            setCommentObjList([]);
        } else {
            const newComments: ITaskComment[] = JSON.parse(task.comments);
            setCommentObjList(newComments);
        }
    }, [isMini, task.comments]);

    /**
     * Handle selection of the task
     *
     * @param {TTaskUnknown} task - task selected
     */
    const handleSelectTask =
        (task: TTaskUnknown): (() => void) =>
        (): void => {
            if (props.variant === "mini") {
                if (props.onSelection) {
                    props.onSelection(task);
                }
            }
        };

    /**
     * Handle closure of the task card
     */
    const handleCloseCard = (): void => {
        if (props.onSelection) {
            props.onSelection(task);
        }
    };

    /**
     * Update Comments
     */
    const handleCommentsChanged = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setComments(event.target.value);
    };

    /**
     * Submit new comments
     */
    const handleCommentsSubmit = (): void => {
        if (comments.trim() != "") {
            const newComment: ITaskComment = {
                userId: userSession ? userSession.userId : 0,
                timestamp: Date.now(),
                type: "txt",
                comment: comments,
            };

            const newCommentsObjList = [...commentObjList, newComment];
            setCommentObjList(newCommentsObjList);
            setComments("");

            const newTask = deepClone(task);
            newTask.comments = JSON.stringify(newCommentsObjList);

            dispatch(taskUpdate([newTask]));
        }
    };

    /**
     * return icon based on task
     */
    const getTaskIcon = (task: TTaskUnknown): JSX.Element => {
        if (Task.isProcessApproval(task)) {
            return <ProcessApprovalIcon className={classes.iconMini} />;
        } else if (Task.isProcessReview(task)) {
            return <ProcessReviewIcon className={classes.iconMini} />;
        } else {
            return <React.Fragment />;
        }
    };

    return (
        <div>
            {task && (
                <div className={classes.cardContainer}>
                    <KortexPanelCard
                        key={index}
                        isSelected={props.isSelected}
                        padding={10}
                        onSelect={handleSelectTask(task)}
                        statusColor={getTaskStatusColor(task.status)}
                    >
                        <div className={classes.taskMainContainer}>
                            <div className={classes.iconContainer}>{getTaskIcon(task)}</div>
                            <div className={classes.taskAllContainer}>
                                <div className={classes.taskTitle}>
                                    <Typography className={classes.taskTitleTypo} color="primary" variant="h6">
                                        {translate(getTaskTypeKey(task.type))}
                                    </Typography>
                                </div>
                                <div className={classes.taskCardText}>
                                    <KortexLabelText
                                        label={translate("tasks.taskCard.status")}
                                        text={translate(getTaskStatusKey(task.status))}
                                        className={classes.textSmall}
                                    />
                                    <KortexLabelText
                                        label={translate("tasks.taskCard.reference")}
                                        text={task.referenceId}
                                        className={classes.textSmall}
                                    />
                                    <KortexLabelText
                                        label={translate("tasks.taskCard.submitted")}
                                        text={task.createdOn ? formatDate(task.createdOn) : ""}
                                        className={classes.textSmall}
                                    />
                                    {!isMini && (
                                        <KortexLabelText
                                            label={translate("tasks.taskCard.requestedBy")}
                                            text={requestedByUser}
                                            className={classes.textSmall}
                                        />
                                    )}
                                    {Task.isProcessApproval(task) && (
                                        <KortexLabelText
                                            label={translate("tasks.taskCard.approverGroup")}
                                            text={approvalGroup}
                                            className={classes.textSmall}
                                        />
                                    )}
                                    {!isMini && (
                                        <KortexLabelText
                                            label={translate("tasks.taskCard.processId")}
                                            text={processId ? processId.toString() : ""}
                                            className={classes.textSmall}
                                        />
                                    )}
                                    {!isMini && (
                                        <KortexLabelText
                                            label={translate("tasks.taskCard.processName")}
                                            text={processName ? processName : ""}
                                            className={classes.text}
                                        />
                                    )}

                                    {!isMini && (
                                        <KortexLabelText
                                            label={translate("tasks.taskCard.processPath")}
                                            text={processPath ? `${processPath}` : ""}
                                            className={classes.text}
                                        />
                                    )}

                                    {Task.isProcessReview(task) && (
                                        <KortexLabelText
                                            label={translate("tasks.taskCard.reviewer")}
                                            text={reviewByUser}
                                            className={classes.text}
                                        />
                                    )}
                                </div>
                                {!isMini && (
                                    <div className={classes.taskContainer}>
                                        <div className={classes.taskCommentsContainer}>
                                            <Typography color="primary" variant="h6">
                                                {translate("tasks.taskCard.comments")}
                                            </Typography>
                                        </div>
                                        <div className={classes.taskCommentsContainer}>
                                            <div className={classes.taskCommentContainer}>
                                                {commentObjList.map((commentObj: ITaskComment, index: number): JSX.Element => {
                                                    return (
                                                        <div key={index} className={classes.taskCommentBlock}>
                                                            <div className={classes.taskCommentHeaderContainer}>
                                                                <div className={classes.taskCommentBold}>
                                                                    {`${users.find((user) => user.userId == commentObj.userId)
                                                                        ?.firstName} ${users.find(
                                                                        (user) => user.userId == commentObj.userId
                                                                    )?.lastName}`}
                                                                </div>
                                                                <div className={classes.taskComment}>
                                                                    {getDateTimeStr(commentObj.timestamp)}
                                                                </div>
                                                            </div>
                                                            <div className={classes.taskCommentComment}>{commentObj.comment}</div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className={classes.taskNewCommentContainer}>
                                            <KortexTextField
                                                TextFieldProps={{
                                                    multiline: true,
                                                    rowsMax: 10,
                                                    disabled: task.status === TaskStatusEnum.ARCHIVE,
                                                }}
                                                className={classes.chatText}
                                                value={comments}
                                                onChange={handleCommentsChanged}
                                                variant={"standard"}
                                            />
                                            <IconButton onClick={handleCommentsSubmit}>
                                                <SubmitIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={classes.spacer} />
                            <div className={classes.taskIconContainer}>
                                {!isMini && (
                                    <IconButton onClick={handleCloseCard}>
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    </KortexPanelCard>
                </div>
            )}
        </div>
    );
}
