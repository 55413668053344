var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Thread(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M17.2569727,11.7519488 L21,15.4949762 L17.2569727,19.2380035 L17.256,16.4639488 L3,16.4639698 L3,14.5259826 L17.256,14.5259488 L17.2569727,11.7519488 Z M17.2569727,4 L21,7.74302733 L17.2569727,11.4860547 L17.256,8.712 L3,8.71202094 L3,6.77403373 L17.256,6.774 L17.2569727,4 Z", id: "icons-thread" }),
        ";"));
}
export default Thread;
