import { IntlShape, MessageDescriptor, useIntl } from "react-intl";

export type TranslateCb = (id: MessageDescriptor["id"], defaultMessage?: MessageDescriptor["defaultMessage"]) => string;

interface IUseTranslate {
    (id: MessageDescriptor["id"], defaultMessage?: MessageDescriptor["defaultMessage"]): string;
    intl: IntlShape;
}

type UseTranslate = IUseTranslate;

/**
 * Translate hook
 * Return translation from a locale id
 *
 */
export function useTranslate(): UseTranslate {
    // State and setters for debounced value
    const intl = useIntl();

    function translate(id: MessageDescriptor["id"], defaultMessage?: MessageDescriptor["defaultMessage"]): string {
        return intl.formatMessage({ defaultMessage, id });
    }

    translate.intl = intl;

    return translate;
}
