export const electronicSignature = {
    rpc: {
        getOne: {
            funcId: 10001,
            funcName: "electronicSignatureGetOne",
        },
        getAll: {
            funcId: 10002,
            funcName: "electronicSignatureGetAll",
        },
        insert: {
            funcId: 10003,
            funcName: "electronicSignatureInsert",
        },
        inserted: {
            funcId: 10004,
            funcName: "electronicSignatureInserted",
        },
    },
    serviceId: 110,
    serviceVersion: 1,
};
