import { InterpolationError } from "../interfaces";
import { localeKeys } from "../locales";
import { interpolate } from "./interpolate";
import { wrapVariableIdentifier } from "./wrap-variable-identifer";
export function interpolateVariable(identifier, variables) {
    try {
        return interpolate.parse(interpolate(wrapVariableIdentifier(identifier), variables));
    }
    catch (error) {
        throw new InterpolationError(localeKeys.utils.interpolationError, "Interpolation error.", error);
    }
}
