import util from "util";
/**
 * This function returns a string representation of an object (first parameter) that is intended for debugging.
 * This string will be displayed in the console.
 * For more details, refer to the `util.inspect` documentation from Node.js' `util` module.
 */
export function deepConsoleLog(obj, options = {}) {
    const { color = true, depth = null, showHidden = false } = options;
    const objStr = util.inspect(obj, showHidden, depth, color);
    console.log(objStr);
    return objStr;
}
