import { IProcessUiModel, ProcessValidation, validateProcess } from "@kortex/aos-common";

/**
 * Checks whether a process is valid or not and provides a callback to run when the validation
 * returns errors.
 *
 * @param {IProcessUiModel} editedProcess - the process to validate
 * @param {ProcessValidation} onInvalid -  - callback to run when the process is not valid
 */
export function isProcessValid(editedProcess: IProcessUiModel, onInvalid?: (error: Readonly<ProcessValidation>) => void): boolean {
    const validation = validateProcess(editedProcess);

    if (Boolean(validation.process.errors.length) || Boolean(validation.processAction.errors.length)) {
        onInvalid?.(validation);
        return false;
    }

    return true;
}
