import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default function Checkbox(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                d="M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z M4,4 L4,20 L20,20 L20,4 L4,4 Z M16.0238095,7.75 L17.452381,9.25 L10.7952381,16.25 L6.5,11.75 L7.92857143,10.25 L10.7952381,13.25 L16.0238095,7.75 Z"
                id="icons-checkbox"
            />
        </SvgIcon>
    );
}
