import { ActionTypeEnum, FailureCodeEnum, FailureSourceEnum, RemoteEventEnum, SequencerActionStatusEnum } from "../../enums";
import { RemoteActionExternalError, RemoteActionMethodTimeoutError, SequencerError, } from "../../interfaces";
import { Khrono } from "../../khrono";
import { localeKeys } from "../../locales";
import { KortexSequencerDebugger, assertInvalidCoreActionFlow, assertRemoteMethodDefined, deepClone, defer, getExtendedActionConfig, isPromiseStarted, } from "../../utilities";
import { interpolateActionConfig } from "../../utilities/interpolate-action-config";
const debug = KortexSequencerDebugger.core.remote;
export const SEQUENCER_ACTION_REMOTE_DEFAULT_STATE = {};
export const SEQUENCER_ACTION_REMOTE_METHOD_TIMEOUT = {
    actionCompletionMaxMs: 60000,
    actionCompletionMinMs: 0,
    methodConfirmationMs: 10000,
};
function create({ index, remote, variableManager, variableNamespace }) {
    let _waitingForStartConfirmation = false, _pause, _play, _playCompleteTimeout, _playConfirmationTimeout, _playStartMs = 0, _resume, _stop, _paused = false, _pauseDurationMs = 0, _pauseStartMs = 0, _remoteActionStarted = false, _state = {}, _stateChanged = async () => void 0;
    const timeout = {
        ...SEQUENCER_ACTION_REMOTE_METHOD_TIMEOUT,
        ...remote.timeout,
    };
    const clientUpdateMethodMap = {
        [RemoteEventEnum.ACTION_COMPLETED]: _handleRemoteActionCompleted,
        [RemoteEventEnum.ACTION_FAILED]: _handleRemoteActionFailed,
        [RemoteEventEnum.ACTION_PAUSED]: _handleRemoteActionPaused,
        [RemoteEventEnum.ACTION_RESUMED]: _handleRemoteActionResumed,
        [RemoteEventEnum.ACTION_STARTED]: _handleRemoteActionStarted,
        [RemoteEventEnum.ACTION_STOPPED]: _handleRemoteActionStopped,
    };
    function _assertActionStarted() {
        assertInvalidCoreActionFlow(!_remoteActionStarted, localeKeys.action.core.remote.actionNotStarted, "Action is not started.");
    }
    function _assertActionPaused() {
        assertInvalidCoreActionFlow(!(_remoteActionStarted && _paused), localeKeys.action.core.remote.actionNotPaused, "Action is not paused.");
    }
    function _assertActionNotPaused() {
        assertInvalidCoreActionFlow(_paused, localeKeys.action.core.remote.actionAlreadyPaused, "Action is already paused.");
    }
    function _createRemoteErrorPayload(reason, key) {
        if (reason instanceof SequencerError) {
            return reason;
        }
        if (typeof reason === "string") {
            return new RemoteActionExternalError(key, reason);
        }
        if (reason instanceof Error) {
            return new RemoteActionExternalError(key, reason.message, reason);
        }
        return new RemoteActionExternalError(key);
    }
    function _fail(failure) {
        debug("fail:%o", failure);
        _assertActionStarted();
        return _play.resolve({
            payload: failure,
            status: SequencerActionStatusEnum.FAILED,
        });
    }
    async function _handleRemoteActionCompleted(payload) {
        debug("client update:remote action completed:%o", payload);
        _assertActionStarted();
        if (timeout.actionCompletionMinMs > 0) {
            const remainingTime = timeout.actionCompletionMinMs - (new Date().getTime() - _playStartMs) + _pauseDurationMs;
            if (remainingTime <= 0) {
                return _play.resolve({
                    payload: payload,
                    status: SequencerActionStatusEnum.COMPLETED,
                });
            }
            debug("client update:remote action completed:mininum completion time is not up:remaining time=%d", remainingTime);
        }
        return _play.resolve({
            payload: payload,
            status: SequencerActionStatusEnum.COMPLETED,
        });
    }
    async function _handleRemoteActionFailed(payload) {
        const { code = FailureCodeEnum.REMOTE_ACTION_FAILURE, error, key = localeKeys.action.core.remote.actionFailed, message = "", } = payload;
        debug("client update:remote action failed:%o", payload);
        _assertActionStarted();
        return _fail({
            action: {
                state: _state,
            },
            code,
            error,
            key,
            message,
            source: FailureSourceEnum.REMOTE,
        });
    }
    async function _handleRemoteActionPaused() {
        debug("client update:remote action paused");
        try {
            _assertActionStarted();
            _assertActionNotPaused();
        }
        catch (error) {
            _pause?.reject(error);
            throw error;
        }
        return _pause.resolve();
    }
    async function _handleRemoteActionResumed() {
        debug("client update:remote action resumed");
        try {
            _assertActionPaused();
        }
        catch (error) {
            _resume?.reject(error);
            throw error;
        }
        return _resume.resolve();
    }
    async function _handleRemoteActionStarted(payload) {
        debug("client update:remote action started:%o", payload);
        try {
            assertInvalidCoreActionFlow(!_waitingForStartConfirmation, localeKeys.action.core.remote.clientUpdateStartNotRequestedByAction, "Action did not request this remote action to be played.");
        }
        catch (error) {
            _play?.reject(error);
            throw error;
        }
        clearTimeout(_playConfirmationTimeout);
        _waitingForStartConfirmation = false;
        _remoteActionStarted = true;
        if (timeout.actionCompletionMaxMs > 0) {
            _playCompleteTimeout = setTimeout(() => {
                const timeoutError = new RemoteActionMethodTimeoutError(localeKeys.action.core.remote.playCompletionTimeoutError, "Play completion timeout has expired.");
                _play?.resolve({
                    payload: {
                        action: {
                            state: _state,
                        },
                        code: FailureCodeEnum.REMOTE_ACTION_FAILURE,
                        key: timeoutError.key,
                        source: FailureSourceEnum.REMOTE,
                        error: timeoutError,
                    },
                    status: SequencerActionStatusEnum.FAILED,
                });
            }, timeout.actionCompletionMaxMs);
        }
    }
    async function _handleRemoteActionStopped(payload) {
        debug("client update:remote action stopped:%o", payload);
        try {
            _assertActionStarted();
        }
        catch (error) {
            _stop?.reject(error);
            throw error;
        }
        _stop.resolve();
    }
    function assertValidConfig(config) {
        debug("config validation %o", config);
        debug("config validation:success");
    }
    async function clientUpdate(payload) {
        debug("client update:%o", payload);
        try {
            await clientUpdateMethodMap[payload.event](payload.payload);
        }
        catch (reason) {
            const error = _createRemoteErrorPayload(reason, localeKeys.action.core.remote.clientUpdateError);
            return {
                payload: {
                    action: {
                        state: _state,
                    },
                    code: FailureCodeEnum.FLOW_ERROR,
                    error: error,
                    key: error.key,
                    source: FailureSourceEnum.ACTION,
                },
                status: SequencerActionStatusEnum.FAILED,
            };
        }
        return {
            payload: null,
            status: SequencerActionStatusEnum.CONTINUE,
        };
    }
    async function onStateChange(cb) {
        debug("on state change:callback saved");
        _stateChanged = cb;
    }
    async function pause() {
        debug("pause");
        assertRemoteMethodDefined(remote.pause);
        _assertActionStarted();
        _assertActionNotPaused();
        let promiseTimeout;
        _pause = defer((_, reject) => {
            if (timeout.methodConfirmationMs > 0) {
                promiseTimeout = setTimeout(() => {
                    reject(new RemoteActionMethodTimeoutError(localeKeys.action.core.remote.pauseConfirmationTimeoutError, "Pause confirmation timeout has expired."));
                }, timeout.methodConfirmationMs);
            }
        });
        _pause.promise.then(() => {
            clearTimeout(promiseTimeout);
            _paused = true;
            _pauseStartMs = new Date().getTime();
        }, (error) => {
            _fail({
                action: {
                    state: _state,
                },
                code: FailureCodeEnum.REMOTE_ACTION_FAILURE,
                error,
                key: error.key,
                source: FailureSourceEnum.REMOTE,
            });
        });
        Khrono.createRemoteActionConfirmationEvent(RemoteEventEnum.ACTION_PAUSED, remote.pause, async (reason) => {
            _pause?.reject(_createRemoteErrorPayload(reason, localeKeys.action.core.remote.pauseError));
        }, index);
        return _pause.promise;
    }
    async function play(config) {
        debug("play:%o", config);
        if (isPromiseStarted(_play)) {
            debug("play:fail:action is already in progress");
            return {
                payload: {
                    action: {
                        state: _state,
                    },
                    code: FailureCodeEnum.FLOW_ERROR,
                    key: localeKeys.action.core.remote.playAlreadyInProgress,
                    source: FailureSourceEnum.ACTION,
                },
                status: SequencerActionStatusEnum.FAILED,
            };
        }
        _play = Khrono.createRemoteActionCompletionEvent(async (resolve) => {
            _playStartMs = new Date().getTime();
            _waitingForStartConfirmation = true;
            if (timeout.methodConfirmationMs > 0) {
                _playConfirmationTimeout = setTimeout(() => {
                    const timeoutError = new RemoteActionMethodTimeoutError(localeKeys.action.core.remote.playConfirmationTimeoutError, "Play confirmation timeout has expired.");
                    return resolve({
                        payload: {
                            action: {
                                state: _state,
                            },
                            code: FailureCodeEnum.REMOTE_ACTION_FAILURE,
                            key: timeoutError.key,
                            source: FailureSourceEnum.REMOTE,
                            error: timeoutError,
                        },
                        status: SequencerActionStatusEnum.FAILED,
                    });
                }, timeout.methodConfirmationMs);
            }
        });
        _play.promise.finally(() => {
            clearTimeout(_playCompleteTimeout);
            _paused = false;
            _remoteActionStarted = false;
            _waitingForStartConfirmation = false;
        });
        Khrono.createRemoteActionConfirmationEvent(RemoteEventEnum.ACTION_STARTED, remote.play, async (reason) => {
            const error = _createRemoteErrorPayload(reason, localeKeys.action.core.remote.playError);
            return _play.resolve({
                payload: {
                    action: {
                        state: _state,
                    },
                    code: FailureCodeEnum.REMOTE_ACTION_FAILURE,
                    error,
                    key: error.key,
                    source: FailureSourceEnum.REMOTE,
                },
                status: SequencerActionStatusEnum.FAILED,
            });
        }, interpolateActionConfig(getExtendedActionConfig(config), variableManager.getAllVars(variableNamespace)), index);
        return _play.promise;
    }
    async function resume() {
        debug("resume");
        assertRemoteMethodDefined(remote.resume);
        _assertActionPaused();
        let promiseTimeout;
        _resume = defer((_, reject) => {
            if (timeout.methodConfirmationMs > 0) {
                promiseTimeout = setTimeout(() => {
                    reject(new RemoteActionMethodTimeoutError(localeKeys.action.core.remote.resumeConfirmationTimeoutError, "Resume confirmation timeout has expired."));
                }, timeout.methodConfirmationMs);
            }
        });
        _resume.promise.then(() => {
            clearTimeout(promiseTimeout);
            _paused = false;
            _pauseDurationMs += new Date().getTime() - _pauseStartMs;
        }, (error) => _fail({
            action: {
                state: _state,
            },
            code: FailureCodeEnum.REMOTE_ACTION_FAILURE,
            error,
            key: error.key,
            source: FailureSourceEnum.REMOTE,
        }));
        Khrono.createRemoteActionConfirmationEvent(RemoteEventEnum.ACTION_RESUMED, remote.resume, async (reason) => {
            _resume?.reject(_createRemoteErrorPayload(reason, localeKeys.action.core.remote.resumeError));
        }, index);
        return _resume.promise;
    }
    async function setState(state, feedback, triggerOnStateChange = true) {
        debug("set state: ", state);
        const previousState = deepClone(_state);
        _state = state;
        if (triggerOnStateChange) {
            _stateChanged({
                feedback,
                previousState,
                state: _state,
            });
        }
        return {
            status: SequencerActionStatusEnum.CONTINUE,
            payload: _state,
        };
    }
    async function stop(causeFailure) {
        debug("stop");
        _assertActionStarted();
        let promiseTimeout;
        _stop = defer(async (_, reject) => {
            if (timeout.methodConfirmationMs > 0) {
                promiseTimeout = setTimeout(() => {
                    reject(new RemoteActionMethodTimeoutError(localeKeys.action.core.remote.stopConfirmationTimeoutError, "Stop confirmation timeout has expired."));
                }, timeout.methodConfirmationMs);
            }
        });
        _stop.promise.then(() => {
            clearTimeout(promiseTimeout);
            if (causeFailure) {
                return _fail({
                    action: {
                        state: _state,
                    },
                    code: FailureCodeEnum.STOP,
                    key: localeKeys.action.core.remote.stop,
                    source: FailureSourceEnum.REMOTE,
                });
            }
            return _play?.resolve({
                payload: null,
                status: SequencerActionStatusEnum.STOPPED,
            });
        }, (error) => _fail({
            action: {
                state: _state,
            },
            code: FailureCodeEnum.REMOTE_ACTION_FAILURE,
            error,
            key: error.key,
            source: FailureSourceEnum.REMOTE,
        }));
        Khrono.createRemoteActionConfirmationEvent(RemoteEventEnum.ACTION_STOPPED, remote.stop, async (reason) => {
            _stop?.reject(_createRemoteErrorPayload(reason, localeKeys.action.core.remote.stopError));
        }, index);
        return _stop.promise;
    }
    return {
        get state() {
            return deepClone(_state);
        },
        get type() {
            return ActionTypeEnum.REMOTE;
        },
        assertValidConfig,
        clientUpdate,
        onStateChange,
        pause,
        play,
        resume,
        setState,
        stop,
    };
}
export const SequencerActionRemoteFactory = {
    create,
};
