import {
    DataStoreStorageTypeEnum,
    IDataStoreReadItemConfigFilter,
    IJobDbModel,
    IJobProcessDbModel,
    IProcessDbModel,
    IResultKeyValue,
    IResultMasterDbModel,
    IResultProcessDbModel,
    IResultProcessStatusDbModel,
    IResultTableValueDbModel,
    ITreeNodeDbModel,
    IUserDbModel,
    Keys,
} from "@kortex/aos-common";

export const DATASTORE_GROUP = "groupId";
export const DATASTORE_SUBGROUP = "subGroupId";
export const DATASTORE_TABLE = "valueTableId";
export const DATASTORE_TABLE_COL_PREFIX = "col";
export const DATASTORE_TABLE_COL_INDEX_OFFSET = 1;

const JOB: Pick<Keys<IJobDbModel>, "jobRefId"> = {
    jobRefId: "jobRefId",
};

const JOB_PROCESS: Pick<Keys<IJobProcessDbModel>, "zoneIdsJSON"> = {
    zoneIdsJSON: "zoneIdsJSON",
};

const PROCESS: Pick<Keys<IProcessDbModel>, "version"> = {
    version: "version",
};

const RESULT_MASTER: Pick<Keys<IResultMasterDbModel>, "refId" | "trackingId"> = {
    trackingId: "trackingId",
};

const RESULT_KEY_VALUE: Keys<IResultKeyValue> = {
    groupId: "groupId",
    subGroupId: "subGroupId",
    tag: "tag",
    value: "value",
    metadata: "metadata",
};

const RESULT_PROCESS: Pick<Keys<IResultProcessDbModel>, "fail" | "pass"> = {
    fail: "fail",
    pass: "pass",
};

const RESULT_PROCESS_STATUS: Pick<Keys<IResultProcessStatusDbModel>, "fail" | "pass" | "qtyPlayed" | "trackingId"> = {
    fail: "fail",
    pass: "pass",
    qtyPlayed: "qtyPlayed",
    trackingId: "trackingId",
};

const RESULT_TABLE_VALUE: Pick<Keys<IResultTableValueDbModel>, "valueTableId"> = {
    valueTableId: "valueTableId",
};

const TREE_NODE: Pick<Keys<ITreeNodeDbModel>, "label"> = {
    label: "label",
};

const USER: Pick<Keys<IUserDbModel>, "userName"> = {
    userName: "userName",
};

// To add a new read item, add a new object in->dataStoreReadItemList
// This will automatically add a item in the StoreEditor Main FAB
// Then, set your filter like in the database table column name
// Go in the runner and the HUB to add the runner setup call and the hub database request
// Runner: DataStore.ts function handleReadItem
// Hub: DataStore.ts function handleReadItem
// Make a grep search -> api-process.ts function handleGetDataStoreReadItem
export const dataStoreReadItemList = [
    {
        storageType: DataStoreStorageTypeEnum.KEY_VALUE,
        nameString: "basicValue",
        filters: [
            JOB.jobRefId,
            RESULT_MASTER.trackingId,
            RESULT_KEY_VALUE.groupId,
            RESULT_KEY_VALUE.subGroupId,
            RESULT_KEY_VALUE.tag,
            RESULT_KEY_VALUE.value,
            RESULT_KEY_VALUE.metadata,
        ],
        defaultFilters: [RESULT_KEY_VALUE.groupId, RESULT_KEY_VALUE.subGroupId, RESULT_KEY_VALUE.tag],
    },
    {
        storageType: DataStoreStorageTypeEnum.TABLE,
        nameString: "table",
        filters: [JOB.jobRefId, RESULT_MASTER.trackingId, RESULT_TABLE_VALUE.valueTableId],
        defaultFilters: [JOB.jobRefId, RESULT_MASTER.trackingId, RESULT_TABLE_VALUE.valueTableId],
    },
    {
        storageType: DataStoreStorageTypeEnum.RESULTS,
        nameString: "results",
        filters: [
            JOB.jobRefId,
            TREE_NODE.label,
            RESULT_MASTER.trackingId,
            USER.userName,
            JOB_PROCESS.zoneIdsJSON,
            RESULT_PROCESS.pass,
            RESULT_PROCESS.fail,
            PROCESS.version,
        ],
        defaultFilters: [JOB.jobRefId, RESULT_MASTER.trackingId],
    },
    {
        storageType: DataStoreStorageTypeEnum.STATUS,
        nameString: "status",
        filters: [
            JOB.jobRefId,
            TREE_NODE.label,
            RESULT_PROCESS_STATUS.trackingId,
            RESULT_PROCESS_STATUS.pass,
            RESULT_PROCESS_STATUS.fail,
            PROCESS.version,
            RESULT_PROCESS_STATUS.qtyPlayed,
        ],
        defaultFilters: [JOB.jobRefId, RESULT_PROCESS_STATUS.trackingId],
    },
];

/**
 * Get Source filters from storage type
 *
 * @param {DataStoreStorageTypeEnum} storageType - storage type to get list
 */
export function getSourceFilters(storageType: DataStoreStorageTypeEnum): string[] {
    const readItem = dataStoreReadItemList.find((item) => item.storageType === storageType);
    if (readItem) {
        return readItem.filters as string[];
    }
    return [];
}

/**
 * Get Default filters from storage type
 *
 * @param {DataStoreStorageTypeEnum} storageType - storage type to get list
 *
 * @returns {IDataStoreReadItemConfigFilter} Default Filters
 */
export function getDefaultFilters(storageType: DataStoreStorageTypeEnum): IDataStoreReadItemConfigFilter[] {
    const readItem = dataStoreReadItemList.find((item) => item.storageType === storageType);
    let defaultFiltersName: string[];
    if (readItem) {
        defaultFiltersName = readItem.defaultFilters as string[];
    } else {
        defaultFiltersName = [];
    }
    const defaultFilters = defaultFiltersName.map((filterName): IDataStoreReadItemConfigFilter => {
        return {
            column: filterName,
            value: "",
            custom: false,
            customIndex: 0,
        };
    });
    return defaultFilters;
}

/**
 * Get string key from storage type
 *
 * @param {DataStoreStorageTypeEnum} storageType - storage type to get list
 */
export function getStringKey(storageType: DataStoreStorageTypeEnum): string {
    const readItem = dataStoreReadItemList.find((item) => item.storageType === storageType);
    if (readItem) {
        return readItem.nameString;
    }
    return "";
}
