import { useContext, useEffect, useState } from "react";
import shortid from "shortid";

import { Callback, COPY_PASTE_KEY, EventTypeCopy, Id } from "./event";
import { KeybindManagerContext } from "./useKeybindManager";

type CallbackClipboarEvent = Callback<ClipboardEvent>;

interface IOptions {
    /**
     * If `true`, the callback will not be called.
     */
    disabled?: boolean;

    /**
     * If `true`, trigger the next event bound to the same key. Only works if `disabled` is `true`.
     */
    next?: boolean;

    /**
     * The default action of the vent will not be taken as it normally would be.
     */
    preventDefault?: boolean;

    /**
     * Prevent further propagation of the current event in the capturing and bubbling phases.
     */
    stopPropagation?: boolean;
}

/**
 * Create a key event.
 */
export function useKeybindCopyPaste(
    copyCallback: CallbackClipboarEvent,
    pasteCallback: CallbackClipboarEvent,
    options: IOptions = {}
): void {
    const { disabled = false, next = false, preventDefault = false, stopPropagation = false } = options;

    const { emit, layers, getLastKeyId, off, on, pushKey, pushLayer, removeKey, removeLayer } = useContext(KeybindManagerContext);
    const [id] = useState(shortid.generate());

    /**
     * Get last ID of the event that was bound to the key
     */
    const getLastId = (indexModifier = 0): Id => getLastKeyId(COPY_PASTE_KEY, indexModifier);

    /**
     * Check if event should not be triggered.
     * Returns `true` if a `useStopKeybindPropagation` hook is present in the layer stack (FILO).
     */
    const isStopped = (): boolean => {
        for (let i = layers.length - 1; i >= 0; i--) {
            const [currentId, stop] = layers[i];

            if (currentId === id) return false;
            if (stop) return true;
        }

        return false;
    };

    /**
     * Trigger the callback if all conditions are statisfied.
     */
    const keyListener = (event: ClipboardEvent, eventType: EventTypeCopy): void => {
        // Validation & options
        if (isStopped()) return void 0;
        if (preventDefault) event.preventDefault();
        if (stopPropagation) event.stopPropagation();
        if (disabled) {
            if (next) emit(getLastId(1), eventType, event); // Trigger the next key event
            return void 0;
        }

        // Callback
        if (eventType === "copy") copyCallback(event);
        else pasteCallback?.(event);
    };

    /**
     * On mount/unmount, add/remove the key and ID to the keybind manager
     */
    useEffect(() => {
        pushKey(COPY_PASTE_KEY, id);
        pushLayer(id);

        return (): void => {
            removeKey(COPY_PASTE_KEY, id);
            removeLayer(id);
        };
    }, []);

    /**
     * Manage the keydown and keyup events
     */
    useEffect(() => {
        // Copy
        const copyListener: CallbackClipboarEvent = (event) => keyListener(event, "copy");
        on(id, "copy", copyListener);

        // Paste
        const pasteListener: CallbackClipboarEvent = (event): void => keyListener(event, "paste");
        on(id, "paste", pasteListener);

        // Destructor
        return (): void => {
            off(id, "copy", copyListener);
            off(id, "paste", pasteListener);
        };
    }, [copyCallback, disabled, pasteCallback]);
}
