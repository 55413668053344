var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Loop2(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { d: "M13.4254038,5.00949228 C13.3797451,5.0327836 13.349306,5.09195616 13.349306,5.18638045 L13.349306,6.76011866 L4.52195676,6.76011866 C4.13659731,6.76011866 3.7287129,6.91182702 3.43771477,7.21272577 C3.14671663,7.51362451 3,7.93538635 3,8.33385686 L3,15.8878003 C3,16.2862708 3.14671663,16.7080326 3.43771477,17.0089314 C3.7287129,17.3098301 4.13659731,17.4615385 4.52195676,17.4615385 L6.04391353,17.4615385 L6.04391353,15.5730526 L4.82634812,15.5730526 L4.82634812,8.64860451 L13.349306,8.64860451 L13.349306,10.2223427 C13.349306,10.4111913 13.4747152,10.4659574 13.5964718,10.3406878 L16.7548364,7.90139361 C16.876593,7.77549455 16.876593,7.63385811 16.7548364,7.50795905 L13.5964718,5.06866483 C13.5355935,5.00571531 13.4710625,4.98620095 13.4254038,5.00949228 Z M17.9560865,6.38524489 L17.9560865,8.27375325 L19.1736519,8.27375325 L19.1736519,15.1982839 L10.650694,15.1982839 L10.650694,13.6245269 C10.650694,13.4356761 10.5252848,13.3809094 10.4035282,13.5061804 L7.24516356,15.9455037 C7.12340702,16.0714043 7.12340702,16.2130424 7.24516356,16.338943 L10.4035282,18.7782663 C10.5252848,18.9041668 10.650694,18.8494001 10.650694,18.6599197 L10.650694,17.0861628 L19.4780432,17.0861628 C19.8634027,17.0861628 20.2712871,16.9344526 20.5622852,16.6335503 C20.8532834,16.3326479 21,15.9108811 21,15.5124058 L21,7.95837235 C21,7.55989709 20.8532834,7.13813022 20.5622852,6.83722789 C20.2712871,6.53632556 19.8634027,6.38461538 19.4780432,6.38461538 L17.9560865,6.38461538 L17.9560865,6.38524489 Z", id: "icons-loop2" })));
}
export default Loop2;
