import * as React from "react";
import { IconButton, makeStyles, Typography } from "@material-ui/core/";
import PreviewRight from "@material-ui/icons/ChevronRightOutlined";
import PreviewLeft from "@material-ui/icons/ChevronLeftOutlined";
import RotateRight from "@material-ui/icons/RotateRight";
import RotateLeft from "@material-ui/icons/RotateLeft";
import { useEffect, useState } from "react";
import { TValidRotationAngle } from "@kortex/aos-common";

import { useTranslate } from "../../../hooks/useTranslate";

export const PDF_PLAYER_HEIGHT = 50;

const useStyles = makeStyles({
    mainContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: `${PDF_PLAYER_HEIGHT}px`,
    },
    playerButton: {
        margin: "0px",
    },
});

export interface IPdfPlayerProps {
    allPages: boolean;
    totalPages: number;
    selectedPages?: number[];
    onPageChange?: (page: number) => void;
    onPageRotate?: (angle: TValidRotationAngle) => void;
}

type IAllProps = IPdfPlayerProps;

export default function PdfPlayer(props: IAllProps): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageOffsetArray, setPageOffsetArray] = useState<number[]>([]);
    const [pageRotateAngle, setPageRotateAngle] = useState<TValidRotationAngle>(0);
    const [pageTotal, setPageTotal] = useState<number>(props.totalPages);

    /**
     * Setup Page Offset Array for PDF document with all the pages
     */
    const setupAllPages = (): void => {
        const pagesArray: number[] = [];
        // Build Up Page Number Array
        for (let count = 1; count <= props.totalPages; count++) {
            pagesArray.push(count);
        }
        setPageOffsetArray(pagesArray);

        // Calculate the total number of pages
        setPageTotal(pagesArray.length);
        if (props.onPageChange) {
            props.onPageChange(pagesArray[0]); // Here, it must be the real page inside de full document
        }
    };

    /**
     * Called when the user select selected pages... All pages have always priority
     */
    useEffect((): void => {
        // Build Up Page Number Array
        if (!props.allPages && props.selectedPages && props.selectedPages.length > 0) {
            setPageOffsetArray(props.selectedPages);

            // Calculate the total number of pages
            setPageTotal(props.selectedPages.length);
            if (props.onPageChange) {
                props.onPageChange(props.selectedPages[0]); // Here, it must be the real page inside de full document
            }
        }
    }, [props.selectedPages]);

    /**
     * Called when the user change the number of pages or allPages flag is set/unset
     */
    useEffect((): void => {
        setPageNumber(1);
        if (props.allPages) {
            setupAllPages();
        }
    }, [props.totalPages, props.allPages]);

    /**
     * Handle pdf page up request
     */
    const handlePageUp = (): void => {
        if (pageNumber < pageTotal) {
            const nextPage = pageNumber + 1;
            setPageNumber(nextPage); // Here, it's the page in the linear list of pages

            if (props.onPageChange) {
                props.onPageChange(pageOffsetArray[nextPage - 1]); // Here, it must be the real page inside de full document
            }
        }
    };

    /**
     * Handle pdf page down request
     */
    const handlePageDown = (): void => {
        if (pageNumber > 1) {
            const nextPage = pageNumber - 1;
            setPageNumber(nextPage);

            if (props.onPageChange) {
                props.onPageChange(pageOffsetArray[nextPage - 1]);
            }
        }
    };

    /**
     * Utility function that add 90 degs wrapping to 360
     *
     * @param {object} angle - Angle to add 90 degs
     */
    const angleAdd = (angle: TValidRotationAngle): TValidRotationAngle => {
        switch (angle) {
            case 0:
                return 90;
            case 90:
                return 180;
            case 180:
                return 270;
            case 270:
            default:
                return 0;
        }
    };

    /**
     * Utility function that remove 90 degs wrapping to 360
     *
     * @param {object} angle - Angle to remove 90 degs
     */
    const angleRemove = (angle: TValidRotationAngle): TValidRotationAngle => {
        switch (angle) {
            case 90:
                return 0;
            case 180:
                return 90;
            case 270:
                return 180;
            case 0:
            default:
                return 270;
        }
    };

    /**
     * Handle pdf page rotate right request
     */
    const handlePageRotateRight = (): void => {
        const newAngle: TValidRotationAngle = angleAdd(pageRotateAngle);
        setPageRotateAngle(newAngle);
        if (props.onPageRotate) {
            props.onPageRotate(newAngle);
        }
    };

    /**
     * Handle pdf page rotate left request
     */
    const handlePageRotateLeft = (): void => {
        const newAngle: TValidRotationAngle = angleRemove(pageRotateAngle);
        setPageRotateAngle(newAngle);
        if (props.onPageRotate) {
            props.onPageRotate(newAngle);
        }
    };

    return (
        <div className={classes.mainContainer}>
            <IconButton
                id="prevPageButtonId"
                disabled={pageNumber <= 1}
                size="small"
                className={classes.playerButton}
                onClick={handlePageDown}
            >
                <PreviewLeft />
            </IconButton>
            <span id="pageOfId">
                <Typography>{translate("pdfPlayer.page")}</Typography>
                {pageNumber}
                <Typography>{translate("pdfPlayer.pageOf")}</Typography>
                {pageTotal}
            </span>
            <IconButton
                id="nextPageButtonId"
                disabled={pageNumber >= pageTotal}
                size="small"
                className={classes.playerButton}
                onClick={handlePageUp}
            >
                <PreviewRight />
            </IconButton>
            <IconButton id="rotateLeftButtonId" size="small" className={classes.playerButton} onClick={handlePageRotateLeft}>
                <RotateLeft />
            </IconButton>
            <IconButton id="rotateRightButtonId" size="small" className={classes.playerButton} onClick={handlePageRotateRight}>
                <RotateRight />
            </IconButton>
        </div>
    );
}
