import { theme } from "@aos/react-components";
import { IProcessUiModel, ProcessId, ProcessWhereUsed, getActiveProcessWhereUsed, getNonArchiveProcessWhereUsed } from "@kortex/aos-common";
import { deepClone } from "@kortex/utilities";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
} from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SelectAllIcon from "@material-ui/icons/SelectAll";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import { client } from "../../../utilitites/kortex-client/client";
import { getJobProcessStatusLabelKey, getJobStatusLabelKey } from "../Scheduler/SchedulerData";

const LATEST_INDEX = -1;

const useStyles = makeStyles({
    content: {
        height: "60vh",
        maxWidth: "1102px",
        width: "80vw",
    },
    contentLoading: {
        alignItems: "center",
        display: "flex",
        height: "60vh",
        justifyContent: "center",
        maxWidth: "1102px",
        width: "80vw",
    },
    emptyWhereUsedMessage: {
        alignItems: "center",
        display: "flex",
        height: "100%",
        justifyContent: "center",
        width: "100%",
    },
    pannelDetailsContent: {
        width: "100%",
    },
    pannelSummary: {
        backgroundColor: theme.palette.grey[300],
        minHeight: "32px",
    },
    pannelSummaryContent: {
        alignItems: "center",
        display: "inline-flex",
        width: "100%",
    },
    topOptions: {
        display: "flex",
        flexDirection: "row",
    },
    topOptionsFlex: {
        display: "flex",
        flexGrow: 1,
    },
    showInactiveCheckbox: {
        display: "flex",
        alignItems: "center",
    },
    spacer: {
        paddingBottom: "12px",
    },
    tableCell: {
        padding: "12px",
    },
    tableHeader: {
        backgroundColor: theme.palette.grey[200],
    },
    typographyProcessVersion: {
        flex: 1,
    },
    buttons: {
        margin: "10px",
        padding: "10px",
    },
});

interface IOwnProps {
    showEmptyMessage?: boolean; // If no "Where used" found, display a message
    treeNodeId: number;
    updateToProcess: IProcessUiModel;

    onLoad?: (whereUsedFound: ProcessWhereUsed[]) => void;
    onUpdateJobProcessList?: (jobProcessList: number[]) => void;
}

export default function ProcessUpdateVersion(props: IOwnProps): JSX.Element {
    const { onLoad, showEmptyMessage, treeNodeId, updateToProcess } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [openedPanels, setOpenedPanels] = useState<number[]>([]);
    const [showInactive, setShowInactive] = useState<boolean>(false);
    const [whereUsed, setWhereUsed] = useState<ProcessWhereUsed[]>([]);
    const [filteredWhereUsed, setFilteredWhereUsed] = useState<ProcessWhereUsed[]>([]);
    const [whereUsedLoaded, setWhereUsedLoaded] = useState<boolean>(false);
    const [jobProcessIdToUpdate, setJobProcessIdToUpdate] = useState<number[]>([]);

    /**
     * UseEffect used to call the callback when jobProcessIdToUpdate changed
     */
    useEffect((): void => {
        props.onUpdateJobProcessList && props.onUpdateJobProcessList(jobProcessIdToUpdate);
    }, [jobProcessIdToUpdate]);

    /**
     * Set inner state when "Show inactive" checkbox is checked or unchecked
     */
    const handleCheckShowInactive = (): void => {
        setShowInactive(!showInactive);
    };

    /**
     * Open/close the clicked expansion pannel
     */
    const handleSummaryClick =
        (processId?: ProcessId): (() => void) =>
        (): void => {
            if (!processId) {
                return;
            }

            const index = openedPanels.findIndex((id) => id === processId);

            if (index === -1) {
                // Panel was closed
                setOpenedPanels([...openedPanels, processId]);
            } else {
                // Panel was opened
                const closedPanelIds = [...openedPanels];
                closedPanelIds.splice(index, 1);
                setOpenedPanels(closedPanelIds);
            }
        };

    /**
     * Get filtered where used
     * If showInactive is true, show all where used
     * else only show active ones
     */
    const getFilteredWhereUsed = (): ProcessWhereUsed[] => {
        const cleanList = removeCurrentVersion(whereUsed, updateToProcess.processId);
        if (showInactive) {
            return getNonArchiveProcessWhereUsed(cleanList);
        }

        return getActiveProcessWhereUsed(cleanList);
    };

    /**
     * Filter an array of "Where used" and returns those that are used by active job processes or routings
     *
     * @param {ProcessWhereUsed[]} whereUsed - where used
     * @param {number} processId - process id to remove
     */
    const removeCurrentVersion = (whereUsed: ProcessWhereUsed[], processId: number): ProcessWhereUsed[] => {
        return (
            deepClone(whereUsed) // Copy where used (parameter)
                .map((whereUsedItem) => {
                    return {
                        jobs: whereUsedItem.jobs.map((whereUsedJob) => {
                            return {
                                job: whereUsedJob.job,
                                // Remove inactive job processes
                                // A job process is inactive when it is not READY or IN PROGRESS
                                jobProcesses: whereUsedJob.jobProcesses.filter((jobProcess) => jobProcess.processId !== processId),
                            };
                        }),
                        process: whereUsedItem.process, // Process does not change
                        routings: whereUsedItem.routings, // Remove all archived and retired routings
                    };
                })
                // Remove empty Where Used items
                .filter((whereUsedItem) => whereUsedItem.jobs.length || whereUsedItem.routings.length)
        );
    };

    /**
     * Load all where used
     */
    const loadWhereUsed = async (): Promise<void> => {
        let loadedWhereUsed: ProcessWhereUsed[] = [];

        loadedWhereUsed = [
            ...(await client.services.process.whereUsedAllVersions({ nodeId: treeNodeId, jobOnly: true })({ timeout: 10_000 })),
        ];

        // If there is only one "Where used" found, expand its panel
        if (loadedWhereUsed.length >= 1) {
            setOpenedPanels([loadedWhereUsed[0].process?.processId ?? LATEST_INDEX]);
        }

        // Set inner state
        setWhereUsed(loadedWhereUsed);
        // Remove the loading icon
        setWhereUsedLoaded(true);

        if (onLoad) {
            onLoad(loadedWhereUsed);
        }
    };

    /**
     * Handle selection of job process to update
     */
    const handleUpdateJobProcess = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        const jobProcessId: number = parseInt(event.target.value);
        const isPresent = isChecked(jobProcessId);

        if (checked && !isPresent) {
            setJobProcessIdToUpdate([...jobProcessIdToUpdate, jobProcessId]);
        } else if (!checked && isPresent) {
            const cleanArray = jobProcessIdToUpdate.filter((inArrayJobProcessId) => inArrayJobProcessId !== jobProcessId);
            setJobProcessIdToUpdate(cleanArray);
        }
    };

    /**
     * Return true if jobProcessId is selected
     */
    const isChecked = (jobProcessId: number): boolean => {
        return jobProcessIdToUpdate.find((inArrayJobProcessId) => inArrayJobProcessId === jobProcessId) != undefined;
    };

    /**
     * Select all the jobProcess based on the currently filters
     */
    const selectAll = (): void => {
        const all: number[] = [];
        filteredWhereUsed.map((whereUsedItem) => {
            whereUsedItem.jobs.map((whereUsedItemJob) => {
                whereUsedItemJob.jobProcesses.map((whereUsedItemJobProcess) => {
                    all.push(whereUsedItemJobProcess.jobProcessId);
                });
            });
        });
        setJobProcessIdToUpdate(all);
    };

    /**
     * Select all the jobProcess
     */
    const clearAll = (): void => {
        setJobProcessIdToUpdate([]);
    };

    /**
     * Find where tree node is used when page is opened
     */
    useEffect((): void => {
        loadWhereUsed();
    }, [updateToProcess, treeNodeId]);

    /**
     * Find where tree node is used when page is opened
     */
    useEffect((): void => {
        // Get filtered list of where used
        setFilteredWhereUsed(getFilteredWhereUsed());
    }, [whereUsedLoaded, showInactive]);

    return (
        <React.Fragment>
            {
                /* LOADING ICON */
                !Boolean(whereUsedLoaded) ? (
                    <div className={classes.contentLoading}>
                        <CircularProgress />
                    </div>
                ) : (
                    <div />
                )
            }
            {
                /* WHERE USED LOADED */
                whereUsedLoaded && (
                    <div className={classes.content}>
                        {Boolean(whereUsed.length) && (
                            <div className={classes.topOptions}>
                                <div className={classes.showInactiveCheckbox}>
                                    <Typography variant={"body2"}>{translate("processUpdate.showInactive")}</Typography>
                                    <Checkbox checked={showInactive} onChange={handleCheckShowInactive} />
                                </div>
                                <div className={classes.topOptionsFlex} />
                                <div>
                                    <Button
                                        id="selectAllButtonId"
                                        variant="contained"
                                        color="secondary"
                                        onClick={selectAll}
                                        className={classes.buttons}
                                        startIcon={<SelectAllIcon />}
                                    >
                                        {translate("processUpdate.selectAll")}
                                    </Button>
                                    <Button
                                        id="clearAllButtonId"
                                        variant="contained"
                                        color="secondary"
                                        onClick={clearAll}
                                        className={classes.buttons}
                                        startIcon={<ClearAllIcon />}
                                    >
                                        {translate("processUpdate.clearAll")}
                                    </Button>
                                </div>
                            </div>
                        )}
                        {Boolean(filteredWhereUsed.length) ? (
                            filteredWhereUsed
                                .sort((a, b) => parseFloat(b.process?.version ?? "0") - parseFloat(a.process?.version ?? "0"))
                                .map((item, index) => {
                                    const expanded = Boolean(openedPanels.includes(item.process?.processId ?? LATEST_INDEX));

                                    return (
                                        <Accordion key={index} expanded={expanded}>
                                            <AccordionSummary
                                                className={classes.pannelSummary}
                                                onClick={handleSummaryClick(item.process?.processId ?? LATEST_INDEX)}
                                            >
                                                <div className={classes.pannelSummaryContent}>
                                                    <Typography className={classes.typographyProcessVersion} variant="h4">
                                                        {item.process ? item.process.version : "Latest"}
                                                    </Typography>
                                                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className={classes.pannelDetailsContent}>
                                                    {
                                                        /* JOB LIST */
                                                        item.jobs.length ? (
                                                            <React.Fragment>
                                                                <Typography variant="h6">{translate("processUpdate.jobs")}</Typography>
                                                                <Table>
                                                                    <TableHead className={classes.tableHeader}>
                                                                        <TableRow>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography variant={"body2"}>
                                                                                    {translate("processUpdate.refId")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography variant={"body2"}>
                                                                                    {translate("processUpdate.reference")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography variant={"body2"}>
                                                                                    {translate("processUpdate.partNumber")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography variant={"body2"}>
                                                                                    {translate("processUpdate.jobStatus")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography variant={"body2"}>
                                                                                    {translate("processUpdate.jobProcessStatus")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography variant={"body2"}>
                                                                                    {translate("processUpdate.remaining")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell className={classes.tableCell}>
                                                                                <Typography variant={"body2"}>
                                                                                    {translate("general.update")}
                                                                                </Typography>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {item.jobs.map((whereUsedJob) =>
                                                                            whereUsedJob.jobProcesses.map((jobProcess, jobProcessIndex) => (
                                                                                <TableRow key={jobProcessIndex}>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography variant={"body2"}>
                                                                                            {whereUsedJob.job.jobRefId}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography variant={"body2"}>
                                                                                            {whereUsedJob.job.referenceId}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography variant={"body2"}>
                                                                                            {whereUsedJob.job.partNumber}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography variant={"body2"}>
                                                                                            {translate(
                                                                                                getJobStatusLabelKey(
                                                                                                    whereUsedJob.job.status
                                                                                                )
                                                                                            )}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography variant={"body2"}>
                                                                                            {translate(
                                                                                                getJobProcessStatusLabelKey(
                                                                                                    jobProcess.status
                                                                                                )
                                                                                            )}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    <TableCell className={classes.tableCell}>
                                                                                        <Typography variant={"body2"}>
                                                                                            {jobProcess.qty - jobProcess.qtyPassed}
                                                                                        </Typography>
                                                                                    </TableCell>
                                                                                    {props.updateToProcess && (
                                                                                        <TableCell className={classes.tableCell}>
                                                                                            <Checkbox
                                                                                                checked={isChecked(jobProcess.jobProcessId)}
                                                                                                onChange={handleUpdateJobProcess}
                                                                                                value={jobProcess.jobProcessId}
                                                                                            />
                                                                                        </TableCell>
                                                                                    )}
                                                                                </TableRow>
                                                                            ))
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </React.Fragment>
                                                        ) : (
                                                            <div />
                                                        )
                                                    }
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    );
                                })
                        ) : Boolean(showEmptyMessage) ? (
                            /* WHERE USED LOADED BUT NO MATCH FOUND (PROCESS IS NOT USED BY ANY ROUTING OR JOB) */
                            <div className={classes.emptyWhereUsedMessage}>
                                {!Boolean(whereUsed.length) ? (
                                    <Typography>{translate("processUpdate.empty")}</Typography>
                                ) : (
                                    <Typography>{translate("processUpdate.emptyActive")}</Typography>
                                )}
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                )
            }
        </React.Fragment>
    );
}
