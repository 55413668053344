import { ErpSettingsGetRes, UnwrapAOSPayload } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";

import { setErpSettingsListAction } from "./erp-settings-actions";

// thunks

/**
 * Retrieves erp setting
 *
 */
export function getErpSettings(): StandardThunk<UnwrapAOSPayload<ErpSettingsGetRes> | undefined> {
    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ErpSettingsGetRes> | undefined> {
        return api.services.erpSettings
            .get(emptyObject)()
            .then((settings) => {
                dispatch(setErpSettingsListAction({ ...settings }));

                return { ...settings };
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return undefined;
            });
    };
}
