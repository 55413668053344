import debug from "debug";
const DEBUG_NAMEPACE = {
    KORTEX: "kortex-sequencer",
    ACTION: "action",
    ACTION_GROUP: "action-group",
    IMPLEMENTER: "implementer",
    KHRONO: "khrono",
    SEQUENCER: "sequencer",
    STATE_HISTORY: "state-history",
    VARIABLE_MANAGER: "variable-manager",
    WORKER: "worker",
    WORKER_MANAGER: "worker-manager",
    CORE_CONDITION: "core-condition",
    CORE_FAIL: "core-fail",
    CORE_GO_TO: "core-go-to",
    CORE_INPUT: "core-input",
    CORE_LOOP: "core-loop",
    CORE_MESSAGE: "core-message",
    CORE_REMOTE: "core-remote",
    CORE_SET: "core-set",
    CORE_SUB_PROGRAM: "core-sub-program",
    CORE_THREAD: "core-thread",
    CORE_TIMER: "core-timer",
    CORE_WAIT_CONDITION: "core-wait-condition",
    CORE_WAIT_TIME: "core-wait-time",
};
const SEPARATOR = ":";
function joinDebugNamespace(...keys) {
    return keys.map((key) => DEBUG_NAMEPACE[key]).join(SEPARATOR);
}
const wrapDebug = (namespace) => {
    return (...args) => {
        debug(namespace)(...args);
    };
};
export const KortexSequencerDebugger = {
    action: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION")),
    actionGroup: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP")),
    core: {
        condition: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_CONDITION")),
        fail: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_FAIL")),
        goTo: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_GO_TO")),
        input: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_INPUT")),
        loop: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_LOOP")),
        message: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_MESSAGE")),
        remote: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_REMOTE")),
        set: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_SET")),
        subProgram: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_SUB_PROGRAM")),
        thread: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_THREAD")),
        timer: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_TIMER")),
        wait: {
            condition: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_WAIT_CONDITION")),
            time: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "ACTION_GROUP", "ACTION", "CORE_WAIT_TIME")),
        },
    },
    implementer: wrapDebug(joinDebugNamespace("KORTEX", "IMPLEMENTER")),
    khrono: wrapDebug(joinDebugNamespace("KHRONO")),
    stateHistory: wrapDebug(joinDebugNamespace("KORTEX", "IMPLEMENTER", "STATE_HISTORY")),
    sequencer: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER")),
    variableManager: wrapDebug(joinDebugNamespace("KORTEX", "VARIABLE_MANAGER")),
    worker: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "WORKER")),
    workerManager: wrapDebug(joinDebugNamespace("KORTEX", "SEQUENCER", "WORKER_MANAGER")),
    FORMATTER: {
        JSON: "%j",
        NUMBER: "%d",
        OBJECT_MULTIPLE_LINE: "%O",
        OBJECT_SINGLE_LINE: "%o",
        STRING: "%s",
    },
};
