import { upsertObjectFromArray } from "@kortex/aos-common";

import { TreeActions, TreeState, TreeActionType } from "./tree-types";

const initialState: TreeState = {
    editedTreeProcessNodeId: undefined,
    files: [],
    processes: [],
    workZones: [],
};

/**
 * Tree reducer
 *
 * @param {TreeState} [state=initialState] - tree state
 * @param {TreeActions} action - tree actions
 *
 * @returns {TreeState}
 */
export function treeReducer(state: TreeState = initialState, action: TreeActions): TreeState {
    switch (action.type) {
        case TreeActionType.CLEAR_TREES: {
            return {
                ...initialState,
            };
        }
        case TreeActionType.INSERT_FILE: {
            return {
                ...state,
                files: upsertObjectFromArray(state.files, action.value, function (node) {
                    return node.treeNodeId === this.treeNodeId;
                }),
            };
        }
        case TreeActionType.INSERT_PROCESS: {
            return {
                ...state,
                processes: upsertObjectFromArray(state.processes, action.value, function (node) {
                    return node.treeNodeId === this.treeNodeId;
                }),
            };
        }
        case TreeActionType.INSERT_WORK_ZONE: {
            return {
                ...state,
                workZones: upsertObjectFromArray(state.workZones, action.value, function (node) {
                    return node.treeNodeId === this.treeNodeId;
                }),
            };
        }
        case TreeActionType.SET_EDITED_TREE_PROCESS_NODE_ID: {
            return {
                ...state,
                editedTreeProcessNodeId: action.value,
            };
        }
        case TreeActionType.SET_FILES: {
            return {
                ...state,
                files: [...action.value],
            };
        }
        case TreeActionType.SET_PROCESSES: {
            return {
                ...state,
                processes: [...action.value],
            };
        }
        case TreeActionType.SET_WORK_ZONES: {
            return {
                ...state,
                workZones: [...action.value],
            };
        }
        case TreeActionType.UPDATE_FILE: {
            return {
                ...state,
                files: upsertObjectFromArray(state.files, action.value, function (node) {
                    return node.treeNodeId === this.treeNodeId;
                }),
            };
        }
        case TreeActionType.UPDATE_PROCESS: {
            return {
                ...state,
                processes: upsertObjectFromArray(state.processes, action.value, function (node) {
                    return node.treeNodeId === this.treeNodeId;
                }),
            };
        }
        case TreeActionType.UPDATE_WORK_ZONE: {
            return {
                ...state,
                workZones: upsertObjectFromArray(state.workZones, action.value, function (node) {
                    return node.treeNodeId === this.treeNodeId;
                }),
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
}
