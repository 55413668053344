import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    JobProcessesAllReq,
    JobProcessesAllRes,
    JobProcessUpdatedNotif,
    JobProcessDeletedNotif,
    JobProcessInsertedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { jobProcessesAll } from "./allJobProcesses";
import { jobProcessUpdated } from "./updated";
import { jobProcessDeleted } from "./deleted";
import { jobProcessInserted } from "./inserted";

interface IAOSClientServiceWrappedJob {
    getAllJobProcesses: AOSClientServiceRequestWrapped<JobProcessesAllReq, JobProcessesAllRes>;
    onUpdated: AOSClientServiceNotificationWrapped<JobProcessUpdatedNotif>;
    onDeleted: AOSClientServiceNotificationWrapped<JobProcessDeletedNotif>;
    onInserted: AOSClientServiceNotificationWrapped<JobProcessInsertedNotif>;
}

type AOSClientServiceWrappedJob = IAOSClientServiceWrappedJob;
// ADD_API_CALL Job
export function generateWorkScheduleServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedJob {
    return {
        getAllJobProcesses: AOSClientServiceWrapperUI.request(jobProcessesAll(router)),
        onUpdated: AOSClientServiceWrapperUI.notification(jobProcessUpdated(router, notifier)),
        onDeleted: AOSClientServiceWrapperUI.notification(jobProcessDeleted(router, notifier)),
        onInserted: AOSClientServiceWrapperUI.notification(jobProcessInserted(router, notifier)),
    };
}

generateWorkScheduleServiceClient.serviceId = 35;
