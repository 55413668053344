var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
export default function Input(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M20.3636364,3 L5.63636364,3 C4.73636364,3 4,3.90100111 4,5.00222469 L4,8.99666296 L5.63636364,8.99666296 L5.63636364,4.98220245 L20.3636364,4.98220245 L20.3636364,19.0278087 L5.63636364,19.0278087 L5.63636364,15.003337 L4,15.003337 L4,19.0177976 C4,20.1190211 4.73636364,21 5.63636364,21 L20.3636364,21 C21.2636364,21 22,20.1190211 22,19.0177976 L22,5.00222469 C22,3.89098999 21.2636364,3 20.3636364,3 Z M8.42857143,15 L11,12 L8.42857143,9 L8.42857143,11.25 L2,11.25 L2,12.75 L8.42857143,12.75 L8.42857143,15 Z", id: "icons-input" })));
}
