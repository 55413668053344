import { SequencerError } from "./sequencer-error";
export class CoreActionConfigError extends SequencerError {
    constructor(key, message = "Core action configuration error") {
        super(key, message);
    }
    get name() {
        return "CoreActionConfigError";
    }
    get originalError() {
        return super.originalError;
    }
}
