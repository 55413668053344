import React, { FC, useEffect } from "react";

import { useBomContext } from "../../context";

const BomPartNumber: FC = () => {
    const { search } = useBomContext();

    useEffect(() => {
        // ...
    }, [search.value]);

    return <div>Part Number</div>;
};

export default BomPartNumber;
