import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    TreeProcessAllReq,
    TreeProcessAllRes,
    TreeProcessArchiveReq,
    TreeProcessArchiveRes,
    TreeProcessInsertReq,
    TreeProcessInsertRes,
    TreeProcessInsertedNotif,
    TreeProcessUpdateBomReq,
    TreeProcessUpdateBomRes,
    TreeProcessUpdateReq,
    TreeProcessUpdateRes,
    TreeProcessUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { treeProcessAll } from "./all";
import { treeProcessArchive } from "./archive";
import { treeProcessInsert } from "./insert";
import { treeProcessInserted } from "./inserted";
import { treeProcessUpdate } from "./update";
import { treeProcessUpdateBom } from "./updateBom";
import { treeProcessUpdated } from "./updated";

interface IAOSClientServiceWrappedTreeProcess {
    archive: AOSClientServiceRequestWrapped<TreeProcessArchiveReq, TreeProcessArchiveRes>;
    getAll: AOSClientServiceRequestWrapped<TreeProcessAllReq, TreeProcessAllRes>;
    insert: AOSClientServiceRequestWrapped<TreeProcessInsertReq, TreeProcessInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<TreeProcessInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<TreeProcessUpdatedNotif>;
    update: AOSClientServiceRequestWrapped<TreeProcessUpdateReq, TreeProcessUpdateRes>;
    updateBom: AOSClientServiceRequestWrapped<TreeProcessUpdateBomReq, TreeProcessUpdateBomRes>;
}

type AOSClientServiceWrappedTreeProcess = IAOSClientServiceWrappedTreeProcess;

// ADD_API_CALL Tree process
export function generateTreeProcessServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedTreeProcess {
    return {
        archive: AOSClientServiceWrapperUI.request(treeProcessArchive(router)),
        getAll: AOSClientServiceWrapperUI.request(treeProcessAll(router)),
        insert: AOSClientServiceWrapperUI.request(treeProcessInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(treeProcessInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(treeProcessUpdated(router, notifier)),
        update: AOSClientServiceWrapperUI.request(treeProcessUpdate(router)),
        updateBom: AOSClientServiceWrapperUI.request(treeProcessUpdateBom(router)),
    };
}

generateTreeProcessServiceClient.serviceId = 51;
