export class WorkInstructionsPDFConfig {
    constructor() {
        this.url = "";
        this.allPages = true;
        this.totalPages = 1;
        this.selectedPages = [1];
        this.rotate = 0;
        this.originalWidth = 100;
        this.originalHeight = 100;
    }
}
