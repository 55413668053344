import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    UserTrainingGetAllTrainingsByUserIdReq,
    UserTrainingGetAllTrainingsByUserIdRes,
    UserTrainingGetTrainingHistoryByUserIdAndTreeNodeIdReq,
    UserTrainingGetTrainingHistoryByUserIdAndTreeNodeIdRes,
    UserTrainingInsertReq,
    UserTrainingInsertRes,
    UserTrainingInsertedNotif,
    UserTrainingUpdateStatusReq,
    UserTrainingUpdateStatusRes,
    UserTrainingUpdatedNotif,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { trainingGetAllTrainingsByUserId } from "./getAllTrainingsByUserId";
import { trainingGetTrainingHistoryByUserIdAndTreeNodeId } from "./getTrainingHistoryByUserIdAndTreeNodeId";
import { userTrainingInsert } from "./insert";
import { userTrainingInserted } from "./inserted";
import { userTrainingUpdateStatus } from "./updateStatus";
import { userTrainingUpdated } from "./updated";

interface IAOSClientServiceWrappedUserTraining {
    // USER TRAINING
    getAllTrainingsByUserId: AOSClientServiceRequestWrapped<UserTrainingGetAllTrainingsByUserIdReq, UserTrainingGetAllTrainingsByUserIdRes>;
    getTrainingHistoryByUserIdAndTreeNodeId: AOSClientServiceRequestWrapped<
        UserTrainingGetTrainingHistoryByUserIdAndTreeNodeIdReq,
        UserTrainingGetTrainingHistoryByUserIdAndTreeNodeIdRes
    >;
    insert: AOSClientServiceRequestWrapped<UserTrainingInsertReq, UserTrainingInsertRes>;
    onInserted: AOSClientServiceNotificationWrapped<UserTrainingInsertedNotif>;
    onUpdated: AOSClientServiceNotificationWrapped<UserTrainingUpdatedNotif>;
    updateStatus: AOSClientServiceRequestWrapped<UserTrainingUpdateStatusReq, UserTrainingUpdateStatusRes>;
}

type AOSClientServiceWrappedUserTraining = IAOSClientServiceWrappedUserTraining;

// ADD_API_CALL Formation
export function generateUserTrainingServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedUserTraining {
    return {
        // USER TRAINING
        getAllTrainingsByUserId: AOSClientServiceWrapperUI.request(trainingGetAllTrainingsByUserId(router)),
        getTrainingHistoryByUserIdAndTreeNodeId: AOSClientServiceWrapperUI.request(trainingGetTrainingHistoryByUserIdAndTreeNodeId(router)),
        insert: AOSClientServiceWrapperUI.request(userTrainingInsert(router)),
        onInserted: AOSClientServiceWrapperUI.notification(userTrainingInserted(router, notifier)),
        onUpdated: AOSClientServiceWrapperUI.notification(userTrainingUpdated(router, notifier)),
        updateStatus: AOSClientServiceWrapperUI.request(userTrainingUpdateStatus(router)),
    };
}

generateUserTrainingServiceClient.serviceId = 131;
