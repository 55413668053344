import { IUserDbModel, ProcessId, UserId } from "@kortex/aos-common";
import { useClientService } from "@kortex/aos-ui/hooks/useClientService";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { useSelectorUserSession } from "@kortex/aos-ui/redux/selectors";
import React, { FC, useEffect, useState } from "react";

import { UserValidationDialog, UserValidationDialogProps } from "../../UserValidationDialog";

interface IOwnProps
    extends Omit<UserValidationDialogProps, "userFilterCb" | "validationGroupsId">,
        Required<Pick<UserValidationDialogProps, "validationGroupsId">> {
    dryRun?: boolean;
    processId: ProcessId;
}

const StepValidationDialog: FC<IOwnProps> = (props) => {
    const { dryRun, labelContext, loadingUsers = false, processId, validationGroupsId, ...userValidationProps } = props;

    const getTrainedUsers = useClientService("users", "getTrainedUsers");
    const session = useSelectorUserSession();
    const translate = useTranslate();

    const [loading, setLoading] = useState<boolean>(false);
    const [trainedUserIds, setTrainedUserIds] = useState<UserId[]>([]);

    const areUsersLoading = loading && loadingUsers;

    useEffect((): void => {
        if (!userValidationProps.open) return void 0;

        setLoading(true);

        getTrainedUsers({ processId, certified: false, userGroupIds: validationGroupsId })
            .then((trainedUsers): void => {
                if (!trainedUsers?.length) {
                    setTrainedUserIds([]);
                    return void 0;
                }

                const userIds = trainedUsers.map((user) => user.userId);

                if (dryRun && session) userIds.unshift(session.userId); // For a dry run only: add the logged in user to the list

                setTrainedUserIds(userIds);
            })
            .finally(() => setLoading(false));
    }, [userValidationProps.open]);

    const userFilter = (user: IUserDbModel): boolean => {
        return trainedUserIds.includes(user.userId);
    };

    return (
        <UserValidationDialog
            {...userValidationProps}
            labelContext={labelContext || translate("player.validationRequiredByTrainedUser")}
            loadingUsers={areUsersLoading}
            userFilterCb={userFilter}
        />
    );
};

export default StepValidationDialog;
