import { KortexDateTimePicker, KortexTextField } from "@aos/react-components";
import { IOrgSettingDbModel, TimeBaseSettingEnum, UserGroupStatusEnum, getTimestampFromDateStr } from "@kortex/aos-common";
import { useEntitiesUsersGroups } from "@kortex/aos-ui/redux/effects";
import { useSelectorTimezone } from "@kortex/aos-ui/redux/selectors";
import { Checkbox, Divider, FormControl, Input, InputLabel, MenuItem, Select, Typography, makeStyles } from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import GlobalSettingElement from "../GlobalSettingElement";
import { globalSettingsStyles } from "../GlobalSettingsCommon";

export const useStyles = makeStyles({
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        padding: "10px 10px",
        height: "130px",
    },
    excludedGroupUserIdSelector: {
        fontSize: "1rem",
        lineHeight: "1rem",
        maxWidth: "300px",
        padding: "5px",
    },
    excludedGroupUserIdFormControl: {
        margin: "5px 16px 5px 5px",
    },
    option: {
        display: "flex",
        flexDirection: "column",
        padding: "5px",
    },
    root: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    section: {
        display: "flex",
    },
    sectionRight: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    subSection: {
        display: "flex",
        flexDirection: "row",
        padding: "5px",
    },
    dateTimeField: {
        margin: "5px 16px 5px 5px",
    },
    dateTimeFieldInput: {
        fontSize: "1rem",
        padding: "5px",
    },
});

type TrainingSettings = Pick<
    IOrgSettingDbModel,
    | "trainingEnabled"
    | "trainingInactivityTimeout"
    | "trainingInactivityTimeoutUnit"
    | "trainingInactivityScheduledEvery"
    | "trainingInactivityScheduledEveryUnit"
    | "trainingInactivityStartDate"
    | "trainingInactivityExcludedUserGroupIds"
>;

type TrainingSettingsKeysTextField = keyof Pick<
    TrainingSettings,
    "trainingInactivityTimeout" | "trainingInactivityScheduledEvery" | "trainingInactivityStartDate"
>;

type TrainingSettingsKeysCheckbox = keyof Pick<
    TrainingSettings,
    "trainingEnabled" | "trainingInactivityTimeoutUnit" | "trainingInactivityScheduledEveryUnit"
>;

interface IOwnProps {
    expanded: boolean;
    onCollapseChange: (settingName: string, expanded: boolean) => void;
    onUpdate: <T extends keyof IOrgSettingDbModel>(setting: T, value: IOrgSettingDbModel[T]) => void;
    trainingSettings: TrainingSettings;
    userCanEdit: boolean;
}

function GlobalSettingTraining(props: IOwnProps): JSX.Element {
    const { trainingSettings, userCanEdit } = props;

    const classesSettings = globalSettingsStyles();
    const classes = useStyles();
    const timezoneOffset = useSelectorTimezone();
    const translate = useTranslate();
    const userGroups = useEntitiesUsersGroups().filter((group) => group.status === UserGroupStatusEnum.ACTIVE);

    const inputDisabled = !userCanEdit || !trainingSettings.trainingEnabled;

    /**
     * Handle setting change
     */
    const handleTextFieldChange =
        (setting: TrainingSettingsKeysTextField): ((value: number) => void) =>
        (value: number): void => {
            props.onUpdate(setting, value);
        };

    /**
     * Handle setting checkbox change
     */
    const handleCheckboxChange =
        (
            setting: TrainingSettingsKeysCheckbox,
            value?: TimeBaseSettingEnum
        ): ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) =>
        (_: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
            props.onUpdate(setting, value ?? checked);
        };

    /**
     * Handle user group change
     */
    const handleUserGroupChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        // @ts-expect-error - value should be number[]
        props.onUpdate("trainingInactivityExcludedUserGroupIds", event.target.value);
    };

    /**
     * Handle panel collapse change
     *
     * @param {boolean} expanded - true or false, if expanded or not
     */
    const handleCollapseChange = (expanded: boolean): void => {
        if (props.onCollapseChange) {
            props.onCollapseChange("GlobalSettingTraining", expanded);
        }
    };

    const handleTrainingInactivityValidationDateChange = (date: MaterialUiPickersDate): void => {
        props.onUpdate("trainingInactivityStartDate", date ? getTimestampFromDateStr(String(date)) - timezoneOffset : 0);
    };

    return (
        <GlobalSettingElement
            titleKey="settings.globalSettings.trainingTitle"
            expanded={props.expanded}
            onCollapseChange={handleCollapseChange}
            id={"trainingSettingsId"}
        >
            <div className={classes.root}>
                {/* TRAINING ENABLED */}
                <div className={classes.subSection}>
                    <div className={classes.content}>
                        <Typography variant="caption">{translate("settings.globalSettings.enabled")}</Typography>
                        <Checkbox
                            checked={trainingSettings.trainingEnabled}
                            disabled={!userCanEdit}
                            id="globalSettingsTrainingTrainingEnabledId"
                            onChange={handleCheckboxChange("trainingEnabled")}
                        />
                    </div>
                </div>
                <Divider orientation="vertical" variant="middle" />
                <div className={classes.sectionRight}>
                    <div className={classes.section}>
                        {/* TRAINING INACTIVITY */}
                        <div className={classes.subSection}>
                            <div className={classes.content}>
                                <KortexTextField
                                    changedDelayMS={500}
                                    className={classesSettings.textField}
                                    label={translate("settings.globalSettings.trainingInactivityTimeout")}
                                    min={0}
                                    step={1}
                                    onChanged={handleTextFieldChange("trainingInactivityTimeout")}
                                    TextFieldProps={{
                                        disabled: inputDisabled,
                                        id: "globalSettingsTrainingInactivityTimeId",
                                    }}
                                    type="number"
                                    value={trainingSettings.trainingInactivityTimeout}
                                    variant="standard"
                                />
                                <div className={classes.subSection}>
                                    <div className={classes.option}>
                                        <Typography variant="caption">{translate("general.years")}</Typography>
                                        <Checkbox
                                            checked={trainingSettings.trainingInactivityTimeoutUnit === TimeBaseSettingEnum.YEAR}
                                            id="globalSettingsTrainingInactivityTimeoutUnitDaysId"
                                            onChange={handleCheckboxChange("trainingInactivityTimeoutUnit", TimeBaseSettingEnum.YEAR)}
                                            disabled={inputDisabled}
                                        />
                                    </div>
                                    <div className={classes.option}>
                                        <Typography variant="caption">{translate("general.months")}</Typography>
                                        <Checkbox
                                            checked={trainingSettings.trainingInactivityTimeoutUnit === TimeBaseSettingEnum.MONTH}
                                            id="globalSettingsTrainingInactivityTimeoutUnitHoursId"
                                            onChange={handleCheckboxChange("trainingInactivityTimeoutUnit", TimeBaseSettingEnum.MONTH)}
                                            disabled={inputDisabled}
                                        />
                                    </div>
                                    <div className={classes.option}>
                                        <Typography variant="caption">{translate("general.weeks")}</Typography>
                                        <Checkbox
                                            checked={trainingSettings.trainingInactivityTimeoutUnit === TimeBaseSettingEnum.WEEK}
                                            disabled={inputDisabled}
                                            id="globalSettingsTrainingInactivityTimeoutUnitMinutesId"
                                            onChange={handleCheckboxChange("trainingInactivityTimeoutUnit", TimeBaseSettingEnum.WEEK)}
                                        />
                                    </div>
                                    <div className={classes.option}>
                                        <Typography variant="caption">{translate("general.days")}</Typography>
                                        <Checkbox
                                            checked={trainingSettings.trainingInactivityTimeoutUnit === TimeBaseSettingEnum.DAY}
                                            disabled={inputDisabled}
                                            id="globalSettingsTrainingInactivityTimeoutUnitSecondsId"
                                            onChange={handleCheckboxChange("trainingInactivityTimeoutUnit", TimeBaseSettingEnum.DAY)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* TRAINING INACTIVITY SCHEDULE DATE */}
                        <div className={classes.subSection}>
                            <div className={classes.content}>
                                <KortexDateTimePicker
                                    DateTimePickerProps={{
                                        className: `${classes.dateTimeField} ${classesSettings.textField}`,
                                        disabled: inputDisabled,
                                        InputProps: {
                                            inputProps: {
                                                className: classes.dateTimeFieldInput,
                                                id: "globalSettingsTrainingInactivityTimeId",
                                            },
                                        },
                                        clearLabel: translate("general.clear"),
                                        cancelLabel: translate("general.cancel"),
                                        okLabel: translate("general.select"),
                                    }}
                                    label={translate("settings.globalSettings.trainingInactivityValidationDate")}
                                    onChange={handleTrainingInactivityValidationDateChange}
                                    value={trainingSettings.trainingInactivityStartDate + timezoneOffset}
                                />
                            </div>
                        </div>
                        {/* TRAINING INACTIVITY SCHEDULE EVERY ... */}
                        <div className={classes.subSection}>
                            <div className={classes.content}>
                                <KortexTextField
                                    changedDelayMS={500}
                                    className={classesSettings.textField}
                                    label={translate("settings.globalSettings.trainingInactivityValidationFrequency")}
                                    min={0}
                                    step={1}
                                    onChanged={handleTextFieldChange("trainingInactivityScheduledEvery")}
                                    TextFieldProps={{
                                        disabled: inputDisabled,
                                        id: "globalSettingsTrainingInactivityScheduledEveryId",
                                    }}
                                    type="number"
                                    value={trainingSettings.trainingInactivityScheduledEvery}
                                    variant="standard"
                                />
                                <div className={classes.subSection}>
                                    <div className={classes.option}>
                                        <Typography variant="caption">{translate("general.years")}</Typography>
                                        <Checkbox
                                            checked={trainingSettings.trainingInactivityScheduledEveryUnit === TimeBaseSettingEnum.YEAR}
                                            id="globalSettingsTrainingInactivityScheduledEveryUnitYearId"
                                            onChange={handleCheckboxChange(
                                                "trainingInactivityScheduledEveryUnit",
                                                TimeBaseSettingEnum.YEAR
                                            )}
                                            disabled={inputDisabled}
                                        />
                                    </div>
                                    <div className={classes.option}>
                                        <Typography variant="caption">{translate("general.months")}</Typography>
                                        <Checkbox
                                            checked={trainingSettings.trainingInactivityScheduledEveryUnit === TimeBaseSettingEnum.MONTH}
                                            id="globalSettingsTrainingInactivityScheduledEveryUnitMonthId"
                                            onChange={handleCheckboxChange(
                                                "trainingInactivityScheduledEveryUnit",
                                                TimeBaseSettingEnum.MONTH
                                            )}
                                            disabled={inputDisabled}
                                        />
                                    </div>
                                    <div className={classes.option}>
                                        <Typography variant="caption">{translate("general.weeks")}</Typography>
                                        <Checkbox
                                            checked={trainingSettings.trainingInactivityScheduledEveryUnit === TimeBaseSettingEnum.WEEK}
                                            disabled={inputDisabled}
                                            id="globalSettingsTrainingInactivityScheduledEveryUnitWeekId"
                                            onChange={handleCheckboxChange(
                                                "trainingInactivityScheduledEveryUnit",
                                                TimeBaseSettingEnum.WEEK
                                            )}
                                        />
                                    </div>
                                    <div className={classes.option}>
                                        <Typography variant="caption">{translate("general.days")}</Typography>
                                        <Checkbox
                                            checked={trainingSettings.trainingInactivityScheduledEveryUnit === TimeBaseSettingEnum.DAY}
                                            disabled={inputDisabled}
                                            id="globalSettingsTrainingInactivityScheduledEveryUnitDayId"
                                            onChange={handleCheckboxChange("trainingInactivityScheduledEveryUnit", TimeBaseSettingEnum.DAY)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div className={classes.section}>
                        {/* TRAINING INACTIVITY EXCLUDED USER GROUPS */}
                        <div className={classes.subSection}>
                            <div className={classes.content}>
                                <FormControl className={classes.excludedGroupUserIdFormControl}>
                                    <InputLabel variant="standard" id="globalSettingsTrainingInactivityExlcudedUserGroupsIdsLabelId">
                                        {translate("settings.globalSettings.trainingInactivityValidationExcludedGroups")}
                                    </InputLabel>
                                    <Select
                                        disabled={inputDisabled}
                                        id="globalSettingsTrainingInactivityExlcudedUserGroupsIdsId"
                                        input={<Input id="globalSettingsTrainingInactivityExlcudedUserGroupsInputId" />}
                                        inputProps={{ className: `${classes.excludedGroupUserIdSelector} ${classesSettings.textField}` }}
                                        labelId="globalSettingsTrainingInactivityExlcudedUserGroupsIdsLabelId"
                                        multiple={true}
                                        onChange={handleUserGroupChange}
                                        value={trainingSettings.trainingInactivityExcludedUserGroupIds}
                                    >
                                        {userGroups.map((group) => (
                                            <MenuItem key={group.userGroupId} value={group.userGroupId}>
                                                {group.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </GlobalSettingElement>
    );
}

export default GlobalSettingTraining;
