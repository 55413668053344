import { KortexDialogConfirmation, KortexTextField, theme } from "@aos/react-components";
import MomentUtils from "@date-io/moment";
import {
    IProcessTrainingCertificateForm,
    IUserDbModel,
    MIN_TO_MS,
    ProcessId,
    UserId,
    getDateFormat,
    getDateFromTimestamp,
    getTimestampFromDateStr,
    isFieldEmpty,
} from "@kortex/aos-common";
import { UserValidationDialog } from "@kortex/aos-ui/components/core/UserValidationDialog";
import { useSnackbar } from "@kortex/aos-ui/components/layout/snackbarConfigurator";
import { useClientService } from "@kortex/aos-ui/hooks/useClientService";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Unpromisify } from "@kortex/aos-ui/redux/app.types";
import { useEntitiesUserSession } from "@kortex/aos-ui/redux/effects";
import {
    useSelectorLanguage,
    useSelectorProcesses,
    useSelectorTrainingPendingList,
    useSelectorTreeProcess,
} from "@kortex/aos-ui/redux/selectors";
import { trainingPendingListInsertedAction } from "@kortex/aos-ui/redux/training-pending-manager/training-pending-actions";
import { deepClone } from "@kortex/utilities";
import { Fab, Tooltip, Typography, makeStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { FocusEvent, useEffect, useState } from "react";

import TrainingFormProcessList from "../../utilities/TrainingFormProcessList";

import TrainingCertificateFormUserList, { IUserListWarninings } from "./userList/UserList";

type UserSelectorType = "trainer" | "trainee";

const useStyles = makeStyles({
    fab: {
        margin: "5px",
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
        textAlign: "center",
    },

    // DIALOG
    headerContent: {
        display: "flex",
        backgroundColor: theme.palette.grey[200],
        padding: "16px",
    },
    infoContent: {
        display: "flex",
    },
    trainingInfo: {
        display: "flex",
        marginTop: "27px",
        width: "85%",
    },
    trainingDurationTitle: {
        paddingBottom: "5px",
    },
    hours: {
        width: "75px",
        margin: "5px 0px 0px 5px",
    },
    date: {
        marginTop: "5px",
        marginLeft: "20px",
    },
    trainingCertificateReference: {
        width: "75%",
    },
    containerFab: {
        height: "60px",
        display: "flex",
        justifyContent: "flex-end",
    },
});

function TrainingCertificateFormDialog(): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    const dispatch = useThunkDispatch();
    const snackbar = useSnackbar();
    const userInfo = useEntitiesUserSession();
    const treeNodes = useSelectorTreeProcess();
    const processes = useSelectorProcesses();
    const language = useSelectorLanguage();
    const trainingPendingList = useSelectorTrainingPendingList();
    const trainingCertificateInsert = useClientService("trainingCertificate", "insert");
    const getTrainedUsers = useClientService("users", "getTrainedUsers");
    const signatureContext = translate("training.trainingCertificateTrainerSignatureContext");
    const getUserIdsOfTrainingsByOriginalProcessIds = useClientService("training", "getUserIdsOfTrainingsByOriginalProcessIds");

    const [open, setOpen] = useState<boolean>(false);
    const [selectedProcesses, setSelectedProcesses] = useState<IProcessTrainingCertificateForm[]>([]);
    const [traineeIdsSelected, setTraineeIdsSelected] = useState<UserId[]>([]);
    const [trainerIdsSelected, setTrainerIdsSelected] = useState<UserId[]>(userInfo ? [userInfo.userId] : []);
    const [trainingCertificateReference, setTrainingCertificateReference] = useState<string>("");
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [date, setDate] = useState<number>(Date.now());
    const [trainerSignatureDialogOpen, setTrainerSignatureDialogOpen] = useState<boolean>(false);
    const [userWarnings, setUserWarnings] = useState<IUserListWarninings>({});

    const disabled = selectedProcesses.length === 0 || traineeIdsSelected.length === 0 || trainerIdsSelected.length === 0;

    useEffect(() => {
        if (open === false) {
            setTraineeIdsSelected([]);
            setSelectedProcesses([]);
            setTrainerIdsSelected(userInfo ? [userInfo.userId] : []);
            setTrainingCertificateReference("");
            setHours(0);
            setMinutes(0);
            setUserWarnings({});
        }
    }, [open]);

    /* ----------------------------------------------------------------------------------------------- */
    /* DIALOG ---------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------- */
    /**
     * Handle called when the user cancel the dialog
     */
    const handleCancel = (): void => {
        setOpen(false);
    };

    /**
     * Handle called when the user cancel the dialog
     */
    const handleTrainerSignatureCancel = (): void => {
        setTrainerSignatureDialogOpen(false);
    };

    /**
     * Handle called when the user confir the dialog
     */
    const handleConfirm = (): void => {
        setTrainerSignatureDialogOpen(true);
    };

    /**
     * Handle called when the trainer has signed the certificate
     */
    const handleTrainerHasSigned = async (): Promise<void> => {
        const trainingDuration = (hours * 60 + minutes) * MIN_TO_MS;

        await trainingCertificateInsert({
            additionalTrainerIds: trainerIdsSelected.filter((id) => id !== trainerIdsSelected[0]),
            context: signatureContext,
            mainTrainerId: trainerIdsSelected[0],
            trainingCertificate: {
                duration: trainingDuration,
                reference: trainingCertificateReference,
                type: "training",
                date: date,
            },
            trainingProcessIds: selectedProcesses.map((process) => process.process.processId),
            userIdsTrained: traineeIdsSelected,
        }).then((res) => {
            setTrainerSignatureDialogOpen(false);

            if (res) {
                setOpen(false);
                dispatch(trainingPendingListInsertedAction([...res]));
            }
        });
    };

    /**
     * Opens dialog
     */
    const handleOpenDialog = (): void => {
        setOpen(true);
    };

    /**
     * handles the value changed of Training Certificate Reference
     */
    const handleTrainingCertificateReference = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setTrainingCertificateReference(event.target.value);
    };
    /**
     * Handle hours changed
     */
    const handleHoursChanged = (value: number): void => {
        if (isNaN(value) || isFieldEmpty(value.toString()) === true) {
            setHours(0);
        } else {
            setHours(value);
        }
    };

    /**
     * Handle minutes changed
     */
    const handleMinutesChanged = (value: number): void => {
        if (isNaN(value) || isFieldEmpty(value.toString()) === true) {
            setMinutes(0);
        } else {
            setMinutes(value);
        }
    };

    /* ----------------------------------------------------------------------------------------------- */
    /* TRAINER/TRAINEE ------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------- */
    const handleUserIdSelectorDialogConfirm =
        (userSelectorType: UserSelectorType): ((selectedUserIdsDialog: UserId[]) => void) =>
        (selectedUserIdsDialog: UserId[]): void => {
            const userIds = deepClone(userSelectorType === "trainer" ? trainerIdsSelected : traineeIdsSelected);

            for (const id of selectedUserIdsDialog) {
                if (!userIds.includes(id)) {
                    userIds.push(id);
                }
            }

            if (userSelectorType === "trainer") {
                setTrainerIdsSelected(userIds);
            } else {
                updateTraineesAndProcesses(userIds);
            }
        };

    /**
     * Deletes user Id
     */
    const handleDeleteUserId =
        (userSelectorType: UserSelectorType): ((trainerIdToBeDeleted: UserId) => void) =>
        (trainerIdToBeDeleted: UserId): void => {
            const updateUserIdsSelected = deepClone(userSelectorType === "trainer" ? trainerIdsSelected : traineeIdsSelected);
            const index = updateUserIdsSelected.findIndex((trainerId) => trainerId === trainerIdToBeDeleted);

            updateUserIdsSelected.splice(index, 1);

            if (userSelectorType === "trainer") {
                setTrainerIdsSelected(updateUserIdsSelected);
            } else {
                updateTraineesAndProcesses(updateUserIdsSelected);
            }
        };

    const updateUserWarnings = (
        updatedTraineeIds = traineeIdsSelected,
        updatedProcesses = selectedProcesses,
        processTrainings: Unpromisify<ReturnType<typeof getUserIdsOfTrainingsByOriginalProcessIds>> = []
    ): void => {
        // Warning messages
        // Find users that are already trained
        const updatedUserWarnings: { [userId: UserId]: { pending: boolean; name: string }[] } = {};
        const processIds = updatedProcesses.map((process) => process.process.processId);

        for (const training of processTrainings) {
            if (updatedTraineeIds.includes(training.userId) && training.trained) {
                if (!updatedUserWarnings[training.userId]) {
                    updatedUserWarnings[training.userId] = [];
                }

                updatedUserWarnings[training.userId].push({
                    pending: false,
                    name: updatedProcesses.find((process) => process.process.processId === training.processId)?.treeNode.label ?? "",
                });
            }
        }

        // Find user that have pending trainings
        for (const trainingPending of trainingPendingList) {
            for (const process of trainingPending.processes) {
                if (updatedTraineeIds.includes(trainingPending.user.userId) && processIds.includes(process.processId)) {
                    if (!updatedUserWarnings[trainingPending.user.userId]) {
                        updatedUserWarnings[trainingPending.user.userId] = [];
                    }

                    updatedUserWarnings[trainingPending.user.userId].push({
                        pending: true,
                        name: process.label,
                    });
                }
            }
        }

        // Format warnings
        const warnings: IUserListWarninings = {};

        for (const stringifiedUserId of Object.keys(updatedUserWarnings)) {
            const userId = parseInt(stringifiedUserId);

            let hasPending = false;
            let isTrained = false;
            let warningMessagePending = translate("training.warningMessagePending");
            let warningMessageTrained = translate("training.warningMessageTrained");

            for (const process of updatedUserWarnings[userId]) {
                if (process.pending) {
                    hasPending = true;
                    warningMessagePending += `\n${process.name}`;
                } else {
                    isTrained = true;
                    warningMessageTrained += `\n${process.name}`;
                }
            }

            warnings[userId] = `${hasPending ? warningMessagePending : ""}${hasPending && isTrained ? "\n\n" : ""}${
                isTrained ? warningMessageTrained : ""
            }`;
        }

        // Update warnings
        setUserWarnings(warnings);
    };

    const updateTraineesAndProcesses = (
        updatedTraineeIds = traineeIdsSelected,
        updatedProcesses = selectedProcesses,
        preventWarnings = false
    ): void => {
        // Update processes
        setSelectedProcesses(updatedProcesses);
        setTraineeIdsSelected(updatedTraineeIds);

        if (!updatedProcesses.length) {
            setUserWarnings([]);

            return void 0;
        }

        const processIds = updatedProcesses.map((process) => process.process.processId);

        if (!preventWarnings) {
            // Search all users with a training for the selected processes
            getUserIdsOfTrainingsByOriginalProcessIds({ processIds }).then((processTrainings) =>
                updateUserWarnings(updatedTraineeIds, updatedProcesses, processTrainings)
            );
        }
    };

    /**
     * Search all users with a training for the selected processes
     */
    const handleGetTraineesBySelectedProcesses = (): void => {
        if (!selectedProcesses.length) return void 0;

        getUserIdsOfTrainingsByOriginalProcessIds({ processIds: selectedProcesses.map((process) => process.process.processId) }).then(
            (processTrainings = []) => {
                if (processTrainings.length) {
                    const addedUserIds: UserId[] = deepClone(traineeIdsSelected);

                    for (const { userId } of processTrainings) {
                        if (!addedUserIds.includes(userId) && !trainerIdsSelected.includes(userId)) {
                            addedUserIds.push(userId);
                        }
                    }

                    updateTraineesAndProcesses(addedUserIds, undefined, true);
                    updateUserWarnings(addedUserIds, undefined, processTrainings);
                }
            }
        );
    };

    const handleClearTrainees = (): void => {
        setTraineeIdsSelected([]);
    };

    /* ----------------------------------------------------------------------------------------------- */
    /* PROCESS --------------------------------------------------------------------------------------- */
    /* ----------------------------------------------------------------------------------------------- */
    /**
     * validate if the trainer is trained for the process selected
     */
    const validateQualificationTrainer = async (processId: ProcessId, trainerId: UserId): Promise<boolean> => {
        // retrieve users trained and certified
        const trainedUsers = await getTrainedUsers({
            processId,
            certified: true,
            userIds: [trainerId],
        }).catch(() => [] as IUserDbModel[]);

        if (!trainedUsers?.length) return false;

        // validate
        return trainedUsers.some((user) => user.userId === trainerId);
    };

    /**
     * handle process selector,
     */
    const handleProcessIdsSelected = async (selectedProcessesVersionId: ProcessId[]): Promise<void> => {
        let mainTrainerIsQualified = true;
        let processNameWithoutTrainedUsers: string | undefined = undefined;

        // validate if the main trainer is qualified for the processes selected
        for (const processId of selectedProcessesVersionId) {
            const validate = await validateQualificationTrainer(processId, trainerIdsSelected[0]);

            if (!validate) {
                mainTrainerIsQualified = false;
                processNameWithoutTrainedUsers = treeNodes.find(
                    (treeNode) => treeNode.treeNodeId === processes.find((process) => process.processId === processId)?.treeNodeId
                )?.label;
                break;
            }
        }

        if (!mainTrainerIsQualified && processNameWithoutTrainedUsers) {
            // trainer not qualified
            snackbar.warning(
                `${userInfo?.userName} ${translate("training.trainingCertificateTrainerNotQualified")} ${processNameWithoutTrainedUsers}`
            );

            // stop handle
            return;
        }

        const processesSelected = deepClone(selectedProcesses);
        const processIdsSelected = selectedProcesses.map((process) => process.process.processId);

        for (const processId of selectedProcessesVersionId) {
            // validate that the process is not already selected
            if (!processIdsSelected.includes(processId)) {
                const process = processes.find((process) => process.processId === processId);

                if (process) {
                    const treeNode = treeNodes.find((treeNode) => treeNode.treeNodeId === process.treeNodeId);

                    if (treeNode) {
                        processesSelected.push({ process, treeNode });
                    }
                }

                processIdsSelected.push(processId);
            }
        }

        updateTraineesAndProcesses(undefined, processesSelected);
    };

    /**
     * Deletes process Id
     */
    const handleDeleteProcess = (processIdToBeDeleted: ProcessId): void => {
        const updateProcessesSelected = deepClone(selectedProcesses);
        const index = updateProcessesSelected.findIndex((process) => process.process.processId === processIdToBeDeleted);

        updateProcessesSelected.splice(index, 1);
        updateTraineesAndProcesses(undefined, updateProcessesSelected);
    };

    /**
     * Handle from date value change event
     */
    const handleFromDateOnChange = (date: MaterialUiPickersDate): void => {
        setDate(getTimestampFromDateStr(String(date)));
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        event.target.select();
    };

    return (
        <>
            {/* ICON TO OPEN DIALOG */}
            <div className={classes.containerFab}>
                <Fab className={classes.fab} size="medium" id="addTrainingCertificateId" color="secondary" onClick={handleOpenDialog}>
                    <Tooltip classes={{ tooltip: classes.tooltip }} title={translate("training.addTrainingCertificate")} placement="left">
                        <AddIcon />
                    </Tooltip>
                </Fab>
            </div>

            {/* DIALOG */}
            <KortexDialogConfirmation
                open={open}
                textLabels={{
                    titleLabel: translate("training.trainingCertificateFormDialogTitle"),
                    cancelButtonLabel: translate("general.cancel"),
                    proceedButtonLabel: translate("general.confirm"),
                }}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                closeOnEscape={true}
                confirmDisabled={disabled}
                dialogProps={{
                    maxWidth: "lg",
                }}
            >
                {/* REF AND DURATION TRAINING CERTIFICATE */}
                <div>
                    <div className={classes.headerContent}>
                        <Typography variant="h6">{translate("training.detail")}</Typography>
                    </div>
                    <div className={classes.infoContent}>
                        <div className={classes.trainingInfo}>
                            <KortexTextField
                                TextFieldProps={{
                                    id: "trainingCertificateReferenceInputId",
                                }}
                                variant="outlined"
                                label={translate("training.trainingCertificateReference")}
                                value={trainingCertificateReference}
                                onChange={handleTrainingCertificateReference}
                                className={classes.trainingCertificateReference}
                            />
                            <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                                <DatePicker
                                    className={classes.date}
                                    allowKeyboardControl={true}
                                    format={getDateFormat(false)}
                                    id="fromDateId"
                                    label={translate("general.date")}
                                    onChange={handleFromDateOnChange}
                                    value={getDateFromTimestamp(date)}
                                    variant="inline"
                                    inputVariant="outlined"
                                    disableFuture={true}
                                    clearLabel={translate("general.clear")}
                                    cancelLabel={translate("general.cancel")}
                                    okLabel={translate("general.select")}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div>
                            <Typography className={classes.trainingDurationTitle} variant="subtitle2">
                                {translate("training.trainingDuration")}
                            </Typography>
                            <KortexTextField
                                TextFieldProps={{
                                    id: "trainingDurationInputHoursId",
                                    inputProps: {
                                        onFocus: handleFocus,
                                    },
                                }}
                                value={hours}
                                label={translate("general.hours")}
                                className={classes.hours}
                                onChanged={handleHoursChanged}
                                type={"number"}
                                variant="outlined"
                            />
                            <KortexTextField
                                TextFieldProps={{
                                    id: "trainingDurationInputMinutesId",
                                    inputProps: {
                                        onFocus: handleFocus,
                                    },
                                }}
                                value={minutes}
                                label={translate("general.minutes")}
                                className={classes.hours}
                                onChanged={handleMinutesChanged}
                                type={"number"}
                                variant="outlined"
                                max={59}
                            />
                        </div>
                    </div>
                </div>
                {/* TRAINER LIST */}
                <TrainingCertificateFormUserList
                    title={translate("training.trainingCertificateTrainers")}
                    userIdsSelected={trainerIdsSelected}
                    userIdToExclude={[...trainerIdsSelected, ...traineeIdsSelected]}
                    trainer={true}
                    handleDeleteUserId={handleDeleteUserId("trainer")}
                    handleUserIdSelectorDialogConfirm={handleUserIdSelectorDialogConfirm("trainer")}
                />

                {/* PROCESS LIST */}
                <TrainingFormProcessList
                    title={translate("training.trainingProcess")}
                    handleDeleteProcess={handleDeleteProcess}
                    handleProcessIdsSelected={handleProcessIdsSelected}
                    selectedProcesses={selectedProcesses}
                />

                {/* TRAINEE LIST */}
                <TrainingCertificateFormUserList
                    // disabled={selectedProcesses.length === 0}
                    title={translate("training.trainingCertificateTrainee")}
                    userIdsSelected={traineeIdsSelected}
                    userIdToExclude={[...trainerIdsSelected, ...traineeIdsSelected]}
                    userWarnings={userWarnings}
                    handleClearUsers={handleClearTrainees}
                    handleDeleteUserId={handleDeleteUserId("trainee")}
                    handleGetUsers={handleGetTraineesBySelectedProcesses}
                    handleUserIdSelectorDialogConfirm={handleUserIdSelectorDialogConfirm("trainee")}
                />
            </KortexDialogConfirmation>
            <UserValidationDialog
                open={trainerSignatureDialogOpen}
                isElectronicSignature={true}
                electronicSignatureContext={signatureContext}
                onClose={handleTrainerSignatureCancel}
                preSelectedUserId={userInfo ? userInfo.userId : 0}
                onValidate={handleTrainerHasSigned}
            />
        </>
    );
}

export default TrainingCertificateFormDialog;
