import { IJobProcessUiModel } from "@kortex/aos-common";

import { TranslateCb } from "../hooks/useTranslate";
import { GetJobInProgress } from "../components/pages/Scheduler/SchedulerData";

/**
 * Builds the process title
 *
 * @param {IJobProcessUiModel} jobProcess - the job process object
 * @param {boolean} dryRunMode - indicates if the process is being played in dry mode
 */
export const getProcessTitle = (jobProcess: IJobProcessUiModel, dryRunMode: boolean, translate: TranslateCb): string => {
    const jobProgress = GetJobInProgress(jobProcess.qtyStarted, jobProcess.qty, jobProcess.qtyPassed, jobProcess.qtyFailed);
    let fullTitle = `${jobProcess.processName}`;

    fullTitle += ` - (${jobProgress.remainingToStart} ${translate("player.of")} ${jobProcess.qty})`;
    if (dryRunMode) {
        fullTitle += ` (${translate("scheduler.dryRun")})`;
    }
    return fullTitle;
};
