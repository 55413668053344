import { ProcessAction, removeObjectFromArray, upsertObjectFromArray } from "@kortex/aos-common";

import { Unpack } from "../app.types";

import { ProcessActions, ProcessActionTypeRedux, ProcessState } from "./process-types";

const initialState: ProcessState = {
    copiedActions: [],
    copiedActionStep: undefined,
    editedProcessId: undefined,
    processes: [],
    settings: [],
    processFromTreeNodeIdLookupTable: [],
};

/**
 * Groups process actions by process
 */
function groupActions(actions: Unpack<ProcessState["processes"]>["actions"]): Array<Unpack<ProcessState["processes"]>["actions"]> {
    return actions.reduce<Array<Unpack<ProcessState["processes"]>["actions"]>>((acc, current) => {
        if (!acc[current.processId]) {
            acc[current.processId] = [];
        }

        return (
            (acc[current.processId] = upsertObjectFromArray(acc[current.processId], current, function (action) {
                return action.processActionId === this.processActionId;
            })),
            acc
        );
    }, []);
}

/**
 * Retrieves processes from state; only returns found processes
 */
function getProcesses(state: ProcessState, processIds: number[]): ProcessState["processes"] {
    return state.processes.reduce<ProcessState["processes"]>((acc, process) => {
        if (processIds.length === acc.length) {
            return acc;
        }

        if (processIds.indexOf(process.processId) > -1) {
            acc.push(process);
        }

        return acc;
    }, []);
}

// definition

export function processReducer(state: ProcessState = initialState, action: ProcessActions): ProcessState {
    switch (action.type) {
        case ProcessActionTypeRedux.CLEAR_PROCESSES:
            return {
                ...initialState,
                processFromTreeNodeIdLookupTable: [],
            };
        case ProcessActionTypeRedux.DELETE_PROCESS_ACTION: {
            // group action by process
            const groupedActions = groupActions(action.value as ProcessAction[]);

            // retrieve existing process (skip 404 process)
            const processes = getProcesses(
                state,
                Object.keys(groupedActions).map((id) => parseInt(id, 10))
            );

            return {
                ...state,
                processes: upsertObjectFromArray(state.processes, processes, function (process) {
                    // this process will change
                    if (process.processId === this.processId) {
                        // with these actions
                        process.actions = removeObjectFromArray(process.actions, groupedActions[this.processId], function (action) {
                            return action.processActionId === this.processActionId;
                        });

                        return true;
                    }

                    return false;
                }),
            };
        }
        // FIXME: This case is not since we do not have a step-specific state in Redux.
        //        At the moment, we only update the action when a step is deleted.
        case ProcessActionTypeRedux.DELETE_PROCESS_ACTION_STEP: {
            const processes = getProcesses(
                state,
                Object.keys([action.value]).map((id) => parseInt(id, 10))
            );

            return {
                ...state,
                processes: upsertObjectFromArray(state.processes, processes, function (process) {
                    // this process will change
                    if (process.processId === this.processId) {
                        // with these actions
                        process.actions = removeObjectFromArray(process.actions, action.value[this.processId], function (action) {
                            return action.processActionId === this.processActionId;
                        });

                        return true;
                    }

                    return false;
                }),
            };
        }
        case ProcessActionTypeRedux.INSERT_PROCESS_ACTION:
        case ProcessActionTypeRedux.UPDATE_PROCESS_ACTION: {
            // group action by process
            const groupedActions = groupActions(action.value);
            // retrieve existing process (skip 404 process)
            const processes = getProcesses(
                state,
                Object.keys(groupedActions).map((id) => parseInt(id, 10))
            );

            return {
                ...state,
                processes: upsertObjectFromArray(state.processes, processes, function (process) {
                    // this process will change
                    if (process.processId === this.processId) {
                        // with these actions
                        process.actions = upsertObjectFromArray(process.actions, groupedActions[this.processId], function (action) {
                            return action.processActionId === this.processActionId;
                        });

                        return true;
                    }

                    return false;
                }),
            };
        }
        case ProcessActionTypeRedux.UPDATE_PROCESS_ACTION_STEP: {
            // retrieve existing process (skip 404 process)
            const processes = getProcesses(
                state,
                Object.keys(action.value).map((id) => parseInt(id, 10))
            );

            return {
                ...state,
                processes: upsertObjectFromArray(state.processes, processes, function (process) {
                    // this process will change
                    if (process.processId === this.processId) {
                        // with these actions
                        process.actions = upsertObjectFromArray(process.actions, action.value[this.processId], function (action) {
                            return action.processActionId === this.processActionId;
                        });

                        return true;
                    }

                    return false;
                }),
            };
        }
        case ProcessActionTypeRedux.INSERT_PROCESS:
            return {
                ...state,
                processes: upsertObjectFromArray(state.processes, action.value, function (process) {
                    return process.processId === this.processId;
                }),
            };
        case ProcessActionTypeRedux.SET_COPIED_PROCESS_ACTION:
            return {
                ...state,
                copiedActions: action.value,
            };
        case ProcessActionTypeRedux.SET_COPIED_PROCESS_ACTION_STEP:
            return {
                ...state,
                copiedActionStep: action.value,
            };
        case ProcessActionTypeRedux.SET_EDITED_PROCESS_ID:
            return {
                ...state,
                editedProcessId: action.value,
            };
        case ProcessActionTypeRedux.SET_PROCESS_ACTION_SETTINGS:
            return {
                ...state,
                settings: [...action.value],
            };
        case ProcessActionTypeRedux.SET_PROCESSES:
            return {
                ...state,
                processes: [...action.value],
            };
        case ProcessActionTypeRedux.UPDATE_PROCESS:
            return {
                ...state,
                processes: upsertObjectFromArray(state.processes, action.value, function (process) {
                    return process.processId === this.processId;
                }),
            };
        case ProcessActionTypeRedux.UPDATE_PROCESS_ACTION_SETTINGS:
            return {
                ...state,
                settings: upsertObjectFromArray(state.settings, action.value, function (settings) {
                    return settings.processActionSettingsId === this.processActionSettingsId;
                }),
            };
        case ProcessActionTypeRedux.UPDATE_PROCESSES:
            return {
                ...state,
                processes: upsertObjectFromArray(state.processes, action.value, function (process) {
                    return process.processId === this.processId;
                }),
            };
        case ProcessActionTypeRedux.UPDATE_PROCESS_LOOKUP_FROM_TREENODEID:
            return {
                ...state,
                processFromTreeNodeIdLookupTable: [...state.processFromTreeNodeIdLookupTable, action.value],
            };
        case ProcessActionTypeRedux.UPDATE_PROCESSES_LOOKUP_FROM_TREENODESID:
            const treeNodesIdList = state.processFromTreeNodeIdLookupTable;
            for (const treeNodeId of action.value) {
                if (!state.processFromTreeNodeIdLookupTable.includes(treeNodeId)) {
                    treeNodesIdList.push(treeNodeId);
                }
            }
            return {
                ...state,
                processFromTreeNodeIdLookupTable: treeNodesIdList,
            };
        default:
            return state;
    }
}
