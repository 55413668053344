// krc
import { theme } from "@aos/react-components";
import {
    IActionOrderingTable,
    IJobProcessOfTheReworkModel,
    IProcessUiModel,
    ProcessApprovalStatusFilter,
    ProcessId,
    orderActionList,
} from "@kortex/aos-common";
// react
import * as React from "react";
import { useEffect, useState } from "react";
// mui
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import {
    Button,
    Checkbox,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    makeStyles,
} from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import SelectAllIcon from "@material-ui/icons/SelectAll";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesTreeProcess } from "../../../../../redux/effects";
import { processGet, processGetLatestVersion } from "../../../../../redux/process-manager/process-thunks-process";

const useStyles = makeStyles({
    dialogTitle: {
        display: "flex",
    },
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
    },
    topOptionsFlex: {
        display: "flex",
        flexGrow: 1,
    },
    buttons: {
        margin: "10px",
        padding: "10px",
    },
    pannelDetailsContent: {
        width: "100%",
    },
    tableCell: {
        padding: "12px",
    },
    tableHeader: {
        backgroundColor: theme.palette.grey[200],
    },
});

interface IFailPathProcessList {
    processId: ProcessId;
    processName: string;
}

interface IOwnProps {
    jobPocessesOriginalJob: IJobProcessOfTheReworkModel[];
    processOriginalJobListSelected: (processIdList: number[]) => void;
}

export default function ProcessSelectorOriginalJobList(props: IOwnProps): JSX.Element {
    const { jobPocessesOriginalJob, processOriginalJobListSelected } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();
    const allNodes = useEntitiesTreeProcess();

    const [processIdToSelect, setProcessIdToSelect] = useState<number[]>([]);
    const [jobProcessListNotRework, setJobProcessListNotRework] = useState<IJobProcessOfTheReworkModel[] | undefined>(undefined);
    const [failPathProcessList, setFailPathProcessList] = useState<IFailPathProcessList[] | undefined>(undefined);

    /**
     * will be called on mount
     */
    useEffect((): void => {
        setJobProcessListNotRework(jobPocessesOriginalJob.filter((processNotRework) => processNotRework.reworkId === 0));
        getFailPathProcessList();
    }, []);

    /**
     * get all the job routing failure path processes
     */
    const getFailPathProcessList = async (): Promise<void> => {
        // get all routing id in the job.
        const routingIdList: number[] = Array.from(
            new Set(jobPocessesOriginalJob.filter((process) => process.routingId > 0).map((process) => process.routingId))
        );

        // get all fail path action on each routing.
        const allFailPathActionsOrdered: IActionOrderingTable[] = [];
        let routingProcessWithActions: IProcessUiModel | undefined;
        for (const routingId of routingIdList) {
            routingProcessWithActions = await dispatch(processGet(routingId));
            if (routingProcessWithActions) {
                const actions = routingProcessWithActions.actions.filter(
                    (action) => action.type !== "core-input" && action.type !== "core-output"
                );
                const actionFailPath = routingProcessWithActions.actions.find((action) => action.type === "core-routing-fail");

                if (actionFailPath && actionFailPath.outputs.length > 0 && actionFailPath.outputs[0].remoteIds.length > 0) {
                    const nextActionOfFailPathAction = actionFailPath.outputs[0].remoteIds[0].actionId;
                    orderActionList(actions, nextActionOfFailPathAction)
                        .filter((action) => action.playOrder > 0)
                        .map((action) => allFailPathActionsOrdered.push(action));
                }
            }
        }

        // get fail path process list
        const processList: IFailPathProcessList[] = [];
        for (const failPathAction of allFailPathActionsOrdered) {
            if (routingProcessWithActions) {
                const action = routingProcessWithActions.actions.find((action) => action.processActionId === failPathAction.actionId);
                if (action && action.type === "core-routing-process") {
                    const actionExtendedProps = action.steps[0];
                    if (actionExtendedProps.config.latest && actionExtendedProps.config.treeNodeId) {
                        const process: IProcessUiModel | undefined = await dispatch(
                            processGetLatestVersion({
                                treeNodeId: actionExtendedProps.config.treeNodeId,
                                filter: ProcessApprovalStatusFilter.RELEASED,
                            })
                        );
                        const treeNode = allNodes.find((node) => node.treeNodeId === actionExtendedProps.config.treeNodeId);
                        if (process && treeNode) {
                            processList.push({
                                processId: process.processId,
                                processName: treeNode.label,
                            });
                        }
                    }
                }
            }
        }
        setFailPathProcessList(processList);
    };

    /**
     * Select all the process
     */
    const selectAll = (): void => {
        const all: number[] = [];
        if (jobProcessListNotRework) {
            jobProcessListNotRework.map((jobProcessNotRework) => {
                all.push(jobProcessNotRework.processId);
            });
        }
        setProcessIdToSelect(all);
        processOriginalJobListSelected(all);
    };

    /**
     * Select all fail path process
     */
    const selectAllFailPathProcess = (): void => {
        const all: number[] = [];
        if (failPathProcessList) {
            failPathProcessList.map((failPathProcess) => {
                all.push(failPathProcess.processId);
            });
        }
        setProcessIdToSelect(all);
        processOriginalJobListSelected(all);
    };

    /**
     * UnSelect all the process
     */
    const clearAll = (): void => {
        setProcessIdToSelect([]);
        processOriginalJobListSelected([]);
    };

    /**
     * Return true if Process is selected
     */
    const isChecked = (processId: number): boolean => {
        return processIdToSelect.find((inArrayProcessId) => inArrayProcessId === processId) !== undefined;
    };

    /**
     * Handle selection of job process to update
     */
    const handleUpdateProcessSelected = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
        const processId: number = parseInt(event.target.value);
        const isPresent = isChecked(processId);

        if (checked && !isPresent) {
            setProcessIdToSelect([...processIdToSelect, processId]);
            processOriginalJobListSelected([...processIdToSelect, processId]);
        } else if (!checked && isPresent) {
            const cleanArray = processIdToSelect.filter((inArrayProcessId) => inArrayProcessId !== processId);
            setProcessIdToSelect(cleanArray);
            processOriginalJobListSelected(cleanArray);
        }
    };

    return (
        <div>
            {/* Header Process original job*/}
            <DialogContent className={classes.dialogContent}>
                <div className={classes.dialogTitle}>
                    <div className={classes.dialogDescription}>
                        <Typography variant="h2">{translate("rework.addProcessFromOriginalJob")}</Typography>
                    </div>
                    <div className={classes.topOptionsFlex} />
                    <div>
                        <Button
                            id="selectAllButtonId"
                            variant="contained"
                            color="secondary"
                            onClick={selectAll}
                            className={classes.buttons}
                            startIcon={<SelectAllIcon />}
                        >
                            {translate("processUpdate.selectAll")}
                        </Button>
                        <Button
                            id="clearAllButtonId"
                            variant="contained"
                            color="secondary"
                            onClick={clearAll}
                            className={classes.buttons}
                            startIcon={<ClearAllIcon />}
                        >
                            {translate("processUpdate.clearAll")}
                        </Button>
                    </div>
                </div>
            </DialogContent>

            {/* Container Process original job */}
            <Table className={classes.pannelDetailsContent}>
                <TableHead className={classes.tableHeader}>
                    <TableRow>
                        <TableCell className={classes.tableCell}>
                            <Typography variant={"body2"}>{translate("processInfo.processName")}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Typography variant={"body2"}>{translate("general.select")}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {jobProcessListNotRework?.map(
                        (processRow, rowIndex): JSX.Element => (
                            <TableRow key={rowIndex}>
                                <TableCell id={`processName${rowIndex}Id`} className={classes.tableCell}>
                                    <Typography variant={"body2"}>{processRow.process.treeNodeProcess.label}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Checkbox
                                        id={`checkBoxSelectProcess${rowIndex}Id`}
                                        checked={isChecked(processRow.processId)}
                                        onChange={handleUpdateProcessSelected}
                                        value={processRow.processId}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>

            {/* Header FAIL PATH process*/}
            <DialogContent className={classes.dialogContent}>
                <div className={classes.dialogTitle}>
                    <div className={classes.dialogDescription}>
                        <Typography variant="h2">{translate("rework.addFailPathProcess")}</Typography>
                    </div>
                    <div className={classes.topOptionsFlex} />
                    <div>
                        <Button
                            id="selectAllFailPathProcessButtonId"
                            variant="contained"
                            color="secondary"
                            onClick={selectAllFailPathProcess}
                            className={classes.buttons}
                            startIcon={<SelectAllIcon />}
                        >
                            {translate("processUpdate.selectAll")}
                        </Button>
                        <Button
                            id="clearAllFailPathProcessButtonId"
                            variant="contained"
                            color="secondary"
                            onClick={clearAll}
                            className={classes.buttons}
                            startIcon={<ClearAllIcon />}
                        >
                            {translate("processUpdate.clearAll")}
                        </Button>
                    </div>
                </div>
            </DialogContent>

            {/* Container FAIL PATH process */}
            <Table className={classes.pannelDetailsContent}>
                <TableHead className={classes.tableHeader}>
                    <TableRow>
                        <TableCell className={classes.tableCell}>
                            <Typography variant={"body2"}>{translate("processInfo.processName")}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Typography variant={"body2"}>{translate("general.select")}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {failPathProcessList?.map(
                        (processRow, rowIndex): JSX.Element => (
                            <TableRow key={rowIndex}>
                                <TableCell id={`processName${rowIndex}Id`} className={classes.tableCell}>
                                    <Typography variant={"body2"}>{processRow.processName}</Typography>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <Checkbox
                                        id={`checkBoxSelectFailPathProcess${rowIndex}Id`}
                                        checked={isChecked(processRow.processId)}
                                        onChange={handleUpdateProcessSelected}
                                        value={processRow.processId}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </div>
    );
}
