import { deepClone } from "@kortex/utilities";
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as jsonPatch from "fast-json-patch";
import { merge } from "merge-anything";
import { useEffect, useState } from "react";

import { StandardThunk } from "../redux/store";

import { useThunkDispatch } from "./useThunkDispatch";

/**
 * Hooks to capture a undo redo history based on object diff patch
 * instead of full state capture for every capture (copy/pasted from AOS project)
 */
export function useStoreState<T>(
    rxStoreValue: T,
    actionToDispatch?: (value: any) => StandardThunk,
    options?: {
        debouncingTimeInMs?: number;
        noDispatch?: boolean;
        dispatchArray?: boolean;
        conflictResolutionStrategy?: "merge" | "keepCurrent" | "keepNew";
        endOfDebouncingCb?: (currentValue: T) => void;
    }
): [
    T,
    (
        newValue: T,
        dispatchValue?: T,
        setValueOptions?: {
            updateOnly?: boolean;
            noDeboucing?: boolean;
        }
    ) => void,
] {
    const [currentValue, setCurrentValue] = useState<T>(deepClone(rxStoreValue));
    const [isInUpdate, setIsInUpdate] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<typeof setTimeout> | undefined>();

    const dispatch = useThunkDispatch();

    useEffect((): void => {
        if (rxStoreValue && currentValue) {
            // store updated
            if (isDirty || isInUpdate) {
                let equal = false;
                if (typeof rxStoreValue === "object") {
                    const compareDiffs = jsonPatch.compare(currentValue, rxStoreValue);
                    equal = compareDiffs.length === 0;
                } else {
                    equal = rxStoreValue === currentValue;
                }

                if (!equal) {
                    // Possible conflict, resolve with provided strategy
                    switch (options?.conflictResolutionStrategy) {
                        case "keepCurrent":
                            // nothing to do;
                            setValue(currentValue);
                            break;
                        case "keepNew":
                            setCurrentValue(rxStoreValue);
                            break;
                        case "merge":
                        default:
                            const newStuff: any = merge(deepClone(rxStoreValue), currentValue);
                            setValue(newStuff);
                    }
                }
            } else {
                let equal = false;
                if (typeof rxStoreValue === "object") {
                    const compareDiffs = jsonPatch.compare(currentValue, rxStoreValue);
                    equal = compareDiffs.length === 0;
                } else {
                    equal = rxStoreValue === currentValue;
                }
                if (!equal) {
                    setCurrentValue(rxStoreValue);
                }
            }
        }
    }, [rxStoreValue]);

    const setValue = (
        newValue: T,
        dispatchValue?: T,
        setValueOptions?: {
            updateOnly?: boolean;
            noDeboucing?: boolean;
        }
    ): void => {
        setCurrentValue(newValue);
        if (setValueOptions?.updateOnly || options?.noDispatch) {
            return;
        }
        // else
        setIsDirty(true);

        // if debouncing, accumulate changed
        if (options?.debouncingTimeInMs && (setValueOptions?.noDeboucing === undefined || setValueOptions?.noDeboucing === false)) {
            if (debounceTimeout !== undefined) {
                clearTimeout(debounceTimeout); // Clear current timeout
            }
            setDebounceTimeout(
                setTimeout(() => {
                    if (options?.endOfDebouncingCb) {
                        options.endOfDebouncingCb(newValue);
                    }
                    dispatchToStore(dispatchValue ? dispatchValue : newValue);
                }, options.debouncingTimeInMs)
            ); // Restart Timeout
        } else {
            dispatchToStore(dispatchValue ? dispatchValue : newValue);
        }
    };

    const dispatchToStore = (newValue: T): void => {
        setIsDirty(false);
        if (options?.noDispatch || !actionToDispatch) {
            return;
        }

        setIsInUpdate(true);
        dispatch(actionToDispatch(options?.dispatchArray ? [newValue] : newValue)).then(() => {
            setIsInUpdate(false);
        });
    };

    return [currentValue, setValue];
}

/**** A little bit more info during dev */
//Test objects
/*const obj1 = {
    a: 1,
    b: 1, 
    c: { x: 1, y: 1 },
    d: [ 1, 1 ]
  }
  const obj2 = {
    b: 2, 
    c: { y: 2, z: 2 },
    d: [ 2, 2 ],
    e: 2
  }
  const obj3 = mergeDeep(obj1, obj2);
*/

/* 
Here is the case why e use both isDirty and isInUpdate
- Quelqu'un set une currentValue
[isDirty=true, isInUpdate=false]
- Apres le debounce de 1 seconde, la valeur est dispatché
[isDirty=false, isInUpdate=true]
- Pendant ce temps... un setCurrentValue arrive avant le retour du dispatchEvent
[isDirty=true, isInUpdate=true]
- Le dispatch termine
[isDirty=true, isInUpdate=false]
- *** Yes, le isDirty est toujours à true
*/
