// default classes
export var arrow = {
    position: "absolute",
    fontSize: "".concat(8 / 14, "rem"),
    width: "3em",
    height: "3em",
    "&::before": {
        // eslint-disable-next-line prettier/prettier
        content: "\"\"",
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
    },
};
export var popper = function (theme) { return ({
    zIndex: theme.zIndex.tooltip,
    // eslint-disable-next-line prettier/prettier
    "&[x-placement*=\"bottom-end\"] $arrow": {
        marginTop: "-1em",
        width: "3em",
        height: "1em",
        "&::before": {
            borderWidth: "0 1em 1em 1em",
            borderColor: "transparent transparent ".concat(theme.palette.common.white, " transparent"),
        },
    },
    // eslint-disable-next-line prettier/prettier
    "&[x-placement*=\"bottom\"] $arrow": {
        marginTop: "-1em",
        width: "3em",
        height: "1em",
        "&::before": {
            borderWidth: "0 1em 1em 1em",
            borderColor: "transparent transparent ".concat(theme.palette.common.white, " transparent"),
        },
    },
}); };
