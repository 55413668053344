import { removeObjectFromArray } from "@kortex/aos-common";

import {
    TrainingCertificationPendingActionType,
    TrainingCertificationPendingActions,
    TrainingCertificationPendingState,
} from "./training-certification-pending-types";

const initialState: TrainingCertificationPendingState = {
    trainingCertificationPendingList: [],
    trainingCertificationsPendingOfUser: [],
};

export function trainingCertificationPendingReducer(
    state: TrainingCertificationPendingState = initialState,
    action: TrainingCertificationPendingActions
): TrainingCertificationPendingState {
    switch (action.type) {
        // TRAINING PENDING OF USER
        case TrainingCertificationPendingActionType.CLEAR_TRAINING_CERTIFICATION_PENDING_OF_USER:
            return {
                ...state,
                trainingCertificationsPendingOfUser: [],
            };
        case TrainingCertificationPendingActionType.SET_TRAINING_CERTIFICATION_PENDING_OF_USER:
            return {
                ...state,
                trainingCertificationsPendingOfUser: [...action.value],
            };
        case TrainingCertificationPendingActionType.INSERT_TRAINING_CERTIFICATION_PENDING_OF_USER:
            return {
                ...state,
                trainingCertificationsPendingOfUser: [...state.trainingCertificationsPendingOfUser, ...action.value],
            };
        case TrainingCertificationPendingActionType.DELETE_TRAINING_CERTIFICATION_PENDING_OF_USER: {
            return {
                trainingCertificationPendingList: removeObjectFromArray(
                    state.trainingCertificationPendingList,
                    action.value,
                    function (trainingCertificationPending) {
                        return trainingCertificationPending.trainingCertificationPendingId === this.trainingCertificationPendingId;
                    }
                ),
                trainingCertificationsPendingOfUser: removeObjectFromArray(
                    state.trainingCertificationsPendingOfUser,
                    action.value,
                    function (trainingCertificationPending) {
                        return trainingCertificationPending.trainingCertificationPendingId === this.trainingCertificationPendingId;
                    }
                ),
            };
        }

        // TRAINING PENDING LIST
        case TrainingCertificationPendingActionType.CLEAR_TRAINING_CERTIFICATION_PENDING_LIST:
            return {
                ...state,
                trainingCertificationPendingList: [],
            };
        case TrainingCertificationPendingActionType.SET_TRAINING_CERTIFICATION_PENDING_LIST:
            return {
                ...state,
                trainingCertificationPendingList: [...action.value],
            };
        case TrainingCertificationPendingActionType.INSERT_TRAINING_CERTIFICATION_PENDING_LIST:
            return {
                ...state,
                trainingCertificationPendingList: [...state.trainingCertificationPendingList, ...action.value],
            };
        case TrainingCertificationPendingActionType.DELETE_TRAINING_CERTIFICATION_PENDING_LIST: {
            return {
                trainingCertificationsPendingOfUser: removeObjectFromArray(
                    state.trainingCertificationsPendingOfUser,
                    action.value,
                    function (trainingCertificationPending) {
                        return trainingCertificationPending.trainingCertificationPendingId === this.trainingCertificationPendingId;
                    }
                ),
                trainingCertificationPendingList: removeObjectFromArray(
                    state.trainingCertificationPendingList,
                    action.value,
                    function (trainingCertificationPending) {
                        return trainingCertificationPending.trainingCertificationPendingId === this.trainingCertificationPendingId;
                    }
                ),
            };
        }

        default:
            return state;
    }
}
