import { KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles } from "@material-ui/core";
import React, { createRef } from "react";

const useStyles = makeStyles({
    textField: {
        height: "100%",
        margin: 0,
        width: "100%",
    },
});

export interface IOwnProps {
    communique: string;
    disabled?: boolean;
    onCancel: () => void;
    onConfirm: (changelog: string) => void;
    open: boolean;
}

function WorkInstructionsTrainingCommuniqueEditor(props: IOwnProps): JSX.Element {
    const { communique, disabled = false, onCancel, onConfirm, open } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const ref = createRef<HTMLInputElement>();

    useStopKeybindPropagation(open);

    const handleConfirm = (): void => {
        if (ref.current) onConfirm(ref.current.value.trim());
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={disabled}
            dialogProps={{
                id: "workInstructionsTrainingCommuniqueEditorId",
                maxWidth: "md",
            }}
            onCancel={onCancel}
            onConfirm={handleConfirm}
            open={open}
            textLabels={{
                cancelButtonLabel: translate("general.cancel"),
                proceedButtonLabel: translate("general.confirm"),
                titleLabel: translate("action.workInstructions.toolbar.trainingCommuniqueEditorTitle"),
            }}
        >
            <KortexTextField
                className={classes.textField}
                label={translate("action.workInstructions.toolbar.trainingCommuniqueEditorLabel")}
                TextFieldProps={{
                    disabled,
                    inputProps: { ref },
                    id: "WorkInstructionsTrainingCommuniqueEditorTrainingCommuniqueId",
                    multiline: true,
                    rows: 20,
                }}
                value={communique ?? ""}
                variant="outlined"
            />
        </KortexDialogConfirmation>
    );
}

export default WorkInstructionsTrainingCommuniqueEditor;
