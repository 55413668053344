import { theme } from "@aos/react-components";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { ItemCode } from "./ItemCode";

const useStyles = makeStyles({
    tableCellEmpty: {
        maxWidth: "24px",
        minWidth: "24px",
    },
    tableCellHeader: {
        backgroundColor: theme.palette.primary.main,
        border: `0px solid ${theme.palette.primary.main}`,
        color: theme.palette.common.white,
        maxHeight: "48px",
        padding: "0 16px",
    },
    tableCellItem: {
        maxWidth: "85px",
        minWidth: "85px",
    },
    tableCellLotNumber: {
        maxWidth: "85px",
        minWidth: "85px",
    },
    tableCellQuantity: {
        maxWidth: "28px",
        minWidth: "28px",
    },
    tableContainer: {
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
        maxHeight: "281px", // 505px (Code.tsx height) - 272px (Table code table height + Kanban code table height) + 48px (Item code table header)
    },
    tableRow: {
        height: "64px",
    },
    tableRowOdd: {
        backgroundColor: theme.palette.grey[100],
    },
    tableRowHead: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: "48px",
    },
    tableRowTableEmpty: {
        color: theme.palette.grey[500],
    },
});

interface IItemCodeTableProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    id?: string;
    items: string[];
    onDelete: (index: number) => void;
}

function ItemCodeTable(props: IItemCodeTableProps): JSX.Element {
    const { id, items, onDelete } = props;
    const classes = useStyles(props);
    const translate = useTranslate();

    /**
     * Delete the item code
     */
    const handleDelete =
        (index: number): (() => void) =>
        () => {
            onDelete(index);
        };

    return (
        <TableContainer className={classes.tableContainer} id={id}>
            <Table stickyHeader={true}>
                <TableHead>
                    <TableRow className={classes.tableRowHead}>
                        <TableCell className={`${classes.tableCellHeader} ${classes.tableCellItem}`}>
                            <Typography>{translate("scanner.item")}</Typography>
                        </TableCell>
                        <TableCell className={`${classes.tableCellHeader} ${classes.tableCellLotNumber}`}>
                            <Typography>{translate("scanner.lotNumber")}</Typography>
                        </TableCell>
                        <TableCell className={`${classes.tableCellHeader} ${classes.tableCellQuantity}`}>
                            <Typography>{translate("scanner.quantity")}</Typography>
                        </TableCell>
                        <TableCell className={`${classes.tableCellHeader} ${classes.tableCellEmpty}`} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.length ? (
                        items.map((code, index) => (
                            <ItemCode
                                classes={{ tableRow: index % 2 === 0 ? undefined : classes.tableRowOdd }}
                                code={code}
                                index={index}
                                key={`itemCode${index}`}
                                onDelete={handleDelete(index)}
                            />
                        ))
                    ) : (
                        <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableRowTableEmpty} colSpan={4}>
                                <Typography id="itemCodeTableEmptyId">{translate("scanner.scanItem")}</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ItemCodeTable;
