import { ITrainingCertificationPending, TrainingCertificationSignRequest } from "@kortex/aos-common";

import { BareAction, ComplexAction } from "../app.types";

export interface TrainingCertificationPendingState {
    trainingCertificationsPendingOfUser: ITrainingCertificationPending[];
    trainingCertificationPendingList: ITrainingCertificationPending[];
}

export enum TrainingCertificationPendingActionType {
    // TRAINING CERTIFICATION PENDING OF USER
    CLEAR_TRAINING_CERTIFICATION_PENDING_OF_USER = "@@TRAINING_CERTIFICATION_PENDING/CLEAR_TRAINING_CERTIFICATION_PENDING_OF_USER",
    SET_TRAINING_CERTIFICATION_PENDING_OF_USER = "@@TRAINING_CERTIFICATION_PENDING/SET_TRAINING_CERTIFICATION_PENDING_OF_USER",
    INSERT_TRAINING_CERTIFICATION_PENDING_OF_USER = "@@TRAINING_CERTIFICATION_PENDING/INSERT_TRAINING_CERTIFICATIONS_PENDING_OF_USER",
    DELETE_TRAINING_CERTIFICATION_PENDING_OF_USER = "@@TRAINING_CERTIFICATION_PENDING/DELETE_TRAINING_CERTIFICATION_PENDING_OF_USER",
    // TRAINING CERTIFICATION PENDING OF LIST
    CLEAR_TRAINING_CERTIFICATION_PENDING_LIST = "@@TRAINING_CERTIFICATION_PENDING/CLEAR_TRAINING_CERTIFICATION_PENDING_LIST",
    SET_TRAINING_CERTIFICATION_PENDING_LIST = "@@TRAINING_CERTIFICATION_PENDING/SET_TRAINING_CERTIFICATION_PENDING_LIST",
    INSERT_TRAINING_CERTIFICATION_PENDING_LIST = "@@TRAINING_CERTIFICATION_PENDING/INSERT_TRAINING_CERTIFICATIONS_PENDING_LIST",
    DELETE_TRAINING_CERTIFICATION_PENDING_LIST = "@@TRAINING_CERTIFICATION_PENDING/DELETE_TRAINING_CERTIFICATION_PENDING_LIST",
}

// TRAINING CERTIFICATION PENDING OF USER
export type TrainingCertificationPendingOfUserClear =
    BareAction<TrainingCertificationPendingActionType.CLEAR_TRAINING_CERTIFICATION_PENDING_OF_USER>;
export type TrainingCertificationPendingOfUserInsertedAction = ComplexAction<
    TrainingCertificationPendingActionType.INSERT_TRAINING_CERTIFICATION_PENDING_OF_USER,
    ITrainingCertificationPending[]
>;
export type TrainingCertificationPendingOfUserSetAction = ComplexAction<
    TrainingCertificationPendingActionType.SET_TRAINING_CERTIFICATION_PENDING_OF_USER,
    ITrainingCertificationPending[]
>;
export type TrainingCertificationPendingOfUserDeletedAction = ComplexAction<
    TrainingCertificationPendingActionType.DELETE_TRAINING_CERTIFICATION_PENDING_OF_USER,
    ITrainingCertificationPending[]
>;

// TRAINING CERTIFICATION PENDING OF LIST
export type TrainingCertificationPendingListClear =
    BareAction<TrainingCertificationPendingActionType.CLEAR_TRAINING_CERTIFICATION_PENDING_LIST>;
export type TrainingCertificationPendingListSetAction = ComplexAction<
    TrainingCertificationPendingActionType.SET_TRAINING_CERTIFICATION_PENDING_LIST,
    ITrainingCertificationPending[]
>;
export type TrainingCertificationPendingListInsertedAction = ComplexAction<
    TrainingCertificationPendingActionType.INSERT_TRAINING_CERTIFICATION_PENDING_LIST,
    ITrainingCertificationPending[]
>;
export type TrainingCertificationPendingListDeletedAction = ComplexAction<
    TrainingCertificationPendingActionType.DELETE_TRAINING_CERTIFICATION_PENDING_LIST,
    ITrainingCertificationPending[]
>;

export type TrainingCertificationPendingActions =
    | TrainingCertificationPendingOfUserClear
    | TrainingCertificationPendingOfUserSetAction
    | TrainingCertificationPendingOfUserInsertedAction
    | TrainingCertificationPendingOfUserDeletedAction
    | TrainingCertificationPendingListClear
    | TrainingCertificationPendingListSetAction
    | TrainingCertificationPendingListInsertedAction
    | TrainingCertificationPendingListDeletedAction;

export type TrainingCertificationSign = {
    trainingsCertificationSelected: TrainingCertificationSignRequest;
    unselectTrainingsCertification: ITrainingCertificationPending[];
};
