import { ProcessVariableType } from "../interfaces/models/process";
import { ProcessSystemVariableIdentifier, } from "./ProcessSystemVariableIdentifier";
//--- Variable storer ---------------------------------------------------------
const ProcessVariableStoringMethod = {
    APPEND: "apd",
    OVERWRITE: "ovr",
};
export class ProcessVariableStorer {
    constructor(identifier = "", storingMethod = ProcessVariableStorer.OVERWRITE) {
        this.identifier = "";
        this.storingMethod = ProcessVariableStorer.OVERWRITE;
        this.identifier = identifier;
        this.storingMethod = storingMethod;
    }
    /**
     * Add template litteral to identifier.
     * Ex: varName --> ${varName}
     *
     * @param {string} identifier - variable identifier
     */
    static addTemplateLitteral(identifier) {
        return "${" + identifier + "}";
    }
    /**
     * Extract variable identifier from ${variableIdentifier}
     *
     * @param {string} identifier - variable identifier in a template string
     *
     * @returns {string} - identifier without ${}
     */
    static cleanIdentifier(identifier) {
        const regexResult = new RegExp(/\${(.*?)}/).exec(identifier);
        return regexResult && regexResult[1] ? regexResult[1] : identifier;
    }
}
ProcessVariableStorer.APPEND = ProcessVariableStoringMethod.APPEND;
ProcessVariableStorer.OVERWRITE = ProcessVariableStoringMethod.OVERWRITE;
// Regex: (Find and remove "${")(any character, as few as possible)(Find and remove "}")
export const FIND_VARIABLE_IN_PROCESS_ACTION_REGEX = new RegExp("(?<=\\$\\{).*?(?=\\})", "g");
export const IDENTIFIER_ALPHANUMERIC_VALIDATOR_REGEX = RegExp(/^[a-zA-Z0-9_]+$/);
export const IDENTIFIER_RESERVED_VALIDATOR_REGEX = RegExp(/^(SYS_).+$/);
/**
 * Validates that the identifier is alphanumerics
 *
 * @param {string} identifier - variable identifier
 *
 * Only accepts :
 * + a - z
 * + A - Z
 * + 0 - 9
 * + _
 */
export function isVariableIdentifierAlphanumeric(identifier) {
    return IDENTIFIER_ALPHANUMERIC_VALIDATOR_REGEX.test(identifier);
}
/**
 * Validates that the identifier is a reserved words
 *
 * @param {string} identifier - variable identifier
 *
 * Reserved words :
 * + SYS_
 */
export function isVariableIdentifierReserved(identifier) {
    return IDENTIFIER_RESERVED_VALIDATOR_REGEX.test(identifier);
}
function createProcessVariableFromSystemVariable(identifier, defaultValue) {
    switch (typeof defaultValue) {
        case "number":
            return {
                decimals: 0,
                defaultValue: defaultValue.toString(),
                identifier,
                type: ProcessVariableType.NUMBER,
            };
        default:
            return {
                decimals: 0,
                defaultValue: defaultValue,
                identifier,
                type: ProcessVariableType.STRING,
            };
    }
}
export const PROCESS_SYSTEM_VARIABLES = [
    // Action
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_ACTION_NAME, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_ACTION_STEP, 0),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_ACTION_STEP_NAME, ""),
    // Fail
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_FAIL_ACTION_NAME, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_FAIL_ACTION_STEP, 0),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_FAIL_ACTION_STEP_NAME, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_FAIL_DESCRIPTION, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_FAIL_TIMESTAMP, 0),
    // Local date
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_LOCAL_DATE, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_LOCAL_DAY, 0),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_LOCAL_MONTH, 0),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_LOCAL_TIME, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_LOCAL_TIMESTAMP, 0),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_LOCAL_YEAR, 0),
    // Process
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_PROCESS_NAME, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_PROCESS_REFERENCE_ID, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_PROCESS_STATUS, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_PROCESS_VERSION, ""),
    // Scheduler
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_SCHEDULER_JOB_PART_NUMBER, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_SCHEDULER_JOB_QUANTITY, 0),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_SCHEDULER_JOB_REFERENCE, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_SCHEDULER_JOB_WORK_ORDER, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_SCHEDULER_JOB_PROCESS_QUANTITY, 0),
    // System
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_TRACKING_ID, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_BATCH_ENABLE, 0),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_BATCH_QUANTITY, 0),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_BATCH_FAIL_QUANTITY, 0),
    // User
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_USER_CODE, ""),
    createProcessVariableFromSystemVariable(ProcessSystemVariableIdentifier.SYS_USER_NAME, ""),
];
