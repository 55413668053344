import { SequencerError } from "./sequencer-error";
export class InvalidCoreActionTypeError extends SequencerError {
    constructor(key, message = "Invalid core action type") {
        super(key, message);
    }
    get name() {
        return "InvalidCoreActionTypeError";
    }
    get originalError() {
        return super.originalError;
    }
}
