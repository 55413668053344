import { ProcessUiModel, IUserDbModel } from "@kortex/aos-common";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useRef } from "react";

import { sortProcessByVersions } from "../../../utilitites/sortProcessByVersions";
import { useTranslate } from "../../../hooks/useTranslate";

import { IPage, PrintBasePage } from "./PrintBasePage";
import PrintDraftWatermark from "./PrintDraftWaterMark";

const MAX_HEIGHT = 690;

const useStyles = makeStyles({
    tableCell: {
        whiteSpace: "nowrap",
    },
    tableCellChangelog: {
        whiteSpace: "pre-wrap",
    },
});

interface IOwnProps {
    howMany: number;
    index: number;
    onOverflow?: (index: number) => void;
    processVersions?: ProcessUiModel[];
    selectedVersionId?: number;
    startIndex: number;
    userList: IUserDbModel[];
}

type IAllProps = IOwnProps & IPage;

export default function PrintRevisionHistory(props: IAllProps): JSX.Element {
    const { howMany, onOverflow, processVersions, selectedVersionId, startIndex, userList } = props;

    const classes = useStyles();
    const tableContainer = useRef<HTMLDivElement>(null);
    const translate = useTranslate();

    useEffect((): void => {
        if (tableContainer && tableContainer.current && tableContainer.current.clientHeight > MAX_HEIGHT && onOverflow) {
            onOverflow(props.index);
        }
    });

    const generateTableRows = (versions: ProcessUiModel[], userList: IUserDbModel[]): JSX.Element[] => {
        return versions
            .filter((version, index): boolean => index >= startIndex && index < startIndex + howMany)
            .map((process, index): JSX.Element => {
                // User who submitted the version
                const versionSubmitterUserInfo = userList.find((user) => user.userId === process.versionCreatorId);
                const versionSubmitterUserName = versionSubmitterUserInfo
                    ? `${versionSubmitterUserInfo?.firstName} ${versionSubmitterUserInfo?.lastName} (${versionSubmitterUserInfo?.userName})`
                    : "";
                return (
                    <TableRow key={index}>
                        <TableCell className={classes.tableCell}>{process.version}</TableCell>
                        <TableCell className={classes.tableCell}>{process.versionReferenceCode}</TableCell>
                        <TableCell className={classes.tableCellChangelog}>{process.versionChangeLog}</TableCell>
                        <TableCell className={classes.tableCell}>{versionSubmitterUserName}</TableCell>
                    </TableRow>
                );
            });
    };

    /**
     * Gets the selected process version
     */
    const getSelectedProcessVersion = (): ProcessUiModel | undefined => {
        if (!processVersions) {
            return;
        }

        return processVersions.find((version) => version.processId === selectedVersionId);
    };

    /**
     * Gets version filtered
     */
    const versionFiltered = (): ProcessUiModel[] => {
        if (processVersions) {
            if (getSelectedProcessVersion()?.isDraft) {
                return processVersions;
            } else {
                return processVersions.filter(
                    (process) =>
                        !process.isDraft &&
                        parseFloat(process.version || "") <= parseFloat((getSelectedProcessVersion() as ProcessUiModel).version || "")
                );
            }
        } else {
            return [];
        }
    };

    return (
        <PrintBasePage {...props}>
            <g>
                <foreignObject x="0" y="0" width="100%" height="100%">
                    <div xmlns="http://www.w3.org/1999/xhtml">
                        <Box marginTop={2}>
                            <Typography variant="h1" color="primary" align="center" display="block">
                                {translate("process.printing.revisionHistory")}
                            </Typography>
                        </Box>
                        <div ref={tableContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>{translate("process.printing.version")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{translate("process.printing.code")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{translate("process.printing.description")}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>{translate("process.printing.author")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {processVersions &&
                                        getSelectedProcessVersion() &&
                                        generateTableRows(
                                            sortProcessByVersions(selectedVersionId ? versionFiltered() : processVersions),
                                            userList
                                        )}
                                </TableBody>
                            </Table>
                        </div>
                        {getSelectedProcessVersion() && (
                            <PrintDraftWatermark
                                isDraft={Boolean(
                                    processVersions &&
                                        selectedVersionId !== undefined &&
                                        (getSelectedProcessVersion() as ProcessUiModel).isDraft
                                )}
                            />
                        )}
                    </div>
                </foreignObject>
            </g>
        </PrintBasePage>
    );
}
