import { useState } from "react";
import { Operation } from "fast-json-patch";

/**
 * Hooks to capture a undo redo history based on object diff patch
 * instead of full state capture for every capture
 */
export function useHistoryStack(): [
    (previousDiff: unknown, nextDiff: unknown) => void,
    () => Operation[],
    () => Operation[],
    boolean,
    boolean,
    () => unknown,
] {
    const [undoStack, setUndoStack] = useState<Operation[][]>([]);
    const [redoStack, setRedoStack] = useState<Operation[][]>([]);
    const [stackPointer, setStackPointer] = useState(0);

    const canUndo = undoStack.length > 0 && stackPointer > 0;
    const canRedo = redoStack.length > stackPointer;

    /**
     * Push new object diff patches on the undo/redo stack
     *
     * @param {Array<Operation>} previousDiff - Diff between current object and future object
     * @param {Array<Operation>} nextDiff - Diff between future object and current object
     */
    const push = (previousDiff: Operation[], nextDiff: Operation[]): void => {
        if (previousDiff.length > 0) {
            redoStack.splice(stackPointer, redoStack.length);
            undoStack.splice(stackPointer, undoStack.length);
            setStackPointer(stackPointer + 1);
            setRedoStack([...redoStack, nextDiff]);
            setUndoStack([...undoStack, previousDiff]);
        }
    };

    /**
     * Return diff patch from previous stack pointer
     */
    const undo = (): Operation[] => {
        if (stackPointer > 0) {
            setStackPointer(stackPointer - 1);
            return undoStack[stackPointer - 1];
        }

        return [];
    };

    /**
     * Return diff patch from next stack pointer
     */
    const redo = (): Operation[] => {
        if (stackPointer <= redoStack.length - 1) {
            setStackPointer(stackPointer + 1);
            return redoStack[stackPointer];
        }

        return [];
    };

    /**
     * Clear the current undo/redo stack
     */
    const clear = (): void => {
        setStackPointer(0);
        setRedoStack([]);
        setUndoStack([]);
    };

    return [push, undo, redo, canUndo, canRedo, clear];
}
