import { upsertObjectFromArray } from "@kortex/aos-common";

import { FailureState, FailureActions, FailureActionType } from "./failures-types";

export const initialState: FailureState = {
    symptoms: [],
    types: [],
};

/**
 * Failures reducer
 *
 * @param {FailureState} state - state
 * @param {FailureActions} action - action
 */
export function failuresReducer(state = initialState, action: FailureActions): FailureState {
    switch (action.type) {
        case FailureActionType.FAILURE_SYMPTOM_FETCH_ALL: {
            return {
                ...state,
                symptoms: action.value,
            };
        }
        case FailureActionType.FAILURE_SYMPTOM_NOTIFICATION_INSERTED: {
            return {
                ...state,
                symptoms: upsertObjectFromArray(state.symptoms, action.value, function (symptom) {
                    return symptom.failureSubTypeId === this.failureSubTypeId;
                }),
            };
        }
        case FailureActionType.FAILURE_SYMPTOM_NOTIFICATION_UPDATED: {
            return {
                ...state,
                symptoms: upsertObjectFromArray(state.symptoms, action.value, function (symptom) {
                    return symptom.failureSubTypeId === this.failureSubTypeId;
                }),
            };
        }
        case FailureActionType.FAILURE_TYPE_FETCH_ALL: {
            return {
                ...state,
                types: action.value,
            };
        }
        case FailureActionType.FAILURE_TYPE_NOTIFICATION_INSERTED: {
            return {
                ...state,
                types: upsertObjectFromArray(state.types, action.value, function (type) {
                    return type.failureTypeId === this.failureTypeId;
                }),
            };
        }
        case FailureActionType.FAILURE_TYPE_NOTIFICATION_UPDATED: {
            return {
                ...state,
                types: upsertObjectFromArray(state.types, action.value, function (type) {
                    return type.failureTypeId === this.failureTypeId;
                }),
            };
        }
        default: {
            return state;
        }
    }
}
