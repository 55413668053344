import { APIPayload, APIResponse } from "../../utilitites/kortex-client/client";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";

const REPORT_TIMEOUT = 10_000; // 10 seconds
const AUDIT_TRAIL_TIMEOUT = 240_000; // 4 minutes
const RESULT_PROCESS_ACTION_STEP_TIMEOUT = 120_000; // 2 minute

/**
 * Get Summary Reports
 */
export function reportGetSummary(options: APIPayload<"report", "getSummary">): StandardThunk<APIResponse<"report", "getSummary">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"report", "getSummary">> {
        return api.services.report
            .getSummary(options)({ timeout: REPORT_TIMEOUT })
            .catch((reason) => {
                handleAPIError(reason, _dispatch);
                throw reason;
            });
    };
}

/**
 * Get Process Result Reports
 */
export function reportGetProcessResult(
    options: APIPayload<"report", "getProcessResult">
): StandardThunk<APIResponse<"report", "getProcessResult">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"report", "getProcessResult">> {
        return api.services.report
            .getProcessResult(options)({ timeout: REPORT_TIMEOUT })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Process Step Result Reports
 */
export function reportGetProcessStepResult(
    options: APIPayload<"report", "getProcessStepResult">
): StandardThunk<APIResponse<"report", "getProcessStepResult">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"report", "getProcessStepResult">> {
        return api.services.report
            .getProcessStepResult(options)({ timeout: RESULT_PROCESS_ACTION_STEP_TIMEOUT })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Rework Reports
 */
export function reportGetRework(options: APIPayload<"report", "getRework">): StandardThunk<APIResponse<"report", "getRework">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"report", "getRework">> {
        return api.services.report
            .getRework(options)({ timeout: REPORT_TIMEOUT })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Audit Trail Reports
 */
export function reportGetAuditTrail(options: APIPayload<"report", "getAudit">): StandardThunk<APIResponse<"report", "getAudit">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"report", "getAudit">> {
        // Can be extra long depending on the request
        return api.services.report
            .getAudit(options)({ timeout: AUDIT_TRAIL_TIMEOUT })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Electronic Signature
 */
export function reportGetElectronicSignature(
    options: APIPayload<"report", "getElectronicSignature">
): StandardThunk<APIResponse<"report", "getElectronicSignature">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"report", "getElectronicSignature">> {
        return api.services.report
            .getElectronicSignature(options)({ timeout: REPORT_TIMEOUT })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Entity Reports
 */
export function reportGetEntity(options: APIPayload<"report", "getEntity">): StandardThunk<APIResponse<"report", "getEntity">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"report", "getEntity">> {
        return api.services.report
            .getEntity(options)({ timeout: REPORT_TIMEOUT })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get DataStore Value Reports
 */
export function reportGetDataStoreValue(
    options: APIPayload<"report", "getDataStoreValue">
): StandardThunk<APIResponse<"report", "getDataStoreValue">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"report", "getDataStoreValue">> {
        return api.services.report
            .getDataStoreValue(options)({ timeout: REPORT_TIMEOUT })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get DataStore Table Reports
 */
export function reportGetDataStoreTable(
    options: APIPayload<"report", "getDataStoreTable">
): StandardThunk<APIResponse<"report", "getDataStoreTable">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"report", "getDataStoreTable">> {
        return api.services.report
            .getDataStoreTable(options)({ timeout: REPORT_TIMEOUT })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}

/**
 * Get Training Reports
 */
export function reportGetTraining(options: APIPayload<"report", "getTraining">): StandardThunk<APIResponse<"report", "getTraining">> {
    return async function (
        _dispatch: StandardDispatch,
        _getState: () => AppState,
        { apiUI: api }
    ): Promise<APIResponse<"report", "getTraining">> {
        return api.services.report
            .getTraining(options)({ timeout: 20000 })
            .catch((error) => {
                handleAPIError(error, _dispatch);
                throw error;
            });
    };
}
