import { errorPalette, greyPalette, rainbowPalette, successPalette, theme, warningPalette } from "@aos/react-components";
import { IUserTrainingProcess } from "@kortex/aos-common";

export function getUserTrainingStatusColor(
    current: IUserTrainingProcess | null,
    latest: IUserTrainingProcess | null
): IGetUserTrainingStatusColor {
    // Check for archived
    if (current?.training?.status === "archived") {
        if (!latest || latest?.training?.status === "archived") {
            return ARCHIVED;
        }

        // Check latest training
        if (latest.training) {
            if (latest.training.trained) {
                return UPCOMING_READY;
            } else {
                return UPCOMING;
            }
        }
    }

    // Current training exists and is trained
    if (current?.training?.trained) {
        // If latest is not trained
        if (latest?.training?.trained === false) {
            return UPCOMING;
        }

        return UP_TO_DATE;
    }

    // Current training exists and is not trained
    if (current?.training?.trained === false) {
        // If training is deprecated
        if (current.training.status === "deprecated") {
            return NOT_MAINTAINED;
        }

        // If status of training is not trained
        return LATE;
    }

    // No current training and trained for latest
    if (latest?.training?.trained) {
        return UPCOMING_READY;
    }

    // No current training and not trained for latest
    if (latest?.training?.trained === false && !current?.training) {
        return UPCOMING;
    }

    return LATE;
}

interface IGetUserTrainingStatusColor {
    color: string;
    priority: number; // Lower number = higher priority
    tooltip: string;
}

const ARCHIVED: IGetUserTrainingStatusColor = {
        color: theme.palette.common.black, // Black
        priority: 6,
        tooltip: "training.tooltipArchivedTraining",
    },
    LATE: IGetUserTrainingStatusColor = {
        color: errorPalette[500], // Red
        priority: 1,
        tooltip: "training.tooltipLateTraining",
    },
    NOT_MAINTAINED: IGetUserTrainingStatusColor = {
        color: greyPalette[500], // Grey
        priority: 5,
        tooltip: "training.tooltipNotMaintainedTraining",
    },
    UP_TO_DATE: IGetUserTrainingStatusColor = {
        color: successPalette[500], // Green
        priority: 4,
        tooltip: "training.tooltipUpToDateTraining",
    },
    UPCOMING: IGetUserTrainingStatusColor = {
        color: warningPalette[500], // Yellow
        priority: 2,
        tooltip: "training.tooltipUpcomingTraining",
    },
    UPCOMING_READY: IGetUserTrainingStatusColor = {
        color: rainbowPalette[300], // Light Blue
        priority: 3,
        tooltip: "training.tooltipUpcomingTrainingReady",
    };
