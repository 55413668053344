import { IReportTagEntryDbView } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { useEffect, useState } from "react";

import { reportTagGetEntryViewList } from "../../../redux/report-manager/report-thunks-tag";

const useStyles = makeStyles({
    list: {
        overflow: "scroll",
        maxHeight: "600px",
    },
});

interface ITagSelectorProps {
    selectedTagEntryId?: number;

    onSelectedTagEntryIdChanged?: (tagEntryId: number) => void;
}

export default function TagSelector(props: ITagSelectorProps): JSX.Element {
    /**
     * COMPONENT STATES
     */
    const classes = useStyles();

    const [reportTagEntries, setReportTagEntries] = useState<IReportTagEntryDbView[]>([]);
    const [selectedTagEntryId, setSelectedTagEntryId] = useState(props.selectedTagEntryId || 0);

    const dispatch = useThunkDispatch();

    /**
     * Fetch report tag entry list from database
     */
    useEffect((): void => {
        dispatch(reportTagGetEntryViewList())
            .then((result): void => {
                setReportTagEntries([...result]);
            })
            .catch();
    }, []);

    /**
     * Update local selecte tag entry id based on parent props
     */
    useEffect((): void => {
        setSelectedTagEntryId(props.selectedTagEntryId || 0);
    }, [props.selectedTagEntryId]);

    /**
     * Handle entry selection from the list
     *
     * @param {number} tagEntryId - Selected tag entry Id
     */
    const handleTagEntryClick =
        (tagEntryId: number): (() => void) =>
        (): void => {
            if (tagEntryId !== selectedTagEntryId) {
                setSelectedTagEntryId(tagEntryId);
                if (props.onSelectedTagEntryIdChanged) {
                    props.onSelectedTagEntryIdChanged(tagEntryId);
                }
            }
        };

    return (
        <List className={classes.list}>
            {reportTagEntries.map((tagEntry): JSX.Element => {
                return (
                    <ListItem
                        key={tagEntry.reportTagEntryId}
                        button={true}
                        selected={selectedTagEntryId === tagEntry.reportTagEntryId}
                        onClick={handleTagEntryClick(tagEntry.reportTagEntryId)}
                    >
                        {tagEntry.reportTagEntry}
                    </ListItem>
                );
            })}
        </List>
    );
}
