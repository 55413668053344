import { greyPalette, KortexCard, KortexDialogConfirmation, theme } from "@aos/react-components";
import { ProcessActionType } from "@kortex/aos-common";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography/Typography";
import * as React from "react";
import { useEffect, useState } from "react";

import { useForeground } from "../../../../../hooks/useForeground";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { ILocale } from "../../../../../locales/ILocale";
import { getActionColor } from "../../../../../utilitites/getActionColor";
import { getActionDescription } from "../../../../../utilitites/getActionDescription";
import { getActionLabel } from "../../../../../utilitites/getActionLabel";

import ActionIcon from "./ActionIcon";

const useStyles = makeStyles({
    contentContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        rowGap: "24px",
        columnGap: "24px",
        [theme.breakpoints.down(1280)]: {
            gridTemplateColumns: "1fr 1fr",
        },
    },
    cardContainer: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.secondary[50],
        },
    },
    optionContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        marginBottom: "15px",
    },
});

interface IActionInfo {
    active: boolean;
    isRouting: boolean;
    isProcess: boolean;
    type: ProcessActionType;
    color: string;
    label: keyof ILocale;
    description: keyof ILocale;
}

interface IActionInfoMap {
    [key: string]: IActionInfo;
}

export const actionList: IActionInfoMap = {};
actionList["core-work-instructions"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-work-instructions",
    label: getActionLabel("core-work-instructions"),
    description: getActionDescription("core-work-instructions"),
    color: getActionColor("core-work-instructions"),
};
actionList["core-robot"] = {
    active: false,
    isRouting: false,
    isProcess: true,
    type: "core-robot",
    label: getActionLabel("core-robot"),
    description: getActionDescription("core-robot"),
    color: getActionColor("core-robot"),
};
actionList["core-condition"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-condition",
    label: getActionLabel("core-condition"),
    description: getActionDescription("core-condition"),
    color: getActionColor("core-condition"),
};
actionList["core-loop"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-loop",
    label: getActionLabel("core-loop"),
    description: getActionDescription("core-loop"),
    color: getActionColor("core-loop"),
};
actionList["core-math"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-math",
    label: getActionLabel("core-math"),
    description: getActionDescription("core-math"),
    color: getActionColor("core-math"),
};
actionList["core-message"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-message",
    label: getActionLabel("core-message"),
    description: getActionDescription("core-message"),
    color: getActionColor("core-message"),
};
actionList["core-failure-ticket-create"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-failure-ticket-create",
    label: getActionLabel("core-failure-ticket-create"),
    description: getActionDescription("core-failure-ticket-create"),
    color: getActionColor("core-failure-ticket-create"),
};
actionList["core-time"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-time",
    label: getActionLabel("core-time"),
    description: getActionDescription("core-time"),
    color: getActionColor("core-time"),
};
actionList["core-parser"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-parser",
    label: getActionLabel("core-parser"),
    description: getActionDescription("core-parser"),
    color: getActionColor("core-parser"),
};
actionList["core-set"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-set",
    label: getActionLabel("core-set"),
    description: getActionDescription("core-set"),
    color: getActionColor("core-set"),
};
actionList["core-stop-process"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-stop-process",
    label: getActionLabel("core-stop-process"),
    description: getActionDescription("core-stop-process"),
    color: getActionColor("core-stop-process"),
};
actionList["core-fail"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-fail",
    label: getActionLabel("core-fail"),
    description: getActionDescription("core-fail"),
    color: getActionColor("core-fail"),
};
actionList["core-email"] = {
    active: false,
    isRouting: false,
    isProcess: true,
    type: "core-email",
    label: getActionLabel("core-email"),
    description: getActionDescription("core-email"),
    color: getActionColor("core-email"),
};
actionList["core-connector"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-connector",
    label: getActionLabel("core-connector"),
    description: getActionDescription("core-connector"),
    color: getActionColor("core-connector"),
};
actionList["core-routing-process"] = {
    active: true,
    isRouting: true,
    isProcess: false,
    type: "core-routing-process",
    label: getActionLabel("core-routing-process"),
    description: getActionDescription("core-routing-process"),
    color: getActionColor("core-routing-process"),
};
actionList["core-routing-fail"] = {
    active: true,
    isRouting: true,
    isProcess: false,
    type: "core-routing-fail",
    label: getActionLabel("core-routing-fail"),
    description: getActionDescription("core-routing-fail"),
    color: getActionColor("core-routing-fail"),
};
actionList["core-input"] = {
    active: false,
    isRouting: false,
    isProcess: true,
    type: "core-input",
    label: getActionLabel("core-input"),
    description: getActionDescription("core-input"),
    color: getActionColor("core-input"),
};
actionList["core-output"] = {
    active: false,
    isRouting: false,
    isProcess: true,
    type: "core-output",
    label: getActionLabel("core-output"),
    description: getActionDescription("core-output"),
    color: getActionColor("core-output"),
};
actionList["core-data-store"] = {
    active: true,
    isRouting: false,
    isProcess: true,
    type: "core-data-store",
    label: getActionLabel("core-data-store"),
    description: getActionDescription("core-data-store"),
    color: getActionColor("core-data-store"),
};

interface IOwnProps {
    open: boolean;
    isRouting: boolean;
    addFail: boolean;
    onInsertAction: (actionType: ProcessActionType) => void;
    onClose: () => void;
}

export default function ActionSelector(props: IOwnProps): JSX.Element {
    const classes = useStyles();
    const translate = useTranslate();

    const [selectedActionType, setSelectedActionType] = useState<ProcessActionType | undefined>(undefined);
    const [hideInactiveAction, setHideInactiveAction] = useState<boolean>(true);

    useForeground(props.open);

    useEffect((): void => {
        setSelectedActionType(undefined);
    }, [props.open]);

    const handleClose = (): void => {
        props.onClose();
    };

    const handleSelectAction = (): void => {
        if (!selectedActionType) {
            return;
        }
        props.onInsertAction(selectedActionType);
        props.onClose();
    };

    const selectAction =
        (actionType: ProcessActionType): (() => void) =>
        (): void => {
            setSelectedActionType(actionType);
        };

    const handleChangeHideInactiveAction = (): void => {
        setHideInactiveAction(!hideInactiveAction);
    };

    const handleActionSelect =
        (actionType: ProcessActionType): (() => void) =>
        (): void => {
            setSelectedActionType(actionType);
            props.onInsertAction(actionType);
            props.onClose();
        };

    return (
        <KortexDialogConfirmation
            open={props.open}
            dialogProps={{
                id: "actionSelectorId",
                fullScreen: false,
                maxWidth: "lg",
            }}
            textLabels={{
                titleLabel: translate("processActionSelector.dialogTitle"),
                cancelButtonLabel: translate("processActionSelector.cancel"),
                proceedButtonLabel: translate("processActionSelector.select"),
            }}
            onCancel={handleClose}
            onConfirm={handleSelectAction}
            closeOnEscape={true}
        >
            <div className={classes.optionContainer}>
                <Typography variant="h6">{translate("processActionSelector.hideInactiveActions")}</Typography>
                <Switch checked={hideInactiveAction} onChange={handleChangeHideInactiveAction} value="checked" />
            </div>
            <div className={classes.contentContainer}>
                {Object.keys(actionList).map((actionKey, index) => {
                    if (actionKey === "core-input" || actionKey === "core-output") {
                        return <React.Fragment key={index} />;
                    }
                    if (actionKey === "core-fail" && !props.addFail) {
                        return <React.Fragment key={index} />;
                    }
                    if (actionKey === "core-routing-fail" && !props.addFail) {
                        return <React.Fragment key={index} />;
                    }

                    const action = actionList[actionKey];

                    if (!action.active && hideInactiveAction) {
                        return <React.Fragment key={index} />;
                    }

                    if (!props.isRouting && !action.isProcess) {
                        return <React.Fragment key={index} />;
                    }

                    if (props.isRouting && !action.isRouting) {
                        return <React.Fragment key={index} />;
                    }

                    return (
                        <KortexCard
                            id={"actionCardId-" + action.type}
                            selected={selectedActionType && action.type === selectedActionType}
                            classes={{
                                root: action.active ? classes.cardContainer : undefined,
                            }}
                            onClick={selectAction(action.type)}
                            onDoubleClick={handleActionSelect(action.type)}
                            color={action.active ? action.color : greyPalette[300]}
                            icon={<ActionIcon style={{ color: theme.palette.common.white, fontSize: "2.5rem" }} type={action.type} />}
                            key={index}
                            textLabels={{
                                label: translate(action.label),
                                description: translate(action.description),
                            }}
                        />
                    );
                })}
            </div>
        </KortexDialogConfirmation>
    );
}
