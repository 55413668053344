import { removeObjectFromArray } from "@kortex/aos-common";

import { TrainingPendingActionType, TrainingPendingActions, TrainingPendingState } from "./training-pending-types";

const initialState: TrainingPendingState = {
    trainingsPendingOfUser: [],
    trainingPendingList: [],
};

export function trainingPendingReducer(state: TrainingPendingState = initialState, action: TrainingPendingActions): TrainingPendingState {
    switch (action.type) {
        // TRAINING PENDING OF USER
        case TrainingPendingActionType.CLEAR_TRAINING_PENDING_OF_USER:
            return {
                ...state,
                trainingsPendingOfUser: [],
            };
        case TrainingPendingActionType.SET_TRAINING_PENDING_OF_USER:
            return {
                ...state,
                trainingsPendingOfUser: [...action.value],
            };
        case TrainingPendingActionType.INSERT_TRAINING_PENDING_OF_USER:
            return {
                ...state,
                trainingsPendingOfUser: [...state.trainingsPendingOfUser, ...action.value],
            };
        case TrainingPendingActionType.DELETE_TRAINING_PENDING_OF_USER: {
            return {
                trainingPendingList: removeObjectFromArray(state.trainingPendingList, action.value, function (trainingPending) {
                    return trainingPending.trainingPendingId === this.trainingPendingId;
                }),
                trainingsPendingOfUser: removeObjectFromArray(state.trainingsPendingOfUser, action.value, function (trainingPending) {
                    return trainingPending.trainingPendingId === this.trainingPendingId;
                }),
            };
        }

        // TRAINING PENDING LIST
        case TrainingPendingActionType.CLEAR_TRAINING_PENDING_LIST:
            return {
                ...state,
                trainingPendingList: [],
            };
        case TrainingPendingActionType.SET_TRAINING_PENDING_LIST:
            return {
                ...state,
                trainingPendingList: [...action.value],
            };
        case TrainingPendingActionType.INSERT_TRAINING_PENDING_LIST:
            return {
                ...state,
                trainingPendingList: [...state.trainingPendingList, ...action.value],
            };
        case TrainingPendingActionType.DELETE_TRAINING_PENDING_LIST: {
            return {
                trainingsPendingOfUser: removeObjectFromArray(state.trainingsPendingOfUser, action.value, function (trainingPending) {
                    return trainingPending.trainingPendingId === this.trainingPendingId;
                }),
                trainingPendingList: removeObjectFromArray(state.trainingPendingList, action.value, function (trainingPending) {
                    return trainingPending.trainingPendingId === this.trainingPendingId;
                }),
            };
        }

        default:
            return state;
    }
}
