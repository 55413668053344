import { Snackbar } from "@kortex/aos-ui/components/layout/snackbarConfigurator";

import { AppState, IExtraThunkArg } from "../../store";

export function getProcessIsDraftFromState(
    processId: number,
    getState: () => AppState,
    intl: Pick<IExtraThunkArg, "intl">
): boolean | undefined {
    const processIsDraft = getState().process.processes.find((process) => process.processId === processId)?.isDraft;
    if (processIsDraft) {
        return true;
    } else {
        if (processIsDraft === false) {
            Snackbar.warning(intl.intl.intl.formatMessage({ id: "porcess.messageError.updtateAction" }));
        } else {
            console.error(`
                \nThe function getProcessIsDraftFromState() return undefined.
                \nThe function could not find the process in the redux state corresponding to the process ID of the action updated.
            `);

            Snackbar.error(intl.intl.intl.formatMessage({ id: "porcess.messageError.errorSystem" }));
        }
        return false;
    }
}
