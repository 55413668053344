import { Task } from "./Task";
export class TaskProcessReview extends Task {
    constructor(task) {
        super(task);
    }
    /**
     * Process ID for which the task has been assigned.
     * For a process review task, the link ID is a process ID (linkId = processId).
     */
    get processId() {
        return this._model.linkId;
    }
    set processId(processId) {
        this._model.linkId = processId;
    }
}
