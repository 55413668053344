export const processApprovalGroup = {
    rpc: {
        getAll: {
            funcId: 2300,
            funcName: "processApprovalGroupAll",
        },
    },
    serviceId: 23,
    serviceVersion: 1,
};
