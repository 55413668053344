import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

function Instructions(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M21.9800212,16.84375 C21.9800212,17.0625 21.9175212,17.25 21.7925212,17.40625 C21.6675212,17.5625 21.4800212,17.65625 21.2612712,17.6875 L17.0737712,18.09375 L16.7925212,19.71875 C16.7612712,19.875 16.6675212,20.03125 16.5425212,20.125 C16.4487712,20.21875 16.3237712,20.25 16.1987712,20.25 C16.1675212,20.25 16.1050212,20.25 16.0737712,20.25 L9.16752124,18.90625 L8.16752124,19 C8.16752124,19 8.13627124,19 8.10502124,19 C7.91752124,19 7.73002124,18.9375 7.60502124,18.8125 C7.51127124,18.75 7.44877124,18.625 7.38627124,18.53125 L2.63627124,17.625 C2.41752124,17.59375 2.26127124,17.46875 2.13627124,17.3125 C2.01127124,17.125 1.98002124,16.9375 2.01127124,16.71875 L4.51127124,3.78125 C4.54252124,3.5625 4.66752124,3.40625 4.82377124,3.28125 C5.01127124,3.15625 5.19877124,3.125 5.41752124,3.15625 L9.88627124,4 L19.8237712,3 C20.0112712,3 20.2300212,3.0625 20.3862712,3.1875 C20.5425212,3.3125 20.6362712,3.5 20.6675212,3.71875 L21.9800212,16.84375 Z M3.32377124,16.5 L7.19877124,17.21875 L6.01127124,5.21875 C5.98002124,4.96875 6.07377124,4.71875 6.26127124,4.5625 L5.63627124,4.5 L3.32377124,16.5 Z M15.6675212,18.875 L15.7925212,18.21875 L13.4800212,18.4375 L15.6675212,18.875 Z M8.51127124,17.71875 L20.6987712,16.5 L19.4800212,4.3125 L7.29252124,5.53125 L8.51127124,17.71875 Z M17.2612712,6.6875 C17.6050212,6.65625 17.9175212,6.90625 17.9487712,7.25 C17.9800212,7.59375 17.7300212,7.90625 17.3862712,7.9375 L10.2925212,8.75 C10.2925212,8.75 10.2612712,8.75 10.2300212,8.75 C9.91752124,8.75 9.63627124,8.5 9.60502124,8.1875 C9.57377124,7.84375 9.82377124,7.53125 10.1675212,7.5 L17.2612712,6.6875 Z M14.3550212,10 C14.6987712,9.96875 15.0112712,10.21875 15.0425212,10.5625 C15.0737712,10.90625 14.8237712,11.21875 14.4800212,11.25 L10.6050212,11.6875 C10.6050212,11.6875 10.5737712,11.6875 10.5425212,11.6875 C10.2300212,11.6875 9.94877124,11.4375 9.91752124,11.125 C9.88627124,10.78125 10.1362712,10.46875 10.4800212,10.4375 L14.3550212,10 Z"
                id="icons-instructions"
            />
        </SvgIcon>
    );
}

export default Instructions;
