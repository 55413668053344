import { FilesInsertedAction, FileActionType, FileUpdatedAction, SetFilesAction } from "./file-types";

/**
 * Returns an action
 *
 * @param {FilesInsertedAction} file - inserted file
 */
export function filesInsertedAction(file: FilesInsertedAction["value"]): FilesInsertedAction {
    return { type: FileActionType.INSERT_FILES, value: file };
}

/**
 * Returns an action
 *
 * @param {FileUpdatedAction} files - files
 */
export function filesUpdatedAction(files: FileUpdatedAction["value"]): FileUpdatedAction {
    return { type: FileActionType.UPDATE_FILES, value: files };
}

/**
 * Returns an action
 *
 * @param {SetFilesAction} files - files
 */
export function setFilesAction(files: SetFilesAction["value"]): SetFilesAction {
    return { type: FileActionType.SET_FILES, value: files };
}
