import { isResultSettingMetadataTypeMetadata } from "./isResultSettingMetadataTypeMetadata";
import { isResultSettingMetadataTypePropertyObject } from "./isResultSettingMetadataTypePropertyObject";
/**
 * Convert key value metadata properties to JSON (recursively)
 */
export function convertKeyValueMetadataPropertiesToJSON(currentMetadata, allMetadata, allKeyValueMetadata, accumulator = {}) {
    for (const property of allMetadata) {
        if (isResultSettingMetadataTypeMetadata(property) || property.parentId !== currentMetadata.resultSettingMetadataId) {
            continue;
        }
        const currentKeyValueMetadata = allKeyValueMetadata.find((item) => item.resultSettingMetadataId === property.resultSettingMetadataId);
        if (isResultSettingMetadataTypePropertyObject(property)) {
            accumulator[property.label] = {};
            const nextMetadata = allMetadata.find((item) => item.resultSettingMetadataId === property.objectId);
            if (nextMetadata) {
                convertKeyValueMetadataPropertiesToJSON(nextMetadata, allMetadata, allKeyValueMetadata, accumulator[property.label]);
            }
        }
        else {
            accumulator[property.label] = currentKeyValueMetadata?.value ?? "";
        }
    }
    return accumulator;
}
