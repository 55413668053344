import { useEffect } from "react";
export function useOutsideClick(ref, handler) {
    useEffect(function () {
        var listener = function (event) {
            if (!ref || !ref.current || !ref.current.contains(event.target)) {
                handler();
            }
        };
        document.addEventListener("mousedown", listener);
        return function () {
            document.removeEventListener("mousedown", listener);
        };
    });
}
