import { IProcessUiModel, ProcessActionId } from "@kortex/aos-common";
import { Paper, makeStyles } from "@material-ui/core";
import Debug from "debug";
import React, { FC } from "react";

import { IPlayerState } from "../../../../redux/player-manager/player-types";

import { PlayerControlsContent } from "./content";
import { PlayerControlsFooter } from "./footer";
import { PlayerControlsHeader } from "./header";

const debug = Debug("kortex:ui:player-page");
debug("Loaded");
Debug.enable("kortex:ui:player-page");

const useStyles = makeStyles({
    paperStepContainer: {
        display: "flex",
        height: "calc(100vh - 110px)",
        flexDirection: "column",
    },
});

interface IOwnProps {
    canPlay: boolean;
    dryRunMode?: boolean;
    maskArrowKeys: boolean;
    nextButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
    playerState: IPlayerState; // the actual player state
    playingHistoryIndex: number;
    processWithActions: IProcessUiModel | null;
    playingHistory?: boolean; // are we playing a history snaphot
    onStart: () => void;
    onStop: () => void;
    onPause: () => void;
    onResume: () => void;
    onPlayNext: () => void;
    onRewind: () => void;
    onRewindTo: (processActionId: ProcessActionId, stepIndex: number) => void;
    onActionStepChanged: () => void;
    onVersionChange: (processId: number) => void;
    onMessageDialog: () => void;
    onBomFollowUpDialog: () => void;
    onDetailsDialog: () => void;
    onFail: () => void;
    onTraningCommuniqueOpen: () => void;
    showPurgeIcon?: boolean;
    trainingCommuniqueEnabled?: boolean;
}

const PlayerControls: FC<IOwnProps> = (props) => {
    const {
        dryRunMode,
        nextButtonRef,
        playerState,
        playingHistoryIndex,
        playingHistory,
        processWithActions,
        canPlay = true,
        maskArrowKeys = true,
        showPurgeIcon,
        trainingCommuniqueEnabled = false,
        onActionStepChanged,
        onDetailsDialog,
        onFail,
        onMessageDialog,
        onBomFollowUpDialog,
        onPause,
        onPlayNext,
        onResume,
        onRewind,
        onRewindTo,
        onStart,
        onStop,
        onTraningCommuniqueOpen,
        onVersionChange,
    } = props;

    const classes = useStyles();

    return (
        <div>
            <Paper className={classes.paperStepContainer}>
                <PlayerControlsHeader
                    onVersionChange={onVersionChange}
                    playerState={playerState}
                    dryRunMode={dryRunMode}
                    processWithActions={processWithActions}
                    showPurgeIcon={showPurgeIcon}
                />
                <PlayerControlsContent
                    timelineProps={{
                        onActionStepChanged,
                        onRewindTo,
                        playerState,
                        playingHistoryIndex,
                        playingHistory,
                        processWithActions,
                    }}
                />
                <PlayerControlsFooter
                    canPlay={canPlay}
                    maskArrowKeys={maskArrowKeys}
                    nextButtonRef={nextButtonRef}
                    onDetailsDialog={onDetailsDialog}
                    onFail={onFail}
                    onMessageDialog={onMessageDialog}
                    onBomFollowUpDialog={onBomFollowUpDialog}
                    onPause={onPause}
                    onPlayNext={onPlayNext}
                    onResume={onResume}
                    onRewind={onRewind}
                    onStart={onStart}
                    onStop={onStop}
                    onTraningCommuniqueOpen={onTraningCommuniqueOpen}
                    playerState={playerState}
                    playingHistoryIndex={playingHistoryIndex}
                    dryRunMode={dryRunMode}
                    playingHistory={playingHistory}
                    processWithActions={processWithActions}
                    trainingCommuniqueEnabled={trainingCommuniqueEnabled}
                />
            </Paper>
        </div>
    );
};

export default PlayerControls;
