import { errorPalette } from "@aos/react-components";
import { IWorkInstructionsFormInputConfig } from "@kortex/aos-common";
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../../../../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    errorLabel: {}, // injected
    asterisk: {
        color: errorPalette[500],
        paddingLeft: "3px",
    },
    flex: {
        display: "flex",
    },
});

export interface IOwnProps extends IWorkInstructionsFormInputConfig {
    classes?: Pick<ReturnType<typeof useStyles>, "errorLabel">;
}

export default function WorkInstructionsFormInputRadio(props: IOwnProps): JSX.Element {
    const { itemState, itemProps } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    const displayedLabel = itemState.label ? itemState.label : itemProps.label;

    /**
     * Called when a user interacts with the input
     *
     * @param {React.ChangeEvent<{}>} event - unused
     * @param {string} value - Selected value
     */
    const onStateChanged = (event: React.ChangeEvent<{}>, value: string): void => {
        props.onStateChanged({ ...props.itemState, value });
    };

    return (
        <FormControl>
            <div className={classes.flex}>
                <Typography variant="subtitle1">{displayedLabel}</Typography>
                {itemProps.required && (
                    <Typography className={classes.asterisk} variant="subtitle1">
                        {"*"}
                    </Typography>
                )}
            </div>
            {itemState.errorKey && (
                <div id="error-key-radio" className={classes.errorLabel}>
                    {translate("action.workInstructions.form.error." + itemState.errorKey)}
                </div>
            )}
            <RadioGroup onChange={onStateChanged} value={itemState.value}>
                {itemState.items &&
                    itemState.items.map((item: string, index: number): JSX.Element => {
                        return (
                            <FormControlLabel
                                key={index}
                                value={item}
                                control={<Radio disableRipple={true} />}
                                label={<Typography variant="subtitle1">{item}</Typography>}
                            />
                        );
                    })}
            </RadioGroup>
        </FormControl>
    );
}
