var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function SourceCode(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M8.28941176,15.9083423 L7.12470588,17.0730481 L2,11.9506952 L7.12470588,6.82598931 L8.28941176,7.9906952 L4.32941176,11.9506952 L8.28941176,15.9083423 Z M15.5458824,7.9906952 L16.7105882,6.82598931 L21.8329412,11.9506952 L16.7105882,17.0730481 L15.5458824,15.9083423 L19.5035294,11.9506952 L15.5458824,7.9906952 Z M9.37652176,18.9965177 L13.1093845,4.5 L14.7044116,4.91072051 L10.9715489,19.4072382 L9.37652176,18.9965177 Z", id: "icons-source-code" })));
}
export default SourceCode;
