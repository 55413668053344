export const erpSettings = {
    rpc: {
        getErpSettings: {
            funcId: 16001,
            funcName: "erpSettingsGet",
        },
    },
    serviceId: 160,
    serviceVersion: 1,
};
