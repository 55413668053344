import { KortexTextField, greyPalette } from "@aos/react-components";
import { IWorkInstructionsFormInputConfig, WorkInstructionsFormErrorKey } from "@kortex/aos-common";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import * as React from "react";
import { useState } from "react";
import shortId from "shortid";

import { useTranslate } from "../../../../../../../../../../hooks/useTranslate";

export const TEXTBOXLIST_SPECIFIC_NAME = "workInstructionsFormInputTextboxName";

const useStyles = makeStyles({
    fullWidth: {
        width: "100%",
    },
    root: {
        width: "100%",
        border: `1px solid ${greyPalette[300]}`,
        boxSizing: "border-box",
        padding: "3px 11px 0 1px",
    },
    textBoxList: {
        height: "130px",
        overflow: "auto",
    },
});

export interface IOwnProps extends IWorkInstructionsFormInputConfig {
    autoFocus?: boolean;
    onEnterPressed?: (id: string) => void;
}

export default function WorkInstructionsFormInputTextboxList(props: IOwnProps): JSX.Element {
    const { autoFocus, itemProps, itemState } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [id] = useState<string>(shortId.generate());

    const displayedLabel = itemState.label ? itemState.label : itemProps.label;
    const regex = itemState.regex ? new RegExp(itemState.regex) : undefined;
    const regexNoMatch = itemState.regexError ? itemState.regexError : translate("action.workInstructions.form.error.invalidFormat");
    const tabIndex = itemProps.tabIndex ? parseInt(itemProps.tabIndex) : 0;

    const TEXTBOXLIST_VALIDATION_ERROR_MAP: Record<WorkInstructionsFormErrorKey, string> = {
        [WorkInstructionsFormErrorKey.REQUIRED]: translate("action.workInstructions.form.error.required"),
        [WorkInstructionsFormErrorKey.MIN_ITEMS]: `${translate("action.workInstructions.form.error.minItems")} ${itemProps.minItems}`,
        [WorkInstructionsFormErrorKey.MAX_ITEMS]: `${translate("action.workInstructions.form.error.maxItems")} ${itemProps.maxItems}`,
        [WorkInstructionsFormErrorKey.NONE]: "",
        [WorkInstructionsFormErrorKey.INVALID_FORMAT]: translate("action.workInstructions.form.error.invalidFormat"),
    };

    /**
     * Called when a user interacts with the input
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - change event triggered
     */
    const onStateChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (itemState.errorKey === WorkInstructionsFormErrorKey.REQUIRED) {
            itemState.errorKey = ""; // To clear the "required" error message when user types in the text field
        }

        // todo default value
        props.onStateChanged({ ...props.itemState, value: event.target.value });
    };

    /**
     * Called when a user presses a key in the TextField
     */
    const handleKeyDown = (event: React.KeyboardEvent): void => {
        event.stopPropagation();

        if ((event.key === "Enter" || event.key === "Tab") && !itemProps.multiline) {
            event.preventDefault();

            if (event.key === "Enter") {
                if (itemState.value.trim() !== "") {
                    if (!itemState.items) {
                        itemState.items = [];
                    }

                    itemState.items.push(itemState.value);
                    props.onStateChanged({ ...props.itemState, items: itemState.items });

                    props.onStateChanged({ ...props.itemState, value: "" });
                }
            }
        }
    };

    /**
     * Handles deleting an item from the list.
     *
     * @param {number} index - the index of the list item to delete
     */
    const handleDeleteListItem = (index: number) => (): void => {
        if (itemState.items) {
            itemState.items.splice(index, 1);
            props.onStateChanged({ ...props.itemState, items: itemState.items });
        }
    };

    return (
        <div className={classes.root}>
            <KortexTextField
                variant="outlined"
                onChange={onStateChanged}
                onKeyDown={handleKeyDown}
                label={displayedLabel}
                value={itemState.value}
                regex={regex}
                standardErrorMsgs={{ regexNoMatch }}
                error={TEXTBOXLIST_VALIDATION_ERROR_MAP[itemState.errorKey]}
                TextFieldProps={{
                    required: itemProps.required,
                    autoFocus,
                    classes: {
                        root: classes.fullWidth,
                    },
                }}
                InputProps={{
                    inputProps: {
                        id,
                        name: TEXTBOXLIST_SPECIFIC_NAME + shortId(), //Make input name unique to prevent chrome autofill
                        tabIndex,
                    },
                }}
            />
            <div>
                <List className={classes.textBoxList} dense>
                    {itemState.items &&
                        itemState.items.map((item: string, index: number): JSX.Element => {
                            return (
                                <ListItem dense={true} key={index}>
                                    <ListItemText primary={item} />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={handleDeleteListItem(index)} edge="end">
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                </List>
            </div>
        </div>
    );
}
