import { ISpeedDialAction, KortexSpeedDial, theme } from "@aos/react-components";
import {
    ApprovalStatusEnum,
    ITreeNodeDbModel,
    ProcessApprovalStatusFilter,
    RepositoryEditorRightsEnum,
    TreeNodeId,
    TreeNodeNodeTypeEnum,
    TreeProcessInsertType,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { deepClone } from "@kortex/utilities";
import { Backdrop, Checkbox, CircularProgress, MenuItem, Paper, Select, Typography, makeStyles } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import * as React from "react";
import { useEffect, useState } from "react";
import { AutoSizer } from "react-virtualized";

import RoutingIcon from "../../../../components/core/Icons/Routing/Routing";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useEntitiesTreeProcess } from "../../../../redux/effects";
import { processVersionGetAllWithSameParent } from "../../../../redux/process-manager/process-thunks-process";
import { treeProcessArchive, treeProcessInsert, treeProcessUpdate } from "../../../../redux/tree-manager/tree-thunks-process";
import { IUserRightsProps, userCanInsert } from "../../../../utilitites/IUserRights";
import { getChildrenNodesPath, getParentNodesPath } from "../../../../utilitites/getParentNodes";
import ProcessIcon from "../../../core/Icons/Process/Process";
import TreeView, { NodeWithVersion, TreeViewVariantFolder, TreeViewVariantItem } from "../../../core/TreeView/TreeView";

import BomFollowUpSymptomDialog from "./BomFollowUpSymptom/BomFollowUpSymptomDialog";
import FailureTypeDialog from "./FailureTypeDialog/FailureTypeDialog";
import ProcessRepositoryArchiveDialog from "./ProcessRepositoryArchiveDialog";
import ProcessRepositorySearchBar from "./ProcessRepositorySearchBar";
import ProcessRepositoryWhereUsedDialog from "./ProcessRepositoryWhereUsedDialog";
import ReworkItemStatusDialog from "./ReworkItemStatusDialog/ReworkItemStatusDialog";
import RootCauseDialog from "./RootCauseDialog/RootCauseDialog";

const TREEVIEW_OVERSCAN_ROW_COUNT = 20;

const useStyles = makeStyles({
    root: {
        height: "100%",
        display: "grid",
        gridTemplateColumns: "0.8fr 1.2fr",
        gridColumnGap: "16px",
    },
    label: {
        whiteSpace: "nowrap",
        fontSize: "1rem",
        overflow: "hidden",
    },
    speedDial: {
        position: "absolute",
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
    speedDialActionFab: {
        width: "35px",
        height: "35px",
        boxShadow: "0px 4px 5px 0px",
        backgroundColor: theme.palette.grey[300],
    },
    speedDialTooltipLabel: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.grey[50],
    },
    optionsContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    folderView: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    folders: {
        height: "100%",
        width: "100%",
        overflow: "hidden",
    },
    processView: {
        width: "100%",
        position: "relative",
    },
    rightView: {
        height: "100%",
        width: "100%",
        display: "grid",
        gridTemplateRows: "1fr",
        gridRowGap: "16px",
    },
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 100,
    },
    filterInputSelect: {
        padding: "5px",
        width: "135px",
    },
    filterInput: {
        marginRight: "10px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    filterLabel: {
        marginRight: "10px",
    },
});

export interface IFilters {
    plainText: string;
}

export const defaultFilters: IFilters = {
    plainText: "",
};

export interface IOwnProps extends IUserRightsProps<RepositoryEditorRightsEnum> {
    multiItemSelection?: boolean;
    selectOnly?: boolean;
    filterType?: TreeNodeNodeTypeEnum;
    preselectedTreeNodeId?: number;
    showLatestVersion?: boolean;
    showVersionVariant?: ProcessApprovalStatusFilter;
    handleSelectProcessConfirmation?: () => void;
    onSelectionChanged: (treeNodes: ITreeNodeDbModel[], processes?: (number | undefined)[], isLatest?: (boolean | undefined)[]) => void;
}

export default function ProcessRepository(props: IOwnProps): JSX.Element {
    const {
        preselectedTreeNodeId,
        showLatestVersion,
        handleSelectProcessConfirmation,
        showVersionVariant = ProcessApprovalStatusFilter.ALL,
        userAccessLevel,
        multiItemSelection,
    } = props;

    const classes = useStyles();
    const dispatch = useThunkDispatch();
    const translate = useTranslate();
    const treeNodes = useEntitiesTreeProcess();

    const [filteredTreeNodes, setFilteredTreeNodes] = useState<ITreeNodeDbModel[]>([]);

    const [selectedFolder, setSelectedFolder] = useState<ITreeNodeDbModel | undefined>(undefined);
    const [selectedNodes, setSelectedNodes] = useState<ITreeNodeDbModel[]>([]);
    const [selectedNodeVersions, setSelectedNodeVersions] = useState<NodeWithVersion[]>([]);

    const [processList, setProcessList] = useState<ITreeNodeDbModel[]>([]);
    const [filters, setFilters] = useState<IFilters>(defaultFilters);

    const [showArchived, setShowArchived] = useState<boolean>(false);

    const [selectedWhereUsedNode, setSelectedWhereUsedNode] = useState<ITreeNodeDbModel>();
    const [whereUsedDialogOpened, setWhereUsedDialogOpened] = useState<boolean>(false);

    const [selectedArchiveNode, setSelectedArchiveNode] = useState<ITreeNodeDbModel>();
    const [archiveDialogOpened, setArchiveDialogOpened] = useState<boolean>(false);

    const [selectedReworkItemStatusNode, setSelectedReworkItemStatusNode] = useState<ITreeNodeDbModel>();
    const [reworkItemStatusDialogOpened, setReworkItemStatusDialogOpened] = useState<boolean>(false);

    const [selectedFailureTypeNode, setSelectedFailureTypeNode] = useState<ITreeNodeDbModel>();
    const [failureTypeDialogOpened, setFailureTypeDialogOpened] = useState<boolean>(false);

    const [selectedRootCauseNode, setSelectedRootCauseNode] = useState<ITreeNodeDbModel>();
    const [rootCauseDialogOpened, setRootCauseDialogOpened] = useState<boolean>(false);

    const [selectedBomFollowUpSymptomNode, setSelectedBomFollowUpSymptomNode] = useState<ITreeNodeDbModel>();
    const [bomFollowUpSymptomDialogOpened, setBomFollowUpSymptomDialogOpened] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);
    const [allFilterType, setAllFilterType] = useState<string | undefined>(undefined);

    /**
     * Effect that updates filteredTreeNodes when the filters are changed.
     */
    useEffect(() => {
        if (!processList || !treeNodes) {
            return;
        }
        setFilteredTreeNodes([]);
        let filteredNodes: ITreeNodeDbModel[];
        if (filters.plainText === "") {
            filteredNodes = treeNodes;
        } else {
            filteredNodes = treeNodes.filter(
                (children) =>
                    children.label.toLowerCase().includes(filters.plainText.toLowerCase()) &&
                    (showArchived ? children.archived || !children.archived : !children.archived)
            );
            const initialFilteredNodes = deepClone(filteredNodes);

            // Add parents to nodes
            for (const match of initialFilteredNodes) {
                const matchParents = getParentNodesPath(match.treeNodeId, treeNodes);

                for (const node of matchParents) {
                    if (!filteredNodes.includes(node)) {
                        filteredNodes.push(node);
                    }
                }
            }

            // Add children to nodes
            for (const match of initialFilteredNodes) {
                const matchChildren = getChildrenNodesPath(match.treeNodeId, treeNodes);

                for (const node of matchChildren) {
                    if (!filteredNodes.includes(node)) {
                        filteredNodes.push(node);
                    }
                }
            }

            // Select first found node or folder
            if (initialFilteredNodes.length > 0) {
                const firstProcessNode = initialFilteredNodes.find((node) => node.nodeType !== TreeNodeNodeTypeEnum.FOLDER);
                if (firstProcessNode) {
                    setSelectedNodes([firstProcessNode]);
                    setSelectedFolder(treeNodes.find((node) => node.treeNodeId === firstProcessNode.parentId));
                } else {
                    const firstNode = initialFilteredNodes[0];
                    if (firstNode.nodeType === TreeNodeNodeTypeEnum.FOLDER) {
                        setSelectedFolder(firstNode);
                    } else {
                        setSelectedNodes([firstNode]);
                    }
                }
            }
        }

        const sortedTreeNodes = sortTreeNode(filteredNodes);

        if (allFilterType) {
            const filteredTypeNodes: ITreeNodeDbModel[] = sortedTreeNodes.filter((item) =>
                filteredNodes.find(
                    (treeNode): boolean =>
                        treeNode.parentId === item.treeNodeId && treeNode.nodeType === allFilterType && !treeNode.archived
                )
            );
            setFilteredTreeNodes([...filteredTypeNodes]);
        } else {
            setFilteredTreeNodes(sortedTreeNodes);
        }
    }, [filters, treeNodes, allFilterType]);

    //find the parent node and add if not exist
    function recurse(parentNodeId: number): void {
        const parentNodeObj = treeNodes.find((treeNode) => treeNode.treeNodeId === parentNodeId);
        if (parentNodeObj) {
            const isParentNodeExist = filteredTreeNodes.find((treeNode) => treeNode.treeNodeId === parentNodeObj.treeNodeId);
            if (!isParentNodeExist) {
                setFilteredTreeNodes(sortTreeNode([...filteredTreeNodes, parentNodeObj]));
                recurse(parentNodeObj.parentId);
            }
        }
    }

    // Sort alphanumerically
    const sortTreeNode = (filteredNodes: ITreeNodeDbModel[]): ITreeNodeDbModel[] => {
        const sortNodes = filteredNodes.sort((n1, n2) => {
            if (n1.label > n1.label) {
                return 1;
            }

            if (n1.label < n2.label) {
                return -1;
            }

            return 0;
        });

        return sortNodes;
    };

    useEffect(() => {
        if (allFilterType && filteredTreeNodes.length > 0) {
            filteredTreeNodes.forEach((item) => recurse(item.parentId));
        }
    }, [filteredTreeNodes]);

    /**
     * Opens the process archiving dialog
     *
     * @param {boolean} open - true to open the dialog, false to close it
     */
    const handleOpenArchiveDialog =
        (open: boolean, variant?: TreeViewVariantFolder | TreeViewVariantItem): (() => void) =>
        (): void => {
            if (variant === TreeViewVariantFolder) {
                setSelectedArchiveNode(selectedFolder);
            } else {
                setSelectedArchiveNode(selectedNodes[0]);
            }
            setArchiveDialogOpened(open);
        };

    /**
     * Closes the process archiving dialog
     *
     * @param {boolean} archived - if true, archive selected process version
     */
    const handleConfirmArchiveDialog =
        (archived: boolean): (() => void) =>
        (): void => {
            setArchiveDialogOpened(false);

            if (selectedArchiveNode && selectedArchiveNode?.nodeType === TreeNodeNodeTypeEnum.FOLDER) {
                const treeNodeIds = getAllChildNode(selectedArchiveNode.treeNodeId, [selectedArchiveNode.treeNodeId]);

                dispatch(
                    treeProcessArchive({
                        archive: archived,
                        treeNodeIds: [...treeNodeIds],
                    })
                );
            } else if (selectedArchiveNode) {
                dispatch(
                    treeProcessArchive({
                        archive: archived,
                        treeNodeIds: [selectedArchiveNode.treeNodeId],
                    })
                );
            }
        };

    function getAllChildNode(parentNodeId: TreeNodeId, accumulator: TreeNodeId[] = []): TreeNodeId[] {
        const childNodes = treeNodes.filter((item) => item.parentId === parentNodeId && !item.archived);

        for (const node of childNodes) {
            accumulator.push(node.treeNodeId);
            getAllChildNode(node.treeNodeId, accumulator);
        }

        return accumulator;
    }

    /**
     * Refreshes process list for the tree view
     *
     * @param {number | undefined} parentId - node parent ID
     */
    const refreshProcessList = async (parentId?: number): Promise<void> => {
        let updatedProcessList = [];
        const itemListOfParent = treeNodes.filter(
            (repoNodeItem): boolean => repoNodeItem.parentId === parentId && repoNodeItem.nodeType !== TreeNodeNodeTypeEnum.FOLDER
        );

        // Apply node type filter if necessary (ex: remove all folder)
        if (props.filterType) {
            updatedProcessList = itemListOfParent.filter((item): boolean => item.nodeType === props.filterType);
        } else if (allFilterType) {
            updatedProcessList = itemListOfParent.filter((item): boolean => item.nodeType === allFilterType);
        } else {
            updatedProcessList = itemListOfParent;
        }

        // Apply process version filter if necessary
        if (!updatedProcessList.length || showVersionVariant === ProcessApprovalStatusFilter.ALL) {
            setProcessList(updatedProcessList);
        } else {
            await setProcessListFilteredByVersion(updatedProcessList);
        }
    };

    // Refresh the process list when the list changes or when selecting a new folder
    useEffect((): void => {
        if (selectedNodes) {
            const newFoundNodes: ITreeNodeDbModel[] = [];
            for (const node of selectedNodes) {
                const foundNode = treeNodes.find((findNode) => findNode.treeNodeId === node.treeNodeId);
                if (foundNode) {
                    newFoundNodes.push(foundNode);
                }
            }
            setSelectedNodes(newFoundNodes);
        }

        if (selectedFolder) {
            const foundNode = treeNodes.find((findNode) => findNode.treeNodeId === selectedFolder.treeNodeId);
            if (foundNode) {
                setSelectedFolder(foundNode);
                refreshProcessList(foundNode.treeNodeId);
            }
        }
    }, [treeNodes, selectedFolder, allFilterType]);

    // Selects right Folder and Node when ProcessRepository is opened/closed
    useEffect((): void => {
        const selectedNode = treeNodes.find((node): boolean => node.treeNodeId === preselectedTreeNodeId);
        if (selectedNode) {
            const selectedParentFolder = treeNodes.find((node): boolean => node.treeNodeId === selectedNode.parentId);
            setSelectedFolder(selectedParentFolder);
            setSelectedNodes([selectedNode]);
        }
    }, [preselectedTreeNodeId]);

    /**
     * Filter the process list with chosen variant (approved-only or released-only) and set inner state
     *
     * @param {ITreeNodeDbModel[]} nodes - tree node list
     */
    const setProcessListFilteredByVersion = async (nodes: ITreeNodeDbModel[]): Promise<void> => {
        setIsLoading(true);
        const filteredTreeNodeList: ITreeNodeDbModel[] = [];

        if (nodes) {
            const treeNodeIdList = nodes.map((node) => node.treeNodeId);
            // Get all versions of a process with the same parent
            await dispatch(processVersionGetAllWithSameParent(treeNodeIdList)).then((fetchedProcesses) => {
                const treeNodeIdListOfTheProcesses: TreeNodeId[] = [];

                for (const process of fetchedProcesses) {
                    const node = nodes.find((fetchedNode) => process.treeNodeId === fetchedNode.treeNodeId);

                    if (
                        node &&
                        process.versionStatus === ApprovalStatusEnum.APPROVED &&
                        !treeNodeIdListOfTheProcesses.includes(process.treeNodeId)
                    ) {
                        filteredTreeNodeList.push(node);
                        treeNodeIdListOfTheProcesses.push(node.treeNodeId);
                    }
                }
            });
        }

        setProcessList(filteredTreeNodeList);
        setIsLoading(false);
    };

    /**
     * Handle called when 1 or some folders are selected
     *
     * @param {object[]} nodes - Selected Folder Nodes
     */
    const handleFolderSelect = (nodes: ITreeNodeDbModel[]): void => {
        setSelectedNodes([]);
        if (nodes.length > 0) {
            setSelectedFolder(nodes[0] as ITreeNodeDbModel);
            refreshProcessList(nodes[0].treeNodeId);
        }
        props.onSelectionChanged([]);
    };

    /**
     * Handle called when 1 or some processes are selected
     *
     * @param {ITreeNodeDbModel[]} nodes - Selected processes Nodes
     */
    const handleProcessSelect = (nodes: ITreeNodeDbModel[]): void => {
        const sortedVersions: (number | undefined)[] = [];
        const sortedLatest: (boolean | undefined)[] = [];

        // FIXME: When shift+clicking to select multiple processes at once,
        //        it does not send all the nodes to the parent component when CONFIRM is clicked.
        //        The filter below seems to be causing this problem.
        // Filter versions to keep only those that have an associated node
        const selectedNodeVersionsCopy = selectedNodeVersions.filter((selectedVersion) =>
            selectedNodes.some((selectedNode) => selectedNode.treeNodeId === selectedVersion.nodeId)
        );

        // Sort the versions in the right order
        for (const node of nodes) {
            const foundVersion = selectedNodeVersionsCopy.find((version) => version.nodeId === node.treeNodeId);
            if (foundVersion) {
                sortedVersions.push(foundVersion.versionId);
                sortedLatest.push(foundVersion.latest);
            } else {
                sortedVersions.push(undefined);
                sortedLatest.push(undefined);
            }
        }

        setSelectedNodes(nodes);
        setSelectedNodeVersions(selectedNodeVersionsCopy);
        props.onSelectionChanged(nodes, sortedVersions, sortedLatest);
    };

    /**
     * Handles the change of process version
     *
     * @param {NodeWithVersion} nodeWithVersion - node ID with version ID
     */
    const handleProcessVersionChange = (nodeWithVersion: NodeWithVersion): void => {
        const selectedNodeVersionsCopy = [...selectedNodeVersions];

        // Find if a version has already been selected for the node
        const foundIndex = selectedNodeVersionsCopy.findIndex((version) => version.nodeId === nodeWithVersion.nodeId);

        if (foundIndex !== -1) {
            // If a node has been found, replace the older version by the selected one
            selectedNodeVersionsCopy[foundIndex] = nodeWithVersion;
        } else {
            // If not, add it to the version list
            selectedNodeVersionsCopy.push(nodeWithVersion);
        }

        // Sort the versions in the right order
        const sortedVersions: (number | undefined)[] = [];
        const sortedLatest: (boolean | undefined)[] = [];

        for (const node of selectedNodes) {
            const foundVersion = selectedNodeVersionsCopy.find((version) => version.nodeId === node.treeNodeId);

            if (foundVersion) {
                sortedLatest.push(foundVersion.latest);
                sortedVersions.push(foundVersion.latest ? undefined : foundVersion.versionId);
            } else {
                sortedVersions.push(undefined);
                sortedLatest.push(undefined);
            }
        }

        setSelectedNodeVersions(selectedNodeVersionsCopy);
        props.onSelectionChanged(selectedNodes, sortedVersions, sortedLatest);
    };

    /**
     * Handle to update folder node
     *
     * @param {ITreeNodeDbModel} node - Node to update
     */
    const handleUpdateFolder = (node: ITreeNodeDbModel): void => {
        dispatch(treeProcessUpdate([node]));
    };

    /**
     * Handle to update process node
     *
     * @param {ITreeNodeDbModel} node - Node to update
     */
    const handleUpdateProcess = (node: ITreeNodeDbModel): void => {
        dispatch(treeProcessUpdate([node]));
    };

    /**
     * Opens or closes the where used dialog
     *
     * @param {boolean} open - true if opened, else closed
     */
    const handleOpenWhereUsedDialog =
        (open: boolean, variant?: TreeViewVariantFolder | TreeViewVariantItem): (() => void) =>
        (): void => {
            if (variant === TreeViewVariantFolder) {
                setSelectedWhereUsedNode(selectedFolder);
            } else {
                setSelectedWhereUsedNode(selectedNodes[0]);
            }
            setWhereUsedDialogOpened(open);
        };

    /**
     * Opens or closes the where used dialog
     *
     * @param {boolean} open - true if opened, else closed
     */
    const handleOpenFailureTypeDialog =
        (open: boolean, variant?: TreeViewVariantFolder | TreeViewVariantItem): (() => void) =>
        (): void => {
            if (variant === TreeViewVariantFolder) {
                setSelectedFailureTypeNode(selectedFolder);
            } else {
                setSelectedFailureTypeNode(selectedNodes[0]);
            }
            setFailureTypeDialogOpened(open);
        };

    /**
     * Opens or closes the where used dialog
     *
     * @param {boolean} open - true if opened, else closed
     */
    const handleOpenReworkItemStatusDialog =
        (open: boolean, variant?: TreeViewVariantFolder | TreeViewVariantItem): (() => void) =>
        (): void => {
            if (variant === TreeViewVariantFolder) {
                setSelectedReworkItemStatusNode(selectedFolder);
            } else {
                setSelectedReworkItemStatusNode(selectedNodes[0]);
            }
            setReworkItemStatusDialogOpened(open);
        };

    const handleOpenRootCauseDialog =
        (open: boolean, variant?: TreeViewVariantFolder | TreeViewVariantItem): (() => void) =>
        (): void => {
            if (variant === TreeViewVariantFolder) {
                setSelectedRootCauseNode(selectedFolder);
            } else {
                setSelectedRootCauseNode(selectedNodes[0]);
            }
            setRootCauseDialogOpened(open);
        };

    const handleOpenBomFollowUpSymptomDialog =
        (open: boolean, variant?: TreeViewVariantFolder | TreeViewVariantItem): (() => void) =>
        (): void => {
            if (variant === TreeViewVariantFolder) {
                setSelectedBomFollowUpSymptomNode(selectedFolder);
            } else {
                setSelectedBomFollowUpSymptomNode(selectedNodes[0]);
            }
            setBomFollowUpSymptomDialogOpened(open);
        };

    /**
     * Handle when user select insert folder
     */
    const handleDialMenuInsertFolder = (): void => {
        insertNode(TreeProcessInsertType.FOLDER);
    };

    /**
     * Handle when user select insert process
     */
    const handleDialMenuInsertRouting = (): void => {
        insertNode(TreeProcessInsertType.ROUTING);
    };

    /**s
     * Handle when user select insert process
     */
    const handleDialMenuInsertProcess = (): void => {
        insertNode(TreeProcessInsertType.PROCESS);
    };

    /**
     * Insert and commit to database a new node
     *
     * @param {TreeProcessInsertType} type - (Routing, Folder or Process)
     */
    const insertNode = (type: TreeProcessInsertType): void => {
        dispatch(
            treeProcessInsert({
                label: getEnumTreeViewNodeTypeString(type),
                parentId: selectedFolder ? selectedFolder.treeNodeId : 0,
                type,
            })
        ).then((node) => {
            if (node) {
                if (type === TreeProcessInsertType.FOLDER) {
                    handleFolderSelect([node]);
                } else {
                    handleProcessSelect([node]);
                }
                setAllFilterType(undefined);
            }
        });
    };

    /**
     * Return string based on type of node
     *
     * @param {TreeNodeNodeTypeEnum} type - (Routing, Folder or Process)
     */
    const getEnumTreeViewNodeTypeString = (type: TreeNodeNodeTypeEnum): string => {
        switch (type) {
            case TreeNodeNodeTypeEnum.FOLDER:
                return translate("processRepository.folder");
            case TreeNodeNodeTypeEnum.PROCESS:
                return translate("processRepository.process");
            case TreeNodeNodeTypeEnum.ROUTING:
                return translate("processRepository.routing");
            default:
                return translate("processRepository.unknown");
        }
    };

    /**
     * Handle request to show or hide archived files
     */
    const handleOptionShowArchived = (): void => {
        setShowArchived(!showArchived);
    };

    // Gets the addActions given to SpeedDial
    const getSpeedDialActions = (): ISpeedDialAction[] => {
        if (selectedFolder) {
            return [
                {
                    callback: handleDialMenuInsertRouting,
                    icon: <RoutingIcon id="insertRoutingButtonId" />,
                    label: translate("processRepository.insertRouting"),
                    classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
                },
                {
                    callback: handleDialMenuInsertProcess,
                    icon: <ProcessIcon id="insertProcessButtonId" />,
                    label: translate("processRepository.insertProcess"),
                    classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
                },
                {
                    callback: handleDialMenuInsertFolder,
                    icon: <FolderIcon id="insertFolderButtonId" />,
                    label: translate("processRepository.insertFolder"),
                    classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
                },
            ];
        } else {
            return [
                {
                    callback: handleDialMenuInsertFolder,
                    icon: <FolderIcon id="insertFolderButtonId" />,
                    label: translate("processRepository.insertFolder"),
                    classes: { fab: classes.speedDialActionFab, staticTooltipLabel: classes.speedDialTooltipLabel },
                },
            ];
        }
    };

    /**
     * Called when the filters change. Sets local filters state
     */
    const handleFiltersChange = (newFilters: IFilters): void => {
        setFilters(newFilters);
    };

    const handleSelectFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setAllFilterType(event.target.value);
    };

    return (
        <>
            <div className={classes.root}>
                {/* FOLDER */}
                <Paper className={classes.folderView}>
                    <ProcessRepositorySearchBar enabledFilters={{ plainText: true }} onFiltersChange={handleFiltersChange} />
                    <div className={classes.folders}>
                        <AutoSizer>
                            {({ height, width }): JSX.Element => (
                                <TreeView
                                    enableAttributeArchive={true}
                                    height={height}
                                    nodeList={filteredTreeNodes}
                                    onNodesSelected={handleFolderSelect}
                                    onUpdateNode={handleUpdateFolder}
                                    overscanRowCount={TREEVIEW_OVERSCAN_ROW_COUNT}
                                    selectedNodes={selectedFolder ? [selectedFolder] : []}
                                    onFailureType={handleOpenFailureTypeDialog(true, TreeViewVariantFolder)}
                                    onReworkItemStatus={handleOpenReworkItemStatusDialog(true, TreeViewVariantFolder)}
                                    onRootCause={handleOpenRootCauseDialog(true, TreeViewVariantFolder)}
                                    onBomFollowUpSymptom={handleOpenBomFollowUpSymptomDialog(true, TreeViewVariantFolder)}
                                    showArchived={showArchived}
                                    userAccessLevel={userAccessLevel}
                                    variant={TreeViewVariantFolder}
                                    width={width}
                                    onWhereUsed={handleOpenWhereUsedDialog(true, TreeViewVariantFolder)}
                                    onArchive={handleOpenArchiveDialog(true, TreeViewVariantFolder)}
                                    showProcessVersion={ProcessApprovalStatusFilter.NONE}
                                />
                            )}
                        </AutoSizer>

                        {!props.selectOnly && userCanInsert(userAccessLevel) && (
                            <KortexSpeedDial
                                ariaLabel=""
                                SpeedDialProps={{ className: classes.speedDial, id: "insertButtonId" }}
                                actions={getSpeedDialActions()}
                            />
                        )}
                    </div>
                </Paper>

                <div className={classes.rightView} id="rightViewId">
                    {/* PROCESS */}
                    <Paper className={classes.processView}>
                        <Backdrop className={classes.backdrop} open={isLoading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <AutoSizer>
                            {({ height, width }): JSX.Element => (
                                <TreeView
                                    enableAttributeArchive={true}
                                    height={height}
                                    multiSelect={multiItemSelection}
                                    nodeList={processList}
                                    onArchive={handleOpenArchiveDialog(true, TreeViewVariantItem)}
                                    onNodesSelected={handleProcessSelect}
                                    onNodeVersionChange={handleProcessVersionChange}
                                    onUpdateNode={handleUpdateProcess}
                                    overscanRowCount={TREEVIEW_OVERSCAN_ROW_COUNT}
                                    onWhereUsed={handleOpenWhereUsedDialog(true, TreeViewVariantItem)}
                                    onFailureType={handleOpenFailureTypeDialog(true, TreeViewVariantItem)}
                                    onReworkItemStatus={handleOpenReworkItemStatusDialog(true, TreeViewVariantItem)}
                                    onRootCause={handleOpenRootCauseDialog(true, TreeViewVariantFolder)}
                                    onBomFollowUpSymptom={handleOpenBomFollowUpSymptomDialog(true, TreeViewVariantFolder)}
                                    selectedNodes={selectedNodes}
                                    showArchived={showArchived}
                                    showLatestVersion={showLatestVersion}
                                    showProcessVersion={showVersionVariant}
                                    userAccessLevel={userAccessLevel}
                                    variant={TreeViewVariantItem}
                                    width={width}
                                    handleSelectProcessConfirmation={handleSelectProcessConfirmation}
                                />
                            )}
                        </AutoSizer>
                    </Paper>
                    {/* OPTIONS */}
                    <div className={classes.optionsContainer}>
                        {!props.filterType && (
                            <div className={classes.filterInput}>
                                <Typography className={classes.filterLabel}>{`${translate("treeview.optionShowFilter")} `}</Typography>
                                <Select
                                    inputProps={{
                                        className: classes.filterInputSelect,
                                    }}
                                    onChange={handleSelectFilter}
                                    value={allFilterType || 0}
                                >
                                    <MenuItem key={0} value={0}>
                                        {translate("processRepository.filter.processAndRouting")}
                                    </MenuItem>
                                    <MenuItem key={TreeNodeNodeTypeEnum.PROCESS} value={TreeNodeNodeTypeEnum.PROCESS}>
                                        {translate("processRepository.process")}
                                    </MenuItem>
                                    <MenuItem key={TreeNodeNodeTypeEnum.ROUTING} value={TreeNodeNodeTypeEnum.ROUTING}>
                                        {translate("processRepository.routing")}
                                    </MenuItem>
                                </Select>
                            </div>
                        )}
                        <Typography>{`${translate("treeview.optionShowArchived")} `}</Typography>
                        <Checkbox
                            inputProps={{ id: "showArchivedCheckboxId" }}
                            checked={showArchived}
                            onChange={handleOptionShowArchived}
                        />
                    </div>
                </div>
            </div>
            {selectedWhereUsedNode && (
                <ProcessRepositoryWhereUsedDialog
                    onClose={handleOpenWhereUsedDialog(false)}
                    opened={whereUsedDialogOpened}
                    treeNode={selectedWhereUsedNode}
                />
            )}
            {selectedArchiveNode && (
                <ProcessRepositoryArchiveDialog
                    opened={archiveDialogOpened}
                    currentArchived={selectedArchiveNode?.archived}
                    onCancel={handleOpenArchiveDialog(false)}
                    onConfirm={handleConfirmArchiveDialog(!selectedArchiveNode?.archived)}
                    treeNode={selectedArchiveNode}
                />
            )}
            {selectedFailureTypeNode && (
                <FailureTypeDialog
                    opened={failureTypeDialogOpened}
                    onClose={handleOpenFailureTypeDialog(false)}
                    treeNode={selectedFailureTypeNode}
                />
            )}
            {selectedReworkItemStatusNode && (
                <ReworkItemStatusDialog
                    opened={reworkItemStatusDialogOpened}
                    onClose={handleOpenReworkItemStatusDialog(false)}
                    treeNode={selectedReworkItemStatusNode}
                />
            )}
            {selectedRootCauseNode && (
                <RootCauseDialog
                    opened={rootCauseDialogOpened}
                    onClose={handleOpenRootCauseDialog(false)}
                    treeNode={selectedRootCauseNode}
                />
            )}
            {selectedBomFollowUpSymptomNode && (
                <BomFollowUpSymptomDialog
                    opened={bomFollowUpSymptomDialogOpened}
                    onClose={handleOpenBomFollowUpSymptomDialog(false)}
                    treeNode={selectedBomFollowUpSymptomNode}
                />
            )}
        </>
    );
}
