import { greyPalette, KortexTextField, theme } from "@aos/react-components";
import { ApiErrorSubCodes, assertSubCode, isApiError } from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    makeStyles,
    Typography,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../hooks/useTranslate";
import { EnumLocalStorageItem } from "../../../redux/EnumLocalStorageItem";
import { login, logout } from "../../../redux/user-manager/UserActions";
import { userPasswordUpdateByUser } from "../../../redux/user-manager/users-thunks-user";

const useStyles = makeStyles({
    root: {},
    dialogMain: {
        display: "flex",
        justifyContent: "center",
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "20px",
        height: "100%",
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 30px",
    },
    dialogDescription: {
        display: "flex",
        flexDirection: "column",
        marginTop: "7px",
    },
    dialogInput: {
        marginBottom: "20px",
        cursor: "pointer",
        maxWidth: "300px",
        width: "100%",
    },
    buttonIcons: {
        marginRight: "10px",
    },
    iconButton: {
        padding: 0,
        "&:hover": {
            background: "none",
        },
    },
    passwordHint: {
        color: greyPalette[500],
        fontSize: "0.8rem",
    },
});

interface IOwnProps {
    open: boolean;
    userName: string;
    userId: number;
}

export function PasswordUpdatePage(props: IOwnProps): JSX.Element {
    const { open, userId, userName } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const dispatch = useThunkDispatch();

    const [newPassword, setNewPassword] = useState<string>("");
    const [retypedPassword, setRetypedPassword] = useState<string>("");
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [invalidPasswordFormatError, setInvalidPasswordFormatError] = useState<boolean>(false);
    const [passwordMismatchError, setPasswordMismatchError] = useState<string>("");

    /**
     * Called when the new password is changed
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - Input change event
     */
    const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setNewPassword(event.target.value);

        validatePasswords(event.target.value, retypedPassword);

        setInvalidPasswordFormatError(false);
    };

    /**
     * Handles a change in the Retyped Password textField
     *
     * @param {React.ChangeEvent} event - change event
     */
    const handleRetypedPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setRetypedPassword(event.target.value);

        validatePasswords(newPassword, event.target.value);
    };

    /**
     * Sets local state errors for password validation
     *
     * @param {string} password - typed password to check
     * @param {string} retypedPassword - retyped password to check
     */
    const validatePasswords = (password: string, retypedPassword: string): void => {
        setPasswordMismatchError(password !== retypedPassword ? translate("settings.userSettings.passwordsMismatchError") : "");
    };

    /**
     * Called when user click the eye to show the new password in clear text
     */
    const handleToggleShowNewPassword = (): void => {
        setShowNewPassword(!showNewPassword);
    };

    /**
     * Called when user click to submit the new password
     */
    const handleSubmitButton = (): void => {
        dispatch(userPasswordUpdateByUser(userId, newPassword, false, translate("settings.userSettings.passwordUpdateCompleted")))
            .then((): void => {
                const instance = window.localStorage.getItem(EnumLocalStorageItem.INSTANCE);
                // must force user to relogin to make sure the token is updated
                dispatch(logout()).then(() => {
                    dispatch(login(userName, newPassword, instance ? instance : ""));
                });
            })
            .catch((error) => {
                if (isApiError(error) && assertSubCode(error, ApiErrorSubCodes.USER_INVALID_PASSWORD_FORMAT)) {
                    setInvalidPasswordFormatError(true);
                }
            });
    };

    return (
        <Dialog id="passwordUpdateId" className={classes.dialogMain} open={open} disableAutoFocus={true} fullWidth={true}>
            <DialogTitle>
                <Typography>{translate("passwordChangedDialog.dialogTitle")}</Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <KortexTextField
                    className={classes.dialogInput}
                    value={newPassword}
                    label={translate("passwordChangedDialog.newPassword")}
                    TextFieldProps={{
                        required: true,
                        id: "passwordId",
                    }}
                    error={invalidPasswordFormatError ? translate("settings.userSettings.invalidPasswordError") : undefined}
                    onChange={handleNewPasswordChange}
                    type={showNewPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleToggleShowNewPassword} className={classes.iconButton}>
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <KortexTextField
                    className={classes.dialogInput}
                    label={translate("passwordChangedDialog.retypePassword")}
                    onChange={handleRetypedPasswordChange}
                    TextFieldProps={{
                        id: "retypedPasswordId",
                        required: true,
                    }}
                    value={retypedPassword}
                    error={passwordMismatchError}
                    type="password"
                />
                <Typography className={classes.passwordHint}>{translate("settings.userSettings.passwordHint")}</Typography>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    id="proceedButtonId"
                    variant="contained"
                    color="secondary"
                    className={classes.dialogButtons}
                    disabled={Boolean(invalidPasswordFormatError) || Boolean(passwordMismatchError)}
                    onClick={handleSubmitButton}
                >
                    <Typography>{translate("passwordChangedDialog.submit")}</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}
