import { UserDbModel, UserGroupDbModel, EnumUserStatus, IUserDbModel, IUserGroupDbModel } from "@kortex/aos-common";
import { KortexTextField, theme } from "@aos/react-components";
import { Checkbox, MenuItem, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { useEntitiesUsersGroups, useEntitiesUsers } from "../../../../../redux/effects";

const useStyles = makeStyles({
    pannelDetails: {
        display: "block",
    },
    groupUsers: {
        padding: 0,
    },
    pannelDetailsUserContent: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        width: "100%",
    },
    pannelSummary: {
        backgroundColor: theme.palette.grey[200],
    },
    pannelSummaryContent: {
        alignItems: "center",
        display: "flex",
        width: "100%",
    },
    textField: {
        margin: 0,
        width: "100%",
    },
    user: {
        width: "100%",
    },
    groupLabel: {
        paddingLeft: "9px",
        color: theme.palette.primary[500],
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
});

export interface IGroupLabel {
    userGroupId: number;
    label: JSX.Element;
}

interface IOwnProps {
    onUserSelect: (selectedUsers: UserDbModel["userId"][]) => void;
    onGroupSelect?: (selectedGroups: UserGroupDbModel["userGroupId"][]) => void;
    userFilter: (group: IUserGroupDbModel, searchValue: string) => (user: IUserDbModel) => boolean;
    disabled?: boolean;
    groupHeaderLabels?: IGroupLabel[];
    groupLabels?: IGroupLabel[];
    mandatoryGroups?: number[];
    variant?: "userSelector" | "groupSelector";
}

export default function UserGroupSelector(props: IOwnProps): JSX.Element {
    const {
        userFilter,
        onUserSelect,
        onGroupSelect,
        disabled,
        groupHeaderLabels = [],
        groupLabels = [],
        variant = "groupSelector",
        mandatoryGroups = [],
    } = props;

    const classes = useStyles();
    const groups = useEntitiesUsersGroups({ isActive: true, isApprover: true });
    const translate = useTranslate();
    const users = useEntitiesUsers();

    const [selectedGroups, setSelectedGroups] = useState<UserGroupDbModel["userGroupId"][]>([]);
    const [closedPanels, setClosedPanels] = useState<UserGroupDbModel["userGroupId"][]>(groups.map((group) => group.userGroupId));
    const [searchValue, setSearchValue] = useState<string>("");
    const [selectedUsers, setSelectedUser] = useState<UserDbModel["userId"][]>([]);
    const [selectAllSelected, setSelectAllSelected] = useState<UserGroupDbModel["userGroupId"][]>([]);

    // call onUserSelect prop when selected users change
    useEffect((): void => {
        updateSelectedGroups();

        onUserSelect(selectedUsers);
    }, [selectedUsers]);

    // call onGroupSelect prop when selected groups change
    useEffect((): void => {
        if (onGroupSelect) {
            onGroupSelect(selectedGroups);
        }
    }, [selectedGroups]);

    // set selected groups on mount
    useEffect((): void => {
        if (variant === "groupSelector") {
            const groupsWithMandatory = [...selectedGroups];
            mandatoryGroups.forEach((selectedGroup) => {
                if (selectedGroups.findIndex((selGroup) => selGroup === selectedGroup) === -1) {
                    groupsWithMandatory.push(selectedGroup);
                }
            });
            // if no changes don't fire the change to selectedGroups
            if (JSON.stringify(groupsWithMandatory) !== JSON.stringify(selectedGroups)) {
                setSelectedGroups(groupsWithMandatory);
                setClosedPanels(groups.map((group) => group.userGroupId).filter((groupId) => !groupsWithMandatory.includes(groupId)));
            }
        }
    }, [mandatoryGroups]);

    /**
     * updates selected groups based on user selection
     */
    const updateSelectedGroups = (): void => {
        if (variant === "userSelector") {
            groups.forEach((selectedGroup) => {
                let allUsersSelected = true;
                getSortedAndFilteredGroupUsers(selectedGroup).forEach((user) => {
                    if (selectedUsers.findIndex((selectedUser) => selectedUser === user.userId) === -1) {
                        allUsersSelected = false;
                    }
                });
                if (!allUsersSelected) {
                    const newGroupIds = [...selectedGroups];
                    const groupIndex = selectedGroups.findIndex((selGroup) => selGroup === selectedGroup.userGroupId);
                    if (groupIndex > -1) {
                        newGroupIds.splice(groupIndex, 1);
                        setSelectedGroups(newGroupIds);
                    }
                } else if (selectedGroups.findIndex((selGroup) => selGroup === selectedGroup.userGroupId) === -1) {
                    const updatedSelectedGroups = [...selectedGroups, selectedGroup.userGroupId];
                    setSelectedGroups(updatedSelectedGroups);
                }
            });
        }
    };
    /**
     * Get first name and last name of user
     */
    const getUserFullName = (user: UserDbModel): string => `${user.lastName}, ${user.firstName}`;

    /**
     * Sort groups alphabetically
     */
    const getSortedGroups = (): UserGroupDbModel[] => groups.sort((a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" }));

    /**
     * Get list of users from a group and sort them alphabetically
     */
    const getSortedAndFilteredGroupUsers = (group: UserGroupDbModel): UserDbModel[] =>
        users
            .filter(userFilter(group, searchValue))
            .sort((a, b) => getUserFullName(a).localeCompare(getUserFullName(b), "en", { sensitivity: "base" }));

    /**
     * When a user is clicked, add/remove it from the selected user list
     *
     * @param {number} userId - clicked user ID
     */
    const handleUserClick =
        (userId: UserDbModel["userId"], groupId: UserGroupDbModel["userGroupId"]): (() => void) =>
        (): void => {
            const index = selectedUsers.findIndex((id) => id === userId);

            if (index === -1) {
                // User was not selected
                setSelectedUser([...selectedUsers, userId]);

                const groupIndex = selectedGroups.findIndex((id) => id === groupId);
                if (groupIndex === -1) {
                    // Group was not selected
                    setSelectedGroups([...selectedGroups, groupId]);
                }
            } else {
                // User was already selected
                const userIds = [...selectedUsers];
                userIds.splice(index, 1);
                setSelectedUser(userIds);
            }
        };

    /**
     * When a group is clicked, add/remove it from the selected group list
     * Add/remove all users that are included in that group from the selected user list
     *
     * @param {number} groupId - clicked group ID
     */
    const handleGroupClick =
        (groupId: UserGroupDbModel["userGroupId"]): ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) =>
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
            event.stopPropagation();

            toggleSelectGroup(groupId);
        };

    /**
     * If a group was selected, remove it from selected groups, if it was not selected
     * add it to selected groups.
     *
     * @param {number} groupId - the group id of the panel
     */
    const toggleSelectGroup = (groupId: UserGroupDbModel["userGroupId"]): void => {
        if (!groupId) {
            return;
        }

        const index = selectedGroups.findIndex((id) => id === groupId);

        if (index === -1) {
            // Group was not selected
            setSelectedGroups([...selectedGroups, groupId]);
        } else {
            // Group was already selected
            const groupIds = [...selectedGroups];
            groupIds.splice(index, 1);
            setSelectedGroups(groupIds);
        }
    };

    /**
     * If panel is closed set it to open, if it is open set it to closed.
     *
     * @param {number} groupId - the group id of the panel
     */
    const toggleExpandPanel = (groupId: UserGroupDbModel["userGroupId"]): void => {
        if (!groupId) {
            return;
        }

        const index = closedPanels.findIndex((id) => id === groupId);

        if (index === -1) {
            // Panel was open
            setClosedPanels([...closedPanels, groupId]);
        } else {
            // Panel was closed
            const closedPanelIds = [...closedPanels];
            closedPanelIds.splice(index, 1);
            setClosedPanels(closedPanelIds);
        }
    };

    /**
     * If the group is in select all then remove it, if it was not then add it.
     *
     * @param {number} groupId - the group id of the panel
     */
    const toggleSelectAll = (groupId: UserGroupDbModel["userGroupId"]): void => {
        if (!groupId) {
            return;
        }

        const index = selectAllSelected.findIndex((id) => id === groupId);
        const updatedSelectedUsers = [...selectedUsers];
        const groupUsers = users.filter((user) => user.groups.includes(groupId));

        // It was selected
        if (index >= 0) {
            const selectAllSelectedIds = [...selectAllSelected];
            selectAllSelectedIds.splice(index, 1);
            setSelectAllSelected(selectAllSelectedIds);

            for (const user of groupUsers) {
                const userIndex = updatedSelectedUsers.findIndex((userId) => user.userId === userId);
                updatedSelectedUsers.splice(userIndex, 1);
            }

            setSelectedUser(updatedSelectedUsers);

            return;
        }

        // It was not selected
        setSelectAllSelected([...selectAllSelected, groupId]);

        for (const user of groupUsers) {
            if (!updatedSelectedUsers.includes(user.userId)) {
                updatedSelectedUsers.push(user.userId);
            }
        }

        setSelectedUser(updatedSelectedUsers);
    };

    /**
     * When the select all button is clicked, select/deselect all users in the group.
     *
     * @param {number} groupId - clicked group ID
     */
    const handleSelectAllClick =
        (groupId: UserGroupDbModel["userGroupId"]): (() => void) =>
        (): void => {
            toggleSelectAll(groupId);
        };

    /**
     * Change the search value
     *
     * @param {string} value - updated value
     */
    const handleSearchValueChange = (value: string): void => {
        setSearchValue(value);
    };

    /**
     * Open/close the clicked expansion pannel
     */
    const handleSummaryClick =
        (groupId: UserGroupDbModel["userGroupId"]): (() => void) =>
        (): void => {
            toggleExpandPanel(groupId);
        };

    /**
     * get the group label
     *
     * @param {UserGroupDbModel} userGroup - the user group
     */
    const getGroupLabel = (userGroup: UserGroupDbModel): JSX.Element => {
        if (groupLabels) {
            const groupLabel = groupLabels.find((group) => group.userGroupId === userGroup.userGroupId);
            if (groupLabel) {
                return groupLabel.label;
            }
        }
        return <></>;
    };

    /**
     * get the group header label
     *
     * @param {UserGroupDbModel} userGroup - the user group
     */
    const getGroupHeaderLabel = (userGroup: UserGroupDbModel): JSX.Element => {
        if (groupHeaderLabels) {
            const groupLabel = groupHeaderLabels.find((group) => group.userGroupId === userGroup.userGroupId);
            if (groupLabel) {
                return groupLabel.label;
            }
        }
        return <></>;
    };

    /**
     * Checks if group is mandatory
     *
     * @param {number} groupId - Group ID
     */
    const isMandatory = (groupId: number): boolean => mandatoryGroups.findIndex((mandatoryGroupId) => mandatoryGroupId === groupId) > -1;

    return (
        <>
            <KortexTextField
                changedDelayMS={500}
                className={classes.textField}
                onChanged={handleSearchValueChange}
                TextFieldProps={{
                    disabled,
                    placeholder: translate("process.versioning.reviewRequestSearchUser"),
                }}
                value={searchValue}
            />
            {getSortedGroups().map((group, index) => {
                const selected = selectedGroups.includes(group.userGroupId);
                const selectAll = selectAllSelected.includes(group.userGroupId);
                const expanded = Boolean(group.userGroupId && !closedPanels.includes(group.userGroupId));
                const filteredUsers = getSortedAndFilteredGroupUsers(group)?.filter((user) => user.status !== EnumUserStatus.DISABLED);
                if (filteredUsers.length > 0) {
                    return (
                        <Accordion disabled={disabled} key={index} expanded={expanded}>
                            <AccordionSummary
                                className={classes.pannelSummary}
                                onClick={handleSummaryClick(group.userGroupId)}
                                expandIcon={variant === "userSelector" ? <></> : <ExpandMore />}
                            >
                                <div className={classes.pannelSummaryContent}>
                                    <Checkbox
                                        checked={selected}
                                        disabled={isMandatory(group.userGroupId)}
                                        onClick={handleGroupClick(group.userGroupId)}
                                        id={`${group.name}CheckBoxId`}
                                    />
                                    <Typography variant="h4">{group.name}</Typography>
                                    <div className={classes.groupLabel}>{getGroupLabel(group)}</div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails className={classes.pannelDetails}>
                                <div className={classes.user}>
                                    <MenuItem button={false} dense={true}>
                                        <div className={classes.groupLabel}>{getGroupHeaderLabel(group)}</div>
                                    </MenuItem>
                                </div>
                                <div className={classes.user} key={index}>
                                    <MenuItem button={true} dense={true} onClick={handleSelectAllClick(group.userGroupId)}>
                                        <Checkbox checked={selectAll} id={`${group.name}SelectAllCheckBoxId`} />
                                        <Typography variant="body2">{translate("process.versioning.selectAll")}</Typography>
                                    </MenuItem>
                                </div>
                                <div className={classes.groupUsers}>
                                    <div className={classes.pannelDetailsUserContent}>
                                        {filteredUsers.map((user, index) => {
                                            const selected = selectedUsers.includes(user.userId);
                                            return (
                                                <div className={classes.user} key={index}>
                                                    <MenuItem
                                                        button={true}
                                                        dense={true}
                                                        onClick={handleUserClick(user.userId, group.userGroupId)}
                                                        selected={selectedUsers.includes(user.userId)}
                                                    >
                                                        <Checkbox checked={selected} id={`${user.userName}CheckBoxId`} />
                                                        <Typography variant="body2">{getUserFullName(user)}</Typography>
                                                    </MenuItem>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                } else {
                    return <React.Fragment key={index}></React.Fragment>;
                }
            })}
        </>
    );
}
