import { KortexColorPicker, KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import {
    EnumFontStyles,
    ILocMessage,
    IWorkInstructionsElementConfig,
    IWorkInstructionsTextConfig,
    ProcessEditorRightsEnum,
} from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useEffect, useState } from "react";

import { useForeground } from "../../../../../../../../../hooks/useForeground";
import { useTranslate } from "../../../../../../../../../hooks/useTranslate";
import { IUserRightsProps } from "../../../../../../../../../utilitites/IUserRights";
import KortexTextFieldLocation from "../../../../../../../../core/KortexTextFieldLocation";

const useStyles = makeStyles({
    row: {
        alignItems: "flex-end",
        display: "flex",
        marginBottom: "32px",
    },
    selectContainer: {
        marginRight: "32px",
    },
    select: {
        width: "150px",
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    labelProps: IWorkInstructionsElementConfig<IWorkInstructionsTextConfig>;
    onCancel: () => void;
    onSave: (element: IWorkInstructionsElementConfig<IWorkInstructionsTextConfig>) => void;
    open: boolean;
}

export default function WorkInstructionsTextEditor(props: IOwnProps): JSX.Element {
    const { onCancel, onSave, open, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [labelProps, setLabelProps] = useState<IWorkInstructionsElementConfig<IWorkInstructionsTextConfig>>(props.labelProps);

    const invalidText = labelProps.extendedProps.textLoc.defaultMessage.trim() === "";

    const isForeground = useForeground(open);
    useStopKeybindPropagation(open);

    /**
     * Reassign shape props to local state whenever shape props are changed
     */
    useEffect((): void => {
        setLabelProps(props.labelProps);
    }, [props.labelProps]);

    /**
     * Called when a marker property is changed
     */
    const handleChange =
        (key: keyof IWorkInstructionsTextConfig): ((event: React.ChangeEvent<HTMLSelectElement>) => void) =>
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            setLabelProps({
                ...labelProps,
                extendedProps: {
                    ...labelProps.extendedProps,
                    [key]: event.target.value,
                },
            });
        };

    /**
     * Called when marker's color is changed
     *
     * @param {string} key - updated color attribute name
     */
    const handleColorChange =
        (key: keyof IWorkInstructionsTextConfig): ((color: string) => void) =>
        (color: string): void => {
            // utility functions
            const isWhite = (color: string): boolean => color.slice(0, 7).toLowerCase() === "#ffffff";
            const isTransparent = (color: string): boolean => color.slice(7, 9) === "00";

            const previousColor = labelProps.extendedProps.backgroundColor;
            let newColor = color;

            if (key === "backgroundColor" && isWhite(previousColor) && isTransparent(previousColor) && !isWhite(newColor)) {
                // force opacity to 100%
                newColor = newColor.slice(0, 7).toLowerCase() + "ff";
            }

            setLabelProps({
                ...labelProps,
                extendedProps: {
                    ...labelProps.extendedProps,
                    [key]: newColor,
                },
            });
        };

    /**
     * Called when label's text is changed
     *
     * @param {ILocMessage} textLoc - updated message
     */
    const handleLabelChange = (textLoc: ILocMessage): void => {
        setLabelProps({
            ...labelProps,
            extendedProps: {
                ...labelProps.extendedProps,
                textLoc,
            },
        });
    };

    /**
     * Called when dialog's Cancel button is clicked
     */
    const handleCancel = (): void => {
        onCancel();
    };

    /**
     * Called when dialog's Save button is clicked
     */
    const handleSave = (): void => {
        onSave(labelProps);
    };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={invalidText || !isForeground}
            dialogProps={{
                fullWidth: true,
                maxWidth: "md",
            }}
            onCancel={handleCancel}
            onConfirm={handleSave}
            open={open}
            textLabels={{
                titleLabel: translate("action.workInstructions.text.title"),
                cancelButtonLabel: translate("action.workInstructions.editDialog.cancel"),
                proceedButtonLabel: translate("action.workInstructions.editDialog.confirm"),
            }}
        >
            <div id="dialogTextSettingsId">
                <div className={classes.row}>
                    <KortexTextField
                        className={classes.selectContainer}
                        InputProps={{ className: classes.select }}
                        label={translate("action.workInstructions.text.style")}
                        onChange={handleChange("fontStyle")}
                        TextFieldProps={{
                            select: true,
                        }}
                        value={labelProps.extendedProps.fontStyle}
                    >
                        <MenuItem value={EnumFontStyles.TITLE}>{translate("action.workInstructions.text.style.title")}</MenuItem>
                        <MenuItem value={EnumFontStyles.HEADER1}>{translate("action.workInstructions.text.style.header1")}</MenuItem>
                        <MenuItem value={EnumFontStyles.HEADER2}>{translate("action.workInstructions.text.style.header2")}</MenuItem>
                        <MenuItem value={EnumFontStyles.HEADER3}>{translate("action.workInstructions.text.style.header3")}</MenuItem>
                        <MenuItem value={EnumFontStyles.NORMAL}>{translate("action.workInstructions.text.style.normal")}</MenuItem>
                        <MenuItem value={EnumFontStyles.SMALL}>{translate("action.workInstructions.text.style.small")}</MenuItem>
                    </KortexTextField>
                    <KortexTextField
                        className={classes.selectContainer}
                        InputProps={{ className: classes.select }}
                        label={translate("action.workInstructions.text.horizontalAlignment")}
                        onChange={handleChange("horizontalAlignment")}
                        TextFieldProps={{
                            select: true,
                        }}
                        value={labelProps.extendedProps.horizontalAlignment}
                    >
                        <MenuItem value={"left"}>{translate("action.workInstructions.text.horizontalAlignment.left")}</MenuItem>
                        <MenuItem value={"center"}>{translate("action.workInstructions.text.horizontalAlignment.center")}</MenuItem>
                        <MenuItem value={"right"}>{translate("action.workInstructions.text.horizontalAlignment.right")}</MenuItem>
                    </KortexTextField>
                    <KortexTextField
                        className={classes.selectContainer}
                        InputProps={{ className: classes.select }}
                        label={translate("action.workInstructions.text.verticalAlignment")}
                        onChange={handleChange("verticalAlignment")}
                        TextFieldProps={{
                            select: true,
                        }}
                        value={labelProps.extendedProps.verticalAlignment}
                    >
                        <MenuItem value={"top"}>{translate("action.workInstructions.text.verticalAlignment.top")}</MenuItem>
                        <MenuItem value={"middle"}>{translate("action.workInstructions.text.verticalAlignment.middle")}</MenuItem>
                        <MenuItem value={"bottom"}>{translate("action.workInstructions.text.verticalAlignment.bottom")}</MenuItem>
                    </KortexTextField>
                    <KortexTextField
                        className={classes.selectContainer}
                        InputProps={{ className: classes.select }}
                        label={translate("action.workInstructions.text.padding")}
                        onChange={handleChange("padding")}
                        TextFieldProps={{
                            select: true,
                        }}
                        value={labelProps.extendedProps.padding}
                    >
                        <MenuItem value={"0px"}>{translate("action.workInstructions.text.padding.none")}</MenuItem>
                        <MenuItem value={"1px"}>1px</MenuItem>
                        <MenuItem value={"2px"}>2px</MenuItem>
                        <MenuItem value={"3px"}>3px</MenuItem>
                        <MenuItem value={"4px"}>4px</MenuItem>
                        <MenuItem value={"8px"}>8px</MenuItem>
                        <MenuItem value={"12px"}>12px</MenuItem>
                        <MenuItem value={"18px"}>18px</MenuItem>
                        <MenuItem value={"24px"}>24px</MenuItem>
                    </KortexTextField>
                    <KortexTextField
                        className={classes.selectContainer}
                        InputProps={{ className: classes.select }}
                        label={translate("action.workInstructions.text.border")}
                        onChange={handleChange("border")}
                        TextFieldProps={{
                            select: true,
                        }}
                        value={labelProps.extendedProps.border}
                    >
                        <MenuItem value={"0px"}>{translate("action.workInstructions.text.border.none")}</MenuItem>
                        <MenuItem value={"1px"}>1px</MenuItem>
                        <MenuItem value={"2px"}>2px</MenuItem>
                        <MenuItem value={"3px"}>3px</MenuItem>
                        <MenuItem value={"4px"}>4px</MenuItem>
                        <MenuItem value={"8px"}>8px</MenuItem>
                        <MenuItem value={"12px"}>12px</MenuItem>
                        <MenuItem value={"18px"}>18px</MenuItem>
                        <MenuItem value={"24px"}>24px</MenuItem>
                    </KortexTextField>
                </div>
                <div className={classes.row}>
                    <div className={classes.selectContainer}>
                        <div id="textColorContainerId">
                            <KortexColorPicker
                                color={labelProps.extendedProps.textColor}
                                inputStyle={{ width: "110px" }}
                                onChange={handleColorChange("textColor")}
                                textLabels={{
                                    inputLabel: translate("action.workInstructions.text.textColor"),
                                    opacityLabel: translate("kortexReactComponent.kortexColorPicker.opacity"),
                                }}
                                transparency={true}
                            />
                        </div>
                    </div>
                    <div className={classes.selectContainer}>
                        <div id="backgroundColorContainerId">
                            <KortexColorPicker
                                color={labelProps.extendedProps.backgroundColor}
                                inputStyle={{ width: "110px" }}
                                onChange={handleColorChange("backgroundColor")}
                                textLabels={{
                                    inputLabel: translate("action.workInstructions.text.backgroundColor"),
                                    opacityLabel: translate("kortexReactComponent.kortexColorPicker.opacity"),
                                }}
                                transparency={true}
                            />
                        </div>
                    </div>
                    <div className={classes.selectContainer}>
                        <KortexColorPicker
                            color={labelProps.extendedProps.borderColor}
                            inputStyle={{ width: "110px" }}
                            onChange={handleColorChange("borderColor")}
                            textLabels={{
                                inputLabel: translate("action.workInstructions.text.borderColor"),
                            }}
                        />
                    </div>
                </div>
            </div>
            <KortexTextFieldLocation
                label={translate("action.workInstructions.text.text")}
                locMessage={labelProps.extendedProps.textLoc}
                multiline={true}
                rows={10}
                onChange={handleLabelChange}
                KortexTextFieldProps={{
                    error: invalidText ? translate("action.workInstructions.text.emptyTextError") : undefined,
                    InputProps: {
                        id: "workInstructionsTextEditorText",
                    },
                }}
                userAccessLevel={userAccessLevel}
                showVariablePicker={true}
            />
        </KortexDialogConfirmation>
    );
}
