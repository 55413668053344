import { KortexDialogConfirmation, greyPalette, theme } from "@aos/react-components";
import {
    IProcessUiModel,
    ProcessActionId,
    ProcessActionWorkInstruction,
    getWorkInstructionsStepsWithTrainingCommunique,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { deepClone } from "@kortex/utilities";
import { Drawer, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles } from "@material-ui/core";
import React, { FC, useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import { processActionUpdate } from "../../../../../redux/process-manager/process-thunks-process";

import { ProcessTrainingCommuniqueRow } from "./processTrainingCommuniqueRow";

const useStyles = makeStyles({
    tableContainer: {
        width: "700px",
        overflow: "auto",
    },
    tableCell: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        height: "48px",
    },
    tableCellIconButton: {
        width: "48px",
    },
    tableCellLabel: {
        maxWidth: "250px",
    },
    tableCellStepNumber: {
        width: "96px",
    },
    tableRow: {
        "&:hover": {
            background: greyPalette[200],
        },
    },
});

interface IOwnProps {
    onClose: () => void;
    onStepClick: (processActionId: ProcessActionId, stepIndex: number) => void;
    open: boolean;
    process: IProcessUiModel;
}

const ProcessTrainingCommuniqueDrawer: FC<IOwnProps> = (props) => {
    const { onClose, onStepClick, open, process } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();

    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [actionOfCommuniqueToBeDeleted, setActionOfCommuniqueToBeDeleted] = useState<ProcessActionWorkInstruction | undefined>(undefined);
    const [indexStepOfCommuniqueToBeDeleted, setIndexStepOfCommuniqueToBeDeleted] = useState<number | undefined>(undefined);

    const isDraft = process.version === "draft";

    /**
     * Handle error click
     *
     * @param {ProcessActionId} processActionId - process action ID
     * @param {number} stepIndex - step index
     */
    const handleProcessActionStepClick =
        (processActionId: ProcessActionId, stepIndex: number): (() => void) =>
        (): void => {
            onStepClick(processActionId, stepIndex);
        };

    const renderRows = (): JSX.Element[] => {
        const rows: JSX.Element[] = [];

        getWorkInstructionsStepsWithTrainingCommunique(process, (step, stepIndex, action, actionIndex) => {
            rows.push(
                <ProcessTrainingCommuniqueRow
                    actionLabel={action.label}
                    communique={step.config.trainingCommunique}
                    isDraft={isDraft}
                    key={`processTrainingCommuniqueDrawerStepTableRow${rows.length}`}
                    onGoToStep={handleProcessActionStepClick(action.processActionId, stepIndex)}
                    onDeleteCommunique={handleActionDeleteConfirmationOpen(action, stepIndex)}
                    stepLabel={step.label}
                    trainingCommuniqueProcessVersion={step.config.trainingCommuniqueProcessVersion}
                    id={`${actionIndex}-${stepIndex}`}
                />
            );

            return step;
        });

        return rows;
    };

    const handleActionDeleteConfirmationOpen =
        (processAction: ProcessActionWorkInstruction, stepIndex: number): (() => void) =>
        (): void => {
            if (isDraft) {
                setActionOfCommuniqueToBeDeleted(processAction);
                setIndexStepOfCommuniqueToBeDeleted(stepIndex);
                setDeleteConfirmationOpen(true);
            }
        };

    const handleDeleteCommuniqueConfirmation =
        (willDelete: boolean): (() => void) =>
        (): void => {
            if (willDelete && actionOfCommuniqueToBeDeleted && indexStepOfCommuniqueToBeDeleted !== undefined) {
                const newProcessAction = deepClone(actionOfCommuniqueToBeDeleted);
                newProcessAction.steps[indexStepOfCommuniqueToBeDeleted].config.trainingCommunique = "";
                newProcessAction.steps[indexStepOfCommuniqueToBeDeleted].config.trainingCommuniqueProcessVersion = "";
                dispatch(processActionUpdate([newProcessAction]));
            }

            setDeleteConfirmationOpen(false);
        };

    return (
        <>
            <Drawer anchor="right" open={open} onClose={onClose}>
                <Paper className={classes.tableContainer}>
                    <Table stickyHeader={true} id={"processTrainingCommuniqueDrawerId"}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellIconButton}`} />
                                <TableCell className={`${classes.tableCell}  ${classes.tableCellLabel}`}>
                                    <Typography variant="body1">{translate("processEditor.trainingCommunique.action")}</Typography>
                                </TableCell>
                                <TableCell className={`${classes.tableCell}  ${classes.tableCellLabel}`}>
                                    <Typography variant="body1">{translate("processEditor.trainingCommunique.step")}</Typography>
                                </TableCell>
                                <TableCell className={`${classes.tableCell}  ${classes.tableCellLabel}`}>
                                    <Typography variant="body1">{translate("processEditor.trainingCommunique.version")}</Typography>
                                </TableCell>
                                <TableCell className={`${classes.tableCell} ${classes.tableCellIconButton}`} />
                                <TableCell className={`${classes.tableCell} ${classes.tableCellIconButton}`} />
                            </TableRow>
                        </TableHead>
                        <TableBody>{renderRows()}</TableBody>
                    </Table>
                </Paper>
            </Drawer>
            {deleteConfirmationOpen && (
                <KortexDialogConfirmation
                    closeOnEscape={true}
                    onCancel={handleDeleteCommuniqueConfirmation(false)}
                    onConfirm={handleDeleteCommuniqueConfirmation(true)}
                    open={deleteConfirmationOpen}
                    textLabels={{
                        titleLabel: translate("processEditor.deleteConfirmationTitle"),
                        cancelButtonLabel: translate("processEditor.deleteConfirmationCancel"),
                        proceedButtonLabel: translate("processEditor.deleteConfirmationConfirm"),
                    }}
                >
                    <Typography>{translate("processEditor.deleteCommuniqueConfirmationLabel")}</Typography>
                </KortexDialogConfirmation>
            )}
        </>
    );
};

export default ProcessTrainingCommuniqueDrawer;
