import { Kinova, util } from "@kortex/aos-api-client";
import { MessageLevelEnum, NotificationTypeEnum, TNotification } from "@kortex/aos-common";
import shortid from "shortid";

import { Snackbar } from "../components/layout/snackbarConfigurator";
import { formatMessage } from "../locales/intl-middleware";

import { notificationInsert } from "./notifications-center-manager/notifications-thunks";

export function handleApiErrorNotification(apiError: Kinova.Api.IError): void {
    let message;
    let level = MessageLevelEnum.ERROR;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let parseErrorSubString: any; // FIXME: find better typing for this variable

    if (apiError instanceof util.errors.ApiError) {
        try {
            parseErrorSubString = JSON.parse(apiError.errorSubString ?? "");
            const isAosErrorCustom = Boolean(parseErrorSubString.description);
            if (isAosErrorCustom) {
                message = `
                                ${formatMessage(parseErrorSubString.description.title)} 
                                ${formatMessage(parseErrorSubString.description.explanation)} 
                                ${formatMessage(parseErrorSubString.description.suggestion)}
                            `;
                level = parseErrorSubString.description.level;
            } else {
                message = parseErrorSubString.message;
            }
        } catch {
            message = apiError.errorSubString;
        }

        // Display information to the user.
        Snackbar.toast(`Error:  ${message}`, level);

        // Save the information in the notification center.
        const notif: TNotification = {
            notificationId: shortid.generate(),
            type: NotificationTypeEnum.NOTIFICATION_API_ERROR,
            notification: parseErrorSubString,
        };

        notificationInsert(notif);
    } else {
        handleMessageNotification(JSON.stringify(apiError), MessageLevelEnum.ERROR);
    }
}

export function handleMessageNotification(message: string, level: MessageLevelEnum): void {
    let formattedMessage = message.slice(); // copy

    try {
        // Translate if we received a locale key
        formattedMessage = formatMessage(formattedMessage);
    } catch {
        // Keep the original message
    }

    // Display information to user
    Snackbar.toast(formattedMessage, level);
}
