var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SvgIcon } from "@material-ui/core";
import * as React from "react";
function Docker(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M13.3187429,11.4539007 L15.3904066,11.4539007 L15.3904066,9.56737589 L13.3187429,9.56737589 L13.3187429,11.4539007 Z M10.8524767,11.4539007 L12.9241403,11.4539007 L12.9241403,9.56737589 L10.8524767,9.56737589 L10.8524767,11.4539007 Z M8.43553585,11.4539007 L10.5071995,11.4539007 L10.5071995,9.56737589 L8.43553585,9.56737589 L8.43553585,11.4539007 Z M6.01859496,11.4539007 L8.04093325,11.4539007 L8.04093325,9.56737589 L6.01859496,9.56737589 L6.01859496,11.4539007 Z M3.55232875,11.4539007 L5.62399237,11.4539007 L5.62399237,9.56737589 L3.55232875,9.56737589 L3.55232875,11.4539007 Z M6.01859496,9.17021277 L8.04093325,9.17021277 L8.04093325,7.28368794 L6.01859496,7.28368794 L6.01859496,9.17021277 Z M8.43553585,9.17021277 L10.5071995,9.17021277 L10.5071995,7.28368794 L8.43553585,7.28368794 L8.43553585,9.17021277 Z M10.8524767,9.17021277 L12.9241403,9.17021277 L12.9241403,7.28368794 L10.8524767,7.28368794 L10.8524767,9.17021277 Z M10.8524767,6.88652482 L12.9241403,6.88652482 L12.9241403,5 L10.8524767,5 L10.8524767,6.88652482 Z M22,10.6099291 C22,10.6099291 21.1121442,9.76595745 19.2871072,10.0638298 C19.0898059,8.62411348 17.5607208,7.78014184 17.5607208,7.78014184 C17.5607208,7.78014184 16.1302864,9.5177305 17.1661182,11.4539007 C16.8701663,11.6028369 16.376913,11.8014184 15.6370332,11.8014184 L2.07256903,11.8014184 C1.82594241,12.7446809 1.82594241,19 8.63283714,19 C13.5160442,19 17.1661182,16.7163121 18.8925046,12.5460993 C21.4574214,12.7446809 22,10.6099291 22,10.6099291 Z", id: "icons-docker" })));
}
export default Docker;
