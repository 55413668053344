import {
    TrainingPendingActionType,
    TrainingPendingListClear,
    TrainingPendingListDeletedAction,
    TrainingPendingListInsertedAction,
    TrainingPendingListSetAction,
    TrainingPendingOfUserClear,
    TrainingPendingOfUserDeletedAction,
    TrainingPendingOfUserInsertedAction,
    TrainingPendingOfUserSetAction,
} from "./training-pending-types";

// TRAINING PENDING OF USER
export function trainingPendingOfUserClear(): TrainingPendingOfUserClear {
    return { type: TrainingPendingActionType.CLEAR_TRAINING_PENDING_OF_USER };
}
export function trainingPendingOfUserInsertedAction(
    trainingPending: TrainingPendingOfUserInsertedAction["value"]
): TrainingPendingOfUserInsertedAction {
    return { type: TrainingPendingActionType.INSERT_TRAINING_PENDING_OF_USER, value: trainingPending };
}
export function trainingPendingOfUserSetAction(trainingPending: TrainingPendingOfUserSetAction["value"]): TrainingPendingOfUserSetAction {
    return { type: TrainingPendingActionType.SET_TRAINING_PENDING_OF_USER, value: trainingPending };
}
export function trainingPendingOfUserDeletedAction(
    actions: TrainingPendingOfUserDeletedAction["value"]
): TrainingPendingOfUserDeletedAction {
    return { type: TrainingPendingActionType.DELETE_TRAINING_PENDING_OF_USER, value: actions };
}

// TRAINING PENDING LIST
export function trainingPendingListClear(): TrainingPendingListClear {
    return { type: TrainingPendingActionType.CLEAR_TRAINING_PENDING_LIST };
}
export function trainingPendingListInsertedAction(
    trainingPending: TrainingPendingListInsertedAction["value"]
): TrainingPendingListInsertedAction {
    return { type: TrainingPendingActionType.INSERT_TRAINING_PENDING_LIST, value: trainingPending };
}
export function trainingPendingListSetAction(trainingPending: TrainingPendingListSetAction["value"]): TrainingPendingListSetAction {
    return { type: TrainingPendingActionType.SET_TRAINING_PENDING_LIST, value: trainingPending };
}
export function trainingPendingListDeletedAction(actions: TrainingPendingListDeletedAction["value"]): TrainingPendingListDeletedAction {
    return { type: TrainingPendingActionType.DELETE_TRAINING_PENDING_LIST, value: actions };
}
