import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { bomClearBoms } from "@kortex/aos-ui/redux/bom-manager/bom-thunks";
import { makeStyles } from "@material-ui/core";
import React, { FC, useEffect } from "react";

import { BomBody } from "./body";
import { BomProvider } from "./context";
import { BomHeader } from "./header";

const useStyles = makeStyles({
    root: {
        padding: "16px",
    },
});

const BomPage: FC = () => {
    const classes = useStyles();
    const dispatch = useThunkDispatch();

    // Clear BOM from redux state on unmount
    useEffect(() => {
        dispatch(bomClearBoms());

        return () => {
            dispatch(bomClearBoms());
        };
    }, []);

    return (
        <div className={classes.root} id="bomPageId">
            <BomProvider>
                <BomHeader />
                <BomBody />
            </BomProvider>
        </div>
    );
};

export default BomPage;
