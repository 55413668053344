import {
    ActionBlockSizeEnum,
    ElementConnectorSourceEnum,
    ProcessAction,
    ProcessEditorRightsEnum,
    getHeightIncrementToAlignGrid,
} from "@kortex/aos-common";
import { deepClone } from "@kortex/utilities";
import { ClickAwayListener } from "@material-ui/core";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import { useStoreState } from "../../../../../hooks/useStoreState";
import { processActionUpdate } from "../../../../../redux/process-manager/process-thunks-process";
import { useSelectorEditedProcessId, useSelectorProcesses } from "../../../../../redux/selectors";
import { IUserRightsProps, userCanWrite } from "../../../../../utilitites/IUserRights";
import { calcBoundingBox } from "../../../../../utilitites/math/math";
import DraggableElementManipulator, {
    EnumTransformType,
    IConnector,
    IDraggableElementManipulator,
    ITransformInfo,
} from "../../../../core/DraggableElementManipulator/DraggableElementManipulator";

import ActionBlock from "./ActionBlock/ActionBlock";
import ActionLink, { getActionLinkCoordinates, getInputOutputCoordinates } from "./ActionLink/ActionLink";

const initManipulatorProps: IDraggableElementManipulator = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    cropBottom: 0,
    cropTop: 0,
    cropLeft: 0,
    cropRight: 0,
    rotation: 0,
    points: [],
    scale: 1,

    canvasScale: 0,
    origin: null,

    showResizeControl: false,
    showCornerResizeControl: false,
    showRotateControl: false,
    showScaleControl: false,
    cropMode: false,

    translationEvent: undefined,
};

export interface ILinkSource {
    source: ElementConnectorSourceEnum | null;
    nodeIndex: number;
    actionIndex: number;
}

interface IActionBlocksAndLinksProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    actions: ProcessAction[];
    canvasScale: number;
    onActionBlockDoubleClick: (index: number) => void;
    onActionBlockSelect: (index: number, multiselect?: boolean) => void;
    onActionBlockClickAway: () => void;
    onOpenActionMenu: (index: ProcessAction["processActionId"], actionElement: HTMLElement | SVGSVGElement) => void;
    selectedActionIds: ProcessAction["processActionId"][];
    svgRatio: number;
}

export default function ActionBlocksAndLinks(props: IActionBlocksAndLinksProps): JSX.Element {
    // Props
    const {
        canvasScale,
        onActionBlockDoubleClick,
        onActionBlockSelect,
        onActionBlockClickAway,
        onOpenActionMenu,
        selectedActionIds,
        userAccessLevel,
    } = props;

    const editedProcessId = useSelectorEditedProcessId();
    const processes = useSelectorProcesses();

    // States
    const [actions, setActions] = useStoreState<ProcessAction[]>(props.actions, processActionUpdate, { debouncingTimeInMs: 1000 });
    const [actionBlockManipulatorProps, setActionBlockManipulatorProps] = useState<IDraggableElementManipulator>(initManipulatorProps);
    const [actionLinkSource, setActionLinkSource] = useState<ILinkSource>({ actionIndex: -1, nodeIndex: -1, source: null });

    // Manipulator ref
    const actionBlockManipulatorRef = useRef(null);

    // User rights
    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Updates manipulator on selected actions change
     */
    useEffect((): void => {
        if (!selectedActionIds.length) {
            setActionBlockManipulatorProps(initManipulatorProps);
            return;
        }

        const connectors: IConnector[] = [];

        const selectedProcessActions = selectedActionIds
            .map((processActionId) => actions.find((action) => action.processActionId === processActionId))
            .filter((processAction) => processAction !== undefined) as ProcessAction[];

        // Add connectors to list (only when a single action block is selected)
        if (selectedProcessActions.length === 1) {
            for (const selectedActionBlock of selectedProcessActions) {
                // Ouput-to-input coordinates
                for (const [outputIndex, output] of selectedActionBlock.outputs.entries()) {
                    const coordsPair = getActionLinkCoordinates(actions, selectedActionBlock, output, outputIndex);
                    if (coordsPair) {
                        connectors.push({
                            coordsPair,
                            curved: true,
                            source: ElementConnectorSourceEnum.OUTPUT,
                        });
                    }
                }

                // Input-to-output coordinates
                for (const action of actions) {
                    for (const [outputIndex, output] of action.outputs.entries()) {
                        if (output.remoteIds.length !== 0) {
                            if (output.remoteIds[0].actionId === selectedActionBlock.processActionId) {
                                const coordsPair = getActionLinkCoordinates(actions, action, output, outputIndex);
                                if (coordsPair) {
                                    connectors.push({
                                        coordsPair,
                                        curved: true,
                                        source: ElementConnectorSourceEnum.INPUT,
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }

        // Calculate manipulator bounding box
        let x = Number.MAX_VALUE;
        let y = Number.MAX_VALUE;
        let width = 0;
        let height = 0;
        for (const action of selectedProcessActions) {
            const boundingBox = calcBoundingBox(
                action.posX,
                action.posY,
                ActionBlockSizeEnum.DEFAULT_WIDTH,
                ActionBlockSizeEnum.DEFAULT_HEIGHT,
                0
            );
            x = Math.min(boundingBox.x, x);
            y = Math.min(boundingBox.y, y);
        }

        for (const action of selectedProcessActions) {
            const incrementMultiplier = Math.max(action.inputs.length, action.outputs.length); // Height increment for action blocks with multiple outputs
            const actionBlockHeight =
                ActionBlockSizeEnum.DEFAULT_HEIGHT + incrementMultiplier * getHeightIncrementToAlignGrid(incrementMultiplier);
            const boundingBox = calcBoundingBox(action.posX, action.posY, ActionBlockSizeEnum.DEFAULT_WIDTH, actionBlockHeight, 0);
            width = Math.max(boundingBox.x + boundingBox.width - x, width);
            height = Math.max(boundingBox.y + boundingBox.height - y, height);
        }

        if (selectedProcessActions.length > 1) {
            // Draw manipulator preview without connectors (multiple action blocks selected)
            setActionBlockManipulatorProps((prevState) => ({
                ...prevState,
                canvasScale,
                connectors: undefined,
                height,
                width,
                x: x + ActionBlockSizeEnum.SHADOW_OFFSET,
                y: y + ActionBlockSizeEnum.SHADOW_OFFSET,
            }));
        } else {
            // Draw manipulator preview with connectors (single action block selected)
            setActionBlockManipulatorProps((prevState) => ({
                ...prevState,
                canvasScale,
                connectors,
                height,
                width: ActionBlockSizeEnum.DEFAULT_WIDTH,
                x: selectedProcessActions[0].posX + ActionBlockSizeEnum.SHADOW_OFFSET,
                y: selectedProcessActions[0].posY + ActionBlockSizeEnum.SHADOW_OFFSET,
            }));
        }
    }, [selectedActionIds, actions]);

    /**
     * Removes the translation event of an action block on mouse up
     */
    const handleActionBlockMouseUp = (): void => {
        setActionBlockManipulatorProps((prevState) => ({ ...prevState, translationEvent: undefined }));
    };

    /**
     * Send selected action index to parent object
     *
     *
     * @param {number} index - action index
     */
    const handleActionBlockMouseDown =
        (index: number): ((event: React.MouseEvent<SVGElement, MouseEvent>) => void) =>
        (event: React.MouseEvent<SVGElement, MouseEvent>): void => {
            setActionBlockManipulatorProps({ ...actionBlockManipulatorProps, translationEvent: event });
            onActionBlockSelect(index);
        };

    /**
     * Handles input/output links mouse down event
     * Get coordinates of selected input/output for the manipulator
     *
     * @param {number} index - Action index
     */
    const handleInputOutputMouseDown =
        (
            index: number
        ): ((source: ElementConnectorSourceEnum, nodeIndex: number, event: React.MouseEvent<SVGElement, MouseEvent>) => void) =>
        (source: ElementConnectorSourceEnum, nodeIndex: number, event: React.MouseEvent<SVGElement, MouseEvent>): void => {
            if (actions) {
                document.addEventListener("mouseup", handleActionLinkMouseUp);

                const coords = getInputOutputCoordinates(source, actions[index], nodeIndex);

                setActionBlockManipulatorProps({
                    ...actionBlockManipulatorProps,
                    canvasScale,
                    drawConnectorEvent: { event, source },
                    points: [coords],
                    origin: coords,
                });
                setActionLinkSource({ actionIndex: index, nodeIndex: nodeIndex, source });
                onActionBlockSelect(index);
            }
        };

    /**
     * Called when creating a link and a mouse up event is called on an input/output
     * Links an action output to an action input
     *
     * @param {number} index - Action index
     */
    const handleInputOutputMouseUp =
        (index: number): ((source: ElementConnectorSourceEnum, nodeIndex: number) => void) =>
        (source: ElementConnectorSourceEnum, nodeIndex: number): void => {
            // Validates that
            // 1) a link has been started
            // 2) the source (input/output) is not the same type as the target
            // 3) the input and the output are not from the same action
            if (
                actions &&
                actionLinkSource.nodeIndex !== -1 &&
                actionLinkSource.actionIndex !== -1 &&
                actionLinkSource.source &&
                actionLinkSource.source !== source &&
                actionLinkSource.actionIndex !== index
            ) {
                // Input (source) to output (target)
                if (
                    actionLinkSource.source === ElementConnectorSourceEnum.INPUT ||
                    actionLinkSource.source === ElementConnectorSourceEnum.RETURN
                ) {
                    const copyActions = deepClone(actions);

                    copyActions[index].outputs[nodeIndex].remoteIds = [
                        {
                            actionId: actions[actionLinkSource.actionIndex].processActionId,
                            nodeId: actions[actionLinkSource.actionIndex].inputs[actionLinkSource.nodeIndex].id,
                            isReturn: actionLinkSource.source === ElementConnectorSourceEnum.RETURN,
                        },
                    ];

                    setActions(copyActions, [copyActions[index]]);
                }
                // Ouput (source) to input (target)
                else {
                    const copyActions = deepClone(actions);

                    copyActions[actionLinkSource.actionIndex].outputs[actionLinkSource.nodeIndex].remoteIds = [
                        {
                            actionId: actions[index].processActionId,
                            nodeId: actions[index].inputs[nodeIndex].id,
                            isReturn: source === ElementConnectorSourceEnum.RETURN,
                        },
                    ];

                    setActions(copyActions, [copyActions[actionLinkSource.actionIndex]]);
                }
            }
        };

    /**
     * Called when creating a link and a mouse up event is called
     * Resets link source
     */
    const handleActionLinkMouseUp = (): void => {
        document.removeEventListener("mouseup", handleActionLinkMouseUp);

        setActionLinkSource({ actionIndex: -1, nodeIndex: -1, source: null });
        setActionBlockManipulatorProps(initManipulatorProps);
    };

    /**
     * Called after completing a translation on an action block or after moving a connector
     * Saves the changes and resets the manipulator info
     *
     * @param {ITransformInfo} translationInfo - translation data
     */
    const handleTransformCompleted = (translationInfo: ITransformInfo): void => {
        const processActions = processes.find((process) => process.processId === editedProcessId)?.actions;
        const selectedProcessActions = selectedActionIds
            .map((processActionId) => processActions?.find((processAction) => processAction.processActionId === processActionId))
            .filter((processAction) => processAction !== undefined) as ProcessAction[];

        // Block translation
        if (translationInfo.transformType === EnumTransformType.TRANSLATE_ELEMENT && selectedProcessActions) {
            if (actionBlockManipulatorProps.connectors) {
                for (const connector of actionBlockManipulatorProps.connectors) {
                    if (connector.source && connector.source === ElementConnectorSourceEnum.INPUT) {
                        connector.coordsPair.x2 += translationInfo.diffX;
                        connector.coordsPair.y2 += translationInfo.diffY;
                    } else {
                        connector.coordsPair.x1 += translationInfo.diffX;
                        connector.coordsPair.y1 += translationInfo.diffY;
                    }
                }
            }

            // Update manipulator
            setActionBlockManipulatorProps({
                ...actionBlockManipulatorProps,
                canvasScale,
                translationEvent: undefined,
                x: actionBlockManipulatorProps.x + translationInfo.diffX,
                y: actionBlockManipulatorProps.y + translationInfo.diffY,
            });

            // Update position of selected actions
            const actionsCopy = [...actions];
            const actionsToUpsert: typeof actionsCopy = [];

            for (const action of actionsCopy) {
                if (selectedActionIds.some((id) => id === action.processActionId)) {
                    action.posX += translationInfo.diffX;
                    action.posY += translationInfo.diffY;

                    actionsToUpsert.push(action);
                }
            }

            setActions(actionsCopy, actionsToUpsert);
        } // Moving a connector
        else if (translationInfo.transformType === EnumTransformType.DRAW_CONNECTOR) {
            setActionBlockManipulatorProps(initManipulatorProps);
        }
    };

    /**
     * Opens the action menu
     *
     * @param {number} index - action index
     */
    const handleOpenActionMenu =
        (index: number): ((anchorEl: HTMLElement) => void) =>
        (anchorEl: HTMLElement): void => {
            onOpenActionMenu(index, anchorEl);
        };

    /**
     * Handles action block double click
     *
     * @param {number} index - action index
     */
    const handleActionBlockDoubleClick =
        (index: number): (() => void) =>
        (): void => {
            if (!actions) {
                return;
            }

            if (onActionBlockDoubleClick) {
                onActionBlockDoubleClick(index);
            }
        };

    /**
     * Handles action block click away
     */
    const handleClickAway = (): void => {
        onActionBlockClickAway();
    };

    return (
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
            <g>
                {/* ACTION BLOCKS */}
                {actions &&
                    actions.map((action, index) => (
                        <ActionBlock
                            actionProps={action}
                            disabled={readOnly}
                            key={index}
                            onOpenActionMenu={handleOpenActionMenu(index)}
                            onBlockDoubleClick={handleActionBlockDoubleClick(index)}
                            onBlockMouseDown={handleActionBlockMouseDown(index)}
                            onBlockMouseUp={handleActionBlockMouseUp}
                            onInputOutputMouseDown={handleInputOutputMouseDown(index)}
                            onInputOutputMouseUp={handleInputOutputMouseUp(index)}
                            selected={Boolean(selectedActionIds.find((processActionId) => processActionId === action.processActionId))}
                        />
                    ))}

                {/* ACTION LINKS */}
                {actions && <ActionLink actionsProps={actions} />}

                {/* MANIPULATOR */}
                <DraggableElementManipulator
                    {...actionBlockManipulatorProps}
                    eleRef={actionBlockManipulatorRef}
                    canvasScale={canvasScale}
                    onTransformCompleted={handleTransformCompleted}
                    disabled={selectedActionIds.length === 0}
                    next={false}
                />
            </g>
        </ClickAwayListener>
    );
}
