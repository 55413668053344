export const fr = {
    KORTEX_SEQUENCER_ACTION_CORE_ACTION_ERROR: "Une erreur est survenue lors de l'exécution d'une action.",
    KORTEX_SEQUENCER_ACTION_HOOK_ERROR: "Une erreur est survenue lors de l'exécution du hook d'une action",
    KORTEX_SEQUENCER_ACTION_HOOK_ON_FAILL_ERROR: "Une erreur est survenue lors de l'exécution du hook \"onFail\" d'une action.",
    KORTEX_SEQUENCER_ACTION_INVALID_TYPE: "L'action a un type invalide.",
    KORTEX_SEQUENCER_ACTION_PAUSE_NOT_DEFINED: "Tentative de mettre en pause une action qui ne peut être mise en pause.",
    KORTEX_SEQUENCER_ACTION_NON_REMOTE_ACTION_RECEIVING_REMOTE_EVENT: "Une action non remote a reçu un événement remote.",
    KORTEX_SEQUENCER_ACTION_RESUME_NOT_DEFINED: "Tentative de reprendre une action qui ne peut être reprise.",
    KORTEX_SEQUENCER_ACTION_SET_ACTION_OUTPUT_VARIABLE_MANAGER_ERROR: "Une erreur est survenue lors de l'assignation de sortie de l'action à une variable.",
    KORTEX_SEQUENCER_ACTION_SET_STATE_NOT_DEFINED: "Tentative de modifier l'état d'une action dans laquelle l'état ne peut être modifié.",
    KORTEX_SEQUENCER_ACTION_STOP: "L'action a été arrêtée.",
    KORTEX_SEQUENCER_ACTION_GROUP_HOOK_ACTION_ERROR: "Une erreur est survenue lors de l'exécution d'un hook d'une action.",
    KORTEX_SEQUENCER_ACTION_GROUP_HOOK_ON_FAILL_ERROR: "Une erreur est survenue lors de l'exécution du hook \"onFail\" d'un groupe d'action.",
    KORTEX_SEQUENCER_ACTION_GROUP_FLOW_ERROR_CLIENT_UPDATE_NO_WAITING_ACTION: "Tentative de lancer l'action suivante alors qu'aucune action n'est en attente.",
    KORTEX_SEQUENCER_ACTION_GROUP_FLOW_ERROR_CLIENT_UPDATE_NOT_FAILED: "Tentative de recommencer un groupe d'actions qui n'a pas échoué.",
    KORTEX_SEQUENCER_ACTION_GROUP_FLOW_ERROR_CLIENT_UPDATE_RETRY_CURRENT_ACTION_NOT_FAILED: "Tentative de recommencer une action qui n'a pas échouée.",
    KORTEX_SEQUENCER_ACTION_GROUP_NO_ACTION: "Le groupe d'action n'a aucune action.",
    KORTEX_SEQUENCER_ACTION_GROUP_STOP: "Le groupe d'action a été arrêté.",
    KORTEX_SEQUENCER_ACTION_GROUP_UNDEFINED_SUB_PROGRAM: "Le sous-programme spécifié n'a pu être trouvé.",
    KORTEX_SEQUENCER_CORE_CONDITION_PLAY_ALREADY_PLAYING: "L'action est déjà en train de jouer.",
    KORTEX_SEQUENCER_CORE_CONDITION_PLAY_FAILS_ON_ELSE: "La condition a été configurée de sorte à ce qu'elle échoue lorsqu'elle atteint l'instruction \"else\".",
    KORTEX_SEQUENCER_CORE_CONDITION_PLAY_INVALID_CONDITION: "La condition est invalide.",
    KORTEX_SEQUENCER_CORE_CONDITION_PLAY_VARIABLE_MANAGER_ERROR: "Une erreur est survenue dans le gestionnaire de variables.",
    KORTEX_SEQUENCER_CORE_CONDITION_STOP: "L'action a été arrêtée.",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_INVALID_FIRST_STATEMENT: "La première instruction de la condition n'est pas \"if\".",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_INVALID_LOGICAL_OPERATOR: "La condition a un opérateur logique invalide.",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_NO_STATEMENT: "La condition n'a aucune instructions.",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_TOO_MANY_IF_STATEMENTS: "La condition ne devrait avoir qu'une seule instruction \"if\"",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_UNDEFINED_LEFT_OPERAND_VARIABLE_IDENTIFIER: "L'identifiant d'une variable n'est pas défini (opérande gauche).",
    KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_UNDEFINED_RIGHT_OPERAND_VARIABLE_IDENTIFIER: "L'identifiant d'une variable n'est pas défini (opérande droit).",
    KORTEX_SEQUENCER_CORE_LOOP_PLAY_ALREADY_PLAYING: "L'action est déjà en train de jouer.",
    KORTEX_SEQUENCER_CORE_LOOP_PLAY_INCREMENTED_VARIABLE_NOT_NUMBER: "La variable d'incrémentation n'est pas de type \"numérique\".",
    KORTEX_SEQUENCER_CORE_LOOP_PLAY_INVALID_CONDITION: "La condition de la boucle est invalide.",
    KORTEX_SEQUENCER_CORE_LOOP_PLAY_VARIABLE_MANAGER_ERROR: "Une erreur est survenue dans le gestionnaire de variables.",
    KORTEX_SEQUENCER_CORE_LOOP_STOP: "L'action a été arrêtée.",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_INVALID_INCREMENTER_INCREMENT: "La valeur d'incrémentation de la boucle est invalide.",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_INVALID_INCREMENTER_INITIAL_VALUE: "La valeur initiale de l'incrémenteur de la boucle est invalide.",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_INVALID_LOGICAL_OPERATOR: "La condition de la boucle utilise un opérateur logique invalide.",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_UNDEFINED_CONDITIONS: "La boucle a une condition invalide.",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_UNDEFINED_LEFT_OPERAND_VARIABLE_IDENTIFIER: "L'identifiant d'une variable n'est pas défini (opérande gauche).",
    KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_UNDEFINED_RIGHT_OPERAND_VARIABLE_IDENTIFIER: "L'identifiant d'une variable n'est pas défini (oéprande droite).",
    SEQUENCER_ACTION_CORE_MESSAGE_VALIDATE_INVALID_MESSAGE: "Le message est invalide.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_ALREADY_PAUSED: "L'action est déjà en pause.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_ALREADY_STARTED: "L'action remote a déjà commencé.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_FAILED: "L'action remote a échouée.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_NOT_PAUSED: "L'action remote n'est pas en pause.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_NOT_STARTED: "L'action remote n'est pas commencée.",
    KORTEX_SEQUENCER_CORE_REMOTE_ACTION_CLIENT_UPDATE_START_NOT_REQUESTED_BY_ACTION: "L'action remote n'a pas fait de requête pour être jouée.",
    KORTEX_SEQUENCER_CORE_REMOTE_CLIENT_UPDATE_ERROR: "Une erreur est survenue lors de la communication avec l'action remote.",
    KORTEX_SEQUENCER_CORE_REMOTE_PAUSE_ERROR: "Une erreur est survenue lors de la tentative mettre l'action en pause.",
    KORTEX_SEQUENCER_CORE_REMOTE_PAUSE_CONFIRMATION_TIMEOUT_ERROR: "Le délai de confirmation de la pause a expiré.",
    KORTEX_SEQUENCER_CORE_REMOTE_PLAY_ALREADY_PLAYING: "L'action est déjà en train de jouer.",
    KORTEX_SEQUENCER_CORE_REMOTE_PLAY_ERROR: "Une erreur est survenue lors de la tentative de commencer l'action.",
    KORTEX_SEQUENCER_CORE_REMOTE_PLAY_COMPLETION_TIMEOUT_ERROR: "Le délai pour la complétion de l'action est expiré.",
    KORTEX_SEQUENCER_CORE_REMOTE_PLAY_CONFIRMATION_TIMEOUT_ERROR: "Le délai pour la confirmation du démarrage de l'action est expiré.",
    KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHOD_PLAY_UNDEFINED: "La méthode \"play\" de l'action remote n'est pas définie.",
    KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHOD_STOP_UNDEFINED: "La méthode \"stop\" de l'action remote n'est pas définie.",
    KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHOD_UNDEFINED: "L'action remote a une méthode non définie.",
    KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHODS_UNDEFINED: "Les méthodes de l'action remote ne sont pas définies.",
    KORTEX_SEQUENCER_CORE_REMOTE_RESUME_ERROR: "Une erreur est survenue lors de la tentative de reprendre l'action.",
    KORTEX_SEQUENCER_CORE_REMOTE_RESUME_CONFIRMATION_TIMEOUT_ERROR: "Le délai de confirmation pour reprendre l'action est expiré.",
    KORTEX_SEQUENCER_CORE_REMOTE_SERVICE_NOT_READY: "Le service remote n'est pas prêt.",
    KORTEX_SEQUENCER_CORE_REMOTE_START_ACTION_ERROR: "Une erreur est survenue lors de la tentative de démarrage de l'action",
    KORTEX_SEQUENCER_CORE_REMOTE_STOP: "L'action a été arrêtée.",
    KORTEX_SEQUENCER_CORE_REMOTE_STOP_ERROR: "Une erreur est survenue lors de la tentative de d'arrêter l'action.",
    KORTEX_SEQUENCER_CORE_REMOTE_STOP_CONFIRMATION_TIMEOUT_ERROR: "Le délai de confirmation pour arrêter l'action est expiré.",
    KORTEX_SEQUENCER_CORE_SET_PLAY_ALREADY_PLAYING: "L'action est déjà en train de jouer.",
    KORTEX_SEQUENCER_CORE_SET_PLAY_INVALID_VALUE_TYPE: "Le type de la valeur de la variable est invalide.",
    KORTEX_SEQUENCER_CORE_SET_PLAY_VARIABLE_MANAGER_SET_VARIABLE_ERROR: "Une erreur est survenue lors de l'assignation d'une variable dans le gestionnaire de variables.",
    KORTEX_SEQUENCER_CORE_SET_STOP: "L'action a été arrêtée.",
    KORTEX_SEQUENCER_CORE_SET_INVALID_VALUE_TYPE: "Le type de la variable est invalide.",
    KORTEX_SEQUENCER_CORE_SET_UNDEFINED_VARIABLE_IDENTIFIER: "L'identifiant de la variable est invalide.",
    KORTEX_SEQUENCER_CORE_SUB_PROGRAM_PLAY_INPUT_UNDEFINED: "Une entrée du sous-programme est invalide.",
    KORTEX_SEQUENCER_CORE_SUB_PROGRAM_PLAY_OUTPUT_UNDEFINED: "Une sortie du sous-programme est invalide.",
    KORTEX_SEQUENCER_CORE_SUB_PROGRAM_STOP: "L'action a été arrêtée.",
    KORTEX_SEQUENCER_CORE_SUB_PROGRAM_VALIDATE_INPUT_INVALID_PARENT_VARIABLE_IDENTIFIER: "Entrée du sous-programme: l'identifiant de la variable n'est pas défini.",
    KORTEX_SEQUENCER_CORE_SUB_PROGRAM_VALIDATE_OUTPUT_INVALID_PARENT_VARIABLE_IDENTIFIER: "Sortie du sous-programme: l'identifiant de la variable n'est pas défini.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_PLAY_ALREADY_PLAYING: "L'action est déjà en train de jouer.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_PLAY_INVALID_CONDITION: "La condition n'est pas valide.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_PLAY_VARIABLE_MANAGER_ERROR: "Une erreur est survenue dans le gestionnaire de variables.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_STOP: "L'action a été arrêtée.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_INVALID_LOGICAL_OPERATOR: "Un opérateur logique de la condition n'est pas valide.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_UNDEFINED_CONDITIONS: "L'action nécessite une condition.",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_UNDEFINED_LEFT_OPERAND_VARIABLE_IDENTIFIER: "L'identifiant d'une variable n'est pas défini (opérande gauche).",
    KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_UNDEFINED_RIGHT_OPERAND_VARIABLE_IDENTIFIER: "L'identifiant d'une variable n'est pas défini (opérande droite).",
    KORTEX_SEQUENCER_CORE_WAIT_TIME_PLAY_ALREADY_PLAYING: "L'action est déjà en train de jouer.",
    KORTEX_SEQUENCER_CORE_WAIT_TIME_STOP: "L'action a été arrêtée.",
    KORTEX_SEQUENCER_CORE_WAIT_TIME_VALIDATE_INVALID_MS: "Le temps d'attente est invalide.",
    KORTEX_SEQUENCER_SEQUENCER_ACTION_GROUP_ALREADY_ADDED: "Un groupe d'action avec le même ID existe déjà.",
    KORTEX_SEQUENCER_SEQUENCER_BRANCH_ACTION_GROUP_NOT_FOUND: "Un groupe d'actions avec l'ID spécifié n'a pu être trouvé.",
    KORTEX_SEQUENCER_SEQUENCER_CURRENT_ACTION_GROUP_NOT_DEFINED: "Le séquenceur n'a pas de groupe d'actions actif.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_CLIENT_UPDATE_NO_CURRENT_ACTION_GROUP: "Le groupe d'actions en cours n'a pu être mis à jour: le groupe d'actions en cours n'est pas défini.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_CLIENT_UPDATE_NOT_PLAYING: "Le groupe d'actions en cours n'a pu être mis à jour: le groupe d'action en cours n'est pas en train de jouer.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_CLIENT_UPDATE_RETRY_NOT_FAILED_ACTION: "Le groupe d'action ne peut être rejoué: le groupe d'action en cours n'a pas échoué.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_HOOK_ACTION_REQUEST_ALREADY_IN_PROGRESS: "Un hook est déjà en cours.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_PAUSE_NOT_PAUSABLE: "Le séquenceur ne peut être mis en pause.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_PAUSE_NOT_PLAYING: "Tentative de mettre en pause le séquenceur alors qu'il n'est pas en train de jouer.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_PLAY_NOT_IDLE: "Tentative de démarrer le séquenceur alors n'est pas inactif.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_RESET_SEQUENCER_STILL_ACTIVE: "Tried to reset the sequencer while is it still active.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_RESUME_NOT_PAUSED: "Tentative de reprise du séquenceur alors qu'il n'est pas en pause.",
    KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_STOP_NOT_PLAYING_OR_PAUSED: "Tentative d'arrêt du séquenceur alors qu'il n'est pas en train de jouer ou en pause.",
    KORTEX_SEQUENCER_SEQUENCER_GET_VARIABLE_ERROR: "Un variable n'a pas être trouvée dans le gestionnaire de variables.",
    KORTEX_SEQUENCER_SEQUENCER_HISTORY_ACTION_NOT_FOUND: "Un action n'a pu être trouvée dans l'historique.",
    KORTEX_SEQUENCER_SEQUENCER_HOOK_ERROR: "Une erreur est survenue lors de l'exécution d'un hook du séquenceur.",
    KORTEX_SEQUENCER_SEQUENCER_HOOK_ERROR_ON_FAIL: "Un erreur est survenue lors de l'exécution du hook \"onFail\" du séquenceur.",
    KORTEX_SEQUENCER_SEQUENCER_HOOK_ERROR_ON_FAIL_UNDEFINED: "Le séquenceur nécessite que le hook \"onFail\" soit défini.",
    KORTEX_SEQUENCER_SEQUENCER_INPUT_ACTION_GROUP_NOT_FOUND: "L'action Entrée n'a pu être trouvée.",
    KORTEX_SEQUENCER_SEQUENCER_NEXT_ACTION_GROUP_NOT_FOUND: "Le prochain groupe d'action à jouer n'a pu être trouvé.",
    KORTEX_SEQUENCER_SEQUENCER_SET_VARIABLE_ERROR: "Une erreur est survenue lors de l'assignation d'une variable dans le gestionnaire de variables.",
    KORTEX_SEQUENCER_SEQUENCER_UNDEFINED_SUB_PROGRAM: "Le sous-programme spécifié n'a pu être trouvé.",
    KORTEX_SEQUENCER_SUB_PROGRAM_INPUT_ACTION_GROUP_NOT_FOUND: "Le sous-programme n'a pas d'action Entrée.",
    KORTEX_SEQUENCER_UTILS_INTERPOLATION_ERROR: "Une erreur est survenue lors de l'interpolation d'une chaîne de caractères.",
    KORTEX_SEQUENCER_UTILS_INTERPOLATE_UNKNOWN_VARIABLE_ERROR: "Tentative d'interpolation d'une variable non définie.",
    KORTEX_SEQUENCER_VARIABLE_MANAGER_GET_ALL_ERROR: "Une variable n'a pu être trouvée dans le gestionnaire de variables.",
    KORTEX_SEQUENCER_VARIABLE_MANAGER_INVALID_IDENTIFIER: "Identifiant de variable invalide.",
    KORTEX_SEQUENCER_VARIABLE_MANAGER_SET_INVALID_VALUE_TYPE: "Tentative d'assignation d'une valeur à un variable à laquelle le type ne correspond pas.",
};
