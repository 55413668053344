import { Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import * as React from "react";
import { getDateTimeStr, MessageLevelEnum, TNotification } from "@kortex/aos-common";
import { errorPalette, rainbowPalette, warningPalette, greyPalette } from "@aos/react-components";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import KortexPanelCard from "../../core/KortexPanelCard";
import { useTranslate } from "../../../hooks/useTranslate";
import KortexLabel from "../../core/KortexLabel";

const useStyles = makeStyles({
    root: {
        paddingTop: "5px",
        width: "99%",
    },
    notificationPanelCardContainer: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    groupInfo: {
        alignItems: "center",
        display: "grid",
        gridAutoColumns: "0.01fr 0.3fr 5fr 0.01fr",
        gridAutoFlow: "column",
    },
    iconContainer: {
        color: greyPalette[500],
    },
    buttonNotification: {
        marginRight: "10px",
        padding: "3px",
    },
    gridItem: {
        marginLeft: "10px",
    },
    buttons: {
        marginLeft: "10px",
        display: "flex",
    },
});

interface INotificationStatusColorAndIconCard {
    color: string;
    icon: JSX.Element;
}

export interface INotificationCardProps {
    index: number;
    notification: TNotification;

    onDelete: (notification: TNotification) => void;
}

export default function NotificationCard(props: INotificationCardProps): JSX.Element {
    const { index, notification } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const ref = React.createRef<HTMLDivElement>();

    /**
     * Handle called when the user hit delete to delete a notification
     */
    const handleDeleteNotification = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.stopPropagation();
        props.onDelete(notification);
    };

    /**
     * Return the color and icon used by the notification card corresponding to the level of the notification.
     *
     * @param {TNotification} notification - notification selected
     *
     * @returns {INotificationStatusColorAndIconCard} - color and icon
     */
    const getNotificationStatusColorAndIconCard = React.useCallback((notification: TNotification): INotificationStatusColorAndIconCard => {
        switch (notification.notification.description.level) {
            case MessageLevelEnum.ERROR:
                return {
                    color: errorPalette[600], // Red
                    icon: (
                        <ErrorIcon style={{ color: errorPalette[600] }} className={classes.iconContainer} id={"iconNotificationCardId"} />
                    ),
                };
            case MessageLevelEnum.WARNING:
                return {
                    color: warningPalette[600], // Orange
                    icon: (
                        <WarningIcon
                            style={{ color: warningPalette[600] }}
                            className={classes.iconContainer}
                            id={"iconNotificationCardId"}
                        />
                    ),
                };
            case MessageLevelEnum.INFO:
                return {
                    color: rainbowPalette[400], // Blue Cyan 400
                    icon: <InfoIcon style={{ color: errorPalette[600] }} className={classes.iconContainer} id={"iconNotificationCardId"} />,
                };
            case MessageLevelEnum.SUCCESS:
                return {
                    color: rainbowPalette[600], // Green
                    icon: (
                        <CheckCircleIcon
                            style={{ color: rainbowPalette[600] }}
                            className={classes.iconContainer}
                            id={"iconNotificationCardId"}
                        />
                    ),
                };
            case MessageLevelEnum.OTHER:
                return {
                    color: errorPalette[600], // Red
                    icon: (
                        <ErrorIcon style={{ color: errorPalette[600] }} className={classes.iconContainer} id={"iconNotificationCardId"} />
                    ),
                };
            default:
                return {
                    color: errorPalette[600], // Red
                    icon: (
                        <ErrorIcon style={{ color: errorPalette[600] }} className={classes.iconContainer} id={"iconNotificationCardId"} />
                    ),
                };
        }
    }, []);

    /**
     * Handle called when the user double click on button
     */
    const handleDetailNotification = (): void => {
        // Send the full notification information to the console.
        console.error(notification);
    };

    const notificationStatusColorAndIconCard = getNotificationStatusColorAndIconCard(notification);
    return (
        <div className={classes.root} id={"notificationCardId"}>
            <KortexPanelCard
                key={index}
                isSelected={false}
                spaceBetweenCard={1}
                padding={3}
                variant="none"
                onSelect={handleDetailNotification}
                statusColor={notificationStatusColorAndIconCard.color}
            >
                <div ref={ref}>
                    <div className={classes.notificationPanelCardContainer} id={"containerNotificationCardId"}>
                        <Grid container={true} className={classes.groupInfo}>
                            {/* NOTIFICATION ICON */}
                            <Grid item={true} className={classes.gridItem}>
                                {notificationStatusColorAndIconCard.icon}
                            </Grid>
                            {/* NOTIFICATION DATE */}
                            <Grid item={true} className={classes.gridItem}>
                                <KortexLabel
                                    label={`${getDateTimeStr(notification.notification.timestamp)}`}
                                    styleLabel={{ color: rainbowPalette[14] }}
                                />
                            </Grid>
                            {/* NOTIFICATION INFO */}
                            <Grid item={true} className={classes.gridItem} id={"notificationInfoCardId"}>
                                <Grid>
                                    <KortexLabel
                                        label={translate(`${notification.notification.description.title}`).replace(".", "")}
                                        variant="compact"
                                    />
                                    <KortexLabel
                                        label={translate(`${notification.notification.description.explanation}`)}
                                        styleLabel={{ color: rainbowPalette[14] }}
                                    />
                                    <KortexLabel
                                        label={translate(`${notification.notification.description.suggestion}`)}
                                        styleLabel={{ color: rainbowPalette[14] }}
                                    />
                                </Grid>
                            </Grid>
                            {/* NOTIFICATION DELETE AND SEND ICON */}
                            <Grid item={true} className={classes.buttons}>
                                <IconButton
                                    onClick={handleDeleteNotification}
                                    id={"buttonDeleteNotificationId"}
                                    className={classes.buttonNotification}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </KortexPanelCard>
        </div>
    );
}
