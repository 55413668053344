import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

function Process(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M14 4l2.29 2.29-2.88 2.88 1.42 1.42 2.88-2.88L20 10V4zm-4 0H4v6l2.29-2.29 4.71 4.7V20h2v-8.41l-5.29-5.3z"
                id="icons-routing"
            />
            path
        </SvgIcon>
    );
}

export default Process;
