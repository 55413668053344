import { KortexCard, KortexDialogConfirmation, KortexOutlinedTextField, theme } from "@aos/react-components";
import { IWorkInstructionsElementConfig, IWorkInstructionsTemplateConfig, TWorkInstructionsExtendedConfig } from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../../../hooks/useTranslate";
import { useEntitiesProcessActionSettings } from "../../../../../../../../redux/effects";
import {
    processActionSettingsGetAll,
    processActionSettingsUpdate,
} from "../../../../../../../../redux/process-manager/process-thunks-process-action-settings";
import WorkInstructionsTemplateIcon from "../Icon/WorkInstructionsTemplateIcon";

const useStyles = makeStyles({
    templateCardContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr",
        overflowY: "auto",
        gridColumnStart: 1,
        gridColumnEnd: 3,
        [theme.breakpoints.down(1280)]: {
            gridTemplateColumns: "1fr 1fr",
        },
        rowGap: "24px",
        columnGap: "24px",
        padding: "0px 24px 24px 0px",
    },
    templateContainer: {
        display: "flex",
        flexDirection: "column",
        height: "450px",
    },
});

export interface IOwnProps {
    onCancel: () => void;
    onSelect: (templateId: string, element: IWorkInstructionsElementConfig<TWorkInstructionsExtendedConfig>[]) => void;
    open: boolean;
    stepTemplateId: string;
}

export default function WorkInstructionsTemplateSelector(props: IOwnProps): JSX.Element {
    const { open, onCancel, onSelect, stepTemplateId } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [cardMenuAnchorEl, setCardMenuAnchorEl] = useState<HTMLElement | undefined>(undefined);
    const [selectedTemplate, setSelectedTemplate] = useState<IWorkInstructionsTemplateConfig | undefined>(undefined);
    const [menuTemplateIndex, setMenuTemplateIndex] = useState<number>(-1);
    const [filter, setFilter] = useState<string>("");

    const dispatch = useThunkDispatch();
    const processActionSettings = useEntitiesProcessActionSettings();

    const workInstructionsSettings = processActionSettings.find((settings) => settings.type === "core-work-instructions");

    useStopKeybindPropagation(open);

    /**
     * Effect which update local state when template ID prop changes
     */
    useEffect((): void => {
        if (workInstructionsSettings) {
            setSelectedTemplate(workInstructionsSettings.settings.templates.find((template) => template._id === stepTemplateId));
        }
    }, [stepTemplateId]);

    /**
     * Effect which fetch action global settings (which contain template list)
     */
    useEffect((): void => {
        if (open) {
            dispatch(processActionSettingsGetAll());
        }
    }, [open]);

    /**
     * Function which handles the selection of a template
     *
     * @param {string} templateId - Selected template ID
     */
    const handleSelectTemplate =
        (templateId: string): (() => void) =>
        (): void => {
            if (workInstructionsSettings?.settings) {
                setSelectedTemplate(workInstructionsSettings.settings.templates.find((template) => template._id === templateId));
            }
        };

    /**
     * Functiom which handles the confirmation of the template selection
     */
    const handleConfirmSelection = (): void => {
        const finalTemplate = selectedTemplate as IWorkInstructionsTemplateConfig;
        if (finalTemplate) {
            onSelect(finalTemplate._id, finalTemplate.workInstructionElements);
        }
    };

    /**
     * Functiom which handles the change of the search (filter) text field
     */
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setFilter(event.target.value);
    };

    /**
     * Functiom which handles the click on the card's menu item
     *
     * @param {number} index - Index of selected template
     */
    const handleCardMenuClick =
        (index: number): ((event: React.MouseEvent<HTMLElement>) => void) =>
        (event: React.MouseEvent<HTMLElement>): void => {
            event.stopPropagation();
            setCardMenuAnchorEl(event.currentTarget);
            setMenuTemplateIndex(index);
        };

    /**
     * Functiom which handles the closing of the card's menu
     */
    const handleCardMenuClose = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();
        setCardMenuAnchorEl(undefined);
    };

    /**
     * Functiom which handles deletion of a template
     */
    const handleDeleteTemplate = (): void => {
        if (workInstructionsSettings) {
            workInstructionsSettings.settings.templates.splice(menuTemplateIndex, 1);
            dispatch(processActionSettingsUpdate(workInstructionsSettings));
        }

        setMenuTemplateIndex(-1);
        setCardMenuAnchorEl(undefined);
    };

    return (
        <KortexDialogConfirmation
            open={open}
            dialogProps={{
                maxWidth: "md",
                fullWidth: true,
            }}
            textLabels={{
                titleLabel: translate("action.workInstructions.template.selector.title"),
                cancelButtonLabel: translate("action.workInstructions.editDialog.cancel"),
                proceedButtonLabel: translate("action.workInstructions.template.selector.select"),
            }}
            onCancel={onCancel}
            onConfirm={handleConfirmSelection}
            closeOnEscape={true}
        >
            <div className={classes.templateContainer}>
                <KortexOutlinedTextField
                    label={translate("action.workInstructions.template.selector.search")}
                    onChange={handleFilterChange}
                    style={{
                        marginBottom: "32px",
                        minHeight: "56px",
                        width: "400px",
                    }}
                    value={filter}
                />
                <div className={classes.templateCardContainer}>
                    {workInstructionsSettings &&
                        workInstructionsSettings.settings.templates
                            .filter(
                                (template: IWorkInstructionsTemplateConfig): boolean =>
                                    template.label.toLowerCase().includes(filter.toLowerCase()) ||
                                    template.description.toLowerCase().includes(filter.toLowerCase())
                            )
                            .map(
                                (template: IWorkInstructionsTemplateConfig, index: number): JSX.Element => (
                                    <div
                                        key={index}
                                        style={{
                                            alignItems: "center",
                                            display: "flex",
                                        }}
                                    >
                                        <KortexCard
                                            id="workInstructionsTemplateSelectorCardId"
                                            onClick={handleSelectTemplate(template._id)}
                                            color={template.color}
                                            icon={
                                                <WorkInstructionsTemplateIcon
                                                    style={{ color: "white", fontSize: 40 }}
                                                    icon={template.icon}
                                                />
                                            }
                                            textLabels={{
                                                label: template.label,
                                                description: template.description,
                                            }}
                                            selected={selectedTemplate && selectedTemplate._id === template._id}
                                        />
                                        <IconButton
                                            onClick={handleCardMenuClick(index)}
                                            style={{
                                                height: "48px",
                                                width: "48px",
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </div>
                                )
                            )}
                </div>
                <Menu anchorEl={cardMenuAnchorEl} open={Boolean(cardMenuAnchorEl)} onClose={handleCardMenuClose}>
                    <MenuItem onClick={handleDeleteTemplate}>{translate("action.workInstructions.template.selector.delete")}</MenuItem>
                </Menu>
            </div>
        </KortexDialogConfirmation>
    );
}
