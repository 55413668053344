import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import { AOSClientServiceRequestWrapped, ProcessApprovalGroupAllReq, ProcessApprovalGroupAllRes } from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { processApprovalGroupAll } from "./all";

interface IAOSClientServiceWrappedProcessApprovalGroups {
    getAll: AOSClientServiceRequestWrapped<ProcessApprovalGroupAllReq, ProcessApprovalGroupAllRes>;
}

type AOSClientServiceWrappedProcessApprovalGroups = IAOSClientServiceWrappedProcessApprovalGroups;

// ADD_API_CALL ProcessApprovalGroup
export function generateProcessApprovalGroupServiceClient(router: IRouterClient): AOSClientServiceWrappedProcessApprovalGroups {
    return {
        getAll: AOSClientServiceWrapperUI.request(processApprovalGroupAll(router)),
    };
}

generateProcessApprovalGroupServiceClient.serviceId = 23;
