import { ProcessVariableStorer } from "../../../../../Process/ProcessVariable";
import { ConditionBaseConfig } from "./ConditionConfig";
export function createStepConfigLoop() {
    return {
        conditions: [new ConditionBaseConfig()],
        maxLoopCount: 100,
        incrementVariable: new ProcessVariableStorer(),
        resetVariable: true,
        alwaysDoOnce: false,
    };
}
