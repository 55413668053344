import { IOperatorJobProcessInfo } from "@kortex/aos-common";

import { BareAction, ComplexAction } from "../app.types";

export interface IWorkScheduleState {
    jobProcesses: IOperatorJobProcessInfo[];
}

export enum WorkScheduleActionType {
    CLEAR_WORK_Schedule = "@@WorkSchedule/CLEAR_WORK_Schedule",
    UPDATE_JOB_PROCESSES = "@@WorkSchedule/UPDATE_JOB_PROCESSES",
    UPDATE_JOB_PROCESS = "@@WorkSchedule/UPDATE_JOB_PROCESS",
    UPDATE_JOB_PROCESS_LIST = "@@WorkSchedule/UPDATE_JOB_PROCESS_LIST",
    INSERT_JOB_PROCESS = "@@WorkSchedule/INSERT_JOB_PROCESS",
    DELETE_JOB_PROCESS = "@@WorkSchedule/DELETED_JOB_PROCESS",
}

export type WorkScheduleState = IWorkScheduleState;

export type ClearWorkScheduleAction = BareAction<WorkScheduleActionType.CLEAR_WORK_Schedule>;
export type JobProcessesInsertedAction = ComplexAction<WorkScheduleActionType.INSERT_JOB_PROCESS, IWorkScheduleState["jobProcesses"]>;
export type JobProcessesUpdatedAction = ComplexAction<WorkScheduleActionType.UPDATE_JOB_PROCESS, IWorkScheduleState["jobProcesses"]>;
export type SetJobProcessesListAction = ComplexAction<WorkScheduleActionType.UPDATE_JOB_PROCESSES, IWorkScheduleState["jobProcesses"]>;
export type UpdateJobProcessesListAction = ComplexAction<
    WorkScheduleActionType.UPDATE_JOB_PROCESS_LIST,
    IWorkScheduleState["jobProcesses"]
>;
export type JobProcessesDeletedAction = ComplexAction<WorkScheduleActionType.DELETE_JOB_PROCESS, IWorkScheduleState["jobProcesses"]>;

export type WorkScheduleActions =
    | ClearWorkScheduleAction
    | JobProcessesInsertedAction
    | JobProcessesUpdatedAction
    | SetJobProcessesListAction
    | UpdateJobProcessesListAction
    | JobProcessesDeletedAction;
