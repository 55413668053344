export const WorkInstructionsFormErrorKey = {
    INVALID_FORMAT: "invalidFormat",
    NONE: "",
    REQUIRED: "required",
    MIN_ITEMS: "minItems",
    MAX_ITEMS: "maxItems",
};
export class WorkInstructionsFormItemState {
    constructor(_id, value, label, items, regex, regexError) {
        this._id = "";
        this.label = "";
        this.value = "";
        this.errorKey = WorkInstructionsFormErrorKey.NONE;
        this.items = [];
        this.regex = "";
        this.regexError = "";
        this._id = _id;
        this.value = value;
        this.label = label;
        this.items = items ? items : undefined;
        this.regex = regex;
        this.regexError = regexError;
    }
}
