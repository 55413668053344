// common
import { ProcessActionType } from "@kortex/aos-common";

import { ILocale } from "../locales/ILocale";

/**
 * Gets action label based on action type
 *
 * @param {ProcessActionType} actionType - type of the action
 * @returns {ILocale} action label
 */
export function getActionLabel(actionType: ProcessActionType): keyof ILocale {
    switch (actionType) {
        case "core-condition":
            return "action.condition.label";
        case "core-connector":
            return "action.connector.label";
        case "core-data-store":
            return "action.dataStore.label";
        case "core-email":
            return "action.email.label";
        case "core-loop":
            return "action.loop.label";
        case "core-input":
            return "action.input.label";
        case "core-math":
            return "action.math.label";
        case "core-message":
            return "action.message.label";
        case "core-failure-ticket-create":
            return "action.failureTicketCreate.label";
        case "core-output":
            return "action.output.label";
        case "core-parser":
            return "action.parser.label";
        case "core-robot":
            return "action.robotControl.label";
        case "core-routing-process":
            return "action.routingProcess.label";
        case "core-set":
            return "action.set.label";
        case "core-stop-process":
            return "action.stop.label";
        case "core-fail":
            return "action.fail.label";
        case "core-routing-fail":
            return "action.routingFail.label";
        case "core-time":
            return "action.time.label";
        case "core-work-instructions":
            return "action.workInstructions.label";
        default:
            return "action.undefinedAction.label";
    }
}
