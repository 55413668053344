export const electron = {
    rpc: {
        updateHost: {
            funcId: 60000,
            funcName: "electronUpdateHost",
        },
    },
    serviceId: 600,
    serviceVersion: 1,
};
