import { OrganizationSettingsAllRes, UnwrapAOSPayload } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import {
    setSettingOrganizationListAction,
    settingOrganizationInsertedAction,
    settingOrganizationUpdatedAction,
} from "./setting-organization-actions";

// thunks

/**
 * Retrieves roles
 *
 * @param {IStandardThunkOptions} [options] - options
 */
export function settingOrganizationGetAll(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<OrganizationSettingsAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<OrganizationSettingsAllRes>> {
        if (!skipLookup) {
            if (fetchedOnce.was(settingOrganizationGetAll)) {
                return getState().setting.settingOrganization;
            }
        }

        return api.services.settingOrganization
            .getAll(emptyObject)()
            .then((settings) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(settingOrganizationGetAll);

                    dispatch(setSettingOrganizationListAction([...settings]));
                }

                return settings;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

export function settingOrganizationInsert(insertedSettingOrganization: APIPayload<"settingOrganization", "insert">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.settingOrganization
                .insert(insertedSettingOrganization)()
                .then((insertedSettingOrganization) => {
                    dispatch(settingOrganizationInsertedAction(insertedSettingOrganization));
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
    };
}

export function settingOrganizationUpdate(updatedSettingOrganization: APIPayload<"settingOrganization", "update">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.settingOrganization
                .update(updatedSettingOrganization)()
                .then((updatedSettingOrganization) => {
                    dispatch(settingOrganizationUpdatedAction([updatedSettingOrganization]));
                });
        } catch (reason) {
            // TODO: revisit how that mechanism works; if it returns "false" throw it hot potato style
            if (!handleAPIError(reason, dispatch)) {
                throw reason;
            }
        }
    };
}
