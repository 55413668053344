import { upsertObjectFromArray, removeObjectFromArray } from "@kortex/aos-common";

import { UserState, UserActions, UserActionType } from "./users-types";

const initialState: UserState = {
    groups: [],
    session: undefined,
    roles: [],
    users: [],
};

// definition

export function userReducer(state: UserState = initialState, action: UserActions): UserState {
    switch (action.type) {
        case UserActionType.SET_USER_LIST:
            return {
                ...state,
                users: [...action.value],
            };
        case UserActionType.UPDATE_USER:
            return {
                ...state,
                users: upsertObjectFromArray(state.users, action.value, function (user) {
                    return user.userId === this.userId;
                }),
            };
        case UserActionType.INSERT_USER:
            return {
                ...state,
                users: upsertObjectFromArray(state.users, action.value, function (user) {
                    return user.userId === this.userId;
                }),
            };
        case UserActionType.SET_USER_ROLE_LIST:
            return {
                ...state,
                roles: [...action.value],
            };
        case UserActionType.INSERT_USER_ROLE:
            return {
                ...state,
                roles: upsertObjectFromArray(state.roles, action.value, function (role) {
                    return role.userRoleId == this.userRoleId;
                }),
            };
        case UserActionType.UPDATE_USER_ROLE:
            return {
                ...state,
                roles: upsertObjectFromArray(state.roles, action.value, function (role) {
                    return role.userRoleId == this.userRoleId;
                }),
            };
        case UserActionType.DELETE_USER_ROLE:
            return {
                ...state,
                roles: removeObjectFromArray(state.roles, action.value, function (role) {
                    return role.userRoleId == this.userRoleId;
                }),
            };
        case UserActionType.SET_USER_GROUP_LIST:
            return {
                ...state,
                groups: [...action.value],
            };
        case UserActionType.INSERT_USER_GROUP:
            return {
                ...state,
                groups: upsertObjectFromArray(state.groups, action.value, function (group) {
                    return group.userGroupId == this.userGroupId;
                }),
            };
        case UserActionType.UPDATE_USER_GROUP: {
            return {
                ...state,
                groups: upsertObjectFromArray(state.groups, action.value, function (group) {
                    return group.userGroupId == this.userGroupId;
                }),
            };
        }
        case UserActionType.SET_USER_SESSION:
            return {
                ...state,
                session: action.value,
            };
        case UserActionType.CLEAR_USER_SESSION: {
            return {
                ...state,
                session: undefined,
            };
        }
        default:
            return state;
    }
}
