export const ProcessApprovalType = {
    AUTO: "A",
    MANUAL: "M",
    ELECTRONIC_SIGNATURE: "ES",
};
export var TimeBaseSettingEnum;
(function (TimeBaseSettingEnum) {
    TimeBaseSettingEnum["YEAR"] = "Y";
    TimeBaseSettingEnum["MONTH"] = "O";
    TimeBaseSettingEnum["WEEK"] = "W";
    TimeBaseSettingEnum["DAY"] = "D";
    TimeBaseSettingEnum["HOUR"] = "H";
    TimeBaseSettingEnum["MINUTE"] = "M";
    TimeBaseSettingEnum["SECOND"] = "S";
})(TimeBaseSettingEnum || (TimeBaseSettingEnum = {}));
