import { KortexColorPicker, KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import {
    EnumShapeTypes,
    ICoords,
    ILocMessage,
    IWorkInstructionsElementConfig,
    IWorkInstructionsMarkerConfig,
    ProcessEditorRightsEnum,
} from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../../../../hooks/useTranslate";
import { IUserRightsProps } from "../../../../../../../../../utilitites/IUserRights";
import KortexTextFieldLocation from "../../../../../../../../core/KortexTextFieldLocation";

const useStyles = makeStyles({
    row: {
        alignItems: "flex-end",
        display: "flex",
        marginBottom: "32px",
    },
    select: {
        marginRight: "32px",
    },
});

export interface IOwnProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    markerProps: IWorkInstructionsElementConfig<IWorkInstructionsMarkerConfig>;
    onCancel: () => void;
    onSave: (element: IWorkInstructionsElementConfig<IWorkInstructionsMarkerConfig>) => void;
    open: boolean;
}

export default function WorkInstructionsMarkerEditor(props: IOwnProps): JSX.Element {
    const { onCancel, onSave, open, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [markerProps, setMarkerProps] = useState<IWorkInstructionsElementConfig<IWorkInstructionsMarkerConfig>>(props.markerProps);
    const DEFAULT_ARROW_POSITION: ICoords = { x: 65, y: 125 };

    useStopKeybindPropagation(open);

    /**
     * Reassign shape props to local state whenever shape props are changed
     */
    useEffect((): void => {
        setMarkerProps(props.markerProps);
    }, [props.markerProps]);

    /**
     * Items that are displayed in the "Arrow Count" select input
     */
    const displayArrowCount = (): JSX.Element[] => {
        const items: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        return items.map(
            (item: number): JSX.Element => (
                <MenuItem key={item} value={item}>
                    {item.toString()}
                </MenuItem>
            )
        );
    };

    /**
     * Called when marker's text is changed
     *
     * @param {ILocMessage} labelLoc - updated message
     */
    const handleLabelChange = (labelLoc: ILocMessage): void => {
        setMarkerProps({
            ...markerProps,
            extendedProps: {
                ...markerProps.extendedProps,
                labelLoc,
            },
        });
    };

    /**
     * Called when marker's shape is changed
     */
    const handleShapeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const shape = event.target.value as EnumShapeTypes;

        setMarkerProps({
            ...markerProps,
            extendedProps: {
                ...markerProps.extendedProps,
                shape,
            },
        });
    };

    /**
     * Called when marker's color is changed
     *
     * @param {string} key - updated color
     */
    const handleColorChange =
        (key: keyof IWorkInstructionsMarkerConfig): ((color: string) => void) =>
        (color: string): void => {
            setMarkerProps({
                ...markerProps,
                extendedProps: {
                    ...markerProps.extendedProps,
                    [key]: color,
                },
            });
        };

    /**
     * Called when marker's arrow count is changed
     * Add or remove arrows linked to the marker
     */
    const handleArrowCountChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const arrowCount = parseInt(event.target.value, 10);
        const clone = { ...markerProps };
        const difference = arrowCount - clone.extendedProps.arrows.length;

        if (difference > 0) {
            for (let i = 0; i < difference; ++i) {
                clone.extendedProps.arrows.push(DEFAULT_ARROW_POSITION);
            }
        } else if (difference < 0) {
            for (let i = 0; i < Math.abs(difference); ++i) {
                clone.extendedProps.arrows.pop();
            }
        }

        setMarkerProps({
            ...markerProps,
            extendedProps: {
                ...markerProps.extendedProps,
                arrows: clone.extendedProps.arrows,
            },
        });
    };

    /**
     * Called when dialog's Cancel button is clicked
     */
    const handleCancel = (): void => {
        onCancel();
    };

    /**
     * Called when dialog's Save button is clicked
     */
    const handleSave = (): void => {
        onSave(markerProps);
    };

    /**
     * Called when a key is pressed in the text area
     */
    const handleTextKeydown = (e: React.KeyboardEvent): void => {
        e.nativeEvent.stopImmediatePropagation();
    };

    return (
        <KortexDialogConfirmation
            open={open}
            textLabels={{
                titleLabel: translate("action.workInstructions.marker.title"),
                cancelButtonLabel: translate("action.workInstructions.editDialog.cancel"),
                proceedButtonLabel: translate("action.workInstructions.editDialog.confirm"),
            }}
            onCancel={handleCancel}
            onConfirm={handleSave}
            closeOnEscape={true}
        >
            <React.Fragment>
                <div className={classes.row}>
                    <KortexTextField
                        TextFieldProps={{
                            select: true,
                        }}
                        label={translate("action.workInstructions.marker.shape")}
                        className={classes.select}
                        value={markerProps.extendedProps.shape}
                        onChange={handleShapeChange}
                    >
                        <MenuItem value={EnumShapeTypes.CIRCLE}>{translate("action.workInstructions.marker.shape.circle")}</MenuItem>
                        <MenuItem value={EnumShapeTypes.RECTANGLE}>{translate("action.workInstructions.marker.shape.rectangle")}</MenuItem>
                        <MenuItem value={EnumShapeTypes.TRIANGLE}>{translate("action.workInstructions.marker.shape.triangle")}</MenuItem>
                    </KortexTextField>
                    <KortexTextField
                        TextFieldProps={{
                            select: true,
                        }}
                        label={translate("action.workInstructions.marker.arrowCount")}
                        className={classes.select}
                        value={markerProps.extendedProps.arrows.length}
                        onChange={handleArrowCountChange}
                    >
                        {displayArrowCount()}
                    </KortexTextField>
                    <div className={classes.select}>
                        <KortexColorPicker
                            color={markerProps.extendedProps.textColor}
                            inputStyle={{ width: "110px" }}
                            onChange={handleColorChange("textColor")}
                            textLabels={{ inputLabel: translate("action.workInstructions.marker.textColor") }}
                        />
                    </div>
                    <div className={classes.select}>
                        <KortexColorPicker
                            color={markerProps.extendedProps.backgroundColor}
                            inputStyle={{ width: "110px" }}
                            onChange={handleColorChange("backgroundColor")}
                            textLabels={{ inputLabel: translate("action.workInstructions.marker.backgroundColor") }}
                        />
                    </div>
                </div>
                <KortexTextFieldLocation
                    label={translate("action.workInstructions.marker.text")}
                    locMessage={markerProps.extendedProps.labelLoc}
                    multiline={true}
                    rows={10}
                    onChange={handleLabelChange}
                    KortexTextFieldProps={{
                        onKeyDown: handleTextKeydown,
                    }}
                    showVariablePicker={true}
                    userAccessLevel={userAccessLevel}
                />
            </React.Fragment>
        </KortexDialogConfirmation>
    );
}
