import { theme } from "@aos/react-components";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";

const useStyles = makeStyles({
    root: {},
    dialogContent: {
        ...theme.typography.body2,
        padding: "20px",
        height: "100%",
        display: "flex",
    },
    dialogActions: {
        display: "flex",
        flexDirection: "column-reverse",
        backgroundColor: theme.palette.grey[200],
        padding: "10px",
        margin: 0,
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
        },
    },
    dialogButtons: {
        margin: "5px",
        padding: "0px 30px",
    },
    dialogDescription: {
        display: "flex",
        marginTop: "7px",
        alignItems: "center",
    },
    buttonIcons: {
        marginRight: "10px",
    },
    processTitle: {
        color: theme.palette.primary[500],
    },
    circularProgress: {
        margin: "5px",
        padding: "0px 30px",
    },
});

interface IOwnProps {
    // Own Props
    open: boolean;
    onClose?: () => void;
}

export default function MessageCircularProgressDialog(props: IOwnProps): JSX.Element {
    const { open } = props;

    const classes = useStyles();
    const translate = useTranslate();

    /**
     * handle the close
     */
    const handleClose = (): void => {
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} disableAutoFocus={true} fullWidth={true}>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.dialogDescription}>
                        <CircularProgress color="inherit" className={classes.circularProgress} />
                        <br />
                        <Typography id="messageCircularProgressId" variant="h4">
                            {translate("player.processMessageCircularProgress")}
                        </Typography>
                        <br />
                    </div>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button
                        id="proceedButtonId"
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                        className={classes.dialogButtons}
                    >
                        <Typography>{translate("player.close")}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
