var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import SvgIcon from "@material-ui/core/SvgIcon";
import * as React from "react";
function Parser(props) {
    return (React.createElement(SvgIcon, __assign({}, props),
        React.createElement("path", { fill: "currentColor", d: "M21.2764172,17.2 L21.2764172,21.8062053 L16.9,21.8062053 L16.9,17.2 L21.2764172,17.2 Z M14.2764172,17.2 L14.2764172,21.8062053 L9.9,21.8062053 L9.9,17.2 L14.2764172,17.2 Z M7.27641723,17.2 L7.27641723,21.8062053 L2.9,21.8062053 L2.9,17.2 L7.27641723,17.2 Z M20.9,2.2 L20.9,5.2 L2.9,5.2 L2.9,2.2 L20.9,2.2 Z M9.65,12.7 L11.4,12.7 L11.4,7.7 L12.5666667,7.7 L12.5666667,12.7 L14.3166667,12.7 L11.9833333,14.7 L9.65,12.7 Z M2.4,12.7 L4.15,12.7 L4.15,7.7 L5.31666667,7.7 L5.31666667,12.7 L7.06666667,12.7 L4.73333333,14.7 L2.4,12.7 Z M16.9111111,12.7 L18.6611111,12.7 L18.6611111,7.7 L19.8277778,7.7 L19.8277778,12.7 L21.5777778,12.7 L19.2444444,14.7 L16.9111111,12.7 Z", id: "icons-parsing" })));
}
export default Parser;
