import { KortexTextField, KortexTimeField, greyPalette } from "@aos/react-components";
import {
    ProcessActionStepTime,
    ProcessVariableStorer,
    ProcessVariableStoringMethod,
    ProcessVariableType,
    TimeConversion,
    TimeFunction,
    TimeTimer,
    TimeType,
} from "@kortex/aos-common";
import { ListItem, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";

import { useTranslate } from "../../../../../../hooks/useTranslate";
import { userCanWrite } from "../../../../../../utilitites/IUserRights";
import VariablePicker from "../../../../../core/VariablePicker/VariablePicker";
import { IActionStepProps } from "../IActionStepProps";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        minWidth: "160px",
        overflowX: "hidden",
        overflowY: "auto",
    },
    storeTo: {
        width: "-webkit-fill-available",
    },
    tableCell: {
        width: "300px",
    },
    tableHeader: {
        backgroundColor: greyPalette[200],
    },
    timePicker: {
        fontSize: "1rem",
        padding: "5px",
    },
    type: {
        marginRight: "16px",
        width: "-webkit-fill-available",
    },
    value: {
        display: "flex",
        margin: "5px 16px 5px 5px",
        width: "-webkit-fill-available",
    },
});

export default function TimeEditor(props: IActionStepProps<ProcessActionStepTime>): JSX.Element {
    const { actionStepProps, onChanged, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    if (!Boolean(actionStepProps)) {
        return <div id="timeEditorInvalidId" />;
    }

    const timeFieldDisplayed = actionStepProps.config.type === TimeType.COUNTDOWN || actionStepProps.config.type === TimeType.WAIT;
    const storeToVariableFilter =
        actionStepProps.config.type === TimeType.CONVERSION && actionStepProps.config.function === TimeConversion.CONVERT_TIMESTAMP_TO_DATE
            ? ProcessVariableType.STRING
            : ProcessVariableType.NUMBER;
    const valueVariableFilter =
        actionStepProps.config.type === TimeType.CONVERSION && actionStepProps.config.function === TimeConversion.CONVERT_DATE_TO_TIMESTAMP
            ? ProcessVariableType.STRING
            : ProcessVariableType.NUMBER;
    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Handles sub type change
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - event
     */
    const handleFunctionChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                function: event.target.value as TimeFunction,
            },
        });
    };

    /**
     * Handles storeTo textfield value change
     *
     * @param {string} identifier - textfield value
     * @param {ProcessVariableStoringMethod} storingMethod - overwrite or append
     */
    const handleStoreToChange = (identifier: string, storingMethod: ProcessVariableStoringMethod): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                storeTo: new ProcessVariableStorer(identifier, storingMethod),
            },
        });
    };

    /**
     * Handles time change from KortexTimeField
     *
     * @param {number} seconds - time in seconds
     */
    const handleTimeChange = (seconds: number): void => {
        if (!readOnly) {
            onChanged({
                ...actionStepProps,
                config: {
                    ...actionStepProps.config,
                    value: seconds.toString(),
                },
            });
        }
    };

    /**
     * Handles type change
     *
     * @param {React.ChangeEvent<HTMLSelectElement>} event - event
     */
    const handeTypeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                type: event.target.value as TimeType,
                value: "",
            },
        });
    };

    /**
     * Handles value change from KortexVariablePicker
     *
     * @param {string} value - time in seconds
     */
    const handleValueChange = (value: string): void => {
        onChanged({
            ...actionStepProps,
            config: {
                ...actionStepProps.config,
                value,
            },
        });
    };

    return (
        <Paper className={classes.root} id="timeEditorId">
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeader}>
                        <TableCell>{translate("action.time.type")}</TableCell>
                        <TableCell>{translate("action.time.function")}</TableCell>
                        <TableCell>{translate("action.time.value")}</TableCell>
                        <TableCell>{translate("action.time.storeTo")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {/* TYPE */}
                        <TableCell className={classes.tableCell}>
                            <KortexTextField
                                className={classes.type}
                                onChange={handeTypeChange}
                                TextFieldProps={{
                                    id: "timeEditorTypeId",
                                    select: true,
                                    disabled: readOnly,
                                }}
                                value={actionStepProps.config.type}
                                variant="standard"
                            >
                                <ListItem value={TimeType.CONVERSION}>{translate("action.time.conversion")}</ListItem>
                                <ListItem value={TimeType.COUNTDOWN}>{translate("action.time.countdown")}</ListItem>
                                <ListItem value={TimeType.TIMER}>{translate("action.time.timer")}</ListItem>
                                <ListItem value={TimeType.WAIT}>{translate("action.time.wait")}</ListItem>
                            </KortexTextField>
                        </TableCell>
                        {/* FUNCTION */}
                        <TableCell className={classes.tableCell}>
                            {actionStepProps.config.type === TimeType.CONVERSION && (
                                <KortexTextField
                                    className={classes.type}
                                    onChange={handleFunctionChange}
                                    TextFieldProps={{
                                        id: "timeEditorFunctionId",
                                        select: true,
                                        disabled: readOnly,
                                    }}
                                    value={actionStepProps.config.function}
                                    variant="standard"
                                >
                                    <ListItem value={TimeConversion.CONVERT_DATE_TO_TIMESTAMP}>
                                        {translate("action.time.convertDateToTimestamp")}
                                    </ListItem>
                                    <ListItem value={TimeConversion.CONVERT_TIMESTAMP_TO_DATE}>
                                        {translate("action.time.convertTimestampToDate")}
                                    </ListItem>
                                    <ListItem value={TimeConversion.GET_YEAR_FROM_TIMESTAMP}>
                                        {translate("action.time.getYearFromTimestamp")}
                                    </ListItem>
                                    <ListItem value={TimeConversion.GET_MONTH_FROM_TIMESTAMP}>
                                        {translate("action.time.getMonthFromTimestamp")}
                                    </ListItem>
                                    <ListItem value={TimeConversion.GET_DAY_FROM_TIMESTAMP}>
                                        {translate("action.time.getDayFromTimestamp")}
                                    </ListItem>
                                    <ListItem value={TimeConversion.GET_HOURS_FROM_TIMESTAMP}>
                                        {translate("action.time.getHoursFromTimestamp")}
                                    </ListItem>
                                    <ListItem value={TimeConversion.GET_MINUTES_FROM_TIMESTAMP}>
                                        {translate("action.time.getMinutesFromTimestamp")}
                                    </ListItem>
                                    <ListItem value={TimeConversion.GET_SECONDS_FROM_TIMESTAMP}>
                                        {translate("action.time.getSecondsFromTimestamp")}
                                    </ListItem>
                                </KortexTextField>
                            )}
                            {actionStepProps.config.type === TimeType.TIMER && (
                                <KortexTextField
                                    className={classes.type}
                                    onChange={handleFunctionChange}
                                    TextFieldProps={{
                                        id: "timeEditorFunctionId",
                                        select: true,
                                        disabled: readOnly,
                                    }}
                                    value={actionStepProps.config.function}
                                    variant="standard"
                                >
                                    <ListItem value={TimeTimer.START_TIMER}>{translate("action.time.startTimer")}</ListItem>
                                    <ListItem value={TimeTimer.STOP_TIMER}>{translate("action.time.stopTimer")}</ListItem>
                                </KortexTextField>
                            )}
                        </TableCell>
                        {/* VALUE */}
                        <TableCell className={classes.tableCell}>
                            {timeFieldDisplayed && (
                                <KortexTimeField
                                    className={classes.value}
                                    format="hms"
                                    id="timeEditorTimeId"
                                    onChange={handleTimeChange}
                                    seconds={parseInt(actionStepProps.config.value)}
                                    variant="standard"
                                />
                            )}
                            {actionStepProps.config.type === TimeType.CONVERSION && (
                                <VariablePicker
                                    KortexTextFieldProps={{
                                        className: classes.storeTo,
                                        TextFieldProps: {
                                            id: "timeEditorValueId",
                                        },
                                        variant: "standard",
                                    }}
                                    onChange={handleValueChange}
                                    userAccessLevel={userAccessLevel}
                                    value={actionStepProps.config.value}
                                    variableTypeFilter={valueVariableFilter}
                                />
                            )}
                        </TableCell>
                        {/* STORE TO */}
                        <TableCell className={classes.tableCell}>
                            {actionStepProps.config.type !== TimeType.WAIT && (
                                <VariablePicker
                                    KortexTextFieldProps={{
                                        className: classes.storeTo,
                                        TextFieldProps: {
                                            id: "timeEditorStoreToId",
                                        },
                                        variant: "standard",
                                    }}
                                    onChange={handleStoreToChange}
                                    storingMethod={actionStepProps.config.storeTo.storingMethod}
                                    userAccessLevel={userAccessLevel}
                                    value={actionStepProps.config.storeTo.identifier}
                                    variableTypeFilter={storeToVariableFilter}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    );
}
