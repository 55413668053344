import { ActionTypeEnum, SequencerActionStatusEnum, SequencerActionPlayCompletedPayloadTypeEnum } from "../../enums";
import { deepClone, KortexSequencerDebugger } from "../../utilities";
const debug = KortexSequencerDebugger.core.thread;
export const SEQUENCER_ACTION_THREAD_DEFAULT_STATE = {};
function create({}) {
    const _state = SEQUENCER_ACTION_THREAD_DEFAULT_STATE;
    function assertValidConfig(config) {
        debug("validate %o", config);
    }
    async function play(config) {
        debug("play", config);
        return {
            payload: {
                output: null,
                type: SequencerActionPlayCompletedPayloadTypeEnum.NEXT,
            },
            status: SequencerActionStatusEnum.COMPLETED,
        };
    }
    async function stop() {
        debug("stop");
    }
    return {
        get state() {
            return deepClone(_state);
        },
        get type() {
            return ActionTypeEnum.THREAD;
        },
        assertValidConfig,
        play,
        stop,
    };
}
export const SequencerActionThreadFactory = {
    create,
};
