var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// react
import * as React from "react";
import { useEffect, useState } from "react";
// mui
import { InputAdornment, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { theme } from "../mui/theme";
import { KortexTextField } from "..";
// constants
var DEFAULT_PRECISION = 0;
// Style
var useStyles = makeStyles({
    root: {
        display: "flex",
    },
    mainContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    matrix: {
        display: "flex",
        flexDirection: "column",
        margin: "10px",
        placeContent: "center",
        flexGrow: 1,
    },
    matrixCell: {
        maxWidth: "130px",
        minWidth: "80px",
    },
    row: {
        placeContent: "space-evenly",
        display: "flex",
    },
    inputAdornment: {
        width: "50px",
        display: "flex",
        color: theme.palette.grey[500],
        placeContent: "flex-end",
        fontSize: "0.8em",
    },
    buttonsContainer: {
        placeContent: "center",
        minWidth: "120px",
        display: "flex",
        flexDirection: "column",
        margin: "10px 10px",
        flexGrow: 1,
    },
    button: {
        minWidth: "120px",
        margin: "5px 10px",
    },
    textField: {}, // override
});
function KortexInputMatrix(props) {
    var classes = useStyles(props);
    var cols = props.cols, max = props.max, min = props.min, _a = props.decimals, decimals = _a === void 0 ? DEFAULT_PRECISION : _a, rows = props.rows, textLabels = props.textLabels, values = props.values;
    var _b = useState([[]]), currentValues = _b[0], setCurrentValues = _b[1];
    var _c = useState(false), changed = _c[0], setChanged = _c[1];
    var _d = useState(Array(rows * cols).map(function () { return false; })), errorState = _d[0], setErrorState = _d[1];
    var _e = useState(false), setButtonDisabled = _e[0], setSetButtonDisabled = _e[1];
    /**
     * This useEffect method fires change to parent component if required.
     */
    useEffect(function () {
        if (changed) {
            // format and round dispatched values
            props.onChanged(toSingleDimension(currentValues).map(function (val) { return roundTo(val); }));
        }
    }, [changed]);
    /**
     * This useEffect method initiates the matrix, then updates it
     * when its parent component passes new props to it
     */
    useEffect(function () {
        setErrorState(errorState.map(function () { return false; }));
        setCurrentValues(toDoubleDimension(values));
        setChanged(false);
        setSetButtonDisabled(true);
    }, [props]);
    /**
     * Copies a 2D array into a 1-dimension array
     *
     * @param {string[][]} array - the 2D array
     */
    var toSingleDimension = function (array) {
        var singleDArray = [];
        array.forEach(function (row) {
            row.forEach(function (value) {
                singleDArray.push(Number(value));
            });
        });
        return singleDArray;
    };
    /**
     * Copies a 1-dimension array into a 2D array
     *
     * @param {number[]} array - 1 dimension array
     */
    var toDoubleDimension = function (array) {
        var table = Array(rows);
        for (var i = 0; i < rows; i++) {
            table[i] = Array(rows);
            for (var j = 0; j < cols; j++) {
                table[i][j] = String(roundTo(array[i * cols + j]));
            }
        }
        return table;
    };
    /**
     * Round number to specified decimals
     *
     * @param {number} num - Number to round
     * @returns {number} - Rounded Number
     */
    var roundTo = function (num) {
        var factor = Math.pow(10, decimals);
        return Math.round(num * factor) / factor;
    };
    /**
     * Handles when the SEND button is pressed
     */
    var handleClickOnSend = function () {
        setChanged(true);
    };
    /**
     * Handles when the RESET button is pressed
     * Sets values inside to props (ie, last valid values processed as props)
     */
    var handleClickOnReset = function () {
        // reset to last validated state
        var table = Array(rows);
        for (var i = 0; i < rows; i++) {
            table[i] = Array(rows);
            for (var j = 0; j < cols; j++) {
                table[i][j] = String(roundTo(values[i * cols + j]));
            }
        }
        setCurrentValues(table);
    };
    /**
     * gets the unit for the specified data set
     *
     * @param {number} index - the index of the set in the matrix
     */
    var getUnit = function (index) { return (textLabels.units ? textLabels.units[index] : ""); };
    /**
     * Handles error state of any of the input in the matrix
     *
     * @param {number} rowIndex - changed row index
     * @param {number} colIndex - changed column index
     */
    var handleErrors = function (rowIndex, colIndex) {
        return function (error) {
            var errors = __spreadArray([], errorState, true);
            // Set errorState of input that changed. The input is in error if error !== ""
            errors[rowIndex * cols + colIndex] = error !== "";
            setErrorState(errors);
        };
    };
    /**
     * Handles changed state of a KortexTextField in the Matrix
     *
     * @param {number} row - the tow index of the text field inside the matrix
     * @param {number} col - the column index of the text field inside the matrix
     */
    var handleChange = function (row, col) {
        return function (e) {
            var bufferArray = __spreadArray([], currentValues, true);
            // set state value
            if (e.target.value !== bufferArray[row][col]) {
                bufferArray[row][col] = e.target.value;
                setCurrentValues(bufferArray);
            }
            // only enable set button if the value is different from prop's
            if (e.target.value !== String(values[row * cols + col])) {
                setSetButtonDisabled(false);
            }
        };
    };
    return (React.createElement("div", { className: classes.mainContainer },
        React.createElement("div", { className: classes.matrix }, currentValues.map(function (row, rowIndex) {
            return (React.createElement("div", { key: "".concat(rowIndex), className: classes.row }, row.map(function (value, colIndex) {
                return (React.createElement("div", { key: "".concat(rowIndex, "_").concat(colIndex), className: classes.matrixCell },
                    React.createElement(KortexTextField, { type: "number", value: value, onError: handleErrors(rowIndex, colIndex), onChange: handleChange(rowIndex, colIndex), min: min[rowIndex * cols + colIndex], max: max[rowIndex * cols + colIndex], label: textLabels.labels ? textLabels.labels[rowIndex * cols + colIndex] : undefined, InputProps: {
                            endAdornment: textLabels.units && (React.createElement(InputAdornment, { className: classes.inputAdornment, position: "end", disableTypography: true },
                                React.createElement(Typography, { variant: "body2" }, getUnit(rowIndex * cols + colIndex)))),
                            classes: {
                                root: classes.textField,
                            },
                        } })));
            })));
        })),
        React.createElement("div", { className: classes.buttonsContainer },
            React.createElement(Button, { variant: "outlined", id: "resetButtonId", className: classes.button, onClick: handleClickOnReset }, textLabels.restoreText),
            React.createElement(Button, { id: "sendButtonId", variant: "contained", color: "secondary", className: classes.button, onClick: handleClickOnSend, disabled: errorState.some(function (err) { return err === true; }) || setButtonDisabled }, textLabels.setText))));
}
export default KortexInputMatrix;
