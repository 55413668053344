import { theme } from "@aos/react-components";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { Backdrop, Grow, makeStyles, Portal } from "@material-ui/core";
import React from "react";
import ReactImageMagnify from "react-image-magnify";

const IMAGE_MAX_SIZE = {
    height: 1080,
    width: 1920,
};

const useStyles = makeStyles({
    backdrop: {
        zIndex: theme.zIndex.modal,
    },
    zommedImageContainer: {
        maxHeight: `${IMAGE_MAX_SIZE.height}px`,
        maxWidth: `${IMAGE_MAX_SIZE.width}px`,
    },
    zoomIn: {
        cursor: "zoom-in",
    },
});

interface IOwnProps {
    imageUrl: string;
    onClose: () => void;
    open: boolean;
}

function WorkInstructionsImageMagnifier(props: IOwnProps): JSX.Element {
    const { imageUrl, onClose, open } = props;

    const classes = useStyles();

    useStopKeybindPropagation(open);

    const stopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        event.stopPropagation();
    };

    return (
        <Portal>
            <Backdrop className={classes.backdrop} onClick={onClose} open={open}>
                <Grow in={open}>
                    <div className={classes.zommedImageContainer} id="zoomedImageContainerId" onClick={stopPropagation}>
                        <ReactImageMagnify
                            enlargedImagePosition="over"
                            hoverDelayInMs={750}
                            largeImage={{
                                src: imageUrl,
                                ...IMAGE_MAX_SIZE,
                            }}
                            smallImage={{
                                isFluidWidth: true,
                                src: imageUrl,
                            }}
                        />
                    </div>
                </Grow>
            </Backdrop>
        </Portal>
    );
}

export default WorkInstructionsImageMagnifier;
