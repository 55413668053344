import { ConditionState } from "@kortex/aos-common";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    outputContainer: {
        height: "-webkit-fill-available",
        padding: "32px",
        width: "-webkit-fill-available",
    },
    outputText: {
        marginBottom: "32px",
        textAlign: "center",
    },
    outputTitle: {
        marginBottom: "32px",
    },
});

export interface IOwnProps {
    actionState: ConditionState;
}

export default function ConditionPlayer(props: IOwnProps): JSX.Element {
    const { actionState } = props;

    const classes = useStyles();
    const translate = useTranslate();

    return (
        // @JAY TODO: Since the Runner is now using Kortex-Sequencer's condition,
        //            Some of the actionState properties are no longer updated during the execution.
        //            Kortex-Sequencer's condition does not have a state.
        <div className={classes.outputContainer} id="conditionPlayerId">
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.conditionPlayer.condition")}
            </Typography>
            <Typography className={classes.outputText} id="conditionPlayerConditionId" variant="h3">
                {actionState.condition}
            </Typography>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.conditionPlayer.substitution")}
            </Typography>
            <Typography className={classes.outputText} id="conditionPlayerSubstitutionId" variant="h3">
                {actionState.interpolatedCondition}
            </Typography>
            <Typography className={classes.outputTitle} variant="h1">
                {translate("player.conditionPlayer.result")}
            </Typography>
            <Typography className={classes.outputText} id="conditionPlayerResultId" variant="h3">
                {/*actionState.result.toString()*/}
            </Typography>
        </div>
    );
}
