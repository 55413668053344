export const userRole = {
    rpc: {
        getOne: {
            funcId: 2100,
            funcName: "userRoleOne",
        },
        getAll: {
            funcId: 2101,
            funcName: "userRoleAll",
        },
        insert: {
            funcId: 2102,
            funcName: "userRoleInsert",
        },
        update: {
            funcId: 2103,
            funcName: "userRoleUpdate",
        },
        delete: {
            funcId: 2104,
            funcName: "userRoleDelete",
        },
        inserted: {
            funcId: 2105,
            funcName: "userRoleInserted",
        },
        updated: {
            funcId: 2106,
            funcName: "userRoleUpdated",
        },
        deleted: {
            funcId: 2107,
            funcName: "userRoleDeleted",
        },
    },
    serviceId: 21,
    serviceVersion: 1,
};
