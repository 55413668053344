import { IUserTraining, IUserTrainingFilters } from "@kortex/aos-common";

import { BareAction, ComplexAction } from "../app.types";

export interface UserTrainingState {
    trainingFilters: IUserTrainingFilters;
    trainings: IUserTraining[];
}

export enum UserTrainingActionType {
    CLEAR_USER_TRAININGS = "@@USER_TRAINING/CLEAR_USER_TRAININGS",
    INSERT_USER_TRAININGS = "@@USER_TRAINING/INSERT_TRAININGS",
    SET_TRAINING_FILTERS = "@@USER_TRAINING/SET_TRAINING_FILTERS",
    SET_USER_TRAININGS = "@@USER_TRAINING/SET_USER_TRAININGS",
    UPDATE_USER_TRAININGS = "@@USER_TRAINING/UPDATE_USER_TRAININGS",
}

export type UserTrainingClear = BareAction<UserTrainingActionType.CLEAR_USER_TRAININGS>;
export type UserTrainingInsertedAction = ComplexAction<UserTrainingActionType.INSERT_USER_TRAININGS, IUserTraining[]>;
export type UserTrainingSetAction = ComplexAction<UserTrainingActionType.SET_USER_TRAININGS, IUserTraining[]>;
export type UserTrainingSetTrainingFiltersAction = ComplexAction<
    UserTrainingActionType.SET_TRAINING_FILTERS,
    Partial<IUserTrainingFilters>
>;
export type UserTrainingUpdatedAction = ComplexAction<UserTrainingActionType.UPDATE_USER_TRAININGS, IUserTraining[]>;

export type UserTrainingActions =
    | UserTrainingClear
    | UserTrainingInsertedAction
    | UserTrainingSetAction
    | UserTrainingSetTrainingFiltersAction
    | UserTrainingUpdatedAction;
