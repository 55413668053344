export const localeKeys = {
    action: {
        core: {
            condition: {
                playAlreadyInProgress: "KORTEX_SEQUENCER_CORE_CONDITION_PLAY_ALREADY_PLAYING",
                playFailsOnElse: "KORTEX_SEQUENCER_CORE_CONDITION_PLAY_FAILS_ON_ELSE",
                playInvalidCondition: "KORTEX_SEQUENCER_CORE_CONDITION_PLAY_INVALID_CONDITION",
                playVariableManagerError: "KORTEX_SEQUENCER_CORE_CONDITION_PLAY_VARIABLE_MANAGER_ERROR",
                stop: "KORTEX_SEQUENCER_CORE_CONDITION_STOP",
                validateInvalidFirstStatement: "KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_INVALID_FIRST_STATEMENT",
                validateInvalidLogicalOperator: "KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_INVALID_LOGICAL_OPERATOR",
                validateNoStatement: "KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_NO_STATEMENT",
                validateTooManyIfStatements: "KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_TOO_MANY_IF_STATEMENTS",
                validateUndefinedLeftOperandVariableIdentifier: "KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_UNDEFINED_LEFT_OPERAND_VARIABLE_IDENTIFIER",
                validateUndefinedRightOperandVariableIdentifier: "KORTEX_SEQUENCER_CORE_CONDITION_VALIDATE_UNDEFINED_RIGHT_OPERAND_VARIABLE_IDENTIFIER",
            },
            loop: {
                playAlreadyInProgress: "KORTEX_SEQUENCER_CORE_LOOP_PLAY_ALREADY_PLAYING",
                playIncremetedVariableNotNumber: "KORTEX_SEQUENCER_CORE_LOOP_PLAY_INCREMENTED_VARIABLE_NOT_NUMBER",
                playInvalidCondition: "KORTEX_SEQUENCER_CORE_LOOP_PLAY_INVALID_CONDITION",
                playVariableManagerError: "KORTEX_SEQUENCER_CORE_LOOP_PLAY_VARIABLE_MANAGER_ERROR",
                stop: "KORTEX_SEQUENCER_CORE_LOOP_STOP",
                validateInvalidIncrementerIncrement: "KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_INVALID_INCREMENTER_INCREMENT",
                validateInvalidIncrementerInitialValue: "KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_INVALID_INCREMENTER_INITIAL_VALUE",
                validateInvalidLogicalOperator: "KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_INVALID_LOGICAL_OPERATOR",
                validateUndefinedConditions: "KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_UNDEFINED_CONDITIONS",
                validateUndefinedLeftOperandVariableIdentifier: "KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_UNDEFINED_LEFT_OPERAND_VARIABLE_IDENTIFIER",
                validateUndefinedRightOperandVariableIdentifier: "KORTEX_SEQUENCER_CORE_LOOP_VALIDATE_UNDEFINED_RIGHT_OPERAND_VARIABLE_IDENTIFIER",
            },
            message: {
                validateInvalidMessage: "SEQUENCER_ACTION_CORE_MESSAGE_VALIDATE_INVALID_MESSAGE",
            },
            remote: {
                actionAlreadyPaused: "KORTEX_SEQUENCER_CORE_REMOTE_ACTION_ALREADY_PAUSED",
                actionAlreadyStarted: "KORTEX_SEQUENCER_CORE_REMOTE_ACTION_ALREADY_STARTED",
                actionFailed: "KORTEX_SEQUENCER_CORE_REMOTE_ACTION_FAILED",
                actionNotPaused: "KORTEX_SEQUENCER_CORE_REMOTE_ACTION_NOT_PAUSED",
                actionNotStarted: "KORTEX_SEQUENCER_CORE_REMOTE_ACTION_NOT_STARTED",
                clientUpdateStartNotRequestedByAction: "KORTEX_SEQUENCER_CORE_REMOTE_ACTION_CLIENT_UPDATE_START_NOT_REQUESTED_BY_ACTION",
                clientUpdateError: "KORTEX_SEQUENCER_CORE_REMOTE_CLIENT_UPDATE_ERROR",
                pauseError: "KORTEX_SEQUENCER_CORE_REMOTE_PAUSE_ERROR",
                pauseConfirmationTimeoutError: "KORTEX_SEQUENCER_CORE_REMOTE_PAUSE_CONFIRMATION_TIMEOUT_ERROR",
                playAlreadyInProgress: "KORTEX_SEQUENCER_CORE_REMOTE_PLAY_ALREADY_PLAYING",
                playError: "KORTEX_SEQUENCER_CORE_REMOTE_PLAY_ERROR",
                playCompletionTimeoutError: "KORTEX_SEQUENCER_CORE_REMOTE_PLAY_COMPLETION_TIMEOUT_ERROR",
                playConfirmationTimeoutError: "KORTEX_SEQUENCER_CORE_REMOTE_PLAY_CONFIRMATION_TIMEOUT_ERROR",
                remoteMethodPlayUndefined: "KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHOD_PLAY_UNDEFINED",
                remoteMethodStopUndefined: "KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHOD_STOP_UNDEFINED",
                remoteMethodUndefined: "KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHOD_UNDEFINED",
                remoteMethodsUndefined: "KORTEX_SEQUENCER_CORE_REMOTE_REMOTE_METHODS_UNDEFINED",
                resumeError: "KORTEX_SEQUENCER_CORE_REMOTE_RESUME_ERROR",
                resumeConfirmationTimeoutError: "KORTEX_SEQUENCER_CORE_REMOTE_RESUME_CONFIRMATION_TIMEOUT_ERROR",
                serviceNotReady: "KORTEX_SEQUENCER_CORE_REMOTE_SERVICE_NOT_READY",
                startActionError: "KORTEX_SEQUENCER_CORE_REMOTE_START_ACTION_ERROR",
                stop: "KORTEX_SEQUENCER_CORE_REMOTE_STOP",
                stopError: "KORTEX_SEQUENCER_CORE_REMOTE_STOP_ERROR",
                stopConfirmationTimeoutError: "KORTEX_SEQUENCER_CORE_REMOTE_STOP_CONFIRMATION_TIMEOUT_ERROR",
            },
            set: {
                playAlreadyInProgress: "KORTEX_SEQUENCER_CORE_SET_PLAY_ALREADY_PLAYING",
                playInvalidValueType: "KORTEX_SEQUENCER_CORE_SET_PLAY_INVALID_VALUE_TYPE",
                playVariableManagerSetVariableError: "KORTEX_SEQUENCER_CORE_SET_PLAY_VARIABLE_MANAGER_SET_VARIABLE_ERROR",
                stop: "KORTEX_SEQUENCER_CORE_SET_STOP",
                validateInvalidValueType: "KORTEX_SEQUENCER_CORE_SET_INVALID_VALUE_TYPE",
                validateUndefinedVariableIdentifier: "KORTEX_SEQUENCER_CORE_SET_UNDEFINED_VARIABLE_IDENTIFIER",
            },
            subProgram: {
                playInputUndefined: "KORTEX_SEQUENCER_CORE_SUB_PROGRAM_PLAY_INPUT_UNDEFINED",
                playOutputUndefined: "KORTEX_SEQUENCER_CORE_SUB_PROGRAM_PLAY_OUTPUT_UNDEFINED",
                stop: "KORTEX_SEQUENCER_CORE_SUB_PROGRAM_STOP",
                validateInputInvalidParentVariableIdentifier: "KORTEX_SEQUENCER_CORE_SUB_PROGRAM_VALIDATE_INPUT_INVALID_PARENT_VARIABLE_IDENTIFIER",
                validateOutputInvalidParentVariableIdentifier: "KORTEX_SEQUENCER_CORE_SUB_PROGRAM_VALIDATE_OUTPUT_INVALID_PARENT_VARIABLE_IDENTIFIER",
            },
            waitCondition: {
                playAlreadyInProgress: "KORTEX_SEQUENCER_CORE_WAIT_CONDITION_PLAY_ALREADY_PLAYING",
                playInvalidCondition: "KORTEX_SEQUENCER_CORE_WAIT_CONDITION_PLAY_INVALID_CONDITION",
                playVariableManagerError: "KORTEX_SEQUENCER_CORE_WAIT_CONDITION_PLAY_VARIABLE_MANAGER_ERROR",
                stop: "KORTEX_SEQUENCER_CORE_WAIT_CONDITION_STOP",
                validateInvalidLogicalOperator: "KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_INVALID_LOGICAL_OPERATOR",
                validateUndefinedConditions: "KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_UNDEFINED_CONDITIONS",
                validateUndefinedLeftOperandVariableIdentifier: "KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_UNDEFINED_LEFT_OPERAND_VARIABLE_IDENTIFIER",
                validateUndefinedRightOperandVariableIdentifier: "KORTEX_SEQUENCER_CORE_WAIT_CONDITION_VALIDATE_UNDEFINED_RIGHT_OPERAND_VARIABLE_IDENTIFIER",
            },
            waitTime: {
                playAlreadyInProgress: "KORTEX_SEQUENCER_CORE_WAIT_TIME_PLAY_ALREADY_PLAYING",
                stop: "KORTEX_SEQUENCER_CORE_WAIT_TIME_STOP",
                validateInvalidMilliseconds: "KORTEX_SEQUENCER_CORE_WAIT_TIME_VALIDATE_INVALID_MS",
            },
        },
        coreActionError: "KORTEX_SEQUENCER_ACTION_CORE_ACTION_ERROR",
        hookError: "KORTEX_SEQUENCER_ACTION_HOOK_ERROR",
        hookErrorOnFail: "KORTEX_SEQUENCER_ACTION_HOOK_ON_FAILL_ERROR",
        invalidType: "KORTEX_SEQUENCER_ACTION_INVALID_TYPE",
        pauseNotDefined: "KORTEX_SEQUENCER_ACTION_PAUSE_NOT_DEFINED",
        nonRemoteActionReceivingRemoteEvent: "KORTEX_SEQUENCER_ACTION_NON_REMOTE_ACTION_RECEIVING_REMOTE_EVENT",
        resumeNotDefined: "KORTEX_SEQUENCER_ACTION_RESUME_NOT_DEFINED",
        setActionOutputVariableManagerError: "KORTEX_SEQUENCER_ACTION_SET_ACTION_OUTPUT_VARIABLE_MANAGER_ERROR",
        setStateNotDefined: "KORTEX_SEQUENCER_ACTION_SET_STATE_NOT_DEFINED",
        stop: "KORTEX_SEQUENCER_ACTION_STOP",
    },
    actionGroup: {
        hookError: "KORTEX_SEQUENCER_ACTION_GROUP_HOOK_ACTION_ERROR",
        hookErrorOnFail: "KORTEX_SEQUENCER_ACTION_GROUP_HOOK_ON_FAILL_ERROR",
        noAction: "KORTEX_SEQUENCER_ACTION_GROUP_NO_ACTION",
        flowError: {
            clientUpdate: {
                notFailed: "KORTEX_SEQUENCER_ACTION_GROUP_FLOW_ERROR_CLIENT_UPDATE_ACTION_GROUP_NOT_FAILED",
                noWaitingAction: "KORTEX_SEQUENCER_ACTION_GROUP_FLOW_ERROR_CLIENT_UPDATE_NO_WAITING_ACTION",
                retryCurrentActionNotFailed: "KORTEX_SEQUENCER_ACTION_GROUP_FLOW_ERROR_CLIENT_UPDATE_RETRY_CURRENT_ACTION_NOT_FAILED",
            },
        },
        stop: "KORTEX_SEQUENCER_ACTION_GROUP_STOP",
        undefinedSubProgram: "KORTEX_SEQUENCER_ACTION_GROUP_UNDEFINED_SUB_PROGRAM",
    },
    sequencer: {
        actionGroupAlreadyAdded: "KORTEX_SEQUENCER_SEQUENCER_ACTION_GROUP_ALREADY_ADDED",
        branchActionGroupNotFound: "KORTEX_SEQUENCER_SEQUENCER_BRANCH_ACTION_GROUP_NOT_FOUND",
        currentActionGroupNotDefined: "KORTEX_SEQUENCER_SEQUENCER_CURRENT_ACTION_GROUP_NOT_DEFINED",
        flowError: {
            clientUpdate: {
                noCurrentActionGroup: "KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_CLIENT_UPDATE_NO_CURRENT_ACTION_GROUP",
                notPlaying: "KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_CLIENT_UPDATE_NOT_PLAYING",
                retryNotFailedAction: "KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_CLIENT_UPDATE_RETRY_NOT_FAILED_ACTION",
            },
            hook: {
                alreadyInProgress: "KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_HOOK_ACTION_REQUEST_ALREADY_IN_PROGRESS",
            },
            pause: {
                notPausable: "KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_PAUSE_NOT_PAUSABLE",
                notPlaying: "KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_PAUSE_NOT_PLAYING",
            },
            play: {
                notIdle: "KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_PLAY_NOT_IDLE",
            },
            reset: {
                sequencerStillActive: "KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_RESET_SEQUENCER_STILL_ACTIVE",
            },
            resume: {
                notPaused: "KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_RESUME_NOT_PAUSED",
            },
            stop: {
                notPlayingOrPaused: "KORTEX_SEQUENCER_SEQUENCER_FLOW_ERROR_STOP_NOT_PLAYING_OR_PAUSED",
            },
        },
        getVariableError: "KORTEX_SEQUENCER_SEQUENCER_GET_VARIABLE_ERROR",
        historyActionGroupNotFound: "KORTEX_SEQUENCER_SEQUENCER_HISTORY_ACTION_NOT_FOUND",
        hookError: "KORTEX_SEQUENCER_SEQUENCER_HOOK_ERROR",
        hookErrorOnFail: "KORTEX_SEQUENCER_SEQUENCER_HOOK_ERROR_ON_FAIL",
        hookErrorOnFailUndefined: "KORTEX_SEQUENCER_SEQUENCER_HOOK_ERROR_ON_FAIL_UNDEFINED",
        inputActionGroupNotFound: "KORTEX_SEQUENCER_SEQUENCER_INPUT_ACTION_GROUP_NOT_FOUND",
        nextActionGroupNotFound: "KORTEX_SEQUENCER_SEQUENCER_NEXT_ACTION_GROUP_NOT_FOUND",
        setVariableError: "KORTEX_SEQUENCER_SEQUENCER_SET_VARIABLE_ERROR",
        undefinedSubProgram: "KORTEX_SEQUENCER_SEQUENCER_UNDEFINED_SUB_PROGRAM",
    },
    subProgramManager: {
        inputActionGroupNotFound: "KORTEX_SEQUENCER_SUB_PROGRAM_INPUT_ACTION_GROUP_NOT_FOUND",
    },
    utils: {
        interpolationError: "KORTEX_SEQUENCER_UTILS_INTERPOLATION_ERROR",
        interpolationUnknownVariableError: "KORTEX_SEQUENCER_UTILS_INTERPOLATE_UNKNOWN_VARIABLE_ERROR",
    },
    variableManager: {
        getAllError: "KORTEX_SEQUENCER_VARIABLE_MANAGER_GET_ALL_ERROR",
        invalidIdentifier: "KORTEX_SEQUENCER_VARIABLE_MANAGER_INVALID_IDENTIFIER",
        setInvalidValueType: "KORTEX_SEQUENCER_VARIABLE_MANAGER_SET_INVALID_VALUE_TYPE",
    },
};
