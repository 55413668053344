import { EventEmitter } from "events";

import { IRouterClient } from "@kortex/aos-api-client/definitions/client/IRouterClient";
import {
    AOSClientServiceNotificationWrapped,
    AOSClientServiceRequestWrapped,
    UncategorizedGetResultSettingItemReq,
    UncategorizedGetResultSettingItemRes,
    UncategorizedGetResultSettingMetadataReq,
    UncategorizedGetResultSettingMetadataRes,
    UncategorizedPatchReq,
    UncategorizedPatchRes,
    UncategorizedResultSettingItemUpsertedNotif,
    UncategorizedResultSettingMetadataUpsertedNotif,
    UncategorizedUpsertResultSettingItemReq,
    UncategorizedUpsertResultSettingItemRes,
    UncategorizedUpsertResultSettingMetadataReq,
    UncategorizedUpsertResultSettingMetadataRes,
} from "@kortex/aos-common";

import { AOSClientServiceWrapperUI } from "../helpers";

import { getResultSettingItem } from "./getResultSettingItem";
import { patch } from "./patch";
import { upsertResultSettingItem } from "./upsertResultSettingItem";
import { getResultSettingMetadata } from "./getResultSettingMetadata";
import { upsertResultSettingMetadata } from "./upsertResultSettingMetadata";
import { resultSettingItemUpserted } from "./resultSettingItemUpserted";
import { resultSettingMetadataUpserted } from "./resultSettingMetadataUpserted";

interface IAOSClientServiceWrappedUncategorized {
    getResultSettingItem: AOSClientServiceRequestWrapped<UncategorizedGetResultSettingItemReq, UncategorizedGetResultSettingItemRes>;
    getResultSettingMetadata: AOSClientServiceRequestWrapped<
        UncategorizedGetResultSettingMetadataReq,
        UncategorizedGetResultSettingMetadataRes
    >;
    patch: AOSClientServiceRequestWrapped<UncategorizedPatchReq, UncategorizedPatchRes>;
    onUpsertedResultSettingItem: AOSClientServiceNotificationWrapped<UncategorizedResultSettingItemUpsertedNotif>;
    onUpsertedResultSettingMetadata: AOSClientServiceNotificationWrapped<UncategorizedResultSettingMetadataUpsertedNotif>;
    upsertResultSettingItem: AOSClientServiceRequestWrapped<
        UncategorizedUpsertResultSettingItemReq,
        UncategorizedUpsertResultSettingItemRes
    >;
    upsertResultSettingMetadata: AOSClientServiceRequestWrapped<
        UncategorizedUpsertResultSettingMetadataReq,
        UncategorizedUpsertResultSettingMetadataRes
    >;
}

type AOSClientServiceWrappedUncategorized = IAOSClientServiceWrappedUncategorized;

// ADD_API_CALL Uncategorized
export function generateUncategorisedServiceClient(router: IRouterClient, notifier: EventEmitter): AOSClientServiceWrappedUncategorized {
    return {
        getResultSettingItem: AOSClientServiceWrapperUI.request(getResultSettingItem(router)),
        getResultSettingMetadata: AOSClientServiceWrapperUI.request(getResultSettingMetadata(router)),
        onUpsertedResultSettingItem: AOSClientServiceWrapperUI.notification(resultSettingItemUpserted(router, notifier)),
        onUpsertedResultSettingMetadata: AOSClientServiceWrapperUI.notification(resultSettingMetadataUpserted(router, notifier)),
        patch: AOSClientServiceWrapperUI.request(patch(router)),
        upsertResultSettingItem: AOSClientServiceWrapperUI.request(upsertResultSettingItem(router)),
        upsertResultSettingMetadata: AOSClientServiceWrapperUI.request(upsertResultSettingMetadata(router)),
    };
}

generateUncategorisedServiceClient.serviceId = 90;
