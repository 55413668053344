import { KortexDialogConfirmation } from "@aos/react-components";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
    typography: {
        whiteSpace: "pre-line",
        wordWrap: "break-word",
    },
});

interface IOwnProps {
    communique: string;
    onClose: () => void;
    open: boolean;
}

function WorkInstructionsPlayerTrainingCommuniqueDialog(props: IOwnProps): JSX.Element {
    const { communique, onClose, open } = props;

    const classes = useStyles();
    const translate = useTranslate();

    useStopKeybindPropagation(open);

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            dialogProps={{
                id: "workInstructionsPlayerChangelogDialogId",
                maxWidth: "md",
            }}
            onConfirm={onClose}
            open={open}
            textLabels={{
                proceedButtonLabel: translate("general.close"),
                titleLabel: translate("action.workInstructions.player.trainingCommuniqueEditorTitle"),
            }}
        >
            <Typography className={classes.typography}>{communique}</Typography>
        </KortexDialogConfirmation>
    );
}

export default WorkInstructionsPlayerTrainingCommuniqueDialog;
