import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

function Square(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <rect id="icons-square" x="2" y="2" width="20" height="20" />
        </SvgIcon>
    );
}

export default Square;
