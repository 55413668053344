import { theme } from "@aos/react-components";
import { IProcessTrainings, ProcessTrainingRequirementEnum } from "@kortex/aos-common";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Divider, Tooltip, Typography, makeStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import React, { FC } from "react";

import { getTrainingRequirementLocale } from "../../../utilities";

const useStyles = makeStyles({
    divider: {
        margin: "5px 0",
    },
    iconInfo: {
        cursor: "pointer",
        color: theme.palette.info.main,
    },
    list: {
        margin: 0,
    },
    tooltip: {
        fontSize: "1.1rem",
        maxWidth: "99%",
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.primary[500],
        borderRadius: "5px",
        fontWeight: 400,
        whiteSpace: "pre-line",
    },
});

interface IOwnProps {
    classes?: Partial<ReturnType<typeof useStyles>>;
    processTrainings: IProcessTrainings;
}

const ProcessTrainingVersionInfoIcon: FC<IOwnProps> = (props) => {
    const { processTrainings } = props;

    const classes = useStyles(props);
    const translate = useTranslate();

    return (
        <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement="top"
            title={
                <>
                    <Typography>
                        {`${translate("training.levelOfChange")}: ${translate(
                            getTrainingRequirementLocale(processTrainings.originalProcessTrainingRequirement)
                        )}`}
                    </Typography>
                    {processTrainings.originalProcessTrainingRequirement === ProcessTrainingRequirementEnum.NONE ? (
                        <>
                            <Divider className={classes.divider} />
                            <Typography>{translate("training.previousProcessVerisonWithTrainingRequirement")}</Typography>
                            <ul className={classes.list}>
                                <li>
                                    <Typography>{`${translate("training.previousProcessVerisonWithTrainingRequirement.version")} ${
                                        processTrainings.version
                                    }`}</Typography>
                                </li>
                                <li>
                                    <Typography>
                                        {`${translate("training.levelOfChange")}: ${translate(
                                            getTrainingRequirementLocale(processTrainings.trainingRequirement)
                                        )}`}
                                    </Typography>
                                </li>
                            </ul>
                        </>
                    ) : null}
                </>
            }
        >
            <InfoIcon className={classes.iconInfo} id={"iconInfoId"} />
        </Tooltip>
    );
};

export default ProcessTrainingVersionInfoIcon;
