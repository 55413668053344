import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default function Output(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <path
                fill="currentColor"
                d="M2.63636364,3 L17.3636364,3 C18.2636364,3 19,3.90100111 19,5.00222469 L19,8.99666296 L17.3636364,8.99666296 L17.3636364,4.98220245 L2.63636364,4.98220245 L2.63636364,19.0278087 L17.3636364,19.0278087 L17.3636364,15.003337 L19,15.003337 L19,19.0177976 C19,20.1190211 18.2636364,21 17.3636364,21 L2.63636364,21 C1.73636364,21 1,20.1190211 1,19.0177976 L1,5.00222469 C1,3.89098999 1.73636364,3 2.63636364,3 Z M20.4285714,15 L23,12 L20.4285714,9 L20.4285714,11.25 L14,11.25 L14,12.75 L20.4285714,12.75 L20.4285714,15 Z"
                id="icons-output"
            />
        </SvgIcon>
    );
}
