export const userGroup = {
    rpc: {
        getOne: {
            funcId: 2200,
            funcName: "userGroupOne",
        },
        getAll: {
            funcId: 2201,
            funcName: "userGroupAll",
        },
        insert: {
            funcId: 2202,
            funcName: "userGroupInsert",
        },
        update: {
            funcId: 2203,
            funcName: "userGroupUpdate",
        },
        inserted: {
            funcId: 2204,
            funcName: "userGroupInserted",
        },
        updated: {
            funcId: 2205,
            funcName: "userGroupUpdated",
        },
    },
    serviceId: 22,
    serviceVersion: 1,
};
