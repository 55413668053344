import { ProcessActionSettingsAllRes, UnwrapAOSPayload } from "@kortex/aos-common";

import { emptyObject } from "../../utilitites/kortex-client/api/constants";
import { APIPayload } from "../../utilitites/kortex-client/client";
import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import { processActionSettingsUpdatedAction, setProcessActionSettingsAction } from "./process-actions";

// thunks

/**
 * Get all process action settings
 *
 * @param {IStandardThunkOptions} [options] - options
 */
export function processActionSettingsGetAll(options?: IStandardThunkOptions): StandardThunk<UnwrapAOSPayload<ProcessActionSettingsAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async function (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<ProcessActionSettingsAllRes>> {
        try {
            if (!skipLookup) {
                if (fetchedOnce.was(processActionSettingsGetAll)) {
                    return getState().process.settings;
                }
            }

            return api.services.processActionSettings
                .getAll(emptyObject)()
                .then((processActionSettings) => {
                    if (!skipDispatch) {
                        fetchedOnce.seal(processActionSettingsGetAll);

                        dispatch(setProcessActionSettingsAction([...processActionSettings]));
                    }

                    return processActionSettings;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
            return [];
        }
    };
}

/**
 * Delete a process action
 *
 * @param {APIPayload<"processActionSettings", "update">} processActionSettings - process action settings to update
 */
export function processActionSettingsUpdate(processActionSettings: APIPayload<"processActionSettings", "update">): StandardThunk<void> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<void> => {
        try {
            await api.services.processActionSettings
                .update(processActionSettings)()
                .then((updatedProcessActionSettings) => {
                    dispatch(processActionSettingsUpdatedAction(updatedProcessActionSettings));
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }
    };
}
