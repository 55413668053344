import { KortexDialogConfirmation, theme } from "@aos/react-components";
import { IUserTrainingTrainingsHistory, TreeNodeId } from "@kortex/aos-common";
import { useClientService } from "@kortex/aos-ui/hooks/useClientService";
import { useTranslate } from "@kortex/aos-ui/hooks/useTranslate";
import { Backdrop, CircularProgress, List, ListSubheader, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { useUserTrainingContext } from "../context";

import UserTrainingHistoryCard from "./UserTrainingHistoryCard";
import UserTrainingHistoryCardHeader from "./UserTrainingHistoryCardHeader";

const useStyles = makeStyles({
    backdrop: {
        color: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 2,
    },
    dialogContent: {
        height: "60vh",
        overflowY: "auto",
        width: "100%",
    },
});

interface IOwnProps {
    open: boolean;
    treeNodeId: TreeNodeId;
}

function UserTrainingHistoryDialog(props: IOwnProps): JSX.Element {
    const { open, treeNodeId } = props;

    const classes = useStyles();
    const translate = useTranslate();
    const userTrainingContext = useUserTrainingContext();
    const getTrainingHistoryByUserIdAndTreeNodeId = useClientService("userTraining", "getTrainingHistoryByUserIdAndTreeNodeId");

    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [trainingsHistory, setTrainingsHistory] = useState<IUserTrainingTrainingsHistory[]>([]);

    const processTrainingName = trainingsHistory.length > 0 ? trainingsHistory[0].treeNode.label : translate("training.trainingHistory");

    useEffect((): void => {
        if (open) {
            setIsDataLoading(true);
            getTrainingHistoryByUserIdAndTreeNodeId({
                userId: userTrainingContext.trainingFilters.userId,
                treeNodeId,
            }).then((result = []) => {
                setTrainingsHistory([...result]);
                setIsDataLoading(false);
            });
        } else {
            setTrainingsHistory([]);
        }
    }, [open]);

    /**
     * handles the cancel of the dialog
     */
    const handleCloseDialog = (): void => {
        userTrainingContext.setHistoryTreeNodeIdSelected(0);
        userTrainingContext.setOpenHistoryDialog(false);
        setIsDataLoading(false);
    };

    return (
        <div id="userTrainingHistoryDialogId">
            <Backdrop className={classes.backdrop} open={isDataLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <KortexDialogConfirmation
                dialogProps={{
                    fullWidth: true,
                    maxWidth: "xl",
                    onBackdropClick: handleCloseDialog,
                    keepMounted: false, // ensures the children are mounted on open and dismounted on close... useful for useForeground handling
                }}
                onCancel={handleCloseDialog}
                closeOnEscape={true}
                open={open}
                textLabels={{
                    cancelButtonLabel: translate("general.close"),
                    titleLabel: processTrainingName,
                }}
            >
                <div className={classes.dialogContent} id="trainingHistoryDialogContentId">
                    <List
                        disablePadding={true}
                        /* ----------------------------------------------------------------------------------------------- */
                        /* HEADER ---------------------------------------------------------------------------------------- */
                        /* ----------------------------------------------------------------------------------------------- */
                        subheader={
                            <ListSubheader disableGutters={true}>
                                <UserTrainingHistoryCardHeader />
                            </ListSubheader>
                        }
                    >
                        {/* ----------------------------------------------------------------------------------------------- */}
                        {/* TRAININGS ROWS -------------------------------------------------------------------------------- */}
                        {/* ----------------------------------------------------------------------------------------------- */}
                        {trainingsHistory
                            .sort((a, b) => {
                                const dateA = a.training.createdOn!;
                                const dateB = b.training.createdOn!;

                                // Ordered from most recent to most ancient
                                if (dateA < dateB) return 1;
                                if (dateA > dateB) return -1;

                                return 0;
                            })
                            .map((trainingHistory, index) => (
                                <div key={`trainingHistory${index}`}>
                                    <UserTrainingHistoryCard index={index} trainingHistory={trainingHistory} />
                                </div>
                            ))}
                    </List>
                </div>
            </KortexDialogConfirmation>
        </div>
    );
}

export default UserTrainingHistoryDialog;
