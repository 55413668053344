import { IWoBomComplete } from "@kortex/aos-common";
import React, { createContext, FC, PropsWithChildren, useContext } from "react";

interface IWoBomTableContext {
    woBom: IWoBomComplete["woBom"];
    workInstructionsItems: IWoBomComplete["workInstructionsItems"];
}

const WoBomTableContext = createContext<IWoBomTableContext>({
    woBom: {
        items: {},
        jobRefId: "",
        trackingId: "",
    },
    workInstructionsItems: {},
});

type WoBomTableProviderProps = PropsWithChildren<{
    woBom: IWoBomComplete;
}>;

export const WoBomTableProvider: FC<WoBomTableProviderProps> = (props) => {
    return (
        <WoBomTableContext.Provider
            value={{
                woBom: props.woBom.woBom,
                workInstructionsItems: props.woBom.workInstructionsItems,
            }}
        >
            {props.children}
        </WoBomTableContext.Provider>
    );
};

export const useWoBomTableContext = (): IWoBomTableContext => useContext<IWoBomTableContext>(WoBomTableContext);
