import { theme } from "@aos/react-components";
import { usePlayerContext } from "@kortex/aos-ui/components/core/ProcessPlayer/context";
import { isBomItemNonTraceable, isBomItemTraceable } from "@kortex/aos-ui/components/pages/bom/woBomTable/utils";
import { TableCell, TableRow, makeStyles } from "@material-ui/core";
import React, { FC } from "react";

import { PlayerControlsBomItemRow } from "./row";

const useStyles = makeStyles({
    itemEven: {
        backgroundColor: theme.palette.grey[100],
    },
    itemOdd: {
        backgroundColor: theme.palette.grey[200],
    },
    tableCellMain: {
        padding: 0,
    },
});

const PlayerControlsBomItems: FC = () => {
    const classes = useStyles();
    const { bomStepItems } = usePlayerContext();

    return (
        <TableRow>
            <TableCell className={classes.tableCellMain} colSpan={4} id="itemsStepId">
                {Object.values(bomStepItems)
                    .sort((a, b) => {
                        if (isBomItemNonTraceable(a) && isBomItemTraceable(b)) {
                            return 1;
                        } else if (isBomItemTraceable(a) && isBomItemNonTraceable(b)) {
                            return -1;
                        } else {
                            return 0;
                        }
                    })
                    .map((item, index) => (
                        <PlayerControlsBomItemRow
                            classes={{
                                root: index % 2 === 0 ? classes.itemEven : classes.itemOdd,
                            }}
                            key={`PlayerControlsBomItem${index}`}
                            item={item}
                            index={index}
                        />
                    ))}
            </TableCell>
        </TableRow>
    );
};

export default PlayerControlsBomItems;
