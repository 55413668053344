"use strict";
// interfaces
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventEmitter = exports.generateEventEmitterListenerRegistration = void 0;
// helpers
// tslint:disable-next-line:max-line-length
const generateEventEmitterListenerRegistration = (ee, ev, cb, options) => {
    options = { ...{ once: false, prepend: false }, ...options };
    const registration = {
        unregister: () => (ee.removeListener(ev, cb), undefined),
    };
    if (options.prepend) {
        options.once ? ee.prependOnceListener(ev, cb) : ee.prependListener(ev, cb);
        return registration;
    }
    options.once ? ee.once(ev, cb) : ee.on(ev, cb);
    return registration;
};
exports.generateEventEmitterListenerRegistration = generateEventEmitterListenerRegistration;
const getListenerCallback = (listener) => listener.cb;
// definition
class EventEmitter {
    constructor() {
        this._listeners = new Map();
    }
    addListener(ev, cb) {
        return this._on(ev, cb, false, false);
    }
    emit(ev, ...params) {
        const listeners = this._listeners.get(ev);
        if (listeners && listeners.constructor === Array && listeners.length) {
            for (const [index, listener] of [...listeners].entries()) {
                if (listener.once) {
                    listeners.splice(index, 1);
                }
                getListenerCallback(listener)(...params);
            }
            if (!listeners.length) {
                this.removeAllListeners(ev);
            }
            return true;
        }
        return false;
    }
    eventNames() {
        return [...this._listeners.keys()];
    }
    listeners(ev) {
        return [...(this._listeners.get(ev) || [])].map(getListenerCallback);
    }
    listenerCount(ev) {
        return (this._listeners.get(ev) || []).length;
    }
    on(ev, cb) {
        return this.addListener(ev, cb);
    }
    once(ev, cb) {
        return this._on(ev, cb, true, false);
    }
    off(ev, cb) {
        return this.removeListener(ev, cb);
    }
    prependListener(ev, cb) {
        return this._on(ev, cb, false, true);
    }
    prependOnceListener(ev, cb) {
        return this._on(ev, cb, true, true);
    }
    removeAllListeners(ev) {
        ev ? this._listeners.delete(ev) : this._listeners.clear();
        return this;
    }
    removeListener(ev, cb) {
        const listeners = this._listeners.get(ev);
        if (listeners) {
            listeners.find((listener, listenerIndex) => getListenerCallback(listener) === cb ? Boolean(listeners.splice(listenerIndex, 1).length) : false);
            if (!listeners.length) {
                this.removeAllListeners(ev);
            }
        }
        return this;
    }
    _on(ev, cb, once, prepend) {
        if (!this._listeners.has(ev)) {
            this._listeners.set(ev, []);
        }
        // @ts-ignore
        prepend ? this._listeners.get(ev).unshift({ cb, once }) : this._listeners.get(ev).push({ cb, once });
        return this;
    }
}
exports.EventEmitter = EventEmitter;
