import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

function PasswordReset(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon {...props}>
            <g id="Icons-/-Navigation-/-Menu-Items-/-Reset-Password-No-Labels" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g fill="currentColor" id="Icons-/-Navigation-/-Reset-Password">
                    <g>
                        <path
                            d="M14.45,10.12 L14.45,11.1 C14.975,11.1 15.5,11.64 15.5,12.27 L15.5,15.42 C15.5,15.96 14.975,16.5 14.3625,16.5 L9.55,16.5 C9.025,16.5 8.5,15.96 8.5,15.33 L8.5,12.18 C8.5,11.64 9.025,11.1 9.55,11.1 L9.55,9.75 C9.55,8.49 10.775,7.5 12,7.5 C13.225,7.5 14.45,8.49 14.45,9.75 L14.45,9.88203259 L16.9587834,7.3 C14.2287834,4.6 9.80878343,4.5 7.07878343,7.2 C5.76876567,8.49194884 5.03126725,10.2550874 5.03126725,12.095 C5.03126725,13.9349126 5.76876567,15.6980512 7.07878343,16.99 C9.80878343,19.7 14.2287834,19.7 16.9587834,16.99 C18.3187834,15.65 18.9987834,14.08 18.9987834,12.1 L20.9987834,12.1 C20.9987834,14.08 20.1187834,16.65 18.3587834,18.39 C14.8487834,21.87 9.14878343,21.87 5.63878343,18.39 C2.13878343,14.92 2.10878343,9.28 5.61878343,5.81 C9.12878343,2.34 14.7587834,2.34 18.2687834,5.81 L20.9987834,3 L20.9987834,10.12 L14.45,10.12 Z M12,15 C12.5522847,15 13,14.5522847 13,14 C13,13.4477153 12.5522847,13 12,13 C11.4477153,13 11,13.4477153 11,14 C11,14.5522847 11.4477153,15 12,15 Z M13.5,11.5 L10.5,11.5 L10.5,9.89285714 C10.5,9.03571429 11.2,8.5 12,8.5 C12.8,8.5 13.5,9.03571429 13.5,9.89285714 L13.5,11.5 Z"
                            id="icons-reset-password"
                        ></path>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default PasswordReset;
