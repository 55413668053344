// --------------------------------------------------------------------------------------------------
// PROCESS ACTION TYPE UTILITIES
// --------------------------------------------------------------------------------------------------
/**
 * Check if the action is a condition
 *
 * @param {ProcessAction} processAction - process action
 */
export function isCondition(processAction) {
    return processAction.type === "core-condition";
}
/**
 * Check if the action is a connector
 *
 * @param {ProcessAction} processAction - process action
 */
export function isConnector(processAction) {
    return processAction.type === "core-connector";
}
/**
 * Check if the action is a data store
 *
 * @param {ProcessAction} processAction - process action
 */
export function isDataStore(processAction) {
    return processAction.type === "core-data-store";
}
/**
 * Check if the action is a input
 *
 * @param {ProcessAction} processAction - process action
 */
export function isInput(processAction) {
    return processAction.type === "core-input";
}
/**
 * Check if the action is a loop
 *
 * @param {ProcessAction} processAction - process action
 */
export function isLoop(processAction) {
    return processAction.type === "core-loop";
}
/**
 * Check if the action is a math
 *
 * @param {ProcessAction} processAction - process action
 */
export function isMath(processAction) {
    return processAction.type === "core-math";
}
/**
 * Check if the action is a message
 *
 * @param {ProcessAction} processAction - process action
 */
export function isMessage(processAction) {
    return processAction.type === "core-message";
}
/**
 * Check if the action is a failure ticket fork
 *
 * @param {ProcessAction} processAction - process action
 */
export function isFailureTicketFork(processAction) {
    return processAction.type === "core-failure-ticket-create";
}
/**
 * Check if the action is a output
 *
 * @param {ProcessAction} processAction - process action
 */
export function isOutput(processAction) {
    return processAction.type === "core-output";
}
/**
 * Check if the action is a parser
 *
 * @param {ProcessAction} processAction - process action
 */
export function isParser(processAction) {
    return processAction.type === "core-parser";
}
/**
 * Check if the action is a routing process
 *
 * @param {ProcessAction} processAction - process action
 */
export function isRoutingProcess(processAction) {
    return processAction.type === "core-routing-process";
}
/**
 * Check if the action is a routing fail
 *
 * @param {ProcessAction} processAction - process action
 */
export function isRoutingFail(processAction) {
    return processAction.type === "core-routing-fail";
}
/**
 * Check if the action is a set
 *
 * @param {ProcessAction} processAction - process action
 */
export function isSet(processAction) {
    return processAction.type === "core-set";
}
/**
 * Check if the action is a stop process
 *
 * @param {ProcessAction} processAction - process action
 */
export function isStopProcess(processAction) {
    return processAction.type === "core-stop-process";
}
/**
 * Check if the action is a time
 *
 * @param {ProcessAction[]} processAction - process action
 */
export function isTime(processAction) {
    return processAction.type === "core-time";
}
/**
 * Check if the action is a work instruction
 *
 * @param {ProcessAction} processAction - process action
 */
export function isWorkInstructions(processAction) {
    return processAction.type === "core-work-instructions";
}
// --------------------------------------------------------------------------------------------------
// PROCESS ACTION STEP TYPE UTILITIES
// --------------------------------------------------------------------------------------------------
/**
 * Check if the step is a json parser
 *
 * @param {IParserConfig<TParserType, TParserExtendedConfig>} step - parser step
 */
export function isParserJSON(step) {
    return step.type === "json";
}
/**
 * Check if the step is a regex extraction parser
 *
 * @param {IParserConfig<TParserType, TParserExtendedConfig>} step - parser step
 */
export function isParserRegexExtract(step) {
    return step.type === "regex" && step.parser?.action === "extract";
}
/**
 * Check if the step is a regex substitution parser
 *
 * @param {IParserConfig<TParserType, TParserExtendedConfig>} step - parser step
 */
export function isParserRegexSubstitution(step) {
    return step.type === "regex" && step.parser?.action === "substitution";
}
/**
 * Check if the step is a regex validation parser
 *
 * @param {IParserConfig<TParserType, TParserExtendedConfig>} step - parser step
 */
export function isParserRegexValidation(step) {
    return step.type === "regex" && step.parser?.action === "validation";
}
/**
 * Checks that the type of the action is the equivalent of a sequencer core action
 */
export function isSequencerCoreAction(processAction) {
    return (processAction.type === "core-condition" ||
        processAction.type === "core-fail" ||
        processAction.type === "core-loop" ||
        processAction.type === "core-set");
}
