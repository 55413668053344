import { ActionTypeEnum } from "../enums";
import { InvalidCoreActionTypeError, } from "../interfaces";
import { localeKeys } from "../locales";
import { assertRequiredRemoteMethodsDefined, isActionTypeSubProgram, isActionTypeRemote, isActionTypeCoreAction, KortexSequencerDebugger, } from "../utilities";
import { SequencerActionConditionFactory } from "./condition";
import { SequencerActionFailFactory } from "./fail";
import { SequencerActionGoToFactory } from "./go-to";
import { SequencerActionInputFactory } from "./input";
import { SequencerActionLoopFactory } from "./loop";
import { SequencerActionMessageFactory } from "./message";
import { SequencerActionRemoteFactory } from "./remote";
import { SequencerActionSetFactory } from "./set";
import { SequencerActionSubProgramFactory } from "./sub-program";
import { SequencerActionThreadFactory } from "./thread";
import { SequencerActionTimerFactory } from "./timer";
import { SequencerActionWaitConditionFactory, SequencerActionWaitTimeFactory } from "./wait";
const debug = KortexSequencerDebugger.action;
const sequencerActionCoreMap = {
    [ActionTypeEnum.CONDITION]: SequencerActionConditionFactory,
    [ActionTypeEnum.FAIL]: SequencerActionFailFactory,
    [ActionTypeEnum.GO_TO]: SequencerActionGoToFactory,
    [ActionTypeEnum.INPUT]: SequencerActionInputFactory,
    [ActionTypeEnum.LOOP]: SequencerActionLoopFactory,
    [ActionTypeEnum.MESSAGE]: SequencerActionMessageFactory,
    [ActionTypeEnum.SET]: SequencerActionSetFactory,
    [ActionTypeEnum.SUB_PROGRAM]: SequencerActionSubProgramFactory,
    [ActionTypeEnum.THREAD]: SequencerActionThreadFactory,
    [ActionTypeEnum.TIMER]: SequencerActionTimerFactory,
    [ActionTypeEnum.WAIT_CONDITION]: SequencerActionWaitConditionFactory,
    [ActionTypeEnum.WAIT_TIME]: SequencerActionWaitTimeFactory,
};
export function getSequencerActionCore(type, index, variableManager, variableNamespace, remote, context) {
    if (!isActionTypeCoreAction(type)) {
        debug("core action map:error:%s is not a valid action type", type);
        throw new InvalidCoreActionTypeError(localeKeys.action.invalidType);
    }
    const actionType = type;
    if (isActionTypeRemote(actionType)) {
        assertRequiredRemoteMethodsDefined(remote);
        debug("core action map:remote action:remote methods defined");
        return SequencerActionRemoteFactory.create({ index, remote, variableManager, variableNamespace });
    }
    if (isActionTypeSubProgram(actionType)) {
        return SequencerActionSubProgramFactory.create({ context, index, variableManager, variableNamespace });
    }
    return sequencerActionCoreMap[actionType].create({ index, variableManager, variableNamespace });
}
