import { KortexDialogConfirmation } from "@aos/react-components";
import {
    IReworkJobProcessInsertReq,
    IReworkUiModel,
    ITreeNodeDbModel,
    JobProcessStatusEnum,
    ProcessApprovalStatusFilter,
    TreeNodeNodeTypeEnum,
} from "@kortex/aos-common";
import { useThunkDispatch } from "@kortex/aos-ui/hooks/useThunkDispatch";
import { insertReworkJobProcess } from "@kortex/aos-ui/redux/rework-manager/rework-job-process-thunks";
import { makeStyles } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";

import { useTranslate } from "../../../../../hooks/useTranslate";
import ProcessRepository from "../../../ProcessEditor/RepositoryManager/ProcessRepository";

const useStyles = makeStyles({
    detailContent: {
        display: "flex",
        flexDirection: "row",
        padding: "5px 0",
    },
    createNewJobField: {
        margin: "5px",
        width: "170px",
    },
    dialogRepoContent: {
        height: "60vh",
        width: "80vw",
        maxWidth: "1102px",
    },
});

export interface IAddReworkJobProcessDialogProps {
    repoDialogOpen: boolean;
    rework: IReworkUiModel;
    activeJobIdOfFailureTicket: number;
    filterTypeRepoDialog?: TreeNodeNodeTypeEnum;

    onRepoHandleCloseDialogClose: () => void;
}

export default function AddReworkJobProcessDialog(props: IAddReworkJobProcessDialogProps): JSX.Element {
    const { repoDialogOpen, rework, activeJobIdOfFailureTicket, filterTypeRepoDialog, onRepoHandleCloseDialogClose } = props;
    const classes = useStyles();
    const translate = useTranslate();
    const dispatch = useThunkDispatch();

    const [selectedProcessVersionId, setSelectedProcessVersionId] = useState<number[] | undefined>(undefined);

    /**
     * handle request to close the dialog
     */
    const handleClose = (): void => {
        onRepoHandleCloseDialogClose();
    };

    /**
     * Handle that is called when an option in the dialog process repos is changed... Keep value in a state until apply is called
     *
     */
    const handleRepoSelectionChanged = (processesOrRoutings: ITreeNodeDbModel[], versions?: number[]): void => {
        // param processesOrRoutings should be delete when ProcessRepositorySeletor will be create
        if (versions && versions.length > 0 && versions[0]) {
            setSelectedProcessVersionId(versions);
        }
    };

    /**
     * handles the rework scheduling
     */
    const handleRepoSelection = async (): Promise<void> => {
        if (selectedProcessVersionId) {
            // Insert the rework job process in the job
            const jobProcessesToBeAdded: IReworkJobProcessInsertReq[] = [];
            for (const [count, currentProcessVersionId] of selectedProcessVersionId.entries()) {
                jobProcessesToBeAdded.push({
                    jobId: activeJobIdOfFailureTicket,
                    processId: currentProcessVersionId,
                    status: JobProcessStatusEnum.READY,
                    reworkId: rework.reworkId,
                    qty: rework.quantity,
                    checkPrevious: count !== 0,
                });
            }
            await dispatch(insertReworkJobProcess(jobProcessesToBeAdded));
        }
        handleClose();
    };

    return (
        <KortexDialogConfirmation
            open={repoDialogOpen}
            dialogProps={{
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: handleClose,
            }}
            textLabels={{
                titleLabel: translate("scheduler.selectProcess"),
                cancelButtonLabel: translate("scheduler.cancel"),
                proceedButtonLabel: translate("scheduler.select"),
            }}
            onCancel={handleClose}
            onConfirm={handleRepoSelection}
            confirmDisabled={!Boolean(selectedProcessVersionId)}
            closeOnEscape={true}
        >
            <div className={classes.dialogRepoContent}>
                {repoDialogOpen && (
                    <ProcessRepository
                        multiItemSelection={true}
                        filterType={filterTypeRepoDialog}
                        onSelectionChanged={handleRepoSelectionChanged}
                        selectOnly={true}
                        showVersionVariant={ProcessApprovalStatusFilter.RELEASED}
                        handleSelectProcessConfirmation={handleRepoSelection}
                    />
                )}
            </div>
        </KortexDialogConfirmation>
    );
}
