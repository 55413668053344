export var EnumRunnerOnlineStatus;
(function (EnumRunnerOnlineStatus) {
    EnumRunnerOnlineStatus["ONLINE"] = "online";
    EnumRunnerOnlineStatus["OFFLINE"] = "offline";
})(EnumRunnerOnlineStatus || (EnumRunnerOnlineStatus = {}));
export var EnumRunnerPlayStatus;
(function (EnumRunnerPlayStatus) {
    EnumRunnerPlayStatus["IDLE"] = "idle";
    EnumRunnerPlayStatus["PLAYING"] = "playing";
})(EnumRunnerPlayStatus || (EnumRunnerPlayStatus = {}));
export var EnumRunnerType;
(function (EnumRunnerType) {
    EnumRunnerType["CLOUD"] = "cloud";
    EnumRunnerType["DEVICE"] = "device";
})(EnumRunnerType || (EnumRunnerType = {}));
export class RunnerState {
    constructor() {
        this._id = "";
        this.type = EnumRunnerType.CLOUD;
        this.hostName = "";
        this.osName = "";
        this.platform = "";
        this.arch = "";
        this.osRelease = "";
        this.ip = "";
        this.onlineStatus = EnumRunnerOnlineStatus.OFFLINE;
        this.playStatus = EnumRunnerPlayStatus.IDLE;
        this.lastOnlineDateTime = 0;
        this.lastPlayedProcessId = 0;
        this.lastPlayedProcessDateTime = 0;
        this.processPlayed = 0;
        this.usedByConnectionHandle = "";
        this.isReserved = false;
        this.reservedBy = "";
        this.dryRunMode = false;
        this.isTestRun = false;
    }
    toJSON() {
        return { ...this };
    }
}
