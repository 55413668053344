var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import { ClickAwayListener } from "@material-ui/core";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import { useKeyPressed } from "../hooks";
import { theme } from "../mui/theme";
var useStyles = makeStyles({
    speedDialFab: {
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    speedDialContainer: {
        alignItems: "center",
    },
    staticTooltipLabel: {
        width: "max-content",
    },
    fab: {
        width: "35px",
        height: "35px",
        boxShadow: "0px 4px 5px 0px",
        backgroundColor: theme.palette.grey[50],
    },
});
function KortexSpeedDial(props) {
    var classes = useStyles(props);
    var actions = props.actions, ariaLabel = props.ariaLabel, SpeedDialProps = props.SpeedDialProps, _a = props.tooltipOpen, tooltipOpen = _a === void 0 ? true : _a;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var escapePressed = useKeyPressed("Escape", !open);
    /**
     * Called when user click escape key when the menu is open... close the menu.
     */
    useEffect(function () {
        if (escapePressed) {
            setOpen(false);
        }
    }, [escapePressed]);
    /**
     * Closes the menu
     */
    var close = function () {
        setOpen(false);
    };
    /**
     * Called when clicking on the menu. Opens or closes it.
     */
    var handleClick = function (event) {
        setOpen(!open);
        if (SpeedDialProps && SpeedDialProps.onClick) {
            SpeedDialProps.onClick(event);
        }
    };
    /**
     * Called when a button is clicked. Executes the injected callback and closes the menu.
     *
     * @param {() => undefined} cb - Callback injected from props
     */
    var handleCallback = function (cb) {
        return function () {
            cb();
            close();
        };
    };
    return (React.createElement(ClickAwayListener, { onClickAway: close },
        React.createElement(SpeedDial, __assign({}, SpeedDialProps, { open: open, ariaLabel: ariaLabel, onClick: handleClick, icon: React.createElement(SpeedDialIcon, null), classes: __assign({ actions: classes.speedDialContainer, fab: classes.speedDialFab }, (SpeedDialProps && SpeedDialProps.classes)) }), open &&
            actions.map(function (action, index) {
                return (React.createElement(SpeedDialAction, { key: index, classes: __assign({ fab: classes.fab, staticTooltipLabel: classes.staticTooltipLabel }, action.classes), icon: action.icon, tooltipTitle: action.label, tooltipOpen: tooltipOpen, onClick: handleCallback(action.callback) }));
            }))));
}
export default KortexSpeedDial;
