import * as React from "react";
import { Typography, Checkbox } from "@material-ui/core";
import { secondaryPalette, primaryPalette } from "@aos/react-components";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useEffect } from "react";

import KortexSearchField from "../../../core/KortexSearchField";
import { useTranslate } from "../../../../hooks/useTranslate";

export interface IFilters {
    plainText: string;
    showArchived: boolean;
}

// Used to indicate which filters to display
export interface IEnabledFilters {
    plainText?: boolean;
    status?: boolean;
    planner?: boolean;
    group?: boolean;
    requestedOn?: boolean;
    plannedOn?: boolean;
    scheduledOn?: boolean;
    showArchived?: boolean;
}

export const defaultFilters: IFilters = {
    plainText: "",
    showArchived: false,
};

const defaultEnabledFilters: IEnabledFilters = {
    plainText: true,
    status: true,
    planner: true,
    group: true,
    requestedOn: true,
    plannedOn: true,
    scheduledOn: true,
    showArchived: true,
};

const useStyles = makeStyles({
    searchBox: {
        width: "100%",
    },
    optionsItem: {
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
    },
    filterLabel: {
        color: secondaryPalette[500],
        flex: 1,
    },
    filterInput: {
        flex: 2,
    },
    filterInputSelect: {
        padding: "5px",
    },
    filterDateInput: {
        flex: 1,
    },
    clearFilterLabel: {
        color: secondaryPalette[500],
        "&:hover": {
            color: primaryPalette[500],
        },
    },
});

interface IOwnProps {
    enabledFilters?: IEnabledFilters;
    onFiltersChange: (filters: IFilters) => void;
}

export default function RoleEditorSearchBar(props: IOwnProps): JSX.Element {
    const { enabledFilters = defaultEnabledFilters, onFiltersChange } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [filters, setFilters] = useState<IFilters>(defaultFilters);

    /**
     * When filters change, execute onChange cb
     */
    useEffect((): void => {
        onFiltersChange(filters);
    }, [filters]);

    /**
     * Called when the Show Archived button is toggled
     */
    const handleToggleArchived = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFilters({ ...filters, showArchived: e.target.checked });
    };

    /**
     * Called when filters change. Checks if filters equal their default value (plainText is excluded from check)
     */
    const isFilterActive = (): boolean => {
        let active = false;
        Object.keys(filters).forEach((key): void => {
            if (key !== "plainText" && filters[key] !== defaultFilters[key]) {
                active = true;
            }
        });
        return active;
    };

    /**
     * Called when clicking the Clear Filter label
     */
    const handleClearFilters = (): void => {
        setFilters(defaultFilters);
    };

    /**
     * Called when the text in the searchbar changes
     *
     * @param {string} val - new value
     */
    const handleSearchTextChange = (val: string): void => {
        setFilters({ ...filters, plainText: val });
    };

    return (
        <KortexSearchField className={classes.searchBox} onChange={handleSearchTextChange} isFilterActive={isFilterActive()}>
            <React.Fragment>
                {enabledFilters.showArchived && (
                    <div className={classes.optionsItem}>
                        <Typography className={classes.filterLabel}>{translate("scheduler.optionShowArchived")}</Typography>
                        <Checkbox checked={filters.showArchived} onChange={handleToggleArchived} />
                    </div>
                )}
                <div className={classes.optionsItem}>
                    <div className={classes.filterLabel} />
                    <Typography onClick={handleClearFilters} className={classes.clearFilterLabel}>
                        {translate("scheduler.clearFilters")}
                    </Typography>
                </div>
            </React.Fragment>
        </KortexSearchField>
    );
}
