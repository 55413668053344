import {
    TrainingPendingDbModelKey,
    TrainingPendingDeleteRes,
    TrainingPendingGetAllByUserIdRes,
    TrainingPendingGetAllRequest,
    TrainingPendingGetAllRes,
    TrainingPendingTrainingCertificateSignRes,
    UnwrapAOSPayload,
    UserId,
} from "@kortex/aos-common";

import { IStandardThunkOptions } from "../app.types";
import { handleAPIError } from "../handleAPIError";
import { AppState, StandardDispatch, StandardThunk } from "../store";
import { fetchedOnce, normalizeStandardThunkeReduxOptions } from "../utils";

import {
    trainingPendingListDeletedAction,
    trainingPendingListInsertedAction,
    trainingPendingListSetAction,
    trainingPendingOfUserClear,
    trainingPendingOfUserDeletedAction,
    trainingPendingOfUserSetAction,
} from "./training-pending-actions";
import { TrainingCertificateSign } from "./training-pending-types";

/**
 * clear training pending state
 *
 */
export function clearTrainingPendingOfUser(): StandardThunk<void> {
    return async (dispatch: StandardDispatch): Promise<void> => {
        dispatch(trainingPendingOfUserClear());
    };
}

/**
 * Fetch all trainings
 */
export function trainingPendingGetAll(
    limit: number,
    offset: number,
    filterOptions: TrainingPendingGetAllRequest["filterOptions"],
    options: IStandardThunkOptions
): StandardThunk<UnwrapAOSPayload<TrainingPendingGetAllRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TrainingPendingGetAllRes>> => {
        if (!skipLookup) {
            return { data: getState().trainingPending.trainingPendingList };
        }

        return api.services.trainingPending
            .getAll({ filterOptions, limit, offset })()
            .then((res) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(trainingPendingGetAll);
                    if (offset === 0) {
                        dispatch(trainingPendingListSetAction(res.data));
                    } else {
                        dispatch(trainingPendingListInsertedAction(res.data));
                    }
                }

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return { data: [] };
            });
    };
}

/**
 * Fetch all trainings pending of userId
 */
export function trainingPendingGetAllByUserId(
    userId: UserId,
    options: IStandardThunkOptions
): StandardThunk<UnwrapAOSPayload<TrainingPendingGetAllByUserIdRes>> {
    const { skipDispatch, skipLookup } = normalizeStandardThunkeReduxOptions(options);

    return async (
        dispatch: StandardDispatch,
        getState: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TrainingPendingGetAllByUserIdRes>> => {
        if (!skipLookup) {
            return getState().trainingPending.trainingsPendingOfUser;
        }

        return api.services.trainingPending
            .getAllByUserId({ userId: userId })()
            .then((res) => {
                if (!skipDispatch) {
                    fetchedOnce.seal(trainingPendingGetAllByUserId);
                    dispatch(trainingPendingOfUserSetAction([...res]));
                }

                return res;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);

                return [];
            });
    };
}

/**
 * Training Certificate Sign
 */
export function trainingCertificateSign(
    trainingCertificate: TrainingCertificateSign
): StandardThunk<UnwrapAOSPayload<TrainingPendingTrainingCertificateSignRes>["trainingsPendingDeleted"]> {
    return async function (
        dispatch: StandardDispatch,
        _: () => AppState,
        { apiUI: api }
    ): Promise<UnwrapAOSPayload<TrainingPendingTrainingCertificateSignRes>["trainingsPendingDeleted"]> {
        return api.services.trainingPending
            .trainingCertificateSign(trainingCertificate.trainingsCertificateSelected)()
            .then((res) => {
                const cleanState = [...res.trainingsPendingDeleted];
                trainingCertificate.unselectTrainingsCertificate.map((unseleteTraining) => cleanState.push(unseleteTraining));

                // Deleting deleted and unsigned pending trainings from redux. Unsigned pending trainings will be displayed again the next time you open AOS.
                dispatch(trainingPendingOfUserDeletedAction(cleanState));

                return res.trainingsPendingDeleted;
            })
            .catch((error) => {
                handleAPIError(error, dispatch);
                return [];
            });
    };
}

/**
 * Delete Training Certificate
 */
export function trainingPendingDelete(
    trainingPendingId: TrainingPendingDbModelKey
): StandardThunk<UnwrapAOSPayload<TrainingPendingDeleteRes>> {
    return async (dispatch: StandardDispatch, _: () => AppState, { apiUI: api }): Promise<UnwrapAOSPayload<TrainingPendingDeleteRes>> => {
        try {
            return api.services.trainingPending
                .delete(trainingPendingId)()
                .then((deletedTrainingPending) => {
                    dispatch(trainingPendingListDeletedAction([...deletedTrainingPending]));
                    return deletedTrainingPending;
                });
        } catch (error) {
            handleAPIError(error, dispatch);
        }

        return [];
    };
}
