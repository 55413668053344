import { KortexTextField } from "@aos/react-components";
import {
    DataStoreStorageOutputFormatEnum,
    DataStoreStorageTypeEnum,
    EnumResultSettingType,
    IDataStoreReadItemConfig,
    IResultKeyValue,
    ProcessEditorRightsEnum,
    ProcessVariableStorer,
    ProcessVariableStoringMethod,
} from "@kortex/aos-common";
import { Checkbox, IconButton, Menu, MenuItem, Paper, Typography, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CancelRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useEffect, useRef, useState } from "react";

import FiltersIcon from "../../../../../../../components/core/Icons/Filters/Filters";
import SortAlphaDown from "../../../../../../../components/core/Icons/SortAlphaDown/SortAlphaDown";
import SortAlphaUp from "../../../../../../../components/core/Icons/SortAlphaUp/SortAlphaUp";
import VariablePicker from "../../../../../../../components/core/VariablePicker/VariablePicker";
import { useTranslate } from "../../../../../../../hooks/useTranslate";
import { useSelectorResultSettingItems } from "../../../../../../../redux/selectors";
import { IUserRightsProps, userCanWrite } from "../../../../../../../utilitites/IUserRights";

import {
    DATASTORE_GROUP,
    DATASTORE_SUBGROUP,
    DATASTORE_TABLE,
    DATASTORE_TABLE_COL_INDEX_OFFSET,
    DATASTORE_TABLE_COL_PREFIX,
    getSourceFilters,
    getStringKey,
} from "./DataStoreReadItemHelper";

const INPUT_DELAY_MS = 500;

const useStyles = makeStyles({
    root: {
        marginBottom: "16px",
        padding: "16px",
        display: "flex",
    },
    formControl: {
        width: "100%",
    },
    inputHeight: {
        height: "48px",
    },
    inputPicker: {
        height: "38px",
    },
    input: {
        paddingBottom: "10px",
    },
    filters: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    buttonHolder: {
        display: "flex",
        flex: 0,
        flexDirection: "column",
    },
    buttonRows: {
        display: "flex",
        flex: 0,
        flexDirection: "row",
    },
    filterButton: {
        marginTop: "-15px",
    },
    filterItem: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    filter: {
        margin: "10px",
        width: "300px",
        height: "68px",
    },
    entry: {
        margin: "10px",
        width: "300px",
    },
    closeButton: {
        marginLeft: "-25px",
        marginTop: "-45px",
        backgroundColor: "white",
    },
    optionItems: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    optionItem: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
    },
});

export interface IDataStoreReadItemEditorProps extends IUserRightsProps<ProcessEditorRightsEnum> {
    id?: string;
    readItemProps: IDataStoreReadItemConfig;
    readItemIndex: number;
    onReadItemPropsChanged: (readItemProps: IDataStoreReadItemConfig, index: number) => void;
    onReadItemPropsDeleted: (readItemPropsIndex: number) => void;
}

export default function DataStoreReadItemEditor(props: IDataStoreReadItemEditorProps): JSX.Element {
    const { readItemProps, userAccessLevel } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const resultSettingItems = useSelectorResultSettingItems();

    const readOnly = !userCanWrite(userAccessLevel);

    /**
     * Component states
     */
    const [sourceFilters, setSourceFilters] = useState<string[]>([]);
    const [filters, setFilters] = useState(readItemProps.filters);
    const [storeTo, setStoreTo] = useState<ProcessVariableStorer>(readItemProps.storeTo);
    const [reqColumn, setReqColumn] = useState<string>(readItemProps.reqColumn.toString());
    const [actionChanged, setActionChanged] = useState(false);
    const [groupId, setGroupId] = useState(0);
    const [tableId, setTableId] = useState(0);
    const [sortDescending, setSortDescending] = useState(false);

    const [menuOpen, setMenuOpen] = useState(false);
    const [menuFilterOpen, setMenuFilterOpen] = useState(false);
    const [deleteFilter, setDeleteFilter] = useState(false);

    const menuRef = useRef(null);
    const menuFilterRef = useRef(null);

    /**
     * Effect that update local state when props are
     * updated from parent
     */
    useEffect((): void => {
        setSourceFilters(getSourceFilters(readItemProps.storageType));
    }, []);

    /**
     * Effect that update local state when props are
     * updated from parent
     */
    useEffect((): void => {
        setFilters(readItemProps.filters);
        setReqColumn(readItemProps.reqColumn.toString());
        setStoreTo(readItemProps.storeTo);
        setSortDescending(readItemProps.sortDescending ? readItemProps.sortDescending : false);

        const filterTablePresent = readItemProps.filters.find(
            (filter): boolean => filter.column === DATASTORE_TABLE && props.readItemProps.storageType === DataStoreStorageTypeEnum.TABLE
        );
        if (filterTablePresent !== undefined) {
            setTableId(parseInt(filterTablePresent.value));
        } else {
            setTableId(0);
        }

        const filterGroupPresent = readItemProps.filters.find((filter): boolean => filter.column === DATASTORE_GROUP);
        if (filterGroupPresent !== undefined) {
            setGroupId(parseInt(filterGroupPresent.value));
        } else {
            setGroupId(0);
        }
    }, [readItemProps]);

    /**
     * Effect that trigger action props update to database
     */
    useEffect((): void => {
        if (actionChanged) {
            props.onReadItemPropsChanged(
                {
                    ...readItemProps,
                    filters,
                    reqColumn: reqColumn as keyof IResultKeyValue, // FIXME: fix reqColumn type --> should not be string in state
                    storeTo,
                    sortDescending,
                },
                props.readItemIndex
            );
            setActionChanged(false);
        }
    }, [actionChanged]);

    /**
     * Handle open menu click
     */
    const handleMenuClick = (): void => {
        setMenuOpen(true);
    };

    /**
     * Handle menu close
     */
    const handleMenuClose = (): void => {
        setMenuOpen(false);
    };

    /**
     * Handle open menu click
     */
    const handleMenuFilterClick = (): void => {
        setMenuFilterOpen(true);
    };

    /**
     * Handle menu filter close click
     */
    const handleMenuFilterClose = (): void => {
        setMenuFilterOpen(false);
    };

    /**
     * Handle open menu click
     */
    const handleDeleteFilterClick = (): void => {
        setDeleteFilter(!deleteFilter);
    };

    /**
     * Handle checkbox output format (CSV or JSON)
     */
    const handleChangeStoreOutputFormat =
        (outputFormat: DataStoreStorageOutputFormatEnum): (() => void) =>
        (): void => {
            readItemProps.storeOutputFormat = outputFormat;
            setActionChanged(true);
        };

    /**
     * Handle add filters button
     */
    const handleFilterAdd =
        (filterIndex: number, filter: string): (() => void) =>
        (): void => {
            if (!filters.find((findFilter) => findFilter.column === filter)) {
                filters.push({ column: filter, value: "", custom: false, customIndex: 0 });
            }
            setFilters(filters);
            setActionChanged(true);
        };

    /**
     * Handle add custom filter
     */
    const handleFilterAddCustom =
        (filterIndex: number): (() => void) =>
        (): void => {
            if (!filters.find((findFilter) => findFilter.column === "col" + filterIndex)) {
                filters.push({ column: "col" + filterIndex, value: "", custom: true, customIndex: filterIndex });
            }
            setFilters(filters);
            setActionChanged(true);
        };

    /**
     * Handle delete filter
     */
    const handleFilterDelete =
        (index: number): (() => void) =>
        (): void => {
            filters.splice(index, 1);
            setFilters(filters);
            setActionChanged(true);
        };

    /**
     * Handle filter value changed
     *
     * @param {number} index - index of the filter to changed
     */
    const handleFilterChange =
        (index: number) =>
        (value: string): void => {
            filters[index].value = value;
            setFilters(filters);
            setActionChanged(true);
        };

    /**
     * Handle user change of group id
     *
     * @param {number} index - index of the filter to changed
     */
    const handleResultSettingTypeChanged =
        (index: number) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            if (event.target.value !== filters[index].value) {
                filters[index].value = event.target.value;
                setFilters(filters.filter((filter) => filter.custom === false));
                setActionChanged(true);
                setReqColumn("");
            }
        };

    /**
     * Handle storeTo change
     */
    const handleStoreToChange = (identifier: string, storingMethod: ProcessVariableStoringMethod): void => {
        setStoreTo(new ProcessVariableStorer(identifier, storingMethod));
        setActionChanged(true);
    };

    /**
     * Handle requested column change
     */
    const handleReqColumnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setReqColumn(reqColumn === event.target.value ? "" : event.target.value);
        setActionChanged(true);
    };

    /**
     * Handle delete key value
     */
    const handleDeleteReadItem = (): void => {
        props.onReadItemPropsDeleted(props.readItemIndex);
    };

    /**
     * Retreive the list of the possible column
     */
    const getAllColumn = (): string => {
        const allColumns = resultSettingItems
            .filter((settingItem): boolean => settingItem.type === EnumResultSettingType.COLUMN && settingItem.parentId === tableId)
            .map((filter, index) => `${DATASTORE_TABLE_COL_PREFIX}${index + DATASTORE_TABLE_COL_INDEX_OFFSET}`);
        return allColumns.toString();
    };

    /**
     * Handle change of ascending sortDescending
     */
    const handleToggleAscDesc = (): void => {
        setSortDescending(!sortDescending);
        setActionChanged(true);
    };

    return (
        <Paper className={classes.root}>
            <div className={classes.filters}>
                <div className={classes.filterItem}>
                    {filters.map((filter, index): JSX.Element => {
                        return (
                            <div key={index} className={classes.filterItem}>
                                {filter.column === DATASTORE_GROUP ||
                                filter.column === DATASTORE_SUBGROUP ||
                                (filter.column === DATASTORE_TABLE &&
                                    props.readItemProps.storageType === DataStoreStorageTypeEnum.TABLE) ? (
                                    <KortexTextField
                                        variant="outlined"
                                        className={classes.filter}
                                        InputProps={{
                                            className: classes.inputHeight,
                                        }}
                                        label={filter.custom ? filter.column : translate(`action.datastore.${filter.column}`)}
                                        TextFieldProps={{
                                            disabled: readOnly,
                                            select: true,
                                        }}
                                        value={filter.value}
                                        onChange={handleResultSettingTypeChanged(index)}
                                    >
                                        {resultSettingItems
                                            .filter((settingItem): boolean =>
                                                filter.column === DATASTORE_TABLE &&
                                                props.readItemProps.storageType === DataStoreStorageTypeEnum.TABLE
                                                    ? settingItem.type === EnumResultSettingType.TABLE
                                                    : filter.column === DATASTORE_GROUP
                                                    ? settingItem.type === EnumResultSettingType.GROUP
                                                    : settingItem.type === EnumResultSettingType.SUB_GROUP &&
                                                      settingItem.parentId === groupId
                                            )
                                            .map((item, index): JSX.Element => {
                                                return (
                                                    <MenuItem key={index} value={item.resultSettingItemId}>
                                                        {item.label}
                                                    </MenuItem>
                                                );
                                            })}
                                    </KortexTextField>
                                ) : (
                                    <VariablePicker
                                        KortexTextFieldProps={{
                                            variant: "outlined",
                                            className: classes.filter,
                                            label: filter.custom ? filter.column : translate(`action.datastore.readItem.${filter.column}`),
                                            changedDelayMS: INPUT_DELAY_MS,
                                            TextFieldProps: {
                                                inputProps: {
                                                    className: classes.input,
                                                },
                                            },
                                        }}
                                        userAccessLevel={userAccessLevel}
                                        value={filter.value}
                                        onChange={handleFilterChange(index)}
                                    />
                                )}
                                {deleteFilter && (
                                    <IconButton onClick={handleFilterDelete(index)} className={classes.closeButton}>
                                        <CloseIcon />
                                    </IconButton>
                                )}
                            </div>
                        );
                    })}
                    <div className={classes.filterButton}>
                        <IconButton onClick={handleToggleAscDesc}>{sortDescending ? <SortAlphaDown /> : <SortAlphaUp />}</IconButton>
                    </div>
                </div>
                {/* 2nd line - Source, Value... */}
                <div>
                    <KortexTextField
                        InputProps={{
                            readOnly: true,
                            className: classes.inputHeight,
                        }}
                        className={classes.entry}
                        value={translate(`action.datastore.storageType.${getStringKey(readItemProps.storageType)}`)}
                        label={translate("action.datastore.source")}
                        variant="standard"
                    />
                    <KortexTextField
                        variant="standard"
                        className={classes.filter}
                        InputProps={{
                            className: classes.inputHeight,
                        }}
                        label={translate("action.datastore.reqColumn")}
                        TextFieldProps={{
                            disabled: readOnly,
                            select: true,
                        }}
                        value={reqColumn}
                        onChange={handleReqColumnChange}
                    >
                        {sourceFilters
                            .map((filter, index): JSX.Element => {
                                return (
                                    <MenuItem key={index} value={filter}>
                                        {translate(`action.datastore.readItem.${filter}`)}
                                    </MenuItem>
                                );
                            })
                            .concat(
                                resultSettingItems
                                    .filter(
                                        (settingItem): boolean =>
                                            settingItem.type === EnumResultSettingType.COLUMN && settingItem.parentId === tableId
                                    )
                                    .map((filter, index): JSX.Element => {
                                        return (
                                            <MenuItem
                                                key={DATASTORE_TABLE_COL_PREFIX + (index + DATASTORE_TABLE_COL_INDEX_OFFSET)}
                                                value={DATASTORE_TABLE_COL_PREFIX + (index + DATASTORE_TABLE_COL_INDEX_OFFSET)}
                                            >
                                                {filter.label}
                                            </MenuItem>
                                        );
                                    })
                            )
                            // FIXME: Option "All Column" has no text when selected
                            .concat(
                                props.readItemProps.storageType === DataStoreStorageTypeEnum.TABLE ? (
                                    <MenuItem key={200} value={getAllColumn()}>
                                        {translate("action.datastore.readItem.allColumn")}
                                    </MenuItem>
                                ) : (
                                    []
                                )
                            )}
                    </KortexTextField>

                    <VariablePicker
                        KortexTextFieldProps={{
                            className: classes.entry,
                            variant: "standard",
                            label: translate("action.datastore.storeTo"),
                            changedDelayMS: INPUT_DELAY_MS,
                            TextFieldProps: {
                                inputProps: {
                                    className: classes.inputPicker,
                                },
                            },
                        }}
                        userAccessLevel={userAccessLevel}
                        value={storeTo.identifier}
                        storingMethod={storeTo.storingMethod}
                        onChange={handleStoreToChange}
                    />
                </div>
            </div>

            <div className={classes.buttonHolder}>
                <div className={classes.buttonRows}>
                    <div className={classes.optionItems}>
                        <div className={classes.optionItem}>
                            <div>
                                <IconButton
                                    color={deleteFilter ? "secondary" : undefined}
                                    disabled={readOnly}
                                    id="toggleDeleteFilterButtonId"
                                    onClick={handleDeleteFilterClick}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                            <div ref={menuFilterRef}>
                                <IconButton disabled={readOnly} id="showMenuFilterButtonId" onClick={handleMenuFilterClick}>
                                    <FiltersIcon />
                                </IconButton>
                            </div>
                            <div ref={menuRef}>
                                <IconButton disabled={readOnly} id="showMenuButtonId" onClick={handleMenuClick}>
                                    <MoreVertIcon />
                                </IconButton>
                            </div>
                        </div>

                        <div className={classes.optionItem}>
                            <Typography>{"CSV"}</Typography>
                            <Checkbox
                                color="secondary"
                                checked={
                                    !readItemProps.storeOutputFormat ||
                                    readItemProps.storeOutputFormat === DataStoreStorageOutputFormatEnum.CSV
                                }
                                onChange={handleChangeStoreOutputFormat(DataStoreStorageOutputFormatEnum.CSV)}
                                disabled={readOnly}
                            />
                            <Typography>{"JSON"}</Typography>
                            <Checkbox
                                color="secondary"
                                checked={
                                    readItemProps.storeOutputFormat &&
                                    readItemProps.storeOutputFormat === DataStoreStorageOutputFormatEnum.JSON
                                }
                                onChange={handleChangeStoreOutputFormat(DataStoreStorageOutputFormatEnum.JSON)}
                                disabled={readOnly}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Menu anchorEl={menuFilterRef.current} open={menuFilterOpen} onClose={handleMenuFilterClose}>
                {sourceFilters.map((filter, index): JSX.Element => {
                    return (
                        <MenuItem
                            key={index}
                            id="filterButtonId"
                            onClick={handleFilterAdd(index, filter)}
                            onMouseDown={handleMenuFilterClose}
                        >
                            {translate(`action.datastore.readItem.${filter}`)}
                        </MenuItem>
                    );
                })}
                {props.readItemProps.storageType === DataStoreStorageTypeEnum.TABLE &&
                    resultSettingItems
                        .filter(
                            (settingItem): boolean => settingItem.type === EnumResultSettingType.COLUMN && settingItem.parentId === tableId
                        )
                        .map((filter, index): JSX.Element => {
                            return (
                                <MenuItem
                                    key={100 + index} // 100 is just to be unique
                                    id="filterButtonId"
                                    onClick={handleFilterAddCustom(index + DATASTORE_TABLE_COL_INDEX_OFFSET)}
                                    onMouseDown={handleMenuFilterClose}
                                >
                                    {filter.label}
                                </MenuItem>
                            );
                        })}
            </Menu>

            <Menu anchorEl={menuRef.current} open={menuOpen} onClose={handleMenuClose}>
                <MenuItem id="delete-button" onClick={handleDeleteReadItem} onMouseDown={handleMenuClose}>
                    {translate("action.datastore.delete")}
                </MenuItem>
            </Menu>
        </Paper>
    );
}
