var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b;
// material design
import grey from "@material-ui/core/colors/grey";
import { createMuiTheme } from "@material-ui/core/styles";
import { errorPalette, greyPalette, primaryPalette, secondaryPalette } from "./palettes";
// We use this wrapper to improve the typing of `createMuiTheme`. Our custom overrides are included in `options`.
// @ts-expect-error - `createMuiTheme` wants PaletteColor instead of Color
var createCustomTheme = function (options) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return createMuiTheme.apply(void 0, __spreadArray([options], args, false));
};
// We may also need the default MUI theme
var defaultMuiTheme = createMuiTheme();
// creating the theme
export var theme = createCustomTheme({
    breakpoints: defaultMuiTheme.breakpoints,
    typography: {
        fontFamily: ["Roboto", "Verdana", "sans-serif"].join(","),
        htmlFontSize: 14, // FIXME: font: set to 10px when no more polymer components in app
    },
    palette: {
        type: "light",
        primary: primaryPalette,
        secondary: secondaryPalette,
        error: errorPalette,
        contrastThreshold: 3,
        tonalOffset: 0.2,
        grey: greyPalette,
    },
    shape: {
        // used by some MUI elements internally
        borderRadius: 2,
    },
    overrides: {
        MuiBottomNavigationAction: {
            label: {
                fontSize: "0.8rem",
                fontFamily: "Roboto,Verdana,sans-serif",
            },
        },
        MuiInput: {
            underline: {
                "&:hover:not($disabled):not($focused):not($error):before": {
                    borderBottomColor: secondaryPalette[500],
                },
                "&$focused:after": {
                    borderBottomColor: secondaryPalette[500],
                },
            },
        },
        MuiInputBase: {
            input: {
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    "-moz-appearance": "none",
                    margin: 0,
                },
                "&[type=number]": {
                    "-webkit-appearance": "textfield",
                    "-moz-appearance": "textfield",
                },
                padding: "18.5px 14px",
            },
        },
        MuiOutlinedInput: {
            root: {
                "&:hover:not($focused) $notchedOutline": {
                    border: "1px  solid",
                    borderColor: "".concat(primaryPalette[500], " !important"),
                },
                "&:focus $notchedOutline": {
                    "border:": "2px solid",
                    borderColor: "".concat(primaryPalette[500], " !important"),
                },
            },
            adornedEnd: {
                paddingRight: "10px",
            },
            notchedOutline: {},
        },
        MuiFormControl: {
            root: {
                margin: 0,
            },
            marginNormal: {
                marginTop: 0,
                marginBottom: 0,
            },
        },
        MuiFormLabel: {
            asterisk: {
                color: errorPalette[500],
            },
        },
        MuiInputLabel: {
            root: {
                color: greyPalette[700],
                "&$focused": {
                    color: greyPalette[700],
                },
                "&$error": {
                    color: errorPalette[500],
                },
                "&$required > span:nth-child(2)": {
                    color: errorPalette[500],
                },
            },
        },
        MuiNativeSelect: {
            select: {
                "&:focus": {
                    background: "none",
                },
            },
        },
        MuiButton: {
            root: {
                minWidth: "auto",
                minHeight: "50px",
            },
            outlined: {
                border: "1px solid ".concat(greyPalette[500]),
                borderRadius: "2px",
                color: greyPalette[500],
                "&:hover": {
                    color: secondaryPalette[500],
                    backgroundColor: "transparent",
                    borderColor: secondaryPalette[500],
                },
            },
        },
        // Needed to overwrite the MuiButton-root minheight of 50px declared above. It messes up the DateTimePicker UI alignment.
        MuiPickersToolbarButton: {
            toolbarBtn: {
                minWidth: "auto",
                minHeight: "auto",
            },
        },
        MuiCardActions: {
            root: {
                backgroundColor: grey[200],
            },
        },
        MuiFab: {
            sizeSmall: {
                padding: 0,
                minWidth: "28px",
                minHeight: "28px",
                width: "28px",
                height: "28px",
                boxShadow: "none",
            },
        },
        MuiTooltip: {
            tooltipPlacementLeft: {
                backgroundColor: primaryPalette[500],
            },
        },
        MuiTypography: {
            root: {
                margin: 0,
            },
            subtitle1: {
                fontSize: "1.15rem",
                margin: 0,
            },
            subtitle2: {
                fontSize: "1rem",
                margin: 0,
            },
            body1: {
                fontSize: "1.15rem",
                margin: 0,
                letterSpacing: "0.5px",
            },
            body2: {
                fontSize: "1rem",
                margin: 0,
                letterSpacing: "0.25px",
            },
            overline: {
                textTransform: "uppercase",
                fontSize: "0.85rem",
                margin: 0,
                letterSpacing: "2px",
            },
            caption: {
                textTransform: "none",
                fontSize: "0.85rem",
                margin: 0,
                letterSpacing: "0.4px",
            },
            h1: {
                color: primaryPalette[500],
                fontSize: "2rem",
                margin: 0,
                fontWeight: "bold",
                fontFamily: ["'Exo 2'", "Roboto", "Verdana", "sans-serif"].join(","),
            },
            h2: {
                color: primaryPalette[500],
                fontSize: "1.7rem",
                margin: 0,
                fontWeight: "bold",
                fontFamily: ["'Exo 2'", "Roboto", "Verdana", "sans-serif"].join(","),
            },
            h3: {
                color: secondaryPalette[500],
                fontSize: "1.7rem",
                margin: 0,
                fontWeight: "bold",
                fontFamily: ["'Exo 2'", "Roboto", "Verdana", "sans-serif"].join(","),
            },
            h4: {
                color: primaryPalette[500],
                fontSize: "1.57rem",
                margin: 0,
                fontWeight: "bold",
                fontFamily: ["'Exo 2'", "Roboto", "Verdana", "sans-serif"].join(","),
            },
            h5: {
                color: primaryPalette[500],
                fontSize: "1.4rem",
                margin: 0,
                fontWeight: "bold",
                fontFamily: ["'Exo 2'", "Roboto", "Verdana", "sans-serif"].join(","),
            },
            h6: {
                color: primaryPalette[500],
                fontSize: "1.15rem",
                margin: 0,
                fontWeight: "bold",
                fontFamily: ["'Exo 2'", "Roboto", "Verdana", "sans-serif"].join(","),
            },
        },
        MuiTableCell: {
            root: (_a = {},
                _a[defaultMuiTheme.breakpoints.up("md")] = {
                    padding: 4,
                },
                _a),
        },
        MuiTableHead: {
            root: (_b = {},
                _b[defaultMuiTheme.breakpoints.up("md")] = {
                    padding: 4,
                },
                _b),
        },
    },
    props: {
    // insert global MUI Component prop overrides here
    /*
    MuiButtonBase: {
        // The properties to apply
        disableRipple: true, // No more ripple, on the whole application
    },
    */
    },
});
