import { ITrainingCertificationPending, TrainingCertificationPendingGetAllFilters } from "@kortex/aos-common";
import { UseListObserver, useListObserver } from "@kortex/aos-ui/hooks/useListObserver";
import { useEntitiesTrainingCertificationPendingList } from "@kortex/aos-ui/redux/effects";
import { trainingCertificationPendingGetAll } from "@kortex/aos-ui/redux/training-certification-pending-manager/training-certification-pending-thunks";
import React, { FC, PropsWithChildren, createContext, useContext } from "react";

const TrainingCertificationContext = createContext<
    UseListObserver<ITrainingCertificationPending, TrainingCertificationPendingGetAllFilters>
>({
    setFilters: () => void 0,
    filters: {},
    entities: [],
    hasNext: false,
    isDataLoading: false,
    Observer: () => null,
    observerId: undefined,
});

type TrainingCertificationProviderProps = PropsWithChildren<{}>;

export const TrainingCetrificationProvider: FC<TrainingCertificationProviderProps> = (props) => {
    const { children } = props;

    const { entities, filters, hasNext, isDataLoading, Observer, observerId, setFilters } = useListObserver(
        useEntitiesTrainingCertificationPendingList,
        (trainingCertificationPending) => trainingCertificationPending?.trainingCertificationPendingId ?? 0,
        trainingCertificationPendingGetAll,
        {}
    );

    return (
        <TrainingCertificationContext.Provider
            value={{
                entities,
                filters,
                hasNext,
                isDataLoading,
                Observer,
                observerId,
                setFilters,
            }}
        >
            {children}
        </TrainingCertificationContext.Provider>
    );
};

export const useTrainingCertificationContext = (): UseListObserver<
    ITrainingCertificationPending,
    TrainingCertificationPendingGetAllFilters
> => useContext<UseListObserver<ITrainingCertificationPending, TrainingCertificationPendingGetAllFilters>>(TrainingCertificationContext);
