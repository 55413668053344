import { TreeNodeDbModel, ApprovalStatusEnum, getActiveProcessWhereUsed, ProcessWhereUsed, TreeNodeNodeTypeEnum } from "@kortex/aos-common";
import { KortexDialogConfirmation, errorPalette } from "@aos/react-components";
import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AppState } from "../../../../redux/store";
import { SetProcessesAction } from "../../../../redux/process-manager/process-types";
import ProcessWhereUsedPage from "../../ProcessWhereUsed/ProcessWhereUsedPage";
import { useTranslate } from "../../../../hooks/useTranslate";

const useStyles = makeStyles({
    errorMessage: {
        color: errorPalette[500],
    },
});

interface IOwnProps {
    onCancel: () => void;
    onConfirm: () => void;
    opened: boolean;
    treeNode: TreeNodeDbModel;
    currentArchived: boolean;
}

export default function ProcessRepositoryArchiveDialog(props: IOwnProps): JSX.Element {
    const { onCancel, onConfirm, opened, treeNode } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [canBeArchived, setCanBeArchived] = useState<boolean | null>(null);

    const releasedProcesses = useSelector<AppState, SetProcessesAction["value"]>((state) =>
        state.process.processes.filter(
            (proc) =>
                (proc.treeNodeId === treeNode.treeNodeId || proc.treeNodeId === treeNode.parentId) &&
                proc.versionStatus === ApprovalStatusEnum.APPROVED &&
                proc.releasedOn &&
                (!proc.retiredOn || proc.retiredOn > Date.now())
        )
    );

    // If tree node is undefined, do no render the dialog
    if (!treeNode) {
        return <div />;
    }

    /**
     * Set inner state to indicate whether or not the process version can be archived
     *
     * @param {boolean} processWhereUsed - list of "Where Used" of a processes
     */
    const handleWhereUsedPageLoad = (processWhereUsed: ProcessWhereUsed[]): void => {
        if (treeNode.nodeType === TreeNodeNodeTypeEnum.FOLDER && processWhereUsed.length > 0) {
            setCanBeArchived(releasedProcesses.length === 0 && !Boolean(processWhereUsed.length));
        } else {
            setCanBeArchived(releasedProcesses.length === 0 && !Boolean(getActiveProcessWhereUsed(processWhereUsed).length));
        }
    };

    /**
     * Resets inner state when opening the dialog
     */
    useEffect(() => {
        if (opened) {
            setCanBeArchived(null);
        }
    }, [opened]);

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            confirmDisabled={!Boolean(canBeArchived)}
            dialogProps={{
                id: "archiveDialogId",
                fullWidth: false,
                maxWidth: false,
                onBackdropClick: onCancel,
            }}
            onCancel={onCancel}
            onConfirm={onConfirm}
            open={opened}
            textLabels={{
                cancelButtonLabel: translate("processRepository.archiveDialogCancel"),
                proceedButtonLabel: translate("processRepository.archiveDialogConfirm"),
                titleLabel: translate("processRepository.archiveDialogTitle"),
            }}
        >
            <>
                {canBeArchived === false && (
                    <Typography className={classes.errorMessage} id="processRepositoryArchiveDialogArchivedDisabledId">
                        {translate("processRepository.archiveDialogArchiveDisabledMessage")}
                    </Typography>
                )}
                {canBeArchived !== true && (
                    <ProcessWhereUsedPage
                        onLoad={
                            handleWhereUsedPageLoad // False or null
                        }
                        treeNodeId={treeNode.treeNodeId}
                    />
                )}
                {canBeArchived === true && (
                    <Typography id="processRepositoryArchiveDialogArchivedEnabledId">
                        {props.currentArchived
                            ? translate("processRepository.unarchiveDialogConfirmation")
                            : translate("processRepository.archiveDialogConfirmation")}
                    </Typography>
                )}
            </>
        </KortexDialogConfirmation>
    );
}
