import { KortexColorPicker, KortexDialogConfirmation, KortexTextField } from "@aos/react-components";
import {
    EnumArrowheadIndex,
    EnumArrowheadShape,
    EnumDashType,
    IWorkInstructionsElementConfig,
    IWorkInstructionsLineConfig,
} from "@kortex/aos-common";
import { useStopKeybindPropagation } from "@kortex/aos-ui/hooks/useKeybind";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import NotchedArrowIcon from "@material-ui/icons/ArrowForward";
import CircleIcon from "@material-ui/icons/Lens";
import LineDottedIcon from "@material-ui/icons/MoreHoriz";
import ArrowIcon from "@material-ui/icons/PlayArrow";
import * as React from "react";
import { useEffect, useState } from "react";

import { useTranslate } from "../../../../../../../../../hooks/useTranslate";
import DiamondIcon from "../../../../../../../../core/Icons/Diamond/Diamond";
import LineIcon from "../../../../../../../../core/Icons/Line/Line";
import LineDashedIcon from "../../../../../../../../core/Icons/LineDashed/LineDashed";
import SquareIcon from "../../../../../../../../core/Icons/Square/Square";

const useStyles = makeStyles({
    row: {
        alignItems: "flex-end",
        display: "flex",
    },
    select: {
        height: "65px",
        width: "85px",
    },
    selectContainer: {
        marginRight: "32px",
    },
});

export interface IOwnProps {
    lineProps: IWorkInstructionsElementConfig<IWorkInstructionsLineConfig>;
    onCancel: () => void;
    onSave: (element: IWorkInstructionsElementConfig<IWorkInstructionsLineConfig>) => void;
    open: boolean;
}

export default function WorkInstructionsLineEditor(props: IOwnProps): JSX.Element {
    const { open, onCancel, onSave } = props;

    const classes = useStyles();
    const translate = useTranslate();

    const [lineProps, setLineProps] = useState<IWorkInstructionsElementConfig<IWorkInstructionsLineConfig>>(props.lineProps);

    useStopKeybindPropagation(open);

    /**
     * Reassign line props to local state whenever line props are changed
     */
    useEffect((): void => {
        setLineProps(props.lineProps);
    }, [props.lineProps]);

    /**
     * Called when arrowhead shape is changed
     *
     * @param {number} index - index of the arrowhead (start or end)
     * @returns {(event: React.ChangeEvent<HTMLSelectElement>) => undefined}
     */
    const handleArrowheadShapeChange =
        (index: EnumArrowheadIndex): ((event: React.ChangeEvent<HTMLSelectElement>) => void) =>
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            const clone = { ...lineProps };
            clone.extendedProps.arrowheads[index].shape = event.target.value as EnumArrowheadShape;
            setLineProps(clone);
        };

    /**
     * Called when color is changed
     *
     * @param {string} color - selected color from selector
     */
    const handleColorChange = (color: string): void => {
        setLineProps({
            ...lineProps,
            extendedProps: {
                ...lineProps.extendedProps,
                color,
            },
        });
    };

    /**
     * Called when dialog's cancel button is clicked
     */
    const handleCancel = (): void => {
        onCancel();
    };

    /**
     * Called when dialog's save button is clicked
     */
    const handleSave = (): void => {
        onSave(lineProps);
    };

    /**
     * Called when a line property is changed
     *
     * @param {string} key - key to specify which property is modified
     * @returns {(event: React.ChangeEvent<HTMLSelectElement>) => undefined}
     */
    const handleStyleChange =
        (key: keyof IWorkInstructionsLineConfig): ((event: React.ChangeEvent<HTMLSelectElement>) => void) =>
        (event: React.ChangeEvent<HTMLSelectElement>): void => {
            setLineProps({
                ...lineProps,
                extendedProps: {
                    ...lineProps.extendedProps,
                    [key]: event.target.value,
                },
            });
        };

    return (
        <KortexDialogConfirmation
            closeOnEscape={true}
            onCancel={handleCancel}
            onConfirm={handleSave}
            open={open}
            textLabels={{
                titleLabel: translate("action.workInstructions.line.title"),
                cancelButtonLabel: translate("action.workInstructions.editDialog.cancel"),
                proceedButtonLabel: translate("action.workInstructions.editDialog.confirm"),
            }}
        >
            <div className={classes.row}>
                <div className={classes.selectContainer}>
                    <KortexColorPicker
                        color={lineProps.extendedProps.color}
                        onChange={handleColorChange}
                        textLabels={{ inputLabel: translate("action.workInstructions.line.color") }}
                    />
                </div>
                <KortexTextField
                    className={classes.selectContainer}
                    label={translate("action.workInstructions.line.thickness")}
                    InputProps={{ className: classes.select }}
                    onChange={handleStyleChange("thickness")}
                    TextFieldProps={{
                        id: "selectThickness",
                        select: true,
                    }}
                    value={lineProps.extendedProps.thickness}
                >
                    <MenuItem value={1}>1px</MenuItem>
                    <MenuItem value={2}>2px</MenuItem>
                    <MenuItem value={3}>3px</MenuItem>
                    <MenuItem value={4}>4px</MenuItem>
                    <MenuItem value={8}>8px</MenuItem>
                    <MenuItem value={12}>12px</MenuItem>
                    <MenuItem value={16}>16px</MenuItem>
                    <MenuItem value={24}>24px</MenuItem>
                </KortexTextField>
                <KortexTextField
                    className={classes.selectContainer}
                    InputProps={{ className: classes.select }}
                    label={translate("action.workInstructions.line.dash")}
                    onChange={handleStyleChange("dash")}
                    TextFieldProps={{
                        id: "selectDash",
                        select: true,
                    }}
                    value={lineProps.extendedProps.dash}
                >
                    <MenuItem value={EnumDashType.NONE}>
                        <LineIcon />
                    </MenuItem>
                    <MenuItem value={EnumDashType.DASH}>
                        <LineDashedIcon />
                    </MenuItem>
                    <MenuItem value={EnumDashType.DOT}>
                        <LineDottedIcon />
                    </MenuItem>
                </KortexTextField>
                <KortexTextField
                    className={classes.selectContainer}
                    InputProps={{ className: classes.select }}
                    label={translate("action.workInstructions.line.start")}
                    onChange={handleArrowheadShapeChange(EnumArrowheadIndex.START)}
                    TextFieldProps={{
                        id: "selectArrowheadStart",
                        select: true,
                    }}
                    value={lineProps.extendedProps.arrowheads[0].shape}
                >
                    <MenuItem value={EnumArrowheadShape.NONE}>
                        <LineIcon />
                    </MenuItem>
                    <MenuItem value={EnumArrowheadShape.ARROW}>
                        <ArrowIcon />
                    </MenuItem>
                    <MenuItem value={EnumArrowheadShape.ARROW_NOTCHED}>
                        <NotchedArrowIcon />
                    </MenuItem>
                    <MenuItem value={EnumArrowheadShape.CIRCLE}>
                        <CircleIcon />
                    </MenuItem>
                    <MenuItem value={EnumArrowheadShape.SQUARE}>
                        <SquareIcon />
                    </MenuItem>
                    <MenuItem value={EnumArrowheadShape.DIAMOND}>
                        <DiamondIcon />
                    </MenuItem>
                </KortexTextField>
                <KortexTextField
                    className={classes.selectContainer}
                    InputProps={{ className: classes.select }}
                    label={translate("action.workInstructions.line.end")}
                    onChange={handleArrowheadShapeChange(EnumArrowheadIndex.END)}
                    TextFieldProps={{
                        id: "selectArrowheadEnd",
                        select: true,
                    }}
                    value={lineProps.extendedProps.arrowheads[1].shape}
                >
                    <MenuItem value={EnumArrowheadShape.NONE}>
                        <LineIcon />
                    </MenuItem>
                    <MenuItem value={EnumArrowheadShape.ARROW}>
                        <ArrowIcon />
                    </MenuItem>
                    <MenuItem value={EnumArrowheadShape.ARROW_NOTCHED}>
                        <NotchedArrowIcon />
                    </MenuItem>
                    <MenuItem value={EnumArrowheadShape.CIRCLE}>
                        <CircleIcon />
                    </MenuItem>
                    <MenuItem value={EnumArrowheadShape.SQUARE}>
                        <SquareIcon />
                    </MenuItem>
                    <MenuItem value={EnumArrowheadShape.DIAMOND}>
                        <DiamondIcon />
                    </MenuItem>
                </KortexTextField>
            </div>
        </KortexDialogConfirmation>
    );
}
