import { rainbowPalette, theme, warningPalette } from "@aos/react-components";
import { JobProcessStatusEnum } from "@kortex/aos-common";

import { ILocale } from "../locales/ILocale";

/**
 * Return in progress and remaining job to start
 *
 * @returns {{ inProgress: number; remainingToStart: number }}  inProgress, remainingToStart
 */
export const getJobProcessInProgress = (
    qtyStarted: number,
    qtyTotal: number,
    qtyPass: number,
    qtyFail: number
): { inProgress: number; remainingToStart: number } => {
    let inProgress = qtyStarted - qtyPass - qtyFail;
    inProgress = inProgress >= 0 ? inProgress : 0;

    const remainingToStart = qtyTotal - inProgress - qtyPass;
    remainingToStart >= 0 ? remainingToStart : 0;

    return { inProgress, remainingToStart };
};

/**
 * Return color to be use for each job process status
 *
 * @param {JobProcessStatusEnum} status - Status to get color
 *
 * @returns {string} - color
 */
export function getJobProcessStatusColor(status?: JobProcessStatusEnum): string {
    switch (status) {
        case JobProcessStatusEnum.DRAFT:
            return theme.palette.grey[500]; // Grey 500
        case JobProcessStatusEnum.READY:
            return rainbowPalette[300]; // Blue
        case JobProcessStatusEnum.IN_PROGRESS:
            return rainbowPalette[400]; // Blue Cyan 400
        case JobProcessStatusEnum.ON_HOLD:
            return warningPalette[600]; // Orange
        case JobProcessStatusEnum.DONE:
            return rainbowPalette[600]; // Green
        case JobProcessStatusEnum.ARCHIVED:
            return theme.palette.common.black; // Black
        default:
            return theme.palette.common.white; // White
    }
}

/**
 * Return Label key to be use by intl for each job process status
 *
 * @param {JobProcessStatusEnum} status - Status to be converted
 *
 * @returns {string} - Key to be use by intl
 */
export function getJobProcessStatusLabelKey(status: JobProcessStatusEnum): keyof ILocale {
    switch (status) {
        case JobProcessStatusEnum.DRAFT:
            return "scheduler.jobStatusDraft";
        case JobProcessStatusEnum.READY:
            return "scheduler.jobStatusReady";
        case JobProcessStatusEnum.IN_PROGRESS:
            return "scheduler.jobStatusInProgress";
        case JobProcessStatusEnum.ON_HOLD:
            return "scheduler.jobStatusOnHold";
        case JobProcessStatusEnum.DONE:
            return "scheduler.jobStatusDone";
        case JobProcessStatusEnum.ARCHIVED:
            return "scheduler.jobStatusArchived";
        case JobProcessStatusEnum.CANCELLED:
            return "scheduler.jobStatusCancelled";
        default:
            return "scheduler.jobStatusUnknown";
    }
}
