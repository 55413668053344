import { ActionTypeEnum } from "../enums";
export function isActionGroupConfigCondition(actionGroup) {
    return actionGroup.type === ActionTypeEnum.CONDITION;
}
export function isActionGroupConfigFail(actionGroup) {
    return actionGroup.type === ActionTypeEnum.FAIL;
}
export function isActionGroupConfigGoto(actionGroup) {
    return actionGroup.type === ActionTypeEnum.GO_TO;
}
export function isActionGroupConfigInput(actionGroup) {
    return actionGroup.type === ActionTypeEnum.INPUT;
}
export function isActionGroupConfigLoop(actionGroup) {
    return actionGroup.type === ActionTypeEnum.LOOP;
}
export function isActionGroupConfigMessage(actionGroup) {
    return actionGroup.type === ActionTypeEnum.MESSAGE;
}
export function isActionGroupConfigSet(actionGroup) {
    return actionGroup.type === ActionTypeEnum.SET;
}
export function isActionGroupConfigSubProgram(actionGroup) {
    return actionGroup.type === ActionTypeEnum.SUB_PROGRAM;
}
export function isActionGroupConfigThread(actionGroup) {
    return actionGroup.type === ActionTypeEnum.THREAD;
}
export function isActionGroupConfigTimer(actionGroup) {
    return actionGroup.type === ActionTypeEnum.TIMER;
}
export function isActionGroupConfigWaitCondition(actionGroup) {
    return actionGroup.type === ActionTypeEnum.WAIT_CONDITION;
}
export function isActionGroupConfigWaitTime(actionGroup) {
    return actionGroup.type === ActionTypeEnum.WAIT_TIME;
}
