export const rootCause = {
    rpc: {
        getAll: {
            funcId: 3601,
            funcName: "rootCauseAll",
        },
        insert: {
            funcId: 3602,
            funcName: "rootCauseInsert",
        },
        inserted: {
            funcId: 3603,
            funcName: "rootCauseInserted",
        },
        update: {
            funcId: 3604,
            funcName: "rootCauseUpdate",
        },
        updated: {
            funcId: 3605,
            funcName: "rootCauseUpdated",
        },
    },
    serviceId: 36,
    serviceVersion: 1,
};
