import { ProcessTrainingRequirementEnum } from "@kortex/aos-common";

const TRAINING_REQUIREMENT_LOCALES_MAP: Record<ProcessTrainingRequirementEnum, string> = {
    [ProcessTrainingRequirementEnum.NONE]: "training.levelOfChange.none",
    [ProcessTrainingRequirementEnum.READING]: "training.levelOfChange.reading",
    [ProcessTrainingRequirementEnum.TRAINING]: "training.levelOfChange.training",
    [ProcessTrainingRequirementEnum.CERTIFICATION]: "training.levelOfChange.certification",
} as const;

export function getTrainingRequirementLocale(trainingRequirement: ProcessTrainingRequirementEnum): string {
    return TRAINING_REQUIREMENT_LOCALES_MAP[trainingRequirement];
}
