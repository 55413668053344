import { secondaryPalette } from "@aos/react-components";
import { EnumFontStyles } from "@kortex/aos-common";

export const kortexTheme = {
    KortexDialogTitle: {
        fontSize: 24,
        fontWeight: 500,
        color: secondaryPalette[500],
    },
    KortexDialogSubtitle: {
        fontSize: 20,
        fontWeight: 500,
        color: secondaryPalette[500],
    },
    KortexLabelTitle: {
        fontFamily: "Roboto",
        fontSize: "48px",
        lineHeight: "52px",
    },
    KortexLabelNormal: {
        fontFamily: "Roboto",
        fontSize: "17px",
        lineHeight: "23px",
    },
    KortexLabelHeader1: {
        fontFamily: "Roboto",
        fontSize: "28px",
        fontWeight: 500,
        lineHeight: "32px",
    },
    KortexLabelHeader2: {
        fontFamily: "Roboto",
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "28px",
    },
    KortexLabelHeader3: {
        fontFamily: "Roboto",
        fontSize: "22px",
        fontWeight: 500,
        lineHeight: "26px",
    },
    KortexLabelSmall: {
        fontFamily: "Roboto",
        fontSize: "15px",
        lineHeight: "18px",
    },
};

export function getTextStyle(fontStyle: EnumFontStyles): React.CSSProperties {
    switch (fontStyle) {
        case EnumFontStyles.TITLE:
            return kortexTheme.KortexLabelTitle;
        case EnumFontStyles.NORMAL:
            return kortexTheme.KortexLabelNormal;
        case EnumFontStyles.HEADER1:
            return kortexTheme.KortexLabelHeader1;
        case EnumFontStyles.HEADER2:
            return kortexTheme.KortexLabelHeader2;
        case EnumFontStyles.HEADER3:
            return kortexTheme.KortexLabelHeader3;
        case EnumFontStyles.SMALL:
            return kortexTheme.KortexLabelSmall;
        default:
            return kortexTheme.KortexLabelNormal;
    }
}
