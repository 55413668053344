export const trainingReadingCertificate = {
    rpc: {
        sign: {
            funcId: 13701,
            funcName: "trainingReadingCertificateSign",
        },
        getPendings: {
            funcId: 13702,
            funcName: "trainingReadingCertificateGetPendings",
        },
    },
    serviceId: 137,
    serviceVersion: 1,
};
